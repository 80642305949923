import React, { useCallback, useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { AxiosError } from "axios";
import { useSnackbar } from "notistack";
import { addClientsToDeal, updateDealClient, sendOnboardingInvite } from "api";
import { Client, UserParticipant } from "types/user";
import { Deal } from "types/deal";
import { ClientIntakeStates, ClientOnboarding, IntakeVersion } from "types/intakeForm";
import DeededCircularLoader from "v2/components/DeededCircularLoader";
import AdditionalClientForm from "components/Dashboard/NewClient/AdditionalClientForm";
import ModalBase from "components/Common/Modal/ModalBase";
import { validateSecondaryClient } from "components/Dashboard/NewClient/NewClient";
import { SMALL_BOLD_TEXT, SMALL_TEXT } from 'components/Common/componentStyling/Styles';
import { SmallBold } from 'components/Common/Typography';
import Colors from "components/Common/componentStyling/Colors";
import configuration from "utils/configuration";
import { getUserType, isClient } from "utils/permissionRoles";
import { useMixpanel } from "utils/MixpanelContext";
import useUser from "utils/hooks/useUser";
import CardHeader from '../DealDetails/CardHeader';
import SendOnboardingModal from "./Modals/SendOnboarding";
import ClientTableRow, {ClientTableStyledRow} from "./ClientTableRow";
import ClientTableDraftRow from "./ClientTableDraftRow";

type ClientData = Client & { middle_name: string; name_prefix?: string };

type DealDetailsClientTableProps = {
  onRefresh: () => Promise<void>;
  deal: Deal;
};

interface AddUserState {
  index: number,
  editing: boolean,
  secondary_clients: Array<UserParticipant>,
  inputErrors: { secondary_clients?: Partial<UserParticipant>[] },
}

const DealDetailsClientTable: React.FC<DealDetailsClientTableProps> = ({
  onRefresh,
  deal,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useUser();
  const mixpanel = useMixpanel();
  const clients = useMemo(
    () => deal?.participants?.filter((el) => isClient(el.role)) || [],
    [deal?.participants],
  );

  const clientOnboardingsMap = useMemo(() => {
    const map = new Map<number, ClientOnboarding>();
    deal?.client_onboardings?.forEach((onboarding) => {
      const client = clients.find((c) => c.user_id === onboarding.user_id);
      if (client) {
        map.set(client.id, onboarding);
      }
    });
    return map;
  }, [clients, deal]);

  const [isCompleted, hasOldToken] = useMemo(() => {
    const primaryClient = clients.find(c => c.role === "Client");
    const primaryOnboarding = clientOnboardingsMap.get(primaryClient?.id || 0);

    return [
      Boolean(primaryOnboarding?.intake?.state === ClientIntakeStates.Completed),
      Boolean(primaryOnboarding?.old_token)
    ];
  }, [clients, clientOnboardingsMap]);
  const isV1 = deal.intake_version === IntakeVersion.Legacy;

  const [sendingInvite, setSendingInvite] = useState<number|null>();
  const [modalOpen, setModalOpen] = useState(false);
  const [state, setState] = useState<AddUserState>({
    index: 0,
    editing: false,
    secondary_clients: [
      ...clients as Array<UserParticipant>
    ],
    inputErrors: {},
  })
  const { secondary_clients, index, inputErrors } = state;
  const [open, setOpen] = useState<null | number>(null);
  const [participant, setParticipant] = useState<UserParticipant>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [confirmDiscardOpen, setConfirmResendModal] = useState<boolean>(false);
  const disableDeleteClient = (secondary_clients || []).filter(c => !c.deleted_at).length === 1;

  const handleAddClient = () => {
    setModalOpen(true);
    setState(prevState => ({
      ...prevState,
      editing: false,
      inputErrors: {},
      index: prevState.secondary_clients.length,
      secondary_clients: [
        ...prevState.secondary_clients,
        {
          first_name: "",
          middle_name: "",
          last_name: "",
          email: "",
          phone: "",
        } as UserParticipant,
      ],
    }));
  };

  const handleEditClient = (contact_id: number) => {
    setModalOpen(true);
    setState(prevState => ({
      ...prevState,
      editing: true,
      inputErrors: {},
      index: prevState.secondary_clients.findIndex(c => c.id === contact_id),
      secondary_clients: [
        ...prevState.secondary_clients,
      ],
    }));

    handleMenuClose()
  }

  const handleRemoveClient = (index: number) => {
    setState(prevState => ({
      ...prevState,
      inputErrors: {},
      secondary_clients: [
        ...prevState.secondary_clients.slice(0, index),
        ...prevState.secondary_clients.slice(index + 1),
      ]
    }));
  }

  const handleUpdateClient = (data: UserParticipant, index: number) => {
    setState(prevState => {
      const newSecondaryClients = [...prevState.secondary_clients];
      newSecondaryClients[index] = data;

      return {
        ...prevState,
        inputErrors: {},
        secondary_clients: newSecondaryClients,
      }
    });
  }

  const handleSave = () => {
    let errorMap = validateSecondaryClient(state.secondary_clients);
    setState((prev) => ({
      ...prev,
      inputErrors: { ...errorMap as object },
    }));

    if (
      Object.keys(errorMap).length !== 0 &&
      Object.entries(errorMap).filter((el) => el[1] !== false).length !== 0
    ) {
      return;
    }

    let request = state.editing ?
      updateDealClient(deal.id, state.secondary_clients[index]) :
      addClientsToDeal(deal.id, { secondary_clients: state.secondary_clients.filter(c => !!!c?.id) })

    request.then((res) => {
      const secondary_clients = res.data.filter(el => isClient(el.role)) || [];
      setModalOpen(false);
      // Refresh deal
      onRefresh();

      enqueueSnackbar("Client Information saved", { variant: "success" });
    })
      .catch((error) => {
        enqueueSnackbar(
          error?.response?.data?.error || "Failed to create user",
          {
            variant: "error",
            autoHideDuration: configuration.autoHideErrorDuration,
          },
        );
      });
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, index: number) => {
    setOpen(index);
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setOpen(null);
    setAnchorEl(null);
  };

  const handleSendInvite = useCallback(async (
    participant: UserParticipant,
  ) => {
    if (sendingInvite) {
      return;
    }

    const onboarding = clientOnboardingsMap.get(participant.id);
    try {
      setSendingInvite(participant.id);
      await sendOnboardingInvite(deal.id, participant.id);
      enqueueSnackbar("Onboarding sent to the client", { variant: "success" });
      // Refresh deal
      await onRefresh();
    } catch (error) {
      let message = "Failed to send invite";
      if (error instanceof AxiosError && error.response?.data?.error) {
        message = error.response?.data?.error;
      }

      enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: configuration.autoHideErrorDuration,
      });
    } finally {
      let type = Boolean(onboarding?.invite_sent_at) ? 'resend': 'send'
      setSendingInvite(null);
      mixpanel.track(`${type}_onboarding_clicked_client_table`, {
        role: user.role,
        email: user.email,
        event_type: Boolean(onboarding?.invite_sent_at) ? 'Re-sent': 'Sent',
        user_type: getUserType(user.role),
        deal_province: deal.address?.state || '-',
        deal_type: deal.deal_type,
        customer_type: participant.role,
      });
    }
  }, [sendingInvite]);

  function handleResendModalSubmit() {
    setConfirmResendModal(false);
    handleSendInvite(participant as UserParticipant);
  }

  const handleResendInvite = (
    participant: UserParticipant,
  ) => {
    setConfirmResendModal(true);
    setParticipant(participant);
  }

  useEffect(() => {
    setState(state => ({
      ...state,
      secondary_clients: clients as Array<UserParticipant>,
    }))
  }, [clients]);

  if (secondary_clients === null) {
    return <DeededCircularLoader />;
  }

  return (
    <>
      <CardHeader
        buttonText="Add Client"
        buttonAction={handleAddClient}>Clients</CardHeader>

      <div className="deal__property-card" style={{ 'marginTop': '3rem', overflow: 'auto', padding: 0 }}>
        <SendOnboardingModal
          onClose={() => setConfirmResendModal(false)}
          onSubmit={() => handleResendModalSubmit()}
          openSendOnboardingModal={confirmDiscardOpen}
        />

        <HeaderRow>
          <ClientTableCell sx={{ paddingLeft: 0 }}><SmallBold>Name and contact</SmallBold></ClientTableCell>
          <ClientTableCell><SmallBold>Client Type</SmallBold></ClientTableCell>
          <ClientTableCell><SmallBold>Onboarding Status</SmallBold></ClientTableCell>
          {deal?.idv_feature ?
            <ClientTableCell><SmallBold>IDV Status</SmallBold></ClientTableCell>
          : null }
          <ClientTableCell><SmallBold>Onboarding Link</SmallBold></ClientTableCell>
          <ClientTableCell>&nbsp;</ClientTableCell>
        </HeaderRow>

        {secondary_clients.map((participant, index) => (
            <ClientTableRow
                key={`client-${participant.id}`}
                index={index}
                participant={participant}
                onboarding={clientOnboardingsMap.get(participant.id)}
                deal={deal}
                isV1={isV1}
                isCompleted={isCompleted}
                hasOldToken={hasOldToken}
                deleteDisabled={disableDeleteClient}
                anchorEl={anchorEl}
                menuOpen={open}
                sendingInvite={sendingInvite}
                onClick={handleClick}
                onEditClient={handleEditClient}
                onSendInvite={handleSendInvite}
                onResendInvite={handleResendInvite}
                onRequestMenuClose={handleMenuClose}
                onRequestRefresh={onRefresh}
            />
        ))}
        {deal?.contact_drafts?.map((draft) => (
            <ClientTableDraftRow
                key={`client-draft-${draft.id}`}
                draft={draft}
                deal={deal}
                onRequestRefresh={onRefresh}
            />
        ))}

        <ModalBase
          open={modalOpen}
          onClose={() => {
            setModalOpen(false);
            if (!state.editing) {
              handleRemoveClient(index);
            }
            handleMenuClose();
            onRefresh();
          }}
          onSubmit={handleSave}
          size="small"
          title={state.editing ? "Edit client information" : "Add client information"}
          saveButtonLabel="Save"
          saveDisabled={false}
        >
          <AdditionalClientForm
            key={index}
            client={secondary_clients[index] as ClientData}
            canRemove={false}
            hideDraft={state.editing}
            inputErrors={inputErrors?.secondary_clients?.[index]}
            onRemove={() => { }}
            onUpdate={(data) => handleUpdateClient(data as UserParticipant, index)}
          />
        </ModalBase>
      </div>
    </>
  )
};

const HeaderRow = styled(ClientTableStyledRow)(
  ({theme}) => ({
    padding: '0 0 .8rem',
    margin: 0,
    borderBottom: `1px solid ${Colors['GRAY_300']}`,
    "& > *": {
      marginTop: '2.4rem'
    },
    [theme.breakpoints.down("md")]: {
      display: 'none',
    },
  })
);

export const ClientTableCell = styled(Box)(
  ({theme}) => ({
    ...SMALL_TEXT,
    marginLeft: '2.4rem',
    display: 'inline-block',
    alignSelf: 'center',
    "& > p": {
      lineHeight: '1.8rem',
      paddingBottom: '.8rem'
    },
    "& > p:last-child": {
      paddingBottom: 0
    },
    "& > p > svg": {
      marginRight: '.8rem',
      verticalAlign: 'middle'
    },
    "&:last-child": {
      textAlign: 'right',
      marginRight: '2.4rem'
    },
    [theme.breakpoints.down("md")]: {
      margin: '0 0 1.6rem 0',
      alignSelf: 'start',

      "&:nth-of-type(2), &:nth-of-type(3)": {
        "&::before": {
          ...SMALL_BOLD_TEXT,
          content: "attr(data-type) ': '"
        },
      },
      "&:last-child": {
        position: 'absolute',
        top: '1.6rem',
        right: '2.4rem',
        margin: 0,
      }
    },
  })
);

export default DealDetailsClientTable;
