// @ts-ignore
import { jwtDecode } from "jwt-decode";
import storage from "./storage";

const resolveJwtTokenVerified = () => {
  try {
    const userId = storage.userId;

    if (userId) {
      const token = storage.token;

      if (jwtDecode(token || '').exp.valueOf() * 1000 < new Date().valueOf()) {
        return "expired";
      }

      return token;
    }
    return null;
  } catch (err) {
    return null;
  }
};

/**
 * Verifies if the last interaction made by the user was within the token TTL
 */
const shouldRefreshToken = (): Boolean => {
  const token = resolveJwtTokenVerified();
  if (token === null || token === "expired") {
    return false;
  }

  const decodedToken = jwtDecode(token);
  const maxTokenIdleTime = (decodedToken.exp - decodedToken.nbf) * 1000;

  // First validation may need to populate this property
  if (storage.lastCallTimestamp === null) {
    storage.lastCallTimestamp = new Date().valueOf().toString();
  }
  const idleTime = new Date().valueOf() - Number(storage.lastCallTimestamp);

  return idleTime <= maxTokenIdleTime;
};

export {resolveJwtTokenVerified, shouldRefreshToken};
