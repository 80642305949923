import { PlusIcon } from "components/Common/Icons/Iconography";
import Colors from "components/Common/componentStyling/Colors";
import BasicButton from "components/Common/Button/BasicButton";
import {useTheme, Theme, styled} from "@mui/material/styles";
import {DefaultTheme} from "@mui/styles";
import Box from "@mui/material/Box";
import { H3 } from "components/CommonDashboard/Typography";

type CardHeaderProps = {
  buttonAction: () => void;
  buttonText: string;
  buttonTestId?: string;
};

const CardHeader: React.FC<CardHeaderProps> = ({
  children,
  buttonAction,
  buttonText,
  buttonTestId
}) => {
  const theme = useTheme();

  return (
    <CardHeaderBox theme={theme}>
      <H3>{children}</H3>
      <BasicButton
        leftIcon={
          <PlusIcon color={Colors['WHITE']} />
        }
        testId={buttonTestId}
        size="small"
        label={{
          text: buttonText,
          inputId: `send-intake-button`,
        }}
        typeOf={"primary"}
        onClick={buttonAction}
        sx={{width: 'auto !important'}}
      />
    </CardHeaderBox>
  )
};

const CardHeaderBox = styled(Box)<{theme: Theme & DefaultTheme}>(
  ({theme}) => ({
    display: 'flex',
    justifyContent: "space-between",
    margin: '4rem 0 0 0',
    flexDirection: 'row',
    [theme.breakpoints.down("md")]: {
      flexDirection: 'column',
      gap: '2rem'
    }
  })
);

export default CardHeader;