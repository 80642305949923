import moment, {Moment} from "moment";

export const calculateFiveDayWeekCalendar = (date: Date) => {
  const endMonthDay = moment(date).endOf("month").endOf("week");
  const startMonthDay = moment(date).startOf("month").startOf("week");
  const calendarShowingDaysArray: Moment[][] = [];
  let dateForCycle = startMonthDay.clone();
  while (dateForCycle.isBefore(endMonthDay.clone().subtract(1, "day"), "day")) {
    calendarShowingDaysArray.push(
      Array(5)
        .fill(0)
        .map((day) =>
          dateForCycle.add(1, "day").clone(),
        ) as unknown as Moment[],
    );
    dateForCycle = dateForCycle.add(1, "week").startOf("week").clone();
  }
  return calendarShowingDaysArray;
};
