import React, { FC, useMemo } from "react";
import { useHistory } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import configuration from "utils/configuration";
import useUser from "utils/hooks/useUser";
import newsImg from "assets/img/HomeNews.png";
import { BellIcon, PlusIcon } from "../../CommonDashboard/Icons";
import { BodyRegular, H2 } from "../../CommonDashboard/Typography";
import Colors from "../../CommonDashboard/Styling/Colors";
import Button from "../../CommonDashboard/Button";
import { webLink } from "utils/openLink";
import CompactReview from "components/CommonDashboard/Reviews/CompactReview";

const HomeNews: FC = () => {
    const history = useHistory();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("mobile"));
    const { user } = useUser();

    const { title, lineOne, lineTwo } = useMemo(
        () =>
            user.points_feature
                ? {
                      title: "Earn rewards with every transaction closed!",
                      lineOne:
                          "Now you can earn points towards incredible rewards for every deal you close with Deeded. It's our way of saying thank you for being an incredible partner!",
                      lineTwo: "Check out the awesome rewards below. Some conditions apply.",
                  }
                : {
                      title: "Thank you for partnering with us.",
                      lineOne:
                          "Our team is dedicated to ensuring seamless transaction closings. Let's work together to make our clients happy.",
                      lineTwo: "Check out the some of the awesome reviews we have.",
                  },
        [user.points_feature],
    );

    return (
        <Wrapper>
            <IconWrapper>
                <BellIcon />
            </IconWrapper>
            <Stack gap="4.8rem" direction={isMobile ? "column" : "row"}>
                <Stack gap="1.6rem">
                    <H2>{title}</H2>
                    <BodyRegular>{lineOne}</BodyRegular>
                    <BodyRegular>{lineTwo}</BodyRegular>
                </Stack>
                {user.points_feature ? (
                    <StyledImg alt="Home news block image" src={newsImg} />
                ) : (
                    <CompactReview />
                )}
            </Stack>
            <Stack width="100%" gap="1.6rem" direction={isMobile ? "column" : "row"} alignItems="stretch" pt="2.2rem">
                {user.points_feature ? (
                    <Button
                        onClick={() => history.push("/dashboard/new-client")}
                        typeOf="primary"
                        label={{ text: "Add new transaction" }}
                        startIcon={<PlusIcon color={Colors.WHITE} />}
                    />
                ) : (
                    <Button
                        component="a"
                        href={webLink("reviews")}
                        target="_blank"
                        typeOf="primary"
                        label={{ text: "See more of our reviews" }}
                        startIcon={<PlusIcon color={Colors.WHITE} />}
                    />
                )}
                {user.points_feature && configuration.pointsEnabled && (
                    <Button
                        testId="check-rewards-btn"
                        href={configuration.pointsUrl}
                        target="_blank"
                        component="a"
                        typeOf="secondary"
                        label={{ text: "Check out the Rewards" }}
                    />
                )}
            </Stack>
        </Wrapper>
    );
};

const StyledImg = styled("img")(() => ({
    flexShrink: 0,
    width: "218px",
    height: "300px",
    alignSelf: "center",
}));

const IconWrapper = styled(Box)(() => ({
    display: "flex",
    minWidth: "4.8rem",
    height: "4.8rem",
    borderRadius: "2.4rem",
    background: Colors.PRIMARY_50,
    alignItems: "center",
    justifyContent: "center",
    whiteSpace: "nowrap",
}));

const Wrapper = styled(Stack)(() => ({
    flex: 1,
    flexDirection: "column",
    alignItems: "flex-start",
    backgroundColor: Colors.WHITE,
    gap: "2.4rem",
    padding: "2.4rem",
    borderRadius: "2.4rem",
}));

export default HomeNews;
