import React from "react";

type Storage3DotsSvgType = {
  className: string | undefined;
};
const Storage3DotsSvg: React.FC<Storage3DotsSvgType> = ({className}) => {
  return (
    <svg
      className={className}
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="14" cy="6" r="2" fill="#C4C4C4" />
      <circle cx="14" cy="14" r="2" fill="#C4C4C4" />
      <circle cx="14" cy="22" r="2" fill="#C4C4C4" />
    </svg>
  );
};

export default Storage3DotsSvg;
