import React, {useContext} from "react";
import moment, {Moment} from "moment";
import useTypedSelector from "utils/hooks/useTypedSelector";
import constants from "styles/constants";
import {Box, styled, Typography} from "@mui/material";
import {CalendarMobileContext} from "../../context/CalendarMobileContext";
import {getCurrentDateTypedClosingsCalendarResultTotalByRole} from "../../utils/getCurrentDateTypedClosingsCalendarResultTotalByRole";
import CalendarMobileDateSectorComponent from "./CalendarMobileDateSectorComponent";

const CalendarMobileSortedClosingsComponents = () => {
  const daysToDisplayArray = useGetDaysListClosings();
  return (
    <>
      <Box>
        {daysToDisplayArray.map((dayToDisplay, index) => (
          <CalendarMobileSortedClosingsDayInfoComponent
            key={index}
            dayToDisplay={dayToDisplay}
          />
        ))}
      </Box>
    </>
  );
};
const CalendarMobileSortedClosingsDayInfoComponent: React.FC<{
  dayToDisplay: Moment;
}> = ({dayToDisplay}) => {
  const eventsDate = dayToDisplay.format("DD MMMM YYYY");
  const closingsCalendarResultByDates = useTypedSelector(
    (state) => state.CalendarReducer.closingsCalendarResultByDates,
  );
  const currentDateTypedClosingsCalendarResultTotalByLawClerk =
    getCurrentDateTypedClosingsCalendarResultTotalByRole(
      "Law Clerk",
      closingsCalendarResultByDates,
      dayToDisplay,
    );
  const currentDateTypedClosingsCalendarResultTotalByLawyer =
    getCurrentDateTypedClosingsCalendarResultTotalByRole(
      "Lawyer",
      closingsCalendarResultByDates,
      dayToDisplay,
    );
  return (
    <>
      <CalendarMobileDateSectorComponent eventsDate={eventsDate}>
        <Box
          id={`${eventsDate} 0`}
          sx={{
            height: "84px",
            padding: "16px 20px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <RoleNameBox>
            <RoleTypography>Lawyer</RoleTypography>
            <CountTypography
              isZero={currentDateTypedClosingsCalendarResultTotalByLawyer === 0}
            >
              {currentDateTypedClosingsCalendarResultTotalByLawyer}
            </CountTypography>
          </RoleNameBox>
          <RoleNameBox>
            <RoleTypography>Law Clerk</RoleTypography>
            <CountTypography
              isZero={
                currentDateTypedClosingsCalendarResultTotalByLawClerk === 0
              }
            >
              {currentDateTypedClosingsCalendarResultTotalByLawClerk}
            </CountTypography>
          </RoleNameBox>
        </Box>
      </CalendarMobileDateSectorComponent>
    </>
  );
};
const useGetDaysListClosings = () => {
  const {date, view} = useContext(CalendarMobileContext);
  const daysToDisplayArray = [];
  const startDate =
    view === "day"
      ? moment(date)
      : moment(date)
          .clone()
          .startOf(view as "month" | "week")
          .startOf("week");
  const endDate =
    view === "day"
      ? moment(date)
      : moment(date)
          .clone()
          .endOf(view as "month" | "week")
          .endOf("week");
  startDate.add(-1, "d");
  while (
    startDate.isBefore(endDate.clone().subtract(view === "day" ? 0 : 1, "d"))
  ) {
    daysToDisplayArray.push(startDate.add(1, "d").clone());
  }
  return daysToDisplayArray;
};
const RoleNameBox = styled(Box)({
  display: "flex",
});
const RoleTypography = styled(Typography)({
  fontSize: "14px",
  fontWeight: "700",
  color: constants.colors.red,
  width: "103px",
});
const CountTypography = styled(Typography, { shouldForwardProp: (propName) => propName !== "isZero" })<{
    isZero?: boolean;
}>(({ isZero }) => ({
    fontSize: "14px",
    fontWeight: "700",
    color: isZero ? constants.colors.searchColor : constants.colors.sortingColor,
}));
export default CalendarMobileSortedClosingsComponents;
