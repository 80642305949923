import React from "react";
import "./GenericComponents.scss";
import CalendarSVG from "../../Svg/Calendar";
import NumberFormat from "react-number-format";
import Cleave from "cleave.js/react";
import DeededDatePicker from "v2/components/DeededDatePicker";
import moment from "moment";

/**
 * This is the wrapper for placing generic UI elements
 * Make sure you use it when you add the components
 * @param {Object} props
 * @param {JSX.Element | JSX.Element[]} props.children
 */
export function GenericComponentsWrapper({children}) {
  return <div className="generic__wrapper">{children}</div>;
}

/**
 * The Radio button
 * @param {Object} props
 * @param {string} props.title
 * @param {number} props.value
 * @param {string} props.name
 * @param {boolean} props.isRequired
 * @param {boolean} props.checked
 * @param {(e:any)=>void} props.onChange
 */
export function RadioButton({
  name,
  isRequired,
  title,
  value,
  checked,
  onChange,
}) {
  return (
    <label className="gen_radio-container">
      <h3 className="gen_radio_title">{title}</h3>
      <input
        className="gen_radio_input"
        {...(isRequired ? {required: true} : {})}
        type="radio"
        name={name}
        value={value}
        checked={checked}
        onChange={(e) => onChange(e.target.value)}
      />
      <span className="gen_checkmark"></span>
    </label>
  );
}

/**
 * The Input field button
 * @param {Object} props
 * @param {string} [props.title]
 * @param {string} props.value
 * @param {string} props.name
 * @param {string?} props.numberFormat
 * @param {Object} props.cleaveOptions
 * @param {string|number} props.width
 * @param {(e:any)=>void} props.onChange
 * @param {string} props.placeHolder
 * @param {string?} props.type
 */
export function InputField({
  name,
  required,
  title,
  onChange,
  numberFormat,
  cleaveOptions,
  value,
  width,
  placeHolder,
  type = "text",
  inputClassName = ""
}) {
  // check if there is number format
  const requiredProp = required ? {required: true} : {};
  return (
    <div className="gen_input-field">
      {title && (
        <label className="gen_input_field_label" htmlFor={name}>
          {title}
        </label>
      )}

      {cleaveOptions ? (
        <Cleave
          name={name}
          placeholder={placeHolder}
          value={value}
          options={cleaveOptions}
          onFocus={(e) => onChange(e.target.value)}
          onChange={(e) => onChange(e.target.value)}
        />
      ) : numberFormat ? (
        <NumberFormat
          value={value || ""}
          {...requiredProp}
          placeholder={placeHolder}
          {...(width ? {style: {width: width}} : {})}
          format={numberFormat}
          id={name}
          name={name}
          onChange={(e) => onChange(e.target.value)}
        />
      ) : (
        <input
          {...requiredProp}
          className={inputClassName}
          placeholder={placeHolder}
          {...(width ? {style: {width: width}} : {})}
          name={name}
          id={name}
          type={type}
          value={value || ""}
          onChange={(e) => onChange(e.target.value)}
        />
      )}
    </div>
  );
}

/**
 * This one is useful for pushing title on top of custom forms like radio button serie.
 * @param {Object} props
 * @param {JSX.Element} props.children
 */
export function FormSubtitle({children}) {
  return <p className="gen_form-subtitle">{children}</p>;
}

/**
 * Date picker for setting data like birth date.
 * @param {Object} props
 * @param {string} [props.title]
 * @param {boolean} [props.withTime]
 * @param {JSX.Element | JSX.Element[]} [props.children]
 */
export function DatePickerContaiener({title, children, withTime}) {
  return (
    <div className="gen_date-picker">
      {title && <p className="gen_date_p">{title}</p>}
      <div className={withTime ? "calendar-with-time" : "calendar"}>
        <CalendarSVG color={"#7F7F7F"} />
        {children}
      </div>
    </div>
  );
}

/**
 * Date picker for setting data like birth date.
 * @param {Object} props
 * @param {string} [props.title]
 * @param {number} props.index
 * @param {string} props.date
 * @param {(value: any, index: number)=>void)} props.onDateChanged
 */
export function DatePicker({title, index, date, onDateChanged}) {
  // this is taken from the BirthdayCalendar.js
  const BirthdayCalendar = ({handleDate, date, index}) => {
    return (
      <DeededDatePicker
        dateStart={date ? moment(date).toDate() : null}
        onChange={(date) => handleDate(date, index)}
      />
    );
  };

  // It seems that Date.parse requires the date format in YYYY-MM-DD
  const preformattedDate = date
    .trim()
    .split(".")
    .reverse()
    .reduce((a, b) => a + "-" + b);

  return (
    <DatePickerContaiener title={title}>
      <BirthdayCalendar
        handleDate={onDateChanged}
        date={Date.parse(preformattedDate)}
        index={index}
      />
    </DatePickerContaiener>
  );
}

/**
 * The text area wrapper
 * @param {Object} props
 * @param {string} props.value
 * @param {string} [props.title]
 * @param {(value:string)=>void} props.onChange
 * @param {string} props.name
 * @param {string} props.placeHolder
 */
export function TextArea({value, title, onChange, name, placeHolder}) {
  return (
    <div className="gen_text-area">
      <label htmlFor={name}>
        <p>{title}</p>
        <textarea
          value={value}
          onChange={(e) => onChange(e.target.value)}
          name={name}
          placeholder={placeHolder}
        ></textarea>
      </label>
    </div>
  );
}

/**
 *
 * @param {Object} props
 * @param {string} props.value
 * @param {boolean} props.checked
 * @param {string} props.name
 * @param {string} props.title
 * @param {(checked:boolean)=>void} props.onChange
 */
export function CheckBox({checked, onChange, value, name, title}) {
  onChange = onChange || (() => {});
  return (
    <div className="checkbox-status">
      <label className="container">
        <input
          checked={checked}
          type="checkbox"
          name={name}
          value={value}
          onChange={(e) => onChange(e.target.checked)}
        />
        {title}
        <span className="checkbox-checkmark"></span>
      </label>
    </div>
  );
}

/**
 * Heading wrapper
 * @param {Object} props
 * @param {any} props.children
 * @param {number} props.size
 */
export function Heading({children, size = 1}) {
  // TODO(Oleg): styles aren't implemented for all heading types
  // for now it defaults to title__second
  size = size < 1 ? 1 : size > 6 ? 6 : size;
  return React.createElement("h" + size, {
    children,
    className: "generic-heading",
  });
}

/**
 * A horizontal line which divides the components
 * @param {Object} props
 * @param {any} props.children
 * @param {number} props.size
 */
export function Separator() {
  return <div style={{borderTop: "1px solid #E2E2E2", marginBottom: 30}} />;
}

/**
 * A button used for the document upload
 *
 * @param {Object} props
 * @param {string} props.title
 * @param {number?} props.width
 * @param {Object} props.filenameRef
 * @param {(value: any)=>void} props.onUpload
 * @param {string} props.name
 * @param {Boolean} props.uploaded
 */
export function UploadButton({ title, filenameRef, onUpload, width, name, uploaded = false }) {
  const widthStyle = width
    ? { width, display: "flex", flexDirection: "row" }
    : {};

  return (
    <div className="file_input" style={widthStyle}>
      <label
        ref={filenameRef}
        htmlFor={name || "upload-button"}
        className={uploaded ? "document-uploaded" :
          "document-statement"}
      >
        {title}
      </label>
      <input
        id={name || "upload-button"}
        type="file"
        style={{ display: "none" }}
        onChange={onUpload}
      ></input>
    </div>
  );
}

/**
 * An Empty space container
 *
 * @param {Object} props
 * @param {number?} props.width
 * @param {number?} props.height
 */
export function Spacer({width, height}) {
  return <div style={{width, height}}></div>;
}
