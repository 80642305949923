import React, {useState} from "react";
import {
  Box,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import {styled} from "@mui/material/styles";
import constants from "styles/constants";
import useTypedSelector from "utils/hooks/useTypedSelector";
import {CommunicationTemplate} from "redux/reducers/types/configNotificationsReducerType";
import DeededButton from "v2/components/DeededButton";
import DeededSpace from "v2/components/DeededSpace";
import ConfigNotificationsTemplateItemRow from "./ConfigNotificationsTemplateItemRow";
import ConfigNotificationsTemplateForm from "./ConfigNotificationsTemplateForm";

const ConfigNotificationsCommunications = () => {
  const communications = useTypedSelector(
    (state) => state.ConfigReducer.ConfigNotificationsReducer.communications,
  );
  const [isEditNewTemplate, setIsEditNewTemplate] = useState(false);
  const theme = useTheme();

  return (
    <TableContainer
      sx={{
        "&.MuiTableContainer-root": {
          overflowX: "unset",
          marginRight: "20px",
        },
      }}
      component={Box}
    >
      <Table
        sx={(theme) => ({
          minWidth: 650,
          borderCollapse: "separate",
          [theme.breakpoints.down("sm")]: {
            minWidth: "100%",
            display: "none",
            backgroundColor: constants.colors.white,
            padding: "16px",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.06)",
            borderRadius: "5px",
          },
        })}
        aria-label="simple table"
      >
        <TableHead>
          <TableHeadRow
            sx={(theme) => ({
              [theme.breakpoints.down("sm")]: {
                display: "flex",
                flexDirection: "column",
              },
            })}
          >
            <TableCell
              sx={(theme) => ({
                width: "250px",
                [theme.breakpoints.down("sm")]: {
                  width: "50%",
                  fontSize: "12px",
                  lineHight: "15px",
                  padding: "8px",
                },
              })}
            >
              <TableTitle align="right">Template name</TableTitle>
            </TableCell>

            <TableCell
              sx={(theme) => ({
                width: "200px",
                [theme.breakpoints.down("sm")]: {
                  width: "50%",
                  fontSize: "12px",
                  lineHight: "15px",
                  padding: "8px",
                },
              })}
              align="right"
            >
              <TableTitle>Recipient</TableTitle>
            </TableCell>
            <TableCell
              sx={(theme) => ({
                width: "250px",
                [theme.breakpoints.down("sm")]: {
                  width: "50%",
                  fontSize: "12px",
                  lineHight: "15px",
                  padding: "8px",
                },
              })}
              align="right"
            >
              <TableTitle>Recipient BCC</TableTitle>
            </TableCell>
            <TableCell
              sx={(theme) => ({
                width: "200px",
                [theme.breakpoints.down("sm")]: {
                  width: "50%",
                  fontSize: "12px",
                  lineHight: "15px",
                  padding: "8px",
                },
              })}
              align="right"
            >
              <TableTitle>On/Off</TableTitle>
            </TableCell>
            <TableCell
              sx={(theme) => ({
                width: "15%",
                [theme.breakpoints.down("sm")]: {
                  width: "15%",
                  fontSize: "12px",
                  lineHight: "15px",
                  padding: "8px",
                },
              })}
              align="right"
            >
              <TableTitle></TableTitle>
            </TableCell>
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {communications?.map(
            (
              template: CommunicationTemplate,
              index: React.Key | null | undefined,
            ) => (
              <ConfigNotificationsTemplateItemRow
                template={template}
                key={index}
              />
            ),
          )}
        </TableBody>
      </Table>
      {communications?.map(
        (
          template: CommunicationTemplate,
          index: React.Key | null | undefined,
        ) => (
          <Table
            sx={(theme) => ({
              minWidth: 650,
              borderCollapse: "separate",
              display: "none",
              [theme.breakpoints.down("sm")]: {
                minWidth: "100%",
                display: "flex",
                backgroundColor: constants.colors.white,
                padding: "16px",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.06)",
                borderRadius: "5px",
                marginBottom: "8px",
                position: "relative",
              },
            })}
            aria-label="simple table"
          >
            <TableHead>
              <TableHeadRow
                sx={(theme) => ({
                  [theme.breakpoints.down("sm")]: {
                    display: "flex",
                    flexDirection: "column",
                  },
                })}
              >
                <TableCell
                  sx={(theme) => ({
                    width: "250px",
                    [theme.breakpoints.down("sm")]: {
                      width: "50%",
                      fontSize: "12px",
                      lineHight: "15px",
                      padding: "8px",
                    },
                  })}
                >
                  <TableTitle align="right">Template name</TableTitle>
                </TableCell>

                <TableCell
                  sx={(theme) => ({
                    width: "200px",
                    [theme.breakpoints.down("sm")]: {
                      width: "50%",
                      fontSize: "12px",
                      lineHight: "15px",
                      padding: "8px",
                    },
                  })}
                  align="right"
                >
                  <TableTitle>Recipient</TableTitle>
                </TableCell>
                <TableCell
                  sx={(theme) => ({
                    width: "250px",
                    [theme.breakpoints.down("sm")]: {
                      width: "50%",
                      fontSize: "12px",
                      lineHight: "15px",
                      padding: "8px",
                    },
                  })}
                  align="right"
                >
                  <TableTitle>Recipient BCC</TableTitle>
                </TableCell>
                <TableCell
                  sx={(theme) => ({
                    width: "200px",
                    [theme.breakpoints.down("sm")]: {
                      width: "50%",
                      fontSize: "12px",
                      lineHight: "15px",
                      padding: "8px",
                    },
                  })}
                  align="right"
                >
                  <TableTitle>On/Off</TableTitle>
                </TableCell>
                <TableCell
                  sx={(theme) => ({
                    width: "15%",
                    [theme.breakpoints.down("sm")]: {
                      width: "15%",
                      fontSize: "12px",
                      lineHight: "15px",
                      padding: "8px",
                      display: "none",
                    },
                  })}
                  align="right"
                >
                  <TableTitle></TableTitle>
                </TableCell>
                <TableCell
                  sx={(theme) => ({
                    width: "200px",
                    [theme.breakpoints.down("sm")]: {
                      width: "50%",
                      fontSize: "12px",
                      lineHight: "15px",
                      padding: "8px",
                      display: "none",
                    },
                  })}
                  align="right"
                ></TableCell>
              </TableHeadRow>
            </TableHead>
            <TableBody>
              <ConfigNotificationsTemplateItemRow
                template={template}
                key={index}
              />
            </TableBody>
          </Table>
        ),
      )}
      <DeededSpace
        y={12}
        sx={{
          [theme.breakpoints.down("sm")]: {
            height: "24px",
          },
        }}
      />
      <Collapse in={isEditNewTemplate}>
        <ConfigNotificationsTemplateForm
          handleClose={() => {
            setIsEditNewTemplate(false);
          }}
        />
      </Collapse>
      <AddNewTemplateBtn
        onClick={() => {
          setIsEditNewTemplate(!isEditNewTemplate);
        }}
        type={"button"}
        kind={"secondary"}
      >
        <span
          style={{
            fontSize: "40px",
            fontWeight: "300",
            marginRight: "9px",
          }}
        >
          +
        </span>{" "}
        Add New Template
      </AddNewTemplateBtn>
    </TableContainer>
  );
};

const AddNewTemplateBtn = styled(DeededButton)({});
const TableHeadRow = styled(TableRow)({
  "& .MuiTableCell-root": {
    border: "none",
    // padding: "23px 16px 23px 16px",
  },
});
const TableTitle = styled(Typography)({
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "17px",
  display: "flex",
  alignItems: "center",
  color: constants.colors.deededGray,
  whiteSpace: "nowrap",
});
export default ConfigNotificationsCommunications;
