import React, {useEffect, useRef} from "react";
import {EventType} from "../types/calendar";
import {generateTitleForEvent} from "../utils/generateTitleForEvent";

export interface EventComponentProps {
  event: Event;
  username?: string;
  findSelectedMobileSigner: (event: EventType) => string;
}

export interface Event {
  timezone?: string;
  title: string;
  start?: Date;
  end?: Date;
  type?: string;
  event: EventType;
}

export const EventComponent: React.FC<EventComponentProps> = ({
  event,
  findSelectedMobileSigner,
}) => {
  const ref = useRef<null | HTMLDivElement>(null);
  useEffect(() => {
    const eventElement = ref.current?.closest(".rbc-event");
    eventElement?.classList.add(event.event.timezone ?? "");
    if (event.event.type) {
      eventElement?.classList.add(event.event.type);
    }
    return () => {
      eventElement?.classList.remove(event.event.timezone ?? "");
      eventElement?.classList.remove(event.event.type as string);
    };
  }, [event]);

  const title = generateTitleForEvent(event);
  return <div data-testid="appointment-sell-inside-calendar" ref={ref}>{title}</div>;
};
