import useTypedSelector from "./useTypedSelector";
import {isAdmin, isClient, isProfessional, isStaff} from "../permissionRoles";

const useUser = () => {
    const user = useTypedSelector((state) => state.AuthReducer.currentUser);

    return {
        user,
        isAdmin: isAdmin(user.role),
        isClient: isClient(user.role),
        isStaff: isStaff(user.role),
        isProfessional: isProfessional(user.role),
    };
};

export default useUser;
