import constants from "../../../../styles/constants";

export const generateClosingsSumColor = (
  sum: number,
  capacity?: {high: number; medium: number} | null,
) => {
  if (capacity) {
    if (sum >= capacity.high) {
      return constants.colors.red;
    } else if (sum >= capacity.medium) {
      return constants.colors.yellowClosingsSum;
    } else {
      return constants.colors.sortingColor;
    }
  } else {
    return constants.colors.sortingColor;
  }
};
