import {baseURL} from "../../../api";
import axios from "axios";
import moment from "moment/moment";
import configuration from "../../../utils/configuration";

// Yeah, yeah this one definitely should reside somewhere in configs
// TODO: Move this to configs? Change it globally
// Maybe one day we would need persisted notifications or get them alive for longer time.
export const DEFAULT_AUTO_HIDE_DURATION = 1500;

/*
 * There are three types of API functions related to client intake data
 *
 * Fetch: /deals/{deal}/client-intake IntakeInfoController@showClientIntake
 * Update: /deals/{deal}/client-intake/update IntakeInfoController@updateGeneralInfo
 * Update personal info: /deals/{deal}/personal-info/{id}/update IntakeInfoController@updatePersonalInfo
 */

export const ApiOperation = {
  FETCH: 0,
  UPDATE: 1,
  UPDATE_PERSONAL_INFO: 2,
};

/**
 * Build the API URL for handling server operations
 *
 * @param {number} operation Type of operation
 * @param {number} dealId
 * @param {number} [id] Personal data user ID
 */
function getApiUrl(operation, dealId, id) {
  switch (operation) {
    case ApiOperation.UPDATE_PERSONAL_INFO:
      return `${baseURL}/deals/${dealId}/personal-info/${id}/update`;
    case ApiOperation.UPDATE:
      return `${baseURL}/deals/${dealId}/client-intake/update`;
    case ApiOperation.FETCH:
    default:
      return `${baseURL}/deals/${dealId}/client-intake`;
  }
}

/**
 * Retrieve the user id from the API struct.
 * Since the API object contains sellers and owners
 * array the user ID is stored differently.
 *
 * @param {object} client
 * @param {number} userIndex
 */
function extractUserId(client, userIndex) {
  // The API data can contain "owners" "sellers"
  if ("owners" in client) {
    return client.owners[userIndex].id;
  } else if ("purchasers" in client) {
    return client.purchasers[userIndex].id;
  } else if ("lenders" in client) {
    return client.lenders[userIndex].id;
  }
  return client.sellers[userIndex].id;
}

/**
 *
 * @param {string|Object|number} value - data we get from react forms
 * @param {any} cardData - helper stuff for processing the form data
 * @param {any} client - data holder
 * @param {number} userIndex - user index
 * @param {any} onClose
 * @param {any} enqueueSnackbar - trigger the notifications
 */
export async function onClientIntakeUpdate(
  preferred_province,
  value,
  cardData,
  client,
  userIndex,
  setIsLoading,
  onDataUpdated,
  onClose,
  enqueueSnackbar,
) {
  // Notification bar hide
  const autoHideDuration = DEFAULT_AUTO_HIDE_DURATION;
  const onUpdateSuccess = () => {
    onDataUpdated(() => {
      // It's necessary not only to update the server data but also wait when the
      // updated data is retrieved back
      enqueueSnackbar("Info Updated", {
        variant: "success",
        autoHideDuration,
      });
      setIsLoading(false);
      onClose();
    });
  };
  const onUpdateError = (err) => {
    enqueueSnackbar(err, {
      variant: "error",
      autoHideDuration: configuration.autoHideErrorDuration,
    });
    setIsLoading(false);
  };

  if ("onCustomUpdate" in cardData) {
    // there is a custom update function
    if (typeof cardData.onCustomUpdate === "function") {
      setIsLoading(true);
      cardData.onCustomUpdate(value, onUpdateSuccess, onUpdateError);
      return;
    }
  }

  if (!("payload" in cardData) || typeof cardData.payload !== "function") {
    enqueueSnackbar("No payload struct defined. The data won't be updated", {
      variant: "error",
      autoHideDuration: configuration.autoHideErrorDuration,
    });
    return;
  }

  if (("validate" in cardData) && typeof cardData.validate == "function") {
    const {isValid, error} = cardData.validate(value);
    if (!isValid) {
      return enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: configuration.autoHideErrorDuration,
      });
    }
  }

  // Get the API operation.
  let opType = ApiOperation.UPDATE;
  if (cardData.info && cardData.info === "personal") {
    opType = ApiOperation.UPDATE_PERSONAL_INFO;
  }
  // User id is only used for updating the personal data.
  const userId = extractUserId(client, userIndex);
  // Extract deal id
  const dealId = client.deal_id;
  const url = getApiUrl(opType, dealId, userId);
  // This should process the generic value and give the API-frienly struct

  let payload = cardData.payload(value);
  if (payload.closing_date) {
    payload = {
      ...payload,
      closing_date: moment(payload.closing_date).format("YYYY-MM-DD hh:mm:ss"),
    };
  }
  payload = {...payload, preferred_province};
  // make an axios request
  setIsLoading(true);
  axios
    .post(url, payload)
    .then((result) => {
      onUpdateSuccess();
    })
    .catch((error) => {
      console.error({err: error.response.data.error});
      onUpdateError(error.response.data.error);
    });
}
