import {Box, styled} from "@mui/material";
import SortChevronDefault from "components/Svg/SortChevronDefault";
const DealDetailsActivityLogHeader = () => {
  return (
    <DealDetailsContainer>
      <ProffGroupHeader>
        <SelectedSortValue>
          Document
          <ChevronBox>
            <ChevronBoxDefault>
              <SortChevronDefault />
            </ChevronBoxDefault>
          </ChevronBox>
        </SelectedSortValue>
        <SelectedSortedValue sx={{marginLeft: "13px"}}>
          <span style={{cursor: "pointer"}}>Status</span>
          <ChevronBox>
            <ChevronBoxDefault>
              <SortChevronDefault />
            </ChevronBoxDefault>
          </ChevronBox>
        </SelectedSortedValue>
        <SelectedCompany>
          <span style={{cursor: "pointer"}}>Date</span>

          <ChevronBox>
            <ChevronBoxDefault>
              <SortChevronDefault />
            </ChevronBoxDefault>
          </ChevronBox>
        </SelectedCompany>
        <SelectedDateCreated>
          Time
          <ChevronBox>
            <ChevronBoxDefault>
              <SortChevronDefault />
            </ChevronBoxDefault>
          </ChevronBox>
        </SelectedDateCreated>
        <SelectedLastDeal>
          User
          <ChevronBox>
            <ChevronBoxDefault>
              <SortChevronDefault />
            </ChevronBoxDefault>
          </ChevronBox>
        </SelectedLastDeal>
      </ProffGroupHeader>
    </DealDetailsContainer>
  );
};
const ProffGroupHeader = styled(Box)({
  display: "flex",
  padding: " 1.5rem 2rem",
  marginBottom: "0.5rem",
  marginRight: "1.5rem",
});
const DealDetailsContainer = styled(Box)(({theme}) => ({
  height: "content-max",
  width: "100%",
  padding: "0px 22px 0px 0px",
  marginTop: "26px",
  background: "#F7FAFE",
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));
const SelectedSortValue = styled(Box)({
  position: "relative",
  display: "flex",
  flex: 1,
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "17px",
  alignItems: "center",
  color: "#828282",
  lineBreak: "anywhere",
  marginRight: " 1rem",
});
const ChevronBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  marginLeft: "10px",
  width: "14px",
});
const ChevronBoxDefault = styled(Box)({
  cursor: "pointer",
  marginTop: "-3px",
  marginLeft: " -4px ",
});
const SelectedSortedValue = styled(Box)({
  position: "relative",
  display: "flex",
  flex: 1.5,
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "17px",
  alignItems: "center",
  color: "#828282",
  lineBreak: "anywhere",
  marginRight: " 1rem",
});
const SelectedLastDeal = styled(Box)({
  position: "relative",
  display: "flex",
  flex: 1,
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "17px",
  alignItems: "center",
  color: "#828282",
  lineBreak: "anywhere",
  marginRight: " 1rem",
});
const SelectedCompany = styled(Box)({
  position: "relative",
  display: "flex",
  flex: 1,
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "17px",
  alignItems: "center",
  color: "#828282",
  lineBreak: "anywhere",
  marginRight: " 1rem",
});
const SelectedDateCreated = styled(Box)({
  position: "relative",
  display: "flex",
  flex: 1,
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "17px",
  alignItems: "center",
  color: " #828282",
  lineBreak: "anywhere",
  marginRight: " 1rem",
});
export default DealDetailsActivityLogHeader;
