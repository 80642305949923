import {SVGProps} from "react";
import constants from "styles/constants";

const SortIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={16}
      height={17}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m12 7.5-4 4-4-4"
        stroke={constants.colors.deededGray}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SortIcon;
