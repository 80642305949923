import {FC} from "react";
import Dialog from "@mui/material/Dialog";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import {styled} from "@mui/material/styles";
import {useFormik} from "formik";
import * as yup from "yup";
import {useSnackbar} from "notistack";
import {AxiosError} from "axios";
import {updateUserPointTransactions} from "api";
import configuration from "utils/configuration";
import {PointTransaction, PointTransactionType} from "types/pointTransaction";
import {H4} from "../../Common/Typography";
import BasicButton from "../../Common/Button/BasicButton";
import {CloseIcon} from "../../Common/Icons/Iconography";
import SelectField from "../../CommonDashboard/SelectField";
import Colors from "../../CommonDashboard/Styling/Colors";
import TextField from "../../CommonDashboard/TextField";

const addOptions = ["Bonus points", "Points adjustment", "Correction"];
const useOptions = ["Redemption", "Correction"];

const schema = yup.object().shape({
  label: yup.string().required("Reason is required"),
  points: yup
    .number()
    .moreThan(0)
    .required("Quantity should be greater than 0."),
});

interface ChangePointsModalProps {
  show: boolean;
  type: PointTransactionType;
  userId: number;
  onClose: () => void;
  onSave: (total: number) => void;
}

const UpdatePointsModal: FC<ChangePointsModalProps> = ({
  show,
  type,
  userId,
  onClose,
  onSave,
}) => {
  const {enqueueSnackbar} = useSnackbar();

  const onSubmit = async (
    values: Pick<PointTransaction, "points" | "label">,
  ) => {
    try {
      const resp = await updateUserPointTransactions(userId, {...values, type});
      onSave(resp.data.total);
      handleClose();
    } catch (error) {
      let message = "Failed to save points.";
      if (error instanceof AxiosError && error.response?.data?.error) {
        message = error.response?.data?.error;
      }

      console.error(error);
      enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: configuration.autoHideErrorDuration,
      });
    }
  };

  const {handleSubmit, isSubmitting, errors, resetForm, getFieldProps} =
    useFormik<Pick<PointTransaction, "points" | "label">>({
      validationSchema: schema,
      initialValues: {points: 0, label: ""},
      onSubmit,
      validateOnChange: false,
      validateOnBlur: false,
    });

  const handleClose = () => {
    onClose();
    resetForm();
  };

  return (
    <StyledDialog open={show} onClose={handleClose}>
      <ModalBody>
        <Stack direction="row" justifyContent="space-between">
          <H4 textTransform="capitalize">{type} Points</H4>
          <Box sx={{cursor: "pointer"}} onClick={handleClose}>
            <CloseIcon color={Colors.BLACK} />
          </Box>
        </Stack>

        <SelectField
          {...getFieldProps("label")}
          outlined
          error={errors.label}
          disabled={isSubmitting}
          placeholder="Select"
          label="Reason"
          options={type == "use" ? useOptions : addOptions}
          data-testid="points-modal-label-select"
        />
        <TextField
          {...getFieldProps("points")}
          outlined
          error={errors.points}
          disabled={isSubmitting}
          label="Quantity"
          type="number"
          data-testid="points-modal-points-input"
        />

        <Stack gap="2rem" direction="row" justifyContent="end">
          <BasicButton
            onClick={handleClose}
            typeOf="secondary"
            label={{text: "Back", inputId: "cancel-points-btn"}}
          />
          <BasicButton
            testId="save-points-btn"
            onClick={handleSubmit}
            typeOf="primary"
            label={{text: "Save", inputId: "save-points-btn"}}
          />
        </Stack>
      </ModalBody>
    </StyledDialog>
  );
};

const ModalBody = styled(Stack)({
  width: "100%",
  background: Colors.WHITE,
  gap: "2rem",
});

const StyledDialog = styled(Dialog)({
  ".MuiPaper-root": {
    borderRadius: ".6rem",
    padding: "2rem",
    maxWidth: "42.4rem",
    width: "100%",
    margin: "1.2rem",
  },
});
export default UpdatePointsModal;
