import React, {CSSProperties, FC} from "react";
import {useHistory} from "react-router-dom";
import {styled, useTheme} from "@mui/material";
import {Theme} from "@mui/material/styles";
import {DefaultTheme} from "@mui/styles";
import ArrowBack from "../../Svg/ArrowBack";
import constants from "../../../styles/constants";

interface BackButtonProps {
  style?: CSSProperties;
}

const BackButton: FC<BackButtonProps> = ({style}) => {
  const {goBack} = useHistory();
  const theme = useTheme();

  return (
    <StyledButton
      style={style}
      theme={theme}
      onClick={goBack}
      data-testid="back-button"
    >
      <ArrowBack />
      <span>BACK</span>
    </StyledButton>
  );
};

const StyledButton = styled("button")<{theme: Theme & DefaultTheme}>(
  ({theme}) => ({
    cursor: "pointer",
    fontFamily: "Montserrat",
    display: "flex",
    alignItems: "center",
    marginBottom: "2rem",
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "24px",
    color: constants.colors.black,
    background: "transparent",
    border: "none",
    svg: {
      marginRight: "1rem",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  }),
);

export default BackButton;
