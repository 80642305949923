import { FC, FunctionComponent } from "react";
import { Link, useHistory } from "react-router-dom";
import { IconProps } from "../../CommonDashboard/Icons";
import Colors from "../../CommonDashboard/Styling/Colors";
import NavLink from "../../CommonDashboard/NavLink";

interface SidebarButtonProps {
    label: string;
    to: string;
    Icon: FunctionComponent<IconProps>;
    visible?: boolean;
    isExternalLink?: boolean;
}

const SidebarButton: FC<SidebarButtonProps> = ({ label, to, Icon, visible = true, isExternalLink = false }) => {
    const history = useHistory();

    if (!visible) {
        return null;
    }

    let extraProps = {
        component: Link,
        to,
    } as {};

    if (isExternalLink) {
        extraProps = {
            component: "a",
            target: "_blank",
            rel: "noreferrer",
            href: to,
        };
    }

    return (
        <NavLink
            {...extraProps}
            active={history.location.pathname === to}
            startIcon={<Icon color={Colors.WHITE} />}
            label={{ text: label }}
        />
    );
};

export default SidebarButton;
