import React, {FC} from "react";
import {Box, styled, Typography} from "@mui/material";
import {RootStore} from "types/flux";
import useTypedSelector from "utils/hooks/useTypedSelector";
import {useAppDispatch} from "redux/hooks";
import {
  setLawClerkCountTableViewAC,
  setLawyerCountTableViewAC,
  setMobileSignerCountTableViewAC,
} from "redux/reducers/calendarReducer";
import DeededSwitcher from "v2/components/DeededSwitcher";
import constants from "styles/constants";
import {generateClosingsSumColor} from "../utils/generateClosingsSumColor";

interface CalendarEventsCountTableProps {
  users: {
    name: string;
    purchase: number;
    sale: number;
    mortgage: number;
    lender: number;
    monday: number;
    tuesday: number;
    wednesday: number;
    thursday: number;
    friday: number;
    sum: number;
    sumByWeekDays: number;
    capacity?: {high: number; medium: number} | null;
  }[];
  view: "day" | "week" | "month";
  forRole: string;
  title: string;
  tableViewSelector: (state: RootStore) => "dealType" | "week";
  disablePaddings?: boolean;
}
const CalendarEventsCountTable: FC<CalendarEventsCountTableProps> = ({
  disablePaddings,
  users,
  view,
  forRole,
  title,
  tableViewSelector,
}) => {
  const tableView = useTypedSelector(tableViewSelector);
  const dispatch = useAppDispatch();
  const eventsCountTableData = users;
  const dataAttributeSwitcher = forRole.split(" ").join("-").toLowerCase();
  const updateSwitcherState = (value: "dealType" | "week") => {
    switch (forRole) {
      case "Mobile Signer": {
        return dispatch(setMobileSignerCountTableViewAC(value));
      }
      case "Lawyer": {
        return dispatch(setLawyerCountTableViewAC(value));
      }
      case "Law Clerk": {
        return dispatch(setLawClerkCountTableViewAC(value));
      }
      default: {
        return console.error("undefined role");
      }
    }
  };
  const handleChangeTableView = (e: {target: {checked: boolean}}) => {
    const checked = e.target.checked;
    if (checked) {
      updateSwitcherState("dealType");
    } else {
      updateSwitcherState("week");
    }
  };

  return (
    <>
      <EventsCountTableBox data-testid="calendar-events-counter-table"
        sx={{
          padding: disablePaddings ? "0px" : "16px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <EventsCountTableTitle
            sx={{
              width: "280px",
            }}
          >
            {title}
          </EventsCountTableTitle>
          {forRole !== "Mobile Signer" && view === "week" && (
            <DeededSwitcher
              dataSeleniumId={`${dataAttributeSwitcher}-events-count-table`}
              sx={{}}
              activeSwitcher={tableView === "dealType"}
              handleChangeSwitcher={handleChangeTableView as () => void}
            />
          )}
        </Box>
        {tableView === "dealType" && (
          <>
            <DealTypeOrWeekTableViewBox
              disablePaddings={disablePaddings}
              eventsCountTableDataLength={eventsCountTableData.length}
            >
              <HeadTypography>Name</HeadTypography>
              <HeadTypography>P</HeadTypography>
              <HeadTypography>M</HeadTypography>
              <HeadTypography>S</HeadTypography>
              <HeadTypography>L</HeadTypography>
              <HeadTypography
                sx={{
                  color: constants.colors.red,
                  fontWeight: "700",
                  position: "relative",
                }}
                align={"center"}
              >
                Sum
              </HeadTypography>
            </DealTypeOrWeekTableViewBox>
            <EventsCountDealTypeOrWeekMapBox
              eventsCountTableDataLength={eventsCountTableData.length}
            >
              {eventsCountTableData.map((signer, index) => {
                const sumColor = generateClosingsSumColor(
                  signer.sum,
                  signer.capacity,
                );
                return (
                  <EventsCountDealTypeOrWeekBox
                    disablePaddings={disablePaddings}
                    key={index}
                  >
                    <BodyTypography>{signer.name}</BodyTypography>
                    <BodyTypography>{signer.purchase}</BodyTypography>
                    <BodyTypography>{signer.mortgage}</BodyTypography>
                    <BodyTypography>{signer.sale}</BodyTypography>
                    <BodyTypography>{signer.lender}</BodyTypography>
                    <BodyTypography
                      sx={{
                        color: sumColor,
                        fontWeight: 700,
                      }}
                      align={"center"}
                    >
                      {signer.sum}
                    </BodyTypography>
                  </EventsCountDealTypeOrWeekBox>
                );
              })}
            </EventsCountDealTypeOrWeekMapBox>
          </>
        )}
        {tableView === "week" && (
          <>
            <DealTypeOrWeekTableViewBox
              disablePaddings={disablePaddings}
              eventsCountTableDataLength={eventsCountTableData.length}
              sx={{
                gridTemplateColumns: "3fr 1fr 1fr 1fr 1fr 1fr 1fr",
              }}
            >
              <HeadTypography>Name</HeadTypography>
              <HeadTypography>Mo</HeadTypography>
              <HeadTypography>Tu</HeadTypography>
              <HeadTypography>We</HeadTypography>
              <HeadTypography>Th</HeadTypography>
              <HeadTypography>Fr</HeadTypography>
              <HeadTypography
                sx={{
                  color: constants.colors.red,
                  fontWeight: "700",
                  position: "relative",
                }}
                align={"center"}
              >
                Sum
              </HeadTypography>
            </DealTypeOrWeekTableViewBox>
            <EventsCountDealTypeOrWeekMapBox
              eventsCountTableDataLength={eventsCountTableData.length}
            >
              {eventsCountTableData.map((signer, index) => {
                const sumColor = generateClosingsSumColor(
                  signer.sumByWeekDays,
                  signer.capacity,
                );
                return (
                  <EventsCountDealTypeOrWeekBox
                    disablePaddings={disablePaddings}
                    key={index}
                    sx={{
                      gridTemplateColumns: "3fr 1fr 1fr 1fr 1fr 1fr 1fr",
                    }}
                  >
                    <BodyTypography>{signer.name}</BodyTypography>
                    <BodyTypography>{signer.monday}</BodyTypography>
                    <BodyTypography>{signer.tuesday}</BodyTypography>
                    <BodyTypography>{signer.wednesday}</BodyTypography>
                    <BodyTypography>{signer.thursday}</BodyTypography>
                    <BodyTypography>{signer.friday}</BodyTypography>
                    <BodyTypography
                      sx={{
                        color: sumColor,
                        fontWeight: 700,
                      }}
                      align={"center"}
                    >
                      {signer.sumByWeekDays}
                    </BodyTypography>
                  </EventsCountDealTypeOrWeekBox>
                );
              })}
            </EventsCountDealTypeOrWeekMapBox>
          </>
        )}
      </EventsCountTableBox>
    </>
  );
};
const EventsCountDealTypeOrWeekBox = styled(Box, { shouldForwardProp: (propName) => propName !== "disablePaddings" })<{
    disablePaddings?: boolean;
}>(({ disablePaddings }) => ({
    padding: disablePaddings ? "0px" : "0px 12px 0px 12px",
    display: "grid",
    width: "100%",
    gridTemplateColumns: "3fr 1fr 1fr 1fr 1fr 1fr",
    cursor: "pointer",
    "&:hover": {
        background: constants.colors.redVeryPale,
        transition: "all 300ms",
    },
    transition: "all 300ms",
}));
const EventsCountDealTypeOrWeekMapBox = styled(Box, {
    shouldForwardProp: (propName) => propName !== "eventsCountTableDataLength",
})<{
  eventsCountTableDataLength: number;
}>(({eventsCountTableDataLength}) => ({
  overflowY: eventsCountTableDataLength >= 7 ? "scroll" : "initial",
  maxHeight: "238px",
  "&::-webkit-scrollbar": {
    backgroundColor: constants.colors.configNotificationsTableBorder,
    width: "3px",
    borderRadius: "2px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: constants.colors.red,
    borderRadius: "2px",
  },
}));
const DealTypeOrWeekTableViewBox = styled(Box, {
    shouldForwardProp: (propName) => !["disablePaddings", "eventsCountTableDataLength"].includes(propName.toString()),
})<{
    eventsCountTableDataLength: number;
    disablePaddings?: boolean;
}>(({ eventsCountTableDataLength, disablePaddings }) => ({
    padding: disablePaddings ? "0px" : eventsCountTableDataLength >= 7 ? "0px 15px 0px 12px" : "0px 12px 0px 12px",
    display: "grid",
    borderBottom: `1px solid ${constants.colors.configNotificationsTableBorder}`,
    width: "100%",
    gridTemplateColumns: "3fr 1fr 1fr 1fr 1fr 1fr",
}));
const BodyTypography = styled(Typography)({
  padding: "10px 0px 10px 0px",
  fontFamily: "'Montserrat'",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "12px",
  lineHeight: "15px",
  color: constants.colors.sortingColor,
  maxWidth: "110px",
  overflowX: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
});
const HeadTypography = styled(Typography)({
  padding: "16px 0px 6px 0px",
  fontFamily: "'Montserrat'",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "12px",
  lineHeight: "15px",
  color: constants.colors.sortingColor,
});

const EventsCountTableTitle = styled(Typography)({
  fontFamily: "'Montserrat'",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "18px",
  lineHeight: "22px",
  display: "flex",
  alignItems: "center",
  color: "#333333",
});
const EventsCountTableBox = styled(Box)(() => ({
  width: "100%",
  height: "355px",
  backgroundColor: constants.colors.white,
}));
export default CalendarEventsCountTable;
