import { FC, ReactNode } from "react";
import Box from "@mui/material/Box";
import { SxProps, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

interface SideImageProps {
  MainImage: ReactNode;
  sx?: SxProps
}

const SideImage: FC<SideImageProps> = ({ MainImage, sx}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("mobile"));

  if (isMobile) {
    return null;
  }

  return (
    <Box maxWidth="40rem" display="flex" flex={1} alignItems="center" justifyContent="center" sx={sx}>
      {MainImage}
    </Box>
  );
};

export default SideImage;
