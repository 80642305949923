import React, { FC } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import useUser from "utils/hooks/useUser";
import configuration from "utils/configuration";
import Colors from "../../CommonDashboard/Styling/Colors";
import { BodySmall, H2, SubtitleSmall } from "../../CommonDashboard/Typography";
import { AwardIcon } from "../../CommonDashboard/Icons";

const HomePointsBanner: FC = () => {
    const { user } = useUser();

    if (!configuration.pointsEnabled || !user.points_feature) {
        return null;
    }

    return (
        <Wrapper>
            <Stack direction="row" alignItems="center" gap="1rem" justifyContent="space-evenly">
                <Box p="1.2rem">
                    <AwardIcon color={Colors.NEUTRAL_700} />
                </Box>
                {(user.points ?? 0) > 0 ? (
                    <Stack>
                        <BodySmall color={Colors.NEUTRAL_600}>You have earned</BodySmall>
                        <H2 color={Colors.SUCCESS_700}>{user.points} points</H2>
                    </Stack>
                ) : (
                    <SubtitleSmall color={Colors.NEUTRAL_600}>You haven’t earned any points yet</SubtitleSmall>
                )}
            </Stack>
            <BodySmall color={Colors.NEUTRAL_600}>Earn 5 points with every closed transaction.</BodySmall>
        </Wrapper>
    );
};

const Wrapper = styled(Stack)(({ theme }) => ({
    width: "25.4rem",
    alignItems: "center",
    textAlign: "center",
    gap: "1.6rem",
    border: `1px solid ${Colors.NEUTRAL_300}`,
    padding: "2.4rem",
    borderRadius: "2.4rem",
    [theme.breakpoints.down("mobile")]: {
        width: "100%",
        flex: 1,
    },
}));

export default HomePointsBanner;
