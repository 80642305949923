import React from "react";

const SelectedFilesMoveStorageFolder = () => {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.562 3.18726H9.28418L6.93701 0.840093C6.80966 0.711988 6.65816 0.610421 6.49129 0.541274C6.32441 0.472127 6.14548 0.436773 5.96484 0.437261H1.43701C1.07245 0.437621 0.722922 0.582602 0.465137 0.840386C0.207353 1.09817 0.0623714 1.4477 0.0620117 1.81226V14.2403C0.0624936 14.5909 0.202018 14.9269 0.449974 15.1748C0.697931 15.4226 1.03407 15.562 1.38464 15.5623H16.6385C16.9828 15.5618 17.3128 15.4248 17.5562 15.1814C17.7996 14.938 17.9365 14.608 17.937 14.2638V4.56226C17.9367 4.1977 17.7917 3.84817 17.5339 3.59039C17.2761 3.3326 16.9266 3.18762 16.562 3.18726ZM1.43701 1.81226H5.96484L7.33984 3.18726H1.43701V1.81226Z"
        fill="#EB5757"
      />
    </svg>
  );
};

export default SelectedFilesMoveStorageFolder;
