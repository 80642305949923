import {FC} from "react";
import {useTheme} from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import {Theme} from "@mui/material/styles/createTheme";
import DeededPopup from "v2/components/DeededPopup";
import DeededSearch from "v2/components/DeededSearch";
import constants from "styles/constants";
import {H4, Large, Small} from "components/Common/Typography";
import "./AdditionalDocuments.scss";
import useAdditionalDocuments from "./useAdditionalDocuments";
import CustomFileForm, {CustomDocForm} from "./CustomFileForm";
import {SubtitleSmall} from "../../CommonDashboard/Typography";
import Colors from "../../CommonDashboard/Styling/Colors";

export interface AdditionalDocument {
  description?: string;
  uploader?: string;
  document_type: string;
}

interface AdditionalDocumentsProps {
  toggleAdditionalDocs: () => void;
  handleDocumentsRequest: () => void;
  dealID: string;
  dealType: string;
  open: boolean;
  documentTypes: AdditionalDocument[];
}

const AdditionalDocuments: FC<AdditionalDocumentsProps> = ({
  toggleAdditionalDocs,
  handleDocumentsRequest,
  dealID,
  dealType,
  open,
  documentTypes,
}) => {
  const theme = useTheme();
  const {
    filteredDocument,
    search,
    setSearch,
    handleSubmit,
    handleAdditionalFiles,
    showCustomFile,
    additionalFiles,
  } = useAdditionalDocuments(dealID, dealType, documentTypes);

  const onSubmit = (values: CustomDocForm) => {
    handleSubmit(values,() => {
      toggleAdditionalDocs();
      handleDocumentsRequest();
    });
  };

  return (
    <DeededPopup
      open={!open}
      setOpen={toggleAdditionalDocs}
      title="Additional Info Required from Client"
      TitleCmp={H4}
      sxInternalBox={popupStyle(theme) as never}
    >
      <div className="additional__documents__wrapper">
        <Stack py="2rem">
          <DeededSearch
            placeholder={"Search..."}
            onChange={setSearch}
            value={search}
          />
        </Stack>
        <SubtitleSmall color={Colors.BLACK}>{dealType}</SubtitleSmall>
        <div className="additional__list">
          {filteredDocument && (
            <>
              {filteredDocument.map((el) => (
                <label
                  className="additional__label"
                  key={el.document_type}
                  htmlFor={"add_list" + el.document_type}
                >
                  <input
                    id={"add_list" + el.document_type}
                    type="checkbox"
                    onChange={() => handleAdditionalFiles(el.document_type)}
                  />
                  <Large component="span">{el.document_type}</Large>
                </label>
              ))}
            </>
          )}
          {!(filteredDocument || []).length && <Small>No Results</Small>}
        </div>

        <CustomFileForm
          onSubmit={onSubmit}
          onCancel={toggleAdditionalDocs}
          showCustomFile={showCustomFile}
          additionalFiles={additionalFiles}
        />
      </div>
    </DeededPopup>
  );
};

const popupStyle = (theme: Theme) => ({
  padding: "2rem",
  width: "600px",
  height: "75vh",
  display: "flex",
  flexFlow: "column",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    padding: "1.6rem",
  },
  overflowY: "hidden",
  overflowX: "hidden",
  background: constants.colors.disabled2,
  "&::-webkit-scrollbar": {
    width: "5px",
    scrollbarWidth: "thin",
  },
});

export default AdditionalDocuments;
