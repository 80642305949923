import Dialog from "@mui/material/Dialog";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import { Body, H4, H3, H2 } from "components/Common/Typography";
import Colors from "components/Common/componentStyling/Colors";
import NewColors from "components/CommonDashboard/Styling/Colors"
import { MailIcon, PhoneIcon, PlaceIcon } from "components/CommonDashboard/Icons";
import BasicButton from "components/Common/Button/BasicButton";
import { Deal } from "types/deal";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";

const steps = [
    {
        icon: <MailIcon />,
        text: "You’ll Receive a confirmation email within two hours (during business hours) with your client’s dedicated lawyer and support team details."
    },

    {
        icon: <PhoneIcon />,
        text: "Our team will contact your client, introduce themselves, explain the process, and start necessary steps."
    },

    {
        icon: <PlaceIcon />,
        text: "You’ll be able to track progress and key milestones on your dashboard throughout the transaction."
    }
];
interface ThankYouModalInterface {
    openModal: boolean,
    deal: Deal | null
}

const ThankYouModal = ({ openModal, deal }: ThankYouModalInterface) => {
    const history = useHistory();
    const [newDeal, setNewDeal] = useState<Deal | null>(null);

    useEffect(() => {
        if (deal) {
            setNewDeal(deal);
        }
    }, [deal])
    return <PaperDialog open={openModal}>
        <StyledBody>
            <H4>Thank you for submitting your transaction.</H4>
            <Body>Here's what happens next:</Body>
            {steps.map((step, index) =>
                <Step key={index}>
                    <Stack sx={{ flexDirection: 'row', alignItems: 'center', gap: '.8rem' }}>
                        <Numeration>{index + 1}.</Numeration>
                        <Stack padding={'2.8rem'} style={{ backgroundColor: Colors.RED_20, borderRadius: '50%' }}>
                            {step.icon}
                        </Stack>
                    </Stack>
                    <Body>{step.text}</Body>
                </Step>
            )}
        </StyledBody>
        <ButtonCotainer>
            <BasicButton
                size="medium"
                disabled={!newDeal}
                label={{ text: "Got it!", inputId: `thank-you-deal-proffesional` }}
                typeOf="primary"
                testId="professional-thanks-modal-button"
                onClick={() => { history.replace(`deals/${newDeal?.id}`) }}
            />
        </ButtonCotainer>
    </PaperDialog>

};

const ButtonCotainer = styled(Stack)(({ theme }) => ({
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: '0rem 2rem 2rem 2rem',
    [theme.breakpoints.down('mobile')]: {
        justifyContent: 'stretch',
        '> div': {
            width: '100%'
        },
    },
}));

const Numeration = styled(H2)(({ theme }) => ({
    width: '4rem',
    color: NewColors.PRIMARY,
    textAlign: 'right',
    [theme.breakpoints.down('mobile')]: {
        width: 'inherit',
    },
}));

const StyledBody = styled(Stack)(({ theme }) => ({
    flexDirection: 'column',
    padding: '2rem',
    gap: '2.4rem',
    [theme.breakpoints.down('mobile')]: {
        height: '100%'
    },
}));

const Step = styled(Stack)(({ theme }) => ({
    flexDirection: 'row',
    alignItems: 'center',
    gap: '2.4rem',
    [theme.breakpoints.down('mobile')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
    }
}));

const PaperDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        boxShadow: '0rem 0.1rem 0.2rem rgba(0, 0, 0, 0.15), 0rem 0.5rem 2rem rgba(0, 0, 0, 0.25)',
        borderRadius: "0.6rem",
        width: "100%",
        backgroundColor: Colors.WHITE,
        margin: 0
    },
    margin: '0 1.6rem'
}));

export default ThankYouModal;