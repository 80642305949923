import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { shouldBeAskedTOS } from "utils/permissionRoles";
import useUser from "./useUser";

const useCheckTermsAndConditions = (isRefreshCalled: boolean) => {
    const { user: currentUser } = useUser();
    const history = useHistory();
    useEffect(() => {
        if (
            isRefreshCalled &&
            currentUser &&
            Object.values(currentUser).length > 0 &&
            shouldBeAskedTOS(currentUser.role || "")
            && !currentUser.tos_signed_at
        ) {
            history.push("/accept_terms_of_use");
        }

    }, [isRefreshCalled]);
};

export default useCheckTermsAndConditions;
