import { FC } from "react";

const GiftsImage: FC = () => (
    <svg width="112" height="70" viewBox="0 0 112 70" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_112_7415)">
            <path d="M0 69.769C0 69.8768 0.0865772 69.9634 0.194389 69.9634H111.806C111.913 69.9634 112 69.8768 112 69.769C112 69.6612 111.913 69.5746 111.806 69.5746H0.194389C0.0865772 69.5746 0 69.6612 0 69.769Z" fill="#3F3D56" />
            <path d="M46.3072 69.9143H11.4202C10.027 69.9143 8.89355 68.7808 8.89355 67.3875V2.52676C8.89355 1.13349 10.027 0 11.4202 0H46.3072C47.7004 0 48.8338 1.13349 48.8338 2.52676V67.3875C48.8338 68.7808 47.7004 69.9143 46.3072 69.9143ZM11.4202 0.326702C10.2072 0.326702 9.22024 1.31367 9.22024 2.52676V67.3875C9.22024 68.6007 10.2072 69.5876 11.4202 69.5876H46.3072C47.5202 69.5876 48.5071 68.6007 48.5071 67.3875V2.52676C48.5071 1.31367 47.5202 0.326702 46.3072 0.326702H11.4202Z" fill="#3F3D56" />
            <path d="M108.874 32.9817C108.736 32.7491 108.14 32.9981 108.03 32.7871C107.921 32.5768 108.475 32.2564 108.792 31.5244C108.849 31.3924 109.209 30.5606 108.942 30.3039C108.437 29.8174 106.126 31.8251 105.318 31.1681C105.14 31.0239 105.016 30.736 104.479 30.2687C104.265 30.0828 104.136 29.9988 104.011 30.0301C103.834 30.0744 103.82 30.3119 103.629 30.7789C103.344 31.4792 103.175 31.4369 103.009 31.9957C102.885 32.4105 102.914 32.6516 102.768 32.6986C102.563 32.7651 102.374 32.3251 102.113 32.3645C101.847 32.4047 101.687 32.917 101.636 33.3005C101.541 34.0202 101.805 34.4952 101.952 34.9946C102.111 35.5373 102.205 36.3626 101.838 37.531L98.1987 47.7839C99.0021 45.6801 101.328 39.9128 102.306 37.7696C102.588 37.151 102.899 36.5234 103.539 36.2758C104.155 36.0373 105.001 36.1797 106.079 35.9199C106.205 35.8895 106.554 35.8006 106.598 35.5951C106.635 35.4251 106.429 35.3302 106.461 35.1711C106.503 34.9576 106.907 34.9549 107.404 34.7086C107.754 34.5349 107.973 34.3284 108.16 34.1507C108.217 34.0972 109.058 33.2912 108.874 32.9817Z" fill="#F2F2F2" />
            <path d="M94.5581 38.3792C94.4042 38.3798 94.3543 38.7445 94.219 38.7374C94.0843 38.7304 94.087 38.3659 93.8191 37.9992C93.7708 37.9331 93.4664 37.5164 93.2636 37.5732C92.8791 37.6807 93.1977 39.3942 92.6419 39.6016C92.5199 39.6471 92.3428 39.6249 91.9585 39.7538C91.8056 39.805 91.7271 39.8442 91.7063 39.9146C91.6769 40.0145 91.7894 40.0899 91.9637 40.318C92.2251 40.6602 92.1556 40.7308 92.3819 40.9736C92.5498 41.1539 92.6763 41.2096 92.6575 41.2945C92.6308 41.4146 92.3603 41.3805 92.3045 41.5197C92.2475 41.6617 92.4526 41.8884 92.6261 42.024C92.9519 42.2784 93.2612 42.2857 93.5486 42.3579C93.8609 42.4363 94.2932 42.6281 94.7606 43.1457L98.7426 47.89C97.9419 46.8887 95.7825 44.0829 95.0127 42.9848C94.7905 42.6678 94.5724 42.3338 94.6354 41.9484C94.6961 41.5773 95.01 41.2032 95.1936 40.599C95.2151 40.5284 95.2723 40.3312 95.1841 40.2503C95.1112 40.1834 95.0053 40.257 94.9363 40.1955C94.8437 40.1131 94.9591 39.914 94.9815 39.5991C94.9974 39.377 94.9589 39.2103 94.9259 39.067C94.916 39.0237 94.763 38.3784 94.5581 38.3792Z" fill="#F2F2F2" />
            <path d="M97.6289 39.0685L97.5361 38.2406L97.5785 38.1779C97.7746 37.8877 97.8745 37.6042 97.8757 37.3352C97.8759 37.2924 97.8739 37.2497 97.8719 37.2061C97.8639 37.0334 97.8539 36.8187 97.9658 36.5685C98.0286 36.429 98.2049 36.106 98.4699 36.1459C98.5413 36.1559 98.5952 36.1887 98.6362 36.2271C98.6422 36.2175 98.6484 36.2079 98.655 36.1975C98.7375 36.0716 98.8029 36.0176 98.8659 35.9656C98.9142 35.9257 98.9642 35.8845 99.0425 35.787C99.0769 35.7442 99.1037 35.7066 99.1263 35.675C99.1948 35.5795 99.2844 35.4656 99.4423 35.4676C99.611 35.4756 99.6993 35.6151 99.7579 35.7074C99.8624 35.8721 99.9098 35.9908 99.9411 36.0696C99.9525 36.0984 99.9655 36.1307 99.9721 36.1407C100.026 36.2207 100.468 36.1459 100.635 36.1187C101.009 36.0564 101.332 36.0024 101.466 36.2383C101.561 36.407 101.49 36.63 101.248 36.9191C101.173 37.009 101.093 37.0774 101.022 37.1309C101.081 37.1661 101.134 37.2201 101.155 37.3048C101.205 37.5055 101.033 37.7066 100.642 37.9033C100.546 37.9524 100.415 38.018 100.232 38.0496C100.146 38.0644 100.068 38.068 100.002 38.07C100.001 38.1083 99.9917 38.1499 99.9683 38.1927C99.8998 38.3186 99.7571 38.3786 99.542 38.3642C99.3054 38.3518 99.1105 38.3066 98.9386 38.267C98.7885 38.2327 98.6592 38.2035 98.5577 38.2103C98.3694 38.2255 98.2243 38.3762 98.054 38.5716L97.6289 39.0685Z" fill="#F2F2F2" />
            <path d="M98.059 34.1393L97.2818 34.439L97.2103 34.4139C96.8799 34.2978 96.5804 34.2731 96.3198 34.3401C96.2784 34.3508 96.2375 34.3636 96.1959 34.3766C96.0309 34.4281 95.8258 34.4922 95.5553 34.4474C95.4045 34.4221 95.0473 34.3335 95.0188 34.067C95.0104 33.9954 95.0284 33.9349 95.0551 33.8856C95.0443 33.8822 95.0334 33.8786 95.0217 33.8749C94.879 33.827 94.8102 33.7774 94.744 33.7297C94.693 33.6931 94.6405 33.6552 94.5263 33.6041C94.4762 33.5817 94.4331 33.5653 94.3968 33.5515C94.287 33.5094 94.1541 33.4517 94.116 33.2985C94.0809 33.1333 94.1935 33.0124 94.2679 32.9323C94.4007 32.7894 94.5035 32.7135 94.5717 32.6632C94.5967 32.6449 94.6247 32.6241 94.6327 32.6152C94.6963 32.5423 94.5119 32.1338 94.4433 31.9796C94.2882 31.6337 94.1539 31.3344 94.3482 31.1456C94.4871 31.0104 94.7209 31.0224 95.0618 31.1831C95.1679 31.2331 95.2544 31.2933 95.3242 31.3486C95.3433 31.2826 95.3821 31.2179 95.4587 31.1757C95.64 31.0761 95.8782 31.1919 96.1675 31.5196C96.2396 31.6009 96.3362 31.7106 96.4131 31.8795C96.4492 31.959 96.4724 32.0331 96.4912 32.0968C96.5286 32.0884 96.5711 32.0866 96.6184 32.0984C96.7576 32.1327 96.8518 32.2556 96.8924 32.4673C96.9405 32.6993 96.9462 32.8993 96.9515 33.0756C96.9564 33.2295 96.9609 33.362 96.9933 33.4585C97.0557 33.6368 97.2383 33.7389 97.4706 33.8541L98.059 34.1393Z" fill="#F2F2F2" />
            <path d="M97.6289 31.7003L97.5361 30.8724L97.5785 30.8096C97.7746 30.5194 97.8745 30.236 97.8757 29.9669C97.8759 29.9241 97.8739 29.8814 97.8719 29.8378C97.8639 29.6651 97.8539 29.4504 97.9658 29.2002C98.0286 29.0607 98.2049 28.7377 98.4699 28.7777C98.5413 28.7876 98.5952 28.8204 98.6362 28.8588C98.6422 28.8492 98.6484 28.8396 98.655 28.8292C98.7375 28.7033 98.8029 28.6493 98.8659 28.5974C98.9142 28.5574 98.9642 28.5162 99.0425 28.4187C99.0769 28.3759 99.1037 28.3383 99.1263 28.3067C99.1948 28.2112 99.2844 28.0973 99.4423 28.0993C99.611 28.1073 99.6993 28.2468 99.7579 28.3391C99.8624 28.5038 99.9098 28.6225 99.9411 28.7013C99.9525 28.7301 99.9655 28.7625 99.9721 28.7725C100.026 28.8524 100.468 28.7776 100.635 28.7505C101.009 28.6881 101.332 28.6341 101.466 28.87C101.561 29.0387 101.49 29.2618 101.248 29.5508C101.173 29.6407 101.093 29.7091 101.022 29.7626C101.081 29.7978 101.134 29.8518 101.155 29.9365C101.205 30.1372 101.033 30.3383 100.642 30.535C100.546 30.5841 100.415 30.6497 100.232 30.6813C100.146 30.6961 100.068 30.6997 100.002 30.7017C100.001 30.7401 99.9917 30.7816 99.9683 30.8244C99.8998 30.9503 99.7571 31.0103 99.542 30.9959C99.3054 30.9835 99.1105 30.9383 98.9386 30.8988C98.7885 30.8644 98.6592 30.8352 98.5577 30.842C98.3694 30.8572 98.2243 31.0079 98.054 31.2034L97.6289 31.7003Z" fill="#F2F2F2" />
            <path d="M98.4761 47.7705L98.3026 47.6617L98.1003 47.6849C98.0971 47.6661 98.0861 47.623 98.0695 47.5554C97.979 47.1852 97.703 46.0587 97.4727 44.2846C97.312 43.0462 97.2211 41.773 97.2023 40.4997C97.1835 39.2245 97.2439 38.2591 97.2923 37.4832C97.3288 36.898 97.3732 36.3407 97.4168 35.7978C97.5327 34.3475 97.642 32.9776 97.5607 31.4609C97.5427 31.1223 97.5049 30.4175 97.0932 29.6676C96.8543 29.2327 96.5254 28.8465 96.1152 28.5203L96.3703 28.1997C96.823 28.5603 97.1869 28.988 97.4522 29.4709C97.9087 30.3024 97.9498 31.0703 97.9696 31.4393C98.0524 32.9828 97.9418 34.3659 97.8247 35.8302C97.7815 36.3711 97.7372 36.9259 97.7008 37.5088C97.6528 38.2775 97.593 39.2345 97.6116 40.4934C97.63 41.7514 97.72 43.009 97.8785 44.2319C98.1059 45.9836 98.3779 47.0933 98.4673 47.4579C98.5148 47.6526 98.5248 47.6933 98.4761 47.7705Z" fill="#F2F2F2" />
            <path d="M95.3646 29.1707C95.3478 29.1707 95.3308 29.1703 95.3136 29.1691C94.965 29.1503 94.6423 28.9416 94.354 28.5491C94.2189 28.3644 94.1498 28.1537 94.0119 27.7336C93.9905 27.6688 93.8865 27.3402 93.8234 26.8881C93.782 26.5927 93.7872 26.4691 93.8458 26.36C93.9107 26.2385 94.0159 26.1537 94.1342 26.0966C94.129 26.0574 94.1332 26.017 94.1502 25.9758C94.2199 25.8051 94.4054 25.8275 94.5057 25.8383C94.5565 25.8447 94.6199 25.8531 94.6882 25.8495C94.7958 25.8443 94.8535 25.8123 94.9411 25.7644C95.0248 25.7184 95.1289 25.6612 95.2834 25.6296C95.588 25.5661 95.8433 25.6524 95.9274 25.6808C96.3701 25.8283 96.5884 26.1725 96.841 26.5711C96.8914 26.651 97.0643 26.942 97.1784 27.341C97.2607 27.6288 97.2491 27.7567 97.2244 27.8519C97.174 28.0474 97.0551 28.1593 96.7531 28.4063C96.4377 28.665 96.2794 28.7945 96.1435 28.8732C95.8271 29.0559 95.6284 29.1707 95.3646 29.1707Z" fill="#F2F2F2" />
            <path d="M48.9313 28.8324H8.79639V39.2575H48.9313V28.8324Z" fill="#F06A6A" />
            <path d="M34.3368 0.16333H23.9121V69.7509H34.3368V0.16333Z" fill="#F06A6A" />
            <path opacity="0.2" d="M34.7222 38.4726C34.796 38.1855 34.84 37.887 34.8507 37.5795L47.8889 25.7049L36.6824 20.2317L31.7434 33.403C31.4058 33.3148 31.0534 33.2631 30.6882 33.2631C30.4943 33.2631 30.305 33.2809 30.1181 33.3065L25.2153 20.2317L14.0088 25.7049L26.5345 37.1128C26.5265 37.2189 26.5184 37.325 26.5184 37.4331C26.5184 37.7927 26.5687 38.1398 26.6543 38.4726C18.9763 40.8723 15.8331 48.1189 15.8331 48.1189L25.4759 55.9377C24.9008 50.1863 28.4129 44.2982 30.3116 41.5842C30.4359 41.5953 30.5611 41.6032 30.6882 41.6032C30.8154 41.6032 30.9406 41.5953 31.0648 41.5842C32.9635 44.2982 36.4757 50.1863 35.9005 55.9377L45.5433 48.1189C45.5433 48.1189 42.4001 40.8723 34.7222 38.4726Z" fill="black" />
            <path d="M36.138 53.1202C36.0255 53.1202 35.9122 53.0958 35.8051 53.0457C35.5344 52.9194 35.3634 52.656 35.3587 52.3584C35.2797 47.3162 32.4047 42.3675 30.698 39.8665C30.584 39.6994 30.3872 39.5997 30.1714 39.5997H30.1704C29.9467 39.5997 29.7498 39.6994 29.6359 39.8665C27.9292 42.3675 25.0543 47.3162 24.9751 52.3584C24.9704 52.6559 24.7994 52.9194 24.5287 53.0457C24.2553 53.1738 23.94 53.1351 23.7059 52.9454L15.691 46.4464C15.4049 46.2143 15.3209 45.8079 15.4914 45.4797C16.3016 43.9194 19.4207 38.6606 25.542 36.4981C25.8152 36.4016 25.981 36.1296 25.9453 35.8366C25.9255 35.6729 25.9154 35.5086 25.9154 35.3481C25.9158 35.1567 25.8427 34.9828 25.7147 34.8664L14.1921 24.372C14.001 24.198 13.9088 23.9413 13.9454 23.6854C13.982 23.4296 14.1425 23.2089 14.3748 23.0955L23.9684 18.4102C24.1652 18.314 24.3948 18.3055 24.5986 18.3863C24.8022 18.4674 24.9631 18.6316 25.0401 18.8367L29.4883 30.6993C29.5801 30.9442 29.8156 31.104 30.0828 31.0973C30.2844 31.0927 30.4888 31.1044 30.6926 31.1308C30.9802 31.1693 31.2516 31.007 31.3523 30.7381L35.8151 18.8367C35.8921 18.6316 36.0529 18.4674 36.2566 18.3863C36.4602 18.3055 36.69 18.3141 36.8867 18.4102L46.4802 23.0955C46.7126 23.2089 46.8732 23.4296 46.9097 23.6856C46.9463 23.9413 46.854 24.198 46.6629 24.372L34.591 35.3668C34.4784 35.4692 34.4084 35.6091 34.3937 35.7605C34.3905 35.7932 34.3871 35.8259 34.3832 35.8585C34.3501 36.1338 34.5212 36.4026 34.79 36.4975C40.9127 38.6595 44.0322 43.9193 44.8426 45.4797C45.013 45.8079 44.929 46.2143 44.6429 46.4464L36.628 52.9454C36.4855 53.0609 36.3129 53.1202 36.138 53.1202Z" fill="#F06A6A" />
            <path d="M103.83 69.9783H70.1713C68.778 69.9783 67.6445 68.8448 67.6445 67.4515V50.3248C67.6445 48.9315 68.778 47.798 70.1713 47.798H103.83C105.224 47.798 106.357 48.9315 106.357 50.3248V67.4515C106.357 68.8448 105.224 69.9783 103.83 69.9783ZM70.1713 48.1247C68.9581 48.1247 67.9712 49.1116 67.9712 50.3248V67.4515C67.9712 68.6647 68.9581 69.6516 70.1713 69.6516H103.83C105.043 69.6516 106.03 68.6647 106.03 67.4515V50.3248C106.03 49.1116 105.043 48.1247 103.83 48.1247H70.1713Z" fill="#3F3D56" />
            <path d="M90.3796 47.8175H84.6289V69.9586H90.3796V47.8175Z" fill="#F06A6A" />
            <path d="M106.194 56.1564H67.8081V61.9073H106.194V56.1564Z" fill="#F06A6A" />
            <path opacity="0.2" d="M85.8782 62.61C86.0366 62.6507 86.2012 62.675 86.3709 62.6809L92.9211 69.8737L95.9402 63.6914L88.6747 60.9667C88.7233 60.7805 88.7519 60.5861 88.7519 60.3846C88.7519 60.2776 88.742 60.1732 88.7279 60.0701L95.9402 57.3654L92.9211 51.1831L86.6283 58.0932C86.5698 58.0887 86.5113 58.0842 86.4516 58.0842C86.2532 58.0842 86.0618 58.112 85.8782 58.1592C84.5545 53.9235 80.5572 52.1895 80.5572 52.1895L76.2441 57.5091C79.4167 57.1919 82.6647 59.1294 84.1618 60.1769C84.1557 60.2454 84.1513 60.3145 84.1513 60.3846C84.1513 60.4547 84.1557 60.5238 84.1618 60.5924C82.6647 61.6398 79.4167 63.5773 76.2441 63.2601L80.5572 68.5797C80.5572 68.5797 84.5545 66.8457 85.8782 62.61Z" fill="black" />
            <path d="M93.1576 68.8846C93.0274 68.8846 92.9022 68.83 92.8131 68.7322L86.7478 62.072C86.6978 62.0171 86.625 61.9824 86.5427 61.9741L86.5166 61.9714C86.3684 61.9549 86.2333 62.035 86.1869 62.1666C84.9897 65.5609 82.0751 67.2884 81.2104 67.737C81.0155 67.8384 80.7735 67.7874 80.6348 67.6159L77.0496 63.1938C76.9359 63.0537 76.9129 62.8648 76.9895 62.7008C77.065 62.5393 77.2221 62.4373 77.3995 62.4346C80.1712 62.3919 82.8946 60.8097 84.2713 59.8704C84.3541 59.8138 84.4036 59.7162 84.4034 59.6093C84.4036 59.4976 84.3542 59.4 84.2713 59.3435C82.8949 58.4043 80.1718 56.8221 77.3994 56.7794C77.222 56.7767 77.065 56.6746 76.9895 56.513C76.9129 56.349 76.9359 56.1601 77.0496 56.02L80.6349 51.5979C80.7738 51.4269 81.0158 51.3759 81.2103 51.4767C82.075 51.9254 84.9889 53.6527 86.1863 57.0459C86.2327 57.1774 86.3653 57.2594 86.4987 57.2433C86.644 57.2249 86.7886 57.2208 86.9285 57.2311L92.8131 50.7692C92.9172 50.6548 93.0709 50.5995 93.2239 50.6216C93.377 50.6436 93.5089 50.7397 93.5767 50.8786L96.1616 56.1716C96.2192 56.2895 96.2243 56.427 96.1758 56.5489C96.1273 56.6706 96.0291 56.7668 95.9063 56.8127L89.3624 59.2668C89.2421 59.312 89.1634 59.4297 89.1667 59.5595L89.1673 59.6069C89.1673 59.7087 89.1602 59.8122 89.1462 59.9146C89.1283 60.0464 89.2104 60.1775 89.3415 60.2268L95.9064 62.6887C96.0291 62.7348 96.1274 62.831 96.1759 62.9529C96.2243 63.0748 96.2191 63.2121 96.1616 63.33L93.5767 68.623C93.5089 68.7619 93.377 68.8579 93.2239 68.8798C93.2018 68.883 93.1796 68.8846 93.1576 68.8846Z" fill="#F06A6A" />
            <path d="M62.6732 28.0456H57.4092V26.3122C57.4092 23.4068 59.7679 21.048 62.6732 21.048L62.6732 28.0456Z" fill="#2F2E41" />
            <path d="M61.5065 24.8098L62.4116 28.8808L57.1079 28.0001L59.2692 24.1499L61.5065 24.8098Z" fill="#FFB6B6" />
            <path opacity="0.1" d="M61.5065 24.8098L62.4116 28.8808L57.1079 28.0001L59.2692 24.1499L61.5065 24.8098Z" fill="black" />
            <path d="M61.5067 33.5063H56.4219V41.0322H61.5067V33.5063Z" fill="#FFB6B6" />
            <path d="M64.8117 47.1852L63.6931 53.1855C63.6931 53.1855 64.4049 57.3553 62.2693 61.6267L60.8456 65.9998L58.71 65.6947C58.71 65.6947 60.032 62.4403 60.032 61.0165C60.032 59.5927 60.3371 54.1008 60.3371 54.1008L60.1846 47.8342L64.8117 47.1852Z" fill="#FFB6B6" />
            <path d="M58.405 65.593C58.405 65.593 60.8457 64.6777 61.0491 65.9998L60.9474 66.61C60.9474 66.61 61.8627 69.3559 60.5407 69.5594C59.2186 69.7627 57.9982 70.0679 57.8965 69.4576C57.7948 68.8474 57.7964 68.0857 58.0499 67.5513C58.3033 67.0168 58.405 65.593 58.405 65.593Z" fill="#2F2E41" />
            <path d="M57.1134 46.2544L56.5192 52.3292C56.5192 52.3292 54.6758 56.1364 55.5393 60.8333L55.6908 65.4299L57.827 65.7308C57.827 65.7308 57.4621 62.237 57.8581 60.8694C58.254 59.5018 59.4883 54.1417 59.4883 54.1417L61.3775 48.1648L57.1134 46.2544Z" fill="#FFB6B6" />
            <path d="M58.1482 65.718C58.1482 65.718 56.0583 64.16 55.4953 65.3733L55.4233 65.9878C55.4233 65.9878 53.8094 69.0246 55.0228 69.5877C56.2361 70.1508 57.2945 70.1294 57.5619 69.5716C57.8292 69.0137 58.0395 68.2816 57.9447 67.6977C57.8499 67.1139 58.1482 65.718 58.1482 65.718Z" fill="#2F2E41" />
            <path d="M63.4897 37.5236L67.8626 46.7783C67.8626 46.7783 59.0659 51.2023 53.8794 51.2023L56.4546 37.5236L63.4897 37.5236Z" fill="#2F2E41" />
            <path d="M59.5744 26.6924L62.1168 27.0992L61.9134 33.5064L64.7609 39.2016L61.0998 41.0322C61.0998 41.0322 58.2523 35.6421 57.7439 32.286C57.2354 28.9298 57.5405 26.489 57.5405 26.489L59.5744 26.6924Z" fill="#E6E6E6" />
            <path d="M57.6421 26.8958L55.0997 27.3026L55.303 33.7097L54.6929 39.6084L56.1166 41.2356C56.1166 41.2356 58.9641 35.8455 59.4726 32.4893C59.9811 29.1332 59.676 26.6924 59.676 26.6924L57.6421 26.8958Z" fill="#E6E6E6" />
            <path d="M61.5576 27.2518L62.1169 27.0993C62.1169 27.0993 64.1 26.845 64.4051 27.6586C64.7101 28.4722 64.6085 33.2522 64.6085 33.2522C64.6085 33.2522 65.1332 39.1188 63.0911 39.1856C61.0491 39.2525 61.4559 34.3709 61.4559 34.3709L61.5576 27.2518Z" fill="#E6E6E6" />
            <path d="M60.6193 25.927C62.0729 25.927 63.2513 24.7485 63.2513 23.2948C63.2513 21.8412 62.0729 20.6627 60.6193 20.6627C59.1657 20.6627 57.9873 21.8412 57.9873 23.2948C57.9873 24.7485 59.1657 25.927 60.6193 25.927Z" fill="#FFB6B6" />
            <path d="M59.7957 26.9174C60.3604 26.7742 61.0005 27.3772 61.2257 28.2648C61.3205 28.6181 61.3294 28.989 61.2515 29.3464L61.4941 30.3553L61.512 30.3544L63.4257 34.8647C63.8707 35.9134 63.8237 37.1174 63.2561 38.1051C62.9185 38.6927 62.4753 39.0877 61.9549 38.6845C61.0914 38.0154 60.5274 34.1843 60.2699 31.9836L59.6745 29.7466C59.4355 29.4695 59.2663 29.1392 59.1812 28.7833C58.9559 27.8961 59.2311 27.0607 59.7957 26.9174Z" fill="#FFB6B6" />
            <path d="M57.3369 27.2518L56.7776 27.0993C56.7776 27.0993 54.7945 26.845 54.4895 27.6586C54.1844 28.4722 54.2861 33.2522 54.2861 33.2522C54.2861 33.2522 53.7613 39.1188 55.8034 39.1856C57.8454 39.2525 57.4386 34.3709 57.4386 34.3709L57.3369 27.2518Z" fill="#E6E6E6" />
            <path d="M59.0986 26.9174C58.534 26.7742 57.8939 27.3772 57.6687 28.2648C57.5738 28.6181 57.565 28.989 57.6429 29.3464L57.4003 30.3553L57.3824 30.3544L55.4687 34.8647C55.0237 35.9134 55.0707 37.1174 55.6383 38.1051C55.9759 38.6927 56.4191 39.0877 56.9395 38.6845C57.803 38.0154 58.367 34.1843 58.6245 31.9836L59.2199 29.7466C59.4589 29.4695 59.6281 29.1392 59.7132 28.7833C59.9385 27.8961 59.6633 27.0607 59.0986 26.9174Z" fill="#FFB6B6" />
            <path d="M55.5801 25.4243C55.714 25.0172 55.9783 24.6608 56.0965 24.2489C56.2535 23.702 56.5878 24.9153 56.6204 24.3472C56.6824 23.2675 58.3302 20.4901 59.2709 19.9566C60.2116 19.4231 60.7519 19.5839 61.7103 20.085C61.7103 20.085 62.1756 20.1146 62.7547 20.4005C63.3339 20.6865 63.8553 21.1445 64.0805 21.7498C64.2591 22.2297 64.2393 22.7567 64.299 23.2652C64.4652 24.6819 65.2593 26.0292 65.0888 27.4454C65.0357 27.8872 64.8677 28.3441 64.5065 28.604C64.1452 28.8639 63.5616 28.8356 63.3238 28.4594C63.2427 28.3311 63.2084 28.1788 63.1389 28.0438C63.0693 27.9088 62.9437 27.7829 62.7919 27.7871C62.5637 27.7934 62.4476 28.0996 62.5375 28.3094C62.6275 28.5192 62.8389 28.6458 63.0375 28.7585C62.5647 28.9712 61.9782 28.905 61.5647 28.5922C61.1512 28.2794 60.928 27.733 61.0041 27.2201C61.1113 26.4974 62.1641 26.647 62.2881 25.927C62.3784 25.4029 62.6954 24.5259 62.6091 24.0011C62.5227 23.4763 62.1079 24.1117 62.5449 23.8085C62.0642 24.0402 61.036 22.4548 60.5327 22.2773C60.0295 22.0997 59.6028 21.7152 59.374 21.2331C58.8403 22.0069 58.3707 21.7967 58.1796 22.7171C57.9884 23.6375 58.1652 25.7284 58.9523 26.2423C58.6373 26.4572 58.3862 26.7646 58.2384 27.1161C58.1547 27.3153 58.1004 27.5332 57.9614 27.6986C57.696 28.0145 57.2059 28.0337 56.8153 27.9009C56.3309 27.7362 55.9168 27.3744 55.6884 26.9166C55.4601 26.4588 55.4203 25.9103 55.5801 25.4243Z" fill="#2F2E41" />
        </g>
        <defs>
            <clipPath id="clip0_112_7415">
                <rect width="112" height="70" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default GiftsImage;
