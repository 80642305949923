import {fetchBaseQuery} from "@reduxjs/toolkit/dist/query";
import configuration from "utils/configuration";
import storage from "utils/storage";

/**
 * A configuration object for RTK query, we get the token from state and
 * set the bearer token
 */

const baseQuery = fetchBaseQuery({
  baseUrl: configuration.apiBaseUrl as string,
  prepareHeaders: (headers) => {
    const token = storage.token;

    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

export default baseQuery;
