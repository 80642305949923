import {FC, SetStateAction, useState} from "react";
import moment from "moment";
import {
  DesktopDatePicker,
  DesktopDatePickerProps,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {Box, TextField, Typography, createTheme, StandardTextFieldProps} from "@mui/material";
import {styled, ThemeProvider} from "@mui/material/styles";
import CalendarDealInfoCalendarSvg from "components/Svg/CalendarDealInfoCalendarSvg";
import WarningIcon from "components/Svg/WarningIcon";
import constants from "styles/constants";

interface DeededDatePickerProps {
  dateStart: Date | null;
  onChangeEvent?: (someToChange: unknown) => void;
  onChange?: (value: Date | null) => void;
  name?: string;
  hasError?: string;
  disabled?: boolean;
  fieldWidth?: number;
  fullWidth?: boolean;
  setDate?: (value: () => Date) => void;
  setActiveDatePicker?: React.Dispatch<SetStateAction<boolean>>;
  hideInput?: boolean;
  placeholder?: string;
}

const DeededDatePicker: FC<
  Omit<DesktopDatePickerProps<any>, "onChange" | "value" | "renderInput"> &
    DeededDatePickerProps
> = ({
  name,
  onChangeEvent,
  dateStart,
  hasError,
  disabled,
  fieldWidth,
  fullWidth = false,
  setDate,
  setActiveDatePicker,
  hideInput,
  onChange,
  placeholder = "mm/dd/yyyy",
  ...props
}) => {
  const handleChangeDatePicker = (newValue: Date | null) => {
    onChangeEvent &&
      onChangeEvent({
        target: {
          name,
          value: moment(newValue).format("MM/DD/YYYY"),
        },
      });
    if (setDate) {
      setDate(() => newValue as Date);
    }
    if (onChange) {
      onChange(newValue);
    }
  };

  const [isOpenCalendar, setIsOpenCalendar] = useState(false);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ThemeProvider theme={materialPickerTheme}>
        <DesktopDatePicker
          {...props}
          disabled={disabled}
          value={dateStart}
          onChange={(newValue) =>
            handleChangeDatePicker(newValue as Date | null)
          }
          onOpen={() => {
            if (setActiveDatePicker) {
              setActiveDatePicker(true);
            }
            setIsOpenCalendar(true);
          }}
          onClose={() => {
            if (setActiveDatePicker) {
              setActiveDatePicker(false);
            }
            setIsOpenCalendar(false);
          }}
          slotProps={{
            textField: {
              ...(name ? {name} : {}),
              fullWidth,
              fieldWidth,
              hasError,
              disabled,
              hideInput,
              isOpenCalendar,
              placeholder,
            } as never,
          }}
          slots={{
            openPickerIcon: CalendarDealInfoCalendarSvg,
            textField: PickerTextField as never,
          }}
        />
      </ThemeProvider>
      {hasError && hasError.length !== 0 && (
        <StyledError className="DeededDatePickerError">
          <WarningIcon />
          {hasError}
        </StyledError>
      )}
    </LocalizationProvider>
  );
};

export const materialPickerTheme = createTheme({
  typography: {
    fontSize: 20,
  },
  palette: {
    primary: {
      main: constants.colors.red,
      light: constants.colors.red,
      dark: constants.colors.red,
    },
    info: {
      main: constants.colors.red,
      light: constants.colors.red,
      dark: constants.colors.red,
    },
  },
});

export const PickerTextField: FC<
  Omit<DeededDatePickerProps, "onChange" | "onChangeEvent" | "dateStart">
  & { isOpenCalendar: boolean }
  & StandardTextFieldProps
> = ({
  fullWidth,
  fieldWidth,
  hasError,
  disabled,
  hideInput,
  isOpenCalendar,
  placeholder,
  ...params
}) => (
  <Box
    sx={{
      position: "relative",
    }}
  >
    <StyledDatePickerTextField
      fieldWidth={fullWidth ? "100%" : fieldWidth ? `${fieldWidth}px` : "144px"}
      hasError={!!hasError}
      isOpenCalendar={isOpenCalendar}
      disabled={disabled}
      hideInput={hideInput}
      {...params}
      inputProps={{
        ...params.inputProps,
        ...(placeholder ? {placeholder} : {}),
      }}
      className={`${hideInput ? "hideInput" : ""}`}
    />
  </Box>
);

const StyledDatePickerTextField = styled(TextField, {
  shouldForwardProp: (prop) =>
    prop !== "hasError" &&
    prop !== "fieldWidth" &&
    prop !== "isOpenCalendar" &&
    prop !== "hideInput",
})<{
  hasError?: boolean;
  fieldWidth: string;
  isOpenCalendar?: boolean;
  disabled?: boolean;
  hideInput?: boolean;
}>(({hasError, fieldWidth, isOpenCalendar, disabled, hideInput}) => ({
  width: fieldWidth,
  height: "44px",
  background: constants.colors.white,
  border: "none",
  boxSizing: "border-box",
  borderRadius: "5px",
  ".MuiCalendarPicker-root": {
    width: "300px",
  },
  "& .MuiButtonBase-root": {
    transition: constants.transitions,
    backgroundColor:
      isOpenCalendar && !hideInput
        ? constants.colors.redVeryPale
        : "transparent",
    "&:hover": {
      transition: constants.transitions,
      backgroundColor: constants.colors.redVeryPale,
    },
  },

  "& .MuiOutlinedInput-root": {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "17px",
    textTransform: "uppercase",
    svg: {
      path: {
        stroke: isOpenCalendar
          ? constants.colors.red
          : disabled
            ? constants.colors.grayLight
            : constants.colors.title,
        transition: constants.transitions,
      },
    },
    "& fieldset": {
      borderColor:
        hasError || isOpenCalendar
          ? constants.colors.red
          : constants.colors.grayLight,
      "& legend": {width: 0},
      transition: constants.transitions,
    },
    "&:not(.Mui-disabled):not(.Mui-focused):hover fieldset": {
      borderColor:
        isOpenCalendar || hasError
          ? constants.colors.red
          : constants.colors.deededGray,
      transition: constants.transitions,
    },
    ".Mui-disabled:hover": {
      cursor: "no-drop",
      "& .MuiOutlinedInput-root": {
        cursor: "no-drop",
      },
    },
    "&.Mui-focused": {
      fieldset: {
        borderColor: constants.colors.red,
        borderWidth: "1px",
        boxShadow: "none",
        transition: constants.transitions,
      },
      svg: {
        path: {
          stroke: constants.colors.red,
          transition: constants.transitions,
        },
      },
    },
    height: "44px !important",
    outline: "none !important",
  },
  "&.hideInput": {
    width: "100%",
    height: "44px",
    background: "transparent",
    border: "none !important",
    boxSizing: "border-box",
    borderRadius: "5px",
    padding: 0,
    position: "absolute",
    bottom: 0,
    left: 0,
    ".MuiCalendarPicker-root": {
      width: "300px",
    },
    "& .MuiButtonBase-root": {
      position: "absolute",
      left: "0",
      top: "0",
      minWidth: "100%",
      minHeight: "100%",
      "&:hover": {
        background: "transparent",
      },
      "& svg, & span": {
        display: "none",
      },
    },
    "& .MuiOutlinedInput-root": {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "14px",
      lineHeight: "17px",
      textTransform: "uppercase",
      border: "none !important",
      height: "44px !important",
      outline: "none !important",
      background: "transparent",

      "& input": {
        border: "none !important",
        padding: 0,
        height: "100%",
        fontSize: 0,
      },
      "& fieldset": {
        border: "none !important",
        padding: 0,
        boxShadow: "none !important",
        "& legend": {display: "none"},
      },
      "&:hover fieldset": {
        border: "none !important",
      },
      "&.Mui-focused fieldset": {
        border: "none !important",
        boxShadow: "0px 0px 0px 2px rgba(223, 227, 255, 0.5)",
      },
    },
  },
}));

const StyledError = styled(Typography)({
  color: constants.colors.red,
  ...constants.fontConfig_TEMP,
  marginTop: "5px",
  fontSize: "10px",
  display: "flex",
  alignItems: "center",
  svg: {
    marginRight: "3px",
    marginBottom: "1px",
  },
});

export default DeededDatePicker;
