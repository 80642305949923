import {
  FormControlLabel,
  FormControlLabelProps,
  styled,
  Switch,
  SwitchProps,
  SxProps,
  Theme,
} from "@mui/material";
import constants from "../../styles/constants";

interface DeededSwitcherProps {
  sx: SxProps<Theme> | undefined;
  activeSwitcher: boolean;
  handleChangeSwitcher: () => void;
  label?: string;
  disabled?: boolean;
  dataSeleniumId?: string;
}

const DeededSwitcher: React.FC<
  | DeededSwitcherProps
  | (FormControlLabelProps & {
      activeSwitcher: boolean;
      handleChangeSwitcher: () => void;
      dataSeleniumId?: string;
    })
> = ({
  activeSwitcher,
  handleChangeSwitcher,
  label,
  disabled,
  dataSeleniumId,
  sx,
  ...props
}) => (
  <FormControlLabel
    data-selenium-id={dataSeleniumId}
    {...props}
    data-selenium-test="DeededSwitcher"
    control={
      <NotificationSwitch
        checked={activeSwitcher}
        onChange={handleChangeSwitcher}
        sx={{m: 1}}
      />
    }
    label={label ?? ""}
    disabled={disabled}
    sx={{
      "&.Mui-disabled:hover": {
        cursor: "no-drop",
      },
      ...sx,
    }}
  />
);

const NotificationSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({theme}) => ({
  width: 36,
  height: 14,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: "2px 2px 2px 3px",
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(20px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: constants.colors.green,
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: "10px",
    height: "10px",
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export default DeededSwitcher;
