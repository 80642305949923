import {useState} from "react";
import {useSnackbar} from "notistack";
import saveAs from "file-saver";
import { downloadDocument } from "api";
import configuration from "../../../utils/configuration";
/**
 *
 * @param {string} filename
 * @param {string} type e.g. text/plain
 * @param {number} documentId FOr generating URL
 */
export function useDocumentDownload(filename, type, documentId, downloadUrl) {
  const [isDocumentLoading, setIsDocumentLoading] = useState(false);
  const {enqueueSnackbar} = useSnackbar();

  const documentDownload = (documentId) => {
    setIsDocumentLoading(true);
    downloadDocument(documentId, type, downloadUrl)
      .then((res) => {
        const blob = new Blob([res.data], {type});
        saveAs(blob, filename);
        setIsDocumentLoading(false);
      })
      .catch((err) => {
        setIsDocumentLoading(false);
        enqueueSnackbar("Couldn't download " + filename, {
          variant: "error",
          autoHideDuration: configuration.autoHideErrorDuration,
        });
      });
  };

  return () => {
    if (!isDocumentLoading) {
      documentDownload(documentId);
    }
  };
}

export default useDocumentDownload;
