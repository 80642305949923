import {Dispatch} from "redux";
import {getRoles, getStaff, userCreate, userUpdate} from "../../../api";
export interface StaffUser {
  id: number;
  name_prefix: string | null;
  first_name: string;
  last_name: string;
  full_name: string;
  middle_name: string | null;
  email: string;
  email_verified_at: Date | null;
  phone: string;
  role: string;
  communication_method: string;
  show_guides: number;
  brokerage_or_team: null;
  company: null;
  slack_id: null;
  deleted_at: null;
  total_deals: number;
  userpic: Userpic;
  slug: string | null;
  timezone: string;
  has_personal_page: number;
}

export interface Userpic {
  id: number;
  user_id: number;
  original_filename: string;
  type: string;
  storage_id: number;
}
export interface StaffType{
  "Mobile Signer": StaffUser[];
  Lawyer: StaffUser[];
  "Law Clerk": StaffUser[];
}
export interface StaffCardReducerState {
  roles: Array<string>;
  staff: StaffType | null;
}
type StaffCardActionType = "SET_STAFF_ROLES" | "SET_STAFF";

export type StaffCardAction = {
  type: StaffCardActionType;
  payload?: unknown;
};

const StaffCardState: StaffCardReducerState = {
  roles: [],
  staff: null,
};

const StaffCardReducer = (state = StaffCardState, action: StaffCardAction) => {
  switch (action.type) {
    case "SET_STAFF": {
      return {
        ...state,
        staff: action.payload as unknown,
      };
    }
    case "SET_STAFF_ROLES": {
      return {
        ...state,
        roles: action.payload as Array<string>,
      };
    }
    default:
      return state;
  }
};

export const setStaffRolesAC = (payload: Array<string>) => ({
  type: "SET_STAFF_ROLES",
  payload,
});

export const setStaffAC = (payload: unknown) => ({
  type: "SET_STAFF",
  payload,
});

export const getStaffTC = () => async (dispatch: Dispatch) => {
  try {
    const staff = await getStaff();
    dispatch(setStaffAC(staff.data));
  } catch (e) {
    const errorMessage = (e as unknown as {response: {data: {error: string}}})
      ?.response?.data?.error;
    console.log(errorMessage);
  }
};

export const getStaffRolesTC = () => async (dispatch: Dispatch) => {
  const staff = await getRoles("staff");
  dispatch(setStaffRolesAC(staff.data));
};
export const updateUserTC =
  (baseURL: string, id: number, payload: unknown) =>
  async (dispatch: Dispatch) => {
    await userUpdate(baseURL, id, payload);
  };
export const createUserTC =
  (baseURL: string, payload: unknown) => async (dispatch: Dispatch) => {
    await userCreate(baseURL, payload);
  };
export default StaffCardReducer;
