import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  styled,
} from "@mui/material";
import {baseURL} from "api";
import profilePhotoPlug from "assets/img/unknownUserProfilePhoto.jpg";
import AccordionIcon from "components/Svg/AccordionIcon";
import constants from "styles/constants";

interface ActivityUserPicturesProps {
  id: number;
  original_filename: string;
}
export interface DealDetailActivityLogListItemMobileProps {
  defaultExpanded?: boolean;
  documentName: string;
  status: string;

  date: string;
  time: string;
  user: ActivityLogUserProps;
}
export interface ActivityLogUserProps {
  full_name: string;
  userpic: ActivityUserPicturesProps;
}

const DealDetailActivityLogListItemMobile: React.FC<
  DealDetailActivityLogListItemMobileProps
> = ({defaultExpanded, documentName, status, date, time, user}) => {
  const photoUrl = user?.userpic
    ? `${baseURL}/userpics/${user?.userpic?.id}`
    : profilePhotoPlug;
  return (
    <AccordionStyled
      defaultExpanded={defaultExpanded}
      dealTypeColor={"#ffff00"}
    >
      <WrapperSummary>
        <SelectedLastDeal>
          <ProfilePhotoBox>
            <img
              style={{
                borderRadius: "5px",
                width: "24px",
                height: "24px",
                objectFit: "cover",
              }}
              alt={"img"}
              src={photoUrl}
            />
          </ProfilePhotoBox>
          {user.full_name}
        </SelectedLastDeal>
        <DocumentNameValue>{documentName}</DocumentNameValue>
        <AccordionSummary sx={{borderRadius: "10px"}}>
          <AccordionIcon />
        </AccordionSummary>
      </WrapperSummary>

      <AccordionDetails>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <LeftSide>
            <Box>
              <InfoItem>
                <InfoItemLabel> Status:</InfoItemLabel>
              </InfoItem>
              <InfoItem>
                <InfoItemLabel>Date:</InfoItemLabel>
              </InfoItem>
              <InfoItem>
                <InfoItemLabel>Time:</InfoItemLabel>
              </InfoItem>
            </Box>
          </LeftSide>
          <RightSide>
            <InfoItem>
              <RightSideInfoItemValue>
                <span style={{cursor: "pointer"}}>{status}</span>
              </RightSideInfoItemValue>
            </InfoItem>
            <InfoItem>
              <RightSideInfoItemValue>
                <span style={{cursor: "pointer"}}>{date}</span>
              </RightSideInfoItemValue>
            </InfoItem>
            <InfoItem>
              <RightSideInfoItemValue>{time}</RightSideInfoItemValue>
            </InfoItem>
          </RightSide>
        </Box>
      </AccordionDetails>
    </AccordionStyled>
  );
};
const LeftSide = styled(Box)({
  display: "flex",
  alignItems: "center",
  flex: "0 0 42%",
});
const DocumentNameValue = styled(Box)({
  position: "relative",
  display: "flex",
  flex: 1,
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "15px",
  alignItems: "center",
  color: constants.colors.title,
  lineBreak: "anywhere",
});

const SelectedLastDeal = styled(Box)({
  position: "relative",
  display: "flex",
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: "12px",
  lineHeight: "15px",
  alignItems: "center",
  color: constants.colors.intakeTitle,
  lineBreak: "anywhere",
  marginRight: "48px",
});

const ProfilePhotoBox = styled(Box)({
  borderRadius: "5px",
  marginRight: "8px",
  width: "24px",
  height: "24px",
});

const InfoItem = styled(Box)({
  fontWeight: 400,
  fontSize: "12px",
  lineHeight: "15px",
  "&:first-of-type": {
    display: "flex",
    alignItems: "center",
  },
  "&:not(:last-child)": {
    marginBottom: "16px",
  },
});

const InfoItemLabel = styled(Box)({
  color: constants.colors.black,
  whiteSpace: "nowrap",
});

const RightSideInfoItemValue = styled(Box)({
  color: constants.colors.black,
  fontWeight: 400,
  marginBottom: "-4.3px",
  span: {
    display: "inline-block",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "91px",
  },
});

const RightSide = styled(Box)({
  flex: "0 0 58%",
  justifyContent: "flex-end",
});

const WrapperSummary = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "0 16px",
});

const AccordionStyled = styled(Accordion, {
  shouldForwardProp: (prop) => prop !== "dealTypeColor"
})<{dealTypeColor: string}>(
  ({dealTypeColor}) => ({
    margin: "0 !important",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: `1px solid ${constants.colors.configNotificationsTableBorder}`,
    },
    svg: {
      stroke: constants.colors.title,
      transition: constants.transitions,
    },
    "& .Mui-expanded": {
      svg: {
        transform: "scaleY(-1)",
        transition: constants.transitions,
        stroke: constants.colors.red8,
      },
    },
    "& .MuiAccordionSummary-expandIconWrapper": {
      marginTop: "-5px",
      marginRight: "10px",
    },
    "&:before": {
      display: "none",
    },
    "& .MuiPaper-root.MuiButtonBase-root": {
      minHeight: "48px ",
    },
    "& .css-bz4dnt-MuiCollapse-root": {
      width: 0,
    },
    "& .MuiAccordionSummary-root": {
      borderRadius: "10px",
      width: "48px",
      minHeigth: "48px !important",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    "& .MuiAccordionSummary-root.Mui-expanded": {
      minHeight: "48px",
      "& .MuiAccordionSummary-content": {
        margin: 0,
      },
    },
    "&.MuiPaper-root .MuiAccordion-root .MuiAccordionSummary-root": {
      minHeight: "48px",
      borderRadius: "10px",
    },
  }),
);

export default DealDetailActivityLogListItemMobile;
