import {SVGProps} from "react";
import constants from "../../styles/constants";

const WarningIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={8} cy={8} r={6} fill={constants.colors.red7} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 4.5a.5.5 0 0 1 .5.5v3.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"
      fill={constants.colors.white}
    />
    <path
      d="M8 11.5A.75.75 0 1 0 8 10a.75.75 0 0 0 0 1.5Z"
      fill={constants.colors.white}
    />
  </svg>
);

export default WarningIcon;
