import { Model, } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.min.css";
import "./index.scss";
import questions from "components/Information/Quote/QuoteQuestions.json";
import { registerInputCustomFormat } from "../../Common/SurveyJs/QuestionNumberFormat";
import { useCallback } from "react";
import constants from "styles/constants";

export interface quoteSurveyDataInterface {
    dealType: string,
    province: string,
    sellingPrice: number,
    purchasePrice: number,
    mortgageAmount: number,
    mortgageValue: number,
    isFirstTimeHomeBuyer: boolean,
    hasMortgage: boolean,
}

registerInputCustomFormat();

interface SurveyQuotePropsInterface {
    submitForm: (data: Partial<quoteSurveyDataInterface>) => void
}

const dashboardQuestions = {
    ...questions,
    showProgressBar: 'hidden',
    pages: questions.pages.filter((page) => page.name !== 'yourInformation').map((page) => {
        if (page.name === 'propertyInformation')
            return {
                ...page, title: '', description: '', visibleIf: true,
                elements: page.elements.filter((question) => question.name === 'province')
            };
        return { ...page, title: '', description: '' };
    })
};

export const SurveyQuote = ({
    submitForm
}: SurveyQuotePropsInterface) => {
    const survey = new Model(dashboardQuestions);
    const handleData = useCallback((sender, options) => {
        const surveyData = sender.data as Partial<quoteSurveyDataInterface>;
        submitForm(surveyData);
    }, []);

    survey.onComplete.add(handleData);
    document.documentElement.style.setProperty('--sjs-primary-backcolor', constants.colors.red);

    return (<Survey model={survey} />);
}
