import {useState, useEffect} from "react";
import "./DealMemberEditCard.scss";
import NumberFormat from "react-number-format";
import CrossSVG from "../../Svg/Cross";
import {withSnackbar} from "utils/withSnackbar";
import configuration from "../../../utils/configuration";

const DealMemberEditCard = ({
  member,
  toggleCardDisplay,
  handleCardInputs,
  updateClients,
  enqueueSnackbar,
}) => {
  const [editMember, setEditMember] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    role: "",
    id: "",
    name_prefix: "",
  });
  const [errors, setErrors] = useState({});
  const handleEditMemberInputs = (e) => {
    setErrors({});
    const {name, value} = e.target;
    if (editMember.name_prefix === "Corporate") {
      setEditMember((prevState) => ({
        ...prevState,
        first_name: value,
        last_name: value,
      }));
      return;
    }
    setEditMember((prevState) => ({
      ...prevState,
      [name]: name === "email" ? (value === "" ? null : value) : value,
    }));
  };
  const handleUpdateCard = () => {
    for (let key in editMember) {
      if (key !== "role" && key !== "id" && key !== "name_prefix") {
        setErrors((prev) => ({
          ...prev,
          [key]:
            editMember[key] === null || editMember[key].length === 0
              ? true
              : false,
        }));
      }
    }
    if (Object.keys(errors).length !== 0) return;
    const {first_name, last_name, email, phone} = editMember;
    if (
      first_name.length === 0 ||
      last_name.length === 0 ||
      phone.length === 0 ||
      email === null
    ) {
      enqueueSnackbar("Fields can not be empty", {
        variant: "error",
        autoHideDuration: configuration.autoHideErrorDuration,
      });

      return;
    }

    updateClients(member);
    toggleCardDisplay();
  };
  const handleCloseCard = () => {
    const {first_name, last_name, email, phone} = editMember;
    if (
      first_name.length === 0 ||
      last_name.length === 0 ||
      phone.length === 0 ||
      email === null
    ) {
      enqueueSnackbar("Fields can not be empty", {
        variant: "error",
        autoHideDuration: configuration.autoHideErrorDuration,
      });

      return;
    }
    toggleCardDisplay();
  };
  useEffect(() => {
    if (member) {
      const {first_name, last_name, email, phone, role, id, name_prefix} =
        member;
      setEditMember({
        first_name,
        last_name,
        name_prefix,
        email,
        phone,
        role,
        id,
      });
    }
  }, []);

  useEffect(() => {
    handleCardInputs(undefined, editMember);
  }, [editMember]);

  const {first_name, last_name, email, phone, name_prefix} = editMember;

  return (
    <div className="role__edit--wrapper">
      <div className="role__edit--form">
        <div className="role__edit--header">
          <h2>{`Edit ${editMember.role}`}</h2>
          <div onClick={handleCloseCard}>
            <CrossSVG />
          </div>
        </div>
        <div className="role__edit--inputs">
          <div className="prefix__container">
            <label className="radio-container">
              Mr.
              <input
                required={false}
                checked={name_prefix === "Mr."}
                type="radio"
                name="name_prefix"
                value="Mr."
                onChange={(e) => handleEditMemberInputs(e)}
              />
              <span className="checkmark"></span>
            </label>
            <label className="radio-container">
              Ms.
              <input
                checked={name_prefix === "Ms."}
                required={false}
                type="radio"
                name="name_prefix"
                value="Ms."
                onChange={(e) => handleEditMemberInputs(e)}
              />
              <span className="checkmark"></span>
            </label>

            <label className="radio-container">
              Mrs.
              <input
                checked={name_prefix === "Mrs."}
                required={false}
                type="radio"
                name="name_prefix"
                value="Mrs."
                onChange={(e) => handleEditMemberInputs(e)}
              />
              <span className="checkmark"></span>
            </label>
            <label className="radio-container">
              Dr.
              <input
                checked={name_prefix === "Dr."}
                required={false}
                type="radio"
                name="name_prefix"
                value="Dr."
                onChange={(e) => handleEditMemberInputs(e)}
              />
              <span className="checkmark"></span>
            </label>
            <label className="radio-container">
              Corporation
              <input
                checked={name_prefix === "Corporation"}
                required={false}
                type="radio"
                name="name_prefix"
                value="Corporation"
                onChange={(e) => handleEditMemberInputs(e)}
              />
              <span className="checkmark"></span>
            </label>
          </div>
          {name_prefix === "Corporation" ? (
            <label htmlFor="name">
              First Name
              <input
                className={errors?.first_name && "input-error"}
                autoComplete="new-password"
                type="text"
                name="first_name"
                value={first_name}
                onChange={(e) => {
                  return handleEditMemberInputs(e);
                }}
              />
              {errors?.first_name && (
                <span className="input-error-text">Field is required</span>
              )}
            </label>
          ) : (
            <>
              <label htmlFor="name">
                First Name
                <input
                  className={errors?.first_name && "input-error"}
                  autoComplete="new-password"
                  type="text"
                  name="first_name"
                  value={first_name}
                  onChange={(e) => {
                    return handleEditMemberInputs(e);
                  }}
                />
                {errors?.first_name && (
                  <span className="input-error-text">Field is required</span>
                )}
              </label>
              <label htmlFor="name">
                Last Name
                <input
                  className={errors?.last_name && "input-error"}
                  autoComplete="new-password"
                  type="text"
                  name="last_name"
                  value={last_name}
                  onChange={(e) => {
                    return (
                      handleEditMemberInputs(e), handleCardInputs(e, member)
                    );
                  }}
                />
                {errors?.last_name && (
                  <span className="input-error-text">Field is required</span>
                )}
              </label>
            </>
          )}

          <label htmlFor="phone">
            phone
            <NumberFormat
              className={errors?.phone && "input-error"}
              format="###-###-####"
              name="phone"
              value={phone}
              onChange={(e) => {
                return handleEditMemberInputs(e), handleCardInputs(e, member);
              }}
            />
            {errors?.phone && (
              <span className="input-error-text">Field is required</span>
            )}
          </label>

          <label htmlFor="email">
            Email
            <input
              className={errors?.email && "input-error"}
              autoComplete="new-password"
              type="text"
              name="email"
              value={email || ""}
              onChange={(e) => {
                return handleEditMemberInputs(e), handleCardInputs(e, member);
              }}
            />
            {errors?.email && (
              <span className="input-error-text">Field is required</span>
            )}
          </label>

          <button className="submit" onClick={handleUpdateCard}>
            submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default withSnackbar(DealMemberEditCard);
