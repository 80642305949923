import {Box, Typography} from "@mui/material";
import constants from "../../../../../styles/constants";
import {styled} from "@mui/material/styles";
import {useCallback, useEffect, useState} from "react";
import DeededButton from "v2/components/DeededButton";
import {useProfileUpdateApiKeyEmailsMutation} from "../../../../../redux/reducers/profileApi";
import ProfileIntegrationThreeDEmailComponent from "./ProfileIntegrationThreeDEmailComponent";
import ProfileIntegrationThreeDEmailComponentEditMode from "./ProfileIntegrationThreeDEmailComponentEditMode";

type ProfileIntegrationThreeDEmailsType =
  | {
      email: string;
      full_name: string;
    }[]
  | null;
interface ProfileIntegrationThreeDEmailsProps {
  emails?: ProfileIntegrationThreeDEmailsType;
  refetchApiKeyData: () => void;
}
const ProfileIntegrationThreeDEmails: React.FC<
  ProfileIntegrationThreeDEmailsProps
> = ({emails, refetchApiKeyData}) => {
  const [addMode, setAddMode] = useState(false);
  const [emailsList, setEmailsList] = useState(emails ?? []);
  const [saveEmailsChanges] = useProfileUpdateApiKeyEmailsMutation();
  const updateEmailsList = useCallback(
    (emailsListForUpdate) => {
      saveEmailsChanges({
        emails: emailsListForUpdate,
        refetchApiKeyData: refetchApiKeyData,
      });
    },
    [refetchApiKeyData, saveEmailsChanges],
  );
  const addEmail = (email: string, full_name: string) => {
    const newEmailsList = emailsList.concat([{email, full_name}]);
    setEmailsList(newEmailsList);
    updateEmailsList(newEmailsList);
  };
  useEffect(() => {
    setEmailsList(emails ?? []);
  }, [emails]);
  return (
    <>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "5fr 7fr 3fr",
          borderRadius: "5px 5px 0px 0px",
          border: `1px solid ${constants.colors.configNotificationsTableBorder}`,
          borderBottom: "none",
          padding: "12px 17px 10px 17px",
        }}
      >
        <HeadTypography>Name</HeadTypography>
        <HeadTypography>Email</HeadTypography>
        <HeadTypography />
      </Box>
      <Box
        sx={{
          borderRadius: "0px 0px 5px 5px",
          border: `1px solid ${constants.colors.configNotificationsTableBorder}`,
          minHeight: "50px",
          padding: "16px 5px 0px 17px",
        }}
      >
        {emailsList.map((emailInfo, index) => {
          return (
            <ProfileIntegrationThreeDEmailComponent
              updateEmailsList={updateEmailsList}
              key={index}
              emailInfo={emailInfo}
              index={index}
              emailsList={emailsList}
              setEmailsList={setEmailsList}
            />
          );
        })}
        {addMode && (
          <ProfileIntegrationThreeDEmailComponentEditMode
            onSubmitEvent={({
              email,
              full_name,
            }: {
              email: string;
              full_name: string;
            }) => {
              addEmail(email, full_name);
              setAddMode(false);
            }}
            onClose={() => {
              setAddMode(false);
            }}
            emailInfo={{
              email: "",
              full_name: "",
            }}
          />
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "32px",
          marginBottom: "32px",
        }}
      >
        <DeededButton
          onClick={() => {
            setAddMode(true);
          }}
          sx={{
            width: "220px",
            height: "36px",
          }}
          kind={"secondary"}
        >
          <PlusSpan>+</PlusSpan> Add Additional Emails
        </DeededButton>
      </Box>
    </>
  );
};

const PlusSpan = styled("span")({
  fontSize: "40px",
  fontWeight: "300",
  marginRight: "8px",
});

const HeadTypography = styled(Typography)({
  color: constants.colors.deededGray,
  fontSize: "14px",
});
export default ProfileIntegrationThreeDEmails;
