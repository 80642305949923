import {Dispatch} from "redux";
import {
  downloadReports,
  getEmailTemplates,
  getPreview,
  templatesUpload,
} from "../../../api";
import {addErrorAC} from "../ErrorReducer";
import {saveAs} from "file-saver";

type EmailTemplateActionType =
  | "SET_RESPONSE_TEMPLATE_DATA"
  | "SET_PREVIEW"
  | "UNMOUNT_TEMPLATE_PREVIEW"
  | "SET_TEMPLATES"
  | "SET_SELECTED_TEMPLATE";
type EmailTemplateAction = {
  type: EmailTemplateActionType;
  payload?: unknown;
};
export type EmailTemplateInitialStateType = {
  responseTemplateData: unknown;
  preview: unknown;
  templates: unknown;
  selected_template:
    | {
        label: string;
        value: string;
        id: number;
        default_text: string;
      }
    | {};
};
const emailTemplateInitialState: EmailTemplateInitialStateType = {
  responseTemplateData: {},
  preview: null,
  templates: [],
  selected_template: {},
};

const EmailTemplateReducer = (
  state = emailTemplateInitialState,
  action: EmailTemplateAction,
) => {
  switch (action.type) {
    case "SET_SELECTED_TEMPLATE": {
      return {
        ...state,
        selected_template: action.payload,
      };
    }
    case "SET_TEMPLATES": {
      return {
        ...state,
        templates: action.payload,
      };
    }
    case "UNMOUNT_TEMPLATE_PREVIEW": {
      return {
        ...state,
        preview: null,
      };
    }
    case "SET_PREVIEW": {
      return {
        ...state,
        preview: action.payload,
      };
    }
    case "SET_RESPONSE_TEMPLATE_DATA": {
      return {...state, responseTemplateData: action.payload};
    }
    default:
      return state;
  }
};

export const setResponseTemplateDataAC = (payload: unknown) => {
  return {
    type: "SET_RESPONSE_TEMPLATE_DATA",
    payload,
  };
};
export const setPreviewAC = (payload: unknown) => ({
  type: "SET_PREVIEW",
  payload,
});
export const unmountPreviewAC = () => ({
  type: "UNMOUNT_TEMPLATE_PREVIEW",
});
export const setEmailTemplatesAC = (payload: unknown) => ({
  type: "SET_TEMPLATES",
  payload,
});
export const setSelectedTemplateAC = (
  payload:
    | {
        label: string;
        value: string;
        id: number;
        default_text: string;
      }
    | {},
) => ({
  type: "SET_SELECTED_TEMPLATE",
  payload,
});

export const sendEmailTemplateTC =
  (template: File) => async (dispatch: Dispatch) => {
    const responseData = await templatesUpload(template);
    const newTemplates = await getEmailTemplates();
    dispatch(setResponseTemplateDataAC(responseData));
    dispatch(setEmailTemplatesAC(newTemplates));
  };
export const getPreviewTemplateTC =
  (text: string, templateId: number) => async (dispatch: Dispatch) => {
    const preview = await getPreview(text, templateId);
    dispatch(setPreviewAC(preview));
  };
export const getEmailTemplatesTC = () => async (dispatch: Dispatch) => {
  const templates = await getEmailTemplates();
  dispatch(setEmailTemplatesAC(templates));
};
export const downloadReportsTC =
  (
    url: string,
    type: string,
    filename: string,
    setIsLoading?: (isLoading: boolean) => void,
    params?: Object
  ) =>
  async (dispatch: Dispatch) => {
    try {
      if (setIsLoading) {
        setIsLoading(true);
      }
      const res = await downloadReports(url, type, params);

      const blob = new Blob([res.data], {type});
      saveAs(blob, filename);
      if (setIsLoading) {
        setIsLoading(false);
      }
    } catch (e) {
      const error = e as {response: {data: {error: string}}};
      dispatch(
        addErrorAC(error.response.data.error ?? "Can't download report"),
      );
    }
  };
export default EmailTemplateReducer;
