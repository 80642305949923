import {Box, styled} from "@mui/material";
import constants from "styles/constants";

const DealDetailsSurveyTabHeader = () => (
  <DealDetailsContainer>
    <ProffGroupHeader>
      <SelectedSortValue>
        <div className="wrapperItem">
          <span>Full Name</span>
        </div>
      </SelectedSortValue>
      <SelectedSortValue>
        <div className="wrapperItem">
          <span>What should we keep doing</span>
        </div>
      </SelectedSortValue>
      <SelectedSortValue>
        <div className="wrapperItem">
          <span>What could we do differently</span>
        </div>
      </SelectedSortValue>
      <SelectedSortValue>
        <div className="wrapperItem">
          <span>When reffering us to others what would you say</span>
        </div>
      </SelectedSortValue>
      <SelectedSortValue>
        <div className="wrapperItem">
          <span>Rating</span>
        </div>
      </SelectedSortValue>
      <SelectedSortValue>
        <div className="wrapperItem">
          <span>User</span>
        </div>
      </SelectedSortValue>
    </ProffGroupHeader>
  </DealDetailsContainer>
);

const ProffGroupHeader = styled(Box)({
  display: "flex",
  padding: "1.5rem 2rem",
  marginBottom: "0.5rem",
});
const DealDetailsContainer = styled(Box)({
  width: "100%",
  background: constants.colors.bg,
  marginTop: "26px",
});
const SelectedSortValue = styled(Box)({
  position: "relative",
  display: "flex",
  flex: 1,
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "17px",
  alignItems: "center",
  color: constants.colors.deededGray,
  lineBreak: "anywhere",
  "&:first-child": {
    flex: "0 0 11%",
  },
  "&:nth-child(2)": {
    flex: "0 0 24%",
  },
  "&:nth-child(3)": {
    flex: "0 0 24%",
  },
  "&:nth-child(4)": {
    flex: "0 0 24%",
  },
  "&:nth-child(5)": {
    flex: "0 0 6%",
  },
  "&:nth-child(6)": {
    flex: "0 0 11%",
  },

  "& .wrapperItem": {
    alignItems: "center",
    display: "flex",
    flex: "0 0 90%",
  },
});

export default DealDetailsSurveyTabHeader;
