import React, {MouseEventHandler, useCallback} from "react";
import DeededPopup from "v2/components/DeededPopup";
import useDocumentViewHandlers from "components/Dashboard/DocumentView/useDocumentViewHandlers";
import DeededInput from "v2/components/DeededInput";
import DeededButton from "v2/components/DeededButton";
import {Box, Stack, styled, useTheme} from "@mui/material";
import DocumentFrameDisplay from "components/Dashboard/DocumentView/DocumentFrameDisplay";

import constants from "styles/constants";
import DeededAutocompleteSelect from "v2/components/DeededAutocompleteSelect";
import {useFormik} from "formik";
import * as yup from "yup";
import ReactPanZoom from "react-image-pan-zoom-rotate";
import useTypedSelector from "utils/hooks/useTypedSelector";
import DeededCircularLoader from "v2/components/DeededCircularLoader";
import DeededTextArea from "v2/components/DeededTextArea";
import useDocumentDownloadWithLoadMark from "utils/hooks/useDocumentDownloadWithLoadMark";
import DeededLabel from "v2/components/DeededLabel";
import DeededSpace from "v2/components/DeededSpace";
import DownloadSvg from "./svg/DownloadSvg";
import ApproveSvg from "./svg/ApproveSvg";
import DeleteSvg from "./svg/DeleteSvg";
import RejectSvg from "./svg/RejectSvg";

interface DealOverlayDocumentViewPopupProps {
  dealID: number;
  dealType: string;
  selectedDocument: Object;
  toggleDocumentPopup: () => void;
  showDocumentPopup: boolean;
  setShowDocumentPopup: (showDocumentPopup: boolean) => void;
}

const DealOverlayDocumentViewPopup: React.FC<
  DealOverlayDocumentViewPopupProps
> = ({
  dealID,
  dealType,
  selectedDocument,
  toggleDocumentPopup,
  showDocumentPopup,
  setShowDocumentPopup,
}) => {
  const documentViewHandlers = useDocumentViewHandlers(
    dealID,
    dealType,
    selectedDocument,
    toggleDocumentPopup,
    undefined,
  );

  const dealTypeFolders = useTypedSelector(
    (state) => state.DealsReducer.folders_for_deal_type,
  );
  const schema = yup.object().shape({
    folderName: yup
      .string()
      .required("Folder is required")
      .test(
        "folderName",
        "Folder should be selected from the list",
        (folderName) => {
          return dealTypeFolders.some(
            (folderNameToCheck) => folderName === folderNameToCheck,
          );
        },
      ),
    fileName: yup.string().required(),
    comment: yup.string().nullable(),
    rejectionReason: yup.string().nullable(),
  });
  const {
    fileData,
    documentType,
    newName,
    comment,
    rejectionReason,
    folder,
    documentUpdates,
    downloadData,
    onCommentUpdate,
    onRejectionReasonUpdate,
    setFolder,
    setNewName,
    onApprove,
    onReject,
    onDelete,
  } = documentViewHandlers;
  const documentDownloadProcess = useDocumentDownloadWithLoadMark(
    downloadData.name,
    documentType,
    null as unknown as number,
    downloadData.link,
  );
  const isImage = documentType?.split("/")[0] === "image";
  const initialValues = {
    folderName: folder ? folder : "",
    fileName: newName ? newName : "",
    comment: comment ? comment : "",
    rejectionReason: rejectionReason || "",
  };
  const onSubmit = () => {
    onApprove();
  };

  const onSubmitRejection = () => {
    if (!values.rejectionReason) {
      return setFieldError('rejectionReason', "Please enter a rejection reason.");
    }
    onReject();
  };

  const {handleSubmit, values, errors, handleChange, setFieldError, setErrors} = useFormik({
    validationSchema: schema,
    initialValues,
    onSubmit,
    validateOnChange: false,
    validateOnBlur: false,
  });
  const handleChangeComment = useCallback(
    (newComent) => {
      handleChange({
        target: {
          name: "comment",
          value: newComent,
        },
      });
      onCommentUpdate(newComent);
    },
    [handleChange, onCommentUpdate],
  );
  const handleChangeRejectionReason = useCallback(
    (newReason) => {
      handleChange({
        target: {
          name: "rejectionReason",
          value: newReason,
        },
      });
      onRejectionReasonUpdate(newReason);
    },
    [handleChange, onRejectionReasonUpdate],
  );
  const handleChangeFolder = useCallback(
    (folderName: string) => {
      handleChange({
        target: {
          name: "folderName",
          value: folderName,
        },
      });
      setFolder(folderName);
    },
    [handleChange, setFolder],
  );
  const handleChangeFileName = useCallback(
    (e: {target: {value: string}}) => {
      handleChange({
        target: {
          name: "fileName",
          value: e.target.value,
        },
      });
      setNewName(e.target.value);
    },
    [handleChange, setNewName],
  );

  const theme = useTheme();

  return (
    <>
      <DeededPopup
        sx={{
          overflowX: "hidden",
        }}
        open={showDocumentPopup}
        setOpen={setShowDocumentPopup}
        sxInternalBox={{
          width: "1156px",
          height: "788px",
          overflowX: "hidden",
          [theme.breakpoints.down("lg")]: {
            width: "100%",
            height: "auto",
          },
          [theme.breakpoints.down("sm")]: {
            padding: "20px",
          },
        }}
        title={documentViewHandlers?.title}
        data-testid="deal-document-view-pop-up"
      >
        <OverlayContentBox>
          <Box maxWidth="100%">
            <FileForm autoComplete={"off"}>
              <FieldsBox>
                <DeededLabel title={"File Name"} />
                <DeededInput
                  error={errors.fileName as string}
                  value={values.fileName}
                  onChange={handleChangeFileName}
                />
                <DeededSpace y={errors?.fileName ? 2 : 21} />
                <DeededLabel title={"Folder Name"} />
                <DeededAutocompleteSelect
                  error={errors.folderName as string}
                  onChangeValue={
                    handleChangeFolder as unknown as (value: unknown) => void
                  }
                  options={dealTypeFolders}
                  value={values.folderName}
                  showListDefault={true}
                  data-testid="deal-document-view-folder-name-field"
                />
                <DeededSpace y={errors?.folderName ? 2 : 21} />
                <DeededLabel title={"Comment"} />
                <Box>
                  <DeededTextArea
                    error={errors.comment as string}
                    height={100}
                    text={values.comment}
                    setText={(text) => {
                      if (text.length >= 150) {
                        setErrors({
                          ...errors,
                          comment:
                            "Comment length should be less than 150 symbols",
                        });
                      } else if (text.length < 150) {
                        setErrors({
                          ...errors,
                          comment: undefined,
                        });
                        handleChangeComment(text);
                      }
                    }}
                  />
                  {errors.comment && <DeededSpace y={19} />}
                </Box>
                <DeededSpace y={errors?.comment ? 2 : 21} />
                <DeededLabel title={"Reason for Rejection"} />
                <Box>
                  <DeededTextArea
                    error={errors.rejectionReason as string}
                    height={100}
                    text={values.rejectionReason}
                    data-testid="deal-document-view-rejection-reason-input"
                    inputProps={{ maxLength: 600 }}
                    setText={(text) => {
                      if (text) setFieldError('rejectionReason', undefined);
                      handleChangeRejectionReason(text)
                    }}
                  />
                </Box>
                  {errors.rejectionReason && <DeededSpace y={19} />}
                <DeededSpace y={2} />
                {documentUpdates && (
                  <UpdatesList>
                    {documentUpdates.map(
                      (
                        document: {
                          created_at: Date;
                          action: string;
                          user?: {full_name?: string};
                        },
                        idx: number,
                      ) => {
                        const {created_at, action} = document;
                        const name = document?.user?.full_name ?? "N/A";

                        return (
                          <li key={idx}>
                            <span>
                              {" "}
                              {action} by {name}
                            </span>
                            <span>
                              {new Date(created_at).toLocaleString("en-US")}
                            </span>
                          </li>
                        );
                      },
                    )}
                  </UpdatesList>
                )}
              </FieldsBox>
              <ButtonsBox>
                {!["Pending", "Approved"].includes(selectedDocument?.status) && (
                  <DeededButton
                    sx={{
                      marginRight: "14px",

                      ...svgStyles(
                        "8px",
                        constants.colors.red,
                        constants.colors.white,
                      ),
                    }}
                    onClick={() => {
                      onDelete();
                    }}
                    kind="secondary"
                  >
                    <DeleteSvg />
                    Delete
                  </DeededButton>
                )}
                <DeededButton
                  data-testid="deal-document-view-reject-button"
                  sx={{
                    marginRight: "14px",
                    ...svgStyles(
                      "8px",
                      constants.colors.red,
                      constants.colors.white,
                    ),
                  }}
                  onClick={() => {
                    onSubmitRejection();
                  }}
                  kind={"secondary"}
                >
                  <RejectSvg />
                  Reject
                </DeededButton>
                <DeededButton
                  data-testid="deal-document-view-approve-button"
                  sx={{
                    ...svgStyles(
                      "11px",
                      constants.colors.white,
                      constants.colors.red,
                    ),
                  }}
                  onClick={
                    handleSubmit as unknown as MouseEventHandler<HTMLButtonElement>
                  }

                >
                  <ApproveSvg />
                  Approve
                </DeededButton>
              </ButtonsBox>
            </FileForm>
          </Box>
          <Box sx={{maxWidth: "100%"}}>
            <DocumentBox>
              {!isImage && (
                <DocumentFrameDisplay fileData={fileData} type={documentType} />
              )}
              {isImage && (
                <>
                  {fileData ? (
                    <ReactPanZoom image={fileData} alt="Image alt text" />
                  ) : (
                    <DeededCircularLoader />
                  )}
                </>
              )}
            </DocumentBox>
            <UnderDocumentBtnsBox>
              <UnderDocumentBtn
                onClick={documentDownloadProcess.load}
              >
                <DownloadSvg />
                {documentDownloadProcess.isDocumentLoading
                  ? "Loading..."
                  : "Download"}
              </UnderDocumentBtn>
            </UnderDocumentBtnsBox>
          </Box>
        </OverlayContentBox>
      </DeededPopup>
    </>
  );
};
const svgStyles = (
  marginRight: string,
  basicColor: string,
  hoverColor: string,
) => ({
  "& svg": {
    marginRight: marginRight,
    stroke: basicColor,
    transition: "stroke 300ms",
  },
  "&:hover": {
    "& svg": {
      stroke: hoverColor,
      transition: "stroke 300ms",
    },
  },
});
const FieldsBox = styled(Stack)(({theme}) => ({
  "& .MuiFormControl-root": {
    width: "100%",
  },
  paddingTop: "27px",
  paddingBottom: "27px",
  width: "486px",
  maxHeight: "638px",
  overflowX: "visible",
  maxWidth: "100%",
  [theme.breakpoints.up("lg")]: {
    height: "638px",
  },
}));

const ButtonsBox = styled(Box)(({theme}) => ({
  display: "flex",
  justifyContent: "space-between",
  [theme.breakpoints.down(450)]: {
    marginTop: "24px",
    "& button svg": {
      display: "none",
    },
  },
}));

const UpdatesList = styled("ul")(({theme}) => ({
  flex: 1,
  listStyle: "none",
  overflowY: "auto",
  paddingRight: "0px",
  "&::-webkit-scrollbar": {width: "3px", scrollbarWidth: "thin"},
  "&::-webkit-scrollbar-track": {
    WebkitBorderRadius: "10px",
    borderRadius: "10px",
    padding: "5px",
    background: "var(--white)",
  },
  "&::-webkit-scrollbar-thumb": {
    WebkitBorderRadius: "10px",
    borderRadius: "10px",
    background: "rgba(193, 193, 193, 0.51)",
  },
  "&::-webkit-scrollbar-thumb:window-inactive": {
    background: "rgba(193, 193, 193, 0.51)",
  },
  "& li": {
    fontFamily: "'Montserrat'",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "20px",
    display: "flex",
    width: "100%",
    alignItems: "center",
    borderRadius: "5px",
    background: constants.colors.silver2,
    padding: "12px 16px",
    margin: "0.5rem 0",
    "span:nth-of-type(2)": {marginLeft: "1rem"},
    color: constants.colors.title,
    [theme.breakpoints.down(450)]: {
      fontSize: "14px",
    },
  },
}));
const UnderDocumentBtn = styled(DeededButton)({
  ...svgStyles("11px", constants.colors.white, constants.colors.red),
  width: "150px",
});
const UnderDocumentBtnsBox = styled(Box)<{isImage?: boolean}>(({isImage}) => ({
  display: "flex",
  justifyContent: isImage ? "space-between" : "center",
  marginTop: "24px",
}));

const DocumentBox = styled(Box)(({theme}) => ({
  position: "relative",
  overflow: "hidden",
  width: "563px",
  height: "613px",
  background: constants.colors.silver,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const FileForm = styled("form")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  width: "486px",
  maxWidth: "100%",
});

const OverlayContentBox = styled(Box)(({theme}) => ({
  display: "flex",
  justifyContent: "space-between",
  marginTop: "20px",
  [theme.breakpoints.down("lg")]: {
    flexWrap: "wrap",
    flexDirection: "column-reverse",
  },
}));

export default DealOverlayDocumentViewPopup;
