import {useMemo, useState} from "react";
import {useSnackbar} from "notistack";
import configuration from "utils/configuration";
import {createDealRequestedDocs} from "api";
import {AdditionalDocument} from "./AdditionalDocuments";
import {CustomDocForm} from "./CustomFileForm";

const useAdditionalDocuments = (
  dealID: string,
  dealType: string,
  documentTypes: AdditionalDocument[],
) => {
  const {enqueueSnackbar} = useSnackbar();
  const [additionalFiles, setAdditionalFiles] = useState<AdditionalDocument[]>(
    [],
  );
  const [showCustomFile, setShowCustomFile] = useState(false);
  const [search, setSearch] = useState("");

  const filteredDocument = useMemo(() => {
    let documents;

    if (search.length > 0) {
      const normalizedSearch = search.toLowerCase().replaceAll(" ", "").trim();

      documents = documentTypes.filter((doc) => {
        if (!(doc.uploader === "client" || doc.uploader === "any")) {
          return false;
        }
        return (
          doc.document_type
            .toLowerCase()
            .replaceAll(" ", "")
            .indexOf(normalizedSearch) > -1
        );
      });
    } else {
      documents = documentTypes.filter(
        (doc) => doc.uploader === "client" || doc.uploader === "any",
      );
    }

    if (!documents) {
      return null;
    }

    const otherOption = documents.findIndex(
      (doc) => doc.document_type === "Other (Custom Name)",
    );
    if (otherOption > -1) {
      documents = [
        ...documents.slice(0, otherOption),
        ...documents.slice(otherOption + 1),
        documents[otherOption],
      ];
    }

    return documents;
  }, [search, dealType, documentTypes]);

  const handleAdditionalFiles = (value: string) => {
    if (value === "Other (Custom Name)") {
      setShowCustomFile(!showCustomFile);
      setAdditionalFiles((prev) =>
        prev.filter(
          (el) =>
            documentTypes.findIndex(
              (type) => type.document_type === el.document_type,
            ) > -1,
        ),
      );
      return;
    }

    if (additionalFiles.find((el) => el.document_type === value)) {
      setAdditionalFiles((prev) =>
        prev.filter((el) => el.document_type !== value),
      );
      return;
    }
    setAdditionalFiles((prev) => [
      ...prev,
      {
        document_type: value,
      },
    ]);
  };

  const handleSubmit = (customFile: CustomDocForm, callback: () => void) => {
    if (additionalFiles.length === 0 && !showCustomFile) {
      callback();
      return;
    }

    const payload = {
      requested_documents: additionalFiles,
    };

    if (showCustomFile && customFile) {
      payload.requested_documents = [...additionalFiles, customFile];
    }

    createDealRequestedDocs(dealID, payload)
      .then(() => {
        enqueueSnackbar("Requesting documents", {
          variant: "success",
          autoHideDuration: 1500,
        });
        callback();
      })
      .catch((error) =>
        enqueueSnackbar(error.response.data.error, {
          variant: "error",
          autoHideDuration: configuration.autoHideErrorDuration,
        }),
      );
  };

  return {
    filteredDocument,
    handleSubmit,
    handleAdditionalFiles,
    showCustomFile,
    search,
    setSearch,
    additionalFiles,
  } as const;
};

export default useAdditionalDocuments;
