import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {Box, styled, Theme, Typography, useTheme} from "@mui/material";
import DeededCheckbox from "v2/components/DeededCheckbox";
import DeededButton from "v2/components/DeededButton";
import DeededSearch from "v2/components/DeededSearch";
import constants from "styles/constants";
import useUser from "utils/hooks/useUser";
import useTypedSelector from "utils/hooks/useTypedSelector";
import resetStorage from "utils/resetStorage";
import {UserRoleType} from "types/user";
import CloseDeededSvg from "components/Svg/CloseDeededSvg";
import { professionals } from "components/Dashboard/DealDetails/utils";
import {setSelectedProfForDealsAC} from "redux/reducers/configReducer/profReducer";
import { getConveyanceStatus } from "redux/reducers/dealsReducer";
import DealsListBase from "./components/DealsListBase";
import DealsListHeader from "./components/DealsListHeader";

enum FilterType {
  Archived,
  Closed,
  New,
}

const DealsList = () => {
  const dispatch = useDispatch();
  const [showClosed, setShowClosed] = useState(false);
  const [showArchived, setShowArchived] = useState(false);
  const [showNew, setShowNew] = useState(false);
  const [resetPage, setResetPage] = useState(false);
  const theme = useTheme();
  const user = useUser().user;
  resetStorage();

  const handleCheckBoxes = (type: FilterType) => {
    switch (type) {
      case FilterType.Closed: {
        setResetPage(true);
        setShowClosed(!showClosed);
        setShowArchived(false);
        setShowNew(false);
        break;
      }
      case FilterType.Archived: {
        setResetPage(true);
        setShowClosed(false);
        setShowArchived(!showArchived);
        setShowNew(false);
        break;
      }
      case FilterType.New: {
        setResetPage(true);
        setShowClosed(false);
        setShowArchived(false);
        setShowNew(!showNew);
        break;
      }
    }
  };

  const [search, setSearch] = useState<string | undefined>(undefined);
  const [searchVal, setSearchVal] = useState<string | undefined>(undefined);

  const query = useTypedSelector(
    (state) => state.DealsReducer.dealsListInputQuery,
  );
  const queryMismatch =
    typeof query?.searchQuery === "string" && query?.searchQuery !== searchVal;

  // Check conveyance health
  useEffect(() => {
    dispatch(getConveyanceStatus());
  }, []);

  useEffect(() => {
    if (!queryMismatch && search !== undefined) {
      setSearchVal(search);
    }
  }, [search, queryMismatch]);

  const handleSearch = (val: string) => {
    setSearch(val);
  };

  const {selectedProfForDeals} = useTypedSelector(
    (state) => state.ConfigReducer.ProfReducer,
  );

  const handleDeleteActiveDeal = () => {
    dispatch(setSelectedProfForDealsAC(null));
  };

  const handleClickSearch = () => {
    if (!queryMismatch) {
      setSearchVal(search);
    }
  };

  return (
    <DealsPage>
      <DealsListHeader title="Transactions" />

      <WrapperBox>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            [theme.breakpoints.down("sm")]: {
              justifyContent: "space-between",
            },
          }}
        >
          <DeededSearch
            onChange={
              handleSearch as unknown as ((val: string) => void) &
                React.FormEventHandler<HTMLDivElement>
            }
            placeholder="Search transactions..."
            sx={{
              maxWidth: 567,
              width: 380,
              [theme.breakpoints.down(400)]: {
                width: "70%",
                minWidth: "70%",
              },
            }}
            value={search}
            data-testid="search-field"
          />
        </Box>
        {user.role !== "Client" && (
          <Checkboxes>
            {!professionals.includes(user.role as UserRoleType) && (
              <CheckboxWrapper>
                <DeededCheckbox
                  checked={showNew}
                  onChange={() => {
                    handleCheckBoxes(FilterType.New);
                  }}
                  data-testid='new-deals-checkbox'
                />
                <CheckboxLabel theme={theme}>Unactioned</CheckboxLabel>
              </CheckboxWrapper>
            )}

            <CheckboxWrapper>
              <DeededCheckbox
                checked={showClosed}
                onChange={() => {
                  handleCheckBoxes(FilterType.Closed);
                }}
                data-testid='closed-deals-checkbox'
              />
              <CheckboxLabel theme={theme}>Closed</CheckboxLabel>
            </CheckboxWrapper>
            {(user.role === "Admin" ||
              user.role === "System Admin" ||
              user.role === "Company Admin" ||
              user.role === "Law Clerk"
              ) && (
              <CheckboxWrapper>
                <DeededCheckbox
                  checked={showArchived}
                  onChange={() => {
                    handleCheckBoxes(FilterType.Archived);
                  }}
                  data-testid='archived-deals-checkbox'
                />
                <CheckboxLabel theme={theme}>Archived</CheckboxLabel>
              </CheckboxWrapper>
            )}
          </Checkboxes>
        )}
      </WrapperBox>

      {selectedProfForDeals && (
        <ActiveDeal>
          <div className="name">{`${selectedProfForDeals.first_name} ${selectedProfForDeals.last_name}`}</div>{" "}
          <div className="iconWrapper" onClick={handleDeleteActiveDeal}>
            <CloseDeededSvg />
          </div>
        </ActiveDeal>
      )}

      <DealsListBase
        filterProps={{showClosed, newDeals: showNew, archive: showArchived}}
        status={true}
        resetPage={resetPage}
        setResetPage={setResetPage}
        searchVal={searchVal}
      />
    </DealsPage>
  );
};

const ActiveDeal = styled(Box)({
  margin: "24px 0 8px",
  display: "inline-flex",
  alignItems: "center",
  background: constants.colors.grayTag,
  padding: "0 6px 0 12px",
  borderRadius: "4px",
  div: {
    "&.name": {
      fontSize: "16px",
      lineHeight: "28px",
      color: constants.colors.title,
    },
    "&.iconWrapper": {
      transition: constants.transitions,
      borderRadius: "50%",
      marginTop: "4px",
      marginLeft: "6px",
      opacity: 0.4,
      svg: {
        width: "16px",
        height: "16px",
      },
      "&:hover": {
        opacity: 1,
        cursor: "pointer",
        transition: constants.transitions,
      },
    },
  },
});

const DealsPage = styled(Box)(({theme}) => ({
  background: "#F7FAFE",
  minHeight: "calc(100vh - 60px)",
  padding: "23px 22px 17px 22px",
  [theme.breakpoints.down(814)]: {
    padding: "23px 22px 86px 22px",
  },
}));

const WrapperBox = styled(Box)(({theme}) => ({
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  [theme.breakpoints.down("sm")]: {
    display: "block",
    marginBottom: "15px",
  },
}));
const Checkboxes = styled(Box)(({theme}) => ({
  display: "flex",
  justifyContent: "flex-end",
  [theme.breakpoints.down("sm")]: {
    justifyContent: "center",
  },
  [theme.breakpoints.down(400)]: {
    marginLeft: "-16px",
  },
}));
const CheckboxWrapper = styled(Box)({
  display: "flex",
  alignItems: "center",
});
const CheckboxLabel = styled(Typography)<{theme: Theme}>(({theme}) => ({
  fontSize: "16px",
  fontWeight: "500",
  fontFamily: "Montserrat",
  marginRight: "16px",
  color: constants.colors.intakeTitle,
  [theme.breakpoints.down(1440)]: {
    fontSize: "14px",
  },
  [theme.breakpoints.down(1355)]: {
    fontSize: "12px",
  },
  [theme.breakpoints.down(1225)]: {
    fontSize: "10px",
    marginRight: "10px",
  },
  [theme.breakpoints.down(400)]: {
    marginRight: "0px",
  },
}));

export default DealsList;
