import {useState, useRef, useEffect} from "react";
import {
  FormSubtitle,
  InputField,
  DatePicker,
  TextArea,
  DatePickerContaiener,
  CheckBox,
  Separator,
  UploadButton,
} from "../../GenericComponents";
import {ReusableSelect, provincesList} from "../../../../utils/reusableSelect";
import {PostalCodeValidator} from "../../PostalCodeValidator/PostalCodeValidator";
import {dateFormat} from "./dateFormat";
import {EditCardTitleDescription} from "../ClientIntakeEditCard";
import {useRadios, useDidUpdateEffect} from "./hooks-helpers";
import {useSnackbar} from "notistack";
import DocumentView from "../../DocumentView";
import {useDispatch, useSelector} from "react-redux";
import {setPreferredProvinceAC} from "../../../../redux/reducers/dealsReducer";
import DeededSelect from "v2/components/DeededSelect_DEPRECATED";
import DeededDatePicker from "v2/components/DeededDatePicker";
import {MenuItem} from "@mui/material";
import moment from "moment/moment";
import { PropertyTypes } from "types/intakeForm";
import configuration from "../../../../utils/configuration";

const defaultNumberFormat = "###-###-####";

/**
 * A generic card update form component
 *
 * @callback CardEditFormComponent
 * @param {Object} props
 * @param {any} props.value
 * @param {any} props.onChanged - updates the incoming value
 * @param {Function?} props.onClose An optional close modal parameter, generally used in forms without Save/Cancel buttons
 */

/**
 * This one is a helper to quickly layout forms
 *
 * @param {Object} props
 * @param {string|number} props.height
 */
export function VerticalSpacer({height}) {
  return <div style={{height}} />;
}

export function NumberOfPeopleRadios({value, onChanged, titles}) {
  return useRadios([1, 2, 3], titles, value, onChanged)();
}

/** @type {CardEditFormComponent} */
export function NumberOfSellersRender({value, onChanged}) {
  return (
    <NumberOfPeopleRadios
      value={value}
      onChanged={onChanged}
      titles={["One seller", "Two sellers", "Three sellers"]}
    />
  );
}

/** @type {CardEditFormComponent} */
export function NumberOfSellersOwnersRender({value, onChanged}) {
  return (
    <NumberOfPeopleRadios
      value={value}
      onChanged={onChanged}
      titles={["One owner", "Two owners", "Three owners"]}
    />
  );
}

/**
 *
 * @typedef {Object} FullNameStruct
 * @property {string} namePrefix
 * @property {string} firstName
 * @property {string} lastName
 * @property {string} middleName
 */
/** @type {CardEditFormComponent} */
export function EmailRender({value, onChanged}) {
  return (
    <>
      <InputField
        value={value}
        title="Email"
        onChange={(v) => {
          return onChanged(v);
        }}
      />
    </>
  );
}

export function FullNameRender({value, onChanged}) {
  /** @type {FullNameStruct} */
  // It appears the database value is stored as a concatenated string, so it's not possible
  // to use the form as in the design document
  // Use an Input field for now
  //
  // Update. I was wrong, the database actually stores deconstructed name
  // fields: name_prefix
  // last_name
  // first_name
  const valueT = value;
  const prefixRadios = useRadios(
    ["Mr", "Ms", "Mrs", "Dr"],
    ["Mr.", "Ms.", "Mrs.", "Dr."],
    valueT.namePrefix,
    (v) => onChanged({...valueT, namePrefix: v}),
    null,
    true,
  );

  return (
    <>
      <TwoColumnLayout>
        <LayoutColumn>
          <FormSubtitle>Prefix:</FormSubtitle>
          <VerticalSpacer height={15} />
          {prefixRadios()}
        </LayoutColumn>
      </TwoColumnLayout>
      <TwoColumnLayout>
        <LayoutColumn>
          <InputField
            value={valueT.firstName}
            title="First Name"
            onChange={(v) => onChanged({...valueT, firstName: v})}
          />
        </LayoutColumn>
        <InputField
          value={valueT.middleName}
          title="Middle Name"
          onChange={(v) => onChanged({...valueT, middleName: v})}
        />
      </TwoColumnLayout>
      <InputField
        value={valueT.lastName}
        title="Last Name"
        onChange={(v) => onChanged({...valueT, lastName: v})}
      />
    </>
  );
}

/** @type {CardEditFormComponent} */
export function YesNoRadios({value, onChanged}) {
  // Yes now may come as text or an 1/0 value
  let variants = ["Yes", "No"];
  if (typeof value === "number") {
    variants = [1, 0];
    // Oh well...
  } else if (typeof value === "string" && !variants.includes(value)) {
    variants = ["1", "0"];
  }
  return useRadios(variants, ["Yes", "No"], value, onChanged)();
}

/** @type {CardEditFormComponent} */
export function DateBirthRender({value, onChanged}) {
  // Determing format
  // At the first glance the date format stored in DB is arbitrary
  // I've found date stored in dd/mm/yyyy
  let format = "mm/dd/yyyy";
  if (value.split(".").length > 1) {
    format = "dd.mm.yyyy";
  }

  return (
    <DatePicker
      locale="en-Ca"
      date={value}
      index={0}
      onDateChanged={(value, index) => {
        // Couldn't find the format
        onChanged(dateFormat(value, format));
      }}
    />
  );
}

/**
 * Date picker with time is used for signing date field and scheduled closing date
 * The implementation is generalized for specifying the field title
 *
 * @param {Object} props
 * @param {string?} props.title
 * @param {string} props.value
 * @param {any} props.onChanged
 * @param {any} props.greyOutRange
 */
export function ParsedDataPicker({
  value,
  onChanged,
  title,
  greyOutRange = false,
}) {
  const [parsedDate, setParsedDate] = useState(Date.parse(value) - 7200000);

  useDidUpdateEffect(() => {
    const outgoingValue = (
      parsedDate ? new Date(parsedDate) : new Date()
    ).toISOString();
    onChanged(outgoingValue);
  }, [parsedDate]);

  useEffect(() => {
    if (parsedDate) {
      const outgoingValue = new Date(parsedDate).toISOString();
      onChanged(outgoingValue);
    }
    /**
     * We are not sure if onChanged is a hooked callback
     * Expect bugs...
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <DatePickerContaiener title={title} withTime>
      <DeededDatePicker
        minDate={greyOutRange ? new Date() : undefined}
        dateStart={parsedDate}
        onChange={(date) => setParsedDate(date)}
        format="MMMM d, yyyy h:mm aa"
      />
    </DatePickerContaiener>
  );
}

/** @type {CardEditFormComponent} */
export function SigningAppointmentRender({value, onChanged}) {
  return (
    <ParsedDataPicker value={value} onChanged={onChanged} greyOutRange={true} />
  );
}

/**
 *
 * @typedef {Object} SigningAppointmentStruct
 * @property {number} internet
 * @property {string} preferredTime
 */

/** @type {CardEditFormComponent} */
export function SigningAppointmentExtended({value, onChanged, ...props}) {
  /** @type {SigningAppointmentStruct} */
  const {preferred_province, preferred_time} = useSelector(
    (state) => state.DealsReducer,
  );
  let signingAppointment;
  if (value?.preferredTime) {
    signingAppointment = value;
  } else {
    signingAppointment = {
      preferredTime: moment(Date.parse(preferred_time) + 7200000).format(),
    };
  }
  const dispatch = useDispatch();
  return (
    <>
      <div>
        <SigningAppointmentRender
          value={signingAppointment?.preferredTime}
          onChanged={(time) =>
            onChanged({...signingAppointment, preferredTime: time})
          }
        />
        <DeededSelect
          sx={{
            width: 262,
            height: 45,
            fontFamily: "Montserrat",
            fontSize: 14,
            fontWeight: 500,
            /* identical to box height */
            /* Main / Black */
            color: "#010101",
          }}
          value={preferred_province}
          onChange={(e) => {
            dispatch(setPreferredProvinceAC(e.target.value));
          }}
          displayEmpty
          inputProps={{"aria-label": "Without label"}}
        >
          <MenuItem value={"AB"}>Alberta (AB)</MenuItem>
          <MenuItem value={"BC"}>British Columbia (BC)</MenuItem>
          <MenuItem value={"MB"}>Manitoba (MB)</MenuItem>
          <MenuItem value={"NB"}>New Brunswick (NB)</MenuItem>
          <MenuItem value={"NL"}>Newfoundland & Labrador (NL)</MenuItem>
          <MenuItem value={"NT"}>Northwest Territories (NT)</MenuItem>
          <MenuItem value={"NS"}>Nova Scotia (NS)</MenuItem>
          <MenuItem value={"NU"}>Nunavut (NU)</MenuItem>
          <MenuItem value={"ON"}>Ontario (ON)</MenuItem>
          <MenuItem value={"PE"}>Prince Edward Island (PE)</MenuItem>
          <MenuItem value={"QC"}>Quebec (QC)</MenuItem>
          <MenuItem value={"SK"}>Saskatchewan (SK)</MenuItem>
          <MenuItem value={"YT"}>Yukon(YT)</MenuItem>
        </DeededSelect>
      </div>
      <VerticalSpacer height={22} />
      {/* <Heading size={2}>
        Do you have access to a computer, tablet or mobile phone with a
        high-speed internet connection?
      </Heading>
      <p>
        To attend an online video signing all participants must have access to a
        device with a webcam (example: laptop, tablet), a touch-screen mobile
        phone, and an internet connection.
      </p> */}
      <VerticalSpacer height={22} />
      {/* <YesNoRadios
        value={signingAppointment.internet}
        onChanged={(i) => onChanged({ ...signingAppointment, internet: i })}
      /> */}
      <VerticalSpacer height={36} />
    </>
  );
}

/** @type {CardEditFormComponent} */
export function EmploymentStatusRender({value, onChanged}) {
  return useRadios(
    ["employed", "not employed", "retired"],
    ["Employed", "Not employed", "Retired"],
    value.toLowerCase(),
    onChanged,
  )();
}

/** @type {CardEditFormComponent} */
export function SpousalStatusRender({value, onChanged}) {
  const {spouseName, spousalStatus} = value;

  const titles = [
    "Married",
    "Not married",
    "Not a spouse",
    "Common Law Spouse",
    "Separated",
  ];
  const radioControls = useRadios(
    titles.map((x) => x.toLowerCase()),
    titles,
    value.spousalStatus.toLowerCase(),
    (newSposalStatus) =>
      onChanged({
        ...value,
        spousalStatus: newSposalStatus,
      }),
  );

  return (
    <div style={{display: "flex"}}>
      <div>{radioControls()}</div>
      {spousalStatus.toLowerCase() === "married" && (
        <InputField
          width="191px"
          name="spousalname"
          title="Spouse Name"
          value={spouseName}
          onChange={(newSpouseName) =>
            onChanged({...value, spouseName: newSpouseName})
          }
        />
      )}
    </div>
  );
}

/**
 * Column has a margin right
 * @param {Object} props
 * @param {JSX.Element|JSX.Element[]} props.children
 */
export function LayoutColumn({children}) {
  return <div className="lyt_clmn">{children}</div>;
}

/**
 * A basic two-column layout for input fields
 * @param {Object} props
 * @param {JSX.Element|JSX.Element[]} props.children
 */
export function TwoColumnLayout({children}) {
  return <div className="two_column_lyt">{children}</div>;
}

/**
 *
 * @typedef {Object} AddressStruct
 * @property {string} address
 * @property {string} city
 * @property {string} code
 * @property {string} created_at
 * @property {number} id
 * @property {string} state
 * @property {string} [unit]
 * @property {string} updated_at
 */

/** @type {CardEditFormComponent} */
export function AddressForm({value, onChanged}) {
  /** @type AddressStruct */
  const valueT = value;
  // const marginBottomStyle = { marginBottom: "16px" };
  const inputWidth = "191px";
  const showUnitField = "unit" in valueT && true;
  return (
    <div>
      <TextArea
        title="Address"
        value={valueT.address}
        onChange={(addr) => onChanged({...valueT, address: addr})}
        name="address"
        placeHolder="e.g. 37 Jaden Mountain, apr. 2"
      />
      <TwoColumnLayout>
        {showUnitField ? (
          <LayoutColumn>
            <InputField
              width={inputWidth}
              name="unit"
              title="Unit"
              value={valueT.unit}
              placeHolder="e.g. 10"
              onChange={(unit) => onChanged({...valueT, unit: unit})}
            />
          </LayoutColumn>
        ) : null}
        <InputField
          width={inputWidth}
          name="city"
          title="City"
          value={valueT.city}
          placeHolder="e.g. Ottawa"
          onChange={(city) => onChanged({...valueT, city: city})}
        />
      </TwoColumnLayout>

      <TwoColumnLayout>
        <div style={{width: "191px", marginRight: "24px"}}>
          <ReusableSelect
            options={provincesList}
            defaultValue={{
              label: valueT.state,
              value: valueT.state,
            }}
            onChangeHandler={(state) =>
              onChanged({...valueT, state: state.value})
            }
          />
        </div>
        <PostalCodeValidator
          defaultValue={valueT.code}
          handlePostalCode={(code) => onChanged({...valueT, code: code})}
        />
      </TwoColumnLayout>
    </div>
  );
}
/** @type {CardEditFormComponent} */
export function ResidedAddressForm({value, onChanged}) {
  return null;
}

/** @type {CardEditFormComponent} */
export function PhoneNumberRender({value, onChanged}) {
  return (
    <>
      <InputField
        numberFormat={defaultNumberFormat}
        value={value}
        onChange={onChanged}
        name="phone"
        title="Mobile phone number"
      />
    </>
  );
}
/** @type {CardEditFormComponent} */
export function InsuranceNumberRender({value, onChanged}) {
  return (
    <>
      <InputField
        numberFormat={"###-###-###"}
        value={value}
        onChange={onChanged}
        name="insurance"
        title="Social Insurance Number"
      />
    </>
  );
}

/** @type {CardEditFormComponent} */
export function NameOfEmployerRender({value, onChanged}) {
  return (
    <TextArea
      value={value || ""}
      title="Name of Employer"
      onChange={onChanged}
      name="name_of_employer"
      placeHolder="e.g. «KPMG»"
    />
  );
}

/** @type {CardEditFormComponent} */
export function TitleOfPropertyRender({ value, onChanged }) {
  const isCommonTenancy = (value) => value === "Tenancy in Common";
  const isOther = (value) => value === "Other";
  const onRadioChange = (title_property) => {
    return onChanged({
      ...value, title_property,
      purchasers: value.purchasers.map((ps) => ({ ...ps, ownerhip_share: null})),
      other_comments: null
    })
  };
  const onInputChange = (ownerhip_share, client, idx) => {
    const purchasers = [...value.purchasers];
    purchasers[idx] = { ...client, ownerhip_share };
    onChanged({ ...value, purchasers })
  };
  const handleAddComment = (text) => {
    onChanged({ ...value, other_comments: text });
  };
  const options = ["Sole Owner", "Joint Tenancy", "Tenancy in Common", "Other"];
  const Radio = useRadios(options, options, value.title_property, onRadioChange, [
    "Means ownership by an individual or entity legally capable of holding the title. Common for singles.",
    "Means owning equal shares of the property.  Common for anyone purchasing with a spouse or partner. When one of the joint tenants passes away, the remaining tenant(s) inherits the deceased tenant’s interest in the property.",
    "Means each owner may have equal or different ownership shares in the property. As a result, one party may sell their share without the permission of others. In this type of co-ownership, there can be more than two owners, and the owners may sell their portion of the property to anyone, unless stipulations or restrictions are built into the ownership contract.",
  ]);
  return <>
    <Radio />
    {isCommonTenancy(value.title_property) && <div style={{ marginLeft: "3.5rem" }}>
      <hr style={{marginTop:"1rem", marginBottom: "2rem"}}/>
      <FormSubtitle>Please identify each party's ownership interest in the property</FormSubtitle>
      <div style={{ display: 'flex', columnGap: '2rem', marginTop: '2rem' }}>
        {value.purchasers.map((client, idx) => {
          return <InputField
            key={idx}
            value={client.ownerhip_share || ''}
            onChange= {(e) => onInputChange(e, client,idx)}
            width="15rem"
            name="share"
            type="number"
            title={client.full_name}
          />
        })
        }
      </div>
    </div>}
    {isOther(value.title_property) && <div style={{ marginLeft: "3.5rem" }}>
      <hr style={{marginTop:"1rem", marginBottom: "2rem"}}/>
      <FormSubtitle>Please specify</FormSubtitle>
      <div style={{ display: 'flex', columnGap: '2rem', marginTop: '1rem' }}>
        <TextArea
          onChange={handleAddComment}
          value={value?.other_comments || ''}
        />
      </div>
    </div>}
  </>
}

/**
 *
 * @typedef {Object} CondominiumStruct
 * @property {string} company
 * @property {string} fees
 */

/** @type {CardEditFormComponent} */
export function SellingCondominiumRender({value, onChanged}) {
  /** @type {CondominiumStruct} */
  const condominium = value;
  return (
    <>
      <InputField
        value={condominium.company}
        onChange={(v) => onChanged({...condominium, company: v})}
        name="prop_management"
        title="Property Management Company"
        placeHolder="e.g. ABC Management"
      />
      <VerticalSpacer height={24} />
      <InputField
        value={condominium.fees}
        onChange={(v) => onChanged({...condominium, fees: v})}
        name="monthly_fees"
        title="Aprox monthly maintenance fees"
        cleaveOptions={{numeral: true, prefix: "$", signBeforePrefix: true}}
        placeHolder="e.g. $250"
      />
      <VerticalSpacer height={64} />
    </>
  );
}

/**
 *
 * @typedef {Object} CitizenshipStruct
 * @property {number} permanentResidentOfCanada
 * @property {number} canadianCitizen
 * @property {any} otherCitizenShip - supposedly this should be string
 */
/** @type {CardEditFormComponent} */
export function CitizenshipStatusRender({value, onChanged}) {
  /** @type {CitizenshipStruct} */
  const citizenship = value;
  const [otherCountryFieldVisible, setOtherCountryFieldVisible] = useState(
    citizenship.otherCitizenShip,
  );

  return (
    <>
      <CheckBox
        title="Canadian Citizen"
        value="canadian_citizen"
        name="canadian_citizen"
        checked={citizenship.canadianCitizen == 1}
        onChange={(checked) =>
          onChanged({...citizenship, canadianCitizen: checked ? 1 : 0})
        }
      />
      <VerticalSpacer height={22} />
      <CheckBox
        title="Permanent Resident of Canada"
        checked={citizenship.permanentResidentOfCanada == 1}
        name="permanent_resident"
        value="permanent_resident"
        onChange={(checked) =>
          onChanged({
            ...citizenship,
            permanentResidentOfCanada: checked ? 1 : 0,
          })
        }
      />
      <VerticalSpacer height={22} />
      <CheckBox
        value="other_citizen"
        checked={otherCountryFieldVisible}
        onChange={(v) => {
          setOtherCountryFieldVisible(v);
          if (!v) {
            onChanged({...citizenship, otherCitizenShip: null});
          }
        }}
        name="other_citizen"
        title="Citizen of Another Country"
      />
      {otherCountryFieldVisible && (
        <>
          <VerticalSpacer height={36} />
          <InputField
            value={citizenship.otherCitizenShip || ""}
            onChange={(o) => onChanged({...citizenship, otherCitizenShip: o})}
            title="Which other Country are you a Citizen of ?"
          />
        </>
      )}
      <VerticalSpacer height={36} />
    </>
  );
}

/**
 *
 * @typedef {Object} LenderRenderStruct
 * @property {string} name
 * @property {string} account
 */
/** @type {CardEditFormComponent} */
export function MortgageLenderRender({value, onChanged}) {
  /** @type {LenderRenderStruct} */
  const lr = value;
  return (
    <>
      <InputField
        value={lr.name}
        onChange={(v) => onChanged({...lr, name: v})}
        name="lenders_name"
        title="Lender’s name"
        placeHolder="e.g. MCAP"
      />
      <VerticalSpacer height={24} />
      <InputField
        value={lr.account}
        onChange={(v) => onChanged({...lr, account: v})}
        name="mortgage_account_number"
        title="Mortgage account number"
        placeHolder="e.g. 111222333"
      />
      <VerticalSpacer height={64} />
    </>
  );
}

/** @type {CardEditFormComponent} */
export function TypeOfPropertyRender({value, onChanged}) {
  const opts = Object.values(PropertyTypes);
  return useRadios(opts, opts, value, onChanged)();
}

/**
 *
 * @typedef {Object} SepticTankWaterWellStruct
 * @property {number} septicTank
 * @property {number} waterWell
 */
/** @type {CardEditFormComponent} */
export function SepticTankWaterWellRender({value, onChanged}) {
  /** @type {SepticTankWaterWellStruct} */
  const septicTankAndWaterWell = value;

  return (
    <>
      <YesNoRadios
        value={septicTankAndWaterWell.septicTank}
        onChanged={(v) => onChanged({...septicTankAndWaterWell, septicTank: v})}
      />
      <Separator />
      <EditCardTitleDescription
        title="Does the property have a water Well?"
        description="Most common in rural properties, a well is a hole drilled into the ground to access water"
      />
      <YesNoRadios
        value={septicTankAndWaterWell.waterWell}
        onChanged={(v) => onChanged({...septicTankAndWaterWell, waterWell: v})}
      />
      <Separator />
      <EditCardTitleDescription
        title="Does the property have a fuel/propane service?"
        description="Generally used for heating"
      />
      <YesNoRadios
        value={septicTankAndWaterWell.fuelTank}
        onChanged={(v) => onChanged({...septicTankAndWaterWell, fuelTank: v})}
      />

      {parseInt(septicTankAndWaterWell.fuelTank) === 1 && (
        <>
          <InputField
            value={septicTankAndWaterWell.fuelProviderName}
            onChange={(v) => onChanged({...septicTankAndWaterWell, fuelProviderName: v})}
            name="fuelProviderName"
            title="Fuel Provider Name"
            placeHolder=""
          />
          <VerticalSpacer height={24} />
          <InputField
            value={septicTankAndWaterWell.fuelProviderPhone}
            onChange={(v) => onChanged({...septicTankAndWaterWell, fuelProviderPhone: v})}
            name="fuelProviderPhone"
            title="Fuel Provider Phone"
            placeHolder=""
          />
        </>
      )}
    </>
  );
}

/** @type {CardEditFormComponent} */
export function PrimaryUsePropertyRender({value, onChanged}) {
  const opts = ["Primary Residence", "Investment Property"];
  return useRadios(opts, opts, value, onChanged)();
}

/**
 *
 * @typedef {Object} InformationLenderRenderStruct
 * @property {string} name
 * @property {string} contactName
 * @property {string} phone
 * @property {string} email
 */
/** @type {CardEditFormComponent} */
export function InformationLenderRender({value, onChanged}) {
  /** @type {InformationLenderRenderStruct} */
  const info = value;

  return (
    <>
      <InputField
        value={info.name}
        onChange={(v) => onChanged({...info, name: v})}
        name="Name"
        title="Name"
        placeHolder="e.g. CIBC, TD, MCAP, etc"
      />
      <VerticalSpacer height={24} />
      <InputField
        value={info.contactName}
        onChange={(v) => onChanged({...info, contactName: v})}
        name="contactName"
        title="Contact name "
        placeHolder="e.g. (example ?)"
      />
      <VerticalSpacer height={24} />
      <InputField
        value={info.phone}
        numberFormat={defaultNumberFormat}
        onChange={(v) => onChanged({...info, phone: v})}
        name="phone"
        title="Lender’s phone number"
        placeHolder="e.g. + 1 613 7162568"
      />
      <VerticalSpacer height={24} />
      <InputField
        value={info.email}
        onChange={(v) => onChanged({...info, email: v})}
        name="Email"
        title="Email"
        placeHolder="e.g. andyconrad@gmail.com"
      />
      <VerticalSpacer height={64} />
    </>
  );
}

/**
 *
 * @typedef {Object} NewMortgageStruct
 * @property {string} name
 * @property {string} branchAddress
 * @property {string} contactName
 * @property {string} contactPhone
 * @property {string} contactEmail
 */
/** @type {CardEditFormComponent} */
export function NewMortgageRender({value, onChanged}) {
  /** @type {NewMortgageStruct} */
  const mort = value;
  return (
    <>
      <TwoColumnLayout>
        <LayoutColumn>
          <InputField
            value={mort.name}
            onChange={(v) => onChanged({...mort, name: v})}
            name="Lenders_name"
            title="Lender’s name"
            placeHolder="e.g. MCAP"
          />
        </LayoutColumn>
        <InputField
          value={mort.branchAddress}
          onChange={(v) => onChanged({...mort, branchAddress: v})}
          name="branch_address"
          title="Branch Address"
          placeHolder="e.g. (example)"
        />
      </TwoColumnLayout>
      <TwoColumnLayout>
        <LayoutColumn>
          <InputField
            value={mort.contactName}
            onChange={(v) => onChanged({...mort, contactName: v})}
            name="contact_name_at_lender"
            title="Contact Name at Lender or Mortgage Broker"
            placeHolder="e.g. (example)"
          />
        </LayoutColumn>
        <InputField
          value={mort.contactPhone}
          numberFormat="###-###-####"
          onChange={(v) => onChanged({...mort, contactPhone: v})}
          name="contact_phone_number"
          title="Contact Phone Number"
          placeHolder="e.g. 111222333"
        />
      </TwoColumnLayout>
      <TwoColumnLayout>
        <LayoutColumn>
          <InputField
            value={mort.contactEmail}
            onChange={(v) => onChanged({...mort, contactEmail: v})}
            name="contact_email"
            title="Contact Email"
            placeHolder="e.g. (example)"
          />
        </LayoutColumn>
      </TwoColumnLayout>
      <VerticalSpacer height={64} />
    </>
  );
}

/** @type {CardEditFormComponent} */
export function ScheduledClosingDateRender({value, onChanged}) {
  return (
    <ParsedDataPicker
      value={value}
      onChanged={onChanged}
      title="Scheduled closing date"
    />
  );
}

/**
 *
 * @param {string} documentType Document type required on server
 * @param {(document:any)=>void} onValueUpdated Once the document is readed localy, notify that the hook owner
 */
const validFilesList = [
  {type: "application/pdf"},
  {type: "text/plain"},
  {type: "image/jpeg"},
  {type: "image/gif"},
  {type: "image/png"},
  {type: "application/msword"},
  {type: "application/rtf"},
  {type: "application/x-zip-compressed"},
  {type: "application/vnd.ms-excel"},
  {
    type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  },
  {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"},
  {type: "text/csv"},
  {type: "image/heic"},
];
const isValidFile = (doc) => {
  const filtered = validFilesList.find((file) => file.type === doc.type);
  if (filtered === undefined) {
    return false;
  }
  return true;
};
function useUploadAgreement(documentType, onValueUpdated) {
  const {enqueueSnackbar} = useSnackbar();
  const fileName = useRef(null);
  const [insurance, setInsurance] = useState(null);

  const onUpload = (event) => {
    let file = event.target.files[0];
    let reader = new FileReader();
    if (!isValidFile(file)) {
      enqueueSnackbar("Not valid file format", {
        variant: "error",
        autoHideDuration: configuration.autoHideErrorDuration,
      });
      return;
    }
    reader.readAsDataURL(file);

    reader.onload = () => {
      setInsurance({
        name: file.name,
        size: file.size,
        type: file.type,
        data: reader.result,
      });
      fileName.current.innerText = "Successfully Uploaded";
    };
    reader.onerror = (error) => {
      enqueueSnackbar(error.response.data.error, {
        variant: "error",
        autoHideDuration: configuration.autoHideErrorDuration,
      });
    };
  };

  useDidUpdateEffect(() => {
    onValueUpdated({
      agreements: [{document_type: documentType, file: insurance}],
    });
  }, [insurance]);

  return {
    onUpload,
    filename: fileName,
  };
}

export function DocumentUpdateForm({
  value,
  onChanged,
  onClose,
  onDataUpdated,
  onRenderDescription,
  buttonTitle,
}) {
  const {documentType, doc} = value;
  const {onUpload, filename} = useUploadAgreement(documentType, (v) =>
    onChanged({
      ...value,
      ...v,
    }),
  );
  if (!doc) {
    return (
      <>
        {onRenderDescription && (
          <>
            {onRenderDescription()}
            <VerticalSpacer height={16} />
          </>
        )}
        <UploadButton
          width={400}
          filenameRef={filename}
          title={buttonTitle || "Upload Document"}
          onUpload={onUpload}
        />
      </>
    );
  } else {
    return (
      <DocumentView
        onUpdated={() => {
          onChanged({...value});
          onDataUpdated();
        }}
        onClose={onClose}
        theDocument={doc}
        showHeader={false}
      />
    );
  }
}

/** @type {CardEditFormComponent} */
export function HomeInsurancePolicyRender(props) {
  return (
    <DocumentUpdateForm
      {...props}
      onRenderDescription={() => (
        <p>
          We’ll reqiure proof of your home insurance in order to close your
          transaction. Your policy must note the exact name of your lender. We
          will inform you a few days prior to closing of the exact lender’s name
          to include on your policy.{" "}
        </p>
      )}
    />
  );
}
