import React from "react";
import moment, {Moment} from "moment";
import {Box, styled, Typography} from "@mui/material";
import {Theme} from "@mui/material/styles";
import constants from "styles/constants";
import useTypedSelector from "utils/hooks/useTypedSelector";
import useWindowWidth from "utils/hooks/useWindowWidth";
import {scrollbarStyles} from "../constants/scrollbarStyles";
import {workingDaysWeek} from "../constants/workingDaysWeek";
import {selectUserClosingsCalendarData} from "../utils/selectUserClosingsCalendarData";
import {
  horizontalPhoneCalendar,
  miniDesktopCalendar,
  smallDesktopCalendar,
} from "../constants/breakpoints";

interface BigCalendarClosingsWeekProps {
  width: string;
  date: Date;
}
const BigCalendarClosingsWeek: React.FC<BigCalendarClosingsWeekProps> = ({
  width,
  date,
}) => {
  const closingsCalendarResultByDates = useTypedSelector(
    (state) => state.CalendarReducer.closingsCalendarResultByDates,
  );
  const windowWidth = useWindowWidth();
  const endWeekDay = moment(date).endOf("week");
  const startWeekDay = moment(date).startOf("week");
  const calendarShowingDaysArray: Moment[] = [];
  let dateForCycle = startWeekDay.clone();
  while (dateForCycle.isBefore(endWeekDay.clone().subtract(1, "day"), "day")) {
    calendarShowingDaysArray.push(
      dateForCycle.add(1, "day").clone() as unknown as Moment,
    );
  }
  return (
    <>
      <BigCalendarClosingsWeekBox
        sx={{
          width:
            windowWidth <= miniDesktopCalendar
              ? width
              : `calc(${width} - 402px)`,
        }}
      >
        {calendarShowingDaysArray.map((day, index) => {
          const lawClerksForDisplay = selectUserClosingsCalendarData(
            "Law Clerk",
            day,
            closingsCalendarResultByDates,
          );
          const lawyersForDisplay = selectUserClosingsCalendarData(
            "Lawyer",
            day,
            closingsCalendarResultByDates,
          );
          return (
              <DayBox key={index}>
                <LawClerksBox>
                  <DayOfWeekTypography>
                    {day.format("D")} {workingDaysWeek[index]}
                  </DayOfWeekTypography>
                  <RoleTypography>Law Clerks</RoleTypography>
                  <CountsBox length={lawClerksForDisplay.length}>
                    {lawClerksForDisplay.length !== 0 &&
                      lawClerksForDisplay.map((lawClerk, index) => (
                        <CountItemBox key={index} index={index}>
                          <CountInfoTypography
                            sx={{
                              width: "120px",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {lawClerk.name}
                          </CountInfoTypography>
                          <CountInfoTypography>
                            {lawClerk.count}
                          </CountInfoTypography>
                        </CountItemBox>
                      ))}
                  </CountsBox>
                  <TotalTypography length={lawClerksForDisplay.length}>
                    Total:{" "}
                    <TotalCount>
                      {lawClerksForDisplay.length !== 0
                        ? lawClerksForDisplay
                            .map((lawClerk) => lawClerk.count)
                            .reduce((sum, currentCount) => sum + currentCount)
                        : 0}
                    </TotalCount>
                  </TotalTypography>
                </LawClerksBox>
                <LawyerBox>
                  <RoleTypography
                    sx={{
                      marginTop: "0px",
                    }}
                  >
                    Lawyer
                  </RoleTypography>
                  <CountsBox length={lawyersForDisplay.length}>
                    {lawyersForDisplay.length !== 0 &&
                      lawyersForDisplay.map((lawyer, index) => (
                        <CountItemBox key={index} index={index}>
                          <CountInfoTypography
                            sx={{
                              width: "120px",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {lawyer.name}
                          </CountInfoTypography>
                          <CountInfoTypography>
                            {lawyer.count}
                          </CountInfoTypography>
                        </CountItemBox>
                      ))}
                  </CountsBox>
                  <TotalTypography length={lawyersForDisplay.length}>
                    Total:{" "}
                    <TotalCount>
                      {lawyersForDisplay.length !== 0
                        ? lawyersForDisplay
                            .map((lawyer) => lawyer.count)
                            .reduce((sum, currentCount) => sum + currentCount)
                        : 0}
                    </TotalCount>
                  </TotalTypography>
                </LawyerBox>
              </DayBox>
          );
        })}
      </BigCalendarClosingsWeekBox>
    </>
  );
};

const TotalCount = styled("span")<{theme?: Theme}>(({theme}) => ({
  color: constants.colors.red,
  marginLeft: "7px",
}));
const TotalTypography = styled(Typography)<{length: number; theme?: Theme}>(
  ({length, theme}) => ({
    fontFamily: "'Montserrat'",
    fontStyle: "normal",
    marginTop: length === 0 ? "4px" : length > 4 ? "11px" : "4px",
    fontWeight: 700,
    fontSize: "12px",
    lineHeight: "15px",
    display: "flex",
    alignItems: "center",
    color: constants.colors.sortingColor,
    [theme.breakpoints.down(horizontalPhoneCalendar)]: {
      fontWeight: 700,
      fontSize: "8px",
    },
  }),
);
const CountItemBox = styled(Box)<{index: number}>(({index}) => ({
  marginTop: index === 0 ? "0px" : "15px",
  display: "flex",
  justifyContent: "space-between",
}));
const CountsBox = styled(Box)<{length: number}>(({length}) => ({
  marginTop: "16px",
  height: length > 4 ? "93px" : "100px",
  paddingRight: length > 4 ? "15px" : "0px",
  overflowY: length > 4 ? "scroll" : "initial",
  ...scrollbarStyles,
}));
const RoleTypography = styled(Typography)<{theme?: Theme}>(({theme}) => ({
  fontFamily: "'Montserrat'",
  fontStyle: "normal",
  marginTop: "12px",
  fontWeight: 700,
  textAlign: "center",
  fontSize: "12px",
  lineHeight: "15px",
  color: constants.colors.red,
  flexDirection: "column",
  [theme.breakpoints.down(smallDesktopCalendar)]: {
    fontSize: "10px",
    whiteSpace: "nowrap",
  },
  [theme.breakpoints.down(miniDesktopCalendar)]: {
    fontSize: "12px",
    whiteSpace: "wrap",
  },
}));
const BigCalendarClosingsWeekBox = styled(Box)<{theme?: Theme}>(({theme}) => ({
  display: "flex",
  justifyContent: "space-between",
  [theme.breakpoints.down(horizontalPhoneCalendar)]: {
    flexDirection: "column",
  },
}));
const DayBox = styled(Box)<{theme?: Theme}>(({theme}) => ({
  width: "17%",
  [theme.breakpoints.down(horizontalPhoneCalendar)]: {
    width: "100%",
    marginBottom: "16px",
  },
}));
const LawClerksBox = styled(Box)<{theme?: Theme}>(({theme}) => ({
  width: "100%",
  height: "202px",
  backgroundColor: constants.colors.white,
  padding: "12px 16px",
}));
const LawyerBox = styled(Box)<{theme?: Theme}>(({theme}) => ({
  marginTop: "16px",
  width: "100%",
  height: "175px",
  backgroundColor: constants.colors.white,
  padding: "12px 16px",
}));
const DayOfWeekTypography = styled(Typography)({
  fontFamily: "'Montserrat'",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "12px",
  lineHeight: "15px",
  textAlign: "center",
  color: constants.colors.sortingColor,
});
const CountInfoTypography = styled(Typography)({
  fontFamily: "'Montserrat'",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "10px",
  lineHeight: "12px",
  color: constants.colors.sortingColor,
});
export default BigCalendarClosingsWeek;
