import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { LogOut } from "redux/actions/auth";
import useUser from "utils/hooks/useUser";
import storage from "utils/storage";

const useLogOut = () => {

    const { user: currentUser } = useUser();
    const dispatch = useDispatch();
    const history = useHistory();

    const handleLogout = () => {
        storage.previousUserId = (currentUser.id || '') as string;
        /** Update when customer portal index is created */
        if (window.location.pathname.includes('portal/onboarding')) {
            let url = window.location.pathname.split('/');
            url.pop();
            storage.targetLink = url.join('/');
        }
        dispatch(LogOut());
        storage.removeUserId();
        storage.removeToken();
        history.replace("/login");
    };

    return handleLogout;
}

export default useLogOut;