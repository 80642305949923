
const TermsOfRefinanceAB = () => {
    return (
        <>
            <p style={{ textAlign: 'center' }}><strong style={{ fontSize: '19pt' }}>LAW FIRM ENGAGEMENT AGREEMENT</strong></p>
            <p>Congratulations on your upcoming real estate refinance or new mortgage and thank you for choosing&nbsp;<strong>Neumann Law&nbsp;</strong>(the &ldquo;Law Firm&rdquo;, &ldquo;we&rdquo;, &ldquo;our&rdquo;, or &ldquo;us&rdquo;) for your legal needs.</p>
            <p>Our contact information is as follows:</p>
            <p>
                Neumann Law <br />
                4620 Manilla Road SE<br />
                Calgary, Alberta T2G 4B7<br />
                Tel: 855.456.4335<br />
                Fax: 403.531.9040<br />
                Email: hello@deeded.ca
            </p>
            <p>Please review this agreement thoroughly. This agreement establishes the lawyer-client relationship, and explains your rights, our obligations, and other key points that you&rsquo;ll need to understand about your transaction, rights and privacy.&nbsp;</p>
            <p>If you do not understand any of the terms or language in this agreement, please contact us and we will gladly explain or answer any questions you may have. You are also welcome to obtain independent legal advice from another lawyer before entering into this agreement.&nbsp;</p>
            <p><strong><u>RELATIONSHIP BETWEEN LAW FIRM AND DEEDED TECHNOLOGY PLATFORM</u></strong></p>
            <p><strong>Neumann Law (the &ldquo;Law Firm&rdquo;) is separate from and operates independently of Deeded Inc., which operates the deeded.ca website and app.Deeded.ca (the &ldquo;Platform&rdquo;).</strong></p>
            <p>To assist you with your real estate transaction, the Law Firm engages Deeded, Inc. as a third-party service provider to leverage its technology Platform, which assists the Law Firm in facilitating your real estate transaction, and for certain administrative support services.</p>
            <p>Electronically accepting this engagement agreement establishes a lawyer-client relationship between you and the Law Firm and confirms that you will receive legal services and legal advice respecting your real estate transaction from the Law Firm.&nbsp;</p>
            <p>The Law Firm independently exercises complete control over, and is solely responsible for, the provision of legal advice, opinions, or recommendations about your legal rights, obligations, remedies, strategies, and options; the selection and completion of forms and other documents; and the application of the law to the facts of your situation.</p>
            <p>Deeded, Inc. is&nbsp;<u>not</u>&nbsp;a party to the lawyer-client relationship and does not provide any legal services or advice. Deeded, Inc. may, however, act as an intermediary between you and the Law Firm or other Business Partners (as that term is defined in Deeded Inc&rsquo;s&nbsp;<a href="https://deeded.ca/terms/"><u>Terms of Use</u></a>&nbsp;and&nbsp;<a href="https://deeded.ca/privacy-policy/"><u>Privacy Policy</u></a>, which govern your relationship with Deeded, Inc. and usage of its Platform).</p>
            <p>Neumann Law may also provide administrative services or assistance to the Law Firm such as administrative support, clerical support, technical support, scheduling support, access to certain software or databases, customer service support, and marketing support. &nbsp;</p>
            <p>In engaging Deeded, Inc. to use the Platform and for the provision of the above-mentioned services, the Law Firm has entered into strict confidentiality and privacy agreements with Deeded, Inc. and its staff, but in rare circumstances the assistance provided by Deeded, Inc. may nevertheless lead to a loss of solicitor-client privilege.</p>
            <p><strong>By entering into this agreement, you confirm that you consent to the Law Firm providing your information&mdash;including your personal information and information concerning the nature and status of your real estate transaction&mdash;to Deeded, Inc. for the purpose of and to the extent necessary to facilitate your real estate transaction by leveraging the Deeded, Inc. Platform and administrative services.&nbsp;</strong>You further confirm that providing this consent does&nbsp;<u>not</u>&nbsp;indicate any intention to waive solicitor-client privilege over your communications with the Law Firm&mdash;it is provided only to enable the Law Firm to access and utilize technology and administrative services that assist in its provision of legal services to you.<strong>&nbsp;</strong></p>
            <p>Deeded, Inc. receives remuneration from the Law Firm for the use of its technology and any administrative services rendered.&nbsp;</p>
            <p>Fees for legal services rendered are paid directly to the Law Firm, per the Law Firm&rsquo;s account provided to you.</p>
            <p>If you have any questions about your fees and payment or the use and disclosure of your information, please do not hesitate to contact us&nbsp;<em>before&nbsp;</em>entering into this agreement.</p>
            <p><strong><u>SCOPE OF SERVICES</u></strong></p>
            <p>During the retainer, we have authority to act for and on behalf of you in connection with matters related to your Real Estate refinancing transaction and to conduct such matters in all respects. Except where you have specifically instructed otherwise, we may act on your behalf and bind you in all necessary and related legal forms and procedures within reason and we will be expected to take all steps reasonably necessary to further and protect your interests.</p>
            <p><strong><u>CLOSING DATE</u></strong></p>
            <p>The closing date of your transaction will be listed in the mortgage documents and may be amended by requesting a change with your lender where they will send our office revised mortgage documents. Your bank representative or mortgage broker should be able to assist with any amendments to the closing in the early stages of your transaction. Please keep our office informed of any delays or changes in the closing date.</p>
            <p><strong><em>Additional Fee for Delays&nbsp;</em></strong></p>
            <p>If any delays are caused by you as a result of any reason in your control (i.e. where you fail to communicate with us, or you fail to provide information in a timely manner), then we reserve the right to charge an additional administrative fee.</p>
            <p><strong><u>RETAINER AT AN END</u></strong></p>
            <p>Upon the completion of the closing of your real estate transaction, our retainer will be at an end. Our services will not include any legal representation or advice with respect to any post-closing matters including but not limited to, litigation or default proceedings. If we are able to assist with post-closing matters, we will provide you with a new engagement/retainer letter along with an estimate of our services for representing you.&nbsp;</p>
            <p><strong><u>FEES AND DISBURSEMENTS</u></strong></p>
            <p><strong>In some real estate transactions, there may be unforeseen issues and/or difficulties that arise as a result of matters beyond our law firm&apos;s control. Neumann Law reserves the right to increase fees to reflect any additional time expended with the completion of your transaction and will contact you to discuss same.</strong></p>
            <p><strong><em>Payment to Neumann Law, in trust&nbsp;</em></strong></p>
            <p>Before your closing date, you may have to provide Neumann Law with sufficient funds by&nbsp;wire transfer or bank draft,&nbsp;deposited to our trust account or wire transfer to complete your transaction that are not covered by your mortgage refinance. Please note that we do not accept any funds in cash, including our retainer. These funds include the amount owing to government agencies or to other lenders plus legal fees, disbursements and adjustments for extra expenses and taxes as outlined above. We will advise you prior to closing of the total funds required.&nbsp;Our fees will be paid in full on the closing date, and once we have submitted your package to Land Titles.&nbsp;</p>
            <p><strong><em>What Are Disbursements?</em></strong></p>
            <p>Disbursements are expenses that your lawyer charges you for out-of-pocket expenses that you would otherwise be required to pay if you handled the transaction yourself. Because your lawyer generally pays these expenses, the lawyer requires you to reimburse them by including these items in their account. Examples of disbursements include but are not limited to postage, deliveries, software fees, trust administration fees, government filing and search charges, and the fees of agents who conduct investigations, searches and registrations and all other reasonable out of pocket expenses.</p>
            <p>The net proceeds are released to you as per your signed direction. The sale proceeds are adjusted for any bank charges/debts to ensure that we have been provided the sufficient amount required for your closing.&nbsp;</p>
            <p><strong><em>Other Charges</em></strong></p>
            <p>You will also be responsible for office charges allocated to your file. &nbsp;These include charges for conveyancer/paralegal time, word processing charges, computer costs, in-house photocopying and faxes.</p>
            <p><strong><em>Taxes</em></strong></p>
            <p>You will be charged the applicable taxes on fees and on some disbursements or other charges.</p>
            <p><strong><em>Cancellation Policy</em></strong></p>
            <p>If for any reason the transaction is cancelled by you, the lender, failure to complete the Client Identification and Verification process or due to a conflict of interest that cannot be resolved, you acknowledge and agree that you will be liable and shall pay the amount of your legal fees and disbursements incurred by Neumann Law as incurred prior to the cancellation.</p>
            <p><strong><em>Cancellation Policy - Private Lender Transactions</em></strong></p>
            <p>Should the transaction be instructed and cancelled/not closed for any reason, a fee of&nbsp;<strong>$295.00</strong>&nbsp;plus taxes and a lender interest adjustment fee will be charged to the borrower.&nbsp;</p>
            <p><strong><em>Rush Fee</em></strong></p>
            <p>There is an additional legal fee to provide this service and a retainer is to be provided prior to starting the file. We have no way to ensure that other service providers will deliver in the short time frame requested.&nbsp;</p>
            <p><strong><u>THIRD PARTY PAYMENT PROCESSOR</u></strong></p>
            <p>I/We acknowledge that Neumann Law uses a Third Party Payment Processor to pay accounts and debts as required to close the transaction. I/We explicitly provide consent to Neumann Law to disburse funds to the Third Party Payment Processor as necessary. I/We further acknowledge that there may be delays in the banking system and that Neumann Law is not liable for any losses, damages, and/or claims due to any delays in funding/disbursement of payment. I/We the undersigned agree to adjust any shortfall that may be required to remedy any payments that are required for the completion of the transaction.</p>
            <p><strong><u>INTEREST ON UPAID ACCOUNTS</u></strong></p>
            <p>Bills for fees and disbursements remaining unpaid more than 30 days after the bill is delivered, will bear interest at a prescribed rate as specified on the bill. Payment is due on all of our accounts when rendered. If any account is not paid within 30 days, interest will be charged on the outstanding balance at a rate of&nbsp;<strong>5</strong>% per annum from the date of the account, until paid.</p>
            <p><strong><u>TITLE</u></strong></p>
            <p>If your refinance involves a change to the Title of your property where the owners of property are changing, you must know the manner in which you wish to take title to your new property. You must provide us with the legal name or names you want on the Transfer (please note that initials cannot be used) as well as the dates of birth for each. There are several options as to how title to the property may be recorded.&nbsp;</p>
            <p>If one or more person&rsquo;s name is to be on title of the deed, you will have to choose how you will be taking title; choices include &ldquo;Joint Tenants&rdquo; and &ldquo;Tenants-in-Common&rdquo;.&nbsp;</p>
            <p>If you choose Joint Tenants, in the event of a death of one of the joint tenants, the survivor(s) will own the entire property by way of right of survivorship. If you choose Tenants-in-Common, upon the death of one of the co-owners, the person&rsquo;s interest will pass onto his Estate under his Will or in accordance with intestacy legislation if there is no Will.&nbsp;</p>
            <p>Please note that if Neumann Law acts for more than one individual in the transaction, information received may not be kept confidential between the parties. Should conflict arise between you that cannot be resolved, Neumann may no longer be able to act on either of your behalf.</p>
            <p><strong><u>MORTGAGE</u></strong></p>
            <p>Please ensure that Neumann Law receives mortgage instructions from your lender as early as possible since a majority of your file cannot be processed until we have a full understanding of financing and the requirements of the lender.</p>
            <p>It is your responsibility to notify the lender if the property is not going to be immediately occupied as your principal residence or if you are purchasing the property under a Joint Venture Agreement or any other agreement that requires disclosure such as, renting the property or making any additions, demolition or significant changes to the property, etc.&nbsp;</p>
            <p><strong><u>CONSENT TO ACT FOR BOTH BORROWER AND LENDER</u></strong></p>
            <p>When obtaining a mortgage, Neumann Law may be representing both you and the lender at the time of closing your purchase transaction. Neumann has the obligation to represent the interest of both the borrower and lender in a transaction. As a result, all known material facts disclosed fully to Neumann Law and all other parties who have an interest in this transaction including but not limited to the Mortgage Lender(s) must be disclosed. &nbsp;Material facts include but are not limited to all beneficial interests (including the interest of any other party in the property), the intended and future use of the property (if other than residential), tenancies, Joint Ventures, Rent to Owns etc.</p>
            <p>In the event of a conflict between you and the Lender, which cannot be resolved by mutual agreement, you agree to advise us thereof, and you understand that Neumann Law reserves the right to discontinue acting for either party in this matter.</p>
            <p><strong><em>Additional Fee - Late Mortgage Instructions</em></strong></p>
            <p>Please note, we charge a $150.00 plus GST and provincial sales tax (where applicable) fee premium if we receive the lender mortgage instructions less than 48 hours before the closing date. If mortgage instructions are received with less than 48 hours, our office is forced to delay other files by diverting staff resources and/or we are required to spend time outside of working hours to rush to complete all the required legal tasks prior to closing. A large number of documents and tasks related to the closing rely on having mortgage instructions in hand for important information. As such, please ensure that you finalize your financing with your lender as soon as possible before the closing date to ensure we receive mortgage instructions in a timely fashion.</p>
            <p><strong><em>Additional Fee - Class &quot;B/Alternative&quot; Lenders And Private Mortgages</em></strong></p>
            <p>We charge an additional $100.00 plus GST and provincial sales tax (where applicable) fee premium for mortgages that are from &quot;B&quot; Lenders or Private Lenders. &quot;B&quot; Lenders generally are smaller financial institutions, lending companies and investment companies that are not chartered banks.&nbsp;</p>
            <p>We do not charge a premium for the large chartered Canadian banks and class &quot;A&quot; lenders such as TD Canada Trust, Bank of Montreal, National Bank, Royal Bank, Bank of Nova Scotia (Scotiabank), etc.</p>
            <p>The reason for this premium in fees is because, generally, smaller lenders usually do not have physical branches, and usually ask for many more requirements and documents to be processed by our law firm as compared to the larger class A lenders. Our premium is to cover the extra resources we need to invest for files with mortgages that request more information than that of a standard file.</p>
            <p><strong>Additional Fee &ndash; Debt payouts</strong></p>
            <p>In some instances, your lender will require us to pay your creditors from the proceeds of your mortgage refinance. &nbsp;To administer this, we will ask you for copies of statements from the creditors to which we are to pay. &nbsp;We will issue payment to these creditors through EFT or cheque, as required. &nbsp;Please note that we charge per debt payout in order to cover additional administrative costs. &nbsp; While we strive to promptly forward all payments to your creditors shortly after the closing of your mortgage refinance, Neumann Law cannot guarantee receipt of payment by a creditor and cannot not be held liable for any additional fees, interest, penalties, or per-diem charged by your creditor due to delay in payment. &nbsp;Should you be informed or of a delay in payment to a creditor, you are responsible for notifying us within 24 hours so that we can investigate and work to rectify.&nbsp;</p>
            <p><strong>Additional Fee &ndash; More than one mortgage</strong></p>
            <p>If you require more than one mortgage to be registered or discharged, you will incur extra fees per additional mortgage. &nbsp;Should your property have a notice of security interest on title that requires a postponement, you will incur extra fees to process such postponement(s)</p>
            <p><strong><u>STATUS CERTIFICATION (FOR CONDOMINIUMS/STRATAS)</u></strong></p>
            <p>If the property you are refinancing is a condominium/strata, we will require a review of the Condominium/Strata&rsquo;s Status Certificate as a condition of closing set by your lender and Title Insurer (if applicable). &nbsp;The Status Certificate contains other documents and information, including copies of the declaration, by-laws and rules which you should review carefully as these documents govern the use of the unit and common elements. You will be responsible for obtaining a current status certificate and all associated costs with obtaining the status certificate. If you are unable to request a Status Certificate, you authorize Neumann Law to order it on your behalf (such disbursements will be added on to your account).&nbsp;</p>
            <p><strong><u>INSURANCE</u></strong></p>
            <p>Your lender will require proof of home insurance prior to closing with your new lender named as &ldquo;loss payee&rdquo; on the policy. &nbsp;Obtaining or modifying your insurance policy is solely your responsibility. &nbsp; Any closing delays and associated fees caused by not having adequate proof of insurance are your responsibility.</p>
            <p><strong><u>INSURING TITLE TO YOUR PROPERTY</u></strong></p>
            <p>Title insurance protects your ownership interest (i.e., title) of your property from losses incurred as a result of unknown title defects or other covered matters that exist at the time of your purchase but are unknown to you at that time. Title insurance provides coverage for losses arising from, but not limited to:</p>
            <p>Title Risks:</p>
            <ul>
                <li>
                    <p>Someone else claiming an interest in your title.</p>
                </li>
                <li>
                    <p>Fraud, forgery and false impersonation affecting the validity of your title.</p>
                </li>
                <li>
                    <p>Existing liens against the title including realty tax arrears and municipal utility charges.</p>
                </li>
            </ul>
            <p>Off-Title Risks:</p>
            <ul>
                <li>
                    <p>Violations of municipal zoning by-laws.</p>
                </li>
                <li>
                    <p>Many forms of encroachments onto the property or adjoining land.</p>
                </li>
                <li>
                    <p>Existing work orders.</p>
                </li>
                <li>
                    <p>Lack of legal access to the property.</p>
                </li>
                <li>
                    <p>Unmarketability of land due to adverse matters that an up-to-date survey/Real Property Report (RPR)/Building Location Certificate would have revealed.</p>
                </li>
            </ul>
            <p>There are two types of title insurance policies. The Owner&rsquo;s Policy protects your title to the property. The Loan Policy to protect your lender&rsquo;s priority and enforceability of the mortgage. As a condition of your mortgage, many lenders require a Loan Policy be purchased.&nbsp;</p>
            <p>Title insurance is available for a low premium that is paid only once at the time of closing. Coverage is valid for the duration of ownership and the policy coverage amount is generally the amount you paid for your property. Premium costs are often offset by the savings from the reduced number of required searches your lawyer must complete. Additionally, your title insurance policy can often satisfy the lender&rsquo;s requirement for an up-to-date property survey/RPR/Building Location Certificate, saving you additional costs (typically between $500.00 and $1,200.00).</p>
            <p>There are standard exclusions and exceptions from coverage contained in all title insurance policies. These include:</p>
            <ul>
                <li>
                    <p>Risks created, allowed, or agreed to by you.</p>
                </li>
                <li>
                    <p>Risks that are known to you, but not to the title insurer, on the policy date</p>
                </li>
                <li>
                    <p>Environmental matters, including mold and UFFI,</p>
                </li>
                <li>
                    <p>First Nations land claims.</p>
                </li>
                <li>
                    <p>Matters that result in no loss to you.</p>
                </li>
                <li>
                    <p>Matters disclosed in a home inspection report obtained prior to closing.</p>
                </li>
                <li>
                    <p>Functionality of home systems.</p>
                </li>
                <li>
                    <p>Physical/structural conditions or defects regard-less of whether or not such conditions/defects affect the value or marketability of the property.</p>
                </li>
            </ul>
            <p>Based on the searches performed by your lawyer, there may be property specific exceptions added to your policy.&nbsp;</p>
            <p>It is recommended that your transaction is completed by way of title insurance, and in the event that we do not hear from you in the contrary, we will proceed to order a policy on your behalf for closing.</p>
            <p>We will be ordering a policy from either First Canadian Title, Stewart Title or Chicago Title for closing<strong>. &nbsp;</strong>You are responsible for the costs and associated taxes of a one-time premium for the title insurance policy. &nbsp; &nbsp;<strong>&nbsp;</strong></p>
            <p><strong><u>INFORMATION WE NEED FROM YOU</u></strong></p>
            <p>During the course of your closing, we will require information and certain documents from you in order to facilitate a timely closing of your transaction. &nbsp;&nbsp;</p>
            <p>You are responsible for the timely delivery of information and documents to our office either by electronic, facsimile or couriering documents. &nbsp;Any delays or additional fees, penalties, or interest incurred due to unreasonable delays or withholding required documents or information from you, shall be solely your responsibility.</p>
            <p><strong><u>REMOTE / VIRTUAL SIGNING</u></strong></p>
            <p>Remote commissioning is an authentication and signature process for taking affidavits and statutory declarations that uses audio-visual technology.&nbsp;</p>
            <p>At Neumann Law, we use secure remote commissioning technology to make the signing process safe, easy and secure. &nbsp;Shortly before your closing, we will arrange a day and time to meet with you virtually using our remote commissioning technology. &nbsp;We will need each person whose name will appear on the Mortgage and Transfer to attend the signing, if applicable. If any person whose name will appear on the Mortgage and Transfer will not be available at that time, please advise us as soon as possible as alternative arrangements may have to be made.</p>
            <p>In a remote commissioning procedure, you will be connected by live video conference. The video and signing session will be recorded for our records, along with information about your location, identity, and other measures used to prevent fraud and protect all parties from future disputes. &nbsp;Any recordings, or information captured during, before, or after the remote commissioning session is the sole property of Neumann Law and shall become part of your client file for record keeping purposes and to comply with regulations.&nbsp;</p>
            <p>In order to attend a remote commissioning session, you will require access to a computer, a high-speed internet connection and a touch-screen device such as a mobile phone. &nbsp; If you do not have access to required technology, please inform us immediately so that we can seek alternative options.</p>
            <p><strong><em>Additional Fee &ndash; More than one signing appointment</em></strong></p>
            <p>Our fees include one virtual signing appointment and requires all borrowers, guarantors or any other parties required to sign documents to attend at the scheduled time for signing. &nbsp;Should one or more parties fail to attend, are late to the signing meeting, fail to produce valid identification, or are having technology issues that cannot be resolved in the beginning of the signing meeting, you may be charged for an additional signing appointment. &nbsp;</p>
            <p>Should you require a visit by a lawyer or commissioner of oaths to your home or office in order to sign the documents, you may incur additional fees.</p>
            <p><strong><u>IDENTIFICATION</u></strong></p>
            <p>The Law Society and your mortgage lender require us to verify your identity. We must also ensure your matter doesn&rsquo;t conflict with another matter or client.</p>
            <p>At our meeting and before our meeting, we will require each person, to bring two pieces of identification, one of which must be VALID Canadian/Federal or Provincial government-issued photo identification such as a driver&apos;s licence or passport. Unfortunately,&nbsp;<strong>a health card is not acceptable identification</strong>. Your identification may be shared with third-party providers for verification purposes. If you have any questions about what an acceptable form of identification is or if you do not have Canadian/Federal or Provincial photo identification, please contact us as soon as possible.</p>
            <p><strong><em>An expired or non-valid identification will not be accepted.</em></strong></p>
            <p><strong><em>Client Identification and Verification&nbsp;</em></strong></p>
            <p>Neumann Law retains the services of a third-party to assist with the Client Identification and Verification process (&ldquo;<strong>IDV</strong>&rdquo;) to comply with Law Society Rules. All clients are required to complete the IDV process, and if we are unable to comply with the IDV Rules of the Law Society for any reason, the client will be required to meet in-person with a local Notary or Lawyer to complete the IDV process. If we are unable to complete the IDV process, we will no longer be able to act on the transaction.&nbsp;</p>
            <p>The Client is responsible of all fees associated with the IDV process, including all third-party fees.&nbsp;</p>
            <p>Should you require a visit by a lawyer or commissioner of oaths to your home or office in order to sign the documents, you may incur additional fees.</p>
            <p><strong><u>TERMINATION OF SERVICES</u></strong></p>
            <p><strong><em>By you, the client</em></strong></p>
            <p>You have the right to terminate our services to you upon written notice to our firm.</p>
            <p><strong><em>By us, the Solicitor(s)</em></strong></p>
            <p>Subject to our obligations to you to maintain proper standards of professional conduct, we reserve the right to terminate our services to you for good reasons which include, but are not limited to:</p>
            <ul>
                <li>if you fail to cooperate with me in any reasonable request;</li>
                <li>if our continuing to act would be unethical or impractical;</li>
                <li>improper behavior or aggression;</li>
                <li>if there is a serious loss of confidence between us and you;</li>
                <li>if or retainer has not been paid;</li>
                <li>you are dishonest; or</li>
                <li>if you fail to pay our accounts when rendered.</li>
            </ul>
            <p>If you terminate our services or we withdraw our services, you would only have to pay fees, disbursements, and expenses up until the time we stopped acting for you.</p>
            <p>We would also have to withdraw our services if we learned of a&nbsp;<strong>conflict of interest</strong>&nbsp;that would make it unethical for us to continue to act for you. A conflict of interest occurs when what is best for one of the clients of our firm somehow is not best for or hurts another of our clients. If we have to withdraw our services for you because of a conflict of interest, you will only have to pay our fees and expenses up to the time we stopped acting for you.</p>
            <p><strong><em>Fees Collected On Terminated Or Cancelled Matters With Flat Rate Fees</em></strong></p>
            <p>Please note for matters that have flat rate fees such as Real Estate closings, we reserve the right to charge for our time and resources spent up to the termination of the solicitor-client contract.</p>
            <p>Terminations may also occur upon a client&rsquo;s failure to close as a result of a lack of funds or other breach of contract or due to the client not providing information and co-operation as necessary. Neumann Law shall have the right to charge for its time and resources on files that are terminated as a result of client negligence. For clarity, Neumann Law shall have the right to charge for its time and resources for if a flat rate closing file fails to close for any reason.</p>
            <p><strong><u>USE OF TECHNOLOGY</u></strong></p>
            <p>To facilitate a better closing experience, Neumann Law uses certain proprietary and third-party technologies. &nbsp;</p>
            <p>Neumann Law makes every attempt to keep its content up-to-date and the information it provides as accurate as possible. However, information provided by Neumann Law through its website or any applications should be used solely for informational purposes. Neumann Law makes no representations or warranties about the accuracy or completeness of our content or the continued availability of our content, except where required by law. All of the information provided by Neumann Law is &quot;as is&quot; and without warranties of any kind, whether express or implied. Neumann Law does not represent or warrant that Neumann Law products or services will be error free, except where required by law.</p>
            <p><strong><u>ELECTRONIC COMMUNICATION</u></strong></p>
            <p>Neumann Law uses all reasonable means to protect the security and confidentiality of e-mail information sent and received. You acknowledge that you understand that email can never be a secure means of communication and document exchange and that Neumann Law does not guarantee the security and confidentiality of e-mail communication. You acknowledge that Neumann Law will not be liable for improper use and/or disclosure of confidential information and you fully understand the information provided to you regarding the risks of using e-mail and you consent to receiving all documentation regarding this matter via e-mail communications.&nbsp;</p>
            <p><strong><u>USE OF PERSONAL INFORMATION</u></strong></p>
            <p>Neumann Law is responsible for compliance under the applicable provincial Personal Information Protection Act (PIPA). These laws set out rules for how organizations collect, use and disclose personal information. Neumann Law is committed to being accountable for how it handles personal information, as well as how it follows the rules and procedures outlined in its privacy policy.</p>
            <p>Neumann Law will only collect, use or disclose personal information that is necessary to verify your identity, to provide the requested legal services, or as required by law. You agree Neumann Law may disclose your personal information to third parties including conveyancing software, video-conferencing technology, title insurance providers and for any such further purpose related to providing the legal services as set out in this agreement. Neumann Law will ensure that there is an agreement in place with any third parties that that ensures the organization understands and follows the same privacy obligations.</p>
            <p>You may withdraw your consent for the disclosure of your personal information at any time. However, the withdrawal of such consent, may result in us being unable to continue to provide legal services to you.</p>
            <p><strong><u>TERMINATION OF USE OF OUR TECHNOLOGY</u></strong></p>
            <p>Neumann Law may terminate your access to this Website or any of our proprietary and third-party applications at any time with or without cause and without prior notice. In the event of termination of such access, you acknowledge that any material, data and information you have submitted to Neumann Law on this Website may be lost or may otherwise no longer be available to you. Where required by law, Neumann Law will continue to make certain material available to you.</p>
            <p><strong><u>YOUR AGREEMENT TO THE RETAINER AGREEMENT FOR SOLICITOR AND CLIENT RELATIONSHIP</u></strong></p>
            <p>By checking the box, you are acknowledging that you have read this retainer agreement in full and that you agree to abide by the terms set out above.</p>
            <p>If you do not agree to this retainer agreement, there is no solicitor-client relationship. Please advise us immediately of any questions, concerns, comments or if you do not agree to this retainer agreement.</p></>
    )
};

export default TermsOfRefinanceAB;
