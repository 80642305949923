import React, { useEffect } from "react";
import { Box, styled } from "@mui/material";
import DeededCircularLoader from "v2/components/DeededCircularLoader";
import useTypedSelector from "utils/hooks/useTypedSelector";
import resetStorage from "utils/resetStorage";
import { useAppDispatch } from "redux/hooks";
import { setCalendarViewAC } from "redux/reducers/authReducer";
import { useSetCalendarPreferencesMutation } from "redux/reducers/calendarApi";
import { getStaffTC } from "redux/reducers/calendarReducer";
import { Staff } from "types/staffTypes";
import { H2 } from "components/CommonDashboard/Typography";
import ProfilePreferencesForm, { PreferencesInitialValues } from "./components/ProfilePreferencesForm";
import {getProfileTC} from "../../../redux/reducers/profileReducer";

const ProfilePreferencesTab: React.FC = () => {
    const dispatch = useAppDispatch();
    const [setCalendarPreferences, { isLoading }] = useSetCalendarPreferencesMutation();

    const { isLoadingStaff, staff } = useTypedSelector((state) => state.CalendarReducer);
    const lawClerks = (staff as Staff)["Law Clerk"];

    const handleSave = ({ current_view, region, timezone, lawClerk, medium, high }: PreferencesInitialValues) => {
        const obj = {
            calendar: {
                view: current_view,
                region: region.length !== 0 ? region.join(",") : "ALL",
            },
            timezone_calendar: timezone,
            capacity: {
                user_id: lawClerk,
                medium: +medium,
                high: +high,
            },
        };

        setCalendarPreferences(obj);
        dispatch(setCalendarViewAC(current_view));
    };

    useEffect(() => {
        dispatch(getStaffTC());
        dispatch(getProfileTC());
    }, []);

    if (isLoadingStaff) {
        return <DeededCircularLoader />;
    }

    return (
        <PreferencesPage>
            <PreferencesPageWrapper>
                <H2>Calendar</H2>
                <ProfilePreferencesForm lawClerks={lawClerks} onSubmit={handleSave} />
            </PreferencesPageWrapper>
        </PreferencesPage>
    );
};

const PreferencesPage = styled(Box)(({ theme }) => ({
    display: "flex",
    width: "100%",
    backgroundColor: "white",
    height: "100%",
    margin: "15px 15px 0 0",
    [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        alignItems: "center",
    },
}));

const PreferencesPageWrapper = styled(Box)(({ theme }) => ({
    padding: "22px",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
        width: "100%",
    },
}));

export default ProfilePreferencesTab;
