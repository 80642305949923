import React, {useEffect, useMemo, useState} from "react";

// helpers
import {baseURL} from "api";
import useStaff from "utils/hooks/useStaff";
import {customStyles} from "../DealDetails/dealDetailsStyles";
import {StaffUser} from "redux/reducers/configReducer/staffCardReducer";

// components
import Select from "react-select";
import SignerProfile from "./SignerProfileImage";
import {CrossIcon} from "../DealDetailsCardsSectionV2/Icons";
import {useGetMobileSignersQuery} from "redux/reducers/dealInfoApi";

const MobileSigner: React.FC<{
  onUnAssign: () => void;
  signer?: StaffUser | null;
  value: number | undefined;
  appointment: StaffUser | null;
  name: string;
  onChange: (e: Partial<React.ChangeEvent<any>>) => void;
  disabled?: boolean;
  params: {
    date: string;
    start_time: string;
    end_time: string;
    timezone: string;
    appointment_id: null | number;
  };
}> = ({onUnAssign, name, onChange, value, appointment, disabled, params}) => {
  const signers = useStaff()?.["Mobile Signer"];

  const [userPicId, setUserPicId] = useState<any>(
    appointment ? appointment?.userpic?.id : null,
  );
  const [signersState, setSignersState] = useState<any>([]);
  const [optionValue, setOptionValue] = useState<any>({
    label: "No option",
    value: "No option",
  });
  useEffect(() => {
    if (value === undefined) {
      setUserPicId(null);
      setOptionValue({label: "No option", value: "No option"});
    }
  }, [value]);

  const [mobileSignerSkip, setMobileSignerSkip] = useState(true);
  useEffect(() => {
    if (!disabled) {
      setMobileSignerSkip(false);
    }
  }, [disabled]);

  const {data: mobileSigners} = useGetMobileSignersQuery(params, {
    skip: mobileSignerSkip,
  });
  const mobileSignersOptions = useMemo(() => {
    return mobileSigners?.map(({full_name, id, busy}) => ({
      label: full_name,
      value: id,
      isDisabled: busy,
    }));
  }, [mobileSigners]);

  useEffect(() => {
    if (appointment) {
      setOptionValue({
        ...appointment,
        label: appointment?.full_name,
        value: appointment?.full_name,
      });
    }
  }, []);

  useEffect(() => {
    if (signers && signers.length)
      setSignersState([...signersState, ...signers]);
  }, [signers]);

  return (
    <div className={`mobilesigner ${disabled ? "disabled" : ""}`}>
      <button
        style={{background: "none", padding: "none", border: "none"}}
        type="button"
        onClick={onUnAssign}
        className="unassign--icon"
      >
        <CrossIcon />
      </button>
      <div className="mobilesigner--header">
        {userPicId ? (
          <img src={`${baseURL}/userpics/${userPicId}`} alt="user profile" />
        ) : (
          <SignerProfile />
        )}
        <p>Mobile Signer</p>
      </div>
      <div data-testid="signing-appointment-mobile-signer-drop-down">
        <Select
            inputId="signing-appointment-mobile-signer-drop-down-input"
            styles={customStyles}
            options={mobileSignersOptions}
            value={optionValue}
            onChange={(e) => {
              setOptionValue({...optionValue, ...e});

              const foundSigner = signersState?.find(
                  (signer: any) => signer.userpic?.user_id === +e.value,
              );
              setUserPicId(foundSigner?.userpic?.id);

              onChange({target: {name, value: +e.value}});
            }}
        />
      </div>
      {disabled && (
        <div className="error">
          Please fill in the fields for date, time and time zone
        </div>
      )}
    </div>
  );
};

export default MobileSigner;
