import React, {ChangeEvent, FC, useMemo} from "react";
import NumberFormat from "react-number-format";
import {Client, UserParticipant} from "../../../types/user";
import BasicButton from "../../Common/Button/BasicButton";
import ClientPrefixInput from "./ClientPrefixInput";
import {
    styled,
    InputLabel, Stack, useTheme,
} from "@mui/material";
import CheckboxField from "../../CommonDashboard/CheckboxField";

type ClientData = Client & { middle_name: string; name_prefix?: string | null, draft?: boolean };

interface AdditionalClientFormProps {
  client: Partial<ClientData>;
  inputErrors?: Partial<ClientData|UserParticipant>;
  onRemove: () => void;
  onUpdate: (data: Partial<ClientData>) => void;
  canRemove?: boolean;
  hideDraft?: boolean;
}

const StyledLabel = styled(InputLabel)({
  display: 'flex',
  flexDirection: 'column',
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '12px',
  lineHeight: '15px',
  marginTop: '0 !important',
  marginBottom: '2rem !important',
  marginRight: '0',
});

const AdditionalClientForm: FC<AdditionalClientFormProps> = ({
  client,
  inputErrors,
  onRemove,
  onUpdate,
  canRemove = true,
  hideDraft = false,
}) => {
  const isCorp = useMemo(() => client?.name_prefix === "Corporation", [client]);

  const handleInputs = (e: ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;
    let updateData = {
      ...client,
      [name]: value,
    };

    if (client?.name_prefix === "Corporation" && name === "first_name") {
      if (value.length > 25) return;

      updateData = {
        ...client,
        first_name: value,
        last_name: value,
      };
    }

    if (name === "name_prefix" && value === "Corporation") {
      updateData.middle_name = "";
    }

    onUpdate(updateData);
  };
  
  const handleCheckbox = () => {
      let updateData = {
          ...client,
          draft: !client.draft,
      }
      if (updateData.draft) {
          updateData.email = '';
      }
      onUpdate(updateData);
  }

  return (
    <div className="secondary__client__row with__shadows" data-testid="secondary-client-form">
      <div className="secondary__client__row--wrapper">
        <div className="prefix__row">
          <ClientPrefixInput
            client_prefix={client.name_prefix || ""}
            onChange={handleInputs}
            name="name_prefix"
          />
        </div>
        <div className="name__row">
          <div className="fullname__container">
            <StyledLabel htmlFor="first_name">
              {isCorp ? "Name of Corporation" : "First Name"}
              <input
                style={
                  isCorp
                    ? {minWidth: "30rem", marginBottom: ".5rem"}
                    : undefined
                }
                type="text"
                name="first_name"
                autoComplete="new-password"
                className={inputErrors?.first_name && "input-error"}
                value={client.first_name}
                onChange={handleInputs}
                data-testid="secondary-client-info-first-name-field"
              />
              {isCorp && <span>Max 25 characters.</span>}
              <ErrorMessage error={!!inputErrors?.first_name} />
            </StyledLabel>
            {!isCorp && (
              <>
                <StyledLabel htmlFor="middle_name">
                  Middle Name
                  <input
                    type="text"
                    name="middle_name"
                    autoComplete="new-password"
                    value={client.middle_name}
                    onChange={handleInputs}
                    data-testid="secondary-client-info-middle-name-field"
                  />
                </StyledLabel>
                <StyledLabel htmlFor="last_name">
                  Last Name
                  <input
                    type="text"
                    name="last_name"
                    autoComplete="new-password"
                    className={inputErrors?.last_name && "input-error"}
                    value={client.last_name}
                    onChange={handleInputs}
                    data-testid="secondary-client-info-last-name-field"
                  />
                  <ErrorMessage error={!!inputErrors?.last_name} />
                </StyledLabel>
              </>
            )}
          </div>
        </div>
        <div className="email-phone-date__row">
          <StyledLabel htmlFor="email">
            Client's Primary Email
            <input
              type="text"
              name="email"
              autoComplete="new-password"
              placeholder="clientname@email.com"
              className={Boolean(inputErrors?.email) ? "input-error" : undefined}
              value={client.email}
              onChange={handleInputs}
              data-testid="secondary-client-info-primary-email-field"
              disabled={client.draft}
            />
            <ErrorMessage
                error={!!inputErrors?.email}
                customMessage={inputErrors?.email || undefined}
            />
          </StyledLabel>

          {!hideDraft && (
              <Stack width="20rem" mr="2rem" pb="2.1rem" justifyContent="flex-end">
                  <CheckboxField
                      testId="secondary-client-info-draft-checkbox"
                      name="draft"
                      label="Email unknown"
                      checked={client.draft}
                      onChange={handleCheckbox}
                  />
              </Stack>
          )}

          <StyledLabel htmlFor="phone">
            Client's Mobile Phone Number
            <NumberFormat
              className={inputErrors?.phone ? "input-error" : undefined}
              value={client.phone}
              format="###-###-####"
              placeholder="xxx-xxx-xxxx"
              name="phone"
              onChange={handleInputs}
              data-testid="secondary-client-info-phone-number-field"
            />
            <ErrorMessage
              error={!!inputErrors?.phone}
            />
          </StyledLabel>
        </div>
        {canRemove ?
        <BasicButton
          label={{
            text: "Remove",
            inputId: "remove-user-btn",
          }}
          onClick={onRemove}
          size={"small"}
          typeOf={"CTA"}
        />
        : null}
      </div>
    </div>
  );
};

const ErrorMessage: FC<{error?: boolean; customMessage?: string}> = ({
  error,
  customMessage = "Field is required",
}) => {
  if (!error) {
    return null;
  }
  return <span className="client-error input-error-text">{customMessage}</span>;
};

export default AdditionalClientForm;
