import React, {SetStateAction, useEffect, useState} from "react";
import {Staff} from "types/Config/userManagerTypes";
import {Box, styled, SxProps, Theme} from "@mui/material";
import UserManagerListStaff from "./UserManagerListStaff";
import UserManagerListClients from "./UserManagerListClients";
import UserManagerListProfessionals from "./UserManagerListProfessionals";
import constants from "styles/constants";

interface UserManagerListProps {
  data: Staff[];
  handleUser: (user: Staff) => void;
  role: "staff" | "clients" | "professionals";
  setOrderby: React.Dispatch<SetStateAction<string | null>>;
  setOrder: React.Dispatch<SetStateAction<string | null>>;
  setTypeRole: React.Dispatch<SetStateAction<string>>;
  setRolesFilter: React.Dispatch<SetStateAction<string[] | null>>;
  rolesFilter: string[] | null;
  companiesFilter: string[] | null;
  setCompaniesFilter: React.Dispatch<SetStateAction<string[] | null>>;
  type: string;
  searchVal: string;
}

const UserManagerList: React.FC<UserManagerListProps> = ({
  data,
  handleUser,
  role,
  setOrderby,
  setOrder,
  setTypeRole,
  setRolesFilter,
  rolesFilter,
  companiesFilter,
  setCompaniesFilter,
  type,
  searchVal,
}) => {
  const [selectedSort, setSelectedSort] = useState("");
  const [selectedSortValue, setSelectedSortValue] = useState<"desc" | "asc">(
    "asc",
  );
  const changeSort = (orderby: string, role: string) => {
    setSelectedSort(orderby);
    setTypeRole(role);
    setOrderby(orderby);
    setSelectedSortValue(selectedSortValue === "asc" ? "desc" : "asc");
    setOrder(selectedSortValue);
  };

  const [copiedToClipboard, setCopiedToClipboard] = useState(false);
  const handleClipboardCopy = (email: string) => {
    setCopiedToClipboard(true);
    navigator.clipboard.writeText(email);

    setTimeout(() => {
      setCopiedToClipboard(false);
    }, 1000);
  };

  useEffect(() => {
    setSelectedSortValue("asc");
    setSelectedSort("");
  }, [type]);

  return (
    <Wrapper className={role === "professionals" ? "prof_block" : ""}>
      {role === "staff" && (
        <UserManagerListStaff
          data={data}
          handleUser={handleUser}
          sx={styles.UserManagerListStyles}
          selectedSortValue={selectedSortValue}
          selectedSort={selectedSort}
          changeSort={changeSort}
          copiedToClipboard={copiedToClipboard}
          handleClipboardCopy={handleClipboardCopy}
          searchVal={searchVal}
        />
      )}
      {role === "clients" && (
        <UserManagerListClients
          data={data}
          handleUser={handleUser}
          sx={styles.UserManagerListStyles}
          selectedSortValue={selectedSortValue}
          selectedSort={selectedSort}
          changeSort={changeSort}
          copiedToClipboard={copiedToClipboard}
          handleClipboardCopy={handleClipboardCopy}
          searchVal={searchVal}
        />
      )}
      {role === "professionals" && (
        <UserManagerListProfessionals
          data={data}
          selectedSortValue={selectedSortValue}
          selectedSort={selectedSort}
          changeSort={changeSort}
          copiedToClipboard={copiedToClipboard}
          handleClipboardCopy={handleClipboardCopy}
          setTypeRole={setTypeRole}
          setRolesFilter={setRolesFilter}
          setCompaniesFilter={setCompaniesFilter}
          handleUser={handleUser}
          rolesFilter={rolesFilter}
          companiesFilter={companiesFilter}
          sx={styles.UserManagerListStyles}
          searchVal={searchVal}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled(Box)({
  width: "100%",
  "&.prof_block": {
    width: "100%",
    minWidth: "100%",
  },
});

const styles: Record<"UserManagerListStyles", SxProps<Theme>> = {
  UserManagerListStyles: (theme: Theme) => ({
    "&::-webkit-scrollbar": {
      [theme.breakpoints.up(1400)]: {
        height: "0px",
      },
      [theme.breakpoints.down(1400)]: {
        height: "4px",
      },
      [theme.breakpoints.down("lg")]: {
        height: "0px",
      },
      width: "0px",
    },
    "& .TableHeader": {
      display: "grid",
      gridColumnGap: "15px",
      width: "100%",
      background: constants.colors.white,
      boxShadow: "0px 2px 4px rgba(161, 184, 214, 0.12)",
      borderRadius: "2px",
      marginBottom: "4px",
      padding: "16px",
      paddingLeft: "12px",
      [theme.breakpoints.down("lg")]: {
        display: "none",
      },
    },
    "& .TableHeaderCell": {
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "17px",
      display: "flex",
      alignItems: "center",
      color: constants.colors.deededGray,
      position: "relative",
      "& .IsNoFilter": {
        display: "flex",
        alignItems: "center",
      },
      "& .chevronsBox": {
        "&:hover": {
          cursor: "pointer",
        },
        svg: {
          transition: constants.transitions,
          "&.active": {
            transform: "scaleY(-1)",
            transition: constants.transitions,
          },
        },
      },
      svg: {
        marginLeft: "4px",
        marginRight: "4px",
      },
      "&.prof_block": {
        width: "100%",
      },
    },
    "& .TableRowWrapper": {
      display: "grid",
      width: "100%",
      minWidth: "100%",
      background: constants.colors.white,
      boxShadow: "0px 2px 4px rgba(161, 184, 214, 0.12)",
      borderRadius: "2px",
      marginBottom: "4px",
      padding: "12px",
      paddingLeft: "12px",
      gridColumnGap: "15px",
      transition: constants.transitions,
      "&:hover": {
        background: constants.colors.silver,
        transition: constants.transitions,
      },
      [theme.breakpoints.down("lg")]: {
        background: constants.colors.white,
        borderRadius: "5px",
        position: "relative",
      },
    },
    "& .TableBodyCell": {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "17px",
      display: "flex",
      alignItems: "center",
      color: constants.colors.title,
      [theme.breakpoints.down("lg")]: {
        gridColumnStart: 1,
        gridColumnEnd: 8,
        fontSize: "12px",
        lineHeight: "15px",
        "&:nth-of-type(1)": {
          marginBottom: "13px",
        },
        "&:nth-of-type(2)": {
          marginBottom: "13px",
        },
        "&:nth-of-type(1)>.ItemWrapper": {
          fontWeight: 600,

          "&::before": {
            content: '"User: "',
            color: constants.colors.intakeTitle,
            display: "block",
            fontWeight: 400,
            lineHeight: "15px",
          },
        },
        "&:nth-of-type(2)>.ItemWrapper": {
          display: "grid",
          gridTemplateColumns: "80px 1fr",
          maxWidth: "265px",
          "&::before": {
            content: '"Email: "',
            color: constants.colors.intakeTitle,
          },
        },
        "&:nth-of-type(3)>.ItemWrapper": {
          display: "grid",
          gridTemplateColumns: "80px 1fr",
          maxWidth: "265px",
          "&::before": {
            color: constants.colors.intakeTitle,
          },
        },
        "&:last-child": {
          position: "absolute",
          top: "16px",
          right: 0,
        },
        "&:nth-of-type(4), &:nth-of-type(5), &:nth-of-type(6), &:nth-of-type(7), &:nth-of-type(8)":
          {
            display: "none",
          },
        "&.UserManagerUserTools": {
          display: "block !important",
        },
      },
      "&.email": {
        color: constants.colors.blue,
        position: "relative",
        "&:hover": {
          "& > div": {
            visibility: "visible",
            opacity: 1,
            transition: constants.transitions,
          },
        },
        "& .CopyEmailPrompt": {
          zIndex: "1",
          display: "flex",
          alignItems: "center",
          position: "absolute",
          height: "38px",
          padding: "0 13px",
          backgroundColor: constants.colors.cloudGray,
          boxShadow: "0px 4px 10px rgba(71, 71, 71, 0.33)",
          borderRadius: "5px",
          color: constants.colors.white,
          top: "-40px",
          left: 0,
          [theme.breakpoints.down("lg")]: {
            left: "70px",
          },
          bottom: 0,
          opacity: 0,
          transition: constants.transitions,
          visibility: "hidden",
          ".Triangle": {
            display: "flex",
            width: "12px",
            height: "12px",
            transform: "rotate(45deg)",
            backgroundColor: constants.colors.cloudGray,
            position: "absolute",
            bottom: "-6px",
            left: "20px",
          },
          svg: {
            marginLeft: "9px",
            transition: constants.transitions,
            stroke: constants.colors.searchColor,
            "&:hover": {
              transition: constants.transitions,
              stroke: constants.colors.red,
              cursor: "pointer",
            },
          },
        },
      },
      "& a.totalDeals": {
        color: constants.colors.blue2,
        transition: constants.transitions,
        "&:hover": {
          color: constants.colors.red,
          transition: constants.transitions,
        },
      },
      "&:last-child": {
        justifySelf: "end",
      },
      "& .ItemWrapper": {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        display: "inline-block",
        maxWidth: "180px",
      },
      "& .ImageWrapper": {
        width: "25px",
        height: "25px",
        borderRadius: "5px",
        overflow: "hidden",
        marginRight: "8px",
        "& img": {
          width: "100%",
          height: "100%",
          objectFit: "cover",
          borderRadius: "5px",
        },
      },
    },
  }),
};

export default UserManagerList;
