import {Staff} from "../../../types/Config/userManagerTypes";
import {Dispatch} from "redux";
import axios from "axios";
import {baseURL} from "../../../api";
import {UserManagerType} from "../types/userManagerTypes";
import {addErrorAC, addSuccessMessageAC} from "../ErrorReducer";
import {RootStore} from "types/flux";

export type UserManagerAction = {
  type:
    | "USER_MANAG_GET_STAFF"
    | "USER_MANAG_SET_ROLE"
    | "USER_MANAG_SET_All_ROLES"
    | "USER_MANAG_SET_All_COMPANIES"
    | "USER_MANAG_SET_PAGINATION_INFO"
    | "SET_STAFF_PAGE_CURSOR"
    | "SET_CONFIG_LOADER";
  payload?: unknown;
};

export interface UserManagerStateInterface {
  staff: Array<Staff>;
  role: "staff" | "clients" | "professionals";
  allRoles: Array<string>;
  allCompanies: Array<string>;
  paginationInfo: {
    current_page: number;
    last_page: number;
  };
  configLoader: boolean;
}

const userManagerState = {
  staff: [] as Staff,
  role: "Staff",
  allRoles: [],
  allCompanies: [],
  paginationInfo: {
    current_page: 1,
    last_page: 1,
  },
  configLoader: false,
};

export const userManagerReducer = (
  state = userManagerState,
  action: UserManagerAction,
) => {
  switch (action.type) {
    case "SET_CONFIG_LOADER": {
      return {
        ...state,
        configLoader: action.payload,
      };
    }
    case "USER_MANAG_GET_STAFF": {
      return {
        ...state,
        staff: action.payload,
      };
    }

    case "USER_MANAG_SET_ROLE": {
      return {
        ...state,
        role: action.payload,
      };
    }

    case "USER_MANAG_SET_All_ROLES": {
      return {
        ...state,
        allRoles: action.payload,
      };
    }

    case "USER_MANAG_SET_All_COMPANIES": {
      return {
        ...state,
        allCompanies: action.payload,
      };
    }

    case "USER_MANAG_SET_PAGINATION_INFO": {
      return {
        ...state,
        paginationInfo: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export const setConfigLoaderAC = (payload: boolean) => {
  return {
    type: "SET_CONFIG_LOADER",
    payload,
  };
};

export function setStaff(staff: Staff) {
  return {
    type: "USER_MANAG_GET_STAFF",
    payload: staff,
  };
}

export const setLoading = (val: boolean) => {
  return {
    type: "USER_MANAG_SET_LOADING",
    payload: val,
  };
};
export const setRole = (val: string) => {
  return {
    type: "USER_MANAG_SET_ROLE",
    payload: val,
  };
};
export const setPaginationInfo = (val: {
  current_page: number;
  last_page: number;
}) => {
  return {
    type: "USER_MANAG_SET_PAGINATION_INFO",
    payload: val,
  };
};
export const setAllRoles = (val: string[]) => {
  return {
    type: "USER_MANAG_SET_All_ROLES",
    payload: val,
  };
};
export const setAllCompanies = (val: string[]) => {
  return {
    type: "USER_MANAG_SET_All_COMPANIES",
    payload: val,
  };
};
export const getAllRoles = (type: string) => (dispatch: Dispatch) => {
  axios.get(`/config/roles${type ? `?group=${type}` : ""}`).then((res) => {
    dispatch(setAllRoles(res.data));
  });
};
export const getAllCompanies = (type: string) => (dispatch: Dispatch) => {
  axios.get(`/config/companies`).then((res) => {
    dispatch(setAllCompanies(res.data));
  });
};
export const updateUsers =
  (data: UserManagerType, role: string) => (dispatch: Dispatch) => {
    axios
      .post(`/config/users/create`, data)
      .then((res) => {
        axios.get(`${baseURL}/config/users?tab=${role}&page=1`).then((res) => {
          dispatch(
            setPaginationInfo({
              current_page: res.data.current_page,
              last_page: res.data.last_page,
            }),
          );
          dispatch(setStaff(res.data.data));
          dispatch(
            addSuccessMessageAC(
              `${role.toUpperCase()} was successfully created`,
            ),
          );
        });
      })
      .catch((e) => {
        const error = e as {response: {data: {error: string}}};
        dispatch(
          addErrorAC(
            error.response.data.error ?? `User with this email already exists`,
          ),
        );
      });
  };
export const editUser =
  (data: unknown, userID: number, role: string) => (dispatch: Dispatch) => {
    axios
      .post(`/config/users/${userID}/update`, data)
      .then((res) => {
        axios
          .get(`${baseURL}/config/users?tab=${role}&page=${1}`)
          .then((res) => {
            dispatch(
              setPaginationInfo({
                current_page: res.data.current_page,
                last_page: res.data.last_page,
              }),
            );
            dispatch(setStaff(res.data.data));
            dispatch(
              addSuccessMessageAC(
                `${role.toUpperCase()} was successfully editted`,
              ),
            );
          });
      })
      .catch((e) => {
        const error = e as {response: {data: {error: string}}};
        dispatch(addErrorAC(error.response.data.error));
      });
  };

export const restoreUser =
  (data: unknown, userID: number, role: string) => (dispatch: Dispatch) => {
    axios
      .post(`/config/users/${userID}/restore`, data)
      .then((res) => {
        axios
          .get(`${baseURL}/config/users?tab=${role}&page=${1}&deleted=true`)
          .then((res) => {
            dispatch(
              setPaginationInfo({
                current_page: res.data.current_page,
                last_page: res.data.last_page,
              }),
            );
            dispatch(setStaff(res.data.data));
            dispatch(
              addSuccessMessageAC(
                `${role.toUpperCase()} was successfully editted`,
              ),
            );
          });
      })
      .catch((e) => {
        const error = e as {response: {data: {error: string}}};
        dispatch(addErrorAC(error.response.data.error));
      });
  };
export const mfaEnableTC =
  (userId: number, isTurnedOnMfa: boolean) => async (dispatch: Dispatch) => {
    try {
      await axios.post(`/authenticator/${userId}/verify-status`, {
        status: isTurnedOnMfa,
      });
    } catch (e) {
      const error = e as {response: {data: {error: string}}};
      dispatch(addErrorAC(error.response.data.error));
    }
  };
export interface GetDataByRoleItems {
  type: string;
  page: number;
  orderby?: string;
  order?: string;
  role?: string;
  company?: string;
  search?: string;
  deleted?: boolean;
}

export const getDataByRole =
  ({
    type,
    page,
    orderby,
    order,
    role,
    company,
    search,
    deleted,
  }: GetDataByRoleItems) =>
  (dispatch: Dispatch, getState: () => RootStore) => {
    dispatch(setConfigLoaderAC(true));

    let {current_page: currentPagePreviously, last_page} =
      getState().ConfigReducer.userManagerReducer.paginationInfo;

    dispatch(
      setPaginationInfo({
        current_page: page,
        last_page,
      }),
    );

    axios
      .get(
        `${baseURL}/config/users?tab=${type}&page=${page}${
          orderby ? `&orderby=${orderby}` : ""
        }${order ? `&order=${order}` : ""}${role ? `&role=${role}` : ""}${
          company ? `&company=${company}` : ""
        }${search ? `&search=${search}` : ""}${
          deleted ? `&deleted=${deleted}` : ""
        }`,
      )
      .then((res) => {
        dispatch(
          setPaginationInfo({
            current_page: res.data.current_page,
            last_page: res.data.last_page,
          }),
        );
        dispatch(setStaff(res.data.data));
      })
      .then((res) => {
        dispatch(setRole(type));
        dispatch(setConfigLoaderAC(false));
      })
      .catch((e) => {
        const error = e as {response: {data: {error: string}}};
        dispatch(
          addErrorAC(
            error.response.data.error ??
              "Server is not working, try again later",
          ),
        );
        dispatch(
          setPaginationInfo({
            current_page: currentPagePreviously,
            last_page,
          }),
        );
      });
  };

export const deleteUserForUserManagerPage =
  (userID: number | undefined, role: string) => (dispatch: Dispatch) => {
    axios
      .post(`/config/users/${userID}/delete`, {})
      .then((res) => {
        axios
          .get(`${baseURL}/config/users?tab=${role}&page=${1}`)
          .then((res) => {
            dispatch(
              setPaginationInfo({
                current_page: res.data.current_page,
                last_page: res.data.last_page,
              }),
            );
            dispatch(setStaff(res.data.data));
            dispatch(addSuccessMessageAC("Successfully deleted"));
          })
          .catch((e) => {
            const error = e as {response: {data: {error: string}}};
            dispatch(
              addErrorAC(error.response.data.error ?? "Cannot delete user"),
            );
          });
      })
      .catch((e) => {
        const error = e as {response: {data: {error: string}}};
        dispatch(addErrorAC(error.response.data.error ?? "Cannot delete user"));
      });
  };

export const searchUsers =
  (searchVal: string, role: string) =>
  (dispatch: Dispatch, getState: () => RootStore) => {
    axios
      .get(`${baseURL}/config/users?search=${searchVal}&tab=${role}&page=1`)
      .then((res) => {
        dispatch(
          setPaginationInfo({
            current_page: res.data.current_page,
            last_page: res.data.last_page,
          }),
        );
        dispatch(setStaff(res.data.data));
      });
  };
