import React from "react";
import {baseURL} from "api";
import {Box, styled} from "@mui/material";
import profilePhotoPlug from "assets/img/unknownUserProfilePhoto.jpg";
import constants from "styles/constants";

interface ActivityUserPicturesProps {
  id: number;
  original_filename: string;
}
export interface DealDetailsActivityLogItemProps {
  documentName: string;
  status: string;

  date: string;
  time: string;
  user: ActivityLogUserProps;
}
export interface ActivityLogUserProps {
  full_name: string;
  userpic: ActivityUserPicturesProps;
}

const DealDetailsActivityLogItem = ({
  documentName,
  status,
  date,
  time,
  user,
}: DealDetailsActivityLogItemProps) => {
  const photoUrl = user?.userpic
    ? `${baseURL}/userpics/${user?.userpic?.id}`
    : profilePhotoPlug;
  return (
    <ActivityitemHeader>
      <DocumentNameValue data-testid="activity-log-document-name-column">{documentName}</DocumentNameValue>
      <StatusValue data-testid="activity-log-status-column">
        <span style={{cursor: "pointer"}}>{status}</span>
      </StatusValue>
      <SelectedCompany data-testid="activity-log-date-column">
        <span style={{cursor: "pointer"}}>{date}</span>
      </SelectedCompany>
      <SelectedDateCreated data-testid="activity-log-time-column">{time}</SelectedDateCreated>
      <SelectedLastDeal data-testid="activity-log-user-column">
        <ProfilePhotoBox>
          <img
            style={{
              borderRadius: "5px",
              width: "25px",
              height: "25px",
              objectFit: "cover",
            }}
            alt={"img"}
            src={photoUrl}
          />
        </ProfilePhotoBox>
        {user.full_name}
      </SelectedLastDeal>
    </ActivityitemHeader>
  );
};
const ProfilePhotoBox = styled(Box)({
  borderRadius: "5px",
  marginLeft: "5px",
  marginRight: "10px",
  width: "25px",
  height: "25px",
});
const ActivityitemHeader = styled(Box)({
  display: "flex",
  padding: " 1.5rem 2rem",
  background: "#FFFFFF",
  marginBottom: "0.5rem",
  height: "50px",
  boxShadow: "0px 2px 4px rgba(161, 184, 214, 0.12)",
  borderRadius: "2px",
});
const DocumentNameValue = styled(Box)({
  position: "relative",
  display: "flex",
  flex: 1,
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: "14px",
  lineHeight: "17px",
  alignItems: "center",
  color: constants.colors.title,
  lineBreak: "anywhere",
  marginRight: "15px",
});
const StatusValue = styled(Box)({
  position: "relative",
  display: "flex",
  flex: 1.5,
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "17px",
  alignItems: "center",
  color: constants.colors.title,
  lineBreak: "anywhere",
});
const SelectedLastDeal = styled(Box)({
  position: "relative",
  display: "flex",
  flex: 1,
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "14px",
  lineHeight: "17px",
  alignItems: "center",
  color: constants.colors.title,
  lineBreak: "anywhere",
  marginRight: " 4rem",
});
const SelectedCompany = styled(Box)({
  position: "relative",
  display: "flex",
  flex: 1,
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "17px",
  alignItems: "center",
  color: constants.colors.title,
  lineBreak: "anywhere",
  marginRight: " 0.5rem",
});
const SelectedDateCreated = styled(Box)({
  position: "relative",
  display: "flex",
  flex: 1,
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "17px",
  alignItems: "center",
  color: constants.colors.title,
  lineBreak: "anywhere",
  marginRight: "0rem",
});
export default DealDetailsActivityLogItem;
