import { styled } from "@mui/material/styles";
import {SxProps, Theme, Typography} from "@mui/material";
import Box, {BoxProps} from "@mui/material/Box";
import constants from "../../styles/constants";

interface DeededTabProps {
  active?: boolean;
  onClick?: () => void;
  sx?: SxProps<Theme>;
}

const DeededTab: React.FC<DeededTabProps & Omit<BoxProps, "variant">> = ({
  children,
  active,
  onClick,
  sx,
  ...props
}) => (
  <Box
    sx={{position: "relative", cursor: "pointer", ...sx}}
    {...props}
    data-selenium-test="DeededTab"
  >
    <Box onClick={onClick}>
      <ProfileTag
        sx={
          active
            ? {
                color: `${constants.colors.red} !important`,
                fontWeight: "600 !important",
              }
            : undefined
        }
      >
        {children}
      </ProfileTag>
    </Box>
    {active && (
      <Box
        sx={{
          position: "absolute",
          bottom: -2,
          backgroundColor: constants.colors.red7,
          width: "100%",
          height: "2px",
        }}
      />
    )}
  </Box>
);

const ProfileTag = styled(Typography)({
  ...constants.fontConfig_TEMP,
  fontSize: "18px",
  marginBottom: "6px",
  display: "flex",
  alignItems: "center",
  color: constants.colors.deededGray,
});

export default DeededTab;
