import ErrorBoundary from "components/System/ErrorBoundary";
import React, {ComponentType, useEffect, useState} from "react";
import useDealSelector from "utils/hooks/useDealSelector";
import AppointmentForm from "./AppointmentForm";
import PlusButton from "./PlusButton";
import SigningAppointmentCreateForm from "./SigningAppointmentCreateForm";
import {ProviderContext} from "notistack";
import {withSnackbar} from "utils/withSnackbar";
import {StaffUser} from "../../../redux/reducers/configReducer/staffCardReducer";
import configuration from "../../../utils/configuration";

export interface NewStaffType {
  first_name: string;
  middle_name: string;
  last_name: string;
  id: number;
}
const SigningAppointmentFormSection: React.FC<{
  onRefresh?: () => void;
  enqueueSnackbar: (message: string, settings: unknown) => unknown;
  updateStaffCallback: (
    newStaff: NewStaffType,
    oldSigner: StaffUser | null,
  ) => void;
  deleteUserFromDealStaff: (userId: number) => void;
}> = ({
  onRefresh,
  enqueueSnackbar,
  updateStaffCallback,
  deleteUserFromDealStaff,
}) => {
  const deal = useDealSelector();
  const [newFormIsShown, setNewFormIsShown] = useState(false);
  useEffect(() => {
    /** If we get refetched deal, we hide the new form */
    setNewFormIsShown(false);
  }, [deal]);

  if (!deal) {
    return <>Loading...</>;
  }
  const hasNothingToShow = (deal?.appointment?.length ?? 0) === 0;
  return (
    <>
      {hasNothingToShow && (
        <SigningAppointmentCreateForm
          deleteUserFromDealStaff={deleteUserFromDealStaff}
          updateStaffCallback={updateStaffCallback}
        />
      )}
      <ErrorBoundary>
        {deal?.appointment?.map((appointment) => (
          <AppointmentForm
            deleteUserFromDealStaff={deleteUserFromDealStaff}
            updateStaffCallback={updateStaffCallback}
            key={appointment.id}
            appointment={appointment}
          />
        ))}
        {newFormIsShown && (
          <SigningAppointmentCreateForm
            deleteUserFromDealStaff={deleteUserFromDealStaff}
            updateStaffCallback={updateStaffCallback}
          />
        )}
        <PlusButton data-testid="add-signing-appointment-btn"
          onClick={() => {
            if (newFormIsShown || hasNothingToShow) {
              enqueueSnackbar("Please, save the last signing appointment", {
                variant: "error",
                autoHideDuration: configuration.autoHideErrorDuration,
              });
            } else {
              setNewFormIsShown((x) => !x);
            }
          }}
        />
      </ErrorBoundary>
    </>
  );
};

export default withSnackbar(
  SigningAppointmentFormSection as unknown as ComponentType<ProviderContext>,
);
