import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {withSnackbar} from "utils/withSnackbar";
import {useRouteMatch} from "react-router-dom";
import SurveyList from "./SurveyList";
import {getSurveyData} from "../../../api";
import "./Survey.scss";
import DeededCircularLoader from "v2/components/DeededCircularLoader";
import {getDealInfoByIdTC} from "../../../redux/reducers/dealsReducer";
import DetailsTabNavigation from "../DetailsTabNavigation/DetailsTabNavigation";
import configuration from "../../../utils/configuration";

const Survey = ({enqueueSnackbar}) => {
  const match = useRouteMatch();
  const user = useSelector((state) => state.AuthReducer.currentUser);
  const [surveys, setSurveys] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastpage] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDealInfoByIdTC(match.params.id));
  }, [dispatch, match.params.id]);
  useEffect(() => {
    let isSubscribed = true;
    getSurveyData(match.params.id)
      .then((res) => {
        if (isSubscribed) {
          setSurveys(
            res?.data.map((el) => {
              const {
                full_name,
                survey_submission: {rate, survey_answers},
              } = el;
              return {
                full_name,
                rate,
                survey_answers,
              };
            }),
          );
        }
      })
      .catch((error) =>
        enqueueSnackbar(error.message, {
          variant: "error",
          autoHideDuration: configuration.autoHideErrorDuration,
        }),
      );

    setCurrentPage(1);
    setLastpage(1);
    return () => (isSubscribed = false);
  }, [currentPage]);
  // const windowWidth = useWindowWidth()
  return (
    <div className="feedback__wrapper">
      <DetailsTabNavigation
        showDocumentsTab={user.role !== "Company Admin"}
      />

      {surveys === null ? (
        <DeededCircularLoader />
      ) : (
        <div className="feedback__container">
          <SurveyList surveys={surveys} />
        </div>
      )}
    </div>
  );
};

export default withSnackbar(Survey);
