import moment from "moment/moment";

export const clearCloseLapseDataAfterUnmounting = (
  handleChange: (object: unknown) => void,
) => {
  Object.keys(startValues).map((name) => {
    handleChange({
      target: {
        name: name,
        value: startValues[name as keyof typeof startValues],
      },
    });
  });
};

const startValues = {
  isRepeat: false,
  repeat: "Daily",
  isAllDay: false,
  isShowMobileSigners: false,
  province: "AB",
  dateStart: moment(new Date()).format("MM/DD/YYYY"),
  dateEnd: moment(new Date()).format("MM/DD/YYYY"),
  timeStart: new Date(),
  timeEnd: new Date(),
  timeUntilForDays: new Date(),
  repeatEveryDays: 1,
  repeatEveryWeeks: 1,
  repeatEveryMonths: 1,
  repeatEveryYears: 1,
  selectedDaysOfWeek: [],
  selectedDaysOfMonth: [],
  selectedMothsOfYear: [],
  mobileSignerId: null,
  closing_id: null,
};
