import axios from "axios";
import React, {useState, useEffect} from "react";
import {baseURL} from "../../../../api";
import {CARD_STATUS_MISSED, CARD_STATUS_APPROVED, CARD_STATUS_PENDING} from ".";
import useDocumentDownload from "../../DocumentView/useDocumentDownload";

/**
 * Known document types
 *
 * [SALE]
 * Mortgage Statement
 * Property Tax Bill Statement
 * Void Cheque
 * Rental Agreement
 *
 * [PURCHASE]
 *
 *
 */
export const DocumentTypeNames = {
  MORTGAGE_STATEMENT: "Current Mortgage Statement(s) and Lines of Credit Statement(s)",
  PROPERTY_TAX_BILL_STATEMENT: "Property Tax Bill Statement",
  VOID_CHEQUE: "Void Cheque",
  RENTAL_AGREEMENT: "Equipment Rental Agreement(s)",
  HOME_INSURANCE: "Proof of home insurance",
};

/**
 *
 *
 * @param {Object} deal
 * @param {string} documentType
 */
function findDealDocument(deal, documentType) {
  const selector = (x) => documentType.includes(x.document_type);
  const findDoc = deal.missingDocuments.filter(selector);

  if (findDoc.length === 1) {
    // It's inside missing documents
    return {found: false};
  } else {
    // check the agreements
    const filtered = deal.agreements.filter(selector);
    if (filtered.length) {
      return {doc: filtered[0], found: true};
    }
    // It's not present anywhere
    return {found: false};
  }
}

// const userID = localStorage.getItem("user_id");
// const token = localStorage.getItem(`token_${userID}`);

function DownloadDocComponent({filename, documentId, type}) {
  const onDocumentDownload = useDocumentDownload(filename, type, documentId);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        cursor: "pointer",
      }}
      onClick={onDocumentDownload}
    >
      <div style={{marginRight: 12.5}}>
        <svg
          width="16"
          height="22"
          viewBox="0 0 16 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.03049 7.00049V0.500488H0.500488V21.5005H15.5005V7.00049H9.03049ZM8.00049 18.0005L5.00049 15.0005L5.74549 14.2555L7.47549 15.9905V10.0005H8.52549V15.9905L10.2555 14.2555L11.0005 15.0005L8.00049 18.0005Z"
            fill="#7F7F7F"
          />
        </svg>
      </div>
      <div> {filename}</div>
    </div>
  );
}

/**
 * A general function for setting document-related accessor
 *
 * @param {string} documentType Server-friendly document type name
 */
export function getDocumentCardData(documentType) {
  return {
    accessor: (client) => {
      const queriedDoc = findDealDocument(client.cachedDeal, documentType);
      if (!queriedDoc.found) {
        return {
          status: CARD_STATUS_MISSED,
        };
      } else {
        return {
          content: {
            onRender: () => {
              return (
                <DownloadDocComponent
                  filename={queriedDoc.doc.original_filename}
                  documentId={queriedDoc.doc.id}
                  type={queriedDoc.doc.type}
                />
              );
            },
          },
          // content: queriedDoc.doc.original_filename,
          status: queriedDoc.doc.status.toLowerCase(),
        };
      }
    },
    shouldHideDefaultButtons: (client) => {
      return findDealDocument(client.cachedDeal, documentType).found;
    },
    editDataAccessor: (client) => {
      const foundDocument = findDealDocument(
        client.cachedDeal,
        documentType,
      ).doc;
      return {
        dealId: client.deal_id,
        doc: foundDocument,
        documentType: documentType,
      };
    },
    onCustomUpdate: (value, onUpdateSuccess, onUpdateError) => {
      const {dealId, agreements} = value;
      const payload = {agreements};
      axios
        .post(`${baseURL}/deals/${dealId}/upload`, payload)
        .then((res) => {
          onUpdateSuccess();
        })
        .catch((error) => {
          onUpdateError(error.response.data.error);
        });
    },
  };
}
