import moment from "moment/moment";
import * as yup from "yup";
import {
  createClosingDateTC,
  updateClosingDateTC,
} from "../../../../redux/reducers/calendarReducer";
import {Dispatch} from "react";

export const timeBlockFormikSettings = {
  initialValues: {
    isRepeat: false,
    repeat: "Daily",
    isAllDay: false,
    isShowMobileSigners: false,
    province: "AB",
    dateStart: moment(new Date()).format("MM/DD/YYYY"),
    dateEnd: moment(new Date()).format("MM/DD/YYYY"),
    timeStart: new Date(),
    timeEnd: new Date(),
    repeatEveryDays: 1,
    repeatEveryWeeks: 1,
    repeatEveryMonths: 1,
    repeatEveryYears: 1,
    selectedDaysOfWeek: [],
    selectedDaysOfMonth: [],
    selectedMothsOfYear: [],
    mobileSignerId: null,
    closing_id: null,
  },
  schema: yup.object().shape({
    isRepeat: yup.boolean().required(),
    repeat: yup.string().required(),
    isAllDay: yup.boolean().required(),
    isShowMobileSigners: yup.boolean().required(),
    province: yup.string().required(),
    dateStart: yup.string().required(),
    dateEnd: yup
      .string()
      .required()
      .test("dateEnd", " ", function (dateEnd) {
        return dateEnd! >= this.parent.dateStart;
      }),

    timeStart: yup.date().required(),
    timeEnd: yup
      .date()
      .required()
      .test("timeEnd", " ", function (timeEnd) {
        if (this.parent.dateStart === this.parent.dateEnd) {
          return timeEnd! >= this.parent.timeStart;
        } else {
          return true;
        }
      }),
    repeatEveryDays: yup.number().required(),
    repeatEveryWeeks: yup.number().required(),
    repeatEveryMonths: yup.number().required(),
    repeatEveryYears: yup.number().required(),
    selectedDaysOfWeek: yup.array(),
    selectedDaysOfMonth: yup.array(),
    selectedMothsOfYear: yup.array(),
    mobileSignerId: yup.number().nullable().when("isShowMobileSigners", {
      is: true,
      then: () => yup.number().required(),
    }),
    closing_id: yup.number().nullable(),
  }),
  onSubmit: (
    isRepeat: boolean,
    repeat: string,
    isAllDay: boolean,
    isShowMobileSigners: boolean,
    province: string,
    dateStart: string,
    dateEnd: string,
    timeStart: Date,
    timeEnd: Date,
    repeatEveryDays: number,
    repeatEveryWeeks: number,
    repeatEveryMonths: number,
    repeatEveryYears: number,
    selectedDaysOfWeek: unknown[],
    selectedDaysOfMonth: unknown[],
    selectedMothsOfYear: unknown[],
    mobileSignerId: number | null,
    closing_id: number | null,
    dispatch: Dispatch<unknown>,
    getBlockedEffectFunction: () => void,
  ) => {
    const payload = {
      is_recurring: isRepeat,
      is_full_day: isAllDay,
      for_user: isShowMobileSigners,
      state: province,
      start_date: `${moment(dateStart).format("M/D/YYYY")} ${moment(
        timeStart,
      ).format("HH:mm:ss")}`,
      end_date: `${moment(dateEnd).format("M/D/YYYY")} ${moment(timeEnd).format(
        "HH:mm:ss",
      )}`,
      start_time: `${moment(dateStart).format("M/D/YYYY")} ${moment(
        timeStart,
      ).format("HH:mm:ss")}`,
      end_time: `${moment(dateEnd).format("M/D/YYYY")} ${moment(timeEnd).format(
        "HH:mm:ss",
      )}`,
      user_id: mobileSignerId,
      recurring_pattern: isRepeat
        ? {
            recurring_type: repeat,
            interval:
              repeat === "Daily"
                ? repeatEveryDays
                : repeat === "Weekly"
                ? repeatEveryWeeks
                : repeat === "Monthly"
                ? repeatEveryMonths
                : repeat === "Yearly"
                ? repeatEveryYears
                : null,
            repeat_by_days:
              selectedDaysOfWeek.length === 0 ? null : selectedDaysOfWeek,
            repeat_by_month_days:
              selectedDaysOfMonth.length === 0 ? null : selectedDaysOfMonth,
            repeat_by_months:
              selectedMothsOfYear.length === 0 ? null : selectedMothsOfYear,
          }
        : null,
    };

    if (!closing_id) {
      dispatch(createClosingDateTC(payload, getBlockedEffectFunction));
    } else {
      dispatch(
        updateClosingDateTC(closing_id, payload, getBlockedEffectFunction),
      );
    }
  },
};
