import React from "react";

const StorageBigPdfSvg = () => {
  return (
    <svg
      width="109"
      height="109"
      viewBox="0 0 109 109"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.8436 16.1797C23.6178 16.1797 23.4012 16.2694 23.2416 16.4291C23.0819 16.5888 22.9922 16.8054 22.9922 17.0312V91.9688C22.9922 92.1946 23.0819 92.4112 23.2416 92.5709C23.4012 92.7306 23.6178 92.8203 23.8436 92.8203H85.1564C85.3822 92.8203 85.5987 92.7306 85.7584 92.5709C85.9181 92.4112 86.0078 92.1946 86.0078 91.9688V38.5269L63.6617 16.1797H23.8436C23.8436 16.1797 23.8436 16.1797 23.8436 16.1797ZM19.6286 12.8163C20.7465 11.6984 22.2626 11.0704 23.8435 11.0703H64.7199C65.3975 11.0703 66.0473 11.3395 66.5264 11.8186L90.369 35.6624C90.8481 36.1414 91.1172 36.7912 91.1172 37.4688V91.9688C91.1172 93.5496 90.4892 95.0658 89.3714 96.1837C88.2535 97.3016 86.7374 97.9296 85.1565 97.9297H23.8435C22.2626 97.9296 20.7465 97.3016 19.6286 96.1837C18.5108 95.0658 17.8828 93.5496 17.8828 91.9688V17.0313C17.8828 17.0313 17.8828 17.0313 17.8828 17.0313C17.8828 15.4504 18.5108 13.9342 19.6286 12.8163Z"
        fill="#828282"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.7187 11.0703C66.1297 11.0703 67.2734 12.2141 67.2734 13.625V34.9141H88.5659C89.9768 34.9141 91.1206 36.0578 91.1206 37.4688C91.1206 38.8797 89.9768 40.0234 88.5659 40.0234H64.7187C63.3078 40.0234 62.1641 38.8797 62.1641 37.4688V13.625C62.1641 12.2141 63.3078 11.0703 64.7187 11.0703Z"
        fill="#828282"
      />
      <path
        d="M32.9463 81.9148C32.2923 81.9148 31.7746 81.724 31.3931 81.3425C31.0116 80.961 30.8208 80.4433 30.8208 79.7893V64.5293C30.8208 63.8935 30.9934 63.403 31.3386 63.0578C31.6837 62.7126 32.1742 62.54 32.8101 62.54H39.6498C41.739 62.54 43.3649 63.0669 44.5276 64.1205C45.6902 65.1742 46.2716 66.6457 46.2716 68.535C46.2716 70.4244 45.6902 71.8959 44.5276 72.9495C43.3649 74.0032 41.739 74.53 39.6498 74.53H35.0718V79.7893C35.0718 80.4433 34.8811 80.961 34.4996 81.3425C34.1181 81.724 33.6003 81.9148 32.9463 81.9148ZM39.1048 71.3145C41.2303 71.3145 42.2931 70.3971 42.2931 68.5623C42.2931 66.7093 41.2303 65.7828 39.1048 65.7828H35.0718V71.3145H39.1048Z"
        fill="#828282"
      />
      <path
        d="M49.3091 81.7513C48.6732 81.7513 48.1827 81.5787 47.8376 81.2335C47.4924 80.8884 47.3198 80.3979 47.3198 79.762V64.5293C47.3198 63.8935 47.4924 63.403 47.8376 63.0578C48.1827 62.7126 48.6732 62.54 49.3091 62.54H54.7863C56.8755 62.54 58.674 62.9215 60.1818 63.6845C61.6897 64.4475 62.8432 65.5466 63.6426 66.9818C64.4419 68.417 64.8416 70.1337 64.8416 72.132C64.8416 74.1304 64.4419 75.8562 63.6426 77.3095C62.8614 78.7447 61.7169 79.8438 60.2091 80.6068C58.7012 81.3698 56.8937 81.7513 54.7863 81.7513H49.3091ZM54.4866 78.3178C58.5741 78.3178 60.6178 76.2559 60.6178 72.132C60.6178 68.0264 58.5741 65.9735 54.4866 65.9735H51.5708V78.3178H54.4866Z"
        fill="#828282"
      />
      <path
        d="M68.5257 81.9148C67.8898 81.9148 67.3721 81.724 66.9724 81.3425C66.5909 80.9429 66.4001 80.4251 66.4001 79.7893V64.5293C66.4001 63.8935 66.5727 63.403 66.9179 63.0578C67.2631 62.7126 67.7536 62.54 68.3894 62.54H77.7634C78.4537 62.54 78.9624 62.6763 79.2894 62.9488C79.6164 63.2213 79.7799 63.6482 79.7799 64.2295C79.7799 64.7927 79.6164 65.2105 79.2894 65.483C78.9624 65.7555 78.4537 65.8918 77.7634 65.8918H70.5966V70.3063H77.2184C77.9087 70.3063 78.4174 70.4425 78.7444 70.715C79.0714 70.9875 79.2349 71.4145 79.2349 71.9958C79.2349 72.559 79.0714 72.9768 78.7444 73.2493C78.4174 73.5218 77.9087 73.658 77.2184 73.658H70.5966V79.7893C70.5966 80.4433 70.4059 80.961 70.0244 81.3425C69.6611 81.724 69.1615 81.9148 68.5257 81.9148Z"
        fill="#828282"
      />
    </svg>
  );
};

export default StorageBigPdfSvg;
