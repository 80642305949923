import { Question, ElementFactory, Serializer } from "survey-core";
import { ReactQuestionFactory, SurveyQuestionElementBase } from "survey-react-ui";
import NumberFormat from "react-number-format";
import { createElement } from "react";
import { getFormattedPostalCode } from "utils/getFormattedPostalCode";

const CUSTOM_TYPE = "input-custom-format";

export class QuestionCustomFormatModel extends Question {
    getType() {
        return CUSTOM_TYPE;
    }

    get inputFormatType() {
        return this.getPropertyValue("inputFormatType");
    }
    set inputFormatType(val) {
        this.setPropertyValue("inputFormatType", val);
    }
    get placeholder() {
        return this.getPropertyValue("placeholder");
    }
    set placeholder(val) {
        this.setPropertyValue("placeholder", val);
    }
    getControlClass() {
        const classes = ['sd-input', 'sd-text'];
        this.hasCssError() && classes.push('sd-input--error');
        return classes.join(' ');
    }

}

export function registerInputCustomFormat() {
    ElementFactory.Instance.registerElement(CUSTOM_TYPE, (name) => {
        return new QuestionCustomFormatModel(name);
    });
}

Serializer.addClass(
    CUSTOM_TYPE,
    [{
        name: "inputFormatType",
        default: "decimal",
        choices: ["decimal", "positive-decimal", "phone", "postalcode"],
        category: "general",
        visibleIndex: 2 // Place after the Name and Title
    },
    {
        name: "placeholder",
        default: "",
        category: "general",
    }],
    function () {
        return new QuestionCustomFormatModel("");
    },
    "question"
);

// A class that renders questions of the new type in the UI
export class SurveyQuestionCustomFormat extends SurveyQuestionElementBase {
    constructor(props) {
        super(props);
        this.state = { value: this.question.value };
    }
    get question() {
        return this.questionBase;
    }
    get value() {
        return this.question.value;
    }
    get type() {
        return this.question.inputFormatType;
    }
    handleInputChange = (data) => {
        this.question.value = data;
    };
    // Support the read-only and design modes
    get style() {
        return this.question.getPropertyValue("readOnly") ||
            this.question.isDesignMode ? { pointerEvents: "none" } : undefined;
    }

    renderInput(type) {
        const inputClass = (this.question).getControlClass();
        const props = {};
        if (type === "decimal") {
            props.thousandSeparator = ",";
            props.decimalScale = 2;
            props.onValueChange = ({ value }) => this.handleInputChange(value);
        }

        if (type === "positive-decimal") {
            props.thousandSeparator = ",";
            props.decimalScale = 2;
            props.allowNegative = false;
            props.prefix = '$';
            props.onValueChange = ({ value }) => this.handleInputChange(value);
        }

        if (type === "phone") {
            props.format = "###-###-####";
            props.onChange = ({ target }) => this.handleInputChange(target.value);
        }

        if (type === "postalcode") {
            props.placeholder = this.question.getPropertyValue("placeholder");
            props.onChange = ({ target }) => this.handleInputChange(getFormattedPostalCode(target.value));

            return <input
                {...props}
                value={this.value || ''}
                className={inputClass}
            />
        }
        return <NumberFormat
            {...props}
            value={this.value}
            className={inputClass}
            onValueChange={({ value }) => this.handleInputChange(value)}
        />
    }

    renderElement() {
        return <div style={this.style}>{this.renderInput(this.type)}</div>;
    }
}

ReactQuestionFactory.Instance.registerQuestion(CUSTOM_TYPE, (props) => {
    return createElement(SurveyQuestionCustomFormat, props);
});