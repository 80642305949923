export const ActionType = {
  // AUTH TYPES
  AUTH_LOADER_ON: "AUTH_LOADER_ON",
  USER_UPDATE: "USER_UPDATE",
  USER_LOGIN: "USER_LOGIN",
  USER_LOGOUT: "USER_LOGOUT",
  LOGIN_ERROR: "LOGIN_ERROR",
  REMOVE_ERROR: "REMOVE_ERROR",
  // DEALS TYPES
  DEALS_LOADER_ON: "DEALS_LOADER_ON",
  DEAL_SEARCH: "DEAL_SEARCH",
  DEAL_CREATE: "DEAL_CREATE",
  SET_DEAL_DATA: "SET_DEAL_DATA",
  SET_DEAL_LIST_LOADING: "SET_DEAL_LIST_LOADING",
  SET_DEALS: "SET_DEALS",
  DEAL_CLEAR: "DEAL_CLEAR",
  DEAL_FETCH_BY_ID: "DEAL_FETCH_BY_ID",
  SET_STAGE: "SET_STAGE",
  SHOW_CLOSED: "SHOW_CLOSED",
  SHOW_NEW: "SHOW_NEW",
  // SALE INTAKE ACTION TYPES
  SALE_RESET: "SALE_RESET",
  SET_TMP_DATA_SALE_INTAKE_REQUEST: "SET_TMP_DATA_SALE_INTAKE_REQUEST",
  SALE_SET_OWNERS_NUMBER: "SALE_SET_OWNERS_NUMBER",
  SALE_SET_SELLER_FULLNAME: "SALE_SET_SELLER_FULLNAME",
  SALE_SET_CORPORATE_SALE: "SALE_SET_CORPORATE_SALE",
  SALE_SET_CURRENTLY_RESIDE: "SALE_SET_CURRENTLY_RESIDE",
  SALE_SET_RESIDENT_CANADA: "SALE_SET_RESIDENT_CANADA",
  SALE_SET_BIRTH_DAY: "SALE_SET_BIRTH_DAY",
  SALE_SET_FORWARDING_ADDRESS: "SALE_SET_FORWARDING_ADDRESS",
  SALE_SET_PHONE_NUMBER: "SALE_SET_PHONE_NUMBER",
  SALE_SET_EMPLOYMENT_STATUS: "SALE_SET_EMPLOYMENT_STATUS",
  SALE_SET_EMPLOYER_NAME: "SALE_SET_EMPLOYER_NAME",
  SALE_SET_EMPLOYER_ADDRESS: "SALE_SET_EMPLOYER_ADDRESS",
  SALE_SET_SUPOUSAL_STATUS: "SALE_SET_SUPOUSAL_STATUS",
  SALE_SET_IDENTIFICATION: "SALE_SET_IDENTIFICATION",
  SALE_SET_RENTAL_PROPERY: "SALE_SET_RENTAL_PROPERY",
  SALE_SET_MORTGAGE_LINES_CREDIT: "SALE_SET_MORTGAGE_LINES_CREDIT",
  SALE_SET_SELLING_CONDOMINIUM: "SALE_SET_SELLING_CONDOMINIUM",
  SALE_SET_CURRENT_MORTGAGE_LENDER: "SALE_SET_CURRENT_MORTGAGE_LENDER",
  SALE_SET_BRANCH_ADDRESS_LENDER: "SALE_SET_BRANCH_ADDRESS_LENDER",
  SALE_SET_SIGNING_APPOINTMENT: "SALE_SET_SIGNING_APPOINTMENT",
  SALE_SET_TYPE_PROPERTY: "SALE_SET_TYPE_PROPERTY",
  SALE_SET_RENTAL_CONTRACTS: "SALE_SET_RENTAL_CONTRACTS",
  SALE_SET_CURRENT_MORTGAGE_STATEMENT: "SALE_SET_CURRENT_MORTGAGE_STATEMENT",
  SALE_SET_PROPERTY_TAX_BILL_STATEMENT: "SALE_SET_PROPERTY_TAX_BILL_STATEMENT",
  SALE_SET_VOID_CHEQUE: "SALE_SET_VOID_CHEQUE",
  SALE_SET_ADDITIONAL_MORTGAGES_STETEMENT:
    "SALE_SET_ADDITIONAL_MORTGAGES_STETEMENT",
  SALE_SET_ADDITIONAL_INFO: "SALE_SET_ADDITIONAL_INFO",
  SET_SALE_EMAIL: "SET_SALE_EMAIL",
  SALE_SET_TANK_WELL_FUEL: "SALE_SET_TANK_WELL_FUEL",
  // SURVEY INTAKE ACTION TYPES
  SURVEY_RESET: "SURVEY_RESET",
  SURVEY_SET_LAST_NAME: "SURVEY_SET_LAST_NAME",
  SURVEY_SET_ANSWER_ONE: "SURVEY_SET_ANSWER_ONE",
  SURVEY_SET_ANSWER_TWO: "SURVEY_SET_ANSWER_TWO",
  SURVEY_SET_ANSWER_THREE: "SURVEY_SET_ANSWER_THREE",
  SURVEY_SET_RATING: "SURVEY_SET_RATING",
  // PURCHASE INTAKE ACTION TYPES
  SET_PURCHASE_CLIENT_ID: "SET_PURCHASE_CLIENT_ID",
  SET_TMP_DATA_INTAKE_REQUEST: "SET_TMP_DATA_INTAKE_REQUEST",
  SALE_SET_SEPARATION_AGREEMENT: "SALE_SET_SEPARATION_AGREEMENT",
  PURCHASE_SET_SEPARATION_AGREEMENT: "PURCHASE_SET_SEPARATION_AGREEMENT",
  PURCHASE_RESET: "PURCHASE_RESET",
  PURCHASE_SET_OWNERS_NUMBER: "PURCHASE_SET_OWNERS_NUMBER",
  PURCHASE_SET_PURCHASER_FULLNAME: "PURCHASE_SET_PURCHASER_FULLNAME",
  PURCHASE_SET_CORPORATE_PURCHASE: "PURCHASE_SET_CORPORATE_PURCHASE",
  PURCHASE_SET_BIRTH_DAY: "PURCHASE_SET_BIRTH_DAY",
  PURCHASE_SET_CURRENT_ADDRESS: "PURCHASE_SET_CURRENT_ADDRESS",
  PURCHASE_SET_PHONE_NUMBER: "PURCHASE_SET_PHONE_NUMBER",
  PURCHASE_SET_EMAIL: "PURCHASE_SET_EMAIL",
  PURCHASE_SET_EMPLOYMENT_STATUS: "PURCHASE_SET_EMPLOYMENT_STATUS",
  PURCHASE_SET_EMPLOYER_NAME: "PURCHASE_SET_EMPLOYER_NAME",
  PURCHASE_SET_EMPLOYER_ADDRESS: "PURCHASE_SET_EMPLOYER_ADDRESS",
  PURCHASE_SET_SUPOUSAL_STATUS: "PURCHASE_SET_SUPOUSAL_STATUS",
  PURCHASE_SET_CITIZENSHIP_STATUS: "PURCHASE_SET_CITIZENSHIP_STATUS",
  PURCHASE_SET_OWNED_PROPERTY: "PURCHASE_SET_OWNED_PROPERTY",
  PURCHASE_SET_RESIDED_ADDRESS: "PURCHASE_SET_RESIDED_ADDRESS",
  PURCHASE_SET_PHYSICALLY_PRESENT: "PURCHASE_SET_PHYSICALLY_PRESENT",
  PURCHASE_SET_IDENTIFICATION: "PURCHASE_SET_IDENTIFICATION",
  PURCHASE_SET_SIGNING_APPOINTMENT: "PURCHASE_SET_SIGNING_APPOINTMENT",
  PURCHASE_SET_TITLE_PROPERTY: "PURCHASE_SET_TITLE_PROPERTY",
  PURCHASE_SET_TYPE_PROPERTY: "PURCHASE_SET_TYPE_PROPERTY",
  PURCHASE_SET_RENTED_OUT: "PURCHASE_SET_RENTED_OUT",
  PURCHASE_SET_TANK_WELL: "PURCHASE_SET_TANK_WELL",
  PURCHASE_SET_PRIMARY_USE: "PURCHASE_SET_PRIMARY_USE",
  PURCHASE_SET_TAKING_MORTGAGE: "PURCHASE_SET_TAKING_MORTGAGE",
  PURCHASE_SET_LENDER_INFORMATION: "PURCHASE_SET_LENDER_INFORMATION",
  PURCHASE_SET_HOME_INSURANCE_POLICY: "PURCHASE_SET_HOME_INSURANCE_POLICY",
  PURCHASE_SET_ADDITIONAL_INFO: "PURCHASE_SET_ADDITIONAL_INFO",
  PURCHASE_SET_SOURCE_OF_FOUNDS_INFO: "PURCHASE_SET_SOURCE_OF_FOUNDS_INFO",
  PURCHASE_SET_MORTGAGE_REFERRING: "PURCHASE_SET_MORTGAGE_REFERRING",
  // MORTGAGE INTAKE ACTION TYPES
  MORTGAGE_RESET: "MORTGAGE_RESET",
  MORTGAGE_SET_OWNERS_NUMBER: "MORTGAGE_SET_OWNERS_NUMBER",
  MORTGAGE_SET_MORTGAGE_FULLNAME: "MORTGAGE_SET_MORTGAGE_FULLNAME",
  MORTGAGE_SET_CORPORATE_REFINANCE: "MORTGAGE_SET_CORPORATE_REFINANCE",
  MORTGAGE_SET_BIRTH_DAY: "MORTGAGE_SET_BIRTH_DAY",
  MORTGAGE_SET_CURRENT_ADDRESS: "MORTGAGE_SET_CURRENT_ADDRESS",
  MORTGAGE_SET_PHONE_NUMBER: "MORTGAGE_SET_PHONE_NUMBER",
  MORTGAGE_SET_EMPLOYMENT_STATUS: "MORTGAGE_SET_EMPLOYMENT_STATUS",
  MORTGAGE_SET_EMPLOYER_NAME: "MORTGAGE_SET_EMPLOYER_NAME",
  MORTGAGE_SET_EMPLOYER_ADDRESS: "MORTGAGE_SET_EMPLOYER_ADDRESS",
  MORTGAGE_SET_SUPOUSAL_STATUS: "MORTGAGE_SET_SUPOUSAL_STATUS",
  MORTGAGE_SET_CITIZENSHIP_STATUS: "MORTGAGE_SET_CITIZENSHIP_STATUS",
  MORTGAGE_SET_IDENTIFICATION: "MORTGAGE_SET_IDENTIFICATION",
  MORTGAGE_SET_SIGNING_APPOINTMENT: "MORTGAGE_SET_SIGNING_APPOINTMENT",
  MORTGAGE_SET_TYPE_PROPERTY: "MORTGAGE_SET_TYPE_PROPERTY",
  MORTGAGE_SET_OWNERSHIP_PROPERTY: "MORTGAGE_SET_OWNERSHIP_PROPERTY",
  MORTGAGE_SET_CONDOMINIUM: "MORTGAGE_SET_CONDOMINIUM",
  MORTGAGE_SET_NEW_MORTGAGE: "MORTGAGE_SET_NEW_MORTGAGE",
  MORTGAGE_SET_SCHEDULED_CLOSING_DATE: "MORTGAGE_SET_SCHEDULED_CLOSING_DATE",
  MORTGAGE_SET_DEBT_LIST: "MORTGAGE_SET_DEBT_LIST",
  MORTGAGE_SET_CURRENT_MORTGAGE_STATEMENT:
    "MORTGAGE_SET_CURRENT_MORTGAGE_STATEMENT",
  MORTGAGE_SET_HOME_INSURANCE_POLICY: "MORTGAGE_SET_HOME_INSURANCE_POLICY",
  MORTGAGE_SET_PROPERTY_TAX_BILL_STATEMENT:
    "MORTGAGE_SET_PROPERTY_TAX_BILL_STATEMENT",
  MORTGAGE_SET_VOID_CHEQUE: "MORTGAGE_SET_VOID_CHEQUE",
  MORTGAGE_SET_ADDITIONAL_INFO: "MORTGAGE_SET_ADDITIONAL_INFO",
  MORTGAGE_SET_SOURCE_OF_FOUNDS_INFO: "MORTGAGE_SET_SOURCE_OF_FOUNDS_INFO",
  MORTGAGE_SET_TANK_WELL_FUEL: "MORTGAGE_SET_TANK_WELL_FUEL",
  SET_MORTGAGE_EMAIL: "SET_MORTGAGE_EMAIL",
  SET_TMP_DATA_MORTGAGE_INTAKE_REQUEST: "SET_TMP_DATA_MORTGAGE_INTAKE_REQUEST",
  MORTGAGE_SET_SEPARATION_AGREEMENT: "MORTGAGE_SET_SEPARATION_AGREEMENT",
  // LENDER INTAKE ACTION TYPES
  LENDER_RESET: "LENDER_RESET",
  SET_TMP_DATA_LENDER_INTAKE_REQUEST: "SET_TMP_DATA_LENDER_INTAKE_REQUEST",
  LENDER_SET_OWNERS_NUMBER: "LENDER_SET_OWNERS_NUMBER",
  LENDER_SET_LENDER_FULLNAME: "LENDER_SET_LENDER_FULLNAME",
  LENDER_SET_CORPORATE_REFINANCE: "LENDER_SET_CORPORATE_REFINANCE",
  LENDER_SET_BIRTH_DAY: "LENDER_SET_BIRTH_DAY",
  LENDER_SET_CURRENT_ADDRESS: "LENDER_SET_CURRENT_ADDRESS",
  LENDER_SET_PHONE_NUMBER: "LENDER_SET_PHONE_NUMBER",
  LENDER_SET_EMPLOYMENT_STATUS: "LENDER_SET_EMPLOYMENT_STATUS",
  LENDER_SET_EMPLOYER_NAME: "LENDER_SET_EMPLOYER_NAME",
  LENDER_SET_EMPLOYER_ADDRESS: "LENDER_SET_EMPLOYER_ADDRESS",
  LENDER_SET_SUPOUSAL_STATUS: "LENDER_SET_SUPOUSAL_STATUS",
  LENDER_SET_CITIZENSHIP_STATUS: "LENDER_SET_CITIZENSHIP_STATUS",
  LENDER_SET_IDENTIFICATION: "LENDER_SET_IDENTIFICATION",
  LENDER_SET_SIGNING_APPOINTMENT: "LENDER_SET_SIGNING_APPOINTMENT",
  LENDER_SET_OWNERSHIP_PROPERTY: "LENDER_SET_OWNERSHIP_PROPERTY",
  LENDER_SET_CONDOMINIUM: "LENDER_SET_CONDOMINIUM",
  LENDER_SET_NEW_MORTGAGE: "LENDER_SET_NEW_MORTGAGE",
  LENDER_SET_SCHEDULED_CLOSING_DATE: "LENDER_SET_SCHEDULED_CLOSING_DATE",
  LENDER_SET_DEBT_LIST: "LENDER_SET_DEBT_LIST",
  LENDER_SET_CURRENT_MORTGAGE_STATEMENT:
    "LENDER_SET_CURRENT_MORTGAGE_STATEMENT",
  LENDER_SET_HOME_INSURANCE_POLICY: "LENDER_SET_HOME_INSURANCE_POLICY",
  LENDER_SET_PROPERTY_TAX_BILL_STATEMENT:
    "LENDER_SET_PROPERTY_TAX_BILL_STATEMENT",
  LENDER_SET_VOID_CHEQUE: "LENDER_SET_VOID_CHEQUE",
  LENDER_SET_ADDITIONAL_INFO: "LENDER_SET_ADDITIONAL_INFO",
  LENDER_SET_SOUCE_OF_FOUNDS_INFO: "LENDER_SET_SOUCE_OF_FOUNDS_INFO",
  LENDER_SET_SEPARATION_AGREEMENT: "LENDER_SET_SEPARATION_AGREEMENT",
  SET_LENDER_EMAIL: "SET_LENDER_EMAIL",
};
