import { ReactNode } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Logo from "assets/img/deeded.svg";
import { Body } from 'components/Common/Typography/index';
import constants from 'styles/constants';
import configuration from 'utils/configuration';
import DeededMobileLogo from 'components/Svg/DeededMobileLogo';
import Colors from 'components/CommonDashboard/Styling/Colors';

const pageUrl = configuration.webPageUrl || "https://deeded.ca";
export interface HeaderProps {
    title: string,
    fixed?: boolean,
    rightComponent?: ReactNode,
    mobile?: boolean,
    maxWidth?: string | number,
    transparent?: boolean,
}
export const Header = ({ title, fixed = true, rightComponent, mobile = false, maxWidth, transparent = false }: HeaderProps) => (
    <Stack sx={{ backgroundColor: transparent ? 'transparent' : Colors.WHITE }}>
        <HeaderContainer className="common-header" fixed={fixed} sx={{ maxWidth }} transparent={transparent}>
            <a href={pageUrl} target='_blank' style={{ alignSelf: title ? 'flex-start' : 'center' }}>
                {mobile ? <DeededMobileLogo /> : <img src={Logo} width={136} height={25} alt="Logo" />}
            </a>
            <HeaderTitle>{title}</HeaderTitle>
            <CustomComponentBox>
                {rightComponent}
            </CustomComponentBox>
        </HeaderContainer>
    </Stack>

);

type HeaderContainerProps = BoxProps & {
    fixed?: boolean,
    transparent?: boolean,
    centerLogo?: boolean
};

const HeaderContainer = styled(Stack, {
    shouldForwardProp: (props) => !["fixed", "transparent"].includes(props as string)
})<HeaderContainerProps>(({ theme, fixed = true, transparent = false }) => ({
    padding: "2.4rem",
    flexFlow: "wrap row",
    alignItems: "center",
    alignSelf: 'center',
    zIndex: "999",
    justifyContent: "space-between",
    position: fixed ? 'fixed' : 'inherit',
    backgroundColor: transparent ? 'transparent' : Colors.WHITE,
    width: "100%",
    [theme.breakpoints.down("mobile")]: {
        height: 'auto',
        padding: "2rem 2.8rem 0 2.8rem ",
    },
}));

const HeaderTitle = styled(Body)(({ theme }) => ({
    color: constants.colors.gray800,
    textAlign: "center",
    fontSize: '1.8rem',
    fontWeight: '600',
    [theme.breakpoints.down("mobile")]: {
        paddingTop: "2vh",
        order: 3,
        alignSelf: 'flex-end',
        flexBasis: '100%'
    }
}));

const CustomComponentBox = styled(Box)(({ theme }) => ({
    minWidth: '13.6rem',
    minHeight: '3rem',
    [theme.breakpoints.down("mobile")]: {
        minWidth: 0,
    }
}));