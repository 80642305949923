import {StaffUser} from "redux/reducers/configReducer/staffCardReducer";
import {ContactDraft, User, UserParticipant, UserStaff} from "./user";
import {ClientOnboarding} from "./intakeForm";
export type DealType = "Purchase" | "Sale" | "Mortgage" | "Lender";
export type StatusType = 'Missing' | 'Pending' | 'Approved' | 'Rejected';

export type DealStageType = {
  id: number;
  deal_type: DealType;
  number: number;
  name: string;
  description: string;
};

export type DealCompletedStageType = {
  deal_stage_id: number;
  stage_reached: string;
  reviewed: null | unknown;
  survey: {status: null | unknown; date: null | unknown};
};

export type DocumentUpdateType = {
  created_at: string;
  updated_at: string;
  action: string;
  comment: null | unknown;
  doc_type: null | unknown;
  user: User;
  rejection_reason: string | null;
};

export type DocumentType = {
  id: number;
  deal_id: number;
  original_filename: string | null;
  original_extension: string | null;
  original_size: string | null;
  type: string | null;
  status: StatusType | 'Temporary';
  document_type: string;
  alias: string;
  participant_number: unknown | null;
  participant_id: unknown | null;
  participant_type: unknown | null;
  is_additional: number;
  storage_id: number | null;
  document_updates: DocumentUpdateType[];
  description?: string;
  original_type: string;
};

export interface BaseAddress {
  address: string;
  city: string;
  state: string;
  code: string;
  unit: unknown | null;
  email: string | null;
  phone: string | null;
  fax: string | null;
}

export interface AddressType extends BaseAddress {
  id: number;
  email: string | null;
  phone: string | null;
  fax: string | null;
}

export type AppointmentUpdateType = {
  id: number;
  appointment_id: number;
  appointment_updated_by: {full_name: string};
  appointment_updated_at: string;
  action: string;
};

export type Appointment = {
  id: number;
  deal_id: number;
  address: string;
  datetime: string;
  end_at: string;
  deleted_at: null | unknown;
  timezone: string;
  calendar_id: number;
  signer: null | StaffUser;
  appointment_updates: AppointmentUpdateType[];
  user_id: number;
};

export interface SpecialConditions {
    id: number;
    full_name: string;
    special_condition: string;
}

export interface DealTypeTag {
  id: number;
  tag: string;
  name: string;
  deal_type: string;
}
export interface DealTypeTags {
  Mortgage: DealTypeTag[];
}
export interface Deal {
  deal_type_tag: DealTypeTag;
  id: number;
  uuid: string;
  deal_type: DealType;
  closing_date: string;
  address_id: number;
  referrer_id: number;
  created_at: string;
  updated_at: string;
  lender_name: unknown | null;
  lender_address_id: unknown | null;
  is_archive: number;
  archive_date: string | null;
  folder_path: string;
  deal_number: string;
  status: number;
  closing_date_update: ClosingDateUpdateType[];
  available_stages: DealStageType[];
  completed_stages: DealCompletedStageType[];
  is_new_deal: boolean;
  intake_version: number;
  idv_feature: boolean;
  referringProfessional: UserParticipant[];
  contacts_with_trashed: UserParticipant[];
  staff: UserStaff[];
  document_status: string;
  appointment_updates: AppointmentUpdateType[];
  is_mortgage_stage: number;
  is_separeted: boolean;
  participants: UserParticipant[];
  contact_drafts: ContactDraft[];
  client_onboardings: ClientOnboarding[];
  missingDocuments: DocumentType[];
  address: AddressType;
  lender_address:
    | (unknown & {
        address: string;
        unit: string;
        city: string;
        state: string;
        code: string;
        fax: string | null;
        email: string | null;
        phone: string | null;
      })
    | null;
  lender_mortgage_term?: string,
  lender_maturity_date?: Date,
  lender_loan_amount?: number,
  deal_stage: DealStageType;
  agreements?: DocumentType[];
  appointment?: Appointment[] | null;
  document_notifications?: unknown[];
  deal_updates: DealCompletedStageType[];
  folder_id: number;
  folder_name: string;
  static_lender_id?: number | null;
  is_lod?: boolean;
  specialConditions?: SpecialConditions[];
}

export interface DealConvertedByClient extends Pick<Deal, 'id' | 'deal_type' | 'deal_type_tag'> {
    province: string;
}

export interface DealFullModel {
  current_page: number;
  dealsList: Deal[];
  last_page: number;
  per_page: number;
  total: number;
}
export interface ClosingDateUpdateType {
  id: number;
  created_at: Date;
  updated_at: Date;
  deal_id: number;
  user_id: number;
  text: string;
  status: string;
  key: string;
  description: Date;
}

export enum StageNumber {
  ClientInfoReceived = 1,
  ClientIntakeCompleted = 2,
  Closed = 5,
  Survey = 6,
};

export interface CommunicationTemplate {
    id: number;
    deal_stage_id: number;
    template_name: string;
    recipient: string;
    bcc: string | null;
    subject: string;
    content: string;
    active: number;
    province: string[];
    html_template_id: number | null;
    cc: string | null;
  }
  
export enum DealTypes {
    Purchase = 'Purchase',
    Sale = 'Sale',
    Mortgage = 'Mortgage',
    Lender = 'Lender'
}
