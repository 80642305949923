import React from "react";

const CheckedDealLenderSvg = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="19"
        height="19"
        rx="3.5"
        fill="#F16764"
        stroke="#F16764"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.28558 8.66029L4 10.1924L9.36231 14.6919L17.0758 5.49936L15.5437 4.21379L9.1158 11.8742L5.28558 8.66029Z"
        fill="white"
      />
    </svg>
  );
};

export default CheckedDealLenderSvg;
