import { User } from "types/user";
import { isClient, isProfessional, isStaff } from "utils/permissionRoles";

export default function (user: Partial<User> | undefined): Array<string> {

  if (isStaff(user?.role || '')) {
    return [
      "type",
      "province",
      "client_name",
      "street_address",
      "appointment",
      "lawyer",
      "law_clerk",
      "created_at",
      "closing",
      "deal_stage",
      "number",
      "documents",
    ]
  }

  if (isClient(user?.role || '') || isProfessional(user?.role || '')) {
    return [
      "type",
      "province",
      "client_name",
      "street_address",
      "deal_stage",
      "created_at",
      "closing",
    ]
  }

  return [];
}