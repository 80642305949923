import {Typography, useTheme} from "@mui/material";
import React from "react";
import useTypedSelector from "utils/hooks/useTypedSelector";
import constants from "styles/constants";
import DeededSpace from "v2/components/DeededSpace";
import {styled} from "@mui/material";
import {Theme} from "@mui/material/styles";

const CalendarDealLenderInfo = () => {
  const dealFullInfo = useTypedSelector(
    (state) => state.CalendarReducer.dealFullInfo,
  );
  const theme = useTheme();
  return (
    <>
      <DeededSpace y={10} />
      <Typography
        sx={{
          fontFamily: "Montserrat",
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: "16px",
          lineHeight: "150%",
          display: "flex",
          alignItems: "center",
          color: constants.colors.title,
          [theme.breakpoints.down("sm")]: {
            fontSize: "12px",
          },
        }}
      >
        {dealFullInfo?.lender_name}
      </Typography>
      <DeededSpace y={5} />
      <LenderDescription
        sx={{
          width: "60%",
        }}
      >
        {dealFullInfo?.lender_address?.address}
      </LenderDescription>
      <DeededSpace y={8} />
      <LenderDescription>
        {dealFullInfo?.lender_address?.city},{" "}
        {dealFullInfo?.lender_address?.state}
      </LenderDescription>
      <DeededSpace y={8} />
      <LenderDescription>
        {dealFullInfo?.lender_address?.code}
      </LenderDescription>
    </>
  );
};

const LenderDescription = styled(Typography)<{theme?: Theme}>(({theme}) => ({
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: "normal",
  fontSize: "14px",
  lineHeight: "150%",
  color: constants.colors.deededGray,
  [theme.breakpoints.down("sm")]: {
    fontSize: "10px",
  },
}));
export default CalendarDealLenderInfo;
