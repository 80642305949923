import {Box, MenuItem, styled, useTheme} from "@mui/material";
import React, {useMemo} from "react";
import useStaff from "utils/hooks/useStaff";
import useUser from "utils/hooks/useUser";
import useWindowWidth from "utils/hooks/useWindowWidth";
import {StaffType} from "redux/reducers/configReducer/staffCardReducer";
import DeededCheckbox from "v2/components/DeededCheckbox";
import DeededSpace from "v2/components/DeededSpace";
import DeededSelectV2Transparent from "v2/components/DeededSelect";
import CloseCalendarLapseV2Repeat from "./CloseCalendarLapseV2/CloseCalendarLapseV2Repeat";
import {
  BigCalendarBlockTimeBlockErrorsType,
  BigCalendarBlockTimeBlockValuesType,
  HandleChangeParamsType,
  StylesObjectFieldsType,
} from "../types/bigCalendarBlockTimeTypes";

interface BigCalendarBlockTimeBlockForUserFieldsProps {
  values: BigCalendarBlockTimeBlockValuesType;
  handleChange: (params: HandleChangeParamsType) => void;
  styles: StylesObjectFieldsType;
  errors: BigCalendarBlockTimeBlockErrorsType;
  setIsChangeableRole: (changeableRole: boolean) => void;
}
const BigCalendarBlockTimeBlockHidebleFields: React.FC<
  BigCalendarBlockTimeBlockForUserFieldsProps
> = ({values, handleChange, styles, errors, setIsChangeableRole}) => {
  const staff = useStaff();
  const theme = useTheme();
  const { sm } = theme.breakpoints.values;
  const { user, isAdmin } = useUser();
  const windowWidth = useWindowWidth();
  const staffRoleNames = useMemo(() => {
    if (staff) {
      return Object.keys(staff);
    } else {
      return [];
    }
  }, [staff]);
  const users = useMemo(() => {
    return values.role
      ? staff?.[values.role as keyof StaffType]
          ?.map(({full_name: label, id: value}) => ({
            label,
            value: `${value}`,
          }))
          .filter((filteringUser) => +filteringUser.value !== user.id)
      : [];
  }, [staff, user, values.role]);
  return (
    <>
      <Box
        sx={{
          display: "flex",
        }}
      >
        {isAdmin && (
            <Box
              sx={{
                display: "flex",
                [theme.breakpoints.down("sm")]: {
                  "& .MuiFormControlLabel-root .MuiTypography-root": {
                    fontSize: "12px",
                  },
                },
              }}
            >
              <DeededCheckbox
                data-selenium-id="for-specific-user"
                label="For specific user"
                checked={values.isShowedBlockForUserFields}
                onChange={(_e, checked) => {
                  if (isAdmin) {
                    handleChange({
                      target: {
                        name: "isShowedBlockForUserFields",
                        value: checked,
                      },
                    });
                  }
                }}
              />
            </Box>
        )}
        <Box
          sx={{
            display: "flex",
            [theme.breakpoints.down("sm")]: {
              "& .MuiFormControlLabel-root .MuiTypography-root": {
                fontSize: "12px",
              },
            },
          }}
        >
          <DeededCheckbox
            data-selenium-id="repeat-checkbox"
            label="Repeat"
            checked={values.isRepeat}
            onChange={(_e, checked) => {
              handleChange({
                target: {
                  name: "isRepeat",
                  value: checked,
                },
              });
            }}
          />
        </Box>
      </Box>
      {values.isRepeat && (
        <>
          <DeededSpace y={windowWidth < sm ? 14 : 36} />
          <CloseCalendarLapseV2Repeat
            selectedDaysOfWeek={values.selectedDaysOfWeek}
            selectedDaysOfMonth={values.selectedDaysOfMonth}
            selectedMothsOfYear={values.selectedMothsOfYear}
            repeatEveryDays={values.repeatEveryDays}
            repeatEveryWeeks={values.repeatEveryWeeks}
            repeatEveryMonths={values.repeatEveryMonths}
            repeatEveryYears={values.repeatEveryYears}
            repeat={values.repeat}
            handleChange={handleChange as (someToChange: unknown) => void}
          />
          {values.isShowedBlockForUserFields && windowWidth >= sm && (
            <DeededSpace y={36} />
          )}
        </>
      )}

      {(isAdmin && values.isShowedBlockForUserFields) && (
        <>
          <Box
            sx={{
              ...styles.FormItem,
              [theme.breakpoints.down("sm")]: {
                marginTop: "14px",
              },
            }}
          >
            <FormLabel
              sx={{
                ...styles.FormLabel,
                "& .MuiBox-root .MuiOutlinedInput-root": {
                  height: "36px !important",
                },
                [theme.breakpoints.down("sm")]: {
                  maxWidth: "unset",
                },
              }}
            >
              <Box
                sx={{
                  ...styles.FormLabelText,
                  [theme.breakpoints.down("sm")]: {
                    marginLeft: "8px",
                  },
                }}
              >
                Role
              </Box>
              <DeededSelectV2Transparent
                data-selenium-id="select-role"
                width="100%"
                name="role"
                onChange={(e) => {
                  setIsChangeableRole(true);
                  handleChange(e as HandleChangeParamsType);
                }}
                value={values.role}
              >
                <MenuItem
                  key="Select Role"
                  value={"null"}
                  sx={{display: "none !important"}}
                >
                  Select Role
                </MenuItem>
                {staffRoleNames.map((roleName) => (
                  <MenuItem key={roleName} value={roleName}>
                    {roleName}
                  </MenuItem>
                ))}
              </DeededSelectV2Transparent>
            </FormLabel>
          </Box>
          <Box sx={styles.FormItem}>
            <FormLabel
              sx={{
                ...styles.FormLabel,
                "& .MuiBox-root .MuiOutlinedInput-root": {
                  height: "36px !important",
                },
                [theme.breakpoints.down("sm")]: {
                  maxWidth: "unset",
                },
              }}
            >
              <Box
                sx={{
                  ...styles.FormLabelText,
                  [theme.breakpoints.down("sm")]: {
                    marginLeft: "8px",
                  },
                }}
              >
                User
              </Box>
              <DeededSelectV2Transparent
                data-selenium-id="select-user"
                error={errors?.forSelectedUser}
                disabled={values.role === "null"}
                onChange={(e) => handleChange(e as HandleChangeParamsType)}
                value={values.forSelectedUser}
                width="100%"
                name="forSelectedUser"
                placeholder="Select Mobile Signer"
              >
                <MenuItem
                  key="Block for myself"
                  value="null"
                  sx={{display: "none !important"}}
                >
                  No option
                </MenuItem>
                {users?.map(({label, value}) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </DeededSelectV2Transparent>
            </FormLabel>
          </Box>
        </>
      )}
    </>
  );
};
const FormLabel = styled("label")({});
export default BigCalendarBlockTimeBlockHidebleFields;
