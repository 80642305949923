import React from "react";
import {Box, styled, Typography, useTheme} from "@mui/material";
import {Theme} from "@mui/material/styles";
import profilePhotoPlug from "assets/img/unknownUserProfilePhoto.jpg";
import constants from "styles/constants";
import useWindowWidth from "utils/hooks/useWindowWidth";

interface DealDetailsSurveyTabItemProps {
  fullName: string;
  whatKeepGoing: string;
  whatCouldDifferent: string;
  whatWouldSay: string;
  rating: number;
}

const DealDetailsSurveyTabItem = ({
  fullName,
  whatKeepGoing,
  whatCouldDifferent,
  whatWouldSay,
  rating,
}: DealDetailsSurveyTabItemProps) => {
  return (
    <ActivityitemHeader>
      <DocumenValue>
        <PropertyTitleItem title={"Full Name"} />
        <div className="wrapperItem">
          <span>{fullName}</span>
        </div>
      </DocumenValue>
      <DocumenValue>
        <PropertyTitleItem title={"What should we keep doing"} />
        <div className="wrapperItem">
          <span>{whatKeepGoing}</span>
        </div>
      </DocumenValue>
      <DocumenValue>
        <PropertyTitleItem title={"What could we do differently"} />
        <div className="wrapperItem">
          <span>{whatCouldDifferent}</span>
        </div>
      </DocumenValue>
      <DocumenValue>
        <PropertyTitleItem
          title={"When reffering us to others what would you say"}
        />
        <div className="wrapperItem">
          <span>{whatWouldSay}</span>
        </div>
      </DocumenValue>
      <DocumenValue>
        <PropertyTitleItem title={"Rating"} />
        <div className="wrapperItem">
          <span>{rating}</span>
        </div>
      </DocumenValue>
      <DocumenValue>
        <PropertyTitleItem title={"User"} />
        <div className="wrapperItem">
          <ProfilePhotoBox>
            <img
              style={{
                borderRadius: "5px",
                width: "25px",
                height: "25px",
                objectFit: "cover",
              }}
              alt={"img"}
              src={profilePhotoPlug}
            />
          </ProfilePhotoBox>
          {fullName}
        </div>
      </DocumenValue>
    </ActivityitemHeader>
  );
};
const PropertyTitleItem: React.FC<{
  title: string;
}> = ({title}) => {
    const theme = useTheme();
  const windowWidth = useWindowWidth();
  const isLargeScreen = windowWidth >= theme.breakpoints.values.lg;
  return (
    <>
      {!isLargeScreen && (
        <PropertyTitleItemTypography>{title}:</PropertyTitleItemTypography>
      )}
    </>
  );
};
const PropertyTitleItemTypography = styled(Typography)({
  fontSize: "14px",
  color: constants.colors.deededGray,
  fontWeight: "600",
});
const ProfilePhotoBox = styled(Box)({
  borderRadius: "5px",
  marginLeft: "5px",
  marginRight: "10px",
  width: "25px",
  height: "25px",
});
const ActivityitemHeader = styled(Box)<{theme?: Theme}>(({theme}) => ({
  display: "flex",
  padding: "0.5rem 2rem",
  background: constants.colors.white,
  marginBottom: "0.5rem",
  height: "fit-content",
  boxShadow: "0px 2px 4px rgba(161, 184, 214, 0.12)",
  borderRadius: "2px",
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
    padding: "2rem 2rem",
  },
}));
const DocumenValue = styled(Box)<{theme?: Theme}>(({theme}) => ({
  position: "relative",
  display: "flex",
  flex: 1,
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontSize: "14px",
  lineHeight: "17px",
  alignItems: "center",
  color: constants.colors.title,

  "&:first-child": {
    flex: "0 0 11%",
    fontWeight: "600",
  },
  "&:nth-child(2)": {
    flex: "0 0 24%",
  },
  "&:nth-child(3)": {
    flex: "0 0 24%",
  },
  "&:nth-child(4)": {
    flex: "0 0 24%",
  },
  "&:nth-child(5)": {
    flex: "0 0 6%",
  },
  "&:nth-child(6)": {
    flex: "0 0 11%",
  },

  "& .wrapperItem": {
    display: "flex",
    alignItems: "center",
    flex: "0 0 90%",
    span: {
      display: "inline-block",
    },
  },
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
    alignItems: "flex-start",
    marginBottom: "20px",
  },
}));
export default DealDetailsSurveyTabItem;
