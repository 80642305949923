import { FC, ReactNode, JSX, isValidElement } from "react";
import { ArrowDownIcon, IconProps } from "../Icons";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { BodySmall } from "../Typography";
import Colors from "../Styling/Colors";

type PillProps = {
    label?: string,
    color?: string,
    backgroundColor?: string,
    icon?: FC<IconProps> | FC<{ color: string, size: string }>,
    iconProps?: {
        color?: string,
        position?: 'start' | 'end'
    }
    testId?: string,
    children?: ReactNode
};

const Pill: FC<PillProps> = ({ label, icon, color = Colors.DARK_GREY, backgroundColor = Colors.GREY, iconProps, testId, children }) => {
    return <StyledBox sx={{ backgroundColor }}>
        {(iconProps?.position === 'start' || !iconProps?.position) && icon && (icon({ color: iconProps?.color || color, size: 'small' }))}
        {label && <BodySmall noWrap data-testid={testId} color={color}>{label}</BodySmall>}
        {children && <>{children}</>}
        {iconProps?.position === 'end' && icon && (icon({ color: iconProps?.color || color, size: 'small' }))}
    </StyledBox>
};

const StyledBox = styled(Box)(({ theme }) => ({
    display: 'inline-flex',
    padding: '0.4rem 0.8rem',
    alignItems: 'center',
    gap: '.8rem',
    borderRadius: '3.6rem',
}));


export default Pill;