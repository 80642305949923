import {useMemo} from "react";
import DeededCircularLoader from "v2/components/DeededCircularLoader";

const DocumentFrameDisplay = ({fileData, type}) => {
  let blobURL = useMemo(() => {
    if (typeof fileData !== "string") {
      const blob = new Blob([fileData], {type: "application/pdf"});
      return URL.createObjectURL(blob);
    }
    return fileData;
  }, [fileData]);

  return fileData === null ? (
    <DeededCircularLoader />
  ) : (
    <object
      style={{
        width: type === "application/pdf" ? "100%" : "auto",
        height: "100%",
        margin: "0 auto",
      }}
      data={blobURL}
      type={type}
    >
      <iframe title="pdf document" />
    </object>
  );
};

export default DocumentFrameDisplay;
