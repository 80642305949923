import {getMobileSignersSigningAppointmentCountTableArrayByExactDate} from "./getMobileSignersSigningAppointmentCountTableArrayByExactDate";

export const getMobileSignersSigningAppointmentCountTableArrayAllSum = (
  mobileSignersAppointments: unknown,
) => {
  const typedMobileSignersAppointments = mobileSignersAppointments as {
    oneOfBigAmountUsers: {
      full_name: string;
      sum: number;
      dealTypes: {
        Purchase: number;
        Sale: number;
        Mortgage: number;
        Lender: number;
      };
    };
  };
  const mobileSignersSigningAppointmentCountTableArray =
    getMobileSignersSigningAppointmentCountTableArrayByExactDate(
      mobileSignersAppointments,
    );
  return typedMobileSignersAppointments &&
    Object.keys(typedMobileSignersAppointments).length !== 0
    ? mobileSignersSigningAppointmentCountTableArray
        ?.map((signer) => signer?.sum)
        ?.reduce((sum = 0, currCount) => (currCount ? sum + currCount : sum))
    : 0;
};
