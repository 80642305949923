export const addRemoveDayFromSelected = (
  checkingArray: number[],
  checkingElement: number,
  nameForChange: string,
  handleChange: (someToChange: unknown) => void,
) => {
  if (checkingArray?.includes(checkingElement)) {
    handleChange({
      target: {
        name: nameForChange,
        value: checkingArray.filter((day) => day !== checkingElement),
      },
    });
  } else {
    handleChange({
      target: {
        name: nameForChange,
        value: checkingArray?.concat([checkingElement]),
      },
    });
  }
};
