import React from "react";
import Box from "@mui/material/Box";
import {styled, useTheme} from "@mui/material";
import {DealNumber} from "types/reduxActions/deals";
import {useResetDealNumberMutation} from "redux/reducers/configDealNumbersApi";
import DeededButton from "v2/components/DeededButton";
import Reset from "components/Svg/Reset";
import constants from "styles/constants";
import {ConfigDealNumberInputs} from "./ConfigDealNumberInputs";
import {
  phoneConfig,
  smallDesktopConfigDealNumbers,
} from "../../constants/breakpoints";
import {Theme} from "@mui/material/styles";

interface DealChangesProps {
  prefixNumber?: string | number;
  startNumber?: string | number;
  id: number;
}
interface ConfigDealNumberWrapperProps {
  dealNumberItem: Partial<DealNumber>;
  setDealNumbers: (
    dealNumbers: (prev: Partial<DealNumber>[]) => Partial<DealNumber>[],
  ) => void;
  index: number;
  showReset?: boolean;
}
const ConfigDealNumberWrapper: React.FC<ConfigDealNumberWrapperProps> = ({
  dealNumberItem,
  setDealNumbers,
  index,
  showReset = true
}) => {
  const {id, state, prefix_number, start_number} = dealNumberItem;
  const [resetDealNumber, {isLoading}] = useResetDealNumberMutation();
  const theme = useTheme();
  const dealListChange = ({
    prefixNumber,
    startNumber,
    id,
  }: DealChangesProps) => {
    setDealNumbers((prev) =>
      prev.map((el: {id?: number}) => {
        return el.id !== id
          ? el
          : {
              ...el,
              prefix_number: Number(prefixNumber),
              start_number: Number(startNumber),
            };
      }),
    );
  };
  let color = "";
  let country = "";
  switch (state) {
    case "AB-BC":
      country = "Alberta-Columbia";
      color = "#02CD8F";
      break;
    case "BC":
      country = "British Columbia";
      color = "#349FF2";
      break;
    case "MB":
      country = "Manitoba";
      color = "#74B3CE";
      break;
    case "NB":
      country = "New Brunswick";
      color = "#13664E";
      break;
    case "NT":
      country = "Northwest Territories";
      color = "#29339B";
      break;
    case "NL":
      country = "Newfoundland and Labrador";
      color = "#7B287D";
      break;
    case "NS":
      country = "Nova Scotia";
      color = "#60695C";
      break;
    case "NU":
      country = "Nunavut";
      color = "#13664E";
      break;
    case "ON":
      country = "Ontario";
      color = "#EC9F05";
      break;
    case "PE":
      country = "Prince Edward Island";
      color = "#13664E";
      break;
    case "QC":
      country = "Quebec";
      color = "#13664E";
      break;
    case "SK":
      country = "Saskatchewan";
      color = "#13664E";
      break;
    case "YT":
      country = "Yukon";
      color = "#13664E";
      break;
  }
  return (
    <Wrapper key={id}>
      <ContentItem
        sx={{
          [theme.breakpoints.down(smallDesktopConfigDealNumbers)]: {
            marginRight: state === "AB-BC" ? "5px" : "10px",
            "&::before": {
              content: '"Province"',
              color: constants.colors.intakeTitle,
              display: "block",
              fontWeight: 400,
              lineHeight: "15px",
              paddingBottom: "9px",
            },
          },
        }}
      >
        <WrapperItem
          sx={{
            marginRight: state === "AB-BC" ? "75px" : "120px",
            [theme.breakpoints.down("md")]: {
              marginRight: state === "AB-BC" ? "27px" : "50px",
            },
            [theme.breakpoints.down("sm")]: {
              marginRight: state === "AB-BC" ? "5px" : "10px",
            },
            [theme.breakpoints.down("xs")]: {
              // width: "60%",
            },
            [theme.breakpoints.down("lg")]: {
              marginRight: state === "AB-BC" ? "-25px" : "20px",
            },
          }}
        >
          <MyStyledItem provinceColor={color} state={state!} />
          <CountryState country={country} />
        </WrapperItem>
      </ContentItem>
      <ConfigDealNumberInputs
        startNumber={start_number}
        prefixNumber={prefix_number}
        onInputUpdate={(e: {prefixNumber: number; startNumber: number}) =>
          dealListChange({...e, id: id!})
        }
      />
      {showReset && <ResetButton
        data-selenium-id={`reset-btn-${index}`}
        onClick={() => {
          if (!isLoading) {
            resetDealNumber(id as number);
          }
        }}
      >
        <Reset />
        {isLoading ? "Loading..." : "Reset"}
      </ResetButton>
      }
    </Wrapper>
  );
};
const CountryState = (props: {country: string}) => {
  const theme = useTheme();
  return (
    <>
      {" "}
      <Box>
        <Box
          sx={{
            marginLeft: "12px",
            paddingTop: "4px",
            width: "200px",
          }}
        >
          <ProvinceItem>{props.country}</ProvinceItem>
        </Box>
      </Box>
    </>
  );
};
const MyStyledItem = (props: {state: string; provinceColor: string}) => {
  let firstHalf = props.state ? "AB-BC" : "";
  let AB = firstHalf.slice(0, 2);
  let BC = firstHalf.slice(3, 6);
  return props.state === "AB-BC" ? (
    <Box style={{display: "flex", flexDirection: "row"}}>
      <Box
        sx={{
          backgroundColor: props.provinceColor,
          width: "35px",
          height: "28px",
          alignItems: "center",
          borderRadius: "3px",
          flexDirection: "row",
        }}
      >
        <Box sx={{marginLeft: "7px", paddingTop: "4px"}}>
          <Item>{AB}</Item>
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: "#349FF2",
          width: "35px",
          height: "28px",
          alignItems: "center",
          borderRadius: "3px",
          flexDirection: "row",
          marginLeft: "10px",
        }}
      >
        <Box sx={{marginLeft: "7px", paddingTop: "4px"}}>
          <Item>{BC}</Item>
        </Box>
      </Box>
    </Box>
  ) : (
    <Box
      sx={{
        backgroundColor: props.provinceColor,
        width: "35px",
        height: "28px",
        alignItems: "center",
        borderRadius: "3px",
        flexDirection: "row",
      }}
    >
      <Box
        sx={{
          height: "100%",
          display: "flex",
          width: "35px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Item>{props.state}</Item>
      </Box>
    </Box>
  );
};
const ResetButton = styled(DeededButton)(({theme}) => ({
  height: "36px",
  marginTop: "-5px",
  ">svg": {
    display: "none",
  },
  [theme.breakpoints.down(smallDesktopConfigDealNumbers)]: {
    position: "absolute",
    right: "19px",
    width: "24px",
    minWidth: "24px",
    height: "24px",
    padding: 0,
    margin: 0,
    color: "transparent",
    backgroundColor: "transparent",
    fontSize: 0,
    border: "none",
    ">*": {
      display: "none",
    },
    ">svg": {
      display: "block",
      width: "24px",
      height: "24px",
    },
  },
}));
const Wrapper = styled("div")(({theme}) => ({
  display: "flex",
  marginBottom: "25px",
  background: "#FFFFFF",
  [theme.breakpoints.down(smallDesktopConfigDealNumbers)]: {
    flexDirection: "column",
    marginBottom: "16px",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.06)",
    borderRadius: "5px",
    padding: "16px",
    paddingRight: "19px",
    position: "relative",
  },
}));
const ContentItem = styled("div")({
  flex: 1,
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "1.4rem",
  lineHeight: "1.7rem",
  lineBreak: "anywhere",
  marginLeft: "0.5rem",
  flexWrap: "wrap",
});
const ProvinceItem = styled("h2")<{theme?: Theme}>(({theme}) => ({
  fontSize: "15px",
  lineHeight: "18px",
  fontWeight: "400",
  fontFamily: "Montserrat",
  fontStyle: "normal",
  color: " #828282",
  whiteSpace: "nowrap",
  [theme.breakpoints.down(phoneConfig)]: {
    width: "200px",
    maxWidth: "200px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}));
const Item = styled("strong")({
  fontSize: "15px",
  lineHeight: "18px",
  fontWeight: "600",
  fontFamily: "Montserrat",
  fontStyle: "normal",
  textAlign: "center",
  color: "#FFFFFF",
});
const WrapperItem = styled(Box)({
  display: "flex",
  flexDirection: "row",
});
export default ConfigDealNumberWrapper;
