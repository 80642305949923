import GenericDialog from "components/Common/Modal/GenericDialog";

interface SendOnboardingModalProps {
    onSubmit: () => void,
    onClose: () => void,
    openSendOnboardingModal: boolean
}
const SendOnboardingModal = ({ onClose, onSubmit, openSendOnboardingModal }: SendOnboardingModalProps) => {
    return <>
        <GenericDialog
            action="neutral"
            contentText="Are you sure you want to re-send onboarding?"
            onCancel={() => onClose()}
            onSubmit={() => onSubmit()}
            title={"Re-send onboarding"}
            titleComponent="H4"
            open={openSendOnboardingModal}
            submitText={"Re-send"}
            cancelBtnProps={{ typeOf: "secondary" }}
            dialogProps={{ maxWidth: 'xs' }}
        />
    </>
}


export default SendOnboardingModal;