import React from "react";
import {styled, Theme} from "@mui/material/styles";
import DeededButton from "v2/components/DeededButton";
import {Box, Typography} from "@mui/material";
import constants from "../../../../styles/constants";

interface ProfileIntegrationCardProps {
  icon: Element;
  descriptionTitle: string;
  description: string;
  handleConnect: () => void;
  isConnected: boolean;
  isShowDetails?: boolean;
  handleDetails?: () => void;
}

const ProfileIntegrationCard: React.FC<ProfileIntegrationCardProps> = ({
  icon,
  descriptionTitle,
  description,
  handleConnect,
  isConnected,
  isShowDetails,
  handleDetails,
}) => (
  <IntegrationCardBox>
    <TitleBox>
      <IconSvgBox>{icon}</IconSvgBox>
    </TitleBox>
    <DescriptionBox>
      <DescriptionTitle>{descriptionTitle}</DescriptionTitle>
      <Description title={description}>{description}</Description>
    </DescriptionBox>
    <WrapperBtns>
      <WrapperBtn>
        <TitleBtn
          disabled={isConnected}
          onClick={handleConnect}
          kind="secondary"
        >
          {isConnected ? "Connected" : "Connect"}
        </TitleBtn>
      </WrapperBtn>
      {isShowDetails && (
        <WrapperBtn>
          <TitleBtn
            disabled={!isConnected}
            onClick={handleDetails}
            kind="secondary"
          >
            Details
          </TitleBtn>
        </WrapperBtn>
      )}
    </WrapperBtns>
  </IntegrationCardBox>
);

const Description = styled(Typography)({
  marginTop: "15px",
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "17px",
  maxHeight: "34px",
  overflowY: "scroll",
  width: "100%",
  overflow: "hidden",
  display: "-webkit-box",
  WebkitLineClamp: "2",
  WebkitBoxOrient: "vertical",
  "&::-webkit-scrollbar": {
    width: "0px",
  },
} as unknown as TemplateStringsArray);

const DescriptionTitle = styled(Box)({
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "20px",
  display: "flex",
  alignItems: "center",
  textTransform: "capitalize",
});

const DescriptionBox = styled(Box)({
  marginTop: "8px",
  position: "relative",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
});

const WrapperBtns = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  marginLeft: "-5px",
  marginRight: "-5px",
});

const WrapperBtn = styled(Box)({
  padding: "0 5px",
});

const TitleBtn = styled(DeededButton)({
  width: "105px",
  height: "25px",
  fontWeight: "500",
  marginTop: "12px",
});

const IconSvgBox = styled(Box)({});

const TitleBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const IntegrationCardBox = styled(Box)<{theme:Theme}>(({theme})=>({
  width: "262px",
  borderRadius: "5px",
  padding: "16px",
  border: `1px solid ${constants.colors.grayBorder}`,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  [theme.breakpoints.down('sm')]:{
    width: "227px",
  }
}));
export default ProfileIntegrationCard;
