import React, {ChangeEventHandler, FC} from "react";
import {
  styled,
  InputLabel,
} from "@mui/material";
interface ClientPrefixInputProps {
  client_prefix: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  name?: string;
}

const StyledLabel = styled(InputLabel)({
  flexDirection: 'row',
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '12px',
  lineHeight: '15px',
  display: 'inline-block',
  paddingBottom: '2rem',
});

const ClientPrefixInput: FC<ClientPrefixInputProps> = ({
  client_prefix,
  onChange,
  name = "client_prefix",
}) => {
  return (
    <div className="prefix__container">
      <StyledLabel className="radio-container">
        Mr.
        <input
          required={false}
          checked={client_prefix === "Mr."}
          type="radio"
          name={name}
          value="Mr."
          onChange={onChange}
        />
        <span className="checkmark"></span>
      </StyledLabel>
      <StyledLabel className="radio-container">
        Ms.
        <input
          checked={client_prefix === "Ms."}
          required={false}
          type="radio"
          name={name}
          value="Ms."
          onChange={onChange}
        />
        <span className="checkmark"></span>
      </StyledLabel>

      <StyledLabel className="radio-container">
        Mrs.
        <input
          checked={client_prefix === "Mrs."}
          required={false}
          type="radio"
          name={name}
          value="Mrs."
          onChange={onChange}
        />
        <span className="checkmark"></span>
      </StyledLabel>
      <StyledLabel className="radio-container">
        Dr.
        <input
          checked={client_prefix === "Dr."}
          required={false}
          type="radio"
          name={name}
          value="Dr."
          onChange={onChange}
        />
        <span className="checkmark"></span>
      </StyledLabel>
      <StyledLabel className="radio-container">
        Corporation
        <input
          required={false}
          checked={client_prefix === "Corporation"}
          type="radio"
          name={name}
          value="Corporation"
          onChange={onChange}
        />
        <span className="checkmark"></span>
      </StyledLabel>
    </div>
  );
};

export default ClientPrefixInput;
