import {Box, BoxProps, SxProps, Theme, Typography} from "@mui/material";
import React, {useState} from "react";
import {styled} from "@mui/material/styles";
import constants from "../../styles/constants";
import {copyTextToClipboard} from "../../utils/copyTextToClipboard";
import ClipboardCopyIcon from "../../components/Svg/ClipboardCopyIcon";
import ClipboardCopySuccessIcon from "../../components/Svg/ClipboardCopySuccessIcon";

type DeededHintCloudProps = {
  text: string;
  copied: boolean;
  marginLeftRotatedSquare?: number;
  marginRightRotatedSquare?: number;
  className?: string | undefined;
  sx?: SxProps<Theme>;
} & DeededHintCloudBoxProps;

interface DeededHintCloudBoxProps {
  top?: number;
  left?: number;
  right?: number;
  bottom?: number;
}

const DeededHintCloud: React.FC<
  DeededHintCloudProps & Omit<BoxProps, "variant">
> = ({
  text,
  copied,
  top,
  left,
  right,
  bottom,
  marginLeftRotatedSquare,
  marginRightRotatedSquare,
  className,
  sx,
  ...props
}) => {
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);

  return (
    <DeededHintCloudBox
      data-selenium-test="DeededHintCloud"
      top={top}
      left={left}
      right={right}
      bottom={bottom}
      className={className}
      sx={sx}
      onClick={(e) => {
        e.stopPropagation();
      }}
      {...props}
    >
      <Box>
        <TextBox copied={copied}>
          <CloudText>{text}</CloudText>
          {copied && (
            <>
              <CloudCopyIconBox
                onClick={() => {
                  copyTextToClipboard(text);
                  setCopiedToClipboard(true);
                  setTimeout(() => {
                    setCopiedToClipboard(false);
                  }, 1000);
                }}
              >
                {!copiedToClipboard ? (
                  <ClipboardCopyIcon />
                ) : (
                  <ClipboardCopySuccessIcon />
                )}
              </CloudCopyIconBox>
            </>
          )}
        </TextBox>
        <RotatedSquare
          left={marginLeftRotatedSquare}
          right={marginRightRotatedSquare}
        />
      </Box>
    </DeededHintCloudBox>
  );
};
const CloudCopyIconBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  svg: {
    marginLeft: "9px",
    transition: constants.transitions,
    stroke: constants.colors.searchColor,
    "&:hover": {
      transition: constants.transitions,
      stroke: constants.colors.red,
      cursor: "pointer",
    },
  },
});
const CloudText = styled(Typography)({
  fontFamily: "'Montserrat'",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "17px",
  display: "flex",
  alignItems: "center",
  color: constants.colors.white,
});
const DeededHintCloudBox = styled(Box)<DeededHintCloudBoxProps>(
  ({top, left, right, bottom}) => ({
    position: "absolute",
    top: `${top}px`,
    left: `${left}px`,
    right: `${right}px`,
    bottom: `${bottom}px`,
    cursor: "initial",
    "&.active": {
      opacity: 1,
      visibility: "visible",
      transition: constants.transitions,
    },
    "&.noActive": {
      opacity: 0,
      visibility: "hidden",
      transition: constants.transitions,
    },
  }),
);
const TextBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "copied"
})<{copied: boolean}>(({copied}) => ({
  background: constants.colors.cloudGray,
  borderRadius: "5px",
  padding: copied ? "6px 16px 6px 16px" : "10px 12px 10px 12px",
  display: "flex",
  flex: 1,
  minHeight: "38px",
}));
const RotatedSquare = styled(Box)<{left?: number; right?: number}>(
  ({left, right}) => ({
    position: "absolute",
    bottom: "-5px",
    left: `${left}px`,
    right: `${right}px`,
    height: "13px",
    width: "13px",
    transform: "rotate(45deg)",
    background: constants.colors.cloudGray,
  }),
);
export default DeededHintCloud;
