import "./DocumentView.scss";
import DocumentViewDisplay from "./DocumentViewDisplay";
import useDocumentViewHandlers from "./useDocumentViewHandlers";

// This is getting really bad. I reckon there's also another
// TODO: Refactor this thing

/**
 * A document approval/rejection form
 *
 * @param {Object} props
 * @param {Object} props.onUpdated This callback is necessary for notifying the parent page if any data was changed
 */
const DocumentView = ({dealID, dealType, onClose, theDocument, onUpdated}) => {
  const documentViewHandlers = useDocumentViewHandlers(
    dealID,
    dealType,
    theDocument,
    onClose,
    onUpdated,
  );
  return (
    <div className="document__view__wrapper">
      <DocumentViewDisplay {...documentViewHandlers} showHeader={true} />
    </div>
  );
};

/**
 * A modal for editing the document
 *
 * @param {Object} param0
 */
export const DocumentViewContainer = ({
  dealID,
  dealType,
  toggleDocumentPopup,
  selectedDocument,
  ...props
}) => {
  return (
    <DocumentView
      dealID={dealID}
      dealType={dealType}
      theDocument={selectedDocument}
      onClose={toggleDocumentPopup}
      {...props}
    />
  );
};

export default DocumentViewContainer;
