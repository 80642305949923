import React, {useEffect, useState} from "react";

const useWindowWidth: () => number = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const setWW = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", setWW);
    return () => window.removeEventListener("resize", setWW);
  }, []);
  return windowWidth;
};

export default useWindowWidth;
