import constants from "../../../../styles/constants";

export const styles = {
  calendar: {
    border: "none",
  },
  day: {
    background: constants.colors.white,
    boxShadow: "0px 3px 9px rgba(131, 93, 93, 0.08)",
    margin: "5px",
    border: "none",
  },
  eventText: {
    color: "#ec9f05",
  },
  today: {
    color: "red",
    border: "2px solid red",
  },
};
