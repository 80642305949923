import React from "react";
import {Box, styled, SxProps, Theme} from "@mui/material";
import MagnifingGlassSVG from "components/Svg/MagnifingGlass";
import constants from "styles/constants";

const UserMangerSearchField: React.FC<{
  value: string;
  onChange: (val: string) => void;
  sx?: SxProps<Theme>;
}> = ({value, onChange, sx}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <SearshBox sx={sx}>
      <MagnifingGlassSVG color={constants.colors.deededGray} />
      <InputStyled data-testid="search-field" placeholder="Search" onChange={handleChange} value={value} />
    </SearshBox>
  );
};

const SearshBox = styled(Box)({
  boxSizing: "border-box",
  minWidth: "274px",
  border: `1px solid ${constants.colors.deededSelectBorderCasual}`,
  width: "100%",
  height: "40px",
  lineHeight: "40px !important",
  display: "flex",
  background: "#FFFFFF",
  borderRadius: "5px",
  alignItems: "center",
  paddingLeft: "15px",
  transition: constants.transitions,
  "&:focus-within": {
    transition: constants.transitions,
    border: `1px solid ${constants.colors.red}`,
  },
});

const InputStyled = styled("input")({
  boxSizing: "border-box",
  border: "none",
  height: "37px",
  outline: "none",
  width: "100%",
  lineHeight: "40px !important",
  paddingLeft: "12px",
  borderRadius: "5px",
});

export default UserMangerSearchField;
