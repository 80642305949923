import React from "react";
import "./DealDetails.scss";
import CrossSVG from "components/Svg/Cross";
import Select from "react-select";
import UserSVG from "components/Svg/User";
import visibleEye from "assets/img/visibleEye.png";
import invisibleEye from "assets/img/invisibleEye.png";
import { baseURL } from "api";

const customStyles = {
    container: (base) => ({
        ...base,
        width: "100%",
        height: "40px",
        "div::-webkit-scrollbar": {
            width: "5px",
        },
    }),

    placeholder: (base) => ({
        ...base,
        display: "none",
    }),
    indicatorSeparator: (base) => ({
        ...base,
        display: "none",
    }),
};

const AssignStaff = ({
    person,
    staffByRole,
    handleStuffAssign,
    handleStuffUnassign,
    handleSetStatus,
    userRole,
}) => {
    const handleChangeStatus = () => {
        if (userRole !== "Admin" && userRole !== "System Admin") {
            return;
        }
        if (person.role === "Access" || person.role === "File Opener") return;

        handleSetStatus(person?.deal_user);
    };

    return (
        <div className="assignstaff--item">
            {person?.deal_user && (
                <div
                    data-testid="assign-staff-visible-eye-icon"
                    style={eyeStyles}
                    onClick={() => {
                        handleChangeStatus();
                    }}
                >
                    <img
                        src={
                            person.role === "Access" ||
                                person.role === "File Opener" ||
                                person?.deal_user?.is_hidden
                                ? invisibleEye
                                : visibleEye
                        }
                        alt=""
                    />
                </div>
            )}
            <div
                data-testid="unassign-staff-button"
                className="unassign--icon"
                style={
                    (userRole === "Lawyer" ? { cursor: "default" } : { cursor: "pointer" },
                        { zIndex: 1 })
                }
            >
                <div
                    onClick={() => {
                        if (userRole === "Lawyer") {
                        } else handleStuffUnassign(person.id);
                    }}
                >
                    <CrossSVG />
                </div>
            </div>
            <div className="assignstaff--header">
                {person.userpic === undefined ? (
                    <UserSVG color={"#7F7F7F"} width={40} height={40} />
                ) : // <img src={user} alt="User avatar" />
                    person.userpic === null ? (
                        <UserSVG color={"#7F7F7F"} width={40} height={40} />
                    ) : (
                        // <img src={user} alt="User avatar" />
                        <img alt="..." src={`${baseURL}/userpics/${person.userpic.id}`} />
                    )}

                <p data-testid="assigned-staff-selected-role">{person.role}</p>

                {/* <EditSVG /> */}
            </div>

            <Select
                id="assign-staff-select-user-drop-down"
                styles={customStyles}
                options={staffByRole}
                isDisabled={userRole === "Lawyer"}
                value={person}
                placeholder="Select..."
                getOptionLabel={(d) => {
                    return `${d.full_name}`;
                }}
                getOptionValue={(d) => d.id}
                onChange={(e) => handleStuffAssign(e, person.id)}
            />
        </div>
    );
};

const eyeStyles = {
    width: "24px",
    height: "24px",
    position: "absolute",
    top: "7px",
    left: "18px",
    cursor: "pointer",
    zIndex: "2",
};


const AssignDeededStaff = ({ staff, handleChangeStuffStatus, handleStuffUnassign, handleStuffAssign, user, openModal, staffByRole }) => {
    return <div className="dealdetails__assignstaff">
        <h2>Assign staff</h2>
        <div className="assignstaff__container">
            {(staff || []).map((person, i) => {
                return (
                    <AssignStaff
                        key={i}
                        person={person}
                        handleSetStatus={handleChangeStuffStatus}
                        handleStuffUnassign={handleStuffUnassign}
                        handleStuffAssign={(e, id) => handleStuffAssign(e, id, i)}
                        staffByRole={staffByRole[person.role] || []}
                        userRole={user.role}
                    />
                );
            })}

            <button
                data-testid="deal-details-assign-staff-button"
                className="assignstaff__plus"
                onClick={openModal}
            />

            <button
                className="assignstaff__btn"
                onClick={openModal}
            >
                Add new person for staff
            </button>
        </div>
    </div>
}


export default AssignDeededStaff;