import React from "react";
import {Box, styled, SxProps, Theme, useTheme} from "@mui/material";
import {Staff} from "types/Config/userManagerTypes";
import SortIcon from "components/Svg/SortIcon";
import ClipboardCopyIcon from "components/Svg/ClipboardCopyIcon";
import ClipboardCopySuccessIcon from "components/Svg/ClipboardCopySuccessIcon";
import DeededResultsNotFound from "v2/components/DeededResultsNotFound";
import DeededCircularLoader from "v2/components/DeededCircularLoader";
import useTypedSelector from "utils/hooks/useTypedSelector";
import UserManagerLastResetPasswordTableItem from "./UserManagerLastResetPasswordTableItem";
import UserManagerEditBlockForItems from "./UserManagerEditBlockForItems";
import UserManagerMfaTableItem from "./UserManagerMfaTableItem";

interface UserManagerListStaffProps {
  data: Staff[];
  handleUser: (user: Staff) => void;
  sx: SxProps<Theme>;
  selectedSortValue: "desc" | "asc";
  selectedSort: string;
  changeSort: (orderby: string, role: string) => void;
  copiedToClipboard: boolean;
  handleClipboardCopy: (email: string) => void;
  searchVal: string;
}

const UserManagerListStaff: React.FC<UserManagerListStaffProps> = ({
  data,
  handleUser,
  sx,
  selectedSortValue,
  selectedSort,
  changeSort,
  copiedToClipboard,
  handleClipboardCopy,
  searchVal,
}) => {
  const userManagerResultLoading = useTypedSelector(
    (state) => state.ConfigReducer.userManagerReducer.configLoader,
  );
  const theme = useTheme();

  return (
    <Box sx={sx} role="staff">
      <TableHeader className="TableHeader">
        <Box className="TableHeaderCell">
          User
          <div className="chevronsBox">
            <SortIcon
              className={
                selectedSortValue === "asc" && selectedSort === "full_name"
                  ? "active"
                  : ""
              }
              onClick={() => {
                changeSort("full_name", "staff");
              }}
            />
          </div>
        </Box>
        <Box className="TableHeaderCell">
          Email
          <div className="chevronsBox">
            <SortIcon
              className={
                selectedSortValue === "asc" && selectedSort === "email"
                  ? "active"
                  : ""
              }
              onClick={() => {
                changeSort("email", "staff");
              }}
            />
          </div>
        </Box>
        <Box className="TableHeaderCell">
          Role
          <div className="chevronsBox">
            <SortIcon
              className={
                selectedSortValue === "asc" && selectedSort === "role"
                  ? "active"
                  : ""
              }
              onClick={() => {
                changeSort("role", "staff");
              }}
            />
          </div>
        </Box>
        <Box className="TableHeaderCell">MFA</Box>
        <Box className="TableHeaderCell">Last Password Rest</Box>
      </TableHeader>
      <Box
        sx={{
          "& .TableBodyCell": {
            [theme.breakpoints.down("lg")]: {
              "&:nth-of-type(3)>.ItemWrapper": {
                "&::before": {
                  content: '"Role"',
                },
              },
            },
          },
        }}
      >
        {userManagerResultLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            <DeededCircularLoader />
          </Box>
        ) : data.length !== 0 ? (
          data.map((item) => (
            <TableRowWrapper key={item.id} className="TableRowWrapper">
              <Box className="TableBodyCell" data-testid="user-manager-user-column">
                {/*<div className="ImageWrapper">*/}
                {/*  {item.userpic ? (*/}
                {/*    <img*/}
                {/*      src={`${baseURL}/userpics/${*/}
                {/*        item?.userpic.id*/}
                {/*      }?t=${Date.now()}`}*/}
                {/*      alt="avatar"*/}
                {/*    />*/}
                {/*  ) : (*/}
                {/*    <DefaultPhotoIcon />*/}
                {/*  )}*/}
                {/*</div>*/}
                <Box
                  sx={{
                    [theme.breakpoints.down("xl")]: {
                      maxWidth: "142px !important",
                    },
                    [theme.breakpoints.down("lg")]: {
                      maxWidth: "180px !important",
                    },
                  }}
                  className="ItemWrapper"
                  title={item.full_name}
                >
                  {item.full_name}
                </Box>
              </Box>
              <Box className="TableBodyCell email" data-testid="user-manager-email-column">
                {item.email && (
                  <div className="CopyEmailPrompt">
                    {item.email} <div className="Triangle" />
                    {!copiedToClipboard ? (
                      <ClipboardCopyIcon
                        onClick={() =>
                          handleClipboardCopy(item.email as string)
                        }
                      />
                    ) : (
                      <ClipboardCopySuccessIcon />
                    )}
                  </div>
                )}
                <div className="ItemWrapper" title={item.email}>
                  {item.email}
                </div>
              </Box>
              <Box className="TableBodyCell" data-testid="user-manager-role-column">
                <div className="ItemWrapper" title={item.role}>
                  {item.role}
                </div>
              </Box>
              <UserManagerMfaTableItem item={item} />
              <UserManagerLastResetPasswordTableItem item={item} />
              <Box className="TableBodyCell UserManagerUserTools">
                <UserManagerEditBlockForItems
                  handleUser={() => handleUser(item)}
                  userId={item.id}
                  isDeleted={!!item.deleted_at}
                />
              </Box>
            </TableRowWrapper>
          ))
        ) : (
          <DeededResultsNotFound
            searchVal={searchVal}
            textNotFound="There are no users yet"
          />
        )}
      </Box>
    </Box>
  );
};
const gridTemplateColumns = "1fr 1fr 14% 5% 1fr  100px";
const TableHeader = styled(Box)({
  gridTemplateColumns: gridTemplateColumns,
});

const TableRowWrapper = styled(Box)({
  gridTemplateColumns: gridTemplateColumns,
});

export default UserManagerListStaff;
