import {
  ClosingsCountApiType,
  ResultCalendarSumUserType,
} from "../../../../redux/reducers/types/calendarApiTypes";
import {createUserEventCountTableData} from "./createUserEventCountTableData";

export const getLawClerksOrLawyersClosingsTable = (
  role: "Lawyer" | "Law Clerk",
  closingsList: ClosingsCountApiType,
) => {
  return !closingsList?.resultSum[role]
    ? []
    : Object.keys(closingsList?.resultSum[role] as {})
        .map((lawClerkOrLawyer) => {
          const typedLawClerk = closingsList?.resultSum[role] as {
            oneOfLawClerks: ResultCalendarSumUserType;
          };
          return typedLawClerk[lawClerkOrLawyer as "oneOfLawClerks"];
        })
        .map((lawClerkOrLawyer) =>
          createUserEventCountTableData(
            lawClerkOrLawyer.full_name,
            lawClerkOrLawyer.dealTypes.Purchase,
            lawClerkOrLawyer.dealTypes.Mortgage,
            lawClerkOrLawyer.dealTypes.Sale,
            lawClerkOrLawyer.dealTypes.Lender,
            lawClerkOrLawyer.days.Monday,
            lawClerkOrLawyer.days.Tuesday,
            lawClerkOrLawyer.days.Wednesday,
            lawClerkOrLawyer.days.Thursday,
            lawClerkOrLawyer.days.Friday,
            lawClerkOrLawyer.sum,
            lawClerkOrLawyer.capacity,
          ),
        );
};
