import React from "react";
import {SVGProps} from "react";

const ProfileSlackSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.156.334a2.133 2.133 0 1 0 0 4.266h2.134V2.468A2.135 2.135 0 0 0 8.156.334Zm0 5.689H2.468a2.133 2.133 0 0 0-.001 4.267h5.689a2.133 2.133 0 1 0 0-4.267Z"
      fill="#36C5F0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.666 8.156a2.133 2.133 0 0 0-4.267 0v2.133h2.134c1.179 0 2.134-.955 2.133-2.133Zm-5.689 0v-5.69a2.134 2.134 0 0 0-4.266 0v5.69a2.133 2.133 0 1 0 4.266 0Z"
      fill="#2EB67D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.845 21.667a2.133 2.133 0 1 0 0-4.265H11.71v2.133c0 1.175.954 2.13 2.134 2.132Zm0-5.69h5.689a2.133 2.133 0 0 0 0-4.266h-5.688a2.133 2.133 0 0 0-.001 4.267Z"
      fill="#ECB22E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.334 13.845a2.133 2.133 0 0 0 4.267 0v-2.133H2.468c-1.18 0-2.135.956-2.134 2.133Zm5.689 0v5.689a2.133 2.133 0 0 0 4.266 0v-5.687a2.133 2.133 0 0 0-4.266-.002Z"
      fill="#E01E5A"
    />
  </svg>
);

export default ProfileSlackSvg;
