import React, {useCallback, useEffect, useState} from "react";
import moment from "moment-timezone";
import {timezones} from "../DealDetails/utils";
import AppointmentUpdates from "./AppointmentUpdates";
import MobileSigner from "./MobileSigner";
import {
  useDatepickerMinMaxTime,
  useDisplayDateString,
  useIncomingAppointmentFormData,
} from "./appointmentHooks";
import {AppointmentNullable} from "./types";
import * as yup from "yup";
import {useFormik} from "formik";
import {useDispatch} from "react-redux";
import {
  addSigningAppointmentTC,
  cancelSigningAppointmentTC,
  getBlockedTimeSlotsTC,
  updateAppointmentTC,
} from "redux/reducers/dealsReducer";
import useTypedSelector from "utils/hooks/useTypedSelector";
import {User} from "../../../types/user";
import {setHours, setMinutes, addMinutes} from "date-fns";
import {Deal} from "../../../types/deal";
import {Box, MenuItem, styled, Typography, useTheme} from "@mui/material";
import constants from "../../../styles/constants";
import DeededSelectV2Transparent from "v2/components/DeededSelect";
import useDealSelector from "utils/hooks/useDealSelector";
import DeededButton from "v2/components/DeededButton";
import {NewStaffType} from "./SigningAppointmentFormSection";
import useStaff from "utils/hooks/useStaff";
import {StaffUser} from "redux/reducers/configReducer/staffCardReducer";
import DeededDatePicker from "v2/components/DeededDatePicker";
import DeededTimePicker from "v2/components/DeededTimePicker";

const TIME_PARSE_FORMAT = "HH:mm:ss";
const TIME_DISPLAY_FORMAT = "h:mm aa";
const DATE_PARSE_FORMAT = "YYYY-MM-DD";

const schema = yup.object().shape({
  province: yup
    .string()
    .oneOf(timezones.states)
    .nullable()
    .required("Specify Province"),
  startTime: yup.string().nullable().required("Specify start time"),
  endTime: yup.string().nullable().required("Specify end time"),
  date: yup.string().nullable().required("Specify date"),
  forSelectedUser: yup.number(),
});

const AppointmentForm: React.FC<{
  appointment: AppointmentNullable;
  updateStaffCallback: (
    newStaff: NewStaffType,
    oldSigner: StaffUser | null,
  ) => void;
  deleteUserFromDealStaff: (userId: number) => void;
}> = ({appointment, updateStaffCallback, deleteUserFromDealStaff}) => {
  const {
    incomingAppointmentProvince,
    incomingAppointmentStartTime,
    incomingAppointmentEndTime,
    incomingAppointmentDate,
  } = useIncomingAppointmentFormData(appointment);
  const deal = useDealSelector();
  const clientsList = deal?.participants.filter((participant) =>
    participant.role.includes("Client"),
  );
  const staff = useStaff();
  const appointmentExists = !!appointment?.id;
  const theme = useTheme();
  const dealInfoById = useTypedSelector(
    (state) => state.DealsReducer.deal_info_by_id,
  ) as {closing_date?: string} & Deal & {};
  const closingDate = dealInfoById.closing_date;
  const loading = useTypedSelector((state) => state.DealsReducer.submitLoader);
  const currentUser = useTypedSelector(
    (state) => state.AuthReducer.currentUser,
  ) as User;
  const [isLoading, setIsLoading] = useState(loading);
  const [busyTimes, setBusyTimes] = useState<string[]>([]);
  const [isOpenedClientSelect, setIsOpenedClientSelect] = useState(false);
  const [isOpenedProvinceSelect, setIsOpenedProvinceSelect] = useState(false);
  const [isLoadingBusyTimes, setIsLoadingBusyTimes] = useState(false);
  const setFieldValue = useCallback((name: string, value: boolean) => {
    if (name === "isLoading") {
      setIsLoading(value);
    }
  }, []);
  const appointmentBasicClientIds =
    appointment?.clients?.map((client) => client.id) ?? [];
  const initialValues = {
    province: incomingAppointmentProvince,
    startTime: incomingAppointmentStartTime,
    endTime: incomingAppointmentEndTime,
    date: incomingAppointmentDate,
    mobileSigner: appointment.signer?.id,
    clients: appointmentBasicClientIds,
  };

  const dispatch = useDispatch();
  useEffect(() => {
    const onWindowScroll = () => {
      setIsOpenedProvinceSelect(false);
      setIsOpenedClientSelect(false);
    };
    window.addEventListener("scroll", onWindowScroll);
    return () => {
      window.removeEventListener("scroll", onWindowScroll);
    };
  }, []);
  const onSubmit = ({
    date,
    startTime,
    endTime,
    mobileSigner,
    province,
    clients,
  }: typeof initialValues) => {
    const clientsForSendingToServer = {
      new:
        clients.filter(
          (filterClientId) =>
            !appointmentBasicClientIds!.includes(filterClientId),
        ) ?? null,
      remove:
        appointmentBasicClientIds!.filter(
          (filterClientId) => !clients.includes(filterClientId),
        ) ?? null,
    };
    const payload = {
      address: "Empty Address",
      date: date!,
      signer:
        appointment.signer?.id === mobileSigner
          ? null
          : mobileSigner
          ? {old: appointment.signer?.id ?? null, new: mobileSigner}
          : /** Handle unassignment */
          appointment.signer?.id
          ? /** Case where we had previously mobile signer */
            {
              old: appointment.signer?.id!,
              new: null,
            }
          : /** There wasn't a mobile signer, we send a null object */
            null,
      start_time: startTime!,
      end_time: endTime!,
      timezone: province!,
      clients: clientsForSendingToServer,
    };
    if (mobileSigner && appointment.signer?.id !== mobileSigner) {
      const mobileSignerForUpdateDealStaff = staff?.["Mobile Signer"]?.filter(
        (signer) => signer.id === mobileSigner,
      )?.[0];
      const oldStaff = appointment.signer;
      if (mobileSignerForUpdateDealStaff) {
        updateStaffCallback(
          mobileSignerForUpdateDealStaff as NewStaffType,
          oldStaff,
        );
      }
    } else if (appointment.signer?.id && !mobileSigner) {
      const currentUser = appointment.signer?.id;
      deleteUserFromDealStaff(currentUser);
    }
    if (appointmentExists) {
      dispatch(
        updateAppointmentTC(
          appointment.deal_id,
          appointment.id!,
          payload,
          setFieldValue,
        ),
      );
    } else {
      dispatch(
        addSigningAppointmentTC(appointment.deal_id, payload, setFieldValue),
      );
    }
  };

  const {handleSubmit, values, errors, handleChange, setErrors} = useFormik({
    validationSchema: schema,
    initialValues,
    onSubmit,
    validateOnChange: false,
    validateOnBlur: false,
  });

  useEffect(() => {
    const selectedStartTime = moment(values.startTime, "HH:mm A").format(
      "HH:mm:ss",
    );
    const selectedEndTime = moment(values.endTime, "HH:mm A").format(
      "HH:mm:ss",
    );
    if (busyTimes.includes(selectedStartTime)) {
      setErrors({startTime: "This time is busy"});
      handleChange({
        target: {
          name: "startTime",
          value: null,
        },
      });
    }
    if (busyTimes.includes(selectedEndTime)) {
      setErrors({endTime: "This time is busy"});
      handleChange({
        target: {
          name: "endTime",
          value: null,
        },
      });
    }
  }, [busyTimes, handleChange, setErrors, values.startTime, values.endTime]);

  useEffect(() => {
    if (values.date && values.province && values.mobileSigner) {
      const payload = {
        date: values.date,
        timezone: values.province,
        signer: values.mobileSigner,
        appointment: appointment?.id,
      };

      dispatch(
        getBlockedTimeSlotsTC(
          appointment.deal_id,
          payload,
          setIsLoadingBusyTimes,
          setBusyTimes as (busyTimes: string[]) => void,
        ),
      );
    }
  }, [
    appointment.deal_id,
    dispatch,
    values.date,
    values.province,
    values.mobileSigner,
    appointment?.id,
  ]);

  const displayDateString = useDisplayDateString(
    values.date,
    values.startTime,
    values.province,
    timezones.timezoneToState[currentUser?.timezone as string],
  );
  const onCancel = () => {
    dispatch(cancelSigningAppointmentTC(appointment.deal_id, appointment.id!));
  };
  const [minTime, maxTime] = useDatepickerMinMaxTime(values.date, values.province);
  const hasTimeError = [errors.startTime, errors.endTime].includes(
    "This time is busy",
  );

  const excludedTimes = busyTimes.map((busyTime: string) => {
    const splitTime = busyTime.split(":");
    return setHours(
      setMinutes(new Date(), +splitTime[1]),
      +splitTime[0],
    );
  });

  return (
    <>
      <form onSubmit={handleSubmit} className="signingAppointment">
        <h2 style={{marginBottom: hasTimeError ? "0px" : "2rem"}}>
          Signing Appointment
        </h2>
        {hasTimeError && (
          <BusyTimeTypography>
            Current user is busy on that time
          </BusyTimeTypography>
        )}
        <div className="signingAppointment__container">
          <div className="inputs">
            <div className="inputs__row">
              <label htmlFor="select-appointment-date">
                Appointment Date
                <Box mt="1rem" />
                <DeededDatePicker
                  fullWidth
                  hasError={errors.date}
                  minDate={new Date()}
                  maxDate={moment(closingDate, DATE_PARSE_FORMAT).toDate()}
                  dateStart={values.date === null
                    ? null
                    : moment(values.date, DATE_PARSE_FORMAT).toDate()}
                  name="select-appointment-date"
                  onChange={(date) => {
                    handleChange({
                      target: {
                        name: "date",
                        value: moment(date).format(DATE_PARSE_FORMAT),
                      },
                    });
                  }}
                />
              </label>

              <label htmlFor="select-appointment-time">
                Start Time
                <Box mt="1rem" />
                <DeededTimePicker
                  fullWidth
                  onChange={(date) => {
                    setErrors({});
                    handleChange({
                      target: {
                        name: "startTime",
                        value: moment(date).format(TIME_PARSE_FORMAT),
                      },
                    });
                  }}
                  timeStart={
                    values.startTime === null
                      ? null
                      : moment(values.startTime, TIME_PARSE_FORMAT).toDate()
                  }
                  name="select-appointment-time"
                  minTime={minTime}
                  maxTime={maxTime}
                  excludeTimes={excludedTimes}
                />
                {errors.startTime && (
                  <span className="input-error-text">{errors.startTime}</span>
                )}
              </label>

              <label htmlFor="time">
                End Time
                <Box mt="1rem" />
                <DeededTimePicker
                  fullWidth
                  onChange={(date) => {
                    setErrors({});
                    handleChange({
                      target: {
                        name: "endTime",
                        value: moment(date).format(TIME_PARSE_FORMAT),
                      },
                    });
                  }}
                  timeStart={
                    values.endTime === null
                      ? null
                      : moment(values.endTime, TIME_PARSE_FORMAT).toDate()
                  }
                  name="select-appointment-end-time"
                  minTime={
                    values.startTime
                      ? addMinutes(
                        moment(values.startTime, TIME_PARSE_FORMAT).toDate(),
                        1,
                      )
                      : minTime
                  }
                  maxTime={maxTime}
                  excludeTimes={excludedTimes}
                />
                {errors.endTime && (
                  <span className="input-error-text">{errors.endTime}</span>
                )}
              </label>

              <label htmlFor="province">
                <span>Provinces Time Zone</span>
                <DeededSelectV2Transparent
                  open={isOpenedProvinceSelect}
                  onOpen={() => {
                    setIsOpenedProvinceSelect(true);
                  }}
                  onClose={() => {
                    setIsOpenedProvinceSelect(false);
                  }}
                  hideError={true}
                  error={errors.province}
                  width={167}
                  value={values.province || ""}
                  data-testid="signing-appointment-province-time-zone-drop-down"
                >
                  {timezones.stateOptions.map((option, index) => {
                    return (
                      <MenuItem
                        onClick={(e) => {
                          handleChange({
                            target: {
                              name: "province",
                              value: option.value,
                            },
                          });
                        }}
                        key={index}
                        value={option.value}
                      >
                        {option.label}
                      </MenuItem>
                    );
                  })}
                </DeededSelectV2Transparent>
              </label>

              <SelectClientLabel sx={{position: "relative"}}>
                <span style={{marginBottom: "1rem"}}>Client</span>
                <ClientSelect
                  data-testid="signing-appointment-client-drop-down"
                  open={isOpenedClientSelect}
                  onOpen={() => {
                    setIsOpenedClientSelect(true);
                  }}
                  onClose={() => {
                    setIsOpenedClientSelect(false);
                  }}
                  width={167}
                  value={["Select Client"]}
                  multiple
                >
                  {clientsList?.map((client, index) => (
                    <MenuItem
                      sx={{
                        color: values.clients.includes(client.user_id as never)
                          ? `${constants.colors.red} !important`
                          : constants.colors.title,
                      }}
                      onClick={() => {
                        if (values.clients.includes(client.user_id as never)) {
                          handleChange({
                            target: {
                              name: "clients",
                              value: values.clients.filter(
                                (writtenClient) =>
                                  writtenClient !== client.user_id,
                              ),
                            },
                          });
                        } else {
                          handleChange({
                            target: {
                              name: "clients",
                              value: values.clients.concat([
                                client.user_id as never,
                              ]),
                            },
                          });
                        }
                        setIsOpenedClientSelect(false);
                      }}
                      key={index}
                      value={client.user_id}
                    >
                      {client.full_name}
                    </MenuItem>
                  ))}
                  <MenuItem
                    sx={{
                      display: "none !important",
                    }}
                    value={"Select Client"}
                  >
                    Select Client
                  </MenuItem>
                </ClientSelect>
                <Box
                  sx={{
                    position: "absolute",
                    top: "70px",
                    left: "0px",
                    height: "150px",
                    overflowY: "scroll",
                    "&::-webkit-scrollbar": {
                      width: 0,
                    },
                  }}
                >
                  {values.clients.map((client, index) => {
                    const fullClientInfo = clientsList?.find(
                      (clientInfo) => clientInfo.user_id === client,
                    );
                    return (
                      <Box key={index}>
                        <Typography
                          data-testid="signing-appointment-client-drop-down-selected-client-first-name"
                          sx={{
                            marginTop: "12px",
                            fontSize: "14px",
                            color: constants.colors.deededGray,
                          }}
                        >
                          {fullClientInfo?.full_name}
                        </Typography>
                        <Typography
                          data-testid="signing-appointment-client-drop-down-selected-client-role"
                          sx={{
                            color: constants.colors.title,
                            fontSize: "10px",
                            fontWeight: "600",
                          }}
                        >
                          {fullClientInfo?.role}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
              </SelectClientLabel>
            </div>
            {displayDateString !== null && (
              <div
                className="time-zone"
                data-testid="signing-appointment-created-timezone"
              >
                Your local time for chosen province {values.province}, will be:{" "}
                {displayDateString}
              </div>
            )}
            <AppointmentUpdates updates={appointment.appointment_updates} />
          </div>
          <MobileSigner
            onUnAssign={() => {
              handleChange({
                target: {name: "mobileSigner", value: undefined},
              });
              if (appointment.signer?.id) {
                onSubmit({...values, mobileSigner: undefined});
              }
            }}
            signer={appointment.signer}
            value={values.mobileSigner}
            appointment={appointment.signer}
            name="mobileSigner"
            onChange={handleChange}
            disabled={
              !values.date ||
              !values.province ||
              !values.startTime ||
              !values.endTime
            }
            params={{
              date: values.date ?? "",
              start_time: values.startTime ?? "",
              end_time: values.endTime ?? "",
              timezone: values.province ?? "",
              appointment_id: appointment.id!,
            }}
          />
        </div>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop:
              appointment.appointment_updates?.length === 0
                ? "80px"
                : appointment.appointment_updates?.length === 1
                ? "60px"
                : appointment.appointment_updates?.length === 2
                ? "40px"
                : "20px",
            [theme.breakpoints.down("md")]: {
              marginTop: "20px",
            },
          }}
        >
          <Box sx={{display: "flex"}}>
            <DeededButton
              data-testid="signing-appointment-cancel-button"
              sx={{
                width: "100px",
                marginRight: "20px",
              }}
              kind={"secondary"}
              onClick={onCancel}
              disabled={!appointmentExists}
              className="cancel-appt"
              type="button"
            >
              Cancel
            </DeededButton>
            <DeededButton
              data-testid="signing-appointment-save-button"
              sx={{
                width: "100px",
              }}
              type="submit"
              disabled={isLoading || isLoadingBusyTimes}
            >
              {isLoading || isLoadingBusyTimes ? "Loading" : "Save"}
            </DeededButton>
          </Box>
        </Box>
      </form>
    </>
  );
};
const SelectClientLabel = styled("label")({});
const ClientSelect = styled(DeededSelectV2Transparent)({
  "&.MuiBox-root": {
    width: "250px",
  },
});
const BusyTimeTypography = styled(Typography)({
  fontSize: "20px",
  marginBottom: "3px",
  fontWeight: 500,
  color: constants.colors.red,
});
export default AppointmentForm;
