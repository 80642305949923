import React from "react";

const StorageBigImageSvg = () => {
  return (
    <svg
      width="109"
      height="109"
      viewBox="0 0 109 109"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.8436 16.1797C23.6178 16.1797 23.4012 16.2694 23.2416 16.4291C23.0819 16.5888 22.9922 16.8054 22.9922 17.0312V91.9688C22.9922 92.1946 23.0819 92.4112 23.2416 92.5709C23.4012 92.7306 23.6178 92.8203 23.8436 92.8203H85.1564C85.3822 92.8203 85.5987 92.7306 85.7584 92.5709C85.9181 92.4112 86.0078 92.1946 86.0078 91.9688V38.5269L63.6617 16.1797H23.8436C23.8436 16.1797 23.8436 16.1797 23.8436 16.1797ZM19.6286 12.8163C20.7465 11.6984 22.2626 11.0704 23.8435 11.0703H64.7199C65.3975 11.0703 66.0473 11.3395 66.5264 11.8186L90.369 35.6624C90.8481 36.1414 91.1172 36.7912 91.1172 37.4688V91.9688C91.1172 93.5496 90.4892 95.0658 89.3714 96.1837C88.2535 97.3016 86.7374 97.9296 85.1565 97.9297H23.8435C22.2626 97.9296 20.7465 97.3016 19.6286 96.1837C18.5108 95.0658 17.8828 93.5496 17.8828 91.9688V17.0313C17.8828 17.0313 17.8828 17.0313 17.8828 17.0313C17.8828 15.4504 18.5108 13.9342 19.6286 12.8163Z"
        fill="#828282"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.7187 11.0703C66.1297 11.0703 67.2734 12.2141 67.2734 13.625V34.9141H88.5659C89.9768 34.9141 91.1206 36.0578 91.1206 37.4688C91.1206 38.8797 89.9768 40.0234 88.5659 40.0234H64.7187C63.3078 40.0234 62.1641 38.8797 62.1641 37.4688V13.625C62.1641 12.2141 63.3078 11.0703 64.7187 11.0703Z"
        fill="#828282"
      />
      <path
        d="M50.5825 73.0534L58.864 62.494C58.9447 62.3921 59.0474 62.3098 59.1645 62.2531C59.2815 62.1965 59.4099 62.1671 59.5399 62.1671C59.6699 62.1671 59.7983 62.1965 59.9153 62.2531C60.0324 62.3098 60.1351 62.3921 60.2158 62.494L74.7456 81.0155C75.182 81.569 74.7882 82.3887 74.075 82.3887H34.9138C34.7544 82.389 34.5981 82.3445 34.4628 82.2602C34.3275 82.1759 34.2186 82.0552 34.1487 81.912C34.0787 81.7688 34.0505 81.6087 34.0672 81.4502C34.0839 81.2917 34.1449 81.141 34.2432 81.0155L44.8664 67.4757C44.9461 67.3739 45.0478 67.2916 45.164 67.235C45.2802 67.1784 45.4078 67.1489 45.537 67.1489C45.6662 67.1489 45.7938 67.1784 45.91 67.235C46.0262 67.2916 46.128 67.3739 46.2076 67.4757L50.5825 73.0534Z"
        fill="#828282"
      />
      <path
        d="M39.5565 58.3623C38.758 57.5638 38.3094 56.4808 38.3094 55.3516C38.3094 54.2223 38.758 53.1393 39.5565 52.3408C40.3549 51.5423 41.4379 51.0938 42.5672 51.0938C43.6964 51.0938 44.7794 51.5423 45.5779 52.3408C46.3764 53.1393 46.825 54.2223 46.825 55.3516C46.825 56.4808 46.3764 57.5638 45.5779 58.3623C44.7794 59.1608 43.6964 59.6094 42.5672 59.6094C41.4379 59.6094 40.3549 59.1608 39.5565 58.3623Z"
        fill="#828282"
      />
    </svg>
  );
};

export default StorageBigImageSvg;
