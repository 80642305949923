export const memberStyles = {
  container: (base) => ({
    ...base,
    width: "100%",
    height: "40px",
    zIndex: 10,
    border: "1px solid #e3e3e3",
    borderRadius: "5px",
    background: "#fff",
    "div::-webkit-scrollbar": {
      width: "5px",
    },
  }),
  valueContainer: (base) => ({
    ...base,
    padding: "0",
    "div::-webkit-scrollbar": {
      width: "5px",
    },
  }),
  menuList: (base) => ({
    ...base,
    height: "120px",
  }),
  control: () => ({
    height: "40px",
    display: "flex",
    alignItems: "center",
  }),
  input: (base) => ({
    ...base,
    paddingLeft: "25px",
  }),
  singleValue: (base) => ({
    ...base,
    display: "flex",
    alignItems: "center",
    padding: "0",
    top: "50%",
    paddingLeft: "25px",
  }),
  placeholder: (base) => ({
    ...base,
    display: "none",
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: "none",
  }),
};
export const customStyles = {
  container: (base) => ({
    ...base,
    position: "relative",
    width: "100%",
    height: "40px",
    "div::-webkit-scrollbar": {
      width: "5px",
    },
  }),

  placeholder: (base) => ({
    ...base,
    display: "none",
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: "none",
  }),
};
