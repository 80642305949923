import React from "react";

const StoragePdfFileSvg = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25 28H6.99995C6.73474 28 6.4804 27.8946 6.29287 27.7071C6.10534 27.5196 5.99999 27.2652 6 27V5C5.99999 4.73479 6.10534 4.48044 6.29287 4.2929C6.4804 4.10537 6.73474 4.00001 6.99995 4H19.0003L26 11V27C26 27.2652 25.8947 27.5196 25.7071 27.7071C25.5196 27.8946 25.2653 28 25 28V28Z"
        stroke="#828282"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 4V11H26.001"
        stroke="#828282"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.184 24.048C8.992 24.048 8.84 23.992 8.728 23.88C8.616 23.768 8.56 23.616 8.56 23.424V18.944C8.56 18.7573 8.61067 18.6133 8.712 18.512C8.81333 18.4107 8.95733 18.36 9.144 18.36H11.152C11.7653 18.36 12.2427 18.5147 12.584 18.824C12.9253 19.1333 13.096 19.5653 13.096 20.12C13.096 20.6747 12.9253 21.1067 12.584 21.416C12.2427 21.7253 11.7653 21.88 11.152 21.88H9.808V23.424C9.808 23.616 9.752 23.768 9.64 23.88C9.528 23.992 9.376 24.048 9.184 24.048ZM10.992 20.936C11.616 20.936 11.928 20.6667 11.928 20.128C11.928 19.584 11.616 19.312 10.992 19.312H9.808V20.936H10.992ZM14.4878 24C14.3011 24 14.1571 23.9493 14.0558 23.848C13.9544 23.7467 13.9038 23.6027 13.9038 23.416V18.944C13.9038 18.7573 13.9544 18.6133 14.0558 18.512C14.1571 18.4107 14.3011 18.36 14.4878 18.36H16.0958C16.7091 18.36 17.2371 18.472 17.6798 18.696C18.1224 18.92 18.4611 19.2427 18.6958 19.664C18.9304 20.0853 19.0478 20.5893 19.0478 21.176C19.0478 21.7627 18.9304 22.2693 18.6958 22.696C18.4664 23.1173 18.1304 23.44 17.6878 23.664C17.2451 23.888 16.7144 24 16.0958 24H14.4878ZM16.0078 22.992C17.2078 22.992 17.8078 22.3867 17.8078 21.176C17.8078 19.9707 17.2078 19.368 16.0078 19.368H15.1518V22.992H16.0078ZM20.6293 24.048C20.4426 24.048 20.2906 23.992 20.1733 23.88C20.0613 23.7627 20.0053 23.6107 20.0053 23.424V18.944C20.0053 18.7573 20.056 18.6133 20.1573 18.512C20.2586 18.4107 20.4026 18.36 20.5893 18.36H23.3413C23.544 18.36 23.6933 18.4 23.7893 18.48C23.8853 18.56 23.9333 18.6853 23.9333 18.856C23.9333 19.0213 23.8853 19.144 23.7893 19.224C23.6933 19.304 23.544 19.344 23.3413 19.344H21.2373V20.64H23.1813C23.384 20.64 23.5333 20.68 23.6293 20.76C23.7253 20.84 23.7733 20.9653 23.7733 21.136C23.7733 21.3013 23.7253 21.424 23.6293 21.504C23.5333 21.584 23.384 21.624 23.1813 21.624H21.2373V23.424C21.2373 23.616 21.1813 23.768 21.0693 23.88C20.9626 23.992 20.816 24.048 20.6293 24.048Z"
        fill="#828282"
      />
    </svg>
  );
};

export default StoragePdfFileSvg;
