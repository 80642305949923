import {CalendarEvent} from "../../../../redux/reducers/calendarReducer";
import {BlockedEvent} from "../../../../types/CalendarComponent/blockedEventTypes";
import moment from "moment";

const formatToSortEventsForMobile = "YYYY-MM-DD";
export const getSortedArrayOfEventsForMobile = (
  allEvents: (CalendarEvent | BlockedEvent)[],
  date: Date,
) => {
  let sortedArray = [];
  let dateMomentForSorting = moment(date)
    .startOf("month")
    .startOf("week")
    .format(formatToSortEventsForMobile);
  const startOfNextMonthString = moment(date).endOf("month").endOf("week");
  while (
    moment(dateMomentForSorting, formatToSortEventsForMobile).isBefore(
      startOfNextMonthString,
    )
  ) {
    const arrayForCurrentDate = allEvents.filter(
      (event) => {
        if (event === undefined) return false;
        return moment(event?.start_at, formatToSortEventsForMobile).format(formatToSortEventsForMobile) === dateMomentForSorting;
      }
    );
    const sortingByTimeArray = Array.from(arrayForCurrentDate);
    for (let i = 1; i < sortingByTimeArray.length; i++) {
      for (let j = 0; j < sortingByTimeArray.length - i; j++) {
        if (
          moment(sortingByTimeArray[j].start_at, "HH:mm:ss").toDate() >
          moment(sortingByTimeArray[j + 1].start_at, "HH:mm:ss").toDate()
        ) {
          [sortingByTimeArray[j], sortingByTimeArray[j + 1]] = [
            sortingByTimeArray[j + 1],
            sortingByTimeArray[j],
          ];
        }
      }
    }
    if (sortingByTimeArray.length > 0) {
      sortedArray.push(sortingByTimeArray);
    }
    dateMomentForSorting = moment(
      dateMomentForSorting,
      formatToSortEventsForMobile,
    )
      .add(1, "d")
      .format(formatToSortEventsForMobile);
  }

  return sortedArray;
};
