import {useState} from "react";
import {addDealFile} from "../../../api/index";
import "./styles.scss";
import { useSnackbar } from "notistack";
import DeededPopup from "v2/components/DeededPopup";
import constants from "styles/constants";
import configuration from "../../../utils/configuration";

const validFilesList = [
  {type: "application/pdf"},
  {type: "text/plain"},
  {type: "image/jpeg"},
  {type: "image/gif"},
  {type: "image/png"},
  {type: "application/msword"},
  {type: "application/rtf"},
  {type: "application/x-zip-compressed"},
  {type: "application/vnd.ms-excel"},
  {
    type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  },
  {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"},
  {type: "text/csv"},
  {type: "image/heic"},
];
const isValidFile = (doc) => {
  const filtered = validFilesList.find((file) => file.type === doc.type);
  if (filtered === undefined) {
    return false;
  }
  return true;
};
const DirectFileUpload = ({
  dealID,
  dealType,
  directFile,
  handleClose,
  toggleDirectFilesPopup,
  open,
}) => {
  const {enqueueSnackbar} = useSnackbar();
  const [fileData, setFileData] = useState(null);
  const getUploadParams = (event) => {
    let file = event.target.files[0];
    let reader = new FileReader();

    if (!isValidFile(file)) {
      enqueueSnackbar("Not valid file format", {
        variant: "error",
        autoHideDuration: configuration.autoHideErrorDuration,
      });
      return;
    }
    reader.readAsDataURL(file);

    reader.onload = () => {
      setFileData({
        name: file.name,
        size: file.size,
        type: file.type,
        data: reader.result,
      });
    };
  };
  const handleUploadFile = () => {
    const payload = {
      agreements: [
        {
          document_type: directFile.document_type,
          id: directFile.id,
          file: fileData,
        },
      ],
    };
    addDealFile(dealID, payload)
      .then((res) => {
        toggleDirectFilesPopup();
        handleClose();
      })
      .catch((error) => {
        enqueueSnackbar(
          error &&
            error.response &&
            error.response.data &&
            error.response.data.error,
          {
            variant: "error",
            autoHideDuration: configuration.autoHideErrorDuration,
          },
        );
      });
  };
  return (
    <DeededPopup
      sx={{}}
      open={open}
      setOpen={handleClose}
      title={directFile.document_type}
      sxInternalBox={{
        width: "300px",
        overflowX: "hidden",
        overflowY: "auto",
        backgroundColor: constants.colors.disabled2,
        "&::-webkit-scrollbar": {
          width: "5px",
          scrollbarWidth: "thin",
        },
      }}
    >
      <div className="directupload__wrapper">
        {directFile && (
          <div className="directupload__inputs">
            <div className="directupload__upload">
              <label
                htmlFor="directupload-upload"
                className={
                  fileData === null
                    ? "directupload-type"
                    : "directupload-uploaded"
                }
              >
                {fileData === null
                  ? "Upload file"
                  : `${fileData.name.slice(0, 20)}`}
              </label>
              <input
                id="directupload-upload"
                type="file"
                style={{display: "none"}}
                onChange={(e) => getUploadParams(e)}
              ></input>
            </div>
          </div>
        )}
        <button
          className="directupload__submit--btn"
          onClick={() => {
            handleUploadFile();
          }}
        >
          submit
        </button>
      </div>
    </DeededPopup>
  );
};

export default DirectFileUpload;
