import { FC } from "react";
import { styled } from "@mui/material";
import Stack from "@mui/material/Stack";
import { getUserType, shouldCreateNewDealClient } from "utils/permissionRoles";
import useUser from "utils/hooks/useUser";
import { useMixpanel } from "utils/MixpanelContext";
import Colors from "../../CommonDashboard/Styling/Colors";
import { BuildingIcon, HomeIcon, PlusIcon } from "../../CommonDashboard/Icons";
import BottomNavLink from "./BottomNavLink";

const BottomNav: FC = () => {
    const mixpanel = useMixpanel();
    const { user } = useUser();

    return (
        <Wrapper direction="row">
            <BottomNavLink to={"/dashboard"} icon={<HomeIcon />} label={{ text: "Home" }} />
            <BottomNavLink to={"/dashboard/deals"} icon={<BuildingIcon />} label={{ text: "My transactions" }} />
            {shouldCreateNewDealClient(user.role) && (
                <BottomNavLink
                    to={"/dashboard/new-client"}
                    icon={<PlusIcon />}
                    label={{ text: "Add new transaction" }}
                    callback={() =>
                        mixpanel.track(`dashboard_add_new_deal`, {
                            role: user.role,
                            email: user.email,
                            user_type: getUserType(user.role),
                        })
                    }
                />
            )}
        </Wrapper>
    );
};

const Wrapper = styled(Stack)(({ theme }) => ({
    zIndex: 98,
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    height: "7.5rem",
    background: Colors.PRIMARY_600,
    borderTopLeftRadius: "2.4rem",
    borderTopRightRadius: "2.4rem",
    padding: "0.8rem",
    gap: "0.8rem",
    justifyContent: "space-evenly",
    [theme.breakpoints.up("mobile")]: {
        display: "none !important",
    },
}));

export default BottomNav;
