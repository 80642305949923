import { FC, useCallback, useMemo, useState } from "react";
import Stack from "@mui/material/Stack";
import {styled} from "@mui/material/styles";
import { H1 } from "components/CommonDashboard/Typography";
import NavTabs from "components/CommonDashboard/NavTabs";
import useUser from "utils/hooks/useUser";
import { isClient, isProfessional, shouldShowPreferences } from "utils/permissionRoles";
import useQueryParams from "utils/hooks/useQueryParams";
import ProfileGeneralTab from "./ProfileGeneralTab";
import ProfileIntegrationTab from "./ProfileIntegrationTab";
import ProfileSecurityTab from "./ProfileSecurityTab";
import ProfilePreferencesTab from "./ProfilePreferencesTab";
import ProfileLandingPageTab from "./ProfileLandingPageTab";

const Profile: FC = () => {
    const params = useQueryParams();
    const { user } = useUser();
    const [activeTab, setActiveTab] = useState(params?.page || "General");

    const tabOptions = useMemo(() => {
        return [
            "General",
            !isProfessional(user.role) && !isClient(user.role) ? "Integration" : null,
            "Security",
            shouldShowPreferences(user.role) ? "Preferences" : null,
            isProfessional(user.role) ? "Client Landing Page" : null,
        ].filter((opt) => opt !== null) as string[];
    }, [user]);

    const showTab = useCallback(
        (tab: string) => tab === activeTab && tabOptions.includes(tab),
        [activeTab, tabOptions],
    );

    return (
        <Content>
            <H1>My profile</H1>
            <NavTabs options={tabOptions} value={activeTab} onChange={setActiveTab} />

            {showTab("General") && <ProfileGeneralTab />}
            {showTab("Integration") && <ProfileIntegrationTab />}
            {showTab("Security") && <ProfileSecurityTab />}
            {showTab("Preferences") && <ProfilePreferencesTab />}
            {showTab("Client Landing Page") && <ProfileLandingPageTab />}
        </Content>
    );
};

const Content = styled(Stack)(({theme}) => ({
    gap: '2.4rem',
    padding: '2.4rem 4.8rem',
    [theme.breakpoints.down("mobile")]: {
        paddingBottom: "10rem",
    },
}))

export default Profile;
