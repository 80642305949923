import {AnyAction, Dispatch} from "redux";
import {
  createCommunications,
  deleteCommunications,
  getCommunications,
  getDealStages,
  getShortcodes,
  updateCommunications,
  updateCommunicationsToggle,
} from "../../../api";
import {addErrorAC, addSuccessMessageAC} from "../ErrorReducer";
import {
  CommunicationTemplate,
  StagesListType,
} from "../types/configNotificationsReducerType";

interface ShortCodesType {
  code: string;
  example: string;
  description: string;
}
export interface ConfigNotificationsReducerState {
  serviceTypesList: string[];
  selectedServiceType: string;
  stagesList: null | StagesListType;
  selectedStage: number | null;
  communications: CommunicationTemplate[] | null;
  shortcodes: ShortCodesType[];
}

type ConfigNotificationsType =
  | "SET_SELECTED_SERVICE_TYPE"
  | "SET_STAGES_LIST"
  | "SET_SELECTED_STAGE"
  | "SET_COMMUNICATIONS"
  | "SET_SHORTCODES";

export type ConfigNotificationAction = {
  type: ConfigNotificationsType;
  payload?: unknown;
};

const notificationsState: ConfigNotificationsReducerState = {
  serviceTypesList: ["Purchase", "Sale", "Lender", "Mortgage"],
  selectedServiceType: "Purchase",
  stagesList: null,
  selectedStage: null,
  communications: null,
  shortcodes: [],
};

export const ConfigNotificationsReducer = (
  state = notificationsState,
  action: ConfigNotificationAction,
): ConfigNotificationsReducerState => {
  switch (action.type) {
    case "SET_SHORTCODES": {
      return {
        ...state,
        shortcodes: action.payload as ShortCodesType[],
      };
    }
    case "SET_COMMUNICATIONS": {
      return {
        ...state,
        communications: action.payload as CommunicationTemplate[] | null,
      };
    }
    case "SET_SELECTED_STAGE": {
      return {
        ...state,
        selectedStage: action.payload as number | null,
      };
    }
    case "SET_STAGES_LIST": {
      return {
        ...state,
        stagesList: action.payload as null | StagesListType,
      };
    }
    case "SET_SELECTED_SERVICE_TYPE": {
      return {
        ...state,
        selectedServiceType: action.payload as string,
      };
    }
    default:
      return state;
  }
};
export const setShortcodesAC = (payload: ShortCodesType[]) => ({
  type: "SET_SHORTCODES",
  payload,
});
export const setCommunicationsAC = (
  payload: CommunicationTemplate[] | null,
) => ({
  type: "SET_COMMUNICATIONS",
  payload,
});
export const setSelectedStageAC = (payload: number | null) => ({
  type: "SET_SELECTED_STAGE",
  payload,
});
export const setSelectedServiceTypeAC = (payload: string) => ({
  type: "SET_SELECTED_SERVICE_TYPE",
  payload,
});
export const setStagesListAC = (payload: null | StagesListType) => ({
  type: "SET_STAGES_LIST",
  payload,
});

export const getDealStagesTC = () => async (dispatch: Dispatch) => {
  try {
    const dealStagesPromise = await getDealStages();
    dispatch(setStagesListAC(dealStagesPromise.data));
  } catch (e) {
    const error = e as {response: {data: {error: string}}};
    dispatch(addErrorAC(error.response.data.error ?? "Cannot get stages"));
  }
};
export const getCommunicationsTC =
  (id: number) => async (dispatch: Dispatch) => {
    try {
      const communicationsPromise = await getCommunications(id);
      dispatch(setCommunicationsAC(communicationsPromise.data));
    } catch (e) {
      const error = e as {response: {data: {error: string}}};
      dispatch(
        addErrorAC(error.response.data.error ?? "Cannot get communications"),
      );
    }
  };
export const updateCommunicationsToggleTC =
  (
    template: CommunicationTemplate,
    setActive: (active: boolean) => void,
    active: boolean,
  ) =>
  async (dispatch: Dispatch) => {
    try {
      setActive(active);
      const payload = {
        active: +Boolean(active),
      };
      const updateCommunicationsTogglePromise =
        await updateCommunicationsToggle(template.id, payload);
    } catch (e) {
      setActive(!active);
      const error = e as {response: {data: {error: string}}};
      dispatch(
        addErrorAC(
          error.response.data.error ?? "Cannot change switcher position",
        ),
      );
    }
  };
export const deleteCommunicationsTemplateTC =
  (templateId: number, stageId: number) => async (dispatch: Dispatch) => {
    try {
      const deleteCommunicationsPromise = await deleteCommunications(
        templateId,
      );
      dispatch(getCommunicationsTC(stageId) as unknown as AnyAction);
    } catch (e) {
      const error = e as {response: {data: {error: string}}};
      dispatch(
        addErrorAC(error.response.data.error ?? "Cannot delete template"),
      );
    }
  };
export const createCommunicationsTC =
  (createData: unknown & {deal_stage_id: number}) =>
  async (dispatch: Dispatch) => {
    try {
      const createCommunicationPromise = await createCommunications(createData);
      dispatch(addSuccessMessageAC("Successfully created"));
      dispatch(
        getCommunicationsTC(createData.deal_stage_id) as unknown as AnyAction,
      );
    } catch (e) {
      const error = e as {response: {data: {error: string}}};
      dispatch(
        addErrorAC(error.response.data.error ?? "Cannot create template"),
      );
    }
  };
export const updateCommunicationsTC =
  (updateData: unknown, templateId: number, stageId: number) =>
  async (dispatch: Dispatch) => {
    try {
      const updateCommunicationsPromise = await updateCommunications(
        updateData,
        templateId,
      );
      dispatch(addSuccessMessageAC("Successfully updated"));
      dispatch(getCommunicationsTC(stageId) as unknown as AnyAction);
    } catch (e) {
      const error = e as {response: {data: {error: string}}};
      dispatch(
        addErrorAC(error.response.data.error ?? "Cannot update template"),
      );
    }
  };
export const getShortcodesTC = () => async (dispatch: Dispatch) => {
  const shortcodes = await getShortcodes();
  dispatch(setShortcodesAC(Object.values(shortcodes.data)));
};
