import axios, {AxiosResponse} from "axios";
import {ActionType} from "../actionTypes";
import {submitLoaderAC} from "../reducers/dealsReducer";
import {Dispatch} from "redux";
import {
  CallbackProp,
  DealCreateResultType,
  NewDealProp,
  ResultCBProp,
} from "../../types/reduxActions/deals";

export const CreateDeal =
  (
    newDeal: NewDealProp,
    resultCB: (arg0: AxiosResponse<ResultCBProp>) => void,
    callback: (arg0: CallbackProp) => void,
  ) =>
  (dispatch: Dispatch) => {
    dispatch(loaderOn());

    axios
      .post(`/deals/create`, newDeal)
      .then((res) => {
        dispatch(submitLoaderAC(false));
        resultCB(res);
        dispatch(dealCreate(res));
        dispatch({type: ActionType.DEAL_CLEAR});
      })
      .catch((error) => {
        dispatch(submitLoaderAC(false));
        callback(error);
      });
  };

export const setStage =
  (dealID: number, stageID: number) => (dispatch: Dispatch) => {
    let stage = {
      deal_stage_id: stageID,
    };
    dispatch(loaderOn());
    axios
      .post(`/deals/${dealID}/set-stage`, stage)
      .then((res) => {
        dispatch({type: ActionType.SET_STAGE, res});
      })
      .catch((error) => {});
  };

export const fetchDealById = (dealID: number) => (dispatch: Dispatch) => {
  dispatch(loaderOn());

  axios
    .get(`/deals/${dealID}`)
    .then((res) => {
      dispatch({type: ActionType.DEAL_FETCH_BY_ID, payload: res.data});
    })
    .catch((error) => {});
};

const loaderOn = () => {
  return {
    type: ActionType.DEALS_LOADER_ON,
  };
};
const dealCreate = (deal: AxiosResponse<DealCreateResultType>) => {
  return {
    type: ActionType.DEAL_CREATE,
    payload: deal.data,
  };
};
