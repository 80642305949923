import React, {useMemo, useState} from "react";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import moment from "moment";
import {
  Box,
  Button,
  IconButton,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import constants from "styles/constants";
import useTypedSelector from "utils/hooks/useTypedSelector";
import {ClosingsDealsCountDailyType} from "redux/reducers/types/calendarApiTypes";
import {getDealByIdCalendarClosingsTC} from "redux/reducers/calendarReducer";
import DeededDatePicker from "v2/components/DeededDatePicker";
import DeededButton from "v2/components/DeededButton";
import {
  miniDesktopCalendar,
  smallDesktopCalendar,
  smallPhoneCalendar,
} from "../constants/breakpoints";
import DownloadClosingsReportSvg from "../svg/DownloadClosingsReportSvg";
import {scrollbarStyles} from "../constants/scrollbarStyles";

interface BigCalendarClosingsDayV2Props {
  width: string;
  date: Date;
  setDate: (date: Date) => void;
  downloadClosingsReportCallback: () => void;
  setShowDealPreview: (showDealPreview: boolean) => void;
}
const BigCalendarClosingsDayV2: React.FC<BigCalendarClosingsDayV2Props> = ({
  width,
  date,
  setDate,
  downloadClosingsReportCallback,
  setShowDealPreview,
}) => {
  const closingsDealsCountDaily = useTypedSelector(
    (state) => state.CalendarReducer.closingsDealsCountDaily,
  );
  const isDayToday =
    moment(date).format("MM/DD/YYYY") ===
    moment(new Date()).format("MM/DD/YYYY");
  const titleDate = moment(date).format("DD MMMM, YYYY");
  const isBlockedList =
    getBlockedOffListForDayViewByClosingsDealsCountDaily(
      closingsDealsCountDaily,
    ).length > 0;
  const isClosingsToShow = !!closingsDealsCountDaily?.resultCalendar?.deals;
  return (
    <>
      <BigCalendarClosingsDayBox
        sx={{
          width: width,
        }}
      >
        <TitleBox>
          <TitleFirstBox>
            <Title>
              Closings{isDayToday ? " Today" : ""}, {titleDate}
            </Title>
            <SelectedDateComponent date={date} setDate={setDate} />
          </TitleFirstBox>
          <IconButton
            onClick={() => {
              downloadClosingsReportCallback();
            }}
            sx={{
              minWidth: 0,
              marginTop: "-8px",
              width: "40px",
              height: "40px",
            }}
          >
            <DownloadClosingsReportSvg />
          </IconButton>
        </TitleBox>
        <ContentStaffBox>
          {isClosingsToShow && (
            <BigCalendarClosingsDayList
              setShowDealPreview={setShowDealPreview}
            />
          )}
          {isBlockedList && <BlockedOffListComponent />}
        </ContentStaffBox>
      </BigCalendarClosingsDayBox>
    </>
  );
};
const BlockedOffListComponent = () => {
  return (
    <>
      <BlockedOffListComponentBox>
        <BlockedOffTitle>Blocked off today</BlockedOffTitle>
        <BlockedOffList />
      </BlockedOffListComponentBox>
    </>
  );
};
const getBlockedOffListForDayViewByClosingsDealsCountDaily = (
  closingsDealsCountDaily: ClosingsDealsCountDailyType | null,
) => {
  if (closingsDealsCountDaily && closingsDealsCountDaily?.blockSlots) {
    const arrayForFilter = Object.keys(closingsDealsCountDaily?.blockSlots).map(
      (blockSlotKey) => {
        return closingsDealsCountDaily?.blockSlots[
          blockSlotKey as unknown as number
        ];
      },
    );
    const filteringArray = arrayForFilter ? arrayForFilter : [];
    return filteringArray
      .filter((blockSlot) => {
        return blockSlot.userOff;
      })
      .map((block) => {
        return {
          fullName: block.userOff?.full_name,
          role: block.userOff?.role,
        };
      });
  } else {
    return [];
  }
};
const BlockedOffList = () => {
  const [isShowedMoreBlocked, setIsShowedMoreBlocked] = useState(false);
  const closingsDealsCountDaily = useTypedSelector(
    (state) => state.CalendarReducer.closingsDealsCountDaily,
  );

  const blockedOffList = useMemo(
    () =>
      getBlockedOffListForDayViewByClosingsDealsCountDaily(
        closingsDealsCountDaily,
      ),
    [closingsDealsCountDaily],
  );
  const slicedListCountNumber = 3;
  const blockedOffListToShow = useMemo(() => {
    if (!isShowedMoreBlocked) {
      return blockedOffList.slice(0, slicedListCountNumber);
    } else {
      return blockedOffList;
    }
  }, [blockedOffList, isShowedMoreBlocked]);
  return (
    <>
      <BlockedOffListBox>
        {blockedOffListToShow.map((blockedOffUser, index) => {
          const isLastUser = blockedOffListToShow.length === index + 1;
          return (
            <>
              <Box
                sx={{
                  display: "flex",
                  marginBottom: isLastUser ? "0px" : "16px",
                }}
                key={index}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "500",
                    color: constants.colors.sortingColor,
                    marginRight: "6px",
                  }}
                >
                  {blockedOffUser.fullName}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                    color: constants.colors.sortingColor,
                  }}
                >
                  ({blockedOffUser.role})
                </Typography>
              </Box>
            </>
          );
        })}
        <Button
          onClick={() => {
            setIsShowedMoreBlocked(true);
          }}
          sx={{
            marginTop: "13px",
            fontSize: "14px",
            textTransform: "capitalize",
            color: constants.colors.red,
            marginLeft: "-7px",
            "&:hover": {
              backgroundColor: constants.colors.redVeryPale,
              transition: constants.transitions,
            },
            transition: constants.transitions,
          }}
        >
          Show more
        </Button>
      </BlockedOffListBox>
    </>
  );
};
const BigCalendarClosingsDayList: React.FC<{
  setShowDealPreview: (showDealPreview: boolean) => void;
}> = ({setShowDealPreview}) => {
  const theme = useTheme();
  const history = useHistory();
  const [isShowedMoreClosings, setIsShowedMoreClosings] = useState(false);
  const closingsDealsCountDaily = useTypedSelector(
    (state) => state.CalendarReducer.closingsDealsCountDaily,
  );
  const dispatch = useDispatch();
  const closings = useMemo(
    () =>
      closingsDealsCountDaily?.resultCalendar?.deals
        ? closingsDealsCountDaily?.resultCalendar?.deals
        : [],
    [closingsDealsCountDaily?.resultCalendar?.deals],
  );
  const slicedListCountNumber = 20;
  const closingsListToShow = useMemo(() => {
    if (!isShowedMoreClosings) {
      return closings.slice(0, slicedListCountNumber);
    } else {
      return closings;
    }
  }, [closings, isShowedMoreClosings]);
  return (
    <>
      <ClosingsListBox>
        {closingsListToShow.map((closing, index) => {
          const isLastClosing = closingsListToShow.length === index + 1;
          return (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                paddingRight: "30px",
                marginBottom: isLastClosing ? "0px" : "16px",
              }}
              key={index}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  color: constants.colors.sortingColor,
                }}
              >
                <span
                  style={{
                    fontWeight: "500",
                  }}
                >
                  {closing.deal_info}
                </span>{" "}
                {closing.users}
              </Typography>
              <DeededButton
                disabled={!closing.deal_id}
                onClick={() => {
                  setShowDealPreview(true);
                  dispatch(
                    getDealByIdCalendarClosingsTC(closing.deal_id as number),
                  );
                }}
                sx={{
                  height: "20px",
                  width: "78px",
                  minWidth: "78px",
                  fontWeight: "400",
                  fontSize: "12px",
                  borderRadius: "4px",
                  [theme.breakpoints.down("sm")]: {
                    maxWidth: "78px",
                  },
                }}
              >
                Details
              </DeededButton>
            </Box>
          );
        })}
        <Button
          onClick={() => {
            setIsShowedMoreClosings(true);
          }}
          sx={{
            marginTop: "13px",
            fontSize: "14px",
            textTransform: "capitalize",
            color: constants.colors.red,
            marginLeft: "-7px",
            "&:hover": {
              backgroundColor: constants.colors.redVeryPale,
              transition: constants.transitions,
            },
            transition: constants.transitions,
          }}
        >
          Show more
        </Button>
      </ClosingsListBox>
    </>
  );
};
const SelectedDateComponent: React.FC<{
  date: Date;
  setDate: (date: Date) => void;
}> = ({date, setDate}) => {
  const theme = useTheme();
  const [isActiveDatePicker, setIsActiveDatePicker] = useState(false);
  const dateStart = moment(date).toDate();
  const handleChange = (someToChange: unknown) => {};
  return (
    <>
      <SelectedDate
        theme={theme}
        className={`${isActiveDatePicker ? "active" : ""}`}
        component={"span"}
      >
        <DeededDatePicker
          dateStart={dateStart}
          onChangeEvent={handleChange}
          name="dateStart"
          setDate={setDate as unknown as (value: () => Date) => void}
          setActiveDatePicker={setIsActiveDatePicker}
          hideInput={true}
        />
      </SelectedDate>
    </>
  );
};
const BlockedOffListBox = styled(Box)({
  marginTop: "31px",
  paddingLeft: "12px",
});
const BlockedOffTitle = styled(Typography)({
  fontWeight: "600",
  fontSize: "18px",
  color: constants.colors.sortingColor,
});
const BlockedOffListComponentBox = styled(Box)({
  marginTop: "24px",
});
const ClosingsListBox = styled(Box)({
  paddingLeft: "12px",
  width: "100%",
});
const ContentStaffBox = styled(Box)({
  marginTop: "31px",
  width: "100%",
  maxHeight: "600px",
  overflowY: "scroll",
  ...scrollbarStyles,
});
const Title = styled(Typography)({
  fontFamily: "'Montserrat'",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "18px",
  height: "18px",
  color: constants.colors.sortingColor,
});
const TitleFirstBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  height: "18px",
});
const SelectedDate = styled(Typography)(({theme}) => ({
  fontStyle: "normal",
  fontWeight: "bold",
  fontSize: "3.2rem",
  lineHeight: "3.9rem",
  margin: "0 0 24px",
  paddingTop: "25px",
  textTransform: "capitalize",
  color: constants.colors.intakeTitle,
  fontFamily: "Montserrat",
  position: "relative",
  transition: constants.transitions,
  svg: {
    stroke: constants.colors.red,
  },
  "&:hover, &.active": {
    color: constants.colors.red,
    transition: constants.transitions,
  },
  "& .MuiFormControl-root .MuiOutlinedInput-root .MuiInputAdornment-root .MuiButtonBase-root":
    {
      display: "block",
      marginTop: "10px",
      svg: {
        display: "block",
        path: {
          stroke: constants.colors.red,
        },
      },
    },
  [theme.breakpoints.down(smallDesktopCalendar)]: {
    fontSize: "2.8rem",
  },
  [theme.breakpoints.down(miniDesktopCalendar)]: {
    fontSize: "2rem",
  },
  [theme.breakpoints.down(smallPhoneCalendar)]: {
    fontSize: "1.5rem",
  },
}));
const TitleBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  height: "18px",
  paddingRight: "24px",
});
const BigCalendarClosingsDayBox = styled(Box)({
  backgroundColor: constants.colors.white,
  fontSize: "16px",
  lineHeight: "20px",
  padding: "32px 2px 32px 20px",
});

export default BigCalendarClosingsDayV2;
