import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface FileTasksFiled {
  id: number;
  deal_id: number;
  stage_number: number;
  name: string;
  input_type: InputType;
  value: string;
  type: Type;
  created_at: Date;
  updated_at: Date;
}

export enum InputType {
  Checkbox = "checkbox",
  Number = "number",
}

export enum Type {
  Purchase = "Purchase",
}

export interface FileTasksStageType {
  "1": FileTasksFiled[];
  "2": FileTasksFiled[];
  "3": FileTasksFiled[];
  "4": FileTasksFiled[];
  "5": FileTasksFiled[];
}
export interface FileTasksState {
  fileTasks: FileTasksStageType | [];
}

const initialState: FileTasksState = {
  fileTasks: [],
};

export const fileTasksSlice = createSlice({
  name: "fileTasks",
  initialState,
  reducers: {
    setFileTaskValue: (
      state,
      action: PayloadAction<{
        fieldIndex: number;
        stageNumber: number;
        value: boolean | string | number;
      }>,
    ) => {
      console.log(state);
      //@ts-ignore
      state.fileTasks[`${action.payload.stageNumber}`][
        action.payload.fieldIndex
      ].value = action.payload.value;
    },
    setFileTasks: (state, action: PayloadAction<FileTasksStageType | []>) => {
      state.fileTasks = action.payload;
    },
  },
});

export const {setFileTasks, setFileTaskValue} = fileTasksSlice.actions;

export default fileTasksSlice.reducer;
