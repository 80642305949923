import React, {
    useState,
    useEffect,
    ChangeEvent,
    SetStateAction,
    FC,
} from "react";
import { useDispatch } from "react-redux";
import NumberFormat from "react-number-format";
import moment from "moment";
import { Box } from "@mui/material";
import { ReusableSelect, provincesList } from "utils/reusableSelect";
import useTypedSelector from "utils/hooks/useTypedSelector";
import { PostalCodeValidator } from "../PostalCodeValidator/PostalCodeValidator";
import {
    getListDealLendersTC,
    setIsSaveToDBLenderAC,
    setSelectedLenderAC,
} from "redux/reducers/dealsReducer";
import { SelectedLenderType } from "redux/reducers/types/dealsReducerTypes";
import { Deal } from "types/deal";
import { User } from "types/user";
import Edit from "components/Svg/Edit";
import DeededDatePicker from "v2/components/DeededDatePicker";
import UncheckedDealLenderSvg from "../../Svg/UncheckedDealLenderSvg";
import CheckedDealLenderSvg from "../../Svg/CheckedDealLenderSvg";

type errorsType = {} & SetStateAction<{lenderName?: string | undefined}> & {
    lenderName?: string;
    address?: string;
    unit?: string;
    city?: string;
    code?: string;
    phone?: string;
    fax?: string;
    email?: string;
  };

interface DealLenderCardProps {
  deal: Deal;
  handleLenderData: (payload: unknown) => unknown;
  user: Partial<User>;
}

const POSTAL_CODE_REGEXP = /[a-zA-Z]\d[a-zA-z]\s\d[a-zA-Z]\d/gi;

/** Requires rewriting logic to formik to avoid convoluted form validation logic */
const DealLenderCard: FC<DealLenderCardProps> = ({
  deal,
  handleLenderData,
  user
}) => {
  const [unvalidatedPostCode, setUnvalidatedPostCode] = useState("");
  const [lenderName, setLenderName] = useState("");
  const [lenderAddress, setLenderAddress] = useState({
    address: "",
    unit: "",
    city: "",
    state: "",
    code: "",
    phone: "",
    fax: "",
    email: "",
  });
  const [lenderInformation, setLenderInformation] = useState({
    mortgageTerm: "",
    loanAmount: "",
    maturityDate: ""
  });
  const [nameFieldActive, setNameFieldActive] = useState(false);
  const [edit, setEdit] = useState(false);
  const [errors, setErrors] = useState<errorsType>({});
  const dispatch = useDispatch();
  const lendersList = useTypedSelector(
    (state) => state.DealsReducer.lendersList,
  );
  const isSaveToDBLender = useTypedSelector(
    (state) => state.DealsReducer.isSaveToDBLender,
  );
  const selectedLender = useTypedSelector(
    (state) => state.DealsReducer.selectedLender,
  );
  const isAdmin = user?.role === "System Admin" || user.role === "Admin";
  useEffect(() => {
    dispatch(getListDealLendersTC(lenderName));
  }, [dispatch, lenderName]);

  const handleLenderInputs = (
    e: ChangeEvent<HTMLInputElement> & {value: string},
    key: string,
  ) => {
    setErrors({});
    dispatch(setSelectedLenderAC(null));
    if (e.target === undefined) {
      const {value} = e;
      setLenderAddress((prevState) => ({
        ...prevState,
        state: value,
      }));
      return;
    } else {
      const {name, value} = e.target;
      if (key === "lender_name") {
        setLenderName(value);

        return;
      }
      if (key === "lender_address") {
        setLenderAddress((prevState) => ({
          ...prevState,
          [name]: value,
        }));
        return;
      }      
      if (key === "lender_information") {
        setLenderInformation((prevState) => ({
          ...prevState,
          [name]: value,
        }));
        return;
      }
    }
  };
  useEffect(() => {
    dispatch(getListDealLendersTC(""));
  }, [dispatch]);

  const handleLenderSave = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const isValidEmail =
      lenderAddress.email?.split("@")?.[1]?.split(".")?.[1]?.length > 1;
    for (let key in lenderAddress) {
      if (key !== "unit" && key !== "fax") {
        setErrors((prev: React.SetStateAction<errorsType>) => ({
          ...prev,
          [key]:
            !(lenderAddress[key as keyof typeof lenderAddress].length === 0)
              ? undefined
              : "Unknown error",
          lenderName:
            !(lenderName.length === 0) ? undefined : "Unknown error",
        }));
        if (key === "email" && !isValidEmail) {
          setErrors((prev: React.SetStateAction<errorsType>) => ({
            ...prev,
            [key]: "Email should be valid",
          }));
        }
      }
    }

    const {address, unit, city, state, code, email, phone, fax} = lenderAddress;

    const preventSave =
      address.length === 0 ||
      email.length === 0 ||
      !isValidEmail ||
      phone.length === 0 ||
      city.length === 0 ||
      !unvalidatedPostCode.match(POSTAL_CODE_REGEXP) ||
      lenderName.length === 0;
    if (preventSave) {
      return;
    }
    let payload;
    const lenderInfo = {
      lender_mortgage_term: lenderInformation.mortgageTerm,
      lender_loan_amount: lenderInformation.loanAmount,
      lender_maturity_date: lenderInformation.maturityDate,
    };
    if (selectedLender === null) {
      payload = {
        lender: {
          name: lenderName,
          address: {
            address,
            unit,
            city,
            state,
            code,
            email,
            phone,
            fax,
          },
          add_new: isSaveToDBLender,
          ...lenderInfo
        },
      };
    } else {
      payload = {
        lender: {
          name: lenderName,
          address: {
            address,
            unit,
            city,
            state,
            code,
            email,
            phone,
            fax,
          },
          add_new: isSaveToDBLender,
          static_lender_id: selectedLender.id,
          lender_address_id: selectedLender.address.id,
          ...lenderInfo
        },
      };
    }
    handleLenderData(payload);
    setEdit(false);
    dispatch(setIsSaveToDBLenderAC(false));
  };
  useEffect(() => {
    if (deal.lender_address && deal.lender_name) {
      const {
        lender_address: {address, unit, city, state, code, email, phone, fax},
        lender_mortgage_term, lender_loan_amount, lender_maturity_date
      } = deal;
      const {lender_name} = deal;
      setLenderName(lender_name as SetStateAction<string>);
      setLenderAddress({
        address,
        unit,
        city,
        state,
        code,
        email: email !== null ? email : "",
        phone: phone !== null ? phone : "",
        fax: fax !== null ? fax : "",
      });
      setUnvalidatedPostCode(code);
      setLenderInformation({
        mortgageTerm: lender_mortgage_term as string,
        loanAmount: (lender_loan_amount || "").toString(),
        maturityDate: (lender_maturity_date || "").toString()
      });
    }
  }, [deal]);

  const handleSelectOldLender = (el: SelectedLenderType) => {
    dispatch(setSelectedLenderAC(el));
    setLenderAddress({
      address: el.address.address,
      unit: el.address.unit !== null ? el.address.unit : "",
      city: el.address.city,
      state: el.address.state,
      code: el.address.code,
      email: el.address.email !== null ? el.address.email : "",
      phone: el.address.phone !== null ? el.address.phone : "",
      fax: el.address.fax !== null ? el.address.fax : "",
    });
    setLenderName(el.lender_name);
  };

  return (
    <div className="dealdetails__lender">
      <h2>Lender Information&nbsp;
        {!edit && <span className={"edit-btn"} onClick={() => setEdit(!edit)}><Edit /></span>}
      </h2>
      <div className="lender__wrapper">
        <label className={"lender-name"} htmlFor="lender-name">
          <span>Lender Name</span>
          <input
            onFocus={() => {
              setNameFieldActive(true);
              dispatch(getListDealLendersTC(lenderName));
            }}
            onBlur={() => {
              setNameFieldActive(false);
            }}
            className={errors?.lenderName && "input-error"}
            autoComplete="new-password"
            disabled={!edit}
            value={lenderName}
            type="text"
            name="name"
            onChange={(e: ChangeEvent<HTMLInputElement> & {value: string}) =>
              handleLenderInputs(e, "lender_name")
            }
          />

          {errors?.lenderName && (
            <span className="input-error-text">Field is required</span>
          )}
          {nameFieldActive && lendersList.length > 0 && (
            <div className={"lenders-hint"}>
              {lendersList.map((el: SelectedLenderType, idx) => {
                return (
                  <p
                    key={idx}
                    onMouseDown={() => {
                      handleSelectOldLender(el);
                    }}
                    className={"lenders-hint-item"}
                  >
                    {el.label}
                  </p>
                );
              })}
            </div>
          )}
        </label>
      </div>
      <div className="lender__wrapper">

        <div className="lender__address">
          <label htmlFor="address">
            <span>Lender Address</span>
            <input
              className={errors?.address && "input-error"}
              autoComplete="new-password"
              value={lenderAddress.address || ""}
              type="text"
              name="address"
              disabled={!edit}
              onChange={(e: ChangeEvent<HTMLInputElement> & {value: string}) =>
                handleLenderInputs(e, "lender_address")
              }
            />
            {errors?.address && (
              <span className="input-error-text">Field is required</span>
            )}
          </label>
          <div className="lender__inputs">
            <label htmlFor="city">
              <span>Unit</span>
              <input
                className={errors?.unit && "input-error"}
                autoComplete="new-password"
                value={lenderAddress.unit || ""}
                type="text"
                name="unit"
                disabled={!edit}
                onChange={(
                  e: ChangeEvent<HTMLInputElement> & {value: string},
                ) => handleLenderInputs(e, "lender_address")}
              />
              {errors?.unit && (
                <span className="input-error-text">Field is required</span>
              )}
            </label>
            <label htmlFor="city">
              <span>City</span>
              <input
                className={errors?.city && "input-error"}
                autoComplete="new-password"
                value={lenderAddress.city || ""}
                type="text"
                name="city"
                disabled={!edit}
                onChange={(
                  e: ChangeEvent<HTMLInputElement> & {value: string},
                ) => handleLenderInputs(e, "lender_address")}
              />
              {errors?.city && (
                <span className="input-error-text">Field is required</span>
              )}
            </label>

            <label htmlFor="code">
              <span>Province</span>
              <div style={{height: 8}} />
              <ReusableSelect
                defaultStyles={false}
                options={provincesList}
                disabled={!edit}
                className={!edit ? 'disabled' : ''}
                defaultValue={{
                  label: lenderAddress.state,
                  value: lenderAddress.state,
                }}
                onChangeHandler={(
                  e: ChangeEvent<HTMLInputElement> & {value: string},
                ) => handleLenderInputs(e, "lender_address")}
              />
            </label>
            <label htmlFor="city">
              <span>Phone number</span>
              <NumberFormat
                className={errors?.phone && "input-error"}
                autoComplete="new-password"
                value={lenderAddress.phone || ""}
                format={"###-###-####"}
                disabled={!edit}
                name="phone"
                onChange={(
                  e: ChangeEvent<HTMLInputElement> & {value: string},
                ) => handleLenderInputs(e, "lender_address")}
              />
              {errors?.phone && (
                <span className="input-error-text">Field is required</span>
              )}
            </label>
            <label htmlFor="city">
              <span>Fax number</span>
              <NumberFormat
                className={errors?.fax && "input-error"}
                format={"###-###-####"}
                autoComplete="new-password"
                disabled={!edit}
                value={lenderAddress.fax || ""}
                type="text"
                name="fax"
                onChange={(
                  e: ChangeEvent<HTMLInputElement> & {value: string},
                ) => handleLenderInputs(e, "lender_address")}
              />
              {errors?.city && (
                <span className="input-error-text">Field is required</span>
              )}
            </label>

            <label htmlFor="city">
              <span>Email</span>
              <input
                type={"email"}
                className={errors?.email && "input-error"}
                autoComplete="new-password"
                disabled={!edit}
                value={lenderAddress.email || ""}
                name="email"
                onChange={(
                  e: ChangeEvent<HTMLInputElement> & {value: string},
                ) => handleLenderInputs(e, "lender_address")}
              />
              {errors?.email && (
                <span className="input-error-text">
                  Email is required <br />
                  and should be valid
                </span>
              )}
            </label>
            <label htmlFor="city">
              <span>Postal Code</span>
              <PostalCodeValidator
                inputError={errors?.code}
                defaultValue={lenderAddress.code}
                onChange={(e) => {
                  setUnvalidatedPostCode(e as string);
                }}
                disabled={!edit}
                handlePostalCode={(code: string) => {
                  setErrors({});
                  setLenderAddress((prevState) => ({
                    ...prevState,
                    code: code,
                  }));
                  setUnvalidatedPostCode(code);
                }}
                name={undefined}
              />
            </label>
            {edit && <label
              className={"lender__save__to__DB__label"}
              htmlFor={"save__to__DB"}
            >
              <p className={"lender__save__to__DB__title"}>Save to Data Base</p>
              <div className={"lender_checkbox_icons"}>
                {isSaveToDBLender && (
                  <div
                    onClick={() => {
                      dispatch(setIsSaveToDBLenderAC(false));
                    }}
                  >
                    <CheckedDealLenderSvg />
                  </div>
                )}
                {!isSaveToDBLender && (
                  <div
                    onClick={() => {
                      dispatch(setIsSaveToDBLenderAC(true));
                    }}
                  >
                    <UncheckedDealLenderSvg />
                  </div>
                )}
              </div>
            </label>}
          </div>
        </div>
        {isAdmin && <div className="lender__info">
          <label htmlFor="mortgageTerm" >
            <span>Mortgage Term</span>
            <input
              type={"mortgageTerm"}
              autoComplete="new-password"
              value={lenderInformation.mortgageTerm || ""}
              name="mortgageTerm"
              disabled={!edit}
              onChange={(
                e: ChangeEvent<HTMLInputElement> & { value: string },
              ) => handleLenderInputs(e, "lender_information")}
            />
          </label>
          <label htmlFor="loanAmount" style={{marginBottom: "1.5rem"}}>
            <span>Loan Amount</span>
            <NumberFormat
              thousandSeparator=","
              autoComplete="new-password"
              disabled={!edit}
              value={lenderInformation.loanAmount || ""}
              name="loanAmount"
              onValueChange={({ value }) => handleLenderInputs({
                target: { value, name: "loanAmount" }
              } as
                ChangeEvent<HTMLInputElement> & { value: string; }, "lender_information")}
            />
          </label>
          <label htmlFor="maturityDate">
            <span>Maturity Date</span>
            <Box mb="1rem" />
            <DeededDatePicker
              fullWidth
              minDate={new Date()}
              name="maturityDate"
              disabled={!edit}
              onChange={(date) => handleLenderInputs({
                target: {value: moment(date).format("YYYY-MM-DD"), name: "maturityDate"}
              } as ChangeEvent<HTMLInputElement> & { value: string; }, "lender_information")}
              dateStart={lenderInformation.maturityDate ? new Date(lenderInformation.maturityDate) : null}
            />
          </label>
        </div>}
        {edit && <button
          type={"button"}
          className="ledner__save-btn"
          onClick={(e) => {
            handleLenderSave(e);
          }}
        >
          Save
        </button>}
      </div>
    </div>
  );
};

export default DealLenderCard;
