import React from "react";

const ApprovedStatus = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 0C5.36705 0 0 5.36761 0 12C0 18.633 5.36761 24 12 24C18.633 24 24 18.6324 24 12C24 5.36705 18.6324 0 12 0ZM12 22.4C6.26545 22.4 1.59998 17.7346 1.59998 12C1.59998 6.26541 6.26545 1.59998 12 1.59998C17.7345 1.59998 22.4 6.26545 22.4 12C22.4 17.7345 17.7345 22.4 12 22.4Z"
        fill="#02CD8F"
      />
      <path
        d="M18.3523 7.59052C18.0398 7.2781 17.5333 7.2781 17.2209 7.59052L10.0988 14.7126L6.77914 11.3929C6.46672 11.0805 5.96014 11.0805 5.64777 11.3929C5.33535 11.7053 5.33535 12.2119 5.64777 12.5243L9.5331 16.4096C9.84547 16.722 10.3522 16.7219 10.6645 16.4096L18.3523 8.72184C18.6647 8.40942 18.6647 7.90294 18.3523 7.59052Z"
        fill="#02CD8F"
      />
    </svg>
  );
};

export default ApprovedStatus;
