import { useMemo, useState } from "react";
import { Stack, styled } from "@mui/material";
import BasicButton from "components/Common/Button/BasicButton";
import { CheckCircle, ErrorIcon } from "components/Common/Icons/Iconography";
import Pill from "components/Common/StatusPills/Pill";
import { Body, Small } from "components/Common/Typography";
import constants from "styles/constants";
import { User } from "types/user";
import { ClientIntake, ClientIntakeStates, IntakeRequest, OldStates } from "types/intakeForm";
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import BasicTooltip from "components/Common/Tooltip/Tooltip";
import {isClient} from "utils/permissionRoles";

interface HeaderIntakeProps {
    intake: IntakeRequest | ClientIntake,
    handleIntakeReview: (reviewed: boolean) => Promise<any>,
    user: User
}

const HeaderIntake = ({ intake, handleIntakeReview, user }: HeaderIntakeProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const reviewedAt = useMemo(() => {
        if (!user.timezone || !intake.reviewed_at) return;
        const date = new Date(intake.reviewed_at as string);
        return format(utcToZonedTime(date, user.timezone), "yyyy/MM/dd 'at' h:mma");
    }, [intake.reviewed_at, user]);

    const handleSubmitReview = (reviewed: boolean) => {
        setIsLoading(true);
        handleIntakeReview(reviewed).finally(() => {
            setIsLoading(false);
        })
    };

    const showReviewBox = () => {
        return intake.state !== ClientIntakeStates.Created;
    };

    return <HeaderStack>
        {!isClient(user?.role) && (
            <LeftStack>
                <Stack direction={'column'} gap={'1rem'} >
                    <Body>Intake Progress:</Body>
                    <Pill {...getPillCustomization(intake)} />
                </Stack>
                {intake.user_ip && <Body>IP: {intake.user_ip}</Body>}
            </LeftStack>
        )}

        {!isClient(user?.role) && showReviewBox() &&
            (intake.reviewed_at ?
                <Stack direction={'row'} gap={'2rem'} alignItems={'center'}>
                    <CheckCircle color={constants.colors.green600} />
                    <Stack>
                        <Small>
                            Reviewed by {intake.reviewed_by?.full_name}
                        </Small>
                        <Small>
                            {reviewedAt}
                        </Small>
                    </Stack>
                    <BasicButton
                        typeOf="dismissive"
                        disabled={isLoading}
                        label={{ text: "Clear Review", inputId: "cleanIntakeId" }}
                        onClick={() => { handleSubmitReview(false) }}
                    />
                </Stack>
                :
                <BasicTooltip
                    disableHoverListener={intake.state !== OldStates.inQNA && intake.state !== ClientIntakeStates.InProgress}
                    message="Client has not yet completed the intake"
                    placement="left" type="info" key={intake.state}
                >
                    <div>
                        <BasicButton
                            typeOf="secondary"
                            disabled={isLoading || intake.state === OldStates.inQNA || intake.state === ClientIntakeStates.InProgress}
                            label={{ text: "Mark as Reviewed", inputId: "reviewedIntakeId" }}
                            onClick={() => { handleSubmitReview(true) }}
                        />
                    </div>
                </BasicTooltip>
            )}
    </HeaderStack>;
}

const HeaderStack = styled(Stack)(({ theme }) => ({
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${constants.colors.gray300}`,
    padding: '0 0 2rem',
    margin: '0 2rem',
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        padding: '2rem',
        gap: '1rem',
    },
}));

const LeftStack = styled(Stack)(({ theme }) => ({
    flexDirection: 'row',
    gap: '4rem',
    [theme.breakpoints.down('md')]: {
        justifyContent: 'space-between',
        alignItems: 'center',
    },
}));

const getPillCustomization = (intake: IntakeRequest | ClientIntake) => {
    let isComplete, label;
    isComplete = intake.state === ClientIntakeStates.Completed;
    label = intake.state.replace('_', ' ');

    if (isComplete) {
        return {
            style: {
                backgroundColor: constants.colors.green500,
                color: constants.colors.white,
                width: "fit-content"
            },
            label: label.charAt(0).toUpperCase() + label.slice(1),
            icon: <CheckCircle color={constants.colors.white} />
        };
    }
    return {
        style: {
            backgroundColor: constants.colors.orange500,
            color: constants.colors.white,
            width: "fit-content"
        },
        label: label.charAt(0).toUpperCase() + label.slice(1),
        icon: <ErrorIcon color={constants.colors.white} />
    }
};



export default HeaderIntake;
