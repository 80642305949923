import React, {useState, useEffect} from "react";
import {styled, useTheme} from "@mui/material";
import DealStagesServiceTypeNew from "./DealStagesServiceTypeNew";
import DealStagesTextArea from "./DealStagesTextArea";
import {Box, MenuItem, SelectChangeEvent, Theme} from "@mui/material";
import {useDispatch} from "react-redux";
import {addSuccessMessageAC} from "redux/reducers/ErrorReducer";
import {
  getDealStageListTC,
  saveDealStageTC,
  getDealStageActiveIndex,
  DealStageInfo,
} from "../../../redux/reducers/dealsReducer";
import useTypedSelector from "../../../utils/hooks/useTypedSelector";
import constants from "styles/constants";
import DeededSelectV2Transparent from "../DeededSelect";
import DeededCircularLoader from "../DeededCircularLoader";
import DeededButton from "../DeededButton";
import useWindowWidth from "../../../utils/hooks/useWindowWidth";
import {horizontalLargePhoneDealStages} from "components/Dashboard/Config/constants/breakpoints";

interface StageProps {
  id?: number;
  deal_type?: string;
  name?: string;
  description?: string;
  number?: number;
}

const isServiceTypes = [
  {
    value: "Purchase",
    label: "Purchase",
    color: constants.dealTypeColor.Purchase,
  },
  {value: "Sale", label: "Sale", color: constants.dealTypeColor.Sale},
  {
    value: "Mortgage",
    label: "Mortgage",
    color: constants.dealTypeColor.Mortgage,
  },
  {value: "Lender", label: "Lender", color: constants.dealTypeColor.Lender},
];

const DealStages = () => {
  const [isCurrentType, setCurrentType] = useState("Purchase");
  const [isActiveStage, setActiveStage] = useState("");
  const [loading, setLoading] = useState(false);
  const [properties, setProperties] = useState<DealStageInfo>(() => ({}));
  const [activeStageIndex, setActiveStageIndex] = useState(0);
  const dispatch = useDispatch();
  const windowWidth = useWindowWidth();
  const isHorizontalLargePhone = windowWidth < horizontalLargePhoneDealStages;
  const deals = useTypedSelector(
    (state: any) => state.DealsReducer.dealStageInfo,
  );
  const activeDeal = useTypedSelector(
    (state: any) => state.DealsReducer.activeStage,
  );
  useEffect(() => {
    dispatch(getDealStageListTC());
    dispatch(getDealStageActiveIndex());
  }, [dispatch]);

  useEffect(() => {
    setProperties(deals);
    setActiveStage(activeDeal);
    setActiveStageIndex(0);
  }, [activeDeal, deals]);

  const currentChoosedPropertyByType = properties[isCurrentType];
  const activeStage = currentChoosedPropertyByType
    ? currentChoosedPropertyByType[activeStageIndex]
    : null;
  const handleTypeInputValue = (stageID: number, stageValue: string) => {
    const stageIndex = currentChoosedPropertyByType.findIndex(
      (el: {id: number}) => el.id === stageID,
    );
    currentChoosedPropertyByType[stageIndex].name = stageValue;
    setProperties((prev) => ({
      ...prev,
      [isCurrentType]: currentChoosedPropertyByType,
    }));
  };
  const handleDescriptionValue = (stageValue: string) => {
    currentChoosedPropertyByType[activeStageIndex].description = stageValue;
    setProperties((prev) => ({
      ...prev,
      [isActiveStage]: currentChoosedPropertyByType,
    }));
  };

  const handleSubmit = (e: {preventDefault: () => void}) => {
    e.preventDefault();
    setLoading(true);
    let {Purchase, Sale, Mortgage, Lender} = properties;
    let dataToUpdate = {
      Purchase,
      Sale,
      Mortgage,
      Lender,
    };
    dispatch(saveDealStageTC(dataToUpdate));
    setLoading(false);
    dispatch(addSuccessMessageAC("Successfully saved"));
  };

  const handleSetCurrentType = (stage: StageProps) => {
    const stageIndex = currentChoosedPropertyByType.findIndex(
      (el: {id: number}) => el.id === stage.id,
    );
    setActiveStageIndex(stageIndex);
  };

  let i = ["1"];

  const theme = useTheme();

  return (
    <>
      {activeStage ? (
        <form onSubmit={handleSubmit}>
          <DealStageWrapper>
            <Wrapper>
              <Box
                sx={(theme) => ({
                  display: "flex",
                  [theme.breakpoints.down("sm")]: {width: "100%"},
                })}
              >
                <DealStageOption>
                  <OptionWrapper>
                    <Box
                      sx={(theme: Theme) => ({
                        [theme.breakpoints.down("sm")]: {width: "100%"},
                      })}
                    >
                      <Title>Service Type:</Title>
                      <DeededSelectV2Transparent
                        onChange={(
                          e: SelectChangeEvent<unknown> | undefined,
                        ) => {
                          setCurrentType(e?.target.value as unknown as string);
                        }}
                        value={
                          isServiceTypes[0] &&
                          isServiceTypes.filter(
                            (el) => el.value === isCurrentType,
                          )[0].value
                        }
                        height="38px"
                        width={isHorizontalLargePhone ? "23rem" : "24rem"}
                        sx={(theme: Theme) => ({
                          backgroundColor: `${constants.colors.white} !important`,
                          [theme.breakpoints.down("sm")]: {
                            width: "100% !important",
                            height: "48px !important",
                          },
                        })}
                        sxMenu={{
                          "& .MuiPaper-root.MuiPaper-elevation": {
                            [theme.breakpoints.down("sm")]: {
                              width: "150px",
                              minWidth: "unset !important",
                            },
                          },
                        }}
                      >
                        {isServiceTypes.map((el) => (
                          <MenuItem key={el.value} value={el.value}>
                            {el.label}
                          </MenuItem>
                        ))}
                      </DeededSelectV2Transparent>
                    </Box>
                  </OptionWrapper>
                  <ServiceTypeWrapper>
                    {currentChoosedPropertyByType.sort((s1, s2) => s1.number - s2.number).map((stage: StageProps) => (
                      <div>
                        <Counter
                          style={{marginLeft: "5px", marginBottom: "4px"}}
                        >
                          {stage.number}
                        </Counter>
                        <DealStagesServiceTypeNew
                          key={stage.id!}
                          value={stage.name!}
                          customSize={true}
                          currentStage={activeStage.id}
                          stage={stage}
                          stageId={stage.id!}
                          handleSetCurrentType={handleSetCurrentType}
                          handleInputValue={handleTypeInputValue}
                        />
                      </div>
                    ))}
                  </ServiceTypeWrapper>
                </DealStageOption>
              </Box>
              <Box>
                {activeStage && (
                  <StagesWrapper>
                    <StagesListForm>
                      <Title
                        sx={{
                          [theme.breakpoints.down("sm")]: {
                            marginBottom: "10px",
                          },
                        }}
                      >
                        {activeStage.name}
                      </Title>
                      <DealStagesTextArea
                        value={activeStage.description}
                        handleInputValue={handleDescriptionValue}
                      />
                      <Box sx={{display: "flex", justifyContent: "flex-end"}}>
                        <DealButton
                          disabled={loading}
                          type="submit"
                          onClick={handleSubmit}
                        >
                          Save
                        </DealButton>
                      </Box>
                    </StagesListForm>
                  </StagesWrapper>
                )}
              </Box>
            </Wrapper>
          </DealStageWrapper>
        </form>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <DeededCircularLoader />
        </Box>
      )}
    </>
  );
};

const Wrapper = styled("div")(({theme}) => ({
  boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.08)",
  padding: "2rem",
  display: "flex",
  [theme.breakpoints.only("md")]: {
    flexWrap: "wrap",
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  [theme.breakpoints.only("xs")]: {
    flexWrap: "wrap",
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  [theme.breakpoints.only("sm")]: {
    flexWrap: "wrap",
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  [theme.breakpoints.down("sm")]: {
    boxShadow: "none",
    padding: "0",
  },
}));
const Title = styled("h2")(({theme}) => ({
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: "18px",
  lineHeight: "21px",
  marginBottom: "2rem",
  color: constants.colors.intakeTitle,
  [theme.breakpoints.down("sm")]: {
    fontSize: "16px",
    lineHeight: "20px",
    letterSpacing: "-0.02em",
    marginBottom: "16px",
  },
}));
const DealStageOption = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: "100%",
});

const StagesWrapper = styled("div")(({theme}) => ({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "space-between",
  backgroundColor: "#ffffff",
  [theme.breakpoints.down("sm")]: {
    backgroundColor: "transparent",
  },
}));
const StagesListForm = styled("div")(({theme}) => ({
  display: "flex",
  flex: 1,
  flexWrap: "wrap",
  flexDirection: "column",
  justifyContent: "space-between",
  backgroundColor: "#ffffff",
  [theme.breakpoints.down("sm")]: {
    backgroundColor: "transparent",
  },
}));
const Counter = styled("h4")({
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: "300",
  fontSize: "12px",
  lineHeight: "15px",
});
const DealStageWrapper = styled("div")(({theme}) => ({
  display: "flex",
  flexDirection: "column",
  backgroundColor: "#ffffff",
  [theme.breakpoints.down("sm")]: {
    backgroundColor: "transparent",
  },
}));
const OptionWrapper = styled("div")(({theme}) => ({
  display: "inline-flex",
  flexDirection: "row",
  [theme.breakpoints.only("md")]: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
  },
  [theme.breakpoints.only("sm")]: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
  },
}));
const ServiceTypeWrapper = styled("div")(({theme}) => ({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: "25px",
  [theme.breakpoints.only("md")]: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: "28px",
  },
  [theme.breakpoints.only("sm")]: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  [theme.breakpoints.only("xs")]: {
    flexDirection: "column",
    flexWrap: "wrap",
  },
}));
const DealButton = styled(DeededButton)(({theme}) => ({
  marginLeft: "auto",
  marginTop: "56px",
  width: "163px",
  [theme.breakpoints.down("sm")]: {
    marginTop: "24px",
  },
}));
export default DealStages;
