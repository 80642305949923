import {useEffect} from "react";

export const useEnablePopupScroll = (isOpened: boolean) => {
  useEffect(() => {
    if (isOpened) {
      document.body.style.overflow = "hidden";
      document.body.style.height = "100vh";
    } else {
      document.body.style.overflow = "inherit";
      document.body.style.height = "auto";
    }
    return () => {
      document.body.style.overflow = "inherit";
      document.body.style.height = "auto";
    };
  }, [isOpened]);
};
