import useTypedSelector from "utils/hooks/useTypedSelector";
import {Box, Typography} from "@mui/material";
import DeededSpace from "v2/components/DeededSpace";
import {styled} from "@mui/material";
import constants from "styles/constants";
import {Theme} from "@mui/material/styles";

const AssignedTeamList = () => {
  const dealFullInfo = useTypedSelector(
    (state) => state.CalendarReducer.dealFullInfo,
  );

  return (
    <>
      {dealFullInfo?.participants.map((participant) => {
        return (
          <>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                border: "1px solid #F2F2F2",
                boxSizing: "border-box",
                borderRadius: "5px",
                height: "78px",
                width: "100%",
                paddingTop: 0,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <NameRoleTypography
                  sx={{
                    marginLeft: "16px",
                  }}
                >
                  {participant.name_prefix} {participant.full_name}
                </NameRoleTypography>
                <NameRoleTypography
                  sx={{
                    marginRight: "16px",
                  }}
                >
                  {participant.role}
                </NameRoleTypography>
              </Box>
              <DeededSpace y={5} />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <EmailPhoneTypography
                  sx={{
                    marginLeft: "16px",
                  }}
                >
                  {participant.email}
                </EmailPhoneTypography>
                <EmailPhoneTypography
                  sx={{
                    marginRight: "16px",
                  }}
                >
                  {participant.phone}
                </EmailPhoneTypography>
              </Box>
            </Box>
            <DeededSpace y={8} />
          </>
        );
      })}
      <DeededSpace y={8} />
    </>
  );
};

const NameRoleTypography = styled(Typography)<{theme: Theme}>(({theme}) => ({
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "150%",
  display: "flex",
  alignItems: "center",
  color: constants.colors.title,
  [theme.breakpoints.down("sm")]: {
    fontSize: "10px",
  },
}));
const EmailPhoneTypography = styled(Typography)<{theme: Theme}>(({theme}) => ({
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: "normal",
  fontSize: "14px",
  lineHeight: "150%",
  display: "flex",
  alignItems: "center",
  color: constants.colors.deededGray,
  [theme.breakpoints.down("sm")]: {
    fontSize: "10px",
  },
}));
export default AssignedTeamList;
