import React, {useState} from "react";
import Box, { BoxProps } from "@mui/material/Box";
import { SxProps, Theme } from "@mui/material/styles";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {
  TimeView,
  LocalizationProvider,
  DesktopTimePicker,
  renderDigitalClockTimeView,
} from "@mui/x-date-pickers";
import {ThemeProvider} from "@mui/material/styles";
import {materialPickerTheme, PickerTextField} from "./DeededDatePicker";

interface DeededTimePickerProps {
  timeStart: Date | null;
  onChangeEvent?: (value: unknown) => void;
  onChange?: (value: Date | null) => void;
  name: string;
  error?: string;
  disabled?: boolean;
  dateFormat?: string;
  excludeTimes?: Date[];
  sx?: SxProps<Theme>;
  minTime?: Date;
  maxTime?: Date;
  timeIntervals?: number;
  fieldWidth?: number;
  fullWidth?: boolean;
  placeholder?: string;
}

const DeededTimePicker: React.FC<
  DeededTimePickerProps & Omit<BoxProps, "variant" | "onChange">
> = ({
  error,
  name,
  onChangeEvent,
  onChange,
  timeStart,
  disabled,
  dateFormat = "h:mm aa",
  excludeTimes,
  timeIntervals = 5,
  sx,
  minTime,
  maxTime,
  fieldWidth,
  fullWidth,
  placeholder = "hh:mm",
  ...props
}) => {
  const [isOpenPicker, setIsOpenPicker] = useState(false);

  const handleChangeTimePicker = (newValue: Date | null) => {
    onChangeEvent &&
      onChangeEvent({
        target: {
          name,
          value: newValue,
        },
      });

    if (onChange) {
      onChange(newValue);
    }
  };

  const handleShouldDisableTime = (value: Date, view: TimeView) => {
    if (excludeTimes) {
      return excludeTimes.includes(value);
    }
    return false;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ThemeProvider theme={materialPickerTheme}>
        <Box {...props}>
          <DesktopTimePicker
            data-selenium-test="DeededTimePicker"
            views={["hours"]}
            closeOnSelect
            minTime={minTime}
            maxTime={maxTime}
            minutesStep={timeIntervals}
            disabled={disabled}
            onChange={handleChangeTimePicker}
            value={timeStart}
            shouldDisableTime={handleShouldDisableTime}
            open={isOpenPicker}
            onOpen={() => setIsOpenPicker(true)}
            onClose={() => setIsOpenPicker(false)}
            format={dateFormat}
            viewRenderers={{
              hours: renderDigitalClockTimeView,
            }}
            slotProps={{
              textField: {
                name,
                fullWidth,
                fieldWidth,
                hasError: error,
                disabled,
                isOpenCalendar: isOpenPicker,
                placeholder,
                readOnly: true,
                onClick: () => setIsOpenPicker(true),
              } as never,
            }}
            slots={{
              textField: PickerTextField as never,
            }}
          />
        </Box>
      </ThemeProvider>
    </LocalizationProvider>
  );
};

export default DeededTimePicker;
