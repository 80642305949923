import {Box, BoxProps, SxProps, Theme, Typography} from "@mui/material";
import MiniOKSvg from "../../components/Svg/MiniOKSvg";
import MiniCloseSvg from "../../components/Svg/MiniCloseSvg";
import {styled} from "@mui/material/styles";
import constants from "../../styles/constants";

interface DeededHintProps {
  active: boolean;
  hint: string;
  sx?: SxProps<Theme>;
}
const DeededHint: React.FC<DeededHintProps & Omit<BoxProps, "variant">> = ({
  active,
  hint,
  sx,
  ...props
}) => {
  return (
    <Box
      {...props}
      sx={{
        display: "flex",
        alignItems: "center",
        marginTop: "4px",
        ...sx,
      }}
      data-selenium-test="DeededHint"
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {active && <MiniOKSvg />}
        {!active && <MiniCloseSvg />}
      </Box>
      <HintTypography active={active}>{hint}</HintTypography>
    </Box>
  );
};

const HintTypography = styled(Typography)<{
  active: boolean;
}>(({active}) => ({
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: "normal",
  fontSize: "12px",
  marginLeft: "7px",
  color: active ? "var(--green)" : constants.colors.deededGray,
}));
export default DeededHint;
