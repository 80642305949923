import {User, UserParticipant, UserRoleType} from "types/user";
import useUser from "utils/hooks/useUser";
import {CrossIcon, PersonIcon, EnvelopeIcon, PhoneIcon} from "./Icons";
import EditSVG from "components/Svg/Edit";
import DealMemberFormModal from "./DealMemberFormModal";
import {useState} from "react";
import CustomToolTip from "./CustomToolTip/CustomToolTip";
import {Box} from "@mui/material";
import DeededHintCloud from "../../../v2/components/DeededHintCloud";

type DealParticipantCardProps = {
  participant: UserParticipant & {isReferringProfessional?: boolean} & {
    middle_name?: string;
  } & {deleted_at: string | null};
  handleUnassignTeamMember: (member: unknown) => void;
};
const DealParticipantCard: React.FC<DealParticipantCardProps> = ({
  participant,
  handleUnassignTeamMember,
}) => {
  const {
    first_name: firstName,
    last_name: lastName,
    middle_name: middleName,
    role,
    email,
    phone,
    isReferringProfessional,
    name_prefix: namePrefix,
    deleted_at,
    preferred,
  } = participant;
  const [isOpenToolTipRole, setIsOpenToolTipRole] = useState(false);
  const [isOpenToolTipName, setIsOpenToolTipName] = useState(false);
  const [isOpenToolTipEmail, setIsOpenToolTipEmail] = useState(false);
  const [isOpenToolTipPhone, setIsOpenToolTipPhone] = useState(false);

  const currentUser = (useUser() as Partial<{user: Partial<User>}>)?.user;
  const checkrole =
    (String(currentUser?.role) === "Broker" ||
      "Client" ||
      "Lender" ||
      "Referring Professional") &&
    (participant?.role === "Admin" || participant?.role === "System Admin") &&
    currentUser?.role !== "Admin" &&
    currentUser?.role !== "System Admin"
      ? "Document Support Specialist"
      : role;
  const isEditable =
    !isReferringProfessional && role !== "Admin" && role !== "System Admin";
  const handleCopy = (field: any) => {
    return navigator.clipboard.writeText(field);
  };
  const rolesWithBlockedControls = [
    "Company Admin",
    "Broker",
    "Client",
    "Lender",
    "Referring Professional",
    "Access",
    "Realtor",
    "Mobile Signer",
  ];
  const isOurUserInBlockedControlsList = rolesWithBlockedControls.includes(
    currentUser?.role as string,
  );
  const isCorporation =
    namePrefix === "Corporation"
      ? firstName
      : middleName !== null
      ? firstName + " " + middleName + " " + lastName
      : firstName + " " + lastName;
  const [open, setOpen] = useState(false);

  return (
    <>
      {open && (
        <>
          <DealMemberFormModal
            open={open}
            onClose={setOpen}
            participant={participant}
          />
        </>
      )}
      <div className="role">
        <div className="dealdetails__contacts--header">
          <h2 data-testid="deal-details-member-role-header">
            {isReferringProfessional ? "Referring Professional" : role}
            {!!deleted_at && <p className={"deleted__at"}>Deleted</p>}
            {((!isOurUserInBlockedControlsList &&
              currentUser?.role !== "Document Support Specialist") ||
              (currentUser?.role === "Document Support Specialist" &&
                !isReferringProfessional)) && (
              <div
                data-testid="deal-details-unassign-member-button"
                onClick={() => {
                  handleUnassignTeamMember(participant);
                }}
                className="unassign__container"
                style={{fontSize: "0.5rem"}}
              >
                <CrossIcon />
              </div>
            )}
          </h2>

          {!isReferringProfessional && !isOurUserInBlockedControlsList && (
            <button
              className="edit"
              onClick={() => {
                setOpen((x) => !x);
              }}
            >
              <EditSVG />
            </button>
          )}
        </div>
        <div className="dealdetails__contacts--content">
          <CustomToolTip
            handleTooltipClose={() => setIsOpenToolTipRole(false)}
            open={isOpenToolTipRole}
          >
            <div
              className="field__to__copy"
              onClick={() => {
                setIsOpenToolTipRole(true);
                handleCopy(role);
              }}
            >
              <span>
                <PersonIcon />
              </span>
              <span className="editable" data-testid="deal-details-client-role-value"> {checkrole}</span>
            </div>
          </CustomToolTip>
          <CustomToolTip
            handleTooltipClose={() => setIsOpenToolTipName(false)}
            open={isOpenToolTipName}
          >
            <div
              onClick={() => {
                setIsOpenToolTipName(true);
                handleCopy(`${namePrefix ? namePrefix + " " : ""}
          ${isCorporation}
           `);
              }}
              className="name field__to__copy"
            >
              <span>
                <PersonIcon />
              </span>
              <span className="editable" data-testid="deal-details-client-full-name-value">
                {`${namePrefix ? namePrefix + " " : ""}
             ${isCorporation}
              `}
              </span>
            </div>
          </CustomToolTip>
          {email !== null && (
            <CustomToolTip
              handleTooltipClose={() => setIsOpenToolTipEmail(false)}
              open={isOpenToolTipEmail}
            >
              <div
                className="email field__to__copy"
                onClick={() => {
                  setIsOpenToolTipEmail(true);
                  handleCopy(email);
                }}
              >
                <span>
                  <a href={`mailto:${email}`}>
                    <EnvelopeIcon />
                  </a>
                </span>
                <span className="editable" data-testid="deal-details-client-email-value">{email}</span>
                {preferred === "email" && <PreferredContactComponent />}
              </div>
            </CustomToolTip>
          )}
          {phone !== null && (
            <CustomToolTip
              handleTooltipClose={() => setIsOpenToolTipPhone(false)}
              open={isOpenToolTipPhone}
            >
              <div
                className="phone field__to__copy"
                onClick={() => {
                  setIsOpenToolTipPhone(true);
                  handleCopy(phone);
                }}
              >
                <span>
                  <a href={`tel:+${phone}`}>
                    <PhoneIcon />
                  </a>
                </span>
                <span className="editable" data-testid="deal-details-client-phone-value">{phone}</span>
                {preferred === "phone" && <PreferredContactComponent />}
              </div>
            </CustomToolTip>
          )}
        </div>
      </div>
    </>
  );
};
const PreferredContactComponent = () => {
  const [isShowedPreferredContact, setIsShowedPreferredContact] =
    useState(false);
  return (
    <>
      <Box
        onMouseEnter={() => {
          setIsShowedPreferredContact(true);
        }}
        onMouseLeave={() => {
          setIsShowedPreferredContact(false);
        }}
        sx={{
          position: "relative",
          width: "20px",
          height: "20px",
        }}
      >
        <StarSvg />
        {isShowedPreferredContact && (
          <DeededHintCloud
            sx={{
              width: "150px",
            }}
            bottom={30}
            left={-65}
            marginRightRotatedSquare={70}
            copied={false}
            text={"Preferred contact"}
          />
        )}
      </Box>
    </>
  );
};
const StarSvg = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.275 10.9188L10.425 12.9188C10.8313 13.175 11.3312 12.7938 11.2125 12.325L10.3 8.73752C10.2753 8.63807 10.2792 8.53369 10.3113 8.43638C10.3434 8.33907 10.4023 8.2528 10.4812 8.18752L13.3062 5.83127C13.675 5.52502 13.4875 4.90627 13.0063 4.87502L9.31875 4.63752C9.21814 4.63166 9.12141 4.59663 9.04038 4.53669C8.95936 4.47675 8.89754 4.39451 8.8625 4.30002L7.4875 0.837515C7.4511 0.737455 7.3848 0.651018 7.29758 0.589938C7.21037 0.528858 7.10647 0.496094 7 0.496094C6.89353 0.496094 6.78963 0.528858 6.70242 0.589938C6.6152 0.651018 6.5489 0.737455 6.5125 0.837515L5.1375 4.30002C5.10246 4.39451 5.04064 4.47675 4.95962 4.53669C4.87859 4.59663 4.78186 4.63166 4.68125 4.63752L0.99375 4.87502C0.5125 4.90627 0.325 5.52502 0.69375 5.83127L3.51875 8.18752C3.59771 8.2528 3.65662 8.33907 3.68868 8.43638C3.72075 8.53369 3.72467 8.63807 3.7 8.73752L2.85625 12.0625C2.7125 12.625 3.3125 13.0813 3.79375 12.775L6.725 10.9188C6.8072 10.8665 6.90259 10.8387 7 10.8387C7.09741 10.8387 7.1928 10.8665 7.275 10.9188Z"
        fill="#F2C94C"
      />
    </svg>
  );
};
export default DealParticipantCard;
