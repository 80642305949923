import React, {ChangeEventHandler, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useFormik} from "formik";
import * as yup from "yup";
import {Box, Dialog, MenuItem, Typography} from "@mui/material";
import {styled, useTheme} from "@mui/material/styles";
import TemplateEditor from "components/Dashboard/TemplateEditor/TemplateEditor";
import PreviewEmailSvg from "components/Svg/PreviewEmailSvg";
import DeededInput from "v2/components/DeededInput";
import DeededSelectV2Transparent from "v2/components/DeededSelect";
import DeededLabel from "v2/components/DeededLabel";
import DeededButton from "v2/components/DeededButton";
import DeededMultipleClosableSelect from "v2/components/DeededMultipleClosableSelect";
import useTypedSelector from "utils/hooks/useTypedSelector";
import constants from "styles/constants";
import {CommunicationTemplate} from "redux/reducers/types/configNotificationsReducerType";
import {
  createCommunicationsTC,
  updateCommunicationsTC,
} from "redux/reducers/configReducer/configNotificationsReducer";
import {addErrorAC} from "redux/reducers/ErrorReducer";
import {
  getPreviewTemplateTC,
  sendEmailTemplateTC,
  setPreviewAC,
} from "redux/reducers/configReducer/emailTemplateReducer";
import {configRecipientList} from "../../constants/configRecipientList";
import {configCcAndBccList} from "../../constants/configCcAndBccList";
import {configProvinceList} from "../../constants/configProvinceList";

interface HtmlTemplateType {
  id: number;
  name_template: string;
  default_text: string;
}
interface ConfigNotificationsTemplateFormProps {
  template?: CommunicationTemplate;
  handleClose: () => void;
}
const schema = yup.object().shape({
  recipient: yup.string().required(),
  templateName: yup.string().required(),
  province: yup.array().of(yup.string()).min(1, "Province is required"),
  subject: yup.string().required(),
  content: yup.string().required(),
});
const ConfigNotificationsTemplateForm: React.FC<
  ConfigNotificationsTemplateFormProps
> = ({template, handleClose}) => {
  const dispatch = useDispatch();
  const templates = useTypedSelector(
    (state) => state.ConfigReducer.EmailTemplateReducer.templates,
  ) as {
    data: HtmlTemplateType[];
  };
  const preview = useTypedSelector(
    (state) => state.ConfigReducer.EmailTemplateReducer.preview,
  ) as {data?: string};
  const selectedStage = useTypedSelector(
    (state) => state.ConfigReducer.ConfigNotificationsReducer.selectedStage,
  );
  const [openHtmlTemplatePreview, setOpenHtmlTemplatePreview] = useState(false);
  const [openProvinceSelector, setOpenProvinceSelector] = useState(false);
  const theme = useTheme();

  const initialValues = {
    recipient: template?.recipient ?? "",
    bcc: template?.bcc ?? "",
    templateName:
      template?.template_name && template?.template_name?.length > 0
        ? template?.template_name
        : "Signing - Client V1",
    cc: template?.cc ?? "",
    province:
      template?.province && template?.province?.length > 0
        ? template?.province
        : ["ALL"],
    htmlTemplateId: template?.html_template_id ?? "",
    subject: template?.subject ?? "",
    content: template?.content ?? "",
  };

  const {handleSubmit, values, errors, handleChange} = useFormik({
    validationSchema: schema,
    initialValues,
    onSubmit: (values: typeof initialValues, {resetForm}) => {
      const {
        recipient,
        bcc,
        templateName,
        cc,
        province,
        htmlTemplateId,
        subject,
        content,
      } = values;
      const payload = {
        recipient,
        bcc,
        template_name: templateName,
        cc,
        province,
        html_template_id: htmlTemplateId,
        subject,
        content,
      };
      if (!selectedStage) {
        dispatch(addErrorAC("Please select stage"));
      } else if (template) {
        dispatch(
          updateCommunicationsTC(payload, template.id, selectedStage as number),
        );
      } else {
        dispatch(
          createCommunicationsTC({...payload, deal_stage_id: selectedStage}),
        );
        handleClose();
        resetForm();
      }
    },
    validateOnChange: false,
    validateOnBlur: false,
  });
  useEffect(() => {
    if (errors?.content) {
      dispatch(addErrorAC(errors?.content));
    }
  }, [dispatch, errors]);

  const handleSelectHtmlTemplate = (
    selectedTemplate: PointerEvent & {target: {value: number}},
  ) => {
    handleChange({
      target: {
        name: "htmlTemplateId",
        value: selectedTemplate.target?.value,
      },
    });
    const selectedTemplateText = templates.data.find(
      (templ) => templ.id === selectedTemplate.target?.value,
    )?.default_text;
    handleChange({
      target: {
        name: "content",
        value: selectedTemplateText,
      },
    });
  };
  const addFile = (event: {target: {files: File[]}}) => {
    dispatch(sendEmailTemplateTC(event.target.files[0]));
  };
  const handlePreview = () => {
    if (values.htmlTemplateId) {
      const text = values.content ? values.content : "";
      setOpenHtmlTemplatePreview(true);
      dispatch(getPreviewTemplateTC(text, +values.htmlTemplateId));
    } else {
      dispatch(addErrorAC("Please select HTML template"));
    }
  };
  const handleDialogClick = () => {
    setOpenHtmlTemplatePreview(false);
    dispatch(setPreviewAC(null));
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Box
          sx={(theme) => ({
            display: "flex",
            justifyContent: "space-between",
            marginTop: "31px",
            [theme.breakpoints.down("sm")]: {
              flexDirection: "column",
              marginTop: "0",
            },
          })}
        >
          <Box
            sx={(theme) => ({
              width: "30%",
              [theme.breakpoints.down("sm")]: {
                width: "100%",
                marginBottom: "16px",
              },
            })}
          >
            <DeededLabel
              title="Message Recipient:"
              sx={{
                [theme.breakpoints.down("sm")]: {
                  "& p": {
                    fontSize: "16px",
                    lineHeight: "20px",
                    letterSpacing: "-0.02em",
                    marginBottom: "12px",
                  },
                },
              }}
            />
            <MessageRecipientAndMessageBCCSelector
              width="100%"
              error={errors?.recipient}
              name="recipient"
              onChange={handleChange}
              value={values.recipient}
            >
              {configRecipientList
                .filter((recipient) => recipient !== "None")
                .filter((recipient) => {
                  return recipient !== values.bcc;
                })
                .filter((recipient) => {
                  return recipient !== "Client, Secondary Client";
                })
                .map((recipient, index) => {
                  return (
                    <MenuItem key={index} value={recipient}>
                      {recipient}
                    </MenuItem>
                  );
                })}
            </MessageRecipientAndMessageBCCSelector>
          </Box>
          <Box
            sx={(theme) => ({
              width: "30%",
              [theme.breakpoints.down("sm")]: {
                width: "100%",
                marginBottom: "16px",
              },
            })}
          >
            <DeededLabel
              title="Message BCC:"
              sx={{
                [theme.breakpoints.down("sm")]: {
                  "& p": {
                    fontSize: "16px",
                    lineHeight: "20px",
                    letterSpacing: "-0.02em",
                    marginBottom: "12px",
                  },
                },
              }}
            />
            <MessageRecipientAndMessageBCCSelector
              width="100%"
              name="bcc"
              onChange={handleChange}
              value={values.bcc}
            >
              {configCcAndBccList
                .filter((bcc) => {
                  return bcc !== values.recipient;
                })
                .map((bcc, index) => (
                  <MenuItem value={bcc} key={index}>
                    {bcc}
                  </MenuItem>
                ))}
            </MessageRecipientAndMessageBCCSelector>
          </Box>
          <Box
            sx={(theme) => ({
              width: "30%",
              [theme.breakpoints.down("sm")]: {
                width: "100%",
                marginBottom: "16px",
              },
            })}
          >
            <DeededLabel
              title="Template Name:"
              sx={{
                [theme.breakpoints.down("sm")]: {
                  "& p": {
                    fontSize: "16px",
                    lineHeight: "20px",
                    letterSpacing: "-0.02em",
                    marginBottom: "12px",
                  },
                },
              }}
            />
            <DeededInput
              sx={(theme) => ({
                width: "100%",
                [theme.breakpoints.down("sm")]: {
                  backgroundColor: constants.colors.white,
                },
                "&.MuiTypography-root": {
                  width: "200px !important",
                },
              })}
              error={
                errors?.templateName ? "template name ia a required" : undefined
              }
              onChange={handleChange}
              name="templateName"
              value={values.templateName}
            />
          </Box>
        </Box>
        <Box
          sx={{
            marginTop: "17px",
            [theme.breakpoints.down("sm")]: {
              flexDirection: "column",
              marginTop: "0",
            },
          }}
        >
          <DeededLabel
            title="CC:"
            sx={{
              [theme.breakpoints.down("sm")]: {
                "& p": {
                  fontSize: "16px",
                  lineHeight: "20px",
                  letterSpacing: "-0.02em",
                  marginBottom: "12px",
                },
              },
            }}
          />
          <MessageRecipientAndMessageBCCSelector
            width="100%"
            onChange={handleChange}
            name="cc"
            value={values.cc}
          >
            {configCcAndBccList.map((cc, index) => (
              <MenuItem value={cc} key={index}>
                {cc}
              </MenuItem>
            ))}
          </MessageRecipientAndMessageBCCSelector>
        </Box>
        <Box
          sx={{
            marginTop: "17px",
          }}
        >
          <DeededLabel
            title="Province:"
            sx={{
              [theme.breakpoints.down("sm")]: {
                "& p": {
                  fontSize: "16px",
                  lineHeight: "20px",
                  letterSpacing: "-0.02em",
                  marginBottom: "12px",
                },
              },
            }}
          />

          <DeededMultipleClosableSelect
            error={errors?.province as string | undefined}
            open={openProvinceSelector}
            onClose={() => {
              setOpenProvinceSelector(false);
            }}
            handleOpen={() => {
              setOpenProvinceSelector(true);
            }}
            handleChange={handleChange}
            menuList={configProvinceList}
            name="province"
            value={values.province}
            sx={{
              [theme.breakpoints.down("sm")]: {
                width: "100% !important",
                background: `${constants.colors.white} !important`,
              },
            }}
          />
        </Box>
        <Box
          sx={(theme) => ({
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
            marginTop: "17px",
            [theme.breakpoints.down("sm")]: {
              flexDirection: "column",
            },
          })}
        >
          <Box
            sx={(theme) => ({
              [theme.breakpoints.down(1700)]: {
                width: "70%",
              },
              [theme.breakpoints.down("sm")]: {
                width: "100%",
              },
              width: "80%",
            })}
          >
            <DeededLabel
              title="Select Ready HTML Template"
              sx={{
                [theme.breakpoints.down("sm")]: {
                  "& p": {
                    fontSize: "16px",
                    lineHeight: "20px",
                    letterSpacing: "-0.02em",
                    marginBottom: "12px",
                  },
                },
              }}
            />
            <MessageRecipientAndMessageBCCSelector
              name="htmlTemplateId"
              onChange={handleSelectHtmlTemplate as (e?: unknown) => void}
              width="100%"
              value={values.htmlTemplateId}
            >
              {templates?.data?.map((readyTemplate, index) => (
                <MenuItem key={index} value={readyTemplate.id}>
                  {readyTemplate.name_template}
                </MenuItem>
              ))}
            </MessageRecipientAndMessageBCCSelector>
          </Box>

          <NewTemplateBtn htmlFor="newTemplate">
            <Typography
              sx={() => ({
                fontSize: "40px",
                fontWeight: "300",
                marginRight: "9px",
              })}
            >
              +
            </Typography>
            <Typography className="NewTemplateBtnDescription">
              New template
            </Typography>
          </NewTemplateBtn>
          <input
            onChange={
              addFile as unknown as ChangeEventHandler<HTMLInputElement>
            }
            style={{display: "none"}}
            type="file"
            id="newTemplate"
            name="newTemplate"
            accept=".zip"
          />
        </Box>
        <Box
          sx={{
            marginTop: "17px",
            marginBottom: "17px",
          }}
        >
          <DeededLabel
            title="Subject:"
            sx={{
              [theme.breakpoints.down("sm")]: {
                "& p": {
                  fontSize: "16px",
                  lineHeight: "20px",
                  letterSpacing: "-0.02em",
                  marginBottom: "12px",
                },
              },
            }}
          />
          <SubjectInput
            error={errors?.subject}
            name="subject"
            onChange={handleChange}
            value={values.subject ?? ""}
          />
        </Box>
        <Box
          sx={{
            "& .ck-editor__main": {
              "& .ck-content": {
                "& p": {
                  fontSize: "14px",
                },
              },
            },
          }}
        >
          <TemplateEditor
            value={values.content}
            handleTextField={(text: string) => {
              handleChange({
                target: {
                  name: "content",
                  value: text,
                },
              });
            }}
            empty={undefined}
            title={undefined}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row-reverse",
            marginBottom: "12px",
          }}
        >
          <DeededButton
            sx={{
              width: "100px",
            }}
            type="submit"
          >
            Save
          </DeededButton>
          <CancelPreviewBtns
            onClick={handleClose}
            kind="secondary"
            type="button"
          >
            Cancel
          </CancelPreviewBtns>
          <CancelPreviewBtns
            sx={{
              width: "140px",
              [theme.breakpoints.down("sm")]: {
                "& .PreviewEmailSvg": {
                  display: "none !important",
                },
              },
            }}
            onClick={handlePreview}
            kind="secondary"
            type="button"
          >
            <PreviewEmailSvg />
            <Typography
              sx={{
                marginTop: "3px",
                fontSize: "14px",
                fontWeight: "600",
                marginLeft: "5px",
              }}
            >
              Preview
            </Typography>
          </CancelPreviewBtns>
        </Box>
      </Form>
      <Dialog
        disableScrollLock
        open={openHtmlTemplatePreview}
        onClick={handleDialogClick}
      >
        <Box>
          <iframe
            onClick={handleDialogClick}
            title=" "
            className="email_template"
            width={600}
            height={800}
            srcDoc={preview?.data}
          >
            your browser no support iframe
          </iframe>
        </Box>
      </Dialog>
    </>
  );
};

const NewTemplateBtn = styled("label")(({theme}) => ({
  textAlign: "center",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  width: "170px",
  height: "44px",
  borderRadius: "5px",
  textTransform: "capitalize",
  cursor: "pointer",
  fontFamily: "inherit",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: 14,
  border: `1px solid ${constants.colors.red}`,
  color: constants.colors.red,
  letterSpacing: "unset",
  background: constants.colors.white,
  transition: "background 250ms",
  marginRight: "0px",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    marginTop: "10px",
  },
  "& .NewTemplateBtnDescription": {
    fontFamily: "inherit",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 14,
  },
  "&:hover": {
    color: constants.colors.white,
    background: constants.colors.red,
    transition: "background 250ms",
  },
}));
const CancelPreviewBtns = styled(DeededButton)({
  "& .PreviewEmailSvg": {
    stroke: constants.colors.red,
    transition: "stroke 250ms",
  },
  "&:hover": {
    "& .PreviewEmailSvg": {
      stroke: constants.colors.white,
      transition: "stroke 250ms",
    },
  },
  width: "100px",
  marginRight: "20px",
});
const SubjectInput = styled(DeededInput)(({theme}) => ({
  width: "100%",
  [theme.breakpoints.down("sm")]: {
    backgroundColor: constants.colors.white,
  },
}));
const MessageRecipientAndMessageBCCSelector = styled(DeededSelectV2Transparent)(
  ({theme}) => ({
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
      background: `${constants.colors.white} !important`,
    },
  }),
);
const Form = styled("form")({});
export default ConfigNotificationsTemplateForm;
