import React, {SyntheticEvent, useContext} from "react";
import {
  FileTasksFiled,
  setFileTaskValue,
} from "redux/reducers/fileTasksSlice";
import {useDispatch} from "react-redux";
import {Box} from "@mui/material";
import DeededCheckbox from "v2/components/DeededCheckbox";
import {DealDetailsFileTasksContext} from "components/Dashboard/DealDetailsFileTasks/context/DealDetailsFileTasksContext";
import {useUpdateTaskValueMutation} from "redux/reducers/dealInfoApi";

const DealDetailsCheckboxStageField: React.FC<{
  fieldIndex: number;
  field: FileTasksFiled;
}> = ({field, fieldIndex}) => {
  const dispatch = useDispatch();
  const [updateTaskValueMutationCallback] = useUpdateTaskValueMutation();
  const {dealId} = useContext(DealDetailsFileTasksContext);
  const handleChecked = (e: {target: {checked: boolean}}) => {
    const booleanInNumber = Number(e.target.checked);
    dispatch(
      setFileTaskValue({
        fieldIndex,
        stageNumber: field.stage_number,
        value: booleanInNumber,
      }),
    );
    updateTaskValueMutationCallback({
      dealId,
      fileTaskId: field.id,
      value: booleanInNumber,
    });
  };
  return (
    <>
      <Box
        sx={{
          height: "52px",
          "& .MuiFormControlLabel-root ": {
            alignItems: "flex-start",
            "& .MuiTypography-root": {
              fontSize: "16px",
              fontWeight: "500",
              lineHeight: "20px",
            },
            "& .MuiCheckbox-root": {
              marginRight: "12px",
              minWidth: "20px",
              minHeight: "20px",
              padding: "0px",
            },
          },
        }}
      >
        <DeededCheckbox
          onChange={
            handleChecked as unknown as (
              event: SyntheticEvent<Element, Event>,
              checked: boolean,
            ) => void
          }
          checked={!!+field.value}
          label={field.name}
        />
      </Box>
    </>
  );
};
export default DealDetailsCheckboxStageField;
