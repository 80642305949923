import React, { FC } from "react";
import { Stack } from "@mui/material";
import { Deal } from "types/deal";
import DeededRadioButton from "v2/components/DeededRadioButton";
import BaseModal from "components/CommonDashboard/Modal";
import { availableDealStates } from "components/Dashboard/DealDetails/utils";
import { H3 } from "components/CommonDashboard/Typography";
import useDealInfoForm from "components/Dashboard/DealHeader/hooks/useDealInfoForm";
import TextField from "components/CommonDashboard/TextField";
import { SaveIcon } from "components/CommonDashboard/Icons";
import Colors from "components/CommonDashboard/Styling/Colors";
import { PostalCodeFormat } from "components/CommonDashboard/TextField/TextFieldFormatters";
import DateField from "components/CommonDashboard/DateField/DateField";
import AddressAutocompleteField from "components/CommonDashboard/AddressAutocompleteField";

interface EditDealInfoModalProps {
    deal: Deal;
    open: boolean;
    onClose: () => void;
    onSave: () => void;
}

const EditDealInfoModal: FC<EditDealInfoModalProps> = ({ open, onClose, onSave, deal }) => {
    const { handleSubmit, isSubmitting, values, controlFor, reset } = useDealInfoForm(deal, onSave);

    const handleClose = () => {
        reset({
            values: {
                address: deal.address.address ?? "",
                unit: (deal.address.unit ?? "") as string,
                city: deal.address.city ?? "",
                state: deal.address.state ?? "",
                code: deal.address.code ?? "",
                closing_date: deal.closing_date ?? "",
                deal_number: deal.deal_number ?? "",
            },
        });
        onClose();
    };

    return (
        <BaseModal
            open={open}
            onCloseClick={handleClose}
            title="Edit lead"
            buttonProps={[
                { disabled: isSubmitting, label: { text: "Cancel" }, typeOf: "outline", onClick: handleClose },
                {
                    disabled: isSubmitting,
                    loading: isSubmitting,
                    label: { text: "Save changes" },
                    typeOf: "primary",
                    onClick: () => handleSubmit(),
                    startIcon: <SaveIcon color={Colors.WHITE} />,
                },
            ]}
        >
            <Stack width="100%" gap="2.6rem">
                <AddressAutocompleteField {...controlFor("address")} label="Address" observation="(optional)" />
                <Stack gap="1rem">
                    <H3>Province</H3>
                    <Stack direction="row" gap="2rem">
                        {availableDealStates.map(({ id, label }) => (
                            <DeededRadioButton
                                {...controlFor("state")}
                                value={id}
                                key={id}
                                label={label}
                                checked={values.state === id}
                            />
                        ))}
                    </Stack>
                </Stack>
                <TextField {...controlFor("unit")} label="Unit" observation="(optional)" />
                <TextField {...controlFor("city")} label="City" observation="(optional)" />
                <TextField
                    {...controlFor("code")}
                    label="Postal Code"
                    observation="(optional)"
                    InputProps={{
                        inputComponent: PostalCodeFormat as any,
                    }}
                />
                <DateField {...controlFor("closing_date")} label="Closing date" />
                <TextField {...controlFor("deal_number")} label="Transaction number" />
            </Stack>
        </BaseModal>
    );
};

export default EditDealInfoModal;
