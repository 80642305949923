import { OverridedMixpanel } from "mixpanel-browser";
import { RootStore } from "./flux";
import { User } from "./user";

export type SourceOfFundsPayload = {
  savings: boolean;
  gift: boolean;
  sale_proceeds: boolean;
  mortgage_proceeds: boolean;
  other_value: boolean;
  other: string | null;
};

export type SourceOfFundsSubmitHandler = (payload: {
  data?: SourceOfFundsPayload;
  dispatch: (action: unknown) => void;
  role?: string;
  mixpanel?: OverridedMixpanel;
  mixpanelPayload?: {
    role: string;
    email: string;
    full_name: string;
    deal_type: string;
    deal_id: number;
    page: string;
    clicked_button: string;
  };
}) => void;
export type IdentityVerificationStepsPayload = {
  title: "IDENTITY VERIFICATION";
  subtitle: {
    title: string;
    path: string;
    page: string;
    data: string;
  }[];
}[];
export type SourceOfFundsSelector = (
  state: RootStore,
) => SourceOfFundsPayload | null;
export type IdentityVerificationStepsSelector = (
  state: RootStore,
) => IdentityVerificationStepsPayload | null;
export type BaseIntakeFormReducer = {
  sourceOfFunds: SourceOfFundsPayload | null;
  takingMortgage?: boolean;
};

export enum PropertyTypes {
  Detached = "Detached",
  SemiDetached = "Semi-Detached",
  ResidentialContainingMoreThan2Units = "Residential containing more than 2 units",
  FreeholdTownhouse = "Freehold Townhouse",
  CondoTownhouse = "Condominium Townhouse",
  Condominium = "Condominium",
  RecreationalCottage = "Recreational / Cottage",
  FarmRural = "Farm / Rural",
  VacantLand = "Vacant Land"
};

export type IntakeRequest = {
  content: string | null,
  deal_id: number,
  form_schema: JSON | string | null,
  id: number,
  state: State,
  token: string,
  reviewed_at: Date | string | null,
  reviewed_by: User | null,
  user_ip: string | null,
}

export enum OldStates {
  inQNA = 'in_qna',
  QNAComplete = 'qna_complete'
}

export type State = OnboardingStates;

export enum OnboardingStates {
  Created = 'created',
  Accessed = 'accessed',
  InRetainer = 'in_retainer',
  InIDV = 'in_idv',
  Completed = 'completed'
}

export enum ClientIntakeStates {
  Created = 'created',
  InProgress = 'in_progress',
  Completed = 'completed'
}

export enum IntakeVersion {
  Legacy = 1,
  CustomerPortal = 2,
  NewOnBoarding = 3
}

export enum IDVStates {
  Created = "created",
  Started = "started",
  Expired = "expired",
  Completed = "completed",
  Failed = "failed",
  MarkedForReview = "marked_for_review",
  Declined = "declined",
  Approved = "approved",
}

export enum OnboardingTaskStatus {
  NotStarted = "not_started",
  InProgress = "in_progress",
  Completed = "completed",
}


export type ClientIDV = {
  id: number,
  user_id: number,
  inquiry_id: string | null,
  state: IDVStates,
  can_be_resumed: boolean,
  status_label: string | null,
}

export type ClientOnboarding = {
  id: number,
  uuid: string,
  deal_id: number,
  user_id: number,
  state: State,
  user_ip: string | null,
  intakes: ClientIntake[],
  intake: ClientIntake,
  user_idv: ClientIDV | null,
  invite_sent_at: string | null,
  last_reminder_sent_at: string | null,
  status_label: string | null,
  old_token?: string,
  onboarding_tasks: OnboardingTask[],
}

export type OnboardingTask = {
  id: number,
  status: OnboardingTaskStatus,
  title: string,
  label: string,
  created_at: string,
  updated_at: string,
};

export type ClientIntake = {
  id: number,
  uuid: string,
  deal_id: number,
  user_id: number,
  user_ip: string | null,
  form_schema: Object | null,
  content: null | Object,
  state: ClientIntakeStates,
  reviewed_at: Date | string | null,
  reviewed_by: User | null,
}
