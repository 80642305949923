import Colors from "./Colors";
export const FONT_FAMILY = "Satoshi, sans-serif";
export const FONT_STYLE = "normal";

export const H1_TEXT = {
  fontFamily: FONT_FAMILY,
  fontSize: "5.2rem",
  fontStyle: FONT_STYLE,
  fontWeight: 700,
  lineHeight: "6.76rem",
  letterSpacing: "-0.156rem",
};

export const H1_TEXT_MOBILE = {
  fontFamily: FONT_FAMILY,
  fontWeight: 700,
  fontSize: "4rem",
  fontStyle: FONT_STYLE,
  lineHeight: "5.2rem",
  letterSpacing: "-0.12rem",
};

export const H2_TEXT = {
  fontFamily: FONT_FAMILY,
  fontSize: "3rem",
  fontStyle: FONT_STYLE,
  fontWeight: 700,
  lineHeight: "3.9rem",
};

export const H2_TEXT_MOBILE = {
  fontFamily: FONT_FAMILY,
  fontSize: "2.4rem",
  lineHeight: "31.2px",
};

export const H3_TEXT = {
  fontFamily: FONT_FAMILY,
  fontSize: "2rem",
  fontStyle: FONT_STYLE,
  fontWeight: 700,
  lineHeight: "2.6rem",
  letterSpacing: "-0.06rem",
};

export const H4_TEXT = {
  fontFamily: FONT_FAMILY,
  fontSize: "2rem",
  fontStyle: FONT_STYLE,
  fontWeight: 700,
  lineHeight: "3.2rem",
  letterSpacing: "-0.04rem",
};

export const SUBTITLE_SMALL = {
  fontFamily: FONT_FAMILY,
  fontSize: "1.6rem",
  fontStyle: FONT_STYLE,
  fontWeight: 700,
  lineHeight: "2.4rem",
  letterSpacing: "-0.016rem",
};

export const SUBTITLE_REGULAR = {
  fontFamily: FONT_FAMILY,
  fontSize: "1.8rem",
  fontStyle: FONT_STYLE,
  fontWeight: 700,
  lineHeight: "2.7rem",
};

export const OVERLINE_TEXT = {
  fontFamily: FONT_FAMILY,
  fontSize: "1.4rem",
  fontStyle: FONT_STYLE,
  fontWeight: 700,
  lineHeight: "2rem",
  letterSpacing: "-0.014rem",
};

export const BODY_BIG = {
  fontFamily: FONT_FAMILY,
  fontSize: "3rem",
  fontStyle: FONT_STYLE,
  fontWeight: 400,
  lineHeight: "3.6rem",
  letterSpacing: "-0.06rem",
};

export const BODY_REGULAR = {
  fontFamily: FONT_FAMILY,
  fontSize: "1.8rem",
  fontStyle: FONT_STYLE,
  fontWeight: 400,
  lineHeight: "2.6rem",
  letterSpacing: "-0.018rem",
};

export const BODY_SMALL = {
  fontFamily: FONT_FAMILY,
  fontSize: "1.6rem",
  fontStyle: FONT_STYLE,
  fontWeight: 400,
  lineHeight: "2.4rem",
  letterSpacing: "-0.016rem",
};

export const BODY_SMALLER = {
  fontFamily: FONT_FAMILY,
  fontSize: "1rem",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "2.4rem",
  letterSpacing: "-0.01rem",
};

export const BUTTON_SMALL_TEXT = {
    fontFamily: FONT_FAMILY,
    fontSize: "1.4rem",
    fontStyle: FONT_STYLE,
    fontWeight: 700,
    lineHeight: "2rem",
    letterSpacing: "-0.014rem",
};

export const BUTTON_LARGE_TEXT = {
  fontFamily: FONT_FAMILY,
  fontSize: "1.6rem",
  fontWeight: 700,
  lineHeight: "2.4rem",
  letterSpacing: "-0.016rem"
};

export const BUTTON_XLARGE_TEXT = {
    fontFamily: FONT_FAMILY,
    fontSize: "2rem",
    fontStyle: FONT_STYLE,
    fontWeight: 700,
    lineHeight: "3rem",
};

export const LINK = {
    textDecoration: 'underline',
    fontWeight: 700, 
    color: Colors.PRIMARY_600
};

export const CAPTION = {
    fontFamily: FONT_FAMILY,
    fontSize: "1rem",
    fontStyle: FONT_STYLE,
    fontWeight: 400,
    lineHeight: "1.5rem",
};

export const LINK_LARGE = {
    fontFamily: FONT_FAMILY,
    fontSize: "1.6rem",
    fontStyle: FONT_STYLE,
    fontWeight: 700,
    lineHeight: "2.4rem",
    textDecoration: 'underline'
};
