import { baseURL } from "api";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { checkTokenExpiration } from "utils";
import storage from "./storage";

const state = {
    requestInterceptorId: null as null | number,
    responseInterceptorId: null as null | number,
};

const reloadAxios = (history: ReturnType<typeof useHistory>) => {
    axios.defaults.baseURL = baseURL as string;

    if (state.requestInterceptorId !== null) {
        axios.interceptors.request.eject(state.requestInterceptorId);
    }

    if (state.responseInterceptorId !== null) {
        axios.interceptors.response.eject(state.responseInterceptorId);
    }

    state.requestInterceptorId = axios.interceptors.request.use(
        (config) => {
            config.headers.common["Authorization"] = `Bearer ${checkTokenExpiration()}`;
            return config;
        },
        (error) => {
            if (error.response.data.error === "Unauthenticated.") {
                history.push("/login");
            }

            return Promise.reject(error);
        },
    );

    // Add a response interceptor
    state.responseInterceptorId = axios.interceptors.response.use(
        (response) => {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            if (response.config.url !== "/refresh") {
                storage.lastCallTimestamp = new Date().valueOf().toString();
            }
            return response;
        },
        (error) => {
            if (error.response && error.response.data && error.response.data.error === "Unauthenticated.") {
                localStorage.clear();
                history.push("/login");
            }

            if (!error?.response?.data?.error) {
                error["response"] = { data: { error: error?.message || "Server Error" } };
            }
            if (!error?.message) {
                error["message"] = "Server Error";
            }

            return Promise.reject(error);
        },
    );
};

export default reloadAxios;
