import {Box, styled, Theme, useTheme} from "@mui/material";
import constants from "styles/constants";
import SortDealsArrow from "components/Svg/SortDealsArrow";

interface DealsListSortingMenuItemProps {
  title: string;
  type: string;
  handleSort: (type: string) => void;
  sortBy: string;
  sortOrder: boolean;
}

const DealsListSortingMenuItemTable = ({
  title,
  handleSort,
  type,
  sortBy,
  sortOrder,
}: DealsListSortingMenuItemProps) => {
  const isSortingItem = type === sortBy;
  const theme = useTheme();
  return (
    <MenuItem
      theme={theme}
      sortOrder={sortOrder}
      isSortingItem={isSortingItem}
      onClick={() => handleSort(type)}
    >
      {title}
      <SortDealsArrow
        color={
          isSortingItem
            ? constants.colors.sortingColor
            : constants.colors.deededGray
        }
      />
    </MenuItem>
  );
};

const MenuItem = styled(Box, {
  shouldForwardProp: (prop) => !["isSortingItem", "sortOrder"].includes(prop as string),
})<{
  isSortingItem: boolean;
  sortOrder: boolean;
  theme: Theme;
}>(({isSortingItem, sortOrder, theme}) => ({
  display: "flex",
  alignItems: "center",
  fontSize: "16px",
  cursor: "pointer",
  color: isSortingItem
    ? constants.colors.sortingColor
    : constants.colors.deededGray,
  fontWeight: 400,
  "& .SortDealsArrow": {
    marginLeft: "10px",
    transform: !isSortingItem
      ? "rotate(0deg)"
      : isSortingItem && sortOrder
      ? "rotate(180deg)"
      : "rotate(0deg)",
  },
  [theme.breakpoints.down(1500)]: {
    fontSize: "14px",
    whiteSpace: "nowrap",
  },
  [theme.breakpoints.down(1400)]: {
    fontSize: "12px",
  },
  [theme.breakpoints.down(1310)]: {
    fontSize: "10px",
  },
  [theme.breakpoints.down(1235)]: {
    "& .SortDealsArrow": {
      marginTop: "-2px",
    },
    fontSize: "10px",
  },
}));

export default DealsListSortingMenuItemTable;
