import React from "react";
import {Staff} from "types/Config/userManagerTypes";
import {Box, Typography, useTheme} from "@mui/material";
import CheckedMfaSvg from "../../svg/CheckedMfaSvg";

const UserManagerMfaTableItem: React.FC<{
  item: Staff;
}> = ({item}) => {
  const theme = useTheme();
  return (
    <>
      <Box
        sx={{
          [theme.breakpoints.up("lg")]: {
            svg: {
              marginTop: "8px",
            },
          },
          [theme.breakpoints.down("lg")]: {
            display: !!item.google_verify ? "grid !important" : "none",
            gridTemplateColumns: "80px 1fr",
            marginTop: !!item.google_verify ? "13px" : "0px",
            alignItems: "center",
          },
        }}
      >
        {!!item.google_verify && (
          <Typography
            sx={{
              display: "none",
              [theme.breakpoints.down("lg")]: {
                display: "block",
                fontSize: "12px",
                fontWeight: "500",
              },
            }}
          >
            MFA:
          </Typography>
        )}
        {!!item.google_verify && <CheckedMfaSvg />}
      </Box>
    </>
  );
};

export default UserManagerMfaTableItem;
