import {AnyAction, Dispatch} from "redux";
import {getFolders, moveFiles} from "../../api";
import {getFoldersTC, setFolderIdAC, setLocaleStorage} from "./storageReducer";
import {RootState} from "../store";

export interface SelectedFileMoveStorageState {
  folders: unknown[];
  path: unknown[];
  currentFolderId: null | unknown;
  checkedFiles: unknown[];
  checkedFolders: unknown[];
  isSelectedFoldersMoveStorage: false | unknown;
  checkedFilesAndFolders: unknown[];
  allSelectOnPage: boolean;
}

type SelectedFileMoveStorageActionType =
  | "SET_FOLDERS"
  | "SET_PATH"
  | "CLEAR_PATH"
  | "SET_CURRENT_FOLDER_ID"
  | "SET_CHECKED_FILES"
  | "DELETE_FILE_FROM_CHECKED_FILES"
  | "CLEAR_CHECKED_FILES"
  | "SET_CHECKED_FOLDERS"
  | "DELETE_FOLDER_FROM_CHECKED_FOLDERS"
  | "SET_IS_SELECTED_FOLDERS_MOVE_STORAGE"
  | "SET_CHECKED_FILES_AND_FOLDERS"
  | "ALL_SELECT_ON_PAGE"
  | "CLEAR_DOWNLOAD_FILES"
  | "ADD_NEW_LIST_OF_FOLDERS"
  | "CLEAR_FOLDERS"
  | "CLEAR_CURRENT_FOLDER_ID";

export type SelectedFileMoveStorageAction = {
  type: SelectedFileMoveStorageActionType;
  payload?: unknown;
};

const selectedFileMoveStorageState: SelectedFileMoveStorageState = {
  folders: [],
  path: [],
  currentFolderId: null,
  checkedFiles: [],
  checkedFolders: [],
  isSelectedFoldersMoveStorage: false,
  checkedFilesAndFolders: [],
  allSelectOnPage: false,
};

export const SelectedFileMoveStorageReducer = (
  state = selectedFileMoveStorageState,
  action: SelectedFileMoveStorageAction,
): SelectedFileMoveStorageState => {
  switch (action.type) {
    case "CLEAR_CURRENT_FOLDER_ID": {
      return {
        ...state,
        currentFolderId: null,
      };
    }
    case "CLEAR_FOLDERS": {
      return {
        ...state,
        folders: [],
      };
    }
    case "ADD_NEW_LIST_OF_FOLDERS": {
      const payload = action.payload as {
        data: {name: string; id: number; doc_type: string}[];
      };
      return {
        ...state,
        folders: [
          ...state.folders.concat([
            ...payload.data.map((f) => {
              return {
                name: f.name,
                id: f.id,
                doc_type: f.doc_type,
              };
            }),
          ]),
        ],
      };
    }
    case "CLEAR_DOWNLOAD_FILES": {
      return {
        ...state,
        checkedFiles: [],
        checkedFolders: [],
        checkedFilesAndFolders: [],
      };
    }
    case "ALL_SELECT_ON_PAGE": {
      return {
        ...state,
        allSelectOnPage: action.payload as boolean,
      };
    }
    case "SET_CHECKED_FILES_AND_FOLDERS": {
      return {
        ...state,
        checkedFilesAndFolders: [
          ...state.checkedFiles,
          ...state.checkedFolders,
        ],
      };
    }
    case "SET_IS_SELECTED_FOLDERS_MOVE_STORAGE": {
      return {...state, isSelectedFoldersMoveStorage: action.payload};
    }
    case "DELETE_FOLDER_FROM_CHECKED_FOLDERS": {
      return {
        ...state,
        checkedFolders: [
          ...state.checkedFolders.filter((x) => x !== action.payload),
        ],
      };
    }
    case "SET_CHECKED_FOLDERS": {
      return {
        ...state,
        checkedFolders: [...state.checkedFolders, action.payload],
      };
    }
    case "CLEAR_CHECKED_FILES": {
      return {
        ...state,
        checkedFiles: [],
      };
    }
    case "DELETE_FILE_FROM_CHECKED_FILES": {
      return {
        ...state,
        checkedFiles: [
          ...state.checkedFiles.filter((x) => x !== action.payload),
        ],
      };
    }
    case "SET_CHECKED_FILES": {
      return {
        ...state,
        checkedFiles: [...state.checkedFiles, action.payload],
      };
    }
    case "SET_CURRENT_FOLDER_ID": {
      return {
        ...state,
        currentFolderId: action.payload,
      };
    }
    case "CLEAR_PATH": {
      return {
        ...state,
        path: [],
      };
    }
    case "SET_PATH": {
      return {
        ...state,
        // TODO: fix typing?
        path: action.payload as unknown[],
      };
    }
    case "SET_FOLDERS": {
      const list = action.payload as {
        data: {
          name: string;
          id: number;
          doc_type: string;
        }[];
      };
      return {
        ...state,
        folders: list.data.map((f) => {
          return {
            name: f.name,
            id: f.id,
            doc_type: f.doc_type,
          };
        }),
      };
    }
    default:
      return state;
  }
};
// TODO: Fix me
export const setPathAC = (payload: unknown[]) => ({
  type: "SET_PATH",
  payload,
});
export const setFolders = (payload: {
  data: {
    name: string;
    id: number;
    doc_type: string;
  }[];
}) => ({
  type: "SET_FOLDERS",
  payload,
});
export const clearPathAC = () => ({
  type: "CLEAR_PATH",
});
export const setCurrentFolderId = (payload: number) => ({
  type: "SET_CURRENT_FOLDER_ID",
  payload,
});
export const setCheckedFilesAC = (payload: number[]) => ({
  type: "SET_CHECKED_FILES",
  payload,
});
export const deleteCheckedFileAC = (payload: number) => ({
  type: "DELETE_FILE_FROM_CHECKED_FILES",
  payload,
});
export const clearChckedFilesAC = () => ({
  type: "CLEAR_CHECKED_FILES",
});
export const setCheckedFoldersAC = (payload: number) => ({
  type: "SET_CHECKED_FOLDERS",
  payload,
});
export const deleteFoldersFromCheckedFolders = (payload: unknown) => ({
  type: "DELETE_FOLDER_FROM_CHECKED_FOLDERS",
  payload,
});
export const setIsSelectedFoldersMoveStorageAC = (payload: unknown) => ({
  type: "SET_IS_SELECTED_FOLDERS_MOVE_STORAGE",
  payload,
});
export const setCheckedFilesAndFoldersAC = () => ({
  type: "SET_CHECKED_FILES_AND_FOLDERS",
});
export const setAllSelectOnThePageAC = (payload: unknown) => ({
  type: "ALL_SELECT_ON_PAGE",
  payload,
});
export const clearCurrentFolderIdAC = () => ({
  type: "CLEAR_CURRENT_FOLDER_ID",
});
export const getFoldersForMoveFileStorageTC =
  (
    dealFolderId: null | number,
    searchValue?: string,
    page?: number,
    dealId?: number,
  ) =>
  async (dispatch: Dispatch) => {
    let folders = await getFolders(
      dealFolderId,
      searchValue,
      1,
      dealId,
      false,
      true,
    );
    dispatch(setFolders(folders));
  };
export const moveFilesTC =
  (
    checkedFiles: unknown,
    folderId: unknown,
    breadCrumbs: {id: number; deal_id: number}[],
    searchValue: string,
    mainStorageFolderId: number,
  ) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    await moveFiles(checkedFiles, folderId);
    if (breadCrumbs?.length === 0) {
      dispatch(getFoldersTC() as unknown as AnyAction);
    } else {
      dispatch(
        getFoldersTC(
          mainStorageFolderId,
          searchValue,
          1,
          breadCrumbs?.[0]?.deal_id,
          true,
        ) as unknown as AnyAction,
      );
    }
    dispatch(clearChckedFilesAC());
    dispatch(clearDownloadFiles());
  };

export const clearDownloadFiles = () => ({
  type: "CLEAR_DOWNLOAD_FILES",
});
export const addNewListOfFoldersAC = (payload: unknown) => ({
  type: "ADD_NEW_LIST_OF_FOLDERS",
  payload,
});
export const clearFoldersForMoveStorageAC = () => ({
  type: "CLEAR_FOLDERS",
});

export const getFoldersForInfiniteScrollTC =
  (dealFolderId: any, searchValue: string, page: number, dealId: undefined) =>
  async (dispatch: (arg0: {type: string; payload: unknown}) => void) => {
    let newFolders = await getFolders(
      dealFolderId,
      searchValue,
      page,
      dealId,
      false,
      true,
    );

    dispatch(addNewListOfFoldersAC(newFolders));
  };
