import {useState, useEffect} from "react";
import "./ClientIntakeEditCard.scss";
import {withSnackbar} from "utils/withSnackbar";

import CrossSVG from "../../Svg/Cross";
import {GenericComponentsWrapper, InputField} from "../GenericComponents";
import {clientIntakeEditData} from "./clientIntakeEditData";
import _ from "lodash";
import {onClientIntakeUpdate} from "./clientIntakeUpdate";
import {useSelector} from "react-redux";
import DeededCircularLoader from "v2/components/DeededCircularLoader";

function ChangeCancelButtons({onSave, onCancel}) {
  return (
    <div className="client__edit_btns">
      <EditButton color="green" onClick={onSave}>
        Save
      </EditButton>
      <EditButton color="normal" onClick={onCancel}>
        Cancel
      </EditButton>
    </div>
  );
}

/**
 * @param props: {color: string}
 * @param {string} props.color Could be 'normal, red or green'
 */
function EditButton({onClick, children, color}) {
  const loading = false;
  return (
    <div className="client__edit_btn-generic">
      <button
        onClick={onClick}
        disabled={loading}
        type="submit"
        className={`client__edit_save-btn ${color}`}
      >
        {children}
      </button>
    </div>
  );
}

/**
 * This function is useful for implementing closing event on escape.
 * @param {(e:any)=>void} onKey
 */
function useEscKey(onKey) {
  const onKeyUp = (e) => {
    if (e.which === 27) {
      onKey();
    }
  };
  useEffect(() => {
    document.addEventListener("keyup", onKeyUp);
    return () => {
      document.removeEventListener("keyup", onKeyUp);
    };
  });
  return;
}

/**
 *
 * @param {Object} props
 * @param {string} props.title
 * @param {string?} props.description
 */
export function EditCardTitleDescription({title, description}) {
  return (
    <div className="client__edit--title-desc">
      <h2>{title}</h2>
      {description ? <div className="desc">{description}</div> : null}
    </div>
  );
}

// TODO (Oleg): Refactor inputs.
// At the moment the input props list is quite bulky
/**
 *
 * @param {Object} obj - Props
 * @param {string} obj.title - Title is used for header display
 * @param {string} obj.dealType - A parameter which is used for textual information required.
 * @param {{displayValue: any, obj: Object}} obj.initialValue - A parameter which is used for textual information required.
 * @param {string} obj.fieldType - Not sure what that is. Probably will be removed
 * @param {any} obj.initialValue - ?
 * @param {Object} obj.cardData - This holds payload creation and data representation for displaying and editing
 * @param {()=>void} obj.onClose - For triggering close actions
 * @param {()=>void} obj.onDataUpdated - If the data is updated this function is used to trigger the re-rendering
 * @param {Object} obj.client - raw API data
 * @param {number} obj.userIndex - Specifies which user is currently edited
 * @param {()=>Promise<any>} obj.enqueueSnackbar - handle notifications
 */
function ClientIntakeEditCard({
  title,
  dealType,
  fieldType = "input",
  initialValue,
  cardData,
  onClose,
  onDataUpdated,
  client,
  userIndex,
  enqueueSnackbar,
}) {
  // If there is a structured object use that instead of display value
  if (initialValue.obj) {
    initialValue = _.isEmpty(initialValue.obj) ? "" : initialValue.obj;
  } else {
    initialValue = initialValue.displayString || "";
  }
  // extract title from the cardData

  const [value, setValue] = useState(initialValue || "");

  useEscKey(onClose);
  const {preferred_province} = useSelector((state) => state.DealsReducer);
  const [isLoading, setIsLoading] = useState(false);

  // Some fields contain the same names but may differ in form content (like signing appointment)
  // first try to find the deal-type specific entry

  let customData = clientIntakeEditData.get(`[${dealType}] ${title}`);
  if (!customData) {
    customData = clientIntakeEditData.get(title);
  }

  // Description is used on certain cards, modifies the look of the card
  let description = null;
  // React component for rendering the form
  let OnCustomRender = null;
  if (customData) {
    if (customData.title) {
      title = customData.title;
    }
    if (customData.description) {
      description =
        typeof customData.description === "string"
          ? customData.description
          : customData.description();
    }
    if (customData.render) {
      OnCustomRender = customData.render;
    }
  }

  // According to design doc the detached header is used only along with description
  const isCenteredHeaderType = description == null;
  let shouldHideDefaultButtons = false;
  if (
    cardData &&
    cardData.shouldHideDefaultButtons &&
    typeof cardData.shouldHideDefaultButtons === "function"
  ) {
    shouldHideDefaultButtons = cardData.shouldHideDefaultButtons(
      client,
      userIndex,
    );
  }

  return (
    <div className="client__edit_wrapper">
      <div className="client__edit--form">
        <div className={isCenteredHeaderType ? "header" : "header2"}>
          {isCenteredHeaderType ? <h2>{title} </h2> : null}
          <div onClick={onClose}>
            <CrossSVG width={32} height={32} color="black" />
          </div>
        </div>
        <GenericComponentsWrapper>
          <div className="client__edit--content" style={{position: "relative"}}>
            {isLoading && (
              <div
                style={{
                  position: "absolute",
                  top: "25%",
                  left: "40%",
                  zIndex: 2,
                }}
              >
                <DeededCircularLoader />
              </div>
            )}
            <div>
              {isCenteredHeaderType ? null : (
                <EditCardTitleDescription
                  title={title}
                  description={description}
                />
              )}

              <div style={{opacity: isLoading ? "0%" : "100%"}}>
                {OnCustomRender ? (
                  <OnCustomRender
                    value={value}
                    onChanged={setValue}
                    onClose={onClose}
                    onDataUpdated={onDataUpdated}
                  />
                ) : (
                  fieldType === "input" && (
                    <InputField
                      title="Value"
                      name="sample_input"
                      value={value}
                      onChange={(e) => setValue(e.target.value)}
                    />
                  )
                )}

                {shouldHideDefaultButtons ? null : (
                  <ChangeCancelButtons
                    onSave={() => {
                      // TOOD(Oleg): simplify the input data
                      // all junk goes here
                      onClientIntakeUpdate(
                        preferred_province,
                        value,
                        cardData,
                        client,
                        userIndex,
                        setIsLoading,
                        onDataUpdated,
                        onClose,
                        enqueueSnackbar,
                      );
                    }}
                    onCancel={onClose}
                  />
                )}
              </div>
            </div>
          </div>
        </GenericComponentsWrapper>
      </div>
    </div>
  );
}

export default withSnackbar(ClientIntakeEditCard);
