import { FC, SyntheticEvent, useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import useUser from "utils/hooks/useUser";
import configuration from "utils/configuration";
import useProfilePartnerPage from "./hooks/useProfilePartnerPage";
import { PageSection } from "components/CommonDashboard/Container/PageContainer";
import { BodyRegular, ButtonSmallText, LinkLarge, SubtitleSmall } from "components/CommonDashboard/Typography";
import Colors from "components/CommonDashboard/Styling/Colors";
import TextField from "components/CommonDashboard/TextField";
import SwitchButton from "components/CommonDashboard/SwitchButton";
import { CopyIcon, SaveIcon } from "components/CommonDashboard/Icons";
import ClickToCopy from "components/CommonDashboard/ClickToCopy";
import Button from "components/CommonDashboard/Button";
import CircularLoader from "components/Common/Loader/CircularLoader";

const ProfileLandingPageTab: FC = () => {
    const { user } = useUser();
    const [partnerPage, loading, update, refresh] = useProfilePartnerPage();
    const [pageName, setPageName] = useState<string>();
    const [error, setError] = useState<string>();
    const [slug, setSlug] = useState<string>();
    const [enabled, setEnabled] = useState(!!partnerPage);

    const handleCheckbox = async (e: SyntheticEvent, checked: boolean) => {
        const success = await update(checked);
        if (success) {
            setEnabled(checked);
        }
    };

    const handleName = (value: string) => {
        setPageName(value);
        if (!value.length) {
            return setError("Field required.");
        }
        if (!value.match(/^[a-zA-Z\d\s]*$/)) {
            return setError(
                "Special characters are not accepted. Please remove all special characters before saving the changes.",
            );
        }
        setError(undefined);
    };

    const handleCancel = () => {
        setPageName(partnerPage?.page_name || `${user.first_name} ${user.last_name}`);
        setEnabled(!!partnerPage);
    };

    const handleSave = async () => {
        await update(enabled, pageName);
    };

    useEffect(() => {
        if (!loading && partnerPage) {
            setPageName(partnerPage.page_name);
            setSlug(partnerPage.slug);
            setEnabled(true);
        }
    }, [loading, partnerPage]);

    if (loading && !partnerPage) {
        return <CircularLoader containerHeight="30vh" />;
    }

    return (
        <Stack gap="2.4rem">
            <PageSection alignItems="flex-start" gap="0.8rem">
                <SubtitleSmall color={Colors.NEUTRAL_700}>Activate Personalized Client Landing Page</SubtitleSmall>
                <SwitchButton
                    testId="landing-page-checkbox"
                    disabled={loading}
                    checked={enabled}
                    onChange={handleCheckbox}
                />
                <ButtonSmallText color={Colors.NEUTRAL_600}>
                    By enabling your personalizing client landing page, you will have a link that you can share with
                    your clients to seamlessly start their closing process and learn more about Deeded. By using this
                    service, you authorize Deeded to create a personalized URL and landing page incorporating your brand
                    for the purpose of facilitating the closing process. Deeded will not use your brand for any other
                    purposes without your explicit consent.
                </ButtonSmallText>
            </PageSection>

            {enabled && (
                <>
                    <PageSection alignItems="flex-start" gap="0.8rem">
                        <SubtitleSmall color={Colors.NEUTRAL_700}>Link to your personalized landing page</SubtitleSmall>
                        <BodyRegular color={Colors.NEUTRAL_600}>
                            Copy the link below and share it with your clients.
                        </BodyRegular>
                        <ClickToCopy noIcon>
                            <Stack direction="row" gap="1rem">
                                <LinkLarge data-testid="partner-page-link" color={Colors.NEUTRAL_600}>
                                    {configuration.frontendUrl.replace(new RegExp('^https?://'), "")}/partners/{slug}
                                </LinkLarge>
                                <CopyIcon color={Colors.NEUTRAL_600} />
                            </Stack>
                        </ClickToCopy>
                    </PageSection>

                    <PageSection alignItems="flex-start" gap="0.8rem">
                        <SubtitleSmall color={Colors.NEUTRAL_700}>
                            Name or brand to use on your personalized page
                        </SubtitleSmall>
                        <BodyRegular color={Colors.NEUTRAL_600}>
                            Please provide your name or company name to be displayed on your personalized landing page.
                        </BodyRegular>
                        <Stack width="30rem">
                            <TextField
                                data-testid="page-name-input"
                                disabled={loading}
                                value={pageName}
                                error={error}
                                onChange={(e) => handleName(e.target.value)}
                                outlined
                            />
                        </Stack>
                    </PageSection>
                </>
            )}

            <Stack direction="row" justifyContent="space-between">
                <Button
                    disabled={loading || Boolean(error)}
                    typeOf="outline"
                    label={{ text: "Cancel" }}
                    onClick={handleCancel}
                />
                <Button
                    testId="save-changes-button"
                    loading={loading}
                    disabled={Boolean(error)}
                    typeOf="primary"
                    label={{ text: "Save changes" }}
                    startIcon={<SaveIcon color={Colors.WHITE} />}
                    onClick={handleSave}
                />
            </Stack>
        </Stack>
    );
};

export default ProfileLandingPageTab;
