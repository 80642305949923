// @ts-check
import moment from "moment";

const getUtcOffset = () => moment().utcOffset() / 60;

export const provinceTimeToDate = (
  timeDate: Date,
  provinceUtcOffset: number | string,
  date: Date | undefined,
) => {
  timeDate = new Date(timeDate);
  const theUtcOffset = getUtcOffset();
  date = date ?? new Date();
  provinceUtcOffset = +provinceUtcOffset;
  timeDate.setDate(date.getDate());
  timeDate.setMonth(date.getMonth());
  return (
    moment(timeDate)
      // Convert to UTC
      .subtract(theUtcOffset, "hours")
      // From UTC to the target timezone
      .add(provinceUtcOffset, "hours")
      .toDate()
  );
};

export const mapIsoStringToTimezoneTime = (
  isoDate: moment.MomentInput,
  timeZone: {label?: string; value?: string; offset: any},
  provinceTimeZone: {label: string; value: string; key: string},
) => {
  const provinceUtcOffset = Number(timeZone.offset);

  const theUtcOffset = getUtcOffset();
  const m = moment(isoDate)
    .subtract(provinceUtcOffset, "hours")
    .add(theUtcOffset, "hours");

  return m.toDate();
};

export const professionals = [
  "Realtor",
  "Real Estate/Mortgage Team",
  "Broker",
  "Lender",
];

const states = [
  "AB",
  "BC",
  "MB",
  "NB",
  "NL",
  "NT",
  "NS",
  "NU",
  "ON",
  "PE",
  "QC",
  "SK",
  "YT",
];

export const timezones = {
  states,

  stateOptions: states.map((x) => ({label: x, value: x})),

  stateToTimezone: {
    AB: "America/Edmonton",
    BC: "America/Vancouver",
    MB: "America/Winnipeg",
    NB: "America/Moncton",
    NL: "America/Goose_Bay",
    NT: "America/Yellowknife",
    NS: "America/Halifax",
    NU: "America/Resolute",
    ON: "America/Toronto",
    PE: "America/Halifax",
    QC: "America/Montreal",
    SK: "America/Regina",
    YT: "America/Whitehorse",
  } as Record<string, string>,

  timezoneToState: {
    "America/Edmonton": "AB",
    "America/Vancouver": "BC",
    "America/Winnipeg": "MB",
    "America/Moncton": "NB",
    "America/Goose_Bay": "NL",
    "America/Yellowknife": "NT",
    "America/Halifax": "NS",
    "America/Resolute": "NU",
    "America/Toronto": "ON",
    "America/Montreal": "QC",
    "America/Regina": "SK",
    "America/Whitehorse": "YT",
  } as Record<string, string>,
};

export const formatMessageDate = (date: Date) => {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  };
  return date.toLocaleString("en-US", options);
};

export const availableDealStates = [
    { id: "ON", label: "Ontario" },
    { id: "AB", label: "Alberta" },
    { id: "BC", label: "British Columbia" }
];