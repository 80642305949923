export const CrossIcon = () => (
  <svg width={22} height={22} viewBox="0 0 22 22" fill="#7F7F7F">
    <path
      d="M22 1.29419L20.7246 0.0216064L11 9.72767L1.27535 0.0216064L0 1.29419L9.72465 11.0003L0 20.7057L1.27535 21.9783L11 12.2729L20.7246 21.9783L22 20.7057L12.2747 11.0003L22 1.29419Z"
      fill="#7F7F7F"
    />
  </svg>
);

export const EnvelopeIcon = () => (
  <svg width={22} height={15} viewBox="0 0 22 15" fill="#7F7F7F">
    <path
      d="M1.66667 0C1.33906 0 1.03836 0.100892 0.78125 0.269114L10.1649 9.158C10.576 9.54764 11.1087 9.54762 11.5191 9.158L20.8854 0.269114C20.6283 0.100892 20.3276 0 20 0H1.66667ZM0.104167 1.14583C0.0406944 1.32933 0 1.5207 0 1.72745V13.2726C0 13.6234 0.0976943 13.9466 0.269111 14.2188L6.90105 7.5955L0.104167 1.14583ZM21.5625 1.14583L14.783 7.58681L21.3976 14.2188C21.569 13.9466 21.6667 13.6234 21.6667 13.2726V1.72745C21.6667 1.5207 21.626 1.32933 21.5625 1.14583ZM13.9757 8.35939L12.283 9.96528C11.4769 10.7306 10.2072 10.7295 9.40106 9.96528L7.70833 8.36806L1.14583 14.9132C1.30997 14.969 1.48411 15 1.66667 15H20C20.1826 15 20.3567 14.969 20.5208 14.9132L13.9757 8.35939Z"
      fill="#7F7F7F"
    />
  </svg>
);

export const PhoneIcon = () => (
  <svg width={16} height={19} viewBox="0 0 16 19" fill="#7F7F7F">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.49635 1.37095C3.90125 -0.313123 5.6139 -0.361408 6.27462 2.19944C6.86537 4.48682 7.00899 4.82211 5.1478 6.12533C3.64849 7.17529 8.93867 14.7304 10.438 13.6807C12.2992 12.3775 12.5653 12.6271 14.5129 13.9645C16.6932 15.4611 16.062 17.0541 13.6569 18.7381C8.53886 20.9523 -2.28073 5.50056 1.49636 1.37095L1.49635 1.37095Z"
      fill="#7F7F7F"
    />
  </svg>
);

export const PersonIcon = () => (
  <svg width={20} height={20} viewBox="0 0 20 20">
    <path
      d="M10.0002 9.78131C12.2238 9.78131 14.0266 7.59171 14.0266 4.89069C14.0266 2.1896 13.4348 0 10.0002 0C6.56563 0 5.97363 2.1896 5.97363 4.89069C5.97363 7.59171 7.77643 9.78131 10.0002 9.78131Z"
      fill="#7F7F7F"
    />
    <path
      d="M2.39483 17.2487C2.39415 17.0841 2.39346 17.2023 2.39483 17.2487V17.2487Z"
      fill="#7F7F7F"
    />
    <path
      d="M17.605 17.3774C17.6071 17.3323 17.6057 17.0646 17.605 17.3774V17.3774Z"
      fill="#7F7F7F"
    />
    <path
      d="M17.5963 17.0513C17.5217 12.346 16.9072 11.0053 12.2048 10.1566C12.2048 10.1566 11.5428 11.0001 9.99997 11.0001C8.45712 11.0001 7.79506 10.1566 7.79506 10.1566C3.14392 10.996 2.49203 12.3169 2.40632 16.8984C2.39929 17.2725 2.39603 17.2922 2.39478 17.2487C2.39506 17.3301 2.3954 17.4806 2.3954 17.7431C2.3954 17.7431 3.51495 20 9.99997 20C16.4849 20 17.6045 17.7431 17.6045 17.7431C17.6045 17.5745 17.6047 17.4572 17.6048 17.3774C17.6036 17.4043 17.6011 17.3522 17.5963 17.0513Z"
      fill="#7F7F7F"
    />
  </svg>
);
