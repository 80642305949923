import { FC, SyntheticEvent } from "react";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { styled } from "@mui/material/styles";
import Colors from "../Styling/Colors";
import { BUTTON_LARGE_TEXT } from "../Styling/Styles";
import {CardsIcon} from "../Icons";

interface TabsProps {
    value: string;
    onChange: (newVal: string) => void;
    options: string[];
}

const NavTabs: FC<TabsProps> = ({ options, value, onChange }) => {
    const handleChange = (event: SyntheticEvent, newValue: string) => {
        onChange(newValue);
    };

    return (
        <Tabs
            value={value}
            onChange={handleChange}
            TabIndicatorProps={{ sx: { background: Colors.PRIMARY_600 } }}
        >
            {options.map((tab) => (
                <StyledTab key={tab} value={tab} label={tab} iconPosition="start" />
            ))}
        </Tabs>
    );
};

const StyledTab = styled(Tab)(() => ({
    ...BUTTON_LARGE_TEXT,
    textTransform: "capitalize",
    color: Colors.NEUTRAL_300,
    padding: "0 2.4rem",
    "&.Mui-selected": {
        color: Colors.NEUTRAL_700,
    },
}));

export default NavTabs;
