import React, {useEffect, useState} from "react";
import FolderSimple from "../../../Svg/FolderSimple";
import SearchFileMoveStorage from "../../../Svg/SearchFileMoveStorage";
import SelectedFilesMoveStorageFolder from "../../../Svg/SelectedFilesMoveStorageFolder";
import SelectedFilesMoveStorageArrow from "../../../Svg/SelectedFilesMoveStorageArrow";
import {
  clearBreadCrumbsAC,
  getFoldersTC,
} from "../../../../redux/reducers/storageReducer";
import {
  clearCurrentFolderIdAC,
  getFoldersForInfiniteScrollTC,
  getFoldersForMoveFileStorageTC,
  moveFilesTC,
  setCurrentFolderId,
  setPathAC,
} from "../../../../redux/reducers/selectedFileMoveStorageReducer";
import {useDispatch, useSelector} from "react-redux";
import SelectedFilesMoveStorageBackArrow from "../../../Svg/SelectedFilesMoveStorageBackArrow";
import CloseSelectedFilesSearchStorageSvg from "../../../Svg/CloseSelectedFilesSearchStorageSvg";
import InfiniteScroll from "react-infinite-scroll-component";
import DeededButton from "../../../../v2/components/DeededButton";
import {styled} from "@mui/material";
import constants from "../../../../styles/constants";
import {useAppSelector} from "../../../../redux/hooks";

const SelectedFilesMoveStorage = ({setIsSelectedFilesMoveStorage}) => {
  const folders = useSelector(
    (state) => state.SelectedFileMoveStorageReducer.folders,
  );
  const path = useSelector(
    (state) => state.SelectedFileMoveStorageReducer.path,
  );

  const checkedFiles = useSelector(
    (state) => state.SelectedFileMoveStorageReducer.checkedFiles,
  );
  const moveReducer = useSelector(
    (state) => state.SelectedFileMoveStorageReducer,
  );
  const folderId = useAppSelector((state) => state.StorageReducer.folderID);
  const breadCrumbs = useAppSelector(
    (state) => state.StorageReducer.breadCrumbs,
  );
  const [startFolderId, setStartFolderId] = useState(
    breadCrumbs?.[0]?.id ?? folderId,
  );
  const currentFolderId =
    useSelector(
      (state) => state.SelectedFileMoveStorageReducer.currentFolderId,
    ) ?? startFolderId;
  const dispatch = useDispatch();

  // if(path.length === 1 && currentFolderId !== null) dispatch(clearCurrentFolderIdAC())
  useEffect(() => {
    return () => {
      dispatch(setCurrentFolderId(null));
    };
  }, [dispatch]);
  useEffect(() => {
    if (startFolderId) {
      dispatch(getFoldersForMoveFileStorageTC(startFolderId));
    } else {
      dispatch(getFoldersForMoveFileStorageTC(currentFolderId));
    }
  }, [currentFolderId, dispatch, startFolderId]);
  const [isArrowDisplay, setIsArrowDisplay] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  useEffect(() => {
    if (startFolderId) {
      setIsArrowDisplay(true);
    } else if (currentFolderId === null) setIsArrowDisplay(false);
  }, [currentFolderId, startFolderId]); // if(currentFolderId === null){

  const search = (value) => {
    dispatch(getFoldersForMoveFileStorageTC(currentFolderId, value));
  };
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [isCloseIconPushed, setIsCloseIconPushed] = useState(false);
  const [page, setPage] = useState(2);
  const getNewFolders = () => {
    dispatch(getFoldersForInfiniteScrollTC(currentFolderId, searchValue, page));
    setPage(page + 1);
  };
  return (
    <div className={"selectedFilesMoveStorageBox"}>
      <div className={"relativeSelectedFilesMoveStorageBox"}>
        <div className={"rotatedMiniRectangleHalfRedBorder"}></div>
        <input
          onFocus={() => {
            setIsSearchActive(true);
          }}
          onBlur={() => {
            setIsCloseIconPushed(true);
            setIsSearchActive(false);
          }}
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.currentTarget.value);
            search(e.currentTarget.value);
          }}
          placeholder={"Move to..."}
          className={"selectedFilesMoveStorageSearch"}
        />
        {!isArrowDisplay && !isSearchActive && (
          <div className={"folderSimple"}>
            <FolderSimple />
          </div>
        )}
        {isArrowDisplay && !isSearchActive && (
          <div
            onClick={() => {
              if (path.length === 0) {
                setStartFolderId(null);
                dispatch(getFoldersForMoveFileStorageTC(null));
                dispatch(setPathAC([null]));
                return;
              }
              dispatch(clearCurrentFolderIdAC());
              const newPath = path?.slice(0, path.length - 1);
              dispatch(setPathAC(newPath));
              setStartFolderId(null);
              dispatch(
                getFoldersForMoveFileStorageTC(newPath[newPath.length - 1]),
              );
              setPage(2);
            }}
            className={"selectedFilesMoveStorageBackArrow"}
          >
            <SelectedFilesMoveStorageBackArrow />
          </div>
        )}
        {isSearchActive && (
          <div className={"closeSelectedFilesSearchStorage"}>
            <CloseSelectedFilesSearchStorageSvg />
          </div>
        )}
        <div
          className={
            (isSearchActive
              ? "searchFileMoveStorageActive"
              : "searchFileMoveStorage") +
            " " +
            (isCloseIconPushed ? "searchFileMoveStorageRight" : "")
          }
        >
          <SearchFileMoveStorage />
        </div>
        <div id={"scrollableDiv"} className={"selectedFilesMoveStorageFolders"}>
          <InfiniteScroll
            scrollableTarget="scrollableDiv"
            dataLength={folders.length}
            next={() => getNewFolders()}
            hasMore={true}
            loader={<h4></h4>}
            endMessage={
              <p style={{textAlign: "center"}}>
                <b></b>
              </p>
            }
          >
            {folders.map((el) => {
              if (el.doc_type === "Directory")
                return (
                  <div
                    key={el.id}
                    onClick={() => {
                      setSearchValue("");
                      setStartFolderId(null);
                      dispatch(setCurrentFolderId(el.id));
                      dispatch(setPathAC([...path, currentFolderId]));
                      setIsArrowDisplay(true);
                      dispatch(getFoldersForMoveFileStorageTC(el.id));
                      setPage(2);
                    }}
                    className={"selectedFilesMoveStorageFolder"}
                  >
                    <div className={"selectedFilesMoveStorageFolderIcon"}>
                      <SelectedFilesMoveStorageFolder />
                    </div>
                    <p className={"selectedFilesMoveStorageFolderName"}>
                      {el.name}
                    </p>
                    <div className={"SelectedFilesMoveStorageArrow"}>
                      <SelectedFilesMoveStorageArrow />
                    </div>
                  </div>
                );
              else return null;
            })}{" "}
          </InfiniteScroll>
        </div>
        <div className={"selectedFilesMoveStorageMoveToBox"}>
          <CancelButton
            onClick={() => {
              setIsSelectedFilesMoveStorage(false);
            }}
            kind={"secondary"}
          >
            Cancel
          </CancelButton>
          <MoveButton
            onClick={() => {
              if (startFolderId) {
                dispatch(moveFilesTC(checkedFiles, startFolderId));
              } else {
                dispatch(moveFilesTC(checkedFiles, currentFolderId));
              }
              window.location.pathname === "/dashboard/storage" &&
                dispatch(getFoldersTC());
              setIsSelectedFilesMoveStorage(false);
              dispatch(clearBreadCrumbsAC());
            }}
          >
            Move
          </MoveButton>
        </div>
      </div>
    </div>
  );
};
const CancelButton = styled(DeededButton)({
  width: "100px",
  height: "33px",
});
const MoveButton = styled(DeededButton)({
  width: "100px",
  height: "33px",
  backgroundColor: constants.colors.green,
  border: `1px solid ${constants.colors.green}`,
  "&:hover": {
    backgroundColor: constants.colors.white,
    color: constants.colors.red,
  },
});
export default SelectedFilesMoveStorage;
