import {clearEventsAC} from "../../../../redux/reducers/calendarReducer";
import {Dispatch} from "react";
import {StaffUser} from "../../../../redux/reducers/configReducer/staffCardReducer";

export const getEventsEffect = (
  date: Date,
  regions: unknown[],
  dispatch: Dispatch<unknown>,
  getEventsCallback: (
    date: Date,
    regions: string[] | null,
    staff: string[] | null,
    dealTypeState: string[],
    selectedStaffUser: StaffUser | null,
    calendarTimezone: string,
    view: "day" | "week" | "month",
  ) => void,
  staff: string[] | null,
  dealTypeState: string[],
  isAllProvinciesChecked: boolean,
  selectedStaffUser: StaffUser | null,
  calendarTimezone: string,
  view: "day" | "week" | "month",
) => {
  if (regions.length === 0) {
    dispatch(clearEventsAC());
    const t = setTimeout(() => {
      getEventsCallback(
        date,
        null,
        staff,
        dealTypeState,
        selectedStaffUser,
        calendarTimezone,
        view,
      );
    }, 500);
    return () => {
      clearTimeout(t);
    };
  } else {
    const t = setTimeout(() => {
      getEventsCallback(
        date,
        (isAllProvinciesChecked ? "ALL" : regions) as string[] | null,
        staff,
        dealTypeState,
        selectedStaffUser,
        calendarTimezone,
        view,
      );
    }, 500);
    return () => {
      clearTimeout(t);
    };
  }
};
