import {useLocation} from "react-router-dom";
import {useMemo} from "react";

const useQueryParams = () => {
  const {search} = useLocation();

  return useMemo(
    () => Object.fromEntries(new URLSearchParams(search).entries()),
    [search],
  );
};

export default useQueryParams;
