import {FC, useMemo} from "react";
import Stack from "@mui/material/Stack";
import {SxProps, useTheme} from "@mui/material/styles";
import {
  BodyRegular,
  BodySmall,
  ButtonLargeText,
  H3,
} from "components/CommonDashboard/Typography";
import Colors from "components/CommonDashboard/Styling/Colors";
import Accordion from "components/CommonDashboard/Accordion";
import {
  PlaceIcon,
  MailIcon,
  FaxIcon,
  BuildingIcon,
  PhoneIcon,
  CopyIcon,
} from "components/CommonDashboard/Icons";
import ClickToCopy from "components/CommonDashboard/ClickToCopy";
import {User, UserStaff} from "types/user";
import {Deal} from "types/deal";
import {transformLabels} from "utils/transformLabels";
import {useMixpanel} from "utils/MixpanelContext";
import {formatPhoneNumber} from "../utils/formatPhoneNumber";

interface MyTeamStaffUserProps {
  staff: UserStaff;
  deal: Deal;
  description: string | null;
  authUser: User | null;
  sx?: SxProps
}

const MyTeamStaffUser: FC<MyTeamStaffUserProps> = ({staff, deal, description, authUser, sx}) => {
  const theme = useTheme();
  const mixpanel = useMixpanel();
  const skipTransform = staff.role === "Law Clerk" && !["AB", "BC"].includes(deal.address.state);

  const address = useMemo(() => {
    if (!staff.address) return null;
    const {address, city, state, code} = staff.address;

    if (!address || !city || !state || !code) return null;

    return `${address}, ${city}, ${state}, ${code}`;
  }, [staff.address]);

  const contactInfo = [
    {event: "email", text: staff.email, icon: <MailIcon color={Colors.PRIMARY} size="small" />, allowCopy: true},
    {event: "phone", text: formatPhoneNumber(staff.phone), icon: <PhoneIcon color={Colors.PRIMARY} size="small" />, allowCopy: true},
    {event: "fax", text: formatPhoneNumber(staff.address?.fax), icon: <FaxIcon color={Colors.PRIMARY} size="small" />, allowCopy: true},
    {text: staff.law_firm, icon: <BuildingIcon color={Colors.PRIMARY} size="small" />, allowCopy: false},
    {text: address, icon: <PlaceIcon color={Colors.PRIMARY} size="small" />, allowCopy: false},
  ].filter((c) => Boolean(c.text));


  const handleCopyCallback = (info: typeof contactInfo[0]) => {
    if (!info.event) return;

    let role = '';
    switch (staff.role) {
      case "Law Clerk":
        role = "law_clerk";
        break;
      case "Document Support Specialist":
        role = "customer_support";
        break;
      case "Lawyer":
        role = "lawyer";
        break;
      default:
        return;
    }

    mixpanel.track(`${role}_${info.event}_copied`, {
      deal_type: deal.deal_type,
      deal_province: deal.address.state,
      client_type: deal.participants.find(p => p.user_id === authUser?.id)?.role,
    });
  };

  return (
    <Accordion
      title={
        <Stack pr="1rem">
          <H3>{`${staff.first_name} ${staff.last_name}`}</H3>
          <BodySmall color={Colors.DARK_GREY}>{transformLabels(staff.role, skipTransform)}</BodySmall>
        </Stack>
      }
      sx={{
        [theme.breakpoints.down("mobile")]: {
          ".MuiAccordionSummary-expandIconWrapper": {
            position: "relative",
            alignSelf: "center",
          },
        },
        ...sx
      }}
    >
      <Stack flexDirection="column" alignItems="flex-start" gap="1rem">
        {description && (
          <BodyRegular mb="0.6rem" color={Colors.DARK_GREY}>{description}</BodyRegular>
        )}

        {contactInfo.map((c) => (
          <Stack key={c.text} flexDirection="row" gap="1.6rem" alignItems="center">
            <Stack
              sx={{
                height: "4.2rem",
                width: "4.2rem",
                minWidth: "4.2rem",
                border: `1px solid ${Colors.LIGHT_GREY}`,
                borderRadius: "50%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {c.icon}
            </Stack>
            <ButtonLargeText color={Colors.BLACK} sx={{ wordBreak: 'break-all', whiteSpace: 'break-spaces' }}>
              {c.allowCopy ? <ClickToCopy callback={() => handleCopyCallback(c)}>{c.text} </ClickToCopy> : c.text}
            </ButtonLargeText>
          </Stack>
        ))}
      </Stack>
    </Accordion>
  );
};

export default MyTeamStaffUser;
