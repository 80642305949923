import {FC, forwardRef, useMemo, useState} from "react";
import MuiTextField, {
  TextFieldProps as MuiTextFieldProps,
} from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import {styled, alpha} from "@mui/material/styles";
import {StyledComponent} from "@emotion/styled";
import {BodySmall, SubtitleSmall} from "../Typography";
import {BODY_REGULAR} from "../Styling/Styles";
import {EyeCrossedIcon, EyeIcon} from "../Icons";
import {MinusIcon, PlusIcon} from "../../Common/Icons/Iconography";
import Colors from "components/CommonDashboard/Styling/Colors";

export interface TextFieldProps
  extends Omit<
    MuiTextFieldProps,
    "size" | "label" | "error" | "type" | "variant"
  > {
  label?: string;
  LabelComponent?: StyledComponent<{color?: string}>;
  observation?: string;
  error?: string;
  size?: "default"; // Only size for now
  type?: "text" | "password" | "email" | "number";
  outlined?: boolean;
}

const TextField: FC<TextFieldProps> = forwardRef<
  HTMLDivElement,
  TextFieldProps
>(function inputRender(
  {
    outlined = false,
    label,
    LabelComponent = SubtitleSmall,
    observation,
    size,
    error,
    fullWidth = true,
    disabled,
    type,
    ...props
  },
  ref,
) {
  const [showPassword, setShowPassword] = useState(false);

  const handleLocalChange = (newValue: string | number) => {
    if (!props.onChange || !props.name) return;

    props.onChange({target: {name: props.name, value: newValue}} as never);
  }

  const inputProps = useMemo(() => {
    if (type === "password") {
      return {
        ...(props.InputProps || {}),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <EyeCrossedIcon color={Colors.DARK_GREY} /> : <EyeIcon color={Colors.DARK_GREY} />}
            </IconButton>
          </InputAdornment>
        ),
      };
    }
    if (type === "number") {
      const value = (props?.value || 0) as number;
      return {
        ...(props.InputProps || {}),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={() => handleLocalChange(value + 1)}
              aria-label="plus"
            >
              <PlusIcon color={Colors.BLACK} />
            </IconButton>
          </InputAdornment>
        ),
        startAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={() => handleLocalChange(value <= 0 ? 0 : value - 1)}
              aria-label="minus">
              <MinusIcon color={Colors.BLACK} />
            </IconButton>
          </InputAdornment>
        ),
      };
    }
    return props.InputProps || {};
  }, [type, showPassword, props.InputProps, props.value]);

  return (
    <Stack flex={fullWidth ? 1 : undefined} direction="column" gap="0.6rem">
      <Stack direction="row" gap="0.7rem">
        {label && (
          <LabelComponent color={Colors[error ? "ERROR" : "BLACK"]}>
            {label}
          </LabelComponent>
        )}
        {observation && (
          <BodySmall color={Colors[error ? "ERROR" : "DARK_GREY"]}>
            {observation}
          </BodySmall>
        )}
      </Stack>

      <TextFieldStyledInput
        {...props}
        ref={ref}
        disabled={disabled}
        variant="outlined"
        fullWidth={fullWidth}
        outlined={outlined}
        hasError={Boolean(error)}
        type={showPassword ? "text" : type}
        InputProps={inputProps}
      />

      {error && <BodySmall color={Colors.ERROR}>{error}</BodySmall>}
    </Stack>
  );
});

export const TextFieldStyledInput = styled(MuiTextField, {
  shouldForwardProp: (prop) => prop !== "hasError" && prop !== "outlined",
})<{outlined?: boolean; hasError?: boolean}>(
  ({outlined, hasError, disabled, type}) => {
    const borderColor = hasError
      ? Colors.ERROR
      : outlined
      ? Colors.LIGHT_GREY
      : Colors.WHITE;

    return {
      ".MuiInputBase-root": {
        backgroundColor: Colors.WHITE,
        padding: 0,
        fieldset: {
          transition: "all .2s ease",
          borderColor: borderColor,
          borderWidth: ".1rem !important",
          borderRadius: "1.6rem",
          boxShadow: `0px 1px 2px 0px ${alpha(Colors.BLACK, 0.1)}`,
          "& legend": {width: 0},
        },
        "&:hover fieldset": {
          borderColor: borderColor,
        },
        "&.Mui-disabled ": {
          backgroundColor: Colors.LIGHT_GREY,
          cursor: "no-drop",
          fieldset: {
            borderColor: Colors.NEUTRAL_500,
          },
        },
        "&.Mui-focused": {
          fieldset: {
            borderColor: Colors.FOCUS,
          },
        },
        ".MuiInputBase-input": {
          ...BODY_REGULAR,
          padding: "1rem 1.4rem",
          color: Colors.BLACK,
          textAlign: type == "number" ? "center" : "left",
          "&::placeholder": {
            color: disabled ? Colors.NEUTRAL_500 : Colors.DARK_GREY,
            opacity: 1,
          },
        },
        ".MuiInputAdornment-root": {
          svg: {
            width: "1.8rem",
            height: "1.8rem",
          },
        },
      },
    };
  },
);

export default TextField;
