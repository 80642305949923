import React from "react";
import {FileTasksFiled} from "redux/reducers/fileTasksSlice";
import DealDetailsTextAreaStageField from "components/Dashboard/DealDetailsFileTasks/components/DealDetailsTextAreaStageField";
import DealDetailsInputStageField from "components/Dashboard/DealDetailsFileTasks/components/DealDetailsInputStageField";
import DealDetailsCheckboxStageField from "components/Dashboard/DealDetailsFileTasks/components/DealDetailsCheckboxStageField";

const DealDetailsFileTasksPageStageField: React.FC<{
  fieldIndex: number;
  field: FileTasksFiled;
}> = ({field, fieldIndex}) => {
  if (field.input_type === "checkbox" && field.stage_number === 5) {
    return (
      <DealDetailsTextAreaStageField fieldIndex={fieldIndex} field={field} />
    );
  } else if (field.input_type === "checkbox") {
    return (
      <DealDetailsCheckboxStageField fieldIndex={fieldIndex} field={field} />
    );
  } else if (field.input_type === "number") {
    return <DealDetailsInputStageField fieldIndex={fieldIndex} field={field} />;
  } else {
    return <></>;
  }
};
export default DealDetailsFileTasksPageStageField;
