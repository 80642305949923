import React, {useState, useEffect} from "react";
import "./PostalCodeValidator.scss";

export const PostalCodeValidator = ({
  isRequired = false,
  defaultValue,
  name,
  handlePostalCode,
  inputError,
  disabled = false,
  style = {},
  onChange = (code = "") => {},
  ...params
}) => {
  const [postCode, setPostCode] = useState("");
  const [error, setError] = useState(false);
  const handleChange = (e) => {
    const {value} = e.target;
    const code = value.split(" ").join("");
    const symbols = [];
    for (let i = 0; i < code.length; i++) {
      symbols.push(code[i]);
    }
    let formatted =
      symbols.slice(0, 3).join("") + " " + symbols.slice(3, 6).join("");
    formatted = formatted.toUpperCase().trim();
    setPostCode(() => formatted);
    onChange(formatted);
  };

  useEffect(() => {
    let regEx = /[a-zA-Z]\d[a-zA-z]\s\d[a-zA-Z]\d/gi;
    if (postCode.match(regEx) || postCode === "") {
      handlePostalCode(postCode);
      setError(false);
    } else {
      setError(true);
    }
  }, [postCode]);
  useEffect(() => {
    if (defaultValue) {
      setPostCode(defaultValue);
    }
  }, [defaultValue]);
  return (
    <input
      required={isRequired}
      className={`postal__validator ${inputError && "input-error"}`}
      style={{border: error ? "1px solid #f16764" : ""}}
      name={name}
      value={postCode}
      disabled={disabled}
      placeholder={disabled ? "" : "L2L 3G4"}
      autoComplete="new-password"
      onChange={(e) => handleChange(e)}
      {...params}
    />
  );
};
