import { FC } from "react";
import { User, UserRoleType, UserStaff } from "types/user";
import MyTeamStaffUser from "customerPortal/components/MyTeamStaffUser";
import { Deal } from "types/deal";
import Colors from "components/CommonDashboard/Styling/Colors";
import { H3 } from "components/CommonDashboard/Typography";
import { styled } from '@mui/material/styles';
import Stack from "@mui/material/Stack";
import { transformLabels } from "utils/transformLabels";
import UserRole from "types/roles";

interface DeededTeamProps {
  staff?: UserStaff[];
  user: User;
  deal: Deal;
}

const myTeamStaffDescriptionsForProfessionals = ({ staffRole, staffName, dealProvince }: { staffRole: UserRoleType, staffName?: string, dealProvince: string }) => {
  const descriptions: Partial<Record<UserRoleType, string>> = {
    "Law Clerk": `${staffName} will be the ${transformLabels(UserRole.LawClerk, !["AB", "BC"].includes(dealProvince)).toLowerCase()} and main point of contact for your client transaction, facilitating communication and assisting with any queries or concerns.`,
    "Lawyer": `${staffName} will be the lawyer for your client transaction. Their role is to oversee the transaction, review key documents, and offer their professional expertise and support throughout the process.`,
    "Document Support Specialist": `${staffName} will collaborate with your client to ensure that our team has all the necessary documents and information to successfully complete the transaction.`
  };
  return descriptions[staffRole] || '';
}

const DeededTeam: FC<DeededTeamProps> = ({ staff = [], user, deal }) => {
  return (
    <TeamStack>
      <H3>Deeded Team</H3>
      <Stack flexDirection="row" gap="1.6rem" flexWrap='wrap' alignItems='start'>
        {staff.map((s) => {
          const desc = myTeamStaffDescriptionsForProfessionals({ staffRole: s.role, staffName: s.first_name, dealProvince: deal.address.state });
          return <MyTeamStaffUser
            key={s.id.toString()}
            staff={s}
            deal={deal}
            description={desc}
            authUser={user}
            sx={{
              border: `1px solid ${Colors.GREY_2}`,
              maxWidth: '43.5rem',
            }}
          />
        })}
      </Stack>
    </TeamStack>
  );
};

const TeamStack = styled(Stack)(({ theme }) => ({
  boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
  padding: "4rem",
  margin: "3rem 0",
  gap: '2rem',
  alignContent: 'stretch',
  backgroundColor: Colors.WHITE,
  [theme.breakpoints.down('mobile')]: {
    boxShadow: 'none',
    padding: '2rem',
    margin: "1rem 0",
    borderRadius: '.5rem'
  }
}));

export default DeededTeam;
