import React from "react";

const StorageFolderSvg = () => {
  return (
    <svg
      width="96"
      height="96"
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M80.9985 26.9998H49.2407L38.9985 16.7576C38.4428 16.1986 37.7817 15.7554 37.0536 15.4537C36.3254 15.1519 35.5446 14.9976 34.7563 14.9998H14.9985C13.4077 15.0013 11.8825 15.634 10.7576 16.7589C9.63275 17.8837 9.0001 19.409 8.99854 20.9998V75.2312C9.00064 76.761 9.60947 78.2275 10.6915 79.3089C11.7735 80.3904 13.2402 80.9985 14.77 80.9998H81.3325C82.8346 80.9977 84.2745 80.4 85.3367 79.3379C86.3988 78.2758 86.9964 76.8358 86.9985 75.3338V32.9998C86.997 31.409 86.3643 29.8837 85.2394 28.7589C84.1146 27.634 82.5894 27.0013 80.9985 26.9998ZM14.9985 20.9998H34.7563L40.7563 26.9998H14.9985V20.9998Z"
        fill="#EB5757"
      />
    </svg>
  );
};
export default StorageFolderSvg;
