import React, { FC, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import useUser from "utils/hooks/useUser";
import { isProfessional } from "utils/permissionRoles";
import Hello2Image from "assets/img/hello2-image";
import HomeHelloBackground from "assets/img/HomeHelloBackground.png";
import Colors from "components/CommonDashboard/Styling/Colors";
import PageContainer, { PageSection } from "components/CommonDashboard/Container/PageContainer";
import { BodyRegular, H2, SubtitleRegular } from "components/CommonDashboard/Typography";
import { BankIcon, BuildingIcon, PlusIcon } from "components/CommonDashboard/Icons";
import HomePointsBanner from "./HomePointsBanner";
import HomeAction from "./HomeAction";
import HomeNews from "./HomeNews";
import HomePerks from "./HomePerks";

const Home: FC = () => {
    const history = useHistory();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("mobile"));
    const lessThanLg = useMediaQuery(theme.breakpoints.down("lg"));
    const { user } = useUser();

    useEffect(() => {
        if (user && !isProfessional(user.role)) {
            history.replace("/dashboard/deals");
        }
    }, []);

    return (
        <PageContainer>
            <PageSection
                gap="2.4rem"
                direction={isMobile ? "column" : "row"}
                sx={{
                    backgroundImage: lessThanLg ? "unset" : `url(${HomeHelloBackground})`,
                    backgroundRepeat: "no-repeat",
                }}
            >
                <Box minWidth="0" hidden={lessThanLg}>
                    <Hello2Image />
                </Box>
                <Stack flex={1} minWidth="20rem" gap="1rem">
                    <H2 color={Colors.NEUTRAL_700}>Hey, {user.first_name}</H2>
                    <BodyRegular color={Colors.NEUTRAL_600}>
                        Let's close some transactions together, shall we?
                    </BodyRegular>
                </Stack>
                <HomePointsBanner />
            </PageSection>
            <PageSection gap="1.6rem" bgcolor={Colors.PRIMARY_100} direction={lessThanLg ? "column" : "row"}>
                <SubtitleRegular flex={1} minWidth="15rem">
                    What would you like to do today?
                </SubtitleRegular>
                <Stack
                    minWidth={isMobile ? "100%" : "1rem"}
                    maxWidth="100%"
                    gap="1.6rem"
                    direction={isMobile ? "column" : "row"}
                >
                    <HomeAction Icon={BuildingIcon} to="/dashboard/deals" label="See my transactions" count={user.total_deals} />
                    <HomeAction Icon={PlusIcon} to="/dashboard/new-client" label="Add new transaction" />
                    <HomeAction Icon={BankIcon} to="/dashboard/quote" label="Closing cost estimator" />
                </Stack>
            </PageSection>
            <Stack gap="2.4rem" direction={isMobile ? "column" : "row"}>
                <HomeNews />
                <HomePerks />
            </Stack>
        </PageContainer>
    );
};

export default Home;
