type mobileSignerType = {
  first_name: string;
  middle_name: string | null;
  last_name: string;
};
export type setMobileSignersParameterType =
  | (mobileSignerType & {
      value: string;
    })
  | null;
export const mobileSignersSetter = (
  staff: {["Mobile Signer"]: mobileSignerType[]},
  setMobileSigners: (signers: setMobileSignersParameterType) => void,
) => {
  staff["Mobile Signer"] !== null && staff["Mobile Signer"] !== undefined
    ? //@ts-ignore
      setMobileSigners(() => {
        return staff["Mobile Signer"].map((person) => {
          return {
            ...person,
            value:
              person.middle_name !== null
                ? `${person.first_name} ${person.middle_name} ${person.last_name}`
                : `${person.first_name} ${person.last_name}`,
          };
        });
      })
    : setMobileSigners(null);
};
