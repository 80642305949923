import { FC } from "react";
import {useDispatch} from "react-redux";
import {Box, MenuItem, SelectChangeEvent} from "@mui/material";
import {styled} from "@mui/material/styles";
import {useFormik} from "formik";
import moment from "moment";
import * as yup from "yup";
import DeededLabel from "v2/components/DeededLabel";
import DeededDatePicker from "v2/components/DeededDatePicker";
import DeededButton from "v2/components/DeededButton";
import DeededSelectV2Transparent from "v2/components/DeededSelect";
import DeededPopup from "v2/components/DeededPopup";
import {downloadReportsTC} from "redux/reducers/configReducer/emailTemplateReducer";
interface DownloadReportsPopupProps {
  openDownloadRangePopup: boolean;
  setOpenDownloadRangePopup: (openDownloadRangePopup: boolean) => void;
  currIndex: number;
  setIsLoading: (isLoading: boolean) => void;
}

const DownloadReportsPopup: FC<DownloadReportsPopupProps> = ({
  openDownloadRangePopup,
  setOpenDownloadRangePopup,
  setIsLoading,
}) => {
  const dispatch = useDispatch();
  const type =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  const fileName = `${moment(new Date()).format("MM_DD_YYYY")}.xlsx`;
  const initialValues = {
    exportReportType: "day",
    role: "All Roles",
    dateStart: moment(new Date()).format("MM/DD/YYYY"),
    dateEnd: moment(new Date()).format("MM/DD/YYYY"),
  };
  const onSubmit = () => {
    const role = values.role === "All Roles" ? "" : values.role;
    const period = values.exportReportType;
    const date_from = moment(values.dateStart, "MM/DD/YYYY").format("M/D/YYYY");
    const date_to = moment(values.dateEnd, "MM/DD/YYYY").format("M/D/YYYY");

    let params;
    if (period === "other") {
      params = {period, date_from, date_to, role};
    } else {
      params = {period, role};
    }
    dispatch(downloadReportsTC(`/config/export-deals`, type, fileName, setIsLoading, params));
    setOpenDownloadRangePopup(false);
  };
  const schema = yup.object().shape({
    role: yup.string().required(),
    exportReportType: yup.string().required(),
    dateStart: yup.date().required(),
    dateEnd: yup
      .date()
      .required()
      .test("dateEnd", " ", function (dateEnd) {
        if (this.parent.exportReportType === "other") {
          return dateEnd! >= this.parent.dateStart;
        } else {
          return true;
        }
      }),
  });

  const formik = useFormik({
    validationSchema: schema,
    initialValues,
    onSubmit,
    validateOnChange: false,
    validateOnBlur: false,
  });
  const {handleSubmit, values, errors} = formik;
  const handleFormikChange = formik.handleChange;

  return (
    <DeededPopup
      sx={{}}
      open={openDownloadRangePopup}
      title="Download Client Report"
      setOpen={setOpenDownloadRangePopup}
      hideBtnClose={true}
      sxInternalBox={{
        width: "430px",
        overflowX: "hidden",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
          width: "5px",
          scrollbarWidth: "thin",
        },
      }}
    >
      <DeededLabelSpaced
        onClick={(e) => {
          e.stopPropagation();
        }}
        sx={{
          width: "100%",
          marginTop: "27px",
          position: "relative",
        }}
        title="Choose role"
      >
        <SelectReportRange
          value={values.role}
          onChange={(e?: SelectChangeEvent<unknown>) => {
            if (e) {
              handleFormikChange({
                target: {
                  name: "role",
                  value: e.target.value,
                },
              });
            }
          }}
        >
          <MenuItem value="All Roles">All Roles</MenuItem>
          <MenuItem value="Lawyer">Lawyer</MenuItem>
          <MenuItem value="Law Clerk">Law Clerk</MenuItem>
          <MenuItem value="Company Admin">Company Admin</MenuItem>
          <MenuItem value="Admin">Admin</MenuItem>
          <MenuItem value="System Admin">System Admin</MenuItem>
          <MenuItem value="Broker">Broker</MenuItem>
          <MenuItem value="Realtor">Realtor</MenuItem>
          <MenuItem value="Referring Professional">
            Referring Professional
          </MenuItem>
        </SelectReportRange>
      </DeededLabelSpaced>

      <DeededLabelSpaced
        onClick={(e) => {
          e.stopPropagation();
        }}
        sx={{
          width: "100%",
          marginTop: "15px",
          position: "relative",
        }}
        title="Choose Period"
      >
        <SelectReportRange
          value={values.exportReportType}
          onChange={(event?: SelectChangeEvent<unknown>) => {
            if (event) {
              handleFormikChange({
                target: {
                  name: "exportReportType",
                  value: event.target.value,
                },
              });
            }
          }}
        >
          <MenuItem value="day">Day</MenuItem>
          <MenuItem value="week">Week</MenuItem>
          <MenuItem value="month">Month</MenuItem>
          <MenuItem value="year">Year</MenuItem>
          <MenuItem value="other">Other</MenuItem>
        </SelectReportRange>
      </DeededLabelSpaced>
      {values.exportReportType === "other" && (
        <>
          <DownloadReportDateRangeBox>
            <Box>
              <DeededLabel title="Start Date" />
              <DeededDatePicker
                fieldWidth={175}
                hasError={errors.dateStart as string}
                dateStart={new Date(values.dateStart)}
                onChangeEvent={handleFormikChange}
                name="dateStart"
              />
            </Box>
            <Box>
              <DeededLabel title="End Date" />
              <DeededDatePicker
                fieldWidth={175}
                hasError={errors.dateEnd as string}
                dateStart={new Date(values.dateEnd)}
                maxDate={new Date()}
                onChangeEvent={handleFormikChange}
                name="dateEnd"
              />
            </Box>
          </DownloadReportDateRangeBox>
        </>
      )}
      <DownloadReportButtonsBox>
        <DownloadReportDownloadButton
          type="submit"
          onClick={() => {
            handleSubmit();
          }}
        >
          Download
        </DownloadReportDownloadButton>
        <DownloadReportCancelBtn
          kind="secondary"
          type="button"
          onClick={() => {
            setOpenDownloadRangePopup(false);
          }}
        >
          Cancel
        </DownloadReportCancelBtn>
      </DownloadReportButtonsBox>
    </DeededPopup>
  );
};
const DownloadReportDateRangeBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  marginTop: "10px",
});
const DownloadReportDownloadButton = styled(DeededButton)({
  width: "216px",
  height: "40px",
});
const DownloadReportCancelBtn = styled(DeededButton)({
  width: "100px",
  height: "40px",
  marginRight: "16px",
});
const DownloadReportButtonsBox = styled(Box)({
  display: "flex",
  flexDirection: "row-reverse",
  marginTop: "35px",
});
const SelectReportRange = styled(DeededSelectV2Transparent)({
  width: "100% !important",
  height: "44px !important",
});

const DeededLabelSpaced = styled(DeededLabel)({
  marginBottom: "20px",
});

export default DownloadReportsPopup;
