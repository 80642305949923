import { ChangeEvent } from "react";
import { AxiosResponse } from "axios";
import * as yup from "yup";
import { FormikHelpers, useFormik } from "formik";
import { useSnackbar } from "notistack";
import { updateDealClientData } from "api";
import configuration from "utils/configuration";
import formatDealNumber from "utils/formatDealNumber";
import { AlertTypes } from "types/alertTypes";
import { Deal } from "types/deal";
import { H3 } from "components/CommonDashboard/Typography";
import {AddressAutocompleteResult} from "components/CommonDashboard/AddressAutocompleteField";

interface FormValues {
    address: string;
    unit: string;
    city: string;
    state: string;
    code: string;
    closing_date: string;
    deal_number: string;
}

const schema = yup.object().shape({
    address: yup.string(),
    unit: yup.string(),
    city: yup.string(),
    code: yup.string(),
    state: yup.string().required(),
    closing_date: yup.string().required("Closing date is required"),
    deal_number: yup.string().required("Deal number is required"),
});

const useDealInfoForm = (deal: Deal, saveCallback?: () => void) => {
    const { enqueueSnackbar } = useSnackbar();

    const onSubmit = async (values: FormValues, helpers: FormikHelpers<FormValues>) => {
        const payload = {
            deal_type: deal.deal_type,
            closing_date: new Date(values.closing_date).toLocaleDateString("en-US"),
            deal_number: values.deal_number,
            address: {
                address: values.address,
                unit: values.unit,
                city: values.city,
                state: values.state,
                code: values.code,
            },
        };

        try {
            await updateDealClientData(deal.id, payload);
            enqueueSnackbar("Property details update", {
                variant: AlertTypes.Success,
                autoHideDuration: 1500,
            });
            saveCallback && saveCallback();
        } catch (e: any) {
            const error = e as { response: AxiosResponse<{ error?: string }> };
            enqueueSnackbar(error.response.data.error ?? "Couldn't update.", {
                variant: AlertTypes.Error,
                autoHideDuration: configuration.autoHideErrorDuration,
            });
        }
    };

    const { handleSubmit, isSubmitting, values, errors, handleChange, setValues, handleBlur, resetForm } =
        useFormik<FormValues>({
            validationSchema: schema,
            initialValues: {
                address: deal.address.address ?? "",
                unit: (deal.address.unit ?? "") as string,
                city: deal.address.city ?? "",
                state: deal.address.state ?? "",
                code: deal.address.code ?? "",
                closing_date: deal.closing_date ?? "",
                deal_number: deal.deal_number ?? "",
            },
            onSubmit,
            validateOnChange: false,
            validateOnBlur: false,
        });

    const handleAddressAutocomplete = (address: AddressAutocompleteResult) => {
        return void setValues({ ...values, ...address }, true);
    }

    const controlFor = (name: keyof FormValues) => ({
        name,
        value: values[name] as string,
        onChange: (e: ChangeEvent<any>) => {
            if (e.target.name === "deal_number") {
                values.deal_number = formatDealNumber(e.target.value ?? "");
                return void setValues({ ...values }, true);
            }
            handleChange(e);
        },
        onBlur: handleBlur,
        error: errors[name],
        disabled: isSubmitting,
        outlined: true,
        LabelComponent: H3,
        fullWidth: true,
        ...(name === 'address' ? {onSelectAddress: handleAddressAutocomplete} : {})
    });

    
    return { values, controlFor, handleSubmit, isSubmitting, reset: resetForm } as const;
};

export default useDealInfoForm;
