export const changeDateEffect = (
  date: Date,
  getBlocked: (
    date:Date,
    isAllProvinciesChecked: boolean,
    calendarTimezone: string,
    view:"day"|"week"|"month"
  ) => void,
  isAllProvinciesChecked: boolean,
  regions: string[] | null,
  calendarTimezone: string,
  view:"day"|"week"|"month"
) => {
  const t = setTimeout(() => {
    getBlocked(
      date,
      (isAllProvinciesChecked ? "ALL" : regions) as unknown as boolean,
      calendarTimezone,
      view
    );
  }, 500);
  return () => {
    clearTimeout(t);
  };
};

