import {Deal} from "types/deal";
import useTypedSelector from "./useTypedSelector";

const useDealSelector = () => {
  const deal = useTypedSelector((state) => state.DealsReducer.deal_info_by_id);
  if (Object.keys(deal).length === 0) {
    return null;
  }
  return deal as Deal;
};

export default useDealSelector;
