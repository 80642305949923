import {
  BigCalendarBlockTimeBlockErrorsType,
  BigCalendarBlockTimeBlockValuesType,
  HandleChangeType,
  StylesObjectFieldsType,
} from "../types/bigCalendarBlockTimeTypes";
import React from "react";
import {useAppSelector} from "redux/hooks";
import moment from "moment";
import {Box, styled, useTheme} from "@mui/material";
import DeededTimePicker from "v2/components/DeededTimePicker";
import DeededLabel from "v2/components/DeededLabel";
import DeededDatePicker from "v2/components/DeededDatePicker";
import DeededSpace from "v2/components/DeededSpace";
import {Theme} from "@mui/material/styles";
import useWindowWidth from "utils/hooks/useWindowWidth";

interface BigCalendarBlockTimeDateFieldsProps {
  styles: StylesObjectFieldsType;
  values: BigCalendarBlockTimeBlockValuesType;
  handleChange: HandleChangeType;
  errors: BigCalendarBlockTimeBlockErrorsType;
}
const BigCalendarBlockTimeDateFields: React.FC<
  BigCalendarBlockTimeDateFieldsProps
> = ({styles, values, handleChange, errors}) => {
  const createEventForCurrentDateByDefault = useAppSelector(
    (state) => state.CalendarReducer.createEventForCurrentDateByDefault,
  );
  const windowWidth = useWindowWidth()
  const theme = useTheme();
  const { sm } = theme.breakpoints.values;
  const setMinTime = () => {
    if (values.dateStart === values.dateEnd) {
      return moment(values.startTime).toDate();
    } else {
      return moment("00:00:00", "hh:mm:ss").toDate();
    }
  };
  const minTime = setMinTime();
  return (
    <>
      {createEventForCurrentDateByDefault ? (
        <Box sx={styles.FormItem}>
          <Box
            sx={{
              display: "flex",
              maxWidth: "169px",
              [theme.breakpoints.down("sm")]: {
                flexDirection: "column",
                maxWidth: "unset",
              },
            }}
          >
            <ExactDateTimeFormLabel
              sx={{
                ...styles.FormLabel,
                marginRight: "24px",
                [theme.breakpoints.down("sm")]: {
                  marginBottom: "16px",
                },
              }}
            >
              <Box sx={styles.FormLabelText}>Start Date/Time</Box>
              <DeededTimePicker
                data-selenium-id="start-date-time-picker"
                disabled={values.blackAllDay}
                timeStart={values.startTime ? moment(values.startTime).toDate() : null}
                dateFormat="MMMM dd, Y h:mm aa"
                onChangeEvent={handleChange as (value: unknown) => void}
                name="startTime"
                sx={{
                  "& .MuiOutlinedInput-root.MuiInputBase-root": {
                    width: "183px !important",
                    input: {
                      fontSize: "11px",
                    },
                  },
                }}
              />
              {errors.startTime && (
                <Box sx={styles.Error}>{errors.startTime}</Box>
              )}
            </ExactDateTimeFormLabel>
            <ExactDateTimeFormLabel sx={styles.FormLabel}>
              <Box sx={styles.FormLabelText}>End Date/Time</Box>
              <DeededTimePicker
                data-selenium-id="end-date-time-picker"
                maxTime={moment("23:59:59", "hh:mm:ss").toDate()}
                minTime={minTime as Date | undefined}
                disabled={values.blackAllDay}
                dateFormat="MMMM dd, Y h:mm aa"
                timeStart={values.endTime ? moment(values.endTime).toDate() : null}
                onChangeEvent={handleChange as (value: unknown) => void}
                name="endTime"
                sx={{
                  ".MuiOutlinedInput-root.MuiInputBase-root": {
                    width: "183px !important",
                    input: {
                      fontSize: "11px",
                    },
                  },
                }}
              />
              {errors.endTime && <Box sx={styles.Error}>{errors.endTime}</Box>}
            </ExactDateTimeFormLabel>
          </Box>
        </Box>
      ) : (
        <>
          {" "}
          <AnyDateFieldsBox>
            <AnyDateFiledBox>
              <DeededLabel title={"Start Date"} />
              <DeededDatePicker
                data-selenium-id="start-date-picker"
                dateStart={values.dateStart ? moment(values.dateStart).toDate() : null}
                onChangeEvent={handleChange as (someToChange: unknown) => void}
                name={"dateStart"}
              />
            </AnyDateFiledBox>
            <DeededSpace x={windowWidth<sm?16:24} />
            <AnyTimeFieldBox>
              <DeededLabel title={"Start Time"} />
              <DeededTimePicker
                data-selenium-id="start-time-picker"
                disabled={values.blackAllDay}
                timeStart={values.startTime ? moment(values.startTime).toDate() : null}
                onChangeEvent={handleChange as (someToChange: unknown) => void}
                name={"startTime"}
              />
            </AnyTimeFieldBox>
          </AnyDateFieldsBox>
          <>
            <DeededSpace y={18} />
            <AnyDateFieldsBox>
              <AnyDateFiledBox>
                <DeededLabel title="End Date" />
                <DeededDatePicker
                  data-selenium-id="end-date-picker"
                  minDate={moment(values.dateStart, "M/D/YYYY").toDate()}
                  hasError={errors.dateEnd}
                  dateStart={values.dateEnd ? moment(values.dateEnd).toDate() : null}
                  name="dateEnd"
                  onChangeEvent={handleChange as (value: unknown) => void}
                />
              </AnyDateFiledBox>
              <DeededSpace x={windowWidth<sm?16:24} />
              <AnyTimeFieldBox>
                <DeededLabel title={"End Time"} />
                <DeededTimePicker
                  data-selenium-id="end-time-picker"
                  maxTime={moment("23:59:59", "hh:mm:ss").toDate()}
                  minTime={minTime as Date | undefined}
                  disabled={values.blackAllDay}
                  error={errors.endTime}
                  timeStart={values.endTime ? moment(values.endTime).toDate() : null}
                  onChangeEvent={handleChange as (value: unknown) => void}
                  name={"endTime"}
                />
              </AnyTimeFieldBox>
            </AnyDateFieldsBox>
          </>
          <DeededSpace y={24} />
        </>
      )}
    </>
  );
};
const FormLabel = styled("label")({});
const ExactDateTimeFormLabel = styled(FormLabel)<{theme?: Theme}>(
  ({theme}) => ({
    [theme.breakpoints.down("sm")]: {
      marginRight: "0px",
    },
  }),
);
const AnyTimeFieldBox = styled(Box)<{theme?: Theme}>(({theme}) => ({
  [theme.breakpoints.down("sm")]: {
    width:"39%"
  },
  "& .MuiBox-root": {
    [theme.breakpoints.down("sm")]: {
      "& .MuiFormControl-root": {
        "& .MuiOutlinedInput-root": {
          height: "36px !important",
        },
      },
      width: "100%",
    },
  },
}));
const AnyDateFiledBox = styled(Box)<{theme?: Theme}>(({theme}) => ({
  [theme.breakpoints.down("sm")]: {
    width:"59%",
    ".MuiBox-root":{
      height:"36px"
    },
    "& .MuiFormControl-root": {
      width: "100%",
      marginBottom: "16px",
      "& .MuiOutlinedInput-root": {
        height: "36px !important",
        "& .MuiInputAdornment-root .MuiButtonBase-root": {
          top: "-2px",
          "&:hover": {
            backgroundColor: "transparent",
          },
          "&:active": {
            backgroundColor: "transparent",
          },
          "&:focus": {
            backgroundColor: "transparent",
          },
        },
      },
    },
  },
}));
const AnyDateFieldsBox = styled(Box)<{theme?: Theme}>(({theme}) => ({
  display: "flex",
  width:"100%",

}));

export default BigCalendarBlockTimeDateFields;
