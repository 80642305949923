import React, {SetStateAction, useEffect, useMemo, useState} from "react";
import {useDispatch} from "react-redux";
import {
  Box,
  MenuItem,
  Pagination,
  styled,
  SxProps,
  Typography,
} from "@mui/material";
import {Theme, useTheme} from "@mui/material/styles";
import useTypedSelector from "utils/hooks/useTypedSelector";
import useWindowWidth from "utils/hooks/useWindowWidth";
import useUser from "utils/hooks/useUser";
import {Staff} from "types/Config/userManagerTypes";
import {
  getDataByRole,
  GetDataByRoleItems,
  setRole,
} from "redux/reducers/configReducer/userManagerReducer";
import constants from "styles/constants";
import ExportRecordsSvg from "components/Svg/ExportRecordsSvg";
import DeededButton from "v2/components/DeededButton";
import DeededSelectV2Transparent from "v2/components/DeededSelect";
import DeededCheckbox from "v2/components/DeededCheckbox";
import DeededPopup from "v2/components/DeededPopup";
import {phoneConfig} from "../../constants/breakpoints";
import UserMangerSearchField from "./UserMangerSearchField";
import UserManagerList from "./UserManagerList";
import UserPopups from "./UserPopups";

const roles = {
  staff: "staff",
  client: "clients",
  professionals: "professionals",
};

const UserManager: React.FC<{
  handleExportBtnClick: (e: unknown & {preventDefault: () => void}) => void;
  setCurrIndex: React.Dispatch<SetStateAction<number>>;
  isLoading: boolean;
  isShowDeleted?: boolean;
  setIsShowDeleted: (isShowDeleted?: boolean) => void;
}> = ({
  handleExportBtnClick,
  setCurrIndex,
  isLoading,
  isShowDeleted,
  setIsShowDeleted,
}) => {
  const user = useUser().user;
  const defaultRole = (user.role === 'Admin') ? roles.professionals.toLocaleLowerCase(): roles.staff.toLocaleLowerCase()
  const [searchVal, setSearchVal] = useState("");
  const [searchData, setSearchData] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState<Staff>({} as Staff);
  const [orderby, setOrderby] = useState<string | null>(null);
  const [order, setOrder] = useState<string | null>(null);
  const [rolesFilter, setRolesFilter] = useState<string[] | null>(null);
  const [companiesFilter, setCompaniesFilter] = useState<string[] | null>(null);
  const [type, setTypeRole] = useState(defaultRole);
  const dispatch = useDispatch();
  const theme = useTheme();
  const staffData = useTypedSelector(
    (state) => state.ConfigReducer.userManagerReducer.staff,
  );
  const windowWidth = useWindowWidth();
  const role = useTypedSelector(
    (state) => state.ConfigReducer.userManagerReducer.role,
  );
  const {current_page, last_page} = useTypedSelector(
    (state) => state.ConfigReducer.userManagerReducer.paginationInfo,
  );

  const handleCreateObjDataByRole = useMemo(
    () =>
      ({type, page, search}: {type: string; page: number; search?: string}) => {
        let obj: GetDataByRoleItems = {
          type,
          page,
        };
        if (order) {
          obj = {...obj, order};
        }
        if (orderby) {
          obj = {...obj, orderby};
        }
        if (rolesFilter && rolesFilter.length > 0) {
          obj = {...obj, role: rolesFilter.join(",")};
        }
        if (companiesFilter && companiesFilter.length > 0) {
          obj = {...obj, company: companiesFilter.join(",")};
        }
        if (search) {
          obj = {...obj, search};
        }
        if (isShowDeleted) {
          obj = {...obj, deleted: isShowDeleted};
        }

        return obj;
      },
    [order, orderby, rolesFilter, companiesFilter, isShowDeleted],
  );

  useEffect(() => {
    dispatch(setRole(type));
  }, [dispatch]);

  const handleSetRole = (
    selectedTemplate: PointerEvent & {target: {value: string}},
  ) => {
    setTypeRole(selectedTemplate.target.value.toLocaleLowerCase());
    setSearchVal("");
    setSearchData("");

    if (selectedTemplate.target.value === "professionals") {
      setCurrIndex(2);
      setResetPage(true);
    } else if (selectedTemplate.target.value === "clients") {
      setCurrIndex(1);
      setResetPage(true);
      setRolesFilter(null);
      setCompaniesFilter(null);
    } else {
      setCurrIndex(0);
      setResetPage(true);
      setRolesFilter(null);
      setCompaniesFilter(null);
    }

    closePopup();
  };

  const handleSearch = (val: string) => {
    setSearchVal(val);
  };

  useEffect(() => {
    const t = setTimeout(() => {
      setSearchData(searchVal);
    }, 300);
    return () => {
      clearTimeout(t);
    };
  }, [searchVal]);

  useEffect(() => {
    dispatch(
      getDataByRole(
        handleCreateObjDataByRole({
          type,
          page: 1,
          search: searchData,
        }),
      ),
    );
  }, [dispatch, handleCreateObjDataByRole, searchData, type]);

  const openPopup = (): void => {
    setIsPopupOpen(true);
    document.body.style.overflow = "hidden";
    document.body.style.height = "100vh";
  };

  const closePopup = useMemo(
    () => () => {
      setIsPopupOpen(false);
      document.body.style.overflow = "inherit";
      document.body.style.height = "auto";
      setCurrentUser({} as SetStateAction<Staff>);
    },
    [],
  );

  const getUserToEdit = (user: Staff) => {
    setIsPopupOpen(false);
    setCurrentUser(user);
    setTimeout(() => {
      setIsPopupOpen(true);
      document.body.style.overflow = "hidden";
      document.body.style.height = "100vh";
    });
  };
  const userManagerResultLoading = useTypedSelector(
    (state) => state.ConfigReducer.userManagerReducer.configLoader,
  );

  const [resetPage, setResetPage] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    if (staffData) {
      if (resetPage) {
        setCurrentPage(1);
        setResetPage(false);
      }
    }
  }, [current_page, last_page, resetPage, staffData]);

  useEffect(() => {
    const close = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        closePopup();
      }
    };
    window.addEventListener("keydown", close);

    return () => window.removeEventListener("keydown", close);
  }, [closePopup]);

  const title = role === "staff" ? "Add New Staff" : "Add New Professionals";
  const isUser = (user: Staff) => user.hasOwnProperty("id");

  return (
    <>
      <HeaderBox>
        <HeaderBoxInnerLeft>
          <DeededSelectV2Transparent
            onChange={handleSetRole as (e?: unknown) => void}
            value={role}
            width="176px"
            height={"40px"}
            sx={{
              height: "40px",
              backgroundColor: `${constants.colors.white} !important`,
              [theme.breakpoints.down("lg")]: {
                display: "none",
              },
            }}
          data-testid="role-types-drop-down"
          >
            {Object.values(roles).filter(el => (user.role === 'Admin' && el === 'professionals') || user.role === 'System Admin').map((el) => (
              <MenuItem key={el} value={el}>
                {el}
              </MenuItem>
            ))}
          </DeededSelectV2Transparent>
          {isShowDeleted && (
            <TextOnlyForDeletedUsers>
              Only Deleted Users Are Shown
            </TextOnlyForDeletedUsers>
          )}
        </HeaderBoxInnerLeft>

        <HeaderBoxInnerRight role={role}>
          {role === "clients" && windowWidth >= theme.breakpoints.values.lg && (
            <ShowDeletedUsersCheckbox
              setIsShowDeleted={setIsShowDeleted}
              isShowDeleted={isShowDeleted}
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                marginRight: "20px",
              }}
            />
          )}
          <UserMangerSearchField
            value={searchVal}
            onChange={handleSearch}
            sx={{
              [theme.breakpoints.down("lg")]: {
                height: "44px",
                input: {
                  fontSize: "14px",
                },
              },
            }}
          />
          <DeededSelectV2Transparent
            onChange={handleSetRole as (e?: unknown) => void}
            value={role}
            width="176px"
            sxMenu={{
              "& .MuiPaper-root.MuiPaper-elevation": {
                [theme.breakpoints.down("lg")]: {
                  width: "50%",
                  minWidth: "unset !important",
                },
                [theme.breakpoints.down(phoneConfig)]: {
                  width: "150px",
                  minWidth: "unset !important",
                },
              },
            }}
            sx={{
              backgroundColor: `${constants.colors.white} !important`,
              display: "none",
              "& .deededSelectV2IconSvg": {
                top: "18px",
              },
              [theme.breakpoints.down("lg")]: {
                display: "block",
                "& > div": {
                  textTransform: "capitalize",
                },
              },
            }}
          >
            {Object.values(roles).map((el) => (
              <MenuItem key={el} value={el}>
                {el}
              </MenuItem>
            ))}
          </DeededSelectV2Transparent>

          {role === "clients" && windowWidth < theme.breakpoints.values.lg && (
            <Box
              sx={{
                height: "10px",
              }}
            >
              <ShowDeletedUsersCheckbox
                setIsShowDeleted={setIsShowDeleted}
                isShowDeleted={isShowDeleted}
                sx={{
                  width: "100%",
                }}
              />
            </Box>
          )}
          {role === "staff" || role === "professionals" ? (
            <>
              <Box
                sx={{
                  marginLeft: "20px",
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                  [theme.breakpoints.down("lg")]: {
                    marginBottom: "40px !important",
                  },
                }}
              >
                <DeededButton
                  data-testid="add-new-staff-or-professionals-button"
                  sx={{
                    height: "38px",
                  }}
                  onClick={openPopup}
                  kind="primary"
                  type="button"
                >
                  {title}
                </DeededButton>
                <ShowDeletedUsersCheckbox
                  isShowDeleted={isShowDeleted}
                  setIsShowDeleted={setIsShowDeleted}
                />
              </Box>
              <ConfigBtn
                sx={{
                  height: "38px",
                }}
                onClick={handleExportBtnClick}
                kind="secondary"
                type="button"
              >
                <ExportRecordsSvg className="ExportRecordsSvg" />
                <Typography
                  sx={{
                    marginTop: "3px",
                    fontSize: "14px",
                    fontWeight: "600",
                    marginLeft: "5px",
                  }}
                >
                  {isLoading ? "Loading..." : "export reports"}
                </Typography>
              </ConfigBtn>
            </>
          ) : null}
        </HeaderBoxInnerRight>
      </HeaderBox>
      <>
        <ResultsHolder role={role}>
          <UserManagerList
            data={staffData}
            handleUser={getUserToEdit}
            role={role}
            setOrderby={setOrderby}
            setOrder={setOrder}
            type={type}
            setTypeRole={setTypeRole}
            setRolesFilter={setRolesFilter}
            rolesFilter={rolesFilter}
            companiesFilter={companiesFilter}
            setCompaniesFilter={setCompaniesFilter}
            searchVal={searchVal}
          />
        </ResultsHolder>

        {last_page > 1 && !userManagerResultLoading && (
          <UserManagerPagination
            count={last_page}
            variant="outlined"
            shape="rounded"
            page={currentPage}
            onChange={(_, val) => {
              if (!userManagerResultLoading) {
                dispatch(
                  getDataByRole(
                    handleCreateObjDataByRole({
                      type: role,
                      page: val,
                      search: searchData,
                    }),
                  ),
                );

                setCurrentPage(val);
              }
            }}
          />
        )}
      </>
      {isPopupOpen && (
        <DeededPopup
          sx={{}}
          open={isPopupOpen}
          setOpen={closePopup}
          title={
            isUser(currentUser)
              ? `${!currentUser.deleted_at ? "Edit" : "Restore"} ${role}`
              : `Add ${role}`
          }
          sxInternalBox={{
            width: "532px",
            overflowX: "hidden",
            overflowY: "auto",
            borderRadius: "10px",

            "&::-webkit-scrollbar": {
              width: "5px",
              scrollbarWidth: "thin",
            },
          }}
        >
          <UserPopups
            role={role}
            user={currentUser}
            closeHandler={closePopup}
            setResetPage={setResetPage}
          />
        </DeededPopup>
      )}
    </>
  );
};
const ShowDeletedUsersCheckbox: React.FC<{
  setIsShowDeleted: (isShowDeleted: boolean) => void;
  sx?: SxProps<Theme>;
  isShowDeleted?: boolean;
}> = ({setIsShowDeleted, sx, isShowDeleted}) => {
  return (
    <>
      <DeededCheckbox
        checked={isShowDeleted}
        onChange={(e) => {
          const event = e as unknown as {target: {checked: boolean}};
          setIsShowDeleted(event.target.checked);
        }}
        sx={{
          "& .MuiCheckbox-root": {
            "&:hover": {
              backgroundColor: "transparent !important",
            },
          },
          "&:not(.Mui-disabled):hover": {
            "& .MuiCheckbox-root": {
              transition: constants.transitions,
              background: "transparent !important",
            },
          },
          ...sx,
        }}
        label={"Show Deleted Users"}
      />
    </>
  );
};
const TextOnlyForDeletedUsers = styled(Box)(({theme}) => ({
  margin: "0 10px",
  color: constants.colors.red,
  [theme.breakpoints.down(1330)]: {
    margin: "10px 0",
  },
}));

const HeaderBox = styled(Box)(({theme}) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "start",
  [theme.breakpoints.down("lg")]: {
    flexWrap: "wrap",
    ".MuiBox-root": {
      width: "100%",
    },
    ">div>div>div": {
      width: "100% !important",
    },
  },
}));

const HeaderBoxInnerRight = styled(Box)<{
  role: string;
}>(({theme, role}) => ({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: role === "clients" ? "center" : "top",
  [theme.breakpoints.down("lg")]: {
    flexWrap: "wrap",
    ".MuiBox-root": {
      width: "100%",
    },
    ">div:first-of-type": {
      marginBottom: "24px",
    },
    ">div": {
      marginBottom: "16px",
      marginLeft: 0,
    },
    button: {
      width: "100%",
      marginLeft: 0,
    },
  },
}));

const HeaderBoxInnerLeft = styled(Box)(({theme}) => ({
  display: "flex",
  textTransform: "capitalize",
  alignItems: "center",
  input: {
    padding: "12px 14px",
  },
  [theme.breakpoints.down(1330)]: {
    flexWrap: "wrap",
  },
}));

const PopupHolder = styled(Box)({
  maxWidth: "468px",
  width: "100%",
  "&.prof_block": {
    position: "fixed",
    zIndex: 100,
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    width: "100%",
    maxWidth: "100%",
    padding: "5rem 0rem 10rem",
    overflowY: "auto",
  },
  "& .overlay": {
    background: "rgba(0, 0, 0, 0.15)",
    position: "fixed",
    zIndex: 1,
  },
});

const ResultsHolder = styled(Box)<{role: string}>(
  ({theme, role}) => ({
    display: "flex",
    marginTop: "0.5rem",
    padding: "16px 16px 0",
    background: constants.colors.white,
    justifyContent: "space-between",
    [theme.breakpoints.down("lg")]: {
      marginTop: role === "clients" ? "10px" : "30px",
      background: constants.colors.bg,
      padding: "0",
    },
  }),
);

const ConfigBtn = styled(DeededButton)({
  transition: constants.transitions,
  width: "140px",
  "& .ExportRecordsSvg": {
    stroke: constants.colors.red,
    transition: "stroke 250ms",
  },
  "&:hover": {
    transition: constants.transitions,
    "& .ExportRecordsSvg": {
      stroke: constants.colors.white,
      transition: "stroke 250ms",
    },
  },
  minWidth: "175px",
  marginLeft: "16px",
  "& > p": {
    marginTop: 0,
  },
});

const UserManagerPagination = styled(Pagination)(({theme}) => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "64px",
  paddingBottom: "16px",
  li: {
    margin: "0 3px",
  },
  "& .MuiPaginationItem-root": {
    fontSize: "14px",
    fontWeight: 500,
    fontFamily: "Montserrat",
    border: "none",
    padding: "0",
    margin: "0",
    transition: constants.transitions,
    "&:hover": {
      transition: constants.transitions,
    },
  },
  "& .MuiPagination-ul li:first-of-type, .MuiPagination-ul li:last-child": {
    background: "white",
    borderRadius: "5px",
    svg: {
      fontSize: "18px",
    },
  },
  "& .Mui-selected": {
    backgroundColor: `${constants.colors.red} !important`,
    color: "white",
    border: "none",
    transition: constants.transitions,
  },
  "& .Mui-disabled": {
    background: constants.colors.disabled,
    transition: constants.transitions,
  },
  [theme.breakpoints.down("sm")]: {
    " .MuiButtonBase-root": {
      width: "25px",
      height: "25px",
      minWidth: "unset",
    },
  },
  [theme.breakpoints.down("lg")]: {
    li: {
      margin: "0 1px",
    },
  },
}));

export default UserManager;
