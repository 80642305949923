import {Event} from "../components/EventComponent";

export const generateTitleForEvent = (event: Event) => {

  if(event.title === "Blocked"){
    if(event.event.holiday){
      return `Company - Blocked off ${event.event.holiday}`
    }
    else if(event.event.for_user){
      return `${event.event.userOff?.full_name} - ${event.title} off`
    }
    else{
      return `${event.title}`
    }
  }
  else{
    return event.title
  }
};
