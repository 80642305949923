import {useEffect, useState, useMemo, useContext} from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import StorageBreadCrumbSeparator from "../../../Svg/StorageBreadCrumbSeparator";
import StorageDropDownIconSvg from "../../../Svg/StorageDropDownIconSvg";
import {useDispatch, useSelector} from "react-redux";
import {
  clearBreadCrumbsAC,
  clearInteralIdsForDisplayingFoldersAC,
  filterBreadCrumbsAC,
  getFoldersTC,
  setCurrentPage,
  setFolderIdAC,
  clearFoldersAC,
  setFileInfoSectionFalseAC,
} from "../../../../redux/reducers/storageReducer";
import Pagination from "@mui/material/Pagination";
import {makeStyles} from "@mui/styles";
import {Folders} from "..";
import AddNewDocumentPage from "../AddNewDocumentPage/AddNewDocumentPage";
import {Checkbox, Box, useTheme} from "@mui/material";
import {
  deleteCheckedFileAC,
  deleteFoldersFromCheckedFolders,
  setCheckedFilesAC,
  setCheckedFoldersAC,
} from "../../../../redux/reducers/selectedFileMoveStorageReducer";
import back from "../../../../assets/img/back.svg";
import threeDots from "../../../../assets/img/threeDots.png";
import * as storageConfig from "components/Dashboard/StorageCommonComponents/utils/storageConfig";
import {useLocation, Link} from "react-router-dom";
import DeededCircularLoader from "v2/components/DeededCircularLoader";
import { StorageContext } from "../BaseStoragePage";

const Files = ({
  addNewDocument,
  nodealFolderId,
  setAddNewDocument,
  openFolderId,
  clientName,
  nopagination,
}) => {
  let folder = useSelector((state) => state.StorageReducer.folderID);
  let folders = useSelector((state) => state.StorageReducer.folders);
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  let deal;
  if (openFolderId) {
    deal = openFolderId;
  }
  let currentPage = useSelector((state) => state.StorageReducer.currentPage);
  let value = useSelector((state) => state.StorageReducer.searchValue);
  const pagesCount = useSelector((state) => state.StorageReducer.pagesCount);
  const breadCrumbs = useSelector((state) => state.StorageReducer.breadCrumbs);
  const fileInfoSection = useSelector(
    (state) => state.StorageReducer.fileInfoSection,
  );
  const {isShouldShowFileInfoSection} = useContext(StorageContext);
  const checkedFilesAndFolders = useSelector(
    (state) => state.SelectedFileMoveStorageReducer.checkedFilesAndFolders,
  );

  const inFolderCount = useSelector(
    (state) => state.StorageReducer.inFolderCount,
  );
  const folderSize = useSelector((state) => state.StorageReducer.folderSize);
  const idActions = useSelector((state) => state.StorageReducer.idActions);
  const [, setSelectedFirstFolder] = useState(null);
  const allSelectOnPage = useSelector(
    (state) => state.SelectedFileMoveStorageReducer.allSelectOnPage,
  );

  const location = useLocation();
  const storage = location.pathname.includes("storage");

  useEffect(() => {
    dispatch(setFileInfoSectionFalseAC());

    return () => {
      dispatch(clearFoldersAC());
      dispatch(setCurrentPage(1));
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getFoldersTC(
        folder,
        value,
        currentPage,
        nodealFolderId ? undefined : deal ?? undefined,
        nopagination,
      ),
    );
  }, [
    currentPage,deal,
    dispatch,
    folder,
    nodealFolderId,
    nopagination,
    value,
  ]);

  const selectFolder = (folderID) => {
    dispatch(setFolderIdAC(folderID));
    dispatch(
      getFoldersTC(folderID, value, currentPage, undefined, nopagination),
    );
  };
  const getStorageFolders = (inFolder) => {
    dispatch(
      getFoldersTC(inFolder, undefined, undefined, undefined, nopagination),
    );
  };
  const handleBack = () => {
    if (breadCrumbs.length && breadCrumbs.length === 1) {
      if (!clientName) {
        dispatch(clearInteralIdsForDisplayingFoldersAC());
        !clientName
          ? getStorageFolders(clientName ? folder : null)
          : dispatch(getFoldersTC(folder, value, 1, deal, nopagination));
        setSelectedFirstFolder(null);
        dispatch(setFolderIdAC(null));
        dispatch(setCurrentPage(1));
        dispatch(clearBreadCrumbsAC());
      }
    } else {
      dispatch(clearInteralIdsForDisplayingFoldersAC());
      selectFolder(breadCrumbs[breadCrumbs.length - 2].id);
      dispatch(filterBreadCrumbsAC(breadCrumbs.length - 2));
    }
  };

  let loader = useSelector((state) => state.StorageReducer.loader);
  return (
    <>
      <Box
        className={isShouldShowFileInfoSection ? "FilesBox" : "FilesBoxFull"}
      >
        {addNewDocument && (
          <AddNewDocumentPage
            addNewDocument={addNewDocument}
            setAddNewDocument={setAddNewDocument}
            breadCrumbs={breadCrumbs}
          />
        )}
        <Breadcrumbs
          style={{marginLeft: 28, marginTop: 18}}
          separator={<StorageBreadCrumbSeparator />}
          aria-label="breadcrumb"
        >
          {storage && (
            <Link
              className="breadCrumbNormal"
              color="inherit"
              href="#"
              to="#"
              onClick={() => {
                if (!clientName) {
                  dispatch(clearInteralIdsForDisplayingFoldersAC());
                  !clientName
                    ? getStorageFolders(clientName ? folder : null)
                    : dispatch(
                        getFoldersTC(folder, value, 1, deal, nopagination),
                      );
                  setSelectedFirstFolder(null);
                  dispatch(setFolderIdAC(null));
                  dispatch(setCurrentPage(1));
                  dispatch(clearBreadCrumbsAC());
                }
              }}
            >
              {clientName ?? "Storage"}
            </Link>
          )}

          {breadCrumbs.map((b, index) => {
            return (
              <p
                key={b.name + "index"}
                onClick={() => {
                  dispatch(clearInteralIdsForDisplayingFoldersAC());

                  selectFolder(b.id);
                  dispatch(filterBreadCrumbsAC(index));
                }}
                className="breadCrumbsActiveLabel"
                data-testid="storage-active-brad-crumbs-label"
              >
                {b.name}
              </p>
            );
          })}
        </Breadcrumbs>
        <Box
          sx={{
            [theme.breakpoints.down("lg")]: {
              overflowX: "scroll",
              width: "100%",
            },
            "&::-webkit-scrollbar": {
              height: 0,
              width: 0,
            },
          }}
        >
          <Box
            sx={{
              [theme.breakpoints.down("lg")]: {
                minWidth: "1050px",
                width: "1050px",
              },
            }}
            className="filters"
          >
            <div className="filter filterStorage">
              <div className="itemWrapper">
                <div className={`${classes.checkedRoot} icon`}>
                  {checkedFilesAndFolders.length > 0 && (
                    <div className={classes.boldBorderForCheckbox}></div>
                  )}
                  {!storageConfig.hideFileCheckboxes && (
                    <Checkbox
                      checked={allSelectOnPage}
                      onChange={(e) => {
                        e.target.checked
                          ? folders.map((el) => {
                              if (checkedFilesAndFolders.includes(el.id))
                                return;
                              if (el.doc_type === "Directory")
                                dispatch(setCheckedFoldersAC(el.id));
                              else dispatch(setCheckedFilesAC(el.id));
                            })
                          : folders.map((el) => {
                              if (el.doc_type === "Directory")
                                dispatch(
                                  deleteFoldersFromCheckedFolders(el.id),
                                );
                              else dispatch(deleteCheckedFileAC(el.id));
                            });
                      }}
                      inputProps={{"aria-label": "primary checkbox"}}
                    />
                  )}
                </div>
                <p className="filterFiles">Name</p>
                <StorageDropDownIconSvg />
              </div>
            </div>
            <div className="filter filterStorage">
              <div className="itemWrapper">
                <p className="filterFiles">Last Edit</p>
                <StorageDropDownIconSvg />
              </div>
            </div>
            <div className="filter filterStorage">
              <div className="itemWrapper">
                <p className="filterFiles">Members</p>
                <StorageDropDownIconSvg />
              </div>
            </div>
            <div className="filter filterStorage">
              <div className="itemWrapper">
                <p className="filterFiles">Status</p>
                <StorageDropDownIconSvg />
              </div>
            </div>
            <div className="filter filterStorage">
              <div className="itemWrapper">
                <div className="filterFiles">Comments</div>
                <StorageDropDownIconSvg />
              </div>
            </div>
            <div className="filter filterStorage">
              <div className="itemWrapper">
                <p className="filterFiles">Files inside</p>
                <StorageDropDownIconSvg />
              </div>
            </div>
            <div className="filter filterStorage">
              <div className="itemWrapper"></div>
            </div>
          </Box>
          <Box
            sx={{
              [theme.breakpoints.down("lg")]: {
                minWidth: "1050px",
                width: "1050px",
              },
            }}
            className="filtersUnderline"
          ></Box>
          {(!storage ? breadCrumbs.length > 1 : breadCrumbs.length) ? (
            <div
              data-testid="storage-back-button"
              className="backButton"
              onClick={handleBack}
            >
              <div>
                <img src={threeDots} alt="back" />
              </div>
              <div className="backIcon">
                <img src={back} alt="back" />
              </div>
              <span className="filesFileName">Back</span>
            </div>
          ) : null}
          {!loader ? (
            <Folders
              dealId={deal}
              searchValue={value}
              nopagination={nopagination}
              folders={folders}
              folder={folder}
              value={value}
              currentPage={currentPage}
              inFolderCount={inFolderCount}
              selectFolder={selectFolder}
              idActions={idActions}
              dispatch={dispatch}
              folderSize={folderSize}
              setSelectedFirstFolder={setSelectedFirstFolder}
            />
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh",
              }}
            >
              <DeededCircularLoader />
            </Box>
          )}
        </Box>

        {!nopagination && folders.length !== 0 && !loader && (
          <div className={classes.root}>
            <Pagination
              sx={{
                ...classes.pagination,
              }}
              count={pagesCount}
              page={
                currentPage <= pagesCount
                  ? currentPage
                  : dispatch(setCurrentPage(1))
              }
              onChange={(e, page) => {
                dispatch(clearInteralIdsForDisplayingFoldersAC());
                dispatch(setCurrentPage(page));
              }}
            />
          </div>
        )}
      </Box>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  boldBorderForCheckbox: {
    position: "absolute",
    top: 2,
    left: 8,
    border: "3px solid #F16764",
    borderRadius: 3,
    width: 17,
    height: 17,
    zIndex: 1,
  },
  checkedRoot: {
    position: "relative",

    "& .MuiSvgIcon-root": {
      fontSize: "2rem",
      marginLeft: "6px",
    },
    "& .MuiCheckbox-root": {
      padding: 0,
    },
    "& .MuiCheckbox-colorSecondary": {
      color: "#7F7F7F",
      marginLeft: 7,
      marginRight: "8px",
      width: 15,
      height: 15,
      borderRadius: 5,
    },
    "& .Mui-checked": {
      color: "#F16764",
    },
    "& MuiIconButton-label": {},
  },
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .Mui-selected": {
      borderRadius: 4,
      backgroundColor: "#F16764",
      color: "white",
    },
    "& :hover": {
      "& .MuiButtonBase-root": {
        borderRadius: 4,
      },
    },
    "& .MuiButtonBase-root": {
      fontSize: 12,
      fontFamily: "Montserrat",
      fontWeight: 500,
    },

    "& > *": {
      marginTop: theme.spacing(2),
    },
    [theme.breakpoints.down("sm")]: {
      "& .MuiButtonBase-root": {
        width: "25px",
        height: "25px",
        minWidth: "unset",
      },
    },
  },
}));

export default Files;
