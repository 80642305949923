import { CheckCircle, QuestionIcon, XCircle } from 'components/Common/Icons/Iconography';
import { Clock } from 'components/Svg/Icons';
import constants from 'styles/constants';
import { StatusType, DocumentType } from 'types/deal';


export const getFilePillProps = (type: StatusType) => {
    const pillType = {
        Approved: {
            style: {
                backgroundColor: constants.colors.green500,
                color: constants.colors.white,
                width: "fit-content"
            },
            label: "Approved",
            icon: <CheckCircle color={constants.colors.white} />
        },
        Pending: {
            style: {
                backgroundColor: constants.colors.yellow100,
                color: constants.colors.black3,
                width: "fit-content"
            },
            label: "Awaiting Approval",
            icon: <Clock color={constants.colors.black3} size={'20'} />
        },
        Rejected: {
            style: {
                backgroundColor: constants.colors.orange500,
                color: constants.colors.white,
                width: "fit-content"
            },
            label: "Rejected",
            icon: <XCircle color={constants.colors.white} />
        },
        Missing: {
            style: {
                backgroundColor: constants.colors.white,
                color: constants.colors.red500,
                border: `1px solid ${constants.colors.red500}`,
                width: "fit-content"
            },
            label: "Missing",
            icon: <QuestionIcon color={constants.colors.red500} />
        }
    };
    return pillType[type];
}

export const getDocumentName = (document: DocumentType) => (
    document.alias === document.document_type ? (document.original_type || document.alias) : document.alias);

export const getDocumentLatestUpdate = (document: DocumentType) =>
  [...(document.document_updates || [])].sort(
    (a, b) =>
      new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime(),
  ).shift() || null;

export const isDocumentUploadedByClient = (
  document: DocumentType,
): boolean => {
  const latestUpload = [...(document.document_updates || [])]
    .filter((d) => d.action === "Uploaded")
    .sort((a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime())
    .shift() || null;
    return ["Client", "Secondary Client"].includes(latestUpload?.user.role || "");
}

export const getDocumentFormattedSize = (document: DocumentType) => {
    if (!document.original_size) {
        return '0KB';
    }

    const units = [' bytes', 'KB', 'MB', 'GB'];
    let unitAccumulator = 0;
    let n = parseInt(document.original_size, 10) || 0;

    while(n >= 1024 && ++unitAccumulator){
        n = n/1024;
    }

    return(n.toFixed(n < 10 && unitAccumulator > 0 ? 1 : 0) + units[unitAccumulator]);
}
