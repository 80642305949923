import React from "react";
import TextField, {TextFieldProps} from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import {styled, SxProps, Theme} from "@mui/material/styles";
import constants from "../../styles/constants";
import WarningIcon from "../../components/Svg/WarningIcon";

type DeededInputProps = Omit<TextFieldProps, "error"> & {
  error?: string;
  height?: number | string;
  value?: string | number;
  disabled?: boolean;
  errorMessageWithoutIcon?:boolean
  sx?: SxProps<Theme>;
};
const DeededInput: React.FC<DeededInputProps> = React.forwardRef(
  ({error, value, disabled, sx, errorMessageWithoutIcon,...props}, ref) => (
    <>
      <PersonalInfoInput
        {...props}
        ref={ref}
        variant={props.variant! as "outlined"}
        height={props.height! as string}
        hasError={!!error}
        value={value ?? ""}
        disabled={disabled}
        data-selenium-test="DeededInput"
        sx={sx}
      />
      {error && (
        <StyledError className="DeededInputError">
          {!errorMessageWithoutIcon && <WarningIcon />}
          {error}
        </StyledError>
      )}
    </>
  ),
);

const PersonalInfoInput = styled(TextField, {
  shouldForwardProp: (prop) => prop !== "hasError"
})<{
  height?: string;
  width?: string;
  hasError?: boolean;
}>(({hasError, height}) => ({
  "& .MuiInputBase-adornedStart *:first-of-type": {
    flexShrink: 0,
    marginRight: "10px",
  },

  "& .MuiOutlinedInput-root": {
    appearance: "none",
    borderRadius: "5px",
    padding: "0 14px",
    height: height ? height : "44px",
    "& fieldset": {
      borderColor: hasError ? constants.colors.red : constants.colors.grayLight,
      transition: constants.transitions,
      "& legend": {width: 0},
    },

    "&:hover fieldset": {
      appearance: "none",
      borderColor: hasError
        ? constants.colors.red
        : constants.colors.deededGray,
      transition: constants.transitions,
    },
    "&.Mui-disabled ": {
      cursor: "no-drop",
    },
    "&.Mui-disabled fieldset": {
      border: `1px solid ${constants.colors.grayLight}`,
      transition: constants.transitions,
    },
    "&.Mui-focused fieldset": {
      appearance: "none",
      borderColor: constants.colors.red,
      borderWidth: "1px",
      boxShadow: "none",
      transition: constants.transitions,
    },
    "& .MuiInputBase-input": {
      appearance: "none",
      "&::placeholder": {
        fontWeight: 300,
        color: constants.colors.placeholder,
      },
      "&[disabled]": {
        cursor: "no-drop",
      },
      padding: "0",
      ...constants.fontConfig_TEMP,
    },
  },
}));

const StyledError = styled(Typography)({
  color: constants.colors.red,
  ...constants.fontConfig_TEMP,
  marginTop: "5px",
  fontSize: "10px",
  display: "flex",
  alignItems: "center",
  svg: {
    marginRight: "3px",
    marginBottom: "1px",
  },
});

export default DeededInput;
