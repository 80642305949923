export interface CalendarAppointment {
  id: number;
  deal_id: number;
  address: string;
  datetime: Date;
  end_at: Date;
  deleted_at: Date | null;
  timezone: string;
  calendar_id: number;
  user_id: number;
  appointment_updates: AppointmentUpdate[];
  signer: Signer;
}

export interface AppointmentUpdate {
  id: number;
  appointment_id: number;
  appointment_updated_by: AppointmentUpdatedBy;
  appointment_updated_at: Date;
  action: string;
}

export interface AppointmentUpdatedBy {
  full_name: string;
}

export interface Signer {
  id: number;
  name_prefix: string;
  first_name: string;
  last_name: string;
  email: string;
  email_verified_at: Date;
  phone: string;
  role: string;
  communication_method: string;
  show_guides: number;
  brokerage_or_team: null;
  company: null;
  slack_id: null;
  deleted_at: null;
  middle_name: null;
  slug: null;
  timezone: string;
  has_personal_page: number;
  full_name: string;
  total_deals: number;
  userpic: Userpic;
}

export interface Userpic {
  id: number;
  user_id: number;
  original_filename: string;
  type: string;
  storage_id: number;
}

export enum Workhours {
  start = 8,
  end = 21
};
