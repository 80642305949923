import { Box, Stack } from "@mui/material";
import { FC, ReactNode } from "react";
import Colors from "components/CommonDashboard/Styling/Colors";
import { SubtitleRegular } from "components/CommonDashboard/Typography";

const IntegrationDealHeader: FC = () => {
    return (
        <Stack
            minWidth={1300}
            mb="0.8rem"
            px="1.6rem"
            height="6rem"
            borderRadius="2.4rem"
            flexDirection="row"
            alignItems="center"
            gap="1.6rem"
            bgcolor={Colors.NEUTRAL_700}
        >
            <Cell w="22rem">Address</Cell>
            <Cell>Date received</Cell>
            <Cell>Date updated</Cell>
            <Cell>Client</Cell>
            <Cell>Agent</Cell>
            <Cell>Velocity ID</Cell>
            <Cell>Transaction type</Cell>
            <Cell mW="13.5rem" />
        </Stack>
    );
};

const Cell: FC<{ children?: ReactNode; w?: string; mW?: string }> = ({ children, w, mW }) => (
    <Box minWidth={w} maxWidth={mW} flex={1} overflow="hidden">
        <SubtitleRegular noWrap color={Colors.WHITE}>
            {children}
        </SubtitleRegular>
    </Box>
);

export default IntegrationDealHeader;
