const StorageSmallFolderSvg = () => {
  return (
    <svg
      width="26"
      height="22"
      viewBox="0 0 26 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.9995 3.99976H13.4136L9.99951 0.585701C9.81428 0.399366 9.59391 0.251633 9.35119 0.151055C9.10846 0.0504772 8.84819 -0.00094617 8.58545 -0.000236902H1.99951C1.46924 0.000286298 0.960836 0.211168 0.585876 0.586127C0.210917 0.961087 3.49191e-05 1.46949 -0.000488281 1.99976V20.0769C0.000212659 20.5868 0.203157 21.0757 0.563821 21.4361C0.924485 21.7966 1.41341 21.9993 1.92334 21.9998H24.1108C24.6115 21.9991 25.0915 21.7999 25.4456 21.4458C25.7996 21.0918 25.9988 20.6118 25.9995 20.1111V5.99976C25.999 5.46949 25.7881 4.96109 25.4131 4.58613C25.0382 4.21117 24.5298 4.00029 23.9995 3.99976ZM1.99951 1.99976H8.58545L10.5854 3.99976H1.99951V1.99976Z"
        fill="#EB5757"
      />
    </svg>
  );
};

export default StorageSmallFolderSvg;
