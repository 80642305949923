import {useSelector} from "react-redux";
import {RootStore} from "types/flux";

function useTypedSelector<TSelected = unknown>(
  selector: (state: RootStore) => TSelected,
  equalityFn?: (left: TSelected, right: TSelected) => boolean,
): TSelected {
  return useSelector<RootStore, TSelected>(selector, equalityFn);
}

export default useTypedSelector;
