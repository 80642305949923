import React, {FC, useMemo} from "react";
import BackButton from "../../Common/Button/BackButton";
import {NavLink, useRouteMatch} from "react-router-dom";
import styled from "@emotion/styled";
import {Theme} from "@mui/material/styles";
import {DefaultTheme} from "@mui/styles";
import {useTheme} from "@mui/material";
import Stack from "@mui/material/Stack";
import constants from "../../../styles/constants";
import useGetUrlDealsOrLeads from "../../../utils/hooks/useGetUrlDealsOrLeads";
import useTypedSelector from "../../../utils/hooks/useTypedSelector";
import useUser from "../../../utils/hooks/useUser";

interface DetailsTabNavigationProps {
  title?: string;
  hideAllTabs?: boolean;
  showDocumentsTab?: boolean;
  showIntakeTab?: boolean;
  showLogTab?: boolean;
  showSurveyTab?: boolean;
  showTasksTab?: boolean;
  intakeTabLabel?: string;
  showOnlyNavigation?: boolean;
}

const DetailsTabNavigation: FC<DetailsTabNavigationProps> = ({
  title,
  hideAllTabs = false,
  showDocumentsTab = true,
  showIntakeTab = true,
  showLogTab,
  showSurveyTab,
  showTasksTab,
  intakeTabLabel,
  showOnlyNavigation = false
}) => {
  const theme = useTheme();
  const baseRoute = useGetUrlDealsOrLeads();
  const match = useRouteMatch<{id: string}>();
  const dealId = match.params.id;
  const {user} = useUser();
  const currentDealType = useTypedSelector(
    (state) => state.DealsReducer.currentDealDealType,
  );
  const isDefaultRole =
    user.role === "Admin" ||
    user.role === "System Admin" ||
    user.role === "Document Support Specialist";

  // If `showLogTab` not set use default logic
  const _showLogTab = useMemo<boolean>(() => {
    if (showLogTab !== undefined) {
      return showLogTab;
    }
    return isDefaultRole;
  }, [isDefaultRole, showLogTab]);

  // If `showSurveyTab` not set use default logic
  const _showSurveyTab = useMemo<boolean>(() => {
    if (showSurveyTab !== undefined) {
      return showSurveyTab;
    }
    return isDefaultRole;
  }, [isDefaultRole, showSurveyTab]);

  return (
    <NavigationContainer theme={theme} sx={{padding: showOnlyNavigation ? '0rem' : "4rem 2rem 4rem"}}>
      {!showOnlyNavigation && <BackButton />}
      <ContentWrapper>
        {!showOnlyNavigation && <Title theme={theme}>{title || `my ${baseRoute}`}</Title>}

        {hideAllTabs ? null : (
          <TabsContainer theme={theme}>
            <NavLink
              replace
              to={`/dashboard/${baseRoute}/${dealId}?skip_notify_alert=true`}
              data-testid="deal-information-tab"
            >
              Deal Information
            </NavLink>
            {showDocumentsTab && (
              <NavLink
                replace
                to={`/dashboard/${baseRoute}/files/${dealId}`}
                data-testid="file-documents-tab"
              >
                File Documents
              </NavLink>
            )}
            {showIntakeTab && (
              <NavLink
                replace
                to={`/dashboard/${baseRoute}/client-intake/${dealId}`}
                data-testid="client-intake-tab"
              >
                {intakeTabLabel ||
                  (user.role === "Client" ? "Intake Form" : "Client Intake")}
              </NavLink>
            )}
            {_showLogTab && (
              <NavLink
                replace
                to={`/dashboard/${baseRoute}/activity-log/${dealId}`}
                data-testid="activity-log-tab"
              >
                History
              </NavLink>
            )}
            {(currentDealType === "Lender" || showTasksTab) && (
              <NavLink
                replace
                to={`/dashboard/${baseRoute}/file-tasks/${dealId}`}
                data-testid="file-tasks-tab"
              >
                File Tasks
              </NavLink>
            )}
            {_showSurveyTab && (
              <NavLink
                replace
                to={`/dashboard/${baseRoute}/review-survey/${dealId}`}
                data-testid="survey-tab"
              >
                Survey
              </NavLink>
            )}
          </TabsContainer>
        )}
      </ContentWrapper>
    </NavigationContainer>
  );
};

const NavigationContainer = styled(Stack)<{theme: Theme & DefaultTheme}>(
  ({theme}) => ({
    // height: 12.1rem;
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    alignItems: "flex-start",
    // padding: 0 2rem,

    [theme.breakpoints.down("xs")]: {
      height: "8rem",
      padding: "3rem 2rem 4rem",
    },
  }),
);

const Title = styled("div")<{theme: Theme & DefaultTheme}>(({theme}) => ({
  fontStyle: "normal",
  fontWeight: "bold",
  fontSize: "3.2rem",
  lineHeight: "3.9rem",
  textTransform: "uppercase",

  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

const TabsContainer = styled("div")<{theme: Theme & DefaultTheme}>(
  ({theme}) => ({
    whiteSpace: "nowrap",
    overflowX: "auto",
    display: "inherit",
    scrollbarWidth: "none",
    "&::webkit-scrollbar": {
      display: "none",
    },
    a: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "1.6rem",
      lineHeight: "2rem",
      color: "rgba(44, 44, 44, 0.5)",
      paddingBottom: "1rem",
      borderBottom: "2px solid #e2e2e2",
      padding: "0.8rem 1.5rem",
    },
    "a.active": {
      color: "#2c2c2c",
      borderBottom: `4px solid ${constants.colors.red}`,
      [theme.breakpoints.down("xs")]: {
        color: constants.colors.red,
      },
    },
  }),
);

const ContentWrapper = styled("div")({
  width: "100%",
  alignItems: "center",
  display: "flex",
  justifyContent: "space-between",
});

export default DetailsTabNavigation;
