import React, {useState} from "react";
import {Box, styled} from "@mui/material";
import {useDispatch} from "react-redux";
import {deleteUserForUserManagerPage} from "redux/reducers/configReducer/userManagerReducer";
import useTypedSelector from "utils/hooks/useTypedSelector";
import DealsListDeleteModal from "components/Dashboard/DealsList/components/DealsListDeleteModal";
import EditIcon from "components/Svg/EditIcon";
import DeleteIcon from "components/Svg/DeleteIcon";
import constants from "styles/constants";
import RestoreIcon from "components/Svg/RestoreIcon";

const UserManagerEditBlockForItems: React.FC<{
  handleUser: () => void;
  userId: number | undefined;
  isDeleted?: boolean;
}> = ({handleUser, userId, isDeleted}) => {
  const dispatch = useDispatch();
  const role = useTypedSelector(
    (state) => state.ConfigReducer.userManagerReducer.role,
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleDeleteUser = () => {
    dispatch(deleteUserForUserManagerPage(userId, role));
    setIsModalOpen(false);
  };

  return (
    <Wrapper>
      <EditBox onClick={handleUser}>
        {!isDeleted ? <EditIcon /> : <RestoreIcon />}
      </EditBox>

      {!isDeleted && (
        <DeleteBox onClick={() => setIsModalOpen(true)}>
          <DeleteIcon />
        </DeleteBox>
      )}
      {isModalOpen && (
        <DealsListDeleteModal
          open={isModalOpen}
          handleClose={() => setIsModalOpen(false)}
          handleDelete={handleDeleteUser}
          deleteText="Delete this user?"
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled(Box)({
  display: "flex",
  alignItems: "center",
});

const EditBox = styled(Box)({
  marginRight: "16px",
  cursor: "pointer",
  svg: {
    stroke: constants.colors.deededGray,
    transition: constants.transitions,
    "&:hover": {
      stroke: constants.colors.red,
      transition: constants.transitions,
    },
  },
});

const DeleteBox = styled(Box)({
  cursor: "pointer",
  svg: {
    stroke: constants.colors.deededGray,
    transition: constants.transitions,
    "&:hover": {
      stroke: constants.colors.red,
      transition: constants.transitions,
    },
  },
});

export default UserManagerEditBlockForItems;
