import {SVGProps} from "react";
import constants from "styles/constants";

const DeleteProfilePhotoSvg = (
  props: SVGProps<SVGSVGElement> & {stroke: string},
) => (
  <svg
    width={44}
    height={44}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={0.5}
      y={0.5}
      width={43}
      height={43}
      rx={4.5}
      stroke={constants.colors.silver3}
    />
    <path
      d="M30.25 15.25h-16.5M19.75 19.75v6M24.25 19.75v6M28.75 15.25V29.5a.75.75 0 0 1-.75.75H16a.75.75 0 0 1-.75-.75V15.25M25.75 15.25v-1.5a1.5 1.5 0 0 0-1.5-1.5h-4.5a1.5 1.5 0 0 0-1.5 1.5v1.5"
      stroke={props.stroke}
    />
  </svg>
);

export default DeleteProfilePhotoSvg;
