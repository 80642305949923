import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { getUser, getUserPointTransactions, updateUserPointsFeature } from "api";
import { PointTransaction } from "types/pointTransaction";
import { AlertTypes } from "types/alertTypes";
import configuration from "utils/configuration";

const usePointTransactions = (user_id?: number) => {
    const { enqueueSnackbar } = useSnackbar();
    const [list, setList] = useState<PointTransaction[]>();
    const [total, setTotal] = useState<number>();
    const [pointsFeature, setPointsFeature] = useState<boolean>();
    const [loading, setLoading] = useState<boolean>(false);

    const updatePointsFeature = async (enabled: boolean) => {
        if (loading || !user_id) return;

        try {
            setLoading(true);
            await updateUserPointsFeature(user_id, enabled);
            setPointsFeature(enabled);
        } catch (e) {
            console.error(e);
            enqueueSnackbar("There was an error loading the data", {
                variant: AlertTypes.Error,
                autoHideDuration: configuration.autoHideErrorDuration,
            });
        } finally {
            setLoading(false);
        }
    };

    const refresh = async () => {
        if (loading || !user_id) return;

        try {
            setLoading(true);
            const { data } = await getUserPointTransactions(user_id);
            setList(data);

            const { data: userResp } = await getUser(user_id);
            setTotal(userResp.points);
            setPointsFeature(userResp.points_feature);
        } catch (e) {
            console.error(e);
            enqueueSnackbar("There was an error loading the data", {
                variant: AlertTypes.Error,
                autoHideDuration: configuration.autoHideErrorDuration,
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        void refresh();
    }, [user_id]);

    return { list, total, loading, refresh, pointsFeature, updatePointsFeature } as const;
};

export default usePointTransactions;
