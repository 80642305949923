import {useState, useEffect} from "react";
import "./ClientIntakeCards.scss";
import _ from "lodash";
import ClientIntakeEditCard from "../ClientIntakeEditCard/ClientIntakeEditCard";
import CustomToolTip from "../DealDetailsCardsSectionV2/CustomToolTip/CustomToolTip";

// Client data types
import {
  clientDataSale,
  clientDataPurchase,
  clientDataMortgage,
  clientDataLender,
  CARD_STATUS_MISSED,
  CARD_STATUS_APPROVED,
  CARD_STATUS_PENDING,
} from "../ClientIntakeEditCard/card-accessors";
import EditSVG from "../../Svg/Edit";
import {useSelector} from "react-redux";
import {Typography} from "@mui/material";

// TODO (Oleg): introduce contexts to prevent prop drilling for things like
// API structs
/**
 * Return the intake data based on a deal type.
 * @param {string} dealType
 */
function getClientData(dealType) {
  switch (dealType) {
    case "Sale":
      return clientDataSale;
    case "Purchase":
      return clientDataPurchase;
    case "Mortgage":
      return clientDataMortgage;
    case "Lender":
      return clientDataLender;
    default:
      return clientDataSale;
  }
}

/**
 * Card data provides the title with number attached to the front
 * This function remove the unnecessary number
 *
 * @param {{title: string}} cardData Contains title inside
 */
function extractTitle(cardData) {
  let title = "Card Data";
  if (cardData && cardData.title) {
    const replacer = cardData.title.split(" ")[0];
    title = cardData.title.replace(replacer, "").trim();
  }
  return title;
}

/**
 * Data type for rendering intake card content.
 * It may contain static elements (a render function or a value)
 * Also an async function for dynamically preloading server data
 *
 * @typedef {Object} IntakeCardContent
 * @property {string} status For setting the card color
 * @property {string|(()=>any)} content A string or render function
 * @property {()=>Promise<any>?} asyncLoader Feteches the dynamic content like documents
 */

/**
 * content is a dynamic value, so it's necessary to validate the value properly and extract
 * the status
 *
 * @param {string|number|{onRender: ()=>JSX.Element}|IntakeCardContent|Function} content
 * @param {any} client
 * @param {number} userIndex
 */
function useParsedCardContent(content, client, userIndex) {
  const [displayContent, setDisplayContent] = useState("");
  const [status, setStatus] = useState("blank");
  const [isLoading, setIsLoading] = useState(false);

  const defaultText = "Please fill in the information on this issue";

  useEffect(() => {
    // It's a regular value which can be displayed withot parsing
    if (typeof content === "string" || typeof content === "number") {
      if (content === "") {
        setStatus(CARD_STATUS_MISSED);
        setDisplayContent(defaultText);
      } else {
        setStatus(CARD_STATUS_APPROVED);
        setDisplayContent(content);
      }
      // render function returns the JSX elements (custom rendering)
    } else if (typeof content === "function") {
      setStatus(CARD_STATUS_APPROVED);
      setDisplayContent(content);
      // If it's an object it could come as a render function wrapper
      // (for preventing the auto-excecution bug)
      // or A Intake card content struct
    } else if (typeof content === "object") {
      if (content?.["onRender"]) {
        setDisplayContent(content);
        setStatus(CARD_STATUS_APPROVED);
      } else {
        /** @type {IntakeCardContent} */
        const intakeCardContent = content;

        if (intakeCardContent?.status) {
          setStatus(intakeCardContent.status);
        }
        const staticContent = intakeCardContent?.content || defaultText;
        if (intakeCardContent?.asyncLoader) {
          setIsLoading(true);
          intakeCardContent.asyncLoader(client, userIndex).then((value) => {
            value.content = value.content || defaultText;
            // If there also is a static content then
            // Show both values

            const resultDisplayContent = staticContent
              ? {
                  onRender: function () {
                    return (
                      <>
                        <p>{staticContent}</p>
                        <p>
                          {typeof value.content === "function"
                            ? value.content()
                            : value.content}
                        </p>
                      </>
                    );
                  },
                }
              : value.content;

            setDisplayContent(resultDisplayContent);
            setStatus(value.status);
            setIsLoading(false);
          });
        } else {
          setDisplayContent(staticContent);
        }
      }
    }
  }, [content]);

  /** @type {{displayContent: string | function | {onRender: ()=> JSX.Element}, status: string}} */
  const output = [
    isLoading ? "Loading..." : displayContent,
    isLoading ? CARD_STATUS_PENDING : status,
  ];
  return output;
}

function renderRedBorder(content, title, otherCitizen) {
  if (title === "Citizenship Status" && otherCitizen !== undefined) {
    return true;
  }
  if (
    typeof content === "string" &&
    title === "Property Use" &&
    content.includes("Investment Property")
  ) {
    return true;
  }
  if (typeof content === "string" && title === "Spousal Status") {
    return content.includes("Married") || content.includes("Separated");
  }
  if (
    typeof content === "string" &&
    title === "Rental Property" &&
    content.includes("No")
  ) {
    return true;
  }
  if (
    typeof content === "string" &&
    title === "Description Time Buyer" &&
    content.includes("Yes")
  ) {
    return true;
  }
  if (
    typeof content === "string" &&
    (title === "Corporate Buyer" ||
      title === "Corporate Sale" ||
      title === "Corporate Refinance") &&
    content.includes("Yes")
  ) {
    return true;
  }
  return false;
}

/**
 *
 * @param {Object} props
 * @param {string} props.title - Card title
 * @param {string|IntakeCardContent} props.content
 * @param {(()=>void)?} props.onEdit
 * @param {(()=>void)?} props.client
 * @param {(()=>void)?} props.userIndex
 */
function IntakeCard({
  otherCitizen,
  title,
  idx,
  content,
  onEdit,
  client,
  userIndex,
}) {
  const currUserRole = useSelector(
    (state) => state.AuthReducer.currentUser.role,
  );
  const province = useSelector((state) => state.DealsReducer.province);
  const [displayContent, status] = useParsedCardContent(
    content,
    client,
    userIndex,
  );

  const [isOpenToolTip, setIsOpenToolTip] = useState(false);
  const [copied, setCopied] = useState(false);
  const handleGetCopySourceOfFounds = (_) => {
    const formatBoolean = (value) => (value ? "Yes" : "No");
    return `Savings - ${formatBoolean(client.savings)}
Gift — ${formatBoolean(client.gift)}
Sale proceeds — ${formatBoolean(client.sale_proceeds)}
Mortgage proceeds — ${formatBoolean(client.mortgage_proceeds)}
Other — ${client.other}`;
  };
  const handleCopy = (field) => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 500);

    if (typeof field === "string") {
      return navigator.clipboard.writeText(field);
    } else if (
      field?.props?.children?.[field.props.children.length - 1]?.props?.children
    ) {
      if (title.includes("Source of Funds")) {
        const copyQuestionsValue = handleGetCopySourceOfFounds(field);
        return navigator.clipboard.writeText(copyQuestionsValue);
      }
      return navigator.clipboard.writeText(
        field.props.children[field.props.children.length - 1].props.children,
      );
    } else if (field.props.children) {
      let propsChildren;
      for (let i = field.props.children.length - 1; i >= 0; i--) {
        if (field.props.children[i] !== null) {
          propsChildren = field.props.children[i];
          break;
        }
      }
      return navigator.clipboard.writeText(
        propsChildren.props.children[propsChildren.props.children.length - 1]
          .props.children,
      );
    }
  };

  const notAllowedCopyArray = [
    "01. Number of Lenders",
    "03. Corporate Refinance",
    "09. Citizenship Status",
    "10. Signing Appointment",
    "01. Ownership of Property",
    "02. Scheduled Closing Date",
  ];

  const cardNumber = idx + 1;
  let customTitle =
    (("" + cardNumber).length == 2 ? cardNumber : "0" + cardNumber) +
    ". " +
    extractTitle({title});
  const renderDisplayContent = () => {
    const contentWithoutNull =
      typeof displayContent === "string"
        ? displayContent.replace(/null,/g, " ").replace(/null/g, " ")
        : displayContent;

    return typeof displayContent === "function"
      ? displayContent()
      : typeof displayContent === "object" && "onRender" in displayContent
      ? displayContent.onRender()
      : contentWithoutNull;
  };
  return (
    <div
      className={`detail__item ${
        renderRedBorder(content, extractTitle({title}), otherCitizen) === true
          ? "bold-red"
          : status
      }`}
    >
      <div className="title">
        {customTitle}

        {onEdit && (
          <div className="edit" onClick={onEdit}>
            {currUserRole === "Admin" ||
            currUserRole === "System Admin" ||
            currUserRole === "Document Support Specialist" ? (
              <EditSVG />
            ) : null}
          </div>
        )}
      </div>
      {!notAllowedCopyArray.includes(customTitle) ? (
        <CustomToolTip
          handleTooltipClose={() => setIsOpenToolTip(false)}
          open={isOpenToolTip}
        >
          <div
            onClick={() => {
              handleCopy(displayContent);
            }}
            className="content field__to__copy field__to__copy--new"
          >
            {renderDisplayContent()}
            {copied && (
              <Typography
                sx={{
                  width: "80px",
                  height: "40px",
                  backgroundColor: "black",
                  position: "absolute",
                  top: "20px",
                  left: "50px",
                  borderRadius: "5px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "white",
                  fontFamily: "Montserrat",
                  opacity: "0.5",
                  fontSize: "15px",
                }}
              >
                Copied
              </Typography>
            )}
          </div>
        </CustomToolTip>
      ) : (
        <div className="content">{renderDisplayContent()}</div>
      )}
    </div>
  );
}

/**
 * Card section.
 *
 * @param {Object} props
 * @param {number} props.id
 * @param {string} props.title
 */
function CardsSectionHeader({id, title}) {
  return (
    <div className="header">
      {`0${id}. ${title}`} <span className="line"></span>
    </div>
  );
}

/**
 *
 * @param {Object} props
 * @param {any} props.client
 * @param {Array<any>} props.clientData
 * @param {string} props.classPrefix
 * @param {(any)=>any} props.onExtractData
 * @param {any} props.onEdit
 * @param {number} props.userIndex
 */
function CardsList({
  client,
  clientData,
  classPrefix,
  onExtractData,
  onEdit,
  userIndex,
}) {
  const filteredList = clientData.filter(
    (obj) => !("hideIf" in obj && obj.hideIf(client, userIndex)),
  );

  const filterByCitizenship = client["purchasers"] || [];
  const otherCitizen = filterByCitizenship.filter(
    (card) => card.canadian_citizen === 0 && card.other_citizenship !== null,
  );
  const isBritishColumbia =
    client?.cachedDeal?.address?.state === "BC" ? true : false;
  return (
    <div className={`${classPrefix}-details__list`}>
      {filteredList.map((obj, idx) => {
        const data = onExtractData(obj);
        if (
          (obj?.title.includes("Social Insurance Number") ||
            obj?.title.includes("Resided Address")) &&
          !isBritishColumbia
        )
          return null;
        return (
          <IntakeCard
            otherCitizen={otherCitizen[userIndex]}
            client={client}
            userIndex={userIndex}
            key={`card-${idx}`}
            idx={idx}
            title={obj.title}
            onEdit={
              obj?.title.includes("Source of Funds")
                ? null
                : () => onEdit(obj, client, data)
            }
            content={data}
          />
        );
      })}
    </div>
  );
}

/**
 *
 * @param {Object} props
 * @param {string} props.label
 * @param {JSX.Element[]} props.children
 */
function CardsSection({label, children}) {
  return <div aria-label={label}>{children}</div>;
}

/**
 * Renders in-take cards for the user
 *
 * @param {Object} props
 * @param {Object} props.client data retrieved from server (I suppose?)
 * @param {Object} props.deal data retrieved from server (I suppose?)
 * @param {number} props.userIndex - User index
 * @param {(onFinished: any)=>void} props.onRerender - This is used for refreshing the view
 */
function ClientIntakeCards({client, deal, userIndex, onRerender}) {
  // This is dirty but some cards require document access so I guess it's fine
  client.cachedDeal = deal;

  const clientData = getClientData(deal.deal_type);

  const [cardData, setCardData] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editedData, setEditedData] = useState(null);
  const [editedDataObj, setEditedDataObj] = useState(null);
  const {preferred_province} = useSelector((state) => state.DealsReducer);
  const extractData = (obj, accessorParamName = "accessor") => {
    let extracted =
      typeof obj[accessorParamName] === "string"
        ? _.get(client, obj[accessorParamName], "")
        : obj[accessorParamName](client, userIndex);
    if (obj.title.includes("Signing Appointment")) {
      extracted =
        typeof obj[accessorParamName] === "string"
          ? _.get(client, obj[accessorParamName], "")
          : obj[accessorParamName](client, preferred_province);
    }
    return extracted;
  };

  const handleCardData = (cardData, clientData, currValue) => {
    setCardData(cardData);
    setIsModalVisible(!isModalVisible);
    // It seems that edited data is a string value
    setEditedData(currValue);
    // attempt to read data object
    if ("editDataAccessor" in cardData) {
      let extractedEditData = extractData(cardData, "editDataAccessor");
      setEditedDataObj(extractedEditData);
    } else {
      // Cleanup for the new cards which don't have the structured data accessor
      setEditedDataObj(null);
    }
  };

  const cardsListProps = {
    client,
    onEdit: handleCardData,
    onExtractData: extractData,
    userIndex,
  };
  return (
    <>
      {isModalVisible && (
        <ClientIntakeEditCard
          title={extractTitle(cardData)}
          initialValue={{displayString: editedData, obj: editedDataObj}}
          dealType={deal.deal_type}
          cardData={cardData}
          client={client}
          userIndex={userIndex}
          onDataUpdated={onRerender}
          onClose={() => setIsModalVisible(!isModalVisible)}
        />
      )}
      <CardsSection className="Personal Details">
        <CardsSectionHeader id={1} title="Personal Details" />
        <CardsList
          classPrefix="personal"
          clientData={clientData.personal_details}
          {...cardsListProps}
        />
      </CardsSection>
      <CardsSection label="Property Details">
        <CardsSectionHeader id={2} title="Property Details" />
        <CardsList
          classPrefix="property"
          clientData={clientData.property_details}
          {...cardsListProps}
        />
      </CardsSection>
      <CardsSection label="Mortgage Details">
        <CardsSectionHeader id={3} title="Mortgage Details" />
        <CardsList
          classPrefix="mortgage"
          clientData={clientData.mortgage_details}
          {...cardsListProps}
        />
      </CardsSection>
      <CardsSection label="Other Items">
        <CardsSectionHeader id={4} title={"Other Items"} />
        <CardsList
          classPrefix="insurance"
          clientData={clientData.other_items}
          {...cardsListProps}
        />
      </CardsSection>
    </>
  );
}

export default ClientIntakeCards;
