import React, {SetStateAction} from "react";
import { styled } from "@mui/material/styles";
import {Box} from "@mui/material";
import constants from "styles/constants";
import UserManagerListProfessionalsFilterItem from "./UserManagerListProfessionalsFilterItem";

interface UserManagerListProfessionalsFilterProps {
  allItems: string[];
  activeItems: string[] | null;
  onClose: React.Dispatch<SetStateAction<boolean>>;
  setFilter: React.Dispatch<SetStateAction<string[] | null>>;
  setTypeRole: React.Dispatch<SetStateAction<string>>;
}

const UserManagerListProfessionalsFilter: React.FC<
  UserManagerListProfessionalsFilterProps
> = ({allItems, onClose, setFilter, setTypeRole, activeItems}) => (
  <Box>
    <Overlay onClick={() => onClose(false)} />
    <Filter>
      {allItems.map((item, i) => (
        <UserManagerListProfessionalsFilterItem
          key={i}
          item={item}
          index={i}
          setFilter={setFilter}
          setTypeRole={setTypeRole}
          activeItems={activeItems}
        />
      ))}
    </Filter>
  </Box>
);

const Overlay = styled(Box)({
  position: "fixed",
  left: 0,
  top: 0,
  width: "100%",
  height: "100%",
});

const Filter = styled(Box)({
  position: "absolute",
  backgroundColor: constants.colors.white,
  boxShadow: "0px 12px 66px rgba(0, 0, 0, 0.18)",
  top: "33px",
  left: "-23px",
  borderRadius: "5px",
  zIndex: 5,
  padding: "10px 20px",
  minWidth: "280px",
});

export default UserManagerListProfessionalsFilter;
