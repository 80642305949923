import {SetStateAction} from "react";
import { styled } from "@mui/material/styles";
import {Box} from "@mui/material";
import constants from "styles/constants";
import DeededCheckbox from "v2/components/DeededCheckbox";

interface UserManagerListProfessionalsFilterItemProps {
  item: string;
  index: number;
  setFilter: React.Dispatch<SetStateAction<string[] | null>>;
  setTypeRole: React.Dispatch<SetStateAction<string>>;
  activeItems: string[] | null;
}

const UserManagerListProfessionalsFilterItem: React.FC<
  UserManagerListProfessionalsFilterItemProps
> = ({item, index, setFilter, setTypeRole, activeItems}) => {
  const checkboxActive = !!(
    activeItems &&
    activeItems.length > 0 &&
    activeItems.includes(item)
  );

  const handleChangeCheckbox = (e: {target: {textContent: string}}) => {
    setTypeRole("professionals");
    if (!checkboxActive) {
      setFilter((prevState) =>
        prevState
          ? [...prevState, e.target.textContent]
          : [e.target.textContent],
      );
    } else {
      setFilter((prevState) =>
        prevState
          ? prevState?.filter((item) => item !== e.target.textContent)
          : [],
      );
    }
  };

  return (
    <RoleWrapper>
      <DeededCheckbox
        label={item}
        checked={checkboxActive}
        name="has_personal_role"
        id={`has_personal_role-${index}`}
        onChange={() => {
          handleChangeCheckbox({
            target: {
              textContent: item,
            },
          });
        }}
      />
    </RoleWrapper>
  );
};

const RoleWrapper = styled(Box)({
  display: "flex",
  marginLeft: "-10px",
  transition: constants.transitions,
  position: "relative",
  "& > span": {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 1,
    transition: constants.transitions,
    svg: {
      marginLeft: "0 !important",
      marginRight: "0 !important",
    },
  },
  "&:hover": {
    transition: constants.transitions,
    "& > span": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
      transition: constants.transitions,
    },
  },
  "& .label": {
    marginTop: "11px",
    marginRight: 0,
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "17px",
    display: "flex",
    color: constants.font,
    whiteSpace: "nowrap",
    paddingLeft: "8px",
    position: "relative",
    zIndex: 2,
    "&:hover": {
      transition: constants.transitions,
      cursor: "pointer",
    },
  },
});

export default UserManagerListProfessionalsFilterItem;
