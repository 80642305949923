import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  styled,
} from "@mui/material";
import AccordionIcon from "components/Svg/AccordionIcon";
import DeleteProfilePhotoSvg from "components/Svg/DeleteProfilePhotoSvg";
import constants from "styles/constants";
import {Deal} from "types/deal";
import {truncateString} from "utils/truncateString";
import DeededButton from "v2/components/DeededButton";

interface DealListItemMobileProps {
  defaultExpanded?: boolean;
  deal: Deal;
  dealTypeColor: string;
  provinceColor: string;
  dealStage: string;
  createdAtDisplayString: string;
  closingDateDisplayString: string;
  full_name: string;
  first_name: string;
  name_prefix: string | null;
  handleClickOnDeal: () => void;
  handleClickDelete: (e: Event) => void;
}

const DealListItemMobile: React.FC<DealListItemMobileProps> = ({
  defaultExpanded,
  deal,
  dealTypeColor,
  provinceColor,
  dealStage,
  createdAtDisplayString,
  closingDateDisplayString,
  full_name,
  first_name,
  name_prefix,
  handleClickOnDeal,
  handleClickDelete,
}) => {
  return (
    <AccordionStyled
      defaultExpanded={defaultExpanded}
      dealTypeColor={dealTypeColor}
    >
      <WrapperSummary>
        <LeftSide>
          <AccordionSummary sx={{borderRadius: "10px"}}>
            <AccordionIcon style={{stroke: constants.colors.white}} />
          </AccordionSummary>
          <Box sx={{marginLeft: "10px"}}>
            <InfoItem>
              <InfoItemLabel>Type:</InfoItemLabel>
              <LeftSideInfoItemValue sx={{color: dealTypeColor}}>
                {deal.deal_type}
              </LeftSideInfoItemValue>
            </InfoItem>
            <InfoItem>
              <InfoItemLabel>Address:</InfoItemLabel>
            </InfoItem>
          </Box>
        </LeftSide>

        <RightSide>
          <InfoItem>
            <InfoItemLabel>Name:</InfoItemLabel>
            <RightSideInfoItemValue
              sx={{
                marginLeft: "8px",
                marginBottom: 0,
                fontWeight: 600,
                display: "flex",
              }}
            >
              <span
                title={truncateString(
                  name_prefix === "Corporation" ? first_name : full_name,
                  10,
                )}
                style={{maxWidth: "73px"}}
              >
                {truncateString(
                  name_prefix === "Corporation" ? first_name : full_name,
                  10,
                )}
              </span>
            </RightSideInfoItemValue>
          </InfoItem>
          <InfoItem>
            <RightSideInfoItemValue>
              <span title={deal?.address?.address.slice(0, 30)}>
                {deal?.address?.address.slice(0, 30)}
              </span>
            </RightSideInfoItemValue>
          </InfoItem>
        </RightSide>
      </WrapperSummary>

      <AccordionDetails>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <LeftSide
            sx={{
              paddingLeft: "58px",
            }}
          >
            <Box>
              <InfoItem>
                <InfoItemLabel>Province:</InfoItemLabel>
              </InfoItem>
              <InfoItem>
                <InfoItemLabel>Created:</InfoItemLabel>
              </InfoItem>
              <InfoItem>
                <InfoItemLabel>Closing:</InfoItemLabel>
              </InfoItem>
              <InfoItem>
                <InfoItemLabel>Transaction Stage:</InfoItemLabel>
              </InfoItem>
              <InfoItem>
                <InfoItemLabel>Number:</InfoItemLabel>
              </InfoItem>
            </Box>
          </LeftSide>
          <RightSide sx={{minWidth: "135px"}}>
            <InfoItem>
              <RightSideInfoItemValue>
                <ProvinceBox sx={{background: provinceColor}}>
                  {deal?.address?.state || "-"}
                </ProvinceBox>
              </RightSideInfoItemValue>
            </InfoItem>
            <InfoItem>
              <RightSideInfoItemValue>
                <span title={createdAtDisplayString}>
                  {createdAtDisplayString}
                </span>
              </RightSideInfoItemValue>
            </InfoItem>
            <InfoItem>
              <RightSideInfoItemValue>
                <span title={closingDateDisplayString}>
                  {closingDateDisplayString}
                </span>
              </RightSideInfoItemValue>
            </InfoItem>
            <InfoItem>
              <RightSideInfoItemValue>
                <span title={dealStage}>{dealStage}</span>
              </RightSideInfoItemValue>
            </InfoItem>
            <InfoItem>
              <RightSideInfoItemValue
                sx={{
                  color: constants.colors.dealListItemNumber,
                }}
              >
                <span title={deal.deal_number.slice(0, 9)}>
                  {deal.deal_number.slice(0, 9)}
                </span>
              </RightSideInfoItemValue>
            </InfoItem>
          </RightSide>
        </Box>
        <Bottom>
          <DeededButton
            type="button"
            kind="primary"
            onClick={handleClickOnDeal}
            sx={{
              width: "216px !important",
            }}
          >
            View Deal Details
          </DeededButton>
          {(!deal.is_archive) && <DeededButton
            type="button"
            onClick={handleClickDelete as () => void}
            kind="secondary"
            sx={{
              maxWidth: "44px",
              minWidth: "44px",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: 0,
            }}
          >
            <DeleteProfilePhotoSvg stroke={constants.colors.red} />
          </DeededButton>}
        </Bottom>
      </AccordionDetails>
    </AccordionStyled>
  );
};

const Bottom = styled(Box)({
  display: "flex",
  alignItems: "center",
  marginTop: "30px",
  justifyContent: "space-around",
  "& button": {
    svg: {
      rect: {
        stroke: "transparent",
      },
    },
  },
});

const LeftSide = styled(Box)({
  display: "flex",
  alignItems: "center",
  flex: "0 0 60%",
});

const InfoItem = styled(Box)({
  fontWeight: 600,
  fontSize: "12px",
  lineHeight: "15px",
  "&:first-of-type": {
    display: "flex",
    alignItems: "center",
  },
  "&:not(:last-child)": {
    marginBottom: "10px",
  },
});

const InfoItemLabel = styled(Box)({
  color: constants.colors.intakeTitle,
  whiteSpace: "nowrap",
});

const LeftSideInfoItemValue = styled(Box)({
  marginLeft: "8px",
});

const RightSideInfoItemValue = styled(Box)({
  color: constants.colors.deededGray,
  fontWeight: 400,
  marginBottom: "-4.3px",
  span: {
    display: "inline-block",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "91px",
  },
});

const RightSide = styled(Box)({
  flex: "0 0 40%",
  justifyContent: "flex-end",
});

const WrapperSummary = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  background: constants.colors.white,
  alignItems: "center",
  padding: "0 16px",
});

const ProvinceBox = styled(Box)({
  width: "51px",
  height: "24px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textTransform: "uppercase",
  fontWeight: "600",
  borderRadius: "4px",
  color: "white",
  marginTop: "-3.3px",
});

const AccordionStyled = styled(Accordion, {
  shouldForwardProp: (prop) => prop !== "dealTypeColor"
})<{dealTypeColor: string}>(
  ({dealTypeColor}) => ({
    boxShadow: "none",
    margin: "0 !important",
    border: "none",
    svg: {
      transition: constants.transitions,
    },
    "& .Mui-expanded": {
      svg: {
        transform: "scaleY(-1)",
        transition: constants.transitions,
      },
    },
    "&.MuiPaper-root": {
      borderRadius: "5px",
    },
    "& .MuiAccordionSummary-expandIconWrapper": {
      marginTop: "-5px",
      marginRight: "10px",
    },
    "&:before": {
      display: "none",
    },
    "& .MuiPaper-root.MuiButtonBase-root": {
      minHeight: "48px ",
    },
    "& .css-bz4dnt-MuiCollapse-root": {
      width: 0,
    },
    "& .MuiAccordionSummary-root": {
      borderRadius: "10px",
      background: dealTypeColor,
      width: "48px",
      minHeigth: "48px !important",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    "& .MuiAccordionSummary-root.Mui-expanded": {
      minHeight: "48px",
      "& .MuiAccordionSummary-content": {
        margin: 0,
      },
    },
    "&.MuiPaper-root .MuiAccordion-root .MuiAccordionSummary-root": {
      minHeight: "48px",
      borderRadius: "10px",
    },
    "& .css-15v22id-MuiAccordionDetails-root": {
      padding: "10px 16px 0",
    },
  }),
);

export default DealListItemMobile;
