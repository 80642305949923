import {Fragment, useCallback, useEffect, useState} from "react";

import {
  addBreadCrumbsAC,
  clearFoldersAC,
  clearInteralIdsForDisplayingFoldersAC,
  getEditWorkdocLinkTC,
  getFolderInfoTC,
  getFolderSizeTC,
  getFoldersTC,
  getInteralIdsTC,
  getTagsForFileTC,
  moveFileTC,
  setCurrentPage,
  setDeletedCountAC,
  setFileInfoAC,
  setFileInfoSectionTrueAC,
  setIdActionsAC,
  setIdActionsNullAC,
  setIdActiveMembersListAC,
  setIdHoveredFolderAC,
  setIdMoveFileAC,
  setIsLoadingDocumentAC,
  setLoaderAC,
  setSeachValue,
  setSelectedFormatForFileInfoAC,
  uploadDocumentTC,
} from "../../../redux/reducers/storageReducer";
import {
  addSuccessMessageAC,
} from "../../../redux/reducers/ErrorReducer";
import StorageSmallFolderSvg from "../../Svg/StorageSmallFolderSvg";
import Storage3DotsSvg from "../../Svg/Storage3DotsSvg";

import {
  deleteFile,
  downloadStorageFile,
  downloadStorageFolder,
  getFolderInfo,
} from "../../../api";
import {useSelector} from "react-redux";
import {saveAs} from "file-saver";
import Draggable from "react-draggable";
import Checkbox from "@mui/material/Checkbox";
import {
  deleteFoldersFromCheckedFolders,
  setAllSelectOnThePageAC,
  setCheckedFoldersAC,
} from "../../../redux/reducers/selectedFileMoveStorageReducer";
import {makeStyles} from "@mui/styles";
import * as storageConfig from "components/Dashboard/StorageCommonComponents/utils/storageConfig";
import {formatBytes} from "utils/formatBytes";
import {useDropzone} from "react-dropzone";
import useElementScrollY from "../../../utils/hooks/useElementScrollY";
import DocumentPreview from "./DocumentPreview/DocumentPreview";
import constants from "../../../styles/constants";
import {enableFileFormatsIntake} from "../../../utils/enableFileFormatsIntake";
import useTypedSelector from "../../../utils/hooks/useTypedSelector";
import DeededResultsNotFound from "../../../v2/components/DeededResultsNotFound";
import {returnConvertedFromHeicFile} from "../../../utils/returnConvertedFromHeicFile";
import useWindowWidth from "../../../utils/hooks/useWindowWidth";

import StorageFileItemStaffComponent from "./StorageFileItemStaffComponent";
import {Box, useTheme} from "@mui/material";
import DeededCircularLoader from "v2/components/DeededCircularLoader";
import { Body } from "components/Common/Typography";

const prohibitedFiles = [
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.ms-excel",
];
const Folders = (props) => {
  const {
    folders,
    inFolderCount,
    selectFolder,
    idActions,
    dispatch,
    folderSize,
    setSelectedFirstFolder,
    currentPage,
    value,
    folder,
    nopagination,
    searchValue,
    dealId,
  } = props;
  const theme = useTheme();
  const idActiveMembersList = useSelector(
    (state) => state.StorageReducer.idActiveMembersList,
  );
  const idMoveFile = useSelector((state) => state.StorageReducer.idMoveFile);
  const [idDocForView, setIdDocForView] = useState();

  const interalIdsForDisplayingFolders = useSelector(
    (state) => state.StorageReducer.interalIdsForDisplayingFolders,
  );
  const breadCrumbs = useTypedSelector(
    (state) => state.StorageReducer.breadCrumbs,
  );
  const isGettingFolders = useSelector(
    (state) => state.StorageReducer.isGettingFolders,
  );
  const windowWidth = useWindowWidth();
  const isDraggableFilesResolution = windowWidth >= theme.breakpoints.values.lg;
  const [isViewDoc, setIsViewDoc] = useState(false);

  const [isDrag, setIsDrag] = useState(false);
  const [client, setClient] = useState(
    breadCrumbs?.length !== 0
      ? {label: breadCrumbs[0].clientFullName, value: breadCrumbs[0].clientId}
      : undefined,
  );
  const [targetRowCoordinates, setTargetRowCoordinates] = useState(() => ({
    top: 0,
    left: 0,
  }));
  const [dealType, setDealType] = useState(
    breadCrumbs.length !== 0
      ? {label: breadCrumbs[0].deal_type, value: breadCrumbs[0].deal_id}
      : undefined,
  );
  const [docType, setDocType] = useState(
    breadCrumbs.length === 2 //|| breadCrumbs[0]?.name
      ? {label: breadCrumbs[1].name, value: breadCrumbs[1].name}
      : undefined,
  );
  const [file, setFile] = useState(null);

  const checkedFiles = useSelector(
    (state) => state.SelectedFileMoveStorageReducer.checkedFiles,
  );
  const checkedFolders = useSelector(
    (state) => state.SelectedFileMoveStorageReducer.checkedFolders,
  );
  const idHoveredFolder = useSelector(
    (state) => state.StorageReducer.idHoveredFolder,
  );
  const checkedFilesAndFolders = useSelector(
    (state) => state.SelectedFileMoveStorageReducer.checkedFilesAndFolders,
  );
  const idsDisplayingFolders = useSelector(
    (state) => state.StorageReducer.idsDisplayingFolders,
  );
  const {ref: scrollableElementRef, scrollY: scrollableElementY} =
    useElementScrollY();
  const [dragStartScrollElementY, setDragStartScrollElementY] = useState(-1);
  const [dataDrag, setDataDrag] = useState({
    activeDrags: 0,
    deltaPosition: {
      x: 0,
      y: 0,
    },
    controlledPosition: {
      x: -400,
      y: 200,
    },
  });

  useEffect(() => {
    dispatch(getInteralIdsTC(idsDisplayingFolders));
  }, [dispatch, idsDisplayingFolders]);

  useEffect(() => {
    const allSelect = folders
      ?.map((x) => checkedFilesAndFolders?.includes(x.id))
      ?.reduce((a, b) => a && b, true);

    dispatch(setAllSelectOnThePageAC(allSelect));
  }, [checkedFiles, checkedFolders, folders, dispatch, checkedFilesAndFolders]);

  const [moveFileScroll] = useState(0);

  const scrollBoxYDifference = scrollableElementY - dragStartScrollElementY;

  useEffect(() => {
    const handler = (e) => {
      if (dataDrag.activeDrags === 0) {
        return;
      }

      const targetFolderId = document
        .elementsFromPoint(e.pageX, e.pageY - window.scrollY)
        .map((target) => target.getAttribute("data-folderid"))
        .filter((x) => x)
        .pop();

      if (targetFolderId) {
        dispatch(setIdHoveredFolderAC(+targetFolderId));
      }
      if (!targetFolderId) {
        dispatch(setIdHoveredFolderAC(null));
      }
    };
    const mouseScroll = () => {};
    document.addEventListener("scroll", mouseScroll);
    document.addEventListener("mousemove", handler);
    return () => {
      document.removeEventListener("mousemove", handler);
      document.removeEventListener("scroll", mouseScroll);
    };
  }, [
    dataDrag,
    dispatch,
    moveFileScroll,
    /** This one triggers reloading this effect once the user scrolled the file list box */
    scrollBoxYDifference,
  ]);

  const closePreviewDocumentPopup = () => {
    setIsViewDoc(false);
    setFile(null);
  };

  const dragHandlers = {
    onStart: () => {
      if (isDraggableFilesResolution) {
        setDragStartScrollElementY(scrollableElementY);
        setDataDrag(() => {
          return {
            ...dataDrag,
            activeDrags: ++dataDrag.activeDrags,
            start: performance.now(),
          };
        });
      }
    },
    onStop: () => {
      if (isDraggableFilesResolution) {
        /** time difference between start and end in milliseconds */
        const timeDifference = performance.now() - dataDrag.start;
        /** We assume that the click is registered as an mouse up/down event in a half a second */
        const differenceBetweenStartStopThreshold = 250;

        if (timeDifference > differenceBetweenStartStopThreshold) {
          dispatch(setFileInfoSectionTrueAC());
          dispatch(clearFoldersAC());
          dispatch(setLoaderAC(true));
          dispatch(
            moveFileTC(folder, searchValue, currentPage, dealId, nopagination),
          );
          dispatch(setIdHoveredFolderAC(null));
        }

        setDataDrag(() => {
          return {...dataDrag, activeDrags: --dataDrag.activeDrags};
        });
      }
    },
  };

  const classes = useStyles();

  useEffect(() => {
    if (breadCrumbs.length > 1) {
      setDocType({
        label: breadCrumbs[breadCrumbs.length - 1].name,
        value: breadCrumbs[breadCrumbs.length - 1].name,
      });
    } else {
      setDocType(undefined);
    }
    if (breadCrumbs.length !== 0) {
      setDealType({
        label: breadCrumbs[0].deal_type,
        value: breadCrumbs[0].deal_id,
      });
      setClient({
        label: breadCrumbs[0].clientFullName,
        value: breadCrumbs[0].clientId,
      });
    } else {
      setDealType(undefined);
      setClient(undefined);
    }
  }, [breadCrumbs]);
  const handleConvert = (file) => async () => {
    const convertedFile = await returnConvertedFromHeicFile(file);
    return convertedFile;
  };
  const handleActionsMenuPositionUpdate = useCallback((e) => {
    const {top, left, width} =
      e.target.closest(".folderType2")?.getBoundingClientRect() ?? {};
    if (top && left) {
      setTargetRowCoordinates({top: top + window.scrollY, left: left + width});
    }
  }, []);
  const handleDrop = async (e) => {
    if (dealType && docType && client && e.length !== 0) {
      let arrayOfConvertedFiles = await Promise.all(
        e.map(async (file) => await handleConvert(file)()),
      );
      setLoadingUpload(arrayOfConvertedFiles.length);
      dispatch(
        uploadDocumentTC(
          arrayOfConvertedFiles,
          dealType,
          docType,
          client,
          () => {
            setLoadingUpload(null);
            const isScroll = window.location.pathname !== "/dashboard/storage";
            dispatch(
              getFoldersTC(folder, value, currentPage, undefined, isScroll),
            );
          },
        ),
      );
    }
  };
  const handleEditOnWorkDocs = (fileInfo) => {
    dispatch(setIdActionsNullAC());
    dispatch(getEditWorkdocLinkTC(fileInfo.id));
  };
  const {getRootProps} = useDropzone({
    onDrop: handleDrop,
    accept: enableFileFormatsIntake,
    multiple: true,
    onDragEnter: () => {
      if (dealType && docType && client) {
        setIsDrag(true);
      }
    },
  });

  const [loadingDownload, setLoadingDownload] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingUpload, setLoadingUpload] = useState(null);

  return (
    <div
      {...getRootProps({className: "dropzone"})}
      ref={scrollableElementRef}
      className="foldersBox"
    >
      {!loadingUpload && folders.map((el, index) => {
        const date = el.lastEditF;
        let idFolder = el.id;
        let forBread = {
          name: el.name,
          id: idFolder,
        };
        let bytes;
        if (el.doc_type === "Directory") bytes = formatBytes(+folderSize);
        else bytes = formatBytes(+el.size);
        const fileInfo = {
          fileName: el.name,
          filesInside: inFolderCount,
          capacity: bytes,
          lastUpdate: `${date?.[1]}/${date?.[2]}/${date?.[0]} at ${
            el.lastEditFT?.[0]
          }:${el.lastEditFT?.[1]}  ${el.switcher.toUpperCase()}`, //" today at 03:21 AM",
          Type: el.doc_type,
          owner: "Me",
          edited: "by me",
          dateEdited: `${date?.[1]}/${date?.[2]}/${date?.[0]}`,
          created: "by me",
          dateCreated: `${el.createdDate?.[1]}/${el.createdDate?.[2]}/${el.createdDate?.[0]}`,
          tags: [],
        };
        const styledClassForUnderLine =
          el.doc_type !== "Directory" ? "inFilesUderline" : "inFoldersUderline";

        const fileHoveredOnFolder = el.id === idHoveredFolder;
        const styleForFolderTypeComponent = () => {
          if (fileHoveredOnFolder) return "folderType folderTypeFileHovered";
          return "folderType";
        };

        return (
          <Fragment key={el.id}>
            {el.doc_type === "Directory" ? (
              <Box
                sx={{
                  [theme.breakpoints.down("lg")]: {
                    minWidth: "1082px",
                    width: "1082px",
                  },
                }}
                data-folderid={el.id}
                className={styleForFolderTypeComponent()}
                onClick={() => {
                  if (idActions === null) {
                    dispatch(setIdActiveMembersListAC(null));
                    dispatch(setCurrentPage(1));
                    dispatch(getFolderInfoTC(idFolder));
                    dispatch(getFolderSizeTC(idFolder));
                    dispatch(setFileInfoAC(fileInfo));
                    dispatch(setFileInfoSectionTrueAC());
                  }
                  // bellow is the code from onDoubleClick
                  if (!isGettingFolders) {
                    dispatch(clearInteralIdsForDisplayingFoldersAC());
                    dispatch(setSeachValue(""));
                    selectFolder(idFolder);
                    setSelectedFirstFolder(el.name);
                    getFolderInfo(idFolder).then((folderInfo) => {
                      dispatch(
                        addBreadCrumbsAC({
                          ...forBread,
                          deal_id: folderInfo.dealFile.deal_id,
                          deal_type: folderInfo.dealFile.deal_type,
                          clientId: folderInfo.dealFile.client.id,
                          clientFullName: folderInfo.dealFile.client.full_name,
                        }),
                      );
                    });
                  }
                }}
              >
                <div className="filesFileName itemStorage">
                  <div className="itemWrapper">
                    <div
                      className={
                        classes.checkedRoot + " " + classes.checkboxFolder
                      }
                    >
                      {
                        // NOTE: I have no idea what this does, and if we should remove
                      }
                      {checkedFilesAndFolders?.map((x) =>
                          interalIdsForDisplayingFolders?.[
                            index
                          ]?.interal_ids?.includes(x),
                        )
                        .reduce((a, b) => a || b, false) &&
                        interalIdsForDisplayingFolders.length !== 0 && (
                          <div className={"boldBorderForCheckbox"}></div>
                        )}
                      {!storageConfig.hideFileCheckboxes && (
                        <Checkbox
                          sx={{p: 0}}
                          checked={checkedFolders.includes(el.id)}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          onChange={(e) => {
                            e.target.checked
                              ? dispatch(setCheckedFoldersAC(el.id))
                              : dispatch(
                                  deleteFoldersFromCheckedFolders(el.id),
                                );
                          }}
                          inputProps={{"aria-label": "primary checkbox"}}
                        />
                      )}
                    </div>
                    <div className="wrapperSvg">
                      <StorageSmallFolderSvg />
                      {el.deal_status_label && breadCrumbs.length === 0 && (
                        <span>{el.deal_status_label}</span>
                      )}
                    </div>
                    <span data-testid="storage-name-column" className="filesFileNameSpan">{el.name}</span>
                  </div>
                </div>
                <div className="lastEditSlash itemStorage">{el.lastEdit}</div>
                <div className="itemStorage">
                  <div className="itemWrapper">
                    {el.members?.length === 0 || !el.members ? (
                      <div className="fileMembers">Only you</div>
                    ) : (
                      <div
                        onMouseEnter={(e) => {
                          e.stopPropagation();
                          dispatch(setIdActiveMembersListAC(el?.id));
                        }}
                        onMouseLeave={(e) => {
                          e.stopPropagation();
                          dispatch(setIdActiveMembersListAC(null));
                        }}
                        className="fileMembers"
                      >
                        {el.members?.length} members
                      </div>
                    )}

                    {el.id === idActiveMembersList && (
                      <div
                        onMouseEnter={(e) => {
                          e.stopPropagation();
                          dispatch(setIdActiveMembersListAC(el?.id));
                        }}
                        onMouseLeave={(e) => {
                          e.stopPropagation();
                          dispatch(setIdActiveMembersListAC(null));
                        }}
                        className="membersList"
                      >
                        {" "}
                        {el.members.map((el, index) => {
                          return (
                            <p key={`member-name-${index}`}
                              style={{
                                marginLeft: 20,
                                marginTop: index === 0 ? 10 : 0,
                                paddingBottom: 10,
                              }}
                            >
                              {el.first_name} {el.last_name}
                              <br />
                            </p>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
                <div className="statusPosition itemStorage">
                  <div className="itemWrapper"></div>
                </div>

                <div className="itemStorage comments">
                  <div className="itemWrapper">
                    <div className="fileMembers">
                      <span>{el.comment ?? "-"}</span>
                    </div>
                    {el.comment && (
                      <div className="membersList comment">
                        <span>{el.comment}</span>s
                      </div>
                    )}
                  </div>
                </div>

                <div
                  className="itemStorage"
                  style={{
                    fontFamily: "Montserrat",
                    color: constants.colors.title,
                  }}
                >
                  <div className="itemWrapper">{el.countFiles}</div>
                </div>

                <div
                  className="itemStorage"
                  style={{height: 18}}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (el.id !== idActions) dispatch(setIdActionsAC(el.id));
                    else dispatch(setIdActionsNullAC());
                  }}
                >
                  {el.id === idActions && (
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      className="fileActionsBox"
                    >
                      <p
                        className={
                          el.countFiles !== 0
                            ? "folderAction"
                            : "disabledAction"
                        }
                        onClick={() => {
                          if (el.countFiles !== 0) {
                            setLoadingDownload(true);
                            downloadStorageFolder(idActions).then((blob) => {
                              saveAs(blob, el.name + ".zip");
                              setLoadingDownload(false);
                              dispatch(setIdActionsNullAC());
                            });
                          }
                        }}
                      >
                        {loadingDownload ? "Loading..." : "Download"}
                      </p>
                      {el.isCanDelete &&
                      <p
                        onClick={(e) => {
                          if (el.isCanDelete) {
                            setLoadingDelete(true);
                            deleteFile(idActions).then((res) => {
                              dispatch(setDeletedCountAC());
                              dispatch(
                                getFoldersTC(
                                  folder,
                                  value,
                                  currentPage,
                                  undefined,
                                  nopagination,
                                ),
                              );
                              setLoadingDelete(false);
                              dispatch(setIdActionsNullAC());

                              dispatch(addSuccessMessageAC(res.message));
                            });
                          }
                        }}
                        className={
                          el.isCanDelete ? "folderAction" : "disabledAction"
                        }
                      >
                        {loadingDelete ? "Loading..." : "Delete"}
                      </p>}
                    </div>
                  )}
                  <div className="itemWrapper">
                    <Storage3DotsSvg className="storege3Dots" />
                  </div>
                </div>
              </Box>
            ) : (
              <>
                <Draggable
                  disabled={!isDraggableFilesResolution}
                  positionOffset={{
                    x: 0,
                    y:
                      el.id === idActions
                        ? 0
                        : idMoveFile === el.id
                        ? scrollBoxYDifference
                        : 0,
                  }}
                  axis="y"
                  {...dragHandlers}
                >
                  <Box
                    sx={{
                      [theme.breakpoints.down("lg")]: {
                        minWidth: "1082px",
                        width: "1082px",
                      },
                    }}
                    onMouseOver={(e) => {
                      dispatch(setIdMoveFileAC(el.id));
                      if (el.id === idActions) {
                        handleActionsMenuPositionUpdate(e);
                      }
                    }}
                    onClick={(e) => {
                      dispatch(setFileInfoAC(fileInfo));
                      dispatch(setSelectedFormatForFileInfoAC(el.name));
                      dispatch(setFileInfoSectionTrueAC());
                      dispatch(getTagsForFileTC(el.id));
                    }}
                    onDoubleClick={() => {
                      dispatch(setIsLoadingDocumentAC(true));
                      downloadStorageFile(+el.id)
                        .then((blob) => {
                          setFile({
                            data: URL.createObjectURL(blob),
                            type: blob.type,
                            name: el.name,
                          });
                          dispatch(setIsLoadingDocumentAC(false));
                        })
                        .catch((e) => {
                          dispatch(setIsLoadingDocumentAC(false));
                        });
                      setIdDocForView(el.id);

                      if (prohibitedFiles.includes(file.type)) {
                        handleEditOnWorkDocs(el);
                      } else {
                        setIsViewDoc(true);
                      }
                    }}
                    className={
                      dataDrag.activeDrags === 0
                        ? `folderType2 unsetTransform ${el.id}`
                        : `folderType2 ${el.id}`
                    }
                  >
                    <StorageFileItemStaffComponent
                      folder={folder}
                      value={value}
                      currentPage={currentPage}
                      nopagination={nopagination}
                      date={date}
                      classes={classes}
                      loadingDelete={loadingDelete}
                      setLoadingDelete={setLoadingDelete}
                      loadingDownload={loadingDownload}
                      setLoadingDownload={setLoadingDownload}
                      index={index}
                      folders={folders}
                      isDraggableFilesResolution={isDraggableFilesResolution}
                      el={el}
                      idActions={idActions}
                      setFile={setFile}
                      setIsViewDoc={setIsViewDoc}
                      handleActionsMenuPositionUpdate={
                        handleActionsMenuPositionUpdate
                      }
                      setIdDocForView={setIdDocForView}
                      targetRowCoordinates={targetRowCoordinates}
                      handleEditOnWorkDocs={handleEditOnWorkDocs}
                    />
                  </Box>
                </Draggable>
              </>
            )}

            <Box
              sx={{
                [theme.breakpoints.down("lg")]: {
                  minWidth: "1050px",
                  width: "1050px",
                },
              }}
              className={styledClassForUnderLine}
            ></Box>
            {isViewDoc && (
              <DocumentPreview
                setIsPreviewDocument={closePreviewDocumentPopup}
                file={file}
                setFile={setFile}
              />
            )}
          </Fragment>
        );
      })}
      {loadingUpload && <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh", flexDirection: "column", gap: "2rem" }}>
        <DeededCircularLoader />
        <Body>Loading {loadingUpload} files... this could take a while.</Body> 
      </Box>}
      {isViewDoc && (
        <DocumentPreview
          setIsPreviewDocument={closePreviewDocumentPopup}
          file={file}
          setFile={setFile}
        />
      )}
      {!loadingUpload && folders.length === 0 && (
        <DeededResultsNotFound
          searchVal={searchValue}
          textNotFound="There are no files in the folder"
        />
      )}
      {isDrag && docType && dealType && client && (
        <div
          draggable
          onDragLeave={() => setIsDrag(false)}
          onDrop={() => setIsDrag(false)}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
            backgroundColor: constants.colors.deededGray,
            opacity: 0.2,
          }}
        ></div>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  checkboxFolder: {
    position: "relative",
    marginLeft: "3px",
    marginRight: "13px",
  },
  checkedRoot: {
    "& .Mui-checkbox": {
      border: "1px solid green",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "2rem",
    },
    "& .MuiCheckbox-colorSecondary": {
      marginTop: 6,
      color: "#7F7F7F",
      marginLeft: "11px",
      marginRight: "8px",
      width: 15,
      height: 15,
      borderRadius: 5,
    },
    "& .Mui-checked": {
      color: "#F16764",
    },
    "& MuiIconButton-label": {},
  },
}));
export default Folders;
