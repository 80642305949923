import React from "react";
import DealDetails from "./DealDetails";
import useBreakPoints from "../../../utils/hooks/useBreakPoints";

const DealDetailsHOC = (props: unknown) => {
  const isDesktopResolution = useBreakPoints();
  return <DealDetails isDesktopResolution={isDesktopResolution} {...props} />;
};

export default DealDetailsHOC;
