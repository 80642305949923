import { Deal } from "types/deal";
import { ReactNode, useMemo } from "react";
import { CalendarIcon, HousePinIcon, ReceiptIcon, UserIcon } from "components/CommonDashboard/Icons";
import Colors from "components/CommonDashboard/Styling/Colors";
import { format } from "date-fns";
import { convertDateStringToDateObject } from "conveyance/libs/utils/dateHelper";
import Stack from "@mui/material/Stack";
import Pill from "components/CommonDashboard/Pill";
import { BodyRegular, BodySmall, H1, SubtitleSmall } from "components/CommonDashboard/Typography";
import styled from "@mui/material/styles/styled";

const DealHeaderInfo = ({ deal }: { deal: Deal }) => {
    const client = useMemo(() => (deal.participants || []).find((pt) => pt.role === "Client"), [deal]);
    const dealType = useMemo(() => {
        if (deal?.deal_type_tag && deal.deal_type_tag.tag !== "default")
            return (deal?.deal_type_tag?.name || "").replace(/\s*\([^)]*\)\s*/g, "");
        return deal.deal_type;
    }, [deal]);

    const infoCard: Row[] = [
        {
            icon: <UserIcon color={Colors.NEUTRAL_700} />,
            subtitle: `${client?.name_prefix || ""} ${client?.full_name}`,
        },

        {
            icon: <CalendarIcon color={Colors.NEUTRAL_600} />,
            subtitle: "Closing date:",
            body: deal.closing_date
                ? format(convertDateStringToDateObject(deal.closing_date), "MMMM dd, yyyy")
                : "Unknown",
            bodyTestId: "deal-details-property-closing-date-value",
        },

        {
            icon: <ReceiptIcon color={Colors.NEUTRAL_600} />,
            subtitle: "Transaction:",
            body: deal.deal_number,
            bodyTestId: "deal-details-property-deal-number-value",
        },
    ];

    return (
        <TransactionInfo sx={{ flexDirection: "row", justifyContent: "space-between" }}>
            <Stack sx={{ gap: "1.6rem", alignItems: "flex-start" }}>
                <Pill
                    icon={HousePinIcon}
                    iconProps={{ color: Colors.PRIMARY_600 }}
                    backgroundColor={Colors.PRIMARY_100}
                >
                    <BodySmall color={Colors.PRIMARY_600}>
                        <b data-testid="deal-details-deal-type">{dealType}</b> of
                    </BodySmall>
                </Pill>
                <Stack>
                    <H1 color={Colors.NEUTRAL_700} data-testid="deal-details-property-address">
                        {deal.address.address || "Address not available"}
                    </H1>
                    <BodyRegular color={Colors.NEUTRAL_600} data-testid="deal-details-property-address-extra">
                        {deal.address.address
                            ? `${deal.address.city}, ${deal.address.state} - ${deal.address.code}`
                            : deal.address.state}
                    </BodyRegular>
                </Stack>
            </Stack>
            <TransactionInfoCard data={infoCard} />
        </TransactionInfo>
    );
};

interface Row {
    icon: ReactNode;
    subtitle?: string;
    subtitleTestId?: string;
    body?: string;
    bodyTestId?: string;
}

const TransactionInfoCard = ({ data = [] }: { data: Row[] }) => {
    return (
        <Stack
            sx={{
                borderRadius: "1.6rem",
                backgroundColor: Colors.PRIMARY_100,
                padding: "2.4rem 1.6rem",
                gap: "2.4rem",
            }}
        >
            {data.map((row, key) => (
                <Stack key={key} sx={{ gap: ".8rem", flexDirection: "row" }}>
                    {row.icon}
                    {row.subtitle && (
                        <SubtitleSmall color={Colors.NEUTRAL_700} data-testid={row.subtitleTestId}>
                            {row.subtitle}
                        </SubtitleSmall>
                    )}
                    {row.body && (
                        <BodySmall color={Colors.NEUTRAL_600} data-testid={row.bodyTestId}>
                            {row.body}
                        </BodySmall>
                    )}
                </Stack>
            ))}
        </Stack>
    );
};

const TransactionInfo = styled(Stack)(({ theme }) => ({
    flexDirection: "row",
    justifyContent: "space-between",
    [theme.breakpoints.down("mobile")]: {
        flexDirection: "column",
        alignItems: "start",
        gap: "1rem",
    },
}));

export default DealHeaderInfo;
