import React from "react";
import {Box, styled, useTheme} from "@mui/material";
import {Theme} from "@mui/material/styles";
import constants from "styles/constants";
import useTypedSelector from "utils/hooks/useTypedSelector";
import {largeTabletCalendar} from "../constants/breakpoints";

type CalendarSwitchViewProps = {
  setView: (value: string) => void;
  view: string;
};

const CalendarSwitchView = ({setView, view}: CalendarSwitchViewProps) => {
  const theme = useTheme();
  const calendarType = useTypedSelector(
    (state) => state.CalendarReducer.calendarType,
  );
  const isVisibleDayButtonForClosings = true;
  const isVisibleDayButton =
    isVisibleDayButtonForClosings || calendarType === "Signing Appointment";

  return (
    <Box sx={{marginTop: "-25px", marginRight: "22px"}}>
      {isVisibleDayButton && (
        <CustomButton
          data-testid="calendar-switch-view-day-btn"
          isVisibleDayButton={isVisibleDayButton}
          theme={theme}
          sx={{
            ...dayStyle,
            cursor: "pointer",
            background: view === "day" ? activeStyle.background : "white",

            color: view === "day" ? activeStyle.color : "#919395",
            ":hover": {
              color: constants.colors.white,
              background: constants.colors.red,
            },
          }}
          onClick={() => setView("day")}
        >
          Day
        </CustomButton>
      )}
      <CustomButton
        data-testid="calendar-switch-view-week-btn"
        isVisibleDayButton={isVisibleDayButton}
        theme={theme}
        sx={{
          padding: isVisibleDayButton ? "6px 27px" : "6px 49px",
          borderTopLeftRadius: isVisibleDayButton ? "0px" : "5px",
          borderBottomLeftRadius: isVisibleDayButton ? "0px" : "5px",
          ...(view === "week" ? activeStyle : null),
        }}
        onClick={() => setView("week")}
      >
        Week
      </CustomButton>
      <CustomButton
        data-testid="calendar-switch-view-month-btn"
        isVisibleDayButton={isVisibleDayButton}
        theme={theme}
        sx={{
          ...monthStyle,
          padding: isVisibleDayButton ? "6px 27px" : "6px 49px",
          background: view === "month" ? activeStyle.background : "white",
          color: view === "month" ? activeStyle.color : "#919395",
        }}
        onClick={() => setView("month")}
      >
        Month
      </CustomButton>
    </Box>
  );
};

const activeStyle = {
  background: constants.colors.red,
  color: "white",
};

const dayStyle = {
  borderTopLeftRadius: "5px",
  borderBottomLeftRadius: "5px",
};

const monthStyle = {
  borderTopRightRadius: "5px",
  borderBottomRightRadius: "5px",
};

const CustomButton = styled("button", {
  shouldForwardProp: (prop) => prop !== "isVisibleDayButton"
})<{
  theme: Theme;
  isVisibleDayButton?: boolean;
}>(({theme, isVisibleDayButton}) => ({
  [theme.breakpoints.down(1300)]: {
    padding: isVisibleDayButton ? "6px 14px" : "6px 27px",
    fontSize: "13px",
    fontWeight: "500",
  },
  [theme.breakpoints.down(largeTabletCalendar)]: {
    padding: isVisibleDayButton ? "3px 10px" : "3px 20px",
    fontSize: "10px",
    fontWeight: "500",
  },
  border: "none",
  cursor: "pointer",
  padding: "6px 27px",
  fontSize: "16px",
  fontWeight: "500",
  fontFamily: "Montserrat",
  color: constants.colors.grayCalendarViewSwitch,
  background: "white",
  ":hover": {
    ...activeStyle,
  },
}));

export default CalendarSwitchView;
