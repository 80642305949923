import React, {useState, useEffect} from "react";
import {getDealDocumentsTypes} from "../../../api";
import {withSnackbar} from "utils/withSnackbar";
import {useSelector} from "react-redux";
import useTypedSelector from "../../../utils/hooks/useTypedSelector";
import {returnConvertedFromHeicFile} from "../../../utils/returnConvertedFromHeicFile";
import {Box, useTheme} from "@mui/material";
import configuration from "../../../utils/configuration";
import { useMixpanel } from "utils/MixpanelContext";
import { getUserType } from "utils/permissionRoles";

async function readAsDataUrlAsync(file) {
  return new Promise((resolve) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result);
    };
  });
}

const validFilesList = [
  {type: "application/pdf"},
  {type: "text/plain"},
  {type: "image/jpeg"},
  {type: "image/gif"},
  {type: "image/png"},
  {type: "application/msword"},
  {type: "application/rtf"},
  {type: "application/x-zip-compressed"},
  {type: "application/vnd.ms-excel"},
  {
    type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  },
  {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"},
  {type: "text/csv"},
  {type: "image/heic"},
];
const professionalsList = [
  "Realtor",
  "Real Estate/Mortgage Team",
  "Broker",
  "Lender",
];
const isValidFile = (docList) => {
  const filtered = docList.map((doc) => {
    return validFilesList.find((el) => el.type === doc.type);
  });
  if (filtered.some((file) => file === undefined)) {
    return false;
  }
  return true;
};

const DocumentTypes = ({
  mortgageType,
  province,
  documentType,
  handleFilesUpload,
  filesTotal,
  enqueueSnackbar,
}) => {
  const {currentUser} = useSelector((state) => state.AuthReducer);
  const mixpanel = useMixpanel();
  const newDealSelectedProvince = useTypedSelector(
    (state) => state.DealsReducer.newDealSelectedProvince,
  );
  const theme = useTheme();
  const isCurrUserProfessional = professionalsList.find(
    (el) => el === currentUser?.role,
  );
  const isNewMortgageRefinance =
    mortgageType?.name === "New Mortgage/Refinance";
  const [files, setFiles] = useState(null);
  const [documentTypes, setDocumentTypes] = useState(null);
  const handleRemoveFile = (e, docType, idx) => {
    e.preventDefault();

    setFiles((prevState) => {
      return {
        data: prevState.data.map((x) => {
          return x.document_type === docType
            ? {
                ...x,
                files: x.files.filter((_, index) => index !== idx),
              }
            : x;
        }),
      };
    });
  };
  useEffect(() => {
    setFiles(null);
  }, [newDealSelectedProvince]);
  const getUploadParams = async (event, idx, max, document_type) => {
    const filesArray = await Promise.all(
      Array.from(event.target.files).map((file) =>
        returnConvertedFromHeicFile(file),
      ),
    );
    if (!isValidFile(filesArray)) {
      enqueueSnackbar("Some files have invalid format", {
        variant: "error",
        autoHideDuration: configuration.autoHideErrorDuration,
      });
      return;
    }
    const processedFiles = [];
    let totalSizeOfIconmingFiles = 0;
    for (let i = 0; i < filesArray.length; i++) {
      const file = filesArray[i];
      mixpanel.track(`dashboard_add_new_deal_upload`, {
        role: currentUser.role, 
        email: currentUser.email, 
        user_type: getUserType(currentUser.role),
        document_type:  document_type,
        deal_province: province, 
        deal_type: documentType
      });
      const fileResult = await readAsDataUrlAsync(filesArray[i]);

      totalSizeOfIconmingFiles += file.size;
      processedFiles.push({
        name: file.name,
        size: file.size,
        type: file.type,
        data: fileResult,
      });
    }

    const getTotalSizeOfFiles = (files) => {
      try {
        let total = 0;
        files.forEach((file) => (total += file.size));
        return total;
      } catch (e) {
        return 0;
      }
    };

    setFiles((state) => {
      let totalSizeOfOldFiles = 0;
      state.data.forEach(
        (x) => (totalSizeOfOldFiles += getTotalSizeOfFiles(x.files)),
      );

      if (totalSizeOfOldFiles + totalSizeOfIconmingFiles > 50000000) {
        enqueueSnackbar("Total file size exceeded", {
          variant: "error",
          autoHideDuration: configuration.autoHideErrorDuration,
        });
        return state;
      }
      return {
        data: state.data.map((x, id) =>
          id === idx
            ? {
                ...x,
                files:
                  x.files !== undefined
                    ? [...x.files, ...processedFiles].length > max
                      ? (() => {
                          enqueueSnackbar(
                            "To much files for this type of document",
                            {
                              variant: "error",
                              autoHideDuration: configuration.autoHideErrorDuration,
                            },
                          );

                          return x.files;
                        })()
                      : [...x.files, ...processedFiles]
                    : processedFiles.length > max
                    ? (() => {
                        enqueueSnackbar(
                          "To much files for this type of document",
                          {
                            variant: "error",
                            autoHideDuration: configuration.autoHideErrorDuration,
                          },
                        );

                        return;
                      })()
                    : processedFiles,
              }
            : x,
        ),
      };
    });
  };
  const handleCustomDocumentType = (e, idx) => {
    const {value} = e.target;
    setFiles((state) => ({
      ...state,
      data: state.data.map((doc, i) => {
        if (i === idx) {
          return {
            ...doc,
            document_type: value.length <= 0 ? "Other Documents" : value,
          };
        }
        return doc;
      }),
    }));
  };
  useEffect(() => {
    getDealDocumentsTypes().then((res) => {
      setDocumentTypes(res?.data);
    })
    .catch((error) => {
      enqueueSnackbar(
        error &&
          error.response &&
          error.response.data &&
          error.response.data.error,
        {
          variant: "error",
          autoHideDuration: configuration.autoHideErrorDuration,
        },
      );
    });
  }, []);

  useEffect(() => {
    const setUploadFiles = (data) => {
      if (!documentType) {
        return;
      }
      if (
        documentType === "Lender" &&
        province === "AB" &&
        isCurrUserProfessional
      ) {
        return setFiles({
          data: data["AB"]["Lender"],
        });
      }

      if (documentType === "Lender") {
        return setFiles({
          data: data["ALL"]["Lender"],
        });
      }

      if (
        documentType === "Mortgage" &&
        province === "AB" &&
        isCurrUserProfessional &&
        isNewMortgageRefinance
      ) {
        return setFiles({
          data: data["AB"]["Mortgage"].default,
        });
      }

      if (
        documentType === "Mortgage" &&
        isNewMortgageRefinance
      ) {
        return setFiles({
          data: data["ALL"]["Mortgage"].default,
        });
      }


      if (documentType === "Mortgage") {
        return setFiles({
          data: data["ALL"]["Mortgage"].other,
        });
      }

      if (data[province] && data[province][documentType]) {
        return setFiles({
          data: data[province][documentType],
        });
      }
      setFiles({
        data: data["ALL"][documentType],
      });
    };
    
    if (documentTypes) {
      setUploadFiles(documentTypes);
    }
  }, [
    documentType,
    enqueueSnackbar,
    isCurrUserProfessional,
    isNewMortgageRefinance,
    province,
    documentTypes
  ]);
  useEffect(() => {
    if (files) {
      handleFilesUpload(files);
    }
  }, [files, handleFilesUpload]);
  return (
    <div className="files">
      <div className="files__title">Upload relevant documents</div>
      <div className="files__subtitle">{`Total uploaded size ${filesTotal}`}</div>
      {files &&
        files.data.map((el, idx) => {
          const {document_type, max, files, type} = el;
          if (document_type === undefined) return null;
          return (
            <Box
              sx={{
                [theme.breakpoints.down(972)]: {
                  flexDirection: "column",
                },
              }}
              key={idx}
              className="input"
            >
              <Box
                sx={{
                  [theme.breakpoints.down(972)]: {
                    flex: "unset !important",
                    flexDirection: "column",
                    alignSelf: "flex-start !important",
                  },
                }}
                className="doc__type"
              >
                <Box
                  sx={{
                    flex: "unset",
                  }}
                >
                  {document_type}
                </Box>
                <div
                  className="doc__max"
                  style={{fontWeight: 400}}
                >{`max ${max} attachments`}</div>
              </Box>

              <label
                htmlFor={`document-upload${idx}`}
                className={
                  files === undefined || files.length === 0
                    ? "document-type"
                    : "document-uploaded"
                }
              >
                {files === undefined || files.length === 0
                  ? "Upload file"
                  : files.length >= max
                  ? "Max Uploaded"
                  : "Upload more"}
              </label>
              <input
                disabled={
                  files !== undefined && files.length >= max ? true : false
                }
                id={`document-upload${idx}`}
                type="file"
                style={{display: "none"}}
                multiple={true}
                onChange={(e) => getUploadParams(e, idx, max, document_type)}
              ></input>

              <div className="doc__name__list">
                {type === "custom" && files !== undefined && (
                  <Box>
                    <input
                      type="text"
                      className="custom-document"
                      placeholder="Document Description"
                      maxLength={15}
                      onChange={(e) => handleCustomDocumentType(e, idx)}
                    />
                  </Box>
                )}
                {files && (
                  <Box
                    sx={{
                      display: "flex",
                      // flexWrap:"wrap"
                      overflowX: files.length > 5 ? "scroll" : "visible",
                      marginTop: "10px",
                      marginLeft: "10px",
                      maxWidth: "600px",
                      paddingBottom: "5px",
                      "&::-webkit-scrollbar": {
                        height: "2px",
                      },
                      [theme.breakpoints.down("xl")]: {
                        maxWidth: "400px",
                        overflowX: files.length > 3 ? "scroll" : "visible",
                      },
                      [theme.breakpoints.down("lg")]: {
                        maxWidth: "200px",
                        overflowX: files.length > 1 ? "scroll" : "visible",
                      },
                    }}
                  >
                    {files.map((file, index) => {
                      return (
                        <div
                          className="doc__name"
                          key={`${index} ${file?.name}`}
                          style={{fontWeight: 400}}
                        >
                          <span style={{marginRight: "1rem"}}>
                            {file.name.slice(0, 5)}
                          </span>
                          <button
                            className="delete__file"
                            onClick={(e) => {
                              handleRemoveFile(e, document_type, index);
                            }}
                          >
                            delete
                          </button>
                        </div>
                      );
                    })}
                  </Box>
                )}
              </div>
            </Box>
          );
        })}
    </div>
  );
};

export default withSnackbar(DocumentTypes);
