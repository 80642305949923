import DealDetailsSurveyTabList from "components/Dashboard/Survey/components/DealDetailsSurveyTabList";
import {Box} from "@mui/material";

const SurveyList = ({surveys}) => {
  if (surveys.length === 0) {
    return (
      <h3 style={{textAlign: "center", margin: "2rem 0"}}>
        No Surveys for now
      </h3>
    );
  } else {
    return (
      <Box
        sx={{
          marginTop: "-20px",
        }}
      >
        <DealDetailsSurveyTabList />
      </Box>
    );
  }
};

export default SurveyList;
