import { ReactNode } from "react";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import { styled } from '@mui/material/styles';
import Colors from "../Styling/Colors";
import Stack from "@mui/material/Stack";
import { BodyRegular, H2 } from "../Typography";
import Button, { ButtonProps } from "../Button";
import { CloseIcon } from "../Icons";

type BaseModalProps = {
    size?: 'large' | 'small' | 'medium',
    title?: string,
    icon?: ReactNode,
    textContent?: string,
    buttonProps?: ButtonProps[],
    onCloseClick?: () => void

} & DialogProps

const BaseModal = ({ size = 'medium', title, icon, onCloseClick, children, textContent, buttonProps = [], ...props }: BaseModalProps) => {
    return <Modal
        size={size}
        maxWidth={false}
        {...props}
    >
        <Stack sx={{ alignItems: 'flex-start', gap: '2.4rem' }}>
            <Stack sx={{ flexDirection: 'row', alignSelf: 'stretch', justifyContent: "space-between", alignItems: 'flex-start' }}>
                {title && <H2>{title}</H2>}
                {onCloseClick && <Button typeOf="outline" size="small" onClick={onCloseClick} startIcon={<CloseIcon color={Colors.NEUTRAL_700} />} />}
            </Stack>
            <Stack sx={{ gap: '.8rem', alignItems: 'flex-start', width: '100%' }}>
                {icon && <Stack sx={{ backgroundColor: Colors.PRIMARY_50, borderRadius: '50%', padding: '1.2rem' }}>
                    {icon}
                </Stack>}
                {textContent ? <BodyRegular color={Colors.NEUTRAL_500}>{textContent}</BodyRegular> : children}
            </Stack>
            <ModalActions>
                {buttonProps.map((props, ix) => <Button key={ix} {...props} />)}
            </ModalActions>
        </Stack>
    </Modal>;
}

const sizeWidth = {
    large: '80rem',
    medium: '60rem',
    small: '32rem'
}


const Modal = styled(Dialog, {
    shouldForwardProp: (prop) => prop !== "size"
})<BaseModalProps>(({ size, theme }) => ({
    "& .MuiPaper-root": {
        boxShadow: 'none',
        borderRadius: "2.4rem",
        backgroundColor: Colors.WHITE,
        padding: '4.8rem',
        margin: "2.4rem",
        width: sizeWidth[size || 'medium'],
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            padding: '2.4rem',
        },
    },
}));

const ModalActions = styled(Stack, {
    shouldForwardProp: (prop) => prop !== "size"
})(({ theme }) => ({
    alignSelf: 'flex-end',
    flexDirection: 'row',
    gap: '1.6rem',
    [theme.breakpoints.down('sm')]: {
        width: "100%",
        flexDirection: 'column-reverse', 
        gap: '1.6rem',
        alignItems: 'stretch',
    },
}));

export default BaseModal;