import {useState, useEffect, useCallback} from "react";
import StorageSearchSvg from "../../../Svg/StorageSearchSvg";
import "./Label.scss";
import {useDispatch, useSelector} from "react-redux";
import {setSeachValue} from "../../../../redux/reducers/storageReducer";

const Label = () => {
  const value = useSelector((state) => state.StorageReducer.searchValue);
  const dispatch = useDispatch();

  const onSearch = useCallback(
    (e) => {
      dispatch(setSeachValue(e));
    },
    [dispatch],
  );
  useEffect(() => {
    return () => {
      dispatch(setSeachValue(""));
    };
  }, [dispatch]);
  return (
    <label className="storageSearchLabel" htmlFor="search">
      <div className="storageSearchLabelIcon">
        <StorageSearchSvg />
      </div>
      <DebouncedSearchInput value={value} onSearch={onSearch} />
    </label>
  );
};

const DebouncedSearchInput = ({value: inValue, onSearch}) => {
  const [value, setValue] = useState(inValue);
  useEffect(() => {
    const t = setTimeout(() => {
      onSearch(value);
    }, 300);
    return () => {
      clearTimeout(t);
    };
  }, [onSearch, value]);
  return (
    <input
      value={value}
      onChange={({target: {value}}) => {
        setValue(value);
      }}
      style={{marginTop: 11}}
      className="storageSearch"
      type="text"
      autoComplete="off"
      placeholder="Search for document..."
      name="search"
      data-testid="search-field"
    />
  );
};
export default Label;
