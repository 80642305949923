import { FC, useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import ListItemIcon from "@mui/material/ListItemIcon";
import Button from "components/CommonDashboard/Button";
import { ArrowDownIcon, ArrowUpIcon, LogOutIcon } from "components/CommonDashboard/Icons";
import Colors from "components/CommonDashboard/Styling/Colors";
import { BodySmall, ButtonLargeText, SubtitleRegular } from "components/CommonDashboard/Typography";
import useLogOut from "customerPortal/hooks/useLogOut";

interface HeaderMenuInterface {
    name?: string;
    role?: string;
}

const HeaderMenu: FC<HeaderMenuInterface> = ({ name, role, children }) => {
    const logOut = useLogOut();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleLogOut = () => {
        setAnchorEl(null);
        logOut();
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <Stack gap="1.6rem" direction="row" justifyContent="flex-end" alignItems="center">
            <Stack flexDirection="column" gap=".3rem" alignItems="flex-end" justifyContent="center">
                {name && <SubtitleRegular>{name}</SubtitleRegular>}
                {role && <BodySmall color={Colors.NEUTRAL_500}>{role}</BodySmall>}
            </Stack>
            <Button
                size="small"
                data-testid="header-menu-button"
                typeOf="outline"
                endIcon={
                    Boolean(anchorEl) ? (
                        <ArrowUpIcon color={Colors.NEUTRAL_700} />
                    ) : (
                        <ArrowDownIcon color={Colors.NEUTRAL_700} />
                    )
                }
                onClick={handleClick}
                sx={{ backgroundColor: anchorEl ? Colors.NEUTRAL_100 : Colors.WHITE }}
            />
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                MenuListProps={{ sx: { padding: 0 } }}
                slotProps={{
                    paper: {
                        sx: {
                            minWidth: "32rem",
                            marginTop: "0.6rem",
                            borderRadius: "1.6rem",
                        },
                    },
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                <div onClick={() => setAnchorEl(null)}>{children}</div>
                <HeaderMenuItem onClick={handleLogOut}>
                    <ListItemIcon>
                        <LogOutIcon color={Colors.DARK_GREY} />
                    </ListItemIcon>
                    <ButtonLargeText color={Colors.DARK_GREY}>Logout</ButtonLargeText>
                </HeaderMenuItem>
            </Menu>
        </Stack>
    );
};

export const HeaderMenuItem = styled(MenuItem)(() => ({
    padding: "1.6rem",
    minWidth: "22rem",
    "&:hover, &.Mui-selected": {
        backgroundColor: Colors.CORAL_20,
        p: {
            color: Colors.BLACK,
        },
        "& svg path": {
            fill: Colors.BLACK,
        },
    },
}));

export default HeaderMenu;
