
export const CUSTOMER_PORTAL_PATH = "/portal";
export const ONBOARDING_PATH = CUSTOMER_PORTAL_PATH + "/onboarding";
export const pages = {
  RETAINER: {
    route: (token = ':onboarding_token') => `${ONBOARDING_PATH}/${token}/retainer`,
    title: "Lawyer’s Retainer",
  },
  IDV: {
    route: (token = ':onboarding_token') => `${ONBOARDING_PATH}/${token}/idv`,
    title: "Identity Verification",
  },
  SURVEY: {
    route: (token = ':onboarding_token') => `${ONBOARDING_PATH}/${token}/survey`,
    title: "Requested Information",
  },
  FILES: {
    route: (token = ':onboarding_token') => `${ONBOARDING_PATH}/${token}/documents/view`,
    title: "File Uploads",
  },
  DEAL_STATUS: {
    route: (id: string|number = ':deal_id') => `${CUSTOMER_PORTAL_PATH}/deal/${id}`,
    title: "",
  },
  USER_SETTINGS: {
    route: () => `${CUSTOMER_PORTAL_PATH}/settings`,
    title: "",
  },
  USER_SECURITY: {
    route: () => `${CUSTOMER_PORTAL_PATH}/security`,
    title: "",
  },
  DEALS: {
    route: () => `${CUSTOMER_PORTAL_PATH}/deals`,
    title: "",
  },
};
