import React from "react";

const StorageBigUnknownFileSvg = () => {
  return (
    <svg
      width="109"
      height="109"
      viewBox="0 0 109 109"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.8403 16.1797C23.6145 16.1797 23.3979 16.2694 23.2382 16.4291C23.0785 16.5888 22.9888 16.8054 22.9888 17.0313V91.9688C22.9888 92.1946 23.0785 92.4112 23.2382 92.5709C23.3979 92.7306 23.6145 92.8203 23.8403 92.8203H85.1562C85.268 92.8203 85.3787 92.7983 85.482 92.7555C85.5854 92.7127 85.6792 92.65 85.7583 92.5709C85.8374 92.4919 85.9001 92.398 85.9429 92.2946C85.9857 92.1913 86.0077 92.0806 86.0077 91.9688V38.5269L63.6605 16.1797H23.8403ZM19.6253 12.8162C20.7432 11.6983 22.2594 11.0703 23.8403 11.0703H64.7187C65.3962 11.0703 66.046 11.3395 66.5251 11.8186L90.3689 35.6623C90.8479 36.1414 91.1171 36.7912 91.1171 37.4688V91.9688C91.1171 92.7515 90.9629 93.5267 90.6634 94.2499C90.3638 94.9731 89.9247 95.6302 89.3712 96.1838C88.8177 96.7373 88.1605 97.1764 87.4373 97.476C86.7141 97.7755 85.939 97.9297 85.1562 97.9297H23.8403C22.2594 97.9297 20.7432 97.3017 19.6253 96.1838C18.5074 95.0659 17.8794 93.5497 17.8794 91.9688V17.0313C17.8794 15.4503 18.5074 13.9341 19.6253 12.8162Z"
        fill="#828282"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.7187 11.0703C66.1297 11.0703 67.2734 12.2141 67.2734 13.625V34.9141H88.5659C89.9768 34.9141 91.1206 36.0578 91.1206 37.4688C91.1206 38.8797 89.9768 40.0234 88.5659 40.0234H64.7187C63.3078 40.0234 62.1641 38.8797 62.1641 37.4688V13.625C62.1641 12.2141 63.3078 11.0703 64.7187 11.0703Z"
        fill="#828282"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.3203 57.9062C38.3203 56.4953 39.4641 55.3516 40.875 55.3516H68.125C69.5359 55.3516 70.6797 56.4953 70.6797 57.9062C70.6797 59.3172 69.5359 60.4609 68.125 60.4609H40.875C39.4641 60.4609 38.3203 59.3172 38.3203 57.9062Z"
        fill="#828282"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.3203 71.5312C38.3203 70.1203 39.4641 68.9766 40.875 68.9766H68.125C69.5359 68.9766 70.6797 70.1203 70.6797 71.5312C70.6797 72.9422 69.5359 74.0859 68.125 74.0859H40.875C39.4641 74.0859 38.3203 72.9422 38.3203 71.5312Z"
        fill="#828282"
      />
    </svg>
  );
};

export default StorageBigUnknownFileSvg;
