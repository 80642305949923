import React, {
  JSXElementConstructor,
  ReactElement,
  useEffect,
  useRef,
  useState,
  useContext,
} from "react";
import moment from "moment";
import {BigCalendarContext} from "../context/BigCalendarContext";
import {getMonthArrayByDate} from "../utils/getMonthArrayByDate";
import CalendarClosingDealByDate from "./CalendarClosingOrAppointmentsDealByDate";

const closings = {
  lawyer: [
    {
      name: "Ronald Richards",
      count: 5,
    },
    {
      name: "Courtney Henry",
      count: 2,
    },
    {
      name: "Darrell Steward",
      count: 1,
    },
    {
      name: "Marvin McKinney",
      count: 4,
    },
  ],
  lawClerk: [
    {
      name: "Ronald Richards",
      count: 5,
    },
    {
      name: "Courtney Henry",
      count: 2,
    },
    {
      name: "Darrell Steward",
      count: 1,
    },
    {
      name: "Marvin McKinney",
      count: 4,
    },
  ],
};
export interface DateCellWrapperProps {
  children: ReactElement<unknown, string | JSXElementConstructor<unknown>>;
  value: string & {getDate: () => number};
}
export const DateCellWrapper: React.FC<DateCellWrapperProps> = ({
  children,
  value,
}) => {
  const dateValue = new Date(value);
  const now = new Date();
  const [isShowClosingDealsHint, setIsShowClosingDealsHint] = useState(false);
  const [isHoveredOnHint, setIsHoveredOnHint] = useState(false);
  const ref = useRef<null | HTMLDivElement>(null);
  const {currentDate, regions} = useContext(BigCalendarContext);
  const isDateValueForCurrentDateMonth =
    moment(dateValue).format("M") === moment(currentDate).format("M");
  const calendarWeeks = getMonthArrayByDate(currentDate);
  const isShowDayNumberCellPopupDownDirection =
    moment(value).isBefore(calendarWeeks[2][6]) ||
    +moment(value).format("D") === +calendarWeeks[2][6].format("D");
  const isStartOfWeek =
    +value.getDate() === +moment(value).startOf("week").format("D");
  const handleMouseEnterInNumberCell = () => {
    if (isDateValueForCurrentDateMonth) {
      setIsShowClosingDealsHint(true);
    }
  };
  const handleMouseLeaveFromNumberCell = () => {
    setIsShowClosingDealsHint(false);
  };
  useEffect(() => {
    const monthRow = ref.current?.closest(".rbc-month-row");
    const monthRowBg = monthRow?.querySelector(".rbc-row-bg");
    if (monthRowBg) {
      const typedMonthRowBg: {style: {overflow: string}} =
        monthRowBg as unknown as {style: {overflow: string}};
      const typedMonthRow: {style: {overflow: string}} =
        monthRow as unknown as {style: {overflow: string}};
      typedMonthRowBg.style.overflow = "unset";
      typedMonthRow.style.overflow = "unset";
    }
    const arrayElements = monthRow?.querySelectorAll(".rbc-date-cell a");
    let yourElement: Element | null = null;
    arrayElements?.forEach((dateNumberElement) => {
      if (+dateNumberElement.innerHTML === +value.getDate()) {
        yourElement = dateNumberElement as Element & {
          addEventListener: (listener: string, callback: () => void) => void;
        };
      }
    });
    const typedYourElementWithEventListener: {
      addEventListener: (listener: string, callback: () => void) => void;
      removeEventListener: (listener: string, callback: () => void) => void;
    } = yourElement as unknown as {
      addEventListener: (listener: string, callback: () => void) => void;
      removeEventListener: (listener: string, callback: () => void) => void;
    };
    typedYourElementWithEventListener?.addEventListener(
      "mouseenter",
      handleMouseEnterInNumberCell,
    );
    typedYourElementWithEventListener?.addEventListener(
      "mouseleave",
      handleMouseLeaveFromNumberCell,
    );
    return () => {
      typedYourElementWithEventListener?.removeEventListener(
        "mouseenter",
        handleMouseEnterInNumberCell,
      );
      typedYourElementWithEventListener?.removeEventListener(
        "mouseleave",
        handleMouseLeaveFromNumberCell,
      );
    };
  }, [value]);

  const isCurrentDate =
    dateValue.getFullYear() === now.getFullYear() &&
    dateValue.getMonth() === now.getMonth() &&
    dateValue.getDate() === now.getDate();
  const DateWrapperDiv = children.type;
  return (
    <DateWrapperDiv
      ref={ref}
      {...children.props}
      value={value}
      style={{
        ...(children as unknown as {style: React.StyleHTMLAttributes<"div">})
          .style,
        backgroundColor: "white",
        border: isCurrentDate && "2px solid #F16764",
        position: "relative",
        overflowY: "visible",
      }}
    >
      {isShowClosingDealsHint && (
        <CalendarClosingDealByDate
          title={"Signing Appointments"}
          cellDate={value as unknown as Date}
          regions={regions}
          isHoveredOnHint={isHoveredOnHint}
          eventsCountByName={closings}
          isShowDayNumberCellPopupDownDirection={
            isShowDayNumberCellPopupDownDirection
          }
          isShowClosingDealsHint={isShowClosingDealsHint}
          setIsShowEventCountsHint={setIsShowClosingDealsHint}
          isStartOfWeek={isStartOfWeek}
          setIsHoveredOnHint={setIsHoveredOnHint}
        />
      )}
      {children}
    </DateWrapperDiv>
  );
};
