import React from "react";

const StorageBigDocSvg = () => {
  return (
    <svg
      width="109"
      height="109"
      viewBox="0 0 109 109"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.8436 16.1797C23.6178 16.1797 23.4012 16.2694 23.2416 16.4291C23.0819 16.5888 22.9922 16.8054 22.9922 17.0312V91.9688C22.9922 92.1946 23.0819 92.4112 23.2416 92.5709C23.4012 92.7306 23.6178 92.8203 23.8436 92.8203H85.1564C85.3822 92.8203 85.5987 92.7306 85.7584 92.5709C85.9181 92.4112 86.0078 92.1946 86.0078 91.9688V38.5269L63.6617 16.1797H23.8436C23.8436 16.1797 23.8436 16.1797 23.8436 16.1797ZM19.6286 12.8163C20.7465 11.6984 22.2626 11.0704 23.8435 11.0703H64.7199C65.3975 11.0703 66.0473 11.3395 66.5264 11.8186L90.369 35.6624C90.8481 36.1414 91.1172 36.7912 91.1172 37.4688V91.9688C91.1172 93.5496 90.4892 95.0658 89.3714 96.1837C88.2535 97.3016 86.7374 97.9296 85.1565 97.9297H23.8435C22.2626 97.9296 20.7465 97.3016 19.6286 96.1837C18.5108 95.0658 17.8828 93.5496 17.8828 91.9688V17.0313C17.8828 17.0313 17.8828 17.0313 17.8828 17.0313C17.8828 15.4504 18.5108 13.9342 19.6286 12.8163Z"
        fill="#828282"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.7187 11.0703C66.1297 11.0703 67.2734 12.2141 67.2734 13.625V34.9141H88.5659C89.9768 34.9141 91.1206 36.0578 91.1206 37.4688C91.1206 38.8797 89.9768 40.0234 88.5659 40.0234H64.7187C63.3078 40.0234 62.1641 38.8797 62.1641 37.4688V13.625C62.1641 12.2141 63.3078 11.0703 64.7187 11.0703Z"
        fill="#828282"
      />
      <path
        d="M28.0469 81.7504C27.411 81.7504 26.9205 81.5779 26.5754 81.2327C26.2302 80.8875 26.0576 80.397 26.0576 79.7612V64.5284C26.0576 63.8926 26.2302 63.4021 26.5754 63.0569C26.9205 62.7118 27.411 62.5392 28.0469 62.5392H33.5241C35.6133 62.5392 37.4118 62.9207 38.9196 63.6837C40.4275 64.4467 41.581 65.5458 42.3804 66.9809C43.1797 68.4161 43.5794 70.1329 43.5794 72.1312C43.5794 74.1295 43.1797 75.8554 42.3804 77.3087C41.5992 78.7439 40.4547 79.8429 38.9469 80.6059C37.439 81.3689 35.6315 81.7504 33.5241 81.7504H28.0469ZM33.2244 78.3169C37.3119 78.3169 39.3556 76.255 39.3556 72.1312C39.3556 68.0255 37.3119 65.9727 33.2244 65.9727H30.3086V78.3169H33.2244Z"
        fill="#828282"
      />
      <path
        d="M53.9669 81.9957C52.0594 81.9957 50.3972 81.596 48.9802 80.7967C47.5814 79.9792 46.5004 78.8347 45.7374 77.3632C44.9744 75.8735 44.5929 74.1295 44.5929 72.1312C44.5929 70.1329 44.9744 68.3979 45.7374 66.9264C46.5004 65.4368 47.5814 64.2923 48.9802 63.4929C50.3972 62.6936 52.0594 62.2939 53.9669 62.2939C55.8744 62.2939 57.5276 62.6936 58.9264 63.4929C60.3434 64.2923 61.4334 65.4368 62.1964 66.9264C62.9594 68.3979 63.3409 70.1329 63.3409 72.1312C63.3409 74.1295 62.9594 75.8735 62.1964 77.3632C61.4334 78.8347 60.3434 79.9792 58.9264 80.7967C57.5276 81.596 55.8744 81.9957 53.9669 81.9957ZM53.9669 78.6984C55.5656 78.6984 56.8191 78.1353 57.7274 77.0089C58.6539 75.8826 59.1172 74.2567 59.1172 72.1312C59.1172 70.0057 58.663 68.3889 57.7547 67.2807C56.8464 66.1544 55.5838 65.5912 53.9669 65.5912C52.3501 65.5912 51.0875 66.1544 50.1792 67.2807C49.289 68.3889 48.8439 70.0057 48.8439 72.1312C48.8439 74.2567 49.2981 75.8826 50.2064 77.0089C51.1148 78.1353 52.3683 78.6984 53.9669 78.6984Z"
        fill="#828282"
      />
      <path
        d="M73.7125 81.9957C71.8232 81.9957 70.17 81.596 68.753 80.7967C67.336 79.9974 66.246 78.8529 65.483 77.3632C64.72 75.8735 64.3385 74.1295 64.3385 72.1312C64.3385 70.1329 64.72 68.3979 65.483 66.9264C66.246 65.4368 67.336 64.2923 68.753 63.4929C70.17 62.6936 71.8232 62.2939 73.7125 62.2939C76.0924 62.2939 78.1452 62.9479 79.871 64.2559C80.1435 64.4739 80.3343 64.6919 80.4433 64.9099C80.5523 65.1279 80.6068 65.4004 80.6068 65.7274C80.6068 66.1998 80.4705 66.6085 80.198 66.9537C79.9437 67.2807 79.6258 67.4442 79.2443 67.4442C78.99 67.4442 78.7629 67.4169 78.563 67.3624C78.3814 67.2898 78.1725 67.1717 77.9363 67.0082C77.2096 66.5359 76.5375 66.1998 75.9198 65.9999C75.3021 65.7819 74.639 65.6729 73.9305 65.6729C72.1865 65.6729 70.8695 66.2179 69.9793 67.3079C69.1073 68.3798 68.6713 69.9875 68.6713 72.1312C68.6713 76.4549 70.4244 78.6167 73.9305 78.6167C74.6027 78.6167 75.2385 78.5168 75.838 78.3169C76.4375 78.0989 77.137 77.7538 77.9363 77.2814C78.2088 77.1179 78.4359 77.0089 78.6175 76.9544C78.7992 76.8818 79.0081 76.8454 79.2443 76.8454C79.6258 76.8454 79.9437 77.018 80.198 77.3632C80.4705 77.6902 80.6068 78.0899 80.6068 78.5622C80.6068 78.8892 80.5432 79.1708 80.416 79.4069C80.307 79.6249 80.1254 79.8339 79.871 80.0337C78.1452 81.3417 76.0924 81.9957 73.7125 81.9957Z"
        fill="#828282"
      />
    </svg>
  );
};

export default StorageBigDocSvg;
