import React from "react";
import {
  Box,
  Button,
  MenuItem,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import {Theme} from "@mui/material/styles";
import constants from "styles/constants";
import DeededSpace from "v2/components/DeededSpace";
import DeededSelect from "v2/components/DeededSelect_DEPRECATED";
import {addRemoveDayFromSelected} from "../../utils/addRemoveDayFromSelected";
import {
  DaysOfMonthNumbersArray,
  DaysOfWeekNumbersArray,
  MonthsOfYearNumbersArray,
} from "../../utils/listOfConstantsDateValues";
import {addRemoveWeekDayFromSelected} from "../../utils/addRemoveWeekDayFromSelected";

interface CloseCalendarLapseV2RepeatProps {
  repeat: string;
  handleChange: (someToChange: unknown) => void;
  repeatEveryDays: string | number;
  repeatEveryWeeks: string | number;
  repeatEveryMonths: string | number;
  repeatEveryYears: string | number;
  selectedMothsOfYear: number[];
  selectedDaysOfMonth: number[];
  selectedDaysOfWeek: string[];
}

const CloseCalendarLapseV2Repeat: React.FC<CloseCalendarLapseV2RepeatProps> = ({
  selectedMothsOfYear,
  selectedDaysOfMonth,
  selectedDaysOfWeek,
  repeatEveryYears,
  repeatEveryMonths,
  repeatEveryWeeks,
  repeatEveryDays,
  repeat,
  handleChange,
}) => {
  const theme = useTheme();
  const selectedCellStyles = {
    border: `1px solid ${constants.colors.red}`,
    color: constants.colors.red,
    fontWeight: 600,
    backgroundColor: constants.colors.redVeryPale,
  };
  return (
    <>
      <Box>
        <Typography
          sx={{
            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "16px",
            lineHeight: "20px",
            display: "flex",
            alignItems: "center",
            color: constants.colors.title,
          }}
        >
          Custom repeat
        </Typography>
        <DeededSpace y={23} />
        <Box sx={{display: "flex", alignItems: "center"}}>
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: "13px",
              lineHeight: "17px",
            }}
          >
            Repeat
          </Typography>
          <DeededSpace x={20} />
          <DeededSelect
            data-selenium-id="repeat-type-select"
            value={repeat}
            onChange={(e) => {
              handleChange({
                target: {
                  name: "repeat",
                  value: e.target.value,
                },
              });
            }}
            sx={{
              width: "144px",
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: "13px",
              lineHeight: "17px",
              "& .MuiMenuItem-root": {
                [theme.breakpoints.down("sm")]: {
                  display: "flex !important",
                },
              },
              [theme.breakpoints.down("sm")]: {
                height: "36px !important",
                "& .MuiSelect-select": {
                  padding: "2px 14px",
                },
              },
            }}
          >
            <FlexibleMenuItem value={"Daily"}>Daily</FlexibleMenuItem>
            <FlexibleMenuItem value={"Weekly"}>Weekly</FlexibleMenuItem>
            <FlexibleMenuItem value={"Monthly"}>Monthly</FlexibleMenuItem>
            <FlexibleMenuItem value={"Yearly"}>Yearly</FlexibleMenuItem>
          </DeededSelect>
        </Box>

        {repeat === "Weekly" && (
          <>
            <DeededSpace y={16} />
            <Box
              sx={{
                display: "flex",
              }}
            >
              {" "}
              {DaysOfWeekNumbersArray.map((dayOfWeek) => {
                return (
                  <SortButton
                    repeat={repeat}
                    data-selenium-id="sort-button"
                    onClick={() => {
                      addRemoveWeekDayFromSelected(
                        selectedDaysOfWeek,
                        dayOfWeek.toSend,
                        "selectedDaysOfWeek",
                        handleChange,
                      );
                    }}
                    sx={
                      selectedDaysOfWeek?.includes(dayOfWeek.toSend)
                        ? selectedCellStyles
                        : {}
                    }
                  >
                    {dayOfWeek.toDisplay}
                  </SortButton>
                );
              })}
            </Box>
          </>
        )}
        {repeat === "Monthly" && (
          <>
            <DeededSpace y={16} />
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              {DaysOfMonthNumbersArray.map((dayOfMonth) => {
                return (
                  <SortButton
                    repeat={repeat}
                    data-selenium-id="sort-button"
                    onClick={() => {
                      addRemoveDayFromSelected(
                        selectedDaysOfMonth,
                        dayOfMonth,
                        "selectedDaysOfMonth",
                        handleChange,
                      );
                    }}
                    sx={
                      selectedDaysOfMonth?.includes(dayOfMonth)
                        ? selectedCellStyles
                        : {}
                    }
                  >
                    {dayOfMonth}
                  </SortButton>
                );
              })}
            </Box>
          </>
        )}
        {repeat === "Yearly" && (
          <>
            <DeededSpace y={16} />
            <Box>
              {MonthsOfYearNumbersArray.map((monthOfYear) => {
                return (
                  <SortButton
                    repeat={repeat}
                    data-selenium-id="sort-button-year"
                    onClick={() => {
                      addRemoveDayFromSelected(
                        selectedMothsOfYear,
                        monthOfYear.id,
                        "selectedMothsOfYear",
                        handleChange,
                      );
                    }}
                    sx={
                      selectedMothsOfYear?.includes(monthOfYear.id)
                        ? {
                            ...selectedCellStyles,
                            width: "107px",
                          }
                        : {width: "107px"}
                    }
                  >
                    {monthOfYear.name}
                  </SortButton>
                );
              })}
            </Box>
          </>
        )}
      </Box>
    </>
  );
};
const FlexibleMenuItem = styled(MenuItem)<{theme?: Theme}>(({theme}) => ({
  [theme.breakpoints.down("sm")]: {
    display: "flex !important",
  },
}));
const DescriptionTypography = styled(Typography)({
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "13px",
  lineHeight: "17px",
  color: constants.colors.descriptionTitle,
});
const SortButton = styled(Button)<{theme?: Theme; repeat: string}>(
  ({theme, repeat}) => ({
    border: "1px solid #D8D8D8",
    borderRadius: 0,
    width: "61px",
    height: "44px",
    minWidth: "61px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "13px",
    lineHeight: "17px",
    textAlign: "center",
    textTransform: "capitalize",
    color: constants.colors.descriptionTitle,
    "&:hover": {
      border: `1px solid ${constants.colors.red}`,
      color: constants.colors.red,
      fontWeight: 600,
      backgroundColor: constants.colors.redVeryPale,
    },
    [theme.breakpoints.down("sm")]: {
      width:
        repeat === "Weekly"
          ? "calc(100% / 7)"
          : repeat === "Monthly"
          ? "calc(100% / 7)"
          : repeat === "Yearly"
          ? "calc(100% / 4)"
          : "61px",
      minWidth: "unset",
    },
  }),
);
export default CloseCalendarLapseV2Repeat;
