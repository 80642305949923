import React from "react";
import {Box} from "@mui/material";
import constants from "styles/constants";
import useWindowWidth from "../../../utils/hooks/useWindowWidth";
import {horizontalLargePhoneDealStages} from "components/Dashboard/Config/constants/breakpoints";

interface ServiceTypeProps {
  value: string;
  stage: any;
  stageId: number;
  handleInputValue: (stageId: number, inputValue: string) => void;
  handleSetCurrentType: (stage: any) => void;
  customSize?: boolean;
  currentStage?: number;
}
const ServiceTypeNew = ({
  value,
  stage,
  stageId,
  currentStage,
  handleInputValue,
  handleSetCurrentType,
  customSize,
}: ServiceTypeProps) => {
  const getValue = (val: string) => {
    handleInputValue(stageId, val);
  };
  const windowWidth = useWindowWidth();
  const isHorizontalLargePhone = windowWidth < horizontalLargePhoneDealStages;
  return (
    <Box
      sx={(theme) => ({
        border:
          stageId === currentStage
            ? "1px solid #02cd8f"
            : "1px solid var(--gray)",
        borderRadius: "0.5rem",
        padding: "0.5rem",
        display: "flex",
        alignItems: "center",
        marginRight: "2rem",
        marginBottom: "3rem",
        width: isHorizontalLargePhone
          ? "23rem"
          : customSize
          ? "24rem"
          : "27rem",
        [theme.breakpoints.down("sm")]: {
          width: "100%",
          height: "48px !important",
          backgroundColor: constants.colors.white,
          marginBottom: "16px",
          input: {
            height: "calc(100% - 0.5rem) !important",
            fontSize: "14px !important",
          },
        },
      })}
    >
      <input
        style={{
          border: "none",
          flex: 1,
          fontFamily: "Montserrat",
          fontStyle: "normal",
          fontWeight: "300",
          fontSize: "12px",
          lineHeight: "15px",
          display: "inline-block",
          height: "auto",
          padding: "0.5rem",
          outline: "none",
        }}
        key={stage.id}
        value={value}
        onChange={(e) => {
          getValue(e.target.value);
        }}
        onFocus={(e) => handleSetCurrentType(stage)}
      />
    </Box>
  );
};
export default ServiceTypeNew;
