import { FC } from "react";
import Stack from "@mui/material/Stack";
import { AlertIcon } from "../Icons";
import Colors from "../Styling/Colors";
import { BodyRegular, SubtitleRegular } from "../Typography";
import { SxProps, styled } from '@mui/material/styles';

type AlertPanelProps = {
    message?: string,
    title?: string,
    variant?: 'warning',
    sx?: SxProps,
    showIcon?: boolean
};

const AlertPanel: FC<AlertPanelProps> = ({ message, title, showIcon = true, sx }) => {
    return <StyledPanel sx={sx}>
        {showIcon && <Stack sx={{ backgroundColor: Colors.PRIMARY_50, borderRadius: '50%', padding: '0.8rem' }}>
            <AlertIcon size='small' />
        </Stack>}
        <StyledContent>
            <SubtitleRegular color={Colors.NEUTRAL_700}>{title}</SubtitleRegular>
            <BodyRegular color={Colors.NEUTRAL_700}>{message}</BodyRegular>
        </StyledContent>
    </StyledPanel>
};

const StyledPanel = styled(Stack)(({ theme }) => ({
    flexDirection: 'row',
    padding: '2.4rem',
    alignItems: 'flex-start',
    gap: '1.6rem',
    backgroundColor: Colors.PRIMARY_100,
    borderRadius: '1.6rem',
    [theme.breakpoints.down('mobile')]: {
        flexDirection: 'column',
        justifyContent: 'center',
    }
}));

const StyledContent = styled(Stack)(({ theme }) => ({
    flexDirection: 'column',
    gap: '.8rem',
    marginTop: '.3rem',
    [theme.breakpoints.down('mobile')]: {
        margin: 0,
    }
}));
export default AlertPanel;