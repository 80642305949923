import React, {useContext} from "react";
import {ProfileSecurityPageMtfAuthenticationContext} from "./ProfileSecurityPageMtfAuthentication";
import DeededPopup from "v2/components/DeededPopup";
import {
  Box,
  Button,
  IconButton,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import CloseDeededSvg from "../../../../../components/Svg/CloseDeededSvg";
import constants from "../../../../../styles/constants";
import {useEnablePopupScroll} from "../../../../../utils/hooks/useEnablePopupScroll";

const ProfileSecurityPageMtfAuthenticationSetUpCompletePopup = () => {
  const theme = useTheme();
  const {isOpenedSetUpCompletePopup, setIsOpenedSetUpCompletePopup} =
    useContext(ProfileSecurityPageMtfAuthenticationContext);
  useEnablePopupScroll(isOpenedSetUpCompletePopup);
  return (
    <>
      <DeededPopup
        open={isOpenedSetUpCompletePopup}
        setOpen={setIsOpenedSetUpCompletePopup}
        sxInternalBox={{
          width: "329px",
          height: "426px",
          [theme.breakpoints.down("sm")]: {
            width: "329px",
          },
        }}
      >
        <Box
          sx={{
            position: "relative",
          }}
        >
          <CloseBtn
            onClick={() => {
              setIsOpenedSetUpCompletePopup(false);
            }}
          >
            <CloseDeededSvg />
          </CloseBtn>
          <Typography
            sx={{
              color: constants.colors.sortingColor,
              fontSize: "18px",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            Set Up Complete
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              color: constants.colors.intakeTitle,
              textAlign: "center",
              marginTop: "6px",
            }}
          >
            Your MFA is now enabled. You will be asked to enter your
            authentication code next time you sign in
          </Typography>
          <Box
            sx={{
              marginTop: "24px",
            }}
          >
            <CheckSvg />
          </Box>
          <Typography
            sx={{
              fontSize: "14px",
              color: constants.colors.intakeTitle,
              textAlign: "center",
              marginTop: "24px",
            }}
          >
            Contact your administrator to disable your MFA at any time
          </Typography>
          <DoneButton
            onClick={() => {
              setIsOpenedSetUpCompletePopup(false);
            }}
          >
            Done
          </DoneButton>
        </Box>
      </DeededPopup>
    </>
  );
};
const CheckSvg = () => {
  return (
    <svg
      width="112"
      height="112"
      viewBox="0 0 112 112"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="56" cy="56" r="56" fill="#EAFCF5" />
      <circle cx="56" cy="56" r="40" fill="#35CF94" />
      <path
        d="M78 41.585L50 70.495L36 56.04"
        stroke="white"
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
const DoneButton = styled(Button)({
  marginTop: "32px",
  fontWeight: "600",
  fontSize: "14px",
  backgroundColor: constants.colors.redVeryPale,
  color: constants.colors.red,
  height: "40px",
  width: "150px",
  textTransform: "capitalize",
  borderRadius: "5px",
  marginRight: "16px",
  "&:hover": {
    backgroundColor: constants.colors.pinkPale,
  },
});
const CloseBtn = styled(IconButton)({
  position: "absolute",
  minWidth: "0px",
  top: "-20px",
  right: "-10px",
  transition: `${constants.transitions} !important`,
  "&:hover": {
    transition: `${constants.transitions} !important`,
  },
});
export default ProfileSecurityPageMtfAuthenticationSetUpCompletePopup;
