import React, {useState} from "react";
import moment, {Moment} from "moment";
import {Box, IconButton, styled, Typography} from "@mui/material";
import constants from "styles/constants";
import {getMonthArrayByDate} from "../utils/getMonthArrayByDate";
import CalendarMobileChangeDateArrow from "../svg/CalendarMobileChangeDateArrow";

const CalendarMobileWeekMonth: React.FC<{
  view: string;
  selectedDate: Moment | null;
  onDateChange: (newCurrentDate: Moment | null) => void;
}> = ({selectedDate, onDateChange, view}) => {
  const [currentDate, setCurrentDate] = useState(selectedDate ?? moment());
  const setSelectedDate = (newCurrentDate: Moment) => {
    onDateChange(newCurrentDate);
    setCurrentDate(newCurrentDate);
  };
  const getWeekArrayByDate = (currentDateForWeekArray: Date) => {
    const startOfWeekDateMoment = moment(currentDateForWeekArray).startOf("w");
    let dateForMonth = startOfWeekDateMoment.clone().subtract(1, "day");
    return [
      Array(7)
        .fill(0)
        .map(() => dateForMonth.add(1, "day").clone()) as unknown as Moment[],
    ];
  };
  const getCalendarShowingDaysArray = () => {
    if (view === "month") {
      return getMonthArrayByDate(currentDate.toDate());
    } else {
      return getWeekArrayByDate(currentDate.toDate());
    }
  };
  const calendarShowingDaysArray: Moment[][] = getCalendarShowingDaysArray();
  const dayNamesArray = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const handleDateChange = (move: "next" | "back") => {
    if (move === "next") {
      if (view === "month") {
        setSelectedDate(currentDate.clone().add(1, "M"));
      } else if (view === "week") {
        setSelectedDate(currentDate.clone().add(7, "d"));
      } else {
        setSelectedDate(currentDate.clone().add(1, "d"));
      }
    } else {
      if (view === "month") {
        setSelectedDate(currentDate.clone().add(-1, "M"));
      } else if (view === "week") {
        setSelectedDate(currentDate.clone().add(-7, "d"));
      } else {
        setSelectedDate(currentDate.clone().add(-1, "d"));
      }
    }
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: constants.colors.white,
          paddingRight: "24px",
          paddingLeft: "24px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "23px",
          }}
        >
          <ChangeDateArrowButton
            onClick={() => {
              handleDateChange("back");
            }}
          >
            <CalendarMobileChangeDateArrow />
          </ChangeDateArrowButton>
          <Typography
            sx={{
              fontSize: "20px",
              color: constants.colors.intakeTitle,
              fontWeight: "600",
            }}
          >
            {currentDate.format("MMMM YYYY")}
          </Typography>
          <ChangeDateArrowButton
            onClick={() => {
              handleDateChange("next");
            }}
            sx={{
              transform: "rotate(180deg)",
            }}
          >
            <CalendarMobileChangeDateArrow />
          </ChangeDateArrowButton>
        </Box>
        <CalendarGridBox>
          {dayNamesArray.map((nameDay, indexDayName) => {
            return (
              <DayNameTypography sx={{}} key={indexDayName}>
                {nameDay}
              </DayNameTypography>
            );
          })}
        </CalendarGridBox>
        {calendarShowingDaysArray.map((week, indexWeek) => {
          return (
            <CalendarGridBox
              sx={{
                marginTop: "16px",
              }}
              key={indexWeek}
            >
              {week.map((day, indexDay) => {
                const formatToCompare = "MM/DD/YYYY";
                const isActive =
                  day.format(formatToCompare) ===
                  selectedDate?.format(formatToCompare);
                const isCurrentMonth =
                  day.format("M") === currentDate.format("M");
                return (
                  <DateCell
                    disabled={!isCurrentMonth && view === "month"}
                    sx={{
                      margin: "0 auto",
                    }}
                    key={indexDay}
                    onClick={() => {
                      if (isCurrentMonth || view !== "month") {
                        setSelectedDate(day);
                      }
                    }}
                    isActive={isActive}
                    isCurrentMonth={isCurrentMonth}
                    view={view}
                  >
                    {day.format("D").toString()}
                  </DateCell>
                );
              })}
            </CalendarGridBox>
          );
        })}
      </Box>
    </>
  );
};

const ChangeDateArrowButton = styled(IconButton)({
  borderRadius: "5px",
  backgroundColor: constants.colors.redVeryPale,
  width: "32px",
  height: "32px",
});
const DateCell = styled(IconButton, {
    shouldForwardProp: (propName) => !["isActive", "isCurrentMonth", "view"].includes(propName.toString()),
})<{
    isActive: boolean;
    isCurrentMonth: boolean;
    view: string;
}>(({ isActive, isCurrentMonth, view }) => ({
    minWidth: 0,
    width: "32px",
    height: "32px",
    backgroundColor: isActive ? constants.colors.red : "transparent",
    borderRadius: "5px",
    fontSize: "16px",
    fontWeight: isActive ? "500" : "400",
    cursor: isCurrentMonth || view !== "month" ? "pointer" : "inherit",
    color: isActive
        ? constants.colors.white
        : isCurrentMonth || view !== "month"
          ? constants.colors.intakeTitle
          : constants.colors.grayBorder,
    "&:hover": {
        color: isActive ? constants.colors.white : constants.colors.red,
        backgroundColor: isActive ? constants.colors.red : constants.colors.redVeryPale,
    },
    "&.Mui-disabled": {
        color: constants.colors.grayBorder,
    },
}));
const CalendarGridBox = styled(Box)({
  width: "100%",
  display: "grid",
  gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 1fr",
});
const DayNameTypography = styled(Typography)({
  height: "25px",
  fontSize: "16px",
  width: "100%",
  margin: "0 auto",
  borderBottom: `1px solid ${constants.colors.grayBorder}`,
  color: constants.colors.deededGray,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});
export default CalendarMobileWeekMonth;
