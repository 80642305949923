import { FC } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { styled } from "@mui/material";
import Stack from "@mui/material/Stack";
import useUser from "utils/hooks/useUser";
import storage from "utils/storage";
import {
    isAdmin,
    isProfessional,
    shouldShowCalendarInSidebar,
    shouldShowStorageInSidebar,
} from "utils/permissionRoles";
import configuration from "utils/configuration";
import { LogOut } from "redux/actions/auth";
import { version } from "../../../../package.json";
import DeededSVG from "components/Svg/Deeded";
import Colors from "components/CommonDashboard/Styling/Colors";
import { Caption } from "components/CommonDashboard/Typography";
import {
    BankIcon,
    BuildingIcon,
    CalendarIcon,
    DocumentIcon,
    FolderIcon,
    HomeIcon,
    HubIcon,
    LogOutIcon,
    SettingsIcon,
    UserIcon,
} from "components/CommonDashboard/Icons";
import NavLink from "components/CommonDashboard/NavLink";
import { HEADER_SIZE } from "components/Dashboard/Header/Header";
import SidebarButton from "./SidebarButton";
import PointsBanner from "./PointsBanner";

interface SidebarProps {
    conveyanceEnabled?: boolean;
    onRequestClose: () => void;
    open: boolean;
}

const Sidebar: FC<SidebarProps> = ({ conveyanceEnabled, open, onRequestClose }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { user } = useUser();

    const handleLogout = () => {
        storage.previousUserId = user.id?.toString() ?? null;

        dispatch(LogOut());
        localStorage.removeItem("current_page");
        storage.targetLink = "";
        storage.removeUserId();
        storage.removeToken();

        history.replace("/login");
    };

    return (
        <Wrapper data-testid="sidebar" open={open}>
            <Content onClick={onRequestClose}>
                <Link data-testid="sidebar-deeded-logo" to="/">
                    <DeededSVG color={Colors.PRIMARY_600} />
                </Link>
                <ScrollableContent>
                    <Stack gap="1.6rem">
                        <SidebarButton
                            label="Home"
                            to="/dashboard"
                            Icon={HomeIcon}
                            visible={isProfessional(user.role)}
                        />
                        <SidebarButton label="My transactions" to="/dashboard/deals" Icon={BuildingIcon} />
                        <SidebarButton
                            label="Closing cost estimator"
                            to="/dashboard/quote"
                            Icon={BankIcon}
                            visible={isProfessional(user.role)}
                        />
                        <SidebarButton
                            label="Partner HUB"
                            to={configuration.partnerHubUrl}
                            Icon={HubIcon}
                            visible={isProfessional(user.role)}
                            isExternalLink
                        />
                        <SidebarButton
                            label="Storage"
                            to="/dashboard/storage"
                            Icon={FolderIcon}
                            visible={shouldShowStorageInSidebar(user.role)}
                        />
                        <SidebarButton
                            label="Calendar"
                            to="/dashboard/calendar"
                            Icon={CalendarIcon}
                            visible={shouldShowCalendarInSidebar(user.role)}
                        />
                        <SidebarButton label="My Profile" to="/dashboard/profile" Icon={UserIcon} />
                        <SidebarButton
                            label={user.role === "Admin" ? "Professionals" : "Config"}
                            to="/dashboard/config"
                            Icon={SettingsIcon}
                            visible={isAdmin(user.role)}
                        />
                        <SidebarButton
                            label="Conveyance"
                            to="/dashboard/conveyance/conveyance-deals"
                            Icon={DocumentIcon}
                            visible={conveyanceEnabled}
                        />
                    </Stack>

                    <Stack gap="2.4rem">
                        <PointsBanner />
                        <NavLink onClick={handleLogout} label={{ text: "Log out" }} startIcon={<LogOutIcon />} />

                        <Caption color={Colors.NEUTRAL_500}>V{version}</Caption>
                    </Stack>
                </ScrollableContent>
            </Content>
        </Wrapper>
    );
};

const Wrapper = styled(Stack, {
    shouldForwardProp: (prop) => prop !== "open",
})<{ open: boolean }>(({ theme, open }) => ({
    width: "27rem",
    minWidth: "27rem",
    [theme.breakpoints.down("mobile")]: {
        display: open ? "flex" : "none !important",
    },
}));

const Content = styled(Stack)(({ theme }) => ({
    top: 0,
    left: 0,
    bottom: 0,
    position: "fixed",
    width: "27rem",
    minWidth: "27rem",
    display: "flex",
    padding: "2.4rem 1.2rem 2.4rem 2.4rem",
    zIndex: 99,
    justifyContent: "space-between",
    gap: "4.8rem",
    borderRight: `1px solid ${Colors.NEUTRAL_300}`,
    backgroundColor: Colors.WHITE,
    [theme.breakpoints.down("mobile")]: {
        top: HEADER_SIZE.mobile,
        gap: "1.6rem",
        padding: "2.4rem 1.2rem 2.4rem 2.4rem",
        width: "100%",
    },
}));

const ScrollableContent = styled(Stack)(({ theme }) => ({
    flex: 1,
    height: "100%",
    minHeight: 0,
    paddingRight: "1.2rem",
    justifyContent: "space-between",
    gap: "1.6rem",
    overflowY: "auto",
    [theme.breakpoints.down("mobile")]: {
        paddingRight: "1.2rem",
    },
}));

export default Sidebar;
