import React from "react";
import {Box, Typography} from "@mui/material";
import useTypedSelector from "utils/hooks/useTypedSelector";
import constants from "styles/constants";
import DeededSpace from "v2/components/DeededSpace";
import BasicInfoDealType from "./BasicInfoDealType";

const DealBasicInfo = () => {
  const dealFullInfo = useTypedSelector(
    (state) => state.CalendarReducer.dealFullInfo,
  );
  return (
    <>
      <Box sx={{display: "flex"}}>
        <Typography
          sx={{
            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "14px",
            color: "#9F9F9F",
            lineHeight: "17px",
            display: "flex",
            alignItems: "center",
            marginLeft: "2px",
          }}
        >
          <span style={{fontWeight: "bold", marginRight: "3px"}}>
            {dealFullInfo?.completed_stages[
              dealFullInfo?.completed_stages.length - 1
            ]?.deal_stage.number
              ? "0" +
                dealFullInfo?.completed_stages[
                  dealFullInfo?.completed_stages.length - 1
                ]?.deal_stage.number
              : ""}
          </span>
          {" " + dealFullInfo?.deal_stage.name}
        </Typography>
        <DeededSpace x={27} />
        <Typography
          sx={{
            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "14px",
            display: "flex",
            alignItems: "center",
            color: constants.colors.green,
          }}
        >
          Received
        </Typography>
        <DeededSpace x={8} />
        <Typography
          sx={{
            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "14px",
            display: "flex",
            alignItems: "center",
            color: constants.colors.deededGray,
          }}
        >
          {
            dealFullInfo?.completed_stages[
              dealFullInfo?.completed_stages.length - 1
            ]?.stage_reached
          }
        </Typography>
      </Box>
      <DeededSpace y={30} />
      <BasicInfoDealType />
    </>
  );
};

export default DealBasicInfo;
