import React, {useEffect, useState} from "react";
import "./DocumentView.scss";
import {useDispatch, useSelector} from "react-redux";
import {useMixpanel} from "../../../utils/MixpanelContext";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {makeStyles} from "@mui/styles";
import MenuItem from "@mui/material/MenuItem";
// SVG
import CrossSVG from "../../Svg/Cross";
import DownloadDocSVG from "../../Svg/DownloadDoc";

import DocumentFrameDisplay from "./DocumentFrameDisplay";
import useDocumentDownload from "./useDocumentDownload";
import {setIsFolderSelectedAC} from "../../../redux/reducers/storageReducer";

/**
 * Please, refer to useDocumentViewHandlers return value for the props description
 *
 * @param {Object & {folder: string; setFolder: React.Dispatch<React.SetStateAction<string>>}} props
 */
const DocumentViewDisplay = ({
  title,
  onClose,
  documentType,
  documentStatus,
  fileData,
  onApprove,
  onReject,
  onDelete,
  onCommentUpdate,
  onRejectionReasonUpdate,
  rejectionReason,
  downloadData,
  showHeader,
  dealID,
  dealType,
  documentUpdates,

  // New Stuff
  folder,
  setFolder,
  newName,
  setNewName,
}) => {
  const dispatch = useDispatch();
  let isFolderSelected = useSelector(
    (state) => state.StorageReducer.isFolderSelected,
  );

  let defaultFolder;
  isFolderSelected = true;
  let borderStyleForFolderSelect =
    isFolderSelected === null
      ? "1px solid #e3e3e3"
      : isFolderSelected === true
      ? "1px solid #e3e3e3"
      : isFolderSelected === false
      ? "1px solid #F16764"
      : "";
  borderStyleForFolderSelect =
    documentUpdates?.[documentUpdates?.length - 1]?.doc_type === folder
      ? "1px solid #F16764"
      : borderStyleForFolderSelect;
  const classes = useStyles({borderStyleForFolderSelect});

  const [errors, setErrors] = useState({ rejectionReason: false});
  useEffect(() => {
    return () => {
      dispatch(setIsFolderSelectedAC(null));
    };
  }, [dispatch]);

  const onDocumentDownload = useDocumentDownload(
    downloadData.name,
    documentType,
    null,
    downloadData.link,
  );
  const mixpanel = useMixpanel();
  const currUser = useSelector((state) => state.AuthReducer.currentUser);
  const dealTypeFolders = useSelector(
    (state) => state.DealsReducer.folders_for_deal_type,
  );

  const permissionList = [
    "Admin",
    "System Admin",
    "Lawyer",
    "Law Clerk",
    "Mobile Signer",
    "Closing Advisor",
  ];
  const userPermission = permissionList.find((el) => el === currUser.role);

  const handleChange = (event) => {
    setFolder(event.target.value);
  };
  let defaultCommentValue = "";
  if (documentUpdates !== null) {
    defaultCommentValue =
      documentUpdates?.[documentUpdates.length - 1]?.comment ?? "";
    defaultFolder =
      documentUpdates[documentUpdates.length - 1]?.doc_type ?? "Folder";
  }
  return (
    <div className="document__view__container">
      {showHeader && (
        <div className="header">
          <div className="view__title">{title}</div>
          <div className="close__btn" onClick={onClose}>
            <CrossSVG color={"#000000"} />
          </div>
        </div>
      )}
      <div className="document__view__content">
        <DocumentFrameDisplay fileData={fileData} type={documentType} />
      </div>

      <div className="document__view__download">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a className="download_file" onClick={onDocumentDownload} href="#">
          Download Document
        </a>
        <div>
          <DownloadDocSVG />
        </div>
      </div>

      {documentUpdates && (
        <ul className="document__view__actions">
          {documentUpdates.map((document, idx) => {
            const {created_at, action} = document;
            const name = document?.user?.full_name ?? "N/A";

            return (
              <li key={idx}>
                <span>
                  {" "}
                  {action} by {name}
                </span>
                <span>{new Date(created_at).toLocaleString("en-US")}</span>
              </li>
            );
          })}
        </ul>
      )}

      {documentStatus === "Pending" ||
      documentStatus === "Rejected" ||
      documentStatus === "Approved" ? (
        <>
          <div className={"renameFileDuringApprove"}>
            <input
              value={newName}
              onChange={(e) => {
                setNewName(e.target.value);
              }}
              className={"renameFileDuringApproveInput"}
            />
          </div>
          <FormControl className={classes.formControl}>
            <Select
              value={folder?.length > 0 ? folder : defaultFolder}
              onChange={handleChange}
              displayEmpty
              className={classes.selectEmpty}
              inputProps={{"aria-label": "Without label"}}
            >
              {defaultFolder === null && (
                <MenuItem
                  style={{fontSize: 20, fontFamily: "Montserrat"}}
                  value=""
                  disabled
                >
                  Folder
                </MenuItem>
              )}
              {dealTypeFolders.map((u) => {
                return (
                  <MenuItem className={classes.folders} value={u}>
                    {u}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <div className="document__view__comments">
            <label htmlFor="reason">
              Comment
              <textarea
                name="reason"
                className="text"
                onChange={(e) => {
                  if (e.target.value.length > 190) {
                    e.target.disabled = true;
                    return;
                  }
                  onCommentUpdate(e.target.value);
                }}
                onClick={(e) => (e.target.disabled = false)}
              >
                {defaultCommentValue}
              </textarea> 
            </label>
            <label htmlFor="rejection_reason">
              Reason for Rejection
              <textarea
                name="rejection_reason"
                className={`text ${!errors.rejectionReason || 'error'}`}
                maxLength={600}
                onChange={(e) => {
                  if (e.target.value) setErrors({rejectionReason: false});
                  onRejectionReasonUpdate(e.target.value)
                }}
                onClick={(e) => (e.target.disabled = false)}
              >
                {rejectionReason || ''}
              </textarea> 
              {errors.rejectionReason && <p className="error">Please enter a rejection reason.</p>}
            </label>
            <div className="buttons">
              {userPermission && (
                <button
                  className="approve"
                  onClick={() => {
                    // Send data for mixpanel analytics for Admin and Sub-admin

                    const {role, email, full_name} = currUser;
                    const mixPayload = {
                      role,
                      email,
                      full_name,
                      deal_type: dealType,
                      deal_id: dealID,
                      document_title: title,
                    };
                    mixpanel.track(`${role}_approve_document`, mixPayload);

                    onApprove();
                  }}
                >
                  approve
                </button>
              )}
              {userPermission && (
                <button
                  className="reject"
                  onClick={() => {
                    // Send data for mixpanel analytics for Admin and Sub-admin

                    const {role, email, full_name} = currUser;
                    const mixPayload = {
                      role,
                      email,
                      full_name,
                      deal_type: dealType,
                      deal_id: dealID,
                      document_title: title,
                    };
                    mixpanel.track(`${role}_reject_document`, mixPayload);
                    if (!rejectionReason) {
                      return setErrors({rejectionReason: true});
                    }
                    onReject();
                  }}
                >
                  reject
                </button>
              )}
              {currUser.role === "Admin" || currUser.role === "System Admin" ? (
                <button className="delete" onClick={onDelete}>
                  delete
                </button>
              ) : null}
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    "& :hover": {
      outline: "none !important",
    },
    "& :foccus-visible": {
      outline: "none !important",
    },
    outline: "none !important",
    margin: theme.spacing(1),
    minWidth: 120,
    marginLeft: 0,
    width: "100%",
    height: 40,
    marginBottom: 25,
    "& .MuiInputBase-input": {
      fontSize: "1.2rem",
      fontFamily: "Montserrat",
      fontWeight: 600,
      paddingTop: 10,
      paddingBottom: 10,
      paddingLeft: 10,
    },
    "& .MuiInputBase-root:hover": {
      border: "1px solid #e3e3e3!important",
      outline: "none !important",
      zIndex: 1000,
    },
    "& .MuiInputBase-root": {
      border: ({borderStyleForFolderSelect}) => borderStyleForFolderSelect,
      borderRadius: 5,
      outline: "none",
    },
    "& .MuiInputBase-root:after:hover": {
      border: "none",
    },
    "& .MuiInputBase-root:before:hover": {
      border: "none",
    },
    "& .MuiInput-underline:before": {
      border: "none!important",
      outline: "none",
    },
    "& .MuiInput-underline:before:hover": {
      border: "none",
      outline: "none",
    },
    "& .MuiInput-underline:after": {
      border: "none",
      outline: "none",
    },
    "& .MuiInput-underline:hover": {
      border: "none",
      outline: "none",
    },
    "& .MuiInputBase-root:focusVisible": {
      outline: "none ",
    },
    "& .MuiSelect-outlined:hover": {
      outline: "none",
    },
    "& .MuiInput-underline": {},
  },
  "& .MuiInputBase-root:focusVisible": {
    outline: "none !important",
  },

  folders: {
    fontSize: "20px",
    fontFamily: "Montserrat",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default DocumentViewDisplay;
