import React from "react";

const EditItemNewSvg = ({color}: {color: string}) => {
  return (
    <svg
      className={"EditItemNewSvg"}
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 5.125H3.75C3.40482 5.125 3.125 5.40482 3.125 5.75V18.25C3.125 18.5952 3.40482 18.875 3.75 18.875H16.25C16.5952 18.875 16.875 18.5952 16.875 18.25V14"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.086 15.5157H7.03125C6.88621 15.5157 6.74711 15.458 6.64455 15.3555C6.54199 15.2529 6.48438 15.1138 6.48438 14.9688V11.9141C6.48437 11.8422 6.49852 11.7711 6.526 11.7048C6.55349 11.6384 6.59377 11.5781 6.64455 11.5274L14.8477 3.32424C14.9502 3.22168 15.0893 3.16406 15.2344 3.16406C15.3794 3.16406 15.5185 3.22168 15.6211 3.32424L18.6758 6.37897C18.7784 6.48152 18.836 6.62062 18.836 6.76566C18.836 6.9107 18.7784 7.0498 18.6758 7.15236L10.4727 15.3555C10.4219 15.4063 10.3616 15.4466 10.2953 15.474C10.2289 15.5015 10.1578 15.5157 10.086 15.5157Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.0469 5.125L16.875 8.95312"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default EditItemNewSvg;
