import React, {useContext, useEffect} from "react";
import {
  FileTasksFiled,
  setFileTaskValue,
} from "redux/reducers/fileTasksSlice";
import {useDispatch} from "react-redux";
import {Box, Typography} from "@mui/material";
import constants from "styles/constants";
//@ts-ignore
import Cleave from "cleave.js/react";
import {DealDetailsFileTasksContext} from "components/Dashboard/DealDetailsFileTasks/context/DealDetailsFileTasksContext";
import {useUpdateTaskValueMutation} from "redux/reducers/dealInfoApi";

const DealDetailsInputStageField: React.FC<{
  fieldIndex: number;
  field: FileTasksFiled;
}> = ({field, fieldIndex}) => {
  const [updateTaskValueMutationCallback] = useUpdateTaskValueMutation();
  const {dealId} = useContext(DealDetailsFileTasksContext);
  const dispatch = useDispatch();
  const handleChangeInput = (e: {target: {value: string}}) => {
    let valueToSet = e.target.value
      .replace("$", "")
      .replaceAll(" ", "")
      .replaceAll(",", "");
    if (valueToSet.length < 6) {
      dispatch(
        setFileTaskValue({
          fieldIndex,
          stageNumber: field.stage_number,
          value: e.target.value,
        }),
      );
    }
  };
  const handleBlur = (e: {target: {value: string}}) => {
    const valueToSend = e.target.value
      .replace("$", "")
      .replaceAll(" ", "")
      .replaceAll(",", "");
    updateTaskValueMutationCallback({
      dealId,
      fileTaskId: field.id,
      value: +valueToSend,
    });
  };
  return (
    <>
      <Box
        sx={{
          marginTop: "12px",
        }}
      >
        <Typography
          sx={{
            fontWeight: "600",
            fontSize: "16px",
            color: constants.colors.title,
            textTransform: "uppercase",
            borderBottom: `2px solid ${constants.colors.red}`,
            display: "inline-block",
            lineHeight: "20px",
            marginBottom: "10px",
            marginLeft: "8px",
          }}
        >
          {field?.name}
        </Typography>
        <Box
          sx={{
            width: "285px",
            input: {
              height: "36px",
              outline: "none",
              border: `1px solid ${constants.colors.grayBorder}`,
              borderRadius: "5px",
              paddingLeft: "15px",
              fontFamily: "Montserrat",
            },
          }}
        >
          <Cleave
            onBlur={handleBlur}
            style={{
              width: "100%",
              height: "36px !important",
            }}
            maxLength="8"
            name="condo_fee"
            value={field.value}
            placeholder="e.g. (example)"
            options={{
              numeral: true,
              prefix: "$",
              signBeforePrefix: true,
            }}
            onChange={handleChangeInput}
          />
        </Box>
        <Typography
          sx={{
            marginTop: "8px",
            fontSize: "12px",
            marginLeft: "8px",
            color: constants.colors.title,
          }}
        >
          Enter the fee charged by the broker
        </Typography>
      </Box>
    </>
  );
};
export default DealDetailsInputStageField;
