import { FC } from "react";
import { styled } from '@mui/material/styles';
import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Colors from "../Styling/Colors";
import { BUTTON_LARGE_TEXT, BUTTON_SMALL_TEXT, BUTTON_XLARGE_TEXT } from "../Styling/Styles"
import { LoadingIcon } from "../Icons";
import { borderRadius } from "react-select/src/theme";


export type ButtonProps = {
    /**
     * Button type (action).
     */
    typeOf: "CTA" | "primary" | "secondary" | "outline" | "link";
    /**
     * Button text.
     */
    label?: { text: string, inputId?: string };
    /**
     * The size of the component.
     */
    size?: "medium" | "small" | "large";
    /**
     * Id for tests
     */
    testId?: string;
    /**
     * Show loading animation
     */
    loading?: boolean;
    /**
     * Target tab (only for links)
     */
    target?: "_blank" | "_self" | "_top" | "_parent";
} & Omit<MuiButtonProps, 'touchRippleRef' | 'TouchRippleProps' | 'onFocusVisible' | 'focusVisibleClassName' | 'focusRipple' |
    'disableTouchRipple' | 'disableRipple' | 'centerRipple' | 'action' | 'variant' | 'children' | 'disableElevation' | 'disableFocusRipple'>;


const Button: FC<ButtonProps> = ({ typeOf, label, size = "medium", startIcon, endIcon, loading, testId, ...props }) => {
    return (
        <CustomButton
            typeOf={typeOf}
            size={size}
            label={label}
            data-testid={testId}
            disableRipple={typeOf === "link"}
            {...props}
        >
            <Box display={"inline-flex"} gap={typeOf === "link" ? "0.8rem" : "1rem"} alignItems="center">
                {startIcon && !loading && startIcon}
                {loading && <LoadingIcon size={size} color={Colors.WHITE} />}
                {label?.text && <ButtonText size={size}>{!loading ? label.text : "Processing"}</ButtonText>}
                {endIcon && endIcon}
            </Box>
        </CustomButton>
    );
};

const CustomButton = styled(MuiButton, {
    shouldForwardProp: (prop) => prop !== "typeOf" && prop !== "size" && prop !== "label"
})<ButtonProps>(({ typeOf, size, theme, label }) => ({
    ...getSizeMeasures(size, label),
    textTransform: "none",
    minWidth: 0,
    '&.Mui-disabled': {
        color: Colors.NEUTRAL_500,
        background: Colors.NEUTRAL_200,
        '& svg path': {
            fill: Colors.NEUTRAL_500,
        }
    },
    ...(getStyle(typeOf))
}));

const getSizeMeasures = (size: ButtonProps["size"], label: ButtonProps["label"]) => {
    switch (size) {
        case "large":
            return { padding: label?.text ? '1.5rem 3.2rem' : '12px', borderRadius: 16 };
        case "small":
            return { padding: label?.text ?  "1.3rem 2.4rem" : '10px', borderRadius: 12 };
        case "medium":
        default:
            return { padding:  label?.text ?  "1.3rem 1.6rem" : '12px', borderRadius: 16 };
    }
}

const getStyle = (type: ButtonProps["typeOf"]) => {
    switch (type) {
        case "primary":
            return PRIMARY_STYLE;
        case "CTA":
            return CTA_STYLE;
        case "secondary":
        case "outline":
            return OUTLINE_STYLE;
        case "link":
            return LINK_STYLE;
        default:
            return OUTLINE_STYLE;
    }
}

const CTA_STYLE = {
    background: Colors.PRIMARY_600,
    color: Colors.WHITE,
    '&:hover': {
        background: Colors.PRIMARY_500,
        color: Colors.WHITE,
        opacity: '.6'
    },
}

const PRIMARY_STYLE = {
    background: Colors.BLACK,
    color: Colors.WHITE,
    '&:hover': {
        background: Colors.BLACK,
        color: Colors.WHITE,
        opacity: '.6'
    },
}

const OUTLINE_STYLE = {
    background: Colors.WHITE,
    color: Colors.NEUTRAL_700,
    border: `.1rem solid ${Colors.NEUTRAL_300}`,
    '&:hover': {
        background: Colors.NEUTRAL_50,
        color: Colors.NEUTRAL_600
    },
    '&.Mui-disabled': {
        color: Colors.NEUTRAL_400,
        background: Colors.WHITE,
        borderColor: Colors.NEUTRAL_300,
        '& svg path': {
            fill: Colors.NEUTRAL_400,
        }
    },
}

const LINK_STYLE = {
    display: "inline-block",
    padding: "0",
    background: "transparent",
    color: Colors.NEUTRAL_700,
    border: "none",
    '&:hover': {
        background: "transparent",
        color: Colors.NEUTRAL_600,
        '& svg path': {
            fill: Colors.NEUTRAL_600,
        },
        'p, span': {
            textDecoration: "underline !important",
        }
    },
    '&.Mui-disabled': {
        color: Colors.NEUTRAL_400,
        background: "transparent",
        border: "none",
        '& svg path': {
            fill: Colors.NEUTRAL_400,
        }
    },
}

export const ButtonText = styled(Typography, {
    shouldForwardProp: (prop) => prop !== "size"
})(({ size }: { size: "medium" | "small" | "large" }) => ({
    ...(size === "large" ? BUTTON_XLARGE_TEXT : size === "medium" ? BUTTON_LARGE_TEXT : BUTTON_SMALL_TEXT),
    color: 'inherit',
}));

export default Button;