import {
  ResultCalendarDateType,
  ResultCalendarDateUserType,
} from "../../../../redux/reducers/types/calendarApiTypes";
import {Moment} from "moment";

interface ClosingsCalendarResultByDatesType {
  oneOfALotDates: {
    "Law Clerk": {
      oneOfLawClerks: ResultCalendarDateUserType;
      total: number;
    };
    Lawyer: {
      oneOfLawyers: ResultCalendarDateUserType;
      total: number;
    };
  };
}
export const getCurrentDateTypedClosingsCalendarResultTotalByRole = (
  role: "Law Clerk" | "Lawyer",
  closingsCalendarResultByDates: ResultCalendarDateType,
  day: Moment,
) => {
  const currentDayForSelectClosingsCounts = day.format("YYYY-MM-DD");
  const typedClosingsCalendarResultByDates =
    closingsCalendarResultByDates as unknown as ClosingsCalendarResultByDatesType;
  const currentDateTypedClosingsCalendarResult =
    typedClosingsCalendarResultByDates[
      currentDayForSelectClosingsCounts as "oneOfALotDates"
    ];

  if (role === "Law Clerk") {
    return currentDateTypedClosingsCalendarResult?.["Law Clerk"]?.total ?? 0;
  } else {
    return currentDateTypedClosingsCalendarResult?.["Lawyer"]?.total ?? 0;
  }
};
