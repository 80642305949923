import React, {Fragment, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import moment from "moment";
import {Box, styled, Typography, useTheme} from "@mui/material";
import {getClosedDealsByDay, getClosedDealsByWeekOrMonth} from "api";
import {ClosingDateType} from "api/types_TEMP";
import {
  getDealByIdCalendarClosingsTC,
  getLewClerksDealsNumberTC,
} from "redux/reducers/calendarReducer";
import useTypedSelector from "utils/hooks/useTypedSelector";
import DeededButton from "v2/components/DeededButton";
import constants from "styles/constants";
import {getDayOfWeek} from "../utils/getDayOfWeek";

const addZero = (val: number): string =>
  val >= 10 ? val.toString() : "0" + val;

type CalendarClosingDatesProps = {
  setShowDealPreview?: (showDealPreview: boolean) => void;
  isMobile?: boolean;
  date: Date;
  isAllProvinciesChecked: boolean;
  regions: string[] | string;
  view: string;
  setSigningsTotal: (numb: number) => void;
  staffState: string[] | string;
  dealTypeState: string[] | string;
};

type DealClosedType = {
  deal_id: number;
  closing_date: string;
  name: string;
  show: boolean;
};

const CalendarClosingDates = ({
  setShowDealPreview,
  isMobile,
  view,
  date,
  isAllProvinciesChecked,
  regions,
  staffState,
  dealTypeState,
}: CalendarClosingDatesProps) => {
  const [datesToClose, setDatesToClose] = useState<ClosingDateType[]>([]);
  const [datesToCloseByWeek, setDatesToCloseByWeek] = useState<
    Record<string, DealClosedType[]>
  >({});
  const lawClerksDealsNumber = useTypedSelector(
    (state) => state.CalendarReducer.lawClerksDealsNumber,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const startDay = getDayOfWeek(date, 0);
    const endDay = getDayOfWeek(date, 6);
    if (view === "day") {
      const day: string = addZero(date.getDate());
      const month: string = addZero(date.getMonth() + 1);
      const year: string = addZero(date.getFullYear());
      getClosedDealsByDay({
        day: `${year}-${month}-${day}`,
        regions: isAllProvinciesChecked ? "ALL" : regions,
        role: (staffState as string) ?? "ALL",
        dealType: (dealTypeState as string) ?? "ALL",
      }).then((res) => {
        setDatesToClose(res.data);
      });
      dispatch(
        getLewClerksDealsNumberTC({
          startDay: `${year}-${month}-${day}`,
          endDay: `${year}-${month}-${day}`,
          regions: isAllProvinciesChecked ? "ALL" : regions,
          role: (staffState as string) ?? "ALL",
          dealType: (dealTypeState as string) ?? "ALL",
        }),
      );
    } else if (view === "week") {
      const startDayD: string = addZero(startDay.getDate());
      const startMonth: string = addZero(startDay.getMonth() + 1);
      const startYear: string = addZero(startDay.getFullYear());
      const endDayD: string = addZero(endDay.getDate());
      const endMonth: string = addZero(endDay.getMonth() + 1);
      const endYear: string = addZero(endDay.getFullYear());
      getClosedDealsByWeekOrMonth({
        startDay: `${startYear}-${startMonth}-${startDayD}`,
        endDay: `${endYear}-${endMonth}-${endDayD}`,
        regions: isAllProvinciesChecked ? "ALL" : regions,
        role: (staffState as string) ?? "ALL",
        dealType: (dealTypeState as string) ?? "ALL",
      }).then((res: {data: {}}) => {
        setDatesToCloseByWeek(res.data);
      });
      dispatch(
        getLewClerksDealsNumberTC({
          startDay: `${startYear}-${startMonth}-${startDayD}`,
          endDay: `${endYear}-${endMonth}-${endDayD}`,
          regions: isAllProvinciesChecked ? "ALL" : regions,
          role: (staffState as string) ?? "ALL",
          dealType: (dealTypeState as string) ?? "ALL",
        }),
      );
    } else {
      const startDay = moment(date).startOf("month").format("YYYY-MM-DD");
      const endDay = moment(date).endOf("month").format("YYYY-MM-DD");
      getClosedDealsByWeekOrMonth({
        startDay,
        endDay,
        regions: isAllProvinciesChecked ? "ALL" : regions,
        role: (staffState as string) ?? "ALL",
        dealType: (dealTypeState as string) ?? "ALL",
      }).then((res: {data: {}}) => {
        setDatesToCloseByWeek(res.data);
      });
      dispatch(
        getLewClerksDealsNumberTC({
          startDay,
          endDay,
          regions: isAllProvinciesChecked ? "ALL" : regions,
          role: (staffState as string) ?? "ALL",
          dealType: (dealTypeState as string) ?? "ALL",
        }),
      );
    }
  }, [
    date,
    view,
    isAllProvinciesChecked,
    regions,
    dispatch,
    staffState,
    dealTypeState,
  ]);

  return (
    <DealClosedBlock isMobile={isMobile}>
      {!isMobile && <Title>Details</Title>}
      {view === "day" ? (
        <HeadClosings>
          Closings <TotalDeals>({datesToClose.length})</TotalDeals>
        </HeadClosings>
      ) : (
        <HeadClosings isMobile={isMobile}>
          Closings{" "}
          <TotalDeals>
            {" "}
            (
            {datesToCloseByWeek &&
              Object.values(datesToCloseByWeek).reduce(
                (acc, x) => acc + x.length,
                0,
              )}
            )
          </TotalDeals>
        </HeadClosings>
      )}

      <>
        {view === "day" &&
          (datesToClose.length !== 0 ? (
            <Box>
              {(datesToClose as DealClosedType[]).map((dealClosed) => {
                return (
                  <DealClosedItem key={dealClosed.deal_id}>
                    <DealClosedName>{dealClosed.name}</DealClosedName>
                    <DetailsButton
                      setShowDealPreview={
                        setShowDealPreview as (showDealPreview: boolean) => void
                      }
                      dealClosed={dealClosed}
                    />
                  </DealClosedItem>
                );
              })}
            </Box>
          ) : (
            <DealClosedName>
              There is no deals with closing dates for today
            </DealClosedName>
          ))}
        {(view === "week" || view === "month") &&
          (Object.keys(datesToCloseByWeek).length !== 0 ? (
            Object.keys(datesToCloseByWeek).map((day: string) => {
              const mmdd = day.slice(5).replaceAll("-", "/");
              const yyyy = day.slice(0, 4);
              const date = `${mmdd}/${yyyy}`;
              return (
                <Fragment key={day}>
                  <Date>{date}</Date>
                  <Box>
                    {datesToCloseByWeek[day].map(
                      (dealClosed: DealClosedType) => {
                        return (
                          <DealClosedItem key={dealClosed.deal_id}>
                            <DealClosedName>{dealClosed.name}</DealClosedName>
                            <DetailsButton
                              setShowDealPreview={
                                setShowDealPreview as (
                                  showDealPreview: boolean,
                                ) => void
                              }
                              dealClosed={dealClosed}
                            />
                          </DealClosedItem>
                        );
                      },
                    )}
                  </Box>
                </Fragment>
              );
            })
          ) : (
            <DealClosedName>
              There is no deals with closing dates for this week
            </DealClosedName>
          ))}
      </>
      {lawClerksDealsNumber.length > 0 && (
        <LawClerksTitle>Law Clerks Deals Count</LawClerksTitle>
      )}
      {lawClerksDealsNumber.map((lawClerk, index) => (
        <LawClerksDealsNumber key={index}>
          {lawClerk.full_name}: {lawClerk.current_deals_count}
        </LawClerksDealsNumber>
      ))}
    </DealClosedBlock>
  );
};
const DetailsButton: React.FC<{
  setShowDealPreview: (showDealPreview: boolean) => void;
  dealClosed: DealClosedType;
}> = ({dealClosed, setShowDealPreview}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  return (
    <>
      <DeededButton
        disabled={!dealClosed.show}
        sx={{
          borderRadius: "4px",
          fontSize: "12px",
          lineHeight: "16px",
          textAlign: "center",
          height: "20px",
          width: "77px",
          fontWeight: "400",
          minWidth: "77px",
          maxWidth: "77px",
          [theme.breakpoints.down("sm")]: {
            width: "77px",
            minWidth: "77px",
          },
        }}
        onClick={() => {
          dispatch(getDealByIdCalendarClosingsTC(dealClosed.deal_id as number));
          setShowDealPreview(true);
        }}
      >
        Details
      </DeededButton>
    </>
  );
};
const LawClerksTitle = styled(Typography)({
  display: "flex",
  alignItems: "center",
  fontWeight: "600",
  marginBottom: "16px",
  fontSize: "14px",
  fontFamily: "Montserrat",
  marginTop: "26px",
});
const LawClerksDealsNumber = styled(Typography)({
  fontSize: "14px",
  fontWeight: "400",
});
const Title = styled(Typography)({
  marginBottom: "24px",
  fontWeight: "600",
  fontSize: "18px",
  lineHeight: "24px",
});

const HeadClosings = styled(Box, { shouldForwardProp: (propName) => propName !== "isMobile" })<{
    isMobile?: boolean;
}>(({ isMobile }) => ({
    marginTop: isMobile ? "20px" : "0px",
    display: "flex",
    alignItems: "center",
    fontWeight: "600",
    marginBottom: "16px",
    fontSize: isMobile ? "18px" : "14px",
    fontFamily: "Montserrat",
    color: constants.colors.sortingColor,
}));

const TotalDeals = styled(Typography)({
  fontWeight: "600",
  fontSize: "14px",
  fontFamily: "Montserrat",
  color: "#828282",
  marginLeft: "5px",
});
const Date = styled(Typography)({
  color: "#f16764",
  marginBottom: "10px",
  fontWeight: "700",
  fontSize: "14px",
});

const DealClosedItem = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  marginBottom: "10px",
});

const DealClosedName = styled(Typography)({
  fontSize: "14px",
  fontWeight: "400",
});

const DealClosedBlock = styled(Box, { shouldForwardProp: (propName) => propName !== "isMobile" })<{
    isMobile?: boolean;
}>(({ isMobile }) => ({
    maxHeight: "700px",
    overflow: "auto",
    width: isMobile ? "100%" : "calc(100% - 15px)",
    height: "max-content",
    marginLeft: isMobile ? "0px" : "16px",
    padding: isMobile ? "0px" : "24px",
    backgroundColor: "#ffffff",
    color: "#333333",
    "::-webkit-scrollbar": {
        width: isMobile ? "0px" : "6px",
        cursor: "pointer",
    },
}));

export default CalendarClosingDates;
