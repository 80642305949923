import {Dispatch} from "redux";
import {ActivityLogData} from "./types/activityLogReducerTypes";
import {addErrorAC} from "./ErrorReducer";
import {getActivityLogsByID} from "../../api";

type ActivityLogAction =
  | "SET_ACTIVITY_LOG_DATA"
  | "SET_PAGES_AMOUNT"
  | "SET_ACTIVITY_LOG_CURRENT_PAGE";
interface ActivityLogActionType {
  type: ActivityLogAction;
  payload: unknown;
}
export interface ActivityLogStateType {
  activityLogData: ActivityLogData[];
  pagesAmount: number;
  activityLogCurrentPage: number;
}
const activityLogState: ActivityLogStateType = {
  activityLogData: [],
  pagesAmount: 1,
  activityLogCurrentPage: 1,
};

export const ActivityLogReducer = (
  state = activityLogState,
  action: ActivityLogActionType,
) => {
  switch (action.type) {
    case "SET_ACTIVITY_LOG_CURRENT_PAGE": {
      return {
        ...state,
        activityLogCurrentPage: action.payload as number,
      };
    }
    case "SET_PAGES_AMOUNT": {
      return {
        ...state,
        pagesAmount: action.payload as number,
      };
    }
    case "SET_ACTIVITY_LOG_DATA": {
      return {
        ...state,
        activityLogData: action.payload as ActivityLogData[],
      };
    }
    default:
      return {
        state,
      };
  }
};
export const setActivityLogCurrentPageAC = (payload: number) => ({
  type: "SET_ACTIVITY_LOG_CURRENT_PAGE",
  payload,
});
export const setPagesAmountAC = (payload: number) => ({
  type: "SET_PAGES_AMOUNT",
  payload,
});
export const setActivityLogDataAC = (payload: ActivityLogData[]) => ({
  type: "SET_ACTIVITY_LOG_DATA",
  payload,
});
export const getActivityLogsByIdTC =
  (dealId: number, page: number) => async (dispatch: Dispatch) => {
    try {
      const activityLogPromise = await getActivityLogsByID(dealId, page);
      dispatch(setPagesAmountAC(activityLogPromise.data.last_page));
      dispatch(setActivityLogDataAC(activityLogPromise.data.data));
    } catch (e) {
      const error = e as {response: {data: {error: string}}};
      dispatch(
        addErrorAC(
          error?.response?.data?.error ?? "Cannot get activity log data",
        ),
      );
    }
  };
