import useTypedSelector from "utils/hooks/useTypedSelector";
import {
  Box,
  MenuItem,
  SelectChangeEvent,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import React, {useContext, useMemo} from "react";
import constants from "styles/constants";
import DeededSelect from "v2/components/DeededSelect";

import DealDetailsFileTasksSwitchFileTaskPopup from "components/Dashboard/DealDetailsFileTasks/components/DealDetailsFileTasksSwitchFileTaskPopup";

import DealDetailsFileTasksPageStage from "components/Dashboard/DealDetailsFileTasks/components/DealDetailsFileTasksPageStage";
import {DealDetailsFileTasksContext} from "components/Dashboard/DealDetailsFileTasks/context/DealDetailsFileTasksContext";
import { FileTasksFiled } from "redux/reducers/fileTasksSlice";

export const selectedFileTypeValues = {
    purchase: "Purchase",
    mortgage: "Mortgage",
};

export interface StageType {
    name: string;
    fields: FileTasksFiled[];
}

const DealDetailsFileTasksPage = () => {
  const fileTasks = useTypedSelector(
    (state) => state.FileTasksReducer.fileTasks,
  );
  const theme = useTheme();
  const {selectedFileType, setIsOpenedFileTaskPopup, setFileTypeValueToSet} =
    useContext(DealDetailsFileTasksContext);
  const handleFileTypeChange = (e: {target: {value: string}}) => {
    setIsOpenedFileTaskPopup(true);
    setFileTypeValueToSet(e.target.value);
  };
  const stagesArray = useMemo(() => {
    const existingStages = Object.keys(fileTasks).map(
      (fileTaskStage, index) => {
        const stageNumber = index + 1;
        const generateName = () => {
          if (stageNumber === 1) {
            return "Stage 1: Preliminary Tasks";
          } else if (stageNumber === 2) {
            return "Stage 2: Title & Documents";
          } else if (stageNumber === 3) {
            return "Stage 3: Fund & Payment";
          } else if (stageNumber === 4) {
            return "Stage 4: Closing";
          } else if (stageNumber === 5) {
            return "Stage 5: Undertakings";
          } else {
            return "";
          }
        };
        const name = generateName();
        return {
          name,
          //@ts-ignore
          fields: fileTasks[fileTaskStage],
        };
      },
    );
    if (existingStages.length === 4) {
      return existingStages.concat([
        {name: "Stage 5: Undertakings", fields: []},
      ]);
    } else {
      return existingStages;
    }
  }, [fileTasks]);
  return (
    <>
      <Box
        sx={{
          backgroundColor: constants.colors.white,
          padding: "32px",
        }}
      >
        <FileTasksTitle>File Tasks</FileTasksTitle>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
            [theme.breakpoints.down("sm")]: {
              flexDirection: "column",
              justifyContent: "unset",
              alignItems: "flex-start",
            },
          }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              color: constants.colors.title,
              lineHeight: "20px",
              marginRight: "20px",
              [theme.breakpoints.down("sm")]: {
                marginRight: "0px",
              },
            }}
          >
            Use this File Task list to indicate which actions have taken place
            on this deal
          </Typography>
          <DeededSelect
            onChange={
              handleFileTypeChange as (
                e?: SelectChangeEvent<unknown> | undefined,
              ) => void
            }
            sx={{
              height: "36px !important",
              [theme.breakpoints.down("sm")]: {
                marginTop: "15px",
              },
            }}
            value={selectedFileType}
          >
            <MenuItem value={selectedFileTypeValues.purchase}>
              Purchase File
            </MenuItem>
            <MenuItem value={selectedFileTypeValues.mortgage}>
              Mortgage File
            </MenuItem>
          </DeededSelect>
        </Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr 1fr",
            marginTop: "48px",
            [theme.breakpoints.down("xl")]: {
              gridTemplateColumns: "1fr 1fr 1fr",
            },
            [theme.breakpoints.down("lg")]: {
              gridTemplateColumns: "1fr 1fr",
            },
            [theme.breakpoints.down("md")]: {
              gridTemplateColumns: "1fr",
            },
          }}
        >
          {stagesArray.map((stage, index) => {
            if (index <= 3) {
              return (
                <DealDetailsFileTasksPageStage
                  isUndertakings={stage.name.includes("Undertakings")}
                  stage={stage as StageType}
                  key={index}
                />
              );
            } else {
              return null;
            }
          })}
        </Box>
        {stagesArray?.[4] && (
          <>
            <Box>
              <DealDetailsFileTasksPageStage
                isUndertakings={stagesArray?.[4].name.includes("Undertakings")}
                stage={stagesArray?.[4] as StageType}
              />
            </Box>
          </>
        )}
      </Box>
      <DealDetailsFileTasksSwitchFileTaskPopup />
    </>
  );
};

const FileTasksTitle = styled(Typography)({
  color: constants.colors.intakeTitle,
  fontWeight: "700",
  fontSize: "22px",
});
export default DealDetailsFileTasksPage;
