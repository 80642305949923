import React from "react";

export default function ArrowBack({
  color = "#000000",
  width = "31",
  height = "18",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 31 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="1" y="6.59998" width="30" height="4" rx="2" fill={color} />
      <rect
        x="2.82812"
        y="6"
        width="12"
        height="4"
        rx="2"
        transform="rotate(45 2.82812 6)"
        fill={color}
      />
      <rect
        y="8.48529"
        width="12"
        height="4"
        rx="2"
        transform="rotate(-45 0 8.48529)"
        fill={color}
      />
    </svg>
  );
}
