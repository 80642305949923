import React from "react";

type CrossType = {
  width?: number | undefined;
  height?: number | undefined;
  color?: string | undefined;
};
const Cross: React.FC<CrossType> = ({
  width = 22,
  height = 22,
  color = "#7F7F7F",
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 22 22" fill={color}>
      <path
        d="M22 1.29419L20.7246 0.0216064L11 9.72767L1.27535 0.0216064L0 1.29419L9.72465 11.0003L0 20.7057L1.27535 21.9783L11 12.2729L20.7246 21.9783L22 20.7057L12.2747 11.0003L22 1.29419Z"
        fill={color}
      />
    </svg>
  );
};
export default Cross;
