import React from "react";
import {Button, styled} from "@mui/material";
import constants from "../../styles/constants";
import {SxProps, Theme} from "@mui/material/styles";

interface DeededProvinceButtonProps {
  title: string;
  bgColor: string;
  checked?: boolean;
  onClick: () => void;
  sx?: SxProps<Theme>;
}
const DeededProvinceButton: React.FC<DeededProvinceButtonProps> = ({
  title,
  bgColor,
  checked,
  ...props
}) => {
  return (
    <DeededProvinceButtonBasic checked={checked} bgColor={bgColor} {...props}>
      {title}
    </DeededProvinceButtonBasic>
  );
};
const DeededProvinceButtonBasic = styled(Button, {
  shouldForwardProp: (prop) => prop !== "bgColor"
})<{
  checked?: boolean;
  bgColor: string;
}>(({bgColor, checked}) => ({
  fontWeight: "700",
  fontSize: "14px",
  color: constants.colors.white,
  backgroundColor: checked ? bgColor : constants.colors.deededGray,
  minWidth: "unset",
  width: "50px",
  height: "24px",
  borderRadius: "5px",
  transition: constants.transitions,
  ":hover": {
    backgroundColor: checked ? bgColor : constants.colors.deededGray,
    opacity: "0.5",
    transition: constants.transitions,
  },
}));
export default DeededProvinceButton;
