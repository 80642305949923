import { Stack, styled } from "@mui/material";
import constants from "styles/constants";
import {useSnackbar} from "notistack";
import {Copy} from 'components/Svg/Icons'
import { H2, H3, H4, Small, Body } from "components/Common/Typography";
import IntakeFileSection from "./IntakeFileSection";
import { FlagIcon } from "components/Common/Icons/Iconography";
import configuration from "../../../utils/configuration";
import { ClientIntakeStates, OldStates } from "types/intakeForm";
import {isClient} from "../../../utils/permissionRoles";

const IntakeBody = ({ intake, deal, user }) => {

    const keywords = [
        'local',
        'global',
        'merge',
        'list',
        'boolean',
        'text',
        'checklist',
        'choice',
        'radiogroup',
        'dropdown',
        'paneldynamic',
    ];

    const {enqueueSnackbar} = useSnackbar();
    const ClipboardCopy = (data) => {
        if('clipboard' in navigator) {
            navigator.clipboard.writeText(data).then(
                () => {
                    enqueueSnackbar('Copied!', {
                        variant: "success",
                        autoHideDuration: 1500,
                    });
                },
                () => {
                    enqueueSnackbar('Unable to write to clipboard', {
                        variant: "error",
                        autoHideDuration: configuration.autoHideErrorDuration,
                    });
                }
            );
        }
        else {
            enqueueSnackbar('Unable to write to clipboard (HTTPS is required)', {
                variant: "error",
                autoHideDuration: configuration.autoHideErrorDuration,
            });
        }
    }

    const titleCase = (title) => title ? title.split('_').map(i =>
        keywords.indexOf(i) === -1 ? i[0].toUpperCase() + i.slice(1) : null
    ).join(' ') : '';

    const parseAwnswer = (awnser) => {
        switch (typeof awnser) {
            case 'boolean':
                return awnser ? 'Yes' : 'No';
            case 'object':
                if (awnser !== null && Array.isArray(awnser)) {
                    return awnser.map(q => titleCase(parseAwnswer(q))).join(', ');
                }
                return null;
            case 'string':
                return awnser.indexOf('choice_') === 0 ? titleCase(awnser) : awnser;
            default:
                return awnser;
        }
    }

    const sortIntake = (intake) => {
        let single = [];
        let sorted = [];
        let unsorted = [];
        let result = {};

        for(let prop in intake) {
            let index = parseInt(prop.split('_').pop());
            let data = intake[prop];
            if(typeof data === 'object' && Array.isArray(data)) {
                data = intake[prop]
                    .filter(i => i.length !== 0)
                    .map(i => (typeof i === 'string') ? i : sortIntake(i))
            }
            else {
                if(typeof index === 'number' && !isNaN(index)) {
                    single[index] = {
                        prop,
                        data,
                    };
                }
                else {
                    single.push({
                        'prop': prop,
                        data,
                    })
                }
            }
            
            if(data.length === 0) continue;

            if(typeof index === 'number' && !isNaN(index)) {
                sorted[index] = {
                    prop,
                    data,
                };
            }
            else {
                unsorted.push({
                    'prop': prop,
                    data,
                })
            }
        }

        single.forEach(i => {
            result[i.prop] = i.data;
        })

        sorted.forEach(i => {
            result[i.prop] = i.data;
        })

        unsorted.forEach(i => {
            result[i.prop] = i.data;
        })
        return result;
    }

    const removeIntakeIndexesAndRetainOriginalKeys = (intakeData) => {
        let result = {};
        const removeTrailingIndex = (str) => {
            const index = parseInt(str.split('_').pop());
            return isNaN(index) ? str : str.replace(`_${index}`, '');
        }

        for (let prop in intakeData) {
            const data = intakeData[prop]
            const newProp = removeTrailingIndex(prop);

            if (Array.isArray(data) && data.some(item => typeof item === 'object')) {
                result[newProp] = data.map(item => removeIntakeIndexesAndRetainOriginalKeys(item))

            }  else if (Array.isArray(data) && data.every(item => typeof item === 'string')) {
                result[newProp] = {value: data, originalPropNameWithIndex:prop }
            } else {
                result[newProp] = {
                    value: intakeData[prop],
                    originalPropNameWithIndex: prop
                };
            }

        }
        return result
    }
    const parseIntake = (intake) => {
        let result = [];
        for (let prop in intake) {
            if (Array.isArray(intake[prop]) && prop.indexOf('local_dropdown') === -1 && prop.indexOf('local_checklist') === -1) {
                result.push(
                    <Container className="container">
                        <H3>{titleCase(prop)}</H3>
                        {intake[prop].map((p, i) =>
                            <Container className="container">
                                <H4>{titleCase(prop)} {i + 1}</H4>
                                <Row className="row" >
                                    {parseIntake(p)}
                                </Row>
                            </Container>
                        )}
                    </Container>
                )
                continue;
            }
            result.push(
                <Group className="question-group" onClick={() => {ClipboardCopy(parseAwnswer(intake[prop]?.value ?? intake[prop]))}}>
                    <Small style={{color: constants.colors.gray800, paddingBottom: '1rem' }}>{titleCase(prop)}? <Copy color={constants.colors.black3}/></Small>
                    <Body data-testid={intake[prop]?.originalPropNameWithIndex}>{parseAwnswer(intake[prop]?.value ?? intake[prop])}</Body>
                </Group>
            )
        }
        return result;
    }

    return <BodyStack data-testid="intake-body">
        {intake?.content?.data ?
            <Stack>
                <Container className="surveyjs-intake container" gap={'3rem'}>
                    <H2>Intake</H2>
                    {!isClient(user.role) && <Flags flags={intake.flags}/>}
                    {(intake.state === OldStates.inQNA || intake.state === ClientIntakeStates.InProgress) && <Error>Client has not yet completed the intake. <br />
                        Information may be missing or incomplete.
                    </Error>}
                    <Row className="row">
                        {parseIntake(removeIntakeIndexesAndRetainOriginalKeys(sortIntake(intake.content.data)))}
                    </Row>
                </Container>
                <IntakeFileSection deal={deal} />
            </Stack>
            :
            <H2 style={{ textAlign: "center", margin: "3rem auto", color: constants.colors.gray200 }}>No intake data</H2>
        }
    </BodyStack>;
}


const Flags = ({ flags }) => {
    return <Stack flexDirection={'column'} gap={'1rem'}>{flags.map((flag) => {
        return <Stack flexDirection={'row'} gap='1rem'>
            <FlagIcon color={constants.colors.red500}/> <Body>{flag.message}</Body>
        </Stack>;
    })}</Stack>;
}

const BodyStack = styled(Stack)(({ theme }) => ({
    flexDirection: 'row',
    justifyContent: 'space-between',    
    margin: '0 2rem 2rem',
    paddingBottom: '2rem',
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        padding: '2rem',
        gap: '1rem',
    },
}));

const Container = styled(Stack)(({ theme }) => ({
    border: `1px solid ${constants.colors.gray300}`,
    padding: '2rem',
    borderRadius: '7px',
    marginTop: '2rem',
    display: 'flex',
    width: '100%',
    flexDirection: 'column',

}));

const Row = styled(Stack)(({ theme }) => ({
    marginTop: '2rem',
    gap: '3rem 1rem',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    '& > .container': {
        marginTop: '0'
    }
}));

const Error = styled(Stack)(({ theme }) => ({
    position: 'absolute', 
    textAlign: 'center', 
    width: '100%', 
    color: constants.colors.orange500,
    fontWeight: 700,
    lineHeight: '2.2rem' 
}));

const Group = styled(Stack)(({ theme }) => ({
    padding: '.5rem',
    paddingRight:' 4rem',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '40rem',
    position: 'relative',
    '&:hover': {
        borderRadius: '.6rem',
        background: constants.colors.gray200,
    },
    '& svg': {
        position: 'absolute',
        right: '.5rem',
        top: '.5rem',
        display: 'none',
    },
    '&:hover svg': {
        cursor: 'pointer',
        display: 'block',
    },
}));

export default IntakeBody; 