import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { Box, IconButton, styled } from "@mui/material";
import { cancelDealTC } from "redux/reducers/dealsReducer";
import DeededButton from "v2/components/DeededButton";
import DeededPopup from "v2/components/DeededPopup";
import { getDealIdFromLink } from "utils/getDealIdFromLink";
import CloseDeededSvg from "components/Svg/CloseDeededSvg";
import DealDetailsCommentTextArea from "components/Dashboard/DealPropertyCard/components/DealDetailsCommentTextArea";
import Checkbox from "components/Common/Checkbox";
import { BodyRegular, H2 } from "components/CommonDashboard/Typography";
import Stack from "@mui/material/Stack";

interface DealDetailsCloseDealPopupProps {
    isOpenCloseDealPopup: boolean;
    setIsOpenCloseDealPopup: (isOpenCloseDealPopup: boolean) => void;
    setIsArchive: (isArchive: boolean) => void;
    dealIdx?: number;
}

const DealDetailsCloseDealPopup: React.FC<DealDetailsCloseDealPopupProps> = ({
  isOpenCloseDealPopup,
  setIsOpenCloseDealPopup,
  setIsArchive,
  dealIdx
}) => {
  const [comment, setComment] = useState("");
  const [isTest, setIsTest] = useState(false);
  const [commentError, setCommentError] = useState<string | undefined>();
  const location = useLocation();
  const dealId = dealIdx ?? getDealIdFromLink(location.pathname);
  const dispatch = useDispatch();
  const handleSend = () => {
    if (comment.length === 0) {
      setCommentError("Comment is required");
      return;
    }
    dispatch(
      cancelDealTC(dealId, comment, isTest, setIsArchive, setIsOpenCloseDealPopup),
    );
  };

  return (
    <DeededPopup
      sx={{}}
      hideBtnClose={true}
      open={isOpenCloseDealPopup}
      setOpen={setIsOpenCloseDealPopup}
      sxInternalBox={{
        width: "430px",
        overflowX: "hidden",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
          width: "5px",
          scrollbarWidth: "thin",
        },
      }}
    >
      <Box
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <CloseDealTitleBox>
          <H2>Close Deal</H2>
          <CloseIconBtn
            data-testid="close-deal-modal-close-icon"
            onClick={() => {
              setIsOpenCloseDealPopup(false);
            }}
          >
            <CloseDeededSvg />
          </CloseIconBtn>
        </CloseDealTitleBox>
        
        <BodyRegular>Cancellation Reason</BodyRegular>
        <DealDetailsCommentTextArea
          setCommentError={setCommentError}
          error={commentError}
          text={comment}
          setText={setComment}
          data-testid="close-deal-modal-cancellation-reason-field"
        />
          <Stack mt={1} direction="row" justifyContent="end" width="100%">
            <Checkbox
              data-testid="close-deal-modal-cancellation-is-test-field"
              label="Test Deal"
              checked={isTest}
              onChange={() => setIsTest(!isTest)}
            />
          </Stack>
        <ButtonBox>
          <SendBtn type={"submit"} data-testid="close-deal-modal-send-button" onClick={handleSend}>
            Send
          </SendBtn>
          <CloseBtn
              data-testid="close-deal-modal-close-button"
              type={"button"}
            onClick={() => {
              setIsOpenCloseDealPopup(false);
            }}
            kind="secondary"
          >
            Close
          </CloseBtn>
        </ButtonBox>
      </Box>
    </DeededPopup>
  );
};
const CloseBtn = styled(DeededButton)({
  width: "100px",
  height: "40px",
  marginRight: "16px",
});
const SendBtn = styled(DeededButton)({
  width: "216px",
  height: "40px",
});
const ButtonBox = styled(Box)({
  display: "flex",
  flexDirection: "row-reverse",
  marginTop: "25px",
});

const CloseIconBtn = styled(IconButton)({
  marginTop: "-22px",
  marginRight: "-22px",
  minWidth: "0px",
});
const CloseDealTitleBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "20px",
});

export default DealDetailsCloseDealPopup;
