import {useSnackbar} from "notistack";
import React, {useEffect, useState} from "react";
import {baseURL} from "../../../api";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {setIsFolderSelectedAC} from "../../../redux/reducers/storageReducer";
import {DealsReducer} from "../../../redux/reducers/dealsReducer";
import configuration from "../../../utils/configuration";

const userID = localStorage.getItem("user_id");
export const documentDownloadToken = localStorage.getItem(`token_${userID}`);

/**
 *
 * @param {Object} selectedDocument contains status, file name and other server-related document data
 * @param {()=>void} [onClose] Callback responsible for closing the modal
 */
function useDocumentViewHandlers(
  dealID,
  dealType,
  selectedDocument,
  onClose,
  onUpdated,
) {
  const [folder, setFolder] = React.useState(
    selectedDocument.document_updates[
      selectedDocument.document_updates.length - 1
    ]?.doc_type,
  );
  const dispatch = useDispatch();
  const isFolderSelected = useSelector(
    (state) => state.StorageReducer.isFolderSelected,
  );
  const prevName = useSelector((state) => state.DealsReducer.dealDoc);
  const {enqueueSnackbar} = useSnackbar();
  const [currDoc, setCurrDoc] = useState(null);
  const [newName, setNewName] = useState(
    prevName?.original_filename ? prevName?.original_filename : "New_file_name",
  );
  const [fileData, setFileData] = useState(null);
  const [comment, setComment] = useState(
    selectedDocument
      ? selectedDocument.document_updates[
          selectedDocument.document_updates.length - 1
        ]?.comment
      : "",
  );
  const [rejectionReason, setRejectionReason] = useState(
    selectedDocument
      ? selectedDocument.document_updates[
          selectedDocument.document_updates.length - 1
        ]?.rejection_reason
      : "",
  );
  const docDownLoad = {
    link:
      currDoc &&
      `${baseURL}/agreements/${currDoc.id}`,
    name: currDoc && currDoc.original_filename,
  };

  const handleApprove = () => {
    const {id} = currDoc;

    axios
      .post(`${baseURL}/agreements/${id}/approve`, {
        docType: folder,
        comment: comment,
        newName: newName,
      })
      .then((res) => {
        enqueueSnackbar("Processing approve", {
          variant: "success",
          autoHideDuration: 1500,
        });

        if (onUpdated) {
          onUpdated();
        }
        if (onClose) {
          onClose();
        }
      })
      .catch((error) => {
        enqueueSnackbar(
          error.response
            ? error.response?.data.error
            : "Couldn't approve, unknown error.",
          {
            variant: "error",
            autoHideDuration: configuration.autoHideErrorDuration,
          },
        );
      });
  };
  const handleDelete = () => {
    const {id} = currDoc;
    axios
      .post(`${baseURL}/agreements/${id}/delete`)
      .then((res) => {
        enqueueSnackbar(res.data.message, {
          variant: "success",
          autoHideDuration: 1500,
        });
        if (onUpdated) {
          onUpdated();
        }
        if (onClose) {
          onClose();
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.response?.data.error, {
          variant: "error",
          autoHideDuration: configuration.autoHideErrorDuration,
        });
      });
  };
  const handleReject = () => {
    const {id} = currDoc;
    axios
      .post(`${baseURL}/agreements/${id}/reject`, {
        docType: folder,
        comment: comment,
        newName: newName,
        rejectionReason
      })
      .then((res) => {
        enqueueSnackbar("Processing reject", {
          variant: "success",
          autoHideDuration: 1500,
        });
        setComment("");
        if (onUpdated) {
          onUpdated();
        }
        if (onClose) {
          onClose();
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.response?.data.error, {
          variant: "error",
          autoHideDuration: configuration.autoHideErrorDuration,
        });
      });
  };
  const callFileData = () => {
    const {type} = currDoc;
    if (currDoc.type === "application/pdf") {
      axios(
        `${baseURL}/agreements/${currDoc.id}`,
        {
          responseType: "arraybuffer",
          headers: {
            "Content-Type": type,
            Accept: type,
          },
        },
      )
        .then((res) => {
          setFileData(res.data);
        })
        .catch((error) =>
          enqueueSnackbar(error.response?.data.error, {
            variant: "error",
            autoHideDuration: configuration.autoHideErrorDuration,
          }),
        );
    } else {
      axios(`${baseURL}/agreements/${currDoc.id}/preview`)
        .then((res) => {
          setFileData(res.data);
        })
        .catch((error) =>
          enqueueSnackbar(error.response?.data.error, {
            variant: "error",
            autoHideDuration: configuration.autoHideErrorDuration,
          }),
        );
    }
  };
  useEffect(() => {
    if (selectedDocument) {
      setCurrDoc(selectedDocument);
    }
    if (currDoc) {
      callFileData();
    }
  }, [selectedDocument, currDoc]);

  return {
    /** @type {string} An approval status */
    documentStatus: currDoc ? currDoc.status : null,
    /** @type {string} Tax bill, void cheque etc... */
    documentType: currDoc ? currDoc.type : null,
    /** @type {any} Displayable content in the iframe */
    fileData: fileData,
    /** @type {string} For the h3 header */
    title: (currDoc && currDoc.alias) || "No document name",
    /** @type {(value: string)=>void} Callback for updating the comment asynchronously */
    onCommentUpdate: setComment,
    /** @type {(value: string)=>void} Callback for updating the rejectionReason asynchronously */
    onRejectionReasonUpdate: setRejectionReason,
    /** @type {()=>void} For closing the modal if it exists */
    onClose,
    /** @type {{name: string, link: string}} Other document metadata */
    downloadData: docDownLoad,
    /** @type {()=>void} Handler for approving the document */
    onApprove: handleApprove,
    /** @type {()=>void} Handler for rejecting the document */
    onReject: handleReject,
    /** @type {()=>void} Handler for deleting the document */
    onDelete: handleDelete,
    dealID,
    dealType,
    documentUpdates: currDoc ? currDoc.document_updates : null,
    comment,
    rejectionReason,
    folder,
    setFolder,
    newName,
    setNewName,
  };
}

export default useDocumentViewHandlers;
