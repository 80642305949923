import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import useTypedSelector from "utils/hooks/useTypedSelector";
import {Box, Typography, useTheme} from "@mui/material";
import constants from "styles/constants";
import DeededSpace from "v2/components/DeededSpace";
import DeededInput from "v2/components/DeededInput";
import moment from "moment/moment";
import DeededButton from "v2/components/DeededButton";
import {sendDealCommentTC} from "redux/reducers/calendarReducer";
import {useDispatch} from "react-redux";
import {CalendarFullDealInfoType} from "types/calendarFullDealInfoType";
import DeededCheckbox from "v2/components/DeededCheckbox";

const DealCalendarComments = () => {
  const dealFullInfo = useTypedSelector(
    (state) => state.CalendarReducer.dealFullInfo,
  ) as CalendarFullDealInfoType & {isOpenedByClosing?: boolean};
  const currentAppointmentId = useTypedSelector(
    (state) => state.CalendarReducer.currentAppointmentId,
  );

  const theme = useTheme();
  const [text, setText] = useState("");
  const [isNotifyComment, setIsNotifyComment] = useState(true);
  const dispatch = useDispatch();
  const handleCheckedSendNotifications = (e: {target: {checked: boolean}}) => {
    setIsNotifyComment(e.target.checked);
  };
  return (
    <Box sx={{position: "relative"}}>
      <Box sx={{padding: "8px 16px 16px"}}>
        {dealFullInfo?.comments?.map((comment) => {
          return (
            <Box
              sx={{
                position: "relative",
              }}
            >
              <DeededSpace y={10} />
              <Box sx={{display: "flex"}}>
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "16px",
                    lineHeight: "150%",
                    display: "flex",
                    alignItems: "center",
                    color: constants.colors.title,
                    overflow: "hidden",
                    maxWidth: "50%",
                    [theme.breakpoints.down("sm")]: {
                      fontSize: "12px",
                    },
                  }}
                >
                  {comment.added_by.full_name}
                </Typography>
                <DeededSpace x={5} />
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "16px",
                    lineHeight: "150%",
                    display: "flex",
                    alignItems: "center",
                    color: constants.colors.deededGray,
                    [theme.breakpoints.down("sm")]: {
                      fontSize: "12px",
                    },
                  }}
                >
                  {" "}
                  {comment.added_by.role}
                </Typography>
              </Box>
              <DeededSpace y={8} />
              <Typography
                sx={{
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontWeight: "normal",
                  fontSize: "14px",
                  lineHeight: "150%",
                  color: constants.colors.title,
                  overflowWrap: "anywhere",
                  [theme.breakpoints.down("sm")]: {
                    fontSize: "10px",
                  },
                }}
              >
                {comment.content}
              </Typography>
              <DeededSpace y={9} />
              <Typography
                sx={{
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontWeight: "normal",
                  fontSize: "14px",
                  lineHeight: "150%",
                  color: constants.colors.deededGray,
                  [theme.breakpoints.down("sm")]: {
                    fontSize: "10px",
                  },
                }}
              >
                {moment(comment.created_at).format("MM/DD/YYYY, HH:mm:ss A")}
              </Typography>
              <DeededSpace y={16} />
              <Box
                sx={{
                  width: "100%",
                  backgroundColor: "#f2f2f2",
                  height: "2px",
                }}
              />
            </Box>
          );
        })}
      </Box>
      <Box sx={{position: "relative"}}>
        <BoxedTextArea setText={setText} text={text} />
        <DeededButton
          onClick={() => {
            dispatch(
              sendDealCommentTC(
                dealFullInfo?.id as number,
                text,
                currentAppointmentId as number,
                isNotifyComment,
                dealFullInfo?.isOpenedByClosing,
              ),
            );
            setText(" ");
          }}
          sx={{
            width: "101px",
            position: "absolute",
            bottom: "10px",
            right: "10px",
            [theme.breakpoints.down("sm")]: {
              width: "101px",
            },
          }}
        >
          Save
        </DeededButton>
      </Box>
      <Box
        sx={{
          marginTop: "14px",
          "& .MuiCheckbox-root": {
            minHeight: "20px",
            minWidth: "20px",
            maxHeight: "20px",
            maxWidth: "20px",
            padding: "0px",
          },
          "& .MuiTypography-root": {
            marginLeft: "14px",
          },
        }}
      >
        <DeededCheckbox
          label={"Send Notification"}
          checked={isNotifyComment}
          onChange={
            handleCheckedSendNotifications as unknown as (
              event: SyntheticEvent<Element, Event>,
              checked: boolean,
            ) => void
          }
        />
      </Box>
    </Box>
  );
};

const BoxedTextArea: React.FC<
  React.ComponentProps<typeof DeededInput> & {
    text: string;
    setText: (text: string) => void;
  }
> = ({onChange, ...props}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [height, setHeight] = useState(0);
  const updateTextAreaHeight = useCallback(() => {
    const newHeight = ref.current?.getBoundingClientRect().height;
    if (newHeight) {
      setHeight(newHeight);
    }
  }, []);

  useEffect(() => {
    const t = setInterval(updateTextAreaHeight, 100);
    return () => clearInterval(t);
  }, [updateTextAreaHeight]);

  useEffect(() => {
    updateTextAreaHeight();
  }, [props.text, updateTextAreaHeight]);

  return (
    <Box sx={{height: `${height}px`}}>
      <DeededInput
        {...props}
        value={props.text}
        onChange={
          onChange
            ? (e) => {
                props.setText(e.target.value);
                onChange(e);
              }
            : (e) => props.setText(e.target.value)
        }
        ref={ref}
        height="auto"
        sx={{
          // paddingBottom: "48px",
          width: "100%",
          position: "absolute",
          "& .MuiOutlinedInput-root": {
            minHeight: "94px",
            alignItems: "flex-start",
          },
          "& .MuiOutlinedInput-root .MuiInputBase-input": {
            lineHeight: 1.5,
            paddingBottom: "72px",
          },
          "& textarea": {
            marginTop: 2,
          },
        }}
        multiline
      />
    </Box>
  );
};

export default DealCalendarComments;
