import {Moment} from "moment";
import {
  ResultCalendarDateType,
  ResultCalendarDateUserType,
  ResultCalendarSumUserType,
} from "../../../../redux/reducers/types/calendarApiTypes";

interface ExistClosingsCalendarResultByDatesType {
  oneOfALotDates: {
    "Law Clerk":
      | {
          oneOfLawClerks: ResultCalendarSumUserType;
          total: number;
        }
      | [];
    Lawyer:
      | {
          oneOfLawyers: ResultCalendarSumUserType;
          total: number;
        }
      | [];
  };
}
interface ExistResultCalendarDateType {
  "Law Clerk": {
    oneOfLawClerks: ResultCalendarDateUserType;
  };
  Lawyer: {
    oneOfLawyers: ResultCalendarDateUserType;
  };
  weekDay: string;
}
export const selectUserClosingsCalendarData = (
  role: "Lawyer" | "Law Clerk",
  day: Moment,
  closingsCalendarResultByDates: ResultCalendarDateType,
) => {
  const typedClosingsCalendarResultByDates =
    closingsCalendarResultByDates as unknown as ExistClosingsCalendarResultByDatesType;
  const currentDayForSelectClosingsCounts = day.format(
    "YYYY-MM-DD",
  ) as keyof typeof typedClosingsCalendarResultByDates;
  const ExistResultCalendarSum = typedClosingsCalendarResultByDates[
    currentDayForSelectClosingsCounts
  ] as unknown as ExistResultCalendarDateType;
  if (role === "Lawyer") {
    return ExistResultCalendarSum?.[role]
      ? Object.keys(
          typedClosingsCalendarResultByDates[
            currentDayForSelectClosingsCounts
          ]?.["Lawyer"],
        )
          ?.filter((key) => key !== "total")
          ?.map((key) => {
            return {
              name: ExistResultCalendarSum?.["Lawyer"][key as "oneOfLawyers"]
                ?.full_name,
              count:
                ExistResultCalendarSum?.["Lawyer"][key as "oneOfLawyers"]
                  ?.deals,
              deals_names:
                ExistResultCalendarSum?.["Lawyer"][key as "oneOfLawyers"]
                  ?.deals_names,
            };
          })
      : [];
  } else {
    return ExistResultCalendarSum?.["Law Clerk"]
      ? Object.keys(ExistResultCalendarSum?.["Law Clerk"])
          ?.filter((key) => key !== "total")
          ?.map((key) => {
            return {
              name: ExistResultCalendarSum?.["Law Clerk"][
                key as "oneOfLawClerks"
              ]?.full_name,
              count:
                ExistResultCalendarSum?.["Law Clerk"][key as "oneOfLawClerks"]
                  ?.deals,
              deals_names:
                ExistResultCalendarSum?.["Law Clerk"][key as "oneOfLawClerks"]
                  ?.deals_names,
            };
          })
      : [];
  }
};
