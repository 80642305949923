import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {Box, styled} from "@mui/material";
import {Theme} from "@mui/material/styles";
import {useAppSelector} from "redux/hooks";
import {
  setLawClerkCountTableViewAC,
  setLawyerCountTableViewAC,
  setMobileSignerCountTableViewAC,
} from "redux/reducers/calendarReducer";
import {useGetCountMobileSignerAppointmentsQuery} from "redux/reducers/calendarApi";
import {ClosingsCountApiType} from "redux/reducers/types/calendarApiTypes";
import useTypedSelector from "utils/hooks/useTypedSelector";
import useUser from "utils/hooks/useUser";
import DeededSpace from "v2/components/DeededSpace";
import {getDateStartOrEndForRequestClosingOrAppointmentsCounts} from "../utils/getDateStartOrEndForRequestClosingOrAppointmentsCounts";
import {getMobileSignersSigningAppointmentCountTableArray} from "../utils/getMobileSignersSigningAppointmentCountTableArray";
import {getLawClerksOrLawyersClosingsTable} from "../utils/getLawClerksOrLawyersClosingsTable";
import {rolesForGettingSigningAppointments} from "../constants/rolesForGettingSigningAppointments";
import {allProvincesLabelsList} from "../constants/allProvincesLabelsList";
import CalendarEventsCountTable from "./CalendarEventsCountTable";

export interface MobileSignersAppointmentsType {
  oneOfBigAmountUsers: {
    full_name: string;
    sum: number;
    dealTypes: {
      Purchase: number;
      Sale: number;
      Mortgage: number;
      Lender: number;
    };
  };
}
interface CalendarEventsCountingTablesProps {
  disablePaddings?: boolean;
  date: Date;
  view: "day" | "week" | "month";
  isGettingEvents: boolean;
  regions: string | string[];
  dealType: string;
  setSigningsTotal: React.Dispatch<React.SetStateAction<number>>;
  closingsList?: ClosingsCountApiType[] & {
    resultCalendar: {closings_count: number};
  };
}
const CalendarEventsCountingTables: React.FC<
  CalendarEventsCountingTablesProps
> = ({
  disablePaddings,
  view,
  isGettingEvents,
  regions,
  date,
  dealType,
  setSigningsTotal,
  closingsList,
}) => {
  const dispatch = useDispatch();
  const calendarType = useAppSelector(
    (state) => state.CalendarReducer.calendarType,
  );
  const selectedStaffUser = useTypedSelector(
    (state) => state.CalendarReducer.selectedStaffUser,
  );

  const dateStartForRequestClosingOrAppointmentsCounts =
    getDateStartOrEndForRequestClosingOrAppointmentsCounts(view, date, "start");
  const dateEndForRequestClosingOrAppointmentsCounts =
    getDateStartOrEndForRequestClosingOrAppointmentsCounts(view, date, "end");
  const user = useUser().user;
  const regionsArray = regions as string[];
  const {
    data: mobileSignersAppointments,
    refetch: refetchMobileSignerAppointments,
  } = useGetCountMobileSignerAppointmentsQuery(
    {
      dateFrom: dateStartForRequestClosingOrAppointmentsCounts,
      dateTo: dateEndForRequestClosingOrAppointmentsCounts,
      region:
        regionsArray?.length === allProvincesLabelsList.length
          ? "ALL"
          : regionsArray?.join(","),
      users: selectedStaffUser ? `${selectedStaffUser?.id}` : "ALL",
    },
    {skip: !rolesForGettingSigningAppointments.includes(user.role as string)},
  );

  useEffect(() => {
    if (closingsList) {
      setSigningsTotal(closingsList?.resultCalendar.closings_count);
    }
  }, [closingsList, setSigningsTotal]);

  const typedMobileSignersAppointments =
    mobileSignersAppointments as MobileSignersAppointmentsType;

  const mobileSignersSigningAppointmentCountTableArray =
    getMobileSignersSigningAppointmentCountTableArray(
      typedMobileSignersAppointments,
    );

  useEffect(() => {
    refetchMobileSignerAppointments();
  }, [view, refetchMobileSignerAppointments]);
  useEffect(() => {
    refetchMobileSignerAppointments();
  }, [date, refetchMobileSignerAppointments]);
  const lawClerksClosingsTable = getLawClerksOrLawyersClosingsTable(
    "Law Clerk",
    closingsList as unknown as ClosingsCountApiType,
  );
  const lawyerClosingsTable = getLawClerksOrLawyersClosingsTable(
    "Lawyer",
    closingsList as unknown as ClosingsCountApiType,
  );
  useEffect(() => {
    if (view !== "week") {
      dispatch(setMobileSignerCountTableViewAC("dealType"));
      dispatch(setLawyerCountTableViewAC("dealType"));
      dispatch(setLawClerkCountTableViewAC("dealType"));
    }
  }, [dispatch, view]);

  return (
    <>
      <CalendarEventsCountingTablesMainBox>
        {calendarType === "Signing Appointment" && (
          <CalendarEventsCountTable
            disablePaddings={disablePaddings}
            users={mobileSignersSigningAppointmentCountTableArray}
            view={view}
            title={
              disablePaddings
                ? "Mobile Signers"
                : "Mobile Signers Signing Appointment Count"
            }
            forRole={"Mobile Signer"}
            tableViewSelector={(state) =>
              state.CalendarReducer.mobileSignerCountTableView
            }
          />
        )}

        {calendarType === "Closings" && (
          <>
            {calendarType === "Closings" &&
            view === "month" &&
            !isGettingEvents ? (
              <DeededSpace y={25} />
            ) : (
              <></>
            )}
            <CalendarEventsCountTable
              disablePaddings={disablePaddings}
              users={lawClerksClosingsTable}
              view={view}
              title={"Law Clerk Closing Count"}
              forRole={"Law Clerk"}
              tableViewSelector={(state) =>
                state.CalendarReducer.lawClerkCountTableView
              }
            />
            <DeededSpace y={25} />
            <CalendarEventsCountTable
              disablePaddings={disablePaddings}
              users={lawyerClosingsTable}
              view={view}
              title={"Lawyer Closing Count"}
              forRole={"Lawyer"}
              tableViewSelector={(state) =>
                state.CalendarReducer.lawyerCountTableView
              }
            />
          </>
        )}
      </CalendarEventsCountingTablesMainBox>
    </>
  );
};

const CalendarEventsCountingTablesMainBox = styled(Box)<{theme?: Theme}>(
  ({theme}) => ({
    minWidth: "100%",
    width: "100%",
  }),
);

export default CalendarEventsCountingTables;
