import {SVGProps} from "react";

const RestoreIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.481 9.347h-4.5v-4.5"
        stroke=""
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.169 17.831a8.25 8.25 0 1 0 0-11.662L2.98 9.347"
        stroke=""
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RestoreIcon;
