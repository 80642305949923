import React from "react";

const StorageImageIconSvg = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25 28H6.99995C6.73474 28 6.4804 27.8946 6.29287 27.7071C6.10534 27.5196 5.99999 27.2652 6 27V5C5.99999 4.73479 6.10534 4.48044 6.29287 4.2929C6.4804 4.10537 6.73474 4.00001 6.99995 4H19.0003L26 11V27C26 27.2652 25.8947 27.5196 25.7071 27.7071C25.5196 27.8946 25.2653 28 25 28V28Z"
        stroke="#828282"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 4V11H26.001"
        stroke="#828282"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.8499 21.4469L17.2812 18.3469C17.3049 18.3169 17.335 18.2928 17.3694 18.2762C17.4037 18.2595 17.4414 18.2509 17.4796 18.2509C17.5178 18.2509 17.5554 18.2595 17.5898 18.2762C17.6242 18.2928 17.6543 18.3169 17.678 18.3469L21.9437 23.7844C22.0718 23.9469 21.9562 24.1875 21.7468 24.1875H10.2499C10.2031 24.1876 10.1572 24.1745 10.1175 24.1498C10.0778 24.125 10.0458 24.0896 10.0253 24.0476C10.0048 24.0055 9.99647 23.9585 10.0014 23.912C10.0063 23.8654 10.0242 23.8212 10.053 23.7844L13.1718 19.8094C13.1952 19.7795 13.2251 19.7553 13.2592 19.7387C13.2933 19.7221 13.3307 19.7135 13.3687 19.7135C13.4066 19.7135 13.4441 19.7221 13.4782 19.7387C13.5123 19.7553 13.5422 19.7795 13.5655 19.8094L14.8499 21.4469Z"
        fill="#828282"
      />
      <path
        d="M11.6129 17.1339C11.3785 16.8995 11.2468 16.5815 11.2468 16.25C11.2468 15.9185 11.3785 15.6005 11.6129 15.3661C11.8473 15.1317 12.1653 15 12.4968 15C12.8283 15 13.1462 15.1317 13.3807 15.3661C13.6151 15.6005 13.7468 15.9185 13.7468 16.25C13.7468 16.5815 13.6151 16.8995 13.3807 17.1339C13.1462 17.3683 12.8283 17.5 12.4968 17.5C12.1653 17.5 11.8473 17.3683 11.6129 17.1339Z"
        fill="#828282"
      />
    </svg>
  );
};

export default StorageImageIconSvg;
