import {FC} from "react";
import {useFormik} from "formik";
import * as yup from "yup";
import {Stack} from "@mui/material";
import {AdditionalDocument} from "./AdditionalDocuments";
import BasicButton from "../../Common/Button/BasicButton";
import TextField from "../../CommonDashboard/TextField";

interface CustomFileFormProps {
  onSubmit: (values: CustomDocForm) => void;
  onCancel: () => void;
  showCustomFile: boolean;
  additionalFiles: AdditionalDocument[];
}

export type CustomDocForm = Omit<AdditionalDocument, "uploader">;

const CustomFileForm: FC<CustomFileFormProps> = ({
  onSubmit,
  onCancel,
  showCustomFile,
  additionalFiles,
}) => {
  const schema = yup.object().shape({
    document_type: yup
      .string()
      .test("document_type", "Field is required", (value) =>
        showCustomFile ? (value?.length || 0) > 0 : true,
      ),
    description: yup
      .string()
      .test("description", "Field is required", (value) =>
        showCustomFile ? (value?.length || 0) > 0 : true,
      ),
  });

  const {handleSubmit, getFieldProps, isSubmitting, errors, isValid} =
    useFormik<CustomDocForm>({
      onSubmit,
      validationSchema: schema,
      initialValues: {
        document_type: "",
        description: "",
      },
    });

  return (
    <Stack gap="2rem">
      {showCustomFile && (
        <TextField
          {...getFieldProps("document_type")}
          error={errors.document_type}
          outlined
          disabled={isSubmitting}
          label="Document name"
          placeholder="Type a custom document name"
          type="text"
          data-testid="custom-file-name-input"
        />
      )}
      {showCustomFile && (
        <TextField
          {...getFieldProps("description")}
          error={errors.description}
          outlined
          multiline
          maxRows={4}
          disabled={isSubmitting}
          label="Document description"
          placeholder="Type a custom document description"
          type="text"
          data-testid="custom-file-description-input"
          sx={{ textarea: {minHeight: "10rem"} }}
        />
      )}

      <Stack direction="row" justifyContent="end" gap="2rem">
        <BasicButton
          onClick={onCancel}
          typeOf="secondary"
          label={{text: "Cancel"}}
        />
        <BasicButton
          disabled={!isValid || (!additionalFiles.length && !showCustomFile)}
          onClick={handleSubmit}
          typeOf="primary"
          label={{text: "Submit", inputId: "request-file-submit"}}
        />
      </Stack>
    </Stack>
  );
};

export default CustomFileForm;
