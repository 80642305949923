import Label from "./Label";
import StoragePlusIconSvg from "../../Svg/StoragePlusIconSvg";
import React, {useEffect, useState} from "react";
import SelectedFilesMoveStorage from "../StorageCommonComponents/SelectedFilesMoveStorage/SelectedFilesMoveStorage";
import {useDispatch, useSelector} from "react-redux";
import {downloadStorageFile, downloadStorageFiles} from "../../../api";
import {saveAs} from "file-saver";
import WarningMessageForMovingFoldersSvg from "../../Svg/WarningMessageForMovingFoldersSvg";
import {
  clearDownloadFiles,
  setCheckedFilesAndFoldersAC,
  setIsSelectedFoldersMoveStorageAC,
} from "../../../redux/reducers/selectedFileMoveStorageReducer";
import * as storageConfig from "components/Dashboard/StorageCommonComponents/utils/storageConfig";
import {clearBreadCrumbsAC} from "../../../redux/reducers/storageReducer";
import {addErrorAC} from "../../../redux/reducers/ErrorReducer";
import DeededButton from "../../../v2/components/DeededButton";
import constants from "../../../styles/constants";
import {Box, styled, Typography, useTheme} from "@mui/material";
import useWindowWidth from "../../../utils/hooks/useWindowWidth";

const StorageHeading = ({setAddNewDocument, requestWorkdocsAccess = null}) => {
  const theme = useTheme();
  const [isSelectedFilesMoveStorage, setIsSelectedFilesMoveStorage] =
    useState(false);
  const [warningFolders, setWarningFolders] = useState(false);
  const windowWidth = useWindowWidth();
  const isShouldShowWarning = warningFolders && windowWidth > 1390;
  const isShouldShowWarningForFlexibleStorage =
    warningFolders && windowWidth <= 1390;
  const [isDownloadingFiles, setIsDownloadingFiles] = useState(false);
  const checkedFiles = useSelector(
    (state) => state.SelectedFileMoveStorageReducer.checkedFiles,
  );
  const checkedFolders = useSelector(
    (state) => state.SelectedFileMoveStorageReducer.checkedFolders,
  );
  const checkedFilesAndFolders = useSelector(
    (state) => state.SelectedFileMoveStorageReducer.checkedFilesAndFolders,
  );
  const isSelectedFoldersMoveStorage = useSelector(
    (state) =>
      state.SelectedFileMoveStorageReducer.isSelectedFoldersMoveStorage,
  );
  const {folders} = useSelector((state) => state.StorageReducer);
  const dispatch = useDispatch();
  useEffect(() => {
    if (checkedFolders.length > 0) {
      dispatch(setIsSelectedFoldersMoveStorageAC(true));
    } else {
      dispatch(setIsSelectedFoldersMoveStorageAC(false));
      setWarningFolders(false);
    }
  }, [checkedFolders, dispatch]);
  useEffect(() => {
    dispatch(setCheckedFilesAndFoldersAC());
  }, [checkedFiles, checkedFolders, dispatch]);
  const redirectAfterDownloading = () => {
    dispatch((null, undefined, undefined, undefined, false));
    dispatch(clearDownloadFiles());
    dispatch(clearBreadCrumbsAC());
  };

  return (
    <GeneralBox className="subTitleStorage">
      {window.location.pathname === "/dashboard/storage" && (
        <div className="storageTitleBox">
          <p data-testid="title" className="storageTitle">STORAGE</p>
        </div>
      )}
      <div className="storageSubtitleBox">
        <Label />
        {isShouldShowWarning && (
          <div className={"moveFoldersWarning"}>
            <div className={"warningMessageBox"}>
              <div className={"warningMessageForMovingFoldersSvg"}>
                <WarningMessageForMovingFoldersSvg />
              </div>
              <p className={"warningMessage"}>
                It is possible to move only files in storage
              </p>
            </div>
            <div className={"rotatedMoveFoldersWarningSmallBox"}></div>
          </div>
        )}

        {isSelectedFilesMoveStorage && !isSelectedFoldersMoveStorage && (
          <SelectedFilesMoveStorage
            setIsSelectedFilesMoveStorage={setIsSelectedFilesMoveStorage}
          />
        )}
        {!storageConfig.hideMoveToFunctionality && (
          <>
            <ButtonBox>
              <DownloadFilesButton
                theme={theme}
                onClick={() => {
                  if (!isDownloadingFiles) {
                    setIsDownloadingFiles(true);
                    if (
                      checkedFilesAndFolders.length === 1 &&
                      checkedFiles.includes(checkedFilesAndFolders[0])
                    ) {
                      const checkedFile = folders.filter(
                        (file) => file.id === checkedFilesAndFolders[0],
                      );
                      downloadStorageFile(checkedFilesAndFolders[0])
                        .then((blob) => {
                          saveAs(blob, checkedFile?.[0].name);
                          setIsDownloadingFiles(false);
                        })
                        .catch((e) => {
                          setIsDownloadingFiles(false);
                          const error = JSON.parse(
                            [...new Uint8Array(e.response.data)]
                              .map((x) => String.fromCharCode(x))
                              .join(""),
                          ).error;
                          dispatch(addErrorAC(error));
                        });
                    } else {
                      downloadStorageFiles(checkedFilesAndFolders)
                        .then((blob) => {
                          saveAs(blob, "Download_Files.zip");
                          window.location.pathname === "/dashboard/storage" &&
                            redirectAfterDownloading();
                          window.location.pathname !== "/dashboard/storage" &&
                            dispatch(clearDownloadFiles());
                          setIsDownloadingFiles(false);
                        })
                        .catch((e) => {
                          setIsDownloadingFiles(false);
                          const error = JSON.parse(
                            [...new Uint8Array(e.response.data)]
                              .map((x) => String.fromCharCode(x))
                              .join(""),
                          ).error;
                          dispatch(addErrorAC(error));
                        });
                    }
                  }
                }}
              >
                {isDownloadingFiles ? "Loading..." : "Download Files"} (
                {checkedFilesAndFolders.length})
              </DownloadFilesButton>
            </ButtonBox>
            <ButtonBox>
              <MoveToButton
                kind={"secondary"}
                onClick={() => {
                  if (isSelectedFoldersMoveStorage) setWarningFolders(true);
                  else
                    setIsSelectedFilesMoveStorage(!isSelectedFilesMoveStorage);
                }}
              >
                {isShouldShowWarningForFlexibleStorage && (
                  <FlexibleMoveStorageWarning />
                )}
                Move ({checkedFiles.length})
              </MoveToButton>
            </ButtonBox>
          </>
        )}
        <ButtonBox>
          <AddNewDocumentButton
            theme={theme}
            onClick={() => {
              setAddNewDocument(true);
            }}
          >
            <StoragePlusIconSvg />
            Add new document
          </AddNewDocumentButton>
        </ButtonBox>
        {requestWorkdocsAccess && requestWorkdocsAccess()}
      </div>
    </GeneralBox>
  );
};
const FlexibleMoveStorageWarning = () => {
  return (
    <>
      <Box
        sx={{
          position: "absolute",
          top: "-40px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "183px",
            height: "46px",
            borderRadius: "5px",
            background: "#ffe7e7",
          }}
        >
          <Box
            sx={{
              marginLeft: "11px",
              marginTop: "11px",
            }}
            className={"warningMessageForMovingFoldersSvg"}
          >
            <WarningMessageForMovingFoldersSvg />
          </Box>
          <Typography
            sx={{
              ontFamily: "Montserrat",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: "12px",
              lineHeight: "15px",
              marginLeft: "8px",
              display: "flex",
              alignItems: "center",
              color: "#f05a57",
            }}
          >
            It is possible to move only files in storage
          </Typography>
        </Box>
        <div className={"rotatedMoveFoldersWarningSmallBox"}></div>
      </Box>
    </>
  );
};
const ButtonBox = styled(Box)(({theme}) => ({
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    paddingRight: "50px",
  },
}));
const GeneralBox = styled(Box)(({theme}) => ({}));
const AddNewDocumentButton = styled(DeededButton)(({theme}) => ({
  width: "216px",
  marginTop: "11px",
  marginRight: "10px",
  backgroundColor: constants.colors.green,
  border: `1px solid ${constants.colors.green}`,
  [theme.breakpoints.down(1435)]: {
    fontSize: "10px",
    width: "170px",
  },
  svg: {
    stroke: constants.colors.white,
    marginRight: "11px",
    transition: "all 250ms",
  },
  "&:hover": {
    svg: {
      stroke: constants.colors.red,
      transition: "all 250ms",
    },
    backgroundColor: constants.colors.white,
    color: constants.colors.red,
    transition: "all 250ms",
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    marginLeft: "20px",
    fontSize: "14px",
  },
}));
const MoveToButton = styled(DeededButton)(({theme}) => ({
  position: "relative",
  marginRight: "24px",
  marginTop: "11px",
  width: "120px",
  [theme.breakpoints.down(1435)]: {
    fontSize: "10px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    marginLeft: "20px",
    fontSize: "14px",
  },
}));
const DownloadFilesButton = styled(DeededButton)(({theme}) => ({
  marginRight: "24px",
  marginTop: "11px",
  width: "160px",
  [theme.breakpoints.down(1435)]: {
    fontSize: "10px",
    width: "120px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    marginLeft: "20px",
    fontSize: "14px",
  },
}));
export default StorageHeading;
