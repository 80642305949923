import React from "react";

type CalendarType = {
  color: string | undefined;
};
const Calendar: React.FC<CalendarType> = ({color}) => {
  return (
    <svg width="18" height="17" viewBox="0 0 18 17" fill={color}>
      <path
        d="M5.23161 0.113647C5.01822 0.113647 4.84525 0.288366 4.84525 0.503892V3.82097C4.84525 4.0365 5.01822 4.21122 5.23161 4.21122C5.445 4.21122 5.61798 4.0365 5.61798 3.82097V0.503892C5.61798 0.288366 5.445 0.113647 5.23161 0.113647ZM13.7316 0.113647C13.5182 0.113647 13.3452 0.288366 13.3452 0.503892V3.82097C13.3452 4.0365 13.5182 4.21122 13.7316 4.21122C13.945 4.21122 14.118 4.0365 14.118 3.82097V0.503892C14.118 0.288366 13.945 0.113647 13.7316 0.113647ZM2.52707 1.28438C1.66822 1.28438 0.975586 1.98398 0.975586 2.85146V6.35756H17.9756V2.85146C17.9756 1.98398 17.2829 1.28438 16.4241 1.28438H15.271H14.5043V2.94902C14.7413 3.16338 14.8907 3.47439 14.8907 3.82097C14.8907 4.46755 14.3718 4.99171 13.7316 4.99171C13.0915 4.99171 12.5725 4.46755 12.5725 3.82097C12.5725 3.47439 12.7219 3.16338 12.9589 2.94902V1.28438H5.99831V2.94292C6.23894 3.15744 6.3907 3.4713 6.3907 3.82097C6.3907 4.46755 5.87176 4.99171 5.23161 4.99171C4.59147 4.99171 4.07252 4.46755 4.07252 3.82097C4.07252 3.47748 4.21957 3.16928 4.45286 2.95512V1.28438H2.52707ZM0.975586 7.13805V14.5466C0.975586 15.414 1.6682 16.1136 2.52707 16.1136H16.4241C17.2829 16.1136 17.9756 15.414 17.9756 14.5466V7.13805H0.975586Z"
        fill={color}
      />
    </svg>
  );
};

export default Calendar;
