import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  styled,
  Typography,
} from "@mui/material";
import constants from "styles/constants";
import DeededSpace from "v2/components/DeededSpace";
import useTypedSelector from "utils/hooks/useTypedSelector";
import CalendarAccordionRow from "components/Svg/CalendarAccordionRow";
import DealCalendarComments from "../DealCalendarComments";
import CalendarDealLenderInfo from "./CalendarDealLenderInfo";
import AssignedTeamList from "./AssignedTeamList";
import CalendarSigningAppointment from "./CalendarSigningAppointment";

const DealInfoAccordions = () => {
  const dealFullInfo = useTypedSelector(
    (state) => state.CalendarReducer.dealFullInfo,
  );
  return (
    <>
      <DealInfoAccordion defaultExpanded={true}>
        <StyledAccordionSummary expandIcon={<CalendarAccordionRow />}>
          <AccordionTypography>Signing Appointment</AccordionTypography>
        </StyledAccordionSummary>
        <AccordionDetails sx={{padding: "8px 0"}}>
          <CalendarSigningAppointment />
        </AccordionDetails>
      </DealInfoAccordion>
      <DeededSpace y={16} />
      <DealInfoAccordion
        defaultExpanded={true}
        sx={{
          borderRadius: "5px",
          minHeight: "44px !important",
        }}
      >
        <StyledAccordionSummary expandIcon={<CalendarAccordionRow />}>
          <AccordionTypography>Assigned Team</AccordionTypography>
        </StyledAccordionSummary>
        <DeededSpace y={16} />
        <AccordionDetails sx={{padding: 0}}>
          <AssignedTeamList />
        </AccordionDetails>
      </DealInfoAccordion>
      <DeededSpace y={16} />
      {dealFullInfo?.lender_name && (
        <>
          {" "}
          <DealInfoAccordion
            sx={{
              borderRadius: "5px",
            }}
          >
            <StyledAccordionSummary expandIcon={<CalendarAccordionRow />}>
              <AccordionTypography>Lender Information</AccordionTypography>
            </StyledAccordionSummary>
            <AccordionDetails>
              <CalendarDealLenderInfo />
            </AccordionDetails>
          </DealInfoAccordion>
          <DeededSpace y={16} />
        </>
      )}
      <DealInfoAccordion
        sx={{
          borderRadius: "5px",
        }}
      >
        <StyledAccordionSummary expandIcon={<CalendarAccordionRow />}>
          <AccordionTypography>Comments</AccordionTypography>
        </StyledAccordionSummary>
        <AccordionDetails
          sx={{
            padding: "0 !important",
          }}
        >
          <DealCalendarComments />
          <DeededSpace y={31} />
        </AccordionDetails>
      </DealInfoAccordion>
    </>
  );
};
const StyledAccordionSummary = styled(AccordionSummary)({
  background: "#F2F2F2",
});
const AccordionTypography = styled(Typography)({
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "18px",
  color: constants.colors.title,
});
const DealInfoAccordion = styled(Accordion)({
  boxShadow: "none",
  border: "none",
  margin: "0 !important",
  "&:before": {
    display: "none",
  },
  "& .MuiPaper-root.MuiButtonBase-root": {
    minHeight: "44px ",
  },
  "& .MuiAccordionSummary-root": {
    borderRadius: "5px",
    minHeight: "44px !important",
  },
  "& .MuiAccordionSummary-root.Mui-expanded": {
    minHeight: "44px",
    "& .MuiAccordionSummary-content": {
      margin: 0,
    },
  },
  "&. MuiPaper-root .MuiAccordion-root .MuiAccordionSummary-root": {
    minHeight: "44px",
  },
});
export default DealInfoAccordions;
