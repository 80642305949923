import React, {useContext, useEffect} from "react";
import DeededPopup from "v2/components/DeededPopup";
import CloseDeededSvg from "components/Svg/CloseDeededSvg";
import {Box, IconButton, styled, Typography} from "@mui/material";
import constants from "styles/constants";
import DeededButton from "v2/components/DeededButton";
import {DealDetailsFileTasksContext} from "components/Dashboard/DealDetailsFileTasks/context/DealDetailsFileTasksContext";
import {useSetDefaultTasksListByTaskTypeMutation} from "redux/reducers/dealInfoApi";

const DealDetailsFileTasksSwitchFileTaskPopup = () => {
  const [setDefaultTasksListByTaskTypeCallback] =
    useSetDefaultTasksListByTaskTypeMutation();
  const {
    dealId,
    isOpenedFileTaskPopup,
    setIsOpenedFileTaskPopup,
    setSelectedFileType,
    fileTypeValueToSet,
    setFileTypeValueToSet,
  } = useContext(DealDetailsFileTasksContext);
  const handleConfirm = () => {
    setSelectedFileType(fileTypeValueToSet);
    setIsOpenedFileTaskPopup(false);
    setDefaultTasksListByTaskTypeCallback({
      dealId,
      taskType: fileTypeValueToSet,
    });
  };
  useEffect(() => {
    return () => {
      setFileTypeValueToSet("");
    };
  }, [setFileTypeValueToSet]);
  return (
    <>
      <DeededPopup
        open={isOpenedFileTaskPopup}
        setOpen={setIsOpenedFileTaskPopup}
        sxInternalBox={{
          position: "relative",
          width: "394px",
          height: "350px",
          padding: "32px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <CloseBtn
          onClick={() => {
            setIsOpenedFileTaskPopup(false);
          }}
        >
          <CloseDeededSvg />
        </CloseBtn>
        <Typography
          sx={{
            fontWeight: "600",
            fontSize: "18px",
            color: constants.colors.title,
            marginBottom: "32px",
          }}
        >
          Switch file task type?
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
            fontSize: "14px",
            color: constants.colors.intakeTitle,
            marginBottom: "40px",
          }}
        >
          By pressing “Confirm” you will lose any progress on your current file
          task list
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
            fontSize: "14px",
            color: constants.colors.intakeTitle,
            marginBottom: "44px",
          }}
        >
          Please confirm that you would like to switch the File Task type. Any
          progress made on your current task list will be deleted
        </Typography>
        <PopupButtonsBox>
          <DeededButton
            kind={"secondary-v2"}
            sx={{
              width: "156px",
            }}
            onClick={() => {
              setIsOpenedFileTaskPopup(false);
            }}
          >
            Cancel
          </DeededButton>
          <DeededButton
            sx={{
              width: "156px",
            }}
            onClick={handleConfirm}
          >
            Confirm
          </DeededButton>
        </PopupButtonsBox>
      </DeededPopup>
    </>
  );
};
const CloseBtn = styled(IconButton)({
  position: "absolute",
  top: "8px",
  right: "8px",
  transition: `${constants.transitions} !important`,
  "&:hover": {
    transition: `${constants.transitions} !important`,
  },
});
const PopupButtonsBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
});
export default DealDetailsFileTasksSwitchFileTaskPopup;
