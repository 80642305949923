import React from "react";

const Edit = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.8097 0.128906C14.7101 0.128906 14.6138 0.164656 14.5368 0.241656L2.03394 12.7053C1.98581 12.7535 1.95144 12.8126 1.93425 12.8731L0.123373 19.383C0.0855606 19.5178 0.123373 19.6622 0.221686 19.7612C0.295248 19.8333 0.393561 19.8725 0.493248 19.8725C0.527623 19.8725 0.563373 19.8677 0.600498 19.8574L7.10769 18.0094C7.15169 17.997 7.19844 17.9702 7.25275 17.9221C7.30981 17.8712 7.31737 17.865 7.27819 17.9056L7.2995 17.8829L7.32356 17.865C7.36412 17.8334 7.37306 17.8245 7.36137 17.8362L19.7769 5.48522C19.8498 5.41303 19.8904 5.31472 19.8904 5.21228C19.8904 5.11122 19.8505 5.01222 19.7776 4.93934L15.0799 0.240281C15.0036 0.163969 14.9087 0.128906 14.8084 0.128906H14.8097ZM14.807 1.05772L18.9595 5.21022L17.2194 6.93928L13.069 2.79022L14.51 1.35334L14.807 1.05772ZM12.5231 3.33334L14.3608 5.16966L4.7255 14.8063L2.87406 12.9508L12.2261 3.62897L12.5231 3.33334ZM14.9053 5.71553L16.6722 7.48103L7.01075 17.0985L5.26931 15.3529L14.609 6.01322L14.9053 5.71553ZM2.509 13.6761L6.26756 17.4457L1.04806 18.9265L2.31581 14.3705L2.509 13.6761Z"
        fill="black"
        fillOpacity="0.5"
      />
    </svg>
  );
};

export default Edit;
