import {User} from "types/user";
import {Dispatch} from "redux";
import {getProfile} from "../../api";

export interface AuthReducerState {
  currentUser: Partial<User>;
  authLoading: boolean;
  error: string;
  refreshCalled: boolean;
}

type AuthActionType =
  | "AUTH_LOADER_ON"
  | "AUTH_LOADER_OFF"
  | "USER_UPDATE"
  | "USER_LOGIN"
  | "USER_LOGOUT"
  | "LOGIN_ERROR"
  | "REMOVE_ERROR"
  | "UPDATE_CURRENT_USER"
  | "SET_FULL_NAME"
  | "UPDATE_WORKDOC_USER_STATUS"
  | "UPDATE_CALENDAR_REGIONS"
  | "UPDATE_CALENDAR_VIEW"
  | "UPDATE_CALENDAR_ROLE"
  | "UPDATE_CALENDAR_DEAL_TYPE"
  | "REFRESH_CALLED";

export type AuthAction = {
  type: AuthActionType;
  payload?: unknown;
};

const authState: AuthReducerState = {
  currentUser: {},
  authLoading: false,
  error: "",
  refreshCalled: false,
};

export const AuthReducer = (
  state = authState,
  action: AuthAction,
): AuthReducerState => {
  switch (action.type) {
    case "REFRESH_CALLED":
      return {
        ...state,
        refreshCalled: true,
      };
    case "UPDATE_CALENDAR_REGIONS": {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          settings: {
            ...state.currentUser.settings,
            calendar: {
              ...state.currentUser.settings!.calendar,
              region: action.payload as string,
            },
          },
        },
      };
    }
    case "UPDATE_CALENDAR_VIEW": {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          settings: {
            ...state.currentUser.settings,
            calendar: {
              ...state.currentUser.settings!.calendar,
              view: action.payload as string,
            },
          },
        },
      };
    }
    case "UPDATE_CALENDAR_ROLE": {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          settings: {
            ...state.currentUser.settings,
            calendar: {
              ...state.currentUser.settings!.calendar,
              role: action.payload as string,
            },
          },
        },
      };
    }
    case "UPDATE_CALENDAR_DEAL_TYPE": {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          settings: {
            ...state.currentUser.settings,
            calendar: {
              ...state.currentUser.settings!.calendar,
              dealType: action.payload as string,
            },
          },
        },
      };
    }
    case "UPDATE_WORKDOC_USER_STATUS": {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          workdocs_user_status: action.payload as boolean,
        },
      };
    }
    case "UPDATE_CURRENT_USER": {
      return {
        ...state,
        currentUser: action.payload as User,
      };
    }
    case "AUTH_LOADER_OFF":
      return {...state, authLoading: false};
    case "AUTH_LOADER_ON":
      return {...state, authLoading: true};
    case "USER_LOGIN": {
      return {
        ...state,
        currentUser: action.payload as User,
        authLoading: false,
      };
    }
    case "USER_UPDATE": {
      return {
        ...state,
        currentUser: action.payload as User,
      };
    }
    case "USER_LOGOUT":
      return {
        ...state,
        refreshCalled: false,
        currentUser: {},
      };
    case "LOGIN_ERROR":
      return {...state, error: action.payload as string, authLoading: false};
    case "REMOVE_ERROR":
      return {...state, error: ""};
    default:
      return state;
  }
};
export const setUpdateWorkdocUserStatus = (payload: boolean) => ({
  type: "UPDATE_WORKDOC_USER_STATUS",
  payload,
});
export const setCalendarRegionAC = (payload: string | null) => ({
  type: "UPDATE_CALENDAR_REGIONS",
  payload,
});
export const setCalendarViewAC = (payload: string | null) => ({
  type: "UPDATE_CALENDAR_VIEW",
  payload,
});
export const setCalendarRoleAC = (payload: string | null) => ({
  type: "UPDATE_CALENDAR_ROLE",
  payload,
});
export const setCalendarDealTypeAC = (payload: string | null) => ({
  type: "UPDATE_CALENDAR_DEAL_TYPE",
  payload,
});
export const setCurrentUserAC = (payload: User | null) => ({
  type: "UPDATE_CURRENT_USER",
  payload,
});
export const getCurrentUserTC = () => async (dispatch: Dispatch) => {
  const currentUser = await getProfile();
  dispatch(setCurrentUserAC(currentUser.data));
};
