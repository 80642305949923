const timeZones = [
  {name: "Alberta (AB)", value: "America/Edmonton"},
  {name: "British Columbia (BC)", value: "America/Vancouver"},
  {name: "Manitoba (MB)", value: "America/Winnipeg"},
  {name: "New Brunswick (NB)", value: "America/Moncton"},
  {name: "Newfoundland & Labrador (NL)", value: "America/Goose_Bay"},
  {name: "Northwest Territories (NT)", value: "America/Yellowknife"},
  {name: "Nova Scotia (NS)", value: "America/Halifax"},
  {name: "Nunavut (NU)", value: "America/Resolute"},
  {name: "Ontario (ON)", value: "America/Toronto"},
  {name: "Prince Edward Island (PE)", value: "America/Halifax"},
  {name: "Quebec (QC)", value: "America/Montreal"},
  {name: "Saskatchewan (SK)", value: "America/Regina"},
  {name: "Yukon(YT)", value: "America/Whitehorse"},
];

export default timeZones;
