import React, {useEffect} from "react";
import {Box, styled, Typography} from "@mui/material";
import StorageCloseIconSvg from "../../../Svg/StorageCloseIconSvg";
import DocumentFrameDisplay from "components/Dashboard/DocumentView/DocumentFrameDisplay";
import DeededCircularLoader from "v2/components/DeededCircularLoader";
import {Theme} from "@mui/material/styles";
import useTypedSelector from "../../../../utils/hooks/useTypedSelector";

type DocumentPreviewProps = {
  setIsPreviewDocument: (toggle: boolean) => void;
  file: {data: string; type: string; name: string} | null;
  setFile: (file: {data: string; type: string; name: string} | null) => void;
  isLoadingPreview: boolean;
};

const DocumentPreview = ({
  setIsPreviewDocument,
  file,
  setFile,
}: DocumentPreviewProps) => {
  const isLoadingPreview = useTypedSelector(
    (state) => state.StorageReducer.isLoadingDocument,
  );
  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.code !== "Escape") return;

    setIsPreviewDocument(false);
  };

  useEffect(() => {
    return () => {
      setFile(null);
    };
  }, [setFile]);
  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return function cleanup() {
      window.removeEventListener("keydown", handleKeyPress);
    };
  });

  return (
    <MainHead
      onClick={() => {
        setIsPreviewDocument(false);
      }}
    >
      <PreviewDocument
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Box sx={{display: "flex", justifyContent: "space-between"}}>
          <AddNewDocumentText className="addANewDocumentP">
            View document
          </AddNewDocumentText>
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontWeight: "600",
              fontSize: "20px",
              color: "#333333",
            }}
          >
            {file ? file.name : ""}
          </Typography>
          <Box
            sx={{
              cursor: "pointer",
              stroke: "#333333",
              width: "24px",
              height: "24px",
              maxWidth: "24px",
            }}
            onClick={() => {
              setIsPreviewDocument(false);
            }}
          >
            <StorageCloseIconSvg />
          </Box>
        </Box>
        <ImageWrapper>
          {file?.data && !isLoadingPreview ? (
            <ImageBlock>
              <DocumentFrameDisplay fileData={file?.data} type={file?.type} />
            </ImageBlock>
          ) : isLoadingPreview ? (
            <DeededCircularLoader />
          ) : (
            <></>
          )}
        </ImageWrapper>
      </PreviewDocument>
    </MainHead>
  );
};

const MainHead = styled(Box)({
  display: "flex",
  alignItems: "center",
  verticalAlign: "center",
  width: "100vw",
  height: "100vh",
  position: "fixed",
  top: "0",
  left: "0",
  background: "rgba(0, 0, 0, 0.4)",
  zIndex: "13",
});

const PreviewDocument = styled(Box)<{theme?: Theme}>(({theme}) => ({
  width: "90vw",
  height: "90vh",
  margin: "auto",
  padding: "40px",
  background: "#ffffff",
  boxShadow:
    "0px 12px 16px rgba(0, 0, 0, 0.07), 0px 12px 66px rgba(0, 0, 0, 0.18)",
  borderRadius: "5px",
  fontFamily: "Montserrat",
  fontWeight: "normal",
  fontSize: "12px",
  [theme.breakpoints.down("md")]: {
    width: "90vw",
    height: "60vh",
    "& .MuiBox-root .MuiTypography-root": {
      fontSize: "12px",
    },
  },
}));

const ImageWrapper = styled(Box)({
  width: "100%",
  height: "calc(100% - 30px)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "& object": {},
});

const ImageBlock = styled(Box)({
  width: "100%",
  height: "calc(100% - 30px)",
  marginTop: "30px",
  display: "flex",
  "& img": {
    objectFit: "cover",
    height: "100%",
    margin: "0 auto",
  },
});

const AddNewDocumentText = styled(Typography)({
  fontFamily: "Montserrat",
  fontWeight: "600",
  fontSize: "20px",
  color: "#333333",
});
export default DocumentPreview;
