import React, {useEffect, useState} from "react";
import moment from "moment";
import {useDispatch} from "react-redux";
import {useLocation} from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import {Box, styled} from "@mui/material";
import {getDealIdFromLink} from "utils/getDealIdFromLink";
import useTypedSelector from "utils/hooks/useTypedSelector";
import {
  getActivityLogsByIdTC,
  setActivityLogCurrentPageAC,
  setActivityLogDataAC,
} from "redux/reducers/activityLogReducer";
import constants from "styles/constants";
import DealDetailActivityLogListItemMobile from "components/Dashboard/ActivityLog/components/DealDetailActivityLogListItemMobile";
import DealDetailsActivityLogHeader from "components/Dashboard/ActivityLog/components/DealDetailsActivityLogHeader";
import DealDetailsActivityLogItem, {
    ActivityLogUserProps,
} from "components/Dashboard/ActivityLog/components/DealDetailsActivityLogItem";

const DealDetailsActivityLogList = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const dealId = getDealIdFromLink(location.pathname);
  const pagesAmount = useTypedSelector(
    (state) => state.ActivityLogReducer.pagesAmount,
  );
  const activityLogCurrentPage = useTypedSelector(
    (state) => state.ActivityLogReducer.activityLogCurrentPage,
  );
  const activityLogData = useTypedSelector(
    (state) => state.ActivityLogReducer.activityLogData,
  );
  const [count, setCount] = useState(pagesAmount);
  const [list, setList] = useState(activityLogData);
  useEffect(() => {
    if (activityLogData) {
      setList(activityLogData);
    }
    if (pagesAmount) {
      setCount(pagesAmount);
    }
  }, [activityLogData, pagesAmount]);

  useEffect(() => {
    return () => {
      dispatch(setActivityLogDataAC([]));
      dispatch(setActivityLogCurrentPageAC(1));
    };
  }, [dispatch]);
  useEffect(() => {
    dispatch(getActivityLogsByIdTC(dealId, 1));
  }, [dealId, dispatch]);

  const handlePageChange = (page: number) => {
    dispatch(setActivityLogCurrentPageAC(page));
    dispatch(getActivityLogsByIdTC(dealId, page));
  };
  return (
    <>
      <Box>
        <Box
          sx={(theme) => ({
            [theme.breakpoints.down("sm")]: {
              display: "none",
            },
          })}
        >
          <DealDetailsActivityLogHeader />

          {list &&
            list.map((item, idx) => {
              const {text, status} = item;
              const time = moment(item.updated_at, "M/D/YYYY hh:mm:ss").format(
                "h:mm A",
              );
              const date = moment(item.updated_at, "M/D/YYYY hh:mm:ss").format(
                "M/D/YYYY",
              );
              return (
                <Box key={idx}>
                  <DealDetailsActivityLogItem
                    documentName={text}
                    status={status}
                    date={date}
                    time={time}
                    user={item.user as unknown as ActivityLogUserProps}
                  />
                </Box>
              );
            })}
        </Box>

        <Box
          sx={(theme) => ({
            [theme.breakpoints.up("sm")]: {
              display: "none",
            },
          })}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "11px 15px 6px",
            }}
          >
            <LeftSide>User</LeftSide>
            <RightSide>Document</RightSide>
          </Box>
          <Box
            sx={(theme) => ({
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.06)",
              borderRadius: "5px",
              backgroundColor: constants.colors.white,
            })}
          >
            {list &&
              list.map((item, idx) => {
                const {text, status} = item;
                const time = moment(
                  item.updated_at,
                  "M/D/YYYY hh:mm:ss",
                ).format("h:mm A");
                const date = moment(
                  item.updated_at,
                  "M/D/YYYY hh:mm:ss",
                ).format("M/D/YYYY");
                return (
                  <DealDetailActivityLogListItemMobile
                    key={idx}
                    documentName={text}
                    status={status}
                    date={date}
                    time={time}
                    user={item.user as unknown as ActivityLogUserProps}
                  />
                );
              })}
          </Box>
        </Box>
        <Box
          sx={{display: "flex", justifyContent: "center", marginTop: "15px"}}
        >
          <Pagination
            onChange={(e, page) => {
              handlePageChange(page);
            }}
            page={activityLogCurrentPage}
            sx={styles.pagination}
            count={count}
            shape="rounded"
          />
        </Box>
      </Box>
    </>
  );
};
const LeftSide = styled(Box)({
  display: "flex",
  alignItems: "center",
  flex: "0 0 42%",
});
const RightSide = styled(Box)({
  flex: "0 0 58%",
  justifyContent: "flex-end",
});
const styles = {
  pagination: {
    "& li button": {
      backgroundColor: constants.colors.red,
      color: "white",
      fontSize: "12px",
      fontWeight: 500,
      fontFamily: "Montserrat",
    },
  },
};

export default DealDetailsActivityLogList;
