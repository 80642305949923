import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {styled} from "@mui/material/styles";
import {
  Collapse,
  IconButton,
  TableCell,
  TableRow,
  useTheme,
} from "@mui/material";
import {CommunicationTemplate} from "redux/reducers/types/configNotificationsReducerType";
import constants from "styles/constants";
import EditItemNewSvg from "components/Svg/EditItemNewSvg";
import TrashItemNewSvg from "components/Svg/TrashItemNewSvg";
import {
  deleteCommunicationsTemplateTC,
  updateCommunicationsToggleTC,
} from "redux/reducers/configReducer/configNotificationsReducer";
import useTypedSelector from "utils/hooks/useTypedSelector";
import DeededSpace from "v2/components/DeededSpace";
import DeededSwitcher from "v2/components/DeededSwitcher";
import ConfigNotificationsTemplateForm from "./ConfigNotificationsTemplateForm";

interface ConfigNotificationsTemplateItemRowProps {
  template: CommunicationTemplate;
}
const ConfigNotificationsTemplateItemRow: React.FC<
  ConfigNotificationsTemplateItemRowProps
> = ({template}) => {
  const dispatch = useDispatch();
  const selectedStage = useTypedSelector(
    (state) => state.ConfigReducer.ConfigNotificationsReducer.selectedStage,
  );
  const [showEditTemplateRow, setShowEditTemplateRow] = useState(false);
  const [isHoveredOnEditBtn, setIsHoveredOnEditBtn] = useState(false);
  const [isHoveredOnTrashBtn, setIsHoveredOnTrashBtn] = useState(false);
  const [active, setActive] = useState(!!template.active);
  const {template_name, recipient, bcc} = template;
  const handleNotificationsChecked = (e: {target: {checked: boolean}}) => {
    dispatch(
      updateCommunicationsToggleTC(template, setActive, e.target.checked),
    );
  };
  const handleNotificationsDelete = () => {
    dispatch(
      deleteCommunicationsTemplateTC(template.id, selectedStage as number),
    );
  };
  const theme = useTheme();

  return (
    <>
      <SummaryRow
        showEditTemplateRow={showEditTemplateRow}
        sx={{borderRadius: "50%"}}
      >
        <TemplateCell>{template_name}</TemplateCell>
        <TemplateCell>{recipient}</TemplateCell>
        <TemplateCell>{bcc}</TemplateCell>
        <TemplateCell>
          <DeededSwitcher
            activeSwitcher={active}
            handleChangeSwitcher={handleNotificationsChecked as () => void}
            sx={{}}
          />
        </TemplateCell>
        <TableCell align={"right"}>
          <EditBtn
            sx={{
              marginTop: showEditTemplateRow ? "-1px" : "",
            }}
            onClick={() => {
              setShowEditTemplateRow(!showEditTemplateRow);
            }}
            onMouseEnter={() => {
              setIsHoveredOnEditBtn(true);
            }}
            onMouseLeave={() => {
              setIsHoveredOnEditBtn(false);
            }}
          >
            <EditItemNewSvg
              color={
                showEditTemplateRow || isHoveredOnEditBtn
                  ? constants.colors.red
                  : "#BDBDBD"
              }
            />
          </EditBtn>
          <TrashBtn
            onClick={handleNotificationsDelete}
            sx={{
              marginTop: showEditTemplateRow ? "-1px" : "",
            }}
            onMouseEnter={() => {
              setIsHoveredOnTrashBtn(true);
            }}
            onMouseLeave={() => {
              setIsHoveredOnTrashBtn(false);
            }}
          >
            <TrashItemNewSvg
              color={isHoveredOnTrashBtn ? constants.colors.red : "#C4C4C4"}
            />
          </TrashBtn>
        </TableCell>
      </SummaryRow>
      <EditTemplateRow showEditTemplateRow={showEditTemplateRow}>
        <TableCell
          style={{paddingBottom: 0, paddingTop: 0}}
          colSpan={5}
          sx={{
            [theme.breakpoints.down("sm")]: {
              width: "calc(100vw - 53px)",
              display: "block",
              paddingBottom: "10px !important",
            },
          }}
        >
          <Collapse in={showEditTemplateRow} timeout="auto" unmountOnExit>
            <ConfigNotificationsTemplateForm
              handleClose={() => {
                setShowEditTemplateRow(false);
              }}
              template={template}
            />
          </Collapse>
        </TableCell>
      </EditTemplateRow>
      <DeededSpace
        y={8}
        sx={{
          [theme.breakpoints.down("sm")]: {
            display: "none",
          },
        }}
      />
    </>
  );
};

const TemplateCell = styled(TableCell)({
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "17px",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
  maxWidth: "150px",
});
const TrashBtn = styled(IconButton)({
  minWidth: "0px",
  padding: 0,
  marginRight: "-5px",
  borderRadius: "5px",
  "&:hover": {
    background: constants.colors.redVeryPale,
  },
});
const EditBtn = styled(IconButton)({
  minWidth: "0px",
  padding: 0,
  marginRight: "10px",
  borderRadius: "5px",
  "& .EditItemNewSvg": {
    marginTop: "-2px",
  },
  "&:hover": {
    background: constants.colors.redVeryPale,
  },
});
const itemBorder = `1px solid ${constants.colors.configNotificationsTableBorder}`;

const EditTemplateRow = styled(TableRow)<{showEditTemplateRow: boolean}>(
  ({showEditTemplateRow, theme}) => ({
    borderRadius: "5px",
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      left: "-143px",
      top: "20px",
    },
    "& .MuiTableCell-root": {
      borderBottom: showEditTemplateRow ? itemBorder : "none",
      borderLeft: showEditTemplateRow ? itemBorder : "none",
      borderRight: showEditTemplateRow ? itemBorder : "none",
      borderRadius: showEditTemplateRow ? "0px 0px 5px 5px" : "0px",
    },
  }),
);
const SummaryRow = styled(TableRow)<{showEditTemplateRow: boolean}>(
  ({showEditTemplateRow, theme}) => ({
    background: "white",
    borderRadius: "5px",
    borderLeft: itemBorder,
    borderRight: itemBorder,
    borderTop: itemBorder,
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "transparent",
      border: "none",
      borderRadius: 0,
      display: "flex",
      flexDirection: "column",
      "& td.MuiTableCell-root": {
        height: "33px",
        padding: "8px",
        display: "flex",
        alignItems: "center",
        border: "none",
      },
      "& :last-child.MuiTableCell-root": {
        height: "49px",
        position: "absolute",
        right: "23px",
        top: "107px",
      },
    },
    "& :last-child.MuiTableCell-root": {
      borderRight: itemBorder,
      borderRadius: showEditTemplateRow ? "5px 5px 0px 5px" : "5px 5px 5px 5px",
      [theme.breakpoints.down("sm")]: {
        border: "none",
        button: {
          marginRight: "10px",
        },
      },
    },
    "& :first-of-type.MuiTableCell-root": {
      borderLeft: itemBorder,
      borderRadius: showEditTemplateRow ? "5px 5px 0px 0px" : "5px 5px 5px 5px",
      [theme.breakpoints.down("sm")]: {
        border: "none",
      },
    },
    "& .MuiTableCell-root": {
      paddingTop: "0px",
      paddingBottom: "0px",
      height: "51px",
      borderTop: itemBorder,
      borderBottom: showEditTemplateRow ? "none" : itemBorder,
    },
  }),
);
export default ConfigNotificationsTemplateItemRow;
