import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { convertIntegrationDeal, listIntegrationDealsPending } from "api";
import { AlertTypes } from "types/alertTypes";
import { IntegrationDeal } from "types/integrations";
import configuration from "utils/configuration";

const useIntegrationDeals = () => {
    const { enqueueSnackbar } = useSnackbar();
    const [list, setList] = useState<IntegrationDeal[]>();
    const [loading, setLoading] = useState<boolean>(false);

    const updateDeal = async (integrationId: number, dealType: string) => {
        try {
            const dealTypeSlug = isNaN(Number(dealType)) ? dealType : "Mortgage";
            const tagId = isNaN(Number(dealType)) ? null : Number(dealType);

            const { data } = await convertIntegrationDeal(integrationId, {
                deal_type: dealTypeSlug,
                ...(tagId ? { deal_type_tag_id: tagId } : {}),
            });

            return data.deal_id;
        } catch (e) {
            console.error(e);
            enqueueSnackbar("There was an error creating the Deal", {
                variant: AlertTypes.Error,
                autoHideDuration: configuration.autoHideErrorDuration,
            });
            await refresh();
        }
    };

    const refresh = async () => {
        if (loading) return;

        try {
            setLoading(true);
            const { data } = await listIntegrationDealsPending();
            setList(data);
        } catch (e) {
            console.error(e);
            enqueueSnackbar("There was an error loading the data", {
                variant: AlertTypes.Error,
                autoHideDuration: configuration.autoHideErrorDuration,
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        void refresh();
    }, []);

    return { list, loading, refresh, updateDeal } as const;
};

export default useIntegrationDeals;
