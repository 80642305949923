import { FC, JSX, isValidElement } from "react";
import AccordionMui, { AccordionProps as AccordionPropsMui } from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails, { AccordionDetailsProps } from "@mui/material/AccordionDetails";
import { ArrowDownIcon } from "../Icons";
import Button from "../Button";
import { styled } from "@mui/material/styles";
import { H3 } from "../Typography";

type AccordionProps = {
    title: string | JSX.Element,
    detailsProps?: AccordionDetailsProps
} & Omit<AccordionPropsMui, 'title' | 'TransitionProps' | 'TransitionComponent'>;

const Accordion: FC<AccordionProps> = ({ title, children, detailsProps, ...props }) => {
    return <StyledAccordionMui disableGutters square={true} {...props}>
        <StyledAccordionSummary
            expandIcon={<Button typeOf="secondary" startIcon={<ArrowDownIcon />} />}
            sx={{
                padding: 0
            }}
        >
            {isValidElement(title) ? title : <H3>{title}</H3>}
        </StyledAccordionSummary>
        <AccordionDetails sx={{ padding: '2.4rem 0 0' }} {...detailsProps}>
            {children}
        </AccordionDetails>
    </StyledAccordionMui>
};

const StyledAccordionMui = styled(AccordionMui)(({ theme }) => ({
    borderRadius: '1.6rem',
    padding: '2.4rem',
    boxShadow: 'none',
    "& .MuiAccordionSummary-expandIconWrapper": {
        [theme.breakpoints.down('mobile')]: {
            alignSelf: 'flex-end'
        }
    },
    "&::before": {
        display: "none"
    }
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    padding: 0,
    "& .MuiAccordionSummary-content": {
        margin: 0
    },
    "&.Mui-expanded": {
        padding: 0,
        minHeight: 0,
        
    },
    "& .MuiAccordionSummary-expandIconWrapper": {
        [theme.breakpoints.down('mobile')]: {
            position: 'absolute',
            bottom: 0,
            right: 0
        }
    },
}));


export default Accordion;