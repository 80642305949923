import {BlockedEvent} from "types/CalendarComponent/blockedEventTypes";
import moment from "moment/moment";

export const handleBlockedEventSelected = (
  e: BlockedEvent,
  handleChange: (object: unknown) => void,
  version: number = 1,
) => {
  if (version === 1) {
    changingFunctionV1(e, handleChange);
  } else if (version === 2) {
    changingFunctionV2(e, handleChange);
  }
};
const changingFunctionV1 = (
  e: BlockedEvent,
  handleChange: (object: unknown) => void,
) => {
  simpledHandleChange(handleChange, "closing_id", e.id);
  simpledHandleChange(
    handleChange,
    "dateStart",
    moment(e.start_time_calendar).format("MM/DD/YYYY"),
  );
  simpledHandleChange(
    handleChange,
    "timeStart",
    new Date(e.start_time_calendar),
  );
  simpledHandleChange(
    handleChange,
    "dateEnd",
    moment(e.end_time_calendar).format("MM/DD/YYYY"),
  );
  simpledHandleChange(handleChange, "timeEnd", new Date(e.end_time_calendar));
  simpledHandleChange(handleChange, "province", e.state);
  simpledHandleChange(handleChange, "isAllDay", e.is_full_day);
  simpledHandleChange(handleChange, "isShowMobileSigners", e.for_user);
  simpledHandleChange(handleChange, "mobileSignerId", e.user_id);
  timeRecurringSettings(e, handleChange);
};
const changingFunctionV2 = (
  e: BlockedEvent,
  handleChange: (object: unknown) => void,
) => {
  simpledHandleChange(handleChange, "blackAllDay", e.is_full_day);
  simpledHandleChange(handleChange, "holiday", !!e.holiday);
  simpledHandleChange(handleChange, "holidays", e.holiday);
  simpledHandleChange(handleChange, "closing_id", e.id);
  simpledHandleChange(
    handleChange,
    "dateStart",
    moment(e.start_time_calendar).format("MM/DD/YYYY"),
  );
  simpledHandleChange(
    handleChange,
    "startTime",
    new Date(e.start_time_calendar),
  );
  simpledHandleChange(
    handleChange,
    "dateEnd",
    moment(e.end_time_calendar).format("MM/DD/YYYY"),
  );
  simpledHandleChange(handleChange, "endTime", new Date(e.end_time_calendar));
  simpledHandleChange(handleChange, "region", e.state);
  if (e.userOff && e.user_id && !!e.for_user) {
    simpledHandleChange(
      handleChange,
      "isShowedBlockForUserFields",
      !!e.for_user,
    );
    simpledHandleChange(handleChange, "role", e.userOff.role);
    simpledHandleChange(handleChange, "forSelectedUser", e.user_id);
  }
  timeRecurringSettings(e, handleChange);
};
const timeRecurringSettings = (
  e: BlockedEvent,
  handleChange: (object: unknown) => void,
) => {
  simpledHandleChange(handleChange, "isRepeat", e.is_recurring);
  simpledHandleChange(
    handleChange,
    "repeat",
    e.recurring_pattern?.recurring_type,
  );
  simpledHandleChange(
    handleChange,
    "repeatEveryDays",
    e.recurring_pattern?.interval,
  );
  simpledHandleChange(
    handleChange,
    "repeatEveryWeeks",
    e.recurring_pattern?.interval,
  );
  simpledHandleChange(
    handleChange,
    "repeatEveryMonths",
    e.recurring_pattern?.interval,
  );
  simpledHandleChange(
    handleChange,
    "repeatEveryYears",
    e.recurring_pattern?.interval,
  );
  simpledHandleChange(
    handleChange,
    "selectedDaysOfWeek",
    e.recurring_pattern?.repeat_by_days,
  );
  simpledHandleChange(
    handleChange,
    "selectedDaysOfMonth",
    e.recurring_pattern?.repeat_by_month_days,
  );
  simpledHandleChange(
    handleChange,
    "selectedMothsOfYear",
    e.recurring_pattern?.repeat_by_months,
  );
};
const simpledHandleChange = (
  handleChange: (object: unknown) => void,
  name: string,
  value: unknown,
) => {
  if (value) {
    handleChange({
      target: {
        name,
        value,
      },
    });
  }
};
