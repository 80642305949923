import React from "react";
import {BlockedEvent} from "../../../../types/CalendarComponent/blockedEventTypes";

export const CalendarMobileContext = React.createContext({
  setTableType: (param: string) => {},
  tableType: "",
  date: new Date(),
  regions: [],
  isGettingEvents: false,
  view: "",
  dealTypeState: "",
  setSigningsTotal: (param: number) => {},
  isAllProvinciesChecked: false,
  staffState: "",
  closingsList: [],
  setDate: (date: Date) => {},
  setView: (view: string) => {},
  setRegions: (regions: string[] | string) => {},
  setDealTypeState: (dealTypeState: string) => {},
  totalNumberOf: 0,
  handleChange: (param: unknown) => {},
  setShowDealPreview: (showDealPreview: boolean) => {},
  setShowModal: (showModal: boolean) => {},
  setSelectedBlockedEvent: (selectedBlockedEvent: BlockedEvent | null) => {},
  setCurrentPageForCreateEventPopup: (
    currentPageForCreateEventPopup: number,
  ) => {},
  currentPageForCreateEventPopup:1,
  selectedBlockedEvent:{},
  getBlockedEffectFunction:()=>{},
  dateStart:new Date(),
  dateEnd:new Date(),
  getEventsEffectFunction:()=>{}
});
