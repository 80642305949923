import { Stack } from "@mui/material";
import { User } from "types/user";
import { ClientIntake, IntakeRequest } from "types/intakeForm";
import HeaderIntake from "./IntakeHeader";
import IntakeBody from "./IntakeBody";
import { Deal } from "types/deal";

interface ClientIntakeProps {
    intake: IntakeRequest | ClientIntake,
    handleIntakeReview: (reviewed: boolean) => Promise<any>,
    user: User,
    deal: Deal,
}

const Intake = ({ intake, handleIntakeReview, user, deal }: ClientIntakeProps) => {
    return <Stack>
        <HeaderIntake intake={intake} handleIntakeReview={handleIntakeReview} user={user} />
        <IntakeBody intake={intake} deal={deal} user={user} />
    </Stack>;
}

export default Intake; 