import {dateFormat} from "../../ClientIntakeEditCard/components/dateFormat";
import {VerticalSpacer} from "../../ClientIntakeEditCard/components";
import {baseURL} from "../../../../api";
import axios from "axios";
import {getDocumentCardData, DocumentTypeNames} from "./document-accessors";
import moment from "moment";
import { PropertyTypes } from "types/intakeForm";
// TODO (Oleg): Move all axios calls into separate file

/**
 *
 * @typedef {Object} AddressStruct
 * @property {string} address
 * @property {string} city
 * @property {string} code
 * @property {string} created_at
 * @property {number} id
 * @property {string} state
 * @property {string} [unit]
 * @property {string} updated_at
 */

/** @type {AddressStruct} */
const blankAddressStruct = {
  address: "",
  city: "",
  code: "",
  created_at: "",
  id: -1,
  state: "",
  unit: "",
  updated_at: "",
};

// Statuses for cards
export const CARD_STATUS_APPROVED = "approved";
export const CARD_STATUS_PENDING = "pending";
export const CARD_STATUS_MISSED = "missed";
export const CARD_STATUS_REJECTED = "rejected";

/**
 *
 * @param {number} dealId
 * @param {string} documentType See DocumentTypes enum
 */
async function requestDocument(dealId, documentType) {
  const url = `${baseURL}/deals/${dealId}`;
  const {data} = await axios.get(url, {});
  return {data: null};
}

/**
 *
 * @param {*} client
 * @param {string} client
 */
async function getDocument(client, documentType) {
  const dealId = client.deal_id;
  try {
    const doc = await requestDocument(dealId, documentType);
    if (!doc.data) {
      return {
        status: CARD_STATUS_MISSED,
        content: doc.data,
      };
    }
  } catch (err) {
    console.error(err);
    return {status: CARD_STATUS_MISSED};
  }
}

/**
 * Used for a fields with a lowercase letter
 * @param {string} str
 */
function toTitleCase(str) {
  if (str === null) return "";
  return str[0].toUpperCase() + str.substring(1);
}

/**
 * User data could be stored either in owners or purchasers or sellers
 * @param {Object} client API data struct
 * @param {number} i user id
 */
function findUser(client, i) {
  if ("owners" in client) return client.owners[i];
  if ("lenders" in client) return client.lenders[i];
  if ("purchasers" in client) return client.purchasers[i];
  return client.sellers[i];
}

/**
 * Since there are many address fields, make them appear in an unified way
 * @param {AddressStruct} address
 */
function createAddressString(address) {
  if (!address) {
    return {status: CARD_STATUS_MISSED};
  }
  return `${address.address}; ${address.city}; ${address.state}; ${address.code}`;
}

// Extract these fields into birth date
const dateBirthDetails = {
  accessor: (client, i) => {
    let birthDay = findUser(client, i).birth_day;
    // pre-format birth date
    // It seems that Date.parse requires the date format in YYYY-MM-DD
    if (birthDay.split(".").length > 1) {
      birthDay = birthDay
        .trim()
        .split(".")
        .reverse()
        .reduce((a, b) => a + "-" + b);
    }
    const date = Date.parse(birthDay);
    birthDay = dateFormat(date, "mm / dd / yyyy");
    return birthDay;
  },
  editDataAccessor: (client, i) => findUser(client, i).birth_day,
  payload: (value) => ({birth_day: value}),
};

const spousalStatusData = {
  accessor: (client, i) => {
    return `${toTitleCase(findUser(client, i).spousalStatus)} ${
      i.spouseName !== null && toTitleCase(findUser(client, i).spouseName)
    }`;
  },
  editDataAccessor: (client, i) => ({
    spousalStatus: findUser(client, i).spousalStatus,
    spouseName: findUser(client, i).spouseName,
  }),
  payload: (value) => value,
};

const sinData = {
  accessor: (client, i) => {
    return `${findUser(client, i).sin}` ;
  },
  editDataAccessor: (client, i) => findUser(client, i).sin,
  payload: (value) => ({sin: value}),
};

const tankFuelWellData = {
  accessor: (client) => {
    return () => (<>
      <p>Septic Tank: <b>{`${client.septic_tank === 1 ? "Yes" : "No"}`}</b></p>
      <p>Water Well: <b>{`${client.water_well === 1 ? "Yes" : "No"}`}</b></p>
      <p>Fuel/Propane: <b>{`${client.fuel_tank === 1 ? "Yes" : "No"}`}</b></p>
      {client.fuel_tank === 1 && (
        <>
          <p>Fuel Provider Name: <b>{client.fuel_provider_name}</b></p>
          <p>Fuel Provider Number: <b>{client.fuel_provider_phone}</b></p>
        </>
      )}
    </>);
  },
  editDataAccessor: (client, i) => ({
    septicTank: client.septic_tank,
    waterWell: client.water_well,
    fuelTank: client.fuel_tank,
    fuelProviderName: client.fuel_provider_name,
    fuelProviderPhone: client.fuel_provider_phone,
  }),
  payload: (value) => ({
    septic_tank: value.septicTank,
    water_well: value.waterWell,
    fuel_tank: value.fuelTank,
    fuel_provider_name: value.fuelProviderName,
    fuel_provider_phone: value.fuleProviderPhone,
  }),
}

const citizenshipStatusData = {
  accessor: (client, i) => {
    const person = findUser(client, i);
    return () => {
      return (
        <>
          <p>
            <span>Canadian Citizen: </span>
            <b>{person.canadian_citizen === 1 ? "Yes" : "No"}</b>
          </p>
          {person.other_citizenship && (
            <p>
              <span>Citizen of Another Country: </span>
              <b>{person.other_citizenship}</b>
            </p>
          )}
        </>
      );
    };
  },

  editDataAccessor: (client, i) => {
    const person = findUser(client, i);
    return {
      permanentResidentOfCanada: person.permanent_resident_of_canada,
      canadianCitizen: person.canadian_citizen,
      otherCitizenShip: person.other_citizenship,
    };
  },

  payload: (value) => ({
    permanent_resident_of_canada: value.permanentResidentOfCanada,
    canadian_citizen: value.canadianCitizen,
    other_citizenship: value.otherCitizenShip,
  }),
};

const signingAppointmentData = {
  accessor: (client, province) => {
    // if (!client.preferred_time) {
    //   return { status: CARD_STATUS_MISSED };
    // }
    if (client.preferred_time === null) {
      return () => {
        const hasClientComment = client.client_comment !== null;

        return (
          <div>
            <span
              style={{
                position: "absolute",
                top: 0,
                left: "-6px",
                height: "100%",
                width: "6px",
                background: "#f16764",
              }}
            ></span>
            {hasClientComment && <p>Reschedule requested </p>}
            <VerticalSpacer height={12} />
            <p style={{color: "red"}}>
              {hasClientComment ? client.client_comment : ""}
            </p>
          </div>
        );
      };
    }
    return () => {
      return (
        <>
          <p>
            {moment(client.iso_datetime, "YYYY-MM-DD HH:mm:ss").format(
              "MM/DD/YYYY hh:mm A",
            )}{" "}
            ({province})
          </p>
          <VerticalSpacer height={12} />
          <p style={{color: "red"}}>
            {client.client_comment === null ? "" : client.client_comment}
          </p>
        </>
      );
    };
  },
  editDataAccessor: (client, i) => ({
    preferredTime: client.iso_datetime,
    internet: +client.internet,
    province: client.client.current_address.state,
  }),
  payload: (value) => ({
    preferred_time: moment(new Date(value.preferredTime)).format(
      "YYYY-MM-DD HH:mm:ss",
    ),
    internet: +value.internet,
    preferred_province: value.province,
  }),
};

const employmentStatusData = {
  accessor: (client, i) => {
    return `${toTitleCase(findUser(client, i).employment_status)} ${toTitleCase(
      findUser(client, i).occupation,
    )}`;
  },
  editDataAccessor: (client, i) => {
    return findUser(client, i).employment_status;
  },
  payload: (value) => ({
    employment_status: value,
  }),
};

function hideIfNotEmployed(client, index) {
  const user = findUser(client, index);
  if (!user.employment_status) {
    return true;
  }
  if (
    ["not employed", "retired"].includes(user.employment_status.toLowerCase())
  ) {
    return true;
  }
  return false;
}

function hideIfIsNotCondominium(client) {
  if (
    !client.type_property ||
    ![PropertyTypes.Condominium, PropertyTypes.CondoTownhouse].includes(client.type_property)
  ) {
    return true;
  }
  return false;
}


export const clientDataSale = {
  personal_details: [
    {
      title: "01. Number of Sellers",
      key: "clients_length",
      accessor: "sellers.length",
    },
    {
      info: "personal",
      title: "02. Full Name of Seller",
      accessor: (client, i) => {
        return `${client.sellers[i].full_name}`;
      },
      editDataAccessor: (client, i) => {
        const {name_prefix, first_name, last_name, middle_name} =
          client.sellers[i];
        return {
          namePrefix: name_prefix,
          firstName: first_name,
          lastName: last_name,
          middleName: middle_name,
        };
      },
      payload: (value) => ({
        name_prefix: value.namePrefix,
        first_name: value.firstName,
        last_name: value.lastName,
        middle_name: value.middleName,
      }),
    },
    {
      info: "personal",
      title: "03. Corporate Sale",
      accessor: (client, i) => {
        return client.sellers[i].is_corporate === 1 ? "Yes" : "No";
      },
      payload: (value) => ({is_corporate: value === "Yes" ? 1 : 0}),
    },
    {
      info: "personal",
      title: "04. Reside at the Property",
      accessor: (client, i) => {
        return client.sellers[i].isReside === 1 ? "Yes" : "No";
      },
      payload: (value) => ({isReside: value === "Yes" ? 1 : 0}),
    },
    {
      info: "personal",
      title: "05. Resident of Canada",
      accessor: (client, i) => {
        return client.sellers[i].isResidentCanada === 1 ? "Yes" : "No";
      },
      payload: (value) => ({isResidentCanada: value === "Yes" ? 1 : 0}),
    },
    {
      info: "personal",
      title: "06. Date of Birth",
      ...dateBirthDetails,
    },
    {
      info: "personal",
      title: "07. Forwarding Address ",
      accessor: (client, i) => {
        return createAddressString(client.sellers[i].forwarding_address);
      },
      editDataAccessor: (client, i) => {
        return client.sellers[i].forwarding_address || blankAddressStruct;
      },
      payload: (value) => ({
        forwarding_address: {
          address: value.address,
          city: value.city,
          state: value.state,
          code: value.code,
          unit: value.unit,
        },
      }),
    },
    {
      info: "personal",
      title: "08. Phone Number",
      accessor: (client, i) => {
        return `${client.sellers[i].phone}`;
      },
      payload: (value) => ({
        phone: value,
      }),
    },
    {
      info: "personal",
      title: "09. Employment Status",
      ...employmentStatusData,
    },
    {
      info: "personal",
      title: "10. Name of Employer",
      accessor: (client, i) => {
        return client.sellers[i].employer_name || {status: CARD_STATUS_MISSED};
      },
      hideIf: hideIfNotEmployed,
      editDataAccessor: (client, i) => {
        return client.sellers[i].employer_name || {};
      },
      payload: (value) => ({
        employer_name: value,
      }),
    },
    {
      info: "personal",
      title: "11. Employer Address",
      accessor: (client, i) =>
        createAddressString(client.sellers[i].employer_address),
      hideIf: hideIfNotEmployed,
      editDataAccessor: (client, i) => {
        return client.sellers[i].employer_address || blankAddressStruct;
      },
      payload: (value) => ({
        employer_address: {
          address: value.address,
          city: value.city,
          state: value.state,
          code: value.code,
          unit: value.unit,
        },
      }),
    },
    {
      info: "personal",
      title: "12. Spousal Status",
      ...spousalStatusData,
    },
    {
      info: "general",
      title: "13. Signing Appointment",
      ...signingAppointmentData,
    },
    {
      info: "personal",
      title: "14. Email",
      accessor: (client, i) => {
        return client.sellers[i].email;
      },
      payload: (value) => ({email: value}),
    },
    {
      info: "personal",
      title: "15. Social Insurance Number",
      ...sinData,
    },
  ],
  property_details: [
    {
      info: "general",
      title: "01. Type of Property",
      accessor: (client) => {
        return client.type_property;
      },
      payload: (type_property) => {
        const { Condominium, CondoTownhouse } = PropertyTypes;
        if (![Condominium, CondoTownhouse].includes(type_property))
          return {
            type_property,
            selling_condominium_company: null,
            selling_condominium_fees: null,
          }
        return { type_property }
      },
    },
    {
      info: "general",
      title: "02. Selling a Condominium",
      accessor: (client) => {
        return `${client.selling_condominium_company}, ${client.selling_condominium_fees}`;
      },
      hideIf: hideIfIsNotCondominium,
      editDataAccessor: (client, i) => {
        return {
          company: client.selling_condominium_company,
          fees: client.selling_condominium_fees,
        };
      },
      payload: (value) => ({
        selling_condominium_company: value.company,
        selling_condominium_fees: value.fees,
      }),
    },
    {
      info: "general",
      title: "03. Rental Property",
      accessor: (client) => {
        return client.is_rental_property === 1 ? "Yes" : "No";
      },
      payload: (value) => ({is_rental_property: value === "Yes" ? 1 : 0}),
    },
    {
      info: "general",
      title: "04. Septic, Fuel & Water Well",
      nonmutable: true,
      ...tankFuelWellData
    },
  ],
  mortgage_details: [
    {
      info: "general",
      title: "01. Current Mortgage Lender",
      accessor: (client) => {
        if (
          !client.current_mortgage_lender_name ||
          !client.current_mortgage_lender_account
        ) {
          return {status: CARD_STATUS_MISSED};
        }
        return () => (
          <>
            <p>
              Name: <b>{client.current_mortgage_lender_name}</b>
            </p>
            <p>
              Account: <b>{client.current_mortgage_lender_account}</b>
            </p>
          </>
        );
      },
      editDataAccessor: (client) => {
        return {
          name: client.current_mortgage_lender_name,
          account: client.current_mortgage_lender_account,
        };
      },
      payload: (value) => ({
        current_mortgage_lender_name: value.name,
        current_mortgage_lender_account: value.account,
      }),
    },
    {
      info: "general",
      title: "02. Branch Address of Lender",
      accessor: (client, i) => createAddressString(client.branch_address),
      editDataAccessor: (client) => {
        return client.branch_address || blankAddressStruct;
      },
      payload: (value) => ({
        branch_address_lender: {
          address: value.address,
          city: value.city,
          state: value.state,
          code: value.code,
          unit: value.unit,
        },
      }),
    },
  ],
  other_items: [
    {
      info: "general",
      title: "01. Rental Contracts",
      accessor: (client) => (client.rental_contracts === 1 ? "Yes" : "No"),
      payload: (value) => ({
        rental_contracts: value === "Yes" ? 1 : 0,
      }),
    },
    {
      info: "general",
      title: "02. Rental Agreement",
      hideIf: (client) => {
        return !client.rental_contracts;
      },
      ...getDocumentCardData(DocumentTypeNames.RENTAL_AGREEMENT),
    },
    {
      info: "general",
      title: "03. Recent Property Tax Bill",
      ...getDocumentCardData(DocumentTypeNames.PROPERTY_TAX_BILL_STATEMENT),
    },
    {
      info: "general",
      title: "04. Void Cheque",
      ...getDocumentCardData(DocumentTypeNames.VOID_CHEQUE),
    },
    {
      info: "general",
      title: "05. Additional Information",
      nonmutable: true,
      accessor: (client) => {
        return `${client.additional_information}`;
      },
    },
  ],
};
export const clientDataMortgage = {
  personal_details: [
    {
      info: "general",
      title: "01. Number of Owners",
      key: "clients_length",
      accessor: "owners.length",
    },
    {
      info: "personal",
      title: "02. Full Name of Owner",
      accessor: (client, i) => {
        return `${client.owners[i].first_name} ${client.owners[i].middle_name !== 'null' ? client.owners[i].middle_name : '' } ${client.owners[i].last_name}`;
      },
      editDataAccessor: (client, i) => {
        const {name_prefix, first_name, last_name, middle_name} =
          client.owners[i];
        return {
          namePrefix: name_prefix,
          firstName: first_name,
          lastName: last_name,
          middleName: middle_name,
        };
      },
      payload: (value) => ({
        name_prefix: value.namePrefix,
        first_name: value.firstName,
        last_name: value.lastName,
        middle_name: value.middleName,
      }),
    },
    {
      info: "personal",
      title: "03. Corporate Refinance",
      accessor: (client, i) => {
        return client.owners[i].is_corporate === 1 ? "Yes" : "No";
      },
      payload: (value) => ({is_corporate: value === "Yes" ? 1 : 0}),
    },
    {
      info: "personal",
      title: "03. Date of Birth",
      ...dateBirthDetails,
    },
    {
      info: "personal",
      title: "04. Current Address",
      accessor: (client, i) =>
        createAddressString(client.owners[i].current_address),
      editDataAccessor: (client, i) => {
        return client.owners[i].current_address || blankAddressStruct;
      },
      payload: (value) => ({
        current_address: {
          address: value.address,
          city: value.city,
          state: value.state,
          code: value.code,
          unit: value.unit,
        },
      }),
    },
    {
      info: "personal",
      title: "05. Phone Number",
      accessor: (client, i) => {
        return `${client.owners[i].phone}`;
      },
      payload: (value) => ({
        phone: value,
      }),
    },
    {
      info: "personal",
      title: "06. Employment Status",
      ...employmentStatusData,
    },
    {
      info: "personal",
      title: "07. Name of Employer",
      accessor: (client, i) => {
        return `${client.owners[i].employer_name}`;
      },
      hideIf: hideIfNotEmployed,
      payload: (value) => ({
        employer_name: value,
      }),
    },
    {
      info: "personal",
      title: "08. Employer Address",
      accessor: (client, i) =>
        createAddressString(client.owners[i].employer_address),
      hideIf: hideIfNotEmployed,
      editDataAccessor: (client, i) => {
        return client.owners[i].employer_address || blankAddressStruct;
      },
      payload: (value) => ({
        employer_address: {
          address: value.address,
          city: value.city,
          state: value.state,
          code: value.code,
          unit: value.unit,
        },
      }),
    },

    {
      info: "personal",
      title: "10. Spousal Status",
      ...spousalStatusData,
    },
    {
      info: "personal",
      title: "10. Citizenship Status",
      ...citizenshipStatusData,
    },

    {
      info: "general",
      title: "13. Signing Appointment",
      ...signingAppointmentData,
    },
    {
      info: "personal",
      title: "14. Email",
      accessor: (client, i) => {
        return client.owners[i].email;
      },
      payload: (value) => ({email: value}),
    },
    {
      info: "personal",
      title: "15. Social Insurance Number",
      ...sinData,
    },
  ],
  property_details: [
    {
      info: "general",
      title: "01. Type of Property",
      accessor: (client) => {
        return client.type_property;
      },
      payload: (type_property) => ({
        type_property,
      }),
    },
    
    {
      info: "general",
      title: "02. Condominium",
      accessor: (client) => {
        if (!client.condominium_company && !client.condominium_fee) {
          return {status: CARD_STATUS_MISSED};
        }
        return () => (
          <>
            {client.condominium_company && (
              <p>
                Property Management Company: <b>{client.condominium_company}</b>
              </p>
            )}
            {client.condominium_fee && (
              <p>
                Monthly Condo Fee Amount: <b>{client.condominium_fee}</b>
              </p>
            )}
          </>
        );
      },
      hideIf: hideIfIsNotCondominium,
      editDataAccessor: (client, i) => {
        return {
          company: client.condominium_company,
          fees: client.condominium_fee,
        };
      },
      payload: (value) => ({
        condominium_company: value.company,
        condominium_fee: value.fees,
      }),
    },
    {
      info: "personal",
      title: "03. Ownership of Property",
      accessor: (client) => {
        return client.client.is_reside === 1 ? "Yes" : "No";
      },
      payload: (value) => ({is_reside: value === "Yes" ? 1 : 0}),
    },
    {
      info: "general",
      title: "04. Septic, Fuel & Water Well",
      nonmutable: true,
      ...tankFuelWellData
    },
  ],
  mortgage_details: [
    {
      info: "general",
      title: "01. New Mortgage",
      accessor: (client) => {
        if (
          !client.lender_name &&
          !client.branch_address &&
          !client.contact_name &&
          !client.contact_phone &&
          !client.contact_email
        ) {
          return {status: CARD_STATUS_MISSED};
        }
        return () => (
          <>
            {client.lender_name && (
              <p>
                Lender Name: <b>{client.lender_name}</b>
              </p>
            )}
            {client.branch_address && (
              <p>
                Branch Address: <b>{client.branch_address}</b>
              </p>
            )}
            {client.contact_name && (
              <p>
                Contact Name at Lender or Mortgage Broker:
                <b>{client.contact_name}</b>
              </p>
            )}
            {client.contact_phone && (
              <p>
                Phone: <b>{client.contact_phone}</b>
              </p>
            )}
            {client.contact_email && (
              <p>
                Email: <b>{client.contact_email}</b>
              </p>
            )}
          </>
        );
      },
      editDataAccessor: (client, i) => {
        return {
          name: client.lender_name,
          branchAddress: client.branch_address,
          contactName: client.contact_name,
          contactPhone: client.contact_phone,
          contactEmail: client.contact_email,
        };
      },
      payload: (value) => ({
        lender_name: value.name,
        branch_address: value.branchAddress,
        contact_name: value.contactName,
        contact_phone: value.contactPhone,
        contact_email: value.contactEmail,
      }),
    },
    {
      info: "general",
      title: "02. Scheduled Closing Date",
      accessor: (client) => {
        return dateFormat(
          Date.parse(client.closing_date),
          "mm / dd / yyyy, hh:MM TT",
        );
      },
      payload: (closing_date) => ({closing_date}),
    },
  ],
  other_items: [
    // {
    //   info: "general",
    //   title: "01. Home Insurance Policy",
    //   ...getDocumentCardData(DocumentTypeNames.HOME_INSURANCE),
    // },
    {
      info: "general",
      title: "01. Additional Information",
      nonmutable: true,
      accessor: (client) => {
        return client.additional_information
          ? `${client.additional_information}`
          : "Empty";
      },
      editDataAccessor: (client, i) => {
        return client.additional_information
          ? `${client.additional_information}`
          : "";
      },
      payload: (value) => ({
        additional_information: value,
      }),
    },
    {
      info: "general",
      title: "02. Source of Funds",
      nonmutable: true,
      accessor: (client) => {
        return () => {
          return (
            <>
              <p>Savings - {`${client.savings ? "Yes" : "No"}`}</p>
              <p>Gift - {`${client.gift ? "Yes" : "No"}`}</p>
              <p>Sale proceeds - {`${client.sale_proceeds ? "Yes" : "No"}`}</p>
              <p>
                Mortgage proceeds -{" "}
                {`${client.mortgage_proceeds ? "Yes" : "No"}`}
              </p>
              <p>Other - {client.other}</p>
            </>
          );
        };
      },
    },
    {
      info: "general",
      title: "03. Recent Property Tax Bill",
      ...getDocumentCardData(DocumentTypeNames.PROPERTY_TAX_BILL_STATEMENT),
    },
    {
      info: "general",
      title: "04. Void Cheque",
      ...getDocumentCardData(DocumentTypeNames.VOID_CHEQUE),
    },
  ],
};
export const clientDataLender = {
  personal_details: [
    {
      info: "general",
      title: "01. Number of Lenders",
      key: "clients_length",
      accessor: "lenders.length",
    },
    {
      info: "personal",
      title: "02. Full Name of Lender",
      accessor: (client, i) => {
        return `${client.lenders[i].full_name}`;
      },
      editDataAccessor: (client, i) => {
        const {name_prefix, first_name, last_name, middle_name} =
          client.lenders[i];
        return {
          namePrefix: name_prefix,
          firstName: first_name,
          lastName: last_name,
          middleName: middle_name,
        };
      },
      payload: (value) => ({
        name_prefix: value.namePrefix,
        first_name: value.firstName,
        last_name: value.lastName,
        middle_name: value.middleName,
      }),
    },
    {
      info: "personal",
      title: "03. Corporate Refinance",
      accessor: (client, i) => {
        return client.lenders[i].is_corporate === 1 ? "Yes" : "No";
      },
      payload: (value) => ({is_corporate: value === "Yes" ? 1 : 0}),
    },
    {
      info: "personal",
      title: "03. Date of Birth",
      ...dateBirthDetails,
    },
    {
      info: "personal",
      title: "04. Current Address",
      accessor: (client, i) =>
        createAddressString(client.lenders[i].current_address),
      editDataAccessor: (client, i) => {
        return client.lenders[i].current_address || blankAddressStruct;
      },
      payload: (value) => ({
        current_address: {
          address: value.address,
          city: value.city,
          state: value.state,
          code: value.code,
          unit: value.unit,
        },
      }),
    },
    {
      info: "personal",
      title: "05. Phone Number",
      accessor: (client, i) => {
        return `${client.lenders[i].phone}`;
      },
      payload: (value) => ({
        phone: value,
      }),
    },
    {
      info: "personal",
      title: "06. Employment Status",
      ...employmentStatusData,
    },
    {
      info: "personal",
      title: "07. Name of Employer",
      accessor: (client, i) => {
        return `${client.lenders[i].employer_name}`;
      },
      hideIf: hideIfNotEmployed,
      payload: (value) => ({
        employer_name: value,
      }),
    },
    {
      info: "personal",
      title: "08. Employer Address",
      accessor: (client, i) =>
        createAddressString(client.lenders[i].employer_address),
      hideIf: hideIfNotEmployed,
      editDataAccessor: (client, i) => {
        return client.lenders[i].employer_address || blankAddressStruct;
      },
      payload: (value) => ({
        employer_address: {
          address: value.address,
          city: value.city,
          state: value.state,
          code: value.code,
          unit: value.unit,
        },
      }),
    },
    {
      info: "personal",
      title: "10. Spousal Status",
      ...spousalStatusData,
    },
    {
      info: "personal",
      title: "10. Citizenship Status",
      ...citizenshipStatusData,
    },

    {
      info: "general",
      title: "13. Signing Appointment",
      ...signingAppointmentData,
    },
    {
      info: "personal",
      title: "14. Email",
      accessor: (client, i) => {
        return client.lenders[i].email;
      },
      payload: (value) => ({email: value}),
    },
    {
      info: "personal",
      title: "15. Social Insurance Number",
      ...sinData,
    },
  ],
  property_details: [
    {
      info: "personal",
      title: "01. Ownership of Property",
      accessor: (client) => {
        return client.client.is_reside === 1 ? "Yes" : "No";
      },
      payload: (value) => ({is_reside: value === "Yes" ? 1 : 0}),
    },

    {
      info: "general",
      title: "02. Condominium",
      accessor: (client) => {
        if (!client.condominium_company && !client.condominium_fee) {
          return {status: CARD_STATUS_MISSED};
        }
        return () => (
          <>
            {client.condominium_company && (
              <p>
                Property Management Company: <b>{client.condominium_company}</b>
              </p>
            )}
            {client.condominium_fee && (
              <p>
                Monthly Condo Fee Amount: <b>{client.condominium_fee}</b>
              </p>
            )}
          </>
        );
      },
      editDataAccessor: (client, i) => {
        return {
          company: client.condominium_company,
          fees: client.condominium_fee,
        };
      },
      payload: (value) => ({
        condominium_company: value.company,
        condominium_fee: value.fees,
      }),
    },
  ],
  mortgage_details: [
    {
      info: "general",
      title: "01. New Mortgage",
      accessor: (client) => {
        if (
          !client.lender_name &&
          !client.branch_address &&
          !client.contact_name &&
          !client.contact_phone &&
          !client.contact_email
        ) {
          return {status: CARD_STATUS_MISSED};
        }
        return () => (
          <>
            {client.lender_name && (
              <p>
                Lender Name: <b>{client.lender_name}</b>
              </p>
            )}
            {client.branch_address && (
              <p>
                Branch Address: <b>{client.branch_address}</b>
              </p>
            )}
            {client.contact_name && (
              <p>
                Contact Name at Lender or Mortgage Broker:
                <b>{client.contact_name}</b>
              </p>
            )}
            {client.contact_phone && (
              <p>
                Phone: <b>{client.contact_phone}</b>
              </p>
            )}
            {client.contact_email && (
              <p>
                Email: <b>{client.contact_email}</b>
              </p>
            )}
          </>
        );
      },
      editDataAccessor: (client, i) => {
        return {
          name: client.lender_name,
          branchAddress: client.branch_address,
          contactName: client.contact_name,
          contactPhone: client.contact_phone,
          contactEmail: client.contact_email,
        };
      },
      payload: (value) => ({
        lender_name: value.name,
        branch_address: value.branchAddress,
        contact_name: value.contactName,
        contact_phone: value.contactPhone,
        contact_email: value.contactEmail,
      }),
    },
    {
      info: "general",
      title: "02. Scheduled Closing Date",
      accessor: (client) => {
        return dateFormat(
          Date.parse(client.closing_date),
          "mm / dd / yyyy, hh:MM TT",
        );
      },
      payload: (closing_date) => ({closing_date}),
    },
  ],
  other_items: [
    // {
    //   info: "general",
    //   title: "01. Home Insurance Policy",
    //   ...getDocumentCardData(DocumentTypeNames.HOME_INSURANCE),
    // },
    {
      info: "general",
      title: "01. Additional Information",
      nonmutable: true,
      accessor: (client) => {
        return client.additional_information
          ? `${client.additional_information}`
          : "Empty";
      },
      editDataAccessor: (client, i) => {
        return client.additional_information
          ? `${client.additional_information}`
          : "";
      },
      payload: (value) => ({
        additional_information: value,
      }),
    },
    {
      info: "general",
      title: "02. Source of Funds",
      nonmutable: true,
      accessor: (client) => {
        return () => {
          return (
            <>
              <p>Savings - {`${client.savings ? "Yes" : "No"}`}</p>
              <p>Gift - {`${client.gift ? "Yes" : "No"}`}</p>
              <p>Sale proceeds - {`${client.sale_proceeds ? "Yes" : "No"}`}</p>
              <p>
                Mortgage proceeds -{" "}
                {`${client.mortgage_proceeds ? "Yes" : "No"}`}
              </p>
              <p>Other - {client.other}</p>
            </>
          );
        };
      },
    },
  ],
};

export const clientDataPurchase = {
  personal_details: [
    {
      title: "01. Number of Purchasers",
      accessor: "purchasers.length",
      key: "clients_length",
      info: "general",
    },
    {
      info: "personal",
      title: "02. Full Name of Purchaser",
      accessor: (client, i) => {
        return `${client.purchasers[i].full_name}`;
      },
      editDataAccessor: (client, i) => {
        const {name_prefix, first_name, last_name, middle_name} =
          client.purchasers[i];
        return {
          namePrefix: name_prefix,
          firstName: first_name,
          lastName: last_name,
          middleName: middle_name,
        };
      },
      payload: (value) => ({
        name_prefix: value.namePrefix,
        first_name: value.firstName,
        last_name: value.lastName,
        middle_name: value.middleName,
      }),
    },
    {
      info: "personal",
      title: "03. Corporate Buyer",
      accessor: (client, i) => {
        return client.purchasers[i].is_corporate === 1 ? "Yes" : "No";
      },
      payload: (value) => ({is_corporate: value === "Yes" ? 1 : 0}),
    },
    {
      info: "personal",
      title: "04. Date of Birth",
      ...dateBirthDetails,
    },
    {
      info: "personal",
      title: "05. Current Address",
      accessor: (client, i) =>
        createAddressString(client.purchasers[i].current_address),
      editDataAccessor: (client, i) => {
        return client.purchasers[i].current_address || blankAddressStruct;
      },
      payload: (value) => ({
        current_address: {
          address: value.address,
          city: value.city,
          state: value.state,
          code: value.code,
          unit: value.unit,
        },
      }),
    },
    {
      info: "personal",
      title: "06. Phone Number",
      accessor: (client, i) => {
        return `${client.purchasers[i].phone}`;
      },
      payload: (value) => ({
        phone: value,
      }),
    },
    {
      info: "personal",
      title: "07. Employment Status",
      ...employmentStatusData,
    },
    {
      info: "personal",
      title: "08. Name of Employer",
      accessor: (client, i) => {
        return `${client.purchasers[i].employer_name}`;
      },
      hideIf: hideIfNotEmployed,
      payload: (value) => ({
        employer_name: value,
      }),
    },
    {
      info: "personal",
      title: "09. Employer Address",
      accessor: (client, i) =>
        createAddressString(client.purchasers[i].employer_address),
      hideIf: hideIfNotEmployed,
      editDataAccessor: (client, i) => {
        return client.purchasers[i].employer_address || blankAddressStruct;
      },
      payload: (value) => ({
        employer_address: {
          address: value.address,
          city: value.city,
          state: value.state,
          code: value.code,
          unit: value.unit,
        },
      }),
    },
    {
      info: "personal",
      title: "10. Spousal Status",
      ...spousalStatusData,
    },
    {
      info: "personal",
      title: "11. Citizenship Status",
      ...citizenshipStatusData,
    },
    {
      info: "personal",
      title: "12. First Time Buyer?",
      accessor: (client, i) => {
        return client.purchasers[i].owned_property === 0 ? "Yes" : "No";
      },
      payload: (value) => ({owned_property: value === "Yes" ? 1 : 0}),
    },
    {
      info: "personal",
      title: "13. Resided Address",
      accessor: (client, i) => {
        const rows = client.purchasers[i].resided_address.map((el) => {
          const {
            date,
            address: {address, unit, code, state, city},
          } = el;
          return `${address}, ${city}, ${state}, ${unit}, ${code}, ${
            date.split(" ")[0]
          };`;
        });

        return rows.join(" ");
      },
      editDataAccessor: (client, i) => {
        return client.purchasers[i].resided_address;
      },
      payload: (value) => ({resided_address: value}),
    },

    {
      info: "personal",
      title: "14. In Canada 183 days?",
      accessor: (client, i) => {
        return client.purchasers[i].physically_present === 1 ? "Yes" : "No";
      },
      payload: (value) => ({physically_present: value === "Yes" ? 1 : 0}),
    },
    {
      info: "general",
      title: "15. Signing Appointment",
      payload: (value) => console.log(value),
      ...signingAppointmentData,
    },
    {
      info: "personal",
      title: "16. Email",
      accessor: (client, i) => {
        return client.purchasers[i].email;
      },
      payload: (value) => ({email: value}),
    },
    {
      info: "personal",
      title: "17. Social Insurance Number",
      ...sinData,
    },
  ],
  property_details: [
    {
      info: "general",
      title: "01. Title of Property",
      accessor: (client) => {
        const share = Number(client.client.ownerhip_share) ? `- ${Number(client.client.ownerhip_share)}%` : '';
        return `${client.title_property} ${share}`;
      },
      editDataAccessor: (client, i) => {
        return {
          title_property: client.title_property,
          other_comments: client.title_property_comments,
          purchasers: client.purchasers.map((ps) => ({
            ownerhip_share: Number(ps.ownerhip_share),
            full_name: ps.full_name,
            id: ps.id
          }))
        };
      },
      payload: (value) => {
        return ({
          title_property: value.title_property,
          participants: value.purchasers ?
            value.purchasers.map(({ full_name, ...rest }) => rest) : null,
          title_property_comments: value.other_comments
        })
      },
      validate: (value) => {
        if (value.title_property == "Tenancy in Common") {
          if (value.purchasers.some(ps =>
            !ps.ownerhip_share || isNaN(Number(ps.ownerhip_share)) || Number(ps.ownerhip_share) <= 0
            ))
            return {isValid: false, error: "The values must be greater than zero."};
          if (100 !== Math.round(value.purchasers.reduce((prev, curr) => prev + Number(curr.ownerhip_share), 0)))
            return {isValid: false, error: "The sum must be 100"};
        }
        return {isValid: true, error: ""};
      }
    },
    {
      info: "general",
      title: "02. Type of Property",
      accessor: (client) => {
        return client.type_property;
      },
      payload: (type_property) => ({
        type_property,
      }),
    },
    {
      info: "general",
      title: "03. Assuming Tenant?",
      accessor: (client) => {
        return client.rented_out === 1 ? "Yes" : "No";
      },
      payload: (v) => ({
        rented_out: v === "Yes" ? 1 : 0,
      }),
    },
    {
      info: "general",
      title: "04. Septic, Fuel & Water Well",
      nonmutable: true,
      ...tankFuelWellData
    },
    {
      info: "general",
      title: "05. Property Use",
      accessor: (client) => client.primary_use,
      payload: (primary_use) => ({primary_use}),
    },
  ],
  mortgage_details: [
    {
      info: "general",
      title: "01. Taking of Mortgage",
      accessor: (client) => {
        return client.taking_mortgage === 1 ? "Yes" : "No";
      },
      payload: (v) => ({
        taking_mortgage: v === "Yes" ? 1 : 0,
      }),
    },
    {
      info: "general",
      title: "02. Information about Lender",
      accessor: (client, i) => {
        if (
          !client.lender_name &&
          !client.lender_contact_name &&
          !client.lender_phone &&
          !client.lender_email
        ) {
          return {status: CARD_STATUS_MISSED};
        }

        return () => (
          <>
            {client.lender_name && (
              <p>
                Name: <b>{client.lender_name}</b>
              </p>
            )}
            {client.lender_contact_name && (
              <p>
                Contact name: <b>{client.lender_contact_name}</b>
              </p>
            )}
            {client.lender_phone && (
              <p>
                Phone Number: <b>{client.lender_phone}</b>
              </p>
            )}
            {client.lender_email && (
              <p>
                Email: <b>{client.lender_email}</b>
              </p>
            )}
          </>
        );
      },
      editDataAccessor: (client, i) => {
        return {
          name: client.lender_name,
          contactName: client.lender_contact_name,
          phone: client.lender_phone,
          email: client.lender_email,
        };
      },
      payload: (v) => ({
        lender_name: v.name,
        lender_contact_name: v.contactName,
        lender_phone: v.phone,
        lender_email: v.email,
      }),
    },
  ],
  other_items: [
    // {
    //   info: "general",
    //   title: "01. Home Insurance Policy",
    //   ...getDocumentCardData(DocumentTypeNames.HOME_INSURANCE),
    // },
    {
      info: "general",
      title: "01. Additional Information",
      nonmutable: true,
      accessor: (client) => {
        return `${client.additional_information}`;
      },
    },
    {
      info: "general",
      title: "02. Source of Funds",
      nonmutable: true,
      accessor: (client) => {
        return () => {
          return (
            <>
              <p>Savings - {`${client.savings ? "Yes" : "No"}`}</p>
              <p>Gift - {`${client.gift ? "Yes" : "No"}`}</p>
              <p>Sale proceeds - {`${client.sale_proceeds ? "Yes" : "No"}`}</p>
              <p>
                Mortgage proceeds -{" "}
                {`${client.mortgage_proceeds ? "Yes" : "No"}`}
              </p>
              <p>Other - {client.other}</p>
            </>
          );
        };
      },
    },
  ],
};
