import { FC, useMemo, useState } from "react";
import {styled, Theme} from "@mui/material/styles";
import { Box, useTheme } from "@mui/material";
import DeededButton from "v2/components/DeededButton";
import constants from "styles/constants";
import ArrowsIcon from "components/Svg/ArrowsIcon";
import {truncateString} from "utils/truncateString";
import {copyTextToClipboard} from "utils/copyTextToClipboard";
import useWindowWidth from "utils/hooks/useWindowWidth";
import data from "../../utils/profileIntegrationThreeDData";
import {
  useProfileGetApiKeyQuery,
  useProfileUpdateApiKeyMutation,
} from "redux/reducers/profileApi";
import {getProfileTC} from "redux/reducers/profileReducer";
import {useAppDispatch} from "redux/hooks";
import ProfileIntegrationThreeDEmails from "./ProfileIntegrationThreeDEmails";

interface ProfileIntegrationThreeDProps {}

const ProfileIntegrationThreeD: FC<
  ProfileIntegrationThreeDProps
> = ({}) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { sm } = theme.breakpoints.values;
  const [isCopiedTextProduction, setIsCopiedTextProduction] = useState(false);
  const [isCopiedTextApiKey, setIsCopiedTextApiKey] = useState(false);
  const {data: profileApiData, refetch: refetchApiKeyData} =
    useProfileGetApiKeyQuery("");
  const [updateApiKey] = useProfileUpdateApiKeyMutation();
  const apiKey = useMemo(() => profileApiData?.api_key, [profileApiData]);
  const emails = useMemo(() => profileApiData?.emails, [profileApiData]);
  const handleUpdateApiKey = async () => {
    await updateApiKey(refetchApiKeyData);
    await dispatch(getProfileTC());
  };
  const windowWidth = useWindowWidth();
  const handleClickCopyButton = (text: string, name: string) => {
    copyTextToClipboard(text);

    if (name === "Production") {
      setIsCopiedTextProduction(true);
      setTimeout(() => {
        setIsCopiedTextProduction(false);
      }, 1000);
    }
    if (name === "API Key") {
      setIsCopiedTextApiKey(true);
      setTimeout(() => {
        setIsCopiedTextApiKey(false);
      }, 1000);
    }
  };

  const {httpPost, simpleExample, requiredFields} = data;

  return (
    <ContentBox>
      <ContentBoxItem>
        <Title>{httpPost.title}</Title>
        {httpPost.copyBlocks.map(
          ({label, link, generateKey, button}, index) => (
            <ContentBoxItemCopy key={index}>
              <Label>{label}</Label>
              <CopyBlock>
                <Link title="link">
                  {!link && apiKey
                    ? truncateString(apiKey, windowWidth < sm ? 20 : 45)
                    : truncateString(link ?? "", windowWidth < sm ? 20 : 60)}
                </Link>
                {generateKey && (
                  <ArrowIconWrapper onClick={handleUpdateApiKey}>
                    <ArrowsIcon />
                  </ArrowIconWrapper>
                )}
                <DeededButton
                  sx={{width: "81px !important", height: "32px"}}
                  onClick={() =>
                    handleClickCopyButton(link ?? apiKey ?? "", label)
                  }
                >
                  {label === "Production"
                    ? !isCopiedTextProduction
                      ? button.copyText
                      : button.copiedText
                    : !isCopiedTextApiKey
                    ? button.copyText
                    : button.copiedText}
                </DeededButton>
              </CopyBlock>
            </ContentBoxItemCopy>
          ),
        )}
      </ContentBoxItem>
      <ProfileIntegrationThreeDEmails
        refetchApiKeyData={refetchApiKeyData}
        emails={emails}
      />
      <ContentBoxItem>
        <Title>{simpleExample.title}</Title>
        <CodeBlock>{simpleExample.code}</CodeBlock>
      </ContentBoxItem>
      <ContentBoxItem>
        <Title>{requiredFields.title}</Title>
        <ListBlockNumber>
          {requiredFields.array.map(({item}, index) => (
            <ListBlockNumberItem key={index}>
              {item.name}
              <ListBlockWithoutNumber>
                {item.list.map(({name, list}, index) => (
                  <ListBlockWithoutNumberItem key={index}>
                    {name}
                    {list.length !== 0 && (
                      <ListBlockWithoutNumber>
                        {list.map((item, index) => (
                          <ListBlockWithoutNumberItem key={index} content="• ">
                            {item}
                          </ListBlockWithoutNumberItem>
                        ))}
                      </ListBlockWithoutNumber>
                    )}
                  </ListBlockWithoutNumberItem>
                ))}
              </ListBlockWithoutNumber>
              {item.listNumbers.length !== 0 && (
                <ListBlockNumber sx={{marginTop: "24px"}}>
                  {item.listNumbers.map(({name, list, example}, index) => (
                    <ListBlockNumberItem key={index} className="isListInto">
                      {name}
                      {list.length !== 0 && (
                        <ListBlockWithoutNumber key={index}>
                          {list.map((item) => (
                            <ListBlockWithoutNumberItem>
                              {item}
                            </ListBlockWithoutNumberItem>
                          ))}
                        </ListBlockWithoutNumber>
                      )}
                      {example && (
                        <ContentBoxItem
                          sx={{marginLeft: "-26px", marginTop: "32px"}}
                        >
                          {example.title && <Title>{example.title}</Title>}
                          <CodeBlock>{example.code}</CodeBlock>
                        </ContentBoxItem>
                      )}
                    </ListBlockNumberItem>
                  ))}
                </ListBlockNumber>
              )}
            </ListBlockNumberItem>
          ))}
        </ListBlockNumber>
      </ContentBoxItem>
    </ContentBox>
  );
};

const ContentBox = styled(Box)({
  marginTop: "32px",
  color: constants.colors.intakeTitle,
  fontFamily: "Montserrat",
  fontSize: "14px",
  lineHeight: "17px",
});

const ContentBoxItem = styled(Box)({
  "&:not(:last-child)": {
    marginBottom: "40px",
  },
});

const ContentBoxItemCopy = styled(Box)({
  "&:not(:last-child)": {
    marginBottom: "16px",
  },
});

const Title = styled(Box)({
  fontWeight: 600,
  marginBottom: "16px",
});

const Label = styled(Box)({
  fontSize: "12px",
  lineHeight: "15px",
  marginBottom: "4px",
});

const CopyBlock = styled(Box)({
  display: "flex",
});

const ArrowIconWrapper = styled(Box)({
  backgroundColor: constants.colors.silver4,
  display: "flex",
  alignItems: "center",
  paddingRight: "12px",
  transition: constants.transitions,
  svg: {
    transition: constants.transitions,
  },
  "&:hover": {
    transition: constants.transitions,
    cursor: "pointer",
    svg: {
      transform: "rotate(360deg)",
      transition: constants.transitions,
    },
  },
});

const Link = styled("span")({
  fontSize: "13px",
  color: constants.colors.blue2,
  padding: "7px 10px",
  transition: constants.transitions,
  backgroundColor: constants.colors.silver4,
  display: "flex",
  alignItems: "center",
  flexGrow: 1,
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  width: "100%",
});

const CodeBlock = styled("pre")<{theme?: Theme}>(({theme}) => ({
  backgroundColor: constants.colors.silver5,
  padding: "24px",
  fontWeight: 400,
  border: `1px solid ${constants.colors.configNotificationsTableBorder}`,
  borderRadius: "5px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "9px",
    padding: "10px",
  },
}));

const ListBlockNumber = styled("ol")({
  padding: 0,
  margin: 0,
  listStyle: "none",
  counterReset: "section section2",
});

const ListBlockNumberItem = styled("li")({
  fontSize: "14px",
  lineHeight: "17px",
  fontWeight: 600,
  position: "relative",
  paddingLeft: "17px",
  "&:not(:last-child)": {
    marginBottom: "24px",
  },
  "&:before": {
    counterIncrement: "section",
    content: `counter(section, decimal) ". "`,
    color: constants.colors.red,
    position: "absolute",
    left: 0,
  },
  "&.isListInto": {
    paddingLeft: "26px",
    "&:before": {
      counterIncrement: "section section2",
      content: `counters(section, ".") " "`,
      color: constants.colors.red,
      position: "absolute",
      left: 0,
    },
  },
});

const ListBlockWithoutNumber = styled("ul")({
  padding: 0,
  marginTop: "8px",
  listStyle: "none",
});

const ListBlockWithoutNumberItem = styled("li")<{content?: string}>(
  ({content}) => ({
    fontSize: "11px",
    lineHeight: "15px",
    fontWeight: 400,
    position: "relative",
    paddingLeft: "9px",
    "&:not(:last-child)": {
      marginBottom: "6px",
    },
    "&:first-of-type": {
      color: !content ? constants.colors.red : constants.colors.intakeTitle,
    },
    "&:before": {
      content: !content ? `"- "` : `"${content}"`,
      position: "absolute",
      left: 0,
    },
  }),
);

export default ProfileIntegrationThreeD;
