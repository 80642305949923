import React from "react";
import moment from "moment";
import {Box, styled, Typography} from "@mui/material";
import constants from "styles/constants";
import {Deal} from "types/deal";

interface DealDetailsClosingDateHistoryHintProps {
  deal: Deal;
}
const DealDetailsClosingDateHistoryHint: React.FC<
  DealDetailsClosingDateHistoryHintProps
> = ({deal}) => {
  const closingDateHintsDescription = deal?.closing_date_update.map(
    (closingDate) =>
      moment(closingDate.description, "YYYY-MM-DD").format("MM/DD/YYYY"),
  );
  return (
    <>
      {closingDateHintsDescription?.length > 0 && (
        <ClosingDateHintsBox>
          <CloudBox>
            {closingDateHintsDescription.map(
              (closingDateHintDescription, index) => (
                <HintTypography key={index}>
                  {closingDateHintDescription}
                </HintTypography>
              ),
            )}

            <RotatedSquare />
          </CloudBox>
        </ClosingDateHintsBox>
      )}
    </>
  );
};
const RotatedSquare = styled(Box)({
  position: "absolute",
  bottom: "-5px",
  left: `20px`,
  height: "13px",
  width: "13px",
  transform: "rotate(45deg)",
  background: constants.colors.cloudGray,
});
const HintTypography = styled(Typography)({
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "17px",
  display: "flex",
  alignItems: "center",
  color: "white",
  whiteSpace: "nowrap",
  marginBottom: "5px",
});
const CloudBox = styled(Box)({
  position: "relative",
  width: "100%",
  height: "100%",
  backgroundColor: constants.colors.cloudGray,
  borderRadius: "5px",
  padding: "12px 12px 5px 12px",
});
const ClosingDateHintsBox = styled(Box)({
  maxWidth: "200px",
  position: "absolute",
  bottom: "60px",
  left: "0px",
});
export default DealDetailsClosingDateHistoryHint;
