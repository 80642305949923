import React from "react";

const StorageBigExelSvg = () => {
  return (
    <svg
      width="109"
      height="109"
      viewBox="0 0 109 109"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.8436 16.1797C23.6178 16.1797 23.4012 16.2694 23.2416 16.4291C23.0819 16.5888 22.9922 16.8054 22.9922 17.0312V91.9688C22.9922 92.1946 23.0819 92.4112 23.2416 92.5709C23.4012 92.7306 23.6178 92.8203 23.8436 92.8203H85.1564C85.3822 92.8203 85.5987 92.7306 85.7584 92.5709C85.9181 92.4112 86.0078 92.1946 86.0078 91.9688V38.5269L63.6617 16.1797H23.8436C23.8436 16.1797 23.8436 16.1797 23.8436 16.1797ZM19.6286 12.8163C20.7465 11.6984 22.2626 11.0704 23.8435 11.0703H64.7199C65.3975 11.0703 66.0473 11.3395 66.5264 11.8186L90.369 35.6624C90.8481 36.1414 91.1172 36.7912 91.1172 37.4688V91.9688C91.1172 93.5496 90.4892 95.0658 89.3714 96.1837C88.2535 97.3016 86.7374 97.9296 85.1565 97.9297H23.8435C22.2626 97.9296 20.7465 97.3016 19.6286 96.1837C18.5108 95.0658 17.8828 93.5496 17.8828 91.9688V17.0313C17.8828 17.0313 17.8828 17.0313 17.8828 17.0313C17.8828 15.4504 18.5108 13.9342 19.6286 12.8163Z"
        fill="#828282"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.7187 11.0703C66.1297 11.0703 67.2734 12.2141 67.2734 13.625V34.9141H88.5659C89.9768 34.9141 91.1206 36.0578 91.1206 37.4688C91.1206 38.8797 89.9768 40.0234 88.5659 40.0234H64.7187C63.3078 40.0234 62.1641 38.8797 62.1641 37.4688V13.625C62.1641 12.2141 63.3078 11.0703 64.7187 11.0703Z"
        fill="#828282"
      />
      <path
        d="M46.1085 78.6984C46.4537 79.0981 46.6263 79.5159 46.6263 79.9519C46.6263 80.4788 46.4174 80.942 45.9995 81.3417C45.5999 81.7232 45.1366 81.9139 44.6098 81.9139C44.0648 81.9139 43.6015 81.6869 43.22 81.2327L38.097 74.9652L32.9195 81.2327C32.5199 81.6869 32.0566 81.9139 31.5298 81.9139C31.0211 81.9139 30.5579 81.7232 30.14 81.3417C29.7404 80.942 29.5405 80.4788 29.5405 79.9519C29.5405 79.4978 29.704 79.0799 30.031 78.6984L35.6173 72.0494L30.2218 65.5912C29.8766 65.1915 29.704 64.7737 29.704 64.3377C29.704 63.8109 29.9039 63.3567 30.3035 62.9752C30.7214 62.5755 31.1846 62.3757 31.6933 62.3757C32.2383 62.3757 32.7106 62.6119 33.1103 63.0842L38.097 69.1064L43.0293 63.0842C43.4289 62.6119 43.9013 62.3757 44.4463 62.3757C44.9549 62.3757 45.4091 62.5755 45.8088 62.9752C46.2266 63.3567 46.4355 63.8109 46.4355 64.3377C46.4355 64.7919 46.272 65.2097 45.945 65.5912L40.5495 72.0494L46.1085 78.6984Z"
        fill="#828282"
      />
      <path
        d="M51.1717 81.7504C50.5359 81.7504 50.0454 81.5779 49.7002 81.2327C49.355 80.8875 49.1825 80.397 49.1825 79.7612V64.5012C49.1825 63.8654 49.3732 63.3567 49.7547 62.9752C50.1362 62.5937 50.654 62.4029 51.308 62.4029C51.962 62.4029 52.4797 62.5937 52.8612 62.9752C53.2427 63.3567 53.4335 63.8654 53.4335 64.5012V78.2897H60.4095C61.7356 78.2897 62.3987 78.871 62.3987 80.0337C62.3987 81.1782 61.7356 81.7504 60.4095 81.7504H51.1717Z"
        fill="#828282"
      />
      <path
        d="M71.1926 81.9957C69.7574 81.9957 68.4222 81.8322 67.1868 81.5052C65.9515 81.16 64.9432 80.6786 64.1621 80.0609C63.8896 79.8611 63.6898 79.6522 63.5626 79.4342C63.4536 79.198 63.3991 78.9074 63.3991 78.5622C63.3991 78.108 63.5353 77.7084 63.8078 77.3632C64.0985 77.018 64.4255 76.8454 64.7888 76.8454C64.9887 76.8454 65.1794 76.8818 65.3611 76.9544C65.5609 77.0089 65.7971 77.1179 66.0696 77.2814C66.8507 77.7719 67.641 78.1353 68.4403 78.3714C69.2397 78.5894 70.1208 78.6984 71.0836 78.6984C72.2826 78.6984 73.2 78.4986 73.8358 78.0989C74.4717 77.6993 74.7896 77.127 74.7896 76.3822C74.7896 75.819 74.4898 75.3649 73.8903 75.0197C73.309 74.6745 72.2644 74.3475 70.7566 74.0387C69.0671 73.6935 67.7137 73.2757 66.6963 72.7852C65.6972 72.2947 64.9614 71.6861 64.4891 70.9594C64.0349 70.2328 63.8078 69.3517 63.8078 68.3162C63.8078 67.1717 64.1348 66.1453 64.7888 65.2369C65.461 64.3104 66.3784 63.5929 67.5411 63.0842C68.7219 62.5574 70.0481 62.2939 71.5196 62.2939C74.0992 62.2939 76.2248 62.9389 77.8961 64.2287C78.1686 64.4467 78.3593 64.6738 78.4683 64.9099C78.5955 65.1279 78.6591 65.4004 78.6591 65.7274C78.6591 66.1816 78.5138 66.5813 78.2231 66.9264C77.9506 67.2716 77.6327 67.4442 77.2693 67.4442C77.0695 67.4442 76.8787 67.4169 76.6971 67.3624C76.5336 67.3079 76.2974 67.1899 75.9886 67.0082C75.2438 66.5359 74.5534 66.1816 73.9176 65.9454C73.2999 65.7093 72.5097 65.5912 71.5468 65.5912C70.4387 65.5912 69.5667 65.8092 68.9308 66.2452C68.295 66.663 67.9771 67.2444 67.9771 67.9892C67.9771 68.4252 68.0952 68.7885 68.3313 69.0792C68.5857 69.3517 68.9944 69.5969 69.5576 69.8149C70.1389 70.0329 70.9564 70.26 72.0101 70.4962C74.4808 71.0412 76.252 71.7406 77.3238 72.5944C78.4138 73.4483 78.9588 74.62 78.9588 76.1097C78.9588 77.2724 78.6318 78.2988 77.9778 79.1889C77.342 80.0791 76.4337 80.7694 75.2528 81.2599C74.0902 81.7504 72.7368 81.9957 71.1926 81.9957Z"
        fill="#828282"
      />
    </svg>
  );
};

export default StorageBigExelSvg;
