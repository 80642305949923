import React, {ExoticComponent, useEffect} from "react";
import {
  Box,
  Dialog,
  DialogProps,
  IconButton,
  styled,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import {StyledComponent} from "@emotion/styled";
import constants from "../../styles/constants";
import CloseDeededSvg from "../../components/Svg/CloseDeededSvg";

interface DeededPopupProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  sxInternalBox: SxProps<Theme> | undefined;
  title?: string;
  hideBackdrop?: boolean;
  hideBtnClose?: boolean;
  disableScrollLock?: boolean;
  sx?: SxProps<Theme>;
  TitleCmp?: ExoticComponent | StyledComponent<any>;
}

const DeededPopup: React.FC<DeededPopupProps & DialogProps> = ({
  children,
  open,
  setOpen,
  sxInternalBox,
  title,
  hideBackdrop,
  hideBtnClose,
  sx,
  disableScrollLock,
  TitleCmp = Title,
  ...props
}) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    document.body.style.height = "100vh";
    return () => {
      document.body.style.overflow = "inherit";
      document.body.style.height = "auto";
    };
  }, []);

  const theme = useTheme();

  return (
    <Dialog
      {...props}
      disableScrollLock={disableScrollLock ?? open}
      data-selenium-test="DeededPopup"
      sx={{
        "& .MuiPaper-root": {
          maxWidth: "unset",
          [theme.breakpoints.down("sm")]: {
            margin: "15px",
          },
          "&::-webkit-scrollbar": {
            width: "5px",
            scrollbarWidth: "thin",
          },
        },
        ...sx,
      }}
      onClose={() => {
        setOpen(false);
      }}
      open={open}
      hideBackdrop={hideBackdrop}
    >
      <Box
        onClick={(e) => {
          e.stopPropagation();
        }}
        sx={{
          padding: "32px",
          [theme.breakpoints.down("sm")]: {
            width: "100%",
            minWidth: "325px",
          },
          ...sxInternalBox,
        }}
      >
        {title && (
          <TitleBox>
            {title && <TitleCmp>{title}</TitleCmp>}
            {!hideBtnClose && (
              <CloseBtn
                sx={{
                  top: title ? "10px" : 0,
                }}
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CloseDeededSvg />
              </CloseBtn>
            )}
          </TitleBox>
        )}
        {children}
      </Box>
    </Dialog>
  );
};

const CloseBtn = styled(IconButton)({
  minWidth: "0px",
  marginTop: "-22px",
  marginRight: "-22px",
  transition: `${constants.transitions} !important`,
  "&:hover": {
    transition: `${constants.transitions} !important`,
  },
});

const Title = styled(Typography)({
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "18px",
  lineHeight: "22px",
  color: constants.colors.title,
});

const TitleBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});
export default DeededPopup;
