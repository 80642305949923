import moment, {Moment} from "moment";

export const getMonthArrayByDate = (date: Date) => {
  const endMonthDay = moment(date).endOf("month").endOf("week");
  const startMonthDay = moment(date).startOf("month").startOf("week");
  const calendarShowingDaysArray: Moment[][] = [];
  let dateForCycle = startMonthDay.clone().subtract(1, "day");
  while (dateForCycle.isBefore(endMonthDay, "day")) {
    calendarShowingDaysArray.push(
      Array(7)
        .fill(0)
        .map(() => dateForCycle.add(1, "day").clone()) as unknown as Moment[],
    );
  }
  return calendarShowingDaysArray;
};
