import { FC, forwardRef } from "react";
import MuiCheckbox, { CheckboxProps } from "@mui/material/Checkbox";
import { FormControlLabel, FormControlLabelProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import Colors from "../Styling/Colors";
import { BODY_REGULAR } from "../Styling/Styles";

export interface CheckboxFieldProps extends Omit<CheckboxProps, "size"> {
  /**
   * 	A text or an element to be used in an enclosing label element.
   */
  label?: string;
  error?: string;
  /**
   * Checkbox size. Only size for now
   */
  size?: "default";
  /**
   * The position of the label.
   */
  labelPlacement?: FormControlLabelProps["labelPlacement"];
  /**
   * 	If true, the control is disabled.
   */
  disabled?: boolean;
  /**
   * Id for tests
   */
  testId?: string;
}

const CheckboxField: FC<CheckboxFieldProps> = forwardRef<
  HTMLButtonElement,
  CheckboxFieldProps
>(function inputRender(
  { labelPlacement = "end", label, size, error, disabled = false, testId, ...props },
  ref,
) {
  return (
    <StyledFormControlLabel
      label={label}
      disabled={disabled}
      control={<StyledCheckbox checkedIcon={<CheckedIcon />} icon={<UncheckedIcon />} {...props} ref={ref} />}
      labelPlacement={labelPlacement}
      data-testid={testId}
    />
  );
});

const StyledCheckbox = styled(MuiCheckbox)<CheckboxFieldProps>(({theme}) => ({
  '&:hover': {
    backgroundColor: Colors.NEUTRAL_50
  },
  "&.Mui-checked": {
    '&:hover': {
      backgroundColor: Colors.PRIMARY_50
    },
  },
  "&.MuiCheckbox-root": {
    borderRadius: '1.2rem',
    padding: '.6rem',
    [theme.breakpoints.down('mobile')]: {
        alignSelf: 'flex-start',
    }
  },
  "&.Mui-disabled": {
    '& svg rect': {
      fill: Colors.NEUTRAL_200,
      stroke: Colors.NEUTRAL_300
    },
    "&.Mui-checked": {
      '& svg path': {
        stroke: Colors.NEUTRAL_500,
      },
      '& svg rect': {
        fill: Colors.NEUTRAL_200,
        stroke: Colors.NEUTRAL_200,
      },
    },
  },
}));

const StyledFormControlLabel = styled(FormControlLabel)<CheckboxFieldProps>(
  () => ({
    flexDirection: "row",
    ".MuiFormControlLabel-label": {
      ...BODY_REGULAR,
      marginLeft: '.25rem',
    },
    marginLeft: 0,
  }),
);

const CheckedIcon = () => <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect y="0.5" width="24" height="24" rx="8" fill={Colors.PRIMARY_600} />
  <path d="M6 13.25L8.09878 15.2176C8.8681 15.9388 10.0652 15.9388 10.8345 15.2176L18 8.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
</svg>;


const UncheckedIcon = () => <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="0.5" y="1" width="23" height="23" rx="7.5" stroke={Colors.NEUTRAL_300} />
</svg>;


export default CheckboxField;
