import {useSelector} from "react-redux";
import BaseStoragePage from "../../Dashboard/StorageCommonComponents/BaseStoragePage";
import {Files} from "../../Dashboard/StorageCommonComponents";

const FilesStoragePage = ({openFolderId}) => {
  const fileInfoSection = useSelector(
    (state) => state.StorageReducer.fileInfoSection,
  );
  const clientName = useSelector((state) => state.StorageReducer.clientName);

  return (
    <>
      <BaseStoragePage
        fileInfoSection={fileInfoSection}
        files={
          <Files
            nopagination
            clientName={clientName}
            openFolderId={openFolderId}
          />
        }
      />
    </>
  );
};
export default FilesStoragePage;
