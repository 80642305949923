const TermsImg = () => {
  return (
    <svg width="266" height="400" viewBox="0 0 266 400" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1_2906)">
        <path d="M209.915 321.767C209.713 321.996 209.51 322.231 209.315 322.467C206.58 325.645 204.11 329.042 201.929 332.624C201.753 332.899 201.583 333.18 201.426 333.454C196.262 342.108 192.747 351.647 191.059 361.586C190.68 363.835 190.395 366.096 190.204 368.371C190.067 370.123 189.984 371.877 189.956 373.633C189.852 381.006 190.504 389.209 185.761 394.843C184.493 396.325 182.945 397.543 181.207 398.425C179.897 399.104 178.513 399.628 177.083 399.987H162.292C161.548 399.876 160.804 399.758 160.061 399.64C160.041 399.64 160.021 399.634 160.002 399.634L159.852 399.686L158.958 399.987L158.919 400V399.987C158.92 399.961 158.918 399.934 158.912 399.908C158.913 399.86 158.911 399.812 158.906 399.765V399.758C158.899 399.627 158.893 399.503 158.893 399.372L158.873 399.026C158.873 399.026 158.867 399.019 158.873 399.019C158.867 398.823 158.853 398.627 158.847 398.425C158.162 381.365 159.832 363.737 167.87 348.716C170.627 343.57 174.176 338.891 178.388 334.853C178.413 334.827 178.439 334.803 178.466 334.781C186.824 326.794 197.629 321.571 209.034 321.748C209.328 321.748 209.621 321.754 209.915 321.767Z" fill="#F0F0F0" />
        <path d="M209.948 322.137C197.031 328.777 186.531 339.326 179.94 352.285C178.471 355.05 177.447 358.029 176.905 361.113C176.473 364.134 176.857 367.217 178.017 370.039C179.045 372.692 180.404 375.287 180.771 378.145C180.937 379.61 180.792 381.095 180.345 382.5C179.898 383.905 179.16 385.2 178.179 386.3C175.805 389.104 172.567 390.885 169.34 392.518C165.757 394.33 162.007 396.156 159.52 399.432C159.218 399.829 158.621 399.338 158.922 398.941C163.25 393.24 170.872 391.997 176.063 387.362C178.485 385.199 180.272 382.292 180.07 378.943C179.894 376.015 178.495 373.338 177.43 370.661C176.259 367.924 175.774 364.941 176.018 361.974C176.402 358.872 177.31 355.858 178.703 353.062C181.74 346.715 185.753 340.887 190.597 335.79C196.093 329.941 202.547 325.078 209.679 321.41C210.121 321.183 210.387 321.911 209.948 322.137Z" fill="white" />
        <path d="M181.817 347.987C179.797 346.584 178.34 344.508 177.707 342.128C177.074 339.748 177.307 337.221 178.362 334.997C178.577 334.548 179.284 334.861 179.069 335.311C178.082 337.38 177.866 339.735 178.46 341.95C179.054 344.164 180.418 346.093 182.307 347.389C182.717 347.671 182.225 348.268 181.817 347.987Z" fill="white" />
        <path d="M177.318 368.762C182.271 367.909 186.72 365.218 189.78 361.224C190.083 360.828 190.68 361.32 190.378 361.715C187.186 365.865 182.55 368.657 177.394 369.533C176.903 369.616 176.83 368.845 177.318 368.762Z" fill="white" />
        <path d="M196.498 330.884C197.167 331.59 198 332.119 198.923 332.423C199.846 332.726 200.83 332.796 201.787 332.624C202.277 332.535 202.35 333.306 201.863 333.395C200.805 333.579 199.719 333.502 198.698 333.168C197.678 332.835 196.754 332.257 196.008 331.483C195.932 331.416 195.884 331.323 195.874 331.221C195.864 331.12 195.892 331.019 195.954 330.938C196.02 330.859 196.113 330.809 196.215 330.799C196.317 330.789 196.419 330.82 196.498 330.884Z" fill="white" />
        <path d="M245.937 369.064C245.637 369.129 245.337 369.195 245.03 369.266H245.024C240.935 370.157 236.924 371.376 233.031 372.914C232.731 373.031 232.431 373.149 232.131 373.273H232.125C222.845 377.058 214.342 382.527 207.044 389.405C207.018 389.431 206.992 389.45 206.966 389.477C206.95 389.496 206.933 389.513 206.914 389.529L206.881 389.562C203.985 392.31 201.3 395.272 198.849 398.425C198.849 398.431 198.843 398.438 198.836 398.444C198.445 398.954 198.047 399.464 197.655 399.987H158.958L158.919 400V399.987H158.756C158.756 399.98 158.762 399.974 158.769 399.967C158.769 399.961 158.775 399.961 158.775 399.954C158.821 399.889 158.867 399.824 158.906 399.765V399.758C158.915 399.738 158.929 399.721 158.945 399.706C158.984 399.64 159.03 399.588 159.062 399.529C159.323 399.163 159.578 398.791 159.839 398.425C160.263 397.817 160.693 397.209 161.131 396.608L161.131 396.605L161.132 396.603L161.135 396.602L161.137 396.601V396.595C161.144 396.588 161.144 396.582 161.15 396.575L161.163 396.562C166.546 389.097 172.601 382.012 179.536 376.077C179.745 375.894 179.954 375.711 180.176 375.541C183.316 372.879 186.67 370.481 190.204 368.371C192.135 367.227 194.125 366.177 196.174 365.221C201.458 362.781 207.093 361.189 212.87 360.501H212.884C212.897 360.501 212.91 360.495 212.929 360.495C212.949 360.495 212.962 360.488 212.975 360.488C224.465 359.155 236.241 361.501 245.252 368.521C245.487 368.704 245.709 368.881 245.937 369.064Z" fill="#F0F0F0" />
        <path d="M158.756 399.987H158.919V400L158.958 399.987H169.312C166.226 399.457 163.147 399.046 160.061 399.64C159.989 399.653 159.924 399.667 159.852 399.686C159.539 399.745 159.225 399.823 158.912 399.908C158.893 399.915 158.867 399.921 158.847 399.928C158.819 399.936 158.792 399.949 158.769 399.967C158.752 399.97 158.736 399.976 158.723 399.987H158.756Z" fill="white" />
        <path d="M245.748 369.384C245.506 369.345 245.272 369.299 245.03 369.267H245.024C239.99 368.444 234.88 368.186 229.789 368.495C229.534 368.508 229.273 368.528 229.012 368.541C221.946 369.035 214.997 370.62 208.414 373.241C208.408 373.241 208.408 373.241 208.408 373.247C208.336 373.273 208.264 373.299 208.199 373.326C206.666 373.947 205.152 374.626 203.671 375.358C200.837 376.68 198.228 378.44 195.939 380.574C194.107 382.386 192.72 384.599 191.887 387.039C191.803 387.287 191.718 387.529 191.64 387.777C191.581 387.973 191.522 388.176 191.463 388.372C190.693 391.111 190.217 394 188.795 396.503C188.399 397.202 187.916 397.847 187.359 398.425C186.784 399.025 186.135 399.55 185.428 399.987H183.784C184.684 399.597 185.518 399.071 186.257 398.425C186.831 397.928 187.334 397.354 187.751 396.719C189.369 394.274 189.865 391.294 190.622 388.516C190.746 388.065 190.876 387.627 191.02 387.189C191.773 384.792 193.035 382.587 194.719 380.725C196.889 378.48 199.424 376.621 202.216 375.228C203.978 374.3 205.787 373.459 207.644 372.705C207.886 372.6 208.127 372.509 208.369 372.417C220.096 367.845 232.831 366.5 245.252 368.521C245.493 368.554 245.728 368.6 245.97 368.639C246.459 368.724 246.237 369.469 245.748 369.384Z" fill="white" />
        <path d="M207.752 373.056C206.982 370.717 207.068 368.18 207.992 365.899C208.917 363.617 210.622 361.739 212.801 360.6C213.243 360.371 213.619 361.048 213.176 361.277C211.145 362.334 209.557 364.084 208.701 366.21C207.844 368.337 207.774 370.7 208.504 372.873C208.662 373.346 207.909 373.526 207.752 373.056Z" fill="white" />
        <path d="M191.675 386.93C196.142 389.236 201.312 389.771 206.155 388.428C206.635 388.294 206.816 389.047 206.337 389.18C201.295 390.569 195.915 390.001 191.272 387.591C190.83 387.361 191.235 386.702 191.675 386.93Z" fill="white" />
        <path d="M229.753 368.255C229.863 369.222 230.21 370.147 230.765 370.946C231.32 371.745 232.064 372.394 232.93 372.834C233.375 373.058 232.97 373.718 232.528 373.495C231.572 373.005 230.752 372.287 230.137 371.406C229.522 370.524 229.133 369.505 229.002 368.437C228.981 368.338 228.999 368.234 229.052 368.147C229.105 368.06 229.189 367.997 229.286 367.97C229.386 367.946 229.491 367.963 229.578 368.016C229.666 368.07 229.729 368.156 229.753 368.255Z" fill="white" />
        <path d="M207.442 108.692C207.618 87.05 208.497 65.1992 210.078 43.1394C210.104 42.7014 210.137 42.27 210.169 41.8321C211.004 30.3479 212.027 18.8223 213.236 7.25526V7.18989C213.235 5.28336 212.478 3.45525 211.132 2.10713C209.787 0.759012 207.962 0.0011377 206.059 0H13.5837C11.687 0.00373326 9.86861 0.757957 8.5244 2.09846C7.18019 3.43895 6.41905 5.25717 6.40668 7.15721C4.73639 23.0861 3.42711 38.9365 2.47887 54.7086C2.43972 55.2249 2.41363 55.7347 2.38754 56.2511C1.91777 64.0489 1.54369 71.827 1.26531 79.5856C1.24573 80.0431 1.22615 80.5007 1.21963 80.9582C-0.561698 128.562 1.17205 176.232 6.40668 223.58C6.4204 225.479 7.18219 227.295 8.52629 228.634C9.87039 229.974 11.688 230.727 13.5837 230.73H206.059C207.962 230.729 209.787 229.971 211.132 228.623C212.478 227.275 213.235 225.447 213.236 223.54V223.468C212.29 215.056 211.459 206.59 210.743 198.069C210.639 196.82 210.537 195.572 210.437 194.323C209.941 188.186 209.508 182.024 209.138 175.839C209.06 174.59 208.988 173.342 208.923 172.093C208.225 159.714 207.768 147.238 207.553 134.667C207.527 133.418 207.507 132.176 207.488 130.921C207.409 124.784 207.385 118.622 207.416 112.437C207.416 111.188 207.422 109.94 207.442 108.692Z" fill="#F0F0F0" />
        <path d="M265.433 49.0219C265.431 47.1154 264.675 45.2873 263.329 43.9392C261.983 42.591 260.159 41.8332 258.255 41.832H65.7804C63.8837 41.8358 62.0653 42.59 60.7211 43.9305C59.3769 45.271 58.6157 47.0892 58.6034 48.9892C52.1049 111.064 51.0283 172.041 55.3802 230.73C55.9087 237.992 56.5264 245.217 57.2332 252.404C57.6116 256.254 58.014 260.093 58.4403 263.921C58.4925 264.418 58.5447 264.915 58.6034 265.412C58.6171 267.311 59.3789 269.127 60.723 270.467C62.0671 271.806 63.8847 272.559 65.7804 272.562H258.255C260.159 272.561 261.983 271.803 263.329 270.455C264.675 269.107 265.431 267.279 265.433 265.372V265.3C257.649 196.166 257.649 123.424 265.433 49.0873V49.0219Z" fill="white" />
        <path d="M265.433 49.0219C265.431 47.1154 264.675 45.2873 263.329 43.9392C261.983 42.591 260.159 41.8332 258.255 41.832H65.7804C63.8837 41.8358 62.0653 42.59 60.7211 43.9305C59.3769 45.271 58.6157 47.0892 58.6034 48.9892C52.1049 111.064 51.0283 172.041 55.3802 230.73C55.9087 237.992 56.5264 245.217 57.2332 252.404C57.6116 256.254 58.014 260.093 58.4403 263.921C58.4925 264.418 58.5447 264.915 58.6034 265.412C58.6171 267.311 59.3789 269.127 60.723 270.467C62.0671 271.806 63.8847 272.559 65.7804 272.562H258.255C260.159 272.561 261.983 271.803 263.329 270.455C264.675 269.107 265.431 267.279 265.433 265.372V265.3C257.649 196.166 257.649 123.424 265.433 49.0873V49.0219ZM264.128 265.412C264.116 266.965 263.492 268.45 262.393 269.545C261.293 270.639 259.806 271.254 258.255 271.255H65.7804C64.2237 271.253 62.7315 270.632 61.6308 269.529C60.53 268.427 59.9106 266.932 59.9083 265.372V265.3C59.8626 264.882 59.8169 264.457 59.7713 264.039C59.3211 260.039 58.9013 256.028 58.512 252.006C57.8269 244.953 57.2202 237.861 56.6917 230.73C52.3398 172.074 53.4098 111.136 59.9083 49.0873V49.0219C59.9106 47.4625 60.53 45.9676 61.6308 44.8649C62.7315 43.7622 64.2237 43.1416 65.7804 43.1393H258.255C259.807 43.1398 261.295 43.7552 262.396 44.8513C263.496 45.9473 264.118 47.4349 264.128 48.9892C256.344 123.385 256.344 196.206 264.128 265.412Z" fill="#E4E4E4" />
        <path d="M79.0476 108.692C78.5525 108.693 78.078 108.891 77.7284 109.242C77.3787 109.594 77.1824 110.069 77.1824 110.565C77.1824 111.061 77.3787 111.537 77.7284 111.888C78.078 112.24 78.5525 112.438 79.0476 112.439H235.462C235.957 112.438 236.432 112.24 236.781 111.888C237.131 111.537 237.327 111.061 237.327 110.565C237.327 110.069 237.131 109.594 236.781 109.242C236.432 108.891 235.957 108.693 235.462 108.692H79.0476Z" fill="#E6E6E6" />
        <path d="M79.0475 119.934C78.5521 119.935 78.0773 120.133 77.7273 120.484C77.3773 120.835 77.1808 121.311 77.1808 121.808C77.1808 122.304 77.3773 122.78 77.7273 123.131C78.0773 123.482 78.5521 123.68 79.0475 123.681H185.232C185.727 123.68 186.202 123.482 186.552 123.131C186.902 122.78 187.098 122.304 187.098 121.808C187.098 121.311 186.902 120.835 186.552 120.484C186.202 120.133 185.727 119.935 185.232 119.934H79.0475Z" fill="#E6E6E6" />
        <path d="M79.0475 130.922C78.5521 130.923 78.0773 131.121 77.7273 131.472C77.3773 131.823 77.1808 132.299 77.1808 132.795C77.1808 133.292 77.3773 133.768 77.7273 134.119C78.0773 134.47 78.5521 134.668 79.0475 134.669H235.462C235.957 134.668 236.432 134.47 236.782 134.119C237.132 133.768 237.329 133.292 237.329 132.795C237.329 132.299 237.132 131.823 236.782 131.472C236.432 131.121 235.957 130.923 235.462 130.922H79.0475Z" fill="#E6E6E6" />
        <path d="M79.0476 142.164C78.5525 142.165 78.078 142.363 77.7284 142.715C77.3787 143.066 77.1824 143.542 77.1824 144.038C77.1824 144.534 77.3787 145.009 77.7284 145.361C78.078 145.712 78.5525 145.91 79.0476 145.911H185.232C185.727 145.91 186.201 145.712 186.551 145.361C186.901 145.009 187.097 144.534 187.097 144.038C187.097 143.542 186.901 143.066 186.551 142.715C186.201 142.363 185.727 142.165 185.232 142.164H79.0476Z" fill="#E6E6E6" />
        <path d="M79.0476 172.094C78.5525 172.095 78.078 172.293 77.7284 172.644C77.3787 172.995 77.1824 173.471 77.1824 173.967C77.1824 174.463 77.3787 174.939 77.7284 175.29C78.078 175.641 78.5525 175.839 79.0476 175.841H235.462C235.957 175.839 236.432 175.641 236.781 175.29C237.131 174.939 237.327 174.463 237.327 173.967C237.327 173.471 237.131 172.995 236.781 172.644C236.432 172.293 235.957 172.095 235.462 172.094H79.0476Z" fill="#E6E6E6" />
        <path d="M79.0475 183.336C78.5521 183.337 78.0773 183.534 77.7273 183.886C77.3773 184.237 77.1808 184.713 77.1808 185.209C77.1808 185.706 77.3773 186.182 77.7273 186.533C78.0773 186.884 78.5521 187.082 79.0475 187.083H185.232C185.727 187.082 186.202 186.884 186.552 186.533C186.902 186.182 187.098 185.706 187.098 185.209C187.098 184.713 186.902 184.237 186.552 183.886C186.202 183.534 185.727 183.337 185.232 183.336H79.0475Z" fill="#E6E6E6" />
        <path d="M79.0475 194.323C78.5521 194.324 78.0773 194.522 77.7273 194.873C77.3773 195.225 77.1808 195.701 77.1808 196.197C77.1808 196.693 77.3773 197.169 77.7273 197.521C78.0773 197.872 78.5521 198.07 79.0475 198.071H235.462C235.957 198.07 236.432 197.872 236.782 197.521C237.132 197.169 237.329 196.693 237.329 196.197C237.329 195.701 237.132 195.225 236.782 194.873C236.432 194.522 235.957 194.324 235.462 194.323H79.0475Z" fill="#E6E6E6" />
        <path d="M79.0476 205.566C78.5525 205.567 78.078 205.765 77.7284 206.116C77.3787 206.467 77.1824 206.943 77.1824 207.439C77.1824 207.935 77.3787 208.411 77.7284 208.762C78.078 209.113 78.5525 209.311 79.0476 209.313H185.232C185.727 209.311 186.201 209.113 186.551 208.762C186.901 208.411 187.097 207.935 187.097 207.439C187.097 206.943 186.901 206.467 186.551 206.116C186.201 205.765 185.727 205.567 185.232 205.566H79.0476Z" fill="#E6E6E6" />
        <path d="M109.996 85.429H80.1213C79.7344 85.4285 79.3634 85.2744 79.0898 85.0003C78.8162 84.7262 78.6623 84.3545 78.6619 83.9669V79.1718C78.6623 78.7841 78.8162 78.4125 79.0898 78.1384C79.3634 77.8643 79.7344 77.7102 80.1213 77.7097H109.996C110.383 77.7102 110.754 77.8643 111.028 78.1384C111.301 78.4125 111.455 78.7841 111.456 79.1718V83.9669C111.455 84.3545 111.301 84.7262 111.028 85.0003C110.754 85.2744 110.383 85.4285 109.996 85.429Z" fill="#F45753" />
        <path d="M213.781 239.669L204.63 242.699C203.105 243.204 201.58 243.709 200.055 244.214C198.642 244.68 197.114 245.234 195.602 245.033C194.557 244.895 193.366 244.292 193.04 243.215C192.732 242.196 193.385 241.13 194.176 240.527C196.36 238.863 199.121 240.557 200.668 242.28C202.561 244.388 203.632 247.11 205.407 249.316C206.987 251.295 208.99 252.893 211.269 253.993C215.798 256.135 221.322 255.908 225.361 252.786C230.185 249.059 230.677 242.598 230.702 236.962C230.694 236.708 230.587 236.467 230.404 236.29C230.221 236.113 229.978 236.015 229.724 236.015C229.47 236.015 229.226 236.113 229.043 236.29C228.861 236.467 228.754 236.708 228.745 236.962C228.722 242.07 228.432 248.332 223.782 251.528C219.329 254.59 213.475 253.778 209.375 250.516C207.09 248.698 205.685 246.327 204.184 243.872C202.72 241.476 200.902 239.153 198.146 238.221C196.964 237.791 195.67 237.783 194.483 238.198C193.296 238.613 192.288 239.427 191.63 240.5C191.33 241.007 191.141 241.572 191.075 242.157C191.01 242.743 191.069 243.336 191.251 243.897C191.432 244.457 191.73 244.973 192.126 245.409C192.521 245.845 193.005 246.192 193.545 246.426C196.74 247.951 200.396 246.164 203.473 245.145L214.302 241.56C215.492 241.166 214.982 239.272 213.781 239.669Z" fill="#F45753" />
        <path d="M186.664 383.794L180.247 385.912L169.039 362.126L178.51 359L186.664 383.794Z" fill="#9F616A" />
        <path d="M191.242 389.196L190.804 387.869L189.656 384.372L188.873 381.993L186.733 382.699L186.289 382.666L180.411 382.267L179.628 382.215L178.466 382.136L177.266 382.051L173.912 386.934H173.906C172.652 387.343 171.492 387.997 170.493 388.859C169.494 389.721 168.676 390.773 168.086 391.954C168.014 392.098 167.949 392.242 167.884 392.385C167.722 392.737 167.584 393.1 167.472 393.47C166.965 395.072 166.87 396.777 167.198 398.425C167.283 398.833 167.39 399.237 167.518 399.634L167.538 399.699L167.616 399.941L168.086 399.784L172.209 398.425L186.655 393.66L189.519 392.712L190.4 392.418H190.406L192.116 391.856L191.242 389.196Z" fill="#2F2E41" />
        <path d="M125.842 389.386L119.087 389.385L115.873 363.281L125.843 363.281L125.842 389.386Z" fill="#9F616A" />
        <path d="M115.031 274.95L112.036 280.14C112.036 280.14 108.745 281.04 109.821 291.786C110.58 299.374 110.826 333.35 112.738 355.423C113.534 364.609 114.618 371.734 116.148 374.036L117.09 374.881L126.921 375.822L127.168 375.735L130.778 350.194C131.426 345.609 131.329 340.949 130.491 336.396L129.839 329.206L138.89 292.8C138.89 292.8 148.107 316.133 153.98 338.356C155.393 343.706 160.762 347.108 162.798 351.699C163.48 353.237 164.15 354.727 164.804 356.157C169.11 365.573 171.268 372.429 172.901 372.999H174.206L182.035 370.384L183.575 366.505L171.596 331.167L162.625 274.109C145.533 264.901 129.499 263.877 115.031 274.95Z" fill="#2F2E41" />
        <path d="M163.995 198.069C163.405 196.766 162.711 195.514 161.92 194.323C160.112 191.501 157.6 189.201 154.632 187.65L152.061 187.081L147.455 186.061L145.028 183.336L141.048 178.872H127.346L125.513 183.336L123.973 187.081L123.314 188.676L111.583 194.552C111.576 194.552 111.576 194.552 111.57 194.559C111.726 195.735 111.881 196.905 112.033 198.069C112.366 200.598 112.679 203.097 112.973 205.566C113.123 206.821 113.266 208.069 113.403 209.311C113.84 213.207 114.217 217.035 114.532 220.795C114.813 224.155 115.037 227.467 115.204 230.73C115.612 238.143 115.643 245.571 115.295 252.986C115.204 254.732 116.933 256.719 116.796 258.425C116.568 261.209 114.421 263.68 114.043 266.34C113.808 267.974 113.912 269.713 113.501 271.255C113.387 271.706 113.216 272.141 112.992 272.549C112.992 272.556 112.986 272.556 112.986 272.562C108.288 280.968 111.974 276.955 111.537 278.844L111.746 278.896C111.844 278.922 112.144 279.001 112.627 279.112C115.387 279.772 126.766 269.072 134.406 269.072C138.993 269.072 151.135 276.628 155.2 277.177C158.416 277.608 161.287 278.001 162.997 276.491C163.493 276.05 163.872 275.493 164.099 274.87L164.119 274.811L164.112 274.752L163.949 272.562L163.851 271.255L162.455 252.496C161.675 251.528 161.182 250.359 161.033 249.124C160.883 247.889 161.082 246.636 161.607 245.509L161.894 244.901L160.837 230.73L160.641 228.083L160.569 227.188V227.181L160.06 220.338L161.287 216.599V216.592L163.675 209.311L164.902 205.566L165.672 203.213L165.724 203.056C165.313 201.34 164.734 199.67 163.995 198.069Z" fill="#F45753" />
        <path d="M175.898 265.66L162.745 231.605L156.635 219.169L168.383 212.668L177.844 229.368L184.186 264.259C185.185 264.942 185.957 265.908 186.403 267.033C186.849 268.159 186.949 269.392 186.689 270.575C186.43 271.758 185.824 272.836 184.949 273.671C184.074 274.506 182.969 275.06 181.777 275.262C180.585 275.463 179.36 275.303 178.26 274.802C177.16 274.301 176.234 273.481 175.602 272.449C174.97 271.417 174.66 270.219 174.713 269.009C174.766 267.799 175.179 266.633 175.898 265.66Z" fill="#9F616A" />
        <path d="M176.163 218.743L172.797 216.56L168.927 209.311L166.931 205.566L165.672 203.213L165.137 202.206L159.088 198.069L155.004 195.271L151.676 198.069L150.091 199.402L151.644 205.566L152.583 209.311L154.632 217.435L160.641 228.083L162.135 230.73L162.99 232.253L162.997 232.266L163.114 232.469L178.297 231.835L178.773 231.815L178.799 230.73L178.812 230.338C178.819 230.142 178.819 229.946 178.819 229.75C178.817 225.922 177.906 222.149 176.163 218.743Z" fill="#F45753" />
        <path d="M61.2489 252.116L88.157 227.484L97.5086 217.27L107.818 225.879L95.7149 240.769L65.5584 259.346C65.2854 260.526 64.6666 261.597 63.7814 262.421C62.8962 263.246 61.7851 263.787 60.5909 263.974C59.3966 264.161 58.1738 263.986 57.0794 263.472C55.9851 262.958 55.0692 262.128 54.4494 261.088C53.8297 260.048 53.5344 258.847 53.6015 257.638C53.6686 256.429 54.095 255.267 54.826 254.303C55.5569 253.338 56.5591 252.615 57.7036 252.225C58.8481 251.836 60.0828 251.798 61.2489 252.116Z" fill="#9F616A" />
        <path d="M116.796 209.311L116.744 205.566L116.646 198.068L116.607 195.16L116.496 195.081C115.888 194.682 115.2 194.423 114.48 194.323C113.847 194.218 113.201 194.218 112.568 194.323C112.234 194.375 111.905 194.451 111.583 194.552C111.576 194.552 111.576 194.552 111.57 194.559C109.636 195.254 107.928 196.465 106.631 198.062L106.624 198.068C104.668 200.4 102.933 202.91 101.444 205.566C100.706 206.821 100.002 208.089 99.3428 209.311C96.8504 213.958 95.0561 217.984 95.0235 218.056L87.5724 227.75L87.6768 227.926L89.3145 230.73L95.3171 241.012L96.2697 242.646L98.7034 241.345C101.54 239.829 103.777 237.39 105.045 234.43L105.913 231.109L106.233 230.73L114.532 220.795L116.907 217.945L116.796 209.311Z" fill="#F45753" />
        <path d="M145.233 166.08C146.345 159.22 141.695 152.757 134.847 151.644C128 150.531 121.548 155.189 120.437 162.049C119.326 168.908 123.976 175.372 130.823 176.485C137.671 177.598 144.122 172.94 145.233 166.08Z" fill="#9F616A" />
        <path d="M120.694 155.816C120.135 153.858 119.019 152.181 119.577 152.181C120.135 152.181 120.415 150.783 120.415 150.783C120.415 150.783 120.973 150.503 121.252 150.783C121.531 151.062 121.81 148.825 121.81 148.825C121.81 148.825 122.089 147.986 122.368 148.546C122.647 149.105 122.647 148.825 122.927 147.986C123.206 147.148 124.601 146.588 124.601 146.588C124.601 146.588 129.905 146.868 130.742 145.749C131.579 144.631 141.069 145.47 143.023 146.868C144.977 148.266 152.675 150.766 148.107 158.609C150.717 163.184 146.821 171.345 145.814 172.313C144.222 173.844 143.12 174.396 135.587 175.898C134.113 176.192 130.491 163.838 130.491 163.838C130.491 163.838 121.252 157.773 120.694 155.816Z" fill="#2F2E41" />
        <path d="M126.25 388.366L125.846 388.202L118.577 385.235L117.462 384.784L112.751 388.366H112.744C110.083 388.369 107.531 389.43 105.65 391.316C103.768 393.202 102.711 395.759 102.709 398.425V398.752H128.501V388.366H126.25Z" fill="#2F2E41" />
        <path d="M202.059 399.203C202.06 399.305 202.04 399.407 202.001 399.502C201.962 399.597 201.905 399.683 201.833 399.756C201.761 399.829 201.675 399.887 201.581 399.926C201.486 399.966 201.385 399.987 201.283 399.987H68.6381C68.4313 399.987 68.233 399.905 68.0868 399.758C67.9405 399.612 67.8584 399.413 67.8584 399.206C67.8584 398.999 67.9405 398.8 68.0868 398.654C68.233 398.507 68.4313 398.425 68.6381 398.425H201.283C201.489 398.425 201.686 398.507 201.831 398.653C201.977 398.799 202.059 398.996 202.059 399.203Z" fill="#CCCCCC" />
        <path d="M65.1149 0C64.0884 18.28 57.3809 35.7807 45.9317 50.0517C34.4825 64.3226 18.8648 74.6492 1.2654 79.5856C1.24583 80.0431 1.22625 80.5007 1.21973 80.9582C19.1837 76.0075 35.1416 65.5293 46.8404 51.003C58.5393 36.4766 65.3881 18.636 66.4198 0H65.1149Z" fill="white" />
        <path d="M45.1301 0C42.098 11.5409 36.753 22.3429 29.4205 31.7483C22.088 41.1536 12.9214 48.9656 2.47891 54.7086C2.43976 55.2249 2.41367 55.7348 2.38757 56.2511C13.2099 50.4252 22.7132 42.421 30.3011 32.7409C37.8889 23.0609 43.3973 11.914 46.4807 0H45.1301Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_1_2906">
          <rect width="264.865" height="400" fill="white" transform="translate(0.567627)" />
        </clipPath>
      </defs>
    </svg>

  );
};

export default TermsImg;
