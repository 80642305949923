import React, {Component} from "react";
import {connect} from "react-redux";
import {
  getDealByID,
  getStaff,
  unassignStaff,
  unassignTeamMember,
  setStuff,
  setStage,
  getComments,
  createComments,
  getMessages,
  createMessages,
  updateDealClientData,
  getTemplates,
  setStuffStatus,
} from "api";
import {fetchDealById} from "redux/actions/deals";
import DealChat from "../DealDetails/DealChat";
import DealDocuments from "../DealDocuments/DealDocuments";
import DealLenderCard from "../DealLenderCard/DealLenderCard";
import {withSnackbar} from "utils/withSnackbar";
import {withMixpanel} from "utils/MixpanelContext";
import {withUploadedDocs} from "context/UploadDocumentsProvider";
import {getStaffRolesTC} from "redux/reducers/configReducer/staffCardReducer";
import {
  getAssignTeamValuesTC,
  getDealInfoByIdTC,
  setCurrentDealDealTypeAC,
} from "redux/reducers/dealsReducer";
import DealDetailsCardsSectionV2 from "../DealDetailsCardsSectionV2";
import DeededCircularLoader from "v2/components/DeededCircularLoader";
import Box from "@mui/material/Box";
import { CommentSection } from "../DealDetails/CommentSection";
import DetailsTabNavigation from "../DetailsTabNavigation/DetailsTabNavigation";
import configuration from "utils/configuration";
import { isAdmin, shouldShowDealMessages, shouldShowLenderInfo } from "utils/permissionRoles";
import DeededTeam from "../DealDetails/components/DeededTeam";
import AssignMemberPopup from "../DealDetails/AssignMemberPopup";
import AssignStaffPopup from "../DealDetails/AssignStaffPopup";
import AssignDeededStaff from "../DealDetails/AssignStaff";
import DealHeader from "../DealHeader/DealHeader";
import Colors from "components/CommonDashboard/Styling/Colors";
import { Overline } from "components/CommonDashboard/Typography";

const professionals = [
  "Realtor",
  "Real Estate/Mortgage Team",
  "Broker",
  "Lender",
];
class DealDetails extends Component {
  state = {
    isNotifyComment: true,
    sender: null,
    dealCardsSectionId: 0,
    deal: {},
    staff: [],
    selectedStaffRole: "",
    notificationList: [],
    clientsList: [
      "Admin",
      "System Admin",
      "Lawyer",
      "Law Clerk",
      "Mobile Signer",
      "Closing Advisor",
    ],
    professionalsList: [
      "Realtor",
      "Real Estate/Mortgage Team",
      "Broker",
      "Lender",
    ],
    members: [],
    subjectline: "",
    messages: [],
    messageText: "",
    comments: [],
    commentText: "",
    update_member_data: {},
    role: {label: "", value: ""},
    assignTeam: false,
    assignStaff: false,
    dealConfirm: false,
    dealFormKey: "default",
    appointmentDate: new Date(),
    appointmentTime: new Date(),
    appointmentErrors: {},
    propertyAddress: "",
    appointmentAddress: "",
    loading: false,
    btnLoading: false,
    loadingComments: false,
    templatesList: [],
    stageTemplatesList: [],
    intakeTemplate: undefined,
    prePopulateTemplate: null,
    current_stage: null,
    stageTemplate: {
      subject: "",
      content: "",
      label: "",
      value: "",
    },
    templateUser: null,
    cc: null,
    CCList: [
      {label: "Admin", value: "Admin"},
      {label: "System Admin", value: "system Admin"},
      {label: "Client", value: "Client"},
      {label: "Secondary Client", value: "Secondary Client"},
      {label: "Realtor", value: "Realtor"},
      {
        label: "Real Estate/Mortgage Team",
        value: "Real Estate/Mortgage Team",
      },
      {label: "Broker", value: "Broker"},
      {label: "Lender", value: "Lender"},
      {label: "Lawyer", value: "Lawyer"},
      {label: "Law Clerk", value: "Law Clerk"},
      {label: "Mobile Signer", value: "Mobile Signer"},
      {label: "Closing Advisor", value: "Closing Advisor"},
    ],
    BCCList: [
      {label: "None", value: null},
      {label: "Admin", value: "Admin"},
      {label: "System Admin", value: "System Admin"},
      {label: "Client", value: "Client"},
      {label: "Secondary Client", value: "Secondary Client"},
      {label: "Realtor", value: "Realtor"},
      {
        label: "Real Estate/Mortgage Team",
        value: "Real Estate/Mortgage Team",
      },
      {label: "Broker", value: "Broker"},
      {label: "Lender", value: "Lender"},
      {label: "Lawyer", value: "Lawyer"},
      {label: "Law Clerk", value: "Law Clerk"},
      {label: "Mobile Signer", value: "Mobile Signer"},
      {label: "Closing Advisor", value: "Closing Advisor"},
    ],
    html_template_id: null,
  };
  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
    this.props.getStaffRolesTC();
    if (!this.props.user.id) return;
    const {id} = this.props.match.params;
    let promises = [getDealByID(id), getStaff()];
    Promise.all(promises)
      .then((res) => {
        if (!this._isMounted) return;
        let deal = res[0],
          staff = res[1];
        const isUserProfessional = professionals.find(
          (el) => el === this.props.user.role,
        );
        if (isUserProfessional) {
          const {role, email, full_name} = this.props.user;
          const {
            data: {id, deal_type},
          } = deal;
          const mixPayload = {
            role,
            email,
            full_name,
            deal_type,
            deal_id: id,
          };
          this.props.mixpanel.track(`${role}_open_deal`, mixPayload);
        }

        this.setState({
          propertyAddress: deal.data.address.address,
          appointmentDate:
            deal.data.appointment === null
              ? null
              : new Date(deal.data.appointment["iso_datetime"]),

          appointmentTime:
            deal.data.appointment === null
              ? null
              : new Date(deal.data.appointment["iso_datetime"]),
          appointmentAddress:
            deal.data.appointment === null
              ? ""
              : deal.data.appointment["address"],
          currentUser: this.props.user,
          notificationList: [
            ...(() => {
              const map = new Map();
              deal.data.participants.forEach((item) => {
                map.set(item.role, item);
              });
              return Array.from(map.values()).map((el) => ({
                ...el,
                label: el.role,
                value: el.role,
                title: el.role,
              }));
            })(),
            {
              ...deal.data.referringProfessional,
              label: "Referring Professional",
              value: "Referring Professional",
            },
          ],

          deal: deal.data,
          closingDate: new Date(
            deal.data.closing_date + "T00:00:00",
          ).toLocaleDateString("en-US", {timeZone: "America/Toronto"}),
          mobileSignerData: {
            old: null,
            new:
              deal.data.staff.find((el) => el.role === "Mobile Signer")?.id ??
              null,
            current:
              deal.data.staff.find((el) => el.role === "Mobile Signer")?.id ??
              null,
          },
          staff: deal.data.staff.map((el) => ({
            ...el,
            label: `${el.first_name} ${el.middle_name} ${el.last_name}`,
            value: el.id,
          })),
          staffByRole: staff.data,
        });
        this.handleTemplatesList(deal.data.deal_type);
        this.parseDealData(deal.data);
        this.handleStaffByRole(staff.data);
        const isCurrUserClient = this.state.clientsList.find(
          (el) => el === this.props.user.role,
        );
        if (!isCurrUserClient) {
          return;
        } else {
          this.handleGetMessages();
          this.handleGetComments();
        }
      })
      .catch((error) => {
        this.props.enqueueSnackbar(error.response?.data?.error ?? "", {
          variant: "error",
          autoHideDuration: configuration.autoHideErrorDuration,
        });
      });
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.deal.deal_type) {
      this.props.setCurrentDealDealTypeAC(this.state.deal.deal_type);
    }
  }

  isCompanyAdmin() {
    return this.props.user.role === "Company Admin";
  }

  handleTemplatesList = (dealType) => {
    getTemplates(dealType)
      .then((res) => {
        if (!this._isMounted) return;
        this.setState({
          templatesList: res.data.map((el) => {
            return {
              ...el,
              label: el.template_name,
              value: `${el.id}) ` + el.template_name,
            };
          }),
        });
      })
      .catch((error) =>
        this.props.enqueueSnackbar(error.response.data.error, {
          variant: "error",
          autoHideDuration: configuration.autoHideErrorDuration,
        }),
      );
  };

  createNewStaffCard = (staffRole) => {
    const { staff } = this.state;
    this.setState({
        staff: [
            ...staff,
            {
                role: staffRole.value,
                is_hidden: staffRole.value === "Access" ? 1 : 0,
            },
        ],
        assignStaff: false,
    });
  };

  handleGetComments = () => {
    const {id} = this.state.deal;
    if (!this._isMounted) return;
    if (id) {
      this.setState({loadingComments: true});
      getComments(id)
        .then((res) => {
          this.setState({
            comments: res.data.sort((A, B) =>
              new Date(A.created_at).getTime() >
              new Date(B.created_at).getTime()
                ? 1
                : -1,
            ),
            loadingComments: false
          });
        })
        .catch((error) => {
          this.setState({loadingComments: false});
          this.props.enqueueSnackbar(error.response.data.error, {
            variant: "error",
            autoHideDuration: configuration.autoHideErrorDuration,
          })
        });
    }
  };

  handleGetMessages = () => {
    if (!this._isMounted) return;
    const {id} = this.state.deal;
    if (id) {
      getMessages(id)
        .then((res) => {
          this.setState({
            messages: res.data.sort((A, B) =>
              new Date(A.created_at).getTime() >
              new Date(B.created_at).getTime()
                ? -1
                : 1,
            ),
          });
        })
        .catch((error) =>
          this.props.enqueueSnackbar(error.response.data.error, {
            variant: "error",
            autoHideDuration: configuration.autoHideErrorDuration,
          }),
        );
    }
  };
  handleStaffByRole = (staffByRole) => {
    this.setState({
      Lawyers:
        staffByRole["Lawyer"] !== null
          ? staffByRole["Lawyer"]?.map((person) => {
              return {
                ...person,
                value: `${person.first_name} ${person.middle_name} ${person.last_name}`,
                label: `${person.first_name} ${person.middle_name} ${person.last_name}`,
              };
            })
          : [{label: "No value", value: "No value"}],
      LawClerks:
        staffByRole["Law Clerk"] !== null
          ? staffByRole["Law Clerk"]?.map((person) => {
              return {
                ...person,
                value: `${person.first_name} ${person.middle_name} ${person.last_name}`,
                label: `${person.first_name} ${person.middle_name} ${person.last_name}`,
              };
            })
          : [{label: "No value", value: "No value"}],
      MobileSigners:
        staffByRole["Mobile Signer"] !== null
          ? staffByRole["Mobile Signer"]?.map((person) => {
              return {
                ...person,
                value: `${person.first_name} ${person.middle_name} ${person.last_name}`,
                label: `${person.first_name} ${person.middle_name} ${person.last_name}`,
              };
            })
          : [{label: "No value", value: "No value"}],
      ClosingAdvisors:
        staffByRole["Closing Advisor"] !== null
          ? staffByRole["Closing Advisor"]?.map((person) => {
              return {
                ...person,
                value: `${person.first_name} ${person.middle_name} ${person.last_name}`,
                label: `${person.first_name} ${person.middle_name} ${person.last_name}`,
              };
            })
          : [{label: "No value", value: "No value"}],
    });
  };
  parseDealData = (deal) => {
    let members = [];
    for (let key of Object.entries(deal)) {
      let temp = {};

      if (key[0] === "referringProfessional" && key[1] !== null) {
        temp = Object.assign({title: "Referring Professional"}, key[1]);
        members.push(temp);
      }
    }
    this.setState({members});
  };

  getClientFromDeal = (role, participants) => {
    return participants.find((el) => el.role === role);
  };

  handleAssignMember = async (e) => {
    const {deal: {id},} = this.state;
    try {
      this.setState({assignTeam: !this.state.assignTeam});
      const deal = await getDealByID(id);
      this.props.getDealInfoByIdTC(id);
      this.setState({
        dealCardsSectionId: this.state.dealCardsSectionId + 1,
        deal: deal.data,
        notificationList: [
          ...(() => {
            const map = new Map();
            deal.data.participants.forEach((item) => {
              map.set(item.role, item);
            });
            return Array.from(map.values()).map((el) => ({
              ...el,
              label: el.role,
              value: el.role,
              title: el.role,
            }));
          })(),
          {
            ...deal.data.referringProfessional,
            label: "Referring Professional",
            value: "Referring Professional",
          },
        ],
      });
    } catch (error) {
      this.props.enqueueSnackbar(error.response.data.error, {
        variant: "error",
        autoHideDuration: configuration.autoHideErrorDuration,
      });
      this.setState({assignTeam: true});
    }
  };

  handleStuffAssign = (e, oldId = null, i) => {
    const {id} = this.state.deal;

    setStuff(id, {
      old: oldId,
      new: e.id,
    })
      .then((res) => {
        const newStaff = [...this.state.staff];

        const updatedStaffMember = res.data.staff.find((el) => el.id === e.id);

        if (newStaff[i]) {
          newStaff[i] = updatedStaffMember;
        } else {
          newStaff.push(updatedStaffMember);
        }

        this.setState({staff: newStaff});
      })
      .catch((error) =>
        this.props.enqueueSnackbar(error.response.data.error, {
          variant: "error",
          autoHideDuration: configuration.autoHideErrorDuration,
        }),
      );
  };
  handleTextField = (textValue, title) => {
    if (title === "Comments") {
      this.setState({commentText: textValue});
    }
    if (title === "Messages") {
      this.setState({messageText: textValue});
    }
  };
  handleSubjectField = (val) => {
    this.setState({subjectline: val});
  };
  handleTemplatePopulate = (template) => {
    const {subject, content, html_template_id} = template;
    this.setState({
      messageText: content,
      subjectline: subject,
      html_template_id,
    });
  };
  handleCommentsSubmit = (message, sendNotification) => {
    const {
      deal: {id},
    } = this.state;

    this.props.enqueueSnackbar("Saving new comment", {
      variant: "success",
      autoHideDuration: 1500,
    });
    createComments(id, message, sendNotification)
      .then(() => {
        getDealByID(id).then((res) => {
          this.handleGetComments();
        });
      })
      .catch((error) =>
        this.props.enqueueSnackbar(error.response.data.error, {
          variant: "error",
          autoHideDuration: configuration.autoHideErrorDuration,
        })
      );
  };
  handleMessagesSubmit = (e) => {
    e.preventDefault();
    const {
      subjectline,
      messageText,
      recipient,
      cc,
      deal: {id},
      html_template_id,
      sender,
    } = this.state;

    if (messageText.length === 0) return;
    this.setState({btnLoading: true});
    this.props.enqueueSnackbar("Sending new message", {
      variant: "success",

      autoHideDuration: 1500,
    });
    let payload = {
      subject: subjectline,
      content: messageText,
      recipient: recipient || ["Client"],
      cc,
      html_template_id,
      sender,
    };

    createMessages(id, payload)
      .then(() => {
        getDealByID(id).then((res) => {
          this.setState({
            deal: res.data,
            subjectline: "",
            messageText: "",
            recipient: "",
            btnLoading: false,
            dealFormKey: `${Math.random()}`,
            html_template_id: null,
          });
          this.handleGetMessages();
        });
      })
      .catch((error) =>
        this.setState(
          {
            btnLoading: false,
          },
          () => {
            this.props.enqueueSnackbar(error.response.data.error, {
              variant: "error",
              autoHideDuration: configuration.autoHideErrorDuration,
            });
          },
        ),
      );
  };
  handleCommunication = (e) => {
    this.setState({recipient: e === null ? [] : [...e].map((o) => o.id)});
  };

  handleUnassignTeamMember = (memeberID) => {
    const {id} = this.state.deal;
    unassignTeamMember(id, memeberID.id)
      .then((res) => {
        this.props.enqueueSnackbar("Unassigning team member", {
          variant: "success",
          autoHideDuration: 1500,
        });
        this.setState({
          dealCardsSectionId: this.state.dealCardsSectionId + 1,
          deal: res.data,
        });
        this.props.getDealInfoByIdTC(id);
      })
      .catch((error) =>
        this.props.enqueueSnackbar(error.response.data.error, {
          variant: "error",
          autoHideDuration: configuration.autoHideErrorDuration,
        }),
      );
  };
  handleTemplateSender = (template) => {
    const {value} = template;
    this.setState({
      sender: value,
    });
  };
  handleStuffUnassign = (stuffID) => {
    const {id} = this.state.deal;
    if (stuffID === "" || stuffID === undefined) {
      getDealByID(id)
        .then((res) => {
          this.setState((prevState) => ({
            ...prevState,
            staff: res.data.staff,
          }));
        })
        .catch((error) =>
          this.props.enqueueSnackbar(error.response.data.error, {
            variant: "error",
            autoHideDuration: configuration.autoHideErrorDuration,
          }),
        );
      return;
    } else {
      unassignStaff(id, stuffID)
        .then((res) => {
          this.props.enqueueSnackbar("Unassigning staff member", {
            variant: "success",
            autoHideDuration: 1500,
          });
          this.setState((prevState) => ({
            ...prevState,
            staff: res.data.staff,
          }));
        })
        .catch((error) =>
          this.props.enqueueSnackbar(error.response.data.error, {
            variant: "error",
            autoHideDuration: configuration.autoHideErrorDuration,
          }),
        );
    }
  };
  handleCCField = (val) => {
    this.setState({cc: val});
  };
  handleLenderData = (lenderData) => {
    const {lender} = lenderData;
    const client1 =
      this.getClientFromDeal("Client", this.state.deal.participants || []) ||
      {};
    const client2 =
      this.getClientFromDeal(
        "Secondary Client",
        this.state.deal.participants || [],
      ) || {};
    const {
      closingDate,
      deal: {deal_type, id},
    } = this.state;
    const {address} = this.state.deal;
    const payload = {
      deal_type,
      closing_date: closingDate,
      address,
      client: {
        first_name: client1.first_name,
        middle_name: client1.middle_name,
        last_name: client1.last_name,
        email: client1.email,
        phone: client1.phone,
      },
      secondary_client: {
        first_name: client2.first_name || "",
        middle_name: client2.middle_name || "",
        last_name: client2.last_name || "",
        email: client2.email || "",
        phone: client2.phone || "",
      },
      lender,
    };

    updateDealClientData(id, payload)
      .then(() => {
        this.props.enqueueSnackbar("Save lender data", {
          variant: "success",
          autoHideDuration: 1500,
        });
        getDealByID(id).then((res) => {
          this.setState({deal: res.data});
        });
      })
      .catch((error) =>
        this.props.enqueueSnackbar(error.response.data.error, {
          variant: "error",
          autoHideDuration: configuration.autoHideErrorDuration,
        }),
      );
  };

  handleChangeStuffStatus = (user) => {
    setStuffStatus(user.deal_id, user.user_id, {
      status: Number(!user.is_hidden),
    })
      .then(({data}) => {
        const newStaff = [...this.state.staff];

        const updatedStaffIndex = newStaff.findIndex(
          (el) => el.id === user.user_id,
        );

        if (newStaff[updatedStaffIndex]) {
          newStaff[updatedStaffIndex].deal_user.is_hidden = Number(
            !user.is_hidden,
          );
        }

        this.setState({staff: newStaff});
      })
      .catch((error) => console.log(error));
  };

  refreshDeal = async () => {
    try {
      const res = await getDealByID(this.state.deal.id);
        this.setState({deal: res.data});
    } catch (e) {
      this.props.enqueueSnackbar("Failed to refresh deal", {
        variant: "error",
        autoHideDuration: configuration.autoHideErrorDuration,
      });
    }
  }

  render() {
    let {
      assignTeam,
      assignStaff,
      clientsList,
      professionalsList,
      comments,
      messages,
      messageText,
      subjectline,
      deal,
      staff,
      btnLoading,
      loadingComments,
    } = this.state;
    const isCurrUserClient = clientsList.find(
      (el) => el === this.props.user.role,
    );
    const isCurrUserProfessional = professionalsList.find(
      (el) => el === this.props.user.role,
    );
    const isILA = deal?.deal_type_tag?.tag === 'ILA';
    return (
      <div>
        <div className="dealdetails__wrapper">
          {assignTeam && (
            <AssignMemberPopup
              assignTeam={assignTeam}
              handleAssignMember={this.handleAssignMember}
              setOpenModal={() => this.setState({ assignTeam: !assignTeam })}
              dealId={deal.id}
            />
          )}

          {assignStaff && (
            <AssignStaffPopup
                assignStaff={assignStaff}
                setOpenModal={() => this.setState({assignStaff: !assignStaff})}
                createNewStaffCard={(staffRole) => this.createNewStaffCard(staffRole)}
          />)}
          {deal.address && <DealHeader deal={deal} onDealRefresh={this.refreshDeal} navigation={
          <DetailsTabNavigation
            hideAllTabs={
              this.props.user.role === "Client" ||
              isCurrUserProfessional ||
              this.isCompanyAdmin()
            }
            showLogTab={
              this.props.user.role === "Admin" ||
              this.props.user.role === "System Admin"
            }
            showSurveyTab={
              this.props.user.role === "Admin" ||
              this.props.user.role === "System Admin"
            }
            intakeTabLabel={"Client Intake"}
            showOnlyNavigation={true}
          />}/>}
          {Object.keys(deal).length > 0 ? (
            <>
            <Box height='3.2rem' backgroundColor={Colors.PRIMARY_50} />
            {this.state?.deal?.cancel_description && (
                <Overline data-testid="deal-details-cancellation-reason-value" p='5px' sx={{
                    margin: "2rem 2rem 0",
                    border: `2px solid ${Colors.PRIMARY_600}`,
                    borderRadius: "5px",
                    backgroundColor: Colors.PRIMARY_50,
                    textTransform: 'none'
                }}>
                Cancellation reason: {this.state?.deal?.cancel_description}
                </Overline>
            )}
            <div className="dealdetails__container">
              <DealDetailsCardsSectionV2
                handleUnassignTeamMember={this.handleUnassignTeamMember}
                deal={deal}
              />

              {isAdmin(this.props.user?.role) && (
                <button
                  className="assignstaff__btn-red"
                  onClick={() => this.setState({assignTeam: !assignTeam})}
                >
                  assign team
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="20px"
                    height="20px"
                  >
                    <path
                      fillRule="evenodd"
                      d="M 11 2 L 11 11 L 2 11 L 2 13 L 11 13 L 11 22 L 13 22 L 13 13 L 22 13 L 22 11 L 13 11 L 13 2 Z"
                    />
                  </svg>
                </button>
              )}
              {this.props.user && (this.props.user.role === "Admin" || this.props.user.role === "System Admin") && 
                <AssignDeededStaff 
                    staff={staff}
                    handleChangeStuffStatus={(id) => this.handleChangeStuffStatus(id)}
                    handleStuffUnassign={this.handleStuffUnassign}
                    handleStuffAssign={(e, id, i) => this.handleStuffAssign(e, id, i)}
                    user={this.props.user}
                    openModal={(e) => this.setState({assignStaff: !assignStaff})}
                    staffByRole={this.state.staffByRole || []}
                />}
              {/* DEAL CLIENT TEAM */}
              {!isCurrUserClient && (deal.staff || []).length > 0 &&
                <DeededTeam staff={deal.staff} user={this.props.user} deal={deal} />
              }
              {/* DEAL DOCUMENTS */}
              {!isILA && <div className="dealdetails__documents">
                  {this.props.loading && (
                    <div className="loading__wrapper">
                      <div className="loading__title">
                        <h2>please wait</h2>
                        <h2>Uploading document</h2>
                        <div className="lds-ring">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </div>
                    </div>
                  )}
                  <DealDocuments
                    dealID={deal.id}
                    dealType={deal.deal_type}
                    dealProvince={deal?.address?.state}
                  />
                </div>
              }

              {/* DEAL LENDER */}
              {!isILA && shouldShowLenderInfo(this.props.user.role || "") && (
                <DealLenderCard
                  deal={deal}
                  user={this.props.user}
                  handleLenderData={this.handleLenderData}
                />
              )}

              {/* DEAL MESSAGES AND COMMENTS*/}
              <CommentSection comments={comments} submitComment={this.handleCommentsSubmit} isLoading={loadingComments} />
 
              {shouldShowDealMessages(this.props.user.role) &&
                <div>
                  <DealChat
                    staff={staff}
                    data={messages}
                    loading={btnLoading}
                    text={messageText}
                    subjectline={subjectline}
                    key={this.state.dealFormKey}
                    clientsList={this.state.notificationList}
                    templatesList={this.state.templatesList}
                    handleSubjectField={this.handleSubjectField}
                    handleTemplatePopulate={this.handleTemplatePopulate}
                    handleTextFieldSubmit={this.handleMessagesSubmit}
                    handleTextField={this.handleTextField}
                    handleCommunication={this.handleCommunication}
                    handleTemplateSender={this.handleTemplateSender}
                    handleCCField={this.handleCCField}
                    CCList={this.state.CCList}
                  />
                </div>}
            </div>
            </>
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "calc(100vh - 225px)",
              }}
            >
              <DeededCircularLoader />
            </Box>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dealByID: state.DealsReducer.dealByID,
    dealsLoading: state.DealsReducer.dealsLoading,
    user: state.AuthReducer.currentUser,
    roles: state.ConfigReducer.StaffCardReducer.roles,
    assignTeamValues: state.DealsReducer.assignTeamValues,
    passStage: state.DealsReducer.passStage,
    currentDealType: state.DealsReducer.currentDealDealType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchDealById: (dealID) => dispatch(fetchDealById(dealID)),
    onSetStage: (dealID, stageID) => dispatch(setStage(dealID, stageID)),
    getStaffRolesTC: () => dispatch(getStaffRolesTC()),
    getAssignTeamValuesTC: (field, searchValue) =>
      dispatch(getAssignTeamValuesTC(field, searchValue)),
    getDealInfoByIdTC: (id) => dispatch(getDealInfoByIdTC(id)),
    setCurrentDealDealTypeAC: (dealType) =>
      dispatch(setCurrentDealDealTypeAC(dealType)),
  };
};
export default withUploadedDocs(
  withMixpanel(
    withSnackbar(connect(mapStateToProps, mapDispatchToProps)(DealDetails)),
  ),
);
