import {
    Checkbox as MUICheckbox,
    FormControlLabel,
    FormControlLabelProps,
    styled,
    SxProps,
    Theme,
} from "@mui/material";
import constants from "styles/constants";
import { FC } from "react";
import { Box, Selected } from "components/Svg/Icons";

interface CheckboxProps {
    checked?: boolean;
    checkedIcon?: JSX.Element;
    color?: string;
    disabled?: boolean;
    label?: string;
    sx?: SxProps<Theme>;
}

const Checkbox: FC<
    | CheckboxProps
    | (FormControlLabelProps & { checkedIcon?: JSX.Element; color?: string })
> = ({ checked, checkedIcon, color, label, disabled, sx, ...props }) => (
    <StyledFormControlLabel
        label={label ?? ""}
        disabled={disabled}
        sx={sx}
        control={
            <StyledCheckbox
                checked={checked}
                disabled={disabled}
                checkedIcon={checkedIcon || <Selected color={color || constants.colors.black3} size="20px" />}
                icon={<Box color={color || constants.colors.black3} size="20px" />}
            />
        }
        {...props}
    />
);

const StyledFormControlLabel = styled(FormControlLabel)({
    flexDirection: 'row',
    marginRight: 0,
    marginLeft: 0,
    "& .MuiTypography-root": {
        fontSize: "18px",

    },
});

const StyledCheckbox = styled(MUICheckbox)({
    color: constants.colors.black3,
    '&.Mui-checked': {
        color: constants.colors.black,
    },
    "&.Mui-disabled": {
        color: constants.colors.gray100,
        '& svg path': {
            fill: constants.colors.gray100
        }
    },
});

export default Checkbox;