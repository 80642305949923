import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import React from "react";
import configuration from "./configuration";
import { Large, MainTitle } from 'components/Common/Typography';
import { Stack } from '@mui/material';
import { Wrapper } from 'components/Common/Wrapper/Wrapper';
import { version } from '../../package.json';
import storage from './storage';

if (configuration.bugSnag.apiKey) {
  Bugsnag.start({
    apiKey: configuration.bugSnag.apiKey,
    plugins: [new BugsnagPluginReact()],
    appVersion: version,
    releaseStage: configuration.envName,
    onError: function (event) {
      event.setUser(storage.getUserId());
    }
  })
}
const ErrorView = ({ error, info }) => {
  return (<>
    <Wrapper>
      <Stack gap={'3rem'} alignItems={'center'}>
        <MainTitle>Oops...</MainTitle>
        <Large>
          There's been an error in the component tree. Check the logs for additional information.
        </Large>
      </Stack>
    </Wrapper>
  </>
  );
};
export const ErrorBoundaryBugSnag = ({ children }) => {
  const ErrorBoundary = Bugsnag.isStarted() ?
    Bugsnag.getPlugin('react')?.createErrorBoundary(React) :
    null;
  return ErrorBoundary ? (
    <ErrorBoundary FallbackComponent={ErrorView}>
      {children}
    </ErrorBoundary>
  ) : (
    <>{children}</>
  );
}

export const notifyError = (error, onError = undefined, postReportCallback = undefined) => {
  if (Bugsnag.isStarted()) {
    Bugsnag.notify(error, onError, postReportCallback);
  }
}