import React, {useEffect, useMemo, useState} from "react";

import {Box, useTheme} from "@mui/material";
import constants from "styles/constants";
import {
  useGetFileTasksListQuery,
  useSetDefaultTasksListByTaskTypeMutation,
} from "redux/reducers/dealInfoApi";
import {useRouteMatch} from "react-router-dom";
import DealDetailsFileTasksPage from "components/Dashboard/DealDetailsFileTasks/components/DealDetailsFileTasksPage";

import {DealDetailsFileTasksContext} from "components/Dashboard/DealDetailsFileTasks/context/DealDetailsFileTasksContext";
import useTypedSelector from "utils/hooks/useTypedSelector";
import {setFileTasks} from "redux/reducers/fileTasksSlice";
import {useDispatch} from "react-redux";
import DetailsTabNavigation from "components/Dashboard/DetailsTabNavigation/DetailsTabNavigation";
import useUser from "utils/hooks/useUser";

const DealDetailsFileTasks = () => {
  const [isOpenedFileTaskPopup, setIsOpenedFileTaskPopup] = useState(false);
  const [fileTypeValueToSet, setFileTypeValueToSet] = useState("");
  const fileTasks = useTypedSelector(
    (state) => state.FileTasksReducer.fileTasks,
  );
  const user = useUser().user;
  const [selectedFileType, setSelectedFileType] = useState(
    fileTasks?.["1"]?.[0].type ?? "",
  );
  const theme = useTheme();
  const match = useRouteMatch() as unknown as {params: {id: number}};
  const dealId = useMemo(() => {
    return match?.params?.id;
  }, [match]);
  const dispatch = useDispatch();
  const [setDefaultTasksListByTaskTypeCallback] =
    useSetDefaultTasksListByTaskTypeMutation();
  const {refetch: getFileTasksDataRefetch} = useGetFileTasksListQuery({
    dealId,
    taskType: selectedFileType,
    setDefaultDataCallback: setDefaultTasksListByTaskTypeCallback,
  });
  useEffect(() => {
    return () => {
      dispatch(setFileTasks([]));
    };
  }, [dispatch]);
  useEffect(() => {
    if (fileTasks?.["1"]?.[0].type) {
      setSelectedFileType(fileTasks?.["1"]?.[0].type);
    }
  }, [fileTasks]);
  useEffect(() => {
    getFileTasksDataRefetch();
  }, [getFileTasksDataRefetch]);

  return (
    <>
      <DealDetailsFileTasksContext.Provider
        value={{
          dealId,
          fileTypeValueToSet,
          setFileTypeValueToSet,
          isOpenedFileTaskPopup,
          setIsOpenedFileTaskPopup,
          selectedFileType,
          setSelectedFileType,
        }}
      >
        <Box
          sx={{
            backgroundColor: constants.colors.bg,
            overflowY: "scroll",
            height: "calc(100vh)",
            "&::-webkit-scrollbar": {
              width: "5px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: constants.colors.grayBorder,
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "transparent",
            },
            [theme.breakpoints.down(814)]: {
              paddingBottom: "60px",
            },
          }}
        >
          <DetailsTabNavigation
            showDocumentsTab={user.role !== "Company Admin"}
            showTasksTab
          />
          <DealDetailsFileTasksPage />
        </Box>
      </DealDetailsFileTasksContext.Provider>
    </>
  );
};

export default DealDetailsFileTasks;
