import { useEffect, useState } from 'react';
import { Stack, styled } from '@mui/material';
import constants from 'styles/constants';
import { H4, Small } from 'components/Common/Typography';
import BasicButton from 'components/Common/Button/BasicButton';
import confirguration from '../../../utils/configuration';
import { PAPER_PROPS } from 'components/Common/componentStyling/Styles';

const StyledCookiePopup = styled('div')({
    ...PAPER_PROPS,
    position: 'fixed',
    bottom: '1rem',
    right: '1rem',
    maxWidth: '500px',
    width: 'calc(100% - 2rem)',
    padding: '2.4rem',
    zIndex: 9999,
})

const StyledLink = styled('a')({
    color: constants.colors.gray600,
    fontSize: '1.2rem'
})

export default function CookiePopup() {
    const [cookieExists, setCookieExists] = useState(false);
    const setCookie = (name: string, value: string, days: number) => {
        const d = new Date();
        d.setTime(d.getTime() + (days * 24 * 60 * 60 * 1000));
        const expires = "expires=" + d.toUTCString();
        document.cookie = `${name}=${value}; ${expires}; SameSite=Strict;`;
    }

    const handleCookieAccept = () => {
        setCookie('cookie-consent', (new Date().toString()), confirguration.cookieDays)
        setCookieExists(document.cookie.indexOf('cookie-consent') === -1);
    }

    const handleCookieDecline = () => {
        window.location.href = 'https://deeded.ca';
    }

    useEffect(() => {
        setCookieExists(document.cookie.indexOf('cookie-consent') === -1);
    }, [cookieExists, setCookieExists]);

    return cookieExists ? <StyledCookiePopup>
        <Stack>
            <H4 style={{ paddingBottom: '2.4rem', textAlign: 'center' }}>Manage Cookie Consent</H4>
            <Small style={{ paddingBottom: '2.4rem' }}>
                To provide the best experiences, we use technologies like cookies to
                store and/or access device information. Consenting to these
                technologies will allow us to process data such as browsing behavior
                or unique IDs on this site. Not consenting or withdrawing consent,
                may adversely affect certain features and functions.
            </Small>
            <Stack direction='row' justifyContent={'space-between'} style={{ paddingBottom: '1.2rem' }}>
                <BasicButton
                    typeOf='CTA'
                    label={{ text: 'Accept', inputId: 'submit' }}
                    onClick={handleCookieAccept}
                    testId="manage-cookies-accept-btn"
                />
                <BasicButton
                    typeOf='dismissive'
                    label={{ text: 'Deny', inputId: 'cancel' }}
                    onClick={handleCookieDecline}
                    testId="manage-cookies-decline-btn"
                />
            </Stack>
            <Stack direction='row' justifyContent={'center'}>
                <StyledLink href="https://deeded.ca/cookie-policy-ca/" style={{ paddingRight: '2.4rem' }}>
                    Cookie Policy
                </StyledLink>
                <StyledLink href="https://deeded.ca/privacy-policy/">
                    Privacy Policy
                </StyledLink>
            </Stack>
        </Stack>
    </StyledCookiePopup> : null;
}