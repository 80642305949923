import React, {useEffect, useRef, useState} from "react";
import {
  Box,
  BoxProps,
  Grow,
  Paper,
  styled,
  SxProps,
  Theme,
} from "@mui/material";
import DeededInput from "./DeededInput";
import constants from "../../styles/constants";
import DeededSelectV2IconSvg from "../../components/Svg/DeededSelectV2IconSvg";
import CloseDeededSvg from "../../components/Svg/CloseDeededSvg";

interface DeededAutocompleteSelectProps {
  options: string[] | null | {id: number; name: string}[];
  value: string | null;
  onChangeValue: (value: string | {id: number; name: string}) => void;
  error?: string;
  placeholder?: string;
  iconMenuItem?: JSX.Element;
  disabled?: boolean;
  showListDefault?: boolean;
  onSelect?: (option: string) => void;
  straightawaySearch?: boolean;
  showCloseIcon?: boolean;
  onCloseIconClick?: () => void;
  sx?: SxProps<Theme>;
}
const DeededAutocompleteSelect: React.FC<
  DeededAutocompleteSelectProps & Omit<BoxProps, "variant">
> = ({
  options,
  value,
  onChangeValue,
  error,
  placeholder,
  iconMenuItem,
  disabled,
  showListDefault,
  onSelect,
  sx,
  showCloseIcon,
  onCloseIconClick,
  straightawaySearch,
  ...props
}) => {
  const [isOpenList, setIsOpenList] = useState(false);
  const [isClick, setIsClick] = useState(false);
  const [searchedOptions, setSearchedOptions] = useState(options);
  const inputRef = useRef(null);
  const [isFocus, setIsFocus] = useState(false);

  useEffect(() => {
    if (!straightawaySearch && value && value?.length < 3) {
      setSearchedOptions(options);
    } else {
      if (options && options.length > 0 && typeof options[0] === "string") {
        setSearchedOptions(
          options
            ? (options as string[]).filter((option) =>
                option?.toLowerCase().includes(value ? value.toLowerCase() : ""),
              )
            : [],
        );
      } else {
        setSearchedOptions(
          options
            ? (options as {id: number; name: string}[]).filter((option) =>
                option.name
                  ?.toLowerCase()
                  ?.includes(value ? value.toLowerCase() : ""),
              )
            : [],
        );
      }
    }
  }, [options, straightawaySearch, value]);

  const inputRefTyped = inputRef as unknown as {
    current: {blur: () => void; focus: () => void};
  };

  useEffect(() => {
    if (
      isFocus &&
      !isClick &&
      searchedOptions &&
      searchedOptions.length !== 0 &&
      (value || value === "") &&
      value?.length > (showListDefault ? -1 : 1)
    ) {
      setIsOpenList(true);
    } else {
      setIsOpenList(false);
    }
  }, [isClick, isFocus, searchedOptions, showListDefault, value]);

  useEffect(() => {
    if (isFocus && showListDefault) {
      setIsOpenList(true);
    } else if (showListDefault) {
      setIsOpenList(false);
    }
  }, [isFocus, showListDefault]);

  return (
    <DeededAutocompleteSelectBox
      data-selenium-test="DeededAutocompleteSelect"
      className={`${error ? "error" : ""} ${disabled ? "disabled" : ""}`}
      sx={sx}
      {...props}
    >
      <InputBox>
        <DeededInput
          error={error}
          inputRef={inputRef}
          autoComplete="off"
          onBlur={() => {
            setIsOpenList(false);
            setIsFocus(false);
          }}
          value={value as string | number | undefined}
          onChange={(e) => {
            onChangeValue(e.target.value);
            setIsClick(false);
          }}
          placeholder={placeholder}
          disabled={disabled}
          onFocus={() => {
            setIsFocus(true);
          }}
        />
        {!showCloseIcon && (
          <IconBox
            className={`${isOpenList ? "isOpenList" : ""} ${
              disabled ? "disabled" : ""
            } ${"svgBox"}`}
            onClick={() => {
              inputRefTyped.current.focus();
            }}
          >
            <DeededSelectV2IconSvg />
          </IconBox>
        )}
        {showCloseIcon && (
          <CrossIconBox
            onClick={() => {
              if (!disabled) {
                inputRefTyped.current.focus();
                setIsClick(false);
                if (onCloseIconClick) {
                  onCloseIconClick();
                }
              }
            }}
          >
            <CloseDeededSvg />
          </CrossIconBox>
        )}
      </InputBox>
      <Grow in={isOpenList}>
        <ListPaper
          elevation={5}
          style={{
            margin: 5,
            paddingTop: "5px",
            paddingBottom: "5px",
          }}
        >
          {searchedOptions?.map((option, index) => (
            <OptionButton
              onClick={() => {
                if (typeof option === "string") {
                  onChangeValue(option);
                  if (onSelect) {
                    onSelect(option);
                  }
                } else {
                  onChangeValue(option);
                }
                setIsClick(true);
                inputRefTyped.current.blur();
              }}
              key={index}
            >
              {iconMenuItem && iconMenuItem}{" "}
              {typeof option === "string" ? option : option.name}
            </OptionButton>
          ))}
        </ListPaper>
      </Grow>
    </DeededAutocompleteSelectBox>
  );
};
const CrossIconBox = styled(Box)({
  position: "absolute",
  right: "10px",
  top: "1px",
  cursor: "pointer",
  transform: "scale(0.7)",
  "&.disabled": {
    cursor: "no-drop",
  },
});
const IconBox = styled(Box)({
  position: "absolute",
  right: "15px",
  top: "15px",
  cursor: "text",

  ".deededSelectV2IconSvg": {
    transform: "scaleY(1)",
    transition: constants.transitions,
  },
  "&.isOpenList": {
    ".deededSelectV2IconSvg": {
      transform: "scaleY(-1)",
      transition: constants.transitions,
    },
  },
  "&.disabled": {
    cursor: "no-drop",
  },
});
const InputBox = styled(Box)({
  position: "relative",
  "& .MuiFormControl-root": {
    "& .MuiOutlinedInput-root": {
      "& .MuiInputBase-input": {
        paddingRight: "25px",
      },
    },
  },
});
const OptionButton = styled(Box)({
  fontFamily: "Montserrat",
  textTransform: "none",
  color: constants.colors.title,
  fontWeight: "500",
  fontSize: "14px",
  cursor: "pointer",
  padding: "10px 10px 10px 10px",
  justifyContent: "left",
  display: "flex",
  alignItems: "center",
  svg: {
    marginRight: "5px",
    width: "10px",
    height: "10px",
  },
  "&:hover": {
    background: constants.colors.redVeryPale,
    color: constants.colors.red,
  },
  transition: "300ms",
});
const ListPaper = styled(Paper)({
  width: "100%",
  zIndex: 1,
  position: "absolute",
  display: "flex",
  flexDirection: "column",
  maxHeight: "300px",
  overflowY: "auto",
  marginLeft: "-1px !important",
  "&::-webkit-scrollbar": {
    width: "5px",
  },
});
const DeededAutocompleteSelectBox = styled(Box)({
  width: "100%",
  position: "relative",
  "& .MuiFormControl-root": {
    width: "100%",
    fieldset: {
      border: `1px solid ${constants.colors.grayLight} !important`,
      boxShadow: "none !important",
      transition: constants.transitions,
    },
    "&:hover": {
      fieldset: {
        border: `1px solid ${constants.colors.deededGray} !important`,
        transition: constants.transitions,
      },
    },
    ".Mui-focused": {
      fieldset: {
        border: `1px solid ${constants.colors.red} !important`,
        transition: constants.transitions,
      },
    },
  },
  "&.error .MuiFormControl-root": {
    fieldset: {
      border: `1px solid ${constants.colors.red} !important`,
      transition: constants.transitions,
    },
  },
  "&.disabled": {
    cursor: "no-drop",
  },
  "&.disabled .MuiFormControl-root": {
    fieldset: {
      border: `1px solid ${constants.colors.grayLight} !important`,
      transition: constants.transitions,
    },
  },
});
export default DeededAutocompleteSelect;
