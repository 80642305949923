import React, {useState} from "react";
import {Box, Typography, useTheme} from "@mui/material";
import constants from "../../../../../styles/constants";
import DeededButton from "v2/components/DeededButton";
import ProfileSecurityPageMtfAuthenticationEnterPasswordPopup from "./ProfileSecurityPageMtfAuthenticationEnterPasswordPopup";
import {
  useProfileMtfSendPasswordMutation,
  useProfileMtfVerifyQrMutation,
} from "../../../../../redux/reducers/profileApi";
import ProfileSecurityPageMtfAuthenticationEnableMtfPopup from "./ProfileSecurityPageMtfAuthenticationEnableMtfPopup";
import {
  ProfileMtfSendPasswordParamsType,
  ProfileMtfVerifyQrParamsType,
} from "../../../../../redux/reducers/types/profileApiTypes";
import ProfileSecurityPageMtfAuthenticationSetUpCompletePopup from "./ProfileSecurityPageMtfAuthenticationSetUpCompletePopup";
import useTypedSelector from "../../../../../utils/hooks/useTypedSelector";

const ProfileSecurityPageMtfAuthentication = () => {
  const theme = useTheme();
  const profile = useTypedSelector((state) => state.ProfileReducer.profile);
  const [isOpenedEnterPasswordPopup, setIsOpenedEnterPasswordPopup] =
    useState(false);
  const [isOpenedEnableMtfPopup, setIsOpenedEnableMtfPopup] = useState(false);
  const [isOpenedSetUpCompletePopup, setIsOpenedSetUpCompletePopup] =
    useState(false);
  const [
    confirmMtfPasswordCallback,
    {data: confirmMtfPasswordData, isLoading: isConfirmMtfPasswordLoading},
  ] = useProfileMtfSendPasswordMutation();
  const [
    sendMtfCodeCallback,
    {data: sendMtfCodeData, isLoading: isSendMtfCodeLoading},
  ] = useProfileMtfVerifyQrMutation();
  return (
    <>
      <ProfileSecurityPageMtfAuthenticationContext.Provider
        value={{
          isOpenedSetUpCompletePopup,
          setIsOpenedSetUpCompletePopup,
          isOpenedEnterPasswordPopup,
          setIsOpenedEnterPasswordPopup,
          confirmMtfPasswordCallback,
          confirmMtfPasswordData,
          isConfirmMtfPasswordLoading,
          isOpenedEnableMtfPopup,
          setIsOpenedEnableMtfPopup,
          sendMtfCodeCallback,
          sendMtfCodeData,
          isSendMtfCodeLoading,
        }}
      >
        <Box
          sx={{
            width: "568px",
            display: "flex",
            flexDirection: "column",
            marginTop: "40px",
            [theme.breakpoints.down("md")]: {
              width: "100%",
            },
          }}
        >
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: "600",
              color: constants.colors.title,
              marginBottom: "24px",
            }}
          >
            Multi-factor authentication
          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              color: constants.colors.intakeTitle,
              marginBottom: "12px",
            }}
          >
            Multi-factor authentication is not enabled yet.
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              color: constants.colors.title,
              marginBottom: "24px",
            }}
          >
            Multi-factor authentication adds an extra layer of security to your
            account by requiring more than just a password to log in.
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <DeededButton
              disabled={!!profile?.google_verify}
              onClick={() => {
                setIsOpenedEnterPasswordPopup(true);
              }}
              sx={{
                width: "298px",
                height: "36px",
                [theme.breakpoints.down("sm")]: {
                  whiteSpace: "nowrap",
                  fontSize: "12px",
                },
              }}
            >
              Enable Multi-Factor Authentication
            </DeededButton>
          </Box>
        </Box>
        <ProfileSecurityPageMtfAuthenticationEnterPasswordPopup />
        <ProfileSecurityPageMtfAuthenticationEnableMtfPopup />
        <ProfileSecurityPageMtfAuthenticationSetUpCompletePopup />
      </ProfileSecurityPageMtfAuthenticationContext.Provider>
    </>
  );
};

export const ProfileSecurityPageMtfAuthenticationContext = React.createContext({
  isOpenedSetUpCompletePopup: false,
  setIsOpenedSetUpCompletePopup: (isOpenedSetUpCompletePopup: boolean) => {},
  sendMtfCodeCallback: (params: ProfileMtfVerifyQrParamsType) => {},
  sendMtfCodeData: {} as unknown,
  isSendMtfCodeLoading: false,
  isOpenedEnableMtfPopup: false,
  setIsOpenedEnableMtfPopup: (isOpenedEnableMtfPopup: boolean) => {},
  isOpenedEnterPasswordPopup: false,
  setIsOpenedEnterPasswordPopup: (isOpenedEnterPasswordPopup: boolean) => {},
  confirmMtfPasswordCallback: (params: ProfileMtfSendPasswordParamsType) => {},
  confirmMtfPasswordData: {qr: "", code: ""} as
    | {qr: string; code: string}
    | undefined,
  isConfirmMtfPasswordLoading: false,
});
export default ProfileSecurityPageMtfAuthentication;
