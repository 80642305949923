import {Box, Button} from "@mui/material";
import constants from "../../../../../styles/constants";
import ProfileThirdPartyNotePencil from "../../svg/ProfileThirdPartyNotePencil";
import DeededSpace from "v2/components/DeededSpace";
import ProfileThirdPartyToolTrashSvg from "../../svg/ProfileThirdPartyToolTrashSvg";
import {EmailInfoType} from "../../types/profileThirdPartyTypes";
import {styled} from "@mui/material/styles";

const ProfileIntegrationThreeDEmailComponentViewModeTools = ({
  index,
  setEmailsList,
  emailsList,
  setMode,
  isShowedTools,
  updateEmailsList,
}: {
  isShowedTools: boolean;
  setMode: (mode: "view" | "edit") => void;
  emailsList: EmailInfoType[];
  setEmailsList: (emailsList: EmailInfoType[]) => void;
  updateEmailsList: (emailsList: EmailInfoType[]) => void;
  index: number;
}) => {
  const onDeleteEmail = () => {
    const newEmailsList = emailsList.filter(
      (emailItem, emailItemIndex) => emailItemIndex !== index,
    );
    setEmailsList(newEmailsList);
    updateEmailsList(newEmailsList);
  };
  return (
    <>
      <Box
        sx={{
          position: "absolute",
          top: "0px",
          right: "45px",
          width: "161px",
          height: "91px",
          boxShadow:
            "0px 12px 16px rgba(0, 0, 0, 0.07), 0px 12px 66px rgba(0, 0, 0, 0.18)",
          borderRadius: "5px",
          opacity: isShowedTools ? "1" : "0",
          visibility: isShowedTools ? "visible" : "hidden",
          transition: constants.transitions,
          backgroundColor: "white",
          zIndex: 1,
        }}
      >
        <ToolButton
          onClick={() => {
            setMode("edit");
          }}
          sx={{
            marginTop: "13px",
          }}
        >
          <ProfileThirdPartyNotePencil /> <DeededSpace x={9} /> Edit
        </ToolButton>
        <ToolButton
          onClick={() => {
            onDeleteEmail();
          }}
        >
          <ProfileThirdPartyToolTrashSvg /> <DeededSpace x={9} /> Delete
        </ToolButton>
      </Box>
    </>
  );
};
const ToolButton = styled(Button)({
  width: "100%",
  display: "flex",
  justifyContent: "flex-start",
  paddingLeft: "20px",
  color: constants.colors.title,
  fontSize: "12px",
  fontWeight: "500",
  svg: {
    stroke: constants.colors.deededGray,
  },
  transition: constants.transitions,
  "&:hover": {
    svg: {
      stroke: constants.colors.red,
    },
    backgroundColor: constants.colors.redVeryPale,
    color: constants.colors.red,
    transition: constants.transitions,
  },
});
export default ProfileIntegrationThreeDEmailComponentViewModeTools;
