import { FC } from "react";
import { NavLink, useHistory } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTheme, styled } from "@mui/material";
import constants from "styles/constants";
import DeededButton from "v2/components/DeededButton";
import { getUserType, shouldCreateNewDealClient } from "utils/permissionRoles";
import { useMixpanel } from "utils/MixpanelContext";
import useUser from "utils/hooks/useUser";
import Colors from "components/Common/componentStyling/Colors";
import { PlusIcon } from "components/Common/Icons/Iconography";

interface DealsListHeaderProps {
    title: string;
    noMargin?: boolean;
}

const DealsListHeader: FC<DealsListHeaderProps> = ({ title, noMargin }) => {
    const history = useHistory();
    const mixpanel = useMixpanel();

    const isActiveDeals = title === "Transactions";
    const isActiveLeads = title === "Leads";
    const isActiveQuote = title === "Quote";
    const isActiveInt = title === "Integrations";

    const { user, isClient, isStaff, isProfessional } = useUser();
    const theme = useTheme();

    return (
        <HeaderContainer noMargin={noMargin}>
            <FirstLine>
                <Title>{title}</Title>
                <Box
                    sx={{
                        display: "none",
                        [theme.breakpoints.up("mobile")]: {
                            display: "flex",
                        },
                    }}
                >
                    {shouldCreateNewDealClient(user?.role || "") && (
                        <AddNewClient
                            data-testid="add-new-client-button"
                            onClick={() => {
                                mixpanel.track(`dashboard_add_new_deal`, {
                                    role: user.role,
                                    email: user.email,
                                    user_type: getUserType(user.role),
                                });
                                history.push("/dashboard/new-client");
                            }}
                        >
                            <PlusIcon color={Colors.WHITE} />
                            Add New Transaction
                        </AddNewClient>
                    )}
                </Box>
            </FirstLine>
            <Box sx={{ width: "100%", position: "absolute", border: "1px solid #E0E0E0", bottom: "0px" }}></Box>
            <LinksWrapper>
                <CustomLink
                    sx={{
                        fontWeight: isActiveDeals ? 600 : 500,
                        color: isActiveDeals ? constants.colors.red : constants.colors.gray,
                        borderBottom: isActiveDeals ? `2px solid ${constants.colors.red}` : "none",
                        padding: " 3px 10px",
                    }}
                    to={"/dashboard/deals"}
                >
                    My Transactions
                </CustomLink>
                {!isProfessional && !isClient && (
                    <CustomLink
                        to={"/dashboard/leads"}
                        sx={{
                            fontWeight: isActiveLeads ? 600 : 500,
                            color: isActiveLeads ? constants.colors.red : constants.colors.gray,
                            borderBottom: isActiveLeads ? `2px solid ${constants.colors.red}` : "none",
                            padding: " 3px 10px",
                        }}
                    >
                        My Leads
                    </CustomLink>
                )}

                {!isClient && (
                    <CustomLink
                        to={"/dashboard/quote"}
                        sx={{
                            fontWeight: isActiveQuote ? 600 : 500,
                            color: isActiveQuote ? constants.colors.red : constants.colors.gray,
                            borderBottom: isActiveQuote ? `2px solid ${constants.colors.red}` : "none",
                            padding: " 3px 10px",
                        }}
                    >
                        Price Estimator
                    </CustomLink>
                )}

                {isStaff && (
                    <CustomLink
                        to={"/dashboard/integrations"}
                        sx={{
                            fontWeight: isActiveInt ? 600 : 500,
                            color: isActiveInt ? constants.colors.red : constants.colors.gray,
                            borderBottom: isActiveInt ? `2px solid ${constants.colors.red}` : "none",
                            padding: " 3px 10px",
                        }}
                    >
                        Integrations
                    </CustomLink>
                )}
            </LinksWrapper>
        </HeaderContainer>
    );
};

const Title = styled(Typography)({
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "3.2rem",
    lineHeight: "3.9rem",
    margin: "-2px 0 24px",
    textTransform: "uppercase",
    color: constants.colors.intakeTitle,
});

const FirstLine = styled(Box)(({ theme }) => ({
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
    },
}));

const CustomLink = styled(NavLink)({
    fontSize: "20px",
    paddingBottom: "3px",
    textDecoration: "none",
});

const AddNewClient = styled(DeededButton)({
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "17px",
    textTransform: "capitalize",
    textAlign: "center",
    "& svg": {
        stroke: constants.colors.white,
        marginRight: "10px",
        transition: constants.transitions,
    },
    "&:hover svg": {
        stroke: constants.colors.red,
        transition: constants.transitions,
    },
});
const HeaderContainer = styled(Box, { shouldForwardProp: (p) => p !== "noMargin" })<{ noMargin?: boolean }>(
    ({ noMargin }) => ({
        width: "100%",
        marginBottom: noMargin ? "0" : "16px",
        background: "transparent",
        position: "relative",
    }),
);

const LinksWrapper = styled(Box)({
    display: "flex",
    flexFlow: "row",
    position: "relative",
    zIndex: "2",
});

export default DealsListHeader;
