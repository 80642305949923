import React, {useState, useEffect} from "react";
import {Box, styled} from "@mui/material";
import styles from "./ShortCodeDescriptions.module.scss";
import useTypedSelector from "utils/hooks/useTypedSelector";

const ShortCodeDescriptions = () => {
  const thead = ["TAG", "Description"];
  const [tbody, setTbody] = useState([]);
  const shortcodes = useTypedSelector(
    (state) => state.ConfigReducer.ConfigNotificationsReducer.shortcodes,
  );

  useEffect(() => {
    setTbody(shortcodes);
  }, [shortcodes]);

  return (
    <MainBox className={styles.wrapper}>
      <table className={styles.table}>
        <thead>
          <tr key={`thead`}>
            {thead.map((thEl, index) => (
              <th key={`th ${index}`}>{thEl}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tbody.map(({code, example, description}, index) => (
            <tr key={`tr ${index}`}>
              <td>
                <span className={styles.TAG}>{code}</span>
              </td>
              <td dangerouslySetInnerHTML={{__html: description}}></td>
            </tr>
          ))}
        </tbody>
      </table>
    </MainBox>
  );
};

const MainBox = styled(Box)(({theme}) => ({
  [theme.breakpoints.down("sm")]: {
    td: {
      fontSize: "10px",
    },
  },
}));

export default ShortCodeDescriptions;
