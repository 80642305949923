import React from "react";

const DeededLoader = () => {
  return (
    <div className="loader__wrapper">
      <svg
        className="loader"
        width="332"
        height="61"
        viewBox="0 0 332 61"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask id="path-1-inside-1" fill="white">
          <path d="M0.0800781 0V61H24.3971C34.5594 61 42.0909 58.4697 46.9906 53.4093C51.8903 48.2879 54.3402 40.667 54.3402 30.5461C54.3402 20.3643 51.8903 12.7434 46.9906 7.683C42.0909 2.56157 34.5594 0 24.3971 0H0.0800781ZM58.2859 0V61H99.7519V49.5678H72.9852V36.2154H96.2134V24.6924H72.9852V11.4323H99.7519V0H58.2859ZM105.859 0V61H147.325V49.5678H120.559V36.2154H143.787V24.6924H120.559V11.4323H147.325V0H105.859ZM153.432 0V61H177.75C187.912 61 195.443 58.4697 200.342 53.4093C205.242 48.2879 207.692 40.667 207.692 30.5461C207.692 20.3643 205.242 12.7434 200.342 7.683C195.443 2.56157 187.912 0 177.75 0H153.432ZM277.66 0V61H301.977C312.139 61 319.671 58.4697 324.57 53.4093C329.47 48.2879 331.92 40.667 331.92 30.5461C331.92 20.3643 329.47 12.7434 324.57 7.683C319.671 2.56157 312.139 0 301.977 0H277.66ZM211.639 0.256387V30.4755V60.6947H241.62H271.601V30.4755V0.256387H241.62H211.639ZM218.634 7.30848H229.628H240.62V30.4755V53.644H229.628H218.634V30.4755V7.30848ZM242.619 7.30848H253.612H264.605V14.3591V21.4098H253.612H242.619V14.3591V7.30848ZM14.7794 11.4323H22.6736C28.1782 11.4323 32.321 12.9868 35.1036 16.0963C37.8861 19.1447 39.2779 23.9615 39.2779 30.5461C39.2779 37.0089 37.8861 41.7944 35.1036 44.9038C32.321 48.0132 28.1782 49.5678 22.6736 49.5678H14.7794V11.4323ZM168.131 11.4323H176.025C181.53 11.4323 185.674 12.9868 188.456 16.0963C191.239 19.1447 192.629 23.9615 192.629 30.5461C192.629 37.0089 191.239 41.7944 188.456 44.9038C185.674 48.0132 181.53 49.5678 176.025 49.5678H168.131V11.4323ZM292.36 11.4323H300.254C305.758 11.4323 309.901 12.9868 312.683 16.0963C315.466 19.1447 316.858 23.9615 316.858 30.5461C316.858 37.0089 315.466 41.7944 312.683 44.9038C309.901 48.0132 305.758 49.5678 300.254 49.5678H292.36V11.4323ZM242.619 23.4249H253.612H264.605V30.4755V37.5276H253.612H242.619V30.4755V23.4249ZM242.619 39.5413H253.612H264.605V46.5919V53.644H253.612H242.619V46.5919V39.5413Z" />
        </mask>
        <path
          d="M0.0800781 0V61H24.3971C34.5594 61 42.0909 58.4697 46.9906 53.4093C51.8903 48.2879 54.3402 40.667 54.3402 30.5461C54.3402 20.3643 51.8903 12.7434 46.9906 7.683C42.0909 2.56157 34.5594 0 24.3971 0H0.0800781ZM58.2859 0V61H99.7519V49.5678H72.9852V36.2154H96.2134V24.6924H72.9852V11.4323H99.7519V0H58.2859ZM105.859 0V61H147.325V49.5678H120.559V36.2154H143.787V24.6924H120.559V11.4323H147.325V0H105.859ZM153.432 0V61H177.75C187.912 61 195.443 58.4697 200.342 53.4093C205.242 48.2879 207.692 40.667 207.692 30.5461C207.692 20.3643 205.242 12.7434 200.342 7.683C195.443 2.56157 187.912 0 177.75 0H153.432ZM277.66 0V61H301.977C312.139 61 319.671 58.4697 324.57 53.4093C329.47 48.2879 331.92 40.667 331.92 30.5461C331.92 20.3643 329.47 12.7434 324.57 7.683C319.671 2.56157 312.139 0 301.977 0H277.66ZM211.639 0.256387V30.4755V60.6947H241.62H271.601V30.4755V0.256387H241.62H211.639ZM218.634 7.30848H229.628H240.62V30.4755V53.644H229.628H218.634V30.4755V7.30848ZM242.619 7.30848H253.612H264.605V14.3591V21.4098H253.612H242.619V14.3591V7.30848ZM14.7794 11.4323H22.6736C28.1782 11.4323 32.321 12.9868 35.1036 16.0963C37.8861 19.1447 39.2779 23.9615 39.2779 30.5461C39.2779 37.0089 37.8861 41.7944 35.1036 44.9038C32.321 48.0132 28.1782 49.5678 22.6736 49.5678H14.7794V11.4323ZM168.131 11.4323H176.025C181.53 11.4323 185.674 12.9868 188.456 16.0963C191.239 19.1447 192.629 23.9615 192.629 30.5461C192.629 37.0089 191.239 41.7944 188.456 44.9038C185.674 48.0132 181.53 49.5678 176.025 49.5678H168.131V11.4323ZM292.36 11.4323H300.254C305.758 11.4323 309.901 12.9868 312.683 16.0963C315.466 19.1447 316.858 23.9615 316.858 30.5461C316.858 37.0089 315.466 41.7944 312.683 44.9038C309.901 48.0132 305.758 49.5678 300.254 49.5678H292.36V11.4323ZM242.619 23.4249H253.612H264.605V30.4755V37.5276H253.612H242.619V30.4755V23.4249ZM242.619 39.5413H253.612H264.605V46.5919V53.644H253.612H242.619V46.5919V39.5413Z"
          stroke="white"
          strokeWidth="6"
          mask="url(#path-1-inside-1)"
        />
      </svg>
    </div>
  );
};

export default DeededLoader;
