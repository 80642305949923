import moment from "moment/moment";
import {getDayOfWeek} from "./getDayOfWeek";
import {CalendarEvent} from "../../../../redux/reducers/calendarReducer";

export const calculateAndSetSigningsCallback = (
  view: string,
  date: Date,
  calendarEventsFromStore: CalendarEvent[],
  setSigningsTotal: (signingsAmount: number) => void,
  isCalendarClosings: boolean,
) => {
  let startCalculatingDate: Date, endCalculatingDate: Date;

  if (view === "month") {
    startCalculatingDate = moment(date)
      .startOf("month")
      .startOf("week")
      .toDate();
    endCalculatingDate = moment(date).endOf("month").endOf("week").toDate();
  } else if (view === "week") {
    startCalculatingDate = getDayOfWeek(date, 0);
    endCalculatingDate = getDayOfWeek(date, 6);
  } else {
    startCalculatingDate = date;
    endCalculatingDate = date;
  }
  const makeDateString = (date: Date) => moment(date).format("MM/DD/YYYY");
  const displayedEvents = calendarEventsFromStore.filter((event) => {
    const eventStart = makeDateString(event.start);
    const eventEnd = makeDateString(event.end);
    const startCalculatingDateFormated = makeDateString(startCalculatingDate);
    const endCalculatingDateFormated = makeDateString(endCalculatingDate);
    return (
      eventStart >= startCalculatingDateFormated &&
      eventEnd <= endCalculatingDateFormated
    );
  });
  if (!isCalendarClosings) {
    setSigningsTotal(displayedEvents.length);
  }
  return {
    startMonthDate: startCalculatingDate,
    endMonthDate: endCalculatingDate,
  };
};
