const TermsOfSaleON = (props) => {
  return (
      <>
        <h1>LAW FIRM ENGAGEMENT AGREEMENT</h1>
        <div className="type__subtitle">
          Congratulations on your upcoming real estate sale and thank you
          for choosing{" "}
          <span style={{ fontWeight: "600" }}>
            Deeded Law Professional Corporation
          </span>{" "}
          (the “Law Firm”, “we”, “our”, or “us”) for your legal needs.
        </div>
        <div className="type__subtitle">
          Our contact information is as follows:
        </div>
        <div className="type__subtitle">
          <span style={{ fontWeight: "600" }}>
            Deeded Law Professional Corporation
          </span>
          <br />
          2010 Winston Park Drive, Suite 200
          <br />
          Oakville, Ontario, L6H 5R7 <br />
          Tel: 1 647-797-3439 <br />
          Fax: 1 647-954-0133
        </div>
        <div className="type__subtitle">
          Please review this agreement thoroughly. This agreement establishes
          the lawyer-client relationship, and explains your rights, our
          obligations, and other key points that you’ll need to understand about
          your transaction, rights and privacy.
        </div>
        <div className="type__subtitle">
          If you do not understand any of the terms or language in this
          agreement, please contact us and we will gladly explain or answer any
          questions you may have. You are also welcome to obtain independent
          legal advice from another lawyer before entering into this agreement.
        </div>
        <div className="type__title">
          RELATIONSHIP BETWEEN LAW FIRM AND DEEDED TECHNOLOGY PLATFORM
        </div>
        <div className="type__subtitle" style={{ fontWeight: "600" }}>
          Deeded Law Professional Corporation (the “Law Firm”) is separate from
          and operates independently of Deeded, Inc., which operates the
          deeded.ca website and app.Deeded.ca (the “Platform”).
        </div>
        <div className="type__subtitle">
          To assist you with your real estate transaction, the Law Firm engages
          Deeded, Inc. as a third-party service provider to leverage its
          technology Platform, which assists the Law Firm in facilitating your
          real estate transaction, and for certain administrative support
          services.
        </div>
        <div className="type__subtitle">
          Electronically accepting this engagement agreement establishes a
          lawyer-client relationship between you and the Law Firm and confirms
          that you will receive legal services and legal advice respecting your
          real estate transaction from the Law Firm.
        </div>
        <div className="type__subtitle">
          The Law Firm independently exercises complete control over, and is
          solely responsible for, the provision of legal advice, opinions, or
          recommendations about your legal rights, obligations, remedies,
          strategies, and options; the selection and completion of forms and
          other documents; and the application of the law to the facts of your
          situation.
        </div>
        <div className="type__subtitle">
          Deeded, Inc. is <span style={{ textDecoration: "underline" }}>not</span>{" "}
          a party to the lawyer-client relationship and does not provide any
          legal services or advice. Deeded, Inc. may, however, act as an
          intermediary between you and the Law Firm or other Business Partners
          (as that term is defined in Deeded Inc’s{" "}
          <span style={{ textDecoration: "underline" }}>Terms of Use</span> and{" "}
          <span style={{ textDecoration: "underline" }}>Privacy Policy</span>,
          which govern your relationship with Deeded, Inc. and usage of its
          Platform).
        </div>
        <div className="type__subtitle">
          Deeded Inc. may also provide administrative services or assistance to
          the Law Firm such as administrative support, clerical support,
          technical support, scheduling support, access to certain software or
          databases, customer service support, and marketing support.
        </div>
        <div className="type__subtitle">
          In engaging Deeded, Inc. to use the Platform and for the provision of
          the above-mentioned services, the Law Firm has entered into strict
          confidentiality and privacy agreements with Deeded, Inc. and its
          staff, but in rare circumstances the assistance provided by Deeded,
          Inc. may nevertheless lead to a loss of solicitor-client privilege.
        </div>
        <div className="type__subtitle">
          <span style={{ fontWeight: 600 }}>
            By entering into this agreement, you confirm that you consent to the
            Law Firm providing your information—including your personal
            information and information concerning the nature and status of your
            real estate transaction—to Deeded, Inc. for the purpose of and to
            the extent necessary to facilitate your real estate transaction by
            leveraging the Deeded, Inc. Platform and administrative services.
          </span>{" "}
          You further confirm that providing this consent does not indicate any
          intention to waive solicitor-client privilege over your communications
          with the Law Firm—it is provided only to enable the Law Firm to access
          and utilize technology and administrative services that assist in its
          provision of legal services to you.
        </div>
        <div className="type__subtitle">
          Deeded, Inc. receives remuneration from the Law Firm for the use of
          its technology and any administrative services rendered.
        </div>
        <div className="type__subtitle">
          Fees for legal services rendered are paid directly to the Law Firm,
          per the Law Firm’s account provided to you.
        </div>
        <div className="type__subtitle">
          If you have any questions about your fees and payment or the use and
          disclosure of your information, please do not hesitate to contact us
          before entering into this agreement.
        </div>
        <div className="type__title">SCOPE OF SERVICES</div>
        <div className="type__subtitle">
          During the retainer, we have authority to act for and on behalf of you
          in connection with such matters and to conduct such matters in all
          respects. Except where you have specifically instructed otherwise, we
          may act on your behalf and bind you in all necessary and related legal
          forms and procedures within reason and we will be expected to take all
          steps reasonably necessary to further and protect your interests.
        </div>
        <div className="type__subtitle">
          Under this agreement, our scope is limited to ensuring a successful transfer
          of the title of your property, which includes, but not limited to reviewing
          your agreement of purchase and sale, communicating with the buyer’s lawyer,
          discharging of mortgages and other charges (as applicable), payment of
          realty commissions and disbursing of funds.
        </div>
        <div className="type__subtitle">
          For any potential issues that may arise such as breach of contract or
          post-closing issues, we will attempt to resolve any such issues in
          good faith with parties involved in the transaction. Should we not be
          able to reach agreement on any of such issues, we will recommend a
          course of action, including potential litigation. Please note that we
          do not practice litigation and you will have to engage separate
          counsel if needed.
        </div>
        <div className="type__title">CLOSING DATE</div>
        <div className="type__subtitle">
          The closing date of your transaction will be listed in the Agreement
          of Purchase and Sale and may be amended by an amendment form agreement
          to the original Agreement of Purchase and Sale. Your real estate agent
          should be able to assist with any amendments to the closing in the
          early stages of your transaction. Please keep our office informed of
          any delays or changes in the closing date.
        </div>
        <div className="type__title" style={{ fontStyle: "italic" }}>
          Additional Fee For Delays
        </div>
        <div className="type__subtitle">
          If any closing delays occur and the transaction does not close on the
          set closing date, then we reserve the right to charge an additional
          $350.00 plus GST and provincial sales tax (where applicable) per extension.
        </div>
        <div className="type__title">RETAINER AT AN END</div>
        <div className="type__subtitle">
          Upon the completion of the closing of your real estate transaction,
          our retainer will be at an end. Our services will not include any legal
          representation or advice with respect to any post-closing matters including
          but not limited to, litigation or default proceedings. If we are able to
          assist with post-closing matters, we will provide you with a new
          engagement/retainer letter along with estimated costs of our services
          for representing you.
        </div>
        <div className="type__title">FEES AND DISBURSEMENTS</div>
        <div className="type__subtitle">
          Our flat rate legal fee applies to a residential single-family
          dwelling on city services, being vacant on closing with one first
          mortgage and no other encumbrances or liens on title.
        </div>
        <div className="type__subtitle">
          Note: Our standard fees are applicable for all work completed even if
          the transaction does not close, regardless of whether you were in
          breach of contract or not, provided our firm has completed work on
          your file. Additional fees are also applicable for any additional
          payouts beyond paying off your mortgage and net proceeds to yourself.
        </div>
        <div
          className="type__subtitle"
          style={{ fontWeight: "500", fontSize: "1.5rem" }}
        >
          In some real estate transactions, there may be unforeseen issues,
          complexities, and/or difficulties that arise as a result of matters
          beyond our law firm's control. Deeded Law Professional Corporation reserves the right to
          increase fees to reflect any additional time expended with the
          completion of your transaction and will contact you to discuss same.
        </div>
        <div className="type__subtitle">
          The net proceeds are released to you as per your signed direction. The
          sale proceeds are adjusted for any bank charges/debts to ensure that
          we have been provided the sufficient amount required for your closing.
        </div>
        <div className="type__title">HOLDBACK OF FUNDS</div>
        <div className="type__subtitle">
          Please note there is a fee applicable for any holding of funds after
          the transaction closes or the transaction does not close for any
          reason. A <span style={{ fontWeight: "600" }}>$250.00</span> plus GST
          and provincial sales tax (where applicable) fee will be deducted at
          the time of the release of the holdback.
        </div>
        <div className="type__title">PAYMENT TO DEEDED LAW, IN TRUST</div>
        <div className="type__subtitle">
          Before your closing date, you may have to provide Deeded Law with
          sufficient funds by certified cheque or bank draft, deposited to our trust account or
          wire transfer to complete your transaction that are not covered by your
          sale proceeds. Please note that we do not accept any funds in cash,
          including our retainer. These funds include the amount owing to government
          agencies or to other lenders plus legal fees, disbursements and
          adjustments for extra expenses and taxes as outlined above. We will
          advise you prior to closing of the total funds required.
        </div>
        <div className="type__title">WHAT ARE DISBURSEMENTS?</div>
        <div className="type__subtitle">
          Disbursements are expenses that your lawyer charges you for
          out-of-pocket expenses that you would otherwise be required to pay if
          you handled the transaction yourself. Because your lawyer generally
          pays these expenses, the lawyer requires you to reimburse them by
          including these items in their account. Examples of disbursements
          include but are not limited to postage, deliveries, software fees,
          trust administration fees, government filing and search charges, and
          the fees of agents who conduct investigations, searches and
          registrations and all other reasonable out of pocket expenses
        </div>
        <div className="type__subtitle">
          The net proceeds are released to you as per your signed direction.
          The sale proceeds are adjusted for any bank charges/debts to ensure
          that we have been provided the sufficient amount required for your closing.
        </div>
        <div
          className="type__title"
          style={{ fontStyle: "italic", textDecoration: "none" }}
        >
          Other Charges
        </div>
        <div className="type__subtitle">
          You will also be responsible for office charges allocated to your file.
          These include charges for conveyancer/paralegal time, word processing
          charges, computer costs, in-house photocopying and faxes.
        </div>
        <div
          className="type__subtitle"
          style={{ textDecoration: "none", fontStyle: "italic" }}
        >
          Taxes
        </div>
        <div className="type__subtitle">
          You will be charged the applicable taxes on fees and on some
          disbursements or other charges.
        </div>
        <div
          className="type__subtitle"
          style={{ textDecoration: "none", fontStyle: "italic" }}
        >
          Cancellation Policy
        </div>
        <div className="type__subtitle">
          If for any reason the transaction is cancelled by you,
          the lender, failure to complete the Client Identification
          and Verification process or due to a conflict of interest
          that cannot be resolved, you acknowledge and agree that
          you will be liable and shall pay the amount of your legal
          fees and disbursements incurred by Deeded Law as incurred
          prior to the cancellation.
        </div>
        <div
          className="type__title"
          style={{ fontStyle: "italic", textDecoration: "none" }}
        >
          Rush Fee
        </div>
        <div className="type__subtitle">
          There is an additional legal fee to provide this service and a retainer is
          to be provided prior to starting the file. We have no way to ensure that
          other service providers will deliver in the short time frame requested.
        </div>
        <div className="type__title">THIRD PARTY PAYMENT PROCESSOR</div>
        <div className="type__subtitle">
          I/We acknowledge that Deeded Law Professional Corporation uses a Third
          Party Payment Processor to pay accounts and debts as required to close
          the transaction. I/We explicitly provide consent to Deeded Law
          Professional Corporation to disburse funds to the Third Party Payment
          Processor as necessary. I/We further acknowledge that there may be
          delays in the banking system and that Deeded Law Professional
          Corporation is not liable for any losses, damages, and/or claims due
          to any delays in funding/disbursement of payment. I/We the undersigned
          agree to adjust any shortfall that may be required in order to remedy any
          payments that are required for the completion of the transaction.
        </div>
        <div className="type__title">INTEREST</div>
        <div className="type__subtitle">
          Bills for fees and disbursements remaining unpaid more than 30 days
          after the bill is delivered, will bear interest at a prescribed rate
          as specified on the bill. Payment is due on all of our accounts when
          rendered. If any account is not paid within 30 days, interest will be
          charged on the outstanding balance at a rate of 5% per annum from the
          date of the account, until paid.
        </div>
        <div className="type__title">INFORMATION WE NEED FROM YOU</div>
        <div className="type__subtitle">
          During the course of your closing, we will require information and
          certain documents from you in order to facilitate a timely closing of
          your transaction.
        </div>
        <div className="type__subtitle">
          You are responsible for the timely delivery of information and
          documents to our office either by electronic, facsimile or couriering
          documents. Any delays or additional fees, penalties, or interest
          incurred due to unreasonable delays or withholding required documents
          or information from you, shall be solely your responsibility.
        </div>
        <div className="type__title">FIRE INSURANCE</div>
        <div className="type__subtitle">
          You are responsible for cancelling your fire insurance coverage.
          Deed Law recommends that you do not cancel the insurance until our
          firm has notified you that the transaction has closed.
        </div>
        <div className="type__title">UTILITIES</div>
        <div className="type__subtitle">
          You will need to contact utility authorities (electricity, natural gas,
          hot water tank rental, water, internet, phone, TV, and satellite) to
          close your accounts and pre-authorized payments in your name for closing
          and to advise of your closing date and, where applicable, to request that
          the meters be read on the closing date. Advise them that you will be the
          new occupants and that you can be reached at the property. As a precaution,
          we advise that at least two weeks before your closing date. Please note
          that due to privacy laws, Deeded Law Professional Corporation does not
          notify utility companies of your sale and cannot be held liable for any
          fees, charges, recurring charges or penalties that may occur from not
          notifying the respective utility provider.
        </div>
        <div className="type__title">PROPERTY TAXES</div>
        <div className="type__subtitle">
          We will notify your municipal tax office of your sale and adjust for any
          additional taxes owning or paid at the time of closing. Due to the nature
          by which municipal tax offices operate, we strongly suggest verifying that
          your property tax account has been closed with your municipality within
          2-3 weeks of closing.
        </div>
        <div className="type__title">ASSIGNMENT OF HOT WATER TANK, ETC.</div>
        <div className="type__subtitle">
          If you are assigning any items under the Agreement of Purchase and
          Sale it is your responsibility to contact the relevant companies and
          provide our office the assignment assumption agreement (if applicable)
          at least 5 business days before closing.
        </div>
        <div className="type__title">KEYS</div>
        <div className="type__subtitle">
          We will need receive the keys to your property prior to closing.
          When and where possible, we will arrange with you or your Real Estate
          Agent to leave their keys in a secure lockbox on your property.
        </div>
        <div className="type__subtitle">
          Such arrangements are highly dependent on cooperation from your Real Estate
          professionals and may be subject to local rules and regulations.  Deeded
          Law Professional Corporation will not be held responsible where such
          arrangements cannot be made, or delays are caused, for any reason whatsoever.
          You will be responsible for any costs associated with getting us a copy of
          your keys.  In addition, Deeded Law Professional Corporation does not assume
          responsibility for the security of the lockbox, any keys within the box or
          any unauthorized use of keys, within the lock box.  If you have any concerns
          with using a lockbox to transfer keys, please let us know and we will work
          with you on alternate arrangements.
        </div>
        <div className="type__title">REMOTE / VIRTUAL SIGNING</div>
        <div className="type__subtitle">
          Remote commissioning is an authentication and signature process for
          taking affidavits and statutory declarations that uses audio-visual
          technology.
        </div>
        <div className="type__subtitle">
          At Deeded Law Professional Corporation, we use secure remote commissioning
          technology to make the signing process safe, easy and secure.  Shortly before
          your closing, we will arrange a day and time to meet with you virtually using
          our remote commissioning technology.  We will need each person whose name will
          appear on the title of the property being sold to attend the signing, if
          applicable. If any person whose name appears on the title will not be available
          at that time, please advise us as soon as possible as alternative arrangements
          may have to be made.
        </div>
        <div className="type__subtitle">
          In a remote commissioning procedure, you will be connected by live video
          conference to a lawyer, paralegal or commissioner of oaths representing
          Deeded Law Professional Corporation who will be commissioning and/or witnessing
          legal documents that you sign, which are required for your closing. The video
          and signing session will be recorded for our records, along with information
          about your location, identity, and other measures used to prevent fraud and
          protect all parties from future disputes.  Any recordings, or information
          captured during, before, or after the remote commissioning session is the sole
          property of Deeded Law Professional Corporation and shall become part of your
          client file for record keeping purposes and to comply with regulations.
        </div>
        <div className="type__subtitle">
          In order to attend a remote commissioning session, you will require
          access to a computer, a high-speed internet connection and a
          touch-screen device such as a mobile phone. If you do not have access
          to required technology, please inform us immediately so that we can
          seek alternative options.
        </div>
        <div
          className="type__title"
          style={{
            textDecoration: "none",
            fontStyle: "italic",
            fontWeight: "600",
          }}
        >
          Additional Fee – More than one signing appointment
        </div>
        <div className="type__subtitle">
          Our fees include one virtual signing appointment and requires all
          borrowers, guarantors or any other parties required to sign documents
          to attend at the scheduled time for signing. Should one or more
          parties fail to attend, are late to the signing meeting, fail to
          produce valid identification, or are having technology issues that
          cannot be resolved in the beginning of the signing meeting, you may be
          charged for an additional signing appointment.
        </div>
        <div className="type__subtitle">
          Should you require a visit by a lawyer or commissioner of oaths to
          your home or office in order to sign the documents, you may incur
          additional fees.
        </div>
        <div className="type__title">IDENTIFICATION</div>
        <div className="type__subtitle">
          The Law Society requires us to verify your identity. We must also
          ensure your matter doesn’t conflict with another matter or client.
        </div>
        <div className="type__subtitle">
          At our meeting, we will require each person, to bring two pieces of
          identification, one of which must be Canadian/Federal or Provincial
          government-issued photo identification such as a driver's licence or
          passport. Unfortunately, {" "}
          <span style={{ fontStyle: "italic", fontWeight: "600" }}>
            a health card is not acceptable identification.{" "}
          </span>
          If you have any questions about what is an acceptable form of
          identification or if you do not have Canadian/Federal or Provincial
          photo identification, please contact us as soon as possible.
        </div>
        <div
          className="type__title"
          style={{
            textDecoration: "none",
            fontStyle: "italic",
            fontWeight: "600",
          }}
        >
          An expired or non-valid identification will not be accepted.
        </div>
        <div
          className="type__title"
          style={{
            fontStyle: "italic",
            fontWeight: "600",
          }}
        >
          Client Identification and Verification
        </div>
        <div className="type__title">
          Deeded Law retains the services of a third-party to assist with the
          Client Identification and Verification process (“IDV”) to comply with
          Law Society Rules. All clients are required to complete the IDV process,
          and if we are unable to comply with the IDV Rules of the Law Society for
          any reason, the client will be required to meet in-person with a local
          Notary or Lawyer to complete the IDV process. If we are unable to complete
          the IDV process, we will no longer be able to act on the transaction.
        </div>
        <div className="type__subtitle">
          The Client is responsible of all fees associated with the IDV process,
          including all third-party fees.
        </div>
        <div className="type__title">TERMINATION OF SERVICES</div>
        <div
          className="type__subtitle"
          style={{ fontStyle: "italic", fontWeight: "600" }}
        >
          By you, the client
        </div>
        <div className="type__subtitle">
          You have the right to terminate our services to you upon written
          notice to our firm.
        </div>
        <div
          className="type__subtitle"
          style={{ fontStyle: "italic", fontWeight: "600" }}
        >
          By us, the Solicitor(s)
        </div>
        <div className="type__subtitle">
          Subject to our obligations to you to maintain proper standards of
          professional conduct, we reserve the right to terminate our services
          to you for good reasons which include, but are not limited to:
        </div>
        <div className="type__subtitle">
          <ul>
            <li>if you fail to cooperate with me in any reasonable request;</li>
            <li>if our continuing to act would be unethical or impractical;</li>
            <li>improper behavior or aggression;</li>
            <li>if there is a serious loss of confidence between us and you;</li>
            <li>if or retainer has not been paid;</li>
            <li>you are dishonest; or</li>
            <li>if you fail to pay our accounts when rendered.</li>
          </ul>
        </div>
        <div className="type__subtitle">
          If you terminate our services or we withdraw our services, you would
          only have to pay fees, disbursements, and expenses up until the time
          we stopped acting for you.
        </div>
        <div className="type__subtitle">
          We would also have to withdraw our services if we learned of a{" "}
          <span style={{ fontStyle: "italic", fontWeight: "600" }}>
            conflict of interest
          </span>{" "}
          that would make it unethical for us to continue to act for you. A
          conflict of interest occurs when what is best for one of the clients
          of our firm somehow is not best for or hurts another of our clients.
          If we have to withdraw our services for you because of a conflict of
          interest, you will only have to pay our fees and expenses up to the
          time we stopped acting for you.
        </div>
        <div
          className="type__subtitle"
          style={{ fontStyle: "italic", fontWeight: "600" }}
        >
          Fees Collected On Terminated Or Cancelled Matters With Flat Rate Fees
        </div>
        <div className="type__subtitle">
          Please note for matters that have flat rate fees such as Real Estate
          closings, we reserve the right to charge for our time and resources
          spent up to the termination of the solicitor-client contract.
        </div>
        <div className="type__subtitle">
          Terminations may also occur upon a client’s failure to close as a
          result of a lack of funds or other breach of contract or due to the
          client not providing information and co-operation as necessary. Deeded
          Law Professional Corporation shall have the right to charge for its
          time and resources on files that are terminated as a result of client
          negligence. For clarity, Deeded Law Professional Corporation shall
          have the right to charge for its time and resources for if a flat rate
          closing file fails to close for any reason.
        </div>
        <div className="type__title">USE OF TECHNOLOGY</div>
        <div className="type__subtitle">
          To facilitate a better closing experience, Deeded Law Professional
          Corporation uses certain proprietary and third-party technologies.
        </div>
        <div className="type__subtitle">
          Deeded Law Professional Corporation makes every attempt to keep its
          content up-to-date and the information it provides as accurate as
          possible. However, information provided by Deeded Law Professional
          Corporation through its website or any applications should be used
          solely for informational purposes. Deeded Law Professional Corporation
          makes no representations or warranties about the accuracy or
          completeness of our content or the continued availability of our
          content, except where required by law. All of the information provided
          by Deeded Law Professional Corporation is "as is" and without
          warranties of any kind, whether express or implied. Deeded Law
          Professional Corporation does not represent or warrant that Deeded Law
          Professional Corporation products or services will be error free,
          except where required by law.
        </div>
        <div className="type__title">ELECTRONIC COMMUNICATION</div>
        <div className="type__subtitle">
          Deeded Law Professional Corporation uses all reasonable means to
          protect the security and confidentiality of e-ail information sent and
          received. You acknowledge that you understand that email can never be
          a secure means of communication and document exchange and that Deeded
          Law Professional Corporation does not guarantee the security and
          confidentiality of e-mail communication. You acknowledge that Deeded
          Law Professional Corporation will not be liable for improper use
          and/or disclosure of confidential information and you fully understand
          the information provided to you regarding the risks of using e-mail
          and you consent to receiving all documentation regarding this matter
          via e-mail communications.
        </div>
        <div className="type__title">USE OF PERSONAL INFORMATION</div>
        <div className="type__subtitle">
          Deeded Law Professional Corporation is responsible for compliance
          under the applicable provincial Personal Information Protection Act
          (PIPA). These laws set out rules for how organizations collect, use
          and disclose personal information. Deeded Law Professional Corporation Professional Corporation
          is committed to being accountable for how it handles personal
          information, as well as how it follows the rules and procedures
          outlined in its privacy policy.
        </div>
        <div className="type__subtitle">
          Deeded Law Professional Corporation will only collect, use or disclose
          personal information that is necessary to verify your identity, to
          provide the requested legal services, or as required by law. You agree
          Deeded Law Professional Corporation may disclose your personal
          information to third parties including conveyancing software,
          video-conferencing technology, title insurance providers and for any
          such further purpose related to providing the legal services as set
          out in this agreement. Deeded Law Professional Corporation will ensure
          that there is an agreement in place with any third parties that that
          ensures the organization understands and follows the same privacy
          obligations.
        </div>
        <div className="type__subtitle">
          You may withdraw your consent for the disclosure of your personal
          information at any time. However, the withdrawal of such consent, may
          result in us being unable to continue to provide legal services to
          you.
        </div>
        <div className="type__title">
          TERMINATION OF USE OF OUR TECHNOLOGY
        </div>
        <div className="type__subtitle">
          Deeded Law Professional Corporation may terminate your access to this
          Website or any of our proprietary and third-party applications at any
          time with or without cause and without prior notice. In the event of
          termination of such access, you acknowledge that any material, data
          and information you have submitted to Deeded Law Professional
          Corporation on this Website may be lost or may otherwise no longer be
          available to you. Where required by law, Deeded Law Professional
          Corporation will continue to make certain material available to you.
        </div>
        <div className="type__title">
          YOUR AGREEMENT TO THE RETAINER AGREEMENT FOR SOLICITOR AND CLIENT
          RELATIONSHIP
        </div>
        <div className="type__subtitle">
          By checking the box, you are acknowledging that you have read this
          retainer agreement in full and that you agree to abide by the terms
          set out above.
        </div>
        <div className="type__subtitle">
          If you do not agree to this retainer agreement, there is no
          solicitor-client relationship. Please advise us immediately of any
          questions, concerns, comments or if you do not agree to this retainer
          agreement.
        </div>
      </>
  );
};

export default TermsOfSaleON;
