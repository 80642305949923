import React, {useState} from "react";
import {styled} from "@mui/material/styles";
import {Box, useTheme} from "@mui/material";
import DeededButton from "v2/components/DeededButton";
import {useFormik} from "formik";
import * as yup from "yup";
import useUser from "../../../../utils/hooks/useUser";
import DeededInput from "v2/components/DeededInput";
import DeededLabel from "v2/components/DeededLabel";
import ProfileSecurityPasswordHints from "./ProfileSecurityPasswordHints";
import {getProfileTC, workDocIntegrationTC} from "redux/reducers/profileReducer";
import {useDispatch} from "react-redux";
import BooleanControl from "components/Common/BooleanControl/BooleanControl";
import { connectToExistingWorkdocsIntegration } from "api";
import { addErrorAC, addSuccessMessageAC } from "redux/reducers/ErrorReducer";
import { setUpdateWorkdocUserStatus } from "redux/reducers/authReducer";

interface ProfileIntegrationWorkDocFormProps {
  handleCancel: () => void;
}
const ProfileIntegrationWorkDocForm: React.FC<
  ProfileIntegrationWorkDocFormProps
> = ({handleCancel}) => {
  const [showHints, setShowHints] = useState(false);
  const {user} = useUser();
  const dispatch = useDispatch();
  const theme = useTheme();
  const schema = yup.object().shape({
    password: yup
      .string()
      .when('hasAnAccount', {
        is: false,
        then: () => yup.string().required("Please enter password")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{12,})/,
          "Please complete all required conditions",
        ),
      }),
    passwordConfirmation: yup
      .string()
      .when('hasAnAccount', {
        is: false,
        then: () => yup.string().required("Please enter password confirmation")
        .oneOf([yup.ref("password"), null], "Passwords must match")
      }),
  });
  const initialValues = {
    firstName: user.first_name,
    lastName: user.last_name,
    email: user.email,
    password: "",
    passwordConfirmation: "",
    hasAnAccount: false
  };
  const onSubmit = () => {
    handleCancel();
    if (values.hasAnAccount) {
      connectToExistingWorkdocsIntegration().then(() => {
        dispatch(getProfileTC());
        dispatch(setUpdateWorkdocUserStatus(true));
        dispatch(addSuccessMessageAC("Successfully connected"));
      }).catch((error) => {
        dispatch(
          addErrorAC(
            error?.response?.data?.error ?? "Couldn't make WorkDoc integration",
          ),
        );
      });
      return;
    }
    const payload = {
      user_id: user.id,
      password: values.password,
      password_confirmation: values.passwordConfirmation,
    };
    dispatch(
      workDocIntegrationTC(
        payload as {
          user_id: number;
          password: string;
          password_confirmation: string;
        },
      ),
    );
  };
  const {handleSubmit, values, errors, handleChange} = useFormik({
    validationSchema: schema,
    initialValues,
    onSubmit,
    validateOnChange: false,
    validateOnBlur: false,
  });
  return (
    <>
      <form onSubmit={handleSubmit}>
        <WorkDocFormBox>
          <FieldsBox>
            <FirstFieldsBox
              sx={{
                [theme.breakpoints.down("md")]: {
                  flexDirection: "column",
                },
              }}
            >
              <DeededLabelSpaced
                sx={{
                  width: "274px",
                  [theme.breakpoints.down("md")]: {
                    width: "100%",
                  },
                }}
                title="First Name"
              >
                <DeededInput
                  sx={{
                    width: "274px",
                    [theme.breakpoints.down("md")]: {
                      width: "100%",
                    },
                  }}
                  disabled
                  value={values.firstName}
                />
              </DeededLabelSpaced>
              <DeededLabelSpaced
                sx={{
                  width: "274px",
                  marginLeft: "-15px",
                  [theme.breakpoints.down("md")]: {
                    width: "100%",
                    marginLeft: "0px",
                  },
                }}
                title="Last Name"
              >
                <DeededInput
                  sx={{
                    width: "274px",
                    [theme.breakpoints.down("md")]: {
                      width: "100%",
                    },
                  }}
                  disabled
                  value={values.lastName}
                />
              </DeededLabelSpaced>
            </FirstFieldsBox>
            <DeededLabelSpaced
              sx={{
                width: "100%",
                marginTop: "4px",
              }}
              title={"Email"}
            >
              <DeededInput disabled value={values.email} />
            </DeededLabelSpaced>
            {values.hasAnAccount ? <></> : <>
              <DeededLabelSpaced
                onClick={(e) => {
                  e.stopPropagation();
                }}
                sx={{
                  width: "100%",
                  marginTop: "4px",
                  position: "relative",
                }}
                title="Create Workdocs Password"
              >
                <DeededInput
                  onFocus={() => {
                    setShowHints(true);
                  }}
                  onBlur={() => {
                    setShowHints(false);
                  }}
                  type={"password"}
                  onChange={(e) => {
                    handleChange({
                      target: {
                        name: "password",
                        value: e.target.value,
                      },
                    });
                  }}
                  value={values.password}
                  error={errors.password}
                />
                {showHints && (
                  <ProfileSecurityPasswordHints newPass={values.password} />
                )}
              </DeededLabelSpaced>
              <DeededLabelSpaced
                sx={{
                  width: "100%",
                  marginTop: "4px",
                }}
                title="Confirm New Workdocs Password"
              >
                <DeededInput
                  type={"password"}
                  onChange={(e) => {
                    handleChange({
                      target: {
                        name: "passwordConfirmation",
                        value: e.target.value,
                      },
                    });
                  }}
                  value={values.passwordConfirmation}
                  error={errors.passwordConfirmation}
                />
              </DeededLabelSpaced>
            </>}
            <BooleanControl
              label={{ text: "I already have a Workdocs account", inputId: "has-a-workdocs-account" }}
              checked={values.hasAnAccount}
              onChange={() => handleChange({
                target: {
                  name: "hasAnAccount",
                  value: !values.hasAnAccount,
                },
              })}
            />
          </FieldsBox>
          <ButtonsBox>
            <SubmitBtn type={"submit"}>Submit</SubmitBtn>
            <CancelBtn type={"button"} onClick={handleCancel} kind="secondary">
              Cancel
            </CancelBtn>
          </ButtonsBox>
        </WorkDocFormBox>
      </form>
    </>
  );
};
const FirstFieldsBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
});
const DeededLabelSpaced = styled(DeededLabel)(({theme}) => ({
  marginBottom: "20px",
  [theme.breakpoints.down("md")]: {
    marginRight: "0",
  },
}));
const CancelBtn = styled(DeededButton)({
  height: "40px",
  width: "100px",
  marginRight: "16px",
});
const SubmitBtn = styled(DeededButton)({
  height: "40px",
  width: "133px",
});
const ButtonsBox = styled(Box)({
  display: "flex",
  flexDirection: "row-reverse",
});
const FieldsBox = styled(Box)({
  height: "100%",
});
const WorkDocFormBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  marginTop: "25px",
});
export default ProfileIntegrationWorkDocForm;
