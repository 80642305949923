import React, {useCallback} from "react";

export const useToggleMaterialUiDrawer = (setOpen: (open: boolean) => void) => {
  return useCallback(
    (open: boolean) => {
      return (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event &&
          event.type === "keydown" &&
          ((event as React.KeyboardEvent).key === "Tab" ||
            (event as React.KeyboardEvent).key === "Shift")
        ) {
          return;
        }

        setOpen(open);
      };
    },
    [setOpen],
  );
};
