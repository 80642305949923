import {createCalendarClosingOrAppointmentsDealByDateCountsInfo} from "./createCalendarClosingOrAppointmentsDealByDateCountsInfo";

export const getMobileSignersSigningAppointmentCountTableArrayByExactDate = (mobileSignersAppointments:unknown) => {
  const typedMobileSignersAppointments = mobileSignersAppointments as {
    oneOfBigAmountUsers: {
      full_name: string;
      sum: number;
      dealTypes: {
        Purchase: number;
        Sale: number;
        Mortgage: number;
        Lender: number;
      };
    };
  };
  return !typedMobileSignersAppointments
    ? []
    : Object.keys(typedMobileSignersAppointments)
      .map(
        (key) =>
          typedMobileSignersAppointments[key as "oneOfBigAmountUsers"],
      )
      .map((mobileSigner) =>
        createCalendarClosingOrAppointmentsDealByDateCountsInfo(mobileSigner.full_name, mobileSigner.sum),
      );
}