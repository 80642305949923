import {useState, useEffect, useMemo} from "react";
import {connect, useDispatch} from "react-redux";
import {differenceInHours, isBefore} from "date-fns";
import {useHistory} from "react-router-dom";
import "./DealDocuments.scss";
import AddFile from "../AddFile/AddFile";
import AdditionalDocuments from "../AdditionalDocuments/AdditionalDocuments";
import {useMixpanel} from "../../../utils/MixpanelContext";
// API
import {baseURL, getDealByID, getDocumentsTypes} from "api";
import axios from "axios";
// SVG
import ApprovedSVG from "../../Svg/ApprovedStatus";
import PendingSVG from "../../Svg/PendingStatus";
import RejectedSVG from "../../Svg/RejectedStatus";
import MissingSVG from "../../Svg/MissingStatus";
import { IconButton } from "@mui/material";
import { EditIcon, TrashIcon, InfoIcon } from "components/Common/Icons/Iconography";
import {withSnackbar} from "utils/withSnackbar";
import {useUploadedDocs} from "../../../context/UploadDocumentsProvider";
import DirectFileUpload from "../DirectFileUpload/DirectFileUpload";
import {
  setDocViewAC,
  setFoldersForDealTypeTC,
} from "../../../redux/reducers/dealsReducer";
import DealOverlayDocumentViewPopup from "components/Dashboard/DealsList/components/DealOverlay/DealOverlayDocumentViewPopup";
import DealDocumentEditPopup from './DealDocumentEditPopup'
import DeededPopup from "v2/components/DeededPopup";
import {formatRelativeDate} from "utils/date";
import configuration from "utils/configuration";
import useUser from "utils/hooks/useUser";
import GenericDialog from "../../Common/Modal/GenericDialog";
import Colors from "components/Common/componentStyling/Colors";
import { BodySmaller } from "components/CommonDashboard/Typography";

const professionalsList = [
  "Realtor",
  "Real Estate/Mortgage Team",
  "Broker",
  "Lender",
];
const permissionList = [
  "Admin",
    "System Admin",
  "Lawyer",
  "Law Clerk",
  "Mobile Signer",
  "Closing Advisor",
  "Document Support Specialist",
];
const DealDocuments = ({
  dealID,
  dealType,
  dealProvince,
  user,
  enqueueSnackbar,
}) => {
  const { isProfessional } = useUser();
  const mixpanel = useMixpanel();
  const {loading} = useUploadedDocs();
  const [pending, setPending] = useState(null);
  const [approved, setApproved] = useState(null);
  const [rejected, setRejected] = useState(null);
  const [missing, setMissing] = useState(null);
  const [toggleAddFile, setToggleAddFile] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [showDocumentPopup, setShowDocumentPopup] = useState(false);
  const [updateList, setUpdateList] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [showAdditionalDocs, setShowAdditionalDocs] = useState(false);
  const [directFile, setDirectFile] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDocumentTitleModal, setShowDocumentTitleModal] = useState(false);
  const [currentEditDocument, setCurrentEditDocument] = useState({});
  const [confirmNotifyOpen, setConfirmNotifyOpen] = useState(false);
  const [notifyLoading, setNotifyLoading] = useState(false);

  const [deleteDocName, setDeleteDocName] = useState("");
  const [deleteDocID, setDeleteDocID] = useState(null);
  const [dealAddress, setDealAddress] = useState(null);
  const [dealStageNum, setDealStageNum] = useState(null);
  const [alertShown, setAlertShown] = useState(false);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [documentsRequest, setDocumentsRequest] = useState(false);
  const dispatch = useDispatch();
  const latestNotification = useMemo(
    () => (notifications ? notifications[notifications.length - 1] : null),
    [notifications],
  );
  const {location} = useHistory();
  const skipNotifyAlert = useMemo(() => {
    const query = new URLSearchParams(location.search);
    return Boolean(query.get('skip_notify_alert'));
  }, [location.search]);

  const lastDocumentUpdate = (document_updates) => {
    return document_updates[document_updates.length - 1] === undefined ? ""
      : document_updates[document_updates.length - 1];
  }

  useEffect(() => {
    dispatch(setFoldersForDealTypeTC(dealType, dealProvince));
  }, [dealType, dispatch, dealProvince]);
  const toggleAdditionalDocs = () => {
    if (document.querySelector(".addfile__wrapper") === null) {
      setShowAdditionalDocs(!showAdditionalDocs);
      return;
    }
  };

  const userPermission = permissionList.find((el) => el === user.role);
  const toggleDocumentPopup = () => {
    setShowDocumentPopup(!showDocumentPopup);
    setUpdateList(!updateList);
  };

  const toggleAddFilePopup = () => {
    if (document.querySelector(".additional__documents__wrapper") === null) {
      setToggleAddFile(!toggleAddFile);
      setUpdateList(!updateList);
      return;
    }
  };
  const toggleDirectFilesPopup = () => {
    setUpdateList(!updateList);
  };

  const toggleRequestDocuments = () => {
    setDocumentsRequest(!documentsRequest);
  };

  const handleVerifyNotification = () => {
    if (
      latestNotification &&
      differenceInHours(Date.now(), new Date(latestNotification.updated_at)) <
      24
    ) {
      setConfirmNotifyOpen(true);
      return;
    }

    handleSendNotification();
  };

  const handleSendNotification = () => {
    if (notifyLoading) {
      return;
    }

    setConfirmNotifyOpen(false);
    setNotifyLoading(true);
    // Send data for mixpanel analytics for Admin and Sub-admin role only
    const {role, email, full_name} = user;
    const mixPayload = {
      role,
      email,
      full_name,
      deal_id: dealID,
      deal_type: dealType,
    };
    mixpanel.track(`${role}_notify_client_of_missing_documents`, mixPayload);

    axios
      .post(`${baseURL}/deals/${dealID}/notify`)
      .then((res) => {
        enqueueSnackbar("Notification send", {
          variant: "success",
          autoHideDuration: 1500,
        });
        setUpdateList(!updateList);
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.error, {
          variant: "error",
          autoHideDuration: configuration.autoHideErrorDuration,
        });
      })
      .finally(() => setNotifyLoading(false));
  };

  const handleDocumentEdit = (e, doc) => {
    setCurrentEditDocument(doc)
    toggleEditDocumentPopup();
  }

  const toggleEditDocumentPopup = () => {
    setShowDocumentTitleModal(!showDocumentTitleModal)
  };

  const handleDocumentView = (e, doc) => {
    if (user.role === "Client") {
      e.preventDefault();
      return;
    }
    setSelectedDocument(doc);
    toggleDocumentPopup();
  };
  const handleDeleteMissingDocument = () => {
    axios
      .post(`${baseURL}/agreements/${deleteDocID}/deleteMissing`)
      .then((res) => {
        enqueueSnackbar("Deleting missing document", {
          variant: "success",
          autoHideDuration: 1500,
        });
        setUpdateList(!updateList);
      })
      .catch((error) => {
        enqueueSnackbar(error.response?.data.error, {
          variant: "error",
          autoHideDuration: configuration.autoHideErrorDuration,
        });
      })
      .finally(() => setShowDeleteModal(false));
  };

  useEffect(() => {
    let isRendered = true;
    if (dealID || updateList || documentsRequest || !loading) {
      getDealByID(dealID)
        .then((res) => {
          if (!isRendered) return;
          const {agreements, missingDocuments, document_notifications} =
            res.data;

          setDealAddress(res?.data?.address);
          setDealStageNum(res?.data?.deal_stage?.number || 0);
          setNotifications(document_notifications);
          setPending(
            agreements.filter((doc) =>
              doc.status === "Pending" ? true : null,
            ),
          );
          setApproved(
            agreements.filter((doc) =>
              doc.status === "Approved" ? true : null,
            ),
          );
          setRejected(
            agreements.filter((doc) =>
              doc.status === "Rejected" ? true : null,
            ),
          );
          setMissing(missingDocuments);
        })
        .catch((error) => {});
    }
    return () => {isRendered = false;};
  }, [dealID, updateList, documentsRequest, loading]);

  /**
   * Show alert for deals that match all the following rules:
   * - Client has completed the QnA part of the intake
   * - Has missing documents
   * - If present, the latest notification is older than the one of the missing documents
   * - If present, the latest notification is older than 24h
   * Skip if user is coming from a deal tab
   */
  useEffect(() => {
    if (isProfessional || skipNotifyAlert || alertShown || !missing || loading || dealStageNum < 2) {
      return;
    }

    if (latestNotification) {
      const hasRecentDocument =
        missing.findIndex((miss) =>
          isBefore(
            new Date(latestNotification.updated_at),
            new Date(miss.updated_at),
          ),
        ) > -1;
      const lastNotificationTime = differenceInHours(
        Date.now(),
        new Date(latestNotification.updated_at),
      );

      if (!hasRecentDocument || lastNotificationTime <= 24) {
        return;
      }
    }

    setAlertShown(true);
    enqueueSnackbar(
      "Client hasn't received email with latest requested document for submission. Please notify the client.",
      {
        variant: "warning",
        autoHideDuration: 6000,
      },
    );
  }, [
    dealStageNum,
    missing,
    latestNotification,
    enqueueSnackbar,
    loading,
    setAlertShown,
    alertShown,
    isProfessional,
  ]);

  useEffect(() => {
    if (!dealType || !dealProvince) return;
    getDocumentsTypes({dealType, province: dealProvince}).then((res) =>
      setDocumentTypes((res?.data || []).sort((d1, d2) => d1.document_type.localeCompare(d2.document_type)))
    )
  }, [dealType, dealProvince]);

  const isCurrUserProfessional = professionalsList.find(
    (el) => el === user.role,
  );
  if (isCurrUserProfessional) {
    return (
    <>
    <h2>Transaction Documents</h2>
      <div className="documents__title-wrapper">
        <div className="title-item">
          Approved
        <span className="title-item-tooltip">
            ?
            <div className="title-item-tooltip__content">
            We received your document and it has been
            reviewed and acknowledged by our team.
            </div>
        </span>
        </div>
        <div className="title-item">
          Awaiting Approval
        <span className="title-item-tooltip">
            ?
            <div className="title-item-tooltip__content">
            We received your document and our team members
            are reviewing it.
            </div>
        </span>
        </div>
        <div className="title-item">
          Rejected
        <span className="title-item-tooltip">
            ?
            <div className="title-item-tooltip__content">
            We received your document and there may be some
            issues or additional clarification required.
            </div>
        </span>
        </div>
    </div>
      <div className="documents__list-wrapper">
        {/* ADDITIONAL DOCUMENTS */}
        {showAdditionalDocs && (
          <AdditionalDocuments
            open={showAdditionalDocs}
            toggleAdditionalDocs={toggleAdditionalDocs}
            handleDocumentsRequest={toggleRequestDocuments}
            dealID={dealID}
            dealType={dealType}
            documentTypes={documentTypes}
          />
        )}

        {toggleAddFile && (
          <AddFile
            toggleAddFilePopup={toggleAddFilePopup}
            dealType={dealType}
            dealID={dealID}
            dealAddress={dealAddress}
            documentTypes={documentTypes}
          />
        )}
        <div className="documents__list scroller">
          {/* APPROVED */}
          <div className="documents__list--item">
            <div className="list__title">approved documents</div>
            {approved &&
              approved.map((doc) => {
                const {id, original_filename, alias} = doc;
                const title = alias === null ? "No document name" : alias;

                return (
                  <div key={id} className="document">
                    <div>
                      <ApprovedSVG />
                    </div>
                    <div
                      className="document__data"
                      onClick={(e) => handleDocumentView(e, doc)}
                    >
                      <div className="document__title">{title}</div>
                      <div className="document__name">
                        {original_filename.slice(0, 30)}
                      </div>
                      <div className="document__info"></div>
                    </div>
                  </div>
                );
              })}
          </div>
          {/* PENDING */}
          <div className="documents__list--item">
            <div className="list__title">pending documents</div>
            {pending &&
              pending.map((doc) => {
                const {id, original_filename, alias} = doc;
                const title = alias === null ? "No document name" : alias;

                return (
                  <div key={id} className="document">
                    <div>
                      <PendingSVG />
                    </div>
                    <div
                      className="document__data"
                      onClick={(e) => {
                        handleDocumentView(e, doc);
                      }}
                    >
                      <div className="document__title">{title}</div>
                      <div className="document__name">
                        {original_filename.slice(0, 30)}
                      </div>
                      <div className="document__info"></div>
                    </div>
                  </div>
                );
              })}
          </div>
          {/* REJECTED */}
          <div className="documents__list--item">
            <div className="list__title">rejected documents</div>
            {rejected &&
              rejected.map((doc) => {
                const {id, original_filename, document_updates, alias} = doc;
                const {comment, action, rejection_reason} = lastDocumentUpdate(document_updates);

                const title = alias === null ? "No document name" : alias;

                return (
                  <div key={id} className="document">
                    <div>
                      <RejectedSVG />
                    </div>
                    <div
                      className="document__data"
                      onClick={(e) => handleDocumentView(e, doc)}
                    >
                      <div className="document__title">
                        {title}
                        <div className="question">
                          <InfoIcon size={16} color={Colors.BLACK}/>
                          <div className="document__reason">{action === "Rejected" ? rejection_reason || comment : comment}</div>
                        </div>
                      </div>
                      <div className="document__name">
                        {original_filename.slice(0, 30)}
                      </div>
                      <div className="document__info"></div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="deal-documents__buttons">
          <button className="addfile--btn" onClick={toggleAddFilePopup}>
            upload new document
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="20px"
              height="20px"
            >
              <path
                fillRule="evenodd"
                d="M 11 2 L 11 11 L 2 11 L 2 13 L 11 13 L 11 22 L 13 22 L 13 13 L 22 13 L 22 11 L 13 11 L 13 2 Z"
              />
            </svg>
          </button>
        </div>
      </div>
    </>
    );
  }
  return (
    <>
    <h2>Transaction Documents</h2>
      <div className="documents__title-wrapper">
        <div className="title-item">
          Approved
        </div>
        <div className="title-item">
          Awaiting Approval
        </div>
        <div className="title-item">
          Rejected
        </div>
        <div className="title-item">Missing</div>
    </div>
    <div className="documents__list-wrapper">
      {/* DELETE MODAL */}
      {showDeleteModal && (
        <DeededPopup
          sx={{}}
          open={showDeleteModal}
          setOpen={() => {
            setDeleteDocName("");
            setShowDeleteModal(!showDeleteModal);
          }}
          title="Are you sure, you want to delete this missing document?"
          sxInternalBox={{
            width: "380px",
            overflowX: "hidden",
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              width: "5px",
              scrollbarWidth: "thin",
            },
          }}
        >
          <div className="document__delete-modal-wrapper">
            <div tabIndex={1} className="document__delete-modal">
              <div className="document__delete-card">
                <p className="document__delete-name">{deleteDocName}</p>
                <div className="document__delete-card-buttons">
                  <div className="yes" onClick={handleDeleteMissingDocument}>
                    yes
                  </div>
                  <div className="no" onClick={() => setShowDeleteModal(false)}>
                    no
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DeededPopup>
      )}

      {showDocumentTitleModal && (
        <DealDocumentEditPopup
          open={showDocumentTitleModal}
          agreementID={currentEditDocument.id}
          title={currentEditDocument.alias}
          description={currentEditDocument.description}
          enqueueSnackbar={enqueueSnackbar}
          updateList={updateList}
          setUpdateList={setUpdateList}
          toggleEditDocumentPopup={toggleEditDocumentPopup}
          setShowDocumentTitleModal={setShowDocumentTitleModal}
          />
      )}

      {/* ADD FILE  */}
      {toggleAddFile && (
        <AddFile
          open={toggleAddFile}
          toggleAddFilePopup={toggleAddFilePopup}
          dealType={dealType}
          dealID={dealID}
          dealAddress={dealAddress}
          documentTypes={documentTypes}
        />
      )}
      {showAdditionalDocs && (
        <AdditionalDocuments
          toggleAdditionalDocs={toggleAdditionalDocs}
          handleDocumentsRequest={toggleRequestDocuments}
          dealID={dealID}
          dealType={dealType}
          documentTypes={documentTypes}
        />
      )}
      {directFile !== null && (
        <DirectFileUpload
          dealID={dealID}
          dealType={dealType}
          directFile={directFile}
          open={directFile !== null}
          handleClose={() => setDirectFile(null)}
          toggleDirectFilesPopup={toggleDirectFilesPopup}
        />
      )}
      <div className="documents__list scroller">
        {/* DOCUMENT VIEW */}
        {showDocumentPopup && (
          <DealOverlayDocumentViewPopup
            dealID={dealID}
            dealType={dealType}
            selectedDocument={selectedDocument}
            toggleDocumentPopup={toggleDocumentPopup}
            showDocumentPopup={showDocumentPopup}
            setShowDocumentPopup={setShowDocumentPopup}
          />
        )}
        {/* APPROVED */}
        <div className="documents__list--item">
          <div className="list__title">approved documents</div>
          {approved &&
            approved.map((doc) => {
              const {id, original_filename, alias, storage_id} = doc;
              const title = alias === null ? "No document name" : alias;

              return (
                <div key={id} className="document" data-testid="deal-approved-document">
                  <div>
                    <ApprovedSVG />
                  </div>
                  <div
                    className="document__data"
                    onClick={(e) => {
                      if (!storage_id) return;

                      handleDocumentView(e, doc);
                      dispatch(setDocViewAC(doc));
                    }}
                  >
                    <div className="document__title" data-testid="deal-approved-document-title">{title}</div>
                      {storage_id ? (
                          <div className="document__name" data-testid="deal-approved-document-name">
                              {original_filename?.slice(0, 30)}
                          </div>
                      ) : (
                          <BodySmaller sx={{ lineHeight: "1rem" }} color={Colors.RED_300}>
                              <strong>Corrupted file! Request a new file</strong>
                          </BodySmaller>
                      )}
                    <div className="document__info"></div>
                  </div>
                  {storage_id && (
                    <div style={{paddingRight: '4rem'}}>
                      <IconButton onClick={(e) => handleDocumentEdit(e, doc)}>
                        <EditIcon color="#000"/>
                      </IconButton>
                    </div>
                  )}
                </div>
              );
            })}
        </div>
        {/* PENDING */}
        <div className="documents__list--item">
          <div className="list__title">pending documents</div>
          {pending &&
            pending.map((doc) => {
              const {id, original_filename, alias} = doc;
              const title = alias === null ? "No document name" : alias;

              return (
                <div key={id} className="document" data-testid="deal-pending-document">
                  <div>
                    <PendingSVG />
                  </div>
                  <div
                    className="document__data"
                    onClick={(e) => {
                      handleDocumentView(e, doc);
                      dispatch(setDocViewAC(doc));
                    }}
                  >
                    <div className="document__title" data-testid="deal-pending-document-title">{title}</div>
                    <div className="document__name" data-testid="deal-pending-document-name">
                      {original_filename.slice(0, 30)}
                    </div>
                    <div className="document__info"></div>
                  </div>
                  <div style={{paddingRight: '4rem'}}>
                    <IconButton onClick={(e) => handleDocumentEdit(e, doc)}>
                      <EditIcon color="#000"/>
                    </IconButton>
                  </div>
                </div>
              );
            })}
        </div>
        {/* REJECTED */}
        <div className="documents__list--item">
          <div className="list__title">rejected documents</div>
          {rejected &&
            rejected.map((doc) => {
              const {id, original_filename, document_updates, alias, storage_id} = doc;
              const {comment, action, rejection_reason} = lastDocumentUpdate(document_updates);

              const title = alias === null ? "No document name" : alias;

              return (
                <div key={id} className="document" data-testid="deal-rejected-document">
                  <div>
                    <RejectedSVG />
                  </div>
                  <div
                    className="document__data"
                    onClick={(e) => {
                      if (!storage_id) return;
                      handleDocumentView(e, doc);
                      dispatch(setDocViewAC(doc));
                    }}
                  >
                    <div className="document__title">
                      <div data-testid="deal-rejected-document-title">{title}</div>
                      <div className="question">
                        <InfoIcon size={16} color={Colors.BLACK}/>
                        <div className="document__reason" data-testid="deal-rejected-document-reason">{action === "Rejected" ? rejection_reason || comment : comment}</div>
                      </div>
                    </div>
                      {storage_id ? (
                          <div className="document__name" data-testid="deal-rejected-document-name">
                              {original_filename.slice(0, 30)}
                          </div>
                      ) : (
                          <BodySmaller sx={{ lineHeight: "1rem" }} color={Colors.RED_300}>
                              <strong>Corrupted file! Request a new file</strong>
                          </BodySmaller>
                      )}
                    <div className="document__info"></div>
                  </div>
                  {storage_id && (
                    <div style={{paddingRight: '4rem'}}>
                      <IconButton onClick={(e) => handleDocumentEdit(e, doc)}>
                        <EditIcon color="#000"/>
                      </IconButton>
                    </div>
                  )}
                </div>
              );
            })}
        </div>
        {/* MISSING */}
        <div className="documents__list--item">
          <div className="list__title">missing documents</div>
          {missing &&
            missing.map((doc, idx) => {
              const {alias, id} = doc;

              return (
                <div key={idx} className="document" data-testid="deal-missing-document">
                  <div>
                    <MissingSVG />
                  </div>
                  <div className="document__data">
                    <div
                      onClick={() => setDirectFile(doc)}
                      className="document__title"
                      style={{
                        paddingBottom: "10px",
                        borderBottom: "1px solid #000",
                      }}
                      data-testid="deal-missing-document-title"
                    >
                      {alias}
                    </div>
                    <div className="document__name"></div>
                    <div className="document__info"></div>
                  </div>
                  <div>
                    <IconButton onClick={(e) => handleDocumentEdit(e, doc)}>
                      <EditIcon color="#000"/>
                    </IconButton>
                  </div>
                  <IconButton
                    onClick={() => {
                      setDeleteDocID(id);
                      setDeleteDocName(alias);
                      setShowDeleteModal(true);
                    }}
                  >
                    <TrashIcon color="#000" />
                  </IconButton>
                </div>
              );
            })}
        </div>
      </div>
      {user.role !== "Client" && (
        <div className="deal-documents__buttons">
          <button className="addfile--btn" data-testid="deal-add-new-document-btn" onClick={toggleAddFilePopup}>
            upload new document
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="20px"
              height="20px"
            >
              <path
                fillRule="evenodd"
                d="M 11 2 L 11 11 L 2 11 L 2 13 L 11 13 L 11 22 L 13 22 L 13 13 L 22 13 L 22 11 L 13 11 L 13 2 Z"
              />
            </svg>
          </button>
          <button className="addfile--btn" onClick={toggleAdditionalDocs}>
            request new document
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="20px"
              height="20px"
            >
              <path
                fillRule="evenodd"
                d="M 11 2 L 11 11 L 2 11 L 2 13 L 11 13 L 11 22 L 13 22 L 13 13 L 22 13 L 22 11 L 13 11 L 13 2 Z"
              />
            </svg>
          </button>
          {userPermission && (
            <button
              className="addfile--notification"
              disabled={!Boolean(missing?.length > 0 && !notifyLoading)}
              onClick={handleVerifyNotification}
            >
              Notify the user
            </button>
          )}
          {latestNotification && (
            <div className="deal-documents__logs">
              Last update{" "}
              {formatRelativeDate(latestNotification.updated_at)} by{" "}
              {latestNotification.user?.role}{" "}
              {latestNotification.user?.full_name}
            </div>
          )}
        </div>
      )}
      <GenericDialog
        action="secondary"
        title="Confirm Notification"
        onCancel={() => setConfirmNotifyOpen(false)}
        onSubmit={handleSendNotification}
        submitText="Send"
        open={confirmNotifyOpen}
        contentText="A notification was sent less than 24 hours ago, are you sure?"
      />
    </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.AuthReducer.currentUser,
  };
};

export default withSnackbar(connect(mapStateToProps, null)(DealDocuments));