import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { getProfilePartnerPage, updateProfilePartnerPage } from "api";
import configuration from "utils/configuration";
import { PartnerPage } from "types/partnerPage";

const useProfilePartnerPage = () => {
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const [partnerPage, setPartnerPage] = useState<PartnerPage>();

    const update = async (enabled: boolean, pageName?: string) => {
        if (loading) return false;
        
        if (pageName && pageName.length < 2) {
            return enqueueSnackbar('Single letter is not accepted. Please add your full name.', {
                variant: "error",
                autoHideDuration: configuration.autoHideErrorDuration,
            })
        }

        setLoading(true);
        try {
            const data = { enabled } as { enabled: boolean; page_name?: string };
            if (pageName) {
                data.page_name = pageName;
            }
            const res = await updateProfilePartnerPage(data);

            setPartnerPage(JSON.stringify(res.data) == '{}' ? undefined : res.data as PartnerPage);
            return true;
        } catch (e) {
            const error = e as { response: { data: { error: string } } };
            enqueueSnackbar(error.response.data.error ?? "Failed to save settings.", {
                variant: "error",
                autoHideDuration: configuration.autoHideErrorDuration,
            });
            return false;
        } finally {
            setLoading(false);
        }
    };

    const refresh = async () => {
        if (loading) return;
        setLoading(true);
        try {
            const res = await getProfilePartnerPage();
            setPartnerPage(res.data);
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        refresh();
    }, []);

    return [partnerPage, loading, update, refresh] as const;
};

export default useProfilePartnerPage;
