import React from "react";
import {IconButton, styled, SxProps} from "@mui/material";
import constants from "../../styles/constants";
import {Theme} from "@mui/material/styles";

const DeededScrollToTopIconButton: React.FC<{
  sx?: SxProps<Theme>;
  onClick?: () => void;
}> = ({sx, onClick}) => {
  return (
    <>
      <StyledIconButton
        onClick={() => {
          if (onClick) {
            onClick();
          }
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }}
        sx={{
          ...sx,
        }}
      >
        <ArrowSvg />
      </StyledIconButton>
    </>
  );
};
const ArrowSvg = () => {
  return (
    <svg
      width="22"
      height="12"
      viewBox="0 0 22 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 11L11 1L21 11"
        stroke="#F16764"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
const StyledIconButton = styled(IconButton)({
  width: "56px",
  height: "56px",
  backgroundColor: constants.colors.redVeryPale,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  bottom: "20px",
  right: "20px",
  position: "fixed",
  "&:active": {
    backgroundColor: constants.colors.redVeryPale,
  },
  "&:hover": {
    backgroundColor: constants.colors.redVeryPale,
  },
});

export default DeededScrollToTopIconButton;
