import heic2any from "heic2any";

export const returnConvertedFromHeicFile = async (file: File) => {
  if (
    file.name.toLowerCase().endsWith(".heic") ||
    file.type.toLowerCase() === "image/heic"
  ) {
    try {
      const convertedFile = (await heic2any({
        blob: file,
        toType: "image/jpeg",
        quality: 1,
      })) as Blob & {
        name: string;
        lastModified: Date;
      };
      convertedFile.name = `${file.name.slice(0, -5)}.jpeg`;
      convertedFile.lastModified = new Date();
      return new File([convertedFile], `${file.name.slice(0, -5)}.jpeg`, {
        type: "image/jpeg",
      });
    } catch (e) {
      console.error(e);
      return file;
    }
  } else {
    return file;
  }
};
