import {User} from "../../../../types/user";

export const setInitialItemCalendar = (
  user: {isAdmin: boolean; user: Partial<User>},
  allLabelsList: string[],
  item: "region" | "view",
) => {
  if (user.user.settings?.calendar) {
    let itemCalendar;
    if (item === "region") {
      itemCalendar =
        user.user.settings?.calendar[item]
          ?.split(",")
          ?.filter((region) => region !== "")
          ?.join(",") ?? "";
    } else {
      itemCalendar = user.user.settings?.calendar[item];
    }
    if (itemCalendar === null || itemCalendar === "null") {
      return [];
    } else {
      return itemCalendar === "ALL" && item === "region"
        ? allLabelsList
        : item === "region"
        ? itemCalendar.split(",")
        : itemCalendar;
    }
  } else {
    if (item === "region") {
      return allLabelsList;
    } else {
      return "day";
    }
  }
};
