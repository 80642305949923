import { Box, Stack, styled } from "@mui/material";
import Pill from "components/Common/StatusPills/Pill";
import { BodyBold, H2, H3 } from "components/Common/Typography";
import constants from "styles/constants";
import { Deal, StatusType } from "types/deal";
import { getDocumentName, getFilePillProps } from "utils/intakeHelper";


const borderStyle = `0.1rem solid ${constants.colors.gray300}`;

interface IntakeFileSectionInterface {
    deal: Deal
}

const FileList = ({ deal, types }: { deal: Deal, types: StatusType[] }) => {
    return <Stack borderBottom={borderStyle}>
        {deal.agreements?.filter((file) => types.includes(file.status)).map((agreement) => {
            return <RowWrapper key={agreement.id} flexDirection={'row'}>
                <BodyBold flexBasis={'58%'}>{getDocumentName(agreement)}</BodyBold>
                <Pill {...getFilePillProps(agreement.status)} ></Pill>
            </RowWrapper>;
        })}

    </Stack>
}

const IntakeFileSection = ({ deal }: IntakeFileSectionInterface) => {
    return <Stack gap={'2rem'}>
        <H2 marginTop={'1rem'}>Uploaded Docs</H2>
        <H3>Outstanding</H3>
        <FileList deal={deal} types={['Missing', 'Rejected']} />
        <H3>Received</H3>
        <FileList deal={deal} types={['Pending', 'Approved']} />
    </Stack>;
}

const RowWrapper = styled(Stack)(({ theme }) => ({
    flexFlow: "row nowrap",
    [theme.breakpoints.down("mobile")]: {
        flexFlow: "row wrap",
    },
    gap: "2rem",
    borderTop: `0.1rem solid ${constants.colors.gray300}`,
    padding: "1rem 0",
}));


export default IntakeFileSection;