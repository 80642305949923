import {useMemo, useState, useEffect} from "react";
import "./DealDetails.scss";
import Select from "react-select";
import {TextField, styled, Box, Typography, useTheme} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

import {connect} from "react-redux";
import TemplateEditor from "../TemplateEditor/TemplateEditor";
import useDealSelector from "utils/hooks/useDealSelector";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { formatMessageDate } from "./utils";

const DealChat = ({
  staff,
  user,
  data,
  text,
  loading,
  subjectline,
  province,
  clientsList,
  templatesList,
  handleTextField,
  handleSubjectField,
  handleTextFieldSubmit,
  handleCommunication,
  handleTemplatePopulate,
  handleTemplateSender,
  handleCCField,
  CCList,
  cc,
}) => {
  const deal = useDealSelector();
  const [currentTemplatesList, setCurentTemplatesList] = useState([]);
  const [ccValue, setCcValue] = useState("");
  useEffect(() => {
    if (Array.isArray(templatesList)) {
      const currentTemplatesList = templatesList.filter((template) => {
        if (template.province[0] === "ALL" || template.province[0] === province)
          return template;
      });
      setCurentTemplatesList(currentTemplatesList);
    }
  }, [templatesList, province]);
  const allUserParticipans = useMemo(() => {
    if (deal === null) {
      return null;
    }
    const {referringProfessional, participants} = deal;

    return [
      ...participants
        .filter((el) => el.is_referrer === 0)
        .map((x) => ({
          ...x,
          value: x.role + " - " + x.full_name,
          label: x.role + " - " + x.full_name,
        })),
      ...referringProfessional
        .map((x) => ({
          ...x,
          isReferringProfessional: true,
        }))
        .map((x) => ({
          ...x,
          value: `Referring Professional(${x.role}) - ${x.full_name}`,
          label: `Referring Professional(${x.role}) - ${x.full_name}`,
        })),
    ];
  }, [deal]);

  const handleOptionClick = (value) => {
    setCcValue(value.value);
    handleCCField({label: value.value, value: value.value});
  };
  return (
    <div className="dealdetails__contactform">
      <div className="dealdetails__contactform--comments">
        <h2>Messages</h2>
        <div className="comments__wrapper scroller">
          <div style={{height: "100%"}}>
            {data &&
              data.map((text) => {
                return (
                  <div key={text.id} className="comments__content" data-testid="chat-message-content">
                    <div
                      className="comment"
                      dangerouslySetInnerHTML={{__html: text.content}}
                      data-testid="chat-message"
                    ></div>

                    <div className="comment__details" data-testid="chat-message-details">
                      <span>
                        added on {formatMessageDate(new Date(text.created_at))}
                        <br />{" "}
                        {text.added_by && (
                          <>
                            by {text.added_by?.role} {text.added_by?.full_name}{" "}
                          </>
                        )}
                      </span>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <form
        onSubmit={handleTextFieldSubmit}
        className="dealdetails__contactform--message"
      >
        <div className="header">
          <h2>New Messages</h2>
          {staff && (
            <Select
              inputId="chat-select-from-field"
              styles={customStyles}
              options={[
                {label: user.full_name, value: user.id},
                ...staff.map((i) => ({
                  label: i.full_name,
                  value: i.id,
                })),
              ]}
              placeholder="Select from .."
              onChange={(e) => handleTemplateSender(e)}
            />
          )}
          {clientsList && (
            <Select
              inputId="chat-send-to-field"
              isMulti
              styles={templateStyles}
              options={allUserParticipans}
              onChange={handleCommunication}
              placeholder="Send to.."
            />
          )}
          {clientsList && (
            <Select
              inputId="chat-select-template-field"
              styles={templateStyles}
              options={currentTemplatesList}
              placeholder="Select template .."
              onChange={(e) => handleTemplatePopulate(e)}
            />
          )}
          {clientsList && (
            <CustomSelect>
              <Autocomplete
                data-testid="chat-cc-field"
                id="free-solo-demo"
                freeSolo
                options={CCList}
                value={ccValue}
                renderInput={(params) => (
                  <>
                    <TextField
                      {...params}
                      placeholder="CC..."
                      onChange={(e) =>
                        handleCCField({label: "email", value: e.target.value})
                      }
                    />
                    <ArrowBackIosIcon className="selectArrow" />
                  </>
                )}
                renderOption={(props, option) => {
                  return (
                    <CustomOption
                      {...props}
                      onClick={() => handleOptionClick(option)}
                    >
                      {option.label}
                    </CustomOption>
                  );
                }}
              />
            </CustomSelect>
          )}
        </div>
        {clientsList && (
          <div className="subjectline">
            <label htmlFor="subjectline">
              Subject:
              <input
                data-testid="chat-subject-field"
                required
                type="text"
                value={subjectline}
                onChange={(e) => handleSubjectField(e.target.value)}
              />
            </label>
          </div>
        )}
        <div data-testid="chat-message-field">
          <TemplateEditor
            empty={true}
            value={text}
            title={'Messages'}
            handleTextField={handleTextField}
          />
        </div>

        <div className="footer">
          <span />
          <SendButton
            data-testid="chat-send-button"
            disabled={loading}
            type="submit"
            className="send__btn"
          >
            send
          </SendButton>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.AuthReducer.currentUser,
  };
};
const SendButton = styled("button")(({theme}) => ({
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));
const CustomSelect = styled(Box)({
  width: "100%",
  height: "45px",
  position: "relative",

  "& .MuiOutlinedInput-root": {
    color: "black",
    fontSize: "1.4rem",
    height: "38px",
    display: "flex",
    alignItems: "center",
    padding: "0 5px !important",
    marginTop: "15px",
    position: "relative",
  },
  "& .MuiAutocomplete-endAdornment": {
    display: "none",
  },
  "& .selectArrow": {
    position: "absolute",
    top: "23px",
    right: "10px",
    transform: "rotate(270deg)",
    color: "hsl(0,0%,80%)",
  },
});

const CustomOption = styled(Typography)({
  fontSize: "1.4rem",
});

const customStyles = {
  container: (base) => ({
    ...base,
    width: "100%",
    height: "40px",
    "div::-webkit-scrollbar": {
      width: "5px",
      scrollbarWidth: "thin",
    },
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: "none",
  }),
};

const templateStyles = {
  container: (base) => ({
    ...base,
    width: "100%",
    height: "40px",
    marginTop: "15px",
    "div::-webkit-scrollbar": {
      width: "5px",
      scrollbarWidth: "thin",
    },
  }),
  valueContainer: (base) => ({
    ...base,
    maxHeight: "60px",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: 0,
    },
  }),
  menu: (base) => ({
    ...base,
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: "none",
  }),
};

export default connect(mapStateToProps, null)(DealChat);
