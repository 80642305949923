import useWindowWidth from "./useWindowWidth";

const useBreakPoints = () => {
  const windowWidth = useWindowWidth();

  return {
    isDesktopResolution: windowWidth > 1370,
    isMobileResolution: windowWidth < 570,
  };
};
export default useBreakPoints;
