import { Box, BoxProps, styled } from "@mui/material"
import { Header, HeaderProps } from "./Header/Header"
import { ReactNode } from 'react'

interface WrapperProps {
    stageName?: string,
    children: ReactNode,
    headerProps?: Partial<HeaderProps>,
    rootProps?: Partial<RootProps>
}

export const Wrapper = ({ stageName = "", children, headerProps, rootProps }: WrapperProps) => {
    return <Box>
        <Header title={stageName} {...headerProps} />
        <RootWrapper
            className={"main"} {...rootProps}>
            {children}
        </RootWrapper>
    </Box >
};

type RootProps = {
    gap?: string,
    color?: string
};

const RootWrapper = styled(Box, {
    shouldForwardProp: (props) => !["gap", "color"].includes(props as string)
})<BoxProps & RootProps>(({ theme, gap, color }) => ({
    display: "flex",
    flexFlow: "column",
    minHeight: "100vh",
    width: "100vw",
    alignItems: "center",
    paddingTop: '9.4rem',
    backgroundColor: color || 'white',
    gap: gap || '8rem',
    [theme.breakpoints.down("mobile")]: {
        padding: "9rem  1rem"
    }
}));