import {Dispatch} from "redux";
import {getSurveyData} from "../../api";
import {addErrorAC} from "./ErrorReducer";
import {SurveyData} from "./types/dealDetailsSurveyReducerType";

export interface DealDetailsSurveyState {
  surveyData: SurveyData[];
}

type DealDetailsSurveyActionType = "SET_SURVEY_DATA";

export type DealDetailsSurveyAction = {
  type: DealDetailsSurveyActionType;
  payload?: unknown;
};

const dealDetailsSurveyState: DealDetailsSurveyState = {
  surveyData: [],
};

export const DealDetailsSurveyReducer = (
  state = dealDetailsSurveyState,
  action: DealDetailsSurveyAction,
): DealDetailsSurveyState => {
  switch (action.type) {
    case "SET_SURVEY_DATA": {
      return {
        ...state,
        surveyData: action.payload as SurveyData[],
      };
    }
    default:
      return state;
  }
};
export const setSurveyDataAC = (payload: SurveyData[]) => ({
  type: "SET_SURVEY_DATA",
  payload,
});
export const getSurveyDataTC = (id: number) => async (dispatch: Dispatch) => {
  try {
    const surveyPromise = await getSurveyData(id);
    dispatch(setSurveyDataAC(surveyPromise.data));
  } catch (e) {
    const error = e as {response: {data: {error: string}}};
    dispatch(addErrorAC(error.response.data.error ?? "Cannot get survey data"));
  }
};
