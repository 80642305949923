import React, {useState} from "react";
import useThemedStyles from "utils/hooks/useThemedStyles";
import {Box, Button, styled, Typography} from "@mui/material";
import * as yup from "yup";
import {useFormik} from "formik";
import constants from "styles/constants";
import DeededLabel from "v2/components/DeededLabel";
import DeededInput from "v2/components/DeededInput";

import DeededSpace from "v2/components/DeededSpace";
import {Theme, useTheme} from "@mui/material/styles";
import {useDispatch} from "react-redux";
import {updatePasswordTC} from "redux/reducers/profileReducer";
import ProfileSecurityPasswordHints from "./components/ProfileSecurityPasswordHints";
import useTypedSelector from "utils/hooks/useTypedSelector";
import ProfileSecurityPageMtfAuthentication from "./components/ProfileMtf";
import {userPasswordRegex} from "customerPortal/hooks/useUserSecurityForm";

const ProfileSecurityTab = () => {
  const styles = useThemedStyles(stylesFactory);
  const [showHints, setShowHints] = useState(false);
  const [showPassword, setShowPassword] = useState({
    currentPassword: false,
    newPassword: false,
    confirmNewPassword: false,
  });
  const profile = useTypedSelector((state) => state.ProfileReducer.profile);
  const updatedPassword = profile?.updated_password;
  const schema = yup.object().shape({
    current_pass: yup.string().required("Field is required"),
    new_pass: yup
      .string()
      .required("Please enter new password")
      .matches(userPasswordRegex, "Please complete all required conditions"),
    confirm_new_pass: yup
      .string()
      .required("Please confirm your password")
      .oneOf([yup.ref("new_pass"), null], "Passwords must match"),
  });

  const dispatch = useDispatch();
  const initialValues = {
    current_pass: "",
    new_pass: "",
    confirm_new_pass: "",
  };
  const handleViewPassword = (
    fieldName: "currentPassword" | "newPassword" | "confirmNewPassword",
  ) => {
    setShowPassword({
      ...showPassword,
      [fieldName]: !showPassword[fieldName],
    });
  };

  const theme = useTheme();
  const onSubmit = ({
    current_pass,
    new_pass,
    confirm_new_pass,
  }: typeof initialValues) => {
    dispatch(updatePasswordTC(current_pass, new_pass, confirm_new_pass));
  };
  const {handleSubmit, values, errors, handleChange} = useFormik({
    validationSchema: schema,
    initialValues,
    onSubmit,
    validateOnChange: false,
    validateOnBlur: false,
  });
  return (
    <>
      <Box sx={styles.profileSecurityPageBox}>
        <Box sx={styles.profileSecurityBox}>
          <DeededSpace y={50} />
          <Box
            sx={{
              width: 568,
              [theme.breakpoints.down("lg")]: {
                width: "100%",
              },
            }}
          >
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "24px",
                lineHeight: "29px",
                display: "flex",
                alignItems: "center",
                color: constants.font,
              }}
            >
              Personal Information
            </Typography>
            <Form
              onSubmit={handleSubmit}
              sx={{
                [theme.breakpoints.down("sm")]: {
                  width: "100%",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  [theme.breakpoints.down("lg")]: {
                    width: "100%",
                  },
                }}
              >
                <InputBox>
                  <InputLabel title="Current Password">
                    <DeededInput
                      type={showPassword.currentPassword ? "input" : "password"}
                      sx={{
                        width: "568px",
                        [theme.breakpoints.down("sm")]: {
                          width: "100%",
                        },
                      }}
                      value={values.current_pass}
                      onChange={(e) => {
                        handleChange({
                          target: {
                            name: "current_pass",
                            value: e.target.value,
                          },
                        });
                      }}
                      error={errors.current_pass}
                      data-testid="current-password-input"
                    />
                    <EyeComponent
                      onClick={() => handleViewPassword("currentPassword")}
                      isShowedPass={showPassword.currentPassword}
                    />
                  </InputLabel>
                </InputBox>
              </Box>
              <DeededSpace y={24} />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  position: "relative",
                  [theme.breakpoints.down("lg")]: {
                    width: "100%",
                  },
                }}
              >
                {showHints && (
                  <ProfileSecurityPasswordHints newPass={values.new_pass} />
                )}

                <InputBox>
                  <InputLabel title="New Password">
                    <DeededInput
                      type={showPassword.newPassword ? "input" : "password"}
                      sx={{
                        width: "568px",
                        [theme.breakpoints.down("sm")]: {
                          width: "100%",
                        },
                      }}
                      value={values.new_pass}
                      onFocus={() => {
                        setShowHints(true);
                      }}
                      onBlur={() => {
                        setShowHints(false);
                      }}
                      onChange={(e) => {
                        handleChange({
                          target: {
                            name: "new_pass",
                            value: e.target.value,
                          },
                        });
                      }}
                      error={errors.new_pass}
                      data-testid="new-password-input"
                    />
                    <EyeComponent
                      onClick={() => handleViewPassword("newPassword")}
                      isShowedPass={showPassword.newPassword}
                    />
                  </InputLabel>
                </InputBox>
              </Box>
              <DeededSpace y={24} />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  [theme.breakpoints.down("lg")]: {
                    width: "100%",
                  },
                }}
              >
                <InputBox>
                  <InputLabel title="Confirm New Password">
                    <DeededInput
                      type={
                        showPassword.confirmNewPassword ? "input" : "password"
                      }
                      sx={{
                        width: "568px",
                        [theme.breakpoints.down("sm")]: {
                          width: "100%",
                        },
                      }}
                      value={values.confirm_new_pass}
                      onChange={(e) => {
                        handleChange({
                          target: {
                            name: "confirm_new_pass",
                            value: e.target.value,
                          },
                        });
                      }}
                      error={errors.confirm_new_pass}
                      data-testid="confirm-password-input"
                    />
                    <EyeComponent
                      onClick={() => handleViewPassword("confirmNewPassword")}
                      isShowedPass={showPassword.confirmNewPassword}
                    />
                  </InputLabel>
                </InputBox>
              </Box>

              <Button
                data-testid="save-changes-button"
                onClick={() => {}}
                sx={{
                  marginTop: "47px",
                  float: "right",
                  height: "43px",
                  width: "175px",
                  backgroundColor: "var(--red)",
                  border: "1px solid #F16764",
                  boxSizing: "border-box",
                  borderRadius: "5px",
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "14px",
                  lineHeight: "17px",
                  color: "#FFFFFF",
                  "&:hover": {
                    color: "var(--red)",
                  },
                  [theme.breakpoints.down("sm")]: {
                    width: "100%",
                  },
                }}
                type={"submit"}
              >
                Save Changes
              </Button>
            </Form>
          </Box>
        </Box>
        {updatedPassword && (
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "20px",
              lineHeight: "29px",
              display: "flex",
              alignItems: "center",
              color: constants.font,
              marginTop: "20px",
              [theme.breakpoints.down("sm")]: {
                fontSize: "14px",
                fontWeight: "500",
                whiteSpace: "nowrap",
              },
            }}
          >
            Last updated: {updatedPassword}
          </Typography>
        )}
        <ProfileSecurityPageMtfAuthentication />
      </Box>
    </>
  );
};

const EyeHidePasswordSvg = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 5L26 27"
        stroke="#828282"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.3625 19.7C18.4449 20.5406 17.2444 21.0048 16 21C14.9908 20.9999 14.0053 20.6945 13.173 20.1238C12.3407 19.5531 11.7006 18.7439 11.3368 17.8026C10.973 16.8613 10.9025 15.8319 11.1347 14.8498C11.3668 13.8677 11.8907 12.9788 12.6375 12.3"
        stroke="#828282"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.25 8.57495C4.15 11.15 2 16 2 16C2 16 6 25 16 25C18.3432 25.019 20.6572 24.4791 22.75 23.425"
        stroke="#828282"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.075 21.1375C28.8 18.7 30 16 30 16C30 16 26 7.00003 16 7.00003C15.133 6.99832 14.2675 7.0694 13.4125 7.21253"
        stroke="#828282"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.9375 11.0875C18.0008 11.289 18.9697 11.8309 19.6981 12.6313C20.4264 13.4318 20.8746 14.4475 20.975 15.525"
        stroke="#828282"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
const EyeShowPasswordSvg = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 7C6 7 2 16 2 16C2 16 6 25 16 25C26 25 30 16 30 16C30 16 26 7 16 7Z"
        stroke="#828282"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 21C18.7614 21 21 18.7614 21 16C21 13.2386 18.7614 11 16 11C13.2386 11 11 13.2386 11 16C11 18.7614 13.2386 21 16 21Z"
        stroke="#828282"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
const EyeComponent: React.FC<{onClick: () => void; isShowedPass: boolean}> = ({
  onClick,
  isShowedPass,
}) => {
  return (
    <>
      <EyeBox onClick={onClick}>
        {isShowedPass ? <EyeHidePasswordSvg /> : <EyeShowPasswordSvg />}
      </EyeBox>
    </>
  );
};

const EyeBox = styled(Box)({
  position: "absolute",
  top: "32px",
  right: "15px",
  cursor: "pointer",
});
const InputLabel = styled(DeededLabel)<{theme?: Theme}>(({theme}) => ({
  position: "relative",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    ".MuiFormControl-root": {
      width: "100%",
    },
  },
}));
const Form = styled("form")({
  marginTop: "40px",
});
const InputBox = styled(Box)<{theme?: Theme}>(({theme}) => ({
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));
const stylesFactory = (theme: Theme) => ({
  profileSecurityPageBox: {
    display: "flex",
    width: "100%",
    backgroundColor: "white",
    height: "100%",
    margin: "15px 15px 0 0",
    alignItems: "center",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "30px",
      paddingRight: "30px",
    },
  },
  profileSecurityBox: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
});

export default ProfileSecurityTab;
