import { Paper, styled } from "@mui/material"

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: "2rem",
    margin: "1rem 0",
    display: 'flex',
    flexDirection: 'column',
    gap: '4rem',
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)'
}));

export default StyledPaper;