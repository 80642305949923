import {FC} from "react";

const Hello2Image: FC = () => (
    <svg width="100%" viewBox="0 0 300 132" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_112_7426)">
            <path
                d="M270.609 54.6106H94.5972C93.8499 54.6106 93.1331 54.9076 92.6047 55.4364C92.0762 55.9651 91.7793 56.6822 91.7793 57.43C91.7793 58.1777 92.0762 58.8948 92.6047 59.4236C93.1331 59.9523 93.8499 60.2493 94.5972 60.2493H99.5828V127.697C99.5828 128.445 99.8797 129.162 100.408 129.691C100.937 130.22 101.653 130.517 102.401 130.517C103.148 130.517 103.865 130.22 104.393 129.691C104.922 129.162 105.219 128.445 105.219 127.697L105.261 60.2493H246.529L259.554 127.697C259.554 128.445 259.851 129.162 260.379 129.691C260.908 130.22 261.624 130.517 262.372 130.517C263.119 130.517 263.836 130.22 264.364 129.691C264.893 129.162 265.19 128.445 265.19 127.697L266.01 60.2493H270.609C271.356 60.2493 272.073 59.9523 272.601 59.4236C273.13 58.8948 273.427 58.1777 273.427 57.43C273.427 56.6822 273.13 55.9651 272.601 55.4364C272.073 54.9076 271.356 54.6106 270.609 54.6106Z"
                fill="#CCCCCC" />
            <path
                d="M125.106 42.0479V53.9871H95.2095C94.4259 54.0144 93.6447 53.887 92.9104 53.6122C92.176 53.3374 91.503 52.9206 90.9297 52.3855C90.3564 51.8505 89.894 51.2077 89.569 50.4938C89.2439 49.78 89.0626 49.0091 89.0353 48.2252C89.0081 47.4412 89.1354 46.6596 89.4101 45.9249C89.6847 45.1902 90.1014 44.5168 90.6361 43.9432C91.1709 43.3696 91.8134 42.907 92.5268 42.5818C93.2403 42.2566 94.0108 42.0751 94.7943 42.0479C94.9327 42.043 95.071 42.043 95.2095 42.0479L125.106 42.0479Z"
                fill="#F2F2F2" />
            <path
                d="M125.841 53.8953C125.841 54.017 125.792 54.1338 125.706 54.2199C125.62 54.306 125.504 54.3544 125.382 54.3545H94.677C93.0101 54.3533 91.4119 53.69 90.2337 52.5103C89.0554 51.3306 88.3936 49.7311 88.3936 48.0634C88.3936 46.3957 89.0554 44.7962 90.2337 43.6165C91.4119 42.4368 93.0101 41.7735 94.677 41.7723H125.382C125.442 41.7722 125.502 41.7839 125.558 41.807C125.614 41.83 125.664 41.8638 125.707 41.9064C125.75 41.9491 125.784 41.9998 125.807 42.0555C125.83 42.1113 125.842 42.1711 125.842 42.2315C125.842 42.2919 125.83 42.3518 125.807 42.4076C125.784 42.4633 125.75 42.514 125.707 42.5567C125.664 42.5993 125.614 42.6331 125.558 42.6561C125.502 42.6791 125.442 42.6909 125.382 42.6907H94.677C93.2542 42.6929 91.8905 43.2599 90.8852 44.2672C89.8799 45.2746 89.3152 46.6399 89.3152 48.0634C89.3152 49.4869 89.8799 50.8522 90.8852 51.8596C91.8905 52.8669 93.2542 53.4339 94.677 53.4361H125.382C125.504 53.4361 125.62 53.4845 125.706 53.5706C125.792 53.6567 125.841 53.7735 125.841 53.8953Z"
                fill="#F06A6A" />
            <path
                d="M125.107 44.8031H92.979C92.9547 44.8032 92.9314 44.7937 92.9141 44.7767C92.8968 44.7596 92.8869 44.7364 92.8867 44.7121C92.8865 44.6878 92.8959 44.6644 92.9129 44.6471C92.9299 44.6297 92.953 44.6198 92.9773 44.6194H125.107C125.131 44.6197 125.154 44.6295 125.171 44.6467C125.188 44.6639 125.197 44.6871 125.197 44.7113C125.197 44.7355 125.188 44.7587 125.171 44.7759C125.154 44.7931 125.131 44.8029 125.107 44.8031Z"
                fill="#CCCCCC" />
            <path
                d="M125.107 47.0073H92.979C92.9547 47.0074 92.9314 46.9979 92.9141 46.9809C92.8968 46.9638 92.8869 46.9406 92.8867 46.9163C92.8865 46.892 92.8959 46.8686 92.9129 46.8512C92.9299 46.8339 92.953 46.8239 92.9773 46.8236H125.107C125.131 46.8239 125.154 46.8336 125.171 46.8508C125.188 46.868 125.197 46.8913 125.197 46.9154C125.197 46.9396 125.188 46.9629 125.171 46.9801C125.154 46.9973 125.131 47.007 125.107 47.0073Z"
                fill="#CCCCCC" />
            <path
                d="M125.107 49.2115H92.979C92.9547 49.2116 92.9314 49.2021 92.9141 49.185C92.8968 49.168 92.8869 49.1448 92.8867 49.1205C92.8865 49.0962 92.8959 49.0728 92.9129 49.0554C92.9299 49.038 92.953 49.0281 92.9773 49.0278H125.107C125.131 49.028 125.154 49.0378 125.171 49.055C125.188 49.0722 125.197 49.0954 125.197 49.1196C125.197 49.1438 125.188 49.167 125.171 49.1842C125.154 49.2014 125.131 49.2112 125.107 49.2115Z"
                fill="#CCCCCC" />
            <path
                d="M125.107 51.4156H92.979C92.9547 51.4157 92.9314 51.4062 92.9141 51.3892C92.8968 51.3721 92.8869 51.3489 92.8867 51.3246C92.8865 51.3003 92.8959 51.2769 92.9129 51.2596C92.9299 51.2422 92.953 51.2323 92.9773 51.2319H125.107C125.131 51.2322 125.154 51.242 125.171 51.2592C125.188 51.2763 125.197 51.2996 125.197 51.3238C125.197 51.348 125.188 51.3712 125.171 51.3884C125.154 51.4056 125.131 51.4154 125.107 51.4156Z"
                fill="#CCCCCC" />
            <path
                d="M119.471 29.4692V41.4084H89.5737C87.9913 41.4635 86.4518 40.8874 85.294 39.8068C84.1361 38.7263 83.4547 37.2297 83.3996 35.6465C83.3446 34.0632 83.9204 32.523 85.0004 31.3645C86.0804 30.206 87.5762 29.5243 89.1586 29.4692C89.2969 29.4644 89.4353 29.4644 89.5737 29.4692H119.471Z"
                fill="#F2F2F2" />
            <path
                d="M120.205 41.3166C120.205 41.4384 120.157 41.5551 120.071 41.6412C119.984 41.7273 119.868 41.7757 119.746 41.7758H89.0413C87.3736 41.7758 85.7743 41.113 84.5951 39.9332C83.4159 38.7534 82.7534 37.1532 82.7534 35.4847C82.7534 33.8162 83.4159 32.2161 84.5951 31.0363C85.7743 29.8565 87.3736 29.1937 89.0413 29.1937H119.746C119.806 29.1937 119.866 29.2055 119.922 29.2286C119.977 29.2517 120.028 29.2855 120.071 29.3282C120.113 29.3708 120.147 29.4214 120.17 29.4771C120.193 29.5328 120.205 29.5926 120.205 29.6529C120.205 29.7132 120.193 29.7729 120.17 29.8286C120.147 29.8843 120.113 29.9349 120.071 29.9776C120.028 30.0202 119.977 30.054 119.922 30.0771C119.866 30.1002 119.806 30.1121 119.746 30.1121H89.0413C87.6171 30.1121 86.2512 30.6781 85.2442 31.6857C84.2371 32.6933 83.6714 34.0598 83.6714 35.4847C83.6714 36.9097 84.2371 38.2762 85.2442 39.2838C86.2512 40.2914 87.6171 40.8574 89.0413 40.8574H119.746C119.868 40.8574 119.984 40.9058 120.071 40.9919C120.157 41.078 120.205 41.1948 120.205 41.3166Z"
                fill="#3F3D56" />
            <path
                d="M119.471 32.2244H87.3433C87.319 32.2245 87.2956 32.215 87.2783 32.198C87.261 32.1809 87.2512 32.1577 87.251 32.1334C87.2508 32.1091 87.2602 32.0857 87.2771 32.0683C87.2941 32.051 87.3173 32.041 87.3416 32.0407H119.471C119.495 32.041 119.518 32.0507 119.535 32.0679C119.552 32.0851 119.562 32.1083 119.562 32.1325C119.562 32.1567 119.552 32.18 119.535 32.1972C119.518 32.2144 119.495 32.2241 119.471 32.2244Z"
                fill="#CCCCCC" />
            <path
                d="M119.471 34.4286H87.3433C87.319 34.4287 87.2956 34.4192 87.2783 34.4021C87.261 34.3851 87.2512 34.3619 87.251 34.3376C87.2508 34.3133 87.2602 34.2899 87.2771 34.2725C87.2941 34.2551 87.3173 34.2452 87.3416 34.2449H119.471C119.495 34.2451 119.518 34.2549 119.535 34.2721C119.552 34.2893 119.562 34.3125 119.562 34.3367C119.562 34.3609 119.552 34.3841 119.535 34.4013C119.518 34.4185 119.495 34.4283 119.471 34.4286Z"
                fill="#CCCCCC" />
            <path
                d="M119.471 36.6327H87.3433C87.319 36.6328 87.2956 36.6233 87.2783 36.6063C87.261 36.5892 87.2512 36.566 87.251 36.5417C87.2508 36.5174 87.2602 36.494 87.2771 36.4767C87.2941 36.4593 87.3173 36.4494 87.3416 36.449H119.471C119.495 36.4493 119.518 36.4591 119.535 36.4763C119.552 36.4934 119.562 36.5167 119.562 36.5409C119.562 36.5651 119.552 36.5883 119.535 36.6055C119.518 36.6227 119.495 36.6325 119.471 36.6327Z"
                fill="#CCCCCC" />
            <path
                d="M119.471 38.8369H87.3433C87.319 38.837 87.2956 38.8275 87.2783 38.8104C87.261 38.7934 87.2512 38.7702 87.251 38.7459C87.2508 38.7216 87.2602 38.6982 87.2771 38.6808C87.2941 38.6634 87.3173 38.6535 87.3416 38.6532H119.471C119.495 38.6534 119.518 38.6632 119.535 38.6804C119.552 38.6976 119.562 38.7208 119.562 38.745C119.562 38.7692 119.552 38.7925 119.535 38.8097C119.518 38.8268 119.495 38.8366 119.471 38.8369Z"
                fill="#CCCCCC" />
            <path
                d="M154.25 96.8585V86.4485C154.25 85.9884 154.067 85.547 153.742 85.2217C153.417 84.8963 152.976 84.7135 152.516 84.7135H113.065C112.605 84.7135 112.164 84.8963 111.839 85.2217C111.514 85.547 111.331 85.9884 111.331 86.4485V96.8585H154.25Z"
                fill="#CCCCCC" />
            <path d="M154.25 100.762H111.331V114.208H154.25V100.762Z" fill="#CCCCCC" />
            <path
                d="M111.331 118.112V128.522C111.331 128.982 111.514 129.424 111.839 129.749C112.164 130.074 112.605 130.257 113.065 130.257H152.516C152.976 130.257 153.417 130.074 153.742 129.749C154.067 129.424 154.25 128.982 154.25 128.522V118.112H111.331Z"
                fill="#CCCCCC" />
            <path
                d="M132.574 92.521C133.292 92.521 133.874 91.9384 133.874 91.2198C133.874 90.5011 133.292 89.9185 132.574 89.9185C131.855 89.9185 131.273 90.5011 131.273 91.2198C131.273 91.9384 131.855 92.521 132.574 92.521Z"
                fill="white" />
            <path
                d="M132.574 109.871C133.292 109.871 133.874 109.288 133.874 108.57C133.874 107.851 133.292 107.268 132.574 107.268C131.855 107.268 131.273 107.851 131.273 108.57C131.273 109.288 131.855 109.871 132.574 109.871Z"
                fill="white" />
            <path
                d="M132.643 126.854C133.362 126.854 133.944 126.271 133.944 125.552C133.944 124.834 133.362 124.251 132.643 124.251C131.925 124.251 131.343 124.834 131.343 125.552C131.343 126.271 131.925 126.854 132.643 126.854Z"
                fill="white" />
            <path
                d="M149.384 72.8441V84.7833H119.487C117.905 84.8373 116.367 84.2607 115.21 83.1802C114.053 82.0998 113.372 80.6039 113.317 79.0214C113.262 77.4389 113.837 75.8993 114.916 74.7409C115.995 73.5826 117.49 72.9003 119.072 72.8441C119.21 72.8392 119.349 72.8392 119.487 72.8441H149.384Z"
                fill="#F2F2F2" />
            <path
                d="M150.118 84.6915C150.118 84.8132 150.07 84.93 149.984 85.0161C149.898 85.1022 149.781 85.1506 149.659 85.1507H118.954C117.287 85.1511 115.688 84.489 114.509 83.31C113.329 82.1309 112.666 80.5315 112.665 78.8634C112.664 77.1953 113.325 75.5951 114.503 74.4146C115.681 73.2342 117.28 72.5701 118.947 72.5685H149.659C149.72 72.5684 149.779 72.5801 149.835 72.6032C149.891 72.6262 149.942 72.66 149.985 72.7026C150.027 72.7453 150.061 72.796 150.084 72.8517C150.108 72.9075 150.119 72.9673 150.119 73.0277C150.119 73.0881 150.108 73.148 150.084 73.2037C150.061 73.2595 150.027 73.3102 149.985 73.3529C149.942 73.3955 149.891 73.4293 149.835 73.4523C149.779 73.4753 149.72 73.4871 149.659 73.4869H118.954C117.531 73.4888 116.167 74.0557 115.162 75.063C114.156 76.0704 113.592 77.4359 113.592 78.8596C113.592 80.2833 114.156 81.6488 115.162 82.6562C116.167 83.6635 117.531 84.2304 118.954 84.2323H149.659C149.781 84.2323 149.898 84.2807 149.984 84.3668C150.07 84.4529 150.118 84.5697 150.118 84.6915Z"
                fill="#3F3D56" />
            <path
                d="M149.384 75.5993H117.256C117.232 75.5994 117.209 75.5899 117.191 75.5728C117.174 75.5558 117.164 75.5326 117.164 75.5083C117.164 75.484 117.173 75.4606 117.19 75.4432C117.207 75.4258 117.23 75.4159 117.255 75.4156H149.384C149.408 75.4158 149.431 75.4256 149.448 75.4428C149.465 75.46 149.475 75.4832 149.475 75.5074C149.475 75.5316 149.465 75.5548 149.448 75.572C149.431 75.5892 149.408 75.599 149.384 75.5993Z"
                fill="#CCCCCC" />
            <path
                d="M149.384 77.8034H117.256C117.232 77.8036 117.209 77.7941 117.191 77.777C117.174 77.76 117.164 77.7367 117.164 77.7124C117.164 77.6881 117.173 77.6648 117.19 77.6474C117.207 77.63 117.23 77.6201 117.255 77.6198H149.384C149.408 77.62 149.431 77.6298 149.448 77.647C149.465 77.6642 149.475 77.6874 149.475 77.7116C149.475 77.7358 149.465 77.759 149.448 77.7762C149.431 77.7934 149.408 77.8032 149.384 77.8034Z"
                fill="#CCCCCC" />
            <path
                d="M149.384 80.0076H117.256C117.232 80.0078 117.209 79.9983 117.191 79.9812C117.174 79.9642 117.164 79.941 117.164 79.9167C117.164 79.8924 117.173 79.869 117.19 79.8516C117.207 79.8342 117.23 79.8243 117.255 79.824H149.384C149.408 79.8242 149.431 79.834 149.448 79.8512C149.465 79.8684 149.475 79.8916 149.475 79.9158C149.475 79.94 149.465 79.9632 149.448 79.9804C149.431 79.9976 149.408 80.0074 149.384 80.0076Z"
                fill="#CCCCCC" />
            <path
                d="M149.384 82.2118H117.256C117.232 82.2119 117.209 82.2024 117.191 82.1853C117.174 82.1683 117.164 82.1451 117.164 82.1208C117.164 82.0965 117.173 82.0731 117.19 82.0557C117.207 82.0383 117.23 82.0284 117.255 82.0281H149.384C149.408 82.0283 149.431 82.0381 149.448 82.0553C149.465 82.0725 149.475 82.0957 149.475 82.1199C149.475 82.1441 149.465 82.1673 149.448 82.1845C149.431 82.2017 149.408 82.2115 149.384 82.2118Z"
                fill="#CCCCCC" />
            <path
                d="M114.271 25.4863H100.65C100.521 25.4862 100.393 25.5159 100.277 25.5731L96.8394 27.295C96.7263 27.3431 96.6298 27.4235 96.562 27.5261C96.4942 27.6286 96.458 27.7489 96.458 27.8719C96.458 27.9949 96.4942 28.1152 96.562 28.2178C96.6298 28.3203 96.7263 28.4007 96.8394 28.4488L100.277 30.1708C100.393 30.2279 100.521 30.2576 100.65 30.2575H114.271C114.459 30.2684 114.644 30.2048 114.785 30.0806C114.926 29.9564 115.013 29.7815 115.026 29.5939V26.1499C115.013 25.9623 114.926 25.7874 114.785 25.6632C114.644 25.539 114.459 25.4754 114.271 25.4863Z"
                fill="#3F3D56" />
            <path
                d="M115.026 26.15V29.5939C115.013 29.7815 114.926 29.9564 114.785 30.0806C114.644 30.2049 114.459 30.2684 114.271 30.2575H111.558V25.4863H114.271C114.459 25.4754 114.644 25.539 114.785 25.6632C114.926 25.7874 115.013 25.9623 115.026 26.15Z"
                fill="#F06A6A" />
            <path
                d="M32.6075 92.9691C34.3614 93.586 36.2446 93.7405 38.0755 93.4178C39.9065 93.0951 41.6235 92.306 43.0612 91.1267C46.7227 88.0519 47.8707 82.9863 48.8048 78.2958C49.7257 73.6712 50.6466 69.0467 51.5676 64.4221L45.7834 68.407C41.6237 71.2726 37.3703 74.2301 34.4902 78.3809C31.61 82.5317 30.3529 88.1978 32.6669 92.6892"
                fill="#E6E6E6" />
            <path
                d="M33.1019 109.766C32.5164 105.499 31.9145 101.178 32.3251 96.8658C32.6898 93.036 33.8576 89.2954 36.2351 86.2283C37.4971 84.6034 39.0327 83.2112 40.7729 82.1143C41.2266 81.8277 41.6443 82.5472 41.1925 82.8325C38.1815 84.7365 35.8526 87.5479 34.5416 90.8614C33.0936 94.5463 32.8611 98.5631 33.1106 102.477C33.2615 104.845 33.5813 107.196 33.9036 109.545C33.929 109.651 33.9132 109.763 33.8593 109.858C33.8054 109.953 33.7174 110.024 33.6133 110.057C33.507 110.085 33.3937 110.071 33.2979 110.016C33.2022 109.962 33.1318 109.872 33.1019 109.766Z"
                fill="#F2F2F2" />
            <path
                d="M38.7407 102.216C39.493 103.36 40.527 104.291 41.7431 104.919C42.9592 105.547 44.3165 105.851 45.6842 105.802C49.1991 105.635 52.1295 103.18 54.7673 100.85L62.5695 93.9579L57.4057 93.7106C53.6923 93.5328 49.883 93.367 46.346 94.5127C42.8091 95.6584 39.5471 98.4159 38.9003 102.079"
                fill="#E6E6E6" />
            <path
                d="M31.4473 112.219C34.2651 107.23 37.5335 101.686 43.3736 99.914C44.9975 99.423 46.7002 99.2471 48.39 99.3958C48.9226 99.4418 48.7897 100.263 48.258 100.217C45.4254 99.982 42.5983 100.73 40.2526 102.336C37.9954 103.874 36.2379 106.011 34.7504 108.277C33.8392 109.665 33.0231 111.112 32.2068 112.557C31.9459 113.019 31.1834 112.686 31.4473 112.219Z"
                fill="#F2F2F2" />
            <path
                d="M26.9322 117.469C27.0272 116.369 27.4221 115.315 28.0741 114.424C28.7261 113.532 29.6102 112.837 30.6299 112.413V109.529H35.4645V112.504C36.4341 112.948 37.2685 113.642 37.8827 114.514C38.4969 115.387 38.8689 116.406 38.9609 117.469L40.0977 130.691H25.7954L26.9322 117.469Z"
                fill="#F2F2F2" />
            <path
                d="M191.347 54.3396L189.909 53.7337L188.922 46.5203H175.784L174.714 53.7042L173.428 54.3479C173.366 54.3787 173.317 54.4294 173.288 54.4917C173.258 54.5541 173.251 54.6245 173.267 54.6915C173.283 54.7586 173.321 54.8183 173.375 54.861C173.429 54.9037 173.496 54.927 173.564 54.927H191.228C191.299 54.927 191.367 54.9026 191.422 54.8579C191.476 54.8132 191.514 54.751 191.528 54.6818C191.542 54.6126 191.531 54.5407 191.498 54.4784C191.465 54.416 191.412 54.3669 191.347 54.3396Z"
                fill="#E6E6E6" />
            <path
                d="M214.694 47.48H150.155C149.817 47.4792 149.492 47.3441 149.253 47.1043C149.014 46.8645 148.879 46.5395 148.879 46.2007V37.0952H215.97V46.2007C215.97 46.5395 215.836 46.8644 215.597 47.1043C215.357 47.3441 215.033 47.4792 214.694 47.48Z"
                fill="#CCCCCC" />
            <path
                d="M216.038 41.3986H148.821V2.43797C148.821 2.0291 148.984 1.63712 149.273 1.34801C149.562 1.0589 149.954 0.89627 150.362 0.895813H214.496C214.905 0.89627 215.297 1.0589 215.586 1.34801C215.875 1.63711 216.037 2.0291 216.038 2.43797L216.038 41.3986Z"
                fill="#3F3D56" />
            <path
                d="M212.023 38.5709H152.835C152.52 38.5705 152.218 38.4452 151.995 38.2223C151.773 37.9994 151.647 37.6972 151.647 37.382V4.91248C151.647 4.59726 151.773 4.29505 151.995 4.07215C152.218 3.84925 152.52 3.72387 152.835 3.72351H212.023C212.338 3.72387 212.64 3.84925 212.863 4.07215C213.086 4.29505 213.211 4.59726 213.211 4.91248V37.382C213.211 37.6972 213.086 37.9994 212.863 38.2223C212.64 38.4452 212.338 38.5705 212.023 38.5709Z"
                fill="white" />
            <path
                d="M196.434 50.0277C196.816 49.6746 197.271 49.4096 197.766 49.2513C198.262 49.0931 198.786 49.0455 199.302 49.1119C199.818 49.1783 200.313 49.3571 200.753 49.6357C201.192 49.9142 201.565 50.2858 201.846 50.7242L209.851 49.3001L211.903 53.9567L200.569 55.8089C199.802 56.2313 198.906 56.3574 198.052 56.1634C197.198 55.9693 196.445 55.4685 195.935 54.7559C195.425 54.0433 195.194 53.1684 195.286 52.297C195.378 51.4255 195.787 50.6181 196.434 50.0277Z"
                fill="#FFB6B6" />
            <path
                d="M203.846 55.9734L212.192 55.8694L213.249 55.855L230.23 55.641C230.23 55.641 234.841 51.2395 238.856 46.7079L238.293 42.6351C237.745 38.6381 235.763 34.9768 232.717 32.3326C226.966 36.1772 223.631 43.7873 223.631 43.7873L213.103 46.3068L212.049 46.5588L205.507 48.123L203.846 55.9734Z"
                fill="#F06A6A" />
            <path
                d="M246.571 81.0062H230.992C230.992 81.6741 213.962 82.2162 213.962 82.2162C213.586 82.4992 213.244 82.8239 212.941 83.1841C212.032 84.269 211.535 85.6402 211.538 87.0559V88.0239C211.539 89.6282 212.176 91.1666 213.31 92.301C214.444 93.4354 215.981 94.073 217.585 94.0736H246.571C248.175 94.0722 249.712 93.4343 250.845 92.3001C251.979 91.1659 252.617 89.6279 252.618 88.0239V87.0559C252.616 85.4521 251.978 83.9147 250.844 82.7806C249.711 81.6465 248.174 81.0085 246.571 81.0062Z"
                fill="#E4E4E4" />
            <path d="M235.242 93.8333H228.954V130.149H235.242V93.8333Z" fill="#E4E4E4" />
            <path
                d="M211.056 129.928C211.056 130.608 220.585 130.191 232.34 130.191C244.094 130.191 253.624 130.608 253.624 129.928C253.624 129.248 244.094 123.857 232.34 123.857C220.585 123.857 211.056 129.248 211.056 129.928Z"
                fill="#E4E4E4" />
            <path d="M206.832 125.779L202.152 125.778L199.925 107.717L206.832 107.718L206.832 125.779Z"
                  fill="#FFB6B6" />
            <path
                d="M208.025 130.318L192.935 130.317V130.126C192.935 128.567 193.554 127.073 194.655 125.971C195.757 124.869 197.251 124.249 198.808 124.249H198.809L208.026 124.25L208.025 130.318Z"
                fill="#2F2E41" />
            <path d="M192.999 125.779L188.319 125.778L186.093 107.717L193 107.718L192.999 125.779Z" fill="#FFB6B6" />
            <path
                d="M194.193 130.318L179.102 130.317V130.126C179.102 128.567 179.721 127.073 180.823 125.971C181.924 124.869 183.418 124.249 184.976 124.249H184.976L194.193 124.25L194.193 130.318Z"
                fill="#2F2E41" />
            <path
                d="M234.923 21.7985C239.417 21.7985 243.06 18.1534 243.06 13.6569C243.06 9.16038 239.417 5.51526 234.923 5.51526C230.428 5.51526 226.785 9.16038 226.785 13.6569C226.785 18.1534 230.428 21.7985 234.923 21.7985Z"
                fill="#FFB6B6" />
            <path
                d="M248.093 69.4401C248.093 69.4401 250.823 83.8852 240.435 85.7247C230.046 87.5641 215.05 88.857 215.05 88.857L208.316 120.293L199.269 119.485C199.269 119.485 200.472 81.643 203.431 78.6824C206.39 75.7218 225.092 66.8571 225.092 66.8571L248.093 69.4401Z"
                fill="#2F2E41" />
            <path
                d="M227.803 63.386L223.26 67.9464C223.26 67.9464 190.453 73.2334 189.188 80.8145C187.277 92.2601 185.02 117.15 185.02 117.15L194.884 116.409L198.751 88.1169L214.533 84.5252L232.488 74.735L227.803 63.386Z"
                fill="#2F2E41" />
            <path
                d="M242.753 8.79695C241.95 7.28058 240.929 4.25848 239.308 3.698C238.408 3.39929 237.477 3.20095 236.533 3.10656L233.777 4.16704L235.277 2.99993C234.985 2.98252 234.693 2.96829 234.401 2.95724L232.542 3.67275L233.493 2.93248C231.76 2.91468 229.971 3.09502 228.627 4.15084C227.482 5.05093 226.348 8.46066 226.164 9.90597C225.968 11.2886 226.037 12.696 226.367 14.053L226.837 14.5009C227.002 14.1231 227.126 13.7286 227.207 13.3244C227.376 12.5691 227.7 11.8572 228.159 11.2342C228.618 10.6112 229.202 10.0907 229.873 9.70582L229.898 9.69136C230.688 9.24664 231.647 9.26714 232.552 9.29975L236.854 9.45462C237.888 9.49188 239.003 9.55739 239.81 10.2069C240.322 10.686 240.726 11.2704 240.992 11.9199C241.392 12.7295 242.176 15.783 242.176 15.783C242.176 15.783 242.619 15.2066 242.832 15.6356C243.172 14.5163 243.379 13.3606 243.449 12.1926C243.516 11.0195 243.277 9.84892 242.753 8.79695Z"
                fill="#2F2E41" />
            <path
                d="M271.214 17.2323C270.695 17.2665 270.19 17.4142 269.734 17.6649C269.278 17.9156 268.882 18.2632 268.575 18.6834C268.268 19.1035 268.057 19.5859 267.957 20.0966C267.856 20.6072 267.869 21.1337 267.993 21.639L261.45 26.4684L263.413 31.1629L272.558 24.2129C273.393 23.9468 274.101 23.3838 274.548 22.6304C274.995 21.877 275.151 20.9856 274.985 20.1251C274.819 19.2647 274.344 18.495 273.649 17.9618C272.954 17.4286 272.088 17.1691 271.214 17.2323Z"
                fill="#FFB6B6" />
            <path
                d="M270.161 27.0917L253.757 45.32C253.757 45.32 245.912 42.8073 239.845 40.0832L237.119 31.7075C236.486 29.7644 236.216 27.7214 236.322 25.6805C244.733 23.4413 254.516 31.7731 254.516 31.7731L266.795 22.1497L270.161 27.0917Z"
                fill="#F06A6A" />
            <path
                d="M231.883 70.7864C228.956 69.7749 225.93 68.729 222.666 68.3364L222.525 68.3194L222.564 68.1836C225.943 56.313 225.1 48.7195 223.444 37.313C223.092 34.8526 223.544 32.3442 224.734 30.1623C225.924 27.9804 227.787 26.2418 230.045 25.2062L230.065 25.1972L239.388 24.6044L239.409 24.6031L246.19 26.5984C247.165 26.8875 248.019 27.4887 248.62 28.3097C249.221 29.1307 249.537 30.1263 249.519 31.144C249.445 34.8952 249.601 39.0834 249.766 43.5173C250.136 53.4647 250.518 63.7507 248.347 70.4246L248.336 70.4596L248.307 70.4823C246.311 72.1087 243.811 72.9875 241.237 72.9672C238.193 72.9672 235.087 71.8939 231.883 70.7864Z"
                fill="#F06A6A" />
            <path
                d="M299.566 131.104H0.433527C0.318549 131.104 0.208281 131.058 0.126979 130.977C0.0456771 130.896 0 130.785 0 130.67C0 130.555 0.0456771 130.445 0.126979 130.364C0.208281 130.282 0.318549 130.237 0.433527 130.237H299.566C299.681 130.237 299.792 130.282 299.873 130.364C299.954 130.445 300 130.555 300 130.67C300 130.785 299.954 130.896 299.873 130.977C299.792 131.058 299.681 131.104 299.566 131.104Z"
                fill="#CCCCCC" />
        </g>
        <defs>
            <clipPath id="clip0_112_7426">
                <rect width="300" height="130.208" fill="white" transform="translate(0 0.895813)" />
            </clipPath>
        </defs>
    </svg>

)

export default Hello2Image;