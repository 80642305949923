import React from "react";
import {Link} from "react-router-dom";
import "./NotFound.scss";
const NotFound = () => {
  return (
    <div className="notfound__wrapper">
      <div className="container">
        <h1>Oops...</h1>
        <h2>404 - PAGE NOT FOUND</h2>
        <p>
          The page you are looking for might have been removed has its changed
          or is temporarily unavailable
        </p>
        <Link to="/" className="btn">
          go to homepage
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
