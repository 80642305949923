import React, {useEffect} from "react";
import {Box, styled} from "@mui/material";
import CloseCalendarLapseTitle from "./CloseCalendarLapseTitle";
import DeededSpace from "v2/components/DeededSpace";
import DeededCheckbox from "v2/components/DeededCheckbox";
import CloseCalendarLapseV2DateForm from "./CloseCalendarLapseV2DateForm";
import CloseCalendarLapseV2Repeat from "./CloseCalendarLapseV2Repeat";
import CloseCalendarLapseV2MobileSigners from "./CloseCalendarLapseV2MobileSigners";
import CloseCalendarLapseBellowBtns from "./CloseCalendarLapseBellowBtns";
import moment from "moment";
import {clearCloseLapseDataAfterUnmounting} from "../../utils/clearCloseLapseDataAfterUnmounting";
import {CloseCalendarLapseType} from "../../types/closeCalendarLapseType";
import constants from "styles/constants";

type CloseCalendarLapseV2Props = CloseCalendarLapseType;

const CloseCalendarLapseV2: React.FC<CloseCalendarLapseV2Props> = ({
  setErrors,
  errors,
  handleChange,
  values,
  handleSubmit,
  setShowCloseLapse,
  mobileSigners,
}) => {
  useEffect(() => {
    return () => {
      setErrors({});
    };
  }, [setErrors]);
  useEffect(() => {
    return () => {
      clearCloseLapseDataAfterUnmounting(handleChange);
    };
  }, [handleChange]);
  return (
    <>
      <CloseCalendarLapseV2Box
        onClick={() => {
          setShowCloseLapse(false);
        }}
      >
        <CloseCalendarLapseBoxChild
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <CloseCalendarLapseTitle setShowCloseLapse={setShowCloseLapse} />
          <DeededSpace y={27} />

          <CloseCalendarLapseV2DateForm
            errors={errors}
            handleChange={handleChange}
            province={values.province}
            isAllDay={values.isAllDay}
            dateStart={moment(values.dateStart).format("MM/DD/YYYY") as string}
            timeStart={values.timeStart as string}
            dateEnd={values.dateEnd as string}
            timeEnd={values.timeEnd as string}
          />

          <DeededSpace y={8} />

          <Box
            sx={{
              display: "flex",
            }}
          >
            <Box sx={{display: "flex"}}>
              <DeededCheckbox
                label="All day"
                sx={{marginRight: "5px"}}
                checked={values.isAllDay}
                onChange={(_e, checked) => {
                  handleChange({
                    target: {
                      name: "isAllDay",
                      value: checked,
                    },
                  });
                }}
              />
            </Box>
            <Box sx={{display: "flex"}}>
              <DeededCheckbox
                label="Repeat"
                checked={values.isRepeat}
                onChange={(_e, checked) => {
                  handleChange({
                    target: {
                      name: "isRepeat",
                      value: checked,
                    },
                  });
                }}
              />
            </Box>
          </Box>

          {values.isRepeat && (
            <>
              <DeededSpace y={36} />
              <CloseCalendarLapseV2Repeat
                selectedDaysOfWeek={values.selectedDaysOfWeek}
                selectedDaysOfMonth={values.selectedDaysOfMonth}
                selectedMothsOfYear={values.selectedMothsOfYear}
                repeatEveryDays={values.repeatEveryDays}
                repeatEveryWeeks={values.repeatEveryWeeks}
                repeatEveryMonths={values.repeatEveryMonths}
                repeatEveryYears={values.repeatEveryYears}
                repeat={values.repeat}
                handleChange={handleChange}
              />
            </>
          )}

          <Box sx={{display: "flex"}}>
            <DeededCheckbox
              label="Show mobile signers"
              checked={values.isShowMobileSigners}
              onChange={(_e, checked) => {
                handleChange({
                  target: {
                    name: "isShowMobileSigners",
                    value: checked,
                  },
                });
              }}
            />
          </Box>

          {values.isShowMobileSigners && (
            <CloseCalendarLapseV2MobileSigners
              errors={errors}
              mobileSignerId={values.mobileSignerId}
              handleChange={handleChange}
              mobileSigners={mobileSigners}
            />
          )}

          <DeededSpace y={18} />

          <CloseCalendarLapseBellowBtns
            closing_id={values.closing_id}
            handleSubmit={handleSubmit}
            setShowCloseLapse={setShowCloseLapse}
          />
        </CloseCalendarLapseBoxChild>
      </CloseCalendarLapseV2Box>
    </>
  );
};

const CloseCalendarLapseV2Box = styled(Box)({
  overflowY: "scroll",
  position: "fixed",
  top: "0",
  left: "0",
  display: "flex",
  alignItems: "center",
  verticalAlign: "center",
  width: "100vw",
  height: "100vh",
  background: "rgba(0, 0, 0, 0.4)",
  zIndex: 13,
  "&::-webkit-scrollbar": {
    width: "0px",
  },
});

const NotificationsLabel = styled("label")({
  marginTop: "9px",
  marginLeft: "2px",
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "13px",
  lineHeight: "20px",
  display: "flex",
  color: constants.font,
});

const CloseCalendarLapseBoxChild = styled(Box)({
  margin: "auto",
  paddingLeft: "32px",
  paddingRight: "20px",
  width: "493px",
  background: "#ffffff",
  boxShadow:
    "0px 12px 16px rgba(0, 0, 0, 0.07),\n  0px 12px 66px rgba(0, 0, 0, 0.18)",
  borderRadius: "5px",
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: "normal",
  fontSize: "12px",
  lineHeight: "15px",
});

export default CloseCalendarLapseV2;
