import React, {useState} from "react";
import {Moment} from "moment";
import {Box, styled, Typography} from "@mui/material";
import {Theme} from "@mui/material/styles";
import useTypedSelector from "utils/hooks/useTypedSelector";
import useWindowWidth from "utils/hooks/useWindowWidth";
import constants from "styles/constants";
import {getLawClerksOrLawyersClosingsMonthDayCell} from "../utils/getLawClerksOrLawyersClosingsMonthDayCell";
import {getCurrentDateTypedClosingsCalendarResultTotalByRole} from "../utils/getCurrentDateTypedClosingsCalendarResultTotalByRole";
import {selectUserClosingsCalendarData} from "../utils/selectUserClosingsCalendarData";
import {horizontalPhoneCalendar} from "../constants/breakpoints";
import CalendarClosingDealByDate from "./CalendarClosingOrAppointmentsDealByDate";

interface DayCellProps {
  isToday: boolean;
  indexDay: number;
  day: Moment;
  calendarShowingDaysArray: Moment[][];
  isCurrentDayFromCurrentMonth: boolean;
}

const BigCalendarClosingsMonthDayCell: React.FC<DayCellProps> = ({
  isToday,
  indexDay,
  day,
  calendarShowingDaysArray,
  isCurrentDayFromCurrentMonth,
}) => {
  const closingsCalendarResultByDates = useTypedSelector(
    (state) => state.CalendarReducer.closingsCalendarResultByDates,
  );
  const windowWidth = useWindowWidth();
  const isStartOfWeek =
    +day.format("D") === +day.clone().startOf("week").add(1, "day").format("D");
  const [isHoveredOnHint, setIsHoveredOnHint] = useState(false);
  const [isShowClosingDealsHint, setIsShowClosingDealsHint] = useState(false);
  const isShowDayNumberCellPopupDownDirection =
    day.isBefore(calendarShowingDaysArray[2][4]) ||
    +day.format("D") === +calendarShowingDaysArray[2][4].format("D");
  const currentDateTypedClosingsCalendarResultTotalByLawClerk =
    getCurrentDateTypedClosingsCalendarResultTotalByRole(
      "Law Clerk",
      closingsCalendarResultByDates,
      day,
    );
  const currentDateTypedClosingsCalendarResultTotalByLawyer =
    getCurrentDateTypedClosingsCalendarResultTotalByRole(
      "Lawyer",
      closingsCalendarResultByDates,
      day,
    );

  const lawClerksForDisplay = selectUserClosingsCalendarData(
    "Law Clerk",
    day,
    closingsCalendarResultByDates,
  );
  const lawyersForDisplay = selectUserClosingsCalendarData(
    "Lawyer",
    day,
    closingsCalendarResultByDates,
  );

  return (
    <>
      <DateCellBox isToday={isToday} key={indexDay}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <DateNumberCellBox
            isCurrentDayFromCurrentMonth={isCurrentDayFromCurrentMonth}
            onMouseEnter={() => {
              if (isCurrentDayFromCurrentMonth) {
                setIsShowClosingDealsHint(true);
              }
            }}
            onMouseLeave={() => {
              setIsShowClosingDealsHint(false);
            }}
            isToday={isToday}
          >
            {day.format("D")}
          </DateNumberCellBox>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            flexBasis: "100%",
          }}
        >
          <RoleCountItemBox>
            <RoleTypography
              isCurrentDayFromCurrentMonth={isCurrentDayFromCurrentMonth}
            >
              {windowWidth < horizontalPhoneCalendar ? "L" : "Lawyer"}
            </RoleTypography>
            <RoleClosingsCount
              isCurrentDayFromCurrentMonth={isCurrentDayFromCurrentMonth}
            >
              {currentDateTypedClosingsCalendarResultTotalByLawyer}
            </RoleClosingsCount>
            {lawyersForDisplay.length !== 0 && (
              <HoverRoleCountItemBox className="HoverRoleCountItemBox">
                <HoverRoleCountItemTitle>Closing Deals</HoverRoleCountItemTitle>
                {lawyersForDisplay.map((item) => (
                  <Box sx={{marginBottom: "7px"}}>
                    <HoverRoleCountItemBoxRow>
                      <HoverRoleCountItemBoxTitle>
                        {item.name}
                      </HoverRoleCountItemBoxTitle>
                      <HoverRoleCountItemBoxItem>
                        {item.count}
                      </HoverRoleCountItemBoxItem>
                    </HoverRoleCountItemBoxRow>
                    {item.deals_names.map((dealName) => (
                      <HoverRoleCountItemBoxItemDeal>
                        {dealName}
                      </HoverRoleCountItemBoxItemDeal>
                    ))}
                  </Box>
                ))}
              </HoverRoleCountItemBox>
            )}
          </RoleCountItemBox>

          <RoleCountItemBox
            sx={{
              marginTop: "16px",
            }}
          >
            <RoleTypography
              isCurrentDayFromCurrentMonth={isCurrentDayFromCurrentMonth}
            >
              {windowWidth < horizontalPhoneCalendar ? "LC" : "Law Clerk"}
            </RoleTypography>
            <RoleClosingsCount
              isCurrentDayFromCurrentMonth={isCurrentDayFromCurrentMonth}
            >
              {currentDateTypedClosingsCalendarResultTotalByLawClerk}
            </RoleClosingsCount>
            {lawClerksForDisplay.length !== 0 && (
              <HoverRoleCountItemBox className="HoverRoleCountItemBox">
                <HoverRoleCountItemTitle>Closing Deals</HoverRoleCountItemTitle>
                {lawClerksForDisplay.map((item) => (
                  <Box sx={{marginBottom: "7px"}}>
                    <HoverRoleCountItemBoxRow>
                      <HoverRoleCountItemBoxTitle>
                        {item.name}
                      </HoverRoleCountItemBoxTitle>
                      <HoverRoleCountItemBoxItem>
                        {item.count}
                      </HoverRoleCountItemBoxItem>
                    </HoverRoleCountItemBoxRow>
                    {item.deals_names.map((dealName) => (
                      <HoverRoleCountItemBoxItemDeal>
                        {dealName}
                      </HoverRoleCountItemBoxItemDeal>
                    ))}
                  </Box>
                ))}
              </HoverRoleCountItemBox>
            )}
          </RoleCountItemBox>
        </Box>
        {isShowClosingDealsHint && (
          <CalendarClosingDealByDate
            isHoveredOnHint={isHoveredOnHint}
            eventsCountByName={{
              lawyer: getLawClerksOrLawyersClosingsMonthDayCell(
                "Lawyer",
                closingsCalendarResultByDates,
                day,
              ),
              lawClerk: getLawClerksOrLawyersClosingsMonthDayCell(
                "Law Clerk",
                closingsCalendarResultByDates,
                day,
              ),
            }}
            isShowDayNumberCellPopupDownDirection={
              isShowDayNumberCellPopupDownDirection
            }
            isShowClosingDealsHint={isShowClosingDealsHint}
            setIsShowEventCountsHint={setIsShowClosingDealsHint}
            isStartOfWeek={isStartOfWeek}
            setIsHoveredOnHint={setIsHoveredOnHint}
          />
        )}
      </DateCellBox>
    </>
  );
};
const RoleCountItemBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  position: "relative",
  "&:hover .HoverRoleCountItemBox": {
    opacity: 1,
    visibility: "visible",
    transition: constants.transitions,
  },
});
const HoverRoleCountItemBox = styled(Box)({
  position: "absolute",
  left: "-11px",
  top: "100%",
  background: constants.colors.white,
  width: "156px",
  padding: "14px 12px",
  maxHeight: "368px",
  overflowY: "auto",
  overflowX: "hidden",
  zIndex: "5",
  boxShadow: "0px 20px 50px rgba(0, 0, 0, 0.1)",
  borderRadius: "5px",
  visibility: "hidden",
  opacity: 0,
  transition: constants.transitions,
  "&::-webkit-scrollbar": {
    width: "5px",
  },
});
const HoverRoleCountItemTitle = styled(Box)({
  fontFamily: "'Montserrat'",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "20px",
  display: "flex",
  alignItems: "center",
  color: constants.colors.sortingColor,
  marginBottom: "12px",
});
const HoverRoleCountItemBoxTitle = styled(Box)({
  fontFamily: "'Montserrat'",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "12px",
  lineHeight: "15px",
  color: constants.colors.red,
});
const HoverRoleCountItemBoxRow = styled(Box)({
  display: "flex",
  marginTop: "10px",
  justifyContent: "space-between",
});
const HoverRoleCountItemBoxItem = styled(Box)({
  fontFamily: "'Montserrat'",
  fontStyle: "normal",
  fontSize: "12px",
  lineHeight: "15px",
  color: constants.colors.sortingColor,
  fontWeight: 600,
});
const HoverRoleCountItemBoxItemDeal = styled(Box)({
  display: "inline-block",
  justifyContent: "space-between",
  padding: "5px 0px 0px 0px",
  fontFamily: "'Montserrat'",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "12px",
  lineHeight: "15px",
  color: constants.colors.sortingColor,
  maxWidth: "110px",
  overflowX: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
});
const RoleClosingsCount = styled(Typography)<{
  theme?: Theme;
  isCurrentDayFromCurrentMonth: boolean;
}>(({theme, isCurrentDayFromCurrentMonth}) => ({
  fontFamily: "'Montserrat'",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "12px",
  lineHeight: "15px",
  display: "flex",
  alignItems: "center",
  color: constants.colors.sortingColor,
  marginRight: "23px",
  opacity: isCurrentDayFromCurrentMonth ? "1" : "0.5",
  userSelect: isCurrentDayFromCurrentMonth ? "text" : "none",
  [theme.breakpoints.down(horizontalPhoneCalendar)]: {
    fontWeight: 500,
    fontSize: "8px",
    marginRight: "10px",
  },
}));
const RoleTypography = styled(Typography)<{
  theme?: Theme;
  isCurrentDayFromCurrentMonth: boolean;
}>(({theme, isCurrentDayFromCurrentMonth}) => ({
  fontFamily: "'Montserrat'",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "12px",
  lineHeight: "15px",
  display: "flex",
  alignItems: "center",
  color: constants.colors.red,
  opacity: isCurrentDayFromCurrentMonth ? "1" : "0.5",
  userSelect: isCurrentDayFromCurrentMonth ? "text" : "none",
  [theme.breakpoints.down(horizontalPhoneCalendar)]: {
    fontWeight: 500,
    fontSize: "8px",
  },
}));

const DateCellBox = styled(Box)<{isToday: boolean; theme?: Theme}>(
  ({isToday, theme}) => ({
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    paddingLeft: "16px",
    paddingBottom: "16px",
    width: "17%",
    height: "128px",
    boxShadow: "0px 3px 9px rgba(131, 93, 93, 0.08)",
    backgroundColor: constants.colors.white,
    border: isToday ? `1px solid ${constants.colors.red}` : undefined,
    [theme.breakpoints.down(horizontalPhoneCalendar)]: {
      height: "100px",
    },
  }),
);

const DateNumberCellBox = styled(Box)<{
  isToday: boolean;
  isCurrentDayFromCurrentMonth: boolean;
}>(({isToday, isCurrentDayFromCurrentMonth}) => ({
  cursor: isCurrentDayFromCurrentMonth ? "pointer" : "initial",
  width: "25px",
  height: "25px",
  backgroundColor: isToday ? constants.colors.red : constants.colors.bg,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: isToday ? constants.colors.white : constants.colors.cloudGray,
  fontFamily: "'Montserrat'",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "12px",
  lineHeight: "15px",
  opacity: isCurrentDayFromCurrentMonth ? "1" : "0.5",
  userSelect: isCurrentDayFromCurrentMonth ? "text" : "none",
}));

export default BigCalendarClosingsMonthDayCell;
