import React, { FC, useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import useUser from "utils/hooks/useUser";
import configuration from "utils/configuration";
import Colors from "../../CommonDashboard/Styling/Colors";
import { BodySmall, Caption, H3, SubtitleSmall } from "components/CommonDashboard/Typography";
import Button from "components/CommonDashboard/Button";
import { getProfessionalPerks } from "api";
import Carousel from 'react-material-ui-carousel'
import LinearProgress from '@mui/material/LinearProgress';
import { User } from "types/user";

type Perk = {
    description: string,
    imgUrl: string,
    name: string,
    price: number,
    slug: string,
}

const HomePerks: FC = () => {
    const { user } = useUser();
    const [perks, setPerks] = useState<Perk[]>([]);

    if (!configuration.pointsEnabled || !user.points_feature) {
        return null;
    }

    useEffect(() => {
        getProfessionalPerks({ max: 4 }).then(({ data }) => {
            setPerks(data);
        }).catch(() => {
            setPerks([]);
        });
    }, []);

    if (perks.length === 0) return null;

    return (<Wrapper>
        <Stack sx={{ alignSelf: 'stretch', gap: '1.6rem' }}>
            <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between', alignSelf: 'stretch' }}>
                <H3>Rewards</H3>
                <Button typeOf="link" label={{ text: 'See all' }} href={configuration.pointsUrl} component="a" target="_blank" />
            </Stack>
            <BodySmall color={Colors.NEUTRAL_600}>Discover 100 hand-selected rewards tailored to your preferences</BodySmall>
        </Stack>
        <PerksCarousel>
            {perks.map((perk, i) => <PerkItem key={i} perk={perk} user={user} />)}
        </PerksCarousel>
    </Wrapper>
    );
};

const PerkItem = ({ perk, user }: { perk: Perk, user: Partial<User> }) => {
    return <Stack sx={{
        flexDirection: 'column',
        padding: '2.4rem',
        justifyContent: 'space-between',
        border: `1px solid ${Colors.NEUTRAL_300}`,
        borderRadius: '2.4rem',
        minHeight: '31.9rem',
        cursor: 'pointer',
    }} onClick={() => window.open(configuration.pointsUrl + '/' + perk.slug, '_blank')}
    >
        <Stack gap='1.6rem'>
            <img src={perk.imgUrl} />
            <SubtitleSmall color={Colors.NEUTRAL_700}>{perk.name}</SubtitleSmall>
            <Caption color={Colors.NEUTRAL_500}>{perk.price} points</Caption>
        </Stack >
        <LinearProgress
            variant="determinate"
            sx={{
                backgroundColor: Colors.NEUTRAL_100,
                borderRadius: '4px',
                '.MuiLinearProgress-bar1Determinate': {
                    backgroundColor: Colors.SUCCESS_600
                }
            }}
            value={Math.min((Number(user.points || 0) / perk.price) * 100, 100)}
        />
    </Stack>
}


const PerksCarousel: FC = ({ children }) =>
    <Carousel
        autoPlay={false}
        animation="slide"
        IndicatorIcon={<></>}
        indicatorIconButtonProps={{
            style: {
                color: Colors.NEUTRAL_100,
                backgroundColor: Colors.NEUTRAL_100,
                width: '8px',
                height: '8px',
                borderRadius: '8px',
            }
        }}
        activeIndicatorIconButtonProps={{
            style: {
                color: Colors.PRIMARY_600,
                backgroundColor: Colors.PRIMARY_600,
                width: '1.6rem',
                height: '.8rem',
                borderRadius: '8px'
            }
        }}
        indicatorContainerProps={{
            style: {
                display: 'inline-flex',
                justifyContent: 'center',
                gap: '8px',
                marginTop: '2.4rem'
            }

        }}
        sx={{
            width: '100%',
            alignSelf: 'center'
        }}
    >
        {children}
    </Carousel>

const Wrapper = styled(Stack)(({ bgcolor, theme }) => ({
    alignItems: "stretch",
    backgroundColor: bgcolor ? bgcolor.toString() : Colors.WHITE,
    padding: "2.4rem",
    borderRadius: "2.4rem",
    gap: "2.4rem",
    width: "32.5rem",
    [theme.breakpoints.down('mobile')]: {
        width: '100%'
    }
}));


export default HomePerks;
