import React from "react";
import constants from "styles/constants";
import {Box, styled, Typography} from "@mui/material";
import DeededPopup from "v2/components/DeededPopup";

interface DealsListDeleteModalProps {
  open: boolean;
  handleClose: () => void;
  handleDelete: () => void;
  deleteText: string;
}

const DealsListDeleteModal = ({
  open,
  handleClose,
  handleDelete,
  deleteText,
}: DealsListDeleteModalProps) => {
  return (
    <DeededPopup
      sx={{}}
      open={open}
      setOpen={handleClose}
      hideBtnClose={true}
      sxInternalBox={{
        width: "430px",
        overflowX: "hidden",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
          width: "5px",
          scrollbarWidth: "thin",
        },
      }}
    >
      <DeleteContainer>
        <DeleteText>{deleteText}</DeleteText>
      </DeleteContainer>
      <Box sx={{display: "flex", padding: "0 40px"}}>
        <YesButton
          sx={{...buttonStyles}}
          onClick={(e) => {
            e.stopPropagation();
            handleDelete();
          }}
        >
          Yes
        </YesButton>
        <NoButton
          sx={{...buttonStyles}}
          onClick={(e) => {
            e.stopPropagation();
            handleClose();
          }}
        >
          No
        </NoButton>
      </Box>
    </DeededPopup>
  );
};

const DeleteContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "5px",
  background: "white",
});

const DeleteText = styled(Typography)({
  fontSize: "24px",
  fontWeight: "600",
  marginBottom: "50px",
  color: constants.colors.intakeTitle,
});

const buttonStyles = {
  width: "175px",
  height: "50px",
  borderRadius: "5px",
  textTransform: "uppercase",
  fontSize: "16px",
  cursor: "pointer",
  fontWeight: "500",
  ":hover": {
    boxShadow: "0px 4px 4px rgb(79 79 79 / 25%",
  },
};

const YesButton = styled("button")({
  color: "white",
  border: "none",
  background: constants.colors.red,
  marginRight: "20px",
});
const NoButton = styled("button")({
  background: "transparent",
  border: "1px solid grey",
});

export default DealsListDeleteModal;
