import useTypedSelector from "../../../../../utils/hooks/useTypedSelector";
import {useMemo} from "react";

export const useClosingsSum = (view: string) => {
  const closingsDealsCountDaily = useTypedSelector(
    (state) => state.CalendarReducer.closingsDealsCountDaily,
  );
  const closingsCalendarResultByDates = useTypedSelector(
    (state) => state.CalendarReducer.closingsCalendarResultByDates,
  );
  const closingsSum = useMemo(() => {
    if (closingsDealsCountDaily && view === "day") {
      return closingsDealsCountDaily.resultCalendar.closings_count;
    } else if (closingsCalendarResultByDates && view !== "day") {
      return closingsCalendarResultByDates.closings_count;
    } else {
      return 0;
    }
  }, [closingsCalendarResultByDates, closingsDealsCountDaily, view]);
  return closingsSum;
};
