import * as yup from "yup";
import {useFormik} from "formik";
import {Box, IconButton} from "@mui/material";
import DeededInput from "v2/components/DeededInput";
import ProfileThirdPartyAcceptSvg from "../../svg/ProfileThirdPartyAcceptSvg";
import ProfileThirdPartyCrossSvg from "../../svg/ProfileThirdPartyCrossSvg";
import {EmailInfoType} from "../../types/profileThirdPartyTypes";
import {styled} from "@mui/material/styles";

interface ProfileIntegrationThreeDEmailComponentEditModeProps {
  emailInfo: EmailInfoType;
  onClose?: () => void;
  onSubmitEvent: ({
    email,
    full_name,
  }: {
    email: string;
    full_name: string;
  }) => void;
}
const schema = yup.object().shape({
  email: yup.string().required(" ").email(" "),
  full_name: yup.string().required(" "),
});
const ProfileIntegrationThreeDEmailComponentEditMode: React.FC<
  ProfileIntegrationThreeDEmailComponentEditModeProps
> = ({emailInfo, onClose, onSubmitEvent}) => {
  const initialValues = {
    email: emailInfo.email,
    full_name: emailInfo.full_name,
  };
  const onSubmit = onSubmitEvent;
  const {values, errors, handleSubmit, handleChange} = useFormik({
    initialValues,
    onSubmit,
    validationSchema: schema,
    validateOnBlur: false,
    validateOnChange: false,
  });
  const setValue = (valueName: keyof typeof values, value: unknown) => {
    handleChange({
      target: {
        name: valueName,
        value: value,
      },
    });
  };
  return (
    <>
      <EditForm onSubmit={handleSubmit}>
        <Box>
          <DeededInput
            errorMessageWithoutIcon
            placeholder={"User name"}
            sx={{
              width: "90%",
            }}
            height={36}
            error={errors.full_name}
            value={values.full_name}
            onChange={(e) => {
              setValue("full_name", e.target.value);
            }}
          />
        </Box>
        <Box>
          <DeededInput
            errorMessageWithoutIcon
            placeholder={"Email address"}
            height={36}
            error={errors.email}
            value={values.email}
            onChange={(e) => {
              setValue("email", e.target.value);
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "-5px",
          }}
        >
          <IconButton
            type="submit"
            sx={{
              width: "40px",
              height: "40px",
              minWidth: "0px",
            }}
          >
            <ProfileThirdPartyAcceptSvg />
          </IconButton>
          <IconButton
            type="button"
            onClick={onClose}
            sx={{
              width: "40px",
              height: "40px",
              minWidth: "0px",
            }}
          >
            <ProfileThirdPartyCrossSvg />
          </IconButton>
        </Box>
      </EditForm>
    </>
  );
};
const EditForm = styled("form")({
  display: "grid",
  gridTemplateColumns: "5fr 7fr 3fr",
  marginBottom: "10px",
});
export default ProfileIntegrationThreeDEmailComponentEditMode;
