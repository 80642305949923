import React, { useState, useEffect, useMemo, FC } from "react";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import moment from "moment";
import {
    clearDealInfoByIdAC,
    getDealInfoByIdTC,
    passMortgageTC,
    setStartGetDealInfoFalse,
} from "redux/reducers/dealsReducer";
import { StaffUser } from "redux/reducers/configReducer/staffCardReducer";
import { convertClosingDateToDisplayRepresentation, formatRelativeDate } from "utils/date";
import configuration from "utils/configuration";
import useTypedSelector from "utils/hooks/useTypedSelector";
import UserRole from "types/roles";
import { CommunicationTemplate, Deal, DealCompletedStageType, DealStageType, StageNumber } from "types/deal";
import "./DealStages.scss";
import UpdateConfirm from "../DealDetails/UpdateConfirm";
import { saveRevertStage, setStage } from "api";
import { useMixpanel } from "utils/MixpanelContext";

const clientsList = [
    "Admin",
    "System Admin",
    "Lawyer",
    "Law Clerk",
    "Mobile Signer",
    "Closing Advisor",
    "Document Support Specialist",
];

const professionalsList = [
    "Realtor",
    "Real Estate/Mortgage Team",
    "Broker",
    "Lender",
];

interface LocalStageType extends DealStageType {
    complete: boolean;
    date: DealCompletedStageType[];
    to_approve?: boolean;
}

interface DealStagesProps {
    deal: Deal;
    staff?: StaffUser[];
    onRefresh: () => {};
}

const DealStages: FC<DealStagesProps> = ({
    deal,
    staff,
    onRefresh,
}) => {
    const { enqueueSnackbar } = useSnackbar();
    const mixpanel = useMixpanel();
    const [stages, setStages] = useState<LocalStageType[]>([]);
    const [isCurrentUserStaff, setIsCurrentUserStaff] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [currentStage, setCurrentStage] = useState<LocalStageType | null>(null);
    const startGetDealInfo = useTypedSelector(
        (state) => state.DealsReducer.startGetDealInfo,
    );
    const currentUser = useTypedSelector((state) => state.AuthReducer.currentUser);
    const isCurrUserProfessional = professionalsList.find((el) => el === currentUser.role);
    const dealInfo = useTypedSelector((state) => state.DealsReducer.deal_info_by_id) as Deal;
    const isLender = deal.deal_type === "Lender"
    const parseDealStages = (deal: Deal) => {
        let output = deal.available_stages.map((element) => {
            let complete = !!deal.completed_stages.find(
                (x) => x.deal_stage_id === element.id,
            );
            let date = deal.completed_stages.filter((stage) => {
                if (stage.deal_stage_id === element.id) {
                    return stage.stage_reached;
                }
            });

            return {
                ...element,
                complete,
                date,
            } as LocalStageType;
        });

        for (let element of output) {
            if (!element.complete) {
                element.to_approve = true;
                break;
            }
        }

        setStages(output);
    };

    const onApprove = (dealID: number, stageId?: number, notification: boolean = true, stageTemplate?: CommunicationTemplate, sender?: number) => {
        const stageToUpdateId = stages.slice(2).find((el) => el.id === currentStage?.id && deal.deal_type !== "Lender")?.id || stageId;
        if (currentStage?.complete && stageTemplate) {
            return revertStage(currentStage, stageTemplate);
        }
        const { role, email, full_name } = currentUser;
        // Send data for mixpanel analytics
        mixpanel.track(`${role}_change_deal_stage`, {
            role,
            email,
            full_name,
            deal_type: deal.deal_type,
            deal_id: deal.id,
            deal_stage: deal?.deal_stage?.name,
        });
        const newStage = stages.find((el) => el.id === stageId);
        setLoading(true);
        setStage(dealID, stageToUpdateId, notification, stageTemplate?.html_template_id, sender)
            .then((res) => {
                enqueueSnackbar("Stage change", {
                    variant: "success",
                    autoHideDuration: 1500,
                });
                if (newStage?.number === StageNumber.ClientInfoReceived) {
                    enqueueSnackbar(`Email with Lawyer information sent to professional`, { variant: "success" });
                }
                onRefresh();
            }).catch((error) => enqueueSnackbar(error.response.data.error, {
                variant: "error",
                autoHideDuration: configuration.autoHideErrorDuration,
            })).finally(() => setLoading(false))
    }

    const revertStage = (currentStage: LocalStageType, stageTemplate: CommunicationTemplate) => {
        const { content } = stageTemplate;
        const payload = {
            deal_type: currentStage.deal_type,
            deal_stage_number: currentStage.number,
            reason: content
        };
        saveRevertStage(deal.id, payload).then((res) => {
            enqueueSnackbar("Stage reverted", {
                variant: "success",
                autoHideDuration: 1500,
            });
            onRefresh();
        }).catch((error) =>
            enqueueSnackbar(error.response.data.error, {
                variant: "error",
                autoHideDuration: configuration.autoHideErrorDuration,
            }),
        );
    };

  useEffect(() => {
    if (
      currentUser?.role === "Admin" ||
      currentUser?.role === "System Admin" ||
      currentUser?.role === "Lawyer" ||
      currentUser?.role === "Law Clerk" ||
      currentUser?.role === "Mobile Signer" ||
      currentUser?.role === "Closing Advisor" ||
      currentUser?.role === "Access"
    ) {
      setIsCurrentUserStaff(true);
    }
  }, [currentUser]);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getDealInfoByIdTC(deal.id));
    }, [deal.id, dispatch]);
  let scheduledDate: {} | null | undefined;
  let scheduleTime: string;
  let dealAppointments = dealInfo.appointment || [];

  for (let i = 0; i < dealAppointments.length; i++) {
    if (i === dealAppointments.length - 1) {
      scheduledDate = convertClosingDateToDisplayRepresentation(
          dealAppointments[i].datetime.split(" ")[0],
      );
      scheduleTime = moment(
          dealAppointments[i].datetime.split(" ")[1],
        "HH:mm:ss",
      ).format("h:mm a");
    }
  }

    const handleStageChange = (stage: LocalStageType) => {
        setCurrentStage(stage);
        setConfirmModal(true);
    };

  const {hasLawyer, hasDss, hasLawClerk} = useMemo(() => {
    let hasLawyer, hasDss, hasLawClerk = false;
    (staff || []).forEach((e) => {
      switch (e.role) {
        case UserRole.Lawyer:
          return hasLawyer = true;
        case UserRole.DocumentSupportSpecialist:
          return hasDss = true;
        case UserRole.LawClerk:
          return hasLawClerk = true;
      }
    });

    return {hasLawyer, hasDss, hasLawClerk};
  }, [staff])

    const handleNotifyProfessional = (stage: LocalStageType) => {
        let errorMessage;
        if (!hasLawyer) {
            errorMessage = "Please assign lawyer to the deal";
        } else if (!hasLawClerk && deal.address.state === "BC") {
            errorMessage = "Please assign law clerk to the deal";
        }
        if (errorMessage) {
            return enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: configuration.autoHideErrorDuration,
            });
        }

        onApprove(deal.id, stage.id, true, undefined, currentUser.id);
    }

  useEffect(() => {
    return () => {
      dispatch(clearDealInfoByIdAC());
    };
  }, [dispatch]);

  // @ts-ignore
  useEffect(() => {
    let isRendered = true; 
    if (deal) {
      parseDealStages(deal);
    }
    return () => isRendered = false;
  }, [deal]);

    useEffect(() => {
        if (startGetDealInfo) {
            onRefresh();
            dispatch(setStartGetDealInfoFalse());
        }
    }, [dispatch, startGetDealInfo]);


  return (
    <>
      <div className="dealoverlay__stages--steps">
        {stages === null
          ? ""
          : stages.map((stage, idx) => {
              if (stage.number === 6) return null;
              return (
                <div key={stage.id} className="step">
                  <span
                    className={
                      stage.complete
                        ? "step__content completed"
                        : stages.indexOf(stage) === 0
                        ? "step__content approved"
                        : "step__content"
                    }
                    style={{
                      cursor:
                        isCurrUserProfessional || currentUser.role === "Client"
                          ? "auto"
                          : "pointer",
                    }}
                    onClick={() => {
                      const currentStageIndex = stages.indexOf(stage);
                      const numberOfCompletedStages = deal.completed_stages.length;

                        let shouldBlock = currentStageIndex === 0;
                        let shouldChangeState =
                            numberOfCompletedStages >= 1 || (numberOfCompletedStages === 0 && isLender && currentStageIndex === 1);
                      
                      if (isCurrUserProfessional || shouldBlock || !shouldChangeState || currentUser.role === "Client") {
                        return false;
                      }
                      
                      handleStageChange(stage);
                    }}
                  >
                    <span style={{fontWeight: "bolder"}}>0{++idx}</span>
                    <p style={{maxWidth: "120px", wordWrap: "break-word"}}>
                      {stage.name}
                    </p>
                  </span>

                  {/* eslint-disable-next-line no-mixed-operators,array-callback-return */}
                  {stage.name.split(" ").includes("Mortgage") &&
                    !stage.complete &&
                    (isCurrentUserStaff ||
                      currentUser?.role === "Document Support Specialist") && (
                      <button
                        key={idx}
                        className="step__approve"
                        onClick={() => {
                          dispatch(passMortgageTC(dealInfo.id, stage.id));
                        }}
                      >
                        Bypass Stage
                      </button>
                    )}
                  {stage.name.split(" ").includes("Signing") &&
                    !stage.complete &&
                    dealInfo.appointment &&
                    dealInfo.appointment.length > 0 && (
                      <span
                        style={{
                          marginTop: 9,
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        {" "}
                        <span
                          style={{
                            display: "block",
                            color: "var(--green)",
                            fontSize: 12,
                            width: "100%",
                            textAlign: "center",
                          }}
                        >
                          Scheduled for
                        </span>
                        <span style={{fontSize: 12, textAlign: "center"}}>
                          {scheduledDate}{" "}
                          {currentUser.role !== "Broker" && scheduleTime}
                        </span>
                      </span>
                    )}

                  {stage.date.length > 0 &&
                    clientsList.map((el, idx) => {
                      if (el !== currentUser.role) return null;
                      else {
                        return (
                          <div key={idx}>
                            <span className="step__status">
                              <span>
                                {formatRelativeDate(stage.date[0].stage_reached.split(".")[0], false)}
                              </span>
                              <span>completed</span>
                            </span>
                            {stage?.date[0].survey?.status && (
                              <span
                                className="step__status"
                                style={{marginBottom: "1rem"}}
                              >
                                <span>
                                  {formatRelativeDate((stage.date[0].survey.date as string).split(".")[0], false)}
                                </span>
                                <span>{stage?.date[0].survey?.status}</span>
                              </span>
                            )}
                            {stage.number === 2 && (
                              <span key={idx} className="step__status">
                                {stage?.date[0].reviewed !== null && (
                                  <>
                                    <span>
                                      {formatRelativeDate((stage?.date[0]?.reviewed as string).split(".")[0], false)}
                                    </span>
                                    <span style={{color: "#d7af70"}}>
                                      reviewed
                                    </span>
                                  </>
                                )}
                              </span>
                            )}
                          </div>
                        );
                      }
                    })}
                  {/* ADD ITEM */}
                  <div
                    style={{display: "flex", justifyContent: "space-between"}}
                  >
                    {/* APPROVE */}
                    {stage.to_approve &&
                      stages.indexOf(stage) === 0 &&
                      clientsList.map((el, idx) => {
                        if (el !== currentUser.role || isLender) {
                          return null;
                        }

                        return (
                          <button
                            key={idx}
                            className="step__approve"
                            disabled={loading}
                            onClick={() => handleNotifyProfessional(stage)}
                          >
                            Notify Professional
                          </button>
                        );
                      })}
                  </div>
                </div>
              );
            })}
      </div>
            
        {confirmModal && <UpdateConfirm
            open={confirmModal}
            currentStage={currentStage}
            deal={deal}
            handleConfirm={(notification, stageTemplate, templateUser) => {
                const sender = templateUser ? templateUser.id : currentUser.id;
                setConfirmModal(false);
                onApprove(
                    deal.id,
                    (stages || []).find((el) => el.to_approve) === undefined || deal.deal_type === "Lender"
                        ? currentStage?.id
                        : (stages || []).find((el) => el.to_approve)?.id,
                    notification,
                    stageTemplate,
                    sender,
                );
            }}
            handleClose={() => setConfirmModal(false)}
            staffList={staff}
        />}
    </>
    );
};

export default DealStages;
