import DeededSpace from "v2/components/DeededSpace";
import DeededLabel from "v2/components/DeededLabel";
import DeededSelect from "v2/components/DeededSelect_DEPRECATED";
import {MenuItem} from "@mui/material";
import React from "react";
import {MobileSignerType} from "../../types/mobileSigner";

interface CloseCalendarLapseV2MobileSignersProps {
  mobileSigners: MobileSignerType[] | null;
  handleChange: (someToChange: unknown) => void;
  mobileSignerId: number | null;
  errors: unknown & {mobileSignerId?: string};
}

const CloseCalendarLapseV2MobileSigners: React.FC<
  CloseCalendarLapseV2MobileSignersProps
> = ({mobileSignerId, handleChange, mobileSigners, errors}) => {
  return (
    <>
      <DeededSpace y={5} />
      <DeededLabel title={"Show mobile signers"} />
      <DeededSelect
        error={errors?.mobileSignerId ? " " : undefined}
        onChange={(e) => {
          handleChange({
            target: {
              name: "mobileSignerId",
              value: e.target.value,
            },
          });
        }}
        value={mobileSignerId}
        sx={{width: "312px"}}
      >
        {mobileSigners?.map((signer) => {
          return <MenuItem value={signer.id}>{signer.value}</MenuItem>;
        })}
      </DeededSelect>
      <DeededSpace y={15} />
    </>
  );
};
export default CloseCalendarLapseV2MobileSigners;
