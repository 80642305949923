import {useEffect, useMemo, useState} from "react";
import {useSelector} from "react-redux";
import TemplateEditor from "../TemplateEditor/TemplateEditor";
import {ReusableSelect} from "../../../utils/reusableSelect";
import DeededPopup from "v2/components/DeededPopup";
import { StageNumber } from "types/deal";
import { getActiveTemplates, saveEditedTemplate } from "api";
import { useSnackbar } from "notistack";
import configuration from "utils/configuration";

const UpdateConfirm = ({
  open,
  handleConfirm,
  handleClose,
  staffList,
  currentStage,
  deal,
}) => {
    const [notification, setNotification] = useState(true);
    const { currentUser } = useSelector((state) => state.AuthReducer);
    const [templatesList, setTemplatesList] = useState([]);
    const [templateUser, setTemplateUser] = useState(null);
    const [stageTemplate, setStageTemplate] = useState({
        subject: "",
        content: "",
        label: "",
        value: "",
    });

    const { enqueueSnackbar } = useSnackbar();
    const shouldSendOldTemplate = (stage) => stage.number !== StageNumber.Closed; 

    useEffect(() => {
        if (!currentStage?.id || !deal?.id) return;
        getActiveTemplates(deal.id, currentStage.id).then((res) => {
            setTemplatesList(res.data?.map((el) => ({ ...el, label: el.template_name, value: el.template_name })));
        }).catch((error) => enqueueSnackbar(error.response.data.error, {
            variant: "error",
            autoHideDuration: configuration.autoHideErrorDuration,
        }));
    }, [currentStage, deal?.id]);

    const handleStageTemplateSave = (templateUser, stageTemplate, deal) => {
        const { id, subject, content } = stageTemplate;
        const payload = {
            user_id: templateUser?.id ?? currentUser.id,
            template_id: id,
            subject,
            content,
        };

        id && saveEditedTemplate(deal.id, payload).then((res) => {
            enqueueSnackbar("Save template changes", {
                variant: "success",
                autoHideDuration: 1500,
            });
        });
    };
    const onClose = () => {
        setStageTemplate({
            subject: "",
            content: "",
            label: "",
            value: "",
        });
        setTemplateUser(null);
        handleClose();
    };

    const onConfirm = () => {
        handleStageTemplateSave(templateUser, stageTemplate, deal);
        handleConfirm(notification, stageTemplate, templateUser)
    };

  return (
    <DeededPopup
      sx={{}}
      open={open}
      setOpen={onClose}
      title="Confirm stage change?"
      sxInternalBox={{
        width: "600px",
        overflowX: "hidden",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
          width: "5px",
          scrollbarWidth: "thin",
        },
      }}
    >
      <div className="update__confirm-wrapper">
        <div className="update__confirm">
          <label className="container">
            <span className="container-title">Do not send notifications</span>
            <input
              type="checkbox"
              checked={!notification}
              onChange={() => setNotification(!notification)}
            />
            <span className="checkmark"></span>
          </label>
          {!currentStage.complete && shouldSendOldTemplate(currentStage) &&
            <div className="update__confirm">
              <span className="select-title">Send template from:</span>
              <ReusableSelect
                disabled={staffList.find((el) => el.role === currentUser.role)}
                options={[currentUser, ...staffList].map((el) => ({
                  ...el,
                  label: `${el.first_name} ${el.last_name}`,
                  value: el.id,
                }))}
                defaultValue={
                  templateUser !== null
                    ? templateUser
                    : [currentUser, ...staffList].map((el) => ({
                        ...el,
                        label: `${el.first_name} ${el.last_name}`,
                        value: el.id,
                      }))[0]
                }
                onChangeHandler={(val) => setTemplateUser(val)}
                defaultStyles={true}
              />
              <span className="select-title">Select template:</span>
              <ReusableSelect
                options={templatesList}
                defaultValue={stageTemplate}
                onChangeHandler={(e) => setStageTemplate(e)}
                defaultStyles={false}
              />
          

            <label htmlFor="subject">
              Subject:
              <input
                autoComplete="new-password"
                type="text"
                value={stageTemplate.subject}
                onChange={(val) => setStageTemplate((prevState) => ({ ...prevState, subject: val.target }))}
              />
            </label>
          </div>
          }
          {shouldSendOldTemplate(currentStage) && <TemplateEditor
            title={"template"}
            value={stageTemplate.content}
            handleTextField={(val) => setStageTemplate((prevState) => ({ ...prevState, content: val }))}
          />}
          <div className="buttons__container">
            <button
              style={{backgroundColor: "#02cd8f", color: "#fff"}}
              onClick={() => onConfirm()}
            >
              {currentStage.complete ? "Revert" : "Save"}
              
            </button>
            <button style={{border: "1px solid #d9d9d9"}} onClick={onClose}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </DeededPopup>
  );
};

export default UpdateConfirm;
