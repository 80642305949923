import React, { FC, ReactNode } from "react";
import { Box, Stack } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { format } from "date-fns";
import { IntegrationDeal } from "types/integrations";
import Button from "components/CommonDashboard/Button";
import { BodyRegular, ButtonSmallText } from "components/CommonDashboard/Typography";
import Colors from "components/CommonDashboard/Styling/Colors";
import colors from "components/CommonDashboard/Styling/Colors";
import Pill from "components/CommonDashboard/Pill";
import { MailIcon, PhoneIcon } from "components/CommonDashboard/Icons";
import ClickToCopy from "components/CommonDashboard/ClickToCopy";
import SelectField from "components/CommonDashboard/SelectField";
import { allDealTypes } from "components/Dashboard/Calendar/constants/allDealTypes";
import { useGetDealTypeTagsQuery } from "redux/reducers/dealInfoApi";

interface IntegrationDealRowProps {
    deal: IntegrationDeal;
    onUpdate: (dealType: string) => void;
    first?: boolean;
    last?: boolean;
}

const IntegrationDealRow: FC<IntegrationDealRowProps> = ({ deal, onUpdate, first, last }) => {
    const { data: dealTypesTags } = useGetDealTypeTagsQuery("");

    const { handleSubmit, isSubmitting, getFieldProps, isValid } = useFormik<{ deal_type: string }>({
        initialValues: { deal_type: deal.deal_type ?? "" },
        onSubmit: (data: { deal_type: string }) => onUpdate(data.deal_type),
        validateOnChange: true,
        validateOnMount: true,
        validationSchema: yup.object().shape({
            deal_type: yup.string().required(),
        }),
    });

    const hasEmail = Boolean(deal.client.email);
    const createdAt = new Date(deal.created_at);
    const updatedAt = new Date(deal.updated_at);

    return (
        <Stack
            minWidth={1300}
            px="1.6rem"
            gap="1.6rem"
            border={`1px solid ${Colors.NEUTRAL_300}`}
            flexDirection="row"
            sx={{
                borderTopLeftRadius: first ? "2.4rem" : undefined,
                borderTopRightRadius: first ? "2.4rem" : undefined,
                borderBottomWidth: last ? 1 : 0,
                borderBottomLeftRadius: last ? "2.4rem" : undefined,
                borderBottomRightRadius: last ? "2.4rem" : undefined,
            }}
        >
            <Cell w="22rem">
                <Pill label={deal.province} color={colors.PRIMARY_600} backgroundColor={colors.PRIMARY_100} />
                <BodyRegular noWrap color={Colors.NEUTRAL_700}>
                    {deal.address}
                </BodyRegular>
            </Cell>
            <Cell>
                <Stack>
                    <BodyRegular color={Colors.NEUTRAL_700}>
                        {format(createdAt, 'MM/dd/yyyy')}
                    </BodyRegular>
                    <BodyRegular color={Colors.NEUTRAL_700}>
                        {format(createdAt, 'h:mmaaa')}
                    </BodyRegular>
                </Stack>
            </Cell>
            <Cell>
                <Stack>
                    <BodyRegular color={Colors.NEUTRAL_700}>
                        {format(updatedAt, 'MM/dd/yyyy')}
                    </BodyRegular>
                    <BodyRegular color={Colors.NEUTRAL_700}>
                        {format(updatedAt, 'h:mmaaa')}
                    </BodyRegular>
                </Stack>
            </Cell>
            <Cell>
                <Stack gap="0.6rem">
                    <BodyRegular noWrap color={Colors.NEUTRAL_700}>
                        {deal.client.name}
                    </BodyRegular>
                    {hasEmail ? (
                        <Stack direction="row" alignItems="center" gap="0.4rem">
                            <MailIcon size="small" color={Colors.NEUTRAL_700} />
                            <ClickToCopy isFlex>
                                <ButtonSmallText noWrap color={Colors.NEUTRAL_700}>{deal.client.email}</ButtonSmallText>
                            </ClickToCopy>
                        </Stack>
                    ) : (
                        <ButtonSmallText color={Colors.ERROR}>no email</ButtonSmallText>
                    )}
                    {deal.client.phone && (
                        <Stack direction="row" alignItems="center" gap="0.4rem">
                            <PhoneIcon size="small" color={Colors.NEUTRAL_700} />
                            <ClickToCopy isFlex>
                                <ButtonSmallText noWrap>{deal.client.phone}</ButtonSmallText>
                            </ClickToCopy>
                        </Stack>
                    )}
                </Stack>
            </Cell>
            <Cell>
                <Stack gap="0.6rem">
                    <BodyRegular noWrap color={Colors.NEUTRAL_700}>
                        {deal.broker.name}
                    </BodyRegular>
                    <Stack direction="row" alignItems="center" gap="0.4rem">
                        <MailIcon size="small" color={Colors.NEUTRAL_700} />
                        <ClickToCopy isFlex>
                            <ButtonSmallText>{deal.broker.email}</ButtonSmallText>
                        </ClickToCopy>
                    </Stack>
                    {deal.broker.phone && (
                        <Stack direction="row" alignItems="center" gap="0.4rem">
                            <PhoneIcon size="small" color={Colors.NEUTRAL_700} />
                            <ClickToCopy isFlex>
                                <ButtonSmallText>{deal.broker.phone}</ButtonSmallText>
                            </ClickToCopy>
                        </Stack>
                    )}
                </Stack>
            </Cell>
            <Cell>{deal.external_id}</Cell>
            <Cell>
                {deal.deal_type ? (
                    deal.deal_type
                ) : (
                    <SelectField
                        {...getFieldProps("deal_type")}
                        placeholder="Select"
                        sx={{ marginBottom: "5px" }}
                        fullWidth
                        outlined
                        size="small"
                        options={[
                            ...allDealTypes.filter((t) => t !== "Sale").map((type) => ({ id: type, name: type })),
                            ...(dealTypesTags?.Mortgage.filter((t) => !t.name.includes("ILA")).map(({ id, name }) => ({
                                id: id.toString(),
                                name,
                            })) ?? []),
                        ]}
                    />
                )}
            </Cell>
            <Cell mW="13.5rem">
                <Button
                    onClick={() => handleSubmit()}
                    disabled={isSubmitting || !isValid}
                    sx={{ padding: "0.8rem" }}
                    typeOf="CTA"
                    size="small"
                    label={{ text: "Create transaction" }}
                />
            </Cell>
        </Stack>
    );
};

const Cell: FC<{ children?: ReactNode | string; w?: string; mW?: string }> = ({ children, w, mW }) => (
    <Box
        gap="0.8rem"
        py="1.6rem"
        display="flex"
        minWidth={w}
        maxWidth={mW}
        flex={1}
        alignItems="center"
        overflow="hidden"
    >
        {typeof children === "string" ? (
            <BodyRegular noWrap color={Colors.NEUTRAL_700}>
                {children}
            </BodyRegular>
        ) : (
            children
        )}
    </Box>
);

export default IntegrationDealRow;
