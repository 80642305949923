import { useState, useEffect } from 'react';
import configuration from 'utils/configuration';
import useTypedSelector from './useTypedSelector';
import { useDispatch } from 'react-redux';
import { getConveyanceStatus } from 'redux/reducers/dealsReducer';
import { Deal } from 'types/deal';
import { User } from 'types/user';

export interface ConveyanceProps {
    deal?: Deal;
    user?: Partial<User>;
};

const CONVEYABLE_PROVINCE_LIST = [
    "ON",
    "BC",
    "AB"
];

const CONVEYABLE_USER_ROLE_LIST = [
    "Admin",
    "System Admin",
    "Lawyer",
    "Law Clerk",
    "Document Support Specialist",
    "Closing Advisor",
    "Access",
    "File Opener"
];

const useConveyance = (params: ConveyanceProps) => {
    const dispatch = useDispatch();
    const { deal, user } = params;
    
    // const variable declaration
    const [conveyanceEnabled, setConveyanceEnabled] = useState<boolean>(false);
    const [conveyanceConfigFlag, setConveyanceConfigFlag] = useState<boolean>(false);
    const [dealConveyable, setDealConveyable] = useState<boolean | undefined>(undefined);

    // Conveyance logic
    const conveyanceUp: boolean | null = useTypedSelector(
        (state) => state.DealsReducer.conveyanceUp
    );

    const isDealConveyable = (deal: Deal): void => {
        // Is deal conveyable
        if (CONVEYABLE_PROVINCE_LIST.includes(deal.address.state) && deal.deal_type === "Mortgage") {
            setDealConveyable(true);
        } else setDealConveyable(false);
    };

    const doesUserHaveAccess = (user: Partial<User> | undefined): boolean => {
        if (!user) return false;
        if (CONVEYABLE_USER_ROLE_LIST.includes(user.role ?? "")) {
            return true;
        }
        return false;
    }

    // Check conveyance health
    useEffect(() => {
        if (conveyanceUp !== null) return;
        // Only running API call if .env conveyance flag is set to true
        if (conveyanceConfigFlag) dispatch(getConveyanceStatus());
    }, [conveyanceConfigFlag]);

    useEffect(() => {
        if (configuration.conveyanceEnabled === "true") setConveyanceConfigFlag(true);
        else setConveyanceConfigFlag(false);
    }, [configuration.conveyanceEnabled])

    useEffect(() => {
        // Is user allowed to access DCP
        if (!doesUserHaveAccess(user)) {
            setDealConveyable(false);
            setConveyanceEnabled(false);
            return;
        }
        // Is DCP enabled and running
        if (conveyanceConfigFlag && conveyanceUp) setConveyanceEnabled(true);
        else setConveyanceEnabled(false);
        
        // Is deal conveyable
        if (!deal) {
            setDealConveyable(undefined);
            return;
        };
        isDealConveyable(deal);
    }, [conveyanceConfigFlag, conveyanceUp, deal, user]);

    return { conveyanceEnabled, dealConveyable };
}
    
export { useConveyance };