import React from "react";
import "./StoragePage.scss";
import "./Files.scss";
import {FileInfo} from "../StorageCommonComponents";
import useWindowWidth from "../../../utils/hooks/useWindowWidth";

export const StorageContext = React.createContext({
    isShouldShowFileInfoSection: true,
});

const BaseStoragePage = ({children, fileInfoSection, files}) => {
  const windowWidth = useWindowWidth();

  const isShouldShowFileInfoSection = fileInfoSection && windowWidth > 1439;
  return (
    <>
      <StorageContext.Provider
        value={{
          isShouldShowFileInfoSection,
        }}
      >
        {children}
        <div className="storageContent">
          {files}
          {isShouldShowFileInfoSection && <FileInfo />}
        </div>
      </StorageContext.Provider>
    </>
  );
};

export default BaseStoragePage;
