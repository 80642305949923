import React from "react";
import {Box} from "@mui/material";
import DeededCircularLoader from "v2/components/DeededCircularLoader";

const CalendarLoader = ({calendarWidth}: {calendarWidth: string}) => {
  return (
    <>
      <Box
        sx={{
          width: calendarWidth,
          background: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "77vh",
        }}
      >
        <DeededCircularLoader />
      </Box>
    </>
  );
};
export default CalendarLoader;
