import React from "react";

const BulbSvg = () => {
  return (
    <svg
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 37H26"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.8376 28.8749C13.6471 27.9492 12.6829 26.7647 12.018 25.4111C11.3531 24.0576 11.005 22.5704 11.0001 21.0624C10.9751 15.6374 15.3376 11.1249 20.7626 10.9999C22.8622 10.9501 24.9242 11.5626 26.6561 12.7506C28.388 13.9385 29.7019 15.6416 30.4115 17.6183C31.1211 19.5949 31.1904 21.7448 30.6094 23.7631C30.0285 25.7813 28.827 27.5654 27.1751 28.8624C26.8102 29.1444 26.5146 29.5062 26.311 29.92C26.1073 30.3338 26.001 30.7887 26.0001 31.2499V31.9999C26.0001 32.2651 25.8948 32.5195 25.7072 32.707C25.5197 32.8945 25.2653 32.9999 25.0001 32.9999H17.0001C16.7349 32.9999 16.4805 32.8945 16.293 32.707C16.1055 32.5195 16.0001 32.2651 16.0001 31.9999V31.2499C15.997 30.7921 15.8909 30.341 15.6896 29.9298C15.4884 29.5187 15.1972 29.1581 14.8376 28.8749V28.8749Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.0127 15.0996C23.2349 15.3068 24.3627 15.8882 25.2404 16.7637C26.1181 17.6391 26.7024 18.7654 26.9127 19.9871"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 2V7.89682"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.9322 8.70752L31.0003 10.806"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.00042 28.8062L8.93237 26.7077"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.0059 10.7662L7.99984 8.77531"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.9257 26.7748L35.9318 28.7657"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39 19H35"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 19L7 19"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default BulbSvg;
