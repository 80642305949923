import {AxiosResponse} from "axios";
import moment from "moment";
import {AnyAction, Dispatch} from "redux";
import {addErrorAC, addSuccessMessageAC} from "./ErrorReducer";
import {
  createClosingDate,
  createComments,
  deleteClosingDate,
  getClosingDateList,
  getComments,
  getDealByID,
  getEvents,
  getFullDealInfo,
  getLewClerksDealsNumber,
  getStaff,
  updateClosingDate,
} from "api";
import {Staff} from "types/staffTypes";
import {CalendarFullDealInfoType} from "types/calendarFullDealInfoType";
import {CalendarAppointment} from "types/calendarAppointmentType";
import {parseDateFromBackendForBlockedTimeCalendar} from "utils/parseDateFromBackendForBlockedTimeCalendar";
import {StaffUser} from "./configReducer/staffCardReducer";
import {
  ClosingsDealsCountDailyType,
  ResultCalendarDateType,
  ResultCalendarSumType,
} from "./types/calendarApiTypes";
import {getStartOrEndDateToRequest} from "components/Dashboard/Calendar/utils/getStartOrEndDateToRequest";

type CalendarActionType =
  | "GET_EVENTS"
  | "CLEAR_EVENTS"
  | "SET_IS_GETTING_EVENTS"
  | "SET_STAFF"
  | "SET_CLOSING_DATES"
  | "SET_SUCCESS_CLOSING_CREATE"
  | "SET_ERROR_MESSAGE_CLOSING_DATE"
  | "SET_CLOSING_EDIT_MODE"
  | "SET_FULL_DEAL_INFO"
  | "SET_CURRENT_APPOINTMENT_ID"
  | "SET_SELECTED_APPOINTMENT"
  | "SET_SUCCESSFULLY_DELETED"
  | "SET_LAW_CLERKS_DEALS_NUMBER"
  | "SET_CALENDAR_TYPE"
  | "SET_MOBILE_SIGNER_COUNT_TABLE_VIEW"
  | "SET_LAWYER_COUNT_TABLE_VIEW"
  | "SET_LAW_CLERK_COUNT_TABLE_VIEW"
  | "SET_CALENDAR_TIMEZONE"
  | "SET_SELECTED_STAFF_USER"
  | "SET_SELECTED_STAFF_NAME"
  | "SET_CLOSINGS_CALENDAR_RESULT_BY_DATES"
  | "SET_CLOSINGS_CALENDAR_RESULT_BY_SUM"
  | "SET_CREATE_EVENT_FOR_CURRENT_DATE_BY_DEFAULT"
  | "SET_IS_OPENED_EVENT_CREATE_POPUP"
  | "SET_IS_LOADING_STAFF"
  | "SET_IS_LOADING_CLOSING_DATES"
  | "SET_CLOSINGS_DEALS_COUNT_DAILY";
export interface Appointment {
  id: number;
  deal_id: number;
  address: string;
  datetime: Date;
  deleted_at: null;
  timezone: string;
  calendar_id: number;
  user_id: null;
  appointment_updates: AppointmentUpdate[];
  signer: null;
}

export interface AppointmentUpdate {
  id: number;
  appointment_id: number;
  appointment_updated_by: AppointmentUpdatedBy;
  appointment_updated_at: Date;
  action: string;
}

export interface AppointmentUpdatedBy {
  full_name: string;
}

export interface CalendarEvent {
  timezone: string;
  is_full_day: boolean;
  end_at: string;
  end_date: string;
  start_at: string;
  start_date: string;
  appointment?: CalendarAppointment | null;
  title: string;
  start: Date;
  end: Date;
  desc?: string;
  allDay?: boolean;
  state?: string;
  user_id?: number | null;
  for_user?: number;
  type?: string;
  id?: number;
}

type BackendEvent = {
  start_at: string;
  end_at: string;
  name: string;
  appointment: Appointment;
};

export interface CalendarReducerState {
  isLoadingClosingDates: boolean;
  closingsCalendarResultBySum: ResultCalendarSumType;
  closingsCalendarResultByDates: ResultCalendarDateType;
  selectedStaffUser: StaffUser | null;
  selectedStaffName: string;
  calendarTimezone: string;
  mobileSignerCountTableView: "dealType" | "week";
  lawyerCountTableView: "dealType" | "week";
  lawClerkCountTableView: "dealType" | "week";
  calendarType: string;
  isLoadingStaff: boolean;
  staff: Staff | {};
  events: CalendarEvent[];
  isGettingEvents: boolean;
  closingDates: CalendarEvent[] | {data: CalendarEvent[]};
  successClosingCreate: boolean | null;
  errorMessageClosingDate: string;
  isClosingEditMode: boolean;
  dealFullInfo: null | CalendarFullDealInfoType;
  currentAppointmentId: number | null;
  selectedAppointment: CalendarAppointment | null;
  successfullyDeleted: boolean | null;
  lawClerksDealsNumber: LawClerksDealsNumber[];
  createEventForCurrentDateByDefault: boolean;
  isOpenedEventCreatePopup: boolean;
  closingsDealsCountDaily: ClosingsDealsCountDailyType | null;
}

export type CalendarAction = {
  type: CalendarActionType;
  payload?: unknown;
};
export interface LawClerksDealsNumber {
  id: number;
  full_name: string;
  current_deals_count: number;
}

const calendarState: CalendarReducerState = {
  isLoadingClosingDates: false,
  isOpenedEventCreatePopup: false,
  createEventForCurrentDateByDefault: false,
  closingsCalendarResultBySum: {
    "Law Clerk": [],
    Lawyer: [],
  },
  closingsCalendarResultByDates: [] as unknown as ResultCalendarDateType,
  selectedStaffUser: null,
  selectedStaffName: "",
  calendarTimezone: "",
  mobileSignerCountTableView: "dealType",
  lawyerCountTableView: "dealType",
  lawClerkCountTableView: "dealType",
  calendarType: "Signing Appointment",
  isClosingEditMode: false,
  closingDates: [],
  staff: {},
  isLoadingStaff: false,
  isGettingEvents: false,
  successClosingCreate: null,
  errorMessageClosingDate: "",
  dealFullInfo: null,
  currentAppointmentId: null,
  selectedAppointment: null,
  successfullyDeleted: null,
  lawClerksDealsNumber: [],
  closingsDealsCountDaily: null,
  events: [
    // Example of React Big Calendar Deal
    // {
    //   title: "All Day Event very long title",
    //   allDay: true,
    //   start: new Date(2015, 3, 0),
    //   end: new Date(2015, 3, 1),
    // },
  ],
};

export const CalendarReducer = (
  state = calendarState,
  action: CalendarAction,
): CalendarReducerState => {
  switch (action.type) {
    case "SET_CLOSINGS_DEALS_COUNT_DAILY": {
      return {
        ...state,
        closingsDealsCountDaily:
          action.payload as ClosingsDealsCountDailyType | null,
      };
    }
    case "SET_IS_LOADING_CLOSING_DATES": {
      return {
        ...state,
        isLoadingClosingDates: action.payload as boolean,
      };
    }
    case "SET_IS_LOADING_STAFF": {
      return {
        ...state,
        isLoadingStaff: action.payload as boolean,
      };
    }
    case "SET_IS_OPENED_EVENT_CREATE_POPUP": {
      return {
        ...state,
        isOpenedEventCreatePopup: action.payload as boolean,
      };
    }
    case "SET_CREATE_EVENT_FOR_CURRENT_DATE_BY_DEFAULT": {
      return {
        ...state,
        createEventForCurrentDateByDefault: action.payload as boolean,
      };
    }
    case "SET_CLOSINGS_CALENDAR_RESULT_BY_SUM": {
      return {
        ...state,
        closingsCalendarResultBySum: action.payload as ResultCalendarSumType,
      };
    }
    case "SET_CLOSINGS_CALENDAR_RESULT_BY_DATES": {
      return {
        ...state,
        closingsCalendarResultByDates: action.payload as ResultCalendarDateType,
      };
    }
    case "SET_SELECTED_STAFF_USER": {
      return {
        ...state,
        selectedStaffUser: action.payload as StaffUser,
      };
    }
    case "SET_SELECTED_STAFF_NAME": {
      return {
        ...state,
        selectedStaffName: action.payload as string,
      };
    }
    case "SET_CALENDAR_TIMEZONE": {
      return {
        ...state,
        calendarTimezone: action.payload as string,
      };
    }
    case "SET_MOBILE_SIGNER_COUNT_TABLE_VIEW": {
      return {
        ...state,
        mobileSignerCountTableView: action.payload as "dealType" | "week",
      };
    }
    case "SET_LAWYER_COUNT_TABLE_VIEW": {
      return {
        ...state,
        lawyerCountTableView: action.payload as "dealType" | "week",
      };
    }
    case "SET_LAW_CLERK_COUNT_TABLE_VIEW": {
      return {
        ...state,
        lawClerkCountTableView: action.payload as "dealType" | "week",
      };
    }
    case "SET_CALENDAR_TYPE": {
      return {
        ...state,
        calendarType: action.payload as string,
      };
    }
    case "SET_LAW_CLERKS_DEALS_NUMBER": {
      return {
        ...state,
        lawClerksDealsNumber: action.payload as LawClerksDealsNumber[],
      };
    }
    case "SET_SUCCESSFULLY_DELETED": {
      return {
        ...state,
        successfullyDeleted: action.payload as boolean | null,
      };
    }
    case "SET_SELECTED_APPOINTMENT": {
      return {
        ...state,
        selectedAppointment: action.payload as CalendarAppointment | null,
      };
    }
    case "SET_CURRENT_APPOINTMENT_ID": {
      return {
        ...state,
        currentAppointmentId: action.payload as number | null,
      };
    }
    case "SET_FULL_DEAL_INFO": {
      return {
        ...state,
        dealFullInfo: action.payload as CalendarFullDealInfoType | null,
      };
    }
    case "SET_CLOSING_EDIT_MODE": {
      return {
        ...state,
        isClosingEditMode: action.payload as boolean,
      };
    }
    case "SET_ERROR_MESSAGE_CLOSING_DATE": {
      return {
        ...state,
        errorMessageClosingDate: action.payload as string,
      };
    }
    case "SET_SUCCESS_CLOSING_CREATE": {
      return {
        ...state,
        successClosingCreate: action.payload as boolean | null,
      };
    }
    case "SET_CLOSING_DATES": {
      return {
        ...state,
        closingDates: action.payload as CalendarReducerState["closingDates"],
      };
    }
    case "SET_IS_GETTING_EVENTS": {
      return {
        ...state,
        isGettingEvents: action.payload as boolean,
      };
    }
    case "SET_STAFF": {
      return {
        ...state,
        staff: action.payload as Staff,
      };
    }
    case "CLEAR_EVENTS": {
      return {
        ...state,
        events: [],
      };
    }
    case "GET_EVENTS": {
      return {
        ...state,
        events: (action.payload as BackendEvent[]).map((e, index) => {
          return {
            ...e,
            timezone: e.appointment.timezone as string,
            title: e.name,
            start: moment(e.start_at, "YYYY-MM-DD HH:mm:ss").toDate(),
            end: moment(e.end_at, "YYYY-MM-DD HH:mm:ss").toDate(),
          };
        }) as unknown as CalendarEvent[],
      };
    }
    default:
      return state;
  }
};
export const setClosingsDealsCountDailyAC = (
  payload: ClosingsDealsCountDailyType,
) => ({
  type: "SET_CLOSINGS_DEALS_COUNT_DAILY",
  payload,
});
export const setIsLoadingClosingDatesAC = (payload: boolean) => ({
  type: "SET_IS_LOADING_CLOSING_DATES",
  payload,
});
export const setIsLoadingStaffAC = (payload: boolean) => ({
  type: "SET_IS_LOADING_STAFF",
  payload,
});
export const setIsOpenedEventCreatePopupAC = (payload: boolean) => ({
  type: "SET_IS_OPENED_EVENT_CREATE_POPUP",
  payload,
});
export const setCreateEventForCurrentDateByDefaultAC = (payload: boolean) => ({
  type: "SET_CREATE_EVENT_FOR_CURRENT_DATE_BY_DEFAULT",
  payload,
});
export const setClosingsCalendarResultsBySumAC = (
  payload: ResultCalendarSumType,
) => ({
  type: "SET_CLOSINGS_CALENDAR_RESULT_BY_SUM",
  payload,
});
export const setClosingsCalendarResultsByDatesAC = (
  payload: ResultCalendarDateType,
) => ({
  type: "SET_CLOSINGS_CALENDAR_RESULT_BY_DATES",
  payload,
});
export const setSelectedStaffUserAC = (payload: StaffUser | null) => ({
  type: "SET_SELECTED_STAFF_USER",
  payload,
});
export const setSelectedStaffNameAC = (payload: string) => ({
  type: "SET_SELECTED_STAFF_NAME",
  payload,
});
export const setCalendarTimezoneAC = (payload: string) => ({
  type: "SET_CALENDAR_TIMEZONE",
  payload,
});
export const setMobileSignerCountTableViewAC = (
  payload: "dealType" | "week",
) => ({
  type: "SET_MOBILE_SIGNER_COUNT_TABLE_VIEW",
  payload,
});
export const setLawyerCountTableViewAC = (payload: "dealType" | "week") => ({
  type: "SET_LAWYER_COUNT_TABLE_VIEW",
  payload,
});
export const setLawClerkCountTableViewAC = (payload: "dealType" | "week") => ({
  type: "SET_LAW_CLERK_COUNT_TABLE_VIEW",
  payload,
});
export const setCalendarTypeAC = (payload: string) => ({
  type: "SET_CALENDAR_TYPE",
  payload,
});
export const setLawClerksDealsNumberAC = (payload: LawClerksDealsNumber[]) => ({
  type: "SET_LAW_CLERKS_DEALS_NUMBER",
  payload,
});
export const setSuccessfullyDeletedAC = (payload: boolean | null) => ({
  type: "SET_SUCCESSFULLY_DELETED",
  payload,
});
export const setSelectedAppointmentAC = (
  payload: CalendarAppointment | null,
) => ({
  type: "SET_SELECTED_APPOINTMENT",
  payload,
});
export const setCurrentAppointmentIdAC = (payload: number | null) => ({
  type: "SET_CURRENT_APPOINTMENT_ID",
  payload,
});
export const getEventsAC = (payload: BackendEvent[]) => ({
  type: "GET_EVENTS",
  payload,
});
export const clearEventsAC = () => ({
  type: "CLEAR_EVENTS",
});
export const setIsGettingEvents = (payload: boolean) => ({
  type: "SET_IS_GETTING_EVENTS",
  payload,
});
export const setStaffAC = (payload: Staff) => ({
  type: "SET_STAFF",
  payload,
});
export const setClosingDateAC = (payload: unknown) => ({
  type: "SET_CLOSING_DATES",
  payload,
});
export const setSuccessClosingCreateAC = (payload: boolean | null) => ({
  type: "SET_SUCCESS_CLOSING_CREATE",
  payload,
});
export const setErrorMessageClosingDateAC = (payload: string) => ({
  type: "SET_ERROR_MESSAGE_CLOSING_DATE",
  payload,
});
export const setIsClosingEditModeAC = (payload: boolean) => ({
  type: "SET_CLOSING_EDIT_MODE",
  payload,
});
export const setFullDealInfoAC = (
  payload: null | CalendarFullDealInfoType,
) => ({
  type: "SET_FULL_DEAL_INFO",
  payload,
});
export const getFullDealInfoTC = (id: number) => async (dispatch: Dispatch) => {
  try {
    const fullDealInfo = await getFullDealInfo(id);
    dispatch(setFullDealInfoAC(fullDealInfo.data));
  } catch (e) {
    const error = e as {response: {data: {error: string}}};
    dispatch(addErrorAC(error?.response?.data.error ?? "Something was wrong"));
  }
};
export const getDealByIdCalendarClosingsTC =
  (id: number) => async (dispatch: Dispatch) => {
    try {
      const getDealByIDResponse = await getDealByID(id);
      const getCommentsResponse = await getComments(id);
      dispatch(
        setFullDealInfoAC({
          ...getDealByIDResponse.data,
          isOpenedByClosing: true,
          comments: getCommentsResponse?.data ?? [],
        } as unknown as CalendarFullDealInfoType & {
          isOpenedByClosing: boolean;
        }),
      );
    } catch (e) {
      const error = e as {response: {data: {error: string}}};
      dispatch(
        addErrorAC(error?.response?.data.error ?? "Something was wrong"),
      );
    }
  };
export const getEventsTC =
  (
    date: Date,
    regions: string | string[],
    staff: string | string[] = "ALL",
    dealTypes: string | string[] = "ALL",
    selectedStaffUser: StaffUser | null,
    calendarTimezone: string,
    view: "day" | "week" | "month",
  ) =>
  async (dispatch: Dispatch) => {
    try {
      if (calendarTimezone.length !== 0) {
        dispatch(setIsGettingEvents(true));
        const selectedStaffUserIdString = selectedStaffUser
          ? selectedStaffUser.id.toString()
          : "ALL";
        const startDate = getStartOrEndDateToRequest(
          date,
          view,
          "start",
          "YYYY-M-D",
        );
        const endDate = getStartOrEndDateToRequest(
          date,
          view,
          "end",
          "YYYY-M-D",
        );
        const events = await getEvents(
          startDate,
          endDate,
          regions as string,
          staff as string,
          dealTypes as string,
          selectedStaffUserIdString as string,
          calendarTimezone,
        );

        dispatch(getEventsAC(events?.data));
        dispatch(setIsGettingEvents(false));
      }
    } catch (e) {
      dispatch(setIsGettingEvents(false));
    }
  };
export const getStaffTC =
  (with_admin?: boolean) => async (dispatch: Dispatch) => {
    dispatch(setIsLoadingStaffAC(true));
    const staff = (await getStaff(with_admin).then((res) => res.data)) as Staff;
    dispatch(setStaffAC(staff));
    dispatch(setIsLoadingStaffAC(false));
  };

export const createClosingDateTC =
  (payload: unknown, getBlockedEffectFunction?: () => void) =>
  async (dispatch: Dispatch) => {
    const result = await createClosingDate(payload)
      .then((res) => res)
      .catch((e) => {});
    if (result?.status === 200) {
      dispatch(addSuccessMessageAC("The Block Time Off was successfully set"));
      if (getBlockedEffectFunction) {
        getBlockedEffectFunction();
      }
    } else if (result?.response.status >= 400) {
      dispatch(addErrorAC(result.response.data.error));
      dispatch(setErrorMessageClosingDateAC(result.response.data.error));
      dispatch(setSuccessClosingCreateAC(false));
    }
  };
export const getClosingDatesTC =
  (
    date_from: string,
    date_to: string,
    region: string | string[],
    calendarTimezone: string,
    state?: string[],
    user?: number,
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoadingClosingDatesAC(true));
    const closingDates = await getClosingDateList(
      date_from,
      date_to,
      region.toString(),
      calendarTimezone,
      state,
      user,
    );

    const closingDatesForCal = {
      ...closingDates,
      data: Object.keys(closingDates.data).map((el) => {
        return {
          ...closingDates.data[el],
          timezone: closingDates.data[el].state,
          title: closingDates.data[el].type,
          start: closingDates.data[el].is_full_day
            ? parseDateFromBackendForBlockedTimeCalendar(
                closingDates.data[el].start_at,
                "00:00:00",
              )
            : moment(
                closingDates.data[el].start_at,
                "YYYY-MM-DD HH:mm:ss",
              ).toDate(),
          end: closingDates.data[el].is_full_day
            ? parseDateFromBackendForBlockedTimeCalendar(
                moment(closingDates.data[el].start_at, "YYYY-MM-DD")
                  .clone()
                  .add(1, "d")
                  .format("YYYY-MM-DD"),
                "00:00:00",
              )
            : moment(
                closingDates.data[el].end_at,
                "YYYY-MM-DD HH:mm:ss",
              ).toDate(),
        };
      }),
    };
    dispatch(setClosingDateAC(closingDatesForCal));
    dispatch(setIsLoadingClosingDatesAC(false));
  };
export const updateClosingDateTC =
  (
    closing_id: number,
    payload: unknown,
    getBlockedEffectFunction?: () => void,
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoadingClosingDatesAC(true));
    const result = await updateClosingDate(closing_id, payload);
    if (result?.status >= 200 && result?.status < 300) {
      dispatch(setSuccessClosingCreateAC(true));
    } else if (result?.response.status >= 400) {
      dispatch(setIsLoadingClosingDatesAC(false));
      dispatch(addErrorAC(result.response.data.error));
      dispatch(setSuccessClosingCreateAC(false));
    }
  };
export const deleteClosingDateTC =
  (closing_id: number) => async (dispatch: Dispatch) => {
    try {
      const result = await deleteClosingDate(closing_id);
      if (result?.status === 204) {
        dispatch(addSuccessMessageAC("Successfully deleted"));
        dispatch(setSuccessfullyDeletedAC(true));
      }
    } catch (e) {
      const result = e as {response: AxiosResponse};
      if (result?.response.status >= 400) {
        const message = result.response.data.error;

        dispatch(
          addErrorAC(
            message === "" || message === null || message === undefined
              ? "Unknown error"
              : message,
          ),
        );
        dispatch(setSuccessClosingCreateAC(false));
      }
    }
  };

export const sendDealCommentTC =
  (
    dealId: number,
    comment: string,
    appointment_id: number,
    isNotifyComment: boolean,
    isOpenedByClosing?: boolean,
  ) =>
  async (dispatch: Dispatch) => {
    try {
      await createComments(dealId, comment, isNotifyComment);
      dispatch(addSuccessMessageAC("Commented success"));

      if (isOpenedByClosing) {
        dispatch(getDealByIdCalendarClosingsTC(dealId) as unknown as AnyAction);
      } else {
        dispatch(getFullDealInfoTC(appointment_id) as unknown as AnyAction);
      }
    } catch (e) {
      console.log(e);
    }
  };
export const getLewClerksDealsNumberTC =
  ({
    startDay,
    endDay,
    regions,
    role,
    dealType,
  }: {
    startDay: unknown;
    endDay: unknown;
    regions: string | string[];
    role: string;
    dealType: string;
  }) =>
  async (dispatch: Dispatch) => {
    try {
      const lawClerksDealsNumberPromise = await getLewClerksDealsNumber({
        startDay,
        endDay,
        regions,
        role,
        dealType,
      });
      const lawClerksDealsNumberArray = Object.keys(
        lawClerksDealsNumberPromise.data,
      ).map((key) => lawClerksDealsNumberPromise.data[key]);
      dispatch(setLawClerksDealsNumberAC(lawClerksDealsNumberArray));
    } catch (e) {
      const error = e as {response: {data: {error: string}}};
      dispatch(
        addErrorAC(
          error.response.data.error ?? "Cannot get Law Clerks deals counts",
        ),
      );
    }
  };
