import React from "react";
import ProfileV2GeneralPagePhoto from "./components/ProfileGeneralPagePhoto";
import ProfileGeneralPagePersonalInfo from "./components/ProfileGeneralPagePersonalInfo";
import {Theme} from "@mui/material/styles";
import Box from "@mui/material/Box";
import useThemedStyles from "utils/hooks/useThemedStyles";

const ProfileGeneralTab = () => {
  const styles = useThemedStyles(stylesFactory);

  return (
    <>
      <Box sx={styles.profileGeneralPageBox}>
        <Box sx={styles.profileGeneralBox}>
          <ProfileV2GeneralPagePhoto />
          <ProfileGeneralPagePersonalInfo />
        </Box>
      </Box>
    </>
  );
};

const stylesFactory = (theme: Theme) => ({
  profileGeneralPageBox: {
    width: "100%",
    backgroundColor: "white",
    height: "100%",
    marginTop: "15px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 15px",
    },
  },
  profileGeneralBox: {
    display: "flex",
    paddingTop: "40px",
    margin: "0 15px",
    justifyContent: "center",
    [theme.breakpoints.down("lg")]: {
      margin: "0 35px",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
      marginLeft: "0",
      marginRight: "0",
    },
  },
});

export default ProfileGeneralTab;
