export default {
    BLACK: "#1D2027",
    PRIMARY: "#F45753",
    PRIMARY_HOVER: "#D03D3A",
    PRIMARY_25: "#FFFCFC",
    PRIMARY_50: "#FFF9F9",
    GREY: "#E6E6E7",
    LIGHT_GREY: "#E5E7EE",
    GREY_2: "#E9EBF1",
    DARK_GREY: "#616571",
    WARM_GREY: "#DAD1D1",
    CORAL_20: "#FBF3F3",
    CORAL_10: "#FBF9F9",
    CORAL_50: "#F7E7E7",
    NEUTRAL_50: "#FAFAFB",
    NEUTRAL_100: "#F2F3F5",
    NEUTRAL_200: "#E6E7EB",
    NEUTRAL_300: "#D9DBE1",
    NEUTRAL_400: "#A9ADBA",
    NEUTRAL_500: "#868C9E",
    NEUTRAL_600: "#515560",
    NEUTRAL_700: "#30333A",
    PRIMARY_100: "#FFF1F0",
    PRIMARY_200: "#FFE2E0",
    PRIMARY_500: "#F47A79",
    PRIMARY_600: "#F06A6A",
    WHITE: "#FFF",
    INFORMATION: "#2C9AFF",
    INFORMATION_50: "#E0EBF6",
    INFORMATION_700: "#3A70E2",
    INFORMATION_600: "#3B82F6",
    WARNING: "#F2A711",
    WARNING_50: "#F4EDDE",
    WARNING_600: "#F79009",
    ERROR: "#ED5A30",
    ERROR_50: "#F4E5E1",
    SUCCESS: "#4DA772",
    SUCCESS_600: "#12B76A",
    SUCCESS_700: "#039855",
    SUCCESS_50: "#E4EDE7",
    DANGER_600: "#DB382B",
    FOCUS: "#5A4AB8",
    AUXILIARY_600: "#B02372",
}