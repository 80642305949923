import React, {
  ChangeEvent,
  ElementType,
  Fragment,
  ReactEventHandler,
  useEffect,
  useMemo,
  useState,
} from "react";
import {useDispatch} from "react-redux";
import {
  Box,
  MenuItem,
  SelectChangeEvent,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import {Theme} from "@mui/material/styles";
import moment, {Moment} from "moment";
import ChangeDateIcon from "assets/img/ChangeDateIcon.svg";
import {
  CalendarEvent,
  setCalendarTimezoneAC,
  setCalendarTypeAC,
  setIsGettingEvents,
  setIsOpenedEventCreatePopupAC,
  setSelectedStaffNameAC,
  setSelectedStaffUserAC,
} from "redux/reducers/calendarReducer";
import {useAppDispatch, useAppSelector} from "redux/hooks";
import {StaffUser} from "redux/reducers/configReducer/staffCardReducer";
import CalendarSwitchView from "./CalendarSwitchView";
import constants from "styles/constants";
import DeededButton from "v2/components/DeededButton";
import DeededDatePicker from "v2/components/DeededDatePicker";
import DeededRadioButton from "v2/components/DeededRadioButton";
import DeededAutocompleteSelect from "v2/components/DeededAutocompleteSelect";
import DeededSelect from "v2/components/DeededSelect";
import DeededProvinceButton from "v2/components/DeededProvinceButton";
import BlockTimeIcon from "../svg/BlockTimeIcon";
import useStaff from "utils/hooks/useStaff";
import useTypedSelector from "utils/hooks/useTypedSelector";
import useUser from "utils/hooks/useUser";
import useWindowWidth from "utils/hooks/useWindowWidth";
import {storeCalendarSetting} from "../utils/storeCalendarSetting";
import {useClosingsSum} from "../utils/hooks/useClosingsSum";
import {rolesForShowChangeCalendarTypeRadioButtons} from "../constants/rolesForShowChangeCalendarTypeRadioButtons";
import {
  bigTabletCalendar,
  horizontalPhoneCalendar,
  largeTabletCalendar,
  miniDesktopCalendar,
  smallDesktopCalendar,
  smallPhoneCalendar,
  tabletCalendar,
  verticalTabletCalendar,
} from "../constants/breakpoints";

interface CalendarHeaderProps {
  allProvincesList: {label: string; name: string}[];
  blockedAndEvents: CalendarEvent[];
  allProvincesLabelsList: string[];
  isGettingEvents: boolean;
  view: string;
  date: Date;
  regions: string | string[];
  setDate: (value: () => Date) => void;
  dealTypeState: string | string[];
  staffState: string[] | string;
  setDealTypeState: (value: string) => void;
  setStaffState: (value: string) => void;
  setRegions: (value: string | string[]) => void;
  setView: (value: string) => void;
  setShowCloseLapse: (value: boolean) => void;
  signingsAmount: number;
  allRoles: string[];
  allDealTypes: string[];
  dateStart: Date;
  handleChange: (someToChange: unknown) => void;
}

const CalendarHeader = ({
  allProvincesList,
  isGettingEvents,
  setView,
  view,
  regions,
  date,
  setRegions,
  setDate,
  signingsAmount,
  dealTypeState,
  setDealTypeState,
  allDealTypes,
  dateStart,
  handleChange,
}: CalendarHeaderProps) => {
  const calendarType = useAppSelector(
    (state) => state.CalendarReducer.calendarType,
  );
  const closingsSum = useClosingsSum(view);
  const theme = useTheme();
  const staff = useStaff();
  const windowWidth = useWindowWidth();
  const user = useUser().user;
  const shouldShowChangeCalendarTypeRadioButtons =
    rolesForShowChangeCalendarTypeRadioButtons.includes(user.role as string);
  const selectedStaffName = useTypedSelector(
    (state) => state.CalendarReducer.selectedStaffName,
  );
  const selectedStaffUserRedux = useAppSelector(
    (state) => state.CalendarReducer.selectedStaffUser,
  );

  const calendarTimezone = useAppSelector(
    (state) => state.CalendarReducer.calendarTimezone,
  );
  const dateMoment = useMemo(() => moment(date), [date, view]);
  const dispatch = useDispatch();
  const mobileSignersList = staff?.["Mobile Signer"];
  const mobileSignersNamesList = mobileSignersList?.map(
    (signer) => signer.full_name,
  ) as string[] | null;
  const lawClerksAndLawyersList = staff?.["Lawyer"].concat(
    staff?.["Law Clerk"],
  );
  const lawClerksAndLawyersNamesList = lawClerksAndLawyersList?.map(
    (employee) => employee.full_name,
  ) as string[] | null;
  const handleChangeRegions = (e: ChangeEvent<HTMLInputElement>) => {
    const {value, checked} = e.target as HTMLInputElement;
    let newRegions;
    if (checked) {
      newRegions = [...(regions as string[]), value];
    } else {
      newRegions = (regions as string[]).filter((el) => el !== value);
    }

    setRegions(newRegions);
  };
  const handleChangeDealType = (e: unknown) => {
    setDealTypeState(e as string);
  };
  useEffect(() => {
    if (selectedStaffName.length === 0) {
      dispatch(setSelectedStaffUserAC(null));
    }
  }, [dispatch, selectedStaffName]);
  const handleChangeStaff = (option: string) => {
    let selectedStaffUser;
    if (calendarType === "Signing Appointment") {
      selectedStaffUser = mobileSignersList?.filter(
        (signer) => signer.full_name === option,
      )?.[0];
    } else {
      selectedStaffUser = lawClerksAndLawyersList?.filter(
        (employee) => employee.full_name === option,
      )?.[0];
    }
    dispatch(setSelectedStaffUserAC(selectedStaffUser as StaffUser));
    storeCalendarSetting("selectedStaffUser", selectedStaffUser);
  };
  const handleChangeCalendarType = (e: {target: {value: string}}) => {
    dispatch(setSelectedStaffUserAC(null));
    dispatch(setSelectedStaffNameAC(""));
    dispatch(setCalendarTypeAC(e.target.value));
    storeCalendarSetting("calendarType", e.target.value);
  };
  const [activeDatePicker, setActiveDatePicker] = useState(false);
  const handleClearUserSelect = () => {
    dispatch(setSelectedStaffUserAC(null));
    dispatch(setSelectedStaffNameAC(""));
  };
  return (
    <HeaderBlock>
      <FirstLine>
        <Box sx={{display: "flex", alignItems: "center"}}>
          <SelectedDate
            theme={theme}
            className={`${activeDatePicker ? "active" : ""}`}
            component={'span'}
          >
            <DeededDatePicker
              dateStart={dateStart}
              onChangeEvent={handleChange}
              name="dateStart"
              setDate={setDate}
              setActiveDatePicker={setActiveDatePicker}
              hideInput={true}
            />
            <CalendarHeaderSelectedDateTypography
              view={view as "day" | "week" | "month"}
              dateMoment={dateMoment}
            />
            <Signings theme={theme}>
              Total number of{" "}
              {calendarType === "Closings" ? "Closings" : "Signings"}:{" "}
              <NumberSignings>
                {calendarType === "Closings" ? closingsSum : signingsAmount}
              </NumberSignings>{" "}
            </Signings>
          </SelectedDate>
          <Box
            sx={{
              display: "flex",
              marginTop: "-25px",
            }}
          >
            <NextPrevButton
              data-testid="calendar-previous-button"
              theme={theme}
              onClick={() => {
                dispatch(setIsGettingEvents(true));
                return setDate(() =>
                  dateMoment
                    .subtract(1, view as moment.unitOfTime.DurationConstructor)
                    .toDate(),
                );
              }}
            >
              <img src={ChangeDateIcon} alt="prev" />
            </NextPrevButton>
            <NextPrevButton
              data-testid="calendar-next-button"
              theme={theme}
              onClick={() => {
                dispatch(setIsGettingEvents(true));
                return setDate(() =>
                  dateMoment
                    .add(1, view as moment.unitOfTime.DurationConstructor)
                    .toDate(),
                );
              }}
            >
              <img
                style={{transform: "rotate(180deg)"}}
                src={ChangeDateIcon}
                alt="next"
              />
            </NextPrevButton>
          </Box>
        </Box>
        {(windowWidth > bigTabletCalendar || windowWidth <= tabletCalendar) &&
          windowWidth > verticalTabletCalendar && (
            <CalendarHeaderControlsWithChangeView
              setDate={setDate}
              view={view}
              setView={setView}
            />
          )}
      </FirstLine>
      {((windowWidth <= bigTabletCalendar && windowWidth > tabletCalendar) ||
        windowWidth <= verticalTabletCalendar) && (
        <Box sx={{marginTop: "40px", marginBottom: "15px"}}>
          <CalendarHeaderControlsWithChangeView
            setDate={setDate}
            view={view}
            setView={setView}
          />
          {shouldShowChangeCalendarTypeRadioButtons && (
            <CalendarHeaderSwitchCalendarTypeRadioButtons
              handleChangeCalendarType={handleChangeCalendarType as () => void}
            />
          )}
        </Box>
      )}
      {shouldShowChangeCalendarTypeRadioButtons &&
        (windowWidth > bigTabletCalendar || windowWidth <= tabletCalendar) &&
        windowWidth > verticalTabletCalendar && (
          <CalendarHeaderSwitchCalendarTypeRadioButtons
            handleChangeCalendarType={handleChangeCalendarType as () => void}
          />
        )}

      <SecondLine
        sx={{
          marginTop: !shouldShowChangeCalendarTypeRadioButtons
            ? "0px"
            : "-30px",
        }}
      >
        <RegionsBlock>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <SelectRegion>Select Region(s):</SelectRegion>
            <Box sx={{display: "flex"}}>
              {allProvincesList.map(
                ({label, name}: {label: string; name: string}) => {
                  let fill: string;
                  switch (label) {
                    case "ON":
                      fill = constants.provinceColors.ON2;
                      break;
                    case "AB":
                      fill = constants.provinceColors.AB;
                      break;
                    case "BC":
                      fill = constants.provinceColors.BC;
                      break;
                    default:
                      fill = constants.provinceColors.Other;
                  }
                  return (
                    <Fragment key={label}>
                      <DeededProvinceButton
                        data-selenium-id={`province-${label}`}
                        sx={{
                          marginRight: "12px",
                          [theme.breakpoints.down(miniDesktopCalendar)]: {
                            fontSize: "10px",
                            width: "35px",
                            height: "20px",
                            fontWeight: 600,
                          },
                        }}
                        key={label}
                        title={label === "OTHER" ? "RP" : label}
                        bgColor={fill}
                        checked={regions.includes(label)}
                        onClick={() => {
                          if (!isGettingEvents) {
                            dispatch(setIsGettingEvents(true));
                            handleChangeRegions({
                              target: {
                                value: label,
                                checked: !regions.includes(label),
                              },
                            } as unknown as ChangeEvent<HTMLInputElement>);
                          }
                        }}
                      />
                    </Fragment>
                  );
                },
              )}
            </Box>
          </Box>
        </RegionsBlock>
        <ButtonsWrapper
          sx={{
            display: "flex",
            flexWrap: "wrap",
            [theme.breakpoints.down(horizontalPhoneCalendar)]: {
              flexDirection: "column",
              width: "100%",
              paddingRight: "20px",
            },
          }}
        >
          <SelectWrapper
            sx={{
              [theme?.breakpoints.down(miniDesktopCalendar)]: {
                width: "150px",
              },
              [theme?.breakpoints.down(bigTabletCalendar)]: {
                width: "120px",
              },
              [theme.breakpoints.down(horizontalPhoneCalendar)]: {
                width: "100%",
              },
            }}
          >
            <SelectLabel>Deal type</SelectLabel>
            <SelectStaffOrDealType
              data-testid="calendar-mobile-deal-type-drop-down"
              width="100%"
              onChange={(e?: SelectChangeEvent<unknown> | undefined) => {
                handleChangeDealType(e!.target.value);
              }}
              value={dealTypeState}
              sx={{
                width: "100%",
              }}
            >
              <CustomMenuItem value="ALL">All</CustomMenuItem>
              {allDealTypes.map((dealType) => (
                <CustomMenuItem key={dealType} value={dealType}>{dealType}</CustomMenuItem>
              ))}
            </SelectStaffOrDealType>
          </SelectWrapper>
          <SelectWrapper
            sx={{
              marginLeft: "25px",
              width: "216px",
              [theme.breakpoints.down(miniDesktopCalendar)]: {
                width: "150px",
              },
              [theme.breakpoints.down(horizontalPhoneCalendar)]: {
                width: "100%",
                marginLeft: "0px",
                marginTop: "10px",
              },
            }}
          >
            <SelectLabel>
              {calendarType === "Signing Appointment"
                ? "Mobile Signer"
                : "Lawyer / Law Clerk"}
            </SelectLabel>

            {calendarType === "Signing Appointment" ? (
              <SelectPerson
                data-testid="calendar-mobile-signer-drop-down"
                showCloseIcon={
                  selectedStaffUserRedux?.full_name === selectedStaffName
                }
                onCloseIconClick={handleClearUserSelect}
                straightawaySearch
                showListDefault
                onSelect={
                  handleChangeStaff as ((option: string) => void) &
                    ReactEventHandler<HTMLDivElement>
                }
                disabled={!staff}
                onChangeValue={(e) => {
                  dispatch(setSelectedStaffNameAC(e as string));
                }}
                options={mobileSignersNamesList}
                value={selectedStaffName}
                placeholder="All"
              />
            ) : (
              <SelectPerson
                showCloseIcon={
                  selectedStaffUserRedux?.full_name === selectedStaffName
                }
                onCloseIconClick={handleClearUserSelect}
                straightawaySearch
                showListDefault
                onSelect={
                  handleChangeStaff as ((option: string) => void) &
                    ReactEventHandler<HTMLDivElement>
                }
                disabled={!staff}
                onChangeValue={(e) => {
                  dispatch(setSelectedStaffNameAC(e as string));
                }}
                options={lawClerksAndLawyersNamesList}
                value={selectedStaffName}
                placeholder="All"
              />
            )}
          </SelectWrapper>

          <SelectWrapper
            sx={{
              marginLeft: "25px",
              marginRight: "25px",
              width: "222px",
              [theme.breakpoints.down(miniDesktopCalendar)]: {
                width: "150px",
              },
              [theme.breakpoints.down(bigTabletCalendar)]: {
                width: "80px",
              },
              [theme.breakpoints.down(horizontalPhoneCalendar)]: {
                width: "100%",
                marginLeft: "0px",
                marginRight: "0px",
                marginTop: "10px",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <SelectLabel>Timezone</SelectLabel>
              {windowWidth > bigTabletCalendar && (
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontStyle: "normal",
                    fontWeight: "normal",
                    fontSize: "10px",
                    color: constants.colors.deededGray,
                    marginTop: "1px",
                  }}
                >
                  {moment().tz(calendarTimezone)?.format("HH:mm")} (UTC{" "}
                  {moment().tz(calendarTimezone)?.utcOffset() / 60})
                </Typography>
              )}
            </Box>
            {windowWidth > bigTabletCalendar && (
              <CalendarHeaderProvinceSelectWithLargeItems />
            )}
            {windowWidth <= bigTabletCalendar && (
              <CalendarHeaderProvinceSelectWithShortItems />
            )}
          </SelectWrapper>
          {calendarType === "Signing Appointment" && (
            <BlockTimeButton
              theme={theme}
              data-testid="create-signing-appointment-or-block-time-button"
              onClick={() => {
                dispatch(setIsOpenedEventCreatePopupAC(true));
              }}
            >
              <BlockTimeIcon />
              Create <PlusSpan>+</PlusSpan>
            </BlockTimeButton>
          )}
        </ButtonsWrapper>
      </SecondLine>
    </HeaderBlock>
  );
};
const CalendarHeaderSelectedDateTypography = ({
  view,
  dateMoment,
}: {
  view: "day" | "week" | "month";
  dateMoment: Moment;
}) => {
  const theme = useTheme();
  const calendarType = useAppSelector(
    (state) => state.CalendarReducer.calendarType,
  );
  return (
    <>
      {view === "day" ? (
        <>
          {dateMoment?.format("MMMM Do")},{" "}
          <Year theme={theme}>{dateMoment?.format(" YYYY")}</Year>
        </>
      ) : view === "week" ? (
        <>
          {calendarType === "Signing Appointment"
            ? `${dateMoment.startOf("week").format("Do MMM")} - ${dateMoment
                .endOf("week")
                .format("Do MMM")}`
            : `${dateMoment
                .startOf("week")
                .clone()
                .add(1, "day")
                .format("Do MMM")} - ${dateMoment
                .endOf("week")
                .clone()
                .subtract(1, "day")
                .format("Do MMM")}`}
          , <Year theme={theme}>{dateMoment?.format("YYYY")}</Year>
        </>
      ) : (
        <>
          {dateMoment?.format("MMMM")},{" "}
          <Year theme={theme}>{dateMoment?.format("YYYY")}</Year>
        </>
      )}
    </>
  );
};
const CalendarHeaderSwitchCalendarTypeRadioButtons: React.FC<{
  handleChangeCalendarType: () => void;
}> = ({handleChangeCalendarType}) => {
  const calendarType = useAppSelector(
    (state) => state.CalendarReducer.calendarType,
  );
  return (
    <>
      <SelectCalendarTypeBox>
        <DeededRadioButton
          onChange={handleChangeCalendarType as () => void}
          checked={calendarType === "Signing Appointment"}
          label="Signing Appts"
          value="Signing Appointment"
        />
        <DeededRadioButton
          onChange={handleChangeCalendarType as () => void}
          checked={calendarType === "Closings"}
          label="Closings"
          value="Closings"
        />
      </SelectCalendarTypeBox>
    </>
  );
};
const CalendarHeaderControlsWithChangeView: React.FC<{
  setDate: (value: () => Date) => void;
  view: string;
  setView: (value: string) => void;
}> = ({setDate, setView, view}) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  return (
    <>
      <Box sx={{display: "flex"}}>
        <TodayText>{moment(new Date()).format("MMMM DD")}</TodayText>
        <TodayButton
          theme={theme}
          onClick={() => {
            dispatch(setIsGettingEvents(true));
            setDate(() => new Date());
          }}
        >
          Today
        </TodayButton>
        <CalendarSwitchView setView={setView} view={view} />
      </Box>
    </>
  );
};
const CalendarHeaderProvinceSelectWithShortItems = () => {
  const dispatch = useAppDispatch();
  const calendarTimezone = useAppSelector(
    (state) => state.CalendarReducer.calendarTimezone,
  );
  return (
    <>
      <SelectTimezone
        width={"100%"}
        data-selenium-id={"calendar-timezone-select"}
        onChange={(e) => {
          dispatch(setCalendarTimezoneAC(e!.target.value as string));
        }}
        value={calendarTimezone}
        sx={{width: "100%"}}
      >
        <MenuItem value={"America/Edmonton"}>AB</MenuItem>
        <MenuItem value={"America/Vancouver"}>BC</MenuItem>
        <MenuItem value={"America/Winnipeg"}>MB</MenuItem>
        <MenuItem value={"America/Moncton"}>NB</MenuItem>
        <MenuItem value={"America/Goose_Bay"}>NL</MenuItem>
        <MenuItem value={"America/Yellowknife"}>NT</MenuItem>
        <MenuItem value={"America/Halifax"}>NS</MenuItem>
        <MenuItem value={"America/Resolute"}>NU</MenuItem>
        <MenuItem value={"America/Toronto"}>ON</MenuItem>
        <MenuItem value={"America/Halifax"}>PE</MenuItem>
        <MenuItem value={"America/Montreal"}>QC</MenuItem>
        <MenuItem value={"America/Regina"}>SK</MenuItem>
        <MenuItem value={"America/Whitehorse"}>YT</MenuItem>
      </SelectTimezone>
    </>
  );
};
const CalendarHeaderProvinceSelectWithLargeItems = () => {
  const dispatch = useAppDispatch();
  const calendarTimezone = useAppSelector(
    (state) => state.CalendarReducer.calendarTimezone,
  );
  return (
    <>
      <SelectTimezone
        width={"100%"}
        data-selenium-id={"calendar-timezone-select"}
        onChange={(e) => {
          dispatch(setCalendarTimezoneAC(e!.target.value as string));
        }}
        value={calendarTimezone}
        sx={{width: "100%"}}
      >
        <MenuItem value={"America/Edmonton"}>Alberta (AB)</MenuItem>
        <MenuItem value={"America/Vancouver"}>British Columbia (BC)</MenuItem>
        <MenuItem value={"America/Winnipeg"}>Manitoba (MB)</MenuItem>
        <MenuItem value={"America/Moncton"}>New Brunswick (NB)</MenuItem>
        <MenuItem value={"America/Goose_Bay"}>
          Newfoundland & Labrador (NL)
        </MenuItem>
        <MenuItem value={"America/Yellowknife"}>
          Northwest Territories (NT)
        </MenuItem>
        <MenuItem value={"America/Halifax"}>Nova Scotia (NS)</MenuItem>
        <MenuItem value={"America/Resolute"}>Nunavut (NU)</MenuItem>
        <MenuItem value={"America/Toronto"}>Ontario (ON)</MenuItem>
        <MenuItem value={"America/Halifax"}>Prince Edward Island (PE)</MenuItem>
        <MenuItem value={"America/Montreal"}>Quebec (QC)</MenuItem>
        <MenuItem value={"America/Regina"}>Saskatchewan (SK)</MenuItem>
        <MenuItem value={"America/Whitehorse"}>Yukon(YT)</MenuItem>
      </SelectTimezone>
    </>
  );
};
const PlusSpan = styled("span")({
  fontSize: "20px",
  marginLeft: "4px",
  marginTop: "2px",
});
const SelectPerson = styled(DeededAutocompleteSelect)({
  "& .svgBox": {
    top: "13px",
  },
  " .isOpenList": {
    top: "13px",
  },
  "& .MuiPaper-root": {
    zIndex: "5",
  },
  "& .MuiBox-root": {
    "& .MuiFormControl-root": {
      "& .MuiOutlinedInput-root": {
        height: "36px",
        "& .MuiOutlinedInput-input": {
          "&::placeholder": {
            fontFamily: "'Montserrat'",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "15px",
            textColor: constants.colors.grayTag,
            opacity: 1,
          },
        },
      },
    },
  },
});
const SelectTimezone = styled(DeededSelect)({
  height: "36px !important",
  overflow: "hidden",
  "&.MuiOutlinedInput-root": {
    background: constants.colors.white,
  },
  "& .MuiSelect-select": {
    textOverflow: "unset",
    overflow: "hidden",
    whiteSpace: "nowrap",
    //I use this border to hide overflowing text because overflow:hidden doesn't work there
    borderRight: "40px solid transparent",
  },
});
const SelectStaffOrDealType = styled(DeededSelect)({
  "&.MuiOutlinedInput-root": {
    background: constants.colors.white,
  },
  height: "36px !important",
});
const SelectCalendarTypeBox = styled(Box)<{theme?: Theme}>(({theme}) => ({
  display: "flex",
  justifyContent: "flex-end",
  marginRight: "38px",
  marginTop: "-5px",
  " .MuiFormControlLabel-root": {
    [theme.breakpoints.down(largeTabletCalendar)]: {
      " .MuiTypography-root": {
        fontSize: "10px",
      },
      " .MuiRadio-root": {
        svg: {
          width: "16px",
          height: "16px",
        },
      },
    },
  },
  [theme.breakpoints.down(bigTabletCalendar)]: {
    justifyContent: "flex-start",
  },
  [theme.breakpoints.down(tabletCalendar)]: {
    justifyContent: "flex-end",
  },
  [theme.breakpoints.down(verticalTabletCalendar)]: {
    justifyContent: "flex-start",
  },
}));
const HeaderBlock = styled(Box)({
  padding: "10px 2px 0 22px",
});

const SelectedDate = styled(Typography)<{theme: Theme, component?: ElementType}>(({theme}) => ({
  fontStyle: "normal",
  fontWeight: "bold",
  fontSize: "3.2rem",
  lineHeight: "3.9rem",
  margin: "0 0 24px",
  paddingTop: "25px",
  textTransform: "capitalize",
  color: constants.colors.intakeTitle,
  fontFamily: "Montserrat",
  position: "relative",
  "& .MuiBox-root .MuiFormControl-root": {
    position: "absolute",
    top: "0px",
    left: "0px",
  },
  transition: constants.transitions,
  "&:hover, &.active": {
    color: constants.colors.red,
    transition: constants.transitions,
  },
  [theme.breakpoints.down(smallDesktopCalendar)]: {
    fontSize: "2.8rem",
  },
  [theme.breakpoints.down(miniDesktopCalendar)]: {
    fontSize: "2rem",
  },
  [theme.breakpoints.down(smallPhoneCalendar)]: {
    fontSize: "1.5rem",
  },
}));

const Year = styled("span")<{theme: Theme}>(({theme}) => ({
  fontSize: "32px",
  fontWeight: "600",
  fontFamily: "Montserrat",
  color: "#828282",
  [theme.breakpoints.down(smallDesktopCalendar)]: {
    fontSize: "2.8rem",
  },
  [theme.breakpoints.down(miniDesktopCalendar)]: {
    fontSize: "2rem",
  },
  [theme.breakpoints.down(smallPhoneCalendar)]: {
    fontSize: "1.5rem",
  },
}));

const RegionsBlock = styled(Box)({
  height: "65px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  marginTop: "15px",
});

const FirstLine = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  // flexWrap:"wrap",
  alignItems: "center",
  marginTop: "20px",
  height: "50px",
});
const SecondLine = styled(Box)(({theme}) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
}));

const Signings = styled(Typography)<{theme: Theme}>(({theme}) => ({
  fontSize: "14px",
  fontWeight: "400",
  marginTop: "5px",
  color: "#828282",
  textTransform: "none",
  [theme.breakpoints.down(miniDesktopCalendar)]: {
    fontSize: "10px",
  },
}));
const NumberSignings = styled("span")({
  color: "#262626",
  fontFamily: "Montserrat",
});

const ButtonsWrapper = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-end",
  marginTop: "-20px",
});

const SelectWrapper = styled(Box)<{theme?: Theme}>(({theme}) => ({
  width: "176px",
  "& .MuiOutlinedInput-root": {
    color: "black",
    fontSize: "1.4rem",
    height: "44px",
    display: "flex",
    alignItems: "center",
    background: "white",
  },
  "& .MuiAutocomplete-endAdornment": {
    display: "none",
  },
}));

const SelectLabel = styled(Typography)({
  color: "#4F4F4F",
  fontSize: "12px",
  fontWeight: "500",
  marginLeft: "5px",
  marginBottom: "4px",
});

const CustomMenuItem = styled(MenuItem)({
  fontSize: "14px",
});

const SelectRegion = styled(Typography)<{theme?: Theme}>(({theme}) => ({
  fontSize: "14px",
  fontWeight: "600",
  fontFamily: "Montserrat",
  color: "#333333",
  marginRight: "16px",
  [theme.breakpoints.down(miniDesktopCalendar)]: {
    fontSize: "10px",
  },
}));

const BlockTimeButton = styled(DeededButton)<{theme: Theme}>(({theme}) => ({
  fontWeight: "500",
  textTransform: "none",
  width: "176px",
  float: "initial",
  height: "36px",
  display: "flex",
  alignItems: "center",
  "& svg": {
    marginRight: "6px",
    stroke: constants.colors.white,
    transition: "all 300ms",
  },
  "&:hover": {
    color: constants.colors.red,
    border: `1px solid ${constants.colors.red}`,
    backgroundColor: constants.colors.white,
    "& svg": {
      stroke: constants.colors.red,
      transition: "all 300ms",
    },
    transition: "all 300ms",
  },
  transition: "all 300ms",
  [theme.breakpoints.down(miniDesktopCalendar)]: {
    width: "120px",
  },
  [theme.breakpoints.down(horizontalPhoneCalendar)]: {
    width: "100%",
    marginTop: "30px",
  },
}));

const TodayText = styled(Box)<{theme?: Theme}>(({theme}) => ({
  marginTop: "-25px",
  display: "flex",
  alignItems: "center",
  fontSize: "16px",
  color: "#BDBDBD",
  fontWeight: "500",
  fontFamily: "Montserrat",
  [theme.breakpoints.down(largeTabletCalendar)]: {
    fontSize: "10px",
    marginTop: "-27px",
  },
}));

const TodayButton = styled("button")<{theme: Theme}>(({theme}) => ({
  height: "32px",
  display: "flex",
  alignItems: "center",
  fontSize: "16px",
  color: "#4F4F4F",
  fontWeight: "500",
  fontFamily: "Montserrat",
  background: "white",
  border: "none",
  padding: "6px 24px",
  cursor: "pointer",
  marginRight: "22px",
  marginLeft: "8px",
  marginTop: "-25px",
  borderRadius: "5px",
  [theme.breakpoints.down(smallDesktopCalendar)]: {
    padding: "6px 14px",
    fontSize: "13px",
    fontWeight: "500",
    height: "28px",
  },
  [theme.breakpoints.down(largeTabletCalendar)]: {
    padding: "3px 10px",
    fontSize: "10px",
    fontWeight: "500",
    height: "19px",
    marginTop: "-24px",
  },
  [theme.breakpoints.down(smallPhoneCalendar)]: {
    fontSize: "8px",
    padding: "3px 8px",
  },
}));

const NextPrevButton = styled(Box)<{theme: Theme}>(({theme}) => ({
  width: "32px",
  height: "32px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: "white",
  cursor: "pointer",
  marginLeft: "16px",
  ":hover": {
    boxShadow:
      "0px 5px 5px rgba(0, 0, 0, 0.07), 0px 12px 66px rgba(0, 0, 0, 0.18)",
  },
  [theme.breakpoints.down(smallDesktopCalendar)]: {
    width: "24px",
    height: "24px",
    " img": {
      width: "10px",
      height: "10px",
    },
  },
  [theme.breakpoints.down(smallPhoneCalendar)]: {
    width: "16px",
    height: "16px",
    " img": {
      width: "6px",
      height: "6px",
    },
  },
}));

export default CalendarHeader;
