import React, { FC } from "react";
import { styled } from "@mui/material/styles";
import Switch, { SwitchProps } from "@mui/material/Switch";
import Colors from "../Styling/Colors";

export type SwitchButtonProps = {
    /**
     * Id for tests
     */
    testId?: string;
} & SwitchProps;

const SwitchButton: FC<SwitchButtonProps> = ({ testId, ...props }) => {
    return <CustomSwitch data-testid={testId} {...props} />;
};

const CustomSwitch = styled(Switch)<SwitchButtonProps>(() => ({
    width: 52,
    height: 32,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 4,
        transitionDuration: '300ms',
        color: Colors.NEUTRAL_300,
        '&.Mui-checked': {
            transform: 'translateX(20px)',
            color: Colors.WHITE,
            '& + .MuiSwitch-track': {
                backgroundColor: Colors.PRIMARY_600,
                borderColor: Colors.PRIMARY_600,
                opacity: 1,
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color: Colors.WHITE,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                background: Colors.NEUTRAL_200,
                borderColor: Colors.NEUTRAL_200,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: Colors.WHITE,
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color: Colors.NEUTRAL_300,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            background: Colors.NEUTRAL_200,
            borderColor: Colors.NEUTRAL_200,
            opacity: 1,
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        boxSizing: 'border-box',
        width: 24,
        height: 24,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26,
        border: `1px solid ${Colors.NEUTRAL_300}`,
        backgroundColor: Colors.WHITE,
        opacity: 1,
    },
}));

export default SwitchButton;
