import moment from "moment";

export const getDateStartOrEndForRequestClosingOrAppointmentsCounts = (
  view: "day" | "week" | "month",
  date: Date,
  dateSide: "start" | "end",
) => {
  if (dateSide === "start") {
    return view === "day"
      ? moment(date).format("YYYY-MM-DD")
      : moment(date).startOf(view).format("YYYY-MM-DD");
  } else {
    return view === "day"
      ? moment(date).format("YYYY-MM-DD")
      : moment(date).endOf(view).format("YYYY-MM-DD");
  }
};
