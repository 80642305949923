import { FC, FunctionComponent } from "react";
import { useHistory } from "react-router-dom";
import { alpha, styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiButton from "@mui/material/Button";
import { IconProps } from "../../CommonDashboard/Icons";
import Colors from "../../CommonDashboard/Styling/Colors";
import { NavLinkProps } from "../../CommonDashboard/NavLink";
import { ButtonLargeText, Caption } from "../../CommonDashboard/Typography";

interface HomeActionProps {
    label: string;
    to: string;
    Icon: FunctionComponent<IconProps>;
    count?: number;
}

const HomeAction: FC<HomeActionProps> = ({ label, to, Icon, count }) => {
    const history = useHistory();

    return (
        <CustomButton label={{ text: label }} onClick={() => history.push(to)}>
            <Box width="100%" display="inline-flex" gap="0.8rem" alignItems="center" justifyContent="flex-start">
                <IconWrapper>
                    <Icon size="small" />
                </IconWrapper>
                <ButtonLargeText noWrap color="inheirt">
                    {label}
                </ButtonLargeText>
                {count && (
                    <CountWrapper>
                        <Caption color={Colors.WHITE}>{count}</Caption>
                    </CountWrapper>
                )}
            </Box>
        </CustomButton>
    );
};

const CountWrapper = styled(Box)(() => ({
    padding: "0.5rem 0.8rem",
    display: "flex",
    borderRadius: "2.4rem",
    background: alpha(Colors.PRIMARY_50, 0.2),
}));

const IconWrapper = styled(Box)(() => ({
    display: "flex",
    minWidth: "3.2rem",
    height: "3.2rem",
    borderRadius: "1.6rem",
    background: alpha(Colors.PRIMARY_50, 0.2),
    alignItems: "center",
    justifyContent: "center",
    whiteSpace: "nowrap",
}));

const CustomButton = styled(MuiButton, {
    shouldForwardProp: (prop) => prop !== "label",
})<NavLinkProps>(({ theme }) => ({
    padding: "1.6rem",
    borderRadius: "2.4rem",
    textTransform: "none",
    minWidth: 0,
    background: Colors.PRIMARY_600,
    color: Colors.WHITE,
    border: "none",
    "& svg path": {
        fill: Colors.WHITE,
    },
    "&:hover": {
        background: Colors.PRIMARY_600,
    },
    [theme.breakpoints.down("mobile")]: {
        width: "100%"
    },
}));

export default HomeAction;
