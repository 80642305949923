import { Box, styled } from "@mui/material";
import { Body, BodyBold, Small } from "components/Common/Typography";
import { formatMessageDate } from "../utils";



interface CommentProps {
    index: number,
    fullName: string,
    role: string,
    date: Date,
    message: string,
}

export const Comment = ({ index, fullName, role, date, message }: CommentProps) => {

    return <Box sx={{
        display: 'flex',
        flexFlow: 'column',
        gap: '1rem',
    }} data-testid={`deal-details-comment-record-${index}`}>
        <Box sx={{
            display: 'flex',
            flexFlow: 'row',
            justifyContent: 'space-between',
        }}>
            <LeftHeader>
                <BodyBold data-testid="commenter-name">{fullName}</BodyBold>
                <Small data-testid="commenter-role">{role}</Small>
            </LeftHeader>
            <Small data-testid="comment-date">{formatMessageDate(date)}</Small>
        </Box>
        <Body data-testid="comment-message" sx={{
            whiteSpace: 'pre-wrap',
        }} dangerouslySetInnerHTML={{ __html: message }} />

    </Box>;
}


const LeftHeader = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexFlow: 'row',
    gap: '1rem',
    [theme.breakpoints.down('sm')]: {
        flexFlow: 'column',
        gap: '0.2rem'
    },
    alignItems: 'baseline'
}));
