import {FC} from "react";
import {Box, Stack} from "@mui/material";
import {styled} from "@mui/material/styles";
import {PointTransaction} from "types/pointTransaction";
import colors from "../../Common/componentStyling/Colors";
import {SmallBold, Body} from "../../Common/Typography";
import CircularLoader from "../../Common/Loader/CircularLoader";
import {format} from "date-fns";
import {utcToZonedTime} from "date-fns-tz";
import useUser from "../../../utils/hooks/useUser";
import {timezones} from "../DealDetails/utils";

interface PointsListProps {
  data: PointTransaction[];
  loading: boolean;
}

const PointsList: FC<PointsListProps> = ({data, loading}) => {
  if (loading) {
    return (
      <ListContainer>
        <CircularLoader containerHeight="20vh" />
      </ListContainer>
    );
  }

  return (
    <ListContainer>
      <Row
        sx={{
          borderBottom: `1px solid ${colors.GRAY_300}`,
          padding: "0.8rem 2.4rem",
        }}
      >
        <Box width="12rem">
          <SmallBold>Date</SmallBold>
        </Box>
        <Box flex={1}>
          <SmallBold>Activity</SmallBold>
        </Box>
        <Box width="4.4rem" textAlign="right">
          <SmallBold>Points</SmallBold>
        </Box>
      </Row>
      <ScrollContainer>
        {data.map(({id, created_at, label, type, points}) => (
          <Row key={id}>
            <Box width="12rem">
              <Body noWrap>{format(new Date(created_at), "MMM dd, yyyy")}</Body>
            </Box>
            <Box flex={1}>
              <Body noWrap>{label}</Body>
            </Box>
            <Box width="4.4rem" textAlign="right">
              {type === "use" ? (
                <Body sx={{color: colors.RED_400}}>- {points}</Body>
              ) : (
                <Body sx={{color: colors.GREEN_500}}>+ {points}</Body>
              )}
            </Box>
          </Row>
        ))}
      </ScrollContainer>
    </ListContainer>
  );
};

const ListContainer = styled(Stack)({
  height: "34vh",
  maxHeight: "59rem",
});

const ScrollContainer = styled(Stack)({
  overflowY: "auto",
});

const Row = styled(Stack)({
  gap: "3.2rem",
  padding: "1.6rem 2.4rem",
  flexDirection: "row",
});

export default PointsList;
