import {ActionType} from "../actionTypes";
import {
  getDealByID,
  getFoldersForDealType,
  getListDealLenders,
  getListProfessionalsByField,
  passMortgage,
  updateDealMemberData,
  getDealNumbers,
  saveDealNumbers,
  getDealStages,
  updateDealStages,
  updateDealClientData,
  UpdateDealClientDataPayload,
  getDeals,
  dropDeal,
  deleteDealArchive,
  setAppointment,
  updateAppointment,
  cancelAppointment,
  getBlockedTimeSlots,
  getConveyanceHealth,
} from "../../api";
import {Action, Dispatch} from "redux";
import {
  Address,
  DealInfoByID,
  LenderType,
  SelectedLenderType,
} from "./types/dealsReducerTypes";
import {Deal, DealFullModel} from "../../types/deal";
import {DealNumber} from "types/reduxActions/deals";
import {addErrorAC, addSuccessMessageAC} from "./ErrorReducer";
import {SetAppointmentPayload} from "../../api/types_TEMP";
import {AxiosResponse} from "axios";
import {RootStore} from "../../types/flux";

/**
 * TODO: types are incomplete. Look for the types at runtime to fill them out
 */

const dealsState = {
  currentDealDealType: "",
  showArchived: false,
  deal_info_by_id: {},
  deal_type: null,
  folders_for_deal_type: [],
  deals: [],
  query: "",
  showClosed: false,
  showNew: false,
  dealsLoading: false,
  newDeal: null,
  stage: null,
  dealByID: null,
  dealDoc: null as unknown | null,
  assignTeamValues: {},
  submitLoader: false,
  passStage: false,
  startGetDealInfo: false,
  successUpdate: null as boolean | null,
  lendersList: [] as ListDealLenders[],
  isSaveToDBLender: false,
  selectedLender: null as SelectedLenderType | null,
  dealClickEvent: null,
  dealIndex: null,
  province: null,
  preferred_province: "",
  preferred_time: "",
  dealNumbers: [],
  dealStageInfo: [],
  dealsData: {
    dealsList: [],
    current_page: 0,
    last_page: 0,
    per_page: 0,
    total: 0,
  },
  dealsListLoading: false,
  newDealSelectedProvince: "",
  cancellationReason: null,

  dealsList: null as DealFullModel | null,
  dealsListAbortController: null as AbortController | null,
  isDealsListLoading: false,
  dealslistError: null as {message: string} | null,
  dealsListInputQuery: null as DealsListInputQuery | null,
  conveyanceUp: null
};

export interface DealsListInputQuery {
  searchQuery: string | null;
  newDeals: boolean;
  showClosed: boolean;
  archive: boolean;
  sortBy: string | null;
  sortOrder: "asc" | "desc" | null;
  status: boolean;
  userId: number | null;
  page: number;
}

export type DealStageInfo = Record<
  string,
  {id: number; name: string; description: string, number: number}[]
>;

type ListDealLenders = Omit<LenderType, "address"> & {
  address: Address;
  value: string;
  label: string;
};

export type DealReducerState = typeof dealsState & {
  currentDealDealType: string;
  showArchived: boolean;
  deal_info_by_id: Deal | {};
  deal_type: null;
  folders_for_deal_type: never[];
  deals: never[];
  query: string;
  showClosed: boolean;
  showNew: boolean;
  dealsLoading: boolean;
  newDeal: null;
  stage: null;
  dealByID: null;
  startGetDealInfo: boolean;
  successUpdate: boolean | null;
  lendersList: ListDealLenders[];
  isSaveToDBLender: boolean;
  selectedLender: SelectedLenderType | null;
  dealClickEvent: unknown;
  dealIndex: unknown;
  province: null | string;
  preferred_province: string | null;
  preferred_time: string | null;
  dealNumbers: DealNumber[];
  dealStageInfo: DealStageInfo[];
  dealsData: DealFullModel;
  dealsListLoading: boolean;
  newDealSelectedProvince: string;
  cancellationReason: string | null;

  dealsList: DealFullModel | null;
  dealsListAbortController: AbortController | null;
  isDealsListLoading: boolean;
  dealslistError: {message: string} | null;
  dealsListInputQuery: DealsListInputQuery | null;
  conveyanceUp: boolean | null;
};
type DealReducerActionType = {
  type:
    | "FETCH_DEAL_LIST_ABORT_CONTROLLER"
    | "FETCH_DEAL_LIST_LOADING"
    | "FETCH_DEAL_LIST_SUCCESS"
    | "FETCH_DEAL_LIST_ERROR"
    | "SET_DEAL_TYPE"
    | "SET_DOC_VIEW"
    | "SHOW_ARCHIVED"
    | "SET_FOLDERS_FOR_DEAL_TYPE"
    | "SET_DEAL_INFO_BY_ID"
    | "CLEAR_DEAL_INFO_BY_ID"
    | "SET_ASSIGN_TEAM_VALUE"
    | "SUBMIT_LOADER"
    | "SET_PASS_STAGE"
    | "SET_START_GET_DEAL_INFO_TRUE"
    | "SET_START_GET_DEAL_INFO_FALSE"
    | "SET_SUCCESS_UPDATE"
    | "SET_LENDERS_LIST"
    | "SET_IS_SAVE_TO_DB_LENDER"
    | "SET_SELECTED_LENDER"
    | "SET_DEAL_CLICK_EVENT"
    | "SET_DEAL_INDEX"
    | "SET_PROVINCE"
    | "SET_PREFERRED_PROVINCE"
    | "SET_PREFERRED_TIME"
    | "SET_DEAL_NUMBERS"
    | "SET_DEALSTAGE_DATA"
    | "SET_DEALS"
    | "SET_NEW_DEAL_SELECTED_PROVINCE"
    | "SET_DEAL_DATA"
    | "SET_DEAL_LIST_LOADING"
    | "SET_CANCELLATION_REASON"
    | "SET_CURRENT_DEAL_DEAL_TYPE"
    | "SET_CONVEYANCE_STATUS"
    | keyof typeof ActionType;
  payload: unknown;
};

export const DealsReducer = (
  state = dealsState,
  action: DealReducerActionType,
): DealReducerState => {
  switch (action.type) {
    case "SET_CURRENT_DEAL_DEAL_TYPE": {
      return {
        ...state,
        currentDealDealType: action.payload as string,
      };
    }
    case "FETCH_DEAL_LIST_LOADING": {
      let isDealsListLoading = action.payload as boolean;

      return {...state, isDealsListLoading};
    }
    case "FETCH_DEAL_LIST_ABORT_CONTROLLER": {
      return {
        ...state,
        dealsListAbortController: action.payload as AbortController,
      };
    }
    case "FETCH_DEAL_LIST_SUCCESS": {
      let {query, ...dealsList} = action.payload as DealFullModel & {
        query: DealsListInputQuery;
      };

      return {...state, dealsList, dealsListInputQuery: query};
    }
    case "FETCH_DEAL_LIST_ERROR": {
      let dealslistError = action.payload as {message: string};

      return {...state, dealslistError};
    }

    case "SET_CANCELLATION_REASON": {
      return {
        ...state,
        cancellationReason: action.payload as null,
      };
    }
    case "SET_NEW_DEAL_SELECTED_PROVINCE": {
      return {
        ...state,
        newDealSelectedProvince: action.payload as string,
      };
    }
    case "SET_DEALSTAGE_DATA": {
      return {
        ...state,
        dealStageInfo: action.payload as never[] & DealStageInfo[],
      };
    }
    case "SET_PREFERRED_TIME": {
      return {
        ...state,
        preferred_time: action.payload as string,
      };
    }
    case "SET_PREFERRED_PROVINCE": {
      return {
        ...state,
        preferred_province: action.payload as string,
      };
    }
    case "SET_SELECTED_LENDER": {
      return {
        ...state,
        selectedLender: action.payload as SelectedLenderType | null,
      };
    }
    case "SET_IS_SAVE_TO_DB_LENDER": {
      return {
        ...state,
        isSaveToDBLender: action.payload as boolean,
      };
    }
    case "SET_LENDERS_LIST": {
      return {
        ...state,
        lendersList: action.payload as ListDealLenders[],
      };
    }
    case "SET_SUCCESS_UPDATE": {
      return {
        ...state,
        successUpdate: action.payload as boolean | null,
      };
    }
    case "SET_START_GET_DEAL_INFO_FALSE": {
      return {
        ...state,
        startGetDealInfo: false,
      };
    }
    case "SET_START_GET_DEAL_INFO_TRUE": {
      return {
        ...state,
        startGetDealInfo: true,
      };
    }
    case "SET_PASS_STAGE": {
      return {
        ...state,
        passStage: !state.passStage,
      };
    }
    case "SUBMIT_LOADER": {
      return {
        ...state,
        submitLoader: action.payload as boolean,
      };
    }

    case "CLEAR_DEAL_INFO_BY_ID": {
      return {
        ...state,
        deal_info_by_id: {},
      };
    }
    case "SET_DEAL_INFO_BY_ID": {
      return {
        ...state,
        deal_info_by_id: action.payload as DealInfoByID,
      };
    }
    case "SET_DOC_VIEW": {
      return {
        ...state,
        dealDoc: action.payload as unknown | null,
      };
    }
    case "SET_FOLDERS_FOR_DEAL_TYPE": {
      return {
        ...state,
        folders_for_deal_type: (action.payload as {data: unknown})
          .data as never[],
      };
    }
    case "SET_DEAL_TYPE": {
      return {
        ...state,
        deal_type: action.payload as null,
      };
    }
    case "SET_DEAL_NUMBERS": {
      return {
        ...state,
        dealNumbers: action.payload as [],
      };
    }
    case "SHOW_ARCHIVED":
      return {
        ...state,
        showArchived: !state.showArchived,
        showClosed: false,
        showNew: false,
      };

    case "SET_DEAL_CLICK_EVENT": {
      return {
        ...state,
        dealClickEvent: action.payload as unknown as null,
      };
    }

    case "SET_DEAL_INDEX": {
      return {
        ...state,
        dealIndex: action.payload as unknown as null,
      };
    }

    case "SET_DEAL_DATA": {
      return {
        ...state,
        dealsData: action.payload as unknown as {
          dealsList: never[];
          current_page: number;
          last_page: number;
          per_page: number;
          total: number;
        },
      };
    }

    case "SET_DEAL_LIST_LOADING": {
      return {
        ...state,
        dealsListLoading: action.payload as boolean,
      };
    }

    case "SET_PROVINCE": {
      return {
        ...state,
        province: action.payload as unknown as null,
      };
    }
    case ActionType.SHOW_CLOSED:
      return {
        ...state,
        showClosed: !state.showClosed,
        showNew: false,
        showArchived: false,
      };
    case ActionType.SHOW_NEW:
      return {
        ...state,
        showNew: !state.showNew,
        showClosed: false,
        showArchived: false,
      };
    case ActionType.DEALS_LOADER_ON:
      return {
        ...state,
        dealsLoading: true,
      };
    case ActionType.DEAL_SEARCH:
      return {
        ...state,
        query: action.payload as string,
      };
    case ActionType.DEAL_CREATE:
      return {
        ...state,
        deals: [...state.deals, action.payload as never],
        newDeal: action.payload as null,
        dealsLoading: false,
      };
    case ActionType.DEAL_CLEAR:
      return {
        ...state,
        newDeal: null,
      };
    case ActionType.DEAL_FETCH_BY_ID:
      return {
        ...state,
        dealByID: action.payload as null,
        dealsLoading: false,
      };
    case ActionType.SET_STAGE:
      return {
        ...state,
        stage: action.payload as null,
        dealsLoading: false,
      };
    case "SET_CONVEYANCE_STATUS":
      return {
        ...state,
        conveyanceUp: action.payload as null
      }
    default:
      return state;
  }
};
export const setCurrentDealDealTypeAC = (payload: string) => ({
  type: "SET_CURRENT_DEAL_DEAL_TYPE",
  payload,
});
export const setNewDealSelectedProvinceAC = (payload: string) => ({
  type: "SET_NEW_DEAL_SELECTED_PROVINCE",
  payload,
});

export const setPreferredTimeAC = (payload: string | null) => ({
  type: "SET_PREFERRED_TIME",
  payload,
});
export const setPreferredProvinceAC = (payload: string | null) => ({
  type: "SET_PREFERRED_PROVINCE",
  payload,
});
export const setDealTypeAC = (payload: unknown): DealReducerActionType => ({
  type: "SET_DEAL_TYPE",
  payload,
});
export const setDocViewAC = (payload: unknown): DealReducerActionType => ({
  type: "SET_DOC_VIEW",
  payload,
});
export const setFoldersForDealTypeAC = (
  payload: unknown,
): DealReducerActionType => ({
  type: "SET_FOLDERS_FOR_DEAL_TYPE",
  payload,
});
export const setDealInfoByIdAC = (payload: Deal) => ({
  type: "SET_DEAL_INFO_BY_ID",
  payload,
});
export const clearDealInfoByIdAC = () => ({
  type: "CLEAR_DEAL_INFO_BY_ID",
});
export const setAssignTeamValuesAC = (payload: Object) => ({
  type: "SET_ASSIGN_TEAM_VALUE",
  payload,
});
export const submitLoaderAC = (payload: boolean) => {
  return {
    type: "SUBMIT_LOADER",
    payload,
  };
};
export const passStageAC = () => ({
  type: "SET_PASS_STAGE",
});
export const setStartGetDealInfoTrue = () => ({
  type: "SET_START_GET_DEAL_INFO_TRUE",
});
export const setStartGetDealInfoFalse = () => ({
  type: "SET_START_GET_DEAL_INFO_FALSE",
});
export const setSuccessUpdateAC = (payload: boolean | null) => ({
  type: "SET_SUCCESS_UPDATE",
  payload,
});
export const setLendersListAC = (payload: ListDealLenders[]) => ({
  type: "SET_LENDERS_LIST",
  payload,
});
export const setIsSaveToDBLenderAC = (payload: boolean) => ({
  type: "SET_IS_SAVE_TO_DB_LENDER",
  payload,
});
export const setSelectedLenderAC = (payload: SelectedLenderType | null) => ({
  type: "SET_SELECTED_LENDER",
  payload,
});

export const setDealClickEvent = (payload: unknown) => ({
  type: "SET_DEAL_CLICK_EVENT",
  payload,
});

export const setDealIndex = (payload: unknown) => ({
  type: "SET_DEAL_INDEX",
  payload,
});

export const getDealInfoByIdTC =
  (id: number | string) => async (dispatch: Dispatch) => {
    const dealInfo = await getDealByID(+id).then((res) => res.data);
    dispatch(setCurrentDealDealTypeAC(dealInfo.deal_type));
    dispatch(setDealInfoByIdAC(dealInfo));
  };

export const setProvince = (payload: string) => ({
  type: "SET_PROVINCE",
  payload,
});
export const setDealNumbersInfo = (payload: DealNumber) => ({
  type: "SET_DEAL_NUMBERS",
  payload,
});
export const setSaveDealNumbers = () => ({
  type: "SAVE_DEAL_NUMBERS",
});

export const setDeals = (deals: unknown) => ({
  type: "SET_DEALS",
  payload: deals,
});

export const setDealNumbersTC = () => async (dispatch: Dispatch) => {
  const dealDataValue = await getDealNumbers().then((res) => {
    return res.data;
  });
  dispatch(setDealNumbersInfo(dealDataValue));
};

export const setCancellationReasonAC = (payload: string | null) => ({
  type: "SET_CANCELLATION_REASON",
  payload,
});

export const fetchDealListLoadingAC = (payload: boolean) => ({
  type: "FETCH_DEAL_LIST_LOADING",
  payload,
});

export const fetchDealListAbortControllerAC = (
  payload: AbortController | null,
) => ({
  type: "FETCH_DEAL_LIST_ABORT_CONTROLLER",
  payload,
});

export const fetchDealListSuccessAC = (
  payload: DealFullModel & {
    query: DealsListInputQuery;
  },
) => ({
  type: "FETCH_DEAL_LIST_SUCCESS",
  payload,
});

export const fetchDealListErrorAC = (payload: {message: string}) => ({
  type: "FETCH_DEAL_LIST_ERROR",
  payload,
});

export const fetchDeasListTC =
  (query: DealsListInputQuery) => async (dispatch: Dispatch, getState: () => RootStore) => {
    try {
      let currentAbortController = getState().DealsReducer.dealsListAbortController;
      if (currentAbortController) {
        currentAbortController.abort();
      }

      const abortController = new AbortController();
      dispatch(fetchDealListAbortControllerAC(abortController));
      dispatch(fetchDealListLoadingAC(true));

      const paramsForStupidApiFn: Record<string, boolean> = {};

      const {
        searchQuery,
        newDeals,
        showClosed,
        archive,
        sortBy,
        sortOrder,
        status,
        userId,
        page,
      } = query;

      /**
       * We can't just pass all query parameters that are falls
       * For some reason API doesn't like it, so we use a dictionary to collect truthy records
       */
      if (newDeals) {
        paramsForStupidApiFn["newDeals"] = newDeals;
      }
      if (showClosed) {
        paramsForStupidApiFn["showClosed"] = showClosed;
      }
      if (archive) {
        paramsForStupidApiFn["archive"] = archive;
      }

      /**
       * Returns pagination data and other stuff
       */
      const getDealsResponse = await getDeals(
        status,
        page,
        searchQuery ?? "",
        sortBy ?? "",
        sortOrder ?? "desc",
        userId,
        paramsForStupidApiFn as
          | {newDeals: boolean}
          | {showClosed: boolean}
          | {archive: boolean},
        abortController,
      );

      const dealsList = getDealsResponse?.data?.data;
      const current_page = getDealsResponse?.data?.current_page;
      const last_page = getDealsResponse?.data?.last_page;
      const per_page = getDealsResponse?.data?.per_page;
      const total = getDealsResponse?.data?.total;

      dispatch(
        fetchDealListSuccessAC({
          dealsList: dealsList as Deal[],
          current_page: current_page as number,
          last_page: last_page as number,
          per_page: per_page as number,
          total: total as number,
          query,
        }),
      );
    } catch (e) {
      const error = e as {
        message?: string;
        response?: {data?: {error?: string}};
      };

      dispatch(
        fetchDealListErrorAC({
          message:
            error?.message ??
            error?.response?.data?.error ??
            "Couldn't fetch deal list.",
        }),
      );
    } finally {
      dispatch(fetchDealListLoadingAC(false));
      dispatch(fetchDealListAbortControllerAC(null));
    }
  };

export const saveDealNumbersTC =
  (data: unknown) => async (dispatch: Dispatch) => {
    await saveDealNumbers(data);
    dispatch(setDealNumbersInfo(data as DealNumber));
  };
export const setFoldersForDealTypeTC =
  (deal_type: string, deal_province?: string) => async (dispatch: Dispatch) => {
    const foldersForDealType = await getFoldersForDealType(deal_type, deal_province);
    dispatch(setFoldersForDealTypeAC(foldersForDealType));
  };
export const getAssignTeamValuesTC =
  (field: string, searchValue: string) => async (dispatch: Dispatch) => {
    const assignTeamValues = await getListProfessionalsByField(
      field,
      searchValue,
    ).then((res) => {
      return res.data;
    });
    dispatch(setAssignTeamValuesAC(assignTeamValues));
  };
export const passMortgageTC =
  (dealID: number, deal_stage_Id: number) => async (dispatch: Dispatch) => {
    await passMortgage(dealID, deal_stage_Id);
    dispatch(passStageAC());
    dispatch(setStartGetDealInfoTrue());
  };
export const updateTeamTC =
  (dealID: number, payload: unknown, memberID: number) =>
  async (dispatch: Dispatch) => {
    try {
      const res = await updateDealMemberData(
        dealID,
        payload as unknown,
        memberID,
      );
      if (res.status === 200) {
        const dealInfo = await getDealByID(+dealID).then((res) => res.data);
        dispatch(setSuccessUpdateAC(true));
        dispatch(setDealInfoByIdAC(dealInfo));
      }
    } catch (e) {
      const error = e as {response: {data: {error: string}}};
      dispatch(addErrorAC(error.response.data.error ?? "Cannot update user"));
    }
  };
export const updateDealClientDataTC =
  (id: number, payload: unknown) => async (dispatch: Dispatch) => {
    try {
      await updateDealClientData(id, payload as UpdateDealClientDataPayload);
      const dealInfo = await getDealByID(+id).then((res) => res.data);
      dispatch(setDealInfoByIdAC(dealInfo));
    } catch (e) {
      const error = e as {response: {data: {error: string}}};
      dispatch(addErrorAC(error.response.data.error ?? "Wrong update"));
    }
  };
export const getListDealLendersTC =
  (searchValue: string) => async (dispatch: Dispatch) => {
    const lendersList = await getListDealLenders(searchValue);

    const allLenderList: ListDealLenders[] = [];
    for (const el of lendersList.data) {
      for (const ad of el.address) {
        allLenderList.push({
          ...el,
          address: ad,
          value: `${el.lender_name} ${ad.address}`,
          label: `${el.lender_name} ${ad.address}`,
        });
      }
    }

    lendersList.data.forEach((el: LenderType) => {});

    dispatch(setLendersListAC(allLenderList));
  };

export interface DealStage {
  Purchase: [];
  Mortgage: [];
  Lender: [];
  Sale: [];
}

export const setDealInfoData = (payload: DealStage) => ({
  type: "SET_DEALSTAGE_DATA",
  payload,
});

export const getDealStageListTC = () => async (dispatch: Dispatch) => {
  const configRoles = await getDealStages().then((res) => {
    return res.data;
  });
  dispatch(setDealInfoData(configRoles));
};

export const saveDealStageTC =
  (data: unknown) => async (dispatch: Dispatch) => {
    await updateDealStages(data);
    dispatch(setSaveDealStage());
  };

export const setSaveDealStage = () => ({
  type: "SAVE_DEAL_NUMBERS",
});

export const setDealInfoActive = (payload: string) => ({
  type: "SET_DEALSTAGE_ACTIVE",
  payload,
});

export const getDealStageActiveIndex = () => async (dispatch: Dispatch) => {
  const configRoles = await getDealStages().then((res) => {
    return res.data;
  });
  dispatch(setDealInfoActive(configRoles.Purchase[0]));
};
export const cancelDealTC =
  (
    dealId: number,
    comment: string,
    isTest: boolean,
    setIsArchive: (isArchive: boolean) => void,
    setIsOpenCloseDealPopup: (isOpenCloseDealPopup: boolean) => void,
  ) =>
  async (dispatch: Dispatch) => {
    try {
      setIsOpenCloseDealPopup(false);
      await dropDeal(dealId, comment, isTest);
      dispatch(addSuccessMessageAC("Successfully cancelled"));
      dispatch(setCancellationReasonAC(comment));
      setIsArchive(true);
    } catch (e) {
      const error = e as {response: {data: {error: string}}};
      dispatch(addErrorAC(error.response.data.error ?? "Cannot close deal"));
    }
  };
export const archiveDealTC =
  (dealId: number, isTest: boolean, getCurrentList: () => void) =>
  async (dispatch: Dispatch) => {
    try {
      await dropDeal(dealId, undefined, isTest);
      dispatch(addSuccessMessageAC("Successfully deleted"));
      getCurrentList();
    } catch (e) {
      const error = e as {response: {data: {error: string}}};
      dispatch(addErrorAC(error.response.data.error ?? "Cannot delete deal"));
    }
  };
export const deleteDealTC =
  (dealId: number, getCurrentList: () => void) =>
  async (dispatch: Dispatch) => {
    try {
      await deleteDealArchive(dealId);
      dispatch(addSuccessMessageAC("Successfully deleted"));
      getCurrentList();
    } catch (e) {
      const error = e as {response: {data: {error: string}}};
      dispatch(addErrorAC(error.response.data.error ?? "Cannot delete deal"));
    }
  };
export const addSigningAppointmentTC =
  (
    dealId: number,
    payload: SetAppointmentPayload,
    setFieldValue: (fieldName: string, value: boolean) => void,
    getEventsEffectFunction?: () => void,
  ) =>
  async (dispatch: Dispatch<Action>) => {
    try {
      dispatch(submitLoaderAC(true));
      setFieldValue("isLoading", true);
      await setAppointment(dealId, payload);
      dispatch(
        addSuccessMessageAC("The Signing Appointment was successfully saved"),
      );
      dispatch(getDealInfoByIdTC(dealId) as unknown as Action);
      if (getEventsEffectFunction) {
        getEventsEffectFunction();
      }
      dispatch(submitLoaderAC(false));
      setFieldValue("isLoading", false);
    } catch (e) {
      const error = e as {response: AxiosResponse<{error?: string}>};
      dispatch(
        addErrorAC(
          error?.response?.data?.error ?? "Couldn't add signing appointment",
        ),
      );
      dispatch(submitLoaderAC(false));
      setFieldValue("isLoading", false);
    }
  };

export const updateAppointmentTC =
  (
    dealId: number,
    appointmentId: number,
    payload: SetAppointmentPayload,
    setFieldValue: (fieldName: string, value: boolean) => void,
  ) =>
  async (dispatch: Dispatch<Action>) => {
    try {
      dispatch(submitLoaderAC(true));
      setFieldValue("isLoading", true);
      await updateAppointment(dealId, appointmentId, payload);
      dispatch(addSuccessMessageAC("Updated signing appointment"));
      dispatch(getDealInfoByIdTC(dealId) as unknown as Action);
      dispatch(submitLoaderAC(false));
      setFieldValue("isLoading", false);
    } catch (e) {
      const error = e as {response: AxiosResponse<{error?: string}>};
      dispatch(
        addErrorAC(
          error?.response?.data?.error ?? "Couldn't add signing appointment",
        ),
      );
      dispatch(submitLoaderAC(false));
      setFieldValue("isLoading", false);
    }
  };

export const cancelSigningAppointmentTC =
  (dealId: number, appointmentId: number) =>
  async (dispatch: Dispatch<Action>) => {
    try {
      dispatch(submitLoaderAC(true));
      await cancelAppointment(dealId, appointmentId);
      dispatch(addSuccessMessageAC("Cancelled appointment"));
      dispatch(getDealInfoByIdTC(dealId) as unknown as Action);
      dispatch(submitLoaderAC(false));
    } catch (e) {
      const error = e as {response: AxiosResponse<{error?: string}>};
      dispatch(
        addErrorAC(
          error?.response?.data?.error ?? "Couldn't add signing appointment",
        ),
      );
      dispatch(submitLoaderAC(false));
    }
  };
export const getBlockedTimeSlotsTC =
  (
    dealId: number,
    payload: unknown,
    setIsLoadingBusyTimes: (LoadingBusyTimes: boolean) => void,
    setBusyTimes: (busyTimes: string[]) => void,
  ) =>
  async (dispatch: Dispatch) => {
    try {
      setIsLoadingBusyTimes(true);
      const blockedTimeSlotsPromise = await getBlockedTimeSlots(
        dealId,
        payload,
      );
      setIsLoadingBusyTimes(false);
      setBusyTimes(blockedTimeSlotsPromise.data.busy_slots);
    } catch (e) {
      setIsLoadingBusyTimes(false);
    }
  };

// Conveyance
export const setConveyanceStatus = (up: boolean) => ({
  type: "SET_CONVEYANCE_STATUS",
  payload: up,
});

export const getConveyanceStatus = () =>
  async (dispatch: Dispatch) => {
    await getConveyanceHealth()
    .then(function (response: any) {
      if (response.data.status === "ok") {
        dispatch(setConveyanceStatus(true));
      } else {
        dispatch(setConveyanceStatus(false))
      }
    })
    .catch(function (e) {
      dispatch(setConveyanceStatus(false))
    })
  };
  