import { FC, ReactNode } from "react";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/material/styles";
import MuiButton from "@mui/material/Button";
import { Stack } from "@mui/material";
import Colors from "../../CommonDashboard/Styling/Colors";
import { Caption } from "../../CommonDashboard/Typography";

export type BottomNavLinkProps = {
    label: { text: string; inputId?: string };
    to: string;
    testId?: string;
    icon?: ReactNode;
    callback?: () => void;
};

const BottomNavLink: FC<BottomNavLinkProps> = ({ label, icon, testId, to, callback }) => {
    const history = useHistory();

    const handleNav = () => {
        history.push(to);
        callback && callback();
    };

    return (
        <CustomButton onClick={handleNav} data-testid={testId} active={history.location.pathname === to}>
            <Stack width="100%" direction="column" gap="0.4rem" alignItems="center">
                {icon}
                <Caption color="inheirt">{label.text}</Caption>
            </Stack>
        </CustomButton>
    );
};

const CustomButton = styled(MuiButton, {
    shouldForwardProp: (prop) => prop !== "active",
})<{ active: boolean }>(({ active }) => ({
    padding: "0.8rem",
    borderRadius: "2.4rem",
    textTransform: "none",
    width: "11rem",
    minWidth: 0,
    background: active ? Colors.PRIMARY_500 : "transparent",
    color: Colors.WHITE,
    border: "none",
    "& svg path": {
        fill: Colors.WHITE,
    },
}));

export default BottomNavLink;
