import {getCompaniesList, getRoles} from "api";
import {Dispatch} from "redux";

export interface Professional {
  id: number;
  name_prefix: null;
  first_name: string;
  last_name: string;
  email: string;
  email_verified_at: string | null;
  phone: string;
  role: string;
  communication_method: string;
  show_guides: number;
  brokerage_or_team: string | null;
  company: string | null;
  slack_id: string | null;
  deleted_at: Date;
  slug: string | null;
  middle_name: string;
  timezone: string;
  has_personal_page: number;
  full_name: string;
  total_deals: number;
  date_created: Date;
  last_deal: Date;
  closing_date: null;
  userpic: string | null;
}

export interface ProfReducerState {
  showFilterRolesPopup: boolean;
  showFilterCompanyPopup: boolean;
  companyList: string[];
  selectedCompanies: string[];
  rolesList: string[];
  selectedRoles: string[];
  selectedProfForDeals: Professional | null;
}
type ProfActionType =
  | "SET_SHOW_FILTER_ROLES_POPUP"
  | "SET_SHOW_FILTER_COMPANY_POPUP"
  | "SET_COMPANY_LIST"
  | "SET_SELECTED_COMPANIES"
  | "DELETE_SELECTED_COMPANIES"
  | "SET_ROLE_LIST"
  | "SET_SELECTED_ROLES"
  | "DELETE_SELECTED_ROLES"
  | "SELECTED_PROF_ID"
  | "SET_SELECTED_PROF_FOR_DEALS";

export type profAction = {
  type: ProfActionType;
  payload?: unknown;
};

const StaffCardState: ProfReducerState = {
  selectedRoles: [],
  rolesList: [],
  selectedCompanies: [],
  companyList: [],
  showFilterRolesPopup: false,
  showFilterCompanyPopup: false,
  selectedProfForDeals: null,
};

const ProfReducer = (state = StaffCardState, action: profAction) => {
  switch (action.type) {
    case "SET_SELECTED_PROF_FOR_DEALS": {
      return {
        ...state,
        selectedProfForDeals: action.payload,
      };
    }
    case "DELETE_SELECTED_ROLES": {
      return {
        ...state,
        selectedRoles: state.selectedRoles.filter(
          (el) => el !== action.payload,
        ),
      };
    }
    case "SET_SELECTED_ROLES": {
      const newSelectedRoles = [action.payload];
      return {
        ...state,
        selectedRoles: state.selectedRoles.concat(
          newSelectedRoles as ConcatArray<string>,
        ),
      };
    }
    case "SET_ROLE_LIST": {
      return {
        ...state,
        rolesList: action.payload,
      };
    }
    case "DELETE_SELECTED_COMPANIES": {
      return {
        ...state,
        selectedCompanies: state.selectedCompanies.filter(
          (el) => el !== action.payload,
        ),
      };
    }
    case "SET_SELECTED_COMPANIES": {
      const newSelectedCompany = [action.payload];
      return {
        ...state,
        selectedCompanies: state.selectedCompanies.concat(
          newSelectedCompany as ConcatArray<string>,
        ),
      };
    }
    case "SET_COMPANY_LIST": {
      return {
        ...state,
        companyList: action.payload,
      };
    }
    case "SET_SHOW_FILTER_COMPANY_POPUP": {
      return {
        ...state,
        showFilterCompanyPopup: action.payload,
      };
    }
    case "SET_SHOW_FILTER_ROLES_POPUP": {
      return {
        ...state,
        showFilterRolesPopup: action.payload,
      };
    }
    default:
      return state;
  }
};

export const setSelectedProfForDealsAC = (payload: Professional | null) => ({
  type: "SET_SELECTED_PROF_FOR_DEALS",
  payload,
});
export const setShowFilterRolesAC = (payload: boolean) => ({
  type: "SET_SHOW_FILTER_ROLES_POPUP",
  payload,
});
export const setShowFilterCompanyAC = (payload: boolean) => ({
  type: "SET_SHOW_FILTER_COMPANY_POPUP",
  payload,
});
export const setCompanyListAC = (payload: string[]) => ({
  type: "SET_COMPANY_LIST",
  payload,
});
export const setSelectedCompanyAC = (payload: string) => ({
  type: "SET_SELECTED_COMPANIES",
  payload,
});
export const deleteSelectedCompaniesAC = (payload: string) => ({
  type: "DELETE_SELECTED_COMPANIES",
  payload,
});
export const setRoleListAC = (payload: string[]) => ({
  type: "SET_ROLE_LIST",
  payload,
});
export const setSelectedRolesAC = (payload: string) => ({
  type: "SET_SELECTED_ROLES",
  payload,
});
export const deleteSelectedRolesAC = (payload: string) => ({
  type: "DELETE_SELECTED_ROLES",
  payload,
});

export const getRolesListTC = () => async (dispatch: Dispatch) => {
  const configRoles = await getRoles("professionals");
  if (configRoles.status === 200) {
    dispatch(setRoleListAC(configRoles.data));
  }
};
export const getCompaniesListTC = () => async (dispatch: Dispatch) => {
  const configCompanies = await getCompaniesList();
  if (configCompanies.status === 200) {
    dispatch(setCompanyListAC(configCompanies.data));
  }
};

export default ProfReducer;
