import React from "react";

const StorageDropDownIconSvg = () => {
  return (
    <svg
      style={{marginLeft: 6}}
      width="10"
      height="6"
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1L5 5L9 1"
        stroke="#4F4F4F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default StorageDropDownIconSvg;
