import {addSuccessMessageAC} from "../../../../redux/reducers/ErrorReducer";
import {
  setSuccessClosingCreateAC,
  setSuccessfullyDeletedAC,
} from "../../../../redux/reducers/calendarReducer";
import {Dispatch} from "react";

export const closingEffect = (
  successClosingCreate: boolean | null,
  isClosingEditMode: boolean,
  dispatch: Dispatch<(payload: unknown) => void>,
  successfullyDeleted: boolean | null,
  setShowCloseLapse: (close: boolean) => void,
) => {
  if (successClosingCreate && !isClosingEditMode) {
    dispatch(
      addSuccessMessageAC("Successfully created") as unknown as (
        payload: unknown,
      ) => void,
    );
    setShowCloseLapse(false);
    dispatch(
      setSuccessClosingCreateAC(null) as unknown as (payload: unknown) => void,
    );
  } else if (successClosingCreate && isClosingEditMode) {
    dispatch(
      addSuccessMessageAC("Successfully updated") as unknown as (
        payload: unknown,
      ) => void,
    );
    setShowCloseLapse(false);
    dispatch(
      setSuccessClosingCreateAC(null) as unknown as (payload: unknown) => void,
    );
  } else if (!successClosingCreate) {
    dispatch(
      setSuccessClosingCreateAC(null) as unknown as (payload: unknown) => void,
    );
  }
  if (successfullyDeleted) {
    dispatch(
      setSuccessfullyDeletedAC(null) as unknown as (payload: unknown) => void,
    );
  }
};
