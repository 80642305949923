import React from "react";
import DeededInput from "./DeededInput";
import {Box, SxProps, Theme} from "@mui/material";

interface DeededTextAreaProps {
  text: string;
  setText: (text: string) => void;
  height?: number;
  disabled?: boolean;
  error?: string;
  sx?: SxProps<Theme>;
}

const DeededTextArea: React.FC<
  React.ComponentProps<typeof DeededInput> & DeededTextAreaProps
> = ({disabled, error, onChange, setText, sx, ...props}) => {
  return (
    <Box
      sx={{
        height: `${props.height}px`,
        ".MuiOutlinedInput-root.Mui-disabled:hover": {cursor: "no-drop"},
        ...sx,
      }}
      data-selenium-test="DeededTextArea"
    >
      <DeededInput
        {...props}
        value={props.text}
        error={error}
        disabled={disabled}
        onChange={
          onChange
            ? (e) => {
                setText(e.target.value);
                onChange(e);
              }
            : (e) => setText(e.target.value)
        }
        height={`${props.height}px`}
        sx={{
          width: "100%",
          "& .MuiFormControl-root": {
            overflow: "hidden",
          },
          "& .MuiOutlinedInput-root": {
            minHeight: "94px",
            alignItems: "flex-start",
          },
          "& .MuiOutlinedInput-root .MuiInputBase-input": {
            height: `${props.height - 25}px !important`,
            lineHeight: 1.5,
          },
          "& textarea": {
            marginTop: 2,
            marginBottom: 2,
          },
          "& .MuiOutlinedInput-input": {
            overflow: "hidden",
          },
        }}
        multiline
      />
    </Box>
  );
};
export default DeededTextArea;
