import React from "react";

export const DealDetailsFileTasksContext = React.createContext({
  dealId: 0,
  fileTypeValueToSet: "",
  setFileTypeValueToSet: (fileTypeValueToSet: string) => {},
  isOpenedFileTaskPopup: false,
  setIsOpenedFileTaskPopup: (isOpenedFileTaskPopup: boolean) => {},
  selectedFileType: "",
  setSelectedFileType: (selectedFileType: string) => {},
});
