import { styled } from "@mui/material/styles";
import {SxProps, Theme} from "@mui/material";
import Box, {BoxProps} from "@mui/material/Box";
import constants from "../../styles/constants";

const DeededTabs: React.FC<{
  sx?: SxProps<Theme> & Omit<BoxProps, "variant">;
}> = ({children, sx, ...props}) => (
  <TabsMainBox sx={sx} {...props} data-selenium-test="DeededTabs">
    <TabBox>{children}</TabBox>
  </TabsMainBox>
);

const TabsMainBox = styled(Box)({
  position: "relative",
  display: "flex",
  marginBottom: "15px",
});

const TabBox = styled(Box)({
  borderBottom: `2px solid ${constants.colors.grayBorder}`,
  display: "inline-flex",
});

export default DeededTabs;
