import {ResultCalendarDateType, ResultCalendarDateUserType} from "../../../../redux/reducers/types/calendarApiTypes";
import {Moment} from "moment";

interface ClosingsCalendarResultByDatesType {
  oneOfALotDates: {
    "Law Clerk": {
      oneOfLawClerks: ResultCalendarDateUserType;
      total: number;
    };
    Lawyer: {
      oneOfLawyers: ResultCalendarDateUserType;
      total: number;
    };
  };
}
export const getLawClerksOrLawyersClosingsMonthDayCell = (role: "Law Clerk"|"Lawyer", closingsCalendarResultByDates:ResultCalendarDateType, day:Moment) =>{
  const typedClosingsCalendarResultByDates =
    closingsCalendarResultByDates as unknown as ClosingsCalendarResultByDatesType;
  const currentDayForSelectClosingsCounts = day.format("YYYY-MM-DD");
  const currentDateTypedClosingsCalendarResult =
    typedClosingsCalendarResultByDates[
      currentDayForSelectClosingsCounts as "oneOfALotDates"
      ];
  if(role === "Law Clerk"){
    return currentDateTypedClosingsCalendarResult?.["Law Clerk"]
      ? Object.keys(
        currentDateTypedClosingsCalendarResult?.["Law Clerk"],
      )
        ?.filter((key) => key !== "total")
        ?.map((key) => {
          return {
            name: currentDateTypedClosingsCalendarResult?.[
              "Law Clerk"
              ][key as "oneOfLawClerks"]?.full_name,
            count:
            currentDateTypedClosingsCalendarResult?.["Law Clerk"][
              key as "oneOfLawClerks"
              ]?.deals,
          };
        })
      : []
  }
  else {
    return currentDateTypedClosingsCalendarResult?.["Lawyer"]
      ? Object.keys(
        currentDateTypedClosingsCalendarResult?.["Lawyer"],
      )
        ?.filter((key) => key !== "total")
        ?.map((key) => {
          return {
            name: currentDateTypedClosingsCalendarResult?.[
              "Lawyer"
              ][key as "oneOfLawyers"]?.full_name,
            count:
            currentDateTypedClosingsCalendarResult?.["Lawyer"][
              key as "oneOfLawyers"
              ]?.deals,
          };
        })
      : []
  }
}