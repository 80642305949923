

interface IconProps {
    color: string,
    size?: string
}

export const IDV = ({ color, size = "24px" }: IconProps) => {
    return (
        <svg width={size} viewBox="0 0 40 39" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18 20.5C23.5228 20.5 28 16.0228 28 10.5C28 4.97715 23.5228 0.5 18 0.5C12.4772 0.5 8 4.97715 8 10.5C8 16.0228 12.4772 20.5 18 20.5Z" fill={color} />
            <path fillRule="evenodd" clipRule="evenodd" d="M12 22.5C5.37258 22.5 0 27.8726 0 34.5V38.5H36V34.5C36 31.6053 34.975 28.9499 33.2682 26.8771L39.2369 20.9084L33.58 15.2516L26.1411 22.6905C25.4464 22.5653 24.7308 22.5 24 22.5H12ZM20.9942 30.6658L14.4084 24.08L11.58 26.9084L20.9942 36.3227L36.4084 20.9084L33.58 18.08L20.9942 30.6658Z" fill={color} />
        </svg>
    )
}


export const Survey = ({ color, size = "24px" }: IconProps) => {
    return (
        <svg width={size} height={size} viewBox="0 0 14 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M5 8.5C5 9.32843 4.32843 10 3.5 10C2.67157 10 2 9.32843 2 8.5C2 7.67157 2.67157 7 3.5 7C4.32843 7 5 7.67157 5 8.5ZM4 8.5C4 8.77614 3.77614 9 3.5 9C3.22386 9 3 8.77614 3 8.5C3 8.22386 3.22386 8 3.5 8C3.77614 8 4 8.22386 4 8.5Z" fill={color} />
            <path d="M3.5 14C4.32843 14 5 13.3284 5 12.5C5 11.6716 4.32843 11 3.5 11C2.67157 11 2 11.6716 2 12.5C2 13.3284 2.67157 14 3.5 14Z" fill={color} />
            <path fillRule="evenodd" clipRule="evenodd" d="M5 16.5C5 17.3284 4.32843 18 3.5 18C2.67157 18 2 17.3284 2 16.5C2 15.6716 2.67157 15 3.5 15C4.32843 15 5 15.6716 5 16.5ZM4 16.5C4 16.7761 3.77614 17 3.5 17C3.22386 17 3 16.7761 3 16.5C3 16.2239 3.22386 16 3.5 16C3.77614 16 4 16.2239 4 16.5Z" fill={color} />
            <path d="M12 7V8H6V7H12Z" fill={color} />
            <path d="M12 12V11H6V12H12Z" fill={color} />
            <path d="M12 15V16H6V15H12Z" fill={color} />
            <path d="M10 9H6V10H10V9Z" fill={color} />
            <path d="M8 13V14H6V13H8Z" fill={color} />
            <path d="M11 18V17H6V18H11Z" fill={color} />
            <path fillRule="evenodd" clipRule="evenodd" d="M9 2H8.5C8.5 1.17157 7.82843 0.5 7 0.5C6.17157 0.5 5.5 1.17157 5.5 2H5C4.25972 2 3.61337 2.4022 3.26756 3H2C0.895431 3 0 3.89543 0 5V19C0 20.1046 0.89543 21 2 21H12C13.1046 21 14 20.1046 14 19V5C14 3.89543 13.1046 3 12 3H10.7324C10.3866 2.4022 9.74028 2 9 2ZM2 4H3V5H11V4H12C12.5523 4 13 4.44772 13 5V19C13 19.5523 12.5523 20 12 20H2C1.44772 20 1 19.5523 1 19V5C1 4.44772 1.44772 4 2 4ZM7 2.5C7.27614 2.5 7.5 2.27614 7.5 2C7.5 1.72386 7.27614 1.5 7 1.5C6.72386 1.5 6.5 1.72386 6.5 2C6.5 2.27614 6.72386 2.5 7 2.5Z" fill={color} />
        </svg>

    )
}

export const Box = ({ color, size = "24px" }: IconProps) => {
    return (
        <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M14 1H6C3.23858 1 1 3.23858 1 6V14C1 16.7614 3.23858 19 6 19H14C16.7614 19 19 16.7614 19 14V6C19 3.23858 16.7614 1 14 1ZM6 0C2.68629 0 0 2.68629 0 6V14C0 17.3137 2.68629 20 6 20H14C17.3137 20 20 17.3137 20 14V6C20 2.68629 17.3137 0 14 0H6Z" fill={color} />
        </svg>
    )
}

export const Selected = ({ color, size = "24px" }: IconProps) => {
    return (
        <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M0 6C0 2.68629 2.68629 0 6 0H14C17.3137 0 20 2.68629 20 6V14C20 17.3137 17.3137 20 14 20H6C2.68629 20 0 17.3137 0 14V6ZM16.2042 7.20421L8.49712 14.9113L3.79001 10.2042L5.20422 8.79L8.49712 12.0829L14.79 5.79L16.2042 7.20421Z" fill={color} />
        </svg>

    )
}


export const Clock = ({ color, size = "24px" }: IconProps) => {
    return (
        <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 3H10V9.79289L13.3536 13.1464L12.6464 13.8536L9 10.2071V3Z" fill={color} />
            <path d="M2 10.5H4.5V9.5H2V10.5Z" fill={color} />
            <path d="M18 9.5H15.5V10.5H18V9.5Z" fill={color} />
            <path d="M10.5 18V15.5H9.5V18H10.5Z" fill={color} />
            <path fillRule="evenodd" clipRule="evenodd" d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z" fill={color} />
        </svg>

    );
}

export const Copy = ({ color, size = "24px" }: IconProps) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 5H14V6H10V5Z" fill={color}/>
            <path d="M10 8H19V9H10V8Z" fill={color}/>
            <path d="M18 11V10H10V11H18Z" fill={color}/>
            <path d="M10 12H19V13H10V12Z" fill={color}/>
            <path d="M17 14H10V15H17V14Z" fill={color}/>
            <path d="M10 16H13V17H10V16Z" fill={color}/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8 20H21V2H8V20ZM9 19V3H20V19H9Z" fill={color}/>
            <path d="M3 4H7V5H4V21H16V22H3V4Z" fill={color}/>
            <path d="M5 7H7V8H5V7Z" fill={color}/>
            <path d="M5 10H7V11H5V10Z" fill={color}/>
            <path d="M5 12H7V13H5V12Z" fill={color}/>
            <path d="M5 14H7V15H5V14Z" fill={color}/>
            <path d="M5 16H7V17H5V16Z" fill={color}/>
            <path d="M5 18H7V19H5V18Z" fill={color}/>
        </svg>
    );
}