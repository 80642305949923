import {useCallback, useEffect} from "react";
import {useDispatch} from "react-redux";
import {
  DealsListInputQuery,
  fetchDeasListTC,
} from "redux/reducers/dealsReducer";
import useTypedSelector from "utils/hooks/useTypedSelector";

const useDealList = ({
  searchQuery,
  newDeals,
  showClosed,
  archive,
  sortBy,
  sortOrder,
  status,
  userId,
  page,
}: DealsListInputQuery) => {
  const data = useTypedSelector((state) => state.DealsReducer.dealsList);
  const query = useTypedSelector(
    (state) => state.DealsReducer.dealsListInputQuery,
  );
  const isLoading = useTypedSelector(
    (state) => state.DealsReducer.isDealsListLoading,
  );
  const error = useTypedSelector((state) => state.DealsReducer.dealslistError);
  const dispatch = useDispatch();

  const refetch = useCallback(() => {
    dispatch(
      fetchDeasListTC({
        searchQuery,
        newDeals,
        showClosed,
        archive,
        sortBy,
        sortOrder,
        status,
        userId,
        page,
      }),
    );
  }, [
    archive,
    showClosed,
    dispatch,
    newDeals,
    page,
    searchQuery,
    sortBy,
    sortOrder,
    status,
    userId,
  ]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  return {data, isLoading, error, refetch, query};
};

export default useDealList;
