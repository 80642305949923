import {AxiosResponse} from "axios";
import * as yup from "yup";
import {FormikHelpers, useFormik} from "formik";
import {updatePassword} from "api";
import {useSnackbar} from "notistack";
import configuration from "utils/configuration";
import {AlertTypes} from "types/alertTypes";

export const userPasswordRegex = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{12,})(?=^\S.*.\S$)/;

interface FormValues {
  current_pass: string;
  new_pass: string;
  confirm_new_pass: string;
}

const schema = yup.object().shape({
  current_pass: yup.string().required("Field is required"),
  new_pass: yup
    .string()
    .required("Please enter new password")
    .matches(userPasswordRegex, "Please complete all required conditions"),
  confirm_new_pass: yup
    .string()
    .required("Please confirm your password")
    .oneOf([yup.ref("new_pass")], "Passwords must match"),
});

const useUserSecurityForm = () => {
  const {enqueueSnackbar} = useSnackbar();

  const onSubmit = async (values: FormValues, helpers: FormikHelpers<FormValues>) => {
    try {
      await updatePassword(values.current_pass, values.new_pass, values.confirm_new_pass);
      helpers.resetForm();
      enqueueSnackbar("Successfully updated", {
        variant: AlertTypes.Success,
        autoHideDuration: 1500,
      });
    } catch (e) {
      const error = e as {response: AxiosResponse<{error?: string}>};
      enqueueSnackbar(error.response.data.error ?? "Couldn't update password", {
        variant: AlertTypes.Error,
        autoHideDuration: configuration.autoHideErrorDuration,
      });
    }
  };

  const {handleSubmit, isSubmitting, values, errors, handleChange, setValues, handleBlur} =
    useFormik<FormValues>({
      validationSchema: schema,
      initialValues: {
        current_pass: '',
        new_pass: '',
        confirm_new_pass: ''
      },
      onSubmit,
      validateOnChange: false,
      validateOnBlur: false,
    });

  const controlFor = (name: keyof FormValues) => ({
    name,
    value: values[name] as string,
    onChange: handleChange,
    onBlur: handleBlur,
    error: errors[name],
    disabled: isSubmitting,
    type: "password" as const,
  });

  return {values, controlFor, handleSubmit, isSubmitting} as const;
};

export default useUserSecurityForm;
