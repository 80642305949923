import Tooltip from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import React from "react";
import {styled} from "@mui/material/styles";
import Box from "@mui/material/Box";

interface Props {
  handleTooltipClose: () => void;
  open: boolean;
  children: any;
}

const CustomToolTip = ({handleTooltipClose, open, children}: Props) => {
  return (
    <CustomToolTipWrapper>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <div>
          <Tooltip
            placement="top"
            className={"custom-tooltip"}
            PopperProps={{
              disablePortal: true,
            }}
            onMouseLeave={handleTooltipClose}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title="Copied"
          >
            {children}
          </Tooltip>
        </div>
      </ClickAwayListener>
    </CustomToolTipWrapper>
  );
};

const CustomToolTipWrapper = styled(Box)({
  "& .MuiTooltip-tooltip": {
    width: "100px",
    fontSize: "16px !important",
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});
export default CustomToolTip;
