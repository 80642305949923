import {SVGProps} from "react";
import constants from "../../styles/constants";

const ClipboardCopySuccessIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={18}
      height={9}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m1 3 4.5 4.5L12 1"
        stroke={constants.colors.red}
        strokeWidth={2}
      />
    </svg>
  );
};

export default ClipboardCopySuccessIcon;
