import React, {SetStateAction, useCallback, useEffect, useState} from "react";
import {Deal} from "types/deal";
import constants from "styles/constants";

import {
  Box,
  Pagination,
  styled,
  Table,
  TableBody,
  TableContainer,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import useTypedSelector from "utils/hooks/useTypedSelector";
import {User} from "types/user";
import useUser from "utils/hooks/useUser";
import {useDispatch} from "react-redux";
import {FilterProps} from "utils/getFilterProps";
import DeededCircularLoader from "v2/components/DeededCircularLoader";
import DealsListSortingMenuTableHead from "./DealsListTable/DealsListSortingMenuTableHead";
import DealsListItemTableBody from "./DealsListTable/DealsListItemTableBody";
import DeededResultsNotFound from "v2/components/DeededResultsNotFound";
import {addErrorAC} from "redux/reducers/ErrorReducer";
import useDealList from "../hooks/useDealList";
import useQueryParams from "utils/hooks/useQueryParams";
import {useHistory} from "react-router-dom";
import {default as getVisibleColumns} from "./DealsListVisibleColumnMatrix";


interface DealsListBaseProps {
  filterProps: FilterProps;
  status: boolean;
  resetPage?: boolean;
  setResetPage?: React.Dispatch<SetStateAction<boolean>>;
  searchVal: string | undefined;
}

const DealsListBase = ({
  filterProps,
  status,
  resetPage,
  setResetPage,
  searchVal,
}: DealsListBaseProps) => {
  const history = useHistory();
  const params = useQueryParams();

  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState(false);

  const {showClosed, newDeals, archive} = filterProps;

  const {selectedProfForDeals} = useTypedSelector(
    (state) => state.ConfigReducer.ProfReducer,
  );

  const [currentPage, setCurrentPage] = useState(+params?.page || 1);

  const {
    refetch,
    data: dealsData,
    isLoading: dealsListLoading,
    error,
  } = useDealList({
    searchQuery: searchVal ?? null,
    newDeals: newDeals,
    showClosed,
    archive,
    sortBy: sortBy === "" ? null : sortBy,
    sortOrder: sortOrder ? "desc" : "asc",
    status: status,
    userId: selectedProfForDeals?.id ?? null,
    page: currentPage,
  });

  const [perPage, setPerPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [deleteDeal, setDeleteDeal] = useState(false);
  const [deleteID, setDeleteID] = useState<number | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const user = (useUser() as Partial<{user: Partial<User>}>)?.user;
  const visibleColumns = getVisibleColumns(user)

  useEffect(() => {
    if (error?.message) {
      dispatch(addErrorAC(error.message));
    }
  }, [dispatch, error?.message]);

  const handleSort = (type: string) => {
    setSortBy(type);
    setSortOrder(!sortOrder);
  };

  const deletePopup = (dealID: number) => {
    setDeleteDeal(true);
    setIsOpen(true);
    setDeleteID(dealID);
  };

  const handleSetPage = useCallback(
    (page: number) => {
      params.page = page.toString();
      const search = new URLSearchParams(params).toString();
      history.push({search});
      setCurrentPage(page);
    },
    [setCurrentPage, history, params],
  );

  useEffect(() => {
    if (dealsData) {
      if (setResetPage && resetPage) {
        handleSetPage(1);
        setResetPage(false);
      }

      setPerPage(dealsData?.per_page);
      setTotal(dealsData?.total);
    }
  }, [dealsData, resetPage, setResetPage, handleSetPage]);

  return (
    <>
      {dealsListLoading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "50vh",
          }}
        >
          <DeededCircularLoader />
        </Box>
      ) : dealsData?.dealsList?.length !== 0 ? (
        <>
         {isSmallScreen ? (<Box
            sx={{
              "& > div": {
                padding: "24px 0",
                "&:not(:last-child)": {
                  marginBottom: "8px !important",
                },
              },
              [theme.breakpoints.up("sm")]: {
                display: "none",
              },
            }}
          >
            {dealsData?.dealsList?.map((deal: Deal) => (
              <DealsListItemTableBody
                visibleColumns={visibleColumns}
                getCurrentList={refetch}
                id={1}
                isLead={!status}
                user={user}
                deal={deal}
                key={deal.id}
                deletePopup={deletePopup}
              />
            ))}
          </Box>) :
          (<TableContainer
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "calc(100% - 175px)",
              marginTop: "16px",
              "&::-webkit-scrollbar": {
                display: "none",
              },
              [theme.breakpoints.down(1283)]: {
                marginTop: "7px",
                height: "calc(100% - 205px)",
              },
            }}
            component={Box}
          >
            <Table
              aria-label="simple table"
              sx={{
                [theme.breakpoints.down("sm")]: {
                  display: "none",
                },
              }}
            >
              {!dealsListLoading && (
                <DealsListSortingMenuTableHead
                  visibleColumns={visibleColumns}
                  handleSort={handleSort}
                  sortBy={sortBy}
                  sortOrder={sortOrder}
                />
              )}
              <TableBody>
                {dealsData?.dealsList?.map((deal: Deal) => (
                  <DealsListItemTableBody
                    visibleColumns={visibleColumns}
                    getCurrentList={refetch}
                    id={1}
                    isLead={!status}
                    user={user}
                    deal={deal}
                    key={deal.id}
                    deletePopup={deletePopup}
                  />
                ))}
              </TableBody>
            </Table>
            {Math.ceil(total / perPage) > 1 && !dealsListLoading && (
              <DealsPagination
                count={Math.ceil(total / perPage)}
                variant="outlined"
                shape="rounded"
                page={currentPage}
                onChange={(_, val) => {
                  if (!dealsListLoading) {
                    handleSetPage(val);
                  }
                }}
              />
            )}
          </TableContainer>)}
        </>
      ) : (
        <DeededResultsNotFound
          searchVal={searchVal}
          textNotFound="There are no transactions yet"
        />
      )}
    </>
  );
};

const DealsPagination = styled(Pagination)(({theme}) => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "64px",
  marginTop: "16px",
  paddingBottom: "16px",
  li: {
    margin: "0 3px",
  },
  "& .MuiPaginationItem-root": {
    fontSize: "14px",
    fontWeight: 500,
    fontFamily: "Montserrat",
    border: "none",
    padding: "0",
    margin: "0",
  },
  "& .MuiPagination-ul li:first-of-type, .MuiPagination-ul li:last-child": {
    background: "white",
    borderRadius: "5px",
    svg: {
      fontSize: "18px",
    },
  },
  "& .Mui-selected": {
    backgroundColor: `${constants.colors.red} !important`,
    color: "white",
    border: "none",
  },
  "& .Mui-disabled": {
    background: "#F2F6FB",
  },
  [theme.breakpoints.down("sm")]: {
    li: {
      margin: "0 1px",
    },
  },
}));

export default DealsListBase;
