import React, {Component, useEffect} from "react";
import {Link} from "react-router-dom";
import {baseURL} from "../../../api";
import NumberFormat from "react-number-format";
import DocumentTypes from "../DocumentTypes/DocumentTypes";
import "./NewClient.scss";
import "react-dropzone-uploader/dist/styles.css";
import {connect} from "react-redux";
import {CreateDeal} from "../../../redux/actions/deals";
import {withSnackbar} from "utils/withSnackbar";
import fileSize from "filesize";
import axios from "axios";
import {ReusableSelect} from "utils/reusableSelect";
import {PostalCodeValidator} from "../PostalCodeValidator/PostalCodeValidator";
import {withMixpanel} from "utils/MixpanelContext";
import moment from "moment";
import {PROP_CARD_DATE_DISPLAY_FORMAT} from "utils/date";
import {
  setNewDealSelectedProvinceAC,
  submitLoaderAC,
} from "../../../redux/reducers/dealsReducer";
import constants from "../../../styles/constants";
import {Box, styled, useTheme} from "@mui/material";
import DeededCircularLoader from "v2/components/DeededCircularLoader";
import {useGetDealTypeTagsQuery} from "../../../redux/reducers/dealInfoApi";
import configuration from "utils/configuration";
import {getUserType, isProfessional} from "utils/permissionRoles";
import AdditionalClientForm from "./AdditionalClientForm";
import BasicButton from "../../Common/Button/BasicButton";
import ClientPrefixInput from "./ClientPrefixInput";
import ThankYouModal from "./ThankYouModal";
import DeededDatePicker from "v2/components/DeededDatePicker";

const CLOSING_DATE_MAX_YEARS = 10;

export const provincesList = [
    { label: "AB", value: "AB" },
    { label: "ON", value: "ON" },
    { label: "BC", value: "BC"},
];
  
class NewClient extends Component {
  state = {
    isFirstSubmit: false,
    isValidEmail: false,
    closing_date: null,
    deal_type: "",
    mortgage_type: null,
    property_address: {
      address: "",
      city: "",
      state: "",
      code: "",
    },
    client: {
      first_name: "",
      last_name: "",
      middle_name: "",
      email: "",
      phone: "",
    },
    secondary_clients: [],
    inputErrors: {},
    client_prefix: "",
    second_client_prefix: "",
    is_broker_letter: false,
    broker_letter: "No",

    worked_together: "",
    client_known: "",
    client_known_length: "",

    files: [],
    transaction_comment: "",
    disabled: false,
    isLoading: false,
    professionals_list: [],
    professionals_list_without_deleted: [],
    assign_deal: false,
    professional_id: "",
    show_client_row: false,
    error: false,
    files_total: fileSize(0),
    thankYouOpenModal: false,
  };

  componentDidUpdate(prevState) {
    if (this.props.deal && !isProfessional(this.props.currentUser.role)) {
      this.props.history.replace(`deals/${this.props.deal.id}`);
    }
  }

  componentDidMount() {
    (this.props.currentUser.role === "Admin" ||
      this.props.currentUser.role === "System Admin") &&
      this.getProfessionalsList();
  }

  getProfessionalsList = () => {
    axios
      .get(`${baseURL}/config/users?tab=professionals&all=true`)
      .then((res) => {
        const {data} = res;
        this.setState({
          professionals_list:
            data &&
            data.map((el) => {
              return {
                label: el.full_name,
                value: el.full_name,
                id: el.id,
              };
            }),
          professionals_list_without_deleted:
            data &&
            data
              .filter((professional) => professional.deleted_at === null)
              .map((el) => {
                return {
                  label: el.full_name,
                  value: el.full_name,
                  id: el.id,
                };
              }),
        });
      })
      .catch((error) => {
        this.props.submitLoaderAC(false);
        this.props.enqueueSnackbar(
          error &&
            error.response &&
            error.response.data &&
            error.response.data.error,
          {
            variant: "error",
            autoHideDuration: configuration.autoHideErrorDuration,
          },
        );
      });
  };
  handleProfessionalAssign = (person) => {
    this.setState({professional_id: person.id});
  };
  handleNumberInput = (e) => {
    let {value} = e.target;
    let regExp = /\d{3}-\d{3}-\d{4}/;
    if (!value.match(regExp)) {
      this.setState({error: true});
      return;
    } else {
      this.setState({error: false});
    }
  };
  handleInputs = (e, key, name) => {
    if (name === "state") {
      this.props.setNewDealSelectedProvinceAC(e);
    }
    this.setState({inputErrors: {}});
    if (e.target === undefined) {
      const {value} = e;
      this.setState((prevState) => ({
        ...prevState,
        [key]: {
          ...prevState[key],
          [name]: value,
        },
      }));
    } else if (
      this.state.client_prefix === "Corporation" &&
      e.target.name === "first_name"
    ) {
      const {value} = e.target;
      this.setState((prevState) => ({
        ...prevState,
        [key]: {
          ...prevState[key],
          first_name: value,
          last_name: value,
          middle_name: "",
        },
      }));
    } else {
      const {name, value} = e.target;
      this.setState((prevState) => ({
        ...prevState,
        [key]: {
          ...prevState[key],
          [name]: value,
        },
      }));
    }
  };
  handleNamePrefix = (e, key) => {
    const {value} = e.target;
    this.setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  handleRadioBtn = (e, key) => {
    const {value} = e.target;
    this.setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  handleFilesUpload = (files) => {
    const data = files.data.filter((f) => f.files !== undefined);
    const filesSize = data.map((doc) => doc.files.map((el) => el.size));
    const newArray = Array.prototype.concat.apply([], filesSize);
    const totalSize = newArray.reduce((total, amount) => {
      return total + amount;
    }, 0);
    this.setState((prevState) => ({
      ...prevState,
      files: data,
      files_total: fileSize(totalSize),
    }));
  };

  handleDate = (date) => {
    try {
      this.setState({
        closing_date: new Date(date),
        inputErrors: {},
      });
    } catch (error) {
      console.error(error);
    }
  };
  onChangeHandler = (event) => {
    event.preventDefault();
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = () => {
      this.setState({
        file: reader.result,
      });
    };
    reader.onerror = (error) => {
      this.props.submitLoaderAC(false);
      this.props.enqueueSnackbar(error.response.data.error, {
        variant: "error",
        autoHideDuration: configuration.autoHideErrorDuration,
      });
    };
  };

  handleAddClient = () => {
    this.setState(prevState => ({
      ...prevState,
      inputErrors: {},
      secondary_clients: [
        ...prevState.secondary_clients,
        {
          first_name: "",
          middle_name: "",
          last_name: "",
          email: "",
          phone: "",
        },
      ],
    }));
  };
  handleRemoveClient = (index) => {
    this.setState(prevState => ({
      ...prevState,
      inputErrors: {},
      secondary_clients: [
        ...prevState.secondary_clients.slice(0, index),
        ...prevState.secondary_clients.slice(index + 1),
      ]
    }));
  }
  handleUpdateClient = (data, index) => {
    this.setState(prevState => {
      const newSecondaryClients = [...prevState.secondary_clients];
      newSecondaryClients[index] = data;

      return {
        ...prevState,
        inputErrors: {},
        secondary_clients: newSecondaryClients,
      }
    });
  }

  // SUBMIT AND CREATE CLIENT
  handleFormSubmit = (e) => {
    e.preventDefault();

    this.props.submitLoaderAC(true);
    const {
      deal_type,
      closing_date,
      property_address,
      client,
      client_prefix,
      secondary_clients,
      files,
      transaction_comment,
      professional_id,
      assign_deal,
      broker_letter,
      isValidEmail,
      mortgage_type,
      worked_together,
      client_known,
      client_known_length,
    } = this.state;

    this.props.mixpanel.track(`dashboard_add_new_deal_submit`, {
      role: this.props.currentUser.role,
      email: this.props.currentUser.email,
      user_type: getUserType(this.props.currentUser.role),
      deal_province: property_address.state,
      deal_type: deal_type,
      has_secondary_client: secondary_clients.length > 0,
    });
    this.setState({
      isFirstSubmit: true,
    });
    const errorMap = {};
    for (let key in client) {
      if (key === "email") {
        errorMap[key] = !isValidEmail;
      } else if (key !== "middle_name") {
        errorMap[key] = client[key].length === 0;
      }
    }

    Object.assign(errorMap, validateSecondaryClient(secondary_clients, client.email))

    for (let key in property_address) {
      if (key !== "code") {
        errorMap[key] = property_address[key].length === 0;
        errorMap["closing_date"] = closing_date === null;
      }
    }

    this.setState((prev) => ({
      ...prev,
      inputErrors: {...errorMap, isValidEmail},
    }));
    if (
      Object.keys(errorMap).length !== 0 &&
      Object.entries(errorMap).filter((el) => el[1] !== false).length !== 0
    ) {
      this.props.submitLoaderAC(false);
      this.props.enqueueSnackbar("Please, fill required fields", {
        variant: "error",
        autoHideDuration: configuration.autoHideErrorDuration,
      });
      return;
    }
    if (deal_type === "Mortgage" && !mortgage_type) {
      this.props.submitLoaderAC(false);
      this.props.enqueueSnackbar(
        "Please select a subcategory to proceed with this deal",
        {
          variant: "error",
          autoHideDuration: configuration.autoHideErrorDuration,
        },
      );
      return;
    }
    if (!isValidEmail) {
      this.setState({isLoading: true});
      this.props.submitLoaderAC(false);
    }

    const payload = {
      assign_to: assign_deal ? professional_id : "",
      deal_type: deal_type,
      closing_date: moment(closing_date).format(PROP_CARD_DATE_DISPLAY_FORMAT),
      address: property_address,
      comment: transaction_comment,
      is_lod: broker_letter === "Yes",
      client:
        client_prefix.length === 0
          ? client
          : {
              ...client,
              name_prefix: client_prefix,
            },
      secondary_clients,
      agreements: files || [],
      deal_type_tag_id: mortgage_type?.id,
      supplemental: {
        worked_together,
        client_known,
        client_known_length,
      },
    };
    this.props.onCreateDeal(
      payload,
      (res) => {
        if (res) {
          // Send data for mixpanel analytics
          const {role, email, full_name} = this.props.currentUser;
          const mixPayload = {
            role,
            email,
            full_name,
            deal_type: deal_type,
            address: property_address,
            client:
              client_prefix.length === 0
                ? client
                : {
                    ...client,
                    name_prefix: client_prefix,
                  },
          };
          this.props.mixpanel.track(`${role}_create_new_deal`, mixPayload);
          if (files.length !== 0) {
            // Send data for mixpanel analytics if files were uploaded
            const filesPayload = {
              role,
              email,
              full_name,
              deal_type: deal_type,
              address: property_address,
              client:
                client_prefix.length === 0
                  ? client
                  : {
                      ...client,
                      name_prefix: client_prefix,
                    },
              documents_list: JSON.stringify(
                this.state.files.map((file) => file.document_type),
              ),
            };
            this.props.mixpanel.track(`${role}_upload_documents`, filesPayload);
          }
          if (isProfessional(this.props.currentUser.role)) {
            this.setState({ thankYouOpenModal: true });
          }
          this.setState({
            closing_date: null,
            deal_type: "",
            property_address: {
              address: "",
              city: "",
              state: "",
              code: "",
            },
            client: {
              first_name: "",
              last_name: "",
              middle_name: "",
              email: "",
              phone: "",
            },
            secondary_clients: [],
            client_prefix: "",
            second_client_prefix: "",
            files: [],
            transaction_comment: "",
            disabled: false,
            isLoading: false,
            files_total: fileSize(0),
            mortgage_type: null,
          });
          return;
        }
        return;
      },
      (error) => {
        if (error.response === undefined) {
          this.props.submitLoaderAC(false);
          this.props.enqueueSnackbar('ServerError: "File to large"', {
            variant: "error",
            autoHideDuration: configuration.autoHideErrorDuration,
          });

          this.setState({
            disabled: false,
            isLoading: false,
            show_client_row: false,
          });
          return;
        }
        if (error.response.status >= 400) {
          this.props.submitLoaderAC(false);
          this.props.enqueueSnackbar(error.response.data.error, {
            variant: "error",
            autoHideDuration: configuration.autoHideErrorDuration,
          });

          this.setState({
            disabled: false,
            isLoading: false,
          });
          return;
        }
      },
    );
  };

  render() {
    const {
      deal_type,
      client,
      secondary_clients,
      client_prefix,
      broker_letter,
      property_address,
      transaction_comment,
      professionals_list_without_deleted,
      professional_id,
      assign_deal,
      files_total,
      error,
      inputErrors,
    } = this.state;
    const {isLoading} = this.state;
    const isAdminOrLender =
      this.props.currentUser.role === "Admin" ||
      this.props.currentUser.role === "System Admin" ||
      this.props.currentUser.role === "Lender";
    const isBroker = this.props.currentUser.role === "Broker";
    const isNewMortgageRefinance =
      this.state?.mortgage_type?.name === "New Mortgage/Refinance";

    return (
      <div>
        <div className="newclient__wrapper">
          {isLoading && (
            <div className="loading__wrapper">
              <div className="loading__title">
                <h2>Creating new deal</h2>
                <DeededCircularLoader />
              </div>
            </div>
          )}
          <h2
            style={{
              marginBottom: "4.5rem",
            }}
            className="newclient__header"
            data-testid="add-new-client-header"
          >
            Create New Transaction
          </h2>
          <form
            className="newclient__form"
            onSubmit={this.handleFormSubmit}
            autoComplete="new-password"
          >
            {/* TRANSACTION ROW */}
            <SelectTransactionType
              currentUser={this.props.currentUser}
              assign_deal={assign_deal}
              deal_type={deal_type}
              isAdminOrLender={isAdminOrLender}
              isBroker={isBroker}
              professional_id={professional_id}
              professionals_list_without_deleted={
                professionals_list_without_deleted
              }
              handleRadioBtn={this.handleRadioBtn}
              state={this.state}
              setState={this.setState.bind(this)}
              handleProfessionalAssign={this.handleProfessionalAssign}
            />
            {/* CLIENT INFORMATION ROW */}
            <div className="client__row">
              <div className="client__row--wrapper">
                <div className="with__shadows" style={{marginBottom: "1rem"}}>
                  <h3 className="client__row--title">Client Information</h3>
                  <div className="prefix__row">
                    <ClientPrefixInput
                      client_prefix={client_prefix}
                      onChange={(e) => this.handleNamePrefix(e, "client_prefix")}
                    />
                  </div>
                  <div className="name__row">
                    <div className="fullname__container">
                      {client_prefix === "Corporation" ? (
                        <label htmlFor="first_name">
                          Name of Corporation
                          <input
                            // required
                            className={inputErrors?.first_name && "input-error"}
                            style={{minWidth: "30rem", marginBottom: ".5rem"}}
                            type="text"
                            name="first_name"
                            autoComplete="new-password"
                            value={client.first_name}
                            onChange={(e) => {
                              if (e.target.value.length > 25) return false;
                              this.handleInputs(e, "client");
                            }}
                          />
                          <span>Max 25 characters.</span>
                          {inputErrors?.first_name && (
                            <span className="input-error-text">
                              Field is required
                            </span>
                          )}
                        </label>
                      ) : (
                        <>
                          <label htmlFor="first_name">
                            First Name
                            <input
                              className={
                                inputErrors?.first_name && "input-error"
                              }
                              // required
                              data-testid="client-info-first-name-field"
                              type="text"
                              name="first_name"
                              autoComplete="new-password"
                              value={client.first_name}
                              onChange={(e) => this.handleInputs(e, "client")}
                            />
                            {inputErrors?.first_name && (
                              <span className="input-error-text">
                                Field is required
                              </span>
                            )}
                          </label>
                          <label htmlFor="last_name">
                            Middle Name
                            <input
                              // required
                              className={
                                inputErrors?.middle_name && "input-error"
                              }
                              data-testid="client-info-middle-name-field"
                              type="text"
                              name="middle_name"
                              value={client.middle_name}
                              autoComplete="new-password"
                              onChange={(e) => this.handleInputs(e, "client")}
                            />
                            {inputErrors?.middle_name && (
                              <span className="input-error-text">
                                Field is required
                              </span>
                            )}
                          </label>
                          <label htmlFor="last_name">
                            Last Name
                            <input
                              // required
                              className={
                                inputErrors?.last_name && "input-error"
                              }
                              data-testid="client-info-last-name-field"
                              type="text"
                              name="last_name"
                              value={client.last_name}
                              autoComplete="new-password"
                              onChange={(e) => this.handleInputs(e, "client")}
                            />
                            {inputErrors?.last_name && (
                              <span className="input-error-text">
                                Field is required
                              </span>
                            )}
                          </label>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="email-phone-date__row">
                    <label htmlFor="primeEmail">
                      Client`s Primary Email
                      <input
                        required
                        className={
                          inputErrors?.email &&
                          this.state.isFirstSubmit &&
                          "input-error"
                        }
                        data-testid="client-info-primary-email-field"
                        type="email"
                        name="email"
                        value={client.email}
                        placeholder="clientname@email.com"
                        autoComplete="new-password"
                        onChange={(e) => {
                          this.handleInputs(e, "client");

                          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(e.target.value)
                            ? this.setState({isValidEmail: true})
                            : this.setState({isValidEmail: false});

                          this.setState({
                            isFirstSubmit: false,
                          });
                        }}
                      />
                      {inputErrors?.email && this.state.isFirstSubmit && (
                        <span className="input-error-text">
                          Field is required and should be valid
                        </span>
                      )}
                    </label>
                    <label htmlFor="phone" className={error ? "error" : " "}>
                      Client`s Mobile Phone Number
                      <NumberFormat
                        value={client.phone}
                        // required
                        className={inputErrors?.phone && "input-error"}
                        data-testid="client-info-phone-number-field"
                        format="###-###-####"
                        name="phone"
                        autoComplete="new-password"
                        placeholder="xxx-xxx-xxxx"
                        onChange={(e) => {
                          this.handleInputs(e, "client");
                          this.handleNumberInput(e);
                        }}
                      />
                      {inputErrors?.phone && (
                        <span className="input-error-text">
                          Field is required
                        </span>
                      )}
                    </label>
                  </div>
                  <BasicButton
                    label={{
                      text: "Add Additional Client",
                      inputId: "add-user-btn",
                    }}
                    onClick={this.handleAddClient}
                    size={"small"}
                    typeOf={"CTA"}
                  />
                </div>
                {/* SECONDARY CLIENTS */}
                {secondary_clients.map((c, index) => (
                  <AdditionalClientForm
                    key={index}
                    client={c}
                    inputErrors={inputErrors?.secondary_clients?.[index]}
                    onRemove={() => this.handleRemoveClient(index)}
                    onUpdate={(data) => this.handleUpdateClient(data, index)}
                  />
                ))}

                {/* PROPERTY ROW */}
                <div className="with__shadows" style={{marginBottom: "1rem"}}>
                  <h3 className="client__row--title">Property Information</h3>
                  <div className="address__row">
                    <label htmlFor="address">
                      <p>Street Address</p>
                      <input
                        // required
                        className={inputErrors?.address && "input-error"}
                        data-testid="property-info-street-address"
                        type="text"
                        name="address"
                        autoComplete="new-password"
                        value={property_address.address}
                        onChange={(e) => {
                          this.props.submitLoaderAC(false);
                          this.handleInputs(e, "property_address");
                        }}
                      />
                      {inputErrors?.address === true ? (
                        <span className="input-error-text">
                          Field is required
                        </span>
                      ) : (
                        <span style={{visibility: "hidden"}}>
                          Field is required
                        </span>
                      )}
                    </label>
                    <label htmlFor="city">
                      <p>City</p>
                      <input
                        // required
                        className={inputErrors?.city && "input-error"}
                        data-testid="property-info-city-field"
                        type="text"
                        name="city"
                        autoComplete="new-password"
                        value={property_address.city}
                        onChange={(e) => {
                          this.props.submitLoaderAC(false);
                          this.handleInputs(e, "property_address");
                        }}
                      />
                      {inputErrors?.city === true ? (
                        <span className="input-error-text">
                          Field is required
                        </span>
                      ) : (
                        <span style={{visibility: "hidden"}}>
                          Field is required
                        </span>
                      )}
                    </label>

                    <label htmlFor="province" data-testid="property-info-province-drop-down-label">
                      <p>Province</p>
                      <ReusableSelect
                        inputError={inputErrors?.state}
                        defaultStyles={false}
                        options={provincesList.map((option) => option.value === "BC" && deal_type !== "Lender" ? 
                          {...option, label: `${option.label} (Temporarily unavailable)`, disabled: true}
                          : option
                        )}
                        defaultValue={{
                          label: property_address.state,
                          value: property_address.state,
                        }}
                        onChangeHandler={(e) => {
                          this.props.submitLoaderAC(false);
                          this.handleInputs(e, "property_address", "state");
                        }}
                      />
                      {/* <input
                        tabIndex={-1}
                        autoComplete="new-password"
                        readOnly
                        value={property_address.state}
                        style={{
                          opacity: 0,
                          height: 0,
                          position: "absolute",
                          top: "3rem",
                        }}
                        // required
                      /> */}
                    </label>

                    <label htmlFor="code">
                      <p>Postal Code</p>
                      {/* <div style={{ height: 6 }}></div> */}
                      <PostalCodeValidator
                        isRequired={false}
                        defaultValue={property_address.code}
                        handlePostalCode={(code) => {
                          this.props.submitLoaderAC(false);
                          this.setState((prevState) => ({
                            ...prevState,

                            property_address: {
                              ...prevState["property_address"],
                              code: code,
                            },
                          }));
                        }}
                        data-testid="property-info-postal-code-field"
                      />
                      {inputErrors?.code === true ? (
                        <span className="input-error-text">
                          Field is required
                        </span>
                      ) : (
                        <span style={{visibility: "hidden"}}>
                          Field is required
                        </span>
                      )}
                    </label>
                    <label htmlFor="closingDate">
                      <p style={{marginBottom: "1rem"}}>Closing Date</p>
                      <DeededDatePicker
                        fullWidth
                        placeholder="Select date"
                        name="closingDate"
                        hasError={inputErrors?.closing_date ? "Field is required" : null}
                        minDate={new Date()}
                        maxDate={new Date().setFullYear(
                          new Date().getFullYear() + CLOSING_DATE_MAX_YEARS,
                        )}
                        dateStart={this.state.closing_date}
                        onChange={(date) => {
                          this.props.submitLoaderAC(false);
                          return this.handleDate(date);
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div className="newclient__row with__shadows">
                  <p className="newclient__row--subtitle">
                    Is there anything we need to know about the transaction?
                  </p>
                  <textarea
                    value={transaction_comment}
                    onChange={(e) =>
                      this.setState({transaction_comment: e.target.value})
                    }
                    className="newclient__row--comment"
                  />
                  </div>
                {/* eslint-disable-next-line no-mixed-operators */}
                {(deal_type === "Purchase" ||
                  (deal_type === "Mortgage" && isNewMortgageRefinance)) && (
                  <div className="questions newclient__row with__shadows">
                    <p className="newclient__row--subtitle">
                      Is there a broker letter of direction applicable to this
                      closing?
                    </p>
                    <label className="radio-container marginTop15">
                      <input
                        checked={broker_letter === "Yes"}
                        required={false}
                        type="radio"
                        name="broker_letter"
                        value="Yes"
                        onChange={(e) =>
                          this.handleNamePrefix(e, "broker_letter")
                        }
                      />
                      Yes
                    </label>
                    <label className="radio-container marginTop15">
                      <input
                        checked={broker_letter === "No"}
                        required={false}
                        type="radio"
                        name="broker_letter"
                        value="No"
                        onChange={(e) =>
                          this.handleNamePrefix(e, "broker_letter")
                        }
                      />
                      No
                    </label>
                  </div>
                )}
                <div className="filesupload__row with__shadows">
                  <DocumentTypes
                    mortgageType={this.state?.mortgage_type}
                    province={this.state.property_address.state}
                    filesTotal={files_total}
                    documentType={deal_type}
                    handleFilesUpload={this.handleFilesUpload}
                  />
                </div>
              </div>
            </div>

            {isBroker && (
              <div className="questions newclient__row with__shadows">
                <h3 className="newclient__row--title">Compliance Questions</h3>

                <div className="newclient__row">
                  <p className="newclient__row--subtitle">
                    Have you previously represented the client(s)?
                  </p>
                  <label className="radio-container marginTop15">
                    <input
                      data-testid="supplementary-represented-client-radio"
                      checked={this.state.worked_together === "Yes"}
                      required={true}
                      type="radio"
                      name="worked_together"
                      value="Yes"
                      onChange={(e) =>
                        this.handleNamePrefix(e, "worked_together")
                      }
                    />
                    Yes
                  </label>
                  <label className="radio-container marginTop15">
                    <input
                      data-testid="supplementary-represented-client-radio"
                      checked={this.state.worked_together === "No"}
                      required={true}
                      type="radio"
                      name="worked_together"
                      value="No"
                      onChange={(e) =>
                        this.handleNamePrefix(e, "worked_together")
                      }
                    />
                    No
                  </label>

                  {inputErrors?.worked_together === true ? (
                    <span className="input-error-text">Field is required</span>
                  ) : (
                    <span style={{visibility: "hidden"}}>
                      Field is required
                    </span>
                  )}
                </div>

                <div className="newclient__row">
                  <p className="newclient__row--subtitle">
                    Do you personally know the client(s)?
                  </p>
                  <label className="radio-container marginTop15">
                    <input
                      data-testid="supplementary-know-client-radio"
                      checked={this.state.client_known === "Yes"}
                      required={true}
                      type="radio"
                      name="client_known"
                      value="Yes"
                      onChange={(e) => this.handleNamePrefix(e, "client_known")}
                    />
                    Yes
                  </label>
                  <label className="radio-container marginTop15">
                    <input
                      data-testid="supplementary-know-client-radio"
                      checked={this.state.client_known === "No"}
                      required={true}
                      type="radio"
                      name="client_known"
                      value="No"
                      onChange={(e) => this.handleNamePrefix(e, "client_known")}
                    />
                    No
                  </label>

                  {inputErrors?.client_known === true ? (
                    <span className="input-error-text">Field is required</span>
                  ) : (
                    <span style={{visibility: "hidden"}}>
                      Field is required
                    </span>
                  )}
                </div>

                <div
                  className="newclient__row"
                  style={{
                    display:
                      this.state.client_known === "Yes" ? "block" : "none",
                  }}
                >
                  <p className="newclient__row--subtitle">
                    Yes, How long have you known the client?
                  </p>
                  <label htmlFor="address">
                    <input
                      data-testid="how-long-do-u-know-client-input"
                      className={
                        inputErrors?.client_known_length && "input-error"
                      }
                      type="text"
                      name="client_known_length"
                      autoComplete="new-password"
                      value={this.state.client_known_length}
                      onChange={(e) =>
                        this.handleNamePrefix(e, "client_known_length")
                      }
                    />
                    {inputErrors?.client_known_length === true ? (
                      <span className="input-error-text">
                        Field is required
                      </span>
                    ) : (
                      <span style={{visibility: "hidden"}}>
                        Field is required
                      </span>
                    )}
                  </label>
                </div>
              </div>
            )}

            <div className="newclient__row with__shadows">
              <h3 className="newclient__row--title">Disclaimer</h3>
              <p className="newclient__row--subtitle">
                By clicking Submit I acknowledge that my client(s) has given me
                consent and has authorized me to share their information for
                this deal.
              </p>
            </div>

            <div className="newclient__row">
              <div className="newclient__row--wrapper">
                <Link
                  to="/dashboard/deals"
                  className="newclient__btn--back"
                  onClick={() =>
                    this.props.mixpanel.track(`dashboard_add_new_deal_cancel`, {
                      role: this.props.currentUser.role,
                      email: this.props.currentUser.email,
                      user_type: getUserType(this.props.currentUser.role),
                    })
                  }
                >
                  back
                </Link>

                {!this.props.submitLoader && (
                  <button
                    className="dzu-submitButton"
                    data-testid="new-client-submit-button"
                    type="submit"
                    disabled={isLoading}
                  >
                    submit
                  </button>
                )}
                {this.props.submitLoader && (
                  <p className="dzu-submitButton">Loading...</p>
                )}
              </div>
            </div>
          </form>
          <ThankYouModal openModal={this.state.thankYouOpenModal} deal={this.props.deal}/>
        </div>
      </div>
    );
  }
}
const SelectTransactionType = ({
  assign_deal,
  deal_type,
  isAdminOrLender,
  isBroker,
  professional_id,
  professionals_list_without_deleted,
  handleRadioBtn,
  state,
  setState,
  handleProfessionalAssign,
  currentUser,
}) => {
  return (
    <>
      <Box sx={{}} className="transactions__row with__shadows">
        <h3
          className="transactions__row--title"
          data-testid="transaction-row-title"
        >
          Transaction Type
        </h3>
        <div className="transactions__row--wrapper">
          <label
            className="radio-container"
            onClick={() => {
              setState({
                broker_letter: "No",
              });
            }}
          >
            Purchase
            <input
              checked={deal_type === "Purchase"}
              required
              type="radio"
              name="deal_type"
              value="Purchase"
              onChange={(e) => handleRadioBtn(e, "deal_type")}
            />
            <span className="checkmark"></span>
          </label>
          <label
            className="radio-container"
            data-testid="sale-radio-btn"
            onClick={() => {
              setState({
                broker_letter: "No",
              });
            }}
          >
            Sale
            <input
              checked={deal_type === "Sale"}
              required
              type="radio"
              name="deal_type"
              value="Sale"
              onChange={(e) => handleRadioBtn(e, "deal_type")}
            />
            <span className="checkmark"></span>
          </label>
          {isAdminOrLender && !isBroker && (
            <label
              className="radio-container"
              data-testid="lender-radio-btn"
              onClick={() => {
                setState({
                  broker_letter: "No",
                });
              }}
            >
              Lender
              <input
                checked={deal_type === "Lender"}
                required
                type="radio"
                name="deal_type"
                value="Lender"
                onChange={(e) => handleRadioBtn(e, "deal_type")}
              />
              <span className="checkmark"></span>
            </label>
          )}
          <MortgageRadio
            handleRadioBtn={handleRadioBtn}
            state={state}
            setState={setState}
          />

          {currentUser.role === undefined
            ? null
            : (currentUser.role === "Admin" ||
                currentUser.role === "System Admin") && (
                <div className="assign__card">
                  <div className="status">
                    <label
                      className="container"
                      data-testid="assign-deal-to-professional-checkbox"
                    >
                      <input
                        type="checkbox"
                        name="assign_deal"
                        onChange={() => setState({assign_deal: !assign_deal})}
                      />
                      Assign deal to professional
                      <span className="checkmark"></span>
                    </label>
                    <label
                      data-testid="professionals-list-dropdown-label"
                      htmlFor="professinal_list"
                      style={{
                        visibility: assign_deal ? "visible" : "hidden",
                      }}
                    >
                      <ReusableSelect
                        defaultStyles={false}
                        defaultValue={
                          professional_id.length === 0
                            ? {label: "", value: ""}
                            : professionals_list_without_deleted.find(
                                (prof) => prof.id === professional_id,
                              )
                        }
                        options={professionals_list_without_deleted}
                        onChangeHandler={handleProfessionalAssign}
                      />
                    </label>
                  </div>
                </div>
              )}
        </div>
      </Box>
    </>
  );
};
const MortgageRadio = ({state, setState, handleRadioBtn}) => {
  const {deal_type, mortgage_type} = state;
  const isMortgage = deal_type === "Mortgage";
  const theme = useTheme();
  const {data: dealTypesTags, refetch} = useGetDealTypeTagsQuery("");
  useEffect(() => {
    refetch();
  }, [refetch]);
  return (
    <>
      <Box>
        <label
          className="radio-container"
          data-testid="mortgage-radio-btn"
          onClick={() => {
            setState({
              broker_letter: "No",
            });
          }}
        >
          Mortgage
          <input
            checked={isMortgage}
            required
            type="radio"
            name="deal_type"
            value="Mortgage"
            onChange={(e) => handleRadioBtn(e, "deal_type")}
          />
          <span className="checkmark"></span>
        </label>
        {isMortgage && (
          <Box
            sx={{
              marginTop: "20px",
              marginLeft: "15px",
              display: "flex",
              flexWrap: "wrap",
              width: "540px",
              top: "30px",
              left: "0px",
              [theme.breakpoints.down("sm")]: {
                width: "unset",
                flexDirection: "column",
              },
            }}
          >
            {dealTypesTags?.Mortgage
              .filter(({name}) => name !== 'Transfer of Charge')
              .map((mortgageSectionRadioItem) => {
              return (
                <MortgageSectionRadio
                  key={mortgageSectionRadioItem?.id}
                  name={"mortgage_type"}
                  setState={setState}
                  title={mortgageSectionRadioItem?.name}
                  value={mortgageSectionRadioItem}
                  checked={mortgage_type?.id === mortgageSectionRadioItem?.id}
                  onChange={() => {}}
                />
              );
            })}
          </Box>
        )}
      </Box>
    </>
  );
};
const MortgageSectionRadio = ({
  checked,
  onChange,
  value,
  title,
  setState,
  name,
}) => {
  return (
    <>
      <MortgageSectionLabel
        className="radio-container"
        data-testid="mortgage-type-radio-button"
      >
        {title}
        <input
          checked={checked}
          required
          type="radio"
          value={value}
          onChange={() => {
            setState({
              [name]: value,
            });
          }}
        />
        <span
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            height: "16px",
            width: "16px",
            backgroundColor: constants.colors.white,
            borderRadius: "50%",
            border: checked
              ? `5px solid ${constants.colors.red}`
              : `2px solid ${constants.colors.red}`,
          }}
        ></span>
      </MortgageSectionLabel>
    </>
  );
};
const MortgageSectionLabel = styled("label")({
  marginBottom: "16px",
  paddingLeft: "20px !important",
  paddingTop: "0px !important",
  minWidth: "240px",
});
const mapStateToProps = (state) => {
  return {
    // isLoading: state.DealsReducer.dealsLoading,
    deal: state.DealsReducer.newDeal,
    submitLoader: state.DealsReducer.submitLoader,
    currentUser: state.AuthReducer.currentUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setNewDealSelectedProvinceAC: (payload) =>
      dispatch(setNewDealSelectedProvinceAC(payload)),
    submitLoaderAC: (payload) => dispatch(submitLoaderAC(payload)),
    onCreateDeal: (newDeal, resultCB, callback) =>
      dispatch(CreateDeal(newDeal, resultCB, callback)),
  };
};

export const validateSecondaryClient = function(secondary_clients, main_email = "") {
  let errorMap = [];
  secondary_clients.map((sec_cli, index) => {
    const secondaryClientErrors = {};
    for (let key in sec_cli) {
      if (key === "email" && !sec_cli.draft ) {
        secondaryClientErrors[key] =
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
            sec_cli.email,
          ) ? false : "Field is required";
        
        if (
          secondary_clients.filter(c => c.email === sec_cli.email).length > 1 ||
          main_email === sec_cli.email
        ) {
          secondaryClientErrors[key] = "Please choose a different email address. Each client must have a unique email"
        }
      } else if (key === "phone") {
        secondaryClientErrors[key] = !/\d{3}-\d{3}-\d{4}/.test(sec_cli.phone.replaceAll('.', '-'));
      } else if (["first_name", "last_name"].includes(key)) {
        secondaryClientErrors[key] = sec_cli[key].length === 0;
      }
    }

    if (
      Object.keys(secondaryClientErrors).length > 0 &&
      Object.entries(secondaryClientErrors).filter((el) => el[1] !== false)
        .length !== 0
    ) {
      if (!Boolean(errorMap["secondary_clients"])) {
        errorMap["secondary_clients"] = [];
      }
      errorMap["secondary_clients"][index] = secondaryClientErrors;
    }
  });
  return errorMap;
}

export default withMixpanel(
  withSnackbar(connect(mapStateToProps, mapDispatchToProps)(NewClient)),
);
