import React from "react";

const RefreshProfilePhotoSvg = () => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="43" height="43" rx="4.5" stroke="#D9D9D9" />
      <path
        d="M26.5156 19.3477H31.0156V14.8477"
        stroke="#828282"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.166 16.1664C16.9321 15.4003 17.8416 14.7926 18.8425 14.378C19.8434 13.9634 20.9162 13.75 21.9996 13.75C23.0831 13.75 24.1558 13.9634 25.1568 14.378C26.1577 14.7926 27.0672 15.4003 27.8333 16.1664L31.0153 19.3484"
        stroke="#828282"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.4844 24.6523H12.9844V29.1523"
        stroke="#828282"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.8336 27.8343C27.0675 28.6004 26.1581 29.2081 25.1571 29.6227C24.1562 30.0373 23.0834 30.2507 22 30.2507C20.9166 30.2507 19.8438 30.0373 18.8428 29.6227C17.8419 29.2081 16.9324 28.6004 16.1664 27.8343L12.9844 24.6523"
        stroke="#828282"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RefreshProfilePhotoSvg;
