import React from 'react'

const ClockForMobileCalendarIconButtonSvg = () => {
  return <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"/>
    <path d="M12 12.75L16.5466 10.125" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M11.9625 12.7125L8.25 9" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M19 14V18.75V23.5" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
    <path d="M23.75 18.75L19 18.75L14.25 18.75" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
  </svg>

}
export default ClockForMobileCalendarIconButtonSvg