import {useMemo} from "react";
import moment from "moment-timezone";
import {timezones} from "../DealDetails/utils";
import {AppointmentNullable} from "./types";
import {Workhours} from "types/calendarAppointmentType";
import { parse } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { isToday } from "date-fns";

const TIME_PARSE_FORMAT = "HH:mm:ss";
const DATE_PARSE_FORMAT = "YYYY-MM-DD";
const DATE_TIME_PARSE_FORMAT = `${DATE_PARSE_FORMAT} ${TIME_PARSE_FORMAT}`;

export const useIncomingAppointmentFormData = (
  appointment: AppointmentNullable,
) => {
  const rawValue = appointment?.timezone ?? null;

  const incomingAppointmentProvince = useMemo(() => {
    if (rawValue === null) {
      return 'AB';
    }
    if (timezones.timezoneToState?.[rawValue] === undefined) {
      return rawValue;
    } else {
      return timezones.timezoneToState[rawValue];
    }
  }, [rawValue]);

  const [incomingAppointmentDate, incomingAppointmentStartTime] =
    useMemo(() => {
      const datetime = appointment?.datetime ?? null;
      if (datetime === null) {
        return [null, null];
      }
      const spl = datetime.split(" ");
      return [spl[0], spl[1]];
    }, [appointment]);

  const incomingAppointmentEndTime = useMemo(() => {
    const endAt = appointment?.end_at ?? null;
    if (endAt === null) {
      return null;
    }
    return endAt.split(" ")[1];
  }, [appointment]);

  return {
    incomingAppointmentProvince,
    incomingAppointmentStartTime,
    incomingAppointmentEndTime,
    incomingAppointmentDate,
  };
};

export const useDisplayDateString = (
  date: string | null,
  time: string | null,
  province: string | null,
  targetProvince: string | null,
  targetDateFormat: string = "MMMM D, YYYY"
) => {
  const datetime = useMemo(
    () => (date !== null && time !== null ? `${date} ${time}` : null),
    [date, time],
  );

  const displayDateString = useMemo(() => {
    if (datetime === null || province === null) {
      return null;
    }

    try {
      const offset =
        moment()
          .tz(timezones.stateToTimezone[province as string])
          .utcOffset() / 60;
      const targetOffset =
        moment()
          .tz(timezones.stateToTimezone[targetProvince as string])
          .utcOffset() / 60;

      const diff = targetOffset - offset;

      const localOffset = moment().utcOffset() / 60;

      let m = moment(datetime, DATE_TIME_PARSE_FORMAT)
        .tz(timezones.stateToTimezone[province])
        .subtract(offset, "hours")
        .add(localOffset, "hours")
        .add(diff, "hours");

      if (!m) {
        return null;
      }
      return `${m.format(targetDateFormat)} at ${m.format("LT")}`;
    } catch (e) {
      console.error(e);
      return null;
    }
  }, [datetime, province, targetProvince]);

  return displayDateString;
};

export const useDatepickerMinMaxTime = (pickedDate: string | null, region: string | null, format: string = 'yyyy-MM-dd') =>
  useMemo(() => {

    let minTime = new Date();
    minTime.setHours(Workhours.start, 0, 0);

    const date = pickedDate ? parse(pickedDate as string, format, new Date()) : '';
    const timezone = timezones.stateToTimezone[region || 'AB'];
    const nowLocal = new Date(utcToZonedTime(new Date(), timezone));
    const startWork = new Date().setHours(Workhours.start, 0, 0, 0);
    if (date && isToday(date) && nowLocal.getTime() > startWork) {
      minTime = nowLocal;
    }
    const maxTime = new Date();
    maxTime.setHours(Workhours.end, 0, 0);
    return [minTime, maxTime] as [typeof minTime, typeof maxTime];
  }, [pickedDate, region]);
