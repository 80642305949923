import React, {useState, useEffect} from "react";
import {
  getDealByID,
  getActivityLogsByID,
  updateDealClientData,
} from "api/index";
import {useDispatch, useSelector} from "react-redux";
import {withSnackbar} from "utils/withSnackbar";
import {useRouteMatch} from "react-router-dom";
import DealPropertyCard from "../DealPropertyCard/DealPropertyCard";
// SVG
import "./ActivityLog.scss";
import DealDetailsActivityLogList from "components/Dashboard/ActivityLog/components/DealDetailsActivityLogList";
import DeededCircularLoader from "v2/components/DeededCircularLoader";
import {setCurrentDealDealTypeAC} from "redux/reducers/dealsReducer";
import DetailsTabNavigation from "../DetailsTabNavigation/DetailsTabNavigation";
import configuration from "utils/configuration";

const ActivityLog = ({enqueueSnackbar}) => {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const user = useSelector((state) => state.AuthReducer.currentUser);
  const [deal, setDeal] = useState(null);
  const [logs, setLogs] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastpage] = useState(0);
  
  const handleHeaderUpdate = (closing_date, deal_number, address_data) => {
    const {id, deal_type} = deal;
    const {address, unit, city, state, code} = address_data;
    const client1 = deal.participants.find((el) => el.role === "Client");
    const client2 = deal.participants.find(
      (el) => el.role === "Secondary Client",
    );

    const payload = {
      deal_type,
      closing_date: new Date(closing_date).toLocaleDateString("en-US"),
      deal_number,
      address: {
        address,
        unit,
        city,
        state,
        code,
      },
      client: {
        first_name: client1.first_name,
        last_name: client1.last_name,
        email: client1.email,
        phone: client1.phone,
      },
      secondary_client: {
        first_name: client2 === undefined ? "" : client2.first_name,
        last_name: client2 === undefined ? "" : client2.last_name,
        email: client2 === undefined ? "" : client2.email,
        phone: client2 === undefined ? "" : client2.phone,
      },
      lender: {
        name: "",
        address: {
          address: "",
          unit: "",
          city: "",
          code: "",
        },
      },
    };

    updateDealClientData(id, payload)
      .then((res) => {
        enqueueSnackbar("Property details update", {
          variant: "success",
          autoHideDuration: 1500,
        });
        getDealByID(id).then((res) => {
          setDeal(res.data);
        });
      })
      .catch((error) =>
        enqueueSnackbar(error.message, {
          variant: "error",
          autoHideDuration: configuration.autoHideErrorDuration,
        }),
      );
  };
  useEffect(() => {
    if (deal?.deal_type) {
      dispatch(setCurrentDealDealTypeAC(deal?.deal_type));
    }
  }, [deal, dispatch]);
  useEffect(() => {
    let isSubscribed = true;
    getDealByID(match.params.id)
      .then((res) => {
        if (isSubscribed) {
          setDeal(res.data);
        }
      })
      .catch((error) =>
        enqueueSnackbar(error.message, {
          variant: "error",
          autoHideDuration: configuration.autoHideErrorDuration,
        }),
      );
    getActivityLogsByID(match.params.id, currentPage)
      .then((res) => {
        if (isSubscribed) {
          const {data, current_page, last_page} = res?.data;
          setLogs(data);
          setCurrentPage(current_page);
          setLastpage(last_page);
        }
      })
      .catch((error) =>
        enqueueSnackbar(error.message, {
          variant: "error",
          autoHideDuration: configuration.autoHideErrorDuration,
        }),
      );
    return () => (isSubscribed = false);
  }, [currentPage]);

  return (
    <div className="activity__wrapper">
      <DetailsTabNavigation
        showDocumentsTab={user.role !== "Company Admin"}
      />

      {logs === null || deal === null ? (
        <DeededCircularLoader />
      ) : (
        <div className="activity__container">
          {/* HEADER */}
          <DealPropertyCard
            dealState={deal}
            handlePropertyDataUpdate={handleHeaderUpdate}
            user={user}
          />
          <DealDetailsActivityLogList />
        </div>
      )}
    </div>
  );
};

export default withSnackbar(ActivityLog);
