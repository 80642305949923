import { FC, ReactNode } from "react"
import { styled } from "@mui/material/styles"
import Stack from "@mui/material/Stack"
import Colors from "components/CommonDashboard/Styling/Colors"

interface PortalContainerInterface {
    leftPanel: ReactNode,
    rightPanel: ReactNode,
    hideRightPanelOnMobile?: boolean,
}

const PortalContainer: FC<PortalContainerInterface> = ({ leftPanel, rightPanel, hideRightPanelOnMobile = false }) => {
    return <StyledParent>
        <LeftContainer>
            <LeftChild>
                {leftPanel}
            </LeftChild>
        </LeftContainer>
        <RightContainer hide={hideRightPanelOnMobile}>
            {rightPanel}
        </RightContainer>
    </StyledParent>
}

const StyledParent = styled(Stack)(({ theme }) => ({
    flexFlow: 'row',
    minHeight: '89vh',
    alignItems: 'stretch',
    alignSelf: 'stretch',
    [theme.breakpoints.down('mobile')]: {
        flexDirection: 'column',
    }
}));

const LeftChild = styled(Stack)(({ theme }) => ({
    maxWidth: '960px',
    width: '100%',
    gap: '2.4rem',
}));

const LeftContainer = styled(Stack)(({ theme }) => ({
    alignSelf: 'stretch',
    alignItems: 'center',
    padding: '2.4rem 4.8rem',
    width: '100%',
    backgroundColor: Colors.PRIMARY_25,
    flex: '0 4 auto',
    [theme.breakpoints.down('mobile')]: {
        padding: '1.6rem',
    }
}));



const RightContainer = styled(({ hide, ...props }: { hide: boolean }) => <Stack {...props} />)<{ hide: boolean }>(({ theme, hide }) => ({
    alignSelf: 'stretch',
    maxWidth: '55rem', //maxWidth
    padding: '4.8rem',
    flex: '0 1 48rem',
    gap: '4.8rem',
    backgroundColor: Colors.PRIMARY_100,
    [theme.breakpoints.down('mobile')]: {
        maxWidth: 'initial',
        minHeight: '50rem',
        padding: '1.6rem',
        display: hide ? 'none' : 'flex',
    }
}));
export default PortalContainer;