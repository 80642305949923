import React from "react";
import {
  Typography,
} from "@mui/material";
import DeededSpace from "v2/components/DeededSpace";
import useTypedSelector from "utils/hooks/useTypedSelector";
import {CalendarAppointment} from "types/calendarAppointmentType";
import SigningAppointmentAccordion from "./SigningAppointmentAccordion";

const CalendarSigningAppointment = () => {
  const selectedAppointment = useTypedSelector(
    (state) => state.CalendarReducer.selectedAppointment,
  );
  const dealFullInfo = useTypedSelector(
    (state) => state.CalendarReducer.dealFullInfo,
  );
  const selectedAppointmentInfo = dealFullInfo?.appointment.filter(
    (appointment) =>  appointment.calendar_id === selectedAppointment?.calendar_id
  )[0];

  return (
    <>
      {selectedAppointmentInfo && <SigningAppointmentAccordion
        appointment={selectedAppointmentInfo as CalendarAppointment | undefined}
        defaultExpanded={true}
      />}
      <Typography
        sx={{
          fontFamily: "Montserrat",
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: "16px",
          lineHeight: "20px",
          marginLeft: "16px",
          marginTop: "24px",
        }}
      >
        Other appointments
      </Typography>
      <DeededSpace y={12} />
      {dealFullInfo?.appointment
        .filter((appointment) => appointment.calendar_id !== selectedAppointment?.calendar_id)
        .map((otherAppointment) => {
          return (
            <>
              <SigningAppointmentAccordion
                appointment={
                  otherAppointment as CalendarAppointment | undefined
                }
              />
              <DeededSpace y={8} />
            </>
          );
        })}
    </>
  );
};

export default CalendarSigningAppointment;
