import { useTheme, styled } from '@mui/material/styles';
import Box from "@mui/material/Box";
import { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import DealsListHeader from "components/Dashboard/DealsList/components/DealsListHeader";
import { quoteSurveyDataInterface, SurveyQuote } from "./SurveyQuote";
import { getQuote } from "api";
import { useSnackbar } from "notistack";
import DeededButton from "v2/components/DeededButton";
import configuration from "../../../utils/configuration";
import { QuoteTypes } from 'types/quote';

const map = (surveyData: Partial<quoteSurveyDataInterface>, referral: string | null) => {
  const {
    dealType, 
    province, 
    sellingPrice,
    purchasePrice,
    mortgageAmount,
    mortgageValue,
    isFirstTimeHomeBuyer,
    hasMortgage,
  } = surveyData;
  
  const { TitleTrn, ILA, Mortgage } = QuoteTypes;
  let deal_type = dealType, deal_sub_type;
  if (dealType === TitleTrn || dealType === ILA) {
      deal_type = Mortgage;
      deal_sub_type = dealType;
  }
  return {
    deal_type,
    deal_sub_type,
    province: province,
    mortgage: mortgageAmount || mortgageValue || 0,
    price: sellingPrice || purchasePrice,
    isFirstTimeBuyer: isFirstTimeHomeBuyer,
    hasMortgage: hasMortgage,
  };
}

const Quote = () => {
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [quoteData, setQuoteData] = useState({
    transactionType: null,
    costs: {
      standard: 0,
      additional: [],
      other: [],
    },
  });

  const resetQuote = () => {
    setQuoteData({
      transactionType: null,
      costs: {
        standard: 0,
        additional: [],
        other: [],
      },
    });
  }

  const submitForm = (referral: string | null, enqueueSnackbar: any) =>
  (surveyData: Partial<quoteSurveyDataInterface>) => {
    const json = map(surveyData, referral);

    getQuote(json.deal_type as string, surveyData.province as string, json).then((response) => {
      setQuoteData(response.data);

      enqueueSnackbar(`Success`, {
        variant: "success",
      });
    }).catch((e) => {
      enqueueSnackbar(`
      There was an error submitting the form. 
      Please try again. If the problem continues, contact us`, {
        variant: "error",
        autoHideDuration: configuration.autoHideErrorDuration,
      });
    });
  };

  return (
    <QuotePage>
      <DealsListHeader title="Quote" />

      { quoteData.costs.standard ? (
        <Box sx={{
          margin: "8rem",
          [theme.breakpoints.down("sm")]: {
            margin: "1rem", 
          },
        }}>
          <Box sx={{
            marginBottom: "4rem",
            padding: "4rem",
            background: "white",
            maxWidth: "600px"
          }}>
            <h2 className={"uppercase"} data-testid="quote-standard-fee">FEES FOR A STANDARD { (quoteData.transactionType || '').replace('_', ' ') } { quoteData.costs.standard }</h2>

            {quoteData.costs?.other?.length && (
              <h3>Other closing costs</h3>
            )}
            {quoteData.costs?.other?.map(item => (
              <div data-testid="other-costs-value">{item.name}: {item.cost}</div>
            ))}
          </Box>
          <DeededButton onClick={resetQuote}>
            Reset
          </DeededButton>
        </Box>
      ) : (<Box
        sx={{
          display: "flex",
          flexFlow: "row wrap-reverse",
        }}>
          
        <Box
          className={"quote_dashboard"}
          sx={{
            [theme.breakpoints.up("xs")]: {
              width: "calc(100vw - 2rem)",
              margin: "1rem"
            },
            [theme.breakpoints.up("md")]: {
              width: "900px", 
            },
          }}>
          <SurveyQuote submitForm={submitForm(query.get('referral'), enqueueSnackbar)} />
        </Box>
      </Box>)}
    </QuotePage >
  );
};

const QuotePage = styled(Box)(({theme}) => ({
  background: "#F7FAFE",
  minHeight: "calc(100vh - 60px)",
  padding: "23px 22px 17px 22px",
  [theme.breakpoints.down(814)]: {
      padding: "23px 22px 86px 22px",
  },
}));

export default Quote;
