import { useMemo, FC } from "react";
import { UserParticipant } from "types/user";
import { isClient } from "utils/permissionRoles";
import DealParticipantCard from "./DealParticipantCard";
import DeededCircularLoader from "v2/components/DeededCircularLoader";
import { Deal } from "types/deal";

type DealDetailsCardsSectionV2Props = {
  handleUnassignTeamMember: (member: unknown) => void;
  filterClients: boolean;
  deal?: Deal
};

const DealDetailsCardsSectionV2: FC<DealDetailsCardsSectionV2Props> = ({
  handleUnassignTeamMember,
  filterClients,
  deal
}) => {

  const allUserParticipans = useMemo(() => {
    if (!deal) {
      return null;
    }
    const { referringProfessional, participants } = deal;

    return [
      ...participants
        .filter((el) => el.is_referrer === 0)
        .filter(el => filterClients ? !isClient(el?.role): true),
      ...referringProfessional.map((x) => ({
        ...x,
        isReferringProfessional: true,
      })),
    ];
  }, [deal]);

  if (allUserParticipans === null) {
    return <DeededCircularLoader />;
  }

  return (
    <div className="dealdetails__contacts">
      {allUserParticipans.map((participant) => (
        <DealParticipantCard
          participant={
            participant as UserParticipant & {
              isReferringProfessional?: boolean;
            } & { middle_name?: string } & { deleted_at: string | null }
          }
          key={participant.id}
          handleUnassignTeamMember={handleUnassignTeamMember}
        />
      ))}
    </div>
  );
};

export default DealDetailsCardsSectionV2;
