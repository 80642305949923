import React from "react";
import {Box, useTheme, Theme} from "@mui/material";
import {styled} from "@mui/material";
import useTypedSelector from "utils/hooks/useTypedSelector";
import {NavLink} from "react-router-dom";
import DeededButton from "v2/components/DeededButton";

interface DealInfoButtonsProps {
  setShowDealPreview: (showDealPreview: boolean) => void;
}

const DealInfoButtons: React.FC<DealInfoButtonsProps> = ({
  setShowDealPreview,
}) => {
  const theme = useTheme();
  const dealFullInfo = useTypedSelector(
    (state) => state.CalendarReducer.dealFullInfo,
  );
  return (
    <>
      <Box
        sx={{
          backgroundColor: "white",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          width: "492px",
          height: "69px",
          borderTop: "1px solid #E0E0E0",
          zIndex: 1,
          [theme.breakpoints.down("sm")]: {
            width: "100%",
          },
        }}
      >
        <ButtonBox>
          <DeededButton
            data-testid="close-button"
            onClick={() => {
              setShowDealPreview(false);
            }}
            kind="secondary"
            sx={{
              marginRight: "16px",
              width: "100px !important",
              [theme.breakpoints.down("sm")]: {
                marginLeft: "22px",
              },
            }}
          >
            Close
          </DeededButton>
          <ViewDealDetailsLink to={`/dashboard/deals/${dealFullInfo?.id}`}>
            <DeededButton data-testid="view-deal-details-button">View Deal Details</DeededButton>
          </ViewDealDetailsLink>
        </ButtonBox>
      </Box>
    </>
  );
};
const ViewDealDetailsLink = styled(NavLink)<{theme?: Theme}>(({theme}) => ({
  color: "white",
  textDecoration: "none",
  [theme.breakpoints.down("sm")]: {
    width: "150px",
  },
}));
const ButtonBox = styled(Box)<{theme?: Theme}>(({theme}) => ({
  marginRight: "22px",
  [theme.breakpoints.down("sm")]: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
}));
export default DealInfoButtons;
