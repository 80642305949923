import {SVGProps} from "react";
import constants from "styles/constants";

const ArrowsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.519 9.347h4.5v-4.5"
      stroke={constants.colors.red}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.169 6.169a8.24 8.24 0 0 1 11.662 0l3.188 3.178M7.481 14.653h-4.5v4.5"
      stroke={constants.colors.red}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.831 17.831a8.24 8.24 0 0 1-11.662 0L2.98 14.653"
      stroke={constants.colors.red}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ArrowsIcon;
