import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import DealStages from "v2/components/DealStages/DealStages";
import ConfigDealNumbers from "components/Dashboard/Config/components/ConfigDealNumbers";
import DownloadReportsPopup from "components/Dashboard/Config/components/DownloadReportsPopup";
import ConfigNotifications from "components/Dashboard/Config/components/ConfigNotifications";
import UserManager from "components/Dashboard/Config/components/UserManager/UserManager";
import { downloadReportsTC } from "redux/reducers/configReducer/emailTemplateReducer";
import { getShortcodesTC } from "redux/reducers/configReducer/configNotificationsReducer";
import constants from "styles/constants";
import resetStorage from "utils/resetStorage";
import useUser from "utils/hooks/useUser";
import ConfigTab from "./components/ConfigTab";
import { phoneConfig } from "./constants/breakpoints";

const Config = () => {
  resetStorage();
  const dispatch = useDispatch();

  const [value, setValue] = useState(1);
  const [currIndex, setCurrIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [openDownloadRangePopup, setOpenDownloadRangePopup] = useState(false);
  const [isShowDeleted, setIsShowDeleted] = useState(false);

  useEffect(() => {
    dispatch(getShortcodesTC());
  }, [dispatch]);

  const handleExportBtnClick = (e: unknown & {preventDefault: () => void}) => {
    if (currIndex === 2 && !isLoading) {
      setIsLoading(true);
      e.preventDefault();
      const type =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      const filename = `${moment(new Date()).format("MM_DD_YYYY")}.xlsx`;
      dispatch(downloadReportsTC(`/config/export-partners`, type, filename, setIsLoading));
    } else {
      if (!isLoading) {
        setOpenDownloadRangePopup(true);
      }
    }
  };

  const user = useUser().user;
  const role = user.role;

  return (
    <ConfigBox>
      <ConfigWrapper>
        <ConfigHeader data-testid="title">
          Config
        </ConfigHeader>
        <TabsBox>
          <TabList>
            <ConfigTabBox>
              <ConfigTab
                value={1}
                stateVal={value}
                onClick={setValue}
                onDoubleClick={setValue}
                label="User Manager"
              />
              {role === "System Admin" && (
                <>
                  <ConfigTab
                    value={2}
                    stateVal={value}
                    onClick={setValue}
                    label="Transaction Stages"
                  />
                  <ConfigTab
                    value={3}
                    stateVal={value}
                    onClick={setValue}
                    label="Notifications"
                  />
                  <ConfigTab
                    value={4}
                    stateVal={value}
                    onClick={setValue}
                    label="Transaction Numbers"
                  />
                </>
              )}
            </ConfigTabBox>
          </TabList>
        </TabsBox>
        <TabContent>
          {value === 1 && (
            <UserManager
              handleExportBtnClick={handleExportBtnClick}
              setCurrIndex={setCurrIndex}
              isLoading={isLoading}
              isShowDeleted={isShowDeleted}
              setIsShowDeleted={
                setIsShowDeleted as (
                  isShowDeleted?: boolean | undefined,
                ) => void
              }
            />
          )}
          {value === 2 && role === "System Admin" && <DealStages />}
          {value === 3 && role === "System Admin" && <ConfigNotifications />}
          {value === 4 && role === "System Admin" && <ConfigDealNumbers />}
        </TabContent>
        {openDownloadRangePopup && (
          <DownloadReportsPopup
            openDownloadRangePopup={openDownloadRangePopup}
            setOpenDownloadRangePopup={setOpenDownloadRangePopup}
            currIndex={currIndex}
            setIsLoading={setIsLoading}
          />
        )}
      </ConfigWrapper>
    </ConfigBox>
  );
};

const ConfigBox = styled(Box)({
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
});
const ConfigWrapper = styled(Box)(({theme}) => ({
  flex: 1,
  background: constants.colors.bg,
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down("sm")]: {
    paddingBottom: "7rem",
  },
}));
const ConfigHeader = styled(Typography)(
  ({theme}) => ({
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "3.2rem",
    lineHeight: "3.9rem",
    margin: "2.6rem 0 24px",
    marginLeft: "2rem",
    textTransform: "uppercase",
    color: constants.colors.intakeTitle,
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      margin: "3rem 0",
      marginLeft: "2rem",
      fontSize: "2.2rem",
      display: "none",
    },
  }),
);
const TabsBox = styled(Box)({
  display: "flex",
  alignItems: "flex-start",
  flexWrap: "wrap",
  backgroundColor: constants.colors.bg,
  padding: "0 2rem",
});
const TabList = styled("ol")(({theme}) => ({
  borderBottom: "0.2rem solid rgba(0, 0, 0, 0.03)",
  paddingLeft: "0",
  margin: "0 0 1.5rem 1rem",
  flexWrap: "wrap",
  [theme.breakpoints.down("sm")]: {
    display: "flex",
    margin: "16px 16px 24px",
    maxWidth: "93%",
  },
}));
const TabContent = styled(Box)(({theme}) => ({
  padding: "0 3rem",
  paddingBottom: "15rem",
  background: constants.colors.bg,
  flex: 1,
  [theme.breakpoints.down("sm")]: {
    paddingBottom: "24px",
  },
}));
const ConfigTabBox = styled(Box)(({theme}) => ({
  whiteSpace: "nowrap",
  [theme.breakpoints.down("sm")]: {
    display: "flex",
    overflowX: "auto",
    overflowY: "hidden",
    scrollbarWidth: "none",
    paddingBottom: "7px",
    "&::-webkit-scrollbar": {
      height: "3px",
      scrollbarWidth: "thin",
    },
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "0px",
    },
  },
  "& .tab-list-active": {
    color: `${constants.colors.red} !important`,
    border: `0.3rem solid ${constants.colors.red}`,
    borderWidth: "0px 0px 0.3rem 0px",
    paddingBottom: "5px",
    [theme.breakpoints.down("sm")]: {
      marginRight: "0",
      flexGrow: 1,
      textAlign: "center",
    },
  },
  "& .tab-list-item": {
    display: "inline-block",
    listStyle: "none",
    marginBottom: "-1px",
    marginRight: "6rem",
    padding: "0.5rem 0 5px",
    color: "rgba(38, 38, 38, 0.25)",
    cursor: "pointer",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "1.8rem",
    lineHeight: "2.2rem",
    "&:last-child": {
      marginRight: 0,
    },

    "&:hover": {
      color: constants.colors.red,
    },
    [theme.breakpoints.down("md")]: {
      marginRight: "2rem",
      fontSize: "1.4rem",
    },
    [theme.breakpoints.down("sm")]: {
      whiteSpace: "nowrap",
    },
    [theme.breakpoints.down(phoneConfig)]: {
      marginRight: "0.9rem",
      fontSize: "1rem",
      paddingBottom: "1px",
    },
  },
}));
export default Config;
