import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {useLocation} from "react-router-dom";
import { Box, useTheme } from "@mui/material";
import ActivityLogHeader from "components/Dashboard/Survey/components/DealDetailsSurveyTabHeader";
import DealDetailsSurveyTabItem from "components/Dashboard/Survey/components/DealDetailsSurveyTabItem";
import {getDealIdFromLink} from "utils/getDealIdFromLink";
import useTypedSelector from "utils/hooks/useTypedSelector";
import useWindowWidth from "utils/hooks/useWindowWidth";
import {getSurveyDataTC} from "redux/reducers/dealDetailsSurveyReducer";

const DealDetailsSurveyTabList = () => {
  const location = useLocation();
  const theme = useTheme();
  const dealId = getDealIdFromLink(location.pathname);
  const surveyData = useTypedSelector(
    (state) => state.DealDetailsSurveyReducer.surveyData,
  );
  const windowWidth = useWindowWidth();
  const dispatch = useDispatch();
  const isLargeScreen = windowWidth >= theme.breakpoints.values.lg;
  useEffect(() => {
    dispatch(getSurveyDataTC(dealId));
  }, [dealId, dispatch]);
  return (
    <>
      {isLargeScreen && <ActivityLogHeader />}
      {surveyData?.map((item, idx) => {
        const {full_name} = item;
        return (
          <Box id={idx as unknown as string | undefined}>
            <DealDetailsSurveyTabItem
              key={idx}
              fullName={full_name}
              whatKeepGoing={item.survey_submission.survey_answers[0].text}
              whatCouldDifferent={item.survey_submission.survey_answers[1].text}
              whatWouldSay={item.survey_submission.survey_answers[2].text}
              rating={item.survey_submission.rate}
            />
          </Box>
        );
      })}
    </>
  );
};

export default DealDetailsSurveyTabList;
