import React, {SyntheticEvent, useContext, useState} from "react";
import {
  FileTasksFiled,
  setFileTaskValue,
} from "redux/reducers/fileTasksSlice";
import {useDispatch} from "react-redux";
import {Box, styled, Typography, useTheme} from "@mui/material";
import constants from "styles/constants";
import DeededTextArea from "v2/components/DeededTextArea";
import DeleteIcon from "components/Svg/DeleteIcon";
import DeededCheckbox from "v2/components/DeededCheckbox";
import {DealDetailsFileTasksContext} from "components/Dashboard/DealDetailsFileTasks/context/DealDetailsFileTasksContext";
import {
  useDeleteCustomTaskMutation,
  useUpdateNameForCustomTaskMutation,
  useUpdateTaskValueMutation,
} from "redux/reducers/dealInfoApi";

const DealDetailsTextAreaStageField: React.FC<{
  field: FileTasksFiled;
  fieldIndex: number;
}> = ({field, fieldIndex}) => {
  const dispatch = useDispatch();
  const [taskName, setTaskName] = useState(field.name);
  const [updateTaskValueMutationCallback] = useUpdateTaskValueMutation();
  const [deleteCustomTaskCallback] = useDeleteCustomTaskMutation();
  const [updateNameForCustomTaskCallback] =
    useUpdateNameForCustomTaskMutation();
  const {dealId, selectedFileType} = useContext(DealDetailsFileTasksContext);
  const [isOnFocus, setIsOnFocus] = useState(false);
  const isOverfilledTextArea = taskName.length >= 200;
  const isDisplayedError = isOverfilledTextArea && isOnFocus;
  const theme = useTheme();
  const onDelete = () => {
    deleteCustomTaskCallback({dealId, fileTaskId: field.id});
  };
  const handleSetTaskName = (text: string) => {
    if (text.length <= 200) {
      setTaskName(text);
    }
  };
  const handleBlur = (e: {target: {value: string}}) => {
    setIsOnFocus(false);
    updateNameForCustomTaskCallback({
      dealId,
      taskType: selectedFileType,
      taskName: e.target.value,
      fileTaskId: field.id,
    });
  };
  const handleChecked = (e: {target: {checked: boolean}}) => {
    const booleanInNumber = Number(e.target.checked);
    dispatch(
      setFileTaskValue({
        fieldIndex,
        stageNumber: field.stage_number,
        value: booleanInNumber,
      }),
    );
    updateTaskValueMutationCallback({
      dealId,
      fileTaskId: field.id,
      value: booleanInNumber,
    });
  };
  return (
    <>
      <Box
        sx={{
          marginBottom: "24px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            marginLeft: "8px",
          }}
        >
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: "12px",
              color: constants.colors.title,
              marginRight: "8px",
            }}
          >
            Undertaking field
          </Typography>
          <Box>
            <QuestionCircleSvg />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            [theme.breakpoints.down("sm")]: {
              flexDirection: "column",
              alignItems: "flex-start",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "654px",
              marginRight: "65px",
              [theme.breakpoints.down("lg")]: {
                width: "unset",
                flex: 1,
              },
              [theme.breakpoints.down("sm")]: {
                width: "100%",
              },
            }}
          >
            <DeededTextArea
              onFocus={() => setIsOnFocus(true)}
              onBlur={handleBlur}
              sx={{
                "&.MuiBox-root .MuiFormControl-root .MuiOutlinedInput-root .MuiOutlinedInput-input":
                  {
                    height: "55px !important",
                    overflowY: "scroll !important",
                    paddingRight: "5px",
                    "&::-webkit-scrollbar": {
                      width: "2px",
                    },
                    "&::-webkit-scrollbar-track": {
                      backgroundColor: "transparent",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      borderRadius: "10px",
                    },
                  },
                "& .MuiFormControl-root": {
                  height: "74px",
                  "& .MuiOutlinedInput-root": {
                    height: "74px",
                    minHeight: "unset",
                    textarea: {
                      marginTop: "10px",
                      fontSize: "18px !important",
                    },
                  },
                },
                width: "100%",
                maxWidth: "604px",
                height: "74px",

                [theme.breakpoints.down("lg")]: {
                  maxWidth: "unset",
                },
              }}
              text={taskName}
              setText={handleSetTaskName}
              error={isDisplayedError ? "Max 200 characters" : ""}
            />
            <DeleteBox onClick={onDelete}>
              <DeleteIcon />
            </DeleteBox>
          </Box>
          <Box
            sx={{
              "& .MuiFormControlLabel-root ": {
                "& .MuiTypography-root": {
                  fontSize: "16px",
                  fontWeight: "600",
                  color: constants.colors.title,
                },
                "& .MuiCheckbox-root": {
                  minWidth: "20px",
                  minHeight: "20px",
                  padding: "0px",
                  marginRight: "16px",
                },
              },
              marginTop: "-4px",
              [theme.breakpoints.down("sm")]: {
                marginTop: isDisplayedError ? "25px" : "10px",
              },
            }}
          >
            <DeededCheckbox
              onChange={
                handleChecked as unknown as (
                  event: SyntheticEvent<Element, Event>,
                  checked: boolean,
                ) => void
              }
              checked={!!+field.value}
              label={"RESOLVED"}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

const QuestionCircleSvg = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z"
        stroke="#2F80ED"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 11.875C8.34518 11.875 8.625 11.5952 8.625 11.25C8.625 10.9048 8.34518 10.625 8 10.625C7.65482 10.625 7.375 10.9048 7.375 11.25C7.375 11.5952 7.65482 11.875 8 11.875Z"
        fill="#2F80ED"
      />
      <path
        d="M8 9V8.5C8.34612 8.5 8.68446 8.39736 8.97225 8.20507C9.26003 8.01278 9.48434 7.73947 9.61679 7.4197C9.74924 7.09993 9.7839 6.74806 9.71637 6.40859C9.64885 6.06913 9.48218 5.75731 9.23744 5.51256C8.9927 5.26782 8.68087 5.10115 8.34141 5.03363C8.00194 4.9661 7.65007 5.00076 7.3303 5.13321C7.01053 5.26566 6.73722 5.48997 6.54493 5.77775C6.35264 6.06554 6.25 6.40388 6.25 6.75"
        stroke="#2F80ED"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
const DeleteBox = styled(Box)(({theme}) => ({
  marginLeft: "16px",

  cursor: "pointer",
  svg: {
    stroke: constants.colors.deededGray,
    transition: constants.transitions,
    "&:hover": {
      stroke: constants.colors.red,
      transition: constants.transitions,
    },
  },
}));
export default DealDetailsTextAreaStageField;
