import {useEffect, useState} from "react";
import {Box, styled, useTheme} from "@mui/material";
import DeededSearch from "v2/components/DeededSearch";
import DeededButton from "v2/components/DeededButton";
import resetStorage from "utils/resetStorage";
import DealsListBase from "./components/DealsListBase";
import DealsListHeader from "./components/DealsListHeader";

const LeadsList = () => {
  const [search, setSearch] = useState<string | undefined>(undefined);
  const [searchVal, setSearchVal] = useState<string | undefined>(undefined);
  resetStorage();

  useEffect(() => {
    if (search !== undefined) {
      setSearchVal(search);
    }
  }, [search]);

  const handleSearch = (val: string) => {
    setSearch(val);
  };

  const theme = useTheme();

  return (
    <LeadsPage>
      <DealsListHeader title="Leads" />

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          [theme.breakpoints.down("sm")]: {
            marginBottom: "15px",
            justifyContent: "space-between",
          },
        }}
      >
        <DeededSearch
          onChange={
            handleSearch as unknown as ((val: string) => void) &
              React.FormEventHandler<HTMLDivElement>
          }
          placeholder="Search transactions..."
          sx={{
            maxWidth: 567,
            width: 380,
            [theme.breakpoints.down(400)]: {
              width: "70%",
              minWidth: "70%",
            },
          }}
          value={search}
        />
      </Box>
      <DealsListBase
        filterProps={{archive: false, showClosed: false, newDeals: false}}
        status={false}
        searchVal={searchVal}
      />
    </LeadsPage>
  );
};

const LeadsPage = styled(Box)(({theme}) => ({
  background: "#F7FAFE",
  minHeight: "calc(100vh - 60px)",
  padding: "23px 22px 17px 22px",
  [theme.breakpoints.down(814)]: {
    padding: "23px 22px 86px 22px",
  },
}));

export default LeadsList;
