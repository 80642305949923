import React, {ChangeEvent, FormEvent, ReactEventHandler} from "react";
import {useDispatch} from "react-redux";
import {useFormik} from "formik";
import moment from "moment";
import {SwipeableDrawerProps} from "@mui/material/SwipeableDrawer";
import {Theme} from "@mui/material/styles";
import {
  Box,
  IconButton,
  MenuItem,
  SelectChangeEvent,
  styled,
  SwipeableDrawer,
  Typography,
  useTheme,
} from "@mui/material";
import CalendarHeaderSettingsCloseSvg from "../../svg/CalendarHeaderSettingsCloseSvg";
import constants from "styles/constants";
import {
  setCalendarTimezoneAC, setIsGettingEvents,
  setSelectedStaffNameAC,
  setSelectedStaffUserAC,
} from "redux/reducers/calendarReducer";
import {useAppDispatch, useAppSelector} from "redux/hooks";
import {StaffUser} from "redux/reducers/configReducer/staffCardReducer";
import DeededProvinceButton from "v2/components/DeededProvinceButton";
import DeededButton from "v2/components/DeededButton";
import DeededSelect from "v2/components/DeededSelect";
import DeededAutocompleteSelect from "v2/components/DeededAutocompleteSelect";
import useTypedSelector from "utils/hooks/useTypedSelector";
import useStaff from "utils/hooks/useStaff";
import useWindowWidth from "utils/hooks/useWindowWidth";
import {allDealTypes} from "../../constants/allDealTypes";
import {storeCalendarSetting} from "../../utils/storeCalendarSetting";

const CalendarMobileSettingsDrawer: React.FC<
  SwipeableDrawerProps & {
    setIsShowedCalendarSettings: (open: boolean) => void;
    allProvincesList: {label: string; name: string}[];
    regions: string | string[];
    isGettingEvents: boolean;
    setRegions: (regions: string | string[]) => void;
    dealTypeState: string | string[];
    setDealTypeState: (value: string) => void;
  }
> = ({
  setIsShowedCalendarSettings,
  allProvincesList,
  regions,
  isGettingEvents,
  setRegions,
  dealTypeState,
  setDealTypeState,
  ...props
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const selectedStaffName = useTypedSelector(
    (state) => state.CalendarReducer.selectedStaffName,
  );
  const selectedStaffUserRedux = useAppSelector(
    (state) => state.CalendarReducer.selectedStaffUser,
  );
  const calendarTimezone = useAppSelector(
    (state) => state.CalendarReducer.calendarTimezone,
  );
  const initialValues = {
    localRegions: regions,
    localDealTypeState: dealTypeState,
    localSelectedStaffUser: selectedStaffUserRedux,
    localSelectedStaffName: selectedStaffName,
    localCalendarTimezone: calendarTimezone,
  };
  const onSubmit = (submitValues: typeof initialValues) => {
    setRegions(submitValues.localRegions);
    setDealTypeState(submitValues.localDealTypeState as string);
    dispatch(
      setSelectedStaffUserAC(submitValues.localSelectedStaffUser as StaffUser),
    );
    dispatch(setSelectedStaffNameAC(submitValues.localSelectedStaffName));
    storeCalendarSetting(
      "selectedStaffUser",
      submitValues.localSelectedStaffUser,
    );
    dispatch(setCalendarTimezoneAC(submitValues.localCalendarTimezone))
    dispatch(setIsGettingEvents(true));
    setIsShowedCalendarSettings(false)
  };
  const {handleChange, values, handleSubmit} = useFormik({
    initialValues,
    onSubmit,
  });

  return (
    <>
      {/*@ts-ignore*/}
      <SwipeableDrawer
        sx={{
          "& .MuiPaper-root": {
            [theme.breakpoints.down("sm")]: {
              width: "100% !important",
            },
            "&::-webkit-scrollbar": {
              width: 0,
            },
          },
        }}
        {...props}
      >
        <Box
          sx={{
            padding: "40px 20px",
          }}
        >
          <TitleBox>
            <TitleTypography>Settings</TitleTypography>
            <CloseIconButton
              onClick={() => {
                setIsShowedCalendarSettings(false);
              }}
            >
              <CalendarHeaderSettingsCloseSvg />
            </CloseIconButton>
          </TitleBox>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(values as unknown as FormEvent<HTMLFormElement>);
            }}
          >
            <CalendarMobileSettingsDrawerRegions
              handleChange={handleChange}
              localRegions={values.localRegions}
              allProvincesList={allProvincesList}
              isGettingEvents={isGettingEvents}
            />
            <CalendarMobileHeaderFields
              localSelectedStaffUser={values.localSelectedStaffUser}
              localCalendarTimezone={values.localCalendarTimezone}
              localSelectedStaffName={values.localSelectedStaffName}
              localDealTypeState={values.localDealTypeState}
              handleChange={handleChange}
            />
            <DeededButton
              type={"submit"}
              sx={{
                width: "100%",
                height: "34px",
                marginTop: "32px",
              }}
            >
              Apply
            </DeededButton>
          </Form>
        </Box>
      </SwipeableDrawer>
    </>
  );
};
const CalendarMobileHeaderFields: React.FC<{
  localSelectedStaffUser: StaffUser | null
  localCalendarTimezone:string,
  localDealTypeState: string | string[];
  localSelectedStaffName:string
  handleChange: (changeItem: {
    target: {
      name: string;
      value: string|StaffUser|null|undefined|unknown;
    };
  }) => void;
}> = ({handleChange,localCalendarTimezone, localDealTypeState,localSelectedStaffName,localSelectedStaffUser}) => {
  const calendarType = useTypedSelector(
    (state) => state.CalendarReducer.calendarType,
  );
  const windowWidth = useWindowWidth();
  const calendarTimezone = useAppSelector(
    (state) => state.CalendarReducer.calendarTimezone,
  );
  const staff = useStaff();
  const mobileSignersList = staff?.["Mobile Signer"];
  const lawClerksAndLawyersList = staff?.["Lawyer"].concat(
    staff?.["Law Clerk"],
  );
  const mobileSignersNamesList = mobileSignersList?.map(
    (signer) => signer.full_name,
  ) as string[] | null;
  const lawClerksAndLawyersNamesList = lawClerksAndLawyersList?.map(
    (employee) => employee.full_name,
  ) as string[] | null;
  const dispatch = useDispatch();
  const handleChangeDealType = (e: unknown) => {
    handleChange({
      target: {
        name: "localDealTypeState",
        value: e,
      },
    });
  };
  const handleClearUserSelect = () => {
    handleChange({target:{
      name:"localSelectedStaffName",
        value:""
      }})
    handleChange({target:{
        name:"localSelectedStaffUser",
        value:null
      }})
  };
  const handleChangeStaff = (option: string) => {
    let selectedStaffUser;
    if (calendarType === "Signing Appointment") {
      selectedStaffUser = mobileSignersList?.filter(
        (signer) => signer.full_name === option,
      )?.[0];
    } else {
      selectedStaffUser = lawClerksAndLawyersList?.filter(
        (employee) => employee.full_name === option,
      )?.[0];
    }
    handleChange({
      target: {
        name: "localSelectedStaffUser",
        value: selectedStaffUser,
      },
    });
  };
  return (
    <>
      <Box
        sx={{
          marginTop: "26px",
        }}
      >
      <SelectWrapper
        sx={{
          width: "100%",
          marginTop: "0px",
        }}
      >
        <SelectLabel>Deal type</SelectLabel>
        <SelectStaffOrDealType
          width="100%"
          onChange={(e?: SelectChangeEvent<unknown> | undefined) => {
            handleChangeDealType(e!.target.value);
          }}
          value={localDealTypeState}
          sx={{
            width: "100%",
          }}
        >
          <CustomMenuItem value="ALL">All</CustomMenuItem>
          {allDealTypes.map((dealType) => (
            <CustomMenuItem key={dealType} value={dealType}>{dealType}</CustomMenuItem>
          ))}
        </SelectStaffOrDealType>
      </SelectWrapper>
      <SelectWrapper>
        <SelectLabel>
          {calendarType === "Signing Appointment"
            ? "Mobile Signer"
            : "Lawyer / Law Clerk"}
        </SelectLabel>

        {calendarType === "Signing Appointment" ? (
          <SelectPerson
            showCloseIcon={
              localSelectedStaffUser?.full_name === localSelectedStaffName
            }
            onCloseIconClick={handleClearUserSelect}
            straightawaySearch
            showListDefault
            onSelect={
              handleChangeStaff as ((option: string) => void) &
                ReactEventHandler<HTMLDivElement>
            }
            disabled={!staff}
            onChangeValue={(e) => {
              handleChange({
                target:{
                  name:"localSelectedStaffName",
                  value:e as string
                }
              })
            }}
            options={mobileSignersNamesList}
            value={localSelectedStaffName}
            placeholder="All"
          />
        ) : (
          <SelectPerson
            showCloseIcon={
              localSelectedStaffUser?.full_name === localSelectedStaffName
            }
            onCloseIconClick={handleClearUserSelect}
            straightawaySearch
            showListDefault
            onSelect={
              handleChangeStaff as ((option: string) => void) &
                ReactEventHandler<HTMLDivElement>
            }
            disabled={!staff}
            onChangeValue={(e) => {
              handleChange({
                target:{
                  name:"localSelectedStaffName",
                  value:e as unknown
                }
              })
            }}
            options={lawClerksAndLawyersNamesList}
            value={localSelectedStaffName}
            placeholder="All"
          />
        )}
      </SelectWrapper>

      <SelectWrapper>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <SelectLabel>Timezone</SelectLabel>
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: "10px",
              color: constants.colors.deededGray,
              marginTop: "1px",
            }}
          >
            {moment().tz(calendarTimezone)?.format("HH:mm")} (UTC{" "}
            {moment().tz(calendarTimezone)?.utcOffset() / 60})
          </Typography>
        </Box>
        <CalendarMobileHeaderProvinceSelect
          handleChange={handleChange}
          localCalendarTimezone={localCalendarTimezone}
        />
      </SelectWrapper>
      </Box>
    </>
  );
};
const CalendarMobileHeaderProvinceSelect:React.FC<{
  localCalendarTimezone:string,
  handleChange: (changeItem: {
    target: {
      name: string;
      value: string|StaffUser|null|undefined|unknown;
    };
  }) => void;
}> = ({localCalendarTimezone,handleChange}) => {
  return (
    <>
      <SelectTimezone
        width={"100%"}
        data-selenium-id={"calendar-timezone-select"}
        onChange={(e) => {
          handleChange({
            target:{
              name: "localCalendarTimezone",
              value: e!.target.value
            }
          })
        }}
        value={localCalendarTimezone}
        sx={{width: "100%"}}
      >
        <MenuItem value={"America/Edmonton"}>Alberta (AB)</MenuItem>
        <MenuItem value={"America/Vancouver"}>British Columbia (BC)</MenuItem>
        <MenuItem value={"America/Winnipeg"}>Manitoba (MB)</MenuItem>
        <MenuItem value={"America/Moncton"}>New Brunswick (NB)</MenuItem>
        <MenuItem value={"America/Goose_Bay"}>
          Newfoundland & Labrador (NL)
        </MenuItem>
        <MenuItem value={"America/Yellowknife"}>
          Northwest Territories (NT)
        </MenuItem>
        <MenuItem value={"America/Halifax"}>Nova Scotia (NS)</MenuItem>
        <MenuItem value={"America/Resolute"}>Nunavut (NU)</MenuItem>
        <MenuItem value={"America/Toronto"}>Ontario (ON)</MenuItem>
        <MenuItem value={"America/Halifax"}>Prince Edward Island (PE)</MenuItem>
        <MenuItem value={"America/Montreal"}>Quebec (QC)</MenuItem>
        <MenuItem value={"America/Regina"}>Saskatchewan (SK)</MenuItem>
        <MenuItem value={"America/Whitehorse"}>Yukon(YT)</MenuItem>
      </SelectTimezone>
    </>
  );
};
const CalendarMobileSettingsDrawerRegions: React.FC<{
  handleChange: (changeItem: {
    target: {
      name: string;
      value: unknown;
    };
  }) => void;
  localRegions: string | string[];
  allProvincesList: {label: string; name: string}[];
  isGettingEvents: boolean;
}> = ({handleChange, localRegions, allProvincesList, isGettingEvents}) => {
  const handleChangeRegions = (e: ChangeEvent<HTMLInputElement>) => {
    const {value, checked} = e.target as HTMLInputElement;
    let newRegions;
    if (checked) {
      newRegions = [...(localRegions as string[]), value];
    } else {
      newRegions = (localRegions as string[]).filter((el) => el !== value);
    }
    handleChange({
      target: {
        name: "localRegions",
        value: newRegions,
      },
    });
  };
  return (
    <>
      <RegionsBox>
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: "500",
            color: constants.colors.intakeTitle,
          }}
        >
          Select region (s):
        </Typography>
        <Box
          sx={{
            display: "flex",
            marginTop: "16px",
          }}
        >
          {allProvincesList.map(
            ({label, name}: {label: string; name: string}) => {
              let fill: string;
              switch (label) {
                case "ON":
                  fill = constants.provinceColors.ON2;
                  break;
                case "AB":
                  fill = constants.provinceColors.AB;
                  break;
                case "BC":
                  fill = constants.provinceColors.BC;
                  break;
                default:
                  fill = constants.provinceColors.Other;
              }
              return (
                <DeededProvinceButton
                  data-selenium-id={`province-${label}`}
                  sx={{
                    marginRight: "12px",
                    width: "53px",
                    height: "30px",
                  }}
                  key={label}
                  title={label === "OTHER" ? "RP" : label}
                  bgColor={fill}
                  checked={localRegions.includes(label)}
                  onClick={() => {
                    if (!isGettingEvents) {
                      handleChangeRegions({
                        target: {
                          value: label,
                          checked: !localRegions.includes(label),
                        },
                      } as unknown as ChangeEvent<HTMLInputElement>);
                    }
                  }}
                />
              );
            },
          )}
        </Box>
      </RegionsBox>
    </>
  );
};
const SelectTimezone = styled(DeededSelect)({
  height: "36px !important",
  overflow: "hidden",
  "&.MuiOutlinedInput-root": {
    background: constants.colors.white,
  },
  "& .MuiSelect-select": {
    textOverflow: "unset",
    overflow: "hidden",
    whiteSpace: "nowrap",
    //I use this border to hide overflowing text because overflow:hidden doesn't work there
    borderRight: "40px solid transparent",
  },
});
const SelectStaffOrDealType = styled(DeededSelect)({
  "&.MuiOutlinedInput-root": {
    background: constants.colors.white,
  },
  height: "36px !important",
});
const CustomMenuItem = styled(MenuItem)({
  fontSize: "14px",
});
const SelectPerson = styled(DeededAutocompleteSelect)({
  "& .svgBox": {
    top: "13px",
  },
  " .isOpenList": {
    top: "13px",
  },
  "& .MuiPaper-root": {
    zIndex: "5",
  },
  "& .MuiBox-root": {
    "& .MuiFormControl-root": {
      "& .MuiOutlinedInput-root": {
        height: "36px",
        "& .MuiOutlinedInput-input": {
          "&::placeholder": {
            fontFamily: "'Montserrat'",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "15px",
            textColor: constants.colors.grayTag,
            opacity: 1,
          },
        },
      },
    },
  },
});
const SelectLabel = styled(Typography)({
  color: "#4F4F4F",
  fontSize: "12px",
  fontWeight: "500",
  marginLeft: "5px",
  marginBottom: "4px",
});

const SelectWrapper = styled(Box)<{theme?: Theme}>(({theme}) => ({
  marginTop: "16px",
  width: "100%",
  "& .MuiOutlinedInput-root": {
    color: "black",
    fontSize: "1.4rem",
    height: "44px",
    display: "flex",
    alignItems: "center",
    background: "white",
  },
  "& .MuiAutocomplete-endAdornment": {
    display: "none",
  },
}));
const Form = styled("form")({});
const TitleBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});
const RegionsBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
});
const CloseIconButton = styled(IconButton)({
  minWidth: 0,
});
const TitleTypography = styled(Typography)({
  fontSize: "24px",
  fontWeight: "600",
  color: constants.colors.intakeTitle,
});
export default CalendarMobileSettingsDrawer;
