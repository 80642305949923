import {useState, useEffect, useRef, FC} from "react";
import { useLocation } from 'react-router-dom';
import { parse } from "date-fns";
import { Box, styled } from "@mui/material";
import { Card, Comment } from "./components";
import constants from "styles/constants";
import { Body, H3 } from "components/Common/Typography";
import BasicTextInput from "components/Common/TextField/BasicTextInput";
import Checkbox from "components/Common/Checkbox";
import BasicButton from "components/Common/Button/BasicButton";
import useUser from "utils/hooks/useUser";


interface CommentSectionProps {
    comments: Array<any>,
    submitComment: (text: string, sendNotification: boolean) => void,
    isLoading: boolean
}

const DEFAULT_AUTHOR = 'System';
const ANCHOR_SECTION = '#comments';

export const CommentSection: FC<CommentSectionProps> = ({ comments, submitComment, isLoading }) => {
    const location = useLocation();
    const {isProfessional} = useUser();
    const chatContainer = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!chatContainer?.current) return;

        if (location.hash === ANCHOR_SECTION) {
            chatContainer.current.scrollIntoView({ behavior: 'auto', block: 'center' });
        }

        if (comments.length > 0) {
            chatContainer.current.scrollTop = chatContainer.current.scrollHeight;
        }
    }, [comments]);
    return <>
        <Card>
            {/* Temporary styling to match the styling of the legacy section titles. */}
            <H3 sx={{
                fontWeight: 600,
                fontSize: '1.8rem',
                lineHeight: '2.2rem'
            }}>Comments</H3>
            <Wrapper>
                <ChatViewer ref={chatContainer} data-testid="deal-deatils-comments-section">
                    {comments.length > 0 ? comments.map(({ created_at, content, added_by }, idx) =>
                        <Comment
                            index={idx}
                            key={idx}
                            fullName={added_by?.full_name || DEFAULT_AUTHOR}
                            role={added_by?.role || DEFAULT_AUTHOR}
                            date={parse(created_at, "M/d/yyyy HH:mm:ss", new Date())}
                            message={content}
                        />
                    ) :
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '17rem' }}>
                            <Body sx={{ color: constants.colors.gray400 }}>
                                {isLoading ? "Loading..." : "There are no comments"}
                            </Body>
                        </Box>
                    }

                </ChatViewer>
                {!isProfessional &&
                    <InputChat submitMessage={submitComment} />
                }
            </Wrapper>
        </Card >
    </>;
}

const Wrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'stretch',
    gap: '1rem',
    [theme.breakpoints.down('md')]: {
        gap: '2rem',
        flexFlow: 'column',
    }
}));


const ChatViewer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexFlow: 'column',
    flex: 1,
    gap: '2rem',
    overflowY: 'auto',
    maxHeight: '48rem',
    paddingRight: '0.6rem',
    alignSelf: 'flex-end',
    [theme.breakpoints.down('md')]: {
        alignSelf: 'auto',
    }
}));


interface InputChatProps {
    submitMessage: (text: string, sendNotification: boolean) => void,
}

const InputChat = ({ submitMessage }: InputChatProps) => {
    const [newComment, setNewComment] = useState<string>("");
    const [sendNotification, setSendNotification] = useState<boolean>(false);

    return <InputChatContainer >
        <BasicTextInput
            multiline
            maxRows={18}
            minRows={5}
            fullWidth
            onChange={(e) => { setNewComment(e.target.value) }}
            placeholder="Add new comment"
            value={newComment}
            data-testid="add-new-comment-input"
        />
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
        }}>
            <Checkbox
                data-testid="comment-send-notification-checkbox"
                label={"Send notification"}
                checked={sendNotification}
                onChange={() => setSendNotification(!sendNotification)}
            />

            <BasicButton
                disabled={!newComment}
                label={{
                    inputId: 'postCommentId',
                    text: 'Post Comment'
                }}
                onClick={() => {
                    submitMessage(newComment, sendNotification);
                    setNewComment('');
                    setSendNotification(false);
                }}
                size="small"
                typeOf="CTA"
            />
        </Box>
    </InputChatContainer>
}

const InputChatContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'flex-end',
    gap: '2rem',
    width: '45rem',
    paddingLeft: '2rem',
    borderLeft: '0.1rem solid' + constants.colors.gray300,
    [theme.breakpoints.down('md')]: {
        padding: 0,
        borderLeft: 'none',
        width: 'auto',
        borderTop: '0.1rem solid' + constants.colors.gray300,
    }
}));