import { ComponentType } from "react";
import { ProviderContext, useSnackbar } from "notistack";

// Temporary HOC to allow old Class Components to use the new version of snackbar lib
export function withSnackbar<P extends ProviderContext>(WrappedComponent: ComponentType<P>) {
    const displayName = WrappedComponent.displayName || WrappedComponent.name || "Component";

    const ComponentWithContext = (props: Omit<P, keyof ProviderContext>) => {
        const snackProps = useSnackbar();

        // @ts-ignore
        return <WrappedComponent {...snackProps} {...(props as P)} />;
    };

    ComponentWithContext.displayName = `withSnackbar(${displayName})`;

    return ComponentWithContext;
};

export default withSnackbar;
