import {useState} from "react";
import {styled, TableCell, TableHead, TableRow} from "@mui/material";
import DealsListSortingMenuItemTable from "./DealsListSortingMenuItemTable";

interface DealsListSortingMenuProps {
  sortBy: string;
  sortOrder: boolean;
  handleSort: (type: string) => void;
  visibleColumns?: Array<string> | undefined;
}

const DealsListSortingMenuTableHead = ({
  handleSort,
  sortBy,
  sortOrder,
  visibleColumns = []
}: DealsListSortingMenuProps) => {
  const [menuItemState, setMenuItemState] = useState(
    [
      {item: "Type", type: "type"},
      {item: "Province", type: "province"},
      {item: "Client Name", type: "client_name"},
      {item: "Address", type: "street_address"},
      {item: "Signing Appointment", type: "appointment"},
      {item: "Lawyer", type: "lawyer"},
      {item: "Law Clerk", type: "law_clerk"},
      {item: "Created", type: "created_at"},
      {item: "Closing", type: "closing"},
      {item: "Transaction Stage", type: "deal_stage"},
      {item: "Number", type: "number"},
      {item: "Documents", type: "documents"},
    ]
    .sort((a, b) => visibleColumns.indexOf(a.type) - visibleColumns.indexOf(b.type))
    .filter(i => visibleColumns.includes(i.type))
  );

  return (
    <TableHead>
      <SortingMenuContainerRow>
        {menuItemState.map((item) => {
          return (
            <TableCell
              data-testid="my-deals-table-header"
              sx={{
                borderBottom: "none",
              }}
              key={item.type}
            >
              <DealsListSortingMenuItemTable
                sortBy={sortBy}
                sortOrder={sortOrder}
                key={item.type}
                title={item.item}
                type={item.type}
                handleSort={handleSort}
              />
            </TableCell>
          );
        })}
      </SortingMenuContainerRow>
    </TableHead>
  );
};

const SortingMenuContainerRow = styled(TableRow)({});

export default DealsListSortingMenuTableHead;
