import React, {useState} from "react";
import Box from "@mui/material/Box";
import DeededInput from "v2/components/DeededInput";
import constants from "styles/constants";
import {smallDesktopConfigDealNumbers} from "../../constants/breakpoints";

interface ConfigDealNumberInputsProps {
  startNumber?: number;
  prefixNumber?: number;
  onInputUpdate: (obj: {startNumber: number; prefixNumber: number}) => void;
}
export const ConfigDealNumberInputs = ({
  startNumber,
  prefixNumber,
  onInputUpdate,
}: ConfigDealNumberInputsProps) => {
  const [firstInput, setFirstInput] = useState(prefixNumber);
  const [secondInput, setSecondInput] = useState(startNumber);
  const emitChanges = () => {
    onInputUpdate({
      startNumber: Number(secondInput),
      prefixNumber: Number(firstInput),
    });
  };

  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "flex-start",
        [theme.breakpoints.down(smallDesktopConfigDealNumbers)]: {
          flexDirection: "column",
          marginTop: "16px",
        },
      })}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        <DeededInput
          sx={(theme) => ({
            maxWidth: "250px",
            width: "240px",
            height: "36px",
            bottom: "5px",
            flexWrap: "wrap",
            [theme.breakpoints.down(smallDesktopConfigDealNumbers)]: {
              width: "100%",
              maxWidth: "100%",
              height: "90px",
              display: "block",
              bottom: 0,
              "&::before": {
                content: '"Deal Number 1"',
                color: constants.colors.intakeTitle,
                display: "block",
                fontWeight: 400,
                lineHeight: "15px",
                paddingBottom: "9px",
              },
              "&>div": {
                width: "100%",
              },
            },
          })}
          value={firstInput}
          onChange={(e) => {
            setFirstInput(+e.target.value.replace(/\D/g, ""));
          }}
          onBlur={() => emitChanges()}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
        style={{flex: 1}}
      >
        <DeededInput
          sx={(theme) => ({
            maxWidth: "250px",
            width: "240px",
            height: "36px",
            bottom: "5px",
            flexWrap: "wrap",
            [theme.breakpoints.down(smallDesktopConfigDealNumbers)]: {
              width: "100%",
              maxWidth: "100%",
              height: "63px",
              display: "block",
              "&::before": {
                content: '"Deal Number 2"',
                color: constants.colors.intakeTitle,
                display: "block",
                fontWeight: 400,
                lineHeight: "15px",
                paddingBottom: "9px",
              },
              "&>div": {
                width: "100%",
              },
            },
          })}
          value={secondInput}
          onChange={(e) => {
            setSecondInput(+e.target.value.replace(/\D/g, ""));
          }}
          onBlur={() => emitChanges()}
        />
      </Box>
    </Box>
  );
};
