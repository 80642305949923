import {
  Checkbox,
  FormControlLabel,
  FormControlLabelProps,
  styled,
  SxProps,
  Theme,
} from "@mui/material";
import constants from "../../styles/constants";
import SelectIconSvg from "../../components/Svg/ProfileCheckedSvg";
import ProfileUncheckedSvg from "../../components/Svg/ProfileUncheckedSvg";

interface DeededCheckboxProps {
  checked?: boolean;
  checkedIcon?: JSX.Element;
  fill?: string;
  disabled?: boolean;
  label?: string;
  sx?: SxProps<Theme>;
}

const DeededCheckbox: React.FC<
  | DeededCheckboxProps
  | (FormControlLabelProps & {checkedIcon?: JSX.Element; fill?: string})
> = ({checked, checkedIcon, fill, label, disabled, sx, ...props}) => (
  <StyledFormControlLabel
    label={label ?? ""}
    disabled={disabled}
    data-selenium-test="DeededCheckbox"
    sx={sx}
    control={
      <StyledCheckbox
        checked={checked}
        checkedIcon={checkedIcon || <SelectIconSvg fill={fill} />}
        icon={<ProfileUncheckedSvg />}
        disabled={disabled}
      />
    }
    {...props}
  />
);

const StyledFormControlLabel = styled(FormControlLabel)({
  color: constants.colors.sortingColor,
  display: "inline-flex",
  flexDirection: "row",
  marginRight: 0,
  marginLeft: 0,
  "& .MuiCheckbox-root": {
    minHeight: "48px",
    minWidth: "48px",
  },
  "&:not(.Mui-disabled):hover": {
    ".MuiRadio-root": {
      transition: constants.transitions,
      backgroundColor: constants.colors.redVeryPale,
    },
    "& .MuiCheckbox-root": {
      transition: constants.transitions,
      background: constants.colors.redVeryPale,
    },
  },
  "& .MuiTypography-root": {
    fontSize: "14px",
  },
  "& .MuiFormControlLabel-label": {
    "&.Mui-disabled": {
      cursor: "no-drop",
      color: constants.colors.configNotificationsTableBorder,
    },
  },
});

const StyledCheckbox = styled(Checkbox)({
  transition: constants.transitions,
  "&:hover": {
    transition: constants.transitions,
    background: constants.colors.redVeryPale,
  },
  "&.Mui-disabled": {
    pointerEvents: "auto",
    "&:hover": {
      cursor: "no-drop",
      background: "transparent",
    },
    svg: {
      fill: constants.colors.configNotificationsTableBorder,
      rect: {
        stroke: constants.colors.configNotificationsTableBorder,
      },
    },
  },
});

export default DeededCheckbox;
