import React from "react";
import constants from "styles/constants";
import {Box, Typography, useTheme} from "@mui/material";
import BulbSvg from "components/Svg/BulbSvg";

interface SpecialConditionsBannerStaffProps {
    specialConditionsArray: {name: string; condition: string}[];
}

const SpecialConditionsBannerStaff: React.FC<SpecialConditionsBannerStaffProps> = ({specialConditionsArray}) => {
  const theme = useTheme();
  return (
    <>
      <Box
        sx={{
          display: "flex",
          backgroundColor: constants.colors.red,
          borderRadius: "5px",
          height: "113px",
          maxHeight: "113px",
          marginBottom: "20px",
          padding: "8px 16px",
        }}
      >
        <Box>
          <BulbSvg />
        </Box>
        <Box
          sx={{
            overflowY: "scroll",
            "&::-webkit-scrollbar": {
              width: "0px",
            },
          }}
        >
          {specialConditionsArray.map((user, index) => {
            return (
              <>
                <Box
                  sx={{
                    display: "flex",
                    marginLeft: "20px",
                    marginBottom: "10px",
                    [theme.breakpoints.down("sm")]: {
                      justifyContent: "space-between",
                    },
                  }}
                  key={index}
                >
                  <Typography
                    sx={{
                      minWidth: "150px",
                      width: "150px",
                      marginRight: "20px",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      fontWeight: "500",
                      fontSize: "14px",
                      color: constants.colors.white,
                      [theme.breakpoints.down("sm")]: {
                        maxWidth: "100%",
                        width: "100%",
                        minWidth: "unset",
                      },
                    }}
                  >
                    {user.name}
                  </Typography>
                  <Typography
                    sx={{
                      overflowWrap: "anywhere",
                      fontWeight: "400",
                      fontSize: "14px",
                      color: constants.colors.white,
                      [theme.breakpoints.down("sm")]: {
                        maxWidth: "100%",
                        width: "100%",
                        minWidth: "150px",
                      },
                    }}
                  >
                    {user.condition}
                  </Typography>
                </Box>
              </>
            );
          })}
        </Box>
      </Box>
    </>
  );
};
export default SpecialConditionsBannerStaff;
