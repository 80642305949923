import { useState, useEffect, FC, ChangeEvent } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import { useSnackbar } from "notistack";
import { Box, styled, Typography } from "@mui/material";

import { useMixpanel } from "utils/MixpanelContext";
import { ReusableSelect, provincesList } from "utils/reusableSelect";
import {
    convertClosingDateToDisplayRepresentation,
    PROP_CARD_DATE_CONVERSION_FORMAT,
} from "utils/date";
import useTypedSelector from "utils/hooks/useTypedSelector";
import formatDealNumbers from "utils/formatDealNumber";
import configuration from "utils/configuration";
import {
    setDealInfoByIdAC,
    setFoldersForDealTypeTC,
} from "redux/reducers/dealsReducer";
import { useReopenDealMutation } from "redux/reducers/dealInfoApi";
import DeededButton from "v2/components/DeededButton";
import DealDetailsCloseDealPopup from "components/Dashboard/DealPropertyCard/components/DealDetailsCloseDealPopup";
import DealDetailsClosingDateHistoryHint from "components/Dashboard/DealPropertyCard/components/DealDetailsClosingDateHistoryHint";
import DeededPopup from "v2/components/DeededPopup";
import DeededDatePicker from "v2/components/DeededDatePicker";
import constants from "styles/constants";
import { PostalCodeValidator } from "../PostalCodeValidator/PostalCodeValidator";
import CalendarSVG from "../../Svg/Calendar";
import EditSVG from "../../Svg/Edit";
import DotSVG from "../../Svg/Dot";
import HomeSVG from "../../Svg/Home";
import "./DealPropertyCard.scss";

import { User } from "types/user";
import { AddressType, Deal } from "types/deal";

interface DealPropertyCardProps {
    dealState: Deal;
    user: User;
    handlePropertyDataUpdate: (
        closing_date: string,
        deal_number: string,
        address_data: Partial<AddressType>,
    ) => void;
    is_lod: boolean;
}

type FormState = Partial<AddressType> & { deal_number: string };

const DealPropertyCard: FC<DealPropertyCardProps> = ({
    dealState,
    user,
    handlePropertyDataUpdate,
    is_lod,
}) => {
    const { enqueueSnackbar } = useSnackbar();
    const mixpanel = useMixpanel();
    const dispatch = useDispatch();
    const [toggleEditForm, setToggleEditForm] = useState(false);
    const [isArchive, setIsArchive] = useState(!!dealState.is_archive);
    const [editForm, setEditForm] = useState({
        address: "",
        unit: "",
        city: "",
        state: "",
        code: "",
        deal_number: "",
    } as FormState);
    const [isOpenCloseDealPopup, setIsOpenCloseDealPopup] = useState(false);
    const [isShowClosingDateHints, setIsShowClosingDateHints] = useState(false);
    const [errors, setErrors] = useState({} as Partial<FormState>);
    const permissionList = [
        "Admin",
        "System Admin",
        "Lawyer",
        "Law Clerk",
        "Mobile Signer",
        "Closing Advisor",
        "Document Support Specialist",
    ];
    const permissionListWithoutLawClerk = [
        "Admin",
        "System Admin",
        "Lawyer",
        "Mobile Signer",
        "Closing Advisor",
        "Document Support Specialist",
    ];
    const [reopenDeal, { isLoading }] = useReopenDealMutation();
    const [closingDate, setClosingDate] = useState(dealState?.closing_date);
    const dealInfoById = useTypedSelector(
        (state) => state.DealsReducer.deal_info_by_id,
    ) as Deal;

    useEffect(() => {
        dispatch(
            setFoldersForDealTypeTC(
                dealState.deal_type,
                dealState?.address?.state,
            ),
        );
    }, [dealState.deal_type, dispatch]);
    useEffect(() => {
        if (dealState) {
            const { address: addressData, deal_number } = dealState;
            const {
                address = "",
                unit = "",
                city = "",
                state = "",
                code = "",
            } = addressData || {};
            setErrors({});
            setClosingDate(dealState.closing_date);
            if (dealInfoById?.closing_date) {
                dispatch(
                    setDealInfoByIdAC({
                        ...dealInfoById,
                        closing_date: dealState?.closing_date,
                    }),
                );
            }
            setEditForm({
                address,
                unit,
                city,
                state,
                code,
                deal_number,
            });
        }
    }, [dealState]);

    const userPermission = permissionList.find((el) => el === user.role);
    const userPermissionWithoutLawClerk = permissionListWithoutLawClerk.find(
        (el) => el === user.role,
    );
    useEffect(() => {
        for (let key in editForm) {
            if (
                key === "address" ||
                key === "city" ||
                key === "code" ||
                key === "deal_number"
            ) {
                setErrors((prev) => ({
                    ...prev,
                    [key]:
                        (editForm[key as keyof FormState] as string).length ===
                        0,
                }));
            }
        }
    }, [editForm]);

    const handleEditFormUpdate = () => {
        if (editForm.deal_number.length === 0) {
            return;
        }
        if (
            Object.entries(errors).filter((el) => el[1] !== false).length !== 0
        ) {
            enqueueSnackbar("Please, fill required fields", {
                variant: "error",
                autoHideDuration: configuration.autoHideErrorDuration,
            });
            return;
        }
        if (!closingDate) {
            enqueueSnackbar("Please, fill closing date", {
                variant: "error",
                autoHideDuration: configuration.autoHideErrorDuration,
            });
            return;
        }
        let newEditForm = Object.assign({}, editForm);
        delete newEditForm.unit;

        const { address, unit, city, state, code, deal_number } = editForm;
        // Send data for mixpanel analytics for Admin and Sub-admin
        if (dealState.deal_number !== deal_number) {
            const { role, email, full_name } = user;
            const mixPayload = {
                role,
                email,
                full_name,
                deal_id: dealState.id,
                deal_type: dealState.deal_type,
                deal_prev_number: dealState.deal_number,
                deal_current_number: deal_number,
            };
            mixpanel.track(`${role}_modify_deal_number`, mixPayload);
        }
        handlePropertyDataUpdate(closingDate, deal_number, {
            address,
            unit,
            city,
            state,
            code,
        });
        setToggleEditForm(!toggleEditForm);
    };
    const handlePropertyInputs = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target === undefined) {
            const { value } = e as never;

            setEditForm((prevState) => ({
                ...prevState,
                state: value,
            }));
        } else {
            const { name, value } = e.target;

            let newValue = value;
            if (name === "deal_number") {
                newValue = formatDealNumbers(value);
            }
            setEditForm((prevState) => ({
                ...prevState,
                [name]: newValue,
            }));
        }
    };

    useEffect(() => {
        if (toggleEditForm) {
            document.body.style.overflow = "hidden";
            document.body.style.height = "100vh";
        }
        return () => {
            document.body.style.overflow = "inherit";
            document.body.style.height = "auto";
        };
    }, [toggleEditForm]);

    return (
        <div className="deal__property-card">
            {toggleEditForm && (
                <DeededPopup
                    sx={{}}
                    open={toggleEditForm}
                    setOpen={setToggleEditForm}
                    title="Update address or date"
                    sxInternalBox={{
                        width: "450px",
                        overflowX: "hidden",
                        overflowY: "auto",
                        "&::-webkit-scrollbar": {
                            width: "5px",
                            scrollbarWidth: "thin",
                        },
                    }}
                >
                    <div className="deal__property--wrapper">
                        <div className="property__wrapper">
                            <div className="property__form">
                                <div className="property__form--inputs">
                                    <div className="flex-container">
                                        <div className="flex-1">
                                            <label htmlFor="address">
                                                Address
                                                <input
                                                    className={
                                                        errors?.address
                                                            ? "input-error"
                                                            : ""
                                                    }
                                                    autoComplete="new-password"
                                                    type="text"
                                                    name="address"
                                                    value={editForm.address}
                                                    onChange={(e) =>
                                                        handlePropertyInputs(e)
                                                    }
                                                />
                                                {errors?.address && (
                                                    <span className="input-error-text stateError">
                                                        Field is required
                                                    </span>
                                                )}
                                            </label>
                                            <label htmlFor="city">
                                                Unit
                                                <input
                                                    autoComplete="new-password"
                                                    type="text"
                                                    name="unit"
                                                    value={
                                                        (editForm.unit ||
                                                            "") as string
                                                    }
                                                    onChange={(e) =>
                                                        handlePropertyInputs(e)
                                                    }
                                                />
                                            </label>
                                            <label htmlFor="city">
                                                City
                                                <input
                                                    className={
                                                        errors?.city
                                                            ? "input-error"
                                                            : ""
                                                    }
                                                    autoComplete="new-password"
                                                    type="text"
                                                    name="city"
                                                    value={editForm.city}
                                                    onChange={(e) =>
                                                        handlePropertyInputs(e)
                                                    }
                                                />
                                                {errors?.city && (
                                                    <span className="input-error-text stateError">
                                                        Field is required
                                                    </span>
                                                )}
                                            </label>
                                            <label htmlFor="state">
                                                <span>Province</span>
                                                <ReusableSelect
                                                    options={provincesList}
                                                    defaultValue={{
                                                        label: editForm.state as string,
                                                        value: editForm.state as string,
                                                    }}
                                                    onChangeHandler={(
                                                        e: ChangeEvent<HTMLInputElement>,
                                                    ) =>
                                                        handlePropertyInputs(e)
                                                    }
                                                />
                                            </label>
                                        </div>
                                        <div className="flex-2">
                                            <label htmlFor="closing">
                                                Closing date
                                                <Box py="1rem">
                                                    <DeededDatePicker
                                                        fullWidth
                                                        minDate={new Date()}
                                                        dateStart={closingDate ? moment(
                                                            closingDate,
                                                            PROP_CARD_DATE_CONVERSION_FORMAT,
                                                        ).toDate() : null}
                                                        name="closing_date"
                                                        onChange={(date) => {
                                                            setClosingDate(
                                                                moment(
                                                                    date,
                                                                ).format(
                                                                    PROP_CARD_DATE_CONVERSION_FORMAT,
                                                                ),
                                                            );
                                                        }}
                                                    />
                                                </Box>
                                            </label>
                                            <label htmlFor="code">
                                                Postal code
                                                <PostalCodeValidator
                                                    inputError={errors?.code}
                                                    defaultValue={editForm.code}
                                                    handlePostalCode={(
                                                        code: string,
                                                    ) => {
                                                        setErrors({});
                                                        setEditForm(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                code,
                                                            }),
                                                        );
                                                    }}
                                                    name={undefined}
                                                />
                                                {errors?.code && (
                                                    <span className="input-error-text stateError">
                                                        Field is required
                                                    </span>
                                                )}
                                            </label>
                                            <label htmlFor="deal_number">
                                                Transaction number
                                                <input
                                                    disabled={user.role === "Law Clerk"}
                                                    autoComplete="new-password"
                                                    type="text"
                                                    name="deal_number"
                                                    value={editForm.deal_number}
                                                    onChange={(e) =>
                                                        handlePropertyInputs(e)
                                                    }
                                                />
                                                {errors?.deal_number && (
                                                    <span className="input-error-text stateError">
                                                        Field is required
                                                    </span>
                                                )}
                                            </label>
                                        </div>
                                    </div>
                                    <button
                                        className="submit"
                                        onClick={handleEditFormUpdate}
                                    >
                                        submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </DeededPopup>
            )}

            <Property className="property">
                <AddressRow className="address-row">
                    <span className="home">
                        <HomeSVG />
                    </span>
                    <h2>property</h2>
                    <span
                        className="address"
                        data-testid="deal-details-property-address-value"
                    >{`${dealState?.address?.address.slice(
                        0,
                        30,
                    )}\u00A0 ${dealState?.address?.city.slice(
                        0,
                        30,
                    )}\u00A0${dealState?.address?.state.slice(0, 30)}`}</span>
                </AddressRow>
            </Property>

            <ClosingData
                onMouseEnter={() => {
                    setIsShowClosingDateHints(true);
                }}
                onMouseLeave={() => {
                    setIsShowClosingDateHints(false);
                }}
            >
                <span>Closing date</span>
                <div
                    className="closing__value"
                    data-testid="deal-details-property-closing-date-value"
                >
                    <CalendarSVG color={"#7F7F7F"} />
                    {convertClosingDateToDisplayRepresentation(
                        dealState.closing_date,
                    ) || 'Unknown'}
                </div>
                {isShowClosingDateHints && (
                    <DealDetailsClosingDateHistoryHint deal={dealState} />
                )}
            </ClosingData>
            <ClosingData>
                <span>Transaction number</span>
                <div
                    className="closing__value"
                    data-testid="deal-details-property-deal-number-value"
                >
                    {editForm.deal_number.slice(0, 9)}{" "}
                    {is_lod ? <p className={"is_lod"}>LOD</p> : <p></p>}
                </div>
            </ClosingData>
            {userPermissionWithoutLawClerk &&
                !isArchive &&
                user.role !== "Mobile Signer" && (
                    <CloseDealBtn
                        data-testid="deal-details-property-cancel-deal-btn"
                        onClick={() => {
                            setIsOpenCloseDealPopup(true);
                        }}
                        kind={"secondary"}
                    >
                        Cancel Deal
                    </CloseDealBtn>
                )}
            {userPermission &&
                isArchive &&
                user.role !== "Mobile Signer" && (
                    <ReopenDealBtn
                        data-testid="deal-details-reopen-deal-button"
                        onClick={() => {
                            if (!isLoading) {
                                reopenDeal({
                                    dealId: dealState.id,
                                    setIsArchive: setIsArchive,
                                });
                            }
                        }}
                        kind={"secondary"}
                    >
                        {isLoading ? "Loading..." : "Reopen Deal"}
                    </ReopenDealBtn>
                )}
            {userPermission && (
                <Edit onClick={() => setToggleEditForm(!toggleEditForm)}>
                    <EditSVG />
                </Edit>
            )}
            <span
                style={{
                    display: "flex",
                    flexDirection: "column",
                }}
                className={dealState.deal_type}
                data-testid="deal-details-deal-type"
            >
                <Box>
                    <DotSVG />
                    {dealState.deal_type}
                </Box>
                {dealState?.deal_type_tag?.tag &&
                    dealState?.deal_type_tag?.tag !== "default" && (
                        <Typography
                            sx={{
                                height: "16px",
                                backgroundColor:
                                    constants.dealTypeColor.Mortgage,
                                color: constants.colors.white,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: "2px",
                                fontSize: "12px",
                                fontWeight: "500",
                                width: "70px",
                                marginLeft: "23px",
                                marginTop: "2px",
                            }}
                        >
                            {dealState?.deal_type_tag?.tag}
                        </Typography>
                    )}
            </span>
            {isOpenCloseDealPopup && (
                <DealDetailsCloseDealPopup
                    isOpenCloseDealPopup={isOpenCloseDealPopup}
                    setIsOpenCloseDealPopup={setIsOpenCloseDealPopup}
                    setIsArchive={setIsArchive}
                />
            )}
        </div>
    );
};
const CloseDealBtn = styled(DeededButton)(({ theme }) => ({
    width: "131px",
    height: "31px",
    [theme.breakpoints.down("sm")]: {
        order: 5,
        marginRight: "auto",
        width: "auto",
    },
}));
const Property = styled("div")(({ theme }) => ({
    [theme.breakpoints.down("sm")]: {
        width: "100%",
        minWidth: "100%",
    },
}));
const AddressRow = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        alignItems: "flex-start",
        width: "100%",
    },
}));

const ClosingData = styled("div")(({ theme }) => ({
    display: "flex",
    position: "relative",
    marginRight: "5rem",
    flexDirection: "column",
    span: {
        textTransform: "uppercase",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "1.4rem",
        lineHeight: "2.3rem",
    },
    ".closing__value": {
        display: "flex",
        marginTop: "1rem",
    },
    ".is_lod": {
        background: "var(--red)",
        color: "white",
        padding: "5px 10px 5px 10px",
        fontSize: "12px",
        borderRadius: "4px",
        marginLeft: "10px",
        marginTop: "-5px",
    },
    svg: {
        marginRight: "1rem",
    },
    [theme.breakpoints.down(992)]: {
        marginBottom: "20px",
    },
    [theme.breakpoints.down("sm")]: {
        width: "50%",
        marginRight: "0rem",
        order: 2,
        display: "flex",
        justifyContent: "space-between",
        margin: "1.5rem 0",
        "&:nth-of-type(3)": {
            textAlign: "right",
            ".closing__value": {
                justifyContent: "flex-end",
            },
        },
    },
}));
const Edit = styled("span")(({ theme }) => ({
    cursor: "pointer",
    margin: "0 5rem",
    i: {
        fontSize: "2rem",
        color: "var(--gray)",
    },
    [theme.breakpoints.down("sm")]: {
        order: 5,
        marginLeft: 0,
        marginRight: 0,
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        minWidth: "64px",
        padding: "6px 8px",
        borderRadius: "5px",
        backgroundColor: constants.colors.white,
        textTransform: "capitalize",
        fontFamily: "inherit",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "14px",
        color: constants.colors.green,
        letterSpacing: "unset",
        border: `1px solid ${constants.colors.green}`,
        transition: constants.transitions,
        height: "31px",
        "&:hover": {
            transition: constants.transitions,
            color: constants.colors.white,
            border: `1px solid ${constants.colors.green}`,
            backgroundColor: constants.colors.green,
        },
        "& *": {
            display: "none",
        },
        "&:before": {
            content: "'Edit'",
        },
    },
}));

const ReopenDealBtn = CloseDealBtn;

export default DealPropertyCard;
