import React from "react";
import {Box} from "@mui/material";
import DeededInput from "v2/components/DeededInput";

const DealDetailsCommentTextArea: React.FC<
  React.ComponentProps<typeof DeededInput> & {
    text: string;
    setText: (text: string) => void;
    error?: string;
    setCommentError: (error: string | undefined) => void;
  }
> = ({onChange, ...props}) => {
  return (
    <Box sx={{width: "100%"}}>
      <DeededInput
        {...props}
        error={props.error}
        value={props.text}
        onChange={
          onChange
            ? (e) => {
                if (e.target.value.length < 255) {
                  props.setText(e.target.value);
                  props.setCommentError(undefined);
                }
                onChange(e);
              }
            : (e) => {
                if (e.target.value.length < 255) {
                  props.setText(e.target.value);
                  props.setCommentError(undefined);
                }
              }
        }
        height="auto"
        sx={{
          //paddingBottom: "48px",
          width: "100%",
          "& .MuiOutlinedInput-root": {
            minWidth: "250px",
            minHeight: "94px",
            alignItems: "flex-start",
          },
          "& .MuiOutlinedInput-root .MuiInputBase-input": {
            lineHeight: 1.5,
            paddingBottom: "20px",
          },
          "& textarea": {
            marginTop: 2,
          },
        }}
        multiline
      />
    </Box>
  );
};

export default DealDetailsCommentTextArea;
