import constants from "../../../../styles/constants";
export const scrollbarStyles = {
  "&::-webkit-scrollbar": {
    backgroundColor: constants.colors.configNotificationsTableBorder,
    width: "3px",
    borderRadius: "2px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: constants.colors.red,
    borderRadius: "2px",
  },
};
