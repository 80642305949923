import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import StorageHeading from "./../StorageCommonComponents/StorageHeading";
import {BaseStoragePage, Files} from "../StorageCommonComponents";
import {
  resetStorageAC,
  setFileInfoSectionFalseAC,
} from "../../../redux/reducers/storageReducer";
import resetStorage from "utils/resetStorage";

const StoragePage = () => {
  const [addNewDocument, setAddNewDocument] = useState(false);
  const fileInfoSection = useSelector(
    (state) => state.StorageReducer.fileInfoSection,
  );
  const dispatch = useDispatch();
  const [initialized, setInitialized] = useState(false);
  // Removing this, it is an error to have it in use effect, but this is not
  // how the page was working previously, will need to discuss.
  // resetStorage();

  useEffect(() => {
    setInitialized(true);
    dispatch(setFileInfoSectionFalseAC());

    return () => {
      dispatch(resetStorageAC());
      setInitialized(false);
    };
  }, [dispatch]);

  return (
    <BaseStoragePage
      addNewDocument={addNewDocument}
      setAddNewDocument={setAddNewDocument}
      fileInfoSection={fileInfoSection}
      files={
        initialized ? (
          <Files
            nodealFolderId
            addNewDocument={addNewDocument}
            setAddNewDocument={setAddNewDocument}
          />
        ) : (
          <>Loading</>
        )
      }
    >
      <StorageHeading setAddNewDocument={setAddNewDocument} />
    </BaseStoragePage>
  );
};

export default StoragePage;
