import {Box, useTheme} from "@mui/material";
import DeededHint from "v2/components/DeededHint";

interface ProfileSecurityPasswordHintsProps {
  newPass: string;
}
const ProfileSecurityPasswordHints: React.FC<
  ProfileSecurityPasswordHintsProps
> = ({newPass}) => {
  const theme = useTheme();
  return (
    <>
      <Box
        sx={{
          position: "absolute",
          width: 320,
          height: 140,
          top: "80px",
          borderRadius: 3,
          left: "29px",
          boxShadow: "0px 12px 66px 0px #0000002E",
          backgroundColor: "white",
          zIndex: 1,
          [theme.breakpoints.down('sm')]: {
            left: "-30px",
          },
          [theme.breakpoints.down('sm')]: {
            left: "-50px",
          },
        }}
      >
        <Box
          sx={{
            width: 16,
            height: 16,
            position: "absolute",
            transform: "rotate(45deg)",
            backgroundColor: "white",
            top: -8,
            left: 145,
            zIndex: 20000,
          }}
        ></Box>
        <Box
          sx={{
            marginTop: "23px",
            marginLeft: "17px",
          }}
        >
          <Box>
            <DeededHint
              active={!!newPass.match("(?=.{12,})")}
              hint={"Must be at least 12 characters"}
            />
          </Box>
          <Box>
            <DeededHint
              active={!!newPass.match("(?=.*[0-9])")}
              hint={"Must contain at least 1 number"}
            />
          </Box>
          <Box>
            <DeededHint
              active={!!newPass.match("(?=.*[A-Z])")}
              hint={"Must contain at least 1 uppercase letter"}
            />
          </Box>
          <Box>
            <DeededHint
              active={!!newPass.match("(?=.*[a-z])")}
              hint={"Must contain at least 1 lowercase letter"}
            />
          </Box>
          <Box>
            <DeededHint
              active={!newPass.match(/^\s+|\s+$/g) && Boolean(newPass)}
              hint="No spaces at the beginning or end"
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default ProfileSecurityPasswordHints;
