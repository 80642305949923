import { styled } from "@mui/material/styles";
import Typography, { TypographyProps as MuiTypographyProps } from "@mui/material/Typography";
import {
    H1_TEXT,
    H2_TEXT,
    H3_TEXT,
    H4_TEXT,
    SUBTITLE_SMALL,
    SUBTITLE_REGULAR,
    OVERLINE_TEXT,
    BODY_BIG,
    BODY_REGULAR,
    BODY_SMALL,
    BODY_SMALLER,
    BUTTON_LARGE_TEXT,
    BUTTON_SMALL_TEXT,
    H1_TEXT_MOBILE,
    H2_TEXT_MOBILE,
    CAPTION,
    LINK_LARGE,
} from "../Styling/Styles";
import colors from "../Styling/Colors";

interface TypographyProps extends MuiTypographyProps {
  color?: string;
  href?: string;
  target?: string;
}

export const H1 = styled(Typography)<{color?: string}>(
  ({ color, theme }) => ({
    ...H1_TEXT,
    color: color || colors.BLACK,
    [theme.breakpoints.down('mobile')]: {
      ...H1_TEXT_MOBILE,
    }
  }),
);

export const H2 = styled(Typography)<{color?: string}>(
  ({ color, theme }) => ({
    ...H2_TEXT,
    color: color || colors.BLACK,
    [theme.breakpoints.down('mobile')]: {
      ...H2_TEXT_MOBILE,
    }
  }),
);

export const H3 = styled(Typography)(
  ({ color }: TypographyProps) => ({
    ...H3_TEXT,
    color: color || colors.BLACK,
  }),
);

export const H4 = styled(Typography)(
  ({ color }: TypographyProps) => ({
    ...H4_TEXT,
    color: color || colors.BLACK,
  }),
);

export const SubtitleSmall = styled(Typography)(
  ({ color }: TypographyProps) => ({
    ...SUBTITLE_SMALL,
    color: color || colors.PRIMARY,
  }),
);

export const SubtitleRegular = styled(Typography)(
  ({ color }: TypographyProps) => ({
    ...SUBTITLE_REGULAR,
    color: color || colors.BLACK,
  }),
);

export const Overline = styled(Typography)(
  ({ color }: TypographyProps) => ({
    ...OVERLINE_TEXT,
    color: color || colors.PRIMARY,
    textTransform: 'uppercase'
  }),
);

export const BodyBig = styled(Typography)(
  ({ color }: TypographyProps) => ({
    ...BODY_BIG,
    color: color || colors.BLACK,
  }),
);

export const BodySmall = styled(Typography)(
  ({ color }: TypographyProps) => ({
    ...BODY_SMALL,
    color: color || colors.GREY,
  }),
);

export const BodySmaller = styled(Typography)(
  ({ color }: TypographyProps) => ({
    ...BODY_SMALLER,
    color: color || colors.GREY,
  }),
);

export const BodyRegular = styled(Typography)(
  ({ color }: TypographyProps) => ({
    ...BODY_REGULAR,
    color: color || colors.BLACK,
  }),
);

export const ButtonLargeText = styled(Typography)(
  ({ color }: TypographyProps) => ({
    ...BUTTON_LARGE_TEXT,
    color: color || colors.GREY,
  }),
);

export const ButtonSmallText = styled(Typography)(
  ({ color }: TypographyProps) => ({
    ...BUTTON_SMALL_TEXT,
    color: color || colors.BLACK,
  }),
);

export const Caption = styled(Typography)(
  ({ color }: TypographyProps) => ({
    ...CAPTION,
    color: color || colors.BLACK,
  }),
);

export const LinkLarge = styled(Typography)(
  ({ color }: TypographyProps) => ({
    ...LINK_LARGE,
    color: color || colors.BLACK,
  }),
);