import {useDispatch, useSelector} from "react-redux";
import {useState} from "react";
import useWindowWidth from "../../../utils/hooks/useWindowWidth";
import {
  getFoldersTC,
  getWorkdocLinkTC,
  setDeletedCountAC,
  setIdActionsAC,
  setIdActionsNullAC,
  setIsLoadingDocumentAC,
  setRenameModeAC,
} from "../../../redux/reducers/storageReducer";
import {deleteFile, downloadStorageFile, renameFile} from "../../../api";
import ReactDOM from "react-dom";
import {saveAs} from "file-saver";
import {
  addErrorAC,
  addSuccessMessageAC,
} from "../../../redux/reducers/ErrorReducer";
import Checkbox from "@mui/material/Checkbox";
import {
  deleteCheckedFileAC,
  setCheckedFilesAC,
} from "../../../redux/reducers/selectedFileMoveStorageReducer";
import StoragePdfFileSvg from "../../Svg/StoragePdfFileSvg";
import StorageImageIconSvg from "../../Svg/StorageImageIconSvg";
import StorageDocFileSvg from "../../Svg/StorageDocFileSvg";
import StorageExelFileSvg from "../../Svg/StorageExelFileSvg";
import StorageUnknownFileSvg from "../../Svg/StorageUnknownFileSvg";
import StorageApproveSvg from "../../Svg/StorageApproveSvg";
import StoragePendingSvg from "../../Svg/StoragePendingSvg";
import StorageRejectedSvg from "../../Svg/StorageRejectedSvg";
import Storage3DotsSvg from "../../Svg/Storage3DotsSvg";
import * as storageConfig from "../../../components/Dashboard/StorageCommonComponents/utils/storageConfig";
import {Box, useTheme} from "@mui/material";

const prohibitedExtensions = ["doc", "docx", "xls", "xlsx"];
const StorageFileItemStaffComponent = ({
  folder,
  value,
  currentPage,
  nopagination,
  date,
  classes,
  loadingDelete,
  setLoadingDelete,
  loadingDownload,
  setLoadingDownload,
  index,
  folders,
  isDraggableFilesResolution,
  el,
  idActions,
  setFile,
  setIsViewDoc,
  handleActionsMenuPositionUpdate,
  setIdDocForView,
  targetRowCoordinates,
  handleEditOnWorkDocs,
}) => {
  const dispatch = useDispatch();
  const checkedFiles = useSelector(
    (state) => state.SelectedFileMoveStorageReducer.checkedFiles,
  );
  const renameMode = useSelector((state) => state.StorageReducer.renameMode);
  const theme = useTheme();
  const [renameValue, setRenameValue] = useState("");
  const windowWidth = useWindowWidth();

  const handleRename = (el) => {
    dispatch(setRenameModeAC(false));

    if(renameValue) renameFile(el.id, renameValue).then((res) => {
      const isScroll = window.location.pathname !== "/dashboard/storage";

      dispatch(getFoldersTC(folder, value, currentPage, undefined, isScroll));
      setRenameValue("");
    });
  };
  const handleViewOnWorkDocs = (fileInfo) => {
    dispatch(setIdActionsNullAC());
    dispatch(getWorkdocLinkTC(fileInfo.id));
  };

  return (
    <>
      <div
        style={{
          display: "block",
          flex: 1,
        }}
        className="itemStorage"
      >
        <div className="itemWrapper">
          <div className={classes.checkedRoot}>
            {!storageConfig.hideFileCheckboxes && (
              <Checkbox
                checked={checkedFiles.includes(el.id)}
                onChange={(e) => {
                  e.target.checked
                    ? dispatch(setCheckedFilesAC(el.id))
                    : dispatch(deleteCheckedFileAC(el.id));
                }}
                inputProps={{"aria-label": "primary checkbox"}}
              />
            )}
          </div>
          {el.name.endsWith(".pdf") ? (
            <StoragePdfFileSvg />
          ) : el.name.endsWith(".png") ? (
            <StorageImageIconSvg />
          ) : el.name.endsWith(".jpg") ? (
            <StorageImageIconSvg />
          ) : el.name.endsWith(".jpeg") ? (
            <StorageImageIconSvg />
          ) : el.name.endsWith(".tiff") ? (
            <StorageImageIconSvg />
          ) : el.name.endsWith(".doc") ? (
            <StorageDocFileSvg />
          ) : el.name.endsWith(".docx") ? (
            <StorageDocFileSvg />
          ) : el.name.endsWith(".xls") ? (
            <StorageExelFileSvg />
          ) : el.name.endsWith(".xlsx") ? (
            <StorageExelFileSvg />
          ) : el.name.endsWith(".xlsm") ? (
            <StorageExelFileSvg />
          ) : el.name.endsWith(".xlsb") ? (
            <StorageExelFileSvg />
          ) : el.name.endsWith(".xltx") ? (
            <StorageExelFileSvg />
          ) : (
            <StorageUnknownFileSvg />
          )}
          {!renameMode && (
            <div className="filesFileName">
              <span className="filesFileNameSpan">{el.name}</span>
            </div>
          )}
          {renameMode && el.id !== idActions && (
            <div className="filesFileName">
              <span className="filesFileNameSpan">{el.name}</span>
            </div>
          )}
          {renameMode && el.id === idActions && (
            <form
              className="filesFileNameForm"
              onSubmit={() => {
                handleRename(el);
              }}
            >
              <input
                autoFocus={true}
                value={renameValue}
                onChange={(e) => {
                  setRenameValue(e.currentTarget.value);
                }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onBlur={() => {
                  handleRename(el);
                }}
                className="filesFileName itemStorage"
              />
            </form>
          )}
        </div>
      </div>
      {!el.lastEditFO && <div className="withoutEditor itemStorage"></div>}
      <div className="itemStorage">
        <div className="itemWrapper">
          <div className="dateTimeOfFile">
            {date?.[1]}/{date?.[2]}/{date?.[0]}{" "}
            {el.lastEditFT?.[0] !== "00" ? el.lastEditFT?.[0] : "12"}:
            {el.lastEditFT?.[1]} {el.switcher}
          </div>
        </div>
      </div>
      {el?.lastEditFO?.length > 0 && (
        <div className="itemStorage">
          <div className="itemWrapper">
            <div className="fileOwner">by {el.lastEditFO}</div>
          </div>
        </div>
      )}

      {el.status === "Approved" && (
        <div className="statusPosition itemStorage">
          <div className="itemWrapper">
            <StorageApproveSvg />
          </div>
        </div>
      )}
      {el.status === "Pending" && (
        <div className="statusPosition itemStorage">
          <div className="itemWrapper">
            <StoragePendingSvg />
          </div>
        </div>
      )}
      {el.status === "Rejected" && (
        <div className="statusPosition itemStorage">
          <div className="itemWrapper">
            <StorageRejectedSvg />
          </div>
        </div>
      )}
      <div className="itemStorage comments">
        <div className="itemWrapper">
          <div className="fileMembers">
            <span>{el.comment ?? "-"}</span>
          </div>
          {el.comment && (
            <div className="membersList comment">
              <span>{el.comment ?? "-"}</span>
            </div>
          )}
        </div>
      </div>
      <div className="itemStorage"></div>
      <div
        className="itemStorage"
        style={{height: 18}}
        onClick={(e) => {
          e.stopPropagation();

          handleActionsMenuPositionUpdate(e);
          if (el.id !== idActions) dispatch(setIdActionsAC(el.id));
          else dispatch(setIdActionsNullAC());
        }}
      >
        <Box
          sx={{
            [theme.breakpoints.down("lg")]: {
              marginLeft: el.id === idActions ? "5px" : "0px",
            },
          }}
          className="itemWrapper"
        >
          <Storage3DotsSvg className="storege3Dots" />
        </Box>
      </div>
      <StorageFileItemStaffComponentTools
        folder={folder}
        value={value}
        currentPage={currentPage}
        nopagination={nopagination}
        loadingDelete={loadingDelete}
        setLoadingDelete={setLoadingDelete}
        loadingDownload={loadingDownload}
        setLoadingDownload={setLoadingDownload}
        setIsViewDoc={setIsViewDoc}
        setIdDocForView={setIdDocForView}
        setFile={setFile}
        folders={folders}
        index={index}
        el={el}
        idActions={idActions}
        isDraggableFilesResolution={isDraggableFilesResolution}
        targetRowCoordinates={targetRowCoordinates}
        windowWidth={windowWidth}
        handleEditOnWorkDocs={handleEditOnWorkDocs}
      />
    </>
  );
};
const StorageFileItemStaffComponentTools = ({
  folder,
  value,
  currentPage,
  nopagination,
  loadingDelete,
  setLoadingDelete,
  loadingDownload,
  setLoadingDownload,
  setIsViewDoc,
  setIdDocForView,
  setFile,
  folders,
  index,
  el,
  idActions,
  isDraggableFilesResolution,
  targetRowCoordinates,
  windowWidth,
  handleEditOnWorkDocs,
}) => {
  return (
    <>
      {el.id === idActions &&
        isDraggableFilesResolution &&
        ReactDOM.createPortal(
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className="fileActionsBox2"
            style={{
              position: "absolute",
              top: targetRowCoordinates.top,
              left: targetRowCoordinates.left - 220,
            }}
          >
            <ToolsStaff
              folder={folder}
              value={value}
              currentPage={currentPage}
              nopagination={nopagination}
              loadingDelete={loadingDelete}
              setLoadingDelete={setLoadingDelete}
              loadingDownload={loadingDownload}
              setLoadingDownload={setLoadingDownload}
              setIsViewDoc={setIsViewDoc}
              setIdDocForView={setIdDocForView}
              setFile={setFile}
              folders={folders}
              index={index}
              el={el}
              idActions={idActions}
              handleEditOnWorkDocs={handleEditOnWorkDocs}
            />
          </div>,
          document.body,
        )}
      {el.id === idActions && !isDraggableFilesResolution && (
        <>
          <Box
            onClick={(e) => {
              e.stopPropagation();
            }}
            sx={{
              position: "absolute",
              background: "white",
              width: "150px",
              right: "70px",
              boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
              zIndex: 12,
            }}
          >
            <ToolsStaff
              folder={folder}
              value={value}
              currentPage={currentPage}
              nopagination={nopagination}
              loadingDelete={loadingDelete}
              setLoadingDelete={setLoadingDelete}
              loadingDownload={loadingDownload}
              setLoadingDownload={setLoadingDownload}
              setIsViewDoc={setIsViewDoc}
              setIdDocForView={setIdDocForView}
              setFile={setFile}
              folders={folders}
              index={index}
              el={el}
              idActions={idActions}
              handleEditOnWorkDocs={handleEditOnWorkDocs}
            />
          </Box>
        </>
      )}
    </>
  );
};
const ToolsStaff = ({
  folder,
  value,
  currentPage,
  nopagination,
  loadingDelete,
  setLoadingDelete,
  loadingDownload,
  setLoadingDownload,
  setIsViewDoc,
  setIdDocForView,
  setFile,
  folders,
  index,
  el,
  idActions,
  handleEditOnWorkDocs,
}) => {
  const dispatch = useDispatch();
  return (
    <>
      <p
        onClick={() => {
          dispatch(setRenameModeAC(true));
        }}
        className="folderAction"
      >
        Rename
      </p>
      {!prohibitedExtensions.includes(folders[index].name.split(".")[1]) && (
        <p
          className="folderAction"
          onClick={() => {
            dispatch(setIsLoadingDocumentAC(true));
            downloadStorageFile(+el.id)
              .then((blob) => {
                setFile({
                  data: URL.createObjectURL(blob),
                  type: blob.type,
                  name: el.name,
                });
                dispatch(setIsLoadingDocumentAC(false));
              })
              .catch((e) => {
                const error = JSON.parse(
                  [...new Uint8Array(e.response.data)]
                    .map((x) => String.fromCharCode(x))
                    .join(""),
                ).error;
                dispatch(addErrorAC(error));
                dispatch(setIsLoadingDocumentAC(false));
              });
            setIdDocForView(el.id);
            setIsViewDoc(true);
            dispatch(setIdActionsNullAC());
          }}
        >
          View
        </p>
      )}
      <p
        className="folderAction"
        onClick={() => {
          handleEditOnWorkDocs(el);
        }}
      >
        Edit on WD
      </p>
      <p
        className="folderAction"
        onClick={() => {
          const {id, name: filename} = el;
          setLoadingDownload(true);
          downloadStorageFile(id).then((blob) => {
            saveAs(blob, filename);
            setLoadingDownload(false);
            dispatch(setIdActionsNullAC());
          });
        }}
      >
        {loadingDownload ? "Loading..." : "Download"}
      </p>
      <p
        onClick={(e) => {
          e.stopPropagation();

          setLoadingDelete(true);
          deleteFile(idActions).then((res) => {
            dispatch(setDeletedCountAC());
            dispatch(
              getFoldersTC(folder, value, currentPage, undefined, nopagination),
            );
            setLoadingDelete(false);
            dispatch(setIdActionsNullAC());

            dispatch(addSuccessMessageAC(res.message));
          });
        }}
        className="folderAction"
      >
        {loadingDelete ? "Loading..." : "Delete"}
      </p>
    </>
  );
};
export default StorageFileItemStaffComponent;
