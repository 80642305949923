export const configCcAndBccList = [
  "None",
  "Admin",
  "System Admin",
  "Client",
  "Secondary Client",
  "Realtor",
  "Real Estate/Mortgage Team",
  "Broker",
  "Lender",
  "Lawyer",
  "Law Clerk",
  "Mobile Signer",
  "Closing Advisor",
];
