import moment from "moment/moment";

export const parseDateFromBackendForBlockedTimeCalendar = (
  dateWithBadTime: string,
  correctTime: string,
) => {
  const parsedDateWithBadTime = moment(dateWithBadTime, "YYYY-MM-DD").format(
    "YYYY-MM-DD",
  );
  const parsedCorrectTime = moment(correctTime, "HH:mm:ss").format("HH:mm:ss");

  return moment(
    `${parsedDateWithBadTime} ${parsedCorrectTime}`,
    "YYYY-MM-DD HH:mm:ss",
  ).toDate();
};
