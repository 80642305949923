import React, {Fragment} from "react";
import "./FileInfo.scss";
import StorageCloseIconSvg from "../../../Svg/StorageCloseIconSvg";
import StorageFolderSvg from "../../../Svg/StorageFolderSvg";
import {useDispatch, useSelector} from "react-redux";
import StorageCloseTagSvg from "../../../Svg/StorageCloseTagSvg";
import {setFileInfoSectionFalseAC} from "../../../../redux/reducers/storageReducer";
import StorageBigUnknownFileSvg from "../../../Svg/StorageBigUnknownFileSvg";
import StorageBigPdfSvg from "../../../Svg/StorageBigPdfSvg";
import StorageBigDocSvg from "../../../Svg/StorageBigDocSvg";
import StorageBigImageSvg from "../../../Svg/StorageBigImageSvg";
import StorageBigExelSvg from "../../../Svg/StorageBigExelSvg";

const FileInfo = () => {
  const format = useSelector(
    (state) => state.StorageReducer.selectedFormatForFileInfo,
  );
  const fileName = useSelector(
    (state) => state.StorageReducer.fileinfo.fileName,
  );
  const filesinside = useSelector(
    (state) => state.StorageReducer.fileinfo.filesInside,
  );
  const capacity = useSelector(
    (state) => state.StorageReducer.fileinfo.capacity,
  );
  const lastUpdate = useSelector(
    (state) => state.StorageReducer.fileinfo.lastUpdate,
  );
  const Type = useSelector((state) => state.StorageReducer.fileinfo.Type);
  const owner = useSelector((state) => state.StorageReducer.fileinfo.owner);
  const edited = useSelector((state) => state.StorageReducer.fileinfo.edited);
  const dateEdited = useSelector(
    (state) => state.StorageReducer.fileinfo.dateEdited,
  );
  const created = useSelector((state) => state.StorageReducer.fileinfo.created);

  const dateCreatedData = useSelector(
    (state) => state.StorageReducer.fileinfo.dateCreated,
  );

  const dateCreated = dateCreatedData?.slice(20, dateCreatedData.length);
  const tags = useSelector((state) => state.StorageReducer.fileinfo.tags);
  const dispatch = useDispatch();
  return (
    <div className="fileInfoBox">
      <div className="fileInfoTitleBox">
        <p className="fileInfoTitle">File info</p>
        <div
          onClick={() => {
            dispatch(setFileInfoSectionFalseAC());
          }}
          className="storageCloseIconSvg"
        >
          <StorageCloseIconSvg />
        </div>
      </div>
      {Type === "Directory" ? (
        <div className="storageFolder">
          <StorageFolderSvg />
        </div>
      ) : format === ".pdf" ? (
        <div className="storageFolder">
          <StorageBigPdfSvg />
        </div>
      ) : format === ".doc" ? (
        <div className="storageFolder">
          <StorageBigDocSvg />
        </div>
      ) : format === ".jpg" ? (
        <div className="storageFolder">
          <StorageBigImageSvg />
        </div>
      ) : format === ".xls" ? (
        <div className="storageFolder">
          <StorageBigExelSvg />
        </div>
      ) : (
        <div className="storageFolder">
          <StorageBigUnknownFileSvg />
        </div>
      )}
      <p className="fileName">{fileName}</p>
      <p className="filesInsideAndCapacity">
        {Type === "Directory" && <>{filesinside} File inside -</>} {capacity}
      </p>

      <p className="lastUpdate">Last update {lastUpdate}</p>
      <div className="detailedInfo">
        {[
          ["Type:", Type, false],
          ["Owner:", owner, true],
          ["Edited:", `${edited} ${dateEdited}`, true],
          ["Created:", `${created} ${dateCreated}`, true],
        ].map(([label, value, addClass]) => (
          <div className="detailedInfoRow" key={label}>
            <div className="titlesDetailedInfo">
              <p
                className={
                  "titleDetailedInfo " +
                  (addClass ? "mrgnTitleddetailsInfo" : "")
                }
              >
                {label}
              </p>
            </div>
            <div className="infoDetailedInfo">
              <p
                className={
                  "infoDetailedInfoP " +
                  (addClass ? "mrgnTitleddetailsInfo" : "")
                }
              >
                {value}
              </p>
            </div>
          </div>
        ))}
      </div>
      {Type !== "Directory" && (
        <>
          {" "}
          <p className="tagsTitle">Tags:</p>
          <div className="tags">
            {tags.map((el, index) => {
              let width;
              el.length > 6
                ? (width = el.length * 9.875)
                : el.length > 4
                ? (width = el.length * 11.875)
                : (width = el.length * 13.875);
              return (
                <div key={`directory-${index}`} style={{width: width}} className="tag">
                  <p className="tagName">{el}</p>
                  <div className="storageCloseTag">
                    <StorageCloseTagSvg />
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};
export default FileInfo;
