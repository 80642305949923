export const DaysOfWeekNumbersArray = [
  {
    id: 0,
    toDisplay: "Sun",
    toSend: "SU",
  },
  {
    id: 1,
    toDisplay: "Mon",
    toSend: "MO",
  },
  {
    id: 2,
    toDisplay: "Tue",
    toSend: "TU",
  },
  {
    id: 3,
    toDisplay: "Wed",
    toSend: "WE",
  },
  {
    id: 4,
    toDisplay: "Thu",
    toSend: "TH",
  },
  {
    id: 5,
    toDisplay: "Fri",
    toSend: "FR",
  },
  {
    id: 6,
    toDisplay: "Sat",
    toSend: "SA",
  },
];
export const DaysOfMonthNumbersArray = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30, 31,
];
export const MonthsOfYearNumbersArray = [
  {
    name: "Jan",
    id: 1,
  },
  {
    name: "Feb",
    id: 2,
  },
  {
    name: "Mar",
    id: 3,
  },
  {
    name: "Apr",
    id: 4,
  },
  {
    name: "May",
    id: 5,
  },
  {
    name: "Jun",
    id: 6,
  },
  {
    name: "Jul",
    id: 7,
  },
  {
    name: "Aug",
    id: 8,
  },
  {
    name: "Sep",
    id: 9,
  },
  {
    name: "Oct",
    id: 10,
  },
  {
    name: "Nov",
    id: 11,
  },
  {
    name: "Dec",
    id: 12,
  },
];
