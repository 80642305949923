import {AppointmentUpdateType} from "types/deal";
import useTypedSelector from "../../../utils/hooks/useTypedSelector";
import {User} from "../../../types/user";

const AppointmentUpdates: React.FC<{updates: AppointmentUpdateType[]}> = ({
  updates,
}) => {
  const currentUser = useTypedSelector(
    (state) => state.AuthReducer.currentUser,
  ) as User;
  return (
    <>
      <div className="appointment__list">
        {updates.map((el, i) => {
          const lastEl = updates.length - 1;
          const {action} = el;
          if (i === lastEl) {
            return (
              <p
                key={i}
                style={{overflow: "hidden"}}
                className="appointment__details"
                data-testid="signing-appointment-creation-details"
              >
                {action ?? "Created"} by {el.appointment_updated_by.full_name}{" "}
                on &nbsp;
                {new Date(el.appointment_updated_at).toLocaleString("en-US", {
                  timeZone: currentUser?.timezone,
                })}
              </p>
            );
          }
          return (
            <p
              key={i}
              style={{overflow: "hidden"}}
              className="appointment__details"
            >
              {action} by {el.appointment_updated_by.full_name} on &nbsp;
              {new Date(el.appointment_updated_at).toLocaleString("en-US", {
                timeZone: currentUser?.timezone,
              })}
            </p>
          );
        })}
      </div>
    </>
  );
};

export default AppointmentUpdates;
