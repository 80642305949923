import configuration from "utils/configuration";

const data = {
  httpPost: {
    title: "URLs (HTTP POST)",
    copyBlocks: [
      {
        label: "Production",
        link: `${configuration.apiBaseUrl}/integrations/website/submit`,
        generateKey: false,
        button: {
          copyText: "Copy",
          copiedText: "Copied",
        },
      },
      {
        label: "API Key",
        link: null,
        generateKey: true,
        button: {
          copyText: "Copy",
          copiedText: "Copied",
        },
      },
    ],
  },
  simpleExample: {
    title: "Simple example (JSON)",
    code: `
{
  "api_key":"4Z2DGn44b0G1pqxpMJqvOiYZ9sfv
  JUHloQlje4ZE4k3jixSahW",
  "deal_type":"Purchase",
  "closing_date":"12/22/2021",
  "client":{
    "first_name":"John",
    "last_name":"Smith",
    "email":"john@deeded.ca",
    "phone":"416-555-1155"
  },
  "address":{
    "address":"33090 Best Ave.",
    "city":"Markham",
    "state":"ON","code":"L4E 2T1"
  },
  "agreements":[],
  "referral_name":"Darren Broker"
}
    `,
  },
  requiredFields: {
    title: "Required fields",
    array: [
      {
        item: {
          name: "deal_type",
          list: [
            {
              name: "string",
              list: [],
            },
            {
              name: "Can be one of the following:('Purchase', 'Sale', 'Mortgage')",
              list: [],
            },
          ],
          listNumbers: [],
        },
      },
      {
        item: {
          name: "сlosing_date",
          list: [
            {
              name: "string",
              list: [],
            },
            {
              name: "Should be dateformat:m/d/YYYY",
              list: [
                "m-Numeric representation of a month, without leading zeros",
                "d-Day of the month without leading zeros",
                "YYYY-A full numeric representation of a year, 4 digits",
              ],
            },
            {
              name: 'Example: "3/25/2021"',
              list: [],
            },
          ],
          listNumbers: [],
        },
      },
      {
        item: {
          name: "address",
          list: [
            {
              name: "array",
              list: [],
            },
            {
              name: "array fields (address, city, state, code)",
              list: [],
            },
            {
              name: "required fields (address, city, state)",
              list: [],
            },
            {
              name: "optional (code)",
              list: [],
            },
          ],
          listNumbers: [
            {
              name: "address.address",
              list: ["string", "max length 50 characters", "required"],
              example: null,
            },
            {
              name: "address.city",
              list: ["string", "max length 50 characters", "required"],
              example: null,
            },
            {
              name: "address.state (province)",
              list: ["string", "max length 50 characters", "required"],
              example: null,
            },
            {
              name: "address.code (postal code)",
              list: ["string", "max length 50 characters"],
              example: null,
            },
          ],
        },
      },
      {
        item: {
          name: "client",
          list: [
            {
              name: "array",
              list: [],
            },
            {
              name: "array fields (first_name, last_name, email, phone)",
              list: [],
            },
            {
              name: "required fields (first_name, last_name, email, phone)",
              list: [],
            },
          ],
          listNumbers: [
            {
              name: "client.first_name",
              list: ["string", "max length 50 characters", "required"],
              example: null,
            },
            {
              name: "client.last_name",
              list: ["string", "max length 50 characters", "required"],
              example: null,
            },
            {
              name: "client.email",
              list: ["string", "max length 50 characters", "required"],
              example: null,
            },
            {
              name: "client.phone",
              list: [
                "string",
                "max length 50 characters",
                `number format xxx-xxx-xxxx (regex:/\\d{3}-\\d{3}-\\d{4}/)`,
              ],
              example: null,
            },
          ],
        },
      },
      {
        item: {
          name: "referral_name",
          list: [
            {
              name: "string",
              list: [],
            },
          ],
          listNumbers: [],
        },
      },
      {
        item: {
          name: "agreements",
          list: [
            {
              name: "array",
              list: [],
            },
          ],
          listNumbers: [
            {
              name: "agreements.document_type (options, please see below)",
              list: ["string", "required"],
              example: null,
            },
            {
              name: "agreements.files",
              list: [
                "string",
                "required",
                "available mime types (jpeg, png, gif, heic, txt, csv, pdf, doc, docx, rtf, zip, xls, xlsx)",
              ],
              example: {
                title: "Example",
                code: `
agreements: [
  {
    "document_type": 
    "Agreement of Purchase & Sale",
    "files": [
    {
      "name": "image_name.jpg",
      "size": 63345,
      "type": "image/jpeg",
      "data": “data:image   
    jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/
    4QAYRXhpZgAAS..."
      }
    ]
  }
]
                `,
              },
            },
            {
              name: "agreements.document_typeoptions:",
              list: [],
              example: {
                title: "Example",
                code: `
{
  transaction: "Purchase",
  list: [
    {
      value: "Agreement of Purchase & Sale",
    },
    {
      value: "Notice of Fulfillment (APS)",
    },
    {
      value: "Status Certificate",
    },
    {
      value: "Lease Agreement",
    },
    {
      value: "Other (Custom Name)"
    },
  ],
},
{
  transaction: "Sale",
  list: [
    {
      value: "Agreement of Purchase & Sale",
    },
    {
      value: "Notice of Fulfillment (APS)",
    },
    {
      value: "Lease Agreement",
    },
    {
      value: "Property Survey",
    },
    {
      value: "Other (Custom Name)",
    },
  ],
},
{
  transaction: "Mortgage",
  list: [
    {
      value: "Mortgage Commitment",
    },
    {
      value: "Current Mortgage Statement",
    },     
    {      
      value: "Current Statement for Additional 
      Mortgage or Secured Line ofCredit",
    },
    {      
      value: "Current Property Tax Bill",  
    },
    {      
      value: "Current Statements for Debts required 
      to be paid out",      
    },
    {
    value: "Void Cheque",
    },
    {
    value: "Broker Letter of Direction",
      },
    {
    value: "Other (Custom Name)",
      },
  ],
}
                `,
              },
            },
          ],
        },
      },
    ],
  },
};

export default data;
