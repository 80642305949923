import React from "react";

const FolderSimple = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.25 7.5625V17.2639C19.25 17.426 19.1856 17.5814 19.071 17.696C18.9564 17.8106 18.801 17.875 18.6389 17.875H3.4375C3.25516 17.875 3.0803 17.8026 2.95136 17.6736C2.82243 17.5447 2.75 17.3698 2.75 17.1875V5.5C2.75 5.31766 2.82243 5.1428 2.95136 5.01386C3.0803 4.88493 3.25516 4.8125 3.4375 4.8125H8.02083C8.16959 4.8125 8.31433 4.86075 8.43333 4.95L10.8167 6.7375C10.9357 6.82675 11.0804 6.875 11.2292 6.875H18.5625C18.7448 6.875 18.9197 6.94743 19.0486 7.07636C19.1776 7.2053 19.25 7.38016 19.25 7.5625Z"
        stroke="#F16764"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5625 12.375H14.4375"
        stroke="#F16764"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6875 9.625L14.4375 12.375L11.6875 15.125"
        stroke="#F16764"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FolderSimple;
