import {useEffect, useState} from "react";
import {Box, IconButton, Typography} from "@mui/material";
import ProfileThirdPartyThreeDotsSvg from "../../svg/ProfileThirdPartyThreeDotsSvg";
import {styled} from "@mui/material/styles";
import constants from "../../../../../styles/constants";
import {EmailInfoType} from "../../types/profileThirdPartyTypes";
import ProfileIntegrationThreeDEmailComponentViewModeTools from "./ProfileIntegrationThreeDEmailComponentViewModeTools";

interface ProfileIntegrationThreeDEmailComponentViewModeProps {
  emailInfo: EmailInfoType;
  mode: "view" | "edit";
  setMode: (mode: "view" | "edit") => void;
  index: number;
  fullEmailsListLength: number;
  emailsList: EmailInfoType[];
  setEmailsList: (emailsList: EmailInfoType[]) => void;
  updateEmailsList: (emailsList: EmailInfoType[]) => void;
}
const ProfileIntegrationThreeDEmailComponentViewMode: React.FC<
  ProfileIntegrationThreeDEmailComponentViewModeProps
> = ({
  emailInfo,
  setMode,
  index,
  fullEmailsListLength,
  emailsList,
  setEmailsList,
  updateEmailsList,
}) => {
  const [isShowedTools, setIsShowedTools] = useState(false);
  useEffect(() => {
    document.body.addEventListener("click", () => {
      setIsShowedTools(false);
    });
    return () => {
      document.body.removeEventListener("click", () => {
        setIsShowedTools(false);
      });
    };
  }, []);
  return (
    <>
      <Box
        onClick={(e) => {
          e.stopPropagation();
        }}
        sx={{
          display: "grid",
          gridTemplateColumns: "5fr 7fr 3fr",
          position: "relative",
          marginBottom: index + 1 === fullEmailsListLength ? "6px" : "10px",
        }}
      >
        <BodyTypography
          sx={{
            maxWidth: "160px",
            textOverflow: "ellipsis",
            overflowX: "hidden",
            whiteSpace: "no-wrap",
          }}
        >
          {emailInfo?.full_name}
        </BodyTypography>
        <BodyTypography
          sx={{
            maxWidth: "235px",
            textOverflow: "ellipsis",
            overflowX: "hidden",
            whiteSpace: "no-wrap",
          }}
        >
          {emailInfo?.email}
        </BodyTypography>
        <Box
          onClick={(e) => {
            e.stopPropagation();
          }}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <IconButton
            onClick={(e) => {
              e.nativeEvent.stopImmediatePropagation();
              setIsShowedTools(true);
            }}
            sx={{
              minWidth: "0px",
              marginTop: "-10px",
            }}
          >
            <ProfileThirdPartyThreeDotsSvg />
          </IconButton>
          <ProfileIntegrationThreeDEmailComponentViewModeTools
            updateEmailsList={updateEmailsList}
            index={index}
            setEmailsList={setEmailsList}
            emailsList={emailsList}
            setMode={setMode}
            isShowedTools={isShowedTools}
          />
        </Box>
      </Box>
    </>
  );
};
const BodyTypography = styled(Typography)({
  fontSize: "14px",
  color: constants.colors.intakeTitle,
});
export default ProfileIntegrationThreeDEmailComponentViewMode;
