import React, {useState} from "react";
import {SelectProps} from "@mui/material/Select";
import {Box, Select, SxProps, Theme, Typography, useTheme} from "@mui/material";
import {styled} from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import constants from "../../styles/constants";
import WarningIcon from "../../components/Svg/WarningIcon";

type DeededSelectV2Props = Omit<SelectProps, "error"> & {
  error?: string;
  boxSx?: SxProps<Theme>;
  IconComponent?: React.ElementType<unknown>;
  menuItemStyles?: SxProps<Theme> | undefined;
  fieldsetBorderColor?: string;
  hideError?: boolean;
  disabled?: boolean;
  sx?: SxProps<Theme>;
  sxMenu?: SxProps<Theme>;
};

const DeededSelectV2: React.FC<DeededSelectV2Props> = ({
  children,
  error,
  boxSx,
  IconComponent,
  menuItemStyles,
  fieldsetBorderColor,
  hideError,
  disabled,
  sx,
  sxMenu,
  ...props
}) => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const theme = useTheme();

  return (
    <Box sx={{position: "relative", ...(boxSx ?? {})}}>
      <StylesDeededSelectV2
        {...props}
        sx={sx}
        disabled={disabled}
        fieldsetBorderColor={fieldsetBorderColor}
        error={error as undefined}
        isOpenMenu={isOpenMenu}
        onOpen={() => setIsOpenMenu(true)}
        onClose={() => setIsOpenMenu(false)}
        data-selenium-test="DeededSelectV2"
        MenuProps={{
          disableScrollLock: true,
          sx: {
            "& .MuiList-root": {
              paddingTop: "13px",
              paddingBottom: "13px",
            },
            "& .MuiPaper-root.MuiPaper-elevation": {
              borderRadius: "5px",
              marginTop: "3px",
              [theme.breakpoints.down("sm")]: {
                minWidth: "325px !important",
                left: "25px !important",
              },
              "&::-webkit-scrollbar": {
                width: "5px",
                scrollbarWidth: "thin",
              },
            },
            "& .MuiMenuItem-root:not(.Mui-selected):hover": {
              backgroundColor: constants.colors.redVeryPale,
              color: constants.colors.red,
              transition: constants.transitions,
            },
            "& .MuiMenuItem-root.Mui-selected": {
              backgroundColor: `${constants.colors.white} !important`,
              color: constants.colors.red,
              "& .svg-icon-controlled": {
                transition: constants.transitions,
                "& .filled": {
                  fill: constants.colors.red,
                  transition: constants.transitions,
                },
                "& .stroked": {
                  stroke: constants.colors.red,
                  transition: constants.transitions,
                },
              },
              "&:not(.Mui-selected):hover": {
                transition: constants.transitions,
                backgroundColor: `${constants.colors.redVeryPale} !important`,
                color: `${constants.colors.red} !important`,
                "& .svg-icon-controlled": {
                  transition: constants.transitions,
                  "& .filled": {
                    fill: constants.colors.red,
                    transition: constants.transitions,
                  },
                  "& .stroked": {
                    stroke: constants.colors.red,
                    transition: constants.transitions,
                  },
                },
              },
            },

            "& .MuiMenuItem-root": {
              height: "33px",
              fontFamily: "Montserrat",
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "17px",
              display: "flex",
              alignItems: "center",
              textTransform: "capitalize",
              transition: constants.transitions,
              [theme.breakpoints.down("sm")]: {
                minHeight: "40px !important",
              },
              "& .svg-icon-controlled": {
                transition: constants.transitions,
                "& .filled": {
                  fill: constants.colors.title,
                  transition: constants.transitions,
                },
                "& .stroked": {
                  stroke: constants.colors.title,
                  transition: constants.transitions,
                },
              },
              "&:not(.Mui-selected):hover": {
                transition: constants.transitions,
                "& .svg-icon-controlled": {
                  transition: constants.transitions,
                  "& .filled": {
                    fill: constants.colors.red,
                    transition: constants.transitions,
                  },
                  "& .stroked": {
                    stroke: constants.colors.red,
                    transition: constants.transitions,
                  },
                },
              },
            },
            "& .MuiMenuItem-root .MuiTypography-root": {
              fontFamily: "'Montserrat'",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "17px",
              display: "flex",
              alignItems: "center",
              marginTop: "3px",
              marginLeft: "8px",
            },
            ...sxMenu,
          },
        }}
        IconComponent={IconComponent ?? KeyboardArrowDownIcon}
        {...props}
      >
        {children}
      </StylesDeededSelectV2>
      {error && hideError && (
        <FloatingInputError>
          <WarningIcon />
          {error}
        </FloatingInputError>
      )}
    </Box>
  );
};

const StylesDeededSelectV2 = styled(Select, {
  shouldForwardProp: (prop) => !["fieldsetBorderColor", "isOpenMenu"].includes(prop as string)
})<{
  fieldsetBorderColor?: string;
  error?: string;
  isOpenMenu?: boolean;
  disabled?: boolean;
}>(({fieldsetBorderColor, error, isOpenMenu, disabled}) => ({
  "&.MuiInputBase-root": {
    "&:hover fieldset": {
      borderColor: disabled
        ? constants.colors.gray
        : fieldsetBorderColor
        ? fieldsetBorderColor
        : constants.colors.red,
      transition: constants.transitions,
    },
    "&.Mui-focused": {
      "& fieldset": {
        borderColor: fieldsetBorderColor
          ? fieldsetBorderColor
          : constants.colors.red,
        transition: constants.transitions,
      },
    },
  },
  "& fieldset": {
    transition: constants.transitions,
    borderRadius: "5px",
    borderColor: error
      ? `${constants.colors.red} !important`
      : fieldsetBorderColor
      ? fieldsetBorderColor
      : constants.colors.red,
    borderWidth: "1px !important",
  },
  "&.MuiOutlinedInput-root .MuiSelect-select": {
    cursor: `${disabled ? "no-drop" : "pointer"} !important`,
  },
  "&.MuiOutlinedInput-root .MuiSelect-select .svg-icon-controlled": {
    marginLeft: "2px",
  },
  "&.MuiOutlinedInput-root .MuiSelect-select .MuiTypography-root": {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "17px",
    textTransform: "capitalize",
    marginLeft: "8px",
  },
  "&.MuiOutlinedInput-root": {
    border: "none",
    width: "100%",
    height: "40px",
    background: disabled
      ? constants.colors.white
      : !isOpenMenu
      ? constants.colors.red
      : constants.colors.white,
    color: !isOpenMenu ? constants.colors.white : constants.colors.red,
    transition: constants.transitions,
    "& svg": {
      transition: constants.transitions,
      "&:not(.svg-icon-controlled):not(.deededSelectV2IconSvg)": {
        fill: disabled
          ? constants.colors.gray
          : !isOpenMenu
          ? constants.colors.white
          : constants.colors.red,
        transition: constants.transitions,
      },
      "&.svg-icon-controlled": {
        path: {
          stroke: `${
            disabled
              ? constants.colors.gray
              : !isOpenMenu
              ? constants.colors.white
              : constants.colors.red
          } !important`,
          transition: constants.transitions,
        },
      },
    },
    "& > svg": {
      fill: !isOpenMenu ? constants.colors.white : constants.colors.red,
      transform: `rotate(0deg) scaleY(${isOpenMenu ? -1 : 1})`,
    },
    "&:hover": {
      background: constants.colors.white,
      transition: constants.transitions,
      color: disabled ? constants.colors.gray : constants.colors.red,
      "& svg": {
        transition: constants.transitions,
        "&:not(.svg-icon-controlled):not(.deededSelectV2IconSvg)": {
          fill: disabled ? constants.colors.gray : constants.colors.red,
          transition: constants.transitions,
        },
        "&.svg-icon-controlled": {
          path: {
            stroke: `${
              disabled ? constants.colors.gray : constants.colors.red
            } !important`,
            transition: constants.transitions,
          },
        },
      },
    },
    "& .MuiSelect-select": {
      display: "flex",
      alignItems: "center",
    },
    "& .berbix-idv-icon-arrow": {
      position: "absolute",
      top: "17px",
      right: "7px",
    },
    "& .svg-icon-controlled": {
      "& .filled": {
        fill: constants.colors.white,
      },
      "& .stroked": {stroke: constants.colors.white},
    },
  },
}));

const FloatingInputError = styled(Typography)({
  color: constants.colors.red,
  ...constants.fontConfig_TEMP,
  marginTop: "5px",
  fontSize: "10px",
  display: "flex",
  alignItems: "center",
  svg: {
    marginRight: "3px",
    marginBottom: "1px",
  },
});

export default DeededSelectV2;
