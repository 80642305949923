import React, {useState, useEffect, SetStateAction, ComponentType} from "react";
import {
  getDealByID,
  getActivityLogsByID,
  getFolderInfo,
  requestAccessToDealFiles,
} from "api";
import {useDispatch} from "react-redux";
import {ProviderContext} from "notistack";
import {withSnackbar} from "utils/withSnackbar";
import {useRouteMatch, useHistory} from "react-router-dom";
// SVG
import FilesStoragePage from "./FilesStoragePage";
import StorageHeading from "../../Dashboard/StorageCommonComponents/StorageHeading";
import AddNewDocumentPage from "../../Dashboard/StorageCommonComponents/AddNewDocumentPage/AddNewDocumentPage";
import {
  addBreadCrumbsAC,
  setClientNameAC,
} from "../../../redux/reducers/storageReducer";
import {Deal} from "types/deal";
import {Box, styled} from "@mui/material";
import {useAppSelector} from "../../../redux/hooks";
import {setCurrentDealDealTypeAC} from "../../../redux/reducers/dealsReducer";
import DeededButton from "v2/components/DeededButton";
import {isStaff} from "utils/permissionRoles";
import DetailsTabNavigation from "../../Dashboard/DetailsTabNavigation/DetailsTabNavigation";
import configuration from "../../../utils/configuration";

const DealStorage: React.FC<{
  enqueueSnackbar: (
    message: string,
    params: {
      variant: string;
      autoHideDuration: number;
    },
  ) => void;
}> = ({enqueueSnackbar}) => {
  const match = useRouteMatch() as unknown as {params: {id: number}};
  const history = useHistory();
  const user = useAppSelector((state) => state.AuthReducer.currentUser);
  const {folderID, folderName} = useAppSelector(
    (state) => state.StorageReducer,
  );
  const dispatch = useDispatch();
  const [deal, setDeal] = useState<Deal | null>(null);
  const [logs, setLogs] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastpage] = useState(0);
  const [sharedUsers, setSharedUsers] = useState<number[] | null>(null);

  const breadCrumbs = useAppSelector(
    (state) => state.StorageReducer.breadCrumbs,
  );
  useEffect(() => {
    if (deal?.deal_type) {
      dispatch(setCurrentDealDealTypeAC(deal?.deal_type));
    }
  }, [deal, dispatch]);
  useEffect(() => {
    let isSubscribed = true;
    getDealByID(match.params.id)
      .then((res) => {
        let deal = res.data;

        if (isSubscribed) {
          setDeal(deal);
        }

        if (folderID) {
          return;
        }

        dispatch(setClientNameAC(deal));
      })
      .catch((error) => {
        if (error.response?.status === 403) {
            return history.push('/not-found');
        }
        enqueueSnackbar(error.message, {
          variant: "error",
          autoHideDuration: configuration.autoHideErrorDuration,
        });
    });
    if (user.role === "Admin" || user.role === "System Admin") {
      getActivityLogsByID(match.params.id, currentPage)
        .then((res) => {
          if (isSubscribed) {
            const {data, current_page, last_page} = res?.data;
            setLogs(data as unknown as SetStateAction<null>);
            setCurrentPage(current_page);
            setLastpage(last_page);
          }
        })
        .catch((error) =>
          enqueueSnackbar(error.message, {
            variant: "error",
            autoHideDuration: configuration.autoHideErrorDuration,
          }),
        );
    }
    return () => {
      isSubscribed = false;
    };
  }, [
    currentPage,
    dispatch,
    enqueueSnackbar,
    folderID,
    match.params.id,
    user.role,
  ]);

  useEffect(() => {
    if (!folderID) {
      return;
    }

    getFolderInfo(folderID).then((folderInfo) => {
      setSharedUsers(folderInfo.dealFile.shared_users);
      dispatch(
        addBreadCrumbsAC({
          name: folderName,
          id: folderID,
          deal_id: folderInfo.dealFile.deal_id,
          deal_type: folderInfo.dealFile.deal_type,
          clientId: folderInfo.dealFile.client.id,
          clientFullName: folderInfo.dealFile.client.full_name,
        }),
      );
    });
  }, [dispatch, folderID, folderName]);
  const [addNewDocument, setAddNewDocument] = useState(false);

  const requestWorkdocsAccess = () => {
    const [isLoading, setIsLoading] = useState(false);
    return (
      deal &&
      isStaff(user.role) &&
      sharedUsers && (
        <CTAButton
          onClick={() => {
            setIsLoading(true);
            requestAccessToDealFiles(deal.id)
              .then(() => {
                setSharedUsers([...(sharedUsers || []), Number(user.id)]);
                enqueueSnackbar("Success", {
                  variant: "success",
                  autoHideDuration: 1500,
                });
              })
              .catch((error) =>
                enqueueSnackbar(error.response?.data?.error, {
                  variant: "error",
                  autoHideDuration: configuration.autoHideErrorDuration,
                }),
              )
              .finally(() => setIsLoading(false));
          }}
          disabled={
            !user.workdocs_user_status ||
            (sharedUsers || []).includes(Number(user.id)) ||
            isLoading
          }
        >
          {isLoading ? "Loading..." : "See In Workdocs"}
        </CTAButton>
      )
    );
  };

  return (
    <Box className="activity__wrapper">
      <DetailsTabNavigation />

      {(logs === null &&
        (user.role === "Admin" || user.role === "System Admin")) ||
      deal === null ? (
        <></>
      ) : (
        <div className="activity__container2" />
      )}

      <div style={{minHeight: 740, position: "relative"}}>
        <StorageHeading
          setAddNewDocument={setAddNewDocument}
          requestWorkdocsAccess={requestWorkdocsAccess}
        />
        <FilesStoragePage openFolderId={match?.params?.id} />
        {addNewDocument && (
          <AddNewDocumentPage
            addNewDocument={addNewDocument}
            setAddNewDocument={setAddNewDocument}
            breadCrumbs={breadCrumbs}
          />
        )}
      </div>
    </Box>
  );
};

export default withSnackbar(DealStorage as ComponentType<ProviderContext>);

const CTAButton = styled(DeededButton)(({theme}) => ({
  marginRight: "24px",
  marginTop: "11px",
  width: "160px",
  [theme.breakpoints.down(1435)]: {
    fontSize: "10px",
    width: "120px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    marginLeft: "20px",
    fontSize: "14px",
  },
}));
