import {useState} from "react";
import {EmailInfoType} from "../../types/profileThirdPartyTypes";
import ProfileIntegrationThreeDEmailComponentViewMode from "./ProfileIntegrationThreeDEmailComponentViewMode";
import ProfileIntegrationThreeDEmailComponentEditMode from "./ProfileIntegrationThreeDEmailComponentEditMode";

interface ProfileIntegrationThreeDEmailComponentProps {
  emailInfo: EmailInfoType;
  index: number;
  emailsList: EmailInfoType[];
  setEmailsList: (emailsList: EmailInfoType[]) => void;
  updateEmailsList:(emailsList: EmailInfoType[]) => void;
}
const ProfileIntegrationThreeDEmailComponent: React.FC<
  ProfileIntegrationThreeDEmailComponentProps
> = ({emailInfo, index, setEmailsList, emailsList, updateEmailsList}) => {
  const [mode, setMode] = useState("view");
  const editEmail = (email: string, full_name: string) => {
    const newEmailsList = new Array(emailsList.length)
      .fill(0)
      .map((particularEmail, indexOfParticularEmail) => {
        if (indexOfParticularEmail === index) {
          return {
            email,
            full_name,
          };
        } else {
          return emailsList[indexOfParticularEmail];
        }
      });

    setEmailsList(newEmailsList);
    updateEmailsList(newEmailsList);
  };
  return (
    <>
      {mode === "view" && (
        <ProfileIntegrationThreeDEmailComponentViewMode
          updateEmailsList={updateEmailsList}
          setEmailsList={setEmailsList}
          emailsList={emailsList}
          setMode={setMode}
          mode={mode}
          emailInfo={emailInfo}
          index={index}
          fullEmailsListLength={emailsList.length}
        />
      )}
      {mode === "edit" && (
        <ProfileIntegrationThreeDEmailComponentEditMode
          onSubmitEvent={({email, full_name}) => {
            editEmail(email, full_name);
            setMode("view");
          }}
          onClose={() => {
            setMode("view");
          }}
          emailInfo={emailInfo}
        />
      )}
    </>
  );
};
export default ProfileIntegrationThreeDEmailComponent;
