import React, {useEffect} from "react";
import moment from "moment";
import {Box, styled, Typography} from "@mui/material";
import constants from "styles/constants";
import {useAppSelector} from "redux/hooks";
import {useGetCountMobileSignerAppointmentsQuery} from "redux/reducers/calendarApi";
import useUser from "utils/hooks/useUser";
import {scrollbarStyles} from "../constants/scrollbarStyles";
import {getMobileSignersSigningAppointmentCountTableArrayByExactDate} from "../utils/getMobileSignersSigningAppointmentCountTableArrayByExactDate";
import {getMobileSignersSigningAppointmentCountTableArrayAllSum} from "../utils/getMobileSignersSigningAppointmentCountTableArrayAllSum";
import {rolesForGettingSigningAppointments} from "../constants/rolesForGettingSigningAppointments";
import {allProvincesLabelsList} from "../constants/allProvincesLabelsList";

interface CalendarClosingOrAppointmentsDealByDateProps {
  title?: string;
  cellDate?: Date;
  regions?: string[];
  isShowClosingDealsHint: boolean;
  setIsShowEventCountsHint: (isShowClosingDealsHint: boolean) => void;
  isStartOfWeek: boolean;
  setIsHoveredOnHint: (isHoveredOnHint: boolean) => void;
  isShowDayNumberCellPopupDownDirection: boolean;
  isHoveredOnHint: boolean;
  eventsCountByName: {
    signer?: {
      name: string;
      count: number;
    }[];
    lawyer?: {
      name: string;
      count: number;
    }[];
    lawClerk?: {
      name: string;
      count: number;
    }[];
  };
}

const CalendarClosingOrAppointmentsDealByDate: React.FC<
  CalendarClosingOrAppointmentsDealByDateProps
> = ({
  title,
  cellDate,
  regions,
  isHoveredOnHint,
  eventsCountByName,
  isShowClosingDealsHint,
  setIsShowEventCountsHint,
  isStartOfWeek,
  setIsHoveredOnHint,
  isShowDayNumberCellPopupDownDirection,
}) => {
  const calendarType = useAppSelector(
    (state) => state.CalendarReducer.calendarType,
  );
  const regionsArray = regions as string[];
  const user = useUser().user;
  const {
    data: mobileSignersAppointments,
    isLoading: isLoadingMobileSignerAppointments,
    refetch: refetchMobileSignerAppointments,
  } = useGetCountMobileSignerAppointmentsQuery(
    {
      dateFrom: moment(cellDate).format("YYYY-MM-DD"),
      dateTo: moment(cellDate).format("YYYY-MM-DD"),
      region:
        regionsArray?.length === allProvincesLabelsList.length
          ? "ALL"
          : regionsArray?.join(","),
      users: "ALL",
    },
    {skip: !rolesForGettingSigningAppointments.includes(user.role as string)},
  );
  useEffect(() => {
    refetchMobileSignerAppointments();
  }, [refetchMobileSignerAppointments]);
  const mobileSignersSigningAppointmentCountTableArray =
    getMobileSignersSigningAppointmentCountTableArrayByExactDate(
      mobileSignersAppointments,
    );
  const mobileSignersSigningAppointmentCountTableArrayAllSum =
    getMobileSignersSigningAppointmentCountTableArrayAllSum(
      mobileSignersAppointments,
    );
  //this useEffect is created for correct displaying hints when calendarType === "Signing Appointment" and mouse enter in hint
  useEffect(() => {
    if (isHoveredOnHint) {
      setIsShowEventCountsHint(true);
    }
  }, [isHoveredOnHint, setIsShowEventCountsHint]);
  return (
    <>
      <Box
        onMouseEnter={() => {
          setIsHoveredOnHint(true);
          setIsShowEventCountsHint(true);
        }}
        onMouseLeave={() => {
          setIsHoveredOnHint(false);
          setIsShowEventCountsHint(false);
        }}
        sx={{
          position: "absolute",
          bottom: isShowDayNumberCellPopupDownDirection ? "unset" : "0",
          top: isShowDayNumberCellPopupDownDirection ? "0" : "unset",
          right:
            calendarType === "Signing Appointment"
              ? isStartOfWeek
                ? "-140px"
                : "30px"
              : isStartOfWeek
              ? "-150px"
              : "15px",
          background: "white",
          width: "156px",
          padding: "14px 12px",
          maxHeight: "368px",
          overflowY: "scroll",
          overflowX: "hidden",
          zIndex: "5",
          boxShadow: "0px 20px 50px rgba(0, 0, 0, 0.1)",
          borderRadius: "5px",
          ...scrollbarStyles,
        }}
      >
        <ClosingDealByDateTitle>
          {title ?? "Closing Deals"}
        </ClosingDealByDateTitle>

        {mobileSignersSigningAppointmentCountTableArray &&
          calendarType === "Signing Appointment" && (
            <>
              <RoleTitle>Mobile Signer</RoleTitle>
              {isLoadingMobileSignerAppointments ? (
                <StaffCountTypography sx={{marginTop: "10px"}}>
                  Loading...
                </StaffCountTypography>
              ) : (
                mobileSignersSigningAppointmentCountTableArray?.map(
                  (signer, index) => (
                    <StaffCountBox
                      key={index}
                      sx={{
                        marginTop: index === 0 ? "8px" : "12px",
                      }}
                    >
                      <StaffCountTypography
                        sx={{
                          maxWidth: "115px",
                          width: "115px",
                        }}
                      >
                        {signer.name}
                      </StaffCountTypography>
                      <StaffCountTypography>{signer.sum}</StaffCountTypography>
                    </StaffCountBox>
                  ),
                )
              )}
              <TotalStaffCount>
                Total:{" "}
                <TotalStaffCountNumber>
                  {mobileSignersSigningAppointmentCountTableArrayAllSum}
                </TotalStaffCountNumber>
              </TotalStaffCount>
            </>
          )}
        {eventsCountByName.lawyer && calendarType === "Closings" && (
          <>
            <RoleTitle>Lawyer</RoleTitle>
            {eventsCountByName.lawyer.map((lawyer, index) => (
              <StaffCountBox
                key={index}
                sx={{
                  marginTop: index === 0 ? "8px" : "12px",
                }}
              >
                <StaffCountTypography
                  sx={{
                    maxWidth: "115px",
                    width: "115px",
                  }}
                >
                  {lawyer.name}
                </StaffCountTypography>
                <StaffCountTypography>{lawyer.count}</StaffCountTypography>
              </StaffCountBox>
            ))}
            <TotalStaffCount>
              Total:{" "}
              <TotalStaffCountNumber>
                {eventsCountByName.lawyer.length !== 0 ? (
                  eventsCountByName.lawyer
                    .map((lawyer) => lawyer.count)
                    .reduce((sum, currCount) => sum + currCount)
                ) : (
                  <></>
                )}
              </TotalStaffCountNumber>
            </TotalStaffCount>
          </>
        )}
        {eventsCountByName.lawClerk && calendarType === "Closings" && (
          <>
            <RoleTitle>Law Clerk</RoleTitle>
            {eventsCountByName.lawClerk.map((lawClerk, index) => (
              <StaffCountBox
                key={index}
                sx={{
                  marginTop: index === 0 ? "8px" : "12px",
                }}
              >
                <StaffCountTypography
                  sx={{
                    maxWidth: "115px",
                    width: "115px",
                  }}
                >
                  {lawClerk.name}
                </StaffCountTypography>
                <StaffCountTypography>{lawClerk.count}</StaffCountTypography>
              </StaffCountBox>
            ))}
            <TotalStaffCount>
              Total:{" "}
              <TotalStaffCountNumber>
                {eventsCountByName.lawClerk.length !== 0 ? (
                  eventsCountByName.lawClerk
                    .map((lawClerk) => lawClerk.count)
                    .reduce((sum, currCount) => sum + currCount)
                ) : (
                  <></>
                )}
              </TotalStaffCountNumber>
            </TotalStaffCount>
          </>
        )}
      </Box>
    </>
  );
};
const ClosingDealByDateTitle = styled(Typography)({
  fontFamily: "'Montserrat'",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "20px",
  display: "flex",
  alignItems: "center",
  color: constants.colors.sortingColor,
});
const RoleTitle = styled(Typography)({
  fontFamily: "'Montserrat'",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "12px",
  lineHeight: "15px",
  color: constants.colors.red,
  marginTop: "15px",
});
const StaffCountBox = styled(Box)({
  display: "flex",
});
const StaffCountTypography = styled(Typography)({
  fontFamily: "'Montserrat'",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "12px",
  lineHeight: "15px",
  display: "flex",
  alignItems: "center",
  color: constants.colors.sortingColor,
});
const TotalStaffCount = styled(Typography)({
  fontFamily: "'Montserrat'",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "12px",
  lineHeight: "15px",
  display: "flex",
  alignItems: "center",
  color: constants.colors.sortingColor,
  marginTop: "12px",
});
const TotalStaffCountNumber = styled("span")({
  color: constants.colors.red,
  marginLeft: "6px",
});
export default CalendarClosingOrAppointmentsDealByDate;
