import {useLocation} from "react-router-dom";

const useGetUrlDealsOrLeads = () => {
  const location = useLocation();
  const url = location.pathname.includes("deals") ? "deals" : "leads";

  return url;
};

export default useGetUrlDealsOrLeads;
