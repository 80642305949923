import React from "react";

const Reset = () => {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.5188 9.34766H21.0188V4.84766"
        stroke="#BDBDBD"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.8312 17.8313C16.6777 18.9857 15.2077 19.7721 13.6072 20.091C12.0067 20.4099 10.3475 20.2469 8.83966 19.6227C7.33177 18.9984 6.0429 17.941 5.13608 16.5842C4.22926 15.2273 3.74524 13.632 3.74524 12C3.74524 10.368 4.22926 8.7727 5.13608 7.41585C6.0429 6.059 7.33177 5.00158 8.83966 4.37735C10.3475 3.75313 12.0067 3.59014 13.6072 3.90902C15.2077 4.22789 16.6777 5.0143 17.8312 6.16875L21.0187 9.34688"
        stroke="#BDBDBD"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Reset;
