import React from 'react'

const CalendarHeaderSettingsSvgIcon = () => {
  return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 11.25V20.25" stroke="#F16764" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M12 3.75V8.25" stroke="#F16764" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M18.75 18.75V20.25" stroke="#F16764" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M18.75 3.75V15.75" stroke="#F16764" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M21 15.75H16.5" stroke="#F16764" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M5.25 15.75V20.25" stroke="#F16764" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M5.25 3.75V12.75" stroke="#F16764" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M3 12.75H7.5" stroke="#F16764" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M14.25 8.25H9.75" stroke="#F16764" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>

}
export default CalendarHeaderSettingsSvgIcon