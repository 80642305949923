import {SxProps, Theme} from "@mui/material";
import Box, {BoxProps} from "@mui/material/Box";

/**
 * Simple utility for adding margins
 */
const DeededSpace: React.FC<
  BoxProps & {
    x?: number;
    y?: number;
    sx?: SxProps<Theme>;
  }
> = ({x, y, sx, ...props}) => (
  <Box
    data-selenium-test="DeededSpace"
    sx={{
      width: x,
      height: y,
      flexShrink: 0,
      ...sx,
    }}
    {...props}
  />
);

export default DeededSpace;
