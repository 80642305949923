import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {useDispatch} from "react-redux";
import moment from "moment";
import {
    Box,
    IconButton,
    styled,
    SwipeableDrawer,
    Typography,
    useTheme,
} from "@mui/material";
import {SwipeableDrawerProps} from "@mui/material/SwipeableDrawer";
import useWindowWidth from "utils/hooks/useWindowWidth";
import useTypedSelector from "utils/hooks/useTypedSelector";
import {useToggleMaterialUiDrawer} from "utils/toggleMaterialUiDrawer";
import {
  CalendarEvent,
  setIsOpenedEventCreatePopupAC,
} from "redux/reducers/calendarReducer";
import {ClosingsCountApiType} from "redux/reducers/types/calendarApiTypes";
import {useAppSelector} from "redux/hooks";
import constants from "styles/constants";
import DeededButton from "v2/components/DeededButton";
import DeededPopup from "v2/components/DeededPopup";
import DeededScrollToTopIconButton from "v2/components/DeededScrollToTopIconButton";
import {BlockedEvent} from "types/CalendarComponent/blockedEventTypes";
import {getSortedArrayOfEventsForMobile} from "../../utils/getSortedArrayOfEventsForMobile";
import {useClosingsSum} from "../../utils/hooks/useClosingsSum";
import CalendarHeaderSettingsCloseSvg from "../../svg/CalendarHeaderSettingsCloseSvg";
import ClockForMobileCalendarIconButtonSvg from "../../svg/ClockForMobileCalendarIconButtonSvg";
import {CalendarMobileContext} from "../../context/CalendarMobileContext";
import CalendarMobileHeader from "../CalendarMobileHeader";
import CalendarMobileWeekMonth from "../CalendarMobileWeekMonth";
import BigCalendarCreateSigningAppointment from "../BigCalendarCreateSigningAppointment";
import CalendarEventsCountingTables from "../CalendarEventsCountingTables";
import CalendarClosingDates from "../CalendarClosingDates";
import BigCalendarBlockTime from "../BigCalendarBlockTime";
import {stylesFactory} from "../BigCalendar";
import CalendarMobileSortedEventComponents from "./CalendarMobileSortedEventComponents";
import CalendarMobileSortedClosingsComponents from "./CalendarMobileSortedClosingsComponents";
import useUser from "utils/hooks/useUser";
import { shouldShowCalendarBlockTime } from "utils/permissionRoles";

const CalendarMobile: React.FC<{}> = () => {
  const {
    date,
    setDate,
    view,
    totalNumberOf,
    currentPageForCreateEventPopup,
    setSelectedBlockedEvent,
    selectedBlockedEvent,
    getBlockedEffectFunction,
    dateStart,
    dateEnd,
    setShowModal,
    getEventsEffectFunction,
    setCurrentPageForCreateEventPopup,
  } = useContext(CalendarMobileContext);
  const theme = useTheme();
  const { sm } = theme.breakpoints.values;
  const windowWidth = useWindowWidth();
  const calendarType = useTypedSelector(
    (state) => state.CalendarReducer.calendarType,
  );
  const { user } = useUser();
  const box = document.getElementById("MainCalendarBox");
  const closingsSum = useClosingsSum(view);
  const [isOpenedCountingTablesDrawer, setIsOpenedCountingTablesDrawer] =
    useState(false);
  const dispatch = useDispatch();
  const [isOpenedDetailsDrawer, setIsOpenedDetailsDrawer] = useState(false);
  const isSigningAppointmentCalendarType =
    calendarType === "Signing Appointment";
  const isClosingsCalendarType = calendarType === "Closings";
  const calendarEventsFromStore = useTypedSelector(
    (state) => state.CalendarReducer.events,
  );
  const closingDates = useTypedSelector(
    (state) => state.CalendarReducer.closingDates as {data: CalendarEvent[]},
  );
  const topOffset = useCalendarMobileScrollTop();
  let blockedAndEvents = calendarEventsFromStore.concat(closingDates.data);
  const sortedArrayOfEventsForMobile = useMemo(
    () => getSortedArrayOfEventsForMobile(blockedAndEvents, date),
    [blockedAndEvents, date],
  );
  const toggleCountingTablesDrawer = useToggleMaterialUiDrawer(
    setIsOpenedCountingTablesDrawer,
  );
  const toggleDetailsDrawer = useToggleMaterialUiDrawer(
    setIsOpenedDetailsDrawer,
  );
  const isOpenedEventCreatePopup = useAppSelector(
    (state) => state.CalendarReducer.isOpenedEventCreatePopup,
  );
  return (
    <>
      <Box
        sx={{
          backgroundColor: constants.colors.white,
          paddingBottom: "80px",
        }}
      >
        {windowWidth < sm && <CalendarMobileHeader />}
        <Box
          sx={{
            padding: "0px 20px",
            width: "100%",
            marginBottom: "32px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {isSigningAppointmentCalendarType && (
            <DeededButton
              onClick={() => {
                setIsOpenedCountingTablesDrawer(true);
              }}
              kind={"secondary-v2"}
              sx={{
                borderRadius: "3px",
                width: "100%",
              }}
            >
              Signing appointment count{" "}
            </DeededButton>
          )}
          {isClosingsCalendarType && (
            <>
              <ValueDetailsButton
                onClick={() => {
                  setIsOpenedCountingTablesDrawer(true);
                }}
              >
                Value
              </ValueDetailsButton>
              <ValueDetailsButton
                onClick={() => {
                  setIsOpenedDetailsDrawer(true);
                }}
              >
                Details
              </ValueDetailsButton>
            </>
          )}
        </Box>

        {windowWidth < sm && (
          <CalendarMobileWeekMonth
            view={view}
            selectedDate={moment(date)}
            onDateChange={(selectedDate) => {
              setDate(selectedDate?.toDate() as Date);
              const selectedDateElement = document.getElementById(
                selectedDate?.format("DD MMMM YYYY") as string,
              );
              const box = document.getElementById("MainCalendarBox");
              if (selectedDateElement) {
                const topPos = selectedDateElement.offsetTop;
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
                box!.scrollTo({
                  top: topPos - 50,
                  behavior: "smooth",
                });
              }
            }}
          />
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "30px",
            paddingBottom: "32px",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              color: constants.colors.intakeTitle,
            }}
          >
            Total number of{" "}
            {calendarType === "Closings" ? "Closings" : "Signings"}:
            <span
              style={{
                fontWeight: "600",
                marginLeft: "3px",
              }}
            >
              {calendarType === "Closings" ? closingsSum : totalNumberOf}
            </span>{" "}
          </Typography>
        </Box>
        {calendarType === "Signing Appointment" && (
          <CalendarMobileSortedEventComponents
            sortedArrayOfEventsForMobile={sortedArrayOfEventsForMobile}
          />
        )}

        {calendarType === "Closings" && (
          <CalendarMobileSortedClosingsComponents />
        )}
      </Box>
      <CalendarEventsCountingTablesDrawer
        anchor="right"
        onClose={toggleCountingTablesDrawer(false)}
        onOpen={toggleCountingTablesDrawer(true)}
        open={isOpenedCountingTablesDrawer}
        setIsOpenedCountingTablesDrawer={setIsOpenedCountingTablesDrawer}
      />
      <CalendarClosingDatesDrawer
        anchor="right"
        onClose={toggleDetailsDrawer(false)}
        onOpen={toggleDetailsDrawer(true)}
        open={isOpenedDetailsDrawer}
        setIsOpenedDetailsDrawer={setIsOpenedDetailsDrawer}
      />
      <DeededPopup
        sx={{
          "& .MuiPaper-root": {
            margin: "0",
            height: "100%",
            maxHeight: "unset",
            width: "100%",
          },
          "&::-webkit-scrollbar": {
            width: "0px",
          },
        }}
        hideBackdrop={true}
        hideBtnClose={true}
        open={isOpenedEventCreatePopup}
        setOpen={setShowModal}
        sxInternalBox={{
          height: "100%",
          width: "512px",
          overflowX: "hidden",
          overflowY: "auto",
          borderRadius: "0px",
          display: "flex",
          padding: "0px",
          flexDirection: "column",
          "&::-webkit-scrollbar": {
            width: "0px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            height: "68px",
            marginBottom: "40px",
          }}
        >
          <>
            <StyledTab
              sx={{
                cursor: selectedBlockedEvent ? "initial" : "pointer",
              }}
              data-selenium-id={"create-signing-appointment-tab"}
              onClick={() => {
                if (!selectedBlockedEvent) {
                  setCurrentPageForCreateEventPopup(1);
                }
              }}
              active={currentPageForCreateEventPopup === 1}
            >
              Create Signing Appointment
            </StyledTab>
          </>
          {shouldShowCalendarBlockTime(user?.role) && (
              <StyledTab
                data-selenium-id={"create-block-time-tab"}
                onClick={() => {
                  setCurrentPageForCreateEventPopup(2);
                }}
                active={currentPageForCreateEventPopup === 2}
              >
                Block Time
              </StyledTab>
          )}
        </Box>
        <Box
          sx={{
            padding: "0px 24px",
          }}
        >
          {currentPageForCreateEventPopup === 1 && (
            <BigCalendarCreateSigningAppointment
              getEventsEffectFunction={getEventsEffectFunction}
              startDate={dateStart}
              stylesFactory={stylesFactory}
              onClose={() => {
                setShowModal(false);
              }}
            />
          )}
          {(shouldShowCalendarBlockTime(user?.role) && currentPageForCreateEventPopup === 2) && (
            <BigCalendarBlockTime
              setSelectedBlockedEvent={setSelectedBlockedEvent}
              selectedBlockedEvent={selectedBlockedEvent as BlockedEvent}
              getBlockedEffectFunction={getBlockedEffectFunction}
              startDate={dateStart}
              dateEnd={dateEnd}
              stylesFactory={stylesFactory}
              onClose={() => {
                setShowModal(false);
              }}
            />
          )}
        </Box>
      </DeededPopup>
      {topOffset > 0 && (
        <DeededScrollToTopIconButton
          onClick={() => {
            box!.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }}
          sx={{
            bottom: calendarType === "Signing Appointment" ? "156px" : "80px",
          }}
        />
      )}
      {calendarType === "Signing Appointment" && (
        <IconButton
          onClick={() => {
            dispatch(setIsOpenedEventCreatePopupAC(true));
          }}
          sx={{
            position: "fixed",
            bottom: "80px",
            width: "56px",
            height: "56px",
            right: "20px",
            minWidth: "0px",
            backgroundColor: constants.colors.red,
            "&:active": {
              backgroundColor: constants.colors.red,
            },
            "&:hover": {
              backgroundColor: constants.colors.red,
            },
          }}
        >
          <ClockForMobileCalendarIconButtonSvg />
        </IconButton>
      )}
    </>
  );
};
const CalendarClosingDatesDrawer: React.FC<
  SwipeableDrawerProps & {
    setIsOpenedDetailsDrawer: (isOpenedDetailsDrawer: boolean) => void;
  }
> = ({setIsOpenedDetailsDrawer, ...props}) => {
  const {
    view,
    date,
    isAllProvinciesChecked,
    regions,
    setSigningsTotal,
    staffState,
    dealTypeState,
  } = useContext(CalendarMobileContext);
  return (
    <>
      {/*@ts-ignore*/}
      <SwipeableDrawer
        sx={(theme) => ({
          "& .MuiPaper-root": {
            [theme.breakpoints.down("sm")]: {
              width: "100% !important",
            },
            "&::-webkit-scrollbar": {
              width: 0,
            },
          },
        })}
        {...props}
      >
        <Box
          sx={{
            padding: "10px 20px 40px 20px",
          }}
        >
          <CloseIconButton
            onClick={() => {
              setIsOpenedDetailsDrawer(false);
            }}
          >
            <CalendarHeaderSettingsCloseSvg />
          </CloseIconButton>
          <TitleTypography>Details</TitleTypography>
          <CalendarClosingDates
            isMobile
            view={view}
            date={date}
            isAllProvinciesChecked={isAllProvinciesChecked}
            regions={regions}
            setSigningsTotal={setSigningsTotal}
            staffState={staffState}
            dealTypeState={dealTypeState}
          />
        </Box>
      </SwipeableDrawer>
    </>
  );
};
const CalendarEventsCountingTablesDrawer: React.FC<
  SwipeableDrawerProps & {
    setIsOpenedCountingTablesDrawer: (
      isOpenedCountingTablesDrawer: boolean,
    ) => void;
  }
> = ({setIsOpenedCountingTablesDrawer, ...props}) => {
  const {
    date,
    view,
    isGettingEvents,
    regions,
    dealTypeState,
    setSigningsTotal,
    closingsList,
  } = useContext(CalendarMobileContext);
  const calendarType = useTypedSelector(
    (state) => state.CalendarReducer.calendarType,
  );
  return (
    <>
      {/*@ts-ignore*/}
      <SwipeableDrawer
        sx={(theme) => ({
          "& .MuiPaper-root": {
            [theme.breakpoints.down("sm")]: {
              width: "100% !important",
            },
            "&::-webkit-scrollbar": {
              width: 0,
            },
          },
        })}
        {...props}
      >
        <Box
          sx={{
            padding: "10px 20px 40px 20px",
          }}
        >
          <CloseIconButton
            onClick={() => {
              setIsOpenedCountingTablesDrawer(false);
            }}
          >
            <CalendarHeaderSettingsCloseSvg />
          </CloseIconButton>
          <TitleTypography>
            {calendarType === "Signing Appointment"
              ? "Signing appointment count"
              : "Value"}
          </TitleTypography>
          <CalendarEventsCountingTables
            disablePaddings
            closingsList={
              closingsList as unknown as
                | (ClosingsCountApiType[] & {
                    resultCalendar: {closings_count: number};
                  })
                | undefined
            }
            date={date}
            regions={regions}
            isGettingEvents={isGettingEvents}
            view={view as "day" | "week" | "month"}
            dealType={dealTypeState}
            setSigningsTotal={
              setSigningsTotal as Dispatch<SetStateAction<number>>
            }
          />
        </Box>
      </SwipeableDrawer>
    </>
  );
};
const ValueDetailsButton: React.FC<{onClick?: () => void}> = ({
  onClick,
  children,
}) => {
  return (
    <>
      <DeededButton
        sx={(theme) => ({
          borderRadius: "3px",
          [theme.breakpoints.down("sm")]: {
            width: "48%",
          },
        })}
        kind={"secondary-v2"}
        onClick={onClick}
      >
        {children}
      </DeededButton>
    </>
  );
};
const useCalendarMobileScrollTop = () => {
  const box = document.getElementById("MainCalendarBox");
  const [scrollTop, setScrollTop] = useState(box!?.offsetTop);
  useEffect(() => {
    const scrollH = (e: Event) => {
      const event = e as unknown as {target: {scrollTop: number}};
      setScrollTop(event.target.scrollTop);
    };
    box!?.addEventListener("scroll", scrollH);
    return () => box!?.removeEventListener("scroll", scrollH);
  }, [box]);
  return scrollTop;
};
const StyledTab = styled(Typography)<{active: boolean}>(
  ({active}) => ({
    width: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderBottom: "none",
    height: "68px",
    backgroundColor: active
      ? constants.colors.white
      : constants.colors.configNotificationsTableBorder,
    fontSize: "16px",
    textAlign: "center",
    color: active ? constants.colors.red : constants.colors.deededGray,
    fontWeight: "500",
    cursor: "pointer",
  }),
);
const TitleTypography = styled(Typography)({
  fontSize: "24px",
  fontWeight: "500",
  color: constants.colors.intakeTitle,
});
const CloseIconButton = styled(IconButton)({
  marginLeft: "-12px",
  minWidth: 0,
});

export default CalendarMobile;
