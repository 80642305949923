import React, {useCallback, useContext, useState} from "react";
import {Box, Button, styled} from "@mui/material";
import constants from "styles/constants";
import useTypedSelector from "utils/hooks/useTypedSelector";
import {setCalendarTypeAC} from "redux/reducers/calendarReducer";
import {useAppDispatch} from "redux/hooks";
import CalendarHeaderSettingsSvgIcon from "../svg/CalendarHeaderSettings";
import {CalendarMobileContext} from "../context/CalendarMobileContext";
import {allProvincesList} from "../constants/allProvincesList";
import CalendarMobileSettingsDrawer from "./CalendarMobileSettingsDrawer";

const CalendarMobileHeader: React.FC<{}> = () => {
  const {
    view,
    setView,
    regions,
    isGettingEvents,
    setRegions,
    dealTypeState,
    setDealTypeState,
  } = useContext(CalendarMobileContext);
  const dispatch = useAppDispatch();
  const calendarType = useTypedSelector(
    (state) => state.CalendarReducer.calendarType,
  );
  const [isShowedCalendarSettings, setIsShowedCalendarSettings] =
    useState(false);
  const handleChangeCalendarType = (newCalendarType: string) => {
    dispatch(setCalendarTypeAC(newCalendarType));
  };
  const toggleDrawer = useCallback((open: boolean) => {
    return (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setIsShowedCalendarSettings(open);
    };
  }, []);
  return (
    <>
      <CalendarMobileHeaderBox>
        <FirstRowBox>
          <Box
            sx={{
              flex: 1,
              border: `1px solid ${constants.colors.grayBorder}`,
              borderRadius: "5px",
              marginRight: "15px",
            }}
          >
            <SwitchCalendarButton
              onClick={() => {
                handleChangeCalendarType("Signing Appointment");
              }}
              isActive={calendarType === "Signing Appointment"}
            >
              Signing Appts
            </SwitchCalendarButton>
            <SwitchCalendarButton
              onClick={() => {
                handleChangeCalendarType("Closings");
              }}
              isActive={calendarType === "Closings"}
            >
              Closings
            </SwitchCalendarButton>
          </Box>
          <Button
            sx={{
              border: `1px solid ${constants.colors.grayBorder}`,
              borderRadius: "5px",
              width: "36px",
              height: "36px",
              minWidth: "unset",
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
            onClick={() => {
              setIsShowedCalendarSettings(true);
            }}
          >
            <CalendarHeaderSettingsSvgIcon />
          </Button>
        </FirstRowBox>
        <SecondRowBox>
          <CalendarMobileHeaderSwitchView setView={setView} view={view} />
        </SecondRowBox>
      </CalendarMobileHeaderBox>
      <CalendarMobileSettingsDrawer
        setRegions={setRegions}
        regions={regions}
        isGettingEvents={isGettingEvents}
        allProvincesList={allProvincesList}
        open={isShowedCalendarSettings}
        setIsShowedCalendarSettings={setIsShowedCalendarSettings}
        anchor="right"
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        dealTypeState={dealTypeState}
        setDealTypeState={setDealTypeState}
      />
    </>
  );
};

const CalendarMobileHeaderSwitchView: React.FC<{
  view: string;
  setView: (view: string) => void;
}> = ({view, setView}) => {
  const handleChangeView = (newView: string) => {
    setView(newView);
  };
  return (
    <>
      <Box
        sx={{
          width: "100%",
          borderRadius: "5px",
          border: `1px solid ${constants.colors.configNotificationsTableBorder}`,
          height: "36px",
          display: "flex",
        }}
      >
        <ViewButton
          sx={{
            borderBottomLeftRadius: "5px",
            borderTopLeftRadius: "5px",
          }}
          isActive={view === "day"}
          onClick={() => {
            handleChangeView("day");
          }}
        >
          Day
        </ViewButton>
        <ViewButton
          isActive={view === "week"}
          onClick={() => {
            handleChangeView("week");
          }}
        >
          Week
        </ViewButton>
        <ViewButton
          sx={{
            borderBottomRightRadius: "5px",
            borderTopRightRadius: "5px",
          }}
          isActive={view === "month"}
          onClick={() => {
            handleChangeView("month");
          }}
        >
          Month
        </ViewButton>
      </Box>
    </>
  );
};

const ViewButton = styled(Button, { shouldForwardProp: (propName) => propName !== "isActive" })<{
    isActive: boolean;
}>(({ isActive }) => ({
    borderRadius: "0px",
    backgroundColor: isActive ? constants.colors.red : "transparent",
    textTransform: "capitalize",
    color: isActive ? constants.colors.white : constants.colors.grayCalendarViewSwitch,
    height: "36px",
    width: "33%",
    fontSize: "16px",
    fontWeight: "500",
    "&:hover": {
        backgroundColor: isActive ? constants.colors.red : "transparent",
    },
}));
const SwitchCalendarButton = styled(Button, { shouldForwardProp: (propName) => propName !== "isActive" })<{
    isActive: boolean;
}>(({ isActive }) => ({
    height: "36px",
    textTransform: "capitalize",
    fontSize: "16px",
    fontWeight: "600",
    color: isActive ? constants.colors.red : constants.colors.searchColor,
    border: isActive ? `2px solid ${constants.colors.red}` : "none",
    borderRadius: "5px",
    width: "50%",
    whiteSpace: "nowrap",
    "&:hover": {
        backgroundColor: "transparent",
    },
}));
const FirstRowBox = styled(Box)({
  display: "flex",
  marginBottom: "32px",
});
const SecondRowBox = styled(Box)({
  width: "100%",
});
const CalendarMobileHeaderBox = styled(Box)({
  padding: "24px 20px 32px 20px",
  backgroundColor: constants.colors.white,
});
export default CalendarMobileHeader;
