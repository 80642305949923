import React, {PropsWithChildren, useEffect} from "react";
import {useDispatch} from "react-redux";
import moment from "moment/moment";
import {Calendar, momentLocalizer} from "react-big-calendar";
import {Box, styled, useTheme} from "@mui/material";
import {Theme} from "@mui/material/styles";
import {allViews} from "../constants/allViews";
import {BlockedEvent} from "types/CalendarComponent/blockedEventTypes";
import {
  CalendarEvent,
  setCreateEventForCurrentDateByDefaultAC,
} from "redux/reducers/calendarReducer";
import {DateCellWrapper} from "./DateCellWrapper";
import {EventComponent, EventComponentProps} from "./EventComponent";
import {EventType} from "../types/calendar";
import {Staff} from "types/staffTypes";
import useTypedSelector from "utils/hooks/useTypedSelector";
import DeededPopup from "v2/components/DeededPopup";
import DeededSpace from "v2/components/DeededSpace";
import Tab from "v2/components/DeededTab";
import BigCalendarCreateSigningAppointment from "./BigCalendarCreateSigningAppointment";
import BigCalendarBlockTime from "./BigCalendarBlockTime";
import constants from "styles/constants";
import {styles} from "../styles/internalCalendarStyles";
import DeededTabs from "v2/components/DeededTabs";
import {useAppSelector} from "redux/hooks";
import useWindowWidth from "utils/hooks/useWindowWidth";
import {miniDesktopCalendar} from "../constants/breakpoints";
import {handleEventSelect} from "../utils/handleEventSelect";
import useUser from "utils/hooks/useUser";
import { shouldShowCalendarBlockTime } from "utils/permissionRoles";

interface BigCalendarProps {
  blockedAndEvents: CalendarEvent[];
  width: string;
  handleChange: () => void;
  view: string;
  setView: (view: string) => void;
  setDate: (date: Date) => void;
  date: Date;
  setStartDate: (date: Date) => void;
  dateStart: Date;
  setEndDate: (date: Date) => void;
  dateEnd: Date;
  setShowCloseLapse: (showCloseLapse: boolean) => void;
  setShowDealPreview: (showDealPreview: boolean) => void;
  getEventsEffectFunction: () => void;
  getBlockedEffectFunction: () => void;
  setShowModal: (showModal: boolean) => void;
  selectedBlockedEvent: BlockedEvent | null;
  setSelectedBlockedEvent: (selectedBlockedEvent: BlockedEvent | null) => void;
  currentPageForCreateEventPopup: number;
  setCurrentPageForCreateEventPopup: (
    currentPageForCreateEventPopup: number,
  ) => void;
}
moment.locale("en");
momentLocalizer(moment);
const BigCalendar: React.FC<BigCalendarProps> = ({
  getEventsEffectFunction,
  getBlockedEffectFunction,
  blockedAndEvents,
  width,
  handleChange,
  view,
  setView,
  date,
  setShowCloseLapse,
  setDate,
  dateStart,
  setStartDate,
  setShowDealPreview,
  dateEnd,
  setEndDate,
  setCurrentPageForCreateEventPopup,
  currentPageForCreateEventPopup,
  setSelectedBlockedEvent,
  selectedBlockedEvent,
  setShowModal,
}) => {
  const windowWidth = useWindowWidth();
  const localizer = momentLocalizer(moment);
  const dispatch = useDispatch();
  const minTime = new Date();
  const { user } = useUser();
  const staff = useTypedSelector(
    (state) => state.CalendarReducer.staff,
  ) as Staff;

  minTime.setHours(5, 0, 0);
  const maxTime = new Date();
  maxTime.setHours(23, 0, 0);
  const findSelectedMobileSigner = (e: EventType) => {
    const mobileSigner = staff?.["Mobile Signer"]?.filter(
      (person) => person.id === e.user_id,
    );
    return mobileSigner?.[0]?.full_name;
  };

  const isOpenedEventCreatePopup = useAppSelector(
    (state) => state.CalendarReducer.isOpenedEventCreatePopup,
  );

  useEffect(() => {
    //it was added because deeded popup brakes the calendar overflow scroll
    document.body.style.overflow = "inherit";
  }, []);
  useEffect(() => {
    if (!isOpenedEventCreatePopup) {
      dispatch(setCreateEventForCurrentDateByDefaultAC(false));
    }
  }, [dispatch, isOpenedEventCreatePopup]);

  return (
    <>
      {/* @ts-ignore */}
      <Calendar
        min={minTime}
        max={maxTime}
        step={30}
        selectable={true}
        localizer={localizer}
        onNavigate={() => {}}
        style={{
          height: "77vh",
          width:
            windowWidth <= miniDesktopCalendar
              ? width
              : `calc(${width} - 402px)`,
          marginLeft: "auto",
          marginRight: "auto",
          styles,
        }}
        toolbar={false}
        events={blockedAndEvents}
        timeslots={1}
        views={allViews}
        view={view}
        onView={() => {}}
        onSelectEvent={(e) => {
          handleEventSelect(
            e,
            handleChange,
            setShowDealPreview,
            dispatch,
            setShowModal,
            setSelectedBlockedEvent,
            setCurrentPageForCreateEventPopup,
          );
        }}
        components={{
          dateCellWrapper: DateCellWrapper,
          event: (event: Event) =>
            EventComponent({
              event,
              findSelectedMobileSigner,
            } as unknown as PropsWithChildren<EventComponentProps>),
        }}
        date={date}
        onSelectSlot={(e) => {
          if (view === "month") {
            setView("day");
            setDate(e.start as Date);
          } else {
            setStartDate(e.start as Date);
            setEndDate(e.end as Date);
            setShowModal(true);
            dispatch(setCreateEventForCurrentDateByDefaultAC(true));
          }
        }}
      />

      <DeededPopup
        sx={{}}
        hideBackdrop={true}
        hideBtnClose={true}
        open={isOpenedEventCreatePopup}
        setOpen={setShowModal}
        sxInternalBox={{
          width: "512px",
          overflowX: "hidden",
          overflowY: "auto",
          borderRadius: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          "&::-webkit-scrollbar": {
            width: "5px",
            scrollbarWidth: "thin",
          },
        }}
      >
        <DeededTabs>
          {!selectedBlockedEvent && (
            <>
              <StyledTab
                sx={{}}
                data-selenium-id={"create-signing-appointment-tab"}
                onClick={() => {
                  setCurrentPageForCreateEventPopup(1);
                }}
                active={currentPageForCreateEventPopup === 1}
              >
                Create Signing Appointment
              </StyledTab>
              <DeededSpace x={20} />
            </>
          )}
          {shouldShowCalendarBlockTime(user?.role) && (
              <StyledTab
                data-selenium-id={"create-block-time-tab"}
                onClick={() => {
                  setCurrentPageForCreateEventPopup(2);
                }}
                active={currentPageForCreateEventPopup === 2}
              >
                Block Time
              </StyledTab>
          )}
        </DeededTabs>
        <Box>
          {currentPageForCreateEventPopup === 1 && (
            <BigCalendarCreateSigningAppointment
              getEventsEffectFunction={getEventsEffectFunction}
              startDate={dateStart}
              stylesFactory={stylesFactory}
              onClose={() => {
                setShowModal(false);
              }}
            />
          )}
          {(shouldShowCalendarBlockTime(user?.role) && currentPageForCreateEventPopup === 2) && (
            <BigCalendarBlockTime
              setSelectedBlockedEvent={setSelectedBlockedEvent}
              selectedBlockedEvent={selectedBlockedEvent}
              getBlockedEffectFunction={getBlockedEffectFunction}
              startDate={dateStart}
              dateEnd={dateEnd}
              stylesFactory={stylesFactory}
              onClose={() => {
                setShowModal(false);
              }}
            />
          )}
        </Box>
      </DeededPopup>
    </>
  );
};
const StyledTab = styled(Tab)<{theme?: Theme}>(({theme}) => ({
  [theme.breakpoints.down("sm")]: {
    "& .MuiBox-root": {
      "& .MuiTypography-root": {
        fontSize: "11px",
      },
    },
  },
}));
export const stylesFactory = () => ({
  Form: {
    display: "flex",
    flexDirection: "column",
  },
  ClientSelect: {
    color: constants.colors.grayLight,
    fontWeight: 400,
  },
  FormInfo: {
    marginTop: "8px",
    fontSize: "12px",
    lineHeight: "15px",
    color: constants.colors.sortingColor,
  },
  ClientsItems: {
    paddingTop: "8px",
    display: "flex",
    flexWrap: "wrap",
  },
  ClientsItem: {
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "15px",
    color: constants.colors.title,
    marginRight: "12px",
    padding: "8px 10px",
    textTransform: "capitalize",
    background: constants.colors.grayTag,
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
  },
  SvgIcon: {
    display: "flex",
    alignItems: "center",
    svg: {
      width: "16px",
      height: "16px",
      marginLeft: "12px",
      opacity: "0.3",
      transition: constants.transitions,
      "&: hover": {
        opacity: "1",
        transition: constants.transitions,
        cursor: "pointer",
      },
    },
  },
  Error: {
    color: constants.colors.red,
    ...constants.fontConfig_TEMP,
    marginTop: "5px",
    fontSize: "10px",
  },
  FormItem: {
    "&:not(:last-child)": {
      marginBottom: "16px",
    },
  },
  FormItemButtons: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "55px",
  },
  FormLabel: {
    position: "relative",
    marginRight: 0,
    maxWidth: "390px",
    ":hover": {
      cursor: "pointer",
    },
  },
  FormLabelText: {
    marginBottom: "6px",
    fontSize: "12px",
    lineHeight: "15px",
    color: constants.colors.sortingColor,
  },
});

export default BigCalendar;
