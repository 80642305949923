import React from "react";
import {Box, IconButton, styled, Typography} from "@mui/material";
import CloseDeededSvg from "components/Svg/CloseDeededSvg";
import constants from "styles/constants";

interface CloseCalendarLapseTitleProps {
  setShowCloseLapse: (showCloseLapse: boolean) => boolean;
}
const CloseCalendarLapseTitle: React.FC<CloseCalendarLapseTitleProps> = ({
  setShowCloseLapse,
}) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <TitleTypography>Block time off</TitleTypography>
        <IconButton
          sx={{
            marginTop: "15px",
            marginRight: "-10px",
          }}
          onClick={() => {
            setShowCloseLapse(false);
          }}
        >
          <CloseDeededSvg />
        </IconButton>
      </Box>
    </>
  );
};
const TitleTypography = styled(Typography)({
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "18px",
  lineHeight: "22px",
  marginTop: "32px",

  display: "flex",
  alignItems: "center",
  color: constants.colors.title,
});
export default CloseCalendarLapseTitle;
