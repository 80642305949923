import React, {SetStateAction, useEffect, useState} from "react";
import {NavLink} from "react-router-dom";
import {useDispatch} from "react-redux";
import {styled, SxProps, Theme, useTheme} from "@mui/material";
import Box from "@mui/material/Box";
import {Staff} from "types/Config/userManagerTypes";
import {
  Professional,
  setSelectedProfForDealsAC,
} from "redux/reducers/configReducer/profReducer";
import SortIcon from "components/Svg/SortIcon";
import IsNoFilterSvg from "components/Svg/IsNoFilterSvg";
import ClipboardCopyIcon from "components/Svg/ClipboardCopyIcon";
import ClipboardCopySuccessIcon from "components/Svg/ClipboardCopySuccessIcon";
import BasicButton from "components/Common/Button/BasicButton";
import {Spacer} from "components/Dashboard/GenericComponents";
import PointsModal from "components/Dashboard/UserPoints/PointsModal";
import constants from "styles/constants";
import useTypedSelector from "utils/hooks/useTypedSelector";
import useUser from "utils/hooks/useUser";
import configuration from "utils/configuration";
import {
  getAllCompanies,
  getAllRoles,
} from "redux/reducers/configReducer/userManagerReducer";
import UserManagerListProfessionalsFilter from "./UserManagerListProfessionalsFilter";
import DeededResultsNotFound from "v2/components/DeededResultsNotFound";
import DeededCircularLoader from "v2/components/DeededCircularLoader";
import {smallDesktopConfigUserManager} from "../../constants/breakpoints";
import UserManagerLastResetPasswordTableItem from "./UserManagerLastResetPasswordTableItem";
import UserManagerMfaTableItem from "./UserManagerMfaTableItem";
import UserManagerEditBlockForItems from "./UserManagerEditBlockForItems";

interface UserManagerListProfessionalsProps {
  data: Staff[];
  handleUser: (user: Staff) => void;
  sx: SxProps<Theme>;
  selectedSortValue: "desc" | "asc";
  selectedSort: string;
  changeSort: (orderby: string, role: string) => void;
  copiedToClipboard: boolean;
  handleClipboardCopy: (email: string) => void;
  setTypeRole: React.Dispatch<SetStateAction<string>>;
  setRolesFilter: React.Dispatch<SetStateAction<string[] | null>>;
  rolesFilter: string[] | null;
  companiesFilter: string[] | null;
  setCompaniesFilter: React.Dispatch<SetStateAction<string[] | null>>;
  searchVal: string;
}

const UserManagerListProfessionals: React.FC<
  UserManagerListProfessionalsProps
> = ({
  data,
  handleUser,
  sx,
  selectedSortValue,
  selectedSort,
  changeSort,
  copiedToClipboard,
  handleClipboardCopy,
  setTypeRole,
  setRolesFilter,
  rolesFilter,
  companiesFilter,
  setCompaniesFilter,
  searchVal,
}) => {
  const dispatch = useDispatch();
  const userInfo = useUser();

  const handleLinkClicked = (el: Professional) => {
    dispatch(setSelectedProfForDealsAC(el));
  };

  useEffect(() => {
    dispatch(getAllCompanies("professionals"));
    dispatch(getAllRoles("professionals"));
  }, [dispatch]);

  const allRoles = useTypedSelector(
    (state) => state.ConfigReducer.userManagerReducer.allRoles,
  );
  const allCompanies = useTypedSelector(
    (state) => state.ConfigReducer.userManagerReducer.allCompanies,
  );
  const [showListRole, setShowListRole] = useState(false);
  const [showListCompany, setShowListCompany] = useState(false);
  const [showPoints, setShowPoints] = useState<null|Staff>(null);

  const userManagerResultLoading = useTypedSelector(
    (state) => state.ConfigReducer.userManagerReducer.configLoader,
  );

  const theme = useTheme();
  return (
    <Box
      style={{
        overflowX: showListRole || showListCompany ? "unset" : "scroll",
      }}
      sx={sx}
    >
      <TableHeader className="TableHeader">
        <Box className="TableHeaderCell">
          Name
          <div className="chevronsBox">
            <SortIcon
              className={
                selectedSortValue === "asc" && selectedSort === "full_name"
                  ? "active"
                  : ""
              }
              onClick={() => {
                changeSort("full_name", "professionals");
              }}
            />
          </div>
        </Box>
        <Box className="TableHeaderCell">
          <span
            className="IsNoFilter"
            style={{cursor: "pointer"}}
            onClick={() => {
              setShowListRole(!showListRole);
            }}
          >
            <IsNoFilterSvg
              fill={
                rolesFilter && rolesFilter?.length > 0
                  ? constants.colors.title
                  : constants.colors.searchColor
              }
            />
            Role
          </span>
          <div className="chevronsBox">
            <SortIcon
              className={
                selectedSortValue === "asc" && selectedSort === "role"
                  ? "active"
                  : ""
              }
              onClick={() => {
                changeSort("role", "professionals");
              }}
            />
          </div>
          {allRoles.length > 0 && showListRole && (
            <UserManagerListProfessionalsFilter
              allItems={allRoles}
              activeItems={rolesFilter}
              onClose={setShowListRole}
              setFilter={setRolesFilter}
              setTypeRole={setTypeRole}
            />
          )}
        </Box>
        <Box className="TableHeaderCell">
          Email
          <div className="chevronsBox">
            <SortIcon
              className={
                selectedSortValue === "asc" && selectedSort === "email"
                  ? "active"
                  : ""
              }
              onClick={() => {
                changeSort("email", "professionals");
              }}
            />
          </div>
        </Box>
        <Box
          sx={{
            position: "relative",
          }}
          className="TableHeaderCell"
        >
          <span
            className="IsNoFilter"
            style={{cursor: "pointer"}}
            onClick={() => {
              setShowListCompany(!showListCompany);
            }}
          >
            <IsNoFilterSvg
              fill={
                companiesFilter && companiesFilter?.length > 0
                  ? constants.colors.title
                  : constants.colors.searchColor
              }
            />
            Company
          </span>
          <div className="chevronsBox">
            <SortIcon
              className={
                selectedSortValue === "asc" && selectedSort === "company"
                  ? "active"
                  : ""
              }
              onClick={() => {
                changeSort("company", "professionals");
              }}
            />
          </div>
          {allCompanies.length > 0 && showListCompany && (
            <UserManagerListProfessionalsFilter
              allItems={allCompanies}
              activeItems={companiesFilter}
              onClose={setShowListCompany}
              setFilter={setCompaniesFilter}
              setTypeRole={setTypeRole}
            />
          )}
        </Box>
        <Box className="TableHeaderCell">
          Date Created
          <div className="chevronsBox">
            <SortIcon
              className={
                selectedSortValue === "asc" && selectedSort === "date_created"
                  ? "active"
                  : ""
              }
              onClick={() => {
                changeSort("date_created", "professionals");
              }}
            />
          </div>
        </Box>
        <Box className="TableHeaderCell">
          Last Deal
          <div className="chevronsBox">
            <SortIcon
              className={
                selectedSortValue === "asc" && selectedSort === "last_deal"
                  ? "active"
                  : ""
              }
              onClick={() => {
                changeSort("last_deal", "professionals");
              }}
            />
          </div>
        </Box>
        <Box className="TableHeaderCell">
          Closing Date
          <div className="chevronsBox">
            <SortIcon
              className={
                selectedSortValue === "asc" && selectedSort === "closing_date"
                  ? "active"
                  : ""
              }
              onClick={() => {
                changeSort("closing_date", "professionals");
              }}
            />
          </div>
        </Box>
        <Box className="TableHeaderCell">
          Deals
          <div className="chevronsBox">
            <SortIcon
              className={
                selectedSortValue === "asc" && selectedSort === "total_deals"
                  ? "active"
                  : ""
              }
              onClick={() => {
                changeSort("total_deals", "professionals");
              }}
            />
          </div>
        </Box>
        <Box
          sx={{
            whiteSpace: "nowrap",
          }}
          className="TableHeaderCell"
        >
          MFA
        </Box>
        <Box
          sx={{
            whiteSpace: "nowrap",
          }}
          className="TableHeaderCell"
        >
          Last Pwd Rest
        </Box>
      </TableHeader>
      <Box
        sx={{
          "& .TableBodyCell": {
            [theme.breakpoints.down("lg")]: {
              "&:nth-child(2)>.ItemWrapper": {
                "&::before": {
                  content: '"Role: "',
                },
              },
              "&:nth-child(3)>.ItemWrapper": {
                "&::before": {
                  content: '"Email: "',
                },
              },
            },
          },
        }}
      >
        {userManagerResultLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            <DeededCircularLoader />
          </Box>
        ) : data.length !== 0 ? (
          data.map((item) => (
            <TableRowWrapper key={item.id} className="TableRowWrapper">
              <Box className="TableBodyCell" data-testid="user-manager-professionals-name-column">
                <div className="ItemWrapper" title={item.full_name}>
                  {item.full_name}
                </div>
              </Box>
              <Box className="TableBodyCell" data-testid="user-manager-professionals-role-column">
                <div className="ItemWrapper" title={item.role}>
                  {item.role}
                </div>
              </Box>
              <Box className="TableBodyCell email" data-testid="user-manager-professionals-email-column">
                {item.email && (
                  <div className="CopyEmailPrompt">
                    {item.email} <div className="Triangle" />
                    {!copiedToClipboard ? (
                      <ClipboardCopyIcon
                        onClick={() =>
                          handleClipboardCopy(item.email as string)
                        }
                      />
                    ) : (
                      <ClipboardCopySuccessIcon />
                    )}
                  </div>
                )}
                <div className="ItemWrapper" title={item.email}>
                  {item.email}
                </div>
              </Box>
              <Box className="TableBodyCell">
                <div className="ItemWrapper" title={item.company ?? "-"}>
                  {item.company ?? "-"}
                </div>
              </Box>
              <Box className="TableBodyCell">
                <div className="ItemWrapper" title={item.date_created}>
                  {item.date_created}
                </div>
              </Box>
              <Box className="TableBodyCell">
                <div
                  className="ItemWrapper"
                  title={(item.last_deal ?? "-") as string}
                >
                  {item.last_deal ?? "-"}
                </div>
              </Box>
              <Box className="TableBodyCell">
                <div className="ItemWrapper" title={item.closing_date ?? "-"}>
                  {item.closing_date ?? "-"}
                </div>
              </Box>
              <Box className="TableBodyCell">
                <div
                  className="ItemWrapper"
                  title={`${item.total_deals}` ?? "-"}
                >
                  {!item.deleted_at ? (
                    <NavLink
                      onClick={() => {
                        handleLinkClicked(item as unknown as Professional);
                      }}
                      to={"/dashboard/deals"}
                      className={`TableBodyCell ${
                        !item.deleted_at ? "totalDeals" : ""
                      }`}
                    >
                      {item.total_deals ?? "-"}
                    </NavLink>
                  ) : (
                    item.total_deals ?? "-"
                  )}
                </div>
              </Box>
              <UserManagerMfaTableItem item={item} />
              <UserManagerLastResetPasswordTableItem item={item} />
              <Box
                sx={{
                  [theme.breakpoints.down(smallDesktopConfigUserManager)]: {
                    justifySelf: "center !important",
                  },
                  [theme.breakpoints.down("lg")]: {
                    justifySelf: "end",
                  },
                }}
                className="TableBodyCell UserManagerUserTools"
              >
                {(userInfo.isAdmin && configuration.pointsEnabled) &&
                  <BasicButton
                    onClick={() => setShowPoints(item)}
                    typeOf="primary"
                    size="small"
                    label={{text: 'Points', inputId: `points-button-users-${item.id}`}}
                  />
                }
                <Spacer width={12} height={1} />
                <UserManagerEditBlockForItems
                  handleUser={() => handleUser(item)}
                  userId={item.id}
                  isDeleted={!!item.deleted_at}
                />
              </Box>
            </TableRowWrapper>
          ))
        ) : (
          <DeededResultsNotFound
            searchVal={searchVal}
            textNotFound="There are no users yet"
          />
        )}
        <PointsModal
          show={Boolean(showPoints)}
          onClose={() => setShowPoints(null)}
          user={showPoints}
        />
      </Box>
    </Box>
  );
};
const defaultWidth = 100 / 13;
const gridTemplateColumns =
  //"9% 6% 5% 9% 8% 7% 8% 5% 2% 14% 50px"
  `${defaultWidth}% ${defaultWidth}% ${defaultWidth}% calc(${defaultWidth}% + 30px) ${defaultWidth}% ${defaultWidth}% ${defaultWidth}% 5% calc(2% + 10px) 1fr 50px`;
const TableHeader = styled(Box)<{theme?: Theme}>(({theme}) => ({
  // gridTemplateColumns: `repeat(11, ${100 / 12}%)`,
  gridTemplateColumns: gridTemplateColumns,
  [theme.breakpoints.up("lg")]: {
    minWidth: "1081px !important",
  },
  [theme.breakpoints.down("lg")]: {
    gridTemplateColumns: "1fr 1fr 1fr 100px",
  },
}));

const TableRowWrapper = styled(Box)<{theme?: Theme}>(({theme}) => ({
  [theme.breakpoints.up("lg")]: {
    minWidth: "1081px !important",
  },
  // gridTemplateColumns: `repeat(11, ${100 / 12}%)`,
  gridTemplateColumns: gridTemplateColumns,
  [theme.breakpoints.down("lg")]: {
    gridTemplateColumns: "1fr 1fr 1fr 100px",
  },
}));

export default UserManagerListProfessionals;
