import React, {useEffect, useState} from "react";
import {styled} from "@mui/material";
import {useDispatch} from "react-redux";
import useTypedSelector from "utils/hooks/useTypedSelector";
import {
  setDealNumbersTC,
  saveDealNumbersTC,
} from "redux/reducers/dealsReducer";
import {addSuccessMessageAC} from "redux/reducers/ErrorReducer";
import {DealNumber} from "types/reduxActions/deals";
import DeededButton from "v2/components/DeededButton";
import {smallDesktopConfigDealNumbers} from "../../constants/breakpoints";
import ConfigDealNumberWrapper from "./ConfigDealNumberWrapper";

const ConfigDealNumbers: React.FC = () => {
  const dealNumbersData = useTypedSelector(
    (state) => state.DealsReducer?.dealNumbers,
  );
  const dispatch = useDispatch();

  const [dealNumbers, setDealNumbers] = useState<Partial<DealNumber>[]>([]);
  useEffect(() => {
    const mappedData = () => {
      let mapped = dealNumbersData
        .filter((el: {state: string}) => el.state !== "BC")
        .map((el: {group: number; state: string}) =>
          el.group === 1 && el.state === "AB"
            ? {
                ...el,
                state: "AB-BC",
              }
            : el,
        );
      return mapped;
    };
    setDealNumbers(mappedData);
  }, [dealNumbersData]);
  useEffect(() => {
    dispatch(setDealNumbersTC());
  }, [dispatch]);

  const handleFormSubmit = (e: {preventDefault: () => void}) => {
    e.preventDefault();
    const filtered = dealNumbers.find((el) => el.group === 1);
    const abbc = [
      {...filtered, state: "AB"},
      {...filtered, state: "BC"},
    ];
    const map = [...abbc, ...dealNumbers.filter((el) => el.group !== 1)];
    dispatch(addSuccessMessageAC("Successfully saved"));
    dispatch(saveDealNumbersTC([...map]));
  };
  return (
    <>
      <FormWrapper onSubmit={handleFormSubmit}>
        <DealItems>
          <ClientsGroupHeader>
            <ClientsGroupItem>Province</ClientsGroupItem>
            <ClientsGroupItemSpecific>Deal Number 1</ClientsGroupItemSpecific>
            <ClientsGroupItemSpecificSecond>
              Deal Number 2
            </ClientsGroupItemSpecificSecond>
          </ClientsGroupHeader>
          {dealNumbers.length > 0 &&
            dealNumbers.map((el, index) => (
              <ConfigDealNumberWrapper
                key={el.id}
                dealNumberItem={el}
                setDealNumbers={setDealNumbers}
                index={index}
              />
            ))}
          <ButtonWrapper>
            <Dealbutton
              onClick={(e: {preventDefault: () => void}) => handleFormSubmit(e)}
              type="submit"
            >
              Save
            </Dealbutton>
          </ButtonWrapper>
        </DealItems>
      </FormWrapper>
    </>
  );
};

const ClientsGroupHeader = styled("div")(({theme}) => ({
  display: "grid",
  padding: " 1.5rem 0",
  gridTemplateColumns: "367px 1fr 1fr",
  paddingLeft: "4px",
  background: "#FFFFFF",
  [theme.breakpoints.down("lg")]: {
    gridTemplateColumns: "267px 1fr 1fr",
  },
  [theme.breakpoints.down(smallDesktopConfigDealNumbers)]: {
    display: "none",
  },
}));
const ClientsGroupItem = styled("div")({
  display: "flex",
  flex: 1,
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: " 14px",
  lineHeight: "17px",
  color: " #828282",
  marginLeft: "0",
});

const ClientsGroupItemSpecific = styled("div")(({theme}) => ({
  display: "flex",
  flex: 1,
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: " 14px",
  lineHeight: "17px",
  color: " #828282",
  marginLeft: "0,5rem",
  [theme.breakpoints.down("md")]: {
    marginLeft: "5rem",
  },
  [theme.breakpoints.up("lg")]: {
    marginLeft: "0rem",
    marginRight: "0rem",
  },
}));
const ClientsGroupItemSpecificSecond = styled("div")(({theme}) => ({
  display: "flex",
  flex: 1,
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: " 14px",
  lineHeight: "17px",
  color: " #828282",
  marginLeft: "-75px",
  [theme.breakpoints.down(1670)]: {
    marginLeft: "-70px",
  },
  [theme.breakpoints.down(1500)]: {
    marginLeft: "-65px",
  },
  [theme.breakpoints.down(1370)]: {
    marginLeft: "-60px",
  },
}));
const ButtonWrapper = styled("div")(({theme}) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    justifyContent: "flex-end",
  },
  [theme.breakpoints.up("md")]: {
    width: "100%",
    justifyContent: "flex-end",
  },
  [theme.breakpoints.down("lg")]: {
    width: "96%",
    justifyContent: "flex-end",
  },
  [theme.breakpoints.up("lg")]: {
    width: "100%",
    justifyContent: "flex-end",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    justifyContent: "flex-end",
    paddingRight: 0,
  },
}));
const DealItems = styled("div")(({theme}) => ({
  flex: 1,
  display: "flex",
  widht: "100%",
  margin: "auto",
  paddingBottom: "5rem",
  paddingTop: "10px",
  boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.08)",
  flexDirection: "column",
  paddingLeft: "24px",
  paddingRight: "24px",
  [theme.breakpoints.down(smallDesktopConfigDealNumbers)]: {
    margin: 0,
    padding: 0,
    paddingTop: "0px",
    boxShadow: "none",
  },
}));

const Dealbutton = styled(DeededButton)(({theme}) => ({
  marginLeft: "auto",
  marginTop: "56px",
  width: "163px",
  [theme.breakpoints.down("sm")]: {
    marginTop: "8px",
  },
}));

const FormWrapper = styled("form")(({theme}) => ({
  width: "100%",
  height: "100%",
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: "1.4rem",
  backgroundColor: " #FFFFFF",
  [theme.breakpoints.down(smallDesktopConfigDealNumbers)]: {
    backgroundColor: "rgba(0,0,0,0)",
  },
}));

export default ConfigDealNumbers;
