import React, {useEffect} from "react";
import {
  NumberOfSellersRender,
  NumberOfSellersOwnersRender,
  FullNameRender,
  YesNoRadios,
  DateBirthRender,
  SigningAppointmentRender,
  SigningAppointmentExtended,
  EmploymentStatusRender,
  SpousalStatusRender,
  AddressForm,
  ResidedAddressForm,
  PhoneNumberRender,
  InsuranceNumberRender,
  NameOfEmployerRender,
  TitleOfPropertyRender,
  SellingCondominiumRender,
  CitizenshipStatusRender,
  MortgageLenderRender,
  TypeOfPropertyRender,
  SepticTankWaterWellRender,
  PrimaryUsePropertyRender,
  InformationLenderRender,
  NewMortgageRender,
  ScheduledClosingDateRender,
  HomeInsurancePolicyRender,
  DocumentUpdateForm,
  EmailRender,
} from "./components";

/**
 * Markup description for each card
 * @typedef {()=>JSX.Element} RenderFunc
 *
 * Markup description for each card
 * @typedef {{title?:string, description?:string|RenderFunc, render?:CardEditFormComponent}} MarkupDescription
 */

/** @type {Map<string, MarkupDescription>} */
export const clientIntakeEditData = new Map([
  // Mortgage
  [
    "Number of Owners",
    {
      title: "How many owners are on title for the property being refinanced?",
      description:
        "A title means legal rights to a property. How many individuals or corporations will be registered as legal owners to the property?",
      render: NumberOfSellersOwnersRender,
    },
  ],
  [
    "Full Name of Owner",
    {
      description: "As it appears on your drivers’ license or passport",
      render: FullNameRender,
    },
  ],
  [
    "Corporate Sale",
    {
      description: "Are you selling this property on behalf of a corporation?",
      render: YesNoRadios,
    },
  ],
  [
    "Corporate Refinance",
    {
      description:
        "Are you refinancing this property on behalf of a corporation",
      render: YesNoRadios,
    },
  ],
  [
    "Ownership of Property",
    {
      title: "Do you currently reside at the property?",
      description: 'If property is rented out or vacant, please select "No".',
      render: YesNoRadios,
    },
  ],
  [
    "Condominium",
    {
      title: "If the property is a condominium, please provide the following",
      description: " ",
      render: SellingCondominiumRender,
    },
  ],
  [
    "New Mortgage",
    {
      title: "New mortgage or secured line of credit lender",
      description: " ",
      render: NewMortgageRender,
    },
  ],
  [
    "Scheduled Closing Date",
    {
      description:
        "Please indicate the scheduled closing date you have agreed to with your lender and/or mortgage broker.",
      render: ScheduledClosingDateRender,
    },
  ],
  [
    "Number of Sellers",
    {
      description:
        "A title means legal rights to a property.  How many individuals or corporations will be registered as legal owners to the property?",
      render: NumberOfSellersRender,
    },
  ],
  [
    "Full Name of Seller",
    {
      description: "As it appears on your drivers’ license or passport",
      render: FullNameRender,
    },
  ],
  [
    "Reside at the Property",
    {
      title: "Do you currently reside at the property?",
      description: `If property is rented out or vacant, please select "No".`,
      render: YesNoRadios,
    },
  ],
  [
    "[Mortgage] Signing Appointment",
    {
      title: "Book your signing appointment",
      description: " ",
      render: SigningAppointmentExtended,
    },
  ],
  [
    "Resident of Canada",
    {
      title: `Will you be a resident of Canada (for income tax purposes) on the day
    of closing?`,
      description: () => (
        <>
          For definition of Canadian residency status, please{" "}
          <a
            href="https://www.canada.ca/en/revenue-agency/services/tax/international-non-residents/information-been-moved/determining-your-residency-status.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            click here
          </a>{" "}
          for the latest definitions from Canada Revenue Agency.
        </>
      ),
      render: YesNoRadios,
    },
  ],
  [
    "Date of Birth",
    {
      description: "Set date of birth.",
      render: DateBirthRender,
    },
  ],
  [
    "Forwarding Address",
    {
      render: AddressForm,
    },
  ],
  [
    "Phone Number",
    {
      render: PhoneNumberRender,
    },
  ],
  [
    "Social Insurance Number",
    {
      render: InsuranceNumberRender,
    },
  ],
  [
    "Employment Status",
    {
      description:
        "We are required to collect this information by the Law Society",
      render: EmploymentStatusRender,
    },
  ],
  [
    "Name of Employer",
    {
      description:
        "Please enter the name of your employer.  If you are self-employed, please specify the name of your business.",
      render: NameOfEmployerRender,
    },
  ],
  [
    "Employer Address",
    {
      render: AddressForm,
    },
  ],
  [
    "Spousal Status",
    {
      render: SpousalStatusRender,
    },
  ],
  [
    "Signing Appointment",
    {
      render: SigningAppointmentExtended,
    },
  ],
  [
    "Rental Property",
    {
      title: "Is the property a rental property?",
      render: YesNoRadios,
    },
  ],
  [
    "Selling a Condominium",
    {
      title: "Please provide the following",
      description: " ",
      render: SellingCondominiumRender,
    },
  ],
  [
    "Current Mortgage Lender",
    {
      title: "Who is your current mortgage lender?",
      description: "For example TD, Scotiabank, MCAP, etc.",
      render: MortgageLenderRender,
    },
  ],
  [
    "Branch Address of Lender",
    {
      title: "Branch Address of your lender",
      render: AddressForm,
    },
  ],
  [
    "Rental Contracts",
    {
      title:
        "Do you have any service or rental contracts that the buyer will assume?",
      description: "For example, hot water tank, furnace rental, etc.",
      render: YesNoRadios,
    },
  ],
  [
    "Rental Agreement",
    {
      description: " ",
      render: DocumentUpdateForm,
    },
  ],
  [
    "Void Cheque",
    {
      description:
        "A void cheque is needed for us to deposit the proceeds into your bank account.?",
      render: DocumentUpdateForm,
    },
  ],
  // TODO (Oleg): Recent Tax bill
  [
    "[Sale] Recent Property Tax Bill",
    {
      description:
        "Please upload your most recent property tax bill or statement",
      render: DocumentUpdateForm,
    },
  ],

  // Purchase
  [
    "Full Name of Purchaser",
    {
      description: "As it appears on your drivers’ license or passport",
      render: FullNameRender,
    },
  ],

  [
    "Corporate Buyer",
    {
      description:
        "Are you purchasing this property on behalf of a corporation?",
      render: YesNoRadios,
    },
  ],
  [
    "Current Address",
    {
      render: AddressForm,
    },
  ],
  [
    "Citizenship Status",
    {
      description:
        "If you are a Canadian citizen and a citizen of another country, please select both options.",
      render: CitizenshipStatusRender,
    },
  ],
  [
    "Description Time Buyer",
    {
      title:
        "Have you ever owned a property or had a financial interest in a property anywhere in the world?",
      description:
        "We use this information to assess your eligibility as a first-time home buyer. Please indicate yes if you have ever owned a home, or an interest in a home, anywhere in the world, at any time.",
      render: YesNoRadios,
    },
  ],
  [
    "Physically Present",
    {
      title:
        "Have you been physically present in Canada for at least 183 days in the 12 month period prior to your closing?",
      description: " ",
      render: YesNoRadios,
    },
  ],
  [
    "[Purchase] Signing Appointment",
    {
      title: "Book your signing appointment",
      description: " ",
      render: SigningAppointmentExtended,
    },
  ],
  [
    "Title of Property",
    {
      title: "How will you be taking title to this property?",
      description:
        "Real estate can be owned by two or more persons. This process is also known as taking title on a purchase. Both individuals and corporations can also hold title on a property.",
      render: TitleOfPropertyRender,
    },
  ],
  [
    "Type of Property",
    {
      title: "Which type of property are you purchasing?",
      render: TypeOfPropertyRender,
    },
  ],
  [
    "Property Rented Out",
    {
      title: "Is the property currently rented out?",
      description: " ",
      render: YesNoRadios,
    },
  ],
  [
    "Septic, Fuel & Water Well",
    {
      title: "Does the property have a septic tank?",
      description:
        "A septik tank is an underwater sedimentation tank used for wastewater treatment.",
      render: SepticTankWaterWellRender,
    },
  ],
  [
    "Primary use of your new property",
    {
      title: "What is the primary use of your new property?",
      render: PrimaryUsePropertyRender,
    },
  ],
  [
    "Taking of Mortgage",
    {
      title: "Will you be taking a mortgage for this property?",
      description:
        'Please select "Yes" if you are getting a mortgage(s) to purchase this property.',
      render: YesNoRadios,
    },
  ],
  [
    "Information about Lender",
    {
      title: "Information about your lender",
      description:
        "Please provide us with information about your lender. If you are using mortgage broker, please fill in their information.",
      render: InformationLenderRender,
    },
  ],
  // TODO (Oleg): Make it working...
  // It's used in those purchase and mortgage deals
  [
    "Home Insurance Policy",
    {
      render: HomeInsurancePolicyRender,
    },
  ],
  [
    "Resided Address",
    {
      render: ResidedAddressForm,
    },
  ],
  [
    "Full Name of Lender",
    {
      description: "As it appears on your drivers’ license or passport",
      render: FullNameRender,
    },
  ],
  [
    "Email",
    {
      render: EmailRender,
    },
  ],
]);
