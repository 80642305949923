import React from "react";
import {Box, Typography} from "@mui/material";
import constants from "styles/constants";

const CalendarMobileDateSectorComponent: React.FC<{
  eventsDate: string;
}> = ({children, eventsDate}) => {
  return (
    <>
      <Box>
        <Box
          onClick={(e) => {
            const selectedDateElement = document.getElementById(
              `${eventsDate} 0`,
            );
            const box = document.getElementById("MainCalendarBox");
            if (selectedDateElement) {
              const topPos = selectedDateElement.offsetTop;
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
              box!.scrollTo({
                top: topPos - 100,
                behavior: "smooth",
              });
            }
          }}
          id={eventsDate}
          sx={{
            position: "sticky",
            top: "0",
            width: "100%",
            height: "32px",
            backgroundColor: constants.colors.calendarMobileEventsDate,
            border: `1px solid ${constants.colors.grayBorder}`,
            display: "flex",
            alignItems: "center",
            paddingLeft: "20px",
          }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              color: constants.colors.intakeTitle,
            }}
          >
            {eventsDate}
          </Typography>
        </Box>
        {children}
      </Box>
    </>
  );
};
export default CalendarMobileDateSectorComponent;
