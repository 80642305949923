import React, {useContext} from "react";
import {useDispatch} from "react-redux";
import moment from "moment";
import {Box, Typography} from "@mui/material";
import {CalendarEvent} from "redux/reducers/calendarReducer";
import {BlockedEvent} from "types/CalendarComponent/blockedEventTypes";
import constants from "styles/constants";
import {handleEventSelect} from "../../utils/handleEventSelect";
import {generateTitleForEvent} from "../../utils/generateTitleForEvent";
import {EventType} from "../../types/calendar";
import {CalendarMobileContext} from "../../context/CalendarMobileContext";
import CalendarMobileDateSectorComponent from "./CalendarMobileDateSectorComponent";

const CalendarMobileSortedEventComponents: React.FC<{
  sortedArrayOfEventsForMobile: (CalendarEvent | BlockedEvent)[][];
}> = ({sortedArrayOfEventsForMobile}) => {
  const {
    handleChange,
    setShowDealPreview,
    setShowModal,
    setSelectedBlockedEvent,
    setCurrentPageForCreateEventPopup,
  } = useContext(CalendarMobileContext);
  const dispatch = useDispatch();
  return (
    <>
      <Box>
        {sortedArrayOfEventsForMobile.map((eventsArray, index) => {
          const eventsDate = eventsArray?.[0]?.start_at
            ? moment(eventsArray[0].start_at, "YYYY-MM-DD").format(
                "DD MMMM YYYY",
              )
            : "Unknown date";
          return (
            <CalendarMobileDateSectorComponent
              eventsDate={eventsDate}
              key={index}
            >
              <Box sx={{padding: "0px 22px"}}>
                {eventsArray.map((event, index) => {
                  const eventTitle = generateTitleForEvent({
                    event: event as EventType,
                    title: event.title as string,
                  });
                  const eventSeparatorLineColor =
                    getEventSeparatorColor(event);
                  return (
                    <Box
                      id={`${eventsDate} ${index}`}
                      onClick={() => {
                        handleEventSelect(
                          event,
                          handleChange as () => void,
                          setShowDealPreview,
                          dispatch,
                          setShowModal,
                          setSelectedBlockedEvent,
                          setCurrentPageForCreateEventPopup,
                        );
                      }}
                      sx={{
                        display: "flex",
                        borderBottom:
                          index + 1 === eventsArray.length
                            ? "none"
                            : `1px solid ${constants.colors.grayBorder}`,
                        alignItems: "center",
                        padding: "6px 12px",
                        cursor: "pointer",
                      }}
                      key={`${eventsDate}-${index}`}
                    >
                      <Box
                        sx={{
                          marginRight: "12px",
                          minWidth: "57px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "12px",
                            color: constants.colors.title,
                            whiteSpace: "nowrap",
                          }}
                        >
                          {event.is_full_day
                            ? "Full day"
                            : moment(event.start_at, "YYYY-MM-DD HH:mm:ss").format("hh:mm A")}
                        </Typography>
                          {!event.is_full_day && (
                            <Typography
                              sx={{
                                fontSize: "12px",
                                color: constants.colors.title,
                                whiteSpace: "nowrap",
                              }}
                            >
                              {moment(event.end_at, "YYYY-MM-DD HH:mm:ss").format("hh:mm A")}
                            </Typography>
                          )}
                      </Box>
                      <Box
                        sx={{
                          backgroundColor: eventSeparatorLineColor,
                          height: "48px",
                          width: "4px",
                          minWidth: "4px",
                          borderRadius: "5px",
                          marginRight: "12px",
                        }}
                      />
                      <Box>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            color: constants.colors.intakeTitle,
                          }}
                        >
                          {eventTitle}
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </CalendarMobileDateSectorComponent>
          );
        })}
      </Box>
    </>
  );
};

const getEventSeparatorColor = (
    eventToGetColor: CalendarEvent | BlockedEvent,
) => {
    const isBlockedEvent =
        eventToGetColor?.title === "Blocked";
    if (isBlockedEvent) {
        return constants.colors.red;
    } else if (eventToGetColor.timezone === "AB") {
        return constants.provinceColors.AB;
    } else if (eventToGetColor.timezone === "BC") {
        return constants.provinceColors.BC;
    } else if (eventToGetColor.timezone === "ON") {
        return constants.provinceColors.ON3;
    } else {
        return constants.provinceColors.Other;
    }
};

export default CalendarMobileSortedEventComponents;
