import React from "react";

interface ProfileCheckedSvgProps {
  fill?: string;
  width?: number;
  height?: number;
}

const ProfileCheckedSvg = ({fill, width, height}: ProfileCheckedSvgProps) => {
  return (
    <svg
      width={width ?? "20"}
      height={height ?? "20"}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="16" rx="4" fill={fill || "#F16764"} />
      <path
        d="M5.49497 7.90503C5.22161 7.63166 4.77839 7.63166 4.50503 7.90503C4.23166 8.17839 4.23166 8.62161 4.50503 8.89497L5.49497 7.90503ZM6.8 10.2L6.30503 10.695C6.57839 10.9683 7.02161 10.9683 7.29497 10.695L6.8 10.2ZM11.495 6.49497C11.7683 6.22161 11.7683 5.77839 11.495 5.50503C11.2216 5.23166 10.7784 5.23166 10.505 5.50503L11.495 6.49497ZM4.50503 8.89497L6.30503 10.695L7.29497 9.70503L5.49497 7.90503L4.50503 8.89497ZM7.29497 10.695L11.495 6.49497L10.505 5.50503L6.30503 9.70503L7.29497 10.695Z"
        fill="white"
      />
    </svg>
  );
};

export default ProfileCheckedSvg;
