import React, {useState, useEffect} from "react";
import {getDealByID, updateClientIntakeReview, updateDealClientData} from "api";
import {connect, useDispatch} from "react-redux";
import {withSnackbar} from "utils/withSnackbar";
import {useRouteMatch, useHistory} from "react-router-dom";
import "./ClientIntake.scss";
import {DocumentViewContainer} from "../DocumentView/DocumentView";
import {baseURL} from "../../../api";
import axios from "axios";
// Tabs
import {Tab, Tabs, TabList, TabPanel} from "react-tabs";
import "react-tabs/style/react-tabs.scss";
import ClientIntakeCards from "../ClientIntakeCards/ClientIntakeCards";
import DealPropertyCard from "../DealPropertyCard/DealPropertyCard";
// SVG
import CrossSVG from "../../Svg/Cross";
import {
  setCurrentDealDealTypeAC,
  setPreferredProvinceAC,
  setPreferredTimeAC,
  setProvince,
} from "../../../redux/reducers/dealsReducer";
import DeededCircularLoader from "v2/components/DeededCircularLoader";
import Intake from "./Intake";
import DetailsTabNavigation from "../DetailsTabNavigation/DetailsTabNavigation";
import configuration from "../../../utils/configuration";

const ClientIntake = ({enqueueSnackbar, user}) => {
  const [deal, setDeal] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [showDocumentPopup, setShowDocumentPopup] = useState(false);
  const [signingAppointment, setSigningAppointment] = useState(null);
  const [clients, setClients] = useState(null);
  const [intakeType, setIntakeType] = useState("");
  const [intakeReview, setIntakeReview] = useState(null);
  const [reviewed, setReviewed] = useState(false);
  const [reloadData, setReloadId] = useState({id: 0, onFinished: null});
  const [intake, setIntake] = useState(null);
  const [loadingIntake, setLoadingIntake] = useState(false);

  const onIntakeCardsRerender = (onFinished) => {
    setReloadId({id: reloadData.id + 1, onFinished});
  };
  const isActualDeededCustomerClient = ['Client', 'Secondary Client'].includes(user?.role || '')
  const toggleDocumentPopup = () => setShowDocumentPopup(!showDocumentPopup);
  let match = useRouteMatch();
  const handleAddressChange = (e) => {
    const {value} = e.target;
    setDeal({...deal, address: value});
  };
  const handleClosingDate = (value) => {
    setDeal({
      ...deal,
      closing_date: new Date(value).toLocaleDateString("en-US", {
        timeZone: "America/Toronto",
      }),
    });
  };

  const handleHeaderUpdate = (closing_date, deal_number, address_data) => {
    const {id, deal_type} = deal;
    const {address, unit, city, state, code} = address_data;
    const client1 = deal.participants.find((el) => el.role === "Client");
    const client2 = deal.participants.find(
      (el) => el.role === "Secondary Client",
    );

    const payload = {
      deal_type,
      closing_date: new Date(closing_date).toLocaleDateString("en-US"),
      deal_number,
      address: {
        address,
        unit,
        city,
        state,
        code,
      },
      client: {
        first_name: client1.first_name,
        last_name: client1.last_name,
        email: client1.email,
        phone: client1.phone,
      },
      secondary_client: {
        first_name: client2 === undefined ? "" : client2.first_name,
        last_name: client2 === undefined ? "" : client2.last_name,
        email: client2 === undefined ? "" : client2.email,
        phone: client2 === undefined ? "" : client2.phone,
      },
      lender: {
        name: "",
        address: {
          address: "",
          unit: "",
          city: "",
          code: "",
        },
      },
    };

    updateDealClientData(id, payload)
      .then((res) => {
        enqueueSnackbar("Property details update", {
          variant: "success",
          autoHideDuration: 1500,
        });
        getDealByID(id).then((res) => {
          setDeal(res.data);
        });
      })
      .catch((error) =>
        enqueueSnackbar(error.message, {
          variant: "error",
          autoHideDuration: configuration.autoHideErrorDuration,
        }),
      );
  };

  const handleUpdateIntakeReview = (reviewed) => {
    return updateClientIntakeReview(intake.id, { reviewed })
      .then(({ data }) => setIntake(data))
      .catch((error) => enqueueSnackbar("There was an error setting intake to Reviewed status", {
        variant: "error",
        autoHideDuration: configuration.autoHideErrorDuration,
      })
      );
  }

  const handleIntakeReview = (reviewed = null) => {
    if (intake) return handleUpdateIntakeReview(reviewed);
    if (intakeReview !== null || clients === null || intakeType === "") return;
    axios
      .post(`/deals/${deal?.id}/client-intake/review`)
      .then(() => {
        setReviewed(true);
      })
      .catch((error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
          autoHideDuration: configuration.autoHideErrorDuration,
        });
      });
  };
  const handleGetIntakeData = (id, type, onFinished) => {
    setLoadingIntake(true);
    axios
      .get(`${baseURL}/deals/${id}/client-intake`)
      .then((res) => {
        if(res?.data?.token || res?.data?.uuid) {
          return setIntake(res?.data);
        }
        dispatch(
          setPreferredProvinceAC(
            res?.data?.preferred_province !== null
              ? res?.data?.preferred_province
              : "",
          ),
        );

        dispatch(
          setPreferredTimeAC(
            res?.data?.preferred_time !== null ? res?.data?.preferred_time : "",
          ),
        );
        let output = new Array(res.data[type].length)
          .fill(null)
          .map((el, index) => {
            return {
              ...res.data,
              client: res.data[type][index],
            };
          });
        setIntakeReview(res?.data?.intake_review);
        setClients(output);
        setIntakeType(type);
        if (onFinished) {
          onFinished();
        }
      })
      .catch((error) => {
        console.log(error);
      }).finally(() => {
        setLoadingIntake(false);
      });
  };
  useEffect(() => {
    if (deal?.deal_type) {
      dispatch(setCurrentDealDealTypeAC(deal?.deal_type));
    }
  }, [deal, dispatch]);
  useEffect(() => {
    let isSubscribed = true;
    let intake_type = "";
    getDealByID(match.params.id)
      .then((res) => {
        dispatch(setProvince(res.data.appointment[0]?.timezone));
        (isSubscribed || reviewed) && setDeal(res.data);
        if (res.data.deal_type === "Sale") {
          intake_type = "sellers";
          return;
        }
        if (res.data.deal_type === "Purchase") {
          intake_type = "purchasers";
          return;
        }
        if (res.data.deal_type === "Mortgage") {
          intake_type = "owners";
          return;
        }
        if (res.data.deal_type === "Lender") {
          intake_type = "lenders";
          return;
        }
      })
      .then(
        () =>
          (isSubscribed || reviewed) &&
          handleGetIntakeData(
            match.params.id,
            intake_type,
            reloadData.onFinished,
          ),
      )
      .catch((error) => {
        if (error.response?.status === 403) {
            return history.push('/not-found');
        }
        enqueueSnackbar(error.message, {
          variant: "error",
          autoHideDuration: configuration.autoHideErrorDuration,
        });
      });

    return () => (isSubscribed = false);
  }, [reloadData, reviewed]);

  const SignignAppointmentLayout = () => {
    const {title, content} = signingAppointment;
    return (
      <div className="signing__wrapper">
        <div className="signing__container">
          <div className="header">
            <div className="view__title">{title}</div>
            <div
              className="close__btn"
              onClick={() => setSigningAppointment(null)}
            >
              <CrossSVG color={"#000000"} />
            </div>
          </div>
          <div className="content">{content}</div>
          <div className="buttons">
            <button
              className="approve"
              onClick={() => setSigningAppointment(null)}
            >
              approve
            </button>
            <button
              className="reject"
              onClick={() => setSigningAppointment(null)}
            >
              reject
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="clientintake__wrapper">
      <DetailsTabNavigation
        showDocumentsTab={
          user.role !== "Company Admin" &&
          user.role !== "Mobile Signer" &&
          !isActualDeededCustomerClient
        }
      />

      {deal === null || loadingIntake? (
        <DeededCircularLoader />
      ) : ( !!intake ? <Intake deal={deal} intake={intake} handleIntakeReview={handleIntakeReview} user={user}/> : (
        <div className="clientintake__container">
          {/* SIGNING APPOINTMENT */}
          {signingAppointment && SignignAppointmentLayout()}
          {/* DOCUMENT VIEW */}
          {showDocumentPopup && (
            <DocumentViewContainer
              selectedDocument={selectedDocument}
              toggleDocumentPopup={toggleDocumentPopup}
            />
          )}
          {/* HEADER */}
          <DealPropertyCard
            dealState={deal}
            handlePropertyDataUpdate={handleHeaderUpdate}
            user={user}
          />

          <div className="reviewed__card">
            <label className="container">
              <input
                readOnly={true}
                type="checkbox"
                checked={intakeReview !== null}
                name="reviewed"
                onClick={handleIntakeReview}
              />
              Client intake reviewed
              <span className="checkmark"></span>
            </label>
            {intakeReview !== null && (
              <span className="reviewed__data">{`${intakeReview?.reviewed_at} by ${intakeReview?.reviewed_by?.full_name}`}</span>
            )}
          </div>
          {/* CLIENT INTAKE TABS */}
          {clients === null || intakeType === "" ? (
            <h2 style={{textAlign: "center", margin: "3rem auto"}}>
              No Client Intake Data
            </h2>
          ) : (
            <Tabs defaultIndex={0} onSelect={() => {}}>
              <TabList>
                {clients.map((client, idx) => {
                  const {name_prefix, full_name} = client[intakeType][idx];
                  const {ip, city, state} = client.location || [];
                  const isProvinceMatch =
                    client?.cachedDeal?.address?.state === state ? true : false;

                  return (
                    <Tab key={idx}>
                      <div className="client__name">
                        <div className="tab__order">{idx + 1}</div>
                        <div> {`${name_prefix} ${full_name}`}</div>
                      </div>
                      {idx === 0 && (
                        <div className="client__location">
                          <span>IP: {ip || ""}</span>
                          <span>City: {city || ""}</span>
                          <span
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            State:{state || ""}{" "}
                            {!isProvinceMatch && (
                              <span className="province__flag">FLAG</span>
                            )}
                          </span>
                        </div>
                      )}
                    </Tab>
                  );
                })}
              </TabList>
              {clients.map((client, idx) => {
                return (
                  <TabPanel key={idx}>
                    <ClientIntakeCards
                      onRerender={onIntakeCardsRerender}
                      client={client}
                      deal={deal}
                      intakeType={intakeType}
                      userIndex={idx}
                    />
                  </TabPanel>
                );
              })}
            </Tabs>
          )}
        </div>
      ))}
    </div>
  );
};

const mapStateToProps = ({AuthReducer}) => {
  return {
    user: AuthReducer.currentUser,
  };
};
export default withSnackbar(connect(mapStateToProps, null)(ClientIntake));
