import {createUserEventCountTableData} from "./createUserEventCountTableData";
import {MobileSignersAppointmentsType} from "../components/CalendarEventsCountingTables";

export const getMobileSignersSigningAppointmentCountTableArray = (typedMobileSignersAppointments:MobileSignersAppointmentsType)=>{
  return  typedMobileSignersAppointments
    ? Object.keys(typedMobileSignersAppointments)
      .map(
        (key) =>
          typedMobileSignersAppointments[key as "oneOfBigAmountUsers"],
      )
      .map((mobileSigner) =>
        createUserEventCountTableData(
          mobileSigner.full_name,
          mobileSigner.dealTypes.Purchase,
          mobileSigner.dealTypes.Mortgage,
          mobileSigner.dealTypes.Sale,
          mobileSigner.dealTypes.Lender,
          0,
          0,
          0,
          0,
          0,
          mobileSigner.sum,
        ),
      )
    : [];

}