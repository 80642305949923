import React from "react";

type HomeType = {
  color?: string | undefined;
};
const Home: React.FC<HomeType> = ({color}) => {
  return (
    <svg width="24" height="24">
      <path
        d="M19.7489 9.36406L10.7109 0.295388C10.5211 0.104931 10.2686 0 10.0001 0C9.7315 0 9.47898 0.104836 9.28926 0.295388L0.251178 9.36406C-0.0515542 9.66774 -0.0161781 9.92884 0.031774 10.0528C0.0798167 10.1767 0.22313 10.3924 0.643481 10.3924H1.66115V18.4524C1.66115 19.2913 2.35564 20 3.14206 20H7.72303V14.5368H12.1563V20H16.8579C17.6469 20 18.3087 19.2768 18.3087 18.4524V10.3924H19.3565C19.7768 10.3924 19.9201 10.1767 19.9682 10.0528C20.0162 9.92884 20.0516 9.66783 19.7489 9.36406Z"
        fill={color}
      />
    </svg>
  );
};
export default Home;
