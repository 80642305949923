import { FC, forwardRef, useEffect, useState } from "react";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import * as _ from "lodash";
import { TextFieldProps } from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { InputAdornment, MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";
import { StyledComponent } from "@emotion/styled";
import { BodyRegular, BodySmall, SubtitleSmall } from "components/CommonDashboard/Typography";
import Colors from "components/CommonDashboard/Styling/Colors";
import { TextFieldStyledInput } from "components/CommonDashboard/TextField";
import { ZoomIcon } from "components/CommonDashboard/Icons";

export interface AddressAutocompleteResult {
    address: string;
    unit: string;
    city: string;
    state: string;
    code: string;
}

export interface AddressAutocompleteFieldProps
    extends Omit<TextFieldProps, "size" | "label" | "error" | "type" | "variant"> {
    label?: string;
    LabelComponent?: StyledComponent<{ color?: string }>;
    observation?: string;
    error?: string;
    size?: "default"; // Only size for now
    outlined?: boolean;
    onSelectAddress?: (value: AddressAutocompleteResult) => void;
    allowedProvinces?: string[];
}

const AddressAutocompleteField: FC<AddressAutocompleteFieldProps> = forwardRef<
    HTMLDivElement,
    AddressAutocompleteFieldProps
>(function inputRender(
    {
        outlined = false,
        label,
        LabelComponent = SubtitleSmall,
        observation,
        size,
        error,
        fullWidth = true,
        disabled,
        onChange,
        onSelectAddress,
        allowedProvinces = ["AB", "BC", "ON"],
        ...props
    },
    ref,
) {
    const { placesService, placePredictions, getPlacePredictions } = usePlacesService({
        debounce: 400,
        options: { componentRestrictions: { country: "CA" }, fields: ["address_components"] },
    });
    const [predictions, setPredictions] = useState<typeof placePredictions>([]);

    const handleSelect = async (placeId: string | number) => {
        if (!placesService || !onChange || !props.name) return;

        const address = {
            address: "",
            unit: "",
            city: "",
            state: "",
            code: "",
        } as AddressAutocompleteResult;

        placesService.getDetails({ placeId }, (placeDetails: any) => {
            let street,
                stNumber = "";
            placeDetails?.address_components?.map(
                (comp: { types?: string[]; long_name: string; short_name: string }) => {
                    if (comp.types?.includes("route")) street = comp.long_name;
                    if (comp.types?.includes("street_number")) stNumber = comp.long_name;
                    if (comp.types?.includes("subpremise")) address.unit = comp.long_name;
                    if (comp.types?.includes("locality")) address.city = comp.long_name;
                    if (comp.types?.includes("administrative_area_level_1")) address.state = comp.short_name;
                    if (comp.types?.includes("postal_code")) address.code = comp.long_name;
                },
            );
            address.address = [stNumber, street].filter((el) => !!el).join(" ");

            onChange({ target: { name: props.name, value: address.address } } as never);
            onSelectAddress && onSelectAddress(address);
        });

        setPredictions([]);
    };

    useEffect(() => {
        if (placePredictions.length != predictions.length || placePredictions[0]?.place_id) {
            setPredictions(placePredictions || []);
        }
    }, [placePredictions]);

    return (
        <Stack flex={fullWidth ? 1 : undefined} direction="column" gap="0.6rem">
            <Stack direction="row" gap="0.7rem">
                {label && <LabelComponent color={Colors[error ? "ERROR" : "BLACK"]}>{label}</LabelComponent>}
                {observation && <BodySmall color={Colors[error ? "ERROR" : "DARK_GREY"]}>{observation}</BodySmall>}
            </Stack>

            <TextFieldStyledInput
                {...props}
                ref={ref}
                disabled={disabled}
                variant="outlined"
                fullWidth={fullWidth}
                outlined={outlined}
                hasError={Boolean(error)}
                type="text"
                onChange={(e) => {
                    getPlacePredictions({ input: e.target.value });
                    onChange && onChange(e);
                }}
                onFocus={(e) => {
                    if (placePredictions.length && !predictions.length) setPredictions(placePredictions);
                    props.onFocus && props.onFocus(e);
                }}
                InputProps={{
                    ...(props.InputProps || {}),
                    endAdornment: null,
                    startAdornment: (
                        <InputAdornment sx={{ paddingLeft: "1.6rem" }} position="start">
                            <ZoomIcon color={Colors.NEUTRAL_500} />
                        </InputAdornment>
                    ),
                }}
            />
            <Stack display={predictions.length ? "flex" : "none"} height={0} width="100%" position="relative">
                <PredictionsBackdrop onClick={() => setPredictions([])} />
                <PredictionsContainer>
                    {predictions
                        .filter(
                            (p) =>
                                _.intersection(
                                    p.terms.map((t: any) => t.value),
                                    allowedProvinces,
                                ).length > 0,
                        )
                        .map((p) => (
                            <PredictionsItem onClick={() => handleSelect(p.place_id)} key={p.place_id}>
                                <BodyRegular noWrap>{p.description}</BodyRegular>
                            </PredictionsItem>
                        ))}
                </PredictionsContainer>
            </Stack>

            {error && <BodySmall color={Colors.ERROR}>{error}</BodySmall>}
        </Stack>
    );
});

const PredictionsItem = styled(MenuItem)({
    padding: "1.6rem",
    borderRadius: "1.6rem",
    display: "flex",
    flexDirection: "row",
    "&:hover": {
        backgroundColor: Colors.PRIMARY_100,
    },
});

const PredictionsContainer = styled(Stack)({
    zIndex: 999,
    width: "100%",
    backgroundColor: Colors.WHITE,
    position: "absolute",
    padding: ".8rem",
    borderRadius: "1.6rem",
    boxShadow: "0 4px 16px 0 #0000000D",
});

const PredictionsBackdrop = styled(Stack)({
    zIndex: 998,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    position: "fixed",
});

export default AddressAutocompleteField;
