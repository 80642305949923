import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Colors from "../Styling/Colors";

const PageContainer = styled(Stack)<{ background?: keyof typeof Colors; gap?: string }>(
    ({ theme, background = "PRIMARY_50", gap }) => ({
        gap: gap ? gap : "2.4rem",
        padding: "2.4rem 4.8rem",
        minHeight: "100%",
        background: Colors[background],
        [theme.breakpoints.down("lg")]: {
            padding: "2.4rem",
        },
        [theme.breakpoints.down("mobile")]: {
            padding: "2.4rem 1.6rem 7.2rem 1.6rem",
        },
    }),
);

export const PageSection = styled(Stack)(({ bgcolor, alignItems }) => ({
    alignItems: alignItems ? alignItems.toString() : "center",
    backgroundColor: bgcolor ? bgcolor.toString() : Colors.WHITE,
    padding: "2.4rem",
    borderRadius: "2.4rem",
}));

export default PageContainer;
