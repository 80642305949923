import {SVGProps} from "react";

const DefaultImageUploadIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width={10} height={10} viewBox="0 0 20 20" {...props}>
      <path
        d="M10 9.781c2.224 0 4.027-2.19 4.027-4.89C14.027 2.19 13.435 0 10 0 6.566 0 5.974 2.19 5.974 4.89c0 2.702 1.802 4.891 4.026 4.891ZM2.395 17.249c0-.165-.002-.047 0 0ZM17.605 17.377c.002-.045 0-.312 0 0Z"
        fill="#7F7F7F"
      />
      <path
        d="M17.596 17.051c-.074-4.705-.689-6.046-5.391-6.894 0 0-.662.843-2.205.843-1.543 0-2.205-.843-2.205-.843-4.651.839-5.303 2.16-5.389 6.741-.007.375-.01.394-.011.35v.495S3.515 20 10 20s7.605-2.257 7.605-2.257v-.366c-.001.027-.004-.025-.009-.326Z"
        fill="#7F7F7F"
      />
    </svg>
  );
};

export default DefaultImageUploadIcon;
