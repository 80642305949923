//@ts-check
import {useEffect, useState, useRef, FC} from "react";
import {Route, Switch} from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material";
import "./Dashboard.scss";
// PAGES
import Home from "./Home/Home";
import LeadDetails from "./LeadDetails/LeadDetails";
import NewClient from "./NewClient/NewClient";
import NotFound from "../Dashboard/404/NotFound";
import ClientIntake from "./ClientIntake/ClientIntake";
import ActivityLog from "./ActivityLog/ActivityLog";
import Files from "../DealStorage/Files/DealStorage";
import Profile from "components/Dashboard/Profile/Profile";
import Integrations from "components/Dashboard/Integrations/Integrations";
// SVG Components
import DeededLoader from "../Svg/DeededLoader";
import StoragePage from "./StoragePage/StoragePage";
// UTILITIES
import {useDispatch} from "react-redux";
import {useSnackbar} from "notistack";
import Survey from "./Survey/Survey";
import GoogleCalendar from "components/Dashboard/Calendar/Calendar";
import {setIdActionsNullAC} from "redux/reducers/storageReducer";
import DealDetailsHOC from "./DealDetails/DealDetailsHOC";
import Config from "components/Dashboard/Config/Config";
import Header, {HEADER_SIZE} from "components/Dashboard/Header/Header";
import DealsList from "components/Dashboard/DealsList/DealsList";
import LeadsList from "components/Dashboard/DealsList/LeadsList";
import Quote from "./Quote/Quote";
import configuration from "utils/configuration";
import useCheckTokenExpiration from "utils/hooks/useCheckTokenExpiration";
import storage from "utils/storage";
import {
  setCalendarTypeAC,
  setSelectedStaffNameAC,
  setSelectedStaffUserAC,
} from "redux/reducers/calendarReducer";
import {getCalendarSetting} from "components/Dashboard/Calendar/utils/getCalendarSetting";
import DealDetailsFileTasks from "components/Dashboard/DealDetailsFileTasks/DealDetailsFileTasks";
import DcpRoutes from "conveyance/routes/Routes";
import { shouldShowCalendarInSidebar, shouldShowStorageInSidebar } from "utils/permissionRoles";
import useCheckTermsAndConditions from "utils/hooks/useCheckTermsAndConditions";
import { resolveJwtTokenVerified } from 'utils/resolveJwtTokenVerified'
import { useHistory } from "react-router-dom";
import { useConveyance } from "utils/hooks/useConveyance";
import useUser from "utils/hooks/useUser";
import useTypedSelector from "utils/hooks/useTypedSelector";
import Sidebar from "./Sidebar/Sidebar";
import BottomNav from "./BottomNav/BottomNav";

const Dashboard: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const divRef = useRef(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const {enqueueSnackbar} = useSnackbar();
  const storedSelectedStaffName = getCalendarSetting("selectedStaffName");
  const { user: currentUser, isClient, isStaff, isAdmin } = useUser();
  const isLoading = useTypedSelector((state) => state.AuthReducer.authLoading);
  const loginError = useTypedSelector((state) =>
    state.AuthReducer.error === "" || !state.AuthReducer.error ? null : state.AuthReducer.error
  );
  const { conveyanceEnabled } = useConveyance({ user: currentUser })
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));

  useEffect(() => {
    if (storedSelectedStaffName) {
      dispatch(setSelectedStaffNameAC(storedSelectedStaffName));
    }
  }, [dispatch, storedSelectedStaffName]);
  useEffect(() => {
    if (getCalendarSetting("selectedStaffUser")) {
      dispatch(setSelectedStaffUserAC(getCalendarSetting("selectedStaffUser")));
    }
  }, [dispatch]);
  useEffect(() => {
    const storedCalendarType = getCalendarSetting("calendarType");
    if (storedCalendarType) {
      dispatch(setCalendarTypeAC(storedCalendarType));
    } else if (
      currentUser?.role === "Lawyer" ||
      currentUser?.role === "Law Clerk"
    ) {
      dispatch(setCalendarTypeAC("Closings"));
    } else {
      dispatch(setCalendarTypeAC("Signing Appointment"));
    }
  }, [dispatch, currentUser?.role]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (loginError !== null) {
      enqueueSnackbar(loginError, {
        variant: "error",
        autoHideDuration: configuration.autoHideErrorDuration,
      });

      const t = setTimeout(() => {
        /** Logout. I NEED TO BE REFACTORED PLS, MY NEXT OWNER, MOVE ME TO FUNCTION */
        storage.removeUserId();
        storage.removePreviousUserId();
        window.location.reload();
      }, 300);
      return () => clearTimeout(t);
    }
  }, [loginError]);

  useEffect(() => {
    window.addEventListener('focus', e => {
      if (resolveJwtTokenVerified() === 'expired') {
        history.replace('/login');
      }
    })
  }, [history]);

  useEffect(() => {
    if (isClient) {
      return history.replace('/portal/deals');
    }
  }, [isClient]);

  let isRefreshCalled = useCheckTokenExpiration();
  useCheckTermsAndConditions(isRefreshCalled);

  if (isLoading || !isRefreshCalled) {
      return <DeededLoader />
  } 

  return (
    <>
      <Header onMenuClick={() => setSidebarOpen(!sidebarOpen)} sideMenuOpen={sidebarOpen} />
      <div
        onClick={() => {
          dispatch(setIdActionsNullAC());
        }}
        className="dashboard__wrapper"
      >
        <Sidebar
            conveyanceEnabled={conveyanceEnabled}
            open={sidebarOpen}
            onRequestClose={() => setSidebarOpen(false)}
        />
        <BottomNav />
          
        <div className="main" style={{marginTop: HEADER_SIZE[isMobile ? "mobile" : "default"]}}>
          <Switch>
            {shouldShowCalendarInSidebar(currentUser.role) && (
              <Route
                path="/dashboard/calendar"
                exact
                component={GoogleCalendar}
              />
            )}

            <Route path="/dashboard" exact component={Home} />
            <Route path="/dashboard/deals" exact component={DealsList} />
            
            {!isClient && (
              <Route path="/dashboard/quote" exact component={Quote} />
            )}

            {shouldShowStorageInSidebar(currentUser.role) && (
              <Route path="/dashboard/storage" exact component={StoragePage} />
            )}
            <Route
              path="/dashboard/deals/:id"
              exact
              component={DealDetailsHOC}
            />
            <Route path="/dashboard/leads" exact component={LeadsList} />
            <Route path="/dashboard/leads/:id" exact component={LeadDetails} />
            <Route path="/dashboard/deals/files/:id" exact component={Files} />
            <Route path="/dashboard/leads/files/:id" exact component={Files} />
            {/* <Route path="/dashboard/idv/:id" exact component={IdvOldDesign} /> */}
            <Route
              path="/dashboard/deals/client-intake/:id"
              exact
              component={ClientIntake}
            />
            <Route
              path="/dashboard/leads/client-intake/:id"
              exact
              component={ClientIntake}
            />
            <Route
              path="/dashboard/deals/activity-log/:id"
              exact
              component={ActivityLog}
            />
            <Route
              path="/dashboard/leads/activity-log/:id"
              exact
              component={ActivityLog}
            />
            <Route
              path={"/dashboard/leads/file-tasks/:id"}
              exact
              component={DealDetailsFileTasks}
            />
            <Route
              path={"/dashboard/deals/file-tasks/:id"}
              exact
              component={DealDetailsFileTasks}
            />
            <Route
              path="/dashboard/deals/review-survey/:id"
              exact
              component={Survey}
            />
            <Route
              path="/dashboard/leads/review-survey/:id"
              exact
              component={Survey}
            />

            {isAdmin && (
              <Route path="/dashboard/config" component={Config} />
            )}

            {isStaff && (
                <Route path="/dashboard/integrations" component={Integrations} />
            )}

            <Route path="/dashboard/profile" component={Profile} />
            <Route path="/dashboard/new-client" component={NewClient} />
            {conveyanceEnabled && (
              <DcpRoutes role={currentUser?.role}/>
            )}
            <Route component={NotFound} />
          </Switch>
        </div>
        <div ref={divRef} />
      </div>
    </>
  );
};

export default Dashboard;
