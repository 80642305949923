import {useState} from "react";
import {useSnackbar} from "notistack";
import saveAs from "file-saver";
import { downloadDocument } from "api";
import configuration from "../configuration";

const useDocumentDownloadWithLoadMark = (
  filename: string,
  type: string,
  documentId: number,
  downloadUrl: string,
) => {
  const [isDocumentLoading, setIsDocumentLoading] = useState(false);
  const {enqueueSnackbar} = useSnackbar();

  const documentDownload = (documentId: number) => {
    setIsDocumentLoading(true);
    downloadDocument(documentId, type, downloadUrl)
      .then((res) => {
        const blob = new Blob([res.data], {type});
        saveAs(blob, filename);
        setIsDocumentLoading(false);
      })
      .catch((err) => {
        setIsDocumentLoading(false);
        enqueueSnackbar("Couldn't download " + filename, {
          variant: "error",
          autoHideDuration: configuration.autoHideErrorDuration,
        });
      });
  };

  return {
    isDocumentLoading,
    load: () => {
      if (!isDocumentLoading) {
        documentDownload(documentId)
      }
    },
  };
};
export default useDocumentDownloadWithLoadMark;
