import {useState} from "react";
import {Box, useTheme} from "@mui/material";
import {styled} from "@mui/material/styles";
import ProfileIntegrationCard from "./components/ProfileIntegrationCard";
import WorkDocSvg from "../../Svg/WorkDocSvg";
import useUser from "../../../utils/hooks/useUser";
import constants from "styles/constants";
import DeededPopup from "v2/components/DeededPopup";
import ProfileIntegrationWorkDocForm from "./components/ProfileIntegrationWorkDocForm";
import ProfileIntegrationThreeD from "./components/ProfileIntegrationThreeD/ProfileIntegrationThreeD";
import {useProfileGenerateApiKeyMutation} from "redux/reducers/profileApi";
import {getProfileTC} from "redux/reducers/profileReducer";
import {useAppDispatch} from "redux/hooks";
import useTypedSelector from "utils/hooks/useTypedSelector";

const ProfileIntegrationTab = () => {
  const dispatch = useAppDispatch();
  const [showWorkDocIntegrationPopup, setShowWorkDocIntegrationPopup] =
    useState(false);

  const [showThreeDIntegrationPopup, setShowThreeDIntegrationPopup] =
    useState(false);
  const theme = useTheme();
  const {user} = useUser();
  const [generateApiKey] = useProfileGenerateApiKeyMutation();

  const handleConnectToThreeD = async () => {
    await generateApiKey();
    await dispatch(getProfileTC());
  };

  const profile = useTypedSelector((state) => state.ProfileReducer.profile);

  return (
    <>
      <IntegrationBox>
        <IntegrationBoxWrapper>
          <IntegrationBoxItem>
            <ProfileIntegrationCard
              icon={(<WorkDocSvg />) as unknown as Element}
              descriptionTitle="WorkDocs"
              description={workDocDescription}
              handleConnect={() => {
                setShowWorkDocIntegrationPopup(true);
              }}
              isConnected={!!user.workdocs_user_status}
              isShowDetails={false}
            />
          </IntegrationBoxItem>
          <IntegrationBoxItem>
            <ProfileIntegrationCard
              icon={(<WorkDocSvg />) as unknown as Element}
              descriptionTitle="Third Party"
              description={threeDPartyDescription}
              handleConnect={handleConnectToThreeD}
              handleDetails={() => {
                setShowThreeDIntegrationPopup(true);
              }}
              isConnected={!!profile?.api_key_status}
              isShowDetails={true}
            />
          </IntegrationBoxItem>
        </IntegrationBoxWrapper>
      </IntegrationBox>

      <DeededPopup
        sx={{
          "& .MuiPaper-root": {
            maxWidth: "unset",
            overflow: "visible",
            [theme.breakpoints.down("md")]: {
              width: "100%",
            },
          },
        }}
        sxInternalBox={{
          width: "632px",
          overflowX: "hidden",
          overflowY: "auto",
          borderRadius: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          "&::-webkit-scrollbar": {
            width: "5px",
            scrollbarWidth: "thin",
          },
          [theme.breakpoints.down("md")]: {
            width: "100%",
          },
        }}
        open={showWorkDocIntegrationPopup}
        setOpen={() => {
          setShowWorkDocIntegrationPopup(false);
        }}
        title="WorkDocs Integration"
      >
        <ProfileIntegrationWorkDocForm
          handleCancel={() => {
            setShowWorkDocIntegrationPopup(false);
          }}
        />
      </DeededPopup>

      <DeededPopup
        sx={{
          "& .MuiPaper-root": {
            maxWidth: "unset",
            overflow: "visible",
            borderRadius: "4px",
            [theme.breakpoints.down("md")]: {
              width: "98%",
            },
          },
        }}
        sxInternalBox={{
          width: "595px",
          overflowX: "hidden",
          overflowY: "auto",
          borderRadius: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          "&::-webkit-scrollbar": {
            width: "5px",
            scrollbarWidth: "thin",
          },
          [theme.breakpoints.down("md")]: {
            width: "100%",
          },
        }}
        open={showThreeDIntegrationPopup}
        setOpen={() => {
          setShowThreeDIntegrationPopup(false);
        }}
        title="Deeded Lead API"
      >
        <ProfileIntegrationThreeD />
      </DeededPopup>
    </>
  );
};

export const workDocDescription =
    "Fully managed, secure content creation, storage, and collaboration service. With WorkDocs, you can easily create, edit, and share content, and access it from anywhere on any device.";
export const threeDPartyDescription =
    "Third Party tools can be added to your site to help users to find leads more easily and quickly.";

const IntegrationBox = styled(Box)(() => ({
  width: "100%",
  backgroundColor: constants.colors.white,
  height: "100%",
  marginTop: "15px",
  padding: "24px",
  display: "flex",
  alignItems: "flex-start",
}));

const IntegrationBoxWrapper = styled(Box)(({theme}) => ({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "flex-start",
  marginLeft: "-12px",
  marginRight: "-12px",
  [theme.breakpoints.down("sm")]: {
    justifyContent: "center",
  },
}));

const IntegrationBoxItem = styled(Box)(() => ({
  padding: "0 12px",
  marginBottom: "20px",
}));

export default ProfileIntegrationTab;
