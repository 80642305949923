import { FC } from "react";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material";
import Box from "@mui/material/Box";
import useUser from "utils/hooks/useUser";
import configuration from "utils/configuration";
import { isProfessional } from "utils/permissionRoles";
import bannerBg from "assets/img/points-banner-bg.png";
import GiftsImage from "../../Svg/GiftsImage";
import Colors from "../../CommonDashboard/Styling/Colors";
import { BodyRegular } from "../../CommonDashboard/Typography";
import Button from "../../CommonDashboard/Button";
import { ArrowRightIcon } from "../../CommonDashboard/Icons";

const PointsBanner: FC = () => {
    const { user } = useUser();
    const points = user.points ?? 0;

    if (!configuration.pointsEnabled || !user.points_feature || !isProfessional(user.role) || points <= 0) {
        return null;
    }

    return (
        <Stack maxWidth="30rem" alignItems="center">
            <Box marginBottom="-3.9rem" zIndex={2}>
                <GiftsImage />
            </Box>
            <TextWrapper>
                <BodyRegular>
                    Congratulations, you have earned <strong>{points} Loyalty Points!</strong>
                </BodyRegular>
                <Button
                    typeOf="link"
                    component="a"
                    href={configuration.pointsUrl}
                    target="_blank"
                    label={{ text: "Redeem now" }}
                    endIcon={<ArrowRightIcon color={Colors.NEUTRAL_700} />}
                />
            </TextWrapper>
        </Stack>
    );
};

const TextWrapper = styled(Stack)(() => ({
    gap: "2.4rem",
    zIndex: 1,
    top: "-20px",
    background: `url(${bannerBg})`,
    backgroundColor: Colors.PRIMARY_100,
    backgroundSize: "cover",
    borderRadius: "2.4rem",
    padding: "4.8rem 1.6rem 1.6rem",
}));

export default PointsBanner;
