import axios from "axios";
import {User} from "types/user";
import {Dispatch} from "redux";
import storage from "utils/storage";
import reloadAxios from "utils/axiosManager";
import { RootStore } from "types/flux";
interface userLoginType {
  user: User;
  access_token: string;
}
const userDataDispatch = (res: {data: userLoginType}, dispatch: Dispatch) => {
  storage.userId = res.data.user.id as unknown as string;
  storage.token = res.data.access_token;

  if (!storage.previousUserId) {
    storage.previousUserId = res.data.user.id as unknown as string;
  }
  dispatch(userLogIn(res.data.user));
};
export const userMtfLogin =
  (user: {email: string; password: string; code: string}) =>
  (dispatch: Dispatch) => {
    dispatch(loaderOn());
    axios
      .post("login-verify-code", user)
      .then((res) => {
        userDataDispatch(res, dispatch);
      })
      .catch((error) => {
        dispatch(loginError(error?.response?.data?.error));
      });
  };
export const userLogin =
  (
    user: {email: string; password: string},
    setIsMtfAuthShowed: (isMtfAuthShowed: boolean) => void,
    setVerifyToken: (verifyToken: string) => void,
  ) =>
  (dispatch: Dispatch) => {
    dispatch(loaderOn());
    axios
      .post(`/login`, user)
      .then((res) => {
        if (res.status === 201) {
          setIsMtfAuthShowed(true);
          setVerifyToken(res.data.login_token);
          dispatch(loaderOff());
        } else {
          userDataDispatch(res, dispatch);
        }
      })
      .catch((error) => {
        dispatch(loginError(error?.response?.data?.error));
      });
  };

export const userLogOut = (userId: string) => (dispatch: Dispatch) => {
  localStorage.removeItem("current_page");

  storage.previousUserId = userId;

  storage.removeToken();
  storage.removeUserId();
  dispatch(LogOut());
};

export const refreshToken = () => (dispatch: Dispatch, getState: () => RootStore) => {
  axios
    .get("/refresh")
    .then((res) => {
      storage.token = res.data.access_token;
      storage.userId = res.data.user.id;
      const firstName = res.data.user.first_name;
      
      const refreshCalled = getState().AuthReducer.refreshCalled;
      if (refreshCalled) {
        return; //If refresh was already called before, no need to dispatch user info again
      }
      
      if (!firstName) {
        throw new Error("Auth error. Please re-login");
      } else {
        dispatch(userLogIn(res.data.user));
        dispatch({type: "REFRESH_CALLED"});
      }
    })
    .catch((error) => {
      dispatch(loginError(error?.response?.data?.error ?? error?.message));
    });
};
export const loaderOff = () => ({
  type: "AUTH_LOADER_OFF",
});
export const loaderOn = () => {
  return {
    type: "AUTH_LOADER_ON",
  };
};

export const loginError = (error: string) => {
  return {
    type: "LOGIN_ERROR",
    payload: error,
  };
};

export const userLogIn = (user: User) => {
  return {
    type: "USER_LOGIN",
    payload: user,
  };
};
export const userUpdateAC = (user: User) => {
  return {
    type: "USER_UPDATE",
    payload: user,
  };
};

export const LogOut = () => {
  return {
    type: "USER_LOGOUT",
  };
};
