import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import {Box, MenuItem, styled, useTheme} from "@mui/material";
import {Theme} from "@mui/material/styles";
import * as yup from "yup";
import {useFormik} from "formik";
import dayjs from "dayjs";
import {stringOrDate} from "react-big-calendar";
import {addMinutes, setHours, setMinutes} from "date-fns";
import moment from "moment";
import constants from "styles/constants";
import DeededAutocompleteSelect from "v2/components/DeededAutocompleteSelect";
import DeededSelectV2Transparent from "v2/components/DeededSelect";
import DeededTimePicker from "v2/components/DeededTimePicker";
import DeededSelect from "v2/components/DeededSelect_DEPRECATED";
import DeededButton from "v2/components/DeededButton";
import DeededDatePicker from "v2/components/DeededDatePicker";
import CloseDeededSvg from "components/Svg/CloseDeededSvg";
import StorageSmallFolderSvg from "components/Svg/StorageSmallFolderSvg";
import SaveIcon from "components/Svg/SaveIcon";
import { useDatepickerMinMaxTime, useDisplayDateString } from "components/Dashboard/SigningAppointmentFormSection/appointmentHooks";
import { timezones } from "components/Dashboard/DealDetails/utils";
import {useGetDealsByAutocompleteQuery} from "redux/reducers/calendarApi";
import {useAppDispatch, useAppSelector} from "redux/hooks";
import {useGetMobileSignersQuery} from "redux/reducers/dealInfoApi";
import {
  addSigningAppointmentTC,
  getBlockedTimeSlotsTC,
} from "redux/reducers/dealsReducer";
import useThemedStyles from "utils/hooks/useThemedStyles";
import useTypedSelector from "utils/hooks/useTypedSelector";

import useWindowWidth from "utils/hooks/useWindowWidth";
import { User } from "types/user";
import {Workhours} from "types/calendarAppointmentType";
import allRegions from "../constants/allRegions";
import {
  BigCalendarCreateSigningAppointmentErrorsType,
  BigCalendarCreateSigningAppointmentStylesType,
  BigCalendarCreateSigningAppointmentValuesType,
} from "../types/bigCalendarCreateSigningAppointmentTypes";
import {HandleChangeType} from "../types/bigCalendarBlockTimeTypes";
import {validateStartDateTimeForSigningAppointment} from "../utils/validateStartDateTimeForSigningAppointment";
import {stylesFactory} from "./BigCalendar";

const schema = yup.object().shape({
  deal: yup.string().required("Field is required"),
  clients: yup.array().of(yup.string()).min(1).required("Field is required"),
  time: yup.date().typeError("Field is required"),
  region: yup.string().required("Field is required"),
  mobileSigner: yup.string().required("Field is required"),
});
const correctDate = /\d{2}.\d{2}.20\d{2}/;
const correctSecondDate = /20\d{2}.\d{2}.\d{2}/;
const expectableDateFormat = "YYYY-MM-DD";
const apptInterval = 5;

const BigCalendarCreateSigningAppointment: React.FC<{
  startDate: stringOrDate;
  stylesFactory: typeof stylesFactory;
  onClose: () => void;
  getEventsEffectFunction: () => void;
}> = ({startDate, stylesFactory, onClose, getEventsEffectFunction}) => {
  const dispatch = useAppDispatch();
  const styles = useThemedStyles(stylesFactory);
  const theme = useTheme();
  const { sm } = theme.breakpoints.values;
  const windowWidth = useWindowWidth();
  const initialValues = {
    date: dayjs(startDate).format("MM/DD/YYYY"),
    deal: "",
    clients: [],
    startTime: "",
    endTime: "",
    region: allRegions[0],
    mobileSigner: "null",
  };
  const loading = useTypedSelector((state) => state.DealsReducer.submitLoader);
  const currentUser = useTypedSelector(
    (state) => state.AuthReducer.currentUser,
  ) as User;
  const [isLoading, setIsLoading] = useState(loading);
  const setFieldValue = useCallback((name: string, value: boolean) => {
    if (name === "isLoading") {
      setIsLoading(value);
    }
  }, []);
  const onSubmit = ({
    clients,
    startTime,
    endTime,
    region,
    mobileSigner,
    date,
  }: typeof initialValues) => {
    const payload = {
      address: "Empty Address",
      date: dayjs(date!).format(expectableDateFormat),
      signer: {old: null, new: +mobileSigner},
      start_time: dayjs(startTime).format("HH:mm:ss"),
      end_time: dayjs(endTime).format("HH:mm:ss"),
      timezone: region,
      clients: {
        new: clients,
        remove: [],
      },
    };
    if (dealId) {
      dispatch(
        addSigningAppointmentTC(
          dealId,
          payload,
          setFieldValue,
          getEventsEffectFunction,
        ),
      );
      onClose();
    }
  };

  const {handleSubmit, values, handleChange, errors} = useFormik({
    validationSchema: schema,
    initialValues,
    onSubmit,
  });
  const [dealId, setDealId] = useState<number | null>(null);

  const [openSelector, setOpenSelector] = useState(false);

  const handleClickMultiSelect = (id: number) => {
    if (values.clients.includes(id as never)) {
      handleChange({
        target: {
          name: "clients",
          value: values.clients.filter((writtenClient) => writtenClient !== id),
        },
      });
    } else {
      handleChange({
        target: {
          name: "clients",
          value: values.clients.concat([id as never]),
        },
      });
    }
    values.startTime && refetch();
  };

  const [searchDeal, setSearchDeal] = useState("");
  const [skipAutocompleteDeal, setSkipAutocompleteDeal] = useState(true);

  const {data: getDealsByAutocomplete} = useGetDealsByAutocompleteQuery(
    {
      search: searchDeal,
      date: dayjs(values.date).format("YYYY-MM-DD"),
    },
    {skip: skipAutocompleteDeal || !dayjs(values.date).isValid()},
  );
  const dealsArray = useMemo(
    () =>
      getDealsByAutocomplete
        ?.map((item) =>
          item.participants.length > 0
            ? {
                id: item.id,
                name: item.folder_name,
                participants:
                  item.participants.length > 0
                    ? item.participants
                        .filter(
                          (item) =>
                            item.role === "Client" ||
                            item.role === "Secondary Client",
                        )
                        .flat() ?? []
                    : null,
              }
            : null,
        )
        .flat() ?? [],
    [getDealsByAutocomplete],
  );

  const [dealsArrayState, setDealsArrayState] = useState<
    typeof dealsArray | undefined
  >(undefined);
  useEffect(() => {
    if (dealsArray.length > 0) {
      setDealsArrayState(dealsArray);
    }
  }, [dealsArray]);
  const dealsOptions = dealsArray.map((item) => ({
    id: item?.id,
    name: item?.name,
  }));

  useEffect(() => {
    const t = setTimeout(() => {
      if (values.deal.length > 1) {
        setSearchDeal(values.deal);
        setSkipAutocompleteDeal(false);
      } else {
        setSkipAutocompleteDeal(true);
      }
    }, 300);

    return () => {
      setSkipAutocompleteDeal(true);
      clearTimeout(t);
    };
  }, [values]);

  const clientsOptions = useMemo(
    () =>
      dealsArrayState
        ?.filter((item) => item?.id === dealId)
        .map((item) => item?.participants ?? [])
        .flat() ?? [],
    [dealId, dealsArrayState],
  );
  const [isLoadingBusyTimes, setIsLoadingBusyTimes] = useState(false);
  const [busyTimes, setBusyTimes] = useState<string[]>([]);
  useEffect(() => {
    if (
      values.startTime &&
      values.region &&
      values.mobileSigner !== "null" &&
      values.clients.length !== 0
    ) {
      const payload = {
        date: dayjs(values.date).format("YYYY-MM-DD"),
        timezone: values.region,
        signer: +values.mobileSigner,
        appointment: undefined,
      };

      dispatch(
        getBlockedTimeSlotsTC(
          dealId ?? -1,
          payload,
          setIsLoadingBusyTimes,
          setBusyTimes as (busyTimes: string[]) => void,
        ),
      );
    }
  }, [
    values.date,
    dealId,
    dispatch,
    startDate,
    values.clients,
    values.mobileSigner,
    values.region,
    values.startTime,
  ]);
  const {data: mobileSigners, refetch} = useGetMobileSignersQuery(
    {
      date: dayjs(values.date).format("YYYY-MM-DD"),
      start_time: dayjs(values.startTime).format("HH:mm:ss"),
      end_time: dayjs(values.endTime).format("HH:mm:ss"),
      timezone: values.region,
      appointment_id: null,
    },
    {
      skip:
        !(
          correctDate.test(values.date) || correctSecondDate.test(values.date)
        ) ||
        !values.startTime ||
        !values.endTime,
    },
  );
  useEffect(() => {
    if (values.startTime && values.endTime) {
      refetch();
    }
  }, [refetch]);
  const handleChangeDeal = useCallback(
    (deal: {id: number; name: string} | string) => {
      handleChange({
        target: {
          name: "deal",
          value: typeof deal === "string" ? deal : deal.name,
        },
      });
      if (typeof deal !== "string") {
        setDealId(deal.id);
        values.startTime && values.endTime && refetch();
      }
    },
    [handleChange, refetch, values.startTime, values.endTime],
  );


  const displayDateString = useDisplayDateString(
    dayjs(values.date, { format: 'MM/DD/YYYY' }).format('YYYY-MM-DD'),
    values.startTime ? dayjs(values.startTime).format("HH:mm:ss") : null,
    values.region,
    timezones.timezoneToState[currentUser?.timezone as string],
    "MMM D, YYYY"
  ); 

  return (
    <Form sx={styles.Form} onSubmit={handleSubmit}>
      <Box
        sx={{
          ...styles.FormItem,
          [theme.breakpoints.down("sm")]: {
            marginBottom: "18px !important",
          },
        }}
      >
        <FormLabel
          sx={{
            ...styles.FormLabel,
            [theme.breakpoints.down("sm")]: {
              width: "100%",
              maxWidth: "unset",
            },
          }}
        >
          <Box
            sx={{
              ...styles.FormLabelText,
              [theme.breakpoints.down("sm")]: {
                marginLeft: "8px",
              },
            }}
          >
            {windowWidth < sm
              ? "Transaction"
              : "Search by Transaction Number, Client Name or Address"}
          </Box>
          <DeededAutocompleteSelect
            sx={{
              [theme.breakpoints.down("sm")]: {
                "& .MuiBox-root .MuiFormControl-root .MuiOutlinedInput-root": {
                  height: "36px",
                },
                "& .MuiPaper-root": {
                  height: "200px",
                },
              },
            }}
            data-testid="signing-appointment-chose-deal-drop-down"
            error={errors.deal as string}
            onChangeValue={handleChangeDeal}
            options={
              (dealsOptions as {id: number; name: string}[] | null) ?? null
            }
            value={values.deal}
            placeholder="Choose the Deal"
            iconMenuItem={<StorageSmallFolderSvg />}
          />
        </FormLabel>
      </Box>
      <Box sx={styles.FormItem}>
        <FormLabel
          sx={{
            ...styles.FormLabel,
            [theme.breakpoints.down("sm")]: {
              width: "100%",
              maxWidth: "unset",
            },
          }}
        >
          <Box
            sx={{
              ...styles.FormLabelText,
              [theme.breakpoints.down("sm")]: {
                marginLeft: "8px",
              },
            }}
          >
            {windowWidth < sm ? "Client" : "Client(s)"}
          </Box>
          <ClientSelect
            data-testid="signing-appointment-choose-client-drop-down"
            open={openSelector}
            onOpen={() => {
              setOpenSelector(true);
            }}
            onClose={() => {
              setOpenSelector(false);
            }}
            width="100%"
            value={["Choose Client"]}
            multiple
            disabled={!values.deal}
            sx={{
              ...styles.ClientSelect,
              [theme.breakpoints.down("sm")]: {
                "&.MuiOutlinedInput-root": {
                  height: "36px !important",
                },
              },
            }}
          >
            {clientsOptions?.map((client, index) => (
              <MenuItem
                sx={{
                  color: values.clients.includes(client.user_id as never)
                    ? `${constants.colors.red} !important`
                    : constants.colors.title,
                }}
                onClick={() => {
                  handleClickMultiSelect(client.user_id);
                  setOpenSelector(false);
                }}
                key={index}
                value={client.user_id}
              >
                {client.full_name}
              </MenuItem>
            ))}
            <MenuItem
              sx={{
                display: "none !important",
              }}
              value="Choose Client"
            >
              Choose Client
            </MenuItem>
          </ClientSelect>
          {errors.clients && <Box sx={styles.Error}>{errors.clients}</Box>}
          <Box sx={styles.ClientsItems}>
            {values.clients.map((client, index) => {
              const fullClientInfo = clientsOptions?.find(
                (clientInfo) => clientInfo.user_id === client,
              );

              return (
                fullClientInfo && (
                  <Box
                    data-testid="signing-appointment-choose-client-drop-down-selected-value"
                    sx={{
                      ...styles.ClientsItem,
                      color: constants.colors.red,
                    }}
                    key={index}
                    className="active"
                  >
                    {fullClientInfo?.full_name}
                    <Box
                      sx={{display: "flex"}}
                      onClick={() => {
                        handleClickMultiSelect(client);
                      }}
                    >
                      <Box sx={styles.SvgIcon}>
                        <CloseDeededSvg />
                      </Box>
                    </Box>
                  </Box>
                )
              );
            })}
          </Box>
        </FormLabel>
      </Box>
      <Box
        sx={{
          ...styles.FormItem,
          [theme.breakpoints.down("sm")]: {
            marginBottom: "26px !important",
          },
        }}
      >
        {windowWidth < sm && (
          <DateSelectSigningAppointmentMobileContext.Provider
            value={{
              styles,
              values,
              handleChange: handleChange as () => void,
              errors: errors as BigCalendarCreateSigningAppointmentErrorsType,
              busyTimes,
            }}
          >
            <DateSelectSigningAppointmentMobile />
          </DateSelectSigningAppointmentMobileContext.Provider>
        )}
        {windowWidth >= sm && (
          <Box sx={{display: "flex"}}>
            <BigCalendarCreateSigningAppointmentDateFields
              styles={styles}
              values={values}
              handleChange={handleChange}
              errors={errors as Partial<{time: string; date: string}>}
              busyTimes={busyTimes}
            />
          </Box>
        )}
      </Box>
      <Box sx={styles.FormItem}>
        <FormLabel
          sx={{
            ...styles.FormLabel,

            [theme.breakpoints.down("sm")]: {
              maxWidth: "unset",
              "& .MuiBox-root .MuiOutlinedInput-root": {
                height: "36px !important",
              },
            },
          }}
        >
          <Box
            sx={{
              ...styles.FormLabelText,
              fontWeight: "500",
              [theme.breakpoints.down("sm")]: {
                marginLeft: "8px",
              },
            }}
          >
            {windowWidth >= sm && "Select"} Mobile Signer
          </Box>
          <DeededSelectV2Transparent
            data-testid="signing-appointment-mobile-signer-drop-down"
            onChange={(e) => handleChange(e)}
            value={values.mobileSigner}
            width="100%"
            name="mobileSigner"
            placeholder="Select Mobile Signer"
          >
            <MenuItem
              key="No options"
              value="null"
              sx={{
                display: "none !important",
                color: constants.colors.deededGray,
              }}
            >
              No option
            </MenuItem>
            {mobileSigners?.map(({full_name, id, busy}) => (
              <MenuItem key={id} value={id} disabled={busy}>
                {full_name}
              </MenuItem>
            ))}
          </DeededSelectV2Transparent>
        </FormLabel>
        {values.startTime && (
          <Box
            sx={{
              ...styles.FormInfo,

              [theme.breakpoints.down("sm")]: {
                backgroundColor: constants.colors.grayBackgroundClientInfo,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "52px",
                marginBottom: "16px",
                textAlign: "center",
                padding: "0px 10px",
                marginTop: "10px",
              },
            }}
          >
            Your local time for chosen province {values.region}, will be:{" "}
            {displayDateString}
          </Box>
        )}
      </Box>
      <Box
        sx={{
          ...styles.FormItemButtons,
          [theme.breakpoints.down("sm")]: {
            flexDirection: "column-reverse",
            marginTop: "0px",
          },
        }}
      >
        <DeededButton
          data-testid="signing-appointment-cancel-button"
          sx={{
            width: "75px",
            [theme.breakpoints.down("sm")]: {
              width: "100%",
            },
          }}
          kind={windowWidth < sm ? "secondary-v2" : "secondary"}
          onClick={onClose}
          className="cancel-appt"
          type="button"
        >
          Cancel
        </DeededButton>
        <DeededButton
          data-testid="signing-appointment-save-button"
          kind="primary"
          sx={{
            width: "120px",
            svg: {
              marginRight: "8px",
              stroke: constants.colors.white,
              transition: constants.transitions,
            },
            "&:hover": {
              svg: {
                stroke: constants.colors.red,
                transition: constants.transitions,
              },
            },
            [theme.breakpoints.down("sm")]: {
              width: "100%",
              marginBottom: "15px",
            },
          }}
          type="submit"
          disabled={isLoadingBusyTimes || isLoading}
        >
          {windowWidth >= sm && <SaveIcon />}{" "}
          {isLoadingBusyTimes || isLoading ? "Loading" : "Save"}
        </DeededButton>
      </Box>
    </Form>
  );
};
interface BigCalendarCreateSigningAppointmentDateFieldsProps {
  styles: BigCalendarCreateSigningAppointmentStylesType;
  values: BigCalendarCreateSigningAppointmentValuesType;
  handleChange: HandleChangeType;
  errors: BigCalendarCreateSigningAppointmentErrorsType;
  busyTimes: string[];
}

const BigCalendarCreateSigningAppointmentDateFields: React.FC<
  BigCalendarCreateSigningAppointmentDateFieldsProps
> = ({styles, values, handleChange, busyTimes, errors}) => {
  const createEventForCurrentDateByDefault = useAppSelector(
    (state) => state.CalendarReducer.createEventForCurrentDateByDefault,
  );
  const theme = useTheme();

  const [minTime, maxTime] = useDatepickerMinMaxTime(values.date as string, values.region, 'MM/dd/yyyy');
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          marginRight: "24px",
        }}
      >
        {!createEventForCurrentDateByDefault && (
          <FormLabel sx={{...styles.FormLabel, flex: 1}}>
            <Box sx={styles.FormLabelText}>Select Date</Box>
            <div data-testid="signing-appointment-select-date-picker">
              <DeededDatePicker
                minDate={new Date()}
                fieldWidth={163}
                hasError={errors.date}
                dateStart={values.date ? moment(values.date).toDate() : null}
                name="date"
                onChangeEvent={handleChange as (value: unknown) => void}
              />
            </div>
            {errors.startTime && (
              <Box sx={styles.Error}>{errors.startTime}</Box>
            )}
          </FormLabel>
        )}
        <FormLabel htmlFor="time" sx={{...styles.FormLabel, minWidth: "50%"}}>
          <Box sx={styles.FormLabelText}>Start Time</Box>
          <DeededTimePicker
            minTime={minTime}
            maxTime={maxTime}
            timeStart={values.startTime ? moment(values.startTime).toDate() : null}
            dateFormat={
              createEventForCurrentDateByDefault
                ? "MMMM dd, Y h:mm aa"
                : "h:mm aa"
            }
            onChangeEvent={handleChange as (value: unknown) => void}
            name="startTime"
            timeIntervals={apptInterval}
            excludeTimes={busyTimes.map((busyTime: string) => {
              const splittedTime = busyTime.split(":");
              return setHours(
                setMinutes(new Date(), +splittedTime[1]),
                +splittedTime[0],
              );
            })}
          />
          {errors.startTime && <Box sx={styles.Error}>{errors.startTime}</Box>}
        </FormLabel>

        <Box
          sx={{
            minWidth: "50%",
            marginTop: !createEventForCurrentDateByDefault ? "14px" : 0,
          }}
        >
          <RegionSelect
            handleChange={handleChange}
            values={values}
            styles={styles}
          />
        </Box>

        <FormLabel
          htmlFor="end-time"
          sx={{
            ...styles.FormLabel,
            marginTop: "14px",
          }}
        >
          <Box sx={styles.FormLabelText}>End Time</Box>
          <DeededTimePicker
            disabled={!values.startTime}
            minTime={
              values.startTime
                ? addMinutes(new Date(values.startTime), 1)
                : new Date(new Date().setHours(Workhours.start, 0, 0, 0))
            }
            maxTime={new Date(new Date().setHours(Workhours.end, 0, 0, 0))}
            timeStart={values.endTime ? moment(values.endTime).toDate() : null}
            dateFormat={
              createEventForCurrentDateByDefault
                ? "MMMM dd, Y h:mm aa"
                : "h:mm aa"
            }
            onChangeEvent={handleChange as (value: unknown) => void}
            name="endTime"
            timeIntervals={apptInterval}
            excludeTimes={busyTimes.map((busyTime: string) => {
              const splittedTime = busyTime.split(":");
              return setHours(
                setMinutes(new Date(), +splittedTime[1]),
                +splittedTime[0],
              );
            })}
          />
          {errors.endTime && <Box sx={styles.Error}>{errors.endTime}</Box>}
        </FormLabel>
      </Box>
    </>
  );
};
const DateSelectSigningAppointmentMobile = () => {
  const theme = useTheme();
  const {styles, values, handleChange, errors, busyTimes} = useContext(
    DateSelectSigningAppointmentMobileContext,
  );
  
  const [minTime, maxTime] = useDatepickerMinMaxTime(values.date as string, values.region, 'MM/dd/yyyy');
  return (
    <>
      <Box sx={{width: "100%"}}>
        <FormLabel
          sx={{
            ...styles.FormLabel,
            maxWidth: "unset",

            "& .MuiFormControl-root": {
              width: "100%",
              marginBottom: "10px",
              "& .MuiOutlinedInput-root": {
                height: "36px !important",
                "& .MuiInputAdornment-root .MuiButtonBase-root": {
                  top: "-2px",
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                  "&:active": {
                    backgroundColor: "transparent",
                  },
                  "&:focus": {
                    backgroundColor: "transparent",
                  },
                },
              },
            },
          }}
        >
          <Box
            sx={{
              ...styles.FormLabelText,
              [theme.breakpoints.down("sm")]: {
                marginLeft: "8px",
              },
            }}
          >
            Select Date
          </Box>
          <DeededDatePicker
            data-selenium-id={"select-date"}
            minDate={new Date()}
            hasError={errors.date}
            dateStart={values.date ? moment(values.date).toDate() : null}
            name="date"
            onChangeEvent={handleChange as (value: unknown) => void}
          />
          {errors.startTime && <Box sx={styles.Error}>{errors.startTime}</Box>}
        </FormLabel>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <FormLabel
            htmlFor="time"
            sx={{
              ...styles.FormLabel,
              width: "48%",
              [theme.breakpoints.down("sm")]: {
                marginBottom: "13px !important",
              },
            }}
          >
            <Box
              sx={{
                ...styles.FormLabelText,
                [theme.breakpoints.down("sm")]: {
                  marginLeft: "8px",
                },
              }}
            >
              Start Time
            </Box>
            <DeededTimePicker
              minTime={minTime}
              maxTime={maxTime}
              data-selenium-id={"select-time"}
              timeStart={values.startTime as Date}
              dateFormat={"h:mm aa"}
              onChangeEvent={handleChange as (value: unknown) => void}
              name="startTime"
              sx={{
                ".MuiOutlinedInput-root.MuiInputBase-root": {
                  width: `100% !important`,
                  height: "36px",
                  [theme.breakpoints.down("sm")]: {
                    width: "100% !important",
                  },
                  input: {
                    fontSize: "14px",
                  },
                },
              }}
              timeIntervals={apptInterval}
              excludeTimes={busyTimes.map((busyTime: string) => {
                const splittedTime = busyTime.split(":");
                return setHours(
                  setMinutes(new Date(), +splittedTime[1]),
                  +splittedTime[0],
                );
              })}
            />
            {errors.startTime && (
              <Box sx={styles.Error}>{errors.startTime}</Box>
            )}
          </FormLabel>
          <RegionSelect
            handleChange={handleChange}
            values={values}
            styles={styles}
          />
        </Box>
        <FormLabel
          htmlFor="end-time"
          sx={{
            ...styles.FormLabel,
            width: "48%",
          }}
        >
          <Box
            sx={{
              ...styles.FormLabelText,
              [theme.breakpoints.down("sm")]: {
                marginLeft: "8px",
              },
            }}
          >
            End Time
          </Box>
          <DeededTimePicker
            disabled={!values.startTime}
            minTime={
              new Date(
                values.startTime ||
                  new Date().setHours(Workhours.start, 0, 0, 0),
              )
            }
            maxTime={new Date(new Date().setHours(Workhours.end, 0, 0, 0))}
            data-selenium-id={"select-end-time"}
            timeStart={values.endTime as Date}
            dateFormat={"h:mm aa"}
            onChangeEvent={handleChange as (value: unknown) => void}
            name="endTime"
            sx={{
              ".MuiOutlinedInput-root.MuiInputBase-root": {
                width: `100% !important`,
                height: "36px",
                [theme.breakpoints.down("sm")]: {
                  width: "100% !important",
                },
                input: {
                  fontSize: "14px",
                },
              },
            }}
            timeIntervals={apptInterval}
            excludeTimes={busyTimes.map((busyTime: string) => {
              const splittedTime = busyTime.split(":");
              return setHours(
                setMinutes(new Date(), +splittedTime[1]),
                +splittedTime[0],
              );
            })}
          />
          {errors.endTime && <Box sx={styles.Error}>{errors.endTime}</Box>}
        </FormLabel>
      </Box>
    </>
  );
};
const RegionSelect: React.FC<{
  styles: BigCalendarCreateSigningAppointmentStylesType;
  values: BigCalendarCreateSigningAppointmentValuesType;
  handleChange: HandleChangeType;
}> = ({handleChange, values, styles}) => {
  const theme = useTheme();
  return (
    <>
      <FormLabel
        sx={{
          ...styles.FormLabel,
          [theme.breakpoints.down("sm")]: {
            width: "48%",
            "& .MuiBox-root .MuiOutlinedInput-root": {
              height: "36px",
              width: "100%",
              "& .MuiSelect-select": {
                paddingTop: "2px ",
                paddingBottom: "2px",
              },
              "& .MuiSelect-nativeInput": {},
            },
          },
        }}
      >
        <Box
          sx={{
            ...styles.FormLabelText,
            [theme.breakpoints.down("sm")]: {
              marginLeft: "8px",
            },
          }}
        >
          Region
        </Box>
        <DeededSelect
          value={values.region}
          data-testid="region-drop-down"
          onChange={(e) => {
            handleChange({
              target: {
                name: "region",
                value: e.target.value,
              },
            });
          }}
          displayEmpty
          inputProps={{"aria-label": "Without label"}}
        >
          {allRegions.map((item, index) => (
            <FlexibleMenuItem key={index} value={item}>
              {item}
            </FlexibleMenuItem>
          ))}
        </DeededSelect>
      </FormLabel>
    </>
  );
};
const DateSelectSigningAppointmentMobileContext = React.createContext({
  styles: {} as BigCalendarCreateSigningAppointmentStylesType,
  values: {} as BigCalendarCreateSigningAppointmentValuesType,
  handleChange: () => {},
  errors: {} as BigCalendarCreateSigningAppointmentErrorsType,
  busyTimes: {} as string[],
});
const FlexibleMenuItem = styled(MenuItem)<{theme?: Theme}>(({theme}) => ({
  [theme.breakpoints.down("sm")]: {
    display: "flex !important",
  },
}));

const FormLabel = styled("label")({});
const Form = styled("form")({});
const ClientSelect = styled(DeededSelectV2Transparent)({});

export default BigCalendarCreateSigningAppointment;
