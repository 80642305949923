import React, { FC, MouseEvent, useState } from "react";
import { Box, IconButton, Menu, MenuItem, Stack, styled } from "@mui/material";
import { useSnackbar } from "notistack";
import { UserParticipant } from "types/user";
import { ClientOnboarding } from "types/intakeForm";
import { Deal } from "types/deal";
import { makeClientPrimary } from "api";
import { Body, Small, SmallBold, Text } from "components/Common/Typography";
import {
    ChevronDown,
    ChevronUp,
    ConsentingSpouseIcon,
    EditIcon,
    EmailIcon,
    LinkIcon,
    PhoneIcon,
    TrashIcon,
    VerticalEllipsis,
} from "components/Common/Icons/Iconography";
import Colors from "components/Common/componentStyling/Colors";
import BasicButton from "components/Common/Button/BasicButton";
import { SMALL_TEXT } from "components/Common/componentStyling/Styles";
import { isClient } from "utils/permissionRoles";
import configuration from "utils/configuration";
import { useMixpanel } from "utils/MixpanelContext";
import useUser from "utils/hooks/useUser";
import { ClientTableCell } from "./DealDetailsClientTable";
import DeleteClientModal from "./Modals/DeleteClient";

interface ClientTableRowProps {
    index: number;
    participant: UserParticipant;
    onboarding?: ClientOnboarding;
    deal: Deal;
    isV1: boolean;
    isCompleted: boolean;
    hasOldToken: boolean;
    deleteDisabled: boolean;
    anchorEl: HTMLElement | null;
    menuOpen: number | null;
    sendingInvite?: number | null;

    onClick: (e: MouseEvent<HTMLButtonElement>, index: number) => void;
    onRequestMenuClose: () => void;
    onSendInvite: (p: UserParticipant) => void;
    onResendInvite: (p: UserParticipant) => void;
    onEditClient: (id: number) => void;
    onRequestRefresh: () => Promise<void>;
}

const ClientTableRow: FC<ClientTableRowProps> = ({
    index,
    participant,
    deal,
    isV1,
    isCompleted,
    hasOldToken,
    onboarding,
    deleteDisabled,
    anchorEl,
    menuOpen,
    sendingInvite,
    onEditClient,
    onResendInvite,
    onSendInvite,
    onRequestMenuClose,
    onRequestRefresh,
    onClick,
}) => {
    const mixpanel = useMixpanel();
    const { enqueueSnackbar } = useSnackbar();
    const { user } = useUser();

    const [removeClient, setRemoveClient] = useState<UserParticipant | null>(null);
    const isInviteSent = Boolean(onboarding?.invite_sent_at);
    const isPrimary = Boolean(participant.role === "Client");
    const isFakeEmail = participant.email?.includes("-no-email@deeded.ca");
    const disableMakePrimary = isPrimary || isCompleted;
    const userType = participant.role === "Secondary Client" ? "Additional Client" : participant.role;

    const onClickCopyText = (text: string | null) => {
        navigator.clipboard?.writeText(text || "");
        enqueueSnackbar("Copied", {
            variant: "success",
            autoHideDuration: configuration.autoHideErrorDuration / 4,
        });
    };

    const handleMakePrimary = () => {
        makeClientPrimary(deal.id, { contact_id: participant.id })
            .then(() => onRequestRefresh())
            .catch((error) => {
                enqueueSnackbar(error?.response?.data?.error || "Failed to make primary", {
                    variant: "error",
                    autoHideDuration: configuration.autoHideErrorDuration,
                });
            })
            .finally(() => {
                mixpanel.track("make_primary_button_clicked", {
                    deal_type: deal.deal_type,
                    user_role: user.role,
                });
            });

        onRequestMenuClose();
    };

    if (participant?.id <= 0 || participant.deleted_at) {
        return null;
    }

    return (
        <ClientTableStyledRow data-testid="deal-details-client-row">
            <input
                id={`collapsible-${index}`}
                className="toggle"
                defaultChecked={index === 0}
                type="checkbox"
                style={{ display: "none" }}
            />
            <ClientTableCell>
                <ClientTableCopyLabel>
                    <Text
                        Component={SmallBold}
                        copiable={true}
                        gap=".8rem"
                        data-testid="deal-details-client-full-name-value"
                        onClick={() => onClickCopyText(participant.full_name)}
                    >
                        {participant.full_name}
                        <LinkIcon size="small" color={Colors["GRAY_900"]} />
                    </Text>
                </ClientTableCopyLabel>
                <ClientTableCopyLabel>
                    <EmailIcon size="small" color={Colors["GRAY_900"]} />
                    <Text
                        Component={isFakeEmail ? SmallBold : Small}
                        sx={{ color: isFakeEmail ? Colors.RED_500 : Colors.GRAY_900 }}
                        copiable={!isFakeEmail}
                        data-testid="deal-details-client-email-value"
                        onClick={() => onClickCopyText(isFakeEmail ? "no email" : participant.email)}
                    >
                        {isFakeEmail ? "no email" : participant.email}
                    </Text>
                    <LinkIcon size="small" color={Colors["GRAY_900"]} />
                </ClientTableCopyLabel>
                <ClientTableCopyLabel>
                    <PhoneIcon size="small" color={Colors["GRAY_900"]} />
                    <Text
                        Component={Small}
                        copiable={true}
                        data-testid="deal-details-client-phone-value"
                        onClick={() => onClickCopyText(participant.phone)}
                    >
                        {participant.phone}
                    </Text>
                    <LinkIcon size="small" color={Colors["GRAY_900"]} />
                </ClientTableCopyLabel>
            </ClientTableCell>
            <ClientTableCell
                className="collapsible"
                data-type="Client Type"
                data-testid="deal-details-client-role-value"
            >
                {userType}
            </ClientTableCell>
            <ClientTableCell
                className="collapsible"
                data-type="Onboarding Status"
                data-testid="deal-details-onboarding-status"
            >
                {hasOldToken || isV1 ? "-" : onboarding?.status_label || "Not Sent"}
            </ClientTableCell>
            {deal.idv_feature && (
                <ClientTableCell className="collapsible" data-type="IDV Status">
                    {hasOldToken || isV1 ? "-" : onboarding?.user_idv?.status_label || " - "}
                </ClientTableCell>
            )}
            <ClientTableCell className="collapsible">
                {isClient(participant.role) && (
                    <BasicButton
                        disabled={
                            isFakeEmail ||
                            sendingInvite === participant.id ||
                            isV1 ||
                            onboarding?.status_label === "Complete"
                        }
                        testId="deal-details-client-send-invite"
                        leftIcon={<LinkIcon color={Colors[!isInviteSent && isPrimary ? "WHITE" : "BLACK"]} />}
                        size="small"
                        label={{
                            text: isInviteSent ? "Re-send" : "Send",
                            inputId: `send-intake-button`,
                        }}
                        typeOf={!isInviteSent && isPrimary ? "CTA" : "secondary"}
                        onClick={() => {
                            isInviteSent ? onResendInvite(participant) : onSendInvite(participant);
                        }}
                    />
                )}
            </ClientTableCell>
            <ClientTableCell>
                <>
                    <IconButton className="expand-collapse-button" sx={{ textAlign: "center" }}>
                        <label style={{ margin: "0" }} htmlFor={`collapsible-${index}`}>
                            <ChevronUp className="chevron-up" color={Colors.GRAY_500} />
                            <ChevronDown className="chevron-down" color={Colors.GRAY_500} />
                        </label>
                    </IconButton>

                    <IconButton
                        aria-controls={menuOpen === index ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={menuOpen === index ? "true" : undefined}
                        onClick={(e) => onClick(e, index)}
                        sx={{ textAlign: "center" }}
                        data-testid="client-table-tree-dots-menu-button"
                    >
                        <VerticalEllipsis color={Colors.GRAY_500} />
                    </IconButton>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={menuOpen === index}
                        onClose={onRequestMenuClose}
                        MenuListProps={{
                            "aria-labelledby": "basic-button",
                        }}
                    >
                        <StyledMenuItem
                            onClick={() => {
                                onEditClient(participant.id);
                            }}
                        >
                            <Stack gap="0.8rem" flexDirection={"row"} alignItems={"center"}>
                                <EditIcon size={"small"} color={Colors.BLACK} />
                                <Body data-testid="edit-client-drop-down-option">Edit</Body>
                            </Stack>
                        </StyledMenuItem>
                        <StyledMenuItem
                            onClick={handleMakePrimary}
                            disabled={disableMakePrimary}
                            sx={{ display: disableMakePrimary ? "none" : "block" }}
                        >
                            <Stack gap="0.8rem" flexDirection={"row"} alignItems={"center"}>
                                <ConsentingSpouseIcon size={"small"} color={Colors.BLACK} />
                                <Body data-testid="change-to-primary-drop-down-option">Change to primary</Body>
                            </Stack>
                        </StyledMenuItem>
                        <StyledMenuItem disabled={deleteDisabled} onClick={() => setRemoveClient(participant)}>
                            <Stack gap="0.8rem" flexDirection={"row"} alignItems={"center"}>
                                <TrashIcon size={"small"} color={Colors.BLACK} />
                                <Body data-testid="remove-client-drop-down-option"> Remove Client</Body>
                            </Stack>
                        </StyledMenuItem>
                    </Menu>

                    <DeleteClientModal
                        removeClient={removeClient}
                        onClose={() => {
                            onRequestRefresh();
                            onRequestMenuClose();
                            setRemoveClient(null);
                        }}
                    />
                </>
            </ClientTableCell>
        </ClientTableStyledRow>
    );
};

export const ClientTableStyledRow = styled(Box)(({ theme }) => ({
    ...SMALL_TEXT,
    display: "grid",
    width: "100%",
    gridTemplateColumns: `minmax(min(250px, 100%), 2fr) repeat(3, minmax(min(120px, 100%), 1fr)) minmax(min(40px, 100%), 1fr)`,
    margin: "1.6rem 0",

    "& .expand-collapse-button": {
        display: "none",
    },

    [theme.breakpoints.down("md")]: {
        display: "flex",
        flexDirection: "column",
        margin: 0,
        position: "relative",
        padding: "1.6rem 1.6rem 0 1.6rem",
        borderBottom: `1px solid ${Colors["GRAY_300"]}`,
        "&:last-child": {
            border: "none",
        },
        "& .toggle:not(:checked) ~ .collapsible": {
            display: "none",
        },
        "& .toggle:not(:checked) ~ * .chevron-up": {
            display: "none",
        },
        "& .toggle:checked ~ .collapsible": {
            display: "block",
        },
        "& .toggle:checked ~ * .chevron-down": {
            display: "none",
        },
        "& .expand-collapse-button": {
            display: "inline-flex",
        },
    },
}));

export const ClientTableCopyLabel = styled(Stack)({
    flexDirection: "row",
    alignItems: "center",
    gap: ".8rem",
    paddingBottom: "0.8rem",
    "& svg:last-child": {
        display: "none",
    },
    "&:hover svg:last-child": {
        display: "block",
    },
});

const StyledMenuItem = styled(MenuItem)({
    fontSize: "1.6rem",
});

export default ClientTableRow;
