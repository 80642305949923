import {Box, MenuItem} from "@mui/material";
import DeededLabel from "v2/components/DeededLabel";
import DeededDatePicker from "v2/components/DeededDatePicker";
import DeededSpace from "v2/components/DeededSpace";
import DeededTimePicker from "v2/components/DeededTimePicker";
import DeededSelect from "v2/components/DeededSelect_DEPRECATED";
import React from "react";
import {FormikErrors} from "formik";

interface CloseCalendarLapseV2DateFormProps {
  handleChange: (someToChange: unknown) => void;
  province: string;
  dateStart: string;
  timeStart: string;
  dateEnd: string;
  timeEnd: string;
  isAllDay: boolean;
  errors: FormikErrors<unknown> & {dateEnd?: string; timeEnd?: string};
}

const CloseCalendarLapseV2DateForm: React.FC<
  CloseCalendarLapseV2DateFormProps
> = ({
  errors,
  handleChange,
  province,
  isAllDay,
  dateStart,
  timeStart,
  dateEnd,
  timeEnd,
}) => {
  return (
    <>
      {" "}
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Box>
          <DeededLabel title={"Start Date"} />
          <DeededDatePicker
            dateStart={dateStart as unknown as Date | null}
            onChangeEvent={handleChange}
            name={"dateStart"}
          />
        </Box>
        <DeededSpace x={24} />
        <Box>
          <DeededLabel title={"Start Time"} />
          <DeededTimePicker
            timeStart={timeStart as unknown as Date | null}
            onChangeEvent={handleChange}
            name={"timeStart"}
          />
        </Box>
        <DeededSpace x={24} />
        <DeededLabel title="Province">
          <DeededSelect
            value={province}
            onChange={(e) => {
              handleChange({
                target: {
                  name: "province",
                  value: e.target.value,
                },
              });
            }}
            sx={{
              width: 93,
              fontFamily: "Montserrat",
              fontSize: 14,
              fontWeight: 500,
              /* identical to box height */
              /* Main / Black */
              color: "#010101",
            }}
            displayEmpty
            inputProps={{"aria-label": "Without label"}}
          >
            <MenuItem value={"AB"}>AB</MenuItem>
            <MenuItem value={"BC"}>BC</MenuItem>
            <MenuItem value={"MB"}>MB</MenuItem>
            <MenuItem value={"NB"}>NB</MenuItem>
            <MenuItem value={"NL"}>NL</MenuItem>
            <MenuItem value={"NT"}>NT</MenuItem>
            <MenuItem value={"NS"}>NS</MenuItem>
            <MenuItem value={"NU"}>NU</MenuItem>
            <MenuItem value={"ON"}>ON</MenuItem>
            <MenuItem value={"PE"}>PE</MenuItem>
            <MenuItem value={"QC"}>QC</MenuItem>
            <MenuItem value={"SK"}>SK</MenuItem>
            <MenuItem value={"YT"}>YT</MenuItem>
          </DeededSelect>
        </DeededLabel>
      </Box>
      <>
        <DeededSpace y={16} />
        <Box sx={{display: "flex"}}>
          <Box>
            <DeededLabel title="End Date" />
            <DeededDatePicker
              hasError={errors.dateEnd}
              dateStart={dateEnd as unknown as Date | null}
              name="dateEnd"
              onChangeEvent={handleChange as (value: unknown) => void}
            />
          </Box>
          <DeededSpace x={24} />
          <Box>
            <DeededLabel title={"End Time"} />
            <DeededTimePicker
              error={errors.timeEnd}
              timeStart={timeEnd as unknown as Date | null}
              onChangeEvent={handleChange}
              name={"timeEnd"}
            />
          </Box>
        </Box>
      </>
    </>
  );
};
export default CloseCalendarLapseV2DateForm;
