import React from "react";

const SearchFileMoveStorage = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.9685 17.1875C13.9553 17.1875 17.1873 13.9556 17.1873 9.96875C17.1873 5.98194 13.9553 2.75 9.9685 2.75C5.9817 2.75 2.74976 5.98194 2.74976 9.96875C2.74976 13.9556 5.9817 17.1875 9.9685 17.1875Z"
        stroke="#F16764"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.0728 15.0735L19.2494 19.2501"
        stroke="#F16764"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SearchFileMoveStorage;
