import React from "react";

interface ConfigTabProps {
  label: string;
  value: number;
  stateVal: number;
  onClick: (value: number) => void;
  onDoubleClick?: (value: number) => void;
}

const ConfigTab: React.FC<ConfigTabProps> = ({
  label,
  value,
  stateVal,
  onClick,
  onDoubleClick,
  ...props
}) => {
  let className = "tab-list-item";

  if (value === stateVal) {
    className += " tab-list-active";
  }

  return (
    <li
      className={className}
      onClick={() => onClick(value)}
      onDoubleClick={() => {
        if (onDoubleClick) {
          onDoubleClick(value);
        }
      }}
    >
      {label}
    </li>
  );
};

export default ConfigTab;
