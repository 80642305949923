import React from "react";

type DotType = {
  color?: string | undefined;
};
const Dot: React.FC<DotType> = ({color}) => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill={color}>
      <circle cx="6" cy="6" r="6" fill={color} />
    </svg>
  );
};

export default Dot;
