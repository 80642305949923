import React, {ReactNode, useEffect, useState} from "react";

import constants from "../../styles/constants";
import DeededSelectV2IconSvg from "../../components/Svg/DeededSelectV2IconSvg";
import {styled} from "@mui/material/styles";
import DeededSelectV2 from "./DeededSelectV2";
import {SelectChangeEvent, SxProps, Theme} from "@mui/material";

interface DeededSelectProps {
  onChange?: (e?: SelectChangeEvent<unknown>) => void;
  value: unknown;
  width?: number | string;
  height?: number | string;
  minWidth?: number;
  multiple?: boolean;
  name?: string;
  error?: string;
  renderValue?: (selected: never[]) => JSX.Element;
  native?: boolean;
  open?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  onMouseMove?: (e: {clientX: number; clientY: number}) => void;
  hideError?: boolean;
  placeholder?: string;
  disabled?: boolean;
  sx?: SxProps<Theme>;
  sxMenu?: SxProps<Theme>;
}
const DeededSelect: React.FC<DeededSelectProps> = ({
  children,
  onMouseMove,
  onOpen,
  onClose,
  open,
  native,
  renderValue,
  error,
  name,
  multiple,
  onChange,
  value,
  width,
  height,
  hideError,
  placeholder,
  disabled,
  sx,
  sxMenu,
  ...props
}) => {
  const [isOpened, setIsOpened] = useState(!!open);
  useEffect(() => {
    setIsOpened(!!open);
  }, [open]);
  useEffect(() => {
    const onWindowScroll = () => {
      setIsOpened(false);
    };
    document.addEventListener("scroll", onWindowScroll);
    return () => {
      document.removeEventListener("scroll", onWindowScroll);
    };
  }, []);
  const onOpenDropDown = () => {
    if (onOpen) {
      onOpen();
    }
    setIsOpened(true);
  };
  const onCloseDropDown = () => {
    setIsOpened(false);
    if (onClose) {
      onClose();
    }
  };
  const onChangeDropDownValue = (e?: SelectChangeEvent<unknown>) => {
    if (onChange) {
      onChange(e);
    }
    setIsOpened(false);
  };
  return (
    <>
      <DeededSelectV2Basic
        onMouseMove={onMouseMove}
        onOpen={onOpenDropDown}
        onClose={onCloseDropDown}
        open={isOpened}
        renderValue={renderValue as ((value: unknown) => ReactNode) | undefined}
        native={native}
        className="DeededSelectV2Transparent"
        data-selenium-test="DeededSelect"
        {...props}
        error={error}
        hideError={hideError}
        name={name}
        multiple={multiple}
        onChange={onChangeDropDownValue}
        width={width}
        height={height}
        menuItemStyles={menuItemStyles}
        value={value}
        placeholder={placeholder}
        onClick={(e) => {
          e.stopPropagation();
        }}
        fieldsetBorderColor={constants.colors.grayLight}
        IconComponent={DeededSelectV2IconSvg}
        disabled={disabled}
        sx={sx}
        sxMenu={sxMenu}
      >
        {children}
      </DeededSelectV2Basic>
    </>
  );
};

const menuItemStyles = {
  "& .MuiMenuItem-root": {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "17px",
    display: "flex",
    alignItems: "center",
    textTransform: "capitalize",
    transition: constants.transitions,
  },
  "& .Mui-selected": {
    backgroundColor: `${constants.colors.white} !important`,
    color: `${constants.colors.red}  !important`,
    transition: constants.transitions,
  },
};
const DeededSelectV2Basic = styled(DeededSelectV2)<{
  error?: string;
  width?: number | string;
  height?: number | string;
  minWidth?: number;
  disabled?: boolean;
}>(({width, minWidth, error, height, disabled}) => ({
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "17px",
  display: "flex",
  alignItems: "center",
  position: "relative",
  width: `${width ?? "200px"} !important`,
  minWidth: minWidth ?? "",
  height: `${height ?? "44px"} !important`,
  "&.MuiOutlinedInput-root": {
    background: "transparent",
    color: `${constants.colors.title} !important`,
    "&:not(.Mui-disabled):hover": {
      fieldset: {
        borderColor: `${constants.colors.gray} !important`,
      },
      "svg.deededSelectV2IconSvg": {
        fill: "none",
      },
    },
  },
  "& .MuiSelect-select": {
    height: "100% !important",
    padding: "0 12px",
    display: "flex",
    alignItems: "center",
    paddingRight: "45px !important",
    textOverflow: "unset",
    overflow: "hidden",
    whiteSpace: "nowrap",
    //I use this border to hide overflowing text because overflow:hidden doesn't work there
    borderRight: "40px solid transparent",
  },
  "& .deededSelectV2IconSvg": {
    position: "absolute",
    right: "16px",
    transition: constants.transitions,
    path: {
      stroke: disabled ? constants.colors.gray : constants.colors.title,
    },
  },
  "& > div[aria-expanded=true] ~ .deededSelectV2IconSvg": {
    transform: "scaleY(-1)",
    transition: constants.transitions,
  },
  "& > div[aria-expanded=true]": {
    "& ~ fieldset": {
      border: `1px solid ${constants.colors.red} !important`,
    },
  },
  "&.MuiInputBase-root": {
    "&.Mui-focused": {
      "& fieldset": {
        border: `1px solid ${constants.colors.red}`,
      },
    },
    ".Mui-disabled": {
      cursor: "no-drop !important",
    },
    "&.Mui-disabled": {
      "& fieldset": {
        border: `1px solid ${constants.colors.grayLight}`,
      },
    },
  },
  "& fieldset": {
    height: "112%",
    border: error ? `1px solid ${constants.colors.red} !important` : "",
    transition: constants.transitions,
  },
}));
export default DeededSelect;
