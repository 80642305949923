import React from 'react'
import Box from "@mui/material/Box";
import SwipeableDrawer, {
  SwipeableDrawerProps,
} from "@mui/material/SwipeableDrawer";
import DealCalendarInfo from "./DealCalendarInfo";
import DealInfoButtons from "./DealInfoButtons";
import {useTheme} from "@mui/material";

const DealCalendarDrawer: React.FC<
  SwipeableDrawerProps & {setShowDealPreview: (open: boolean) => void}
> = ({setShowDealPreview, ...props}) => {
  const theme = useTheme();
  return (
    <SwipeableDrawer
      sx={{
        "& .MuiPaper-root": {
          [theme.breakpoints.down("sm")]: {
            width: "100% !important",
          },
          "&::-webkit-scrollbar": {
            width: 0,
          },
        },
      }}
      {...props}
    >
      <Box
        sx={{
          width: 492,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          height: "100%",
          [theme.breakpoints.down("sm")]: {
            width: "100%",
          },
        }}
      >
        <Box
          sx={{
            flex: 1,
            overflowY: "auto",
            overflowX: "hidden",
            "&::-webkit-scrollbar": {
              width: 0,
            },
            [theme.breakpoints.down("sm")]: {
              width: "100%",
            },
          }}
        >
          <DealCalendarInfo setShowDealPreview={setShowDealPreview} />
        </Box>
        <DealInfoButtons setShowDealPreview={setShowDealPreview} />
      </Box>
    </SwipeableDrawer>
  );
};

export default DealCalendarDrawer;
