import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { getAllProfessionalUsers } from "api";
import configuration from "utils/configuration";
import { User } from "types/user";

interface ListUser extends User {
    label: string;
    value: string;
}

const useGetProfessionalsForList = () => {
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState<boolean>(false);
    const [list, setList] = useState<ListUser[]>();

    const refresh = async () => {
        if (loading) return;
        setLoading(true);
        try {
            const res = await getAllProfessionalUsers();
            const newList = [] as ListUser[];

            res.data.forEach((u) => {
                if (Boolean(u.deleted_at)) return;

                newList.push({ ...u, label: u.full_name, value: u.full_name });
            });
            setList(newList);
        } catch (e) {
            console.log(e);
            const error = e as { response: { data: { error: string } } };
            enqueueSnackbar(error.response.data.error ?? "Failed to load professionals.", {
                variant: "error",
                autoHideDuration: configuration.autoHideErrorDuration,
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        refresh();
    }, []);

    return [list, loading, refresh] as const;
};

export default useGetProfessionalsForList;
