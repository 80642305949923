import React from "react";
import {Box, styled, Typography} from "@mui/material";
import moment, {Moment} from "moment";
import constants from "styles/constants";
import {workingDaysWeek} from "../constants/workingDaysWeek";
import {calculateFiveDayWeekCalendar} from "../utils/calculateFiveDayWeekCalendar";
import {isDayToday} from "../utils/isDayToday";
import BigCalendarClosingsMonthDayCell from "./BigCalendarClosingsMonthDayCell";

interface BigCalendarClosingsMonthProps {
  width: string;
  date: Date;
}
const BigCalendarClosingsMonth: React.FC<BigCalendarClosingsMonthProps> = ({
  width,
  date,
}) => {
  const calendarShowingDaysArray: Moment[][] =
    calculateFiveDayWeekCalendar(date);
  return (
    <>
      <BigCalendarClosingsMonthBox width={width}>
        <WorkingWeekDaysBox>
          {workingDaysWeek.map((dayName, index) => (
            <DayNameBox key={index}>
              <DayName>{dayName}</DayName>
            </DayNameBox>
          ))}
        </WorkingWeekDaysBox>
        {calendarShowingDaysArray.map((week, indexWeek) => {
          return (
            <WeekBox key={indexWeek} indexWeek={indexWeek}>
              {week.map((day, indexDay) => {
                const isToday = isDayToday(day);
                const isCurrentDayFromCurrentMonth =
                  isDayFromCurrentMonthCheckFunc(day, date);
                return (
                  <BigCalendarClosingsMonthDayCell
                    key={indexDay}
                    calendarShowingDaysArray={calendarShowingDaysArray}
                    isToday={isToday}
                    indexDay={indexDay}
                    day={day}
                    isCurrentDayFromCurrentMonth={isCurrentDayFromCurrentMonth}
                  />
                );
              })}
            </WeekBox>
          );
        })}
      </BigCalendarClosingsMonthBox>
    </>
  );
};
const isDayFromCurrentMonthCheckFunc = (
  dayToCheck: Moment | Date,
  currentDate: Moment | Date,
) => {
  return moment(dayToCheck).format("M") === moment(currentDate).format("M");
};
const BigCalendarClosingsMonthBox = styled(Box)<{width: string}>(({width}) => ({
  width: width,
}));
const WeekBox = styled(Box)<{indexWeek: number}>(({indexWeek}) => ({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  marginTop: indexWeek === 0 ? "9px" : "16px",
}));
const DayName = styled(Typography)({
  fontFamily: "'Montserrat'",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "12px",
  lineHeight: "16px",
  color: constants.colors.sortingColor,
});
const DayNameBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  width: "17%",
});
const WorkingWeekDaysBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
});
export default BigCalendarClosingsMonth;
