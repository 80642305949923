import React from "react";

const MiniCloseSvg = () => {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.9375 3.5625L3.0625 11.4375"
        stroke="#BDBDBD"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.9375 11.4375L3.0625 3.5625"
        stroke="#BDBDBD"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default MiniCloseSvg;
