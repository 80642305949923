export const transformLabels = (original: string, skip: boolean = false) => {
  if (skip) {
    return original;
  }

  switch (original) {
    case "Law Clerk":
      return "Paralegal";
    case "Document Support Specialist":
      return "Customer Support Specialist"
    default:
      return original;
  }
};
