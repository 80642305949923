import React from "react";
import {useDispatch} from "react-redux";
import {Box} from "@mui/material";
import DeededButton from "v2/components/DeededButton";
import {deleteClosingDateTC} from "redux/reducers/calendarReducer";

interface CloseCalendarLapseBellowBtnsProps {
  setShowCloseLapse: (showCloseLapse: boolean) => boolean;
  handleSubmit: (someForChanging: unknown) => void;
  closing_id: number | null;
}

const CloseCalendarLapseBellowBtns: React.FC<
  CloseCalendarLapseBellowBtnsProps
> = ({closing_id, handleSubmit, setShowCloseLapse}) => {
  const dispatch = useDispatch();

  return (
    <>
      {" "}
      <Box
        sx={{
          marginBottom: "32px",
          display: "flex",
          justifyContent: "end",
        }}
      >
        <DeededButton
          onClick={() => {
            setShowCloseLapse(false);
            if (closing_id) {
              dispatch(deleteClosingDateTC(closing_id));
            }
          }}
          sx={{
            width: "100px",
            marginRight: "16px",
          }}
          kind="secondary"
        >
          {closing_id ? "Delete" : "Close"}
        </DeededButton>
        <DeededButton
          onClick={() => {
            handleSubmit("hello");
          }}
          sx={{
            marginRight: "13px",
            width: "124px",
          }}
        >
          Save
        </DeededButton>
      </Box>
    </>
  );
};
export default CloseCalendarLapseBellowBtns;
