import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import useUser from "utils/hooks/useUser";
import { updateUser } from "api";
import { useDispatch } from "react-redux";
import { userUpdateAC } from "redux/actions/auth";
import { TermsAndConditionsDashboard } from "../TermsOfConditions";
import { Wrapper } from "components/Common/Wrapper/Wrapper";
import { Stack } from "@mui/material";
import { ONBOARDING_PATH } from "customerPortal/pages/paths";
import HeaderMenu from "components/CommonDashboard/HeaderMenu";
import PortalContainer from "components/CommonDashboard/Container/PortalContainer";
import SideImage from "customerPortal/components/SideImage";
import TermsImg from "components/Svg/TermsImg";
import { H1 } from "components/CommonDashboard/Typography";
import AlertPanel from "components/CommonDashboard/AlertPanel";
import CheckboxField from "components/CommonDashboard/CheckboxField";
import Button from "components/CommonDashboard/Button";
import { useTheme, styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const TermsAndConditionsApp = ({
}) => {
    const { user, isStaff } = useUser();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));

    return (
        <Wrapper
            headerProps={{
                rightComponent: (
                    <HeaderMenu name={`${user.first_name} ${user.last_name}`} role={isStaff ? user.role : undefined} />
                ),
                mobile: isMobile,
            }}
        >
            <PortalContainer
                leftPanel={<TermsOfConditions currentUser={user} />}
                rightPanel={<SideImage MainImage={<TermsImg />} sx={{ alignItems: "flex-start", marginTop: "calc((100% - 4rem) / 2)" }} />}
                hideRightPanelOnMobile
            />
        </Wrapper>
    );
};

const TermsOfConditions = ({ currentUser }) => {
    let history = useHistory();
    const [accept, setAccept] = useState(false);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const handleNext = (e) => {
        setLoading(true);
        updateUser({
            tos_signed_at: (new Date()).toISOString()
        }, currentUser.id).then((res) => {
            setLoading(false);
            dispatch(userUpdateAC(res.data));
        }).catch((e) => {
            setLoading(false);
        });

    };

    useEffect(() => {
        if (!(currentUser && Object.values(currentUser).length > 0)) {
            history.goBack();
        }

        const query = new URLSearchParams(window.location.search);
        const clientId = query.get("client_id");
        if (currentUser.tos_signed_at && clientId) {
            history.replace(`${ONBOARDING_PATH}/${clientId}/retainer`)
            return;
        }
        if (currentUser.tos_signed_at) {
            history.replace("/dashboard");
        }
    }, []);

    return <>
        <H1>Terms and conditions</H1>
        <AlertPanel title="Scroll to the bottom to accept." />
        <TermsAndConditionsDashboard />
        <AcceptStack flexDirection='row' justifyContent='space-between'>
            <CheckboxField
                testId="agree-to-terms-and-conditions-checkbox"
                label="By checking this box I agree to the Terms and Conditions of Use for Deeded"
                checked={accept}
                onChange={() => setAccept(!accept)}
            />
            <Button
                testId="accept-button"
                label={{ text: 'Accept' }}
                disabled={!accept || loading}
                loading={loading}
                typeOf="CTA"
                onClick={handleNext}
            />
        </AcceptStack>
    </>;
}

const AcceptStack = styled(Stack)(({ theme }) => ({
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.down('mobile')]: {
        flexDirection: 'column',
        gap: '2.4rem'
    }
}));

export default TermsAndConditionsApp;
