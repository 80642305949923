import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import {checkUserEmail, updateDealContactDraft} from "api";
import { ContactDraft } from "types/user";
import { Deal } from "types/deal";
import configuration from "utils/configuration";
import { isClient } from "utils/permissionRoles";
import ModalBase from "components/Common/Modal/ModalBase";
import BaseModal from "components/CommonDashboard/Modal";
import { AlertIcon } from "components/CommonDashboard/Icons";
import AdditionalClientForm from "../../NewClient/AdditionalClientForm";
import { validateSecondaryClient } from "../../NewClient/NewClient";

interface UpdateContactDraftModalProps {
    draftToUpdate: ContactDraft | null;
    deal: Deal;
    onClose: (refresh?: boolean) => void;
}

const UpdateContactDraftModal = ({ onClose, draftToUpdate, deal }: UpdateContactDraftModalProps) => {
    const { enqueueSnackbar } = useSnackbar();
    const [openModal, setOpenModal] = useState(false);
    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [tmpData, setTmpData] = useState<Partial<ContactDraft & { draft?: boolean; email?: string }>>();
    const [inputErrors, setInputErrors] = useState({});

    const contactsForValidation = (deal?.participants || []).filter((p) => isClient(p.role));

    const handleFinishSubmit = () => {
        if (!draftToUpdate || isLoading) return;

        setOpenConfirmModal(false);
        setIsLoading(true);
        
        const payload = {...tmpData};
        
        if (payload.draft) {
            delete payload.email;
        }
        
        updateDealContactDraft(draftToUpdate.deal_id, draftToUpdate.id, payload)
            .then((res) => {
                setOpenModal(false);
                onClose(true);
                enqueueSnackbar("Client Information saved", { variant: "success" });
            })
            .catch((error) => {
                enqueueSnackbar(error?.response?.data?.error || "Failed to create user", {
                    variant: "error",
                    autoHideDuration: configuration.autoHideErrorDuration,
                });
            })
            .finally(() => setIsLoading(false));
    };

    const handleCheckEmail = async () => {
        if (!draftToUpdate || isLoading) return;
        let errorMap = validateSecondaryClient([tmpData, ...contactsForValidation]) as never;
        setInputErrors(errorMap?.["secondary_clients"]?.[0] ?? {});

        if (
            Object.keys(errorMap).length !== 0 &&
            Object.entries(errorMap).filter((el) => el[1] !== false).length !== 0
        ) {
            return;
        }

        if (!tmpData?.email || tmpData?.draft) {
            return handleFinishSubmit();
        }

        try {
            const resp  = await checkUserEmail(tmpData.email);
            if (resp.data.user_found) {
                return setOpenConfirmModal(true);
            }
        } catch (e) {
            console.log(e);
        }

        handleFinishSubmit();
    };

    useEffect(() => {
        if (draftToUpdate) {
            setInputErrors({});
            setTmpData({ email: '', draft: true, ...draftToUpdate });
            return setOpenModal(true);
        }
    }, [draftToUpdate]);
console.log(tmpData);
    return (
        <>
            <ModalBase
                open={openModal}
                onClose={() => {
                    setOpenModal(false);
                    onClose();
                }}
                onSubmit={handleCheckEmail}
                size="small"
                title="Edit client information"
                saveButtonLabel="Save"
                saveDisabled={false}
            >
                <AdditionalClientForm
                    client={tmpData || {}}
                    canRemove={false}
                    inputErrors={inputErrors}
                    onRemove={() => {}}
                    onUpdate={(data) => {
                        setTmpData(data as Partial<ContactDraft>);
                        setInputErrors({});
                    }}
                />
            </ModalBase>

            <BaseModal
                open={openConfirmModal}
                title="Duplicated email"
                icon={<AlertIcon />}
                textContent="This email already exists in the system, by proceeding you will override the user’s record with the information listed in this deal."
                onCloseClick={() => setOpenConfirmModal(false)}
                buttonProps={[
                    {
                        typeOf: "outline",
                        label: { text: "Cancel" },
                        onClick: () => setOpenConfirmModal(false),
                    },
                    {
                        typeOf: "primary",
                        label: { text: "Proceed" },
                        onClick: handleFinishSubmit,
                    },
                ]}
            />
        </>
    );
};

export default UpdateContactDraftModal;
