import { useState, useEffect } from "react";
import { deleteDealContact } from "api";
import GenericDialog from "components/Common/Modal/GenericDialog";
import { useSnackbar } from "notistack";
import { UserParticipant } from "types/user";
import configuration from "utils/configuration";

interface DeleteClientModal {
    removeClient: UserParticipant | null;
    onClose: () => void
}

const DeleteClientModal = ({ onClose, removeClient }: DeleteClientModal) => {
    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const [openWarningModal, setOpenWarningModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const { enqueueSnackbar } = useSnackbar();
    const handleConfirmClientDelete = (participant: UserParticipant | null) => {
        if (!participant || isLoading) return;
        setIsLoading(true);
        deleteDealContact(participant.deal_id, participant.id)
            .then(() => {
                setOpenConfirmModal(false);
                onClose()
                enqueueSnackbar("Client removed", {
                    variant: "success",
                    autoHideDuration: configuration.autoHideErrorDuration,
                })
            })
            .catch((error) =>
                enqueueSnackbar(error?.response?.data?.error || "Failed to delete the client", {
                    variant: "error",
                    autoHideDuration: configuration.autoHideErrorDuration,
                })
            ).finally(() => setIsLoading(false))
    }

    useEffect(() => {
        if (removeClient && removeClient.role === "Client") {
            return setOpenWarningModal(true);
        }
        if (removeClient) {
            return setOpenConfirmModal(true);
        }
    }, [removeClient])

    return <>
        <GenericDialog
            action="positive"
            contentText="Are you sure you want to remove this client?"
            onCancel={() => {
                setOpenConfirmModal(false);
                onClose()
            }}
            onSubmit={() => handleConfirmClientDelete(removeClient)}
            title="Remove client"
            titleComponent="H4"
            open={openConfirmModal}
            submitText={!isLoading ? "Yes, remove client" : "Loading..."}
            cancelBtnProps={{ typeOf: "secondary" }}
            dialogProps={{ maxWidth: 'xs' }}

        />
        <GenericDialog
            contentText={`The client you are attempting to remove is currently set as the primary client. To delete this client, you'll need to designate another client as the primary one for this deal.`}
            onCancel={() => {
                setOpenWarningModal(false);
                onClose()
            }}
            onSubmit={() => { }}
            title="Attention!"
            titleComponent="H4"
            open={openWarningModal}
            cancelBtnProps={{ typeOf: "secondary" }}
            dialogProps={{ maxWidth: 'xs' }}
        />
    </>
}


export default DeleteClientModal;