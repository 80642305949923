import {SVGProps} from "react";

const IsNoFilterSvg = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={14}
      height={13}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.285 1.095A.988.988 0 0 0 12.37.5H1.63a1 1 0 0 0-.74 1.673L5 6.693v4.873a1 1 0 0 0 1.555.832l2-1.333a.997.997 0 0 0 .446-.832v-3.54l4.11-4.52a.988.988 0 0 0 .174-1.078Z"
        fill=""
      />
    </svg>
  );
};

export default IsNoFilterSvg;
