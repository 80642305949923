import React, { FC, useState } from "react";
import { IconButton, Menu, MenuItem, Stack, styled } from "@mui/material";
import { useSnackbar } from "notistack";
import { ContactDraft } from "types/user";
import { Deal } from "types/deal";
import { Body, Small, SmallBold, Text } from "components/Common/Typography";
import {
    ChevronDown,
    ChevronUp,
    EditIcon,
    EmailIcon,
    LinkIcon,
    PhoneIcon,
    TrashIcon,
    VerticalEllipsis,
} from "components/Common/Icons/Iconography";
import BasicButton from "components/Common/Button/BasicButton";
import configuration from "utils/configuration";
import { ClientTableCell } from "./DealDetailsClientTable";
import { ClientTableCopyLabel, ClientTableStyledRow } from "./ClientTableRow";
import DeleteContactDraftModal from "./Modals/DeleteContactDraftModal";
import UpdateContactDraftModal from "./Modals/UpdateContactDraftModal";
import Colors from "../../CommonDashboard/Styling/Colors";

interface ClientTableDraftRowProps {
    draft: ContactDraft;
    deal: Deal;
    onRequestRefresh: () => void;
}

const ClientTableDraftRow: FC<ClientTableDraftRowProps> = ({ draft, deal, onRequestRefresh }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [removeDraft, setRemoveDraft] = useState<ContactDraft | null>(null);
    const [updateDraft, setUpdateDraft] = useState<ContactDraft | null>(null);
    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const onClickCopyText = (text: string | null) => {
        navigator.clipboard?.writeText(text || "");
        enqueueSnackbar("Copied", {
            variant: "success",
            autoHideDuration: configuration.autoHideErrorDuration / 4,
        });
    };

    return (
        <ClientTableStyledRow data-testid="deal-details-client-draft-row">
            <input id={`collapsible-${draft.id}`} className="toggle" type="checkbox" style={{ display: "none" }} />
            <ClientTableCell>
                <ClientTableCopyLabel>
                    <Text
                        Component={SmallBold}
                        copiable={true}
                        gap=".8rem"
                        data-testid="deal-details-draft-full-name-value"
                        onClick={() => onClickCopyText(draft.full_name)}
                    >
                        {draft.full_name}
                        <LinkIcon size="small" color={Colors.BLACK} />
                    </Text>
                </ClientTableCopyLabel>
                <ClientTableCopyLabel>
                    <EmailIcon size="small" color={Colors.DANGER_600} />
                    <Text
                        Component={SmallBold}
                        sx={{ color: Colors.DANGER_600 }}
                        data-testid="deal-details-draft-email-value"
                    >
                        no email
                    </Text>
                </ClientTableCopyLabel>
                <ClientTableCopyLabel>
                    <PhoneIcon size="small" color={Colors.BLACK} />
                    <Text
                        Component={Small}
                        copiable={true}
                        data-testid="deal-details-draft-phone-value"
                        onClick={() => onClickCopyText(draft.phone)}
                    >
                        {draft.phone}
                    </Text>
                    <LinkIcon size="small" color={Colors.BLACK} />
                </ClientTableCopyLabel>
            </ClientTableCell>
            <ClientTableCell
                className="collapsible"
                data-type="Client Type"
                data-testid="deal-details-draft-role-value"
            >
                Additional Client
            </ClientTableCell>
            <ClientTableCell
                className="collapsible"
                data-type="Onboarding Status"
                data-testid="deal-details-onboarding-status"
            >
                Not Sent
            </ClientTableCell>
            {deal.idv_feature && (
                <ClientTableCell className="collapsible" data-type="IDV Status">
                    -
                </ClientTableCell>
            )}
            <ClientTableCell className="collapsible">
                <BasicButton
                    disabled
                    testId="deal-details-draft-send-invite"
                    leftIcon={<LinkIcon color={Colors.BLACK} />}
                    size="small"
                    label={{ text: "Send", inputId: "send-intake-button" }}
                    typeOf="secondary"
                />
            </ClientTableCell>
            <ClientTableCell>
                <>
                    <IconButton className="expand-collapse-button" sx={{ textAlign: "center" }}>
                        <label style={{ margin: "0" }} htmlFor={`collapsible-${draft.id}`}>
                            <ChevronUp className="chevron-up" color={Colors.NEUTRAL_500} />
                            <ChevronDown className="chevron-down" color={Colors.NEUTRAL_500} />
                        </label>
                    </IconButton>

                    <IconButton
                        aria-haspopup="true"
                        onClick={(e) => {
                            setAnchorEl(e.currentTarget);
                            setMenuOpen(true);
                        }}
                        sx={{ textAlign: "center" }}
                        data-testid="client-table-tree-dots-menu-button"
                    >
                        <VerticalEllipsis color={Colors.NEUTRAL_500} />
                    </IconButton>
                    <Menu
                        id="draft-menu"
                        anchorEl={anchorEl}
                        open={menuOpen}
                        onClose={() => setMenuOpen(false)}
                        MenuListProps={{
                            "aria-labelledby": "basic-button",
                        }}
                    >
                        <StyledMenuItem onClick={() => setUpdateDraft(draft)}>
                            <Stack gap="0.8rem" flexDirection="row" alignItems="center">
                                <EditIcon size="small" color={Colors.BLACK} />
                                <Body data-testid="edit-draft-drop-down-option">Edit</Body>
                            </Stack>
                        </StyledMenuItem>
                        <StyledMenuItem onClick={() => setRemoveDraft(draft)}>
                            <Stack gap="0.8rem" flexDirection="row" alignItems="center">
                                <TrashIcon size="small" color={Colors.BLACK} />
                                <Body data-testid="remove-draft-drop-down-option"> Remove Client</Body>
                            </Stack>
                        </StyledMenuItem>
                    </Menu>

                    <DeleteContactDraftModal
                        draftToRemove={removeDraft}
                        onClose={() => {
                            onRequestRefresh();
                            setMenuOpen(false);
                            setRemoveDraft(null);
                        }}
                    />

                    <UpdateContactDraftModal
                        draftToUpdate={updateDraft}
                        deal={deal}
                        onClose={(refresh) => {
                            if (refresh) onRequestRefresh();
                            setMenuOpen(false);
                            setUpdateDraft(null);
                        }}
                    />
                </>
            </ClientTableCell>
        </ClientTableStyledRow>
    );
};

const StyledMenuItem = styled(MenuItem)({
    fontSize: "1.6rem",
});

export default ClientTableDraftRow;
