import { useEffect, useState } from "react";
import DeededPopup from "v2/components/DeededPopup";
import Select from "react-select";
import { memberStyles } from "./dealDetailsStyles";


const assignStaffList = [
    { label: "Lawyer", value: "Lawyer" },
    { label: "Law Clerk", value: "Law Clerk" },
    { label: "Closing Advisor", value: "Closing Advisor" },
    { label: "Access", value: "Access" },
    { label: "File Opener", value: "File Opener" },
    { label: "Document Support Specialist", value: "Document Support Specialist" },
];

interface AssignStaffPopupProps {
    assignStaff: boolean,
    setOpenModal: (open: boolean) => void,
    createNewStaffCard: (newRole: { label: string, value: string }) => void
}

const AssignStaffPopup = ({ assignStaff, setOpenModal, createNewStaffCard }: AssignStaffPopupProps) => {
    const topScroll = window.pageYOffset;

    const [staffRole, setStaffRole] = useState<null | { label: string, value: string }>();

    useEffect(() => {
        document.body.style.height = "auto";
        window.onscroll = () => {
            window.scrollTo({
                top: topScroll,
                behavior: "auto",
            });
        };
        return () => {
            window.onscroll = () => { };
        };
    }, []);
    return (
        <>
            <DeededPopup
                sx={{
                    "& .MuiPaper-root": {
                        overflowY: "visible",
                    },
                }}
                open={assignStaff}
                setOpen={setOpenModal}
                title="Assign staff"
                sxInternalBox={{
                    width: "365px",
                    overflowY: "visible",
                    overflowX: "visible",
                    "&::-webkit-scrollbar": {
                        width: "5px",
                        scrollbarWidth: "thin",
                    },
                }}
            >
                <div className="assign-wrapper">
                    <form
                        className="assign__staff"
                        onSubmit={(e) => {
                            e.preventDefault();
                            staffRole && createNewStaffCard(staffRole);
                        }}
                    >
                        <div className="assign__staff--content">
                            <div className="left">
                                <label htmlFor="member">Choose a staff role</label>
                                <Select
                                    id="assign-staff-chose-staff-role-drop-down"
                                    styles={memberStyles}
                                    options={assignStaffList}
                                    value={staffRole}
                                    onChange={(e) => setStaffRole({ label: e.value, value: e.value })}
                                />
                                <button data-testid="assign-staff-submit-button" className="submit__btn">submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </DeededPopup>
        </>
    );
};

export default AssignStaffPopup;