import {ActionType} from "../actionTypes";
import {Dispatch} from "redux";

import {
  getProfile,
  sendEmailForReset,
  updatePassword,
  updateProfile,
  workDocIntegration,
} from "../../api";
import {ProfileType, UpdateProfileData} from "../../types/profile";
import {AxiosResponse} from "axios";
import {RootStore} from "../../types/flux";
import {getCurrentUserTC, setUpdateWorkdocUserStatus} from "./authReducer";
import {addErrorAC, addSuccessMessageAC, clearSuccessAC} from "./ErrorReducer";

interface fileType {
  data: string | null;
  name: string;
  size: number;
  type: string;
}
export interface ProfileState {
  allowZoomOut: boolean;
  position: {x: number; y: number};
  rotate: number;
  height: number;
  width: number;
  scale: number;
  iconUrl: string;
  file: fileType | {};
  image: string | null;
  saveEdit: boolean;
  profile: ProfileType | null;
}

const profileState = {
  allowZoomOut: false,
  position: {x: 0.1, y: 0.1},
  rotate: 0,
  width: 140,
  height: 140,
  scale: 1,
  saveEdit: false,
  iconUrl: "",
  file: {},
  image: null,
  profile: null,
};

type ProfileReducerActionType = {
  type:
    | "PROFILE_SET_ICON"
    | "SET_ALLOW_ZOOM_OUT"
    | "SET_WIDTH"
    | "SET_HEIGHT"
    | "SET_ROTATE"
    | "SET_POSITION"
    | "SET_SCALE"
    | "SET_SAVE_EDIT"
    | "PROFILE_SET_FILE"
    | "PROFILE_SET_IMAGE"
    | "SET_PROFILE"
    | keyof typeof ActionType;
  payload: unknown;
};

export const ProfileReducer = (
  state: ProfileState = profileState,
  action: ProfileReducerActionType,
) => {
  switch (action.type) {
    case "SET_PROFILE": {
      return {
        ...state,
        profile: action.payload,
      };
    }
    case "SET_ALLOW_ZOOM_OUT": {
      return {
        ...state,
        allowZoomOut: action.payload,
      };
    }
    case "SET_WIDTH": {
      return {
        ...state,
        width: action.payload,
      };
    }
    case "SET_HEIGHT": {
      return {
        ...state,
        height: action.payload,
      };
    }
    case "SET_ROTATE": {
      return {
        ...state,
        rotate: action.payload,
      };
    }
    case "SET_POSITION": {
      return {
        ...state,
        position: action.payload,
      };
    }
    case "SET_SCALE": {
      return {
        ...state,
        scale: action.payload,
      };
    }
    case "SET_SAVE_EDIT": {
      return {
        ...state,
        saveEdit: action.payload,
      };
    }
    case "PROFILE_SET_IMAGE": {
      return {
        ...state,
        image: action.payload,
      };
    }
    case "PROFILE_SET_FILE": {
      return {
        ...state,
        file: action.payload,
      };
    }
    case "PROFILE_SET_ICON": {
      return {
        ...state,
        iconUrl: action.payload,
      };
    }
    default:
      return state;
  }
};

export const setAllowZoomOutAC = (payload: boolean) => ({
  type: "SET_ALLOW_ZOOM_OUT",
  payload,
});
export const setWidthAC = (payload: number) => ({
  type: "SET_WIDTH",
  payload,
});
export const setHeightAC = (payload: number) => ({
  type: "SET_HEIGHT",
  payload,
});
export const setRotateAC = (payload: number) => ({
  type: "SET_ROTATE",
  payload,
});
export const setPositionAC = (payload: {x: number; y: number}) => ({
  type: "SET_POSITION",
  payload,
});
export const setScaleAC = (payload: number) => ({
  type: "SET_SCALE",
  payload,
});

export const setSaveEditAC = (payload: boolean) => ({
  type: "SET_SAVE_EDIT",
  payload,
});
export const setProfileSetImageAC = (payload: string | File | null) => ({
  type: "PROFILE_SET_IMAGE",
  payload,
});
export const setProfileSetFileAC = (payload: fileType | {}) => ({
  type: "PROFILE_SET_FILE",
  payload,
});
export const setProfileIconAC = (
  payload: unknown,
): ProfileReducerActionType => ({
  type: "PROFILE_SET_ICON",
  payload,
});
export const setProfileAC = (payload: ProfileType) => ({
  type: "SET_PROFILE",
  payload,
});
export const updatePasswordTC =
  (oldPassword: string, password: string, password_confirmation: string) =>
  async (dispatch: Dispatch) => {
    try {
      const updatePasswordResponse = await updatePassword(
        oldPassword,
        password,
        password_confirmation,
      );
      dispatch(addSuccessMessageAC("Successfully updated"));
      dispatch(clearSuccessAC());
    } catch (e) {
      const error = e as {response: AxiosResponse<{error?: string}>};

      dispatch(
        addErrorAC(error?.response?.data?.error ?? "Couldn't update password"),
      );
    }
  };

export const updateProfileTC =
  (updatedData: UpdateProfileData) =>
  async (dispatch: Dispatch, getState: () => RootStore) => {
    try {
      const updateProfileResult = await updateProfile(updatedData);
      dispatch(addSuccessMessageAC("Successfully updated"));
      dispatch(clearSuccessAC());
      //@ts-ignore
      dispatch(getCurrentUserTC());
      dispatch(setProfileIconAC(getState().ProfileReducer.image));
      //@ts-ignore
      dispatch(getProfileTC());
      dispatch(setProfileSetImageAC(getState().ProfileReducer.image));
    } catch (e) {
      const error = e as {response: AxiosResponse<{error?: string}>};

      dispatch(
        addErrorAC(error?.response?.data?.error ?? "Couldn't update profile"),
      );
    }
  };
export const getProfileTC = () => async (dispatch: Dispatch) => {
  const profile = await getProfile();
  dispatch(setProfileAC(profile.data));
};
export const workDocIntegrationTC =
  (payload: {
    user_id: number;
    password: string;
    password_confirmation: string;
  }) =>
  async (dispatch: Dispatch) => {
    try {
      const workDocIntegrationPromise = await workDocIntegration(payload);
      const profile = await getProfile();
      dispatch(setProfileAC(profile.data));
      dispatch(setUpdateWorkdocUserStatus(true));
      dispatch(addSuccessMessageAC("Successfully connected"));
    } catch (e) {
      const error = e as {response: AxiosResponse<{error?: string}>};
      dispatch(
        addErrorAC(
          error?.response?.data?.error ?? "Couldn't make WorkDoc integration",
        ),
      );
    }
  };
export const sendEmailForResetTC =
  (email: string, setIsOpenedPopup?: (isOpenedPopup: boolean) => void) =>
  async (dispatch: Dispatch) => {
    try {
      await sendEmailForReset(email);
      if (setIsOpenedPopup) {
        setIsOpenedPopup(false);
      }
      dispatch(addSuccessMessageAC("We have sent you email for new password"));
    } catch (e) {
      const error = e as {response: AxiosResponse<{error?: string}>};
      dispatch(
        addErrorAC(
          error?.response?.data?.error ??
            "Couldn't send reset password request",
        ),
      );
    }
  };
