import {
  CalendarEvent,
  getFullDealInfoTC,
  setCurrentAppointmentIdAC,
  setSelectedAppointmentAC,
} from "../../../../redux/reducers/calendarReducer";
import {BlockedEvent} from "../../../../types/CalendarComponent/blockedEventTypes";
import {handleBlockedEventSelected} from "./handleBlockedEventSelected";
import {CalendarAppointment} from "../../../../types/calendarAppointmentType";
import {Dispatch} from "react";

export const handleEventSelect = (
  e: CalendarEvent | BlockedEvent,
  handleChange: () => void,
  setShowDealPreview: (showDealPreview: boolean) => void,
  dispatch: Dispatch<unknown>,
  setShowModal: (showModal: boolean) => void,
  setSelectedBlockedEvent: (selectedBlockedEvent: BlockedEvent | null) => void,
  setCurrentPageForCreateEventPopup: (
    currentPageForCreateEventPopup: number,
  ) => void,
) => {
  if (e.type === "Blocked") {
    handleBlockedEventSelected(e as unknown as BlockedEvent, handleChange);
    setCurrentPageForCreateEventPopup(2);
    setSelectedBlockedEvent(e as unknown as BlockedEvent);
    setShowModal(true);
    // setShowCloseLapse(true);
  } else {
    if ("appointment" in e) {
      dispatch(
        setSelectedAppointmentAC(e.appointment as CalendarAppointment | null),
      );
    }
    dispatch(getFullDealInfoTC(e.id as number));
    dispatch(setCurrentAppointmentIdAC(e.id as number));
    setShowDealPreview(true);
  }
};
