import {SVGProps} from "react";

const ClipboardCopyIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={18}
      height={18}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.813 11.812h3.374v-9h-9v3.375"
        stroke=""
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.812 6.187h-9v9h9v-9Z"
        stroke=""
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ClipboardCopyIcon;
