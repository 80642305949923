import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import constants from "styles/constants";
import React, {useState} from "react";
import Select, {SelectProps} from "@mui/material/Select";
import makeStyles from "@mui/styles/makeStyles";
import {SxProps, Theme} from "@mui/material";
const colors = constants.colors;

const DeededSelect: React.FC<
  Omit<SelectProps, "error"> & {
    error?: string;
    sx?: SxProps<Theme>;
    boxSx?: SxProps<Theme>;
    IconComponent?: React.ElementType<unknown>;
  }
> = ({children, error, boxSx, IconComponent, ...props}) => {
  const classes = useStyles({hasError: !!error});
  const [isOpenMenu, setIsOpenMenu] = useState(false);

  return (
    <Box sx={{position: "relative", ...(boxSx ?? {})}}>
      <Select
        {...props}
        className={`${classes.main} ${classes.root} ${props.className ?? ""} ${
          isOpenMenu ? "active" : ""
        }`}
        variant="outlined"
        classes={{
          select: `${classes.select} ${props.classes?.select ?? ""} ${
            props.disabled ? classes.disabledText : ""
          }`,
          outlined: classes.outlined,
          icon: `${classes.icon} ${props.classes?.icon ?? ""} ${
            props.disabled ? classes.disabledIcon : ""
          }`,
        }}
        MenuProps={{
          classes: {paper: classes.menu},
          disableScrollLock: true,
        }}
        IconComponent={IconComponent ?? KeyboardArrowDownIcon}
        onOpen={() => {
          setIsOpenMenu(true);
        }}
        onClose={() => {
          setIsOpenMenu(false);
        }}
      >
        {children}
      </Select>
      {error && (
        <Typography
          sx={{
            color: colors.red,
          }}
        >
          {error}
        </Typography>
      )}
    </Box>
  );
};

const useStyles = makeStyles<{}, {hasError?: boolean}>({
  disabledText: {
    color: `orange !important`,
  },
  menu: {
    borderRadius: 5,
    boxShadow: "0px 11px 24px rgba(0, 0, 0, 0.13)",
    "& li": {
      display: "block",
      textTransform: "capitalize",
      position: "relative",
      fontFamily: "Montserrat",
      fontSize: "13px",
      fontWeight: 500,
      transition: constants.transitions,
      "&:hover": {
        backgroundColor: constants.colors.redVeryPale,
        color: constants.colors.red,
        transition: constants.transitions,
      },
    },
    "&::-webkit-scrollbar": {
      width: "6px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#dcdcdc",
    },
    "&::-webkit-scrollbar-thumb": {
      //@ts-ignore
      backgroundColor: colors.primaryDark,
      borderRadius: "4px",
    },
    "& .Mui-selected": {
      backgroundColor: `${constants.colors.red} !important`,
      color: `${constants.colors.white} !important`,
      cursor: "auto",
      transition: constants.transitions,
    },
  },
  root: {
    background: colors.white,
    borderRadius: 5,
  },
  select: {
    fontSize: 14,
    backgroundColor: colors.white,
    padding: "10px 14px",
  },
  disabledIcon: {
    fill: `${colors.gray[50]} !important`,
  },
  icon: {
    fill: colors.gray,
    right: 10,
  },
  outlined: {
    alignItems: "center",
    "&:hover": {
      border: "none",
      outline: "none",
    },
  },
  main: {
    height: "44px",
    borderStyle: "solid",
    borderRadius: 5,
    borderColor: ({hasError}) => (hasError ? colors.red : colors.grayLight),
    borderWidth: 1,
    "&.active": {
      borderColor: colors.red,
    },
    "&:hover": {
      //@ts-ignore
      borderColor: colors.grayLight,
      outline: "none",
    },

    "& fieldset": {
      outline: "none",
      border: "none",

      "&:focused": {
        border: "none",
        //@ts-ignore
        borderColor: colors.red,
        borderWidth: 0,
        boxShadow: "0px 0px 0px 2px rgba(223, 227, 255, 0.5)",
      },
    },
    "& div": {
      display: "flex",
      textTransform: "capitalize",
    },
  },
});

export default DeededSelect;
