import mixpanel from "mixpanel-browser";
import React from "react";
import {createContext, useContext} from "react";
import configuration from "./configuration";

mixpanel.init(configuration.mixpanel.id as string);

const MixpanelContext = createContext(mixpanel);

export const MixpanelProvider: React.FC = ({children}) => (
  <MixpanelContext.Provider value={mixpanel}>
    {children}
  </MixpanelContext.Provider>
);

export const useMixpanel = () => useContext(MixpanelContext);

export function withMixpanel<T>(component: React.ComponentType<T>) {
  return (props: T) => {
    const mixpanel = useMixpanel();
    return React.createElement(component, {...props, mixpanel});
  };
}
