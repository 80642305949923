import DeededPopup from "v2/components/DeededPopup";
import {useContext, useState} from "react";
import {ProfileSecurityPageMtfAuthenticationContext} from "./ProfileSecurityPageMtfAuthentication";
import {useEnablePopupScroll} from "../../../../../utils/hooks/useEnablePopupScroll";
import {Box, Button, styled, Typography, useTheme} from "@mui/material";
import constants from "../../../../../styles/constants";
import DeededButton from "v2/components/DeededButton";
import {Theme} from "@mui/material/styles";

const ProfileSecurityPageMtfAuthenticationEnableMtfPopup = () => {
  const [sixDigitsNumber, setSixDigitsNumber] = useState("");
  const theme = useTheme();
  const {
    confirmMtfPasswordData,
    setIsOpenedEnableMtfPopup,
    isOpenedEnableMtfPopup,
    sendMtfCodeCallback,
    setIsOpenedSetUpCompletePopup,
    sendMtfCodeData,
    isSendMtfCodeLoading,
  } = useContext(ProfileSecurityPageMtfAuthenticationContext);
  useEnablePopupScroll(isOpenedEnableMtfPopup);
  const handleChangeSixDigitsNumber = (e: {target: {value: string}}) => {
    const {value} = e.target;
    const isOnlyDigits = /^\d+$/.test(value);
    const isAllowedChangeValue =
      value.length === 0 || (isOnlyDigits && value.length <= 6);
    if (isAllowedChangeValue) {
      setSixDigitsNumber(e.target.value);
    }
  };
  const handleSubmit = (e: {preventDefault: () => void}) => {
    e.preventDefault();
    sendMtfCodeCallback({
      setIsOpenedEnableMtfPopup,
      setIsOpenedSetUpCompletePopup,
      code: sixDigitsNumber,
    });
  };
  return (
    <>
      <DeededPopup
        sx={{
          "& .MuiPaper-root": {
            maxWidth: "unset",
            "&::-webkit-scrollbar": {
              width: "5px !important",
              scrollbarWidth: "thin",
            },
          },
        }}
        open={isOpenedEnableMtfPopup}
        setOpen={setIsOpenedEnableMtfPopup}
        sxInternalBox={{
          width: "699px",
          [theme.breakpoints.down("md")]: {
            minWidth: "0px",
            width: "100%",
          },
        }}
        title={"Enable multi-factor authentication"}
      >
        <FormWithoutStyles
          onSubmit={handleSubmit}
          sx={{
            marginTop: "36px",
          }}
        >
          <StepBox>
            <IconBox sx={{}}>
              <PhoneSvg />
            </IconBox>
            <Box>
              <StepTypography>Step 1</StepTypography>
              <ContentTypography
                sx={{
                  marginTop: "2px",
                }}
              >
                Download the Google authenticator MFA app on your phone
              </ContentTypography>
            </Box>
          </StepBox>
          <SecondStepBox>
            <StepBox>
              <IconBox>
                <QrSvg />
              </IconBox>
              <Box>
                <StepTypography>Step 2</StepTypography>
                <ContentTypography
                  sx={{
                    marginTop: "2px",
                  }}
                >
                  Open your Google authenticator on your phone and scan the
                  following QR code
                </ContentTypography>
              </Box>
            </StepBox>
            <SecondStepStaffBox>
              <Box
                sx={{
                  width: "180px",
                  height: "180px",
                  marginLeft: "-13px",
                  marginTop: "11px",
                  img: {
                    width: "180px",
                    height: "180px",
                  },
                }}
              >
                <img src={`${confirmMtfPasswordData?.qr}`} alt={"QR"} />
              </Box>
              <ContentTypography
                sx={{
                  marginTop: "13px",
                }}
              >
                If you prefer to manually enter a code, you can enter the code
                provided below
              </ContentTypography>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "500",
                  color: constants.colors.black3,
                  marginTop: "6px",
                }}
              >
                {confirmMtfPasswordData?.code}
              </Typography>
            </SecondStepStaffBox>
          </SecondStepBox>
          <ThirdStepBox>
            <StepBox>
              <IconBox>
                <KeySvg />
              </IconBox>
              <Box>
                <StepTypography>Enable MFA authentication</StepTypography>
                <ContentTypography
                  sx={{
                    marginTop: "2px",
                  }}
                >
                  Enter the MFA code displayed in the app to confirm and enable
                  MFA for your account
                </ContentTypography>
              </Box>
            </StepBox>
            <ThirdStepStaffBox>
              <Box
                sx={{
                  display: "flex",
                  marginTop: "14px",
                  [theme.breakpoints.down("md")]: {
                    flexDirection: "column",
                  },
                }}
              >
                <Box
                  sx={{
                    marginRight: "41px",
                    [theme.breakpoints.down("md")]: {
                      marginRight: "0px",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: constants.colors.black3,
                    }}
                  >
                    6-digit MFA number<RequiredStarSpan>*</RequiredStarSpan>
                  </Typography>
                  <CodeInput 
                    value={sixDigitsNumber}
                    onChange={handleChangeSixDigitsNumber}
                    autoFocus
                  />
                </Box>
              </Box>
            </ThirdStepStaffBox>
          </ThirdStepBox>
          <SubmitButtonsBox>
            <CancelButton
              type={"button"}
              onClick={() => {
                setIsOpenedEnableMtfPopup(false);
              }}
            >
              Cancel
            </CancelButton>
            <EnableMfaButton type={"submit"}>
              {isSendMtfCodeLoading ? "Loading..." : "Enable MFA"}
            </EnableMfaButton>
          </SubmitButtonsBox>
        </FormWithoutStyles>
      </DeededPopup>
    </>
  );
};
const PhoneSvg = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="39"
        height="39"
        rx="4.5"
        fill="white"
        stroke="#E0E0E0"
      />
      <path
        d="M13.5834 32.8337C13.1167 32.8337 12.7084 32.6587 12.3584 32.3087C12.0084 31.9587 11.8334 31.5503 11.8334 31.0837V8.91699C11.8334 8.45033 12.0084 8.04199 12.3584 7.69199C12.7084 7.34199 13.1167 7.16699 13.5834 7.16699H22.9167V8.91699H13.5834V9.79199H22.9167V11.542H13.5834V28.4587H26.4167V24.667H28.1667V31.0837C28.1667 31.5503 27.9917 31.9587 27.6417 32.3087C27.2917 32.6587 26.8834 32.8337 26.4167 32.8337H13.5834ZM13.5834 30.2087V31.0837H26.4167V30.2087H13.5834ZM27.4084 21.9253L21.9834 16.5003L23.2084 15.2753L26.5334 18.5712V9.79199H28.2834V18.5712L31.6084 15.2753L32.8334 16.5003L27.4084 21.9253ZM13.5834 9.79199V8.91699V9.79199ZM13.5834 30.2087V31.0837V30.2087Z"
        fill="#F16764"
      />
    </svg>
  );
};
const QrSvg = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="39"
        height="39"
        rx="4.5"
        fill="white"
        stroke="#E0E0E0"
      />
      <path
        d="M8.33337 13.3788V8.33301H13.3792V10.083H10.0834V13.3788H8.33337ZM8.33337 31.6663V26.6205H10.0834V29.9163H13.3792V31.6663H8.33337ZM26.6209 31.6663V29.9163H29.9167V26.6205H31.6667V31.6663H26.6209ZM29.9167 13.3788V10.083H26.6209V8.33301H31.6667V13.3788H29.9167ZM26.65 26.6788H28.4875V28.5163H26.65V26.6788ZM26.65 23.0038H28.4875V24.8413H26.65V23.0038ZM24.8125 24.8413H26.65V26.6788H24.8125V24.8413ZM22.975 26.6788H24.8125V28.5163H22.975V26.6788ZM21.1375 24.8413H22.975V26.6788H21.1375V24.8413ZM24.8125 21.1663H26.65V23.0038H24.8125V21.1663ZM22.975 23.0038H24.8125V24.8413H22.975V23.0038ZM21.1375 21.1663H22.975V23.0038H21.1375V21.1663ZM28.4875 11.483V18.833H21.1375V11.483H28.4875ZM18.8334 21.1663V28.5163H11.4834V21.1663H18.8334ZM18.8334 11.483V18.833H11.4834V11.483H18.8334ZM17.375 27.058V22.6247H12.9417V27.058H17.375ZM17.375 17.3747V12.9413H12.9417V17.3747H17.375ZM27.0292 17.3747V12.9413H22.5959V17.3747H27.0292Z"
        fill="#F16764"
      />
    </svg>
  );
};
const KeySvg = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="39"
        height="39"
        rx="4.5"
        fill="white"
        stroke="#E0E0E0"
      />
      <path
        d="M14.1666 27C12.2222 27 10.5694 26.3194 9.20829 24.9583C7.84718 23.5972 7.16663 21.9444 7.16663 20C7.16663 18.0556 7.84718 16.4028 9.20829 15.0417C10.5694 13.6806 12.2222 13 14.1666 13C15.6833 13 16.9618 13.3646 18.002 14.0938C19.0423 14.8229 19.8152 15.7903 20.3208 16.9958H32.8333V23.0042H29.7416V27H24.3166V23.0042H20.3208C19.8152 24.2097 19.0423 25.1771 18.002 25.9062C16.9618 26.6354 15.6833 27 14.1666 27ZM14.1666 25.25C15.5472 25.25 16.6798 24.7931 17.5645 23.8792C18.4493 22.9653 18.9694 22.0903 19.125 21.2542H26.1833V25.25H27.9916V21.2542H31.0833V18.7458H19.125C18.9694 17.9097 18.4493 17.0347 17.5645 16.1208C16.6798 15.2069 15.5472 14.75 14.1666 14.75C12.7083 14.75 11.4687 15.2604 10.4479 16.2812C9.42704 17.3021 8.91663 18.5417 8.91663 20C8.91663 21.4583 9.42704 22.6979 10.4479 23.7188C11.4687 24.7396 12.7083 25.25 14.1666 25.25ZM14.1666 21.9833C14.7305 21.9833 15.202 21.7937 15.5812 21.4146C15.9604 21.0354 16.15 20.5639 16.15 20C16.15 19.4361 15.9604 18.9646 15.5812 18.5854C15.202 18.2063 14.7305 18.0167 14.1666 18.0167C13.6027 18.0167 13.1312 18.2063 12.752 18.5854C12.3729 18.9646 12.1833 19.4361 12.1833 20C12.1833 20.5639 12.3729 21.0354 12.752 21.4146C13.1312 21.7937 13.6027 21.9833 14.1666 21.9833Z"
        fill="#F16764"
      />
    </svg>
  );
};
const EnableMfaButton = styled(Button)<{theme?: Theme}>(({theme}) => ({
  height: "40px",
  width: "214px",
  backgroundColor: constants.colors.green,
  color: constants.colors.white,
  textTransform: "capitalize",
  fontWeight: "600",
  fontSize: "14px",
  borderRadius: "5px",
  "&:hover": {
    border: `1px solid ${constants.colors.red}`,
    backgroundColor: constants.colors.white,
    color: constants.colors.red,
    transition: constants.transitions,
  },
  transition: constants.transitions,
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));
const CancelButton = styled(Button)<{theme?: Theme}>(({theme}) => ({
  fontWeight: "600",
  fontSize: "14px",
  backgroundColor: constants.colors.redVeryPale,
  color: constants.colors.red,
  height: "40px",
  width: "100px",
  textTransform: "capitalize",
  borderRadius: "5px",
  marginRight: "16px",
  "&:hover": {
    backgroundColor: constants.colors.pinkPale,
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    marginTop: "16px",
  },
}));
const SubmitButtonsBox = styled(Box)<{theme?: Theme}>(({theme}) => ({
  marginTop: "36px",
  display: "flex",
  justifyContent: "flex-end",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column-reverse",
  },
}));
const FormWithoutStyles = styled("form")({});
const CodeInput = styled("input")<{theme?: Theme}>(({theme}) => ({
  paddingBottom: "2px",
  fontFamily: "Montserrat",
  marginTop: "6px",
  border: "none",
  borderBottom: `1px solid ${constants.colors.gray200}`,
  color: constants.colors.black3,
  fontSize: "16px",
  fontWeight: "500",
  letterSpacing: "0.05em",
  "&:focus-visible": {
    border: "none",
    outline: "none",
    borderBottom: `1px solid ${constants.colors.gray200}`,
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));
const RequiredStarSpan = styled("span")({
  color: constants.colors.red,
  fontSize: "12px",
});
const ThirdStepWarningTypography = styled(Typography)({
  fontSize: "12px",
  fontWeight: "600",
  color: constants.colors.red,
});
const ThirdStepWarningBox = styled(Box)<{theme?: Theme}>(({theme}) => ({
  width: "auto",
  backgroundColor: constants.colors.redVeryPale,
  display: "flex",
  padding: "8px 10px",
  [theme.breakpoints.down("md")]: {
    marginTop: "16px",
  },
}));
const ThirdStepStaffBox = styled(Box)({
  marginLeft: "60px",
});
const ThirdStepBox = styled(Box)({
  marginTop: "24px",
});
const SecondStepStaffBox = styled(Box)({
  marginLeft: "60px",
});
const SecondStepBox = styled(Box)({
  marginTop: "24px",
});
const StepBox = styled(Box)({
  display: "flex",
});
const IconBox = styled(Box)({
  marginRight: "20px",
});
const StepTypography = styled(Typography)({
  color: constants.colors.red,
  fontSize: "18px",
  lineHeight: "22px",
  marginTop: "-2px",
});
const ContentTypography = styled(Typography)({
  fontSize: "14px",
  color: constants.colors.black3,
});
export default ProfileSecurityPageMtfAuthenticationEnableMtfPopup;
