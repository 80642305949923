import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  CalendarEvent,
  getClosingDatesTC,
  getEventsTC,
  getStaffTC,
  setCalendarTimezoneAC,
  setIsGettingEvents,
  setIsOpenedEventCreatePopupAC,
} from "redux/reducers/calendarReducer";
import useTypedSelector from "utils/hooks/useTypedSelector";

import CalendarHeader from "components/Dashboard/Calendar/components/CalendarHeader";

import {Staff} from "types/staffTypes";
import {RootStore} from "types/flux";

import DealCalendarDrawer from "./components/DealCalendarDrawer";
import {useFormik} from "formik";
import CloseCalendarLapseV2 from "components/Dashboard/Calendar/components/CloseCalendarLapseV2/CloseCalendarLapseV2";
import {MobileSignerType} from "components/Dashboard/Calendar/types/mobileSigner";
import useUser from "utils/hooks/useUser";

import resetStorage from "utils/resetStorage";
import {Box, Button, styled, useTheme} from "@mui/material";
import {setInitialItemCalendar} from "./utils/setInitialItemCalendar";
import {allProvincesLabelsList} from "./constants/allProvincesLabelsList";
import {allDealTypes} from "./constants/allDealTypes";
import {allRoles} from "./constants/allRoles";
import {allProvincesList} from "./constants/allProvincesList";
import {calculateAndSetSigningsCallback} from "./utils/calculateAndSetSigningsCallback";
import {
  mobileSignersSetter,
  setMobileSignersParameterType,
} from "./utils/mobileSignersSetter";
import {closingEffect} from "./utils/closingEffect";
import {timeBlockFormikSettings} from "./constants/timeBlockFormikSettings";
import {getEventsEffect} from "./utils/getEventsEffect";
import {changeDateEffect} from "./utils/changeDateEffect";
import BigCalendar from "./components/BigCalendar";
import CalendarLoader from "./components/CalendarLoader";
import {MainCalendarBox} from "./components/MainCalendarBox";
import moment from "moment";
import CalendarEventsCountingTables from "./components/CalendarEventsCountingTables";
import {BigCalendarContext} from "./context/BigCalendarContext";
import {useAppSelector} from "redux/hooks";
import BigCalendarClosingsWeek from "./components/BigCalendarClosingsWeek";
import BigCalendarClosingsMonth from "./components/BigCalendarClosingsMonth";
import {storeCalendarSetting} from "./utils/storeCalendarSetting";
import {getCalendarSetting} from "./utils/getCalendarSetting";
import {getStartOrEndDateToRequest} from "./utils/getStartOrEndDateToRequest";
import useWindowWidth from "utils/hooks/useWindowWidth";
import {miniDesktopCalendar, phoneCalendar} from "./constants/breakpoints";
import CalendarClosingDates from "./components/CalendarClosingDates";
import constants from "styles/constants";
import {
  useGetClosingsDealsCountDailyMutation,
  useGetCountLawClerkAndLawyerClosingsMutation,
} from "redux/reducers/calendarApi";
import {rolesForGettingClosings} from "./constants/rolesForGettingClosings";
import {getDateStartOrEndForRequestClosingOrAppointmentsCounts} from "./utils/getDateStartOrEndForRequestClosingOrAppointmentsCounts";
import {ClosingsCountApiType} from "redux/reducers/types/calendarApiTypes";
import {BlockedEvent} from "types/CalendarComponent/blockedEventTypes";
import CalendarMobile from "./components/CalendarMobile/CalendarMobile";
import BigCalendarClosingsDayV2 from "./components/BigCalendarClosingsDayV2";
import {downloadReportsTC} from "redux/reducers/configReducer/emailTemplateReducer";
import {makeQueryString} from "utils/makeQueryString";
import {CalendarMobileContext} from "./context/CalendarMobileContext";

const allDayDayEventsClassNameForDayView =
  ".calendarContainer.day .rbc-time-header-content, .calendarContainer.day .rbc-time-header.rbc-overflowing";
const GoogleCalendar: React.FC = () => {
  resetStorage();
  const user = useUser();
  const isGettingEvents = useTypedSelector(
    (state) => state.CalendarReducer.isGettingEvents,
  );
  const theme = useTheme();
  const [tableType, setTableType] = useState("Details");
  const [showDealPreview, setShowDealPreview] = useState(false);
  const [mobileSigners, setMobileSigners] = useState<MobileSignerType[] | null>(
    null,
  );
  const [showCloseLapse, setShowCloseLapse] = useState(false);
  const calendarTimezone = useAppSelector(
    (state) => state.CalendarReducer.calendarTimezone,
  );
  useEffect(() => {
    if (calendarTimezone.length > 0) {
      storeCalendarSetting("calendarTimezone", calendarTimezone);
    }
  }, [calendarTimezone]);
  const calendarEventsFromStore = useTypedSelector(
    (state) => state.CalendarReducer.events,
  );
  const closingDates = useTypedSelector(
    (state) => state.CalendarReducer.closingDates as {data: CalendarEvent[]},
  );
  const isLoadingClosingDates = useAppSelector(
    (state) => state.CalendarReducer.isLoadingClosingDates,
  );
  const windowWidth = useWindowWidth();
  let blockedAndEvents = calendarEventsFromStore.concat(closingDates.data);
  const [signingsTotal, setSigningsTotal] = useState(0);
  const storagedView = getCalendarSetting("view");
  const [view, setView] = useState(
    storagedView ??
      setInitialItemCalendar(user, ["day", "week", "month"], "view"),
  );
  const storedDate = getCalendarSetting("date");
  const [date, setDate] = useState<Date>(
    storedDate ? moment(storedDate).toDate() : new Date(),
  );

  const [dateStart, setStartDate] = useState<Date>(new Date());
  const [dateEnd, setEndDate] = useState<Date>(new Date());
  const storegedRegions = getCalendarSetting("regions");
  const [regions, setRegions] = useState(
    storegedRegions ??
      setInitialItemCalendar(user, allProvincesLabelsList, "region"),
  );
  const storagedDealType = getCalendarSetting("dealType");
  const [dealTypeState, setDealTypeState] = useState(storagedDealType ?? "ALL");
  const [staffState, setStaffState] = useState("ALL");
  const selectedStaffName = useTypedSelector(
    (state) => state.CalendarReducer.selectedStaffName,
  );
  const selectedStaffUser = useTypedSelector(
    (state) => state.CalendarReducer.selectedStaffUser,
  );
  const successClosingCreate = useTypedSelector(
    (state) => state.CalendarReducer.successClosingCreate,
  );
  const errorMessageClosingDate = useTypedSelector(
    (state) => state.CalendarReducer.errorMessageClosingDate,
  );
  const staff = useSelector<RootStore>(
    (state) => state.CalendarReducer.staff,
  ) as Staff;
  const isLoadingStaff = useAppSelector(
    (state) => state.CalendarReducer.isLoadingStaff,
  );
  const isClosingEditMode = useSelector<RootStore>(
    (state) => state.CalendarReducer.isClosingEditMode,
  );
  const successfullyDeleted = useTypedSelector(
    (state) => state.CalendarReducer.successfullyDeleted,
  );
  const calendarType = useAppSelector(
    (state) => state.CalendarReducer.calendarType,
  );
  const isCalendarClosings = calendarType === "Closings";
  const isAllProvinciesChecked = useMemo(
    () => regions.length === allProvincesLabelsList.length,
    [regions.length],
  ) as boolean;
  const [getLawClerksAndLawyersCounts, {data: closingsList}] =
    useGetCountLawClerkAndLawyerClosingsMutation();
  const [getClosingsDealsCountDaily, {data: closingDailyList}] =
    useGetClosingsDealsCountDailyMutation();
  const dispatch = useDispatch();
  const [selectedBlockedEvent, setSelectedBlockedEvent] =
    useState<BlockedEvent | null>(null);
  const [currentPageForCreateEventPopup, setCurrentPageForCreateEventPopup] =
    useState(1);
  const setShowModal = useCallback(
    (showModal: boolean) => {
      dispatch(setIsOpenedEventCreatePopupAC(showModal));
    },
    [dispatch],
  );
  useEffect(() => {
    storeCalendarSetting("selectedStaffName", selectedStaffName);
  }, [selectedStaffName]);
  useEffect(() => {
    storeCalendarSetting("date", date);
  }, [date]);
  useEffect(() => {
    storeCalendarSetting("regions", regions);
  }, [regions]);
  useEffect(() => {
    storeCalendarSetting("dealType", dealTypeState);
  }, [dealTypeState]);
  useEffect(() => {
    storeCalendarSetting("view", view);
  }, [view]);
  useEffect(() => {
    if (view === "day" && calendarType === "Closings") {
      setView("day");
    }
  }, [calendarType, view]);
  useEffect(() => {
    if (calendarType === "Closings") {
      setTableType("Volume");
    }
  }, [calendarType]);
  const getIsShowedCalendarAllDayEventsHeader = () => {
    const todayDateMoment = moment(date).format("YYYY-MM-DD");
    const filteredClosingsForCurrentDate = closingDates?.data?.filter(
      (closingDate) =>
        moment(closingDate.start_date, "YYYY-MM-DD HH:mm:ss").format(
          "YYYY-MM-DD",
        ) === todayDateMoment && closingDate.is_full_day,
    );
    return filteredClosingsForCurrentDate?.length > 0;
  };
  const [getStaff, setGetStaff] = useState(false);
  useEffect(() => {
    if (!getStaff) {
      dispatch(getStaffTC(true));
      setGetStaff(true);
    }
  }, [dispatch, getStaff, staff]);
  useEffect(() => {
    if (calendarTimezone.length === 0) {
      const timezoneToSet = getCalendarSetting("calendarTimezone")
        ? getCalendarSetting("calendarTimezone")
        : user.user.timezone_calendar;
      dispatch(setCalendarTimezoneAC(timezoneToSet as string));
    }
  }, [calendarTimezone, dispatch, user.user.timezone_calendar]);

  useEffect(() => {
    dispatch(setIsGettingEvents(true));
  }, [dispatch]);
  useEffect(
    () =>
      mobileSignersSetter(
        staff,
        setMobileSigners as (signers: setMobileSignersParameterType) => void,
      ),
    [setMobileSigners, staff],
  );
  useEffect(
    () =>
      closingEffect(
        successClosingCreate,
        isClosingEditMode as boolean,
        dispatch,
        successfullyDeleted,
        setShowCloseLapse,
      ),
    [
      dispatch,
      errorMessageClosingDate,
      isClosingEditMode,
      successClosingCreate,
      successfullyDeleted,
    ],
  );
  const dateStartForRequestClosingOrAppointmentsCounts =
    getDateStartOrEndForRequestClosingOrAppointmentsCounts(view, date, "start");
  const dateEndForRequestClosingOrAppointmentsCounts =
    getDateStartOrEndForRequestClosingOrAppointmentsCounts(view, date, "end");
  const downloadClosingsReportCallback = useCallback(() => {
    const dateForReport = moment(date).format("YYYY-MM-DD");
    const regionsForReport =
      regions?.length === allProvincesLabelsList.length
        ? "ALL"
        : regions?.join(",");
    const userForReport = selectedStaffUser ? `${selectedStaffUser?.id}` : null;

    const queryString = makeQueryString({
      date_from: dateForReport,
      date_to: dateForReport,
      user_id: userForReport,
      region: regionsForReport,
    });
    const url = `/export-closings${queryString}`;
    const type =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const filename = `Closings(${moment(date).format("MMM/DD/YYYY")}).xlsx`;
    dispatch(downloadReportsTC(url, type, filename));
  }, [date, dispatch, regions, selectedStaffUser]);
  useEffect(() => {
    if (
      rolesForGettingClosings.includes(user.user.role as string) &&
      calendarType === "Closings" &&
      view !== "day"
    ) {
      getLawClerksAndLawyersCounts({
        dateFrom: dateStartForRequestClosingOrAppointmentsCounts,
        dateTo: dateEndForRequestClosingOrAppointmentsCounts,
        region:
          regions?.length === allProvincesLabelsList.length
            ? "ALL"
            : regions?.join(","),
        users: selectedStaffUser ? `${selectedStaffUser?.id}` : null,
        dealType: dealTypeState,
      });
    }
  }, [
    calendarType,
    dateEndForRequestClosingOrAppointmentsCounts,
    dateStartForRequestClosingOrAppointmentsCounts,
    dealTypeState,
    getLawClerksAndLawyersCounts,
    regions,
    selectedStaffUser,
    user.user.role,
    view,
  ]);
  useEffect(() => {
    if (
      rolesForGettingClosings.includes(user.user.role as string) &&
      calendarType === "Closings" &&
      view === "day"
    ) {
      getClosingsDealsCountDaily({
        dateFrom: dateStartForRequestClosingOrAppointmentsCounts,
        dateTo: dateEndForRequestClosingOrAppointmentsCounts,
        region:
          regions?.length === allProvincesLabelsList.length
            ? "ALL"
            : regions?.join(","),
        users: selectedStaffUser ? `${selectedStaffUser?.id}` : null,
        dealType: dealTypeState,
      });
    }
  }, [
    calendarType,
    dateEndForRequestClosingOrAppointmentsCounts,
    dateStartForRequestClosingOrAppointmentsCounts,
    dealTypeState,
    getClosingsDealsCountDaily,
    regions,
    selectedStaffUser,
    user.user.role,
    view,
  ]);
  const getEventsCallback = useCallback(
    (date, regions, staff, dealTypes, selectedStaffUser, calendarTimezone) => {
      if (!isLoadingStaff) {
        dispatch(
          getEventsTC(
            date,
            regions,
            staffState,
            dealTypes,
            selectedStaffUser,
            calendarTimezone,
            view,
          ),
        );
      }
    },
    [dispatch, isLoadingStaff, staffState, view],
  );
  const getEventsEffectFunction = () => {
    getEventsEffect(
      date,
      regions as string[],
      dispatch,
      getEventsCallback,
      staff as unknown as string[],
      dealTypeState as unknown as string[],
      isAllProvinciesChecked,
      selectedStaffUser,
      calendarTimezone,
      view,
    );
  };
  useEffect(() => {
    getEventsEffect(
      date,
      regions as string[],
      dispatch,
      getEventsCallback,
      staff as unknown as string[],
      dealTypeState as unknown as string[],
      isAllProvinciesChecked,
      selectedStaffUser,
      calendarTimezone,
      view,
    );
  }, [
    selectedStaffUser,
    date,
    dispatch,
    getEventsCallback,
    isAllProvinciesChecked,
    regions,
    staffState,
    dealTypeState,
    staff,
    calendarTimezone,
    view,
  ]);

  const getBlocked = useCallback(
    (date, regions, calendarTimezone, view) => {
      const startDay = getStartOrEndDateToRequest(
        date,
        view,
        "start",
        "M/D/YYYY",
      );
      const endDay = getStartOrEndDateToRequest(date, view, "end", "M/D/YYYY");
      dispatch(getClosingDatesTC(startDay, endDay, regions, calendarTimezone));
    },
    [dispatch],
  );

  const calculateAndSetSignings = useCallback(
    () =>
      calculateAndSetSigningsCallback(
        view as string,
        date,
        calendarEventsFromStore,
        setSigningsTotal,
        isCalendarClosings,
      ),
    [view, date, calendarEventsFromStore, isCalendarClosings],
  );
  useEffect(() => {
    calculateAndSetSignings();
  }, [date, calculateAndSetSignings, calendarTimezone]);
  const getBlockedEffectFunction = () => {
    changeDateEffect(
      date,
      getBlocked,
      isAllProvinciesChecked,
      regions as string[],
      calendarTimezone,
      view,
    );
  };
  useEffect(
    () =>
      changeDateEffect(
        date,
        getBlocked,
        isAllProvinciesChecked,
        regions as string[],
        calendarTimezone,
        view,
      ),
    [
      date,
      dispatch,
      successClosingCreate,
      regions,
      isAllProvinciesChecked,
      getBlocked,
      successfullyDeleted,
      calendarTimezone,
      view,
    ],
  );

  const toggleDrawer = useCallback((open: boolean) => {
    return (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setShowDealPreview(open);
    };
  }, []);
  const onSubmit = (values: typeof initialValues) => {
    setShowCloseLapse(false);
    timeBlockFormikSettings.onSubmit(
      values.isRepeat,
      values.repeat,
      values.isAllDay,
      values.isShowMobileSigners,
      values.province,
      values.dateStart,
      values.dateEnd,
      values.timeStart,
      values.timeEnd,
      values.repeatEveryDays,
      values.repeatEveryWeeks,
      values.repeatEveryMonths,
      values.repeatEveryYears,
      values.selectedDaysOfWeek,
      values.selectedDaysOfMonth,
      values.selectedMothsOfYear,
      values.mobileSignerId,
      values.closing_id,
      dispatch,
      getBlockedEffectFunction,
    );
  };
  const schema = timeBlockFormikSettings.schema;
  const initialValues = timeBlockFormikSettings.initialValues;

  const {handleSubmit, values, errors, handleChange, setErrors} = useFormik({
    validationSchema: schema,
    initialValues,
    onSubmit,
    validateOnChange: false,
    validateOnBlur: false,
  });
  const calendarWidth = "100%";

  return (
    <MainCalendarBox
      id={"MainCalendarBox"}
      sx={{
        [theme.breakpoints.down("sm")]: {
          height: "calc(100vh - 58px)",
        },
        [allDayDayEventsClassNameForDayView]: {
          display: "content",
          "& .rbc-time-header-content": {
            borderLeft: "none",
            "& .rbc-allday-cell": {
              marginTop: "0px",
              "& .rbc-row-bg": {
                "& .rbc-day-bg": {
                  boxShadow: "none",
                },
                "& .rbc-day-bg.rbc-today": {
                  border: "none !important",
                },
              },
            },
          },
        },
      }}
    >
      <DealCalendarDrawer
        open={showDealPreview}
        setShowDealPreview={setShowDealPreview}
        anchor="right"
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      />
      {windowWidth < theme.breakpoints.values.sm && (
        <CalendarMobileContext.Provider
          value={{
            setTableType,
            tableType,
            date,
            regions,
            isGettingEvents,
            view,
            dealTypeState,
            setSigningsTotal,
            isAllProvinciesChecked,
            staffState,
            closingsList: closingsList as unknown as never[],
            setDate,
            setView,
            setRegions,
            setDealTypeState,
            totalNumberOf: signingsTotal,
            handleChange,
            setShowDealPreview,
            setShowModal,
            setSelectedBlockedEvent,
            setCurrentPageForCreateEventPopup,
            currentPageForCreateEventPopup,
            selectedBlockedEvent: selectedBlockedEvent as {},
            getBlockedEffectFunction,
            dateStart,
            dateEnd,
            getEventsEffectFunction,
          }}
        >
          <CalendarMobile />
        </CalendarMobileContext.Provider>
      )}
      {windowWidth >= theme.breakpoints.values.sm && (
        <>
          {windowWidth >= theme.breakpoints.values.sm && (
            <CalendarHeader
              isGettingEvents={isGettingEvents}
              allProvincesLabelsList={allProvincesLabelsList}
              allProvincesList={allProvincesList}
              blockedAndEvents={blockedAndEvents}
              setView={setView}
              view={view as string}
              date={date}
              setDate={setDate}
              setShowCloseLapse={setShowCloseLapse}
              regions={regions}
              setRegions={setRegions}
              dealTypeState={dealTypeState}
              setDealTypeState={setDealTypeState}
              allRoles={allRoles}
              allDealTypes={allDealTypes}
              staffState={staffState}
              signingsAmount={signingsTotal}
              setStaffState={(value: string) => {
                setStaffState(value);
              }}
              dateStart={moment(values.dateStart).toDate()}
              handleChange={handleChange}
            />
          )}
          {showCloseLapse && (
            <CloseCalendarLapseV2
              setErrors={setErrors}
              errors={errors}
              handleChange={handleChange}
              values={values}
              handleSubmit={handleSubmit as (someForChanging: unknown) => void}
              setShowCloseLapse={
                setShowCloseLapse as (showCloseLapse: boolean) => boolean
              }
              mobileSigners={mobileSigners as MobileSignerType[] | null}
            />
          )}
          {windowWidth < miniDesktopCalendar && (
            <>
              <CalendarSideTools
                setShowDealPreview={setShowDealPreview}
                closingsList={
                  closingsList as ClosingsCountApiType[] | undefined
                }
                setTableType={setTableType}
                tableType={tableType}
                date={date}
                regions={regions}
                isGettingEvents={isGettingEvents}
                view={view}
                dealTypeState={dealTypeState}
                setSigningsTotal={setSigningsTotal}
                isAllProvinciesChecked={isAllProvinciesChecked}
                staffState={staffState}
              />
            </>
          )}
          <Box className={`calendarContainer ${view}`}>
            {isGettingEvents || isLoadingStaff || isLoadingClosingDates ? (
              <CalendarLoader calendarWidth={calendarWidth} />
            ) : (
              <>
                {isCalendarClosings && view === "day" ? (
                  <BigCalendarClosingsDayV2
                    setShowDealPreview={setShowDealPreview}
                    downloadClosingsReportCallback={
                      downloadClosingsReportCallback
                    }
                    width={calendarWidth}
                    date={date}
                    setDate={setDate}
                  />
                ) : (
                  <>
                    {isCalendarClosings && view === "week" ? (
                      <BigCalendarClosingsWeek
                        width={calendarWidth}
                        date={date}
                      />
                    ) : (
                      <>
                        {isCalendarClosings && view === "month" ? (
                          <BigCalendarClosingsMonth
                            width={calendarWidth}
                            date={date}
                          />
                        ) : (
                          <BigCalendarContext.Provider
                            value={{
                              currentDate: date,
                              regions: regions as never[],
                            }}
                          >
                            <BigCalendar
                              selectedBlockedEvent={selectedBlockedEvent}
                              setSelectedBlockedEvent={setSelectedBlockedEvent}
                              currentPageForCreateEventPopup={
                                currentPageForCreateEventPopup
                              }
                              setCurrentPageForCreateEventPopup={
                                setCurrentPageForCreateEventPopup
                              }
                              setShowModal={setShowModal}
                              getEventsEffectFunction={getEventsEffectFunction}
                              getBlockedEffectFunction={
                                getBlockedEffectFunction
                              }
                              blockedAndEvents={blockedAndEvents}
                              width={calendarWidth}
                              handleChange={handleChange as () => void}
                              view={view as string}
                              setView={setView}
                              setDate={setDate}
                              date={date}
                              setStartDate={setStartDate}
                              dateStart={dateStart}
                              setEndDate={setEndDate}
                              dateEnd={dateEnd}
                              setShowCloseLapse={setShowCloseLapse}
                              setShowDealPreview={setShowDealPreview}
                            />
                          </BigCalendarContext.Provider>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
            {windowWidth >= miniDesktopCalendar && (
              <>
                <CalendarSideTools
                  setShowDealPreview={setShowDealPreview}
                  closingsList={
                    closingsList as ClosingsCountApiType[] | undefined
                  }
                  setTableType={setTableType}
                  tableType={tableType}
                  date={date}
                  regions={regions}
                  isGettingEvents={isGettingEvents}
                  view={view}
                  dealTypeState={dealTypeState}
                  setSigningsTotal={setSigningsTotal}
                  isAllProvinciesChecked={isAllProvinciesChecked}
                  staffState={staffState}
                />
              </>
            )}
          </Box>
        </>
      )}
    </MainCalendarBox>
  );
};
interface CalendarSideToolsProps {
  setTableType: (tableType: string) => void;
  tableType: string;
  date: Date;
  regions: string[];
  isGettingEvents: boolean;
  view: string;
  dealTypeState: string;
  setSigningsTotal: (signingsTotal: number) => void;
  isAllProvinciesChecked: boolean;
  staffState: string;
  closingsList?: ClosingsCountApiType[];
  setShowDealPreview: (showDealPreview: boolean) => void;
}
const CalendarSideTools: React.FC<CalendarSideToolsProps> = ({
  setShowDealPreview,
  setTableType,
  tableType,
  date,
  regions,
  isGettingEvents,
  view,
  dealTypeState,
  setSigningsTotal,
  isAllProvinciesChecked,
  staffState,
  closingsList,
}) => {
  const windowWidth = useWindowWidth();
  const theme = useTheme();
  const calendarType = useAppSelector(
    (state) => state.CalendarReducer.calendarType,
  );
  const isClosings = calendarType === "Closings";
  const isShowedClosingsDetails = tableType === "Details" && isClosings;
  const isShowedCountingTables =
    tableType === "Volume" || calendarType === "Signing Appointment";
  return (
    <>
      <Box
        sx={{
          minHeight: isClosings ? "700px" : "0px",
          background: isClosings ? constants.colors.white : "transparent",
          minWidth: "382px",
          width: "382px",
          marginLeft: "24px",
          marginTop: windowWidth < miniDesktopCalendar ? "20px" : "0px",
          [theme.breakpoints.down(miniDesktopCalendar)]: {
            width: "calc(100% - 45px)",
            marginLeft: "24px",
          },
          [theme.breakpoints.down(phoneCalendar)]: {
            minWidth: "0px",
          },
        }}
      >
        {isClosings && (
          <Box
            sx={{
              display: "flex",
              height: "36px",
            }}
          >
            <TableTypeButton
              onClick={() => setTableType("Details")}
              isActive={tableType === "Details"}
            >
              Details
            </TableTypeButton>
            <TableTypeButton
              onClick={() => setTableType("Volume")}
              isActive={tableType === "Volume"}
            >
              Volume
            </TableTypeButton>
          </Box>
        )}
        {isShowedCountingTables && (
          <CalendarEventsCountingTables
            closingsList={
              closingsList as
                | (ClosingsCountApiType[] & {
                    resultCalendar: {closings_count: number};
                  })
                | undefined
            }
            date={date}
            regions={regions}
            isGettingEvents={isGettingEvents}
            view={view as "day" | "week" | "month"}
            dealType={dealTypeState}
            setSigningsTotal={
              setSigningsTotal as Dispatch<SetStateAction<number>>
            }
          />
        )}
        {isShowedClosingsDetails && (
          <CalendarClosingDates
            setShowDealPreview={setShowDealPreview}
            view={view}
            date={date}
            isAllProvinciesChecked={isAllProvinciesChecked}
            regions={regions}
            setSigningsTotal={setSigningsTotal}
            staffState={staffState}
            dealTypeState={dealTypeState}
          />
        )}
      </Box>
    </>
  );
};
const TableTypeButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "isActive"
})<{isActive: boolean}>(({isActive}) => ({
  width: "50%",
  height: "36px",
  color: isActive ? constants.colors.red : constants.colors.deededGray,
  fontWeight: "500",
  fontSize: "16px",
  textTransform: "capitalize",
  borderRadius: "0px",
  backgroundColor: isActive
    ? constants.colors.white
    : constants.colors.configNotificationsTableBorder,
}));
export default GoogleCalendar;
