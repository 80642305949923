export const createUserEventCountTableData = (
  name: string,
  purchase: number,
  mortgage: number,
  sale: number,
  lender: number,
  monday: number,
  tuesday: number,
  wednesday: number,
  thursday: number,
  friday: number,
  sum?: number,
  capacity?: {high: number; medium: number} | null,
) => ({
  name,
  purchase,
  mortgage,
  sale,
  lender,
  sum: sum ?? purchase + mortgage + sale + lender,
  monday,
  tuesday,
  wednesday,
  thursday,
  friday,
  sumByWeekDays: sum ?? monday + tuesday + wednesday + thursday + friday,
  capacity,
});
