import React, {useState, useEffect} from "react";
// import {Box, useTheme} from "@mui/material";
import {Box, styled} from "@mui/material";
import {Theme} from "@mui/material/styles";
import {DefaultTheme, useTheme} from "@mui/styles";

interface DealTextAreaProps {
  value: string;
  handleInputValue: (inputValue: string) => void;
}
const DealTextArea = ({value, handleInputValue}: DealTextAreaProps) => {
  const [inputValue, setInputValue] = useState(value);
  useEffect(() => {
    setInputValue(value);
  }, [handleInputValue]);
  const theme = useTheme();
  return (
    <Box
      style={{
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "300",
        fontSize: "12px",
        lineHeight: "15px",
      }}
    >
      <Textarea
        theme={theme as Theme & DefaultTheme}
        required
        onChange={(e) => setInputValue(e.target.value)}
        value={inputValue}
        onBlur={() => handleInputValue(inputValue)}
      />
    </Box>
  );
};

const Textarea = styled("textarea")<{theme: Theme & DefaultTheme}>(
  ({theme}) => ({
    resize: "none",
    borderRadius: "4px",
    minHeight: "9rem",
    padding: "1rem",
    outline: "none",
    border: "1px solid #e3e3e3",
    minWidth: "544px",
    height: "300px",
    display: "flex",
    flexWrap: "wrap",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "300",
    fontSize: "12px",
    lineHeight: "15px",
    marginRight: "16px",
    width: "100%",
    "&::-webkit-scrollbar": {
      width: "5px",
    },
    [theme.breakpoints.down("md")]: {
      minWidth: "450px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      minWidth: "100%",
      height: "156px",
      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.06)",
    },
  }),
);
export default DealTextArea;
