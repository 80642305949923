import React, {useEffect, useState} from "react";
import * as yup from "yup";
import {useFormik} from "formik";
import {stringOrDate} from "react-big-calendar";
import moment from "moment";
import {Box, MenuItem, styled, useTheme} from "@mui/material";
import constants from "styles/constants";
import DeededSelect from "v2/components/DeededSelect_DEPRECATED";
import DeededButton from "v2/components/DeededButton";
import DeededSwitcher from "v2/components/DeededSwitcher";
import DeededCheckbox from "v2/components/DeededCheckbox";
import DeededInput from "v2/components/DeededInput";
import { Workhours } from "types/calendarAppointmentType";
import {BlockedEvent} from "types/CalendarComponent/blockedEventTypes";
import SaveIcon from "components/Svg/SaveIcon";
import {useAppDispatch} from "redux/hooks";
import {
  createClosingDateTC,
  deleteClosingDateTC,
  updateClosingDateTC,
} from "redux/reducers/calendarReducer";
import useUser from "utils/hooks/useUser";
import useThemedStyles from "utils/hooks/useThemedStyles";
import useWindowWidth from "utils/hooks/useWindowWidth";
import {
  BigCalendarBlockTimeBlockValuesType,
  StylesObjectFieldsType,
} from "../types/bigCalendarBlockTimeTypes";
import allRegions from "../constants/allRegions";
import {handleBlockedEventSelected} from "../utils/handleBlockedEventSelected";
import {stylesFactory} from "./BigCalendar";
import BigCalendarBlockTimeBlockHidebleFields from "./BigCalendarBlockTimeBlockHidebleFields";
import BigCalendarBlockTimeDateFields from "./BigCalendarBlockTimeDateFields";

const schema = yup.object().shape({
  blackAllDay: yup.boolean(),
  holiday: yup.boolean(),
  holidays: yup.string().when("holiday", {
    is: (holiday: boolean) => holiday === true,
    then: () => yup.string().required("Field is required"),
  }),
  startTime: yup.date().typeError("Field is required"),
  endTime: yup.date().typeError("Field is required"),
  region: yup.string().required("Field is required"),
  isShowedBlockForUserFields: yup.boolean(),
  forSelectedUser: yup
    .string()
    .required("Field is required")
    .test("forSelectedUser", "Field is required", function (forSelectedUser) {
      if (this.parent.isShowedBlockForUserFields && !this.parent.holiday) {
        return forSelectedUser !== "null";
      } else {
        return true;
      }
    }),
});

interface BigCalendarBlockTimeProps {
  startDate: stringOrDate;
  dateEnd: stringOrDate;
  stylesFactory: typeof stylesFactory;
  onClose: () => void;
  getBlockedEffectFunction: () => void;
  selectedBlockedEvent: BlockedEvent | null;
  setSelectedBlockedEvent: (selectedBlockedEvent: BlockedEvent | null) => void;
}

const BigCalendarBlockTime: React.FC<BigCalendarBlockTimeProps> = ({
  startDate,
  stylesFactory,
  dateEnd,
  onClose,
  getBlockedEffectFunction,
  setSelectedBlockedEvent,
  selectedBlockedEvent,
}) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const styles = useThemedStyles(stylesFactory);
  const [isChangeableRole, setIsChangeableRole] = useState(false);
  const { user, isAdmin } = useUser();
  const initialValues = {
    blackAllDay: false,
    holiday: false,
    holidays: "",
    dateStart: moment(startDate).format("MM/DD/YYYY"),
    dateEnd: moment(dateEnd).format("MM/DD/YYYY"),
    startTime: startDate,
    endTime: dateEnd,
    region: allRegions[0],
    forSelectedUser: "null",
    role: "null",
    isShowedBlockForUserFields: false,
    isRepeat: false,
    repeatEveryDays: 1,
    repeatEveryWeeks: 1,
    repeatEveryMonths: 1,
    repeatEveryYears: 1,
    selectedDaysOfWeek: [],
    selectedDaysOfMonth: [],
    selectedMothsOfYear: [],
    repeat: "Daily",
    closing_id: null,
  };

  const onSubmit = ({
    blackAllDay,
    holidays,
    startTime,
    endTime,
    region,
    forSelectedUser,
    dateStart,
    dateEnd,
    repeat,
    repeatEveryDays,
    repeatEveryWeeks,
    repeatEveryMonths,
    repeatEveryYears,
    selectedDaysOfWeek,
    selectedDaysOfMonth,
    selectedMothsOfYear,
    isRepeat,
    closing_id,
  }: typeof initialValues) => {
    if (!user?.id) return;  
      
    const start_time = `${moment(dateStart).format("M/D/YYYY")} ${moment(
      startTime,
    ).format("HH:mm:ss")}`;
    const end_time = `${moment(dateEnd).format("M/D/YYYY")} ${moment(
      endTime,
    ).format("HH:mm:ss")}`;

    const selectedUser = !isAdmin ? user.id : forSelectedUser;
    const for_user = selectedUser && selectedUser !== "null";
    const holiday = holidays === "" ? null : holidays;
    const user_id = selectedUser === "null" ? null : +selectedUser;

    const payload = {
      is_recurring: holiday ? false : isRepeat,
      is_full_day: blackAllDay,
      for_user,
      state: region,
      start_date: start_time,
      end_date: end_time,
      start_time,
      end_time,
      user_id,
      recurring_pattern: holiday
        ? null
        : isRepeat
        ? {
            recurring_type: repeat,
            interval:
              repeat === "Daily"
                ? repeatEveryDays
                : repeat === "Weekly"
                ? repeatEveryWeeks
                : repeat === "Monthly"
                ? repeatEveryMonths
                : repeat === "Yearly"
                ? repeatEveryYears
                : null,
            repeat_by_days:
              selectedDaysOfWeek.length === 0 ? null : selectedDaysOfWeek,
            repeat_by_month_days:
              selectedDaysOfMonth.length === 0 ? null : selectedDaysOfMonth,
            repeat_by_months:
              selectedMothsOfYear.length === 0 ? null : selectedMothsOfYear,
          }
        : null,
      holiday: blackAllDay ? holiday : null,
    };

    if (!closing_id) {
      dispatch(createClosingDateTC(payload, getBlockedEffectFunction));
    } else {
      dispatch(
        updateClosingDateTC(closing_id, payload, getBlockedEffectFunction),
      );
    }
    onClose();
  };
  const onDelete = () => {
    if (values.closing_id) {
      dispatch(deleteClosingDateTC(values.closing_id));
    }
    onClose();
  };
  const {handleSubmit, values, handleChange, errors} = useFormik({
    validationSchema: schema,
    initialValues,
    onSubmit,
    validateOnChange: false,
    validateOnBlur: false,
  });
  useEffect(() => {
    if (selectedBlockedEvent) {
      handleBlockedEventSelected(selectedBlockedEvent, handleChange, 2);
    }
  }, [handleChange, selectedBlockedEvent]);
  useEffect(() => {
    return () => {
      setSelectedBlockedEvent(null);
    };
  }, [setSelectedBlockedEvent]);
  const [activeSwitcher, setActiveSwitcher] = useState(
    !!selectedBlockedEvent?.is_full_day,
  );
  const handleChangeSwitcher = () => {
    handleChange({
      target: {
        name: "blackAllDay",
        value: !activeSwitcher,
      },
    });
    setActiveSwitcher((x) => !x);
  };

  const [activeCheckbox, setActiveCheckbox] = useState(
    !!selectedBlockedEvent?.holiday,
  );
  const handleChangeCheckbox = () => {
    handleChange({
      target: {
        name: "holiday",
        value: !activeCheckbox,
      },
    });
    setActiveCheckbox((x) => !x);
  };
  useEffect(() => {
    if (values.blackAllDay) {
      handleChange({
        target: {
          name: "dateEnd",
          value: values.dateStart,
        },
      });
      handleChange({
        target: {
          name: "startTime",
          value: new Date(new Date(values.dateStart).setHours(Workhours.start, 0, 0, 0))
        },
      });
      handleChange({
        target: {
          name: "endTime",
          value: new Date(new Date(values.dateStart).setHours(Workhours.end, 0, 0, 0))
        },
      });
    }
  }, [handleChange, values.blackAllDay, values.dateStart]);
  useEffect(() => {
    if (
      moment(values.dateStart, "M/D/YYYY").toDate() >
      moment(values.dateEnd, "M/D/YYYY").toDate()
    ) {
      handleChange({
        target: {
          name: "dateEnd",
          value: values.dateStart,
        },
      });
    }
  }, [handleChange, values.dateEnd, values.dateStart]);
  useEffect(() => {
    if (!activeSwitcher) {
      handleChange({
        target: {
          name: "holidays",
          value: "",
        },
      });
      handleChange({
        target: {
          name: "holiday",
          value: false,
        },
      });
      setActiveCheckbox(false);
    }
  }, [activeCheckbox, activeSwitcher, handleChange]);

  useEffect(() => {
    if (activeCheckbox && activeSwitcher) {
      handleChange({
        target: {
          name: "forSelectedUser",
          value: "null",
        },
      });
    }
  }, [activeCheckbox, activeSwitcher, handleChange]);
  useEffect(() => {
    if (isChangeableRole) {
      handleChange({
        target: {
          name: "forSelectedUser",
          value: "null",
        },
      });
    }
  }, [handleChange, isChangeableRole, values.role]);
  useEffect(() => {
    const conditionToChangeEndTime =
      values.dateStart === values.dateEnd && values.startTime > values.endTime;
    if (conditionToChangeEndTime) {
      handleChange({
        target: {
          name: "endTime",
          value: values.startTime,
        },
      });
    }
  }, [
    handleChange,
    values.dateEnd,
    values.dateStart,
    values.endTime,
    values.startTime,
  ]);
  return (
    <Form sx={styles.Form} onSubmit={handleSubmit}>
      <Box sx={styles.FormItem}>
        <DeededSwitcher
          data-selenium-id={"block-all-day-switcher"}
          sx={{
            flexDirection: "row-reverse",
            margin: 0,
            ".MuiFormControlLabel-label": {
              fontWeight: 600,
              fontSize: "12px",
              lineHeight: "15px",
              color: constants.colors.sortingColor,
              marginRight: "8px",
            },
          }}
          activeSwitcher={activeSwitcher}
          handleChangeSwitcher={handleChangeSwitcher}
          label="Block All Day:"
        />
      </Box>

      {(isAdmin && activeSwitcher) && (
        <Box
          sx={{
            ...styles.FormItem,
            display: "flex",
            alignItems: "flex-start",
            [theme.breakpoints.down("sm")]: {
              flexDirection: "column",
            },
          }}
        >
          <FormLabel
            sx={{
              ...styles.FormLabel,
              display: "inline-flex",
              alignItems: "center",
              flexDirection: "row",
              marginLeft: "-9px",
            }}
          >
            <DeededCheckbox
              data-selenium-id="holiday-checkbox"
              checked={activeCheckbox}
              onChange={handleChangeCheckbox}
            />
            <Box sx={{...styles.FormLabelText, marginBottom: 0}}>Holiday</Box>
          </FormLabel>
          {activeCheckbox && (
            <Box
              sx={{
                marginLeft: "24px",
                [theme.breakpoints.down("sm")]: {
                  marginLeft: "0px",
                  width: "100%",
                },
              }}
            >
              <DeededInput
                data-selenium-id="holiday-input"
                type="text"
                placeholder="Holiday"
                sx={{
                  width: "291px",
                  height: "36px",
                  [theme.breakpoints.down("sm")]: {
                    width: "100%",
                  },
                }}
                value={values.holidays}
                onChange={(e) => {
                  handleChange({
                    target: {
                      name: "holidays",
                      value: e.target.value,
                    },
                  });
                }}
                error={errors.holidays}
              />
            </Box>
          )}
        </Box>
      )}
      <BigCalendarBlockTimeDateFields
        styles={styles}
        handleChange={handleChange}
        errors={errors}
        values={values as unknown as BigCalendarBlockTimeBlockValuesType}
      />
      {!activeCheckbox && (
        <>
          <Box
            sx={{
              ...styles.FormItem,
              [theme.breakpoints.down("sm")]: {
                display: "flex",
              },
            }}
          >
            <FormLabel
              sx={{
                ...styles.FormLabel,
                [theme.breakpoints.down("sm")]: {
                  "& .MuiBox-root": {
                    width: "81px",
                    "& .MuiOutlinedInput-root": {
                      width: "100%",
                      height: "36px",
                      "& .MuiSelect-select": {
                        padding: "2px 14px",
                      },
                    },
                  },
                  maxWidth: "unset",
                },
              }}
            >
              <Box sx={styles.FormLabelText}>Region</Box>
              <DeededSelect
                data-selenium-id="region-select"
                value={values.region}
                onChange={(e) => {
                  handleChange({
                    target: {
                      name: "region",
                      value: e.target.value,
                    },
                  });
                }}
                displayEmpty
                inputProps={{"aria-label": "Without label"}}
              >
                {allRegions.map((item, index) => (
                  <MenuItem
                    sx={{
                      [theme.breakpoints.down("sm")]: {
                        display: "flex !important",
                      },
                    }}
                    key={index}
                    value={item}
                  >
                    {item}
                  </MenuItem>
                ))}
              </DeededSelect>
            </FormLabel>
            <Box
              sx={{
                ...styles.FormInfo,
                [theme.breakpoints.down("sm")]: {
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "16px",
                  marginTop: "17px",
                },
              }}
            >
              Region / Timezone for this Appointment is MST
            </Box>
          </Box>
          <BigCalendarBlockTimeBlockHidebleFields
            setIsChangeableRole={setIsChangeableRole}
            values={values as unknown as BigCalendarBlockTimeBlockValuesType}
            handleChange={handleChange}
            styles={styles}
            errors={errors}
          />
        </>
      )}

      <BigCalendarBlockTimeSubmitButtons
        onClose={onClose}
        onDelete={onDelete}
        styles={styles}
        isSelectedBlockEvent={!!selectedBlockedEvent}
      />
    </Form>
  );
};
interface BigCalendarBlockTimeSubmitButtonsProps {
  onDelete: () => void;
  onClose: () => void;
  styles: StylesObjectFieldsType;
  isSelectedBlockEvent: boolean;
}
const BigCalendarBlockTimeSubmitButtons: React.FC<
  BigCalendarBlockTimeSubmitButtonsProps
> = ({onClose, styles, isSelectedBlockEvent, onDelete}) => {
  const theme = useTheme();
  const { sm } = theme.breakpoints.values;
  const windowWidth = useWindowWidth();
  return (
    <>
      <Box
        sx={{
          ...styles.FormItemButtons,
          [theme.breakpoints.down("sm")]: {
            flexDirection: "column-reverse",
          },
        }}
      >
        <DeededButton
          data-selenium-id="cancel-button"
          sx={{
            width: "75px",
            [theme.breakpoints.down("sm")]: {
              width: "100%",
              marginBottom: "15px",
            },
          }}
          kind={windowWidth < sm ? "secondary-v2" : "secondary"}
          onClick={onClose}
          className="cancel-appt"
          type="button"
        >
          Cancel
        </DeededButton>
        <Box
          sx={{
            display: "flex",
            [theme.breakpoints.down("sm")]: {
              flexDirection: "column-reverse",
            },
          }}
        >
          {isSelectedBlockEvent && (
            <DeededButton
              data-selenium-id="delete-button"
              onClick={onDelete}
              kind="secondary"
              sx={{
                width: "120px",
                marginRight: "10px",
                [theme.breakpoints.down("sm")]: {
                  width: "100%",
                  marginRight: "0px",
                  marginBottom: "15px",
                },
              }}
              type="button"
            >
              Delete
            </DeededButton>
          )}
          <DeededButton
            data-selenium-id="save-button"
            kind="primary"
            sx={{
              width: "120px",
              svg: {
                marginRight: "8px",
                stroke: constants.colors.white,
                transition: constants.transitions,
              },
              "&:hover": {
                svg: {
                  stroke: constants.colors.red,
                  transition: constants.transitions,
                },
              },
              [theme.breakpoints.down("sm")]: {
                width: "100%",
                marginBottom: "15px",
              },
            }}
            type="submit"
          >
            {windowWidth >= sm && <SaveIcon />} Save
          </DeededButton>
        </Box>
      </Box>
    </>
  );
};
const FormLabel = styled("label")({});
const Form = styled("form")({});

export default BigCalendarBlockTime;
