import { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { deleteDealContactDraft } from "api";
import GenericDialog from "components/Common/Modal/GenericDialog";
import { ContactDraft } from "types/user";
import configuration from "utils/configuration";

interface DeleteContactDraftModalProps {
    draftToRemove: ContactDraft | null;
    onClose: () => void;
}

const DeleteContactDraftModal = ({ onClose, draftToRemove }: DeleteContactDraftModalProps) => {
    const { enqueueSnackbar } = useSnackbar();
    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleConfirmClientDelete = () => {
        if (!draftToRemove || isLoading) return;
        setIsLoading(true);
        deleteDealContactDraft(draftToRemove.deal_id, draftToRemove.id)
            .then(() => {
                setOpenConfirmModal(false);
                enqueueSnackbar("Client removed", {
                    variant: "success",
                    autoHideDuration: configuration.autoHideErrorDuration,
                });
                onClose();
            })
            .catch((error) =>
                enqueueSnackbar(error?.response?.data?.error || "Failed to delete the client", {
                    variant: "error",
                    autoHideDuration: configuration.autoHideErrorDuration,
                }),
            )
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        if (draftToRemove) {
            return setOpenConfirmModal(true);
        }
    }, [draftToRemove]);

    return (
        <GenericDialog
            action="positive"
            contentText="Are you sure you want to remove this client?"
            onCancel={() => {
                setOpenConfirmModal(false);
                onClose();
            }}
            onSubmit={handleConfirmClientDelete}
            title="Remove client"
            titleComponent="H4"
            open={openConfirmModal}
            submitText={!isLoading ? "Yes, remove client" : "Loading..."}
            cancelBtnProps={{ typeOf: "secondary" }}
            dialogProps={{ maxWidth: "xs" }}
        />
    );
};

export default DeleteContactDraftModal;
