import {Box, Typography, useTheme} from "@mui/material";
import moment from "moment";
import React from "react";
import {Staff} from "types/Config/userManagerTypes";

const UserManagerLastResetPasswordTableItem: React.FC<{
  item: Staff;
}> = ({item}) => {
  const theme = useTheme();
  return (
    <>
      <Box
        sx={{
          [theme.breakpoints.down("lg")]: {
            display: item?.updated_password ? "grid !important" : "none",
            gridTemplateColumns: "80px 1fr",
            marginTop: item?.updated_password ? "13px" : "0px",
          },
        }}
        className="TableBodyCell"
      >
        {item?.updated_password && (
          <Typography
            sx={{
              display: "none",
              [theme.breakpoints.down("lg")]: {
                display: "block",
                fontSize: "12px",
                fontWeight: "500",
              },
            }}
          >
            Psw Rst:
          </Typography>
        )}
        <div
          className="ItemWrapper"
          title={item?.updated_password as string | undefined}
        >
          {item?.updated_password
            ? moment(item?.updated_password, "MM/DD/YYYY, hh:mm:ss A").format(
                "MM/DD/YYYY, hh:mm A",
              )
            : ""}
        </div>
      </Box>
    </>
  );
};
export default UserManagerLastResetPasswordTableItem;
