import React from "react";

type UserType = {
  color: string | undefined;
  width: number | undefined;
  height: number | undefined;
};
const User: React.FC<UserType> = ({color, width = 20, height = 20}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 20 20">
      <path
        d="M10.0002 9.78131C12.2238 9.78131 14.0266 7.59171 14.0266 4.89069C14.0266 2.1896 13.4348 0 10.0002 0C6.56563 0 5.97363 2.1896 5.97363 4.89069C5.97363 7.59171 7.77643 9.78131 10.0002 9.78131Z"
        fill={color}
      />
      <path
        d="M2.39483 17.2487C2.39415 17.0841 2.39346 17.2023 2.39483 17.2487V17.2487Z"
        fill={color}
      />
      <path
        d="M17.605 17.3774C17.6071 17.3323 17.6057 17.0646 17.605 17.3774V17.3774Z"
        fill={color}
      />
      <path
        d="M17.5963 17.0513C17.5217 12.346 16.9072 11.0053 12.2048 10.1566C12.2048 10.1566 11.5428 11.0001 9.99997 11.0001C8.45712 11.0001 7.79506 10.1566 7.79506 10.1566C3.14392 10.996 2.49203 12.3169 2.40632 16.8984C2.39929 17.2725 2.39603 17.2922 2.39478 17.2487C2.39506 17.3301 2.3954 17.4806 2.3954 17.7431C2.3954 17.7431 3.51495 20 9.99997 20C16.4849 20 17.6045 17.7431 17.6045 17.7431C17.6045 17.5745 17.6047 17.4572 17.6048 17.3774C17.6036 17.4043 17.6011 17.3522 17.5963 17.0513Z"
        fill={color}
      />
    </svg>
  );
};

export default User;
