import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {Box, IconButton, styled, Typography, useTheme} from "@mui/material";
import constants from "styles/constants";
import DeededSpace from "v2/components/DeededSpace";
import {setFullDealInfoAC} from "redux/reducers/calendarReducer";
import useTypedSelector from "utils/hooks/useTypedSelector";
import CloseDeededSvg from "components/Svg/CloseDeededSvg";
import DealBasicInfo from "./DealBasicInfo/DealBasicInfo";
import DealInfoAccordions from "./DealInfoAccordions/DealInfoAccordions";

interface DealCalendarInfoProps {
  setShowDealPreview: (open: boolean) => void;
}

const DealCalendarInfo: React.FC<DealCalendarInfoProps> = ({
  setShowDealPreview,
}) => {
  const dealFullInfo = useTypedSelector(
    (state) => state.CalendarReducer.dealFullInfo,
  );
  const dispatch = useDispatch();
  const theme = useTheme();
  useEffect(() => {
    return () => {
      dispatch(setFullDealInfoAC(null));
    };
  }, [dispatch]);
  return (
    <Box
      sx={{
        width: 448,
        position: "relative",
        marginTop: "13px",
        "&::-webkit-scrollbar": {
          width: "5px",
        },
        [theme.breakpoints.down("sm")]: {
          paddingLeft: "22px",
          paddingRight: "22px",
          width: "100%",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <TypographyTitle>Deal Details</TypographyTitle>
        <IconButton
          onClick={() => {
            setShowDealPreview(false);
          }}
        >
          <CloseDeededSvg />
        </IconButton>
      </Box>

      {dealFullInfo !== null && <DealBasicInfo />}
      <DeededSpace y={32} />
      {dealFullInfo !== null && <DealInfoAccordions />}
    </Box>
  );
};

const TypographyTitle = styled(Typography)({
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "26px",
  lineHeight: "32px",
  display: "flex",
  alignItems: "center",
  color: constants.colors.title,
});
export default DealCalendarInfo;
