import { FC, useRef } from "react";
import { styled } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import { CopyIcon } from "../Icons";

interface ClickToCopyProps {
    callback?: () => void;
    noIcon?: boolean;
    isFlex?: boolean;
}

const ClickToCopy: FC<ClickToCopyProps> = ({ children, callback, noIcon, isFlex }) => {
    const { enqueueSnackbar } = useSnackbar();
    const divRef = useRef<HTMLDivElement>(null);

    const handleCopy = async () => {
        const text = divRef.current?.textContent;
        if (!text) {
            return;
        }

        await navigator.clipboard?.writeText(text);
        enqueueSnackbar("Copied", {
            variant: "success",
            autoHideDuration: 1500,
        });
        if (callback) {
            callback();
        }
    };

    if (noIcon) {
        return (
            <CopyWrapper isFlex={isFlex} noIcon={noIcon} ref={divRef} onClick={handleCopy}>
                {children}
            </CopyWrapper>
        );
    }

    return (
        <CopyWrapper isFlex={isFlex} ref={divRef} onClick={handleCopy}>
            <span>{children}</span> <CopyIcon size="small" />
        </CopyWrapper>
    );
};

const CopyWrapper = styled("span", { shouldForwardProp: (prop) => prop !== "noIcon" && prop !== "isFlex" })<{
    noIcon?: boolean;
    isFlex?: boolean;
}>(({ noIcon, isFlex }) => ({
    cursor: "pointer",
    display: isFlex ? "flex" : undefined,
    "&:hover span": {
        textDecoration: "underline",
    },
    ...(!noIcon
        ? {
              "& svg:last-child": {
                  visibility: "hidden",
              },
              "&:hover svg:last-child": {
                  visibility: "visible",
              },
          }
        : {}),
}));

export default ClickToCopy;
