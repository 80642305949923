import React, {useEffect, useMemo, useState} from "react";
import moment from "moment-timezone";
import {Theme} from "@mui/material/styles";
import {styled} from "@mui/material";
import {Box, Typography} from "@mui/material";
import useTypedSelector from "utils/hooks/useTypedSelector";
import DealDeterminantCircle from "components/Svg/DealDeterminantCircle";
import constants from "styles/constants";
import {CalendarFullDealInfoType} from "types/calendarFullDealInfoType";

const BasicInfoDealType = () => {
  const dealFullInfo = useTypedSelector(
    (state) => state.CalendarReducer.dealFullInfo,
  ) as CalendarFullDealInfoType & {isOpenedByClosing?: boolean};
  const [terminateColorForCircle, setTerminateColorForCircle] = useState("");
  const client = useMemo(() => {
    if (dealFullInfo?.isOpenedByClosing) {
      return (
        dealFullInfo?.participants.filter(
          (participant) => participant.role === "Client",
        )[0] ?? {full_name: ""}
      );
    } else {
      return dealFullInfo?.client_contact;
    }
  }, [dealFullInfo]);
  useEffect(() => {
    if (dealFullInfo?.deal_type === "Purchase") {
      setTerminateColorForCircle(constants.colors.green);
    } else if (dealFullInfo?.deal_type === "Sale") {
      setTerminateColorForCircle("#349ff2");
    } else {
      setTerminateColorForCircle("#74b3ce");
    }
  }, [dealFullInfo]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          marginTop: "7px",
          justifyContent: "space-between",
          width: "94%",
        }}
      >
        <Box>
          <TitleTypographyForDealInfo>type</TitleTypographyForDealInfo>
          <Box sx={{display: "flex", marginTop: "9px", marginLeft: "3px"}}>
            {" "}
            <DealDeterminantCircle color={terminateColorForCircle} />
            <StylizedDealType
              sx={{
                color:
                  dealFullInfo?.deal_type === "Purchase"
                    ? constants.colors.green
                    : dealFullInfo?.deal_type === "Sale"
                    ? "#349ff2"
                    : "#74b3ce",
              }}
            >
              {" "}
              {dealFullInfo?.deal_type}
            </StylizedDealType>
          </Box>
        </Box>
        <Box>
          <TitleTypographyForDealInfo>client name</TitleTypographyForDealInfo>
          <ValueTypographyForDealInfo>
            {client?.full_name}
          </ValueTypographyForDealInfo>
        </Box>
        <Box>
          <TitleTypographyForDealInfo>number</TitleTypographyForDealInfo>
          <ValueTypographyForDealInfo>
            {dealFullInfo?.deal_number}
          </ValueTypographyForDealInfo>
        </Box>
        <Box>
          <TitleTypographyForDealInfo>closing</TitleTypographyForDealInfo>
          <ValueTypographyForDealInfo>
            {
              //TODO:Do something with this date, after format we get wrong date
            }
            {moment(dealFullInfo?.closing_date, "YYYY-MM-DD").format(
              "MM/DD/YYYY",
            )}
          </ValueTypographyForDealInfo>
        </Box>
      </Box>
    </>
  );
};

// @ts-ignore
const StylizedDealType = styled(Typography)<{theme?: Theme}>(({theme}) => ({
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "17px",
  display: "flex",
  alignItems: "center",
  marginLeft: "5px",
  marginTop: "-2px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "10px",
  },
}));
const TitleTypographyForDealInfo = styled(Typography)<{theme?: Theme}>(
  ({theme}) => ({
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "10px",
    lineHeight: "12px",
    letterSpacing: "0.05em",
    textTransform: "uppercase",
    color: "#9F9F9F",
    [theme.breakpoints.down("sm")]: {
      fontSize: "8px",
    },
  }),
);
const ValueTypographyForDealInfo = styled(Typography)<{theme?: Theme}>(
  ({theme}) => ({
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "17px",
    display: "flex",
    alignItems: "center",
    marginTop: "7px",
    color: "#494949",
    overflow: "hidden",
    textOverflow: "ellipsis",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
    },
    // maxWidth:170,
  }),
);

export default BasicInfoDealType;
