import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {Box, MenuItem, styled} from "@mui/material";
import DeededSelectV2Transparent from "v2/components/DeededSelect";
import DeededLabel from "v2/components/DeededLabel";
import useTypedSelector from "utils/hooks/useTypedSelector";
import {
  getCommunicationsTC,
  getDealStagesTC,
  setCommunicationsAC,
  setSelectedServiceTypeAC,
  setSelectedStageAC,
} from "redux/reducers/configReducer/configNotificationsReducer";
import {
  NotificationsStage,
  StagesListType,
} from "redux/reducers/types/configNotificationsReducerType";
import {getEmailTemplatesTC} from "redux/reducers/configReducer/emailTemplateReducer";
import constants from "styles/constants";
import ShortCodeDescriptions from "../ShortCodeDescriptions/ShortCodeDescriptions";
import ConfigNotificationsCommunications from "./ConfigNotificationsCommunications";

const ConfigNotifications = () => {
  const dispatch = useDispatch();
  const serviceTypesList = useTypedSelector(
    (state) => state.ConfigReducer.ConfigNotificationsReducer.serviceTypesList,
  );
  const selectedServiceType = useTypedSelector(
    (state) =>
      state.ConfigReducer.ConfigNotificationsReducer.selectedServiceType,
  );
  const stagesList = useTypedSelector(
    (state) => state.ConfigReducer.ConfigNotificationsReducer.stagesList,
  );
  const selectedStage = useTypedSelector(
    (state) => state.ConfigReducer.ConfigNotificationsReducer.selectedStage,
  );
  const [localStagesList, setLocalStagesList] = useState<NotificationsStage[]>(
    [],
  );

  useEffect(() => {
    return () => {
      dispatch(setCommunicationsAC(null));
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(getEmailTemplatesTC());
  }, [dispatch]);
  useEffect(() => {
    if (stagesList) {
      const stagesNames = stagesList[
        selectedServiceType as keyof StagesListType
      ].map((stage) => stage);
      setLocalStagesList(stagesNames);
    }
  }, [selectedServiceType, stagesList]);
  const handleServiceTypeChange = (e: {target: {value: string}}) => {
    if (stagesList) {
      dispatch(setCommunicationsAC([]));
      const defaultStageIdInSelectedService =
        stagesList[e.target.value as keyof StagesListType][0].id;
      dispatch(setSelectedStageAC(defaultStageIdInSelectedService));
    }
    dispatch(setSelectedServiceTypeAC(e.target.value));
  };
  useEffect(() => {
    if (stagesList) {
      dispatch(
        setSelectedStageAC(
          stagesList[selectedServiceType as keyof StagesListType][0].id,
        ),
      );
      dispatch(
        getCommunicationsTC(
          stagesList[selectedServiceType as keyof StagesListType][0].id,
        ),
      );
    }
  }, [dispatch, selectedServiceType, stagesList]);
  const handleStageChange = (e: {target: {value: number}}) => {
    dispatch(setCommunicationsAC([]));
    dispatch(setSelectedStageAC(e.target.value));
    dispatch(getCommunicationsTC(e.target.value));
  };
  useEffect(() => {
    dispatch(getDealStagesTC());
  }, [dispatch]);
  return (
    <>
      <ConfigNotificationsBox>
        <MainSelectsBox>
          <Box
            sx={(theme) => ({
              [theme.breakpoints.down("sm")]: {
                marginBottom: "15px",
              },
            })}
          >
            <DeededLabel
              title="Service Type:"
              sx={(theme) => ({
                [theme.breakpoints.down("sm")]: {
                  "& p": {
                    fontSize: "16px",
                    lineHeight: "20px",
                    letterSpacing: "-0.02em",
                    marginBottom: "12px",
                  },
                },
              })}
            />
            <ServiceTypeSelect
              width={250}
              value={selectedServiceType}
              onChange={handleServiceTypeChange as (e?: unknown) => void}
            >
              {serviceTypesList.map((serviceType, index) => (
                <MenuItem value={serviceType} key={index}>
                  {serviceType}
                </MenuItem>
              ))}
            </ServiceTypeSelect>
          </Box>
          {stagesList && (
            <Box
              sx={(theme) => ({
                marginLeft: "20px",
                [theme.breakpoints.down("sm")]: {
                  margin: 0,
                },
              })}
            >
              <DeededLabel
                title="Stage:"
                sx={(theme) => ({
                  [theme.breakpoints.down("sm")]: {
                    "& p": {
                      fontSize: "16px",
                      lineHeight: "20px",
                      letterSpacing: "-0.02em",
                      marginBottom: "12px",
                    },
                  },
                })}
              />
              <ServiceTypeSelect
                width={250}
                value={selectedStage}
                onChange={handleStageChange as (e?: unknown) => void}
              >
                {localStagesList.map((stage, index) => (
                  <MenuItem key={index} value={stage.id}>
                    {stage.name}
                  </MenuItem>
                ))}
              </ServiceTypeSelect>
            </Box>
          )}
        </MainSelectsBox>
        <CommunicationsBox>
          <ConfigNotificationsCommunications />
          <Box
            sx={(theme) => ({
              marginTop: "50px",
              [theme.breakpoints.down("sm")]: {
                flexDirection: "column",
                marginTop: "24px",
              },
            })}
          >
            <ShortCodeDescriptions />
          </Box>
        </CommunicationsBox>
      </ConfigNotificationsBox>
    </>
  );
};
const CommunicationsBox = styled(Box)(({theme}) => ({
  display: "flex",
  justifyContent: "space-between",
  marginTop: "18px",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    marginTop: "24px",
  },
}));
const ServiceTypeSelect = styled(DeededSelectV2Transparent)(({theme}) => ({
  [theme.breakpoints.down("sm")]: {
    width: "100% !important",
    background: `${constants.colors.white} !important`,
  },
}));
const MainSelectsBox = styled(Box)(({theme}) => ({
  display: "flex",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));
const ConfigNotificationsBox = styled(Box)(({theme}) => ({
  width: "100%",
  minHeight: "100%",
  background: "white",
  padding: "34px 24px",
  [theme.breakpoints.down("sm")]: {
    padding: 0,
    backgroundColor: "transparent",
  },
}));
export default ConfigNotifications;
