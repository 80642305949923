import React, {useState} from "react";
import moment from "moment/moment";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import constants from "styles/constants";
import {CalendarAppointment} from "types/calendarAppointmentType";
import useTypedSelector from "utils/hooks/useTypedSelector";
import {
  useDisplayDateString,
  useIncomingAppointmentFormData,
} from "components/Dashboard/SigningAppointmentFormSection/appointmentHooks";
import {AppointmentNullable} from "components/Dashboard/SigningAppointmentFormSection/types";
import CalendarDealInfoSigningAppointmentAccordionArrowSvg from "components/Svg/CalendarDealInfoSigningAppointmentAccordionArrowSvg";
import CalendarDealInfoCalendarSvg from "components/Svg/CalendarDealInfoCalendarSvg";
import CalendarDealInfoSigningAppointmentClockSvg from "components/Svg/CalendarDealInfoSigningAppointmentClockSvg";
import {timezones} from "components/Dashboard/DealDetails/utils";

interface SigningAppointmentAccordion {
  defaultExpanded?: boolean;
  appointment?: CalendarAppointment;
}

const SigningAppointmentAccordion: React.FC<SigningAppointmentAccordion> = ({
  defaultExpanded,
  appointment,
}) => {
  const {
    incomingAppointmentProvince,
    incomingAppointmentStartTime,
    incomingAppointmentEndTime,
    incomingAppointmentDate,
  } = useIncomingAppointmentFormData(
    appointment as unknown as AppointmentNullable,
  );
  const theme = useTheme();
  const currentUser = useTypedSelector(
    (state) => state.AuthReducer.currentUser,
  );

  const displayDateString = useDisplayDateString(
    incomingAppointmentDate,
    incomingAppointmentStartTime,
    incomingAppointmentProvince,
    //@ts-ignore
    timezones.timezoneToState[currentUser?.timezone as string],
  );
  const createdBy =
    appointment?.appointment_updates[
      appointment?.appointment_updates?.length - 1
    ]?.appointment_updated_by.full_name;
  const updatedAtDate = moment(
    appointment?.appointment_updates[
      appointment?.appointment_updates?.length - 1
    ]?.appointment_updated_at,
    "YYYY-MM-DD HH:mm:ss",
  ).format("MM/DD/YYYY");
  const updatedAtTime = moment(
    appointment?.appointment_updates[
      appointment?.appointment_updates?.length - 1
    ]?.appointment_updated_at,
    "YYYY-MM-DD HH:mm:ss",
  ).format("hh:mm:ss A");
  const mobileSigner = appointment?.signer?.full_name;
  const [
    isHintForUserWhoUpdatedAppointment,
    setIsHintForUserWhoUpdatedAppointment,
  ] = useState(false);
  return (
    <SigningAppointmentAccordionStyled defaultExpanded={defaultExpanded}>
      <AccordionSummary
        sx={{borderRadius: "10px"}}
        expandIcon={<CalendarDealInfoSigningAppointmentAccordionArrowSvg />}
      >
        <Box
          sx={{
            marginLeft: "-3px",
            svg: {
              stroke: constants.colors.red,
            },
          }}
        >
          <CalendarDealInfoCalendarSvg />
        </Box>

        <Typography
          data-testid="deal-details-popup-appointment-date"
          sx={{
            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "14px",
            lineHeight: "150%",
            color: constants.colors.title,
            marginLeft: "8px",
            marginTop: "4px",
            [theme.breakpoints.down("sm")]: {
              fontSize: "10px",
            },
          }}
        >
          {" "}
          {moment(appointment?.datetime, "YYYY-MM-DD HH:mm:ss").format(
            "MM/DD/YYYY",
          )}
        </Typography>
        <Box
          sx={{
            marginLeft: "26px",
            [theme.breakpoints.down("sm")]: {
              marginLeft: "10px",
            },
          }}
        >
          <CalendarDealInfoSigningAppointmentClockSvg />
        </Box>

        <Typography
          data-testid="deal-details-popup-appointment-time"
          sx={{
            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "14px",
            lineHeight: "150%",
            color: constants.colors.title,
            marginLeft: "8px",
            marginTop: "4px",
            flex: 1,
            [theme.breakpoints.down("sm")]: {
              fontSize: "10px",
            },
          }}
        >
          {" "}
          {moment(appointment?.datetime, "YYYY-MM-DD HH:mm:ss").format(
            "hh:mm A",
          )}{" - "}
          {moment(appointment?.end_at, "YYYY-MM-DD HH:mm:ss").format(
            "hh:mm A",
          )}{" "}
          ({appointment?.timezone})
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {
          //TODO: Add province for this
        }
        <Typography
          data-testid="deal-details-popup-appointment-timezone-details"
          sx={{
            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "14px",
            lineHeight: "150%",
            marginLeft: "-3px",
            [theme.breakpoints.down("sm")]: {
              fontSize: "10px",
            },
          }}
        >
          Your local time for chosen province {appointment?.timezone}, will be:
        </Typography>
        <Typography
          data-testid="deal-details-popup-appointment-local-time-details"
          sx={{
            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "150%",
            marginLeft: "-3px",
            [theme.breakpoints.down("sm")]: {
              fontSize: "10px",
            },
          }}
        >
          {displayDateString}
        </Typography>
        <Box
          sx={{
            background: "#FAFAFA",
            borderRadius: "6px",
            width: "416px",
            height: "49px",
            marginLeft: "-3px",
            marginTop: "15px",
            display: "flex",
            padding: "17px 15px 0px 15px",
            [theme.breakpoints.down("sm")]: {
              width: "100%",
            },
          }}
        >
          {
            //TODO: Add the last signing appointment update name
          }
          <Box
            sx={{
              position: "relative",
              flex: 1,
            }}
          >
            {isHintForUserWhoUpdatedAppointment && (
              <>
                <Box
                  sx={{
                    position: "absolute",
                    background: "#C4C4C4",
                    borderRadius: "5px",
                    top: "-40px",
                    left: "50px",
                    padding: "10px 12px 9px 10px",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Montserrat",
                      fontStyle: "normal",
                      fontWeight: "normal",
                      fontSize: "14px",
                      lineHeight: "17px",
                      display: "flex",
                      alignItems: "center",
                      color: "white",
                      [theme.breakpoints.down("sm")]: {
                        fontSize: "8px",
                      },
                    }}
                  >
                    {createdBy}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    position: "absolute",
                    width: "10px",
                    height: "10px",
                    background: "#C4C4C4",
                    top: "-10px",
                    left: "80px",
                    transform: "rotate(45deg)",
                  }}
                ></Box>
              </>
            )}
            <Typography
              onMouseEnter={() => {
                setIsHintForUserWhoUpdatedAppointment(true);
              }}
              onMouseLeave={() => {
                setIsHintForUserWhoUpdatedAppointment(false);
              }}
              sx={{
                fontFamily: "Montserrat",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "14px",
                lineHeight: "17px",
                color: constants.colors.deededGray,
                maxWidth: "140px",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                [theme.breakpoints.down("sm")]: {
                  fontSize: "10px",
                  maxWidth: "100px",
                },
              }}
            >
              Updated by {createdBy}
            </Typography>
          </Box>
          {
            //TODO: Add the last signing appointment update date
          }
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: "14px",
              lineHeight: "17px",
              color: constants.colors.deededGray,
              [theme.breakpoints.down("sm")]: {
                fontSize: "10px",
              },
            }}
          >
            {updatedAtDate}
          </Typography>
          {
            //TODO: Add the last signing appointment update time
          }
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: "14px",
              lineHeight: "17px",
              display: "flex",
              alignItems: "center",
              textAlign: "right",
              color: "#BDBDBD",
              marginLeft: "9px",
              marginTop: "-15px",
              [theme.breakpoints.down("sm")]: {
                fontSize: "10px",
              },
            }}
          >
            {updatedAtTime}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            marginLeft: "-3px",
            marginTop: "19px",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "14px",
              lineHeight: "17px",
              display: "flex",
              alignItems: "center",
            }}
          >
            Mobile Signer:{" "}
          </Typography>
          <Typography
            data-testid="deal-details-popup-appointment-mobile-signer"
            sx={{
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontSize: "14px",
              lineHeight: "17px",
              fontWeight: "600",
              display: "flex",
              alignItems: "center",
              color: constants.colors.deededGray,
              marginLeft: "6px",
            }}
          >
            {mobileSigner}
          </Typography>
        </Box>
      </AccordionDetails>
    </SigningAppointmentAccordionStyled>
  );
};

const SigningAppointmentAccordionStyled = styled(Accordion)({
  boxShadow: "none",
  border: `1px solid #f2f2f2`,

  "&.MuiPaper-root": {
    borderRadius: "5px",
  },
  "& .MuiAccordionSummary-expandIconWrapper": {
    marginTop: "-5px",
    marginRight: "10px",
  },
  "&:before": {
    display: "none",
  },
  "& .MuiPaper-root.MuiButtonBase-root": {
    minHeight: "56px ",
  },
  "& .MuiAccordionSummary-root": {
    borderRadius: "10px",
    minHeight: "56px !important",
  },
  "& .MuiAccordionSummary-root.Mui-expanded": {
    minHeight: "56px",
    "& .MuiAccordionSummary-content": {
      margin: 0,
    },
  },
  "&.MuiPaper-root .MuiAccordion-root .MuiAccordionSummary-root": {
    minHeight: "56px",
    borderRadius: "10px",
  },
});

export default SigningAppointmentAccordion;
