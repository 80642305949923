import { DealType } from "./deal";
import { UserParticipantBase } from "./user";

export interface QuoteResult {
    quote: string;
    additional: { name: string; cost: string, note?: string, description?: string }[];
    inclusions: string[];
    lead_id: number;
    uuid: string;
    deal_type: DealType;
    deal_sub_type?: string, 
    province: 'ON' | 'AB' | 'BC';
    contact: UserParticipantBase;
    input: {
        mortgage?: number,
        price?: number,
        is_Toronto?: boolean, 
        is_first_time_homebuyer?: boolean,
        has_mortgage?: boolean
    }
}

export enum QuoteTypes {
    Purchase = "Purchase",
    Mortgage = "Mortgage",
    Sale = "Sale",
    TitleTrn = "TitleTrn",
    ILA = "ILA",
};