import React from "react";
import {Box, Chip, MenuItem, SxProps, Theme} from "@mui/material";
import DeededSelectV2Transparent from "./DeededSelect";
import {styled} from "@mui/material/styles";
import DeededCloseChipIcon from "../../components/Svg/DeededCloseChipIcon";
import constants from "../../styles/constants";
import ClipboardCopySuccessIcon from "../../components/Svg/ClipboardCopySuccessIcon";

interface DeededMultipleClosableSelectProps {
  error?: string;
  open?: boolean;
  onClose: () => void;
  handleOpen: () => void;
  name: string;
  handleChange: (target: unknown) => unknown;
  value: string[];
  menuList: string[];
  sx?: SxProps<Theme>;
  hideError?: boolean;
  disabled?: boolean;
}
const DeededMultipleClosableSelect: React.FC<
  DeededMultipleClosableSelectProps
> = ({
  error,
  open,
  onClose,
  handleOpen,
  handleChange,
  name,
  value,
  menuList,
  sx,
  hideError,
  disabled,
  ...props
}) => {
  const ref = React.useRef({x: 0, y: 0});
  const handleChangeItems = (item: string) => {
    if (value.includes(item)) {
      handleChange({
        target: {
          name: name,
          value: value.filter((selectedProvince) => selectedProvince !== item),
        },
      });
    } else {
      handleChange({
        target: {
          name: name,
          value: value.concat(item),
        },
      });
    }
  };

  return (
    <DeededMultipleClosableSelector
      {...props}
      sx={{
        "& .MuiSelect-select": {
          paddingBottom: "3px",
          "&:not(.Mui-disabled) > div": {
            div: {
              transition: constants.transitions,
              opacity: 0.9,
              "&:hover": {
                opacity: "1",
                transition: constants.transitions,
              },
            },
          },
          "&.Mui-disabled": {
            "& div": {
              "&:hover": {
                cursor: "no-drop",
                "div, svg": {
                  cursor: "no-drop",
                },
              },
            },
          },
        },
        ...sx,
      }}
      data-selenium-test="DeededMultipleClosableSelect"
      multiple
      disabled={disabled}
      error={error}
      hideError={hideError}
      native={false}
      width="100%"
      open={open}
      onClose={onClose}
      onOpen={() => {
        let elemets = document.elementsFromPoint(ref.current.x, ref.current.y);
        let element = elemets.find((x) => x.classList.contains("test"));
        if (!element) {
          handleOpen();
        }
      }}
      onMouseMove={(e) => {
        ref.current.x = e.clientX;
        ref.current.y = e.clientY;
      }}
      renderValue={(selected: never[]) => {
        return (
          <Box sx={{display: "flex", flexWrap: "wrap", gap: 0.5}}>
            {selected.map((selectedValue) => (
              <MultipleSelectChip
                selectedValue={selectedValue}
                className="test"
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onMouseDown={(event) => {
                  event.stopPropagation();
                }}
                onDelete={(e) => {
                  e.stopPropagation();
                  handleChange({
                    target: {
                      name: name,
                      value: value.filter((x) => x !== selectedValue),
                    },
                  });
                }}
                key={selectedValue}
                label={selectedValue}
                deleteIcon={
                  <DeededCloseChipIcon
                    color={
                      selectedValue === "ALL"
                        ? constants.colors.title
                        : constants.colors.white
                    }
                  />
                }
              />
            ))}
          </Box>
        );
      }}
      value={value}
    >
      {menuList.map((menuItem, index) => (
        <MenuItem
          onClick={() => {
            handleChangeItems(menuItem);
          }}
          value={menuItem}
          key={index}
          sx={{
            "&": {
              display: "flex",
              justifyContent: "space-between",
              svg: {
                opacity: 0,
                visibility: "hidden",
                transition: constants.transitions,
              },
            },
            "& .itemMenu": {
              borderRadius: "3px",
              width: "49px",
              height: "24px",
              textAlign: "center",
              color:
                menuItem === "ALL"
                  ? constants.colors.title
                  : constants.colors.white,
              backgroundColor:
                menuItem === "ALL"
                  ? constants.colors.theAllChip
                  : menuItem === "AB"
                  ? constants.colors.green
                  : menuItem === "BC"
                  ? constants.colors.theBcChip
                  : menuItem === "ON"
                  ? constants.colors.theOnChip
                  : constants.colors.othersChips,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            },
            "&.Mui-selected": {
              svg: {
                opacity: 1,
                visibility: "visible",
                transition: constants.transitions,
              },
            },
          }}
        >
          <Box className="itemMenu">{menuItem}</Box>
          <ClipboardCopySuccessIcon />
        </MenuItem>
      ))}
    </DeededMultipleClosableSelector>
  );
};
const DeededMultipleClosableSelector = styled(DeededSelectV2Transparent)({
  height: "auto",
  minHeight: "44px",
  "& fieldset": {
    height: "auto",
    minHeight: "44px",
  },
});
const MultipleSelectChip = styled(Chip)<{selectedValue: string}>(
  ({selectedValue}) => ({
    "&.MuiButtonBase-root": {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "12px",
      height: "30px",
      borderRadius: "3px",
      color:
        selectedValue === "ALL"
          ? constants.colors.title
          : constants.colors.white,
      marginTop: "3px",
      backgroundColor:
        selectedValue === "ALL"
          ? constants.colors.theAllChip
          : selectedValue === "AB"
          ? constants.colors.green
          : selectedValue === "BC"
          ? constants.colors.theBcChip
          : selectedValue === "ON"
          ? constants.colors.theOnChip
          : constants.colors.othersChips,
    },
    "& .MuiSvgIcon-root": {
      width: "15px",
      height: "15px",
      marginLeft: "5px",
    },
  }),
);
export default DeededMultipleClosableSelect;
