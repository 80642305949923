import { Stack } from "@mui/material";
import Colors from "components/CommonDashboard/Styling/Colors";
import { FONT_FAMILY, BODY_SMALL } from "components/CommonDashboard/Styling/Styles";

const TermsAndConditionsDashboard = () =>
    <Stack data-testid="terms-and-conditions-container" sx={{
        padding: '2.4rem',
        backgroundColor: Colors.WHITE,
        borderRadius: '2.4rem',
        '& ul': {
            marginLeft: '2rem'
        },
        '& p': { marginBottom: '2rem', ...BODY_SMALL },
        '& h2, ul': {
            marginBottom: '2rem',
            fontFamily: FONT_FAMILY,
        },
        '& li': {
            marginBottom: '1rem'
        }
    }}>
        <p>
            Please read the terms carefully before accessing or using
            the&nbsp;app.Deeded.ca or&nbsp;deeded.ca&nbsp;website (the “
            <strong>Platform</strong>”) and their related transactional pages and
            services (collectively, the “<strong>Services</strong>”) and logging in to
            your Deeded account.
        </p>
        <p>
            Deeded Inc. (“Deeded”, “we”, “us” or “our”) is a company that provides a
            Platform for the delivery of tech-enabled real estate and mortgage closing.
        </p>
        <p>
            <span style={{ color: "#ff0000" }}>
                <strong>
                    Deeded Inc. is not a law firm and does not provide legal
                    services.&nbsp;All legal advice and legal services received by users
                    through the Platform is provided by lawyers or law firms who operate
                    independently of Deeded Inc., as detailed further below.
                </strong>
            </span>
        </p>
        <p>
            By accessing or using our “<strong>Platform</strong>” or by being provided
            with any or our “<strong>Services</strong>” and logging in to your Deeded
            account, you agree to be bound by these terms of use (the “
            <strong>Terms of Use</strong>”). You should also read our Privacy Policy,
            which is incorporated by reference into these Terms of Use.
        </p>
        <p>
            You will be asked to accept these Terms of Use as part of your account
            registration on the Platform. Thus, if you do not wish to be bound by these
            Terms of Use, please do not access or use the Platform.
        </p>
        <p>
            These Terms of Use explain your obligations and responsibilities and
            Deeded’s rights in connection with your access and use of the Platform and
            the Services.
        </p>
        <p>
            Deeded may update or revise these Terms of Use from time to time and if your
            rights change, we will always notify you. If you do not agree to, or cannot
            comply with, the Terms of Use as revised, your sole recourse is to terminate
            your use of the Platform.
        </p>
        <p>
            You will be deemed to have accepted the Terms of Use as amended if you
            continue to use the Platform and/or purchase our Services after any
            amendments are made.
        </p>
        <h2>
            <strong>Definitions</strong>
        </h2>
        <p>
            In&nbsp;Deeded’s&nbsp;Terms of Use and Privacy Policy, the term “
            <strong>User</strong>” refers to the person(s) seeking to close or settle a
            residential real property or mortgage transaction. The term “
            <strong>Legal Service Provider</strong>” refers to third party real estate
            lawyers or real estate law firms who operate independently of Deeded; the
            term “<strong>Business Partners</strong>” refers to other third parties such
            as lenders, real estate agents, mortgage agents, and other parties who may
            be leveraging the Platform; and the term “<strong>Transaction</strong>”
            (when capitalized) means a real property or mortgage closing/settlement
            transaction
        </p>
        <p>
            created and managed in the Platform by the User, Legal Service Provider or
            one or more Business Partners in order to facilitate communications, access,
            and use the Services.
        </p>
        <p>
            For greater clarity, in these Terms of Use and&nbsp;Deeded’s&nbsp;Privacy
            Policy, the term “<strong>Services</strong>” (when capitalized) refers to
            those offered and provided by Deeded, that is, the Platform and its features
            and functionalities and the professional, assistance and support services
            provided by Deeded itself, and does not refer to any services provided by
            Legal Service Providers or Business Partners.
        </p>
        <p>
            <strong>Deeded does not provide legal services or legal advice.</strong>
            &nbsp;Deeded may, however, act as an intermediary between Platform users and
            Legal Service Providers, as well as other Business Partners. At no time does
            Deeded draw legal conclusions; provide legal advice, opinions or
            recommendations about your legal rights, remedies, defences, options,
            selection or completion of forms, or strategies; or apply the law to the
            facts of your particular situation.
        </p>
        <p>
            To facilitate a Transaction, the User is required to review and
            electronically accept a separate engagement agreement with a Legal Service
            Provider operating independently of Deeded. When you decide to engage a
            Legal Service Provider to obtain Services, you will create a contractual
            relationship directly with the Legal Service Provider. You and the Legal
            Service Provider have complete discretion regarding whether to enter into an
            engagement agreement; with regard to the terms of any such agreement; and
            with regard to the provision of legal services pursuant to such agreement.
            Such separate engagement agreement is provided by the Legal Service Provider
            and is not subject to review or approval by Deeded. Deeded is not a party to
            the lawyer-client relationship you will create with a Legal Service
            Provider. Such separate engagement agreement is an agreement entered into by
            the User and the Legal Service Provider to establish a lawyer-client
            relationship and confirm the User and Legal Service Provider’s associated
            rights and duties. We highly encourage all Users to read such separate
            engagement agreement, and to make inquiries of the proposed Legal Service
            Provider as appropriate to ensure that you understand it, prior to accepting
            it.
        </p>
        <p>
            Other than making the Platform available, Deeded does not itself provide any
            services or products directly to Users. In order to facilitate a better
            experience for the User and Legal Service Provider, Deeded may provide the
            following services or assistance directly to the Legal Service Provider, in
            exchange for service fees from the Legal Service Provider:
        </p>
        <ul>
            <li>Administrative support;</li>
            <li>Clerical support;</li>
            <li>Technical support;</li>
            <li>Scheduling support;</li>
            <li>Access to certain software and/or databases;</li>
            <li>Customer service support; and</li>
            <li>Marketing support.</li>
        </ul>
        <h2>
            <strong>Disclaimer of Warranty and Limitation of Liability</strong>
        </h2>
        <p>
            <strong>
                You acknowledge, agree, and understand that Deeded is not a party to the
                lawyer- client relationship or any legal dealings directly between Users
                and Legal Service Providers. Without limitation, Users are solely
                responsible for:
            </strong>
            &nbsp;(a) determining the suitability of a Legal Service Provider (such as
            any interviews, vetting, background checks, or similar actions); (b)
            reviewing, negotiating, agreeing to, and executing any terms or conditions
            of an engagement agreement with a Legal Service Provider;
        </p>
        <p>
            (c) paying for any services provided by a Legal Service Provider; (d)
            providing complete and accurate information to a Legal Services Provider;
            and (e) making inquiries as needed to satisfy themselves that the legal
            services provided are appropriate and of good quality.
        </p>
        <p>
            You further acknowledge, agree, and understand that Users are solely
            responsible for assessing whether to enter into an engagement agreement with
            a Legal Service Provider and for verifying any information about such Legal
            Service Provider.
        </p>
        <p>
            Legal Services Providers are solely responsible for ensuring the accuracy
            and legality of any content provided by a Legal Service Provider and for
            performing or providing any legal services for Users.
        </p>
        <p>
            Deeded: (a) does not make any representations about or guarantee the truth,
            quality, efficacy or accuracy of a Legal Service Provider’s listings or
            other content on the Platform; (b) does not verify any feedback or
            information provided by Users of the Platform about the Legal Service
            Providers; and (c) does not vet or otherwise perform background checks on
            the Legal Service Providers other than to
        </p>
        <p>
            confirm that a Legal Service Provider is a duly licenced lawyer in good
            standing within the jurisdiction in which they provide legal services.
        </p>
        <p>
            You acknowledge, agree, and understand that Deeded does not in any way
            supervise, direct, control, or evaluate the Legal Service Providers or their
            work and is not responsible for the Legal Service Provider’s work product.
            Deeded makes no representations about and does not guarantee, and you agree
            not to hold the Company responsible for: (a) the quality, safety, or
            legality of the Legal Service Providers’ services; (b) the qualifications,
            background, or identities of Users or Legal Service Providers who use our
            Platform; (c) the ability of the Legal Service Providers to deliver
            services; (d) the ability of Users to pay for any services; (e) User
            Submissions, statements or posts made by Users; or (f) the ability or
            willingness of a User or Legal Service Provider to actually complete a
            Transaction.
        </p>
        <p>
            You also acknowledge, agree, and understand that the Legal Service Providers
            operate independently of Deeded, and have the sole right to determine, and
            are solely responsible for determining: (a) which legal service requests to
            accept from Users; (b) the time, place, manner, and means of providing any
            legal services to Users; and (c) the type(s) of services to provide to
            Users. Prices for the services of Legal Service Providers who use Deeded’s
            Platform can be found at deeded.ca/#pricing, as may be amended from time to
            time without notice to you. Any additional services, fees, disbursements or
            surcharges, if applicable, are at the sole discretion of the Legal Service
            Provider.
        </p>
        <h2>
            <strong>Termination of the Platform and/or Services</strong>
        </h2>
        <p>
            Deeded reserves the right to suspend, and/or terminate, at any time, without
            warning, full or partial access to the&nbsp;Platform, including your account
            or any or all Transaction(s) you have created on the Platform, as well as
            our Services. We additionally reserve the right to discontinue uploading
            information to such sites, accounts and documents, in which case we shall
            not be held responsible for any damages that you may incur. All information
            on the&nbsp;deeded.ca&nbsp;website, Platform, application, and related
            publications is subject to change without prior notice.
        </p>
        <h2>
            <strong>Hyperlinks and third-party Legal Service Providers</strong>
        </h2>
        <p>
            The&nbsp;Platform&nbsp;and our website contains hyperlinks that may direct
            you to the websites of our business partners, Legal Service Providers, or
            third parties.
        </p>
        <p>
            Such third-party websites are not under Deeded’s control, and we are not
            responsible for the contents of any such website or any link contained in
            such websites. The
        </p>
        <p>
            third-party links included on the Platform are provided for your
            convenience, and the inclusion of such links does not imply a recommendation
            or endorsement by us of any such website or the products or services offered
            therein. If you decide to access any of the third-party websites, you do so
            entirely at your own risk. You acknowledge and agree that we shall not be
            responsible or liable, directly or indirectly, for any damage or loss caused
            or alleged to be caused by, or in connection with the use of or reliance on
            any such third-party website.
        </p>
        <p>
            Any third-party content, data or publications made available through the
            Platform are furnished by us on an as-is basis for your convenience and
            information. Any opinions, advice, statements, services, offers, or other
            information made available by third parties, including Legal Service
            Providers, are those of the respective author(s) or and not of us. DEEDED
            DISCLAIMS ANY WARRANTY OR REPRESENTATION, EITHER EXPRESS OR IMPLIED, THAT
            THE INFORMATION IN SUCH PUBLICATIONS IS ACCURATE OR COMPLETE.
        </p>
        <h2>
            <strong>Your Use of the Platform</strong>
        </h2>
        <p>
            Unless you have entered into a distinct written agreement with Deeded to
            that effect,
        </p>
        <p>
            (i) the Platform and the Services are made available to you and provided
            exclusively for your personal and non-commercial use, (ii) you cannot resell
            the Services or the use of the Platform. You cannot access and use the
            Services and the Platform in a manner that competes with Deeded’s
            activities.
        </p>
        <p>
            You are prohibited from and agree not to submit to
            the&nbsp;Platform&nbsp;any information that is inappropriate, obscene, or
            fraudulent. You are also prohibited, and you agree to refrain from
            transmitting or submitting to the Platform any information that you know to
            be outdated, false or misleading. You are prohibited from and you agree not
            to engage in any activity related to the use of the&nbsp;Platform&nbsp;that
            constitutes a criminal offence, unlawful action or violation of
            Deeded’s&nbsp;Terms of Use and Privacy Policy. You are solely responsible
            for any document or information that you submit to the Platform.
        </p>
        <p>
            Deeded will automatically and without notice suspend your access to the
            Platform and to its services if Deeded has reasonable grounds to believe
            that your use of the Platform constitutes or is related to any criminal
            activity, unlawful act, violation of&nbsp;Deeded’s&nbsp;Terms of Use and
            Privacy Policy, or attempt to do the foregoing.
        </p>
        <p>
            You must not use on the Platform any form of destructive software such as
            viruses, worms, Trojan horses, time bombs, cancelbots or other harmful
            components, files, programs or computer codes designed to interrupt, destroy
            or limit the functioning
        </p>
        <p>
            of the Platform. You may not attempt to gain unauthorized access to the
            Platform, an Estate, other computer systems or networks connected to the
            Platform by password extraction or other means; interfere with or disrupt
            networks or servers connected to the Platform or violate the regulations,
            policies or procedures of such networks; and use, download or otherwise
            copy, or provide to a person or entity, the user directory or other user
            information.
        </p>
        <p>
            To use the Platform, you must register and create a personal user account.
            You will need to submit to Deeded personal information such as your name,
            personal address, your email address, property address and phone number.
            Your account information must be true, accurate, complete and up-to-date at
            all times. Otherwise, your access to your account may be suspended or
            terminated automatically and without notice. For greater clarity, such
            suspension or termination would affect your access to the Platform, but
            would not terminate any lawyer-client relationship created with a Legal
            Service Provider.
        </p>
        <p>
            In several cases and where legally required, you may be required to prove
            your identify. Deeded may use several third-party tools and methods to
            protect our Platform, as well as Legal Service Providers and Business
            Partners who use our Platform, from potential fraud. As such, you may be
            subject to submitting your government identification, providing additional
            information such as photos to validate your identification.
        </p>
        <p>
            Other personal information about you may have to be submitted to the
            Platform by you or other users in order to facilitate a Transaction. Such
            personal information may include marital status, age, driver license,
            purchase contracts, agreements related to the Transaction, contact
            information, telephone number, email address, social insurance number. We
            refer you to our privacy policy. Any personal information you provide us
            will be processed in accordance with our&nbsp;Privacy Policy.
        </p>
        <p>
            You understand and acknowledge that Deeded may share some of your
            information with the Legal Service Provider, in order for the Legal Service
            Provider to complete the Transaction. Such information shall also be
            retained by the Legal Service Provider in accordance with their professional
            or regulatory obligations regarding record keeping or other legal
            obligations, as applicable.
        </p>
        <p>
            You understand and agree that you are responsible for maintaining the
            confidentiality of the passwords associated with your account. You agree
            that you will be solely responsible for activities that occur under your
            account. If you become
        </p>
        <p>
            aware of any unauthorized access or activity, whether criminal or illegal,
            you agree to notify Deeded immediately at the following e-mail
            address:&nbsp;<a href="mailto:hello@deeded.ca">hello@deeded.ca</a>
        </p>
        <h2>
            <strong>Business Partner Terms</strong>
        </h2>
        <p>
            Business Partners can be Lenders, Real Estate Agents, Mortgage Agents, and
            other parties, either individuals, or businesses, who may leverage the
            Platform to assist a User in completing their Transaction.
        </p>
        <p>
            Business Partners have the right to “invite” Users such as home buyers,
            sellers and borrowers to access a specific Transaction on the Platform. As
            such, Business Partners may provide us with personal information (as such
            term is defined in our Privacy Policy) or other information relating to a
            User.
        </p>
        <p>
            Business Partners are solely responsible for obtaining the User’s written
            consent to share such information in accordance with applicable privacy laws
            and any professional obligations. Deeded&nbsp;shall not be held responsible
            for any damages that&nbsp;Business Partners&nbsp;or other people may incur
            when granting access to a Transaction on the Platform.
        </p>
        <p>
            When inviting a user to a specific Transaction on our Platform,
            a&nbsp;Business Partner may receive some of the following information:
        </p>
        <ul>
            <li>A Client’s personal information (as defined in our Privacy Policy);</li>
            <li>
                The nature of the services provided by a Legal Service Provider to a
                Client;
            </li>
            <li>
                The status of any services provided by a Legal Service Provider to a
                Client;
            </li>
            <li>
                The status of the closing/settlement of a Client’s purchase, sale or
                refinancing of real property;
            </li>
            <li>
                Particulars of any services provided by a Legal Service Provider to a
                Client including but not limited to:
            </li>
            <li>Address of real property purchased, sold or refinanced by a Client;</li>
            <li>
                Whether any mortgage funds or closing funds have been received by a Legal
                Service Provider with respect to a Client’s purchase, sale or refinancing
                of real property;
            </li>
            <li>
                Closing date pertaining to a Client’s purchase, sale or refinancing of
                real property;
            </li>
            <li>
                Steps taken by a Legal Service Provider with respect to a Client’s
                purchase, sale or refinancing of real property;
            </li>
            <li>
                Status as the approval of certain documents pertaining to a Client’s
                purchase, sale or refinancing of real property
            </li>
        </ul>
        <h2>
            <strong>Comments</strong>
        </h2>
        <p>
            You can post comments on the Platform about your experience with Deeded’s
            Services and express your appreciation of the Platform. Deeded may review
            your comments before or after they are posted, delay or prevent their
            publication and may delete them once posted if they are likely to cause
            injury or damage to others, or contain personal information or material that
            may be sensitive, illegal or that go against the Platform’s rules of use or
            Deeded’s recommendations. The purpose of this moderation is to enforce the
            rules of use of the Platform and to prevent abuse, but does not diminish
            your responsibility in any way. You must be the author of your comments and
            they must not violate the intellectual property rights of others. The
            comments expressed by a user of the Platform are those of that user and not
            those of Deeded.
        </p>
        <p>
            By posting comments on the Platform or on any social media accounts
            associated with Deeded, you grant Deeded a royalty-free, irrevocable,
            perpetual, worldwide and non-exclusive license to publish, reproduce and
            distribute said comments by any means of communication controlled or
            authorized by Deeded, including social media. You hereby waive any moral
            right you have on or to your published comments and you authorize Deeded to
            modify them to make them easier to understand and to identify you as the
            author of your comments. Deeded is under no obligation to publish your
            comments or keep them accessible, this decision being at our sole
            discretion.
        </p>
        <p>
            Deeded requires all users of the Platform and its Services to be respectful
            and civilized in their comments. You are prohibited from posting comments
            that are likely to offend or that may expose an individual or group of
            individuals to hatred or contempt on the basis of race, origin, color,
            religion, sex, sexual orientation, age, physical or mental disability or
            other grounds. Your comments must be of a personal, non-commercial nature.
            Deeded strongly recommends that you do not post comments containing your
            personal information.
        </p>
        <h2>
            <strong>Indemnification</strong>
        </h2>
        <p>
            You agree to indemnify and hold us harmless from all damages and causes of
            action arising from any of your actions or omissions related to your use of
            the&nbsp;Platform&nbsp;in violation of the present Terms of Use
            and/or&nbsp;Deeded’s&nbsp;Privacy Policy or applicable laws. Neither Deeded
            nor any other user, third party lawyer or other business partner assumes any
            responsibility or liability that may arise from any act or omission or
            unlawful, outdated, false or misleading content, transmission or upload by
            you or any other user.
        </p>
        <h2>
            <strong>Disclaimer Clause</strong>
        </h2>
        <p>
            You acknowledge that you are using the Platform and that you rely on it at
            your own risk. To the extent permitted by law, Deeded does not offer any
            warranty of any kind with respect to the Platform and Deeded’s Services, all
            provided “AS IS”, “WHERE IS” and “AS AVAILABLE”. THE PARTIES EXPRESSLY
            EXCLUDE ALL REPRESENTATIONS, WARRANTIES AND CONDITIONS, INCLUDING, BUT NOT
            LIMITED TO, THE IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY,
            QUALITY OF SERVICES RENDERED, NON- INFRINGEMENT AND FITNESS FOR A PARTICULAR
            PURPOSE AS WELL AS THOSE ARISING OUT OF APPLICABLE LAW OR OTHERWISE UNDER
            THE MARKET OR NORMAL COURSE OF BUSINESS. WE DO NOT WARRANT THAT THE PLATFORM
            WILL CONTINUE TO OPERATE, OPERATE WITHOUT INTERRUPTION OR BE FREE FROM
            ERROR.
        </p>
        <p>
            TO THE EXTENT PERMITTED BY LAW, YOU AGREE THAT DEEDED IS NOT RESPONSIBLE FOR
            ANY LOSS OR DAMAGE OF ANY KIND, INCLUDING, BUT NOT LIMITED TO, DIRECT OR
            PUNITIVE DAMAGES OR OTHER TYPES OF DAMAGES (INCLUDING IN PARTICULAR,
            PROPERTY DAMAGE, LOSS OF INFORMATION, PROGRAMS OR DATA, ECONOMIC LOSSES)
            ARISING FROM THE USE OF THE PLATFORM, ANY CONTENT PROVIDED, A THIRD PARTY
            WEBSITE, OR THAT ARISES FROM ANY
        </p>
        <p>
            ACTS OR OMISSIONS OF A USER, THE INABILITY TO USE OR ACCESS THE PLATFORM,
            WHETHER DEEDED HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGE OR SUCH
            LOSS, OR THAT SUCH DAMAGE OR SUCH LOSS IS REASONABLY FORESEEABLE.
        </p>
        <p>
            YOU ACKNOWLEDGE AND AGREE THAT DEEDED DOES NOT PROVIDE LEGAL SERVICES NOR
            OPINIONS, BUT MAY ACT AS AN INTERMEDIARY BETWEEN PLATFORM USERS AND
            THIRD-PARTY LAWYERS, AS WELL AS OTHER PROFESSIONALS AND BUSINESS PARTNERS,
            ACTING AS INDEPENDENT CONTRACTORS. DEEDED IS NOT RESPONSIBLE FOR ANY SERVICE
            OR OPINION PROVIDED TO YOU (VIA THE PLATFORM OR OTHERWISE) BY LEGAL SERVICE
            PROVIDERS OR ANY OTHER SUCH PROFESSIONAL ADVISORS AND DISCLAIMS ANY
            LIABILITY IN CONNECTION THEREWITH.
        </p>
        <p>
            In all cases, you acknowledge that Deeded’s total and aggregate liability
            for such damages that you may claim and that may be granted to you is
            limited to the value of our agreement with you, but this limitation on liability 
            does not extend to any legal services associated with the use of the Deeded 
            platform or system, as our legal service providers are required to maintain 
            their own professional liability insurance.
        </p>
        <h2>
            <strong>IT Security</strong>
        </h2>
        <p>
            We comply with applicable laws governing the protection of personal
            information. In addition, we use encryption techniques to protect the
            confidentiality of the information exchanged between you and our Legal
            Service Provider within transactional pages. We also protect information
            exchanged when the Platform is accessed through a non-secure platform or in
            a public location, which could endanger the security of your personal
            information or the documents or information submitted to the Platform.
            However, we shall not be held responsible for any damages that you may incur
            as a result of your access to the&nbsp;Platform&nbsp;under such
            circumstances.
        </p>
        <p>
            Telecommunication networks (including the Internet), electronic systems,
            email or telephone communications (including SMS) are neither secure nor
            confidential. They can be accessed, lost or intercepted, in much the same
            way as communications by other means (for example, by post). No electronic
            system is completely secure or error-free. We shall not be held responsible
            for any damages that you may incur from using our&nbsp;Platform&nbsp;or from
            any exchange of e-mail or electronic messages (including SMS), whether
            originating from you or from us.
        </p>
        <p>
            You alone are responsible for ensuring the protection and safekeeping of
            your data when using your computer platforms and devices.
        </p>
        <p>
            Deeded may, in its sole discretion, audit, analyze, monitor, track and
            record the activities conducted on the Platform by you and other users. This
            can include logins and logouts, documents viewed, accessed or deposited,
            pages and sections accessed and features and functionalities used. Deeded
            does so for recordkeeping purposes, to improve the Services and the features
            and functionalities of the Platform as well as to document the activities
            conducted on the Platform for law enforcement and legal proceedings
            purposes.
        </p>
        <h2>
            <strong>Trademarks and Intellectual Property</strong>
        </h2>
        <p>
            All web content (including text, illustrations, graphics, images,
            photographs, audio and video clips, software and codes available on the
            Platform (collectively, the “<strong>Content</strong>“), including the
            manner in which the Content is presented, is protected by Canadian and
            foreign laws, notably under copyright law, and belongs to Deeded. You are
            prohibited from: i) using, reproducing, modifying, adapting, translating,
            downloading or transmitting the Content, in whole or in part, or otherwise
            permit access; ii) modifying, removing or covering any trademark or property
            notice included in or on the Content or the Platform; and iii) decompiling,
            disassembling, decrypting, extracting the Platform or performing reverse
            engineering on the Platform or their components, including, but not limited
            to, software, or assist anyone in doing the foregoing.
        </p>
        <p>
            Certain names, words, titles, phrases, graphics, logos, icons or designs
            used on the&nbsp;Platform&nbsp;may be trademarks belonging to Deeded, our
            business partners, Legal Service Providers or used with the consent of third
            party owners. You agree not to remove, edit or otherwise alter trademarks,
            service marks, logos and other proprietary information contained on
            the&nbsp;Platform. Trademarks and the information contained on
            the&nbsp;Platform, including comments from users, may not be reproduced, in
            whole or in part, or used in any way without obtaining our prior written
            consent.
        </p>
        <h2>
            <strong>Social Media Platforms</strong>
        </h2>
        <p>
            Certain pages of the&nbsp;Platform&nbsp;may be accessible through the
            following social media platforms: Facebook, Instagram, Twitter, YouTube and
            LinkedIn. Before consulting or using our Platform through these social media
            platforms, you must read and comply with their respective terms of use and
            privacy policy.
        </p>
        <h2>
            <strong>Governing Law</strong>
        </h2>
        <p>
            These Terms of Use are governed by and construed in accordance with the laws
            of the Province of Ontario applicable therein, without regard to choice of
            law or
        </p>
        <p>
            conflicts of law principles. You agree that any dispute arising out of, or
            in connection with, the use of the Platform or its services is to be
            submitted to the exclusive jurisdiction of courts located in the Province of
            Ontario.
        </p>
        <h2>
            <strong>Entire Agreement</strong>
        </h2>
        <p>
            These Terms of Use constitute the entire agreement between you and Deeded
            regarding your access and use of the&nbsp;Platform, the Services and any
            other subject matter herein, and&nbsp;they replace any previous agreement,
            representation, warranty or understanding, whether verbal or written,
            express or implied, in this regard. If any portion of these Terms of Use is
            declared or held unlawful, invalid, or unenforceable, then such portion of
            these Terms of Use shall be deemed severed and shall not affect the validity
            or enforceability of the remainder of these Terms of Use.
        </p>
        <h2>
            <strong>Enurement</strong>
        </h2>
        <p>
            These Terms of Use are binding upon and shall enure to the benefit of both
            parties and their respective heirs, agents, legal representatives,
            assignees, successors (including any successor resulting from a merger or
            other statutory arrangement) and permitted assigns.
        </p>
        <h2>
            <strong>Assignment</strong>
        </h2>
        <p>
            You shall not assign or transfer your rights or obligations hereunder or
            otherwise without our prior written consent, which consent shall be at our
            sole discretion.
        </p>
        <h2>
            <strong>Information</strong>
        </h2>
        <p>
            Please send an e-mail to&nbsp;
            <a href="mailto:hello@deeded.ca">hello@deeded.ca&nbsp;</a>for any questions
            regarding the operation of the Deeded Platform.
        </p>
    </Stack>
export default TermsAndConditionsDashboard;