import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { refreshToken } from "redux/actions/auth";
import reloadAxios from "utils/axiosManager";
import { resolveJwtTokenVerified, shouldRefreshToken } from "utils/resolveJwtTokenVerified";

import storage from "utils/storage";
import useTypedSelector from "./useTypedSelector";

const intervalMilliseconds = 15 * 60 * 1000; // 15 min

const useTrackChangeUserIdUpdated = () => {
  useEffect(() => {
    const isBrowser = typeof window !== "undefined";
    if (!isBrowser) {
      return;
    }

    window.addEventListener("storage", localStorageUpdated);

    return () => {
      window.removeEventListener("storage", localStorageUpdated);
    };
  }, []);
  const localStorageUpdated = () => {
    const userId = storage.userId;
    if (userId) {
      const previousUserId = storage.previousUserId;
      if (previousUserId !== userId) {
        storage.previousUserId = userId;
        window.location.reload();
      }
    }
  };
};

const useCheckTokenExpiration = (notSessionCallback?: () => void) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const refreshCalled = useTypedSelector(
    (state) => state.AuthReducer.refreshCalled,
  );
  useTrackChangeUserIdUpdated();

  /**
   * Calls redux to fetch a new refresh token
   */
  const onRefreshToken = useCallback(() => {
    dispatch(refreshToken());
    reloadAxios(history);
  }, [dispatch, history]);

  /** If no token then redirect to login */
  useEffect(() => {
    let token = storage.token;
    if (!token) {
      if (notSessionCallback) {
        return notSessionCallback();
      }
      history.push("/login");
    }
  }, [history, notSessionCallback]);

  /** If we've got token, then we should refresh it */
  useEffect(() => {
    let token = storage.token;
    if (token) {
      onRefreshToken();
    }
  }, [onRefreshToken]);

  /**
   * If we have token but the resolved one is expired then redirect to login
   *
   * Also handle cases for tabs that are opened for 12 hours.
   * Clear token there to ensure all works.
   */
  useEffect(() => {
    if (!storage.token) {
      return;
    }

    const verifiedJwtToken = resolveJwtTokenVerified();

    if (verifiedJwtToken === "expired") {
      history.push("/login");
      storage.removeToken();
      return;
    }

    let timer = setInterval(() => {
      const shouldRefresh = shouldRefreshToken();

      if (shouldRefresh) {
        onRefreshToken();
      }
    }, intervalMilliseconds);

    return () => {
      clearInterval(timer);
    };
  }, [dispatch, history, onRefreshToken]);

  return refreshCalled;
};

export default useCheckTokenExpiration;
