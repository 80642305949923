import { FC, SyntheticEvent, useState } from "react";
import Dialog from "@mui/material/Dialog";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { Staff } from "types/Config/userManagerTypes";
import { PointTransactionType } from "types/pointTransaction";
import usePointTransactions from "components/Dashboard/UserPoints/hooks/usePointTransactions";
import Colors from "../../CommonDashboard/Styling/Colors";
import { H1, H2, H4 } from "../../Common/Typography";
import colors from "../../Common/componentStyling/Colors";
import BasicButton from "../../Common/Button/BasicButton";
import { CloseIcon, MinusIcon, PlusIcon } from "../../Common/Icons/Iconography";
import PointsList from "./PointsList";
import UpdatePointsModal from "./UpdatePointsModal";
import SwitchButton from "components/CommonDashboard/SwitchButton";
import { SubtitleRegular } from "components/CommonDashboard/Typography";

interface PointsModalProps {
    show: boolean;
    onClose: () => void;
    user: Staff | null;
}

const PointsModal: FC<PointsModalProps> = ({ show, onClose, user }) => {
    const { list, total, loading, refresh, pointsFeature, updatePointsFeature } = usePointTransactions(user?.id);
    const [showUpdateModal, setShowUpdateModal] = useState<PointTransactionType>();

    const handleCheckbox = async (e: SyntheticEvent, checked: boolean) => {
        await updatePointsFeature(checked);
    };

    if (!user?.id) return null;

    return (
        <>
            <StyledDialog open={show} onClose={onClose}>
                <ModalBody>
                    <Stack direction="row" justifyContent="space-between">
                        <H4>Points</H4>
                        <Box sx={{ cursor: "pointer" }} onClick={onClose}>
                            <CloseIcon color={Colors.BLACK} />
                        </Box>
                    </Stack>
                    <Stack direction="row" justifyContent="space-between">
                        <Stack gap="2rem" pr="2rem" overflow="hidden">
                            <H1 noWrap>{user.full_name}</H1>
                            <H2 id="user-points" sx={{ color: colors.GREEN_500 }}>
                                {loading ? "-" : total} points
                            </H2>
                        </Stack>
                        <Stack gap="2rem">
                            <BasicButton
                                onClick={() => setShowUpdateModal("add")}
                                typeOf="secondary"
                                label={{ text: "Add points", inputId: "add-points-btn" }}
                                leftIcon={<PlusIcon color={Colors.BLACK} />}
                            />
                            <BasicButton
                                onClick={() => setShowUpdateModal("use")}
                                typeOf="primary"
                                label={{ text: "Use points", inputId: "use-points-btn" }}
                                leftIcon={<MinusIcon color={Colors.WHITE} />}
                            />
                        </Stack>
                    </Stack>

                    <PointsList data={list || []} loading={loading} />

                    <Stack gap="2rem" direction="row" justifyContent="space-between">
                        <Stack direction="row" gap="1rem" alignItems="center">
                            <SubtitleRegular>Show points on profile?</SubtitleRegular>
                            <SwitchButton
                                testId="points-feature-checkbox"
                                disabled={loading}
                                checked={pointsFeature}
                                onChange={handleCheckbox}
                            />
                        </Stack>
                        <BasicButton
                            onClick={onClose}
                            typeOf="primary"
                            label={{ text: "Close", inputId: "save-points-btn" }}
                        />
                    </Stack>
                </ModalBody>
            </StyledDialog>
            <UpdatePointsModal
                show={Boolean(showUpdateModal)}
                type={showUpdateModal!}
                userId={user.id}
                onClose={() => setShowUpdateModal(undefined)}
                onSave={(total) => {
                    void refresh();
                }}
            />
        </>
    );
};

const ModalBody = styled(Stack)({
    width: "100%",
    background: Colors.WHITE,
    gap: "2rem",
});

const StyledDialog = styled(Dialog)({
    ".MuiPaper-root": {
        borderRadius: ".6rem",
        padding: "2rem",
        maxWidth: "82.4rem",
        width: "100%",
        margin: "1.2rem",
    },
});
export default PointsModal;
