import React from "react";
const BlockTimeIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M10 10.625L13.7889 8.4375"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.96875 10.5938L6.875 7.5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.625 18.9062C17.4372 18.9062 18.9062 17.4372 18.9062 15.625C18.9062 13.8128 17.4372 12.3438 15.625 12.3438C13.8128 12.3438 12.3438 13.8128 12.3438 15.625C12.3438 17.4372 13.8128 18.9062 15.625 18.9062Z"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M13.304 13.3042L17.9456 17.9458"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default BlockTimeIcon;
