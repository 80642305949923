import React from "react";

type DeededType = {
  color: string | undefined;
};
const Deeded: React.FC<DeededType> = ({color}) => {
  return (
    <svg width="136" height="25" viewBox="0 0 136 25" fill={color}>
      <path
        d="M0 0V25H9.96597C14.1309 25 17.2175 23.963 19.2256 21.889C21.2337 19.7901 22.2378 16.6668 22.2378 12.5189C22.2378 8.34603 21.2337 5.22271 19.2256 3.14877C17.2175 1.04982 14.1309 0 9.96597 0H0ZM23.8548 0V25H40.8491V20.3147H29.8792V14.8424H39.3989V10.1198H29.8792V4.68538H40.8491V0H23.8548ZM43.3521 0V25H60.3463V20.3147H49.3765V14.8424H58.8961V10.1198H49.3765V4.68538H60.3463V0H43.3521ZM62.849 0V25H72.8155C76.9803 25 80.0666 23.963 82.0745 21.889C84.0827 19.7901 85.0867 16.6668 85.0867 12.5189C85.0867 8.34603 84.0827 5.22271 82.0745 3.14877C80.0666 1.04982 76.9803 0 72.8155 0H62.849ZM113.762 0V25H123.728C127.893 25 130.98 23.963 132.988 21.889C134.996 19.7901 136 16.6668 136 12.5189C136 8.34603 134.996 5.22271 132.988 3.14877C130.98 1.04982 127.893 0 123.728 0H113.762ZM86.7045 0.105077V12.49V24.8749H98.9919H111.279V12.49V0.105077H98.9919H86.7045ZM89.5712 2.99528H94.0771H98.582V12.49V21.9852H94.0771H89.5712V12.49V2.99528ZM99.4013 2.99528H103.906H108.412V5.88489V8.77449H103.906H99.4013V5.88489V2.99528ZM6.02431 4.68538H9.25963C11.5156 4.68538 13.2135 5.32248 14.3539 6.59682C15.4943 7.84618 16.0647 9.82028 16.0647 12.5189C16.0647 15.1676 15.4943 17.1289 14.3539 18.4032C13.2135 19.6776 11.5156 20.3147 9.25963 20.3147H6.02431V4.68538ZM68.8734 4.68538H72.1087C74.3646 4.68538 76.063 5.32248 77.2034 6.59682C78.3438 7.84618 78.9136 9.82028 78.9136 12.5189C78.9136 15.1676 78.3438 17.1289 77.2034 18.4032C76.063 19.6776 74.3646 20.3147 72.1087 20.3147H68.8734V4.68538ZM119.787 4.68538H123.022C125.278 4.68538 126.976 5.32248 128.116 6.59682C129.257 7.84618 129.827 9.82028 129.827 12.5189C129.827 15.1676 129.257 17.1289 128.116 18.4032C126.976 19.6776 125.278 20.3147 123.022 20.3147H119.787V4.68538ZM99.4013 9.60037H103.906H108.412V12.49V15.3802H103.906H99.4013V12.49V9.60037ZM99.4013 16.2054H103.906H108.412V19.095V21.9852H103.906H99.4013V19.095V16.2054Z"
        fill={color}
      />
    </svg>
  );
};

export default Deeded;
