export const getFormattedPostalCode = (value:string) => {
  const typedValue = value as string;
  const code = typedValue.split(" ").join("");
  const symbols = [];
  for (let i = 0; i < code.length; i++) {
    symbols.push(code[i]);
  }
  let formatted =
    symbols.slice(0, 3).join("") + " " + symbols.slice(3, 6).join("");
  formatted = formatted.toUpperCase().trim();
  return formatted
};