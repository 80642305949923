import { ChangeEvent, FC, forwardRef, useState } from "react";
import { DesktopDatePicker, DesktopDatePickerProps, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Box, createTheme, Stack, StandardTextFieldProps, TextField } from "@mui/material";
import { alpha, styled, ThemeProvider } from "@mui/material/styles";
import { StyledComponent } from "@emotion/styled";
import constants from "styles/constants";
import { BodySmall, SubtitleSmall } from "components/CommonDashboard/Typography";
import Colors from "components/CommonDashboard/Styling/Colors";
import { CalendarIcon } from "components/CommonDashboard/Icons";
import { BODY_REGULAR } from "components/CommonDashboard/Styling/Styles";

interface DateFieldProps extends Omit<DesktopDatePickerProps<any>, "onChange" | "renderInput" | "label"> {
    dateStart?: Date;
    label?: string;
    LabelComponent?: StyledComponent<{ color?: string }>;
    name: string;
    error?: string;
    placeholder?: string;
    fullWidth?: boolean;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    outlined?: boolean;
}

const DateField: FC<DateFieldProps> = forwardRef<HTMLDivElement, DateFieldProps>(function inputRender(
    {
        label,
        LabelComponent = SubtitleSmall,
        name,
        dateStart,
        error,
        disabled,
        fullWidth = false,
        placeholder = "mm/dd/yyyy",
        outlined = false,
        ...props
    },
    ref,
) {
    const handleChangeDatePicker = (newValue: Date | null) => {
        if (!props.onChange) return;

        props.onChange({ target: { name, value: newValue } } as never);
    };

    const [isOpenCalendar, setIsOpenCalendar] = useState(false);

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <ThemeProvider theme={materialPickerTheme}>
                <Stack flex={fullWidth ? 1 : undefined} direction="column" gap="0.6rem">
                    <Stack direction="row" gap="0.7rem">
                        {label && <LabelComponent color={Colors[error ? "ERROR" : "BLACK"]}>{label}</LabelComponent>}
                    </Stack>
                    <DesktopDatePicker
                        {...props}
                        ref={ref}
                        disabled={disabled}
                        value={new Date(props.value) || dateStart}
                        onChange={(newValue) => handleChangeDatePicker(newValue as Date | null)}
                        onOpen={() => {
                            setIsOpenCalendar(true);
                        }}
                        onClose={() => {
                            setIsOpenCalendar(false);
                        }}
                        slotProps={{
                            textField: {
                                name,
                                fullWidth,
                                error,
                                disabled,
                                isOpenCalendar,
                                placeholder,
                                outlined,
                            } as never,
                        }}
                        slots={{
                            openPickerIcon: CalendarIcon,
                            textField: PickerTextField as never,
                        }}
                    />
                    {error && <BodySmall color={Colors.ERROR}>{error}</BodySmall>}
                </Stack>
            </ThemeProvider>
        </LocalizationProvider>
    );
});

export const materialPickerTheme = createTheme({
    typography: {
        fontSize: 20,
    },
    palette: {
        primary: {
            main: constants.colors.red,
            light: constants.colors.red,
            dark: constants.colors.red,
        },
        info: {
            main: constants.colors.red,
            light: constants.colors.red,
            dark: constants.colors.red,
        },
    },
});

export const PickerTextField: FC<
    Omit<DateFieldProps, "onChange" | "onChangeEvent" | "dateStart"> & {
        isOpenCalendar: boolean;
    } & StandardTextFieldProps
> = ({ fullWidth, error, disabled, isOpenCalendar, placeholder, ...params }) => (
    <Box
        sx={{
            position: "relative",
        }}
    >
        <StyledDatePickerTextField
            fieldWidth={fullWidth ? "100%" : "144px"}
            hasError={!!error}
            isOpenCalendar={isOpenCalendar}
            disabled={disabled}
            {...params}
            inputProps={{
                ...params.inputProps,
                ...(placeholder ? { placeholder } : {}),
            }}
        />
    </Box>
);

const StyledDatePickerTextField = styled(TextField, {
    shouldForwardProp: (prop) =>
        prop !== "hasError" && prop !== "fieldWidth" && prop !== "isOpenCalendar" && prop !== "outlined",
})<{
    hasError?: boolean;
    fieldWidth: string;
    isOpenCalendar?: boolean;
    disabled?: boolean;
    outlined?: boolean;
}>(({ hasError, fieldWidth, isOpenCalendar, outlined, disabled }) => {
    const borderColor = hasError ? Colors.ERROR : outlined ? Colors.LIGHT_GREY : Colors.WHITE;

    return {
        width: fieldWidth,
        "& .MuiButtonBase-root": {
            position: "absolute",
            right: "16px",
            top: "2px",
            minWidth: 0,
            backgroundColor: isOpenCalendar ? Colors.PRIMARY_100 : "transparent",
            "&:hover": {
                backgroundColor: Colors.PRIMARY_100,
            },
        },

        "& .MuiInputBase-root": {
            backgroundColor: Colors.WHITE,
            paddingRight: "30px",
            textTransform: "uppercase",
            fieldset: {
                borderColor,
                transition: "all .2s ease",
                borderWidth: ".1rem !important",
                borderRadius: "1.6rem",
                boxShadow: `0px 1px 2px 0px ${alpha(Colors.BLACK, 0.1)}`,
                "& legend": { width: 0 },
            },
            "&.Mui-disabled ": {
                backgroundColor: Colors.LIGHT_GREY,
                cursor: "no-drop",
                fieldset: {
                    borderColor: Colors.NEUTRAL_500,
                },
            },
            "&.Mui-focused": {
                fieldset: {
                    borderColor: Colors.FOCUS,
                },
            },
            ".MuiInputBase-input": {
                ...BODY_REGULAR,
                padding: "1rem 1.4rem",
                color: Colors.BLACK,
                "&::placeholder": {
                    color: disabled ? Colors.NEUTRAL_500 : Colors.DARK_GREY,
                    opacity: 1,
                },
            },
        },
    };
});
export default DateField;
