import {useEffect, useRef, useState} from "react";

const useElementScrollY = () => {
  const [scrollY, setScrollY] = useState(0);
  const ref = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    const element = ref.current;
    const setScrollYArrowFunc = () => {
      const newScrollY = element?.scrollTop;
      if (newScrollY !== undefined) {
        setScrollY(newScrollY);
      }
    };
    element?.addEventListener("scroll", setScrollYArrowFunc);
    return () => {
      element?.removeEventListener("scroll", setScrollYArrowFunc);
    };
  }, []);
  return {
    scrollY,
    ref,
  };
};

export default useElementScrollY;
