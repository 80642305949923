import React from "react";

const DownloadDoc = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.8571 10.2854C14.5415 10.2854 14.2857 10.5412 14.2857 10.8568V14.2854C14.2857 14.601 14.0299 14.8568 13.7143 14.8568H2.28573C1.97013 14.8568 1.71429 14.601 1.71429 14.2854V10.8568C1.71429 10.5412 1.45845 10.2854 1.14285 10.2854C0.827248 10.2854 0.571411 10.5412 0.571411 10.8568V14.2854C0.571411 15.2322 1.33892 15.9997 2.2857 15.9997H13.7143C14.6611 15.9997 15.4286 15.2322 15.4286 14.2854V10.8568C15.4286 10.5412 15.1727 10.2854 14.8571 10.2854Z"
        fill="#3C4551"
      />
      <path
        d="M11.2482 9.88171C11.0267 9.66786 10.6757 9.66786 10.4543 9.88171L8.57138 11.7634V0.571439C8.57138 0.255837 8.31555 0 7.99994 0C7.68434 0 7.4285 0.255837 7.4285 0.571439V11.7634L5.54677 9.88171C5.31976 9.66247 4.95802 9.66877 4.73878 9.89574C4.5249 10.1172 4.5249 10.4683 4.73878 10.6897L7.59592 13.5468C7.81881 13.7703 8.18061 13.7707 8.40404 13.5479C8.40438 13.5475 8.40471 13.5472 8.40508 13.5468L11.2622 10.6897C11.4815 10.4627 11.4752 10.101 11.2482 9.88171Z"
        fill="#3C4551"
      />
    </svg>
  );
};

export default DownloadDoc;
