import React from "react";

const WorkDocSvg = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" fill="#548BD7" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28 28H4V4H16.5714L28 15.4286V28ZM26.8571 16V26.8571H5.14286V5.14286H16V16H26.8571Z"
        fill="white"
      />
      <rect
        x="5.14307"
        y="16"
        width="10.8571"
        height="10.8571"
        fill="white"
        fillOpacity="0.5"
      />
    </svg>
  );
};
export default WorkDocSvg;
