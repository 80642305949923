import moment from "moment";

export const getStartOrEndDateToRequest = (
  date: Date,
  view: "day" | "week" | "month",
  type: "end" | "start",
  format: string,
) => {
  if (type === "start") {
    return moment(date).startOf(view).format(format);
  } else {
    return moment(date).endOf(view).format(format);
  }
};
