import React, { FC, useMemo } from "react";
import { Box } from "@mui/material";
import SpecialConditionsBannerStaff from "components/Dashboard/DealDetails/components/SpecialConditionsBannerStaff";
import { Deal } from "types/deal";

interface SpecialConditionsBannerProps {
    deal: Deal;
}

const SpecialConditionsBanner: FC<SpecialConditionsBannerProps> = ({deal}) => {
    const specialConditionsArray = useMemo(() => {
        return (
            deal?.specialConditions?.map((condition) => ({
                name: condition.full_name,
                condition: condition.special_condition,
            })) ?? []
        );
    }, [deal]);
    return (
        <>
            {specialConditionsArray.length > 0 && (
                <Box
                    sx={{
                        marginTop: "3rem",
                    }}
                >
                    <SpecialConditionsBannerStaff
                        specialConditionsArray={specialConditionsArray}
                    />
                </Box>
            )}
        </>
    );
};

export default SpecialConditionsBanner