import { FC, ReactNode, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import Stack from "@mui/material/Stack";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import { convertDeal } from "api";
import { Deal } from "types/deal";
import configuration from "utils/configuration";
import Button from "components/CommonDashboard/Button";
import { ArrowLeftIcon, CloseIcon, EditIcon, HandshakeIcon, ProgressIcon, ThreeDots } from "components/CommonDashboard/Icons";
import Colors from "components/CommonDashboard/Styling/Colors";
import { ButtonLargeText, SubtitleSmall } from "components/CommonDashboard/Typography";
import { HeaderMenuItem } from "components/CommonDashboard/HeaderMenu";
import DealHeaderInfo from "components/Dashboard/DealHeader/DealHeaderInfo";
import BaseModal from "components/CommonDashboard/Modal";
import EditDealInfoModal from "components/Dashboard/DealHeader/EditDealInfoModal";
import DealDetailsCloseDealPopup from "../DealPropertyCard/components/DealDetailsCloseDealPopup";
import useUser from "utils/hooks/useUser";
import { shouldCancelDeal, shouldReopenDeal } from "utils/permissionRoles";
import { useReopenDealMutation } from "redux/reducers/dealInfoApi";

interface DealHeaderProps {
    deal: Deal;
    navigation: ReactNode;
    onDealRefresh?: () => void;
}

const DealHeader: FC<DealHeaderProps> = ({ deal, navigation, onDealRefresh }) => {
    const { goBack, push } = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
    const [showConversionAlert, setShowConversionAlert] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [isOpenCloseDealPopup, setIsOpenCloseDealPopup] = useState(false);
    const [reopenDeal, { isLoading: isReopenLoading }] = useReopenDealMutation();
    const { user } = useUser();

    const handleConvertDeal = async () => {
        setMenuAnchor(null);

        if (!deal.closing_date || (!deal.address.address && deal.deal_type_tag?.tag !== 'ILA')) {
            setShowConversionAlert(true);
            return;
        }

        try {
            await convertDeal(deal.id, { status: true });
            enqueueSnackbar("Converting the deal", {
                variant: "success",
                autoHideDuration: 2000,
            });
            push("/dashboard/deals");
        } catch (e: any) {
            enqueueSnackbar(e.response.data.error, {
                variant: "error",
                autoHideDuration: configuration.autoHideErrorDuration,
            });
        }
    };

    const handleOpenEdit = () => {
        setMenuAnchor(null);
        setShowEdit(true);
    };

    const handleSave = () => {
        setShowEdit(false);
        onDealRefresh && onDealRefresh();
    };

    const handleCancelOpen = () => {
        onDealRefresh && onDealRefresh();
    };

    const alertMessage = useMemo(() => {
        const message = "required to convert the lead into a transaction. Please provide";
        if (deal.deal_type_tag?.tag === "ILA")
            return `Closing date is ${message} a closing date to proceed.`;
        return `Address and closing date information are ${message} this information to proceed.`;
    }, [deal.deal_type_tag?.tag]);

    return (
        <Stack sx={{ flexDirection: "column", padding: "2.4rem 4.8rem 0", gap: "2.4rem" }}>
            <Stack sx={{ justifyContent: "space-between", flexDirection: "row" }}>
                <Stack sx={{ flexDirection: "row", gap: "1.6rem", alignItems: "center" }}>
                    <Button typeOf="outline" startIcon={<ArrowLeftIcon />} onClick={goBack} data-testid="back-button" />
                    <SubtitleSmall color="black"> My leads </SubtitleSmall>
                </Stack>
                <Button testId="actions-menu" onClick={(e) => setMenuAnchor(e.currentTarget)} typeOf="outline" startIcon={<ThreeDots />} />
            </Stack>
            <DealHeaderInfo deal={deal} />
            <Stack>{navigation}</Stack>
            <Menu
                anchorEl={menuAnchor}
                open={Boolean(menuAnchor)}
                onClose={() => setMenuAnchor(null)}
                MenuListProps={{ sx: { padding: 0 } }}
                slotProps={{
                    paper: {
                        sx: {
                            minWidth: "26rem",
                            marginTop: "0.6rem",
                            borderRadius: "1.6rem",
                            padding: "0.8rem",
                        },
                    },
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                <HeaderMenuItem sx={{ borderRadius: "1.6rem" }} onClick={handleOpenEdit}>
                    <ListItemIcon>
                        <EditIcon color={Colors.DARK_GREY} />
                    </ListItemIcon>
                    <ButtonLargeText color={Colors.DARK_GREY}>Edit lead</ButtonLargeText>
                </HeaderMenuItem>
                <HeaderMenuItem sx={{ borderRadius: "1.6rem" }} onClick={handleConvertDeal} data-testid="convert-button">
                    <ListItemIcon>
                        <HandshakeIcon color={Colors.DARK_GREY} />
                    </ListItemIcon>
                    <ButtonLargeText color={Colors.DARK_GREY}>Convert to transaction</ButtonLargeText>
                </HeaderMenuItem>
                {!deal.is_archive && shouldCancelDeal(user.role) && <HeaderMenuItem
                    sx={{ borderRadius: "1.6rem" }}
                    onClick={() => {
                        setMenuAnchor(null);
                        setIsOpenCloseDealPopup(true)
                    }}
                    data-testid="cancel-button">
                    <ListItemIcon>
                        <CloseIcon color={Colors.DARK_GREY} />
                    </ListItemIcon>
                    <ButtonLargeText color={Colors.DARK_GREY}>Cancel lead</ButtonLargeText>
                </HeaderMenuItem>}
                {!!deal.is_archive && shouldReopenDeal(user.role) && <HeaderMenuItem
                    sx={{ borderRadius: "1.6rem" }}
                    onClick={() => {
                        setMenuAnchor(null);
                        !isReopenLoading && reopenDeal({ dealId: deal.id, setIsArchive: handleCancelOpen })
                    }}
                    data-testid="restore-button">
                    <ListItemIcon>
                        <ProgressIcon color={Colors.DARK_GREY} />
                    </ListItemIcon>
                    <ButtonLargeText color={Colors.DARK_GREY}>{isReopenLoading ? 'Loading...' : 'Reopen Deal'}</ButtonLargeText>
                </HeaderMenuItem>}
            </Menu>
            <BaseModal
                open={showConversionAlert}
                onCloseClick={() => setShowConversionAlert(false)}
                onClose={() => setShowConversionAlert(false)}
                title="Lead Conversion Requirements"
                textContent={alertMessage}
            />
            <EditDealInfoModal deal={deal} open={showEdit} onClose={() => setShowEdit(false)} onSave={handleSave} />
            {isOpenCloseDealPopup && (
                <DealDetailsCloseDealPopup
                    isOpenCloseDealPopup={isOpenCloseDealPopup}
                    setIsOpenCloseDealPopup={setIsOpenCloseDealPopup}
                    setIsArchive={handleCancelOpen}
                    dealIdx={deal.id}
                />
            )}
        </Stack>
    );
};

export default DealHeader;
