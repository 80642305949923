import { FC } from "react";
import { styled } from "@mui/material/styles";
import MuiButton, { ButtonProps as MuiButtonProps } from "@mui/material/Button";
import Box from "@mui/material/Box";
import Colors from "../Styling/Colors";
import { ButtonSmallText } from "../Typography";

export type NavLinkProps = {
    /**
     * Button text.
     */
    label: { text: string; inputId?: string };
    /**
     * Id for tests
     */
    testId?: string;
    /**
     * Shows link as active
     */
    active?: boolean;
} & Omit<
    MuiButtonProps,
    | "touchRippleRef"
    | "TouchRippleProps"
    | "onFocusVisible"
    | "focusVisibleClassName"
    | "focusRipple"
    | "disableTouchRipple"
    | "disableRipple"
    | "centerRipple"
    | "action"
    | "variant"
    | "children"
    | "disableElevation"
    | "disableFocusRipple"
>;

const NavLink: FC<NavLinkProps> = ({ label, startIcon, endIcon, testId, ...props }) => {
    return (
        <CustomButton label={label} data-testid={testId} {...props}>
            <Box width="100%" display="inline-flex" gap="0.8rem" alignItems="center" justifyContent="flex-start">
                {startIcon && startIcon}
                <ButtonSmallText textAlign="left" color="inheirt">{label.text}</ButtonSmallText>
            </Box>
        </CustomButton>
    );
};

const CustomButton = styled(MuiButton, {
    shouldForwardProp: (prop) => prop !== "active" && prop !== "label",
})<NavLinkProps>(({ active }) => ({
    padding: "1.6rem 1.6rem",
    borderRadius: "1.6rem",
    textTransform: "none",
    minWidth: 0,
    background: active ? Colors.PRIMARY_600 : "transparent",
    color: active ? Colors.WHITE : Colors.NEUTRAL_500,
    border: "none",
    "& svg path": {
        fill: active ? Colors.WHITE : Colors.NEUTRAL_500,
    },
    "&:hover": {
        background: active ? Colors.PRIMARY_600 : Colors.PRIMARY_50,
    },
    "&:active": {
        background: active ? Colors.PRIMARY_600 : Colors.PRIMARY_100,
    },
}));

export default NavLink;
