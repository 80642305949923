import { FC } from "react";
import _ from "lodash";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import reviewImg from "assets/img/partner-page-review.jpg";
import Colors from "components/CommonDashboard/Styling/Colors";
import { BodyRegular, H3 } from "components/CommonDashboard/Typography";
import { StarIcon } from "components/CommonDashboard/Icons";
import Button from "components/CommonDashboard/Button";

const CompactReview: FC = () => {
    return (
        <ReviewContainer>
            <Stack direction="row" gap="1.6rem" alignItems="center">
                <img alt="Review Profile Picture" src={reviewImg} height="48" width="48" />
                <H3>Alana Z.</H3>
            </Stack>
            <Stack direction="row" gap="0.8rem">
                {_.fill(Array(5), null).map((val, i) => (
                    <StarIcon key={i} color={Colors.WARNING_600} />
                ))}
            </Stack>
            <H3>I almost can't believe this service exists!</H3>
            <BodyRegular>
                I always had questions, and they were always answered with knowledge, and kindness. This was our first
                home purchase and I'm SO glad we were able to use Deeded. I can't say enough about their service.
            </BodyRegular>
            <Button
                component="a"
                href="https://www.deeded.ca/reviews"
                target="_blank"
                size="small"
                typeOf="link"
                label={{ text: "See more or our reviews" }}
            />
        </ReviewContainer>
    );
};

const ReviewContainer = styled(Stack)(({ theme }) => ({
    width: "40rem",
    alignItems: "left",
    borderRadius: "2.4rem",
    padding: "2.4rem",
    gap: "2.4rem",
    background: Colors.WHITE,
    boxShadow: "0px 4px 16px 0px #0000000D",
    flexDirection: "column",
    [theme.breakpoints.down("mobile")]: {
        alignSelf: "center",
        width: "100%",
        maxWidth: "40rem",
    },
}));

export default CompactReview;
