import React, { forwardRef } from "react";
import MuiSelect, { SelectProps as MuiSelectProps } from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import { styled, alpha } from "@mui/material/styles";
import { BodySmall, SubtitleSmall } from "../Typography";
import Colors from "../Styling/Colors";
import { BODY_REGULAR } from "../Styling/Styles";
import { ArrowDownIcon } from "../Icons";

export interface SelectFieldProps extends Omit<MuiSelectProps<string>, "size" | "label" | "error" | "variant"> {
    label?: string;
    observation?: string;
    error?: string;
    size?: "default" | "small";
    outlined?: boolean;
    options: string[] | { id: string; name: string }[];
}

const SelectField = forwardRef<HTMLDivElement, SelectFieldProps>(function inputRender(
    { outlined = false, label, observation, size = "default", error, fullWidth = true, disabled, options, ...props },
    ref,
) {
    return (
        <Stack width={fullWidth ? "100%" : undefined} flex={fullWidth ? 1 : undefined} direction="column" gap="0.6rem">
            <Stack direction="row" gap="0.7rem">
                {label && <SubtitleSmall color={Colors[error ? "ERROR" : "BLACK"]}>{label}</SubtitleSmall>}
                {observation && <BodySmall color={Colors[error ? "ERROR" : "DARK_GREY"]}>{observation}</BodySmall>}
            </Stack>

            <StyledSelect
                _size={size}
                displayEmpty
                {...props}
                ref={ref}
                disabled={disabled}
                variant="outlined"
                fullWidth={fullWidth}
                outlined={outlined}
                hasError={Boolean(error)}
                renderValue={(selected) => {
                    if ((!selected || selected.length === 0) && props.placeholder) {
                        return <em>{props.placeholder}</em>;
                    }

                    for (const option of options) {
                        if (typeof option === "string") {
                            if (option === selected) {
                                return option;
                            }
                            continue;
                        }

                        if (option.id === selected) {
                            return option.name;
                        }
                    }

                    return "";
                }}
                MenuProps={{
                    MenuListProps: { sx: listStyle() },
                }}
                IconComponent={() => (
                    <Box pr="1rem" mt={size === "small" ? "0.5rem" : undefined}>
                        <ArrowDownIcon size="small" color={Colors.DARK_GREY} />
                    </Box>
                )}
            >
                {options.map((item, i) => (
                    <MenuItem value={typeof item === "string" ? item : item.id} key={i}>
                        {typeof item === "string" ? item : item.name}
                    </MenuItem>
                ))}
            </StyledSelect>

            {error && <BodySmall color={Colors.ERROR}>{error}</BodySmall>}
        </Stack>
    );
});

export const StyledSelect = styled(MuiSelect<string>, {
    shouldForwardProp: (prop) => prop !== "hasError" && prop !== "outlined" && prop !== "size",
})<{ outlined?: boolean; hasError?: boolean; _size: SelectFieldProps["size"] }>(({
    outlined,
    hasError,
    disabled,
    _size,
}) => {
    const borderColor = hasError ? Colors.ERROR : outlined ? Colors.LIGHT_GREY : Colors.WHITE;

    return {
        "&.MuiInputBase-root": {
            justifyContent: "center",
            backgroundColor: Colors.WHITE,
            fieldset: {
                transition: "all .2s ease",
                borderColor: borderColor,
                borderWidth: ".1rem !important",
                borderRadius: "8px",
                boxShadow: `0px 1px 2px 0px ${alpha(Colors.BLACK, 0.1)}`,
                "& legend": { width: 0 },
            },
            "&:hover fieldset": {
                borderColor: borderColor,
            },
            "&.Mui-disabled ": {
                backgroundColor: Colors.LIGHT_GREY,
                cursor: "no-drop",
                fieldset: {
                    borderColor: Colors.NEUTRAL_500,
                },
            },
            "&.Mui-focused": {
                fieldset: {
                    borderColor: Colors.FOCUS,
                },
            },
            ".MuiInputBase-input": {
                ...BODY_REGULAR,
                padding: _size === "small" ? "0.5rem 1.4rem" : "1rem 1.4rem",
                color: Colors.BLACK,
                "&::placeholder": {
                    color: disabled ? Colors.NEUTRAL_500 : Colors.DARK_GREY,
                    opacity: 1,
                },
                em: {
                    color: Colors.NEUTRAL_500,
                },
            },
        },
    };
});

const listStyle = () => ({
    paddingTop: 0,
    paddingBottom: 0,
    "& li": {
        ...BODY_REGULAR,
        color: Colors.BLACK,
        height: "4.1rem",
        "&.Mui-selected:hover, &.Mui-selected, &:hover": {
            background: Colors.LIGHT_GREY,
        },
    },
});

export default SelectField;
