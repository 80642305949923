import {useState, useEffect} from "react";
import "./AddNewDocumentPage.scss";
import StorageCloseIconSvg from "../../../Svg/StorageCloseIconSvg";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import Dropzone from "react-dropzone";
import {useDispatch, useSelector} from "react-redux";
import {useSnackbar} from "notistack";
import {
  getClientsByFullName,
  getDealTypesByClientId,
  getFoldersForDealType,
  uploadNewDocument,
} from "../../../../api";
import {getFoldersTC} from "../../../../redux/reducers/storageReducer";
import {enableFileFormatsIntake} from "../../../../utils/enableFileFormatsIntake";
import {useLocation} from "react-router-dom";
import {returnConvertedFromHeicFile} from "../../../../utils/returnConvertedFromHeicFile";
import DeededPopup from "../../../../v2/components/DeededPopup";
import DeededSpace from "../../../../v2/components/DeededSpace";
import configuration from "../../../../utils/configuration";
import DeededCircularLoader from "v2/components/DeededCircularLoader";

const AddNewDocumentPage = ({
  addNewDocument,
  setAddNewDocument,
  breadCrumbs,
}) => {
  const dispatch = useDispatch();
  let folder = useSelector((state) => state.StorageReducer.folderID);
  let value = useSelector((state) => state.StorageReducer.searchValue);
  const location = useLocation();
  const [files, setFiles] = useState([]);
  const [client, setClient] = useState(
    breadCrumbs?.length !== 0
      ? {label: breadCrumbs[0].clientFullName, value: breadCrumbs[0].clientId}
      : undefined,
  );
  const [optionsDealType, setOptionsDealType] = useState([]);
  const [dealType, setDealType] = useState(
    breadCrumbs.length !== 0
      ? {label: breadCrumbs[0].deal_type, value: breadCrumbs[0].deal_id}
      : undefined,
  );
  const [deals, setDeals] = useState([]);
  const [province, setProvince] = useState();
  const [optionsDocType, setOptionsDocType] = useState([]);
  const [docType, setDocType] = useState(
    breadCrumbs.length === 2 //|| breadCrumbs[0]?.name
      ? {label: breadCrumbs[1].name, value: breadCrumbs[1].name}
      : undefined,
  );
  const optionsStatus = [
    {value: "Approved", label: "Approved", color: "#02CD8F"},
    {value: "Pending", label: "Pending", color: "#FFC914"},
    {value: "Rejected", label: "Rejected", color: "#F16764"},
  ];
  const [status, setStatus] = useState(optionsStatus[0]);
  const [loading, setLoading] = useState(false);

  // notistack
  const {enqueueSnackbar} = useSnackbar();

  useEffect(() => {
    if (!client) return;
    const fetchAPI = async () => {
      const options = await getDealTypesByClientId(client.value);
      const optionsDT = options.data.map((i) => ({
        label: i.deal_type,
        value: i.id,
      }));
      setOptionsDealType(optionsDT);
      if (breadCrumbs.length < 1) setDealType(optionsDT[0]);
      setDeals(options.data);
    };
    fetchAPI();
  }, [client]);

  useEffect(() => {
    if (!dealType || !deals?.length ) return;
    const deal = deals.filter((i) => (i.id === dealType.value))[0];
    deal && setProvince(deal?.address?.state);
  }, [dealType?.value, deals]);

  useEffect(() => {
    if (!dealType || !province) return;
    const fetchAPI = async () => {
      const options = await getFoldersForDealType(dealType.label, province);
      const optionsDT = options.data.map((i) => ({
        label: i,
        value: i,
      }));
      setOptionsDocType(optionsDT);
      if (breadCrumbs.length < 2) setDocType(optionsDT[0]);
    };
    fetchAPI();
  }, [dealType, province]);

  const loadOptions = async (inputText, callback) => {
    const clients = await getClientsByFullName(inputText);
    callback(
      Object.values(clients.data).map((i) => ({
        label: i.client_full_name,
        value: i.id,
      })),
    );
  };
  const handleAddFile = async (file) => {
    const convertedFile = [await returnConvertedFromHeicFile(file)];
    setFiles([...files, ...convertedFile]);
  };
  const handleDrop = async (acceptedFiles) => {
    if (acceptedFiles.length !== 0) {
      acceptedFiles.map(handleAddFile);
    } else {
      setFiles([]);
    }
  };
  useEffect(() => {
    //it was added because deeded popup brakes the calendar overflow scroll
    document.body.style.overflow = "inherit";
  }, []);
  const hendleSubmit = (e) => {
    e.preventDefault();
    if (files.length === 0) {
      enqueueSnackbar("You need to add file", {
        variant: "error",
        autoHideDuration: configuration.autoHideErrorDuration,
      });
      return;
    }
    if (!dealType) {
      enqueueSnackbar("You need to add dealType", {
        variant: "error",
        autoHideDuration: configuration.autoHideErrorDuration,
      });
      return;
    }
    if (!docType) {
      enqueueSnackbar("You need to add docType", {
        variant: "error",
        autoHideDuration: configuration.autoHideErrorDuration,
      });
      return;
    }
    if (!client) {
      enqueueSnackbar("You need to choose client", {
        variant: "error",
        autoHideDuration: configuration.autoHideErrorDuration,
      });
      return;
    }
    setLoading(true);
    const filesArray = [];

    const fd = new FormData();
    for (const file of files) {
      fd.append("files[]", file, file.name);
    }

    // fd.append("files", files);
    fd.append("dealId", dealType.value);
    fd.append("dealType", dealType.label);
    fd.append("docType", docType.label);
    fd.append("status", status.label);
    fd.append("client_id", client.value);

    filesArray.push(fd);

    const isStorage = location?.pathname.includes("storage");
    uploadNewDocument(fd)
      .then((res) => {
        dispatch(getFoldersTC(folder, value, 1, undefined, !isStorage));
        setAddNewDocument(false);
      })
      .catch((error) => {
        let err =
          error.response && error.response.data && error.response.data.error;
        const errFiles = err
          .replace(/[^0-9]/g, "")
          .split("")
          .map((item) => files[item].name)
          .join(", ");

        if (error.response.status === 500) {
          err = `These files have a bad format or their format was changed: ${errFiles}`;
        }

        enqueueSnackbar(err, {
          variant: "error",
          autoHideDuration: configuration.autoHideErrorDuration,
        });
      }).finally(() => {
        setLoading(false);
      });
  };

  const handleKeyPress = (e) => {
    if (e.code !== "Escape") return;

    setAddNewDocument(false);
  };

  const removeFile = (index) => {
    setFiles(files.filter((el, i) => i !== index));
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return function cleanup() {
      window.removeEventListener("keydown", handleKeyPress);
    };
  });

  return (
    <DeededPopup
      title={"Add a new document"}
      open={addNewDocument}
      setOpen={setAddNewDocument}
    >
      <DeededSpace y={30} />
      <form onSubmit={hendleSubmit} className="addNewDocumentForm__wrapper ">
        {loading ? <DeededCircularLoader sx={{ padding: '13rem 21rem'  }}/> : <>
        <div className="inputs__wrapper">
          <div className="addNewDocumentLeft">
            <div className="option">
              <h2>Client:</h2>
              <AsyncSelect
                styles={stagesStyles}
                value={client}
                onChange={setClient}
                placeholder={"Choose Client"}
                loadOptions={loadOptions}
                autoFocus
                noOptionsMessage={() => "Please start typing client name"}
              />
            </div>
            <div className="option">
              <h2>Deal type:</h2>
              <Select
                placeholder="Choose Deal Type"
                noOptionsMessage={() =>
                  "There are no deal types yet, you should choose the client first"
                }
                styles={stagesStyles}
                options={optionsDealType}
                value={dealType}
                onChange={setDealType}
              />
            </div>
            <div className="option">
              <h2>Doc type:</h2>
              <Select
                placeholder="Choose Doc Type"
                noOptionsMessage={() =>
                  "There are no doc types yet, you should choose the deal type first"
                }
                styles={stagesStyles}
                options={optionsDocType}
                value={docType}
                onChange={setDocType}
              />
            </div>
          </div>
          <div className="dropZoneBox">
            <div className="App">
              <Dropzone
                accept={enableFileFormatsIntake}
                onDrop={handleDrop}
                multiple
              >
                {({getRootProps, getInputProps}) => (
                  <div {...getRootProps({className: "dropzone"})}>
                    <input {...getInputProps()} />
                    <svg
                      viewBox="0 0 65 65"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="65" height="65" rx="12" fill="white" />
                      <path
                        d="M26.6248 47.1846H22.2185C20.7605 47.1832 19.3194 46.8716 17.991 46.2707C16.6626 45.6697 15.4771 44.7931 14.5134 43.699C13.5497 42.6049 12.8297 41.3183 12.4012 39.9247C11.9728 38.531 11.8456 37.0621 12.0282 35.6156C12.2108 34.169 12.6991 32.7779 13.4604 31.5344C14.2218 30.291 15.239 29.2237 16.4444 28.4034C17.6498 27.5832 19.0159 27.0287 20.452 26.7767C21.8881 26.5248 23.3614 26.5812 24.774 26.9422"
                        stroke="#2F80ED"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M23.6875 32.4971C23.6877 30.1699 24.2409 27.8761 25.3016 25.8047C26.3622 23.7333 27.8998 21.9435 29.7878 20.5829C31.6758 19.2223 33.86 18.3298 36.1606 17.9789C38.4612 17.6281 40.8122 17.8289 43.0199 18.5649C45.2277 19.3008 47.2289 20.5509 48.8588 22.212C50.4886 23.8731 51.7004 25.8977 52.3944 28.119C53.0883 30.3403 53.2444 32.6947 52.8499 34.9882C52.4555 37.2817 51.5217 39.4486 50.1254 41.3104"
                        stroke="#2F80ED"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M30.6748 38.7286L36.9062 32.4971L43.1375 38.7286"
                        stroke="#2F80ED"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M36.9062 47.1846V32.4971"
                        stroke="#2F80ED"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>

                    <p>
                      Drag and Drop of your files here or <span>browse</span> to
                      upload
                    </p>
                  </div>
                )}
              </Dropzone>
            </div>
          </div>
        </div>
        <div>
          <ul className="fileList">
            {files.map((file, index) => (
              <li key={file.name}>
                <p
                  style={{
                    marginTop: "7px",
                    overflowX: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  {file.name}
                </p>
                <button
                  type="button"
                  onClick={() => {
                    removeFile(index);
                  }}
                >
                  <StorageCloseIconSvg />
                </button>
              </li>
            ))}
          </ul>
        </div>
        <div className="buttons__wrapper">
          <button
            type="button"
            onClick={() => {
              setAddNewDocument(false);
            }}
          >
            Cancel
          </button>
          <button>Upload</button>
        </div>
        </>}
      </form>
    </DeededPopup>
  );
};

const stagesStyles = {
  container: (base) => ({
    ...base,
    width: "100%",
    height: "36px",
  }),
  valueContainer: (base) => ({
    ...base,
    padding: 0,
    paddingLeft: "5px",
  }),
  placeholder: (base) => ({
    ...base,
    color: "#7F7F7F",
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: "none",
  }),
};

export default AddNewDocumentPage;
