import {SVGProps} from "react";

const ExportRecordsSvg = (props: SVGProps<SVGSVGElement>) => {
  return (
    <>
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M18.75 21H5.25a.75.75 0 0 1-.75-.75V3.75A.75.75 0 0 1 5.25 3h9l5.25 5.25v12a.75.75 0 0 1-.75.75Z"
          stroke=""
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.25 3v5.25h5.25M9.375 13.875 12 11.25l2.625 2.625M12 17.25v-6"
          stroke=""
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default ExportRecordsSvg;
