import {useMemo, useState} from "react";
import "./AddFile.scss";
import {addDealFile} from "api";
import {useSelector} from "react-redux";
import {useSnackbar} from "notistack";
import {useMixpanel} from "../../../utils/MixpanelContext";
// SVG
import {useUploadedDocs} from "../../../context/UploadDocumentsProvider";
import {enableFileFormatsIntake} from "../../../utils/enableFileFormatsIntake";
import {returnConvertedFromHeicFile} from "../../../utils/returnConvertedFromHeicFile";
import DeededPopup from "v2/components/DeededPopup";
import constants from "styles/constants";
import { Spacer } from "../GenericComponents";
import DeededSearch from "v2/components/DeededSearch";
import { Body, BodyBold, Small } from "components/Common/Typography";
import BasicButton from "components/Common/Button/BasicButton";
import configuration from "../../../utils/configuration";

const validFilesList = [
  {type: "application/pdf"},
  {type: "text/plain"},
  {type: "image/jpeg"},
  {type: "image/gif"},
  {type: "image/png"},
  {type: "application/msword"},
  {type: "application/rtf"},
  {type: "application/x-zip-compressed"},
  {type: "application/vnd.ms-excel"},
  {
    type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  },
  {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"},
  {type: "text/csv"},
  {type: "image/heic"},
];
const isValidFile = (doc) => {
  const filtered = validFilesList.find((file) => file.type === doc.type);
  const isHeic = doc.name.endsWith(".heic");
  if (filtered === undefined && !isHeic) {
    return false;
  }
  return true;
};

const AddFile = ({
  dealType,
  toggleAddFilePopup,
  dealID,
  dealAddress,
  toggleAddFile,
  documentTypes
}) => {
  const {enqueueSnackbar} = useSnackbar();
  const {toggleLoading} = useUploadedDocs();
  const currentUser = useSelector((state) => state.AuthReducer.currentUser);
  const [fileName, setFileName] = useState(null);
  const [otherName, setOtherName] = useState("");
  const [fileData, setFileData] = useState(null);
  const [rawFileData, setRawFileData] = useState(null);
  const [search, setSearch] = useState("");
  const mixpanel = useMixpanel();
  const getUploadParams = async (event) => {
    let file = await returnConvertedFromHeicFile(event.target.files[0]);
    let reader = new FileReader();

    if (!isValidFile(file)) {
      enqueueSnackbar("Not valid file format", {
        variant: "error",
        autoHideDuration: configuration.autoHideErrorDuration,
      });
      return;
    }
    reader.readAsDataURL(file);
    reader.onload = () => {
      setRawFileData(file);

      setFileData({
        name: file.name,
        size: file.size,
        type: file.type,
        data: reader.result,
      });
    };
  };
  const handleOtherFileName = (e) => {
    if (fileName === "Other (Custom Name)") {
      const {value} = e.target;
      setOtherName(value);
      return;
    }
  };
  const handleAddFile = () => {
    if (
      fileName === "Other (Custom Name)" &&
      otherName.length === 0 &&
      fileData !== null
    ) {
      enqueueSnackbar("File name can`t be empty", {
        variant: "error",
        autoHideDuration: configuration.autoHideErrorDuration,
      });
      toggleAddFilePopup();
      return;
    }
    if (fileData === null) return;
    const NEW_API = false;

    if (NEW_API) {
      const payload = {
        agreements: [
          {
            document_type:
              fileName === "Other (Custom Name)" ? otherName : fileName,
          },
        ],
      };

      var formData = new FormData();
      formData.append("file", rawFileData);
      formData.append("data", JSON.stringify(payload));
      enqueueSnackbar("Add document, Please wait", {
        variant: "success",
        autoHideDuration: 1500,
      });
      addDealFile(dealID, formData)
        .then((res) => {
          toggleAddFilePopup();
        })
        .catch((error) => {});
    } else {
      const payload = {
        agreements: [
          {
            document_type:
              fileName === "Other (Custom Name)" ? otherName : fileName,
            file: fileData,
          },
        ],
      };

      toggleLoading();
      toggleAddFilePopup();
      // Send data for mixpanel analytics if files were uploaded
      const {role, email, full_name} = currentUser;
      const mixPayload = {
        role,
        email,
        full_name,
        document_type:
          fileName === "Other (Custom Name)" ? otherName : fileName,
        deal_type: dealType,
        deal_id: dealID,
      };
      mixpanel.track(`${role}_add_document`, mixPayload);
      addDealFile(dealID, payload)
        .then((res) => {
          toggleLoading();
        })
        .catch((error) => {
          enqueueSnackbar(
            error &&
              error.response &&
              error.response.data &&
              error.response.data.error,
            {
              variant: "error",
              autoHideDuration: configuration.autoHideErrorDuration,
            },
            toggleLoading(),
          );
        });
    }
  };

  const filteredDocument = useMemo(() => {
    if (!documentTypes) return null;

    if (search.length > 0) {
      const normalizedSearch = search.toLowerCase().replaceAll(" ", "").trim();
      return documentTypes.filter((item) => item.document_type.toLowerCase().replaceAll(" ", "").indexOf(normalizedSearch) > -1,);
    }

    return documentTypes;
  }, [search, dealType, documentTypes]);

  return (
    <DeededPopup
      sx={{}}
      open={!toggleAddFile}
      setOpen={toggleAddFilePopup}
      title="Add file"
      sxInternalBox={{
        width: "55rem",
        height: "100%",
        overflowY: "auto",
        background: constants.colors.disabled2,
        "&::-webkit-scrollbar": {
          width: "5px",
          scrollbarWidth: "thin",
        },
      }}
    >
      <div className="addfile__wrapper" data-testid="deal-add-file-popup">
        <Spacer height={12} />
        <DeededSearch
          placeholder={"Search..."}
          onChange={setSearch}
          value={search}
        />
        <BodyBold data-testid="deal-add-file-doc-type" textAlign={"center"} marginY={'1rem'}>{dealType}</BodyBold>
        <div className="addfile__list">
          {(filteredDocument|| []).map((doc, index) =>
            <div key={index}>
              <label className="radio-container" data-testid="deal-add-file-doc-name">
                {doc.document_type}
                <input
                  type="radio"
                  name="radio"
                  value={doc.document_type}
                  checked={doc.document_type == fileName}
                  onChange={() => setFileName(doc.document_type)}
                />
                <span className="checkmark"></span>
              </label>
            </div>
          )}
          {!(filteredDocument|| []).length && <Small>No Results</Small>}
        </div>
        {fileName && (
          <div className="addfile__inputs">
            <input
              type="text"
              value={fileName === "Other (Custom Name)" ? otherName : fileName}
              onChange={(e) => handleOtherFileName(e)}
              placeholder="Document description"
            />
            <BasicButton
              label={{inputId: "addfile-upload", text: fileData === null ? "Upload file": `${fileData.name?.slice(0, 20)}` }}
              testId="add-file-upload-btn"
              size='small'
              typeOf="secondary"
              component={"label"}
              sx={{ width: '100%' }}
            >
              <input
                id="add-file-upload"
                type="file"
                accept={enableFileFormatsIntake}
                style={{ display: 'none' }}
                onChange={(e) => getUploadParams(e)}
              />
            </BasicButton>
          </div>
        )}
        <BasicButton
          typeOf="CTA"
          disabled={!fileName}
          testId="add-file-submit-btn"
          label={{ text: "Submit", inputId: "add-file-submit" }}
          size="small"
          onClick={handleAddFile}
          sx={{ width: '100%' }}
        />
      </div>
    </DeededPopup>
  );
};

export default AddFile;
