import React, {useEffect, useState, useRef} from "react";
import {RadioButton} from "../../GenericComponents";

/**
 * This hook is used for updating the data which needs pre-parsing
 * (like Date) or holds the internal state.
 *
 * @param {()=>void} fn
 * @param {any[]} inputs
 */
export function useDidUpdateEffect(fn, inputs) {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) {
      fn();
    } else {
      didMountRef.current = true;
    }
  }, inputs);
}

/**
 * Props
 * @typedef {{value: any, onChanged: (value: any)=>void}} CardFormRenderProps
 */

/**
 * React component for rendering card edit form
 * @typedef {(props: CardFormRenderProps)=>JSX.Element} CardEditFormComponent
 */

/**
 * A hook for handling radio buttons
 * @param {any[]} options
 * @param {string[]} titles
 * @param {any} selected
 * @param {(e:any)=>void} onChanged
 * @param {string[]} paragraphs This is a list of paragraphs which describe each radio option
 *  its count should be equal to options. If there's no description for specific item, leave an empty string
 * @param {boolean} isHorizontal is unused for now
 */
export function useRadios(
  options,
  titles,
  selected,
  onChanged,
  paragraphs,
  isHorizontal = false,
) {
  const [current, setCurrent] = useState(selected);
  useDidUpdateEffect(() => {
    onChanged(current);
  }, [current]);
  const renderRadio = (opt, index) => (
    <>
      <RadioButton
        onChange={(value) => setCurrent(value)}
        value={opt}
        title={titles[index]}
        checked={selected == opt}
      />
      {paragraphs && paragraphs[index] != "" ? (
        <p style={{marginLeft: "3.5rem", marginBottom: 32}}>
          {paragraphs[index]}
        </p>
      ) : null}
    </>
  );

  const renderRadios = () =>
    options.map((opt, index) => (
      <div key={"radio" + index + opt}>{renderRadio(opt, index)}</div>
    ));

  if (!isHorizontal) {
    return renderRadios;
  } else {
    return () => <div className="radio-group_horizontal">{renderRadios()}</div>;
  }
}
