// @ts-check
import moment from "moment";
import {enCA} from "date-fns/locale";
import {formatRelative} from "date-fns";

export {convertDateStringToDateObject} from "conveyance/libs/utils/dateHelper";
export const PROP_CARD_DATE_CONVERSION_FORMAT = "YYYY-MM-DD";
export const PROP_CARD_DATE_DISPLAY_FORMAT = "M/D/YYYY";

export const convertClosingDateToDisplayRepresentation = (
  dateString: string,
) => {
  if (!dateString) {
    return '';
  }
  return moment(dateString, PROP_CARD_DATE_CONVERSION_FORMAT).format(
    PROP_CARD_DATE_DISPLAY_FORMAT,
  );
};

export const formatRelativeDate = (dateStr: string, withTime: boolean = true) => {
  const localeMap = {
    lastWeek: withTime ? "PPP 'at' p" : "PPP",
    yesterday: withTime ? "'Yesterday at' p" : "'Yesterday'",
    today: withTime ? "'Today at' p" : "'Today'",
    tomorrow: withTime ? "'Tomorrow at' p" : "'Tomorrow'",
    nextWeek: withTime ? "PPP 'at' p" : "PPP",
    other: withTime ? "PPP 'at' p" : "PPP",
  };

  return formatRelative(new Date(dateStr), Date.now(), {
    locale: {
      ...enCA,
      formatRelative: (token) => localeMap[token as keyof typeof localeMap],
    },
  });
};
