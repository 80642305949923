import NumberFormat from "react-number-format";
import {UserParticipant} from "types/user";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import React, {SetStateAction, useEffect} from "react";
import {
  setSuccessUpdateAC,
  updateTeamTC,
} from "../../../redux/reducers/dealsReducer";
import {useDispatch} from "react-redux";
import useTypedSelector from "../../../utils/hooks/useTypedSelector";
import {addSuccessMessageAC} from "../../../redux/reducers/ErrorReducer";
import "../DealMemberEditCard/DealMemberEditCard.scss";
import DeededPopup from "v2/components/DeededPopup";

const phoneNumberValidation = /\d{3}-\d{3}-\d{4}/;
/** Use this for validation */
const schema = Yup.object().shape({
  first_name: Yup.string().required("Field is required"),
  last_name: Yup.string().required("Field is required"),
  phone: Yup.string()
    .required("Field is required")
    .test("phone", "Field doesn't match the format", (phone) => {
      return phoneNumberValidation.test(phone as string);
    }),
  email: Yup.string()
    .email("Must be as an email format")
    .required("Field is required"),
});

type DealMemberFormModalProps = {
  open: boolean;
  onClose: React.Dispatch<SetStateAction<boolean>>;
  participant: UserParticipant;
};
const DealMemberFormModal: React.FC<DealMemberFormModalProps> = ({
  participant,
  open,
  onClose,
}) => {
  const dispatch = useDispatch();
  const successUpdate = useTypedSelector(
    (state) => state.DealsReducer.successUpdate,
  );
  useEffect(() => {
    if (successUpdate === true) {
      dispatch(addSuccessMessageAC("Updated success"));
      dispatch(setSuccessUpdateAC(null));
      onClose(false);
    }
  }, [dispatch, onClose, successUpdate]);

  return (
    <DeededPopup
      sx={{}}
      open={open}
      title="Edit Client"
      setOpen={onClose}
      sxInternalBox={{
        width: "400px",
        overflowX: "hidden",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
          width: "5px",
          scrollbarWidth: "thin",
        },
      }}
    >
      <div className="role__edit--wrapper">
        <div className="role__edit--form">
          <Formik
            validateOnBlur={false}
            validateOnChange={false}
            initialValues={participant}
            validationSchema={schema}
            onSubmit={(data) => {
              // Post to backend
              // Use DealReducer to update the value then close the form
              const payload = {
                role: data.role,
                first_name: data.first_name,
                middle_name: data.middle_name,
                last_name: data.last_name,
                name_prefix: data.name_prefix,
                phone: data.phone,
                email: data.email,
              };
              dispatch(
                updateTeamTC(participant.deal_id, payload, participant.id),
              );
            }}
          >
            {({handleSubmit, errors, handleChange, handleBlur, values}) => (
              <Form onSubmit={handleSubmit}>
                <div className="role__edit--inputs">
                  <div className="prefix__container">
                    <label className="radio-container">
                      Mr.
                      <input
                        checked={values.name_prefix === "Mr."}
                        onChange={handleChange}
                        type="radio"
                        name="name_prefix"
                        defaultValue="Mr."
                      />
                      <span className="checkmark" />
                    </label>
                    <label className="radio-container">
                      Ms.
                      <input
                        checked={values.name_prefix === "Ms."}
                        onChange={handleChange}
                        type="radio"
                        name="name_prefix"
                        defaultValue="Ms."
                      />
                      <span className="checkmark" />
                    </label>
                    <label className="radio-container">
                      Mrs.
                      <input
                        checked={values.name_prefix === "Mrs."}
                        onChange={handleChange}
                        type="radio"
                        name="name_prefix"
                        defaultValue="Mrs."
                      />
                      <span className="checkmark" />
                    </label>
                    <label className="radio-container">
                      Dr.
                      <input
                        onChange={handleChange}
                        checked={values.name_prefix === "Dr."}
                        type="radio"
                        name="name_prefix"
                        defaultValue="Dr."
                      />
                      <span className="checkmark" />
                    </label>
                    <label className="radio-container">
                      Corporation
                      <input
                        onChange={handleChange}
                        checked={values.name_prefix === "Corporation"}
                        type="radio"
                        name="name_prefix"
                        defaultValue="Corporation"
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <label htmlFor="name">
                    {values.name_prefix === "Corporation"
                      ? "Corporation Name"
                      : "First Name"}
                    <input
                      className={!errors.first_name ? "" : "input-error"}
                      autoComplete="new-password"
                      type="text"
                      name="first_name"
                      value={values.first_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.first_name && (
                      <span
                        style={{
                          marginTop: "-5px",
                          marginBottom: "10px",
                          color: "red",
                        }}
                      >
                        {errors.first_name}
                      </span>
                    )}
                  </label>
                  {values.name_prefix !== "Corporation" && (
                    <>
                      <label htmlFor="name">
                        Middle Name
                        <input
                          className={!errors.middle_name ? "" : "input-error"}
                          autoComplete="new-password"
                          type="text"
                          name="middle_name"
                          value={values.middle_name!}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.middle_name && (
                          <span
                            style={{
                              marginTop: "-5px",
                              marginBottom: "10px",
                              color: "red",
                            }}
                          >
                            {errors.middle_name}
                          </span>
                        )}
                      </label>

                      <label htmlFor="name">
                        Last Name
                        <input
                          className={!errors.last_name ? "" : "input-error"}
                          autoComplete="new-password"
                          type="text"
                          name="last_name"
                          onChange={handleChange}
                          value={values.last_name}
                        />
                        {errors.last_name && (
                          <span
                            style={{
                              marginTop: "-5px",
                              marginBottom: "10px",
                              color: "red",
                            }}
                          >
                            {errors.last_name}
                          </span>
                        )}
                      </label>
                    </>
                  )}
                  <label htmlFor="phone">
                    {values.name_prefix === "Corporation"
                      ? "Phone Number"
                      : "phone"}
                    <NumberFormat
                      className={!errors.phone ? "" : "input-error"}
                      format="###-###-####"
                      name="phone"
                      value={values.phone}
                      onChange={handleChange}
                    />
                    {errors.phone && (
                      <span
                        style={{
                          marginTop: "-5px",
                          marginBottom: "10px",
                          color: "red",
                        }}
                      >
                        {errors.phone}
                      </span>
                    )}
                  </label>
                  <label htmlFor="email">
                    {values.name_prefix === "Corporation"
                      ? "Email Address"
                      : "Email"}
                    <input
                      autoComplete="new-password"
                      className={!errors.email ? "" : "input-error"}
                      type="text"
                      name="email"
                      value={values.email as string}
                      onChange={handleChange}
                    />
                    {errors.email && (
                      <span
                        style={{
                          marginTop: "-5px",
                          marginBottom: "10px",
                          color: "red",
                        }}
                      >
                        {errors.email}
                      </span>
                    )}
                  </label>
                  <button type={"submit"} className="submit">
                    submit
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </DeededPopup>
  );
};

export default DealMemberFormModal;
