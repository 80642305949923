
export { default as TermsOfRefinanceON } from "./TermsOfRefinanceON";
export { default as TermsOfRefinanceBC } from "./TermsOfRefinanceBC";
export { default as TermsOfRefinanceAB } from "./TermsOfRefinanceAB";
export { default as TermsOfPurchaseON } from "./TermsOfPurchaseON";
export { default as TermsOfPurchaseBC } from "./TermsOfPurchaseBC";
export { default as TermsOfPurchaseAB } from "./TermsOfPurchaseAB";
export { default as TermsOfSaleON } from "./TermsOfSaleON";
export { default as TermsOfSaleBC } from "./TermsOfSaleBC";
export { default as TermsOfSaleAB } from "./TermsOfSaleAB";
export { default as TermsAndConditionsDashboard } from "./TermsAndConditionsDashboard";


import TermsOfRefinanceON from "./TermsOfRefinanceON";
import TermsOfRefinanceBC from "./TermsOfRefinanceBC";
import TermsOfRefinanceAB from "./TermsOfRefinanceAB";
import TermsOfPurchaseON from "./TermsOfPurchaseON";
import TermsOfPurchaseBC from "./TermsOfPurchaseBC";
import TermsOfPurchaseAB from "./TermsOfPurchaseAB";
import TermsOfSaleON from "./TermsOfSaleON";
import TermsOfSaleBC from "./TermsOfSaleBC";
import TermsOfSaleAB from "./TermsOfSaleAB";
import Colors from "components/CommonDashboard/Styling/Colors";
import { Stack } from "@mui/material";
import { BODY_SMALL, SUBTITLE_SMALL } from "components/CommonDashboard/Styling/Styles";

const styles = {
    borderRadius: '24px',
    backgroundColor: Colors.WHITE,
    padding: '2.4rem',
    gap: '2rem',
    h1: {
        textAlign: 'center',
    },
    '.type__title': SUBTITLE_SMALL,
    '.type__subtitle': BODY_SMALL,
    ul: {
        paddingLeft: '2rem'
    },
    '& p': {...BODY_SMALL}
};
export const RetainerIntake = ({ dealType, province }) => {
    const renderTerms = (Component) => <Stack sx={styles}><Component /></Stack>;

    /* Purchase */
    if (!dealType || !province) return <div style={{ width: "740px" }}>Loading...</div>
    if (dealType === "Purchase" && province === "ON") return renderTerms(TermsOfPurchaseON);
    if (dealType === "Purchase" && province === "BC") return renderTerms(TermsOfPurchaseBC);
    if (dealType === "Purchase") return renderTerms(TermsOfPurchaseAB);

    /* Sale */
    if (dealType === "Sale" && province === "ON") return renderTerms(TermsOfSaleON);
    if (dealType === "Sale" && province === "BC") return renderTerms(TermsOfSaleBC);
    if (dealType === "Sale") return renderTerms(TermsOfSaleAB);

    /* Mortgage && Lender */
    if (province === "ON") return renderTerms(TermsOfRefinanceON);
    if (province === "BC") return renderTerms(TermsOfRefinanceBC);
    if (province === "AB") return renderTerms(TermsOfRefinanceAB);

    return renderTerms(TermsOfPurchaseON);
}