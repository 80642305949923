type PlusButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

const PlusButton: React.FC<PlusButtonProps> = (props) => (
  <button {...props} type="button" className="addSigningAppointment">
    +
  </button>
);

export default PlusButton;
