import React, {useContext, useState} from "react";
import DeededPopup from "v2/components/DeededPopup";
import {
  Box,
  Button,
  IconButton,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import constants from "../../../../../styles/constants";
import CloseDeededSvg from "../../../../../components/Svg/CloseDeededSvg";
import DeededInput from "v2/components/DeededInput";
import DeededButton from "v2/components/DeededButton";
import {ProfileSecurityPageMtfAuthenticationContext} from "./ProfileSecurityPageMtfAuthentication";
import {useEnablePopupScroll} from "utils/hooks/useEnablePopupScroll";
import {useDispatch} from "react-redux";
import {sendEmailForResetTC} from "../../../../../redux/reducers/profileReducer";
import useTypedSelector from "../../../../../utils/hooks/useTypedSelector";
import { useSnackbar } from "notistack";
import configuration from "utils/configuration";

const ProfileSecurityPageMtfAuthenticationEnterPasswordPopup: React.FC<{}> =
  () => {
    const dispatch = useDispatch();
    const profile = useTypedSelector((state) => state.ProfileReducer.profile);
    const {enqueueSnackbar} = useSnackbar();
    const theme = useTheme();
    const {
      isOpenedEnterPasswordPopup,
      setIsOpenedEnterPasswordPopup,
      confirmMtfPasswordCallback,
      isConfirmMtfPasswordLoading,
      setIsOpenedEnableMtfPopup,
    } = useContext(ProfileSecurityPageMtfAuthenticationContext);
    const [password, setPassword] = useState("");
    useEnablePopupScroll(isOpenedEnterPasswordPopup);
    const handleChangePassword = (e: {target: {value: string}}) => {
      setPassword(e.target.value);
    };
    const handleSubmit = (e: {preventDefault: () => void}) => {
      e.preventDefault();
      if (!password) {
        return enqueueSnackbar("The password field is required.", {
          variant: "error",
          autoHideDuration: configuration.autoHideErrorDuration,
        });
      }
      confirmMtfPasswordCallback({
        password,
        setIsOpenedEnterPasswordPopup,
        setIsOpenedEnableMtfPopup,
      });
    };
    return (
      <>
        <DeededPopup
          open={isOpenedEnterPasswordPopup}
          setOpen={setIsOpenedEnterPasswordPopup}
          sxInternalBox={{
            width: "341px",
            height: "234px",
          }}
        >
          <FormWithoutStyles onSubmit={handleSubmit}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  color: constants.colors.sortingColor,
                }}
              >
                Enter your Deeded password to enable multi factor authentication
              </Typography>
              <CloseBtn
                sx={{
                  width: "48px",
                  height: "48px",
                }}
                onClick={() => {
                  setIsOpenedEnterPasswordPopup(false);
                }}
              >
                <CloseDeededSvg />
              </CloseBtn>
            </Box>
            <Box
              sx={{
                "& .MuiFormControl-root": {
                  width: "100%",
                  height: "38px",
                  marginTop: "32px",
                },
              }}
            >
              <DeededInput
                type={"password"}
                placeholder={"Password"}
                onChange={handleChangePassword}
                value={password}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                marginTop: "32px",
                justifyContent: "space-between",
              }}
            >
              <DeededButton
                type={"submit"}
                sx={{
                  height: "28px",
                  width: "139px",
                  [theme.breakpoints.down("sm")]: {
                    width: "139px",
                  },
                }}
              >
                {isConfirmMtfPasswordLoading ? "Loading..." : "Submit"}
              </DeededButton>
              <Button
                onClick={() => {
                  dispatch(
                    sendEmailForResetTC(
                      profile?.email as string,
                      setIsOpenedEnterPasswordPopup,
                    ),
                  );
                }}
                type={"button"}
                sx={{
                  color: constants.colors.red,
                  textTransform: "capitalize",
                  fontSize: "14px",
                  height: "28px",
                  whiteSpace: "nowrap",
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                  "&:active": {
                    backgroundColor: "transparent",
                  },
                  "&:focus": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                Forgot Password
              </Button>
            </Box>
          </FormWithoutStyles>
        </DeededPopup>
      </>
    );
  };
const CloseBtn = styled(IconButton)({
  minWidth: "0px",
  marginTop: "-22px",
  marginRight: "-22px",
  transition: `${constants.transitions} !important`,
  "&:hover": {
    transition: `${constants.transitions} !important`,
  },
});
const FormWithoutStyles = styled("form")({});
export default ProfileSecurityPageMtfAuthenticationEnterPasswordPopup;
