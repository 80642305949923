import useDealSelector from "utils/hooks/useDealSelector";
import AppointmentForm from "./AppointmentForm";
import React from "react";
import {StaffUser} from "../../../redux/reducers/configReducer/staffCardReducer";
import {NewStaffType} from "./SigningAppointmentFormSection";

const SigningAppointmentCreateForm: React.FC<{
  updateStaffCallback: (
    newStaff: NewStaffType,
    oldSigner: StaffUser | null,
  ) => void;
  deleteUserFromDealStaff: (userId: number) => void;
}> = ({updateStaffCallback, deleteUserFromDealStaff}) => {
  const deal = useDealSelector();
  return (
    <>
      <AppointmentForm
        deleteUserFromDealStaff={deleteUserFromDealStaff}
        updateStaffCallback={updateStaffCallback}
        appointment={{
          user_id: -1,
          deal_id: deal?.id ?? -1,
          address: "Empty Address",
          datetime: null,
          end_at: null,
          deleted_at: null,
          timezone: null,
          calendar_id: 0,
          signer: null,
          appointment_updates: [],
        }}
      />
    </>
  );
};

export default SigningAppointmentCreateForm;
