import React, {createContext, useContext, useState} from "react";

export const UploadedDocsContext = createContext(null);

export const UploadedDocsProvider = ({children}) => {
  const [loading, setLoading] = useState(false);
  const toggleLoading = () => setLoading((prevState) => !prevState);
  return (
    <UploadedDocsContext.Provider
      value={{
        loading,
        toggleLoading,
      }}
    >
      {children}
    </UploadedDocsContext.Provider>
  );
};

export const useUploadedDocs = () => useContext(UploadedDocsContext);
export const withUploadedDocs = (Component) => {
  return (props) => {
    const {loading} = useUploadedDocs();
    return <Component {...props} loading={loading} />;
  };
};
