import { SVGProps } from "react";

const DeededMobileLogo = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M0 0V24V48H23.811H47.6215V24V0H23.811H0ZM5.55521 5.60076H14.2869H23.0167V24V42.4002H14.2869H5.55521V24V5.60076ZM24.6044 5.60076H33.3338H42.0657V11.2004V16.8H33.3338H24.6044V11.2004V5.60076ZM24.6044 18.4004H33.3338H42.0657V24V29.6008H33.3338H24.6044V24V18.4004ZM24.6044 31.1999H33.3338H42.0657V36.7995V42.4002H33.3338H24.6044V36.7995V31.1999Z"
            fill="#F06A6A"
        />
    </svg>

);

export default DeededMobileLogo;



