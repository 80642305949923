import React, { ChangeEvent, FC, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, Button, MenuItem, styled, Typography, useTheme } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { useFormik } from "formik";
import * as yup from "yup";
import moment from "moment-timezone";
import constants from "styles/constants";
import DeededRadioButton from "v2/components/DeededRadioButton";
import { allProvincesList } from "components/Dashboard/Calendar/constants/allProvincesList";
import DeededProvinceButton from "v2/components/DeededProvinceButton";
import DeededSelectV2Transparent from "v2/components/DeededSelect";
import DeededInput from "v2/components/DeededInput";
import { setInitialItemCalendar } from "components/Dashboard/Calendar/utils/setInitialItemCalendar";
import { allProvincesLabelsList } from "components/Dashboard/Calendar/constants/allProvincesLabelsList";
import { H2 } from "components/CommonDashboard/Typography";
import { setCalendarRegionAC } from "redux/reducers/authReducer";
import { addSuccessMessageAC } from "redux/reducers/ErrorReducer";
import { Access } from "types/staffTypes";
import timeZones from "utils/timeZones";
import useUser from "utils/hooks/useUser";
import useTypedSelector from "utils/hooks/useTypedSelector";

const TIME_PARSE_FORMAT = "HH:mm";

const schema = yup.object().shape({
    current_view: yup.string().required("Field is required"),
    region: yup.array().of(yup.string()),
    timezone: yup.string().required("Field is required"),
    lawClerk: yup.string(),
    isAdmin: yup.boolean(),
    medium: yup.string().when("isAdmin", {
        is: (isAdmin: boolean) => isAdmin,
        then: () => yup.string().required("Field is required"),
    }),
    high: yup.string().when("isAdmin", {
        is: (isAdmin: boolean) => isAdmin,
        then: () => yup.string().required("Field is required"),
    }),
});

export interface PreferencesInitialValues {
    current_view: string;
    region: string[];
    timezone: string;
    lawClerk: number;
    medium: string;
    high: string;
    isAdmin: boolean;
}

interface ProfilePreferencesFormProps {
    lawClerks: Access[];
    onSubmit: (params: PreferencesInitialValues) => void;
}

const ProfilePreferencesForm: FC<ProfilePreferencesFormProps> = ({ lawClerks, onSubmit }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const { isAdmin, ...profile } = useUser();
    const profile2 = useTypedSelector((state) => state.ProfileReducer.profile);
    const { isGettingEvents } = useTypedSelector((state) => state.CalendarReducer);

    const [region, setRegion] = useState(
        setInitialItemCalendar({ ...profile, isAdmin }, allProvincesLabelsList, "region"),
    );

    const isProfileSettings = profile?.user.settings;
    const isLawClerkCapacity = lawClerks && lawClerks[0].settings?.capacity;

    const initialValues = {
        current_view: isProfileSettings ? isProfileSettings?.calendar?.view ?? "day" : "day",
        region: isProfileSettings ? isProfileSettings.calendar?.region?.split(", ") ?? ["ALL"] : ["ALL"],
        timezone: profile2?.timezone_calendar ?? profile.user.timezone_calendar,
        lawClerk: lawClerks && lawClerks[0].id,
        medium: isLawClerkCapacity ? isLawClerkCapacity.medium : "",
        high: isLawClerkCapacity ? isLawClerkCapacity.high : "",
        isAdmin: isAdmin,
    } as PreferencesInitialValues;

    const _onSubmit = (params: PreferencesInitialValues) => {
        onSubmit(params);
        if (params.region.length === 0) {
            dispatch(setCalendarRegionAC(null));
            setRegion(allProvincesLabelsList);
            handleChange({
                target: {
                    name: "region",
                    value: allProvincesLabelsList,
                },
            });
            dispatch(addSuccessMessageAC("All regions were set by default if all region fields are empty"));
        } else {
            dispatch(setCalendarRegionAC(params.region.join(",")));
        }
    };

    const { handleSubmit, values, handleChange, handleBlur, errors } = useFormik({
        validationSchema: schema,
        initialValues,
        onSubmit: _onSubmit,
    });

    const handleChangeRegions = (e: ChangeEvent<HTMLInputElement>) => {
        const { value, checked } = e.target as HTMLInputElement;

        let newRegions;
        if (checked) {
            newRegions = [...(region as string[]), value];
        } else {
            newRegions = (region as string[]).filter((el) => el !== value);
        }

        setRegion(newRegions);
        handleChange({
            target: {
                name: "region",
                value: newRegions,
            },
        });
    };

    const handleChangeLawClerk = (val: unknown) => {
        if (lawClerks) {
            const lawClerk = lawClerks.find((item) => item.id === val)?.settings;
            handleChange({
                target: {
                    name: "high",
                    value: lawClerk?.capacity?.high ?? "",
                },
            });
            handleChange({
                target: {
                    name: "medium",
                    value: lawClerk?.capacity?.medium ?? "",
                },
            });
        }
    };

    const handleChangeInput = (name: string, val: string) => {
        handleChange({
            target: {
                name: name,
                value: val,
            },
        });
        handleChange({
            target: {
                name: "isAdmin",
                value: isAdmin,
            },
        });
    };

    return (
        <Form onSubmit={handleSubmit}>
            <FormItem>
                {["Day", "Week", "Month"].map(option => (
                    <DeededRadioButton
                        key={option}
                        label={option}
                        checked={values.current_view === option.toLowerCase()}
                        onChange={handleChange as () => void}
                        onBlur={handleBlur as () => void}
                        value={option.toLowerCase()}
                        name="current_view"
                    />
                ))}
            </FormItem>
            <FormItem>
                <LabelForm>Select Region(s):</LabelForm>
                <RegionsWrapper>
                    {allProvincesList.map(({ label, name }: { label: string; name: string }) => {
                        let fill: string;
                        switch (label) {
                            case "ON":
                                fill = constants.provinceColors.ON;
                                break;
                            case "AB":
                                fill = constants.provinceColors.AB;
                                break;
                            case "BC":
                                fill = constants.provinceColors.BC;
                                break;
                            default:
                                fill = constants.provinceColors.Other;
                        }
                        return (
                            <DeededProvinceButton
                                data-selenium-id={`province-${label}`}
                                sx={{
                                    marginRight: "12px",
                                    [theme.breakpoints.down("sm")]: {
                                        width: "35px",
                                        fontSize: "12px",
                                        height: "20px",
                                    },
                                }}
                                key={label}
                                title={label === "OTHER" ? "RP" : label}
                                bgColor={fill}
                                checked={region.includes(label)}
                                onClick={() => {
                                    if (!isGettingEvents) {
                                        handleChangeRegions({
                                            target: {
                                                value: label,
                                                checked: !region.includes(label),
                                            },
                                        } as unknown as ChangeEvent<HTMLInputElement>);
                                    }
                                }}
                            />
                        );
                    })}
                </RegionsWrapper>
            </FormItem>
            <FormItem
                sx={{
                    flexGrow: isAdmin ? 0 : 1,
                    alignItems: !isAdmin ? "flex-start" : "center",
                    [theme.breakpoints.down("sm")]: { width: "100%" },
                }}
            >
                <LabelForm sx={{ marginTop: isAdmin ? 0 : "10px" }}>Timezone:</LabelForm>
                <InputWrapper
                    sx={{
                        [theme.breakpoints.down("sm")]: {
                            width: "100%",
                        },
                    }}
                >
                    <Box
                        sx={{
                            [theme.breakpoints.down("sm")]: {
                                width: "100%",
                                " .MuiBox-root": {
                                    width: "100%",
                                },
                            },
                        }}
                    >
                        <DeededSelectV2Transparent
                            onChange={(e) => handleChange(e)}
                            value={values.timezone}
                            name="timezone"
                            sx={{
                                width: "293px",
                                [theme.breakpoints.down("sm")]: {
                                    width: "100%",
                                    marginLeft: "10px",
                                    marginTop: "7px",
                                },
                            }}
                        >
                            {timeZones.map(({ name, value }) => (
                                <MenuItem key={value} value={value}>
                                    {name}
                                </MenuItem>
                            ))}
                        </DeededSelectV2Transparent>

                        {values.timezone && (
                            <TimeZone>
                                {moment().tz(values.timezone)?.format(TIME_PARSE_FORMAT)} (UTC{" "}
                                {moment().tz(values.timezone)?.utcOffset() / 60})
                            </TimeZone>
                        )}
                    </Box>
                </InputWrapper>
            </FormItem>

            {isAdmin && (
                <FormItem
                    sx={{
                        // flexGrow: 1,
                        alignItems: "flex-start",
                        flexDirection: "column",
                        marginTop: "10px",
                    }}
                >
                    <H2 sx={{ marginBottom: "24px" }}>Capacity Thresholds</H2>
                    <FormItemRow>
                        <CapacityThresholdsLabelForm>Law Clerk:</CapacityThresholdsLabelForm>
                        <Box
                            sx={{
                                width: "100%",
                            }}
                        >
                            <DeededSelectV2Transparent
                                name="lawClerk"
                                onChange={(e) => {
                                    handleChange(e);
                                    handleChangeLawClerk(e?.target.value);
                                }}
                                width="100%"
                                value={values.lawClerk}
                            >
                                {lawClerks &&
                                    lawClerks.map(({ id, first_name, last_name }) => (
                                        <MenuItem key={id} value={id}>
                                            {first_name} {last_name}
                                        </MenuItem>
                                    ))}
                            </DeededSelectV2Transparent>
                        </Box>
                    </FormItemRow>
                    <FormItemRow
                        sx={{
                            ".DeededInputError": {
                                whiteSpace: "nowrap",
                                marginLeft: "5px",
                            },
                        }}
                    >
                        <CapacityThresholdsLabelForm>Medium (Yellow):</CapacityThresholdsLabelForm>
                        <DeededInput
                            type="number"
                            sx={{
                                minWidth: "50px",
                                width: "50px",
                                [theme.breakpoints.down("sm")]: {
                                    width: "100%",
                                },
                            }}
                            value={values.medium}
                            onBlur={handleBlur}
                            onChange={(e) => {
                                handleChangeInput("medium", e.target.value);
                            }}
                            error={errors.medium}
                        />
                    </FormItemRow>
                    <FormItemRow
                        className="sjhdjshdj"
                        sx={{
                            ".DeededInputError": {
                                whiteSpace: "nowrap",
                                marginLeft: "5px",
                            },
                        }}
                    >
                        <CapacityThresholdsLabelForm>High (Red):</CapacityThresholdsLabelForm>
                        <DeededInput
                            type="number"
                            sx={{
                                minWidth: "50px",
                                width: "50px",
                                [theme.breakpoints.down("sm")]: {
                                    width: "100%",
                                },
                            }}
                            onBlur={handleBlur}
                            value={values.high}
                            onChange={(e) => {
                                handleChangeInput("high", e.target.value);
                            }}
                            error={errors.high}
                        />
                    </FormItemRow>
                </FormItem>
            )}
            <FormBtnSubmitWrapper>
                <FormBtnSubmit type="submit">Save Changes</FormBtnSubmit>
            </FormBtnSubmitWrapper>
        </Form>
    );
};

const Form = styled("form")(({ theme }) => ({
    marginTop: "24px",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
        marginTop: "30px",
    },
}));

const FormItem = styled(Box)(({ theme }) => ({
    display: "flex",
    marginBottom: "30px",
    alignItems: "center",
    flexWrap: "wrap",
    width: "100%",
    "& .MuiFormControlLabel-root": {
        "& .MuiTypography-root": {
            fontSize: "16px",
            fontWeight: "600",
        },
    },
    [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        alignItems: "flex-start",
        marginBottom: "20px",
        "& > label": {
            marginBottom: "15px",
        },
    },
}));

const FormItemRow = styled(Box)<{ theme?: Theme }>(({ theme }) => ({
    display: "flex",
    alignItems: "center",

    "&:not(:last-child)": {
        marginBottom: "10px",
    },
    [theme.breakpoints.down("sm")]: {
        alignItems: "start",
        flexDirection: "column",
        width: "100%",
    },
}));

const FormBtnSubmitWrapper = styled(Box)({
    display: "flex",
    justifyContent: "flex-end",
});

const FormBtnSubmit = styled(Button)(({ theme }) => ({
    height: "43px",
    width: "175px",
    backgroundColor: "var(--red)",
    border: `1px solid ${constants.colors.red}`,
    boxSizing: "border-box",
    borderRadius: "5px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "17px",
    color: constants.colors.white,
    textTransform: "capitalize",
    "&:hover": {
        color: "var(--red)",
    },
    [theme.breakpoints.down("sm")]: {
        width: "100%",
    },
}));

const LabelForm = styled(Typography)({
    fontSize: "14px",
    fontWeight: "600",
    fontFamily: "Montserrat",
    color: constants.colors.sortingColor,
    marginRight: "16px",
    whiteSpace: "nowrap",
});
const CapacityThresholdsLabelForm = styled(LabelForm)<{ theme?: Theme }>(({ theme }) => ({
    flex: "0 0 150px",
    [theme.breakpoints.down("sm")]: {
        flex: "0 0 30px",
    },
}));
const RegionsWrapper = styled(Box)(({ theme }) => ({
    display: "flex",
    flexWrap: "wrap",
    [theme.breakpoints.down("sm")]: {
        marginTop: "15px",
        marginLeft: "0px",
    },
}));

const InputWrapper = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "unset",
    alignItems: "flex-start",
    "& > div": {
        display: "flex",
        alignItems: "center",
    },
    [theme.breakpoints.down("sm")]: {
        "& > div": {
            flexDirection: "column",
            marginLeft: "-10px",
            alignItems: "flex-start",
        },
    },
}));

const TimeZone = styled(Box)(({ theme }) => ({
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "18px",
    color: constants.colors.deededGray,
    marginLeft: "20px",
    [theme.breakpoints.down("sm")]: {
        marginLeft: "11px",
        marginTop: "15px",
    },
}));

export default ProfilePreferencesForm;
