import React from "react";

const PendingStatus = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 0C5.36705 0 0 5.36761 0 12C0 18.633 5.36761 24 12 24C18.633 24 24 18.6324 24 12C24 5.36705 18.6324 0 12 0ZM12 22.4C6.26545 22.4 1.59998 17.7346 1.59998 12C1.59998 6.26541 6.26545 1.59998 12 1.59998C17.7345 1.59998 22.4 6.26545 22.4 12C22.4 17.7345 17.7345 22.4 12 22.4Z"
        fill="#FFD43D"
      />
      <rect
        width="11"
        height="2"
        rx="1"
        transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 13 15)"
        fill="#FFD43D"
      />
      <rect
        width="2"
        height="2"
        rx="1"
        transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 13 20)"
        fill="#FFD43D"
      />
    </svg>
  );
};

export default PendingStatus;
