import React from "react";
import {SvgIcon} from "@mui/material";

const DeededCloseChipIcon = ({color, ...props}: {color: string}) => {
  return (
    <SvgIcon className="customClose" viewBox="0 0 9 9" {...props}>
      <svg
        width="9"
        height="9"
        viewBox="0 0 9 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.03125 1.96875L1.96875 7.03125"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.03125 7.03125L1.96875 1.96875"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};
export default DeededCloseChipIcon;
