import { forwardRef } from "react";
import NumberFormat, { InputAttributes } from "react-number-format";
import { InputMask, InputMaskProps, Track } from "@react-input/mask";

interface FormatProps {
    onChange: (event: { target: { name: number; value: number } }) => void;
    name: number;
}

const PhoneFormat = forwardRef<NumberFormat<InputAttributes>, FormatProps>(function NumberFormatCustom(props, ref) {
    const { onChange, name, ...other } = props;

    return (
        // @ts-ignore
        <NumberFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        // @ts-ignore
                        value: values.formattedValue.replace(/ /g, ""),
                    },
                });
            }}
            allowNegative={false}
            decimalScale={0}
            format="###-###-####"
        />
    );
});

const PostalCodeFormat = forwardRef<HTMLInputElement, InputMaskProps>((props, forwardedRef) => {
    const track: Track = ({ data }) => (data && isNaN(+data) ? data.toUpperCase() : data);
    return (
        <InputMask
            ref={forwardedRef}
            mask="_#_ #_#"
            replacement={{ "#": /\d/, _: /[a-zA-Z]/ }}
            track={track}
            {...props}
        />
    );
});

export { PhoneFormat, PostalCodeFormat };
