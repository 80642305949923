import React, {useCallback, MouseEventHandler} from "react";
import {Box, styled, useTheme} from "@mui/material";

import DeededPopup from "v2/components/DeededPopup";

import * as yup from "yup";
import {useFormik} from "formik";
import DeededInput from "v2/components/DeededInput";
import DeededButton from "v2/components/DeededButton";
import DeededTextArea from "v2/components/DeededTextArea";
import DeededLabel from "v2/components/DeededLabel";
import DeededSpace from "v2/components/DeededSpace";
import {updateDocumentAgreement} from 'api';
import configuration from "../../../utils/configuration";
import useUser from "../../../utils/hooks/useUser";
import {useMixpanel} from "../../../utils/MixpanelContext";


interface DealDocumentEditPopupProps {
  open: boolean;
  title: string;
  description: string;
  agreementID: number;
  updateList: boolean;
  enqueueSnackbar: (title: string, options: object) => void;
  toggleEditDocumentPopup: () => void;
  setUpdateList: (flag: boolean) => void;
  setShowDocumentTitleModal: (showDocumentEditPopup: boolean) => void;
}

interface SubmitProps {
  title: string;
  description: string;
}

const DealDocumentEditPopup: React.FC<
  DealDocumentEditPopupProps
> = ({
  open,
  title,
  description,
  agreementID,
  enqueueSnackbar,
  toggleEditDocumentPopup,
  updateList,
  setUpdateList,
  setShowDocumentTitleModal
}) => {
  const theme = useTheme();
  const {user} = useUser();
  const mixpanel = useMixpanel();

  const schema = yup.object().shape({
    title: yup.string().required(),
    description: yup.string().nullable(),
  });
  const initialValues = {
    title: title ? title : '',
    description: description ? description : '',
  };

  const onSubmit = (props: SubmitProps) => {
    updateDocumentAgreement(agreementID, props)
    .then(res => {
      setUpdateList(!updateList);
      toggleEditDocumentPopup();

      // Send data for mixpanel analytics
      const {role, email, full_name} = user;
      const mixPayload = {
        role,
        email,
        full_name,
        document_title: props.title,
        title_changed: props.title !== initialValues.title,
        description_changed: props.description !== initialValues.description,
      };
      mixpanel.track(`document_name_and_description_update`, mixPayload);
    })
    .catch((error) => {
      enqueueSnackbar(error.response?.data.error, {
        variant: "error",
        autoHideDuration: configuration.autoHideErrorDuration,
      });
    })
  }

  const {handleSubmit, values, errors, handleChange, setFieldError, setErrors} = useFormik({
    validationSchema: schema,
    initialValues,
    onSubmit,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const handleChangeTitle = useCallback(
    (e) => {
      handleChange({
        target: {
          name: 'title',
          value: e.target.value,
        },
      });
    },
    [handleChange],
  );

  const handleChangeDescription = useCallback(
    (e) => {
      handleChange({
        target: {
          name: 'description',
          value: e.target.value,
        },
      });
    },
    [handleChange],
  );

  return (
    <>
      <DeededPopup
        sx={{
          overflowX: "hidden",
        }}
        open={open}
        setOpen={setShowDocumentTitleModal}
        sxInternalBox={{
          overflowX: "hidden",
          [theme.breakpoints.down("lg")]: {
            width: "100%",
            height: "auto",
          },
          [theme.breakpoints.down("sm")]: {
            padding: "20px",
          },
        }}
        title="Edit Document Details"
        data-testid="deal-document-edit-pop-up"
      >
        <form autoComplete={"off"}>
          <FieldsBox>
            <DeededLabel sx={{marginLeft: 0}} title={"Document Name"} />
            <DeededInput
              value={values.title as string}
              error={errors.title as string}
              onChange={handleChangeTitle}
            />
            <DeededSpace y={21} />
            <DeededLabel title={"Document Description"} />
            <DeededTextArea
              // error={errors.title as string}
              onChange={handleChangeDescription}
              text={values.description as string || ''}
              setText={(text) => {}}
              rows={8}
              inputProps={{ maxLength: 600 }}
              />
            <DeededSpace y={21} />
          </FieldsBox>
          <ButtonsBox>
            <DeededButton
              data-testid="deal-document-edit-cancel-button"
              kind="secondary"
              sx={{
                marginRight: "14px",
              }}
              onClick={() => toggleEditDocumentPopup()}
            >
              Cancel
            </DeededButton>
            <DeededButton
              data-testid="deal-document-edit-save-button"
              onClick={
                handleSubmit as unknown as MouseEventHandler<HTMLButtonElement>
              }
            >
              Save
            </DeededButton>
          </ButtonsBox>
        </form>
      </DeededPopup>
    </>
  );
};
export default DealDocumentEditPopup;

const ButtonsBox = styled(Box)(({theme}) => ({
  display: "flex",
  justifyContent: "space-between",
  [theme.breakpoints.down(450)]: {
    marginTop: "24px",
    "& button svg": {
      display: "none",
    },
  },
}));

const FieldsBox = styled(Box)(({theme}) => ({
  "& .MuiFormControl-root": {
    width: "100%",
  },
  marginTop: '2.4rem',
  overflowX: "visible",
  maxWidth: "100%",
}));