import React from "react";
import {
  FormControlLabel,
  FormControlLabelProps,
  Radio,
  styled,
  SxProps,
  Theme,
} from "@mui/material";
import constants from "../../styles/constants";
interface DeededRadioButtonProps {
  label?: string;
  checked: boolean;
  disabled?: boolean;
  sx?: SxProps<Theme>;
}

const DeededRadioButton: React.FC<
  DeededRadioButtonProps | FormControlLabelProps
> = ({label, disabled, checked, sx, ...props}) => {
  return (
    <StyledFormControlLabel
      disabled={disabled}
      control={<StyledRadio checked={checked as boolean} />}
      label={label ?? ""}
      data-selenium-test="DeededRadioButton"
      sx={sx}
      {...props}
    />
  );
};

const StyledRadio = styled(Radio)<{checked: boolean}>(({checked}) => ({
  "&.MuiRadio-root": {
    padding: "8px",
    color: checked ? constants.colors.red : constants.colors.deededGray,
    transition: constants.transitions,
    "&:hover": {
      transition: constants.transitions,
      backgroundColor: constants.colors.redVeryPale,
    },
    span: {
      " svg": {
        width: "23px",
        height: "23px",
      },
    },
    "&.Mui-disabled": {
      pointerEvents: "auto",
      cursor: "no-drop",
      color: constants.colors.configNotificationsTableBorder,
      span: {
        background: constants.colors.configNotificationsTableBorder,
        borderRadius: "50%",
      },
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
  },
}));

const StyledFormControlLabel = styled(FormControlLabel)({
  color: constants.colors.sortingColor,
  display: "inline-flex",
  flexDirection: "row",
  "&:not(.Mui-disabled):hover": {
    ".MuiRadio-root": {
      transition: constants.transitions,
      backgroundColor: constants.colors.redVeryPale,
    },
  },
  "& .MuiTypography-root": {
    fontSize: "14px",
  },
  "& .MuiFormControlLabel-label": {
    "&.Mui-disabled": {
      color: constants.colors.configNotificationsTableBorder,
      cursor: "no-drop",
    },
  },
});

export default DeededRadioButton;
