import {InputLabel, SxProps, Theme} from "@mui/material";
import {styled} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import constants from "../../styles/constants";

const DeededLabel: React.FC<
  React.ComponentProps<typeof DeededLabelBase> & {
    title: string;
    disabled?: boolean;
    sx?: SxProps<Theme>;
  }
> = ({title, disabled, children, sx, ...props}) => (
  <DeededLabelBase
    {...props}
    data-selenium-test="DeededLabel"
    disabled={disabled}
    sx={sx}
  >
    <StyledLabel>{title}</StyledLabel>
    {children}
  </DeededLabelBase>
);

const DeededLabelBase = styled(InputLabel)({
  display: "flex",
  margin: 0,
  "&.Mui-disabled p": {
    color: constants.colors.searchColor,
    cursor: "no-drop",
  },
});

const StyledLabel = styled(Typography)({
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "12px",
  lineHeight: "15px",
  marginLeft: "7px",
  marginBottom: "6px",
  marginRight: 0,
  color: constants.colors.sortingColor,
});

export default DeededLabel;
