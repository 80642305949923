import { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import { Box, Stack, useTheme } from "@mui/material";
import useIntegrationDeals from "utils/hooks/useIntegrationDeals";
import PageContainer, { PageSection } from "components/CommonDashboard/Container/PageContainer";
import CircularLoader from "components/Common/Loader/CircularLoader";
import DealsListHeader from "components/Dashboard/DealsList/components/DealsListHeader";
import IntegrationDealRow from "components/Dashboard/Integrations/IntegrationDealRow";
import IntegrationDealHeader from "components/Dashboard/Integrations/IntegrationDealHeader";
import { SubtitleRegular } from "components/CommonDashboard/Typography";
import Modal from "components/CommonDashboard/Modal";

const Integrations: FC = () => {
    const theme = useTheme();
    const history = useHistory();
    const { list, loading, updateDeal, refresh } = useIntegrationDeals();
    const [showModal, setShowModal] = useState<number>();

    const headerWrapperStyle = {
        [theme.breakpoints.down("lg")]: {
            padding: "2.4rem",
        },
        [theme.breakpoints.down("mobile")]: {
            padding: "2.4rem 1.6rem 7.2rem 1.6rem",
        },
    };

    const handleUpdate = async (id: number, dealType: string) => {
        const dealId = await updateDeal(id, dealType);
        if (dealId) {
            setShowModal(dealId);
        }
    };

    if (!loading && list?.length === 0) {
        return (
            <Box px="4.8rem" sx={headerWrapperStyle}>
                <DealsListHeader noMargin title="Integrations" />
                <Stack alignItems="center" justifyContent="center" height="20vh" width="100%">
                    <SubtitleRegular>No transactions yet</SubtitleRegular>
                </Stack>
            </Box>
        );
    }

    return (
        <Stack minHeight="100%">
            <Box px="4.8rem" sx={headerWrapperStyle}>
                <DealsListHeader noMargin title="Integrations" />
            </Box>

            <PageContainer gap="0" flex={1}>
                {loading ? (
                    <CircularLoader containerHeight="20vh" />
                ) : (
                    <PageSection alignItems="normal" overflow="auto">
                        <IntegrationDealHeader />
                        {list?.map((d, i) => (
                            <IntegrationDealRow
                                key={i}
                                onUpdate={(dealType) => handleUpdate(d.id, dealType)}
                                deal={d}
                                first={i === 0}
                                last={i + 1 === list?.length}
                            />
                        ))}
                    </PageSection>
                )}
            </PageContainer>
            <Modal
                open={Boolean(showModal)}
                title="Conversion Successful"
                textContent="The lead has been successfully converted to a transaction."
                onCloseClick={() => setShowModal(undefined)}
                buttonProps={[
                    {
                        typeOf: "secondary",
                        label: { text: "Continue on this page" },
                        onClick: () => {
                            refresh();
                            setShowModal(undefined);
                        },
                    },
                    {
                        typeOf: "primary",
                        label: { text: "Go to transaction" },
                        onClick: () => history.push("/dashboard/deals/" + showModal),
                    },
                ]}
            />
        </Stack>
    );
};

export default Integrations;
