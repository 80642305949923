import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import {Box, styled, Typography, useTheme} from "@mui/material";
import constants from "styles/constants";
import DeededTextArea from "v2/components/DeededTextArea";
import DeededButton from "v2/components/DeededButton";
import DeededSpace from "v2/components/DeededSpace";
import useWindowWidth from "utils/hooks/useWindowWidth";
import {useCreateNewCustomTaskMutation} from "redux/reducers/dealInfoApi";
import {DealDetailsFileTasksContext} from "components/Dashboard/DealDetailsFileTasks/context/DealDetailsFileTasksContext";
import DealDetailsFileTasksPageStageField from "components/Dashboard/DealDetailsFileTasks/components/DealDetailsFileTasksPageStageField";
import { StageType } from "components/Dashboard/DealDetailsFileTasks/components/DealDetailsFileTasksPage";

const DealDetailsFileTasksPageStage: FC<{
  stage: StageType;
  isUndertakings?: boolean;
}> = ({stage, isUndertakings}) => {
  const fields = stage?.fields;
  const [isAddingTaskMode, setIsAddingTaskMode] = useState(false);
  const [isAllowedToAddCustomTasks, setIsAllowedToAddCustomTasks] =
    useState(true);
  const [newTaskName, setNewTaskName] = useState("");
  const isOverfilledNewTaskName = newTaskName.length >= 200;
  const [createCustomTaskCallback] = useCreateNewCustomTaskMutation();
  const {dealId, selectedFileType} = useContext(DealDetailsFileTasksContext);
  const windowWidth = useWindowWidth();
  const theme = useTheme();
  const spaceValue = useMemo(() => {
    if (windowWidth >= theme.breakpoints.values.sm) {
      return 230;
    } else {
      return 0;
    }
  }, [windowWidth]);
  useEffect(() => {
    if (fields?.length >= 8) {
      setIsAddingTaskMode(false);
      setIsAllowedToAddCustomTasks(false);
    } else {
      setIsAllowedToAddCustomTasks(true);
    }
  }, [fields?.length]);
  const handleSetNewTaskName = (text: string) => {
    if (text.length <= 200) {
      setNewTaskName(text);
    }
  };
  const handleNewTaskBlur = (e: {target: {value: string}}) => {
    createCustomTaskCallback({
      dealId,
      taskType: selectedFileType,
      taskName: e.target.value,
    });
    setNewTaskName("");
  };
  return (
    <>
      <Box
        sx={{
          width: isUndertakings ? "100%" : "300px",
          display: "flex",
          flexDirection: "column",
          marginBottom: "72px",
          // marginRight: "96px",
          [theme.breakpoints.down("md")]: {
            width: "100%",
          },
        }}
      >
        <Typography
          sx={{
            fontWeight: "600",
            fontSize: "20px",
            color: constants.colors.title,
            marginBottom: "32px",
          }}
        >
          {stage?.name}
        </Typography>
        {fields?.map((field, fieldIndex) => {
          return (
            <DealDetailsFileTasksPageStageField
              fieldIndex={fieldIndex}
              key={field.id}
              field={field}
            />
          );
        })}
        {isAddingTaskMode && (
          <>
            <Box
              sx={{
                marginBottom: "24px",
                display: "flex",
              }}
            >
              <DeededTextArea
                error={isOverfilledNewTaskName ? "Max 200 characters" : ""}
                onBlur={handleNewTaskBlur}
                sx={{
                  "&.MuiBox-root .MuiFormControl-root .MuiOutlinedInput-root .MuiOutlinedInput-input":
                    {
                      height: "55px !important",
                      overflowY: "scroll !important",
                      paddingRight: "5px",
                      "&::-webkit-scrollbar": {
                        width: "2px",
                      },
                      "&::-webkit-scrollbar-track": {
                        backgroundColor: "transparent",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        borderRadius: "10px",
                      },
                    },
                  width: "80%",
                  maxWidth: "604px",
                  height: "74px",
                  fontSize: "18px",
                  "& .MuiFormControl-root": {
                    height: "74px",
                    "& .MuiOutlinedInput-root": {
                      height: "74px",
                      minHeight: "unset",
                      textarea: {
                        marginTop: "10px",
                        fontSize: "18px",
                      },
                    },
                  },
                  [theme.breakpoints.down("lg")]: {
                    maxWidth: "unset",
                  },
                  [theme.breakpoints.down("sm")]: {
                    width: "100%",
                    // maxWidth:"250px"
                  },
                }}
                text={newTaskName}
                setText={handleSetNewTaskName}
              />
              <DeededSpace x={spaceValue} />
            </Box>
          </>
        )}
        {isUndertakings && isAllowedToAddCustomTasks && (
          <DeededButton
            onClick={() => {
              setIsAddingTaskMode(!isAddingTaskMode);
            }}
            sx={{
              height: "36px !important",
              width: "175px",
              whiteSpace: "nowrap",
              fontSize: "14px",
            }}
            kind={"secondary"}
          >
            <PlusSpan>+</PlusSpan> Add Undertaking
          </DeededButton>
        )}
      </Box>
    </>
  );
};
const PlusSpan = styled("span")({
  marginRight: "8px",
  marginLeft: "0px",
  fontWeight: "300",
  fontSize: "35px",
  marginTop: "2px",
});
export default DealDetailsFileTasksPageStage;
